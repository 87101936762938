import { Routes } from "@angular/router"

import { SettingsComponent } from "./components/settings.component"
import * as SettingsCategoryEditors from "./components/settings-category-editors/index"
import { AuthorizationGuard } from "../shared/authorization-guard"
import { UnsavedChangesGuard } from "../shared/unsaved-changes-guard"
import { UserEditorComponent } from "../user/components/user-editor.component"
import { PasswordChangeComponent } from "../user/components/password-change.component"


export const SETTINGS_ROUTES: Routes = [
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthorizationGuard],
    children: [
      { path: "basic", component: SettingsCategoryEditors.BasicSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "print", component: SettingsCategoryEditors.PrintSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "printer-rules", component: SettingsCategoryEditors.PrinterRulesSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "sync", component: SettingsCategoryEditors.SyncSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "automatic-export", component: SettingsCategoryEditors.AutomaticExportSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "eod-export", component: SettingsCategoryEditors.ParcelExportSettingsFileContentComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "automatic-import", component: SettingsCategoryEditors.AutomaticImportSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "automatic-recipients-import", component: SettingsCategoryEditors.AutomaticRecImportSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "pudo", component: SettingsCategoryEditors.PudoSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "routing", component: SettingsCategoryEditors.RoutingSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "proxy", component: SettingsCategoryEditors.ProxySettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "configurations", component: SettingsCategoryEditors.ConfigurationSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "pickup-addresses", component: SettingsCategoryEditors.PickupAddressesSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "bank-accounts", component: SettingsCategoryEditors.BankAccountsSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "bank-accounts/new", component: SettingsCategoryEditors.BankAccountEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "user-management", component: SettingsCategoryEditors.UserManagementComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "user-management/users/:userId", component: UserEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "info", component: SettingsCategoryEditors.InfoSettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "log", component: SettingsCategoryEditors.LogViewerComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "password", component: PasswordChangeComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "instructions", component: SettingsCategoryEditors.InstructionsViewerComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "backup-restore", component: SettingsCategoryEditors.BackupRestoreComponent },
      { path: "email-templates-editor", component: SettingsCategoryEditors.EmailTempaltesEditor, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "email-server-configuration", component: SettingsCategoryEditors.EmailServerConfigurationComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "", redirectTo: "print", pathMatch: "full" }
    ]
  }
];