import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, forkJoin } from "rxjs";
import { delay } from "rxjs/operators";

import * as _ from "lodash";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as Shared from "../../shared/index";
import * as Model from "../models/pickup-points.models";
import { PickupPointsService } from "../services/pickup-points.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-pickup-points",
  templateUrl: "./pickup-points.component.html"
})
export class PickupPointsComponent extends Shared.RoutedPageComponentBase implements OnInit {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _modal: Modal,
    private _pickupPointsService: PickupPointsService,
    private _skdataConfigService: Shared.SkdataConfigService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public filterText: FormControl = new FormControl("");


  private filterTextValue: BehaviorSubject<string> = new BehaviorSubject<string>("");


  public isBusy = false;
  public isBusyLoadingDetails = false;


  /**
   * Ak sa pri poslednom načítavaní adresátov vyskytla chyba,
   * tak táto hodnota je true.
   */
  public hasLoadingFailed = false;


  /**
   * Zoznam všetkych odberných miest.
   */
  public pudos: BehaviorSubject<Model.Pudo[]> = new BehaviorSubject([]);


  /**
   * Filtrovaný zoznam odberných miest.
   */
  public filteredPudos: BehaviorSubject<Model.Pudo[]> = new BehaviorSubject([]);


  public services: Model.PudoService[] = null;


  public selectedPudo: PudoViewModel = null;


  public countries: Shared.Country[] = [];


  private loadPudoServicesCountriesAndPudos = () => {
    this.isBusy = true;
    // Získame si všetky aktívne odberné miesta a tiež možné služby
    // na odberných miestach.
    forkJoin([
      this._pickupPointsService.getActivePickupPoints(),
      this._pickupPointsService.getAllPickupPointServices(),
      this._skdataConfigService.getCountries()
    ]).subscribe(result => {
      var pudos = result[0] as Model.Pudo[];
      var services = result[1] as Model.PudoService[];
      var countries = result[2] as Shared.Country[];

      this.countries = countries;

      this.services = services;

      pudos = _.sortBy(pudos, ["name"]);

      this.pudos.next(pudos);

      this.isBusy = false;
    }, ex => {
      this.loggingService.logErrorData(ex, "Error loading pickup points");

      this.hasLoadingFailed = true;
      this.isBusy = false;
    });
  }


  private setChangeListeners = () => {
    this.filterText.valueChanges.pipe(delay(400)).subscribe(
      value => {
        var text = value as string;

        this.filterTextValue.next(text);
      });

      combineLatest([
        this.filterTextValue,
        this.pudos
      ]).subscribe(result => {
        var filterText = result[0];
        var pudos = result[1];

        if (typeof filterText == "undefined" || filterText == null) {
          filterText = "";
        }

        filterText = filterText.toLowerCase();

        if (filterText.length === 0) {
          // Filter je prázdny, vrátime všetky odberné miesta.
          this.filteredPudos.next(pudos);
        } else {
          // Máme nejaký text ako filter, filtrujeme zatiaľ cez meno.
          var filteredPudos = _.filter(pudos, p =>
            (typeof p.name != "undefined" && p.name != null && p.name.toLowerCase().indexOf(filterText) > -1) ||
            (typeof p.name2 != "undefined" && p.name2 != null && p.name2.toLowerCase().indexOf(filterText) > -1)
          );

          this.filteredPudos.next(filteredPudos);
        }
      });
  }


  private buildOpeningHoursForWeek = (pudoViewModel: PudoViewModel, openingHours: Model.OpeningHours[]) => {
    pudoViewModel.openingHours = [];

    // 0 je nedeľa a 6 je sobota.
    for (var i = 0; i < 7; i++) {
      var weekdayOpeningHours = _.filter(openingHours, (o: Model.OpeningHours) => o.dayId === i);

      if (weekdayOpeningHours.length === 0) {
        pudoViewModel.openingHours = null;
      } else if (weekdayOpeningHours.length === 1) {
        pudoViewModel.openingHours[i] = weekdayOpeningHours[0];
      } else if (weekdayOpeningHours.length === 2) {
        weekdayOpeningHours = _.sortBy(weekdayOpeningHours, ["openingTime"]);

        // Máme buť 2 intervaly, ktoré dokopy tvoria jeden súvislý,
        // alebo sú to 2 intervaly, ktoré majú medzi sebou medzeru - prestávku.

        if (weekdayOpeningHours[0].closingTime === weekdayOpeningHours[1].openingTime) {
          pudoViewModel.openingHours[i] = {
            id: 0,
            openingTime: weekdayOpeningHours[0].openingTime,
            closingTime: weekdayOpeningHours[1].closingTime,
            dayId: i,
            createdByUser: "",
            creationDateTimeUtc: null,
            startDowntimeDateTime: null,
            endDowntimeDateTime: null,
            isNew: true,
            isPersistent: false,
            modificationDateTimeUtc: null,
            modifiedByUser: "",
            pudoId: pudoViewModel.id
          }
        } else {
          // Je tam prestávka.
          pudoViewModel.openingHours[i] = {
            id: 0,
            openingTime: weekdayOpeningHours[0].openingTime,
            closingTime: weekdayOpeningHours[1].closingTime,
            dayId: i,
            createdByUser: "",
            creationDateTimeUtc: null,
            startDowntimeDateTime: weekdayOpeningHours[0].closingTime,
            endDowntimeDateTime: weekdayOpeningHours[1].openingTime,
            isNew: true,
            isPersistent: false,
            modificationDateTimeUtc: null,
            modifiedByUser: "",
            pudoId: pudoViewModel.id
          }
        }
      }

      // Z nejakého dôvodu sú aj záznamy, že hodina otvorenia sa rovná hodina zatvorenia, toto zrušíme.
      if (
        pudoViewModel.openingHours[i] &&
        pudoViewModel.openingHours[i].openingTime.getHours() === pudoViewModel.openingHours[i].closingTime.getHours() &&
        pudoViewModel.openingHours[i].openingTime.getMinutes() === pudoViewModel.openingHours[i].closingTime.getMinutes()) {
        pudoViewModel.openingHours[i] = null;
      }

      if (
        pudoViewModel.openingHours[i] &&
        pudoViewModel.openingHours[i].startDowntimeDateTime && pudoViewModel.openingHours[i].endDowntimeDateTime &&
        pudoViewModel.openingHours[i].startDowntimeDateTime.getHours() === pudoViewModel.openingHours[i].endDowntimeDateTime.getHours() &&
        pudoViewModel.openingHours[i].startDowntimeDateTime.getMinutes() === pudoViewModel.openingHours[i].endDowntimeDateTime.getMinutes()) {
        pudoViewModel.openingHours[i].startDowntimeDateTime = null;
        pudoViewModel.openingHours[i].endDowntimeDateTime = null;
      }
    }
  }


  public select = (pudo: Model.Pudo) => {
    // Musíme načítať dodatočné informácie k odbernému miestu.
    // Tieto informácie ale môžeme už mať, takže najprv overíme existenicu
    // atribútov, ktoré tieto informácie môžu obsahovať.
    var pudoViewModel = pudo as PudoViewModel;

    if (typeof pudoViewModel.services == "undefined") {
      // Nemáme inicializovaný atribút services, to znamená, že sme ešte
      // nenačítavali dodatočné údaje, spravíme to teda.

      pudoViewModel.isLoadingAdditionalData = true;
      forkJoin([
        this._pickupPointsService.getPickupPointServices(pudo.id),
        this._pickupPointsService.getPickupPointOpeningHours(pudo.id),
        this._pickupPointsService.getPickupPointAddress(pudo.addressId)
      ]).subscribe(
        (result: [Model.PudoServiceInPudo[], Model.OpeningHours[], Model.Address]) => {
          var servicesAtPudo = result[0];
          var openingHours = result[1];
          var address = result[2];

          this.buildOpeningHoursForWeek(pudoViewModel, openingHours);
          pudoViewModel.address = address;

          var country = _.find(this.countries, (c: Shared.Country) => c.code === address.countryCode);
          if (country != null) {
            // Duck-typing :P
            (address as any).countryName = country.name;
          }

          pudoViewModel.services = [];

          servicesAtPudo.forEach(sap => {
            var service = _.find(this.services,
              (s: Model.PudoService) => {
                return s.id === sap.pudoServiceId;
              });

            // Našli sme referencovanú službu, pridáme ju medzi služby na odbernom mieste.
            if (service != null) {
              pudoViewModel.services.push(service);
            }
          });

          pudoViewModel.isLoadingAdditionalData = false;
        },
        ex => {
          this.loggingService.logErrorData(ex, "Error loading additional data for PUDO with ID=" + pudoViewModel.id.toString());

          pudoViewModel.isLoadingAdditionalData = false;
        });
    }

    this.selectedPudo = pudoViewModel;
  }


  public handleKeyPress = (event: KeyboardEvent, pudo: Model.Pudo) => {
    if (!event.altKey && !event.ctrlKey && event.keyCode === 13) {
      this.select(pudo);
    }
  }


  public ngOnInit() {
    this.setChangeListeners();

    this.loadPudoServicesCountriesAndPudos();
  }


  private showNotImplementedAlert = () => {
    this._modal.alert().body(this.localizationService.getLocalizedString("not_implemented_yet")).open();
  }


  public createNewShipmentForPudo = () => {
    this.showNotImplementedAlert();
  }


  public import = () => {
    this.showNotImplementedAlert();
  }


  public export = () => {
    this.showNotImplementedAlert();
  }
}


export interface PudoViewModel extends Model.Pudo {
  services: Model.PudoService[];
  openingHours: Model.OpeningHours[];

  address: Model.Address;

  isLoadingAdditionalData: boolean;
}