<form #f (submit)="onSubmit()" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category" wizardTag="basicSettings">
    <div class="settings-wrapper">
    <h1>{{ 'settings_basic' | shpLocalize }}</h1>
    <h2>{{ 'settings_basic_default' | shpLocalize }}</h2>
      <div class="ctl" *ngIf="settings.default_product_code.isVisible" dpdFloatingLabel>
        <!-- <label for="general-default-product-code">{{ 'settings_general_default_product_code' | shpLocalize }}</label>
    <select name="generalDefaultProductCode" id="general-default-product-code" [(ngModel)]="settings.default_product_code.value">
      <option [ngValue]="null">-</option>
      <option *ngFor="let product of products" [ngValue]="product.code">{{ product.name }}</option>
    </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="generalDefaultProductCode" id="general-default-product-code" [(ngModel)]="settings.default_product_code.value">
              <option [ngValue]="null">-</option>
              <option *ngFor="let product of products" [ngValue]="product.code">{{ product.name }}</option>
            </select>
            <label for="general-default-product-code">{{ 'settings_general_default_product_code' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="settings.enable_products_and_services_availability_calculation.isVisible">
        <input type="checkbox" name="enableProductsAndServicesAvailabilityCalculation" id="enable-products-and-services-availability-calculation" [(ngModel)]="settings.enable_products_and_services_availability_calculation.value" />
        <label for="enable-products-and-services-availability-calculation" class="w-100">{{ 'enable_products_and_services_availability_calculation' | shpLocalize }}</label>
        <!-- <label for="enable-products-and-services-availability-calculation"></label> -->
      </div>

      <h3>{{ 'settings_basic_default_dimensions' | shpLocalize }}</h3>

      <div class="ctl" *ngIf="settings.all_parcels_in_a_shipment_have_the_same_dimensions.isVisible">
        <input type="checkbox" name="generalParcelsHaveSameDimensions" id="general-parcels-same-dimensions" [(ngModel)]="settings.all_parcels_in_a_shipment_have_the_same_dimensions.value" />
        <label for="general-parcels-same-dimensions" class="w-100">{{ 'settings_general_parcels_same_dimensions' | shpLocalize }}</label>
        <!-- <label for="general-parcels-same-dimensions"></label> -->
      </div>
      <div class="ctl" *ngIf="settings.parcel_default_dimensions_availability.isVisible">
        <!-- <label for="general-default-dimensions-width">{{ 'settings_general_default_dimensions_width' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.parcel_default_dimensions.value.widthInCentimeters" name="generalDefaultDimensionsWidth" [format]="'F0'" id="general-default-dimensions-width" [step]="1" [showSpinner]="true"
                     [min]="0" [max]="1000"></wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.parcel_default_dimensions.value.widthInCentimeters"
                           [min]="0"
                           [max]="999"
                           [step]="1"
                           [showButtons]="true"
                           [maxFractionDigits]="0"
                           name="generalDefaultDimensionsWidth"
                           id="general-default-dimensions-width"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="general-default-dimensions-width">{{ 'settings_general_default_dimensions_width' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.parcel_default_dimensions_availability.isVisible">
        <!-- <label for="general-default-dimensions-height">{{ 'settings_general_default_dimensions_height' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.parcel_default_dimensions.value.heightInCentimeters" name="generalDefaultDimensionsHeight" [format]="'F0'" id="general-default-dimensions-height" [step]="1" [showSpinner]="true"
                 [min]="0" [max]="1000"></wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.parcel_default_dimensions.value.heightInCentimeters"
                           [min]="0"
                           [max]="999"
                           [step]="1"
                           [showButtons]="true"
                           [maxFractionDigits]="0"
                           name="generalDefaultDimensionsHeight"
                           inputId="general-default-dimensions-heigh"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="general-default-dimensions-heigh">{{ 'settings_general_default_dimensions_height' | shpLocalize }}</label>
          </span>
        </div>

      </div>
      <div class="ctl" *ngIf="settings.parcel_default_dimensions_availability.isVisible">
        <!-- <label for="general-default-dimensions-length">{{ 'settings_general_default_dimensions_length' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.parcel_default_dimensions.value.lengthInCentimeters" name="generalDefaultDimensionsLength" [format]="'F0'" id="general-default-dimensions-length" [step]="1" [showSpinner]="true"
                 [min]="0" [max]="1000"></wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.parcel_default_dimensions.value.lengthInCentimeters"
                           [min]="0"
                           [max]="999"
                           [step]="1"
                           [showButtons]="true"
                           [maxFractionDigits]="0"
                           inputId="general-default-dimensions-length"
                           name="generalDefaultDimensionsLength"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="general-default-dimensions-length">{{ 'settings_general_default_dimensions_length' | shpLocalize }}</label>
          </span>
        </div>

      </div>
      <div class="ctl" *ngIf="settings.parcel_default_dimensions_availability.isVisible">
        <!-- <label for="general-default-dimensions-weight">{{ 'settings_general_default_dimensions_weight' | shpLocalize }}</label>
    <shp-universal-number-input [(ngModel)]="settings.parcel_default_dimensions.value.weightInKilograms" name="generalDefaultDimensionsWeight" [isRequired]="false" [min]="0" [max]="999"></shp-universal-number-input> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.parcel_default_dimensions.value.weightInKilograms"
                           [min]="0"
                           [max]="999"
                           [step]="1"
                           [showButtons]="true"
                           [maxFractionDigits]="2"
                           [minFractionDigits]="2"
                           name="generalDefaultDimensionsWeight"
                           id="general-default-dimensions-weight"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="general-default-dimensions-weight">{{ 'settings_general_default_dimensions_weight' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.shipment_default_note.isVisible">
        <!-- <label for="general-default-note">{{ 'settings_general_default_note' | shpLocalize }}</label>
    <input type="text"
           name="generalDefaultNote" id="general-default-note" [(ngModel)]="settings.shipment_default_note.value" /> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="general-default-note" [(ngModel)]="settings.shipment_default_note.value" type="text" name="generalDefaultNote" pInputText autocomplete="off">
            <label for="general-default-note">{{ 'settings_general_default_note' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="isServiceWithSmsCodeAvailable && settings.sms_notification_for_each_shipment.isVisible">
        <input type="checkbox" name="generalSmsForEachShipment" id="general-sms-for-each-shipment" [(ngModel)]="settings.sms_notification_for_each_shipment.value" />
        <label for="general-sms-for-each-shipment" class="w-100">{{ 'settings_general_sms_for_each_shipment' | shpLocalize }}</label>
        <!-- <label for="general-sms-for-each-shipment"></label> -->
      </div>
      <div class="ctl" *ngIf="settings.e_mail_notification_for_each_shipment.isVisible">
        <input type="checkbox" name="generalemailForEachShipment" id="general-email-for-each-shipment" [(ngModel)]="settings.e_mail_notification_for_each_shipment.value" />
        <label for="general-email-for-each-shipment" class="w-100">{{ 'settings_general_email_for_each_shipment' | shpLocalize }}</label>
        <!-- <label for="general-email-for-each-shipment"></label> -->
      </div>

      <h3>{{ 'settings_basic_default_references' | shpLocalize }}</h3>

      <div class="ctl" *ngIf="settings.all_parcels_in_a_shipment_have_the_same_references.isVisible">
        <input type="checkbox" name="generalParcelsHaveSamereferences" id="general-parcels-same-references" [(ngModel)]="settings.all_parcels_in_a_shipment_have_the_same_references.value" />
        <label for="general-parcels-same-references" class="w-100">{{ 'settings_general_parcels_same_references' | shpLocalize }}</label>
        <!-- <label for="general-parcels-same-references"></label> -->
      </div>
      <div class="ctl" *ngIf="settings.number_of_shipment_references.isVisible">
        <!-- <label for="general-number-of-shipment-references">{{ 'settings_general_number_of_shipment_references' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.number_of_shipment_references.value" name="generalNumberOfShipmentReferences" id="general-number-of-shipment-references" [step]="1" [showSpinner]="true" [min]="1" [max]="4"></wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.number_of_shipment_references.value"
                           [min]="1"
                           [max]="4"
                           [step]="1"
                           [showButtons]="true"
                           name="generalNumberOfShipmentReferences"
                           id="general-number-of-shipment-references"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="general-number-of-shipment-references">{{ 'settings_general_number_of_shipment_references' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.number_of_parcel_references.isVisible">
        <!-- <label for="general-number-of-parcel-references">{{ 'settings_general_number_of_parcel_references' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.number_of_parcel_references.value" name="generalNumberOfParcelReferences" id="general-number-of-parcel-references" [step]="1" [showSpinner]="true" [min]="1" [max]="4"></wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.number_of_parcel_references.value"
                           [min]="1"
                           [max]="4"
                           [step]="1"
                           [showButtons]="true"
                           name="generalNumberOfParcelReferences"
                           id="general-number-of-parcel-references"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="general-number-of-parcel-references">{{ 'settings_general_number_of_parcel_references' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <!-- Default parcel reference 1 -->
      <div class="ctl" *ngIf="settings.default_parcel_reference_1.isVisible">
        <!-- <label for="general-default-parcel-reference-1">{{ 'settings_general_default_parcel_reference_1' | shpLocalize }}</label>
    <input type="text"
           name="generalDefaultParcelReference1" id="general-default-parcel-reference-1" [(ngModel)]="settings.default_parcel_reference_1.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="general-default-parcel-reference-1" [(ngModel)]="settings.default_parcel_reference_1.value" type="text" name="generalDefaultParcelReference1" pInputText autocomplete="off">
            <label for="general-default-parcel-reference-1">{{ 'settings_general_default_parcel_reference_1' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <!-- Default parcel reference 2 -->
      <div class="ctl" *ngIf="settings.default_parcel_reference_2.isVisible">
        <!-- <label for="general-default-parcel-reference-2">{{ 'settings_general_default_parcel_reference_2' | shpLocalize }}</label>
    <input type="text"
           name="generalDefaultParcelReference2" id="general-default-parcel-reference-2" [(ngModel)]="settings.default_parcel_reference_2.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="general-default-parcel-reference-2" [(ngModel)]="settings.default_parcel_reference_2.value" type="text" name="generalDefaultParcelReference2" pInputText autocomplete="off">
            <label for="general-default-parcel-reference-2">{{ 'settings_general_default_parcel_reference_2' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.default_cod_payment_type_id.isVisible">
        <!-- <label for="general-default-cod-payment-type">{{ 'settings_general_default_cod_payment_type' | shpLocalize }}</label>
    <select name="generalDefaultCodPaymentType" id="general-default-cod-payment-type" [(ngModel)]="settings.default_cod_payment_type_id.value">
      <option value="0">{{ 'cod_payment_type_cash' | shpLocalize }}</option>
      <option value="2">{{ 'cod_payment_type_card' | shpLocalize }}</option>
    </select> -->

        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="generalDefaultCodPaymentType" id="general-default-cod-payment-type" [(ngModel)]="settings.default_cod_payment_type_id.value">
              <option value="0">{{ 'cod_payment_type_cash' | shpLocalize }}</option>
              <option value="2">{{ 'cod_payment_type_card' | shpLocalize }}</option>
            </select>
            <label for="general-default-cod-payment-type">{{ 'settings_general_default_cod_payment_type' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <h3>{{ 'settings_basic_default_sender_customs_data' | shpLocalize }}</h3>

      <!-- default sender eori -->
      <div class="ctl" *ngIf="settings.default_sender_eori_number.isVisible">
        <!-- <label for="general-default-sender-eori-number">{{ 'settings_general_default_sender_eori_number' | shpLocalize }}</label>
    <select id="general-default-sender-eori-number-country-code"  name="defaultSenderEoriNumberCountryCode" [(ngModel)]="eoriCountryCode">
      <option value=""> - </option>
      <option *ngFor="let country of countriesByCode" [ngValue]="country.code">{{ country.code }}</option>
    </select>
    <input id="general-default-sender-eori-number" name="generalDefaultSenderEoriNumber" type="text" [(ngModel)]="eoriNumberWithoutCountryCode" #eoriControl="ngModel" shpValidateAlphanumeric maxlength="35"  /> -->
        <div class="p-field">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="general-default-sender-eori-number-country-code" name="defaultSenderEoriNumberCountryCode" [(ngModel)]="eoriCountryCode">
              <option value=""> - </option>
              <option *ngFor="let country of countriesByCode" [ngValue]="country.code">{{ country.code }}</option>
            </select>
          </span>
        </div>
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="general-default-sender-eori-number" name="generalDefaultSenderEoriNumber" type="text" [(ngModel)]="eoriNumberWithoutCountryCode" #eoriControl="ngModel" pInputText shpValidateAlphanumeric maxlength="35">
            <label for="general-default-sender-eori-number">{{ 'settings_general_default_sender_eori_number' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="eoriControl?.errors?.alphaNumeric">
        <label></label>
        <em class="error m-0">{{ 'error_eori_invalid_format' | shpLocalize }}</em>
      </div>

      <!-- default sender vat  -->
      <div class="ctl" *ngIf="settings.default_sender_vat_number.isVisible">
        <!-- <label for="general_default_sender_vat_number">{{ 'settings_general_default_sender_vat_number' | shpLocalize }}</label>
    <input id="general_default_sender_vat_number" name="generalDefaultSenderVatNumber" type="text" [(ngModel)]="settings.default_sender_vat_number.value" #vatControl="ngModel" shpValidateAlphanumeric maxlength="35"/> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="general_default_sender_vat_number" [(ngModel)]="settings.default_sender_vat_number.value" type="text" #vatControl="ngModel" shpValidateAlphanumeric maxlength="35" name="generalDefaultSenderVatNumber" pInputText autocomplete="off">
            <label for="general_default_sender_vat_number">{{ 'settings_general_default_sender_vat_number' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="vatControl?.errors?.alphaNumeric">
        <label></label>
        <em class="error m-0">{{ 'error_vat_invalid_format' | shpLocalize }}</em>
      </div>

      <!-- default sender hmrc -->
      <div class="ctl" *ngIf="settings.default_sender_hmrc_number.isVisible">
        <!-- <label for="general_default_sender_hmrc_number">{{ 'settings_general_default_sender_hmrc_number' | shpLocalize }}</label>
    <input id="general_default_sender_hmrc_number" name="generalDefaultSenderHmrcNumber" type="text" [(ngModel)]="settings.default_sender_hmrc_number.value"/> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="general_default_sender_hmrc_number" [(ngModel)]="settings.default_sender_hmrc_number.value" type="text" name="generalDefaultSenderHmrcNumber" pInputText autocomplete="off">
            <label for="general_default_sender_hmrc_number">{{ 'settings_general_default_sender_hmrc_number' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <h3>{{ 'settings_basic_default_recipient_data' | shpLocalize }}</h3>

      <div class="ctl" *ngIf="settings.default_recipient_language_code.isVisible">
        <!-- <label for="general-default-recipient-language-code">{{ 'default_recipient_language' | shpLocalize }}</label>
    <select name="generalDefaultRecipientLanguageCode" id="general-default-recipient-language-code" [(ngModel)]="settings.default_recipient_language_code.value">
      <option [ngValue]="null">-</option>
      <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
    </select> -->

        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="generalDefaultRecipientLanguageCode" id="general-default-recipient-language-code" [(ngModel)]="settings.default_recipient_language_code.value">
              <option [ngValue]="null">-</option>
              <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
            </select>
            <label for="general-default-recipient-language-code">{{ 'default_recipient_language' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <h3>{{ 'settings_basic_default_adjusted_addresses' | shpLocalize }}</h3>

      <ng-container *ngIf="!isSophia">
        <ng-container *ngFor="let addressType of addressTypes">
          <div *ngIf="settings[addressType.id].isVisible && addressType.enabled" class="address-editor">
            <div class="ctl" *ngIf="!hasAddressTypeValues(addressType.id)">
              <input type="checkbox" id="default-address{{addressType.id}}" (click)="setDefaultAddress(addressType.id)" />
              <label for="default-address{{addressType.id}}" class="w-100">{{ addressType.title | shpLocalize }}</label>
            </div>
            <div class="ctl" *ngIf="hasAddressTypeValues(addressType.id)">
              <input type="checkbox" checked id="default-address-off{{addressType.id}}" (click)="removeDefaultAddress(addressType.id)" />
              <label for="default-address-off{{addressType.id}}" class="w-100">{{ addressType.title | shpLocalize }}</label>
            </div>
            <shp-address-form *ngIf="settings[addressType.id].value" [countries]="countries" [settings]="settings[addressType.id].value" [config]="addressType.config"></shp-address-form>
          </div>
        </ng-container>
      </ng-container>

      <div class="ctl" *ngIf="settings.empty_return_sender.isVisible">
        <label for="empty-return-sender">{{ 'setting_empty_return_sender' | shpLocalize }}</label>
        <input type="checkbox"
               name="emptyReturnSender" id="empty-return-sender" [(ngModel)]="settings.empty_return_sender.value" />
        <label for="empty-return-sender"></label>
      </div>

      <h2>{{ 'settings_basic_tool' | shpLocalize }}</h2>

      <div class="ctl" *ngIf="!isSophia && settings.minimal_recipient_search_char_count.isVisible">
        <!-- <label for="minimal-recipient-search-char-count">{{ 'minimal_recipient_search_char_count' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.minimal_recipient_search_char_count.value" name="minimalRecipientSearchCharCount" id="minimal-recipient-search-char-count"
                     [step]="1" [showSpinner]="true" [min]="1" [max]="10">
    </wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.minimal_recipient_search_char_count.value"
                           [min]="1"
                           [max]="10"
                           [step]="1"
                           [showButtons]="true"
                           name="minimalRecipientSearchCharCount"
                           id="minimal-recipient-search-char-count"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="minimal-recipient-search-char-count">{{ 'minimal_recipient_search_char_count' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="!isSophia && settings.extended_recipient_search_turned_on.isVisible">
        <input type="checkbox" name="extendedRecipientSearchTurnedOn" id="extended-recipient-search-turned-on" [(ngModel)]="settings.extended_recipient_search_turned_on.value" />
        <label for="extended-recipient-search-turned-on" class="w-100">{{ 'extended_recipient_search_turned_on' | shpLocalize }}</label>
        <!-- <label for="extended-recipient-search-turned-on"></label> -->
      </div>

      <div class="ctl" *ngIf="!isSophia && settings.return_to_shipment_list_after_saving.isVisible">
        <input type="checkbox" name="returnToShipmentListAfterSaving" id="return-to-shipment-list-after-saving" [(ngModel)]="settings.return_to_shipment_list_after_saving.value" />
        <label for="return-to-shipment-list-after-saving" class="w-100">{{ 'settings_return_to_shipment_list_after_saving' | shpLocalize }}</label>
        <!-- <label for="return-to-shipment-list-after-saving"></label> -->
      </div>

      <div class="ctl" *ngIf="!isSophia && settings.return_to_recipient_list_after_saving.isVisible">
        <input type="checkbox" name="returnToRecipientListAfterSaving" id="return-to-recipient-list-after-saving" [(ngModel)]="settings.return_to_recipient_list_after_saving.value" />
        <label for="return-to-recipient-list-after-saving" class="w-100">{{ 'settings_return_to_recipient_list_after_saving' | shpLocalize }}</label>
        <!-- <label for="return-to-recipient-list-after-saving"></label> -->
      </div>

      <div class="ctl" *ngIf="settings.add_or_update_recipient_after_order_save.isVisible">
        <input type="checkbox" name="addOrUpdateRecipientAfterOrderSave" id="add-or-update-recipient-after-order-save" [(ngModel)]="settings.add_or_update_recipient_after_order_save.value" />
        <label for="add-or-update-recipient-after-order-save" class="w-100">{{ 'settings_add_or_update_recipient_after_order_save' | shpLocalize }}</label>
        <!-- <label for="add-or-update-recipient-after-order-save"></label> -->
      </div>

    <div class="ctl" *ngIf="!isSophia && settings.parcel_number_ranges_warning_threshold.isVisible">
      <!-- <label for="general-parcel-ranges-warning-threshold">{{ 'settings_general_parcel_number_ranges_warning_threshold' | shpLocalize }}</label> -->
      <!-- <wj-input-number [(value)]="settings.parcel_number_ranges_warning_threshold.value" name="generalParcelNumberRangesWarningThreshold" id="general-parcel-ranges-warning-threshold"
                       [step]="1" [showSpinner]="true" [min]="0" [max]="10000">
      </wj-input-number> -->

      <div class="p-field p-field--full">
        <span class="p-float-label">
          <p-inputNumber
            [(ngModel)]="settings.parcel_number_ranges_warning_threshold.value"
            [min]="0"
            [max]="1000"
            [step]="1"
            [showButtons]="true"
            name="generalParcelNumberRangesWarningThreshold"
            id="general-parcel-ranges-warning-threshold"
            mode="decimal"
            decrementButtonClass="p-button-secondary"
            incrementButtonClass="p-button-secondary"
            incrementButtonIcon="ic-add-2"
            decrementButtonIcon="ic-subtract-2"
          ></p-inputNumber>
          <label for="general-parcel-ranges-warning-threshold">{{ 'settings_general_parcel_number_ranges_warning_threshold' | shpLocalize }}</label>
        </span>
      </div>
    </div>


      <div class="ctl" *ngIf="settings.automated_shipments_deletion_after_x_days.isVisible">
        <!-- <label for="automated-shipments-deletion-after-x-days">{{ 'settings_automated_shipments_deletion_after_x_days' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.automated_shipments_deletion_after_x_days.value" name="automatedShipmentsDeletionAfterXDays" id="automated-shipments-deletion-after-x-days"
                     [step]="1" [showSpinner]="true" [min]="0" [max]="999">
    </wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="settings.automated_shipments_deletion_after_x_days.value"
                           [min]="0"
                           [max]="999"
                           [step]="1"
                           [showButtons]="true"
                           name="automatedShipmentsDeletionAfterXDays"
                           id="automated-shipments-deletion-after-x-day"
                           mode="decimal"
                           decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary"
                           incrementButtonIcon="ic-add-2"
                           decrementButtonIcon="ic-subtract-2"></p-inputNumber>
            <label for="automated-shipments-deletion-after-x-day">{{ 'settings_automated_shipments_deletion_after_x_days' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="settings.can_only_admin_update_shipper.isVisible">
        <label for="can-only-admin-update-shipper">{{ 'setting_can_only_admin_update_shipper' | shpLocalize }}</label>
        <input type="checkbox"
               name="canOnlyAdminUpdateShipper" id="can-only-admin-update-shipper" [(ngModel)]="settings.can_only_admin_update_shipper.value" />
        <label for="can-only-admin-update-shipper"></label>
      </div>
    </div><!-- .settings-wrapper -->
    <div class="commands-toolbar">
      <button id="button-save" [disabled]="" wizardTag="saveBasicSettings" type="submit"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
    </div>

  </div>

</form>
