import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";

import * as Shared from "../../shared/index"
import { Template, TemplatePreviewSettings } from "../../shared/models/notification-template.model";
import { ToastrService } from "ngx-toastr";
import { LocalizationService } from "../../shared/index";
import { HttpClient } from "@angular/common/http"

const customerPersonalizedNotificationTemplateDefault = {
  contentTypeCode: "CustomerShipmentAnnouncement",
  deliveryTypeCode: "Email",
  moduleId: 7,
  name: "customerPersonalizedNotificationTemplate",
  priority: 0,
  version: "1"
};


@Injectable()
export class NotificationsTemplateService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient,
    private _contextService: Shared.ContextService,
    private _toastr: ToastrService,
    private _localitionService: LocalizationService
  ) {
    super(loggingService);
  }


  createTemplate(template: Template): Observable<Template> {
    return this.processRequest<Template>(this._http.post(`${Shared.API_URL}/notification-templates`, template)).pipe(
      tap(() => {
        this._toastr.success(this._localitionService.getLocalizedString("email_template_created"));
      }),
      catchError(err => {
        this._toastr.error(this._localitionService.getLocalizedString("email_template_create_error"));

        return throwError(err);
      })
    )
  }

  updateTemplate(template: Template, data: any): Observable<any> {
    data = {
      ...template,
      ...data
    };

    return this.processRequest<any>(this._http.put(`${Shared.API_URL}/notification-templates/${template.id}`, data)).pipe(
      tap(() => {
        this._toastr.success(this._localitionService.getLocalizedString("email_template_saved"));
      }),
      catchError(err => {
        this._toastr.success(this._localitionService.getLocalizedString("email_template_save_error"));

        return throwError(err);
      })
    )
  }


  getTemplatePreview(templateContent: string): Observable<any> {
    const previewParams = {
      ...this.getDefaultTemplatePreviewSettings(),
      ModuleKey: "Shipper",
      ContentTypeCode: "CustomerShipmentAnnouncement",
      Template: {
        ...customerPersonalizedNotificationTemplateDefault,
        content: templateContent
      }
    };

    return this.processRequest<any>(this._http.post(`${Shared.API_URL}/notification-templates/preview`, previewParams));
  }


  createCustomerPersonalizedNotificationTemplate(templateContent: string, title: string, languageCodes: string): Observable<any> {
    const template = {
      content: templateContent,
      content2: title,
      contentTypeCode: "CustomerShipmentAnnouncement",
      deliveryTypeCode: "Email",
      moduleId: 7,
      name: "customerPersonalizedNotificationTemplate",
      priority: 0,
      version: "1",
      languageCodes
    } as Template;

    return this.createTemplate(template);
  }
  

  getCustomerPersonalizedNotificationTemplate(language: string): Observable<any> {
    const result = this._contextService.currentCustomerDetail.pipe(
      filter(cd => cd != null),
      take(1),
      map(cd => cd.id),
      switchMap(customerDetailId => {
        return this.processRequest<any>(this._http.get(`${Shared.API_URL}/notification-templates/customer-personalized-notification/${customerDetailId}/${language}`));
      })
    )

  return result;
}


getIsCustomerPersonalizedNotificationTemplateSet(): Observable<any> {
  const result = this._contextService.currentCustomerDetail.pipe(
    filter(cd => cd != null),
    take(1),
    map(cd => cd.id),
    switchMap(customerDetailId => {
      return this.processRequest<any>(this._http.get(`${Shared.API_URL}/notification-templates/customer-personalized-notification/${customerDetailId}/is-set`));
    })
  )

return result;
}


  private getDefaultTemplatePreviewSettings(): TemplatePreviewSettings {
    return {
      codAmount: null,
      deliveryDate: new Date(2020, 11, 24),
      isCreditCardPaymentAllowed: null,
      parcelsCount: 5,
      userName: "Test",
      login: "DPD@TEST",
      password: "test",
      delisId: "TST065",
      parcelNumberRangeSize: 1000,
      userIdentification: "TEST@DPD",
      newOrdersCount: 10,
      claimNumber: "12345",
      template: null,
      moduleKey: null,
      contentTypeCode: null,
      customerName: "Orange",
      claimDetailsUrl: "https://tools.dpd.sk/claims"
    };
  }

}


