<div class="dialog shipments-import-path-editor-dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>

  <h1 [innerHtml]="'shipments_import_path' | shpLocalize"></h1>
  <form novalidate [formGroup]="form">
    <div class="ctl">
      <label for="shipments-import-path-path">{{ 'path' | shpLocalize }}</label>
      <input type="text" formControlName="path" id="shipments-import-path-path" />
    </div>

    <div class="ctl">
      <label for="shipments-import-path-import-profile">{{ 'settings_automated_shipments_import_profile' | shpLocalize }}</label>
      <select id="shipments-import-path-import-profile" formControlName="importProfileId">
        <option [ngValue]="null">--</option>
        <option *ngFor="let imp of importProfiles" [ngValue]="imp.id">{{imp.name}}</option>
      </select>
    </div>

    <div class="ctl">
      <label for="shipments-import-path-use-semaphore-files">{{ 'settings_automated_shipments_import_use_semaphore_files' | shpLocalize }}</label>
      <input type="checkbox" formControlName="useSemaphoreFiles" id="shipments-import-path-use-semaphore-files" />
      <label for="shipments-import-path-use-semaphore-files"></label>
    </div>
  </form>

  <div class="buttons">
    <button id="button-save" type="button" [disabled]="!form || !form.valid" (click)="save()"><span class="ic-floppydisk"></span> {{ 'save' | shpLocalize }}</button>
    <button id="button-cancel" type="button" [disabled]="isBusy" (click)="cancel()"><span class="ic-close"></span> {{ 'cancel' | shpLocalize }}</button>
  </div>
</div>
