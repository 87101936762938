import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { forkJoin, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

import * as SettingsModel from "../../settings/models/settings.model";
import { SettingsService } from "../../settings/services/settings.service";
import * as Shared from "../../shared/index";
import { PasswordService } from "../../shared/index";
import { Link } from "../../shared/models/link.model";
import { LoginData } from "../models/login-data.model";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-login-page",
  templateUrl: "./login-page.component.html"
})
export class LoginPageComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  public exception: Shared.ExceptionInfo = null;
  public isRequestActive = false;
  public isResolvingLoginEvents = false;
  public languageCode = Shared.Localization[this.localizationService.currentLocalization];
  public links: Link[] = null;
  public login = "";
  public showLoginForm = true;

  /** Názov aktuálne vybraného súboru z disku. */
  public selectedFileName = "";

  /** Heslo ku konfiguračnému súboru. */
  public filePassword = "";

  /** JS objekt reprezentujúci údaje o vybranom súbore. */
  private _selectedFile: File = null;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _route: ActivatedRoute,
    private _settingsService: SettingsService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _toastr: ToastrService,
    private _linkService: Shared.LinkService,
    private _passwordService: PasswordService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }

  get isBusy(): boolean {
    return this.isRequestActive || this.isResolvingLoginEvents;
  }

  private _destroy$: Subject<void> = new Subject();

  /**
   * Táto sekcia nevyžaduje autentifikovaného používateľa (default je totiž true).
   */
  public requiresAuthenticatedUser() { return false; }


  public processLogin(loginData: LoginData) {
    this.logInImpl(loginData.login, loginData.password);
  }


  /**
   * Handler, ktorý sa má bindovať na event zmeny vybraného súboru cez input type=file.
   */
  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }


  private setSelectedFile = (inputValue: any) => {
    if (inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this.selectedFileName = this._selectedFile.name;
    } else {
      this.selectedFileName = "";
      this._selectedFile = null;
    }
  }


  public uploadConfigurationFile = () => {
    if (this._selectedFile == null) {
      return;
    }

    this.isRequestActive = true;
    this.exception = null;

    // Cez FileReader (IE 10+, ostatné prehliadače OK) si načítame súbor z disku.
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      this.uploadConfiguration(fileReader.result as string, this.filePassword);
    };

    fileReader.onerror = () => {
      this.isRequestActive = false;
    };

    fileReader.onabort = () => {
      this.isRequestActive = false;
    };

    // Zavoláme získanie textového obsahu súboru, lebo obsahuje JSON.
    fileReader.readAsText(this._selectedFile);
  }


  /**
   * Samotné nahranie načítaného konfiguračného súboru a hesla na server.
   */
  private uploadConfiguration = (configurationJson: string, password: string) => {
    const model: SettingsModel.ConfigurationModel = {
      password,
      configurationJson
    };

    this._settingsService.uploadConfiguration(model)
      .subscribe(() => {
        this.isRequestActive = false;

        this._toastr.success(this.localizationService.getLocalizedString("message_configuration_uploaded_successfully"));

        // Už sa používateľ môže prihlásiť.
        this.showLoginForm = true;
      }, (ex: any) => {
        // Niečo zlé sa stalo, zalogujeme a informujeme o tom.
        this.loggingService.logErrorData(ex, "Error uploading configuration.");

        this.exception = {
          httpStatusCode: 500,
          key: "error_uploading_configuration",
          message: "",
          stackTrace: ""
        };

        // Ak máme atribút key, predpokladáme, že je to nám známy typ chyby.
        if (ex.key) {
          this.exception.key = ex.key;
        }

        this.isRequestActive = false;
      });
  }


  public runDemoMode = () => {

  }


  private logInImpl = (login: string, password: string) => {
    this.exception = null;
    this.isRequestActive = true;

    this.authenticationService.logInUser(login, password).pipe(
      finalize(() => this.isRequestActive = false)
    ).subscribe(() => {
      // Navigate demo user to the configurations management.
      if (login === Shared.DEMO_USER_LOGIN) {
        this.router.navigate(["/settings/configurations"]);
        return;
      }

      this.router.navigate(["/"]);
    },
    (ex: Shared.ExceptionInfo) => {
      this.exception = ex;
      this.loggingService.logErrorData(ex);
    });
  }


  public ngOnInit(): void {
    this.isRequestActive = true;

    if (this._shipperSettingsService.isSophia) {
      this._linkService.getLinks("016", this.languageCode).subscribe(result => {
        this.links = result;
      }, (ex: any) => {
        this.loggingService.logErrorData(ex, "Error loading links.");
      });
    }

    this._passwordService.state$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(s => this.isResolvingLoginEvents = s.isResolvePasswordChangeUserLoginEventPending);

    // Musíme si zistiť, či:
    // - Môžeme nahrávať konfigurácie a používať demo mód.
    // - Či máme iba demo tenant k dispozícii.
    // - Či máme aj nejaký ne-demo tenant.
    // Tieto veci by sme sa mali pýtať postupne, ale to sa nám nechce, dáme to všetko naraz.
    forkJoin([
      this._settingsService.getCanUseDemoModeAndUploadConfigurations(),
      this._settingsService.getIsOnlyDemoTenantAvailable(),
      this._settingsService.getHasAnyActiveNonDemoTenant()
    ]).subscribe((result: [boolean, boolean, boolean]) => {
        const isOnlyDemoTenantAvailable = result[1];
        const hasAnyActiveNonDemoTenant = result[2];

        // Ak máme iba demo tenant, tak nič neriešime, používateľa rovno prihlásime.
        if (isOnlyDemoTenantAvailable) {
          this.logInImpl(Shared.DEMO_USER_LOGIN, "");
        } else if (hasAnyActiveNonDemoTenant) {
          // Máme nejaký nedemo tenant, zobrazíme prihlasovací formulár.
          this.showLoginForm = true;

          // Cez queury string sme mohli dostať snahu o impersonovanie cez Shipper Admin používateľa,
          // skúsime si to teda skontrolovať, či nám niečo neprišlo.
          this._route.queryParams.subscribe(p => {
            if (p) {
              this.login = p.l;
            }
          });
        } else {
          // Nemáme žiadne konfigurácie, nezobrazíme prihlasovací formulár,
          // ale formulár na výber, či chce používateľ spustiť demo mód alebo
          // nahrať konfiguračný súbor.
          this.showLoginForm = false;
        }

        this.isRequestActive = false;
      }, (ex: any) => {
        // Niečo zlé sa stalo, zalogujeme a informujeme o tom.
        this.loggingService.logErrorData(ex, "Error reading configuration.");

        this.exception = {
          httpStatusCode: 500,
          key: "error_reading_configuration",
          message: "",
          stackTrace: ""
        };

        this.isRequestActive = false;
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
