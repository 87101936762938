<shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
<div class="settings-category">
  <h1>{{ 'email_server_configuration' | shpLocalize }}</h1>

  <div>
    <form [formGroup]="f" autocomplete="off" novalidate>
      <div class="ctl required">
        <!-- <label for="protocol">{{ 'protocol' | shpLocalize }}</label>
        <input value="SMTP" disabled type="text" id="protocol" name="protocol" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input value="SMTP" disabled type="text" id="protocol" pInputText name="protocol" />
            <label for="protocol">{{ 'protocol' | shpLocalize }}</label>
          </span>
        </div>
        <!-- <select formControlName="protocol" id="protocol" name="protocol">
          <option value="SMTP">SMTP</option>
        </select> -->
      </div>

      <div class="ctl">
        <!-- <label for="host">{{ 'host' | shpLocalize }}</label>
        <input formControlName="host" type="text" id="host" name="host" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input formControlName="host" type="text" id="host" pInputText name="host">
            <label for="host">{{ 'host' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl">
        <!-- <label for="port">{{ 'port' | shpLocalize }}</label>
        <input formControlName="port" type="text" id="port" name="port" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input formControlName="port" type="text" id="port" pInputText name="port">
            <label for="port">{{ 'port' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      
      <div class="ctl">
        <!-- <label for="sender_email">{{ 'sender_email' | shpLocalize }}</label>
        <input formControlName="senderEmail" type="text" id="sender_email" name="senderEmail" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input formControlName="senderEmail" type="text" id="sender_email" pInputText name="senderEmail" />
            <label for="sender_email">{{ 'sender_email' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl">
        <input formControlName="isAuthentificationRequired" type="checkbox" id="is-authentification-required" name="isAuthentificationRequired" />
        <label for="is-authentification-required" class="w-100">{{ 'is_authentification_required' | shpLocalize }}</label>
        <!-- <label for="is-authentification-required"></label> -->
      </div>

      <div class="ctl" [class.required]="isAuthRequired">
        <!-- <label for="user-name">{{ 'user' | shpLocalize }}</label>
        <input formControlName="user" type="text" id="user-name" name="userName" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input formControlName="user" type="text" id="user-name" pInputText name="userName" />
            <label for="user-name">{{ 'user' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      
      <div class="ctl" [class.required]="isAuthRequired">
        <!-- <label for="user-name">{{ 'password' | shpLocalize }}</label>
        <input formControlName="password" type="text" id="user-name" name="userName" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input formControlName="password" type="text" id="user-name" pInputText name="userName" />
            <label for="user-name">{{ 'password' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      
      <div class="ctl">
        <input formControlName="tlsEncryption" type="checkbox" id="tls-encryption" name="tlsEncryption" />
        <label for="tls-encryption">{{ 'tls_encryption' | shpLocalize }}</label>
        <!-- <label for="tls-encryption"></label> -->
      </div>
    </form>
  </div>
  <div class="commands-toolbar mt-3">
    <button (click)="save()" type="button" class="mr-2" [disabled]="f.invalid"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
    <button (click)="openTestEmailDialog()" type="button" [disabled]="f.invalid">{{ 'send_test_email' | shpLocalize }}</button>
  </div>
</div>