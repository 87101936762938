<div class="top">
  <h1 *ngIf="recipient?.isNew">{{ 'new_recipient' | shpLocalize }}</h1>
  <h1 *ngIf="!recipient?.isNew">{{ 'recipient' | shpLocalize }}</h1>
</div>
<form #f="ngForm" (submit)="save()" *ngIf="recipient" class="content-panel recipients" novalidate wizardTag="recipientEditor">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="recipient-details">
    <div class=" mdl-grid">
      <div class="ctl mdl-cell mdl-cell--12-col">
        <label>{{ 'recipient_type' | shpLocalize }}</label>
        <div class="radio-group">
          <input type="radio" id="recipient-customer-type-b2c" value="{{ b2c }}" name="recipient_type" [(ngModel)]="recipient.customerRecipientTypeCode" /><label class="radio-label" for="recipient-customer-type-b2c">{{ 'recipient_type_b2c' | shpLocalize }}</label>
          <input type="radio" id="recipient-customer-type-b2b" value="{{ b2b }}" name="recipient_type" [(ngModel)]="recipient.customerRecipientTypeCode" /><label class="radio-label" for="recipient-customer-type-b2b">{{ 'recipient_type_b2b' | shpLocalize }}</label>
        </div>
      </div>
      <div class="ctl required mdl-cell mdl-cell--12-col">
        <!-- <label for="recipient-name" *ngIf="recipient.customerRecipientTypeCode == b2c">{{ 'recipient_person_name' | shpLocalize }}</label>
        <label for="recipient-name" *ngIf="recipient.customerRecipientTypeCode == b2b">{{ 'recipient_organization_name' | shpLocalize }}</label>
        <input type="text" id="recipient-name" [(ngModel)]="recipient.name" maxlength="35" name="recipient_name" required /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-name" [(ngModel)]="recipient.name" type="text" name="recipient_name" maxlength="35" pInputText required>
            <label for="recipient-name" *ngIf="recipient.customerRecipientTypeCode == b2c">{{ 'recipient_person_name' | shpLocalize }}</label>
            <label for="recipient-name" *ngIf="recipient.customerRecipientTypeCode == b2b">{{ 'recipient_organization_name' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col">
        <!-- <label for="recipient-name2" *ngIf="recipient.customerRecipientTypeCode == b2c">{{ 'recipient_person_name' | shpLocalize }} 2</label>
        <label for="recipient-name2" *ngIf="recipient.customerRecipientTypeCode == b2b">{{ 'recipient_organization_name' | shpLocalize }} 2</label>
        <input type="text" id="recipient-name2" [(ngModel)]="recipient.name2" maxlength="35" name="recipient_name2" /> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-name2" [(ngModel)]="recipient.name2" type="text" name="recipient_name2" maxlength="35" pInputText>
            <label for="recipient-name2" *ngIf="recipient.customerRecipientTypeCode == b2c">{{ 'recipient_person_name' | shpLocalize }} 2</label>
            <label for="recipient-name2" *ngIf="recipient.customerRecipientTypeCode == b2b">{{ 'recipient_organization_name' | shpLocalize }} 2</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="recipient.customerRecipientTypeCode == b2b">
        <!-- <label for="recipient-contact-person">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
        <input type="text" [(ngModel)]="recipient.contactPerson" maxlength="35" id="recipient-contact-person" name="recipient_contactPerson" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-contact-person" [(ngModel)]="recipient.contactPerson" type="text" name="recipient_contactPerson" maxlength="35" pInputText>
            <label for="recipient-contact-person">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl required mdl-cell mdl-cell--8-col">
        <!-- <label for="recipient-street">{{ 'recipient_street' | shpLocalize }}</label>
        <input type="text" [(ngModel)]="recipient.street" maxlength="35" id="recipient-street" name="recipient_street" required /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-street" [(ngModel)]="recipient.street" type="text" name="recipient_street" maxlength="35" pInputText required>
            <label for="recipient-street">{{ 'recipient_street' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--4-col">
        <!-- <label for="recipient-street">{{ 'recipient_house_number' | shpLocalize }}</label>
        <input type="text" [(ngModel)]="recipient.houseNr" maxlength="8" id="recipient-house-nr" name="recipient_house_nr" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-house-nr" [(ngModel)]="recipient.houseNr" type="text" name="recipient_house_nr" pInputText>
            <label for="recipient-house-nr">{{ 'recipient_house_number' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="recipient.customerRecipientTypeCode == b2b">
        <!-- <label for="recipient-street-detail">{{ 'recipient_street_detail' | shpLocalize }}</label>
        <input type="text" [(ngModel)]="recipient.streetDetail" maxlength="35" id="recipient-street-detail" name="recipient_street_detail" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-street-detail" [(ngModel)]="recipient.streetDetail" type="text" maxlength="35" name="recipient_street_detail" pInputText>
            <label for="recipient-street-detail">{{ 'recipient_street_detail' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl required mdl-cell mdl-cell--8-col">
        <!-- <label for="recipient-country">{{ 'recipient_country' | shpLocalize }}</label>
        <select [(ngModel)]="recipient.countryCode" id="recipient-country" [formControl]="addressCountry" name="recipient_country" required (ngModelChange)="onCounrySelect()">
          <option *ngFor="let country of countries | async" value="{{ country.code}}">{{ country.name }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-dropdown id="recipient-country" [options]="countries | async" [(ngModel)]="recipient.countryCode" [formControl]="addressCountry"
                        (onChange)="onCounrySelect()" [autoDisplayFirst]="false" optionValue="code" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" required>
            </p-dropdown>
            <label for="recipient-country">{{ 'recipient_country' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--4-col">
        <!-- <label for="recipient-language-code">{{ 'language' | shpLocalize }}</label>
        <select [(ngModel)]="recipient.languageCode" id="recipient-language-code" name="recipient_language_code">
          <option [ngValue]="''">-</option>
          <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select [(ngModel)]="recipient.languageCode" id="recipient-language-code" name="recipient_language_code">
              <option [ngValue]="''">-</option>
              <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
            </select>
            <label for="recipient-language-code">{{ 'language' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl required mdl-cell mdl-cell--4-col">
        <!-- <label for="recipient-zip">{{'recipient_zip' | shpLocalize }}</label>
        <ng2-completer [formControl]="addressZip"
                       [attr.id]="'recipient-zip'"
                       [attr.name]="'recipient-zip'"
                       required
                       [dataService]="zipCityPairsDataService"
                       [minSearchLength]="1"
                       (selected)="zipCityPairSelected($event)"
                       placeholder="{{ 'recipient_zip' | shpLocalize }}"
                       [(ngModel)]="recipient.zip">
        </ng2-completer>
        <em class="error" *ngIf="isZipInvalid">{{ 'error_zip_invalid' | shpLocalize }}</em>
        <em class="error" *ngIf="isZipNotInCity">{{ 'error_zip_not_in_city' | shpLocalize }}</em> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-autoComplete id="recipient-zip" [(ngModel)]="recipient.zip" [formControl]="addressZip" [suggestions]="filteredZips"
                            (completeMethod)="filterZip($event)" (onSelect)="onZipSelected($event)" name="recipient-zip" [minLength]="1" autocomplete="none" required>
              <ng-template let-zip pTemplate="item"><div class="zip-item"><div><b>{{zip.zip}}</b></div><div><small>{{zip.cityName}}</small></div></div></ng-template>
            </p-autoComplete>
            <label for="recipient-zip">{{ 'recipient_zip' | shpLocalize }}</label>
          </span>
          <em class="error" *ngIf="isZipInvalid">{{ 'error_zip_invalid' | shpLocalize }} {{ 'error_zip_expected_format' | shpLocalize:getZipFormatForSelectedCountry() }}</em>
          <em class="error" *ngIf="isZipNotInCity">{{'address_zip_cannot_contain_spaces' | shpLocalize }}</em>
        </div>
      </div>
      <div class="ctl required mdl-cell mdl-cell--8-col">
        <!-- <label for="recipient-city">{{ 'recipient_city' | shpLocalize }}</label>
        <ng2-completer [(ngModel)]="recipient.city" id="recipient-city" [formControl]="addressCity" name="recipient_city" [minSearchLength]="1"
                       [dataService]="citiesDataService" maxlength="35" required></ng2-completer>
        <em class="error" *ngIf="isCityInvalid">{{ 'error_city_not_found' | shpLocalize }}</em> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-autoComplete id="recipient-city" [(ngModel)]="recipient.city" [formControl]="addressCity" [suggestions]="filteredCities"
                            (completeMethod)="filterCities($event)" maxlength="35"
                            name="recipient_city" [minLength]="1" autocomplete="none" required></p-autoComplete>
            <label for="recipient-city">{{ 'recipient_city' | shpLocalize }}</label>
          </span>
          <em class="error" *ngIf="isCityInvalid">{{ 'error_city_not_found' | shpLocalize }}</em>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col">
        <!-- <label for="recipient-state">{{ 'recipient_state' | shpLocalize }}</label>
        <input type="text" [(ngModel)]="recipient.stateCode" id="recipient-state" maxlength="10" name="recipient_state" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-state" [(ngModel)]="recipient.stateCode" type="text" name="recipient_state" maxlength="2" pInputText>
            <label for="recipient-state">{{ 'recipient_state_code' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col">
        <input [(ngModel)]="recipient.isUsedAsReceiver" id="is-used-as-receiver-checkbox" type="checkbox" name="is_used_as_receiver_checkbox">
        <label for="is-used-as-receiver-checkbox">{{ 'is_used_as_receiver' | shpLocalize }}</label>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col">
        <input [(ngModel)]="recipient.isUsedAsReturnReceiver" id="is-used-as-return-receiver-checkbox" type="checkbox" name="is_used_as_return_receiver_checkbox">
        <label for="is-used-as-return-receiver-checkbox">{{ 'is_used_as_return_receiver' | shpLocalize }}</label>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col">
        <input [(ngModel)]="recipient.isUsedAsCollectionRequestSender" id="is-used-as-collection-request-sender-checkbox" type="checkbox" name="is_used_as_collection_request_sender_checkbox">
        <label for="is-used-as-collection-request-sender-checkbox">{{ 'is_used_as_collection_request_sender' | shpLocalize }}</label>
      </div>
      <div *ngIf="recipient.customerRecipientTypeCode == b2b" class="ctl mdl-cell mdl-cell--12-col">
        <!-- <label for="recipient-state">{{ 'recipient_eori' | shpLocalize }}</label>
        <select *ngIf="!isReadOnly" name="recipient-eori-country-code" [(ngModel)]="eoriNumberCountryCode" (ngModelChange)="onEoriNumberChange()">
          <option *ngFor="let country of countriesByCode | async" [value]="country.code">{{ country.code }}</option>
        </select> -->
        <div class="p-field p-field--half">
          <span class="p-float-label w-100" dpdFloatingLabel>
            <select *ngIf="!isReadOnly" name="recipient-eori-country-code" id="recipient-eori-country-code" [(ngModel)]="eoriNumberCountryCode" (ngModelChange)="onEoriNumberChange()">
              <option *ngFor="let country of countriesByCode | async" [value]="country.code">{{ country.code }}</option>
            </select>
            <label for="recipient-eori-country-code">{{ 'eori_country_code' | shpLocalize }}</label>
          </span>
        </div>

        <!-- <input *ngIf="!isReadOnly" type="text" [(ngModel)]="eoriNumberWithoutCountryCode" (ngModelChange)="onEoriNumberChange()" #eoriControl="ngModel" shpValidateAlphanumeric maxlength="35" id="recipient-eori-number"  name="recipient-eori_number" placeholder="{{ 'recipient_eori' | shpLocalize }}" />
        <input *ngIf="isReadOnly" type="text" [(ngModel)]="recipient.eoriNumber"  id="recipient-eori-number" [readonly]="true" name="recipient-reori_number" />
        <em class="error" *ngIf="eoriControl?.errors?.alphaNumeric">{{ 'error_eori_invalid_format' | shpLocalize }}</em> -->

        <div class="p-field p-field--half">
          <span class="p-float-label">
            <input *ngIf="!isReadOnly" type="text" [(ngModel)]="eoriNumberWithoutCountryCode" (ngModelChange)="onEoriNumberChange()" pInputText #eoriControl="ngModel" shpValidateAlphanumeric maxlength="35" id="recipient-eori-number"  name="recipient-eori_number">
            <input *ngIf="isReadOnly" type="text" [(ngModel)]="recipient.eoriNumber"  id="recipient-eori-number" [readonly]="true" pInputText name="recipient-reori_number">
            <label for="recipient-eori-number">{{ 'recipient_eori' | shpLocalize }}</label>
            <em class="error" *ngIf="eoriControl?.errors?.alphaNumeric">{{ 'error_eori_invalid_format' | shpLocalize }}</em>
          </span>
        </div>

      </div>
      <div class="ctl mdl-cell mdl-cell--6-col">
        <!-- <label for="recipient-reference">{{ 'recipient_reference' | shpLocalize }}</label>
        <input type="text" [(ngModel)]="recipient.referenceNumber" id="recipient-reference" maxlength="35" name="recipient_reference" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-reference" [(ngModel)]="recipient.referenceNumber" type="text" name="recipient_reference" maxlength="35" pInputText>
            <label for="recipient-reference">{{ 'recipient_reference' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--6-col">
        <!-- <label for="recipient-email">{{ 'recipient_email' | shpLocalize }}</label>
        <input type="text" shpValidateEmail [(ngModel)]="recipient.email" maxlength="50" id="recipient-email" name="recipient_email" />
        <em class="error" *ngIf="f?.controls?.recipient_email?.errors?.invalidEmail">{{ 'invalid_email' | shpLocalize }}</em> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-email" [(ngModel)]="recipient.email" type="text" name="recipient_email" maxlength="100" shpValidateEmail pInputText>
            <label for="recipient-email">{{ 'recipient_email' | shpLocalize }}</label>
          </span>
          <em class="error" *ngIf="f?.controls?.recipient_email?.errors?.invalidEmail">{{ 'invalid_email' | shpLocalize }}</em>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--6-col">
        <!-- <label for="recipient-phone">{{ 'recipient_phone' | shpLocalize }}</label> -->
        <shp-phone-number-input [(ngModel)]="recipient.phone" id="recipient-phone" maxlength="30" [defaultCountryCode]="settings.country_code.value" name="recipient_phone"></shp-phone-number-input>
      </div>
      <div class="ctl mdl-cell mdl-cell--6-col">
        <!-- <label for="recipient-fax">{{ 'recipient_fax' | shpLocalize }}</label>
        <input type="text" [(ngModel)]="recipient.fax" id="recipient-fax" maxlength="30" name="recipient_fax" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="recipient-fax" [(ngModel)]="recipient.fax" type="text" name="recipient_fax" maxlength="30" pInputText>
            <label for="recipient-fax">{{ 'recipient_fax' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col">
        <!-- <label for="recipient-comment">{{ 'recipient_comment' | shpLocalize }}</label>
        <textarea rows="5" [(ngModel)]="recipient.comment" maxlength="70" id="recipient-comment" name="recipient_comment"></textarea> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <textarea id="recipient-comment" [(ngModel)]="recipient.comment" name="recipient_comment" rows="5" maxlength="70" pInputTextarea></textarea>
            <label for="recipient-comment">{{ 'recipient_comment' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="showRecipientToPickupAddressAsignment">
        <!-- <label for="recipient-address-id">{{ 'recipient_address_id' | shpLocalize }}</label>
        <select [(ngModel)]="recipient.addressId" id="recipient-address-id" name="recipient_address_id">
          <option *ngFor="let address of pickupAddresses" [value]="address.id">{{ getAddressAsStringForDislay(address) }}</option>
        </select> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-dropdown id="recipient-address-id" [options]="pickupAddresses" [(ngModel)]="recipient.addressId" name="recipient_address_id" [autoDisplayFirst]="false" optionLabel="name1" optionValue="id" [showClear]="true">
              <ng-template let-address pTemplate="item"><div class="address-item"><div>{{ getAddressAsStringForDislay(address) }}</div></div></ng-template>
            </p-dropdown>
            <label for="recipient-address-id">{{ 'recipient_address_id' | shpLocalize }}</label>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="recipient-toolbar toolbar">
    <button type="submit" id="save" [disabled]="!f.form.valid || isValidatingCityAndZip || isCityInvalid || isZipInvalid || isZipNotInCity" wizardTag="saveRecipient"><span class="ic-floppydisk"></span> {{ 'recipient_save' | shpLocalize }}</button>
    <button type="button" id="delete" class="secondary" *ngIf="!recipient?.isNew" (click)="delete()"><span class="ic-bin"></span>{{ 'recipient_delete' | shpLocalize }}</button>
    <button type="button" id="back-to-list" class="secondary" (click)="back()"><span class="ic-arrow-left-1"></span>{{ 'recipient_back_to_list' | shpLocalize }}</button>
  </div>
</form>
