import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";

import { Subscription } from "rxjs";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { ToastrService } from "ngx-toastr";
import { ImportProfilesService } from "../../../import-profiles/services/import-profiles.service";
import { ImportProfile } from "../../../import-profiles/models/import-profiles.models";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-automatic-rec-import-settings",
  templateUrl: "./automatic-rec-import-settings.component.html"
})
export class AutomaticRecImportSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    private _importProfilesService: ImportProfilesService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    toastr: ToastrService) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }

  private _importProfilesSubscription: Subscription = null;

  public importProfileDefault: ImportProfile;
  public importProfiles: ImportProfile[] = null;

  protected afterSettingsLoaded() {
    this.isBusy = true;
    this._importProfilesSubscription = this._importProfilesService.loadListOfImportProfilesByType("Recipients").subscribe(
      result => {
        this.isBusy = false;
        this.importProfiles = result;
      }, ex => {
        this.isBusy = false;
        let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(ex);
    });
  }


  public ngOnInit() {
    super.ngOnInit();

    this.importProfileDefault = { code: null } as any;
  }
}