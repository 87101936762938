import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { forkJoin, Observable, ReplaySubject } from "rxjs";

import { API_URL } from "../api-url";
import { ApiServiceBase } from "./api-service-base";
import { LoggingService } from "./logging.service";

/**
 * Služba poskytujúca prístup k rôznym nastaveniam aplikácie.
 */
@Injectable()
export class ShipperSettingsService extends ApiServiceBase {

  readonly settingsLoaded$: Observable<void>;

  private _isSophia = false;
  private _isCentralShipper = false;
  private _canEditPickupAddresses = false;
  private _canEditBankAccounts = false;
  private _isProductsAndServicesCalculationDuringEditingAllowed = true;
  private _addressProviderInUse = null;
  private _isCustomsTariffNumberValidationEnabled = false;

  private _settingsLoaded$ = new ReplaySubject<void>(1);

  constructor(
    logging: LoggingService,
    private _http: HttpClient
  ) {
    super(logging);
    this.settingsLoaded$ = this._settingsLoaded$.asObservable();
  }


  public get isSophia(): boolean {
    return this._isSophia;
  }

  public get isCentralShipper(): boolean {
    return this._isCentralShipper;
  }

  public get canEditPickupAddresses(): boolean {
    return this._canEditPickupAddresses;
  }

  public get canEditBankAccounts(): boolean {
    return this._canEditBankAccounts;
  }

  public get isProductsAndServicesCalculationDuringEditingAllowed(): boolean {
    return this._isProductsAndServicesCalculationDuringEditingAllowed;
  }

  public get addressProviderInUse(): string {
    return this._addressProviderInUse;
  }

  public get isCustomsTariffNumberValidationEnabled(): boolean {
    return this._isCustomsTariffNumberValidationEnabled;
  }

  /**
   * Metóda vracia promise, ktorá musí vrátiť true, ak sa načítajú úvodné nastavenia aplikácie,
   * ktoré sú poskytnuté z back-endu.
   */
  public loadStartupSettings() {
    const p = new Promise<boolean>((resolve, reject) => {
      forkJoin([
        this.processRequest<boolean>(this._http.get(`${API_URL}/shipper-configuration/is-sophia`)),
        this.processRequest<boolean>(this._http.get(`${API_URL}/shipper-configuration/is-central-shipper`)),
        this.processRequest<boolean>(this._http.get(`${API_URL}/shipper-configuration/can-edit-customer-addresses`)),
        this.processRequest<boolean>(this._http.get(`${API_URL}/shipper-configuration/can-edit-customer-bank-accounts`)),
        this.processRequest<boolean>(this._http.get(`${API_URL}/shipper-configuration/is-products-and-services-calculation-during-editing-allowed`)),
        this.processRequest<boolean>(this._http.get(`${API_URL}/shipper-configuration/is-customs-tariff-number-validation-enabled`)),
        //this.processRequest<string>(this._http.get(`${API_URL}/shipper-configuration/address-provider-in-use`))
      ]).subscribe({
        next: result => {
          this._isSophia = Boolean(result[0]);
          this._isCentralShipper = Boolean(result[1]);
          this._canEditPickupAddresses = Boolean(result[2]);
          this._canEditBankAccounts = Boolean(result[3]);
          this._isProductsAndServicesCalculationDuringEditingAllowed = Boolean(result[4]);
          this._isCustomsTariffNumberValidationEnabled = Boolean(result[5]);
          this._addressProviderInUse = 'NL'; // TODO - treba fixnut

          this._settingsLoaded$.next();
          resolve(true);
        },
        error: ex => {
          reject(ex);
        }
      });
    });

    return p;
  }
}
