import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { forkJoin } from "rxjs";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-routing-settings",
  templateUrl: "./routing-settings.component.html"
})
export class RoutingSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    toastr: ToastrService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }


  public isLoadingDbVersions = true;
  public currentGeoRoutingDbVersion = "";
  public availableGeoRoutingDbVersion = "";
  public isAvailableGeoRoutingDbVersion = false;


  public ngOnInit(): void {
    super.ngOnInit();

    this.setGeoRoutingDbVersion();
  }


  private setGeoRoutingDbVersion() {
    forkJoin([
      this._skdataConfigService.getCurrentGeoRoutingDbVersion(),
      this._skdataConfigService.getAvailableGeoRoutingDbVersion()
    ]).subscribe({
      next: ([current, available]) => {
        this.currentGeoRoutingDbVersion = current;
        this.availableGeoRoutingDbVersion = available;
        this.isAvailableGeoRoutingDbVersion = available != null && String(available).length > 0;
        this.isLoadingDbVersions = false;
      },
      error: ex => {
        this.isLoadingDbVersions = false;

        this.loggingService.logErrorData(ex, "Error retrieving RDB versions.")

        var exception = this.exceptionsHandlerService.getExceptionInfo(ex);

        this.toastr.error(this.localizationService.getLocalizedExceptionString(exception));
      }
    });
  }



  /**
   * JS objekt reprezentujúci údaje o vybranom súbore.
   */
  private _selectedFile: File = null;
  public selectedFileName = "";
  public isUploadingRdb = false;


  /**
  * Handler, ktorý sa má bindovať na event zmeny vybraného súboru cez input type=file.
  */
  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }


  private setSelectedFile(inputValue: any) {
    if (inputValue && inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this.selectedFileName = this._selectedFile.name;
    } else {
      this.selectedFileName = "";
      this._selectedFile = null;
      (document.getElementById("selected-file") as any).value = "";
    }
  }


  public uploadRdbFile() {
    this.isUploadingRdb = true;
    if (this._selectedFile) {
      this._skdataConfigService.uploadRoutindDbZipFile(this._selectedFile).subscribe(() => {
        this.setSelectedFile(null);
        this.setGeoRoutingDbVersion();

        this.toastr.success(this.localizationService.getLocalizedString("rdb_zip_file_successfully_uploaded"));
        this.isUploadingRdb = false;
      }, ex => {
        this.loggingService.logErrorData(ex, "Error setting forced RDB download.")

        var exception = this.exceptionsHandlerService.getExceptionInfo(ex);

        this.toastr.error(this.localizationService.getLocalizedExceptionString(exception));
        this.isUploadingRdb = false;
      });
    }
  }


  public forceRdbDownload() {
    this.settingsService.forceRdbDownload()
    .subscribe({
      next: () => this.toastr.success(this.localizationService.getLocalizedString("rdb_new_version_check_successfully_scheduled")),
      error: ex => {
        this.loggingService.logErrorData(ex, "Error setting forced RDB download.")

        var exception = this.exceptionsHandlerService.getExceptionInfo(ex);

        this.toastr.error(this.localizationService.getLocalizedExceptionString(exception));
      }
    });
  }
}
