import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { forkJoin, of, Subscription } from "rxjs";
import { filter, finalize, mergeMap, take, tap } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { AuthenticationService, BusinessUnitSettingsService, BUSINESS_UNIT_CODE_NL, PollyCollection } from "../../shared/index";
import { ContextService } from "../../shared/services/context.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import * as SettingsModel from "../models/settings.model";
import { SettingsService } from "../services/settings.service";
import { UserOperation } from "../../user/models/user.model";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-settings",
  templateUrl: "./settings.component.html"
})
export class SettingsComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _authenticationService: AuthenticationService,
    private _settingsService: SettingsService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _contextService: ContextService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _toastr: ToastrService,
    private _buSettingsService: BusinessUnitSettingsService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public isBusy = true;
  public hasSavingErrorOccured = false;
  public hasLoadingErrorOccured = false;
  public isAdmin = false;
  public isCentralShipper = false;
  public pollyCollection = PollyCollection.Settings;
  public wizardStepNames = WizardStepName;

  private _customerDetailId = null;
  private _addressId = null;

  // Rôzne kategórie

  public isBasicSettingsAvailable = true;
  public isViewSettingsAvailable = false;
  public isPrintSettingsAvailable = false;
  public isPudoSettingsAvailable = false;
  public isSyncSettingsAvailable = false;
  public isAutomaticImportSettingsAvailable = false;
  public isAutomaticExportSettingsAvailable = false;
  public isEodExportSettingsAvailable = false;
  public isAutomaticRecImportSettingsAvailable = false;
  public isAutomatedImportSettingsAvailable = false;
  public isRoutingSettingsAvailable = false;
  public isProxySettingsAvailable = false;
  public isConfigurationSettingsAvailable = false;
  public isInfoSettingsAvailable = false;
  public isUserManagementAvailable = false;
  public isPrinterRulesSettingsAvailable = false;
  public isLogAvailable = false;
  public isInstructionsAvailable = false;

  public isPickupAddressesSettingsAvailable = false;
  public isBankAccountsSettingsAvailable = false;
  public isBackupRestoreAvailable = false;
  public isEmailTemplatesEditorAvailable = false;
  public isEmailTemplatesEditorVisible = false;
  public isCustomShipmentsExportContentEnabled = false;

  /**
   * Všetky nastavenia aktuálne vybraného CustomerDetail v jednom objekte.
   */
  public settings: SettingsModel.TenantSettings = null;


  private _settingsLoadingSubscription: Subscription = null;

  private setCategoriesAvailability() {
    if (!this.settings) {
      return;
    }    

    this.isBasicSettingsAvailable =
      this.settings.default_product_code.isVisible || this.settings.all_parcels_in_a_shipment_have_the_same_dimensions.isVisible ||
      this.settings.parcel_default_dimensions_availability.isVisible || this.settings.shipment_default_note.isVisible ||
      this.settings.sms_notification_for_each_shipment.isVisible || this.settings.e_mail_notification_for_each_shipment.isVisible ||
      this.settings.all_parcels_in_a_shipment_have_the_same_references.isVisible ||
      this.settings.number_of_shipment_references.isVisible ||
      this.settings.number_of_parcel_references.isVisible ||
      this.settings.default_parcel_reference_1.isVisible || this.settings.default_parcel_reference_2.isVisible ||
      this.settings.default_cod_payment_type_id.isVisible || this.settings.parcel_number_ranges_warning_threshold.isVisible ||
      this.settings.default_recipient_language_code.isVisible || this.settings.return_to_shipment_list_after_saving.isVisible ||
      this.settings.enable_products_and_services_availability_calculation.isVisible || this.settings.can_only_admin_update_shipper.isVisible;

    this.isPrintSettingsAvailable = this.settings.printing_settings_availability.isVisible;

    this.isPudoSettingsAvailable = this.settings.pudo_download_is_ftp.isVisible || this.settings.pudo_download_path.isVisible || this.settings.pudo_list_name.isVisible;

    this.isSyncSettingsAvailable = this.settings.sync_shipments_manually.isVisible || this.settings.sync_shipments_to_endpoint.isVisible ||
      this.settings.sync_shipments_path.isVisible || this.settings.sync_shipments_use_semaphore_files.isVisible || this.settings.sync_shipments_hour.isVisible ||
      this.settings.sync_shipments_minute.isVisible || this.settings.sync_shipments_ftp_host.isVisible || this.settings.sync_shipments_continually.isVisible ||
      this.settings.sync_shipments_periodicity_in_minutes.isVisible || this.settings.sync_shipments_time_mode.isVisible || this.settings.sync_shipments_nminutes_after_save.isVisible ||
      this.settings.sync_collection_requests_hour.isVisible || this.settings.sync_collection_requests_minute.isVisible;

    this.isAutomaticExportSettingsAvailable = this.settings.automated_shipments_export.isVisible || this.settings.automated_shipments_export_path.isVisible ||
      this.settings.automated_shipments_export_file_mask.isVisible || this.settings.automated_shipments_export_periodicity_in_minutes.isVisible;

    this.isEodExportSettingsAvailable = this._shipperSettingsService.isCentralShipper && this.isCustomShipmentsExportContentEnabled;

    this.isAutomaticImportSettingsAvailable = this.settings.automated_shipments_import.isVisible || this.settings.automated_shipments_import_path.isVisible ||
      this.settings.automated_shipments_import_periodicity_in_minutes.isVisible || this.settings.automated_shipments_import_use_semaphore_files.isVisible ||
      this.settings.automated_shipments_import_disable_special_order_validation.isVisible ||
      this.settings.automated_shipments_import_sender_Id.isVisible || this.settings.automated_shipments_import_bank_account_Id.isVisible ||
      this.settings.automated_shipments_import_delete_file_after_processing.isVisible || this.settings.automated_shipments_import_print_labels_after_processing.isVisible ||
      this.settings.automated_shipments_import_error_path.isVisible || this.settings.automated_shipments_import_move_to_archive.isVisible ||
      this.settings.automated_shipments_import_archive_path.isVisible || this.settings.automated_shipments_import_include_parcelnrs_in_archive.isVisible ||
      this.settings.automated_shipments_import_semaphore_files_in_archive.isVisible;

    this.isAutomaticRecImportSettingsAvailable = this.settings.automated_recipients_import.isVisible || this.settings.automated_recipients_import_path.isVisible ||
      this.settings.automated_recipients_import_periodicity_in_minutes.isVisible || this.settings.automated_recipients_import_use_semaphore_files.isVisible ||
      this.settings.automated_recipients_import_delete_file_after_processing.isVisible;

    this.isRoutingSettingsAvailable = this.settings.current_Routing_Tables_version.isVisible;

    this.isProxySettingsAvailable = this.settings.use_proxy_server.isVisible || this.settings.proxy_address.isVisible || this.settings.proxy_port.isVisible;

    this.isInfoSettingsAvailable = true;

    // HACK: Toto neriešime explicitne na strane servera, tak tam dáme dačo, čo by mal meniť len admin.
    this.isPrinterRulesSettingsAvailable = this.settings.default_cod_payment_type_id.isVisible && !this._shipperSettingsService.isCentralShipper && !this._shipperSettingsService.isSophia;
    
    this.isUserManagementAvailable = false; // paranoid safety measure keby náhodou subscribe poniže nebol zavolaný :-)
    this._authenticationService
      .can(UserOperation.ManageUsers)
      .pipe(take(1))
      .subscribe(value => this.isUserManagementAvailable = value);

    this.isConfigurationSettingsAvailable = this.settings.configurations_editing_availability.isVisible;

    this.isPickupAddressesSettingsAvailable = this._shipperSettingsService.canEditPickupAddresses;

    this.isBankAccountsSettingsAvailable = this._shipperSettingsService.canEditBankAccounts;

    this.isLogAvailable = !this._shipperSettingsService.isCentralShipper && !this._shipperSettingsService.isSophia;

    this.isInstructionsAvailable = !this._shipperSettingsService.isCentralShipper && !this._shipperSettingsService.isSophia;

    this.isCentralShipper = this._shipperSettingsService.isCentralShipper;

    if (this._shipperSettingsService.isSophia) {
      this.isAutomaticImportSettingsAvailable = false;
      this.isRoutingSettingsAvailable = false;
      this.isConfigurationSettingsAvailable = false;
      this.isInfoSettingsAvailable = false;
    }
  }


  private loadSettings() {
    this.isBusy = true;

    this._settingsLoadingSubscription = this._contextService.currentAddress.pipe(
      filter(value => value != null),
      tap(value => {
        this._customerDetailId = value.customerDetailId;
        this._addressId = value.id;
      }),
      mergeMap(value => {
        return this._settingsService.getConfigurationSettings(value.customerDetailId, value.id);
      })
    ).subscribe(
        settings => {
          this.settings = settings;
          this.setCategoriesAvailability();

          this.loadAdditionalSettings();
        },
        ex => {
          this.loggingService.logErrorData(ex, "Error loading settings.");

          const exception = this._exceptionsHandlerService.getExceptionInfo(ex);

          this._toastr.error(this.localizationService.getLocalizedExceptionString(exception));

          this.isBusy = false;
        });
  }

  private loadAdditionalSettings() {
    const obs$ = !this._shipperSettingsService.isCentralShipper && !this._shipperSettingsService.isSophia
      ? forkJoin([
        this._authenticationService.isAdmin$.pipe(take(1)),
        this._settingsService.getIsDbBackupAndRestoreAllowed()
      ])
      : forkJoin([
        of(false),
        of(true)
      ]);

    obs$.pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(([isAdmin, isDbBackupAndRestoreAllowed]) => {
        this.isBackupRestoreAvailable = isAdmin && isDbBackupAndRestoreAllowed;
        this.isEmailTemplatesEditorAvailable = isAdmin;
        this.isAdmin = isAdmin;
      }, ex => {
          this.loggingService.logErrorData(ex, "Error loading user IsAdmin.");
      }
    );
  }

  public loadBusinessUnitSettings() {
    forkJoin([
      this._buSettingsService.getIsTemplateEditorVisible(),
      this._buSettingsService.getIsCustomShipmentsExportContentEnabled()
    ]).subscribe(results => {
      [
        this.isEmailTemplatesEditorVisible,
        this.isCustomShipmentsExportContentEnabled
      ] = results;

      this.setCategoriesAvailability();
    });
  }

  public save() {
    this.isBusy = true;
    this.hasSavingErrorOccured = false;

    this._settingsService.updateConfigurationSettings(this._customerDetailId, this._addressId, this.settings)
      .subscribe(() => {
        this.isBusy = false;
        this._toastr.success(this.localizationService.getLocalizedString("settings_successfully_saved"));
      },
        ex => {
          this.hasSavingErrorOccured = true;

          const exception = this._exceptionsHandlerService.getExceptionInfo(ex);

          this._toastr.error(this.localizationService.getLocalizedExceptionString(exception));

          this.isBusy = false;
        });
  }


  public ngOnInit() {
    this.loadSettings();
    this.loadBusinessUnitSettings();
  }


  public ngOnDestroy() {
    if (this._settingsLoadingSubscription != null) {
      this._settingsLoadingSubscription.unsubscribe();
      this._settingsLoadingSubscription = null;
    }
  }
}
