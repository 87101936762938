<!-- {{ settings | json }} -->
<div *ngIf="settings">
	<div class="mdl-grid compact">
		<div class="ctl mdl-cell mdl-cell--12-col required">
			<!-- <input id="input-recipient-organization-name" type="text" [(ngModel)]="settings.name1" required placeholder="{{ 'recipient_organization_name' | shpLocalize }}" name="recipient_name" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-organization-name" type="text" name="recipient_name" pInputText [(ngModel)]="settings.name1" required autocomplete="off">
					<label for="input-recipient-organization-name">{{ 'recipient_organization_name' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--12-col">
			<!-- <input id="input-recipient-organization-name2" type="text" [(ngModel)]="settings.name2" maxlength="35" name="recipient_name2" placeholder="{{ 'recipient_organization_name' | shpLocalize }} 2" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-organization-name2" type="text" name="recipient_name2" pInputText [(ngModel)]="settings.name2" autocomplete="off">
					<label for="input-recipient-organization-name2">{{ 'recipient_organization_name' | shpLocalize }} 2</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--12-col">
			<!-- <input id="input-recipient-contact-person" type="text" [(ngModel)]="settings.contactPerson" maxlength="35" name="recipient_contactPerson" placeholder="{{ 'recipient_organization_contact_name' | shpLocalize }}" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-contact-person" type="text" name="recipient_contactPerson" pInputText [(ngModel)]="settings.contactPerson" autocomplete="off">
					<label for="input-recipient-contact-person">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--8-col required">
			<!-- <input id="input-recipient-street" type="text" [(ngModel)]="settings.street" maxlength="35" required name="recipient_street" placeholder="{{ 'recipient_street' | shpLocalize }}" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-street" type="text" name="recipient_street" pInputText [(ngModel)]="settings.street" required autocomplete="off">
					<label for="input-recipient-street">{{ 'recipient_street' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--4-col">
			<!-- <input id="input-recipient-house-number" type="text" [(ngModel)]="settings.houseNr" maxlength="8" name="recipient_house_nr" placeholder="{{ 'recipient_house_number' | shpLocalize }}" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-house-number" type="text" name="recipient_house_nr" pInputText [(ngModel)]="settings.houseNr" autocomplete="off">
					<label for="input-recipient-house-number">{{ 'recipient_house_number' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--12-col">
			<!-- <input id="input-recipient-street-detail" type="text" [(ngModel)]="settings.addressLine2" name="recipient_street2" placeholder="{{ 'recipient_street_detail' | shpLocalize }}" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-street-detail" type="text" name="recipient_street2" pInputText [(ngModel)]="settings.addressLine2" autocomplete="off">
					<label for="input-recipient-street-detail">{{ 'recipient_street_detail' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--12-col">
			<!-- <select id="select-recipient-country" [(ngModel)]="settings.country" (change)="countryChanged()" name="recipient_country" required>
				<option *ngFor="let country of countries" [ngValue]="country">{{ country.name }}</option>
			</select> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<p-dropdown id="select-recipient-country" [options]="countries" [(ngModel)]="settings.country" (ngModelChange)="countryChanged()" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" required>
						<ng-template let-country pTemplate="item"><div class="country-item"><div>{{country.name}}</div></div></ng-template>
					</p-dropdown>
					<label for="select-recipient-country">{{ 'recipient_country' | shpLocalize }}</label>
				</span>
			  </div>
		</div>

		<div class="ctl mdl-cell mdl-cell--5-col required">
			<!-- <input id="input-recipient-zip" type="text" [(ngModel)]="settings.zip" maxlength="9" name="recipient_zip" required placeholder="{{ 'recipient_zip' | shpLocalize }}" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-zip" type="text" [(ngModel)]="settings.zip" maxlength="9" pInputText name="recipient_zip" required>
					<label for="input-recipient-zip">{{ 'recipient_zip' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--7-col required">
			<!-- <ng2-completer [(ngModel)]="settings.city" name="recipient_city" [minSearchLength]="1"
						   [dataService]="citiesDataService" maxlength="35" required placeholder="{{ 'recipient_city' | shpLocalize }}">
			</ng2-completer> -->

			<div class="p-field p-field--full">
				<span class="p-float-label">
					<p-autoComplete id="input-recipient-city" [(ngModel)]="settings.city" [suggestions]="filteredCities" (completeMethod)="filterCities($event)" maxlength="35"
									name="recipient_city" [minLength]="1" autocomplete="none" required></p-autoComplete>
					<label for="input-recipient-city">{{ 'recipient_city' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--12-col required" *ngIf="settings.country?.isStateDeliveryRequired">
			<input id="input-recipient-state" type="text" [(ngModel)]="settings.stateCode" maxlength="2" required name="recipient_state" placeholder="{{ 'recipient_state_code' | shpLocalize }}" />
		</div>

		<div class="ctl mdl-cell mdl-cell--12-col">
			<!-- <input id="input-recipient-email" type="text" [(ngModel)]="settings.email" maxlength="50" shpValidateEmail name="recipient_email" placeholder="{{ 'recipient_email' | shpLocalize }}" /> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<input id="input-recipient-email" type="text" name="recipient_email" pInputText [(ngModel)]="settings.email" shpValidateEmail>
					<label for="input-recipient-email">{{ 'recipient_email' | shpLocalize }}</label>
				</span>
			</div>
		</div>

		<div class="ctl mdl-cell mdl-cell--12-col">
			<shp-phone-number-input [(ngModel)]="settings.phone" maxlength="30" [defaultCountryCode]="settings.countryCode" name="recipient_phone"></shp-phone-number-input>
		</div>

		<div *ngIf="!config.excludeComments" class="ctl mdl-cell mdl-cell--12-col">
			<!-- <textarea rows="5" [(ngModel)]="settings.comment" id="{{name}}-recipient-comment" [readonly]="isReadOnly" name="recipient_comment" placeholder="{{ 'recipient_comment' | shpLocalize }}"></textarea> -->
			<div class="p-field p-field--full">
				<span class="p-float-label">
					<textarea rows="5" [(ngModel)]="settings.comment" id="{{name}}-recipient-comment" [readonly]="isReadOnly" name="recipient_comment"></textarea>
					<label for="input-recipient-email">{{ 'recipient_comment' | shpLocalize }}</label>
				</span>
			</div>
		</div>
	</div>
</div>

