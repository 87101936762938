import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, ElementRef, HostBinding, HostListener, Renderer2 } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

// import * as Shared from "../../shared/index";
import { AppNewsfeedMessage } from '../models/app-newsfeed-message.model';
import { SELECTOR_PREFIX } from '../prefix';

@Component({
  selector: SELECTOR_PREFIX + '-newsfeed-toast',
  templateUrl: './newsfeed-toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0, display: 'none' })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ]
})
export class NewsfeedToast extends Toast {
  set newsfeedMessage(message: AppNewsfeedMessage) {
    this.hasContent = Boolean(message.content);
    this.hasHighPriority = message.priority == 1;
    this._newsfeedMessage = message;

    this.listenToLinkClicks();
  };

  @HostBinding('class.newsfeed-toast') styleClass = true;

  @HostBinding('class.newsfeed-toast--has-content')
  hasContent = false;

  @HostBinding('class.newsfeed-toast--high-priority')
  hasHighPriority = false;

  private _newsfeedMessage: AppNewsfeedMessage;

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  dismiss(e: Event) {
    e.stopPropagation();
    this.toastPackage.triggerAction({action: "dismiss", message: this._newsfeedMessage});
    this.remove();
  }

  @HostListener('click', ['$event'])
  read(e: Event) {
    e.stopPropagation();
    this.toastPackage.triggerAction({action: "read", message: this._newsfeedMessage});
    this.remove();
  }

  private listenToLinkClicks() {
    setTimeout(() => {
      const linkElements = this._elRef.nativeElement.querySelector("a");

      if (linkElements) {
        this._renderer.listen(linkElements, "click", (e: Event) => e.stopPropagation())
      }
    });
  }
}