import { Routes } from "@angular/router"

import { RecipientsListComponent } from "./components/recipients-list.component"
import { RecipientComponent } from "./components/recipient.component"
import { RecipientsComponent } from "./components/recipients.component"
import { AuthorizationGuard } from "../shared/authorization-guard"
import { UnsavedChangesGuard } from "../shared/unsaved-changes-guard"

export const RECIPIENTS_ROUTES: Routes = [
  {
    path: "recipients",
    component: RecipientsComponent,
    canActivate: [AuthorizationGuard],
    children: [
      { path: "", component: RecipientsListComponent, canActivate: [AuthorizationGuard] },
      { path: ":recipientId", component: RecipientComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] }
    ]
  }
];