<div class="login-page">
  <div class="login-container">
    <div class="content-part login-part" *ngIf="showLoginForm">
      <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
      <shp-login (processLogin)="processLogin($event)" [login]="login" [exception]="exception"></shp-login>
    </div>
    <div class="content-part login-part first-run" *ngIf="!showLoginForm">
      <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
      <h1>{{ 'welcome' | shpLocalize }}</h1>
      <div class="error" *ngIf="exception != null">{{ exception | shpExceptionInfo }}</div>
      <div class="">
        <div class="ctl">
          <label for="selected-file">{{ 'configuration_file' | shpLocalize }}</label>
          <div class="file-input-group">
            <input id="input-filename" type="text" [(ngModel)]="selectedFileName" name="selectedFileName" readonly="readonly" />
            <input type="file" name="selectedFile" id="selected-file" (change)="selectedFileChanged($event)" />
            <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
          </div>
        </div>
        <div class="ctl">
          <label for="selected-file-password">{{ 'password' | shpLocalize}}</label>
          <input type="password" [(ngModel)]="filePassword" name="filePassword" id="selected-file-password" />
        </div>
        <div class="buttons">
          <button id="button-upload" (click)="uploadConfigurationFile()">{{ 'upload_configuration_file' | shpLocalize }}</button>
        </div>
      </div>

      <div class="">
        <div class="buttons">
          <button id="button-run-demo" (click)="runDemoMode()">{{ 'run_demo' | shpLocalize }}</button>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="links && links.length > 0" class="login-page-links">
    <ul>
      <li *ngFor="let link of links"><a href="{{ link.url }}" target="{{ link.openInNewWindow ? '_blank' : '' }}">{{ link.displayName }}</a></li>
    </ul>
  </div>
</div>