<div class="app-brief-info">
  <span *ngIf="!isSophia">Shipper {{ appVersion }}</span>

  <span *ngIf="isNewVersionAvailable"
        class="new-verion-alert-icon"
        title="{{ 'new_version_available' | shpLocalize }}">!</span>

  <span *ngIf="isUpdateAvailable"
        class="update-alert-icon"
        title="{{ 'shipper_update_available' | shpLocalize:updateInfo.version }}"
        (click)="showUpdateInfoDialog()">
    <span class="ic-arrow-shift"></span>
  </span>

  <div *ngIf="!isConnected"
       class="connection-lost-popup">
    <div class="connection-lost-message">
      <span class="ic-network-block"></span>
      {{disconnectedMessage}}
    </div>
  </div>
</div>