
<form [formGroup]="form">
  <ng-container formGroupName="profile">
    <div class="ctl">
      <label>{{ 'include_header_in_exported_file' | shpLocalize }}</label>
      <input type="checkbox" formControlName="fileIncludesHeader" id="include-header-in-exported-file">
      <label for="include-header-in-exported-file"></label>
    </div>

    <div *ngIf="!isCentral" class="ctl">
      <label>{{ 'generate_sem_file' | shpLocalize }}</label>
      <input type="checkbox" formControlName="generateSemFile" id="generate-sem-file">
      <label for="generate-sem-file"></label>
    </div>

    <h2 class="parcel-csv-profile-fields__heading my-3 ml-2">{{ 'custom_export_file_content' | shpLocalize }}</h2>

    <div class="ctl">
      <label>{{ 'enable_custom_export_file_content' | shpLocalize }}</label>
      <input type="checkbox" formControlName="shouldUseFields" id="should-use-fields">
      <label for="should-use-fields"></label>
    </div>
  </ng-container>

  <ng-container *ngIf="isFieldSectionDisplayed">
    <ul
      formArrayName="fields"
      dnd-sortable-container
      [sortableData]="fieldsControl.controls"
      class="parcel-csv-profile-fields">
      <li
        *ngFor="let field of fieldsControl.controls; index as i; first as isFirst; last as isLast"
        [formGroupName]="i"
        dnd-sortable
        [sortableIndex]="i"
        (onDragEnd)="onOrderChange()"
        class="parcel-csv-profile-fields__field">
        <div class="d-flex align-items-end">
          <div class="parcel-csv-profile-fields__control">
            <label *ngIf="isFirst" class="w-auto">{{ 'field' | shpLocalize }}</label>
            <select formControlName="fieldName" class="w-100">
              <option [ngValue]="null"> - </option>
              <option *ngFor="let field of parcelExportFields" [ngValue]="field">{{ field }}</option>
            </select>
          </div>

          <div class="parcel-csv-profile-fields__control ml-2">
            <label *ngIf="isFirst" class="w-auto">{{ 'export_as' | shpLocalize }}&nbsp;<span class="ic-interface-question-mark" [pTooltip]="'help_export_as' | shpLocalize"></span></label>
            <input type="text" class="w-100" formControlName="exportAs">
          </div>

          <div class="parcel-csv-profile-fields__control ml-2">
            <label *ngIf="isFirst" class="w-auto">{{ 'value' | shpLocalize }}&nbsp;<span class="ic-interface-question-mark" [pTooltip]="'help_export_value' | shpLocalize"></span></label>
            <input type="text" class="w-100" formControlName="exportValue">
          </div>

          <button type="button" class="parcel-csv-profile-fields__button" (click)="insertField(i + 1)" title="{{ 'action_insert_field' | shpLocalize }}"><span class="ic-add-2"></span></button>
          <ng-container *ngIf="fieldsControl.controls.length > 1">
            <button type="button" class="ml-2 parcel-csv-profile-fields__button" (click)="removeField(i)" title="{{ 'action_remove_field' | shpLocalize }}"><span class="ic-bin"></span></button>
            <div class="ml-2 parcel-csv-profile-fields__button" dnd-sortable-handle title="{{ 'action_change_order_drag' | shpLocalize }}"><span class="ic-cursor-move-vertical"></span></div>
          </ng-container>
        </div>
        <div *ngIf="!isLast" class="parcel-csv-profile-fields__insert">
          <!-- <button type="button" class="parcel-csv-profile-fields__button parcel-csv-profile-fields__button--insert" (click)="insertField(i + 1)" title="{{ 'action_insert_field' | shpLocalize }}"><span class="ic-add-2"></span></button> -->
        </div>
      </li>
    </ul>
    <!-- <button type="button" class="parcel-csv-profile-fields__button mb-2" (click)="insertField(fieldsControl.controls.length + 1)" title="{{ 'action_insert_field' | shpLocalize }}"><span class="ic-add-2"></span></button> -->
  </ng-container>
</form>
