import { Component, OnInit } from "@angular/core"

import { filter } from "rxjs/operators";

import { DialogRef, ModalComponent } from "ngx-modialog-7";
import { saveAs } from "file-saver";

import * as RecipientModels from "../models/recipient.model"
import { RecipientsService } from "../services/recipients.service"
import * as Shared from "../../shared/index"

@Component({
  selector: Shared.SELECTOR_PREFIX + "-recipients-import-dialog",
  templateUrl: "./recipients-export-dialog.component.html"
})
export class RecipientsExportDialogComponent implements ModalComponent<RecipientModels.ExportDialogModalContext>, OnInit {
  constructor(
    public dialog: DialogRef<RecipientModels.ExportDialogModalContext>,
    private _loggingService: Shared.LoggingService,
    private _recipientsService: RecipientsService,
    private _localizationService: Shared.LocalizationService,
    private _context: Shared.ContextService
  ) {
    this.isExportingAll = !dialog.context.recipientIds;
  }


  public isExportingAll = false;


  public isBusy = false;
  public statusMessage = "";


  private _customerDetailId: number = null;
  private _addressId: number = null;


  /**
  * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
  */
  public beforeDismiss(): boolean | Promise<boolean> { return true; }


  /**
   * Vracia, či nie je možné dialóg zatvoriť cez .close().
   */
  public beforeClose(): boolean | Promise<boolean> { return false; }


  public exportFailed = false;


  /**
   * Záznam z importu (aj úspešné hlášky).
   */
  public logEntries: Shared.LogEntry[] = [];


  public export = (type: string) => {

    this.isBusy = true;
    this.exportFailed = false;

    this._recipientsService.exportReciepients({
      recipientIds: this.dialog.context.recipientIds,
      customerDetailId: this._customerDetailId,
      pickupAddressId: this._addressId
    },
      type)
      .subscribe(exportModel => {
        this.logEntries = exportModel.logEntries;
        this.isBusy = false;

        // Máme aj obsah... Asi...
        if (exportModel.outputText && exportModel.outputText.length > 0) {
          // Musíme vygenrovať BLOB a uložiť ho.
          var blob: Blob;
          var filename: string;

          if (type.toLowerCase() == "xml") {
            filename = "recipients.xml";
            blob = new Blob(["\ufeff", exportModel.outputText], { type: "text/xml;charset=utf-8" });
          } else {
            filename = "recipients.csv";
            blob = new Blob(["\ufeff", exportModel.outputText], { type: "text/csv;charset=utf-8" });
          }

          saveAs(blob, filename);
        }

        this.dialog.close();
      },
        ex => {
          this._loggingService.logErrorData(ex, "Recipients export failed.");
          this.exportFailed = true;
          this.isBusy = false;
        });
  }


  public close = () => {
    this.dialog.close();
  }


  public ngOnInit() {
    this._context.currentAddress.pipe(
      filter(a => a != null)
    ).subscribe(a => {
      this._customerDetailId = a.customerDetailId;
      this._addressId = a.id;
    });
  }
}