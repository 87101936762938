import { ModalOpenContext } from "ngx-modialog-7";
import { ShipperObjectDataParserSettings } from "../../shared/models/shipper-object-data-parser-settings.model";
import { LogEntry } from "../../shared/models/log-entry.model";
import { PagedResult } from "../../shared/models/paged-result.model";
import { EntityDataBaseInteractive, Country } from "../../shared/index";
import { Product } from "../../shared/models/config.models";
import { ImportProfile } from "../../import-profiles/models/import-profiles.models";
import { Invoice, InvoiceLine } from "./custom-clearance.model";


export interface ShipmentsCompatViewSearchParameters {
  filter: string;
  orderAscending: boolean;
  orderByFieldName: string;
  pageIndex: number;
  pageSize: number;
  customerDetailId: number;
  customerAddressCustomId: string;

  isStateInPreparationVisible: boolean;
  isStateInLabelsPrintedVisible: boolean;
  isStatePreparedForDeliveryVisible: boolean;
  isStateSentToDPDVisible: boolean;
  isCollectionRequest: boolean;
  isNormalRequest: boolean;
  isStateDeletedVisible?: boolean;
  isExported: boolean | null;
}


export interface SenderScanSearchParameters {
  filter: string;
  parcelReference: string;
  recipientReference: string;
  orderAscending: boolean;
  orderByFieldName: string;
  pageIndex: number;
  pageSize: number;
  customerDetailId: number;
  customerAddressCustomId: string;

  isStateInPreparationVisible: boolean;
  isStateInLabelsPrintedVisible: boolean;
  isStatePreparedForDeliveryVisible: boolean;
  isStateSentToDPDVisible: boolean;
  isCollectionRequest: boolean;
  isNormalRequest: boolean;
  isExported: boolean | null;
}


export interface Order extends EntityDataBaseInteractive {
  senderId: number;
  receiverId: number;
  receiverPersonId: number;
  orderingDepotCode: string;
  collectingDepotCode: string;
  targetDepotCode: string;
  serviceSetCode: string;
  serviceSetId: number;
  productId: number;
  serviceSetASCode: string;
  serviceSetASId: number;
  orderNr: string;
  webOrderNr: string;
  customerNr: string;
  customerReferenceNr: string;
  customerDelisId: string;
  parcelLabelNumber: string;
  statusCode: string;
  cancellationDigit: string;
  originalOrderDate: Date;
  originalCreationDate: Date;
  customsStatusCode: string;
  pickupTypeId: number;
  pickupOrderDate: Date;
  isPickupDateValidationRequested: boolean;
  pickupDate: Date;
  pickupDateWithOffset: Date;
  lastPickupTime: Date;
  collectingRangeId: number;
  pickupAddressId: number;
  tourId: number;
  quantity: number;
  weight: number;
  content: string;
  day: number;
  fromTime1: Date;
  toTime1: Date;
  fromTime2: Date;
  toTime2: Date;
  dispSys: string;
  partnerReference1: string;
  partnerReference2: string;
  partnerReference3: string;
  partnerReference4: string;
  requiresSenderConfirmation: boolean;
  cancellationUserTypeId: number;
  customerAddressCustomId: string;
  shipperTenantId: number;
  isCollectionRequest: boolean;
  stateId: string;
  parcelsCount: number;
  deliveryWindowId: number;
  isCodAmountSetSeparatelyForEachParcel?: boolean;
  isReturnShipment: boolean;
  shipmentProductDynamicFieldsOptions: ShipmentProductDynamicFieldOption[];
}


export interface OrderForGridView extends Order {
  servicesText: string;
  parcels: Parcel[];
  receiverName: string;
  receiverContactPerson: string;
  receiverStreet: string;
  receiverStreetDetail: string;
  receiverZip: string;
  receiverCity: string;
  receiverCountryName: string;
  receiverEmail: string;
  receiverReference: string;
  receiverPhone: string;
  senderName: string;
  senderContactPerson: string;
  senderStreet: string;
  senderStreetDetail: string;
  senderZip: string;
  senderCity: string;
  senderCountryName: string;

  productName: string;
  additionalServiceNames: string[];


  areAllLabelsGenerated: boolean;
  areAllAcceptanceProtocolsGenerated: boolean;

  isCustomsClearance: boolean;

  isSelected: boolean;

  hasCod: boolean;

  exportDateTimeUtc: string;
}


export interface Parcel extends EntityDataBaseInteractive {
  shipmentId: number;
  senderId: number;
  receiverId: number;
  receiverPersonId: number;
  serviceSetCode: string;
  serviceSetId: number;
  serviceSetASCode: string;
  serviceSetASId: number;
  parcelNr: string;
  parentParcelId: number;
  referentialInfo1: string;
  referentialInfo2: string;
  referentialInfo3: string;
  referentialInfo4: string;
  partnerReferentialInfo1: string;
  partnerReferentialInfo2: string;
  partnerReferentialInfo3: string;
  partnerReferentialInfo4: string;
  parcelRank: number;
  receiverReference: string;
  volume: string;
  declaredWeight: number;
  measuredWeight: number;
  insuranceAmount: number;
  insuranceCurrencyCode: string;
  insuranceParcel: string;
  isHazardousParcel: boolean;
  hazardousPackingCode: string;
  content: string;
  originParcelNr: string;
  ownerBusinessUnit: string;
  partnerCode: string;
  bagNr: string;
  isDummy: boolean;
  stateId: string;
  serviceText: string;
  isLabelGenerated: boolean;
  isAcceptanceProtocolGenerated: boolean;
  isNotificationGenerated: boolean;
  customsTariffNumber: string;
  itemsNumber: number;
  customTransportCost: number;
  customTransportCostCurrencyCode: string;
}


export interface ParcelForGridView extends Parcel {
  pickupDate: Date;
  codAmount: number;
  receiverName: string;
  receiverStreet: string;
  receiverZip: string;
  receiverCity: string;
  receiverCountryName: string;
  senderName: string;
  senderStreet: string;
  senderZip: string;
  senderCity: string;
  senderCountryName: string;
  isIdCheckRequired: boolean;
  shipmentParcelsCount: number;
  orderId: number;
  orderStateId: string;
  serviceAbbr: string;
  shipmentReferentialInfo1: string;
  shipmentReferentialInfo2: string;
  shipmentReferentialInfo3: string;
  shipmentReferentialInfo4: string;
  width: number;
  height: number;
  length: number;
  note: string;
  codPurpose: string;
  isCollectionRequest: boolean;
  customerReferenceNr: string;
  isCustomsClearance: boolean;
}


export interface ParcelsFilter {
  pickupDate: Date;
  serviceText: string;
  serviceCode: string;
  parcelNr: string;
  recipientsAddress: string;
  reference: string;
  weightFrom: number;
  weightTo: number;
  codFrom: number;
  codTo: number;
  customerDetailId: number;
  customerAddressCustomId: string;
  pickupDateFrom: Date;
  pickupDateTo: Date;
  creationDateFrom?: Date;
  creationDateTo?: Date;
  showCollectionRequest: boolean;
  showNormalRequest: boolean;

  isStateInPreparationVisible: boolean;
  isStateInLabelsPrintedVisible: boolean;
  isStatePreparedForDeliveryVisible: boolean;
  isStateSentToDPDVisible: boolean;
  isStateDeletedVisible?: boolean;
  isExported: boolean | null;

  senderAddress: string;
  shipmentReference: string;
  lengthFrom: number;
  lengthTo: number;
  widthFrom: number;
  widthTo: number;
  heightFrom: number;
  heightTo: number;
  note: string;
  codPurpose: string;
}


export interface ParcelsGridViewSearchParameters extends ParcelsFilter {
  orderByFieldName: string;
  orderAscending: boolean;
  pageIndex: number;
  pageSize: number;
}


export interface Sender extends EntityDataBaseInteractive {
  customerId: number;
  customerInvoicingNr: string;
  customerInvoicingNrSubId: number;
  addressId: number;
  geoPostGroupCustomerId: string;
  accountOwner: string;
  vatNumber: string;
  eoriNumber: string;
  invoiceAddressId: number;
  webSite: string;
  destinationCountryRegistration: string;
}


export interface Receiver extends EntityDataBaseInteractive {
  geoPostGroupCustomerId: string;
  addressId: number;
  pudoId: string;
  customerInvoicingNr: string;
  shipperRecipientReferenceNumber: string;
  vatNumber: string;
  eoriNumber: string;
  invoiceAddressId: number;
}


export interface ReceiverPerson extends EntityDataBaseInteractive {
  deliveryTypeId: number;
  personName: string;
  personPhone1: string;
  personPhone2: string;
  personIdNumber: string;
  receiverDepotCode: string;
  addressId: number;
}


export interface Cod extends EntityDataBaseInteractive {
  parcelId: number;
  amount: number;
  currencyCode: string;
  collectionTypeId: number;
  purpose: string;
  sbkCode: string;
  sbkName: string;
  accountNr: string;
  accountName: string;
  iban: string;
  bic: string;
  bankAccountCustomId: string;
}


export interface SwapParcel extends EntityDataBaseInteractive {
  parcelId: number;
  parcelNr: string;
  serviceSetCode: string;
  serviceSetId: number;
  swapTypeCode: string;
  senderId: number;
  receiverId: number;
  receiverPersonId: number;
  note: string;
}


/**
 * "��seln�k" akci� pre zoznam z�sielok.
 */
export class Actions {
  public static get PrintLabels() { return "print_labels"; }
  public static get PrintAcceptanceProtocol() { return "print_acceptance_protocol"; }
  public static get PrintCodList() { return "print_cod_list"; }
  public static get Delete() { return "delete"; }
  public static get Export() { return "export"; }
}


/**
 * Zjednodu�en� model pre edit�ciu atrib�tov z�sielky v r�mci objedn�vky.
 */
export interface OrderEditorParcel {
  /**
   * Identifik�tor bal�ka, ak u� nejak� m� pridelen�.
   */
  id: number;


  /**
   * ��slo bal�ka pridelen� syst�mom.
   * Kop�rujeme ho sem, aby sme to vedeli zobrazi� v UI pri edit�cii
   * existuj�cej objedn�vky.
   */
  nr: string;


  invoiceLineId?: number;


  weight: number;


  width: number;
  height: number;
  length: number;


  referentialInfo1: string; // Max. 35 znakov.
  referentialInfo2: string; // Max. 35 znakov.
  referentialInfo3: string; // Max. 35 znakov.
  referentialInfo4: string; // Max. 35 znakov

  content: string;
  customsTariffNumber: string;
  amountLine: string;
  originCountryCode: string;
  receiverCustomsTariffNumber: string;
  grossWeight: number;
  internalCustomerProductCode: string;
  itemsNumber: number;
  customTransportCost: number;
  customTransportCostCurrencyCode: string;

  limitDate: Date;
  tempMin: number;
  tempMax: number;
  description: string;
}


export interface AdditionalServicesCalculatorInput {
  originDepotCode: string;
  originSubcode: string;
  destinationCountryCode: string;
  destinationPostCode: string;
  destinationCity: string;
  destinationPudoId: number;
  destinationPudoCode: string;
  customerDetailId: number;
  isCleared: boolean;
  dateUtc: Date;
  requestedProduct: Product;
  findProduct: boolean;
  requestedAdditionalServices: Product[];
  deliveryWindowId: number;
  isCollectionRequest: boolean;
  collectingAddressCountryCode: string;
  collectingAddressZip: string;
}


export interface AdditionalServicesCalculatorParameters extends AdditionalServicesCalculatorInput {
  senderCountryCode: string;
  senderPostCode: string;
}


export interface CodData {
  collectionTypeId: number;
  amount: number;
  purpose: string;
  currency: string;
}


export class CollectionType {
  public static Cash = 0;
  public static Card = 2;
}


export interface SwapData {
  type: string;
  note: string;
  parcelsCount: number;
}


export interface IDCheckData {
  idCardNumber: string;
  name: string;
}


export interface DeliveryPlan extends EntityDataBaseInteractive {
  shipmentId: number;
  weekDay: string;
  deliveryDateFrom: Date;
  deliveryDateTo: Date;
  timeFrameFrom: Date;
  timeFrameTo: Date;
  originDateTime: Date;
  bookingNumber: string;
  podInfo1: string;
  podInfo2: string;
  podInfo3: string;
  podInfo4: string;
  podInfo5: string;
  customerInfo1: string;
  customerInfo2: string;
  customerInfo3: string;
  customerInfo4: string;
  customerInfo5: string;
}


export interface Shipment extends EntityDataBaseInteractive {
  orderId: number;
  mpsId: string;
  parentShipmentId: number;
  customsReference: string;
  isCommunalDeliveryRequested: boolean;
  referentialInfo1: string;
  referentialInfo2: string;
  referentialInfo3: string;
  referentialInfo4: string;
  billingReference: string;
  parcelsCount: number;
  volume: number;
  weight: number;
  sendingDepotCode: string;
  shipmentCreationDateTimeUtc: Date;
  originCode: string;
  receiverDepotCode: string;
  customerDelisId: string;
  customerBillingReference: string;
  expectedPickupDateTime: Date;
  pickupDateTime: Date;
  deliveryInstructionModificationAllowCode: number;
  routingPlanVersion: string;
  partnerShipmentReference1: string;
  partnerShipmentReference2: string;
  partnerCode: string;
  isCollectionRequest: boolean;
  isComplete: boolean;
  isDummy: boolean;
  geoRoutingDSort: string;
  geoRoutingPlanVersion: string;
  geoRoutingOSort: string;
  geoRoutingSSort: string;
  geoRoutingMSort: string;
  geoRoutingPlace: string;
  stateId: string;
  destinationBusinessUnitCode: string;
  destinationCountryCode: string;
  destinationNetworkCode: string;
  receiverDepotLabel: string;
  geoRoutingCSort: string;
  barcodeId: string;
  geoRoutingVersionNumber: string;
  shipmentTypeCode: string;
  incoTermCode: string;
  clearanceClearedCode: string;
  invoiceShipment: boolean;
  prealertStatusCode: string;
  hasAccompanyingDocs: boolean;
}

export interface ShipmentAccompanyingDoc extends EntityDataBaseInteractive {
  shipmentId: number;
  accompanyingDocTypeCode: string;
}


export interface Address extends EntityDataBaseInteractive {
  companyName: string;
  companyName2: string;
  name1: string;
  name2: string;
  name3: string;
  name4: string;
  street: string;
  houseNr: string;
  addressLine2: string;
  addressLine3: string;
  floor: string;
  building: string;
  department: string;
  countryCode: string;
  stateCode: string;
  zip: string;
  city: string;
  doorCode: string;
  contactPerson: string;
  phone: string;
  phone2: string;
  interphoneName: string;
  fax: string;
  email: string;
  comment: string;
  latitude: number;
  longitude: number;
  zoneId: number;
  languageCode: string;
  localizationHint: string;
  district: string;
  globalLocationNumber: number;

  country?: Country;
}


export interface Goods extends EntityDataBaseInteractive {
  parcelId: number;
  goodsTypeId: number;
  goodsFeaturesId: number;
  limitDate: Date;
  minTemperature: number;
  maxTemperature: number;
  temparatureUnit: string; // This should actually by number, but there is error in SKDATA2 dta model.
  specificHandlingInstructionId: number;
  description: string; // Max. 100 characters.
  isCreatedInSophia: boolean;
}


export interface NewOrderModel {
  order: Order;
  shipment: Shipment;
  shipmentAccompanyingDocs: ShipmentAccompanyingDoc[];
  parcels: Parcel[];
  goods: Goods[];
  invoice: Invoice;
  invoiceLines: InvoiceLine[];
  senderCustomerDetailId: number;
  hasExternalSender: boolean;
  senderInvoiceAddress: Address;
  senderEoriNumber: string;
  senderVatNumber: string;
  senderWebsite: string;
  senderDestinationCountryRegistration: string;
  senderDelisId: string;
  senderInvoicingNr: string;
  receiver: Receiver;
  receiverAddress: Address;
  receiverInvoiceAddress: Address;
  receiverPerson: ReceiverPerson;
  receiverPersonAddress: Address;
  cod: Cod;
  cods: Cod[];
  deliveryPlan: DeliveryPlan;
  swaps: SwapParcel[];
  swapReceivers: Receiver[];
  swapReceiverAddresses: Address[];
  additionalServiceIds: number[];
  collectingAddress: Address;
  isCollectionRequestSentToMe: boolean;
  returnReceiverAddress: Address;
  hasCustomSenderAddress: boolean;
  senderAddress: Address;
  deliveryWindowId: number | null;
  sendCustomerPersonalizedNotification: boolean;
  customerPersonalizedNotificationLanguageCode: string;
  customerPersonalizedNotificationRecipients: string;
  sendReturnLabelByEmail: boolean;
  excludeReturnLabelFromLabelPrinting: boolean;
  saveAddressToAddressBook: boolean;
  shipmentProductDynamicFieldsOptions?: ShipmentProductDynamicFieldOption[];
}


export interface ShipmentLabelsPrintRequestParameters {
  customerDetailId: number;
  orderIds: number[];
  parcelIds: number[];
  senderAddressId: number;
  onlyCheckIfRequiresPositionSpecification: boolean;
  requestedPrintingStartPositionOnA4: number;
}


export interface ShipmentsImportParametersModel {
  settings: ShipmentDataParserSettings;
  content: string;
  isValidation: boolean;
}


export interface ShipmentDataParserSettings extends ShipperObjectDataParserSettings {
  tenantId: number;
  customerDetailId: number;
  senderCustomId: string;
  daysOffset: number;
  rememberShipmentsWereExported: boolean;
  exportAllShipments: boolean;
  manualExportShipmentsSetType: string;
  orderIds: number[];
  parcelIds: number[];
  importProfileId: number;
  csvRowDelimiter: string;
  csvColumnDelimiter: string;
  defaultProductId?: number;
}

export interface ArchiveShipmentDataParserSetting extends ShipmentDataParserSettings {
  dateFrom: Date;
  dateTo: Date;
}


export interface ImportOutput {
  errorFileName: string;
  isInQueue: boolean;
  isProcessFinishedWithError: boolean;
  itemsProcessedSuccessfullyCount: number;
  itemsProcessedWithErrorCount: number;
  logEntries: LogEntry[];
}


export class ShipmentsImportDialogModalContext extends ModalOpenContext {
  constructor(
    public customerDetailId: number,
    public addressCustomId: string,
    public tenantId: number,
    public importProfiles: ImportProfile[],
    public products: Product[],
    public defaultProductId: number
  ) { super(); }
}


export interface ExportModel {
  outputText: string;
  logEntries: LogEntry[];
}


export interface ExportFileListModel {
  outputFile: ExportFileModel[];
  logEntries: LogEntry[];
}


export interface ExportFileModel {
  outputText: string;
  outputName: string;
}


export interface ResponseBinaryFileModel {
  data: string;
  mediaType: string;
  encoding: string;
  fileName: string;
}


export interface PrintProtocolsResponse extends ExportFileListModel {
  protocolsFile: ResponseBinaryFileModel;
}


export interface OrderEditorModel {
  senderCustomerDetailId: number;
  hasExternalSender: boolean;
  order: Order;
  shipment: Shipment;
  parcels: Parcel[];
  goods: Goods[];
  senderDelisId: string;
  senderInvoicingNr: string;
  sender: Sender;
  senderAddress: Address;
  receiver: Receiver;
  receiverAddress: Address;
  receiverPerson: ReceiverPerson;
  receiverPersonAddress: Address;
  cod: Cod;
  cods: Cod[];
  deliveryPlan: DeliveryPlan;
  swaps: SwapParcel[];
  swapReceivers: Receiver[];
  swapReceiverAddresses: Address[];
  additionalServiceIds: number[];
  pickupAddress: Address;
  isCollectionRequestSentToMe: boolean;
  returnReceiverAddress: Address;
  hasCustomSenderAddress: boolean;
  deliveryWindowId: number;
  invoice: Invoice;
  invoiceLines: InvoiceLine[];
  shipmentAccompanyingDocs: ShipmentAccompanyingDoc[];
  senderInvoiceAddress: Address;
  receiverInvoiceAddress: Address;
  sendCustomerPersonalizedNotification: boolean;
  customerPersonalizedNotificationLanguageCode: string;
  customerPersonalizedNotificationRecipients: string;
  sendReturnLabelByEmail: boolean;
  excludeReturnLabelFromLabelPrinting: boolean;
  saveAddressToAddressBook: boolean;
  shipmentProductDynamicFieldOptions?: ShipmentProductDynamicFieldOption[];
}


export class SwapTypeCode {
  public static AB = "AB";
  public static ABC = "ABC";
}


export class ShipmentStates {
  public static new: string = "New";
  public static inPreparation: string = "InPreparation";
  public static labelsPrinted: string = "LabelsPrinted";
  public static readyForPickup: string = "ReadyForPickup";
  public static pickedUp: string = "PickedUp";
  public static transport: string = "Transport";
  public static partiallyDelivered: string = "PartiallyDelivered";
  public static delivered: string = "Delivered";
}


export interface CityCheckParameters {
  countryCode: string;
  cityName: string;
  postCode: string;
  refDate: Date;
}


export interface CityAndZipCheckResult {
  zipValidationRequirementTypeId: string;
  cityValidationRequirementTypeId: string;
  isCityValid: boolean;
  isZipValid: boolean;
  isZipInCity: boolean;
}

export interface NonWorkingDaysParameters{
  countryCode: string;
  postCode: string;
  month: number;
  year: number;
}

export interface NonWorkingDayModel{
  day: Date;
}


export interface SenderScanFormDefaults {
  senderScanValueType: string;
  senderScanActionType: string;
}


export function getEmptyAddress() {
  var address: Address = {
    id: 0,
    countryCode: "SK",
    city: "",
    creationDateTimeUtc: null,
    modificationDateTimeUtc: null,
    modifiedByUser: null,
    createdByUser: null,
    isNew: true,
    isPersistent: false,
    addressLine2: "",
    addressLine3: "",
    building: "",
    comment: "",
    companyName: "",
    companyName2: "",
    contactPerson: "",
    department: "",
    district: "",
    doorCode: "",
    email: "",
    fax: "",
    floor: "",
    globalLocationNumber: null,
    houseNr: "",
    interphoneName: "",
    languageCode: "",
    latitude: null,
    longitude: null,
    localizationHint: "",
    name1: "",
    name2: "",
    name3: "",
    name4: "",
    phone: "",
    phone2: "",
    stateCode: "",
    street: "",
    zip: "",
    zoneId: null
  };

  return address;
}


export class P0PropertiesRequestModel {
  public countryCode: string;
  public receiverAddress: Address;
  public businessUnitCode: string;
  public depotCode: string;
  public productId: number;
  public additionalServiceIds: number[];
}


export class SetPickupDateDialogModalContext extends ModalOpenContext {
  constructor(
    public orderIds: number[],
    public anyOrderIsCr: boolean
  ) { super(); }
}


export interface AcceptanceProtocolHistoryFilter {
  dateFrom?: string;
  dateTo?: string;
  protocolTypeId?: number;
  customerAddressId?: number;
}


export interface AcceptanceProtocolHistoryGridFilter extends AcceptanceProtocolHistoryFilter {
  orderAscending: boolean;
  orderByFieldName: string;
  pageIndex: number;
  pageSize: number;
}


export interface ProtocolType {
  id: number;
  isNew: boolean;
  isPersistent: boolean;
  name: string;
  resourceKey: string;
}


export interface AcceptanceProtocol {
  creationDateTimeUtc: string;
  date: string;
  id: number;
  protocolTypeId: number;
  protocolTypeName: string;
}


export interface AcceptanceProtocolGridView extends AcceptanceProtocol {
  isSelected: boolean;
}

export enum ShipmentType {
  NORMAL = 'NORMAL',
  CR = 'COLLECTON-REQUEST'
}

export interface FieldOption extends EntityDataBaseInteractive {
  productDynamicFieldId: number;
  titleSk: string;
  titleEn: string;
  titleNl: string;
  titleDe: string;
  titleBe: string;
  titleFr: string;
  titleIt: string;
  titleCs: string;
  optionValue: string;
}

export interface AdditionalField extends EntityDataBaseInteractive {
  productId: number;
  titleSk: string;
  titleEn: string;
  titleNl: string;
  titleDe: string;
  titleBe: string;
  titleFr: string;
  titleIt: string;
  titleCs: string;
  geodataFieldCode: string;
  fieldType: number;
  fieldOptions?: FieldOption[];
}

export interface ShipmentProductDynamicFieldOption extends EntityDataBaseInteractive {
  shipmentId: number;
  productDynamicFieldId: number;
  value?: string;
}
