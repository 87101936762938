<div class="content-panel parcels" wizardTag="shipmnetsOverview">
  <div class="grid">
    <shp-progress-overlay [isActive]="isBusy"
                          [message]="statusMessage"></shp-progress-overlay>
    <shp-retry-overlay [isVisible]="loadingErrorOccured"
                       errorMessage="{{ 'parcels_loading_error' | shpLocalize }}"
                       [reloadFunction]="applyFilter"></shp-retry-overlay>
    <div id="shipments-filter">
      <div id="left">
      </div>
      <div id="center">
        <div class="ctl">
          <input id="shipment_filter_in_preparation"
                 name="shipment_filter_in_preparation"
                 type="checkbox"
                 [(ngModel)]="filter.isStateInPreparationVisible"
                 (ngModelChange)="filterValueChanged()">
          <label for="shipment_filter_in_preparation">{{ 'shipment_filter_in_preparation' | shpLocalize }}</label>

          <input *ngIf="!isSophia && !isCustomerService"
                 id="shipment_filter_labels_printed"
                 name="shipment_filter_labels_printed"
                 type="checkbox"
                 [(ngModel)]="filter.isStateInLabelsPrintedVisible"
                 (ngModelChange)="filterValueChanged()">
          <label *ngIf="!isSophia && !isCustomerService"
                 for="shipment_filter_labels_printed">{{ 'shipment_filter_labels_printed' | shpLocalize }}</label>

          <input *ngIf="!isSophia"
                 id="shipment_filter_prepared_for_delivery_or_send"
                 name="shipment_filter_prepared_for_delivery_or_send"
                 type="checkbox"
                 [(ngModel)]="deliveryStateFilter"
                 (ngModelChange)="filterValueChanged()">
          <label *ngIf="!isSophia"
                 for="shipment_filter_prepared_for_delivery_or_send">{{ 'shipment_filter_prepared_for_delivery_or_send' | shpLocalize }}</label>

          <input *ngIf="!isSophia"
                 id="shipment_filter_exported"
                 name="shipment_filter_exported"
                 type="checkbox"
                 [(ngModel)]="exportedFilter"
                 (ngModelChange)="exportedFilterChange()"/>
          <label *ngIf="!isSophia"
                 for="shipment_filter_exported">{{ 'shipment_filter_exported' | shpLocalize }}</label>

          <input *ngIf="!isSophia"
                 id="shipment_filter_not_exported"
                 name="shipment_filter_not_exported"
                 type="checkbox"
                 [(ngModel)]="notExportedFilter"
                 (ngModelChange)="exportedFilterChange()"/>
          <label *ngIf="!isSophia"
                 for="shipment_filter_not_exported">{{ 'shipment_filter_not_exported' | shpLocalize }}</label>

          <!--<ng-container *ngIf="isImpersonatingShipperAdminUser">
            <input id="shipment_filter_deleted" name="shipment_filter_deleted" type="checkbox" [(ngModel)]="filter.isStateDeletedVisible" (ngModelChange)="exportedFilterChange()" />
            <label for="shipment_filter_deleted">{{ 'shipment_filter_deleted' | shpLocalize }}</label>
          </ng-container>-->
        </div>
      </div>
      <div id="right">
        <div class="ctl" *ngIf="anyCRExists && !isCustomerService">
          <input id="shipment_filter_collection_request" name="shipment_filter_collection_request" type="checkbox" [(ngModel)]="filter.showCollectionRequest" (ngModelChange)="exportedFilterChange()"/>
          <label for="shipment_filter_collection_request">{{ 'shipment_filter_collection_request' | shpLocalize }}</label>
          <input id="shipment_filter_normal_request" name="shipment_filter_normal_request" type="checkbox" [(ngModel)]="filter.showNormalRequest" (ngModelChange)="exportedFilterChange()"/>
          <label for="shipment_filter_normal_request">{{ 'shipment_filter_normal_request' | shpLocalize }}</label>
        </div>
      </div>
    </div>
    <form class="grid-header" #header (submit)="applyFilter()" name="filterForm">
      <table appTable [displayColumns]="displayColumns">
        <thead>
        <tr appRow>
          <th *appColumn="'select'"
              class="col-selector">
            <button *ngIf="isShipmentGridSettingsVisible"
                    class="table-settings-btn"
                    type="button"
                    (click)="openColumnSettings()"><span class="ic-cog-box"></span></button>
          </th>
          <th *appColumn="'pickupDate'"
              class="col-date-2 can-order"
              (click)="setOrderBy('PickupDate')"
              [class.is-order]="orderBy == 'PickupDate'"
              [class.ascending]="orderAscending">{{ 'shipment_order_pickup_date' | shpLocalize }}</th>
          <th *appColumn="'creationDateTimeUtc'"
              class="col-date-2 can-order"
              (click)="setOrderBy('CreationDateTimeUtc')"
              [class.is-order]="orderBy == 'CreationDateTimeUtc'"
              [class.ascending]="orderAscending">{{ 'order_created_on' | shpLocalize }}</th>
          <th *appColumn="'serviceSetCode'"
              class="col-service can-order"
              (click)="setOrderBy('ServiceSetCode')"
              [class.is-order]="orderBy == 'ServiceSetCode'"
              [class.ascending]="orderAscending">{{ 'parcel_service_abbr' | shpLocalize }}</th>
          <th *appColumn="'parcelNr'"
              class="col-parcel-nr can-order"
              (click)="setOrderBy('ParcelNr')"
              [class.is-order]="orderBy == 'ParcelNr'"
              [class.ascending]="orderAscending"><span [innerHtml]="'parcel_number' | shpLocalize"></span></th>
          <th *appColumn="'receiver'"
              class="col-address can-order"
              (click)="setOrderBy('Receiver')"
              [class.is-order]="orderBy == 'Receiver'"
              [class.ascending]="orderAscending">{{ 'parcel_recipient_address' | shpLocalize }}</th>
          <th *appColumn="'referentialInfo'"
              class="col-reference can-order"
              (click)="setOrderBy('ReferentialInfo')"
              [class.is-order]="orderBy == 'ReferentialInfo'"
              [class.ascending]="orderAscending">{{ 'parcel_reference' | shpLocalize }}</th>
          <th *appColumn="'weight'"
              class="col-weight can-order"
              (click)="setOrderBy('Weight')"
              [class.is-order]="orderBy == 'Weight'"
              [class.ascending]="orderAscending">{{ 'parcel_weight' | shpLocalize }} (kg)
          </th>
          <th *appColumn="'cod'"
              class="col-cod-amount can-order"
              (click)="setOrderBy('Cod')"
              [class.is-order]="orderBy == 'Cod'"
              [class.ascending]="orderAscending">{{ 'parcel_cod_amount' | shpLocalize }}</th>
          <th *appColumn="'sender'"
              class="col-address can-order"
              (click)="setOrderBy('Sender')"
              [class.is-order]="orderBy == 'Sender'"
              [class.ascending]="orderAscending">{{ 'test_email_sender_address' | shpLocalize }}</th>
          <th *appColumn="'shipmentReference'"
              class="col-reference can-order"
              (click)="setOrderBy('ShipmentReference')"
              [class.is-order]="orderBy == 'ShipmentReference'"
              [class.ascending]="orderAscending">{{ 'shipment_reference' | shpLocalize }}</th>
          <th *appColumn="'length'"
              class="col-weight can-order"
              (click)="setOrderBy('Length')"
              [class.is-order]="orderBy == 'Length'"
              [class.ascending]="orderAscending">{{ 'parcel_length' | shpLocalize }} (cm)
          </th>
          <th *appColumn="'width'"
              class="col-weight can-order"
              (click)="setOrderBy('Width')"
              [class.is-order]="orderBy == 'Width'"
              [class.ascending]="orderAscending">{{ 'parcel_width' | shpLocalize }} (cm)
          </th>
          <th *appColumn="'height'"
              class="col-weight can-order"
              (click)="setOrderBy('Height')"
              [class.is-order]="orderBy == 'Height'"
              [class.ascending]="orderAscending">{{ 'parcel_height' | shpLocalize }} (cm)
          </th>
          <th *appColumn="'note'"
              class="col-service can-order"
              (click)="setOrderBy('Note')"
              [class.is-order]="orderBy == 'Note'"
              [class.ascending]="orderAscending">{{ 'note' | shpLocalize }}</th>
          <th *appColumn="'codPurpose'"
              class="col-service can-order"
              (click)="setOrderBy('CodPurpose')"
              [class.is-order]="orderBy == 'CodPurpose'"
              [class.ascending]="orderAscending">{{ 'cod_purpose' | shpLocalize }}</th>
          <th *appColumn="'commands'"
              class="col-commands"></th>
          <th *appColumn="'spacer'"
              class="col-spacer"></th>
        </tr>
        <!-- filtre -->
        <tr appRow
            class="filters">
          <th *appColumn="'select'"
              class="col-selector">
            <input type="checkbox"
                    id="select-all-toggle"
                    name="selectAll"
                    [ngModel]="areAllParcelsSelected"
                    (change)="toggleAllSelected()"/>
            <label for="select-all-toggle"
                    title="{{ 'select_all' | shpLocalize }}"></label>
          </th>
          <th *appColumn="'pickupDate'"
              class="col-date-2">
            <div class="filter">
              <div class="ctl">
                <div class="p-field">
                  <span class="p-float-label">
                    <p-calendar
                      [(ngModel)]="filter.pickupDateFrom"
                      (ngModelChange)="exportedFilterChange()"
                      name="pickupDateFromFilter"
                      [showIcon]="true"
                      inputId="pickup_filter_from"
                      dateFormat="dd/mm/yy"
                      icon="ic-calendar-1"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2010:{{currentYear}}"
                      appendTo="body"
                    ></p-calendar>
                    <label for="pickup_filter_from">{{ 'filter_from' | shpLocalize }}</label>
                  </span>
                </div>
                <div class="p-field">
                  <span class="p-float-label">
                    <p-calendar
                      [(ngModel)]="filter.pickupDateTo"
                      (ngModelChange)="exportedFilterChange()"
                      name="pickupDateToFilter"
                      [showIcon]="true"
                      inputId="filter_to"
                      dateFormat="dd/mm/yy"
                      icon="ic-calendar-1"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2010:{{currentYear}}"
                      appendTo="body"
                    ></p-calendar>
                    <label for="filter_to">{{ 'filter_to' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'creationDateTimeUtc'"
              class="col-date-2">
            <div class="filter">
              <div class="ctl">
                <div class="p-field">
                  <span class="p-float-label">
                    <p-calendar
                      [(ngModel)]="filter.creationDateFrom"
                      (ngModelChange)="exportedFilterChange()"
                      name="creationDateFromFilter"
                      [showIcon]="true"
                      inputId="creation_filter_from"
                      dateFormat="dd/mm/yy"
                      icon="ic-calendar-1"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2010:{{currentYear}}"
                      appendTo="body"
                    ></p-calendar>
                    <label for="creation_filter_from">{{ 'filter_from' | shpLocalize }}</label>
                  </span>
                </div>
                <div class="p-field">
                  <span class="p-float-label">
                    <p-calendar
                      [(ngModel)]="filter.creationDateTo"
                      (ngModelChange)="exportedFilterChange()"
                      name="creationDateToFilter"
                      [showIcon]="true"
                      inputId="creation_filter_to"
                      dateFormat="dd/mm/yy"
                      icon="ic-calendar-1"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2010:{{currentYear}}"
                      appendTo="body"
                    ></p-calendar>
                    <label for="creation_filter_to">{{ 'filter_to' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'serviceSetCode'"
              class="col-service">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-service-abbr" type="text" name="serviceCodeFilter" pInputText [(ngModel)]="filter.serviceCode" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-service-abbr">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'parcelNr'"
              class="col-parcel-nr">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-parcel-nr" type="text" name="parcelNrFilter" pInputText [(ngModel)]="filter.parcelNr" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-parcel-nr">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'receiver'"
              class="col-address">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-recipient-address" type="text" name="recipientsAddressFilter" pInputText [(ngModel)]="filter.recipientsAddress" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-recipient-address">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'referentialInfo'"
              class="col-reference">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-reference" type="text" name="referenceFilter" pInputText [(ngModel)]="filter.reference" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-reference">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'weight'"
              class="col-weight ">
            <div class="filter interval">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.weightFrom"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="weightFromFilter"
                      name="weightFromFilter"
                      mode="decimal"
                      autocomplete="off"></p-inputNumber>
                    <label for="weightFromFilter">{{ 'filter_from' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.weightTo"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="weightToFilter"
                      name="weightToFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="weightToFilter">{{ 'filter_to' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'cod'"
              class="col-cod-amount">
            <div class="filter interval">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.codFrom"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="codFromFilter"
                      name="codFromFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="codFromFilter">{{ 'filter_from' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.codTo"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="codToFilter"
                      name="codToFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="codToFilter">{{ 'filter_to' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'sender'"
              class="col-address">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-sender" type="text" name="senderAddressFilter" pInputText [(ngModel)]="filter.senderAddress" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-sender">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'shipmentReference'"
              class="col-service">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-shipment-reference" type="text" name="shipmentReferenceFilter" pInputText [(ngModel)]="filter.shipmentReference" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-shipment-reference">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'length'"
              class="col-weight ">
            <div class="filter interval">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.lengthFrom"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="lengthFromFilter"
                      name="lengthFromFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="lengthFromFilter">{{ 'filter_from' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.lengthTo"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="lengthToFilter"
                      name="lengthToFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="lengthToFilter">{{ 'filter_to' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'width'"
              class="col-weight ">
            <div class="filter interval">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.widthFrom"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="widthFromFilter"
                      name="widthFromFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="widthFromFilter">{{ 'filter_from' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.widthTo"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="widthToFilter"
                      name="widthToFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="widthToFilter">{{ 'filter_to' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'height'"
              class="col-weight ">
            <div class="filter interval">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.heightFrom"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="heightFromFilter"
                      name="heightFromFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="heightFromFilter">{{ 'filter_from' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-inputNumber
                      [(ngModel)]="filter.heightTo"
                      (ngModelChange)="filterValueChanged()"
                      [maxFractionDigits]="0"
                      inputId="heightToFilter"
                      name="heightToFilter"
                      mode="decimal"></p-inputNumber>
                    <label for="heightToFilter">{{ 'filter_to' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'note'"
              class="col-service">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-note" type="text" name="noteFilter" pInputText [(ngModel)]="filter.note" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-note">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'codPurpose'"
              class="col-service">
            <div class="filter">
              <div class="ctl">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <input id="input-filter-cod-purpose" type="text" name="codPurposeFilter" pInputText [(ngModel)]="filter.codPurpose" (ngModelChange)="filterValueChanged()" autocomplete="off">
                    <label for="input-filter-cod-purpose">{{ 'filter' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th *appColumn="'commands'"
              class="col-global-commands">
            <button id="button-filter-clear"
                    type="button"
                    name="clearFilter"
                    (click)="clearFilter()"><span class="ic-close"></span></button>
          </th>
          <th *appColumn="'spacer'"
              class="col-spacer"></th>
        </tr>
        </thead>
      </table>
    </form>
    <div class="scrollbar-spacer-cover"></div>
    <div class="grid-content"
         (scroll)="scrollGridContent()"
         #content>
      <div class="list-empty-message"
           *ngIf="totalParcelsCount === 0 && !isFiltered">
        <div class="message-content">
          {{ 'message_no_parcels_in_db' | shpLocalize }}
        </div>
      </div>

      <div class="list-empty-message"
           *ngIf="totalParcelsCount === 0 && isFiltered">
        <div class="message-content">
          {{ 'message_no_parcels_matching_filter' | shpLocalize }}
        </div>
      </div>

      <table appTable
             [displayColumns]="displayColumns">
        <tbody>
        <ng-container *ngFor="let parcel of parcels | async">
          <tr appRow
              class="{{ 'order-state-' + parcel.orderStateId }}"
              [class.label-printed]="!isSophia ? parcel.isLabelGenerated : false">
            <td *appColumn="'select'"
                class="col-selector relative">
              <div class="spread d-flex flex-direction-column">
                <div class="mb-auto">
                  <input type="checkbox"
                         [ngModel]="parcel.isSelected"
                         (change)="selectionChanged(parcel)"
                         id="is-selected-{{ parcel.id }}"/><label [attr.for]="'is-selected-' + parcel.id"></label>
                </div>
                <div *ngIf="parcel.exportDateTimeUtc"
                     class="order-exported-indicator"
                     title="{{ 'date_of_export' | shpLocalize }}: {{ parcel.exportDateTimeUtc | shpLocalizeDate }}">
                  <span class="ic-data-upload-8"></span>
                </div>
              </div>
            </td>
            <td *appColumn="'pickupDate'"
                class="col-date-2">
              {{ parcel.pickupDate | shpLocalizeDate:"L" }}
              <span *ngIf="isSophia"
                    [class.labels-printed]="parcel.orderStateId === 'LabelsPrinted'"
                    [class.ready-for-export]="parcel.orderStateId === 'ReadyForExport'"
                    [class.transport]="parcel.orderStateId === 'Transport' || parcel.orderStateId === 'Ordered' || parcel.orderStateId === 'Delivered' || parcel.orderStateId === 'Cancelled' || parcel.orderStateId === 'CollectionFailure' || parcel.orderStateId === 'Closed'"
                    class="state">{{ 'order_state_' + parcel.orderStateId | shpLocalize }}{{ isSophia && parcel.isLabelGenerated && parcel.orderStateId !== 'Ordered' ? ' (' + ('parcel_label_printed' | shpLocalize) + ')' : '' }}</span>
            </td>
            <td *appColumn="'creationDateTimeUtc'"
                class="col-date-2">
              {{ parcel.creationDateTimeUtc | shpLocalizeDate }}
            </td>
            <td *appColumn="'serviceSetCode'"
                class="col-service">{{ parcel.serviceCode }}</td>
            <td *appColumn="'parcelNr'"
                class="col-parcel-nr">
              <span *ngIf="parcel.isCollectionRequest">{{ 'collection_request_check_control' | shpLocalize }}</span>
              <a href="{{ getTrackingByParcelNrUrl(parcel.parcelNr) }}"
                                         target="_blank">{{ parcel.parcelNr }}</a><br/>{{ parcel.parcelRank }}
              /{{ parcel.shipmentParcelsCount }}</td>
            <td *appColumn="'receiver'"
                class="col-address">
              {{ parcel.receiverName }}<br/>
              {{ parcel.receiverStreet }}<br/>
              {{ parcel.receiverZip }} {{ parcel.receiverCity }}<br/>
              {{ parcel.receiverCountryName }}
            </td>
            <td *appColumn="'referentialInfo'"
                class="col-reference">
              <div *ngIf="parcel.customerReferenceNr">
                <a href="{{ getTrackingByReferenceUrl(parcel.customerReferenceNr) }}"
                   target="_blank">{{ parcel.customerReferenceNr }}</a>
              </div>
              <div *ngIf="parcel.referentialInfo1"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo1) }}"
                                                      target="_blank">{{ parcel.referentialInfo1 }}</a></div>
              <div *ngIf="parcel.referentialInfo2"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo2) }}"
                                                      target="_blank">{{ parcel.referentialInfo2 }}</a></div>
              <div *ngIf="parcel.referentialInfo3"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo3) }}"
                                                      target="_blank">{{ parcel.referentialInfo3 }}</a></div>
              <div *ngIf="parcel.referentialInfo4"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo4) }}"
                                                      target="_blank">{{ parcel.referentialInfo4 }}</a></div>
            </td>
            <td *appColumn="'weight'"
                class="col-weight">{{ parcel.declaredWeight / 100 | shpNumeral:"0,0.00" }}</td>
            <td *appColumn="'cod'"
                class="col-cod-amount">{{ parcel.codAmount | shpNumeral:"0,0.00" }}</td>
            <td *appColumn="'sender'"
                class="col-address">
              {{ parcel.senderName }}<br/>
              {{ parcel.senderStreet }}<br/>
              {{ parcel.senderZip }} {{ parcel.senderCity }}<br/>
              {{ parcel.senderCountryName }}
            </td>
            <td *appColumn="'shipmentReference'"
                class="col-reference">
              <div *ngIf="parcel.shipmentReferentialInfo1">{{parcel.shipmentReferentialInfo1}}</div>
              <div *ngIf="parcel.shipmentReferentialInfo2">{{parcel.shipmentReferentialInfo2}}</div>
              <div *ngIf="parcel.shipmentReferentialInfo3">{{parcel.shipmentReferentialInfo3}}</div>
              <div *ngIf="parcel.shipmentReferentialInfo4">{{parcel.shipmentReferentialInfo4}}</div>
            </td>
            <td *appColumn="'length'"
                class="col-weight">{{ parcel.length | shpNumeral:"0,0" }}</td>
            <td *appColumn="'width'"
                class="col-weight">{{ parcel.width | shpNumeral:"0,0" }}</td>
            <td *appColumn="'height'"
                class="col-weight">{{ parcel.height | shpNumeral:"0,0" }}</td>
            <td *appColumn="'note'"
                class="col-service">{{ parcel.note }}</td>
            <td *appColumn="'codPurpose'"
                class="col-service">{{ parcel.codPurpose }}</td>
            <td *appColumn="'commands'"
                class="col-commands">
              <ng-container *ngIf="parcel.orderStateId != 'Deleted'">
                <button id="button-edit"
                        type="button"
                        (click)="editShipmentOrder(parcel)"
                        title="{{ 'shipment_edit' | shpLocalize }}"><span class="ic-box-edit"></span></button>
                <button id="button-delete"
                        type="button"
                        (click)="deleteParcel(parcel)"
                        [disabled]="isSophia && !isImpersonatingShipperAdminUser && (parcel.isLabelGenerated || parcel.orderStateId=='Ordered')"
                        title="{{ 'shipment_delete' | shpLocalize }}"><span class="ic-bin"></span></button>
                <button id="button-print-labels"
                        type="button"
                        *ngIf="!parcel.isCollectionRequest"
                        (click)="printLabelsForParcel(parcel)"
                        title="{{ 'shipment_print_labels' | shpLocalize }}"><span class="ic-printer"></span></button>
                <button id="button-miniportal"
                        type="button"
                        *ngIf="!parcel.isCollectionRequest && parcel.isCustomsClearance && this.useMiniportalIntegration && this.isCentralShipper && parcel.orderStateId != 'New' && parcel.orderStateId != 'InPreparation' && parcel.orderStateId != 'Deleted'"
                        (click)="openMiniportal(parcel)"
                        title="{{ 'shipments_open_miniportal' | shpLocalize }}"><span class="ic-cabinet-1"></span></button>
              </ng-container>
              <button id="button-undelete"
                      *ngIf="isImpersonatingShipperAdminUser && parcel.orderStateId == 'Deleted'"
                      type="button"
                      (click)="undeleteParcel(parcel)"
                      title="{{ 'shipment_undelete' | shpLocalize }}"><span class="ic-sign-recycle"></span></button>
              <!--<button id="button-print-acceptance-protocol" *ngIf="allowProtocolsPrintingForSelectedShipments" type="button" (click)="printAcceptanceProtocolForParcel(parcel)"><span class="ic-file-tasks-check"></span></button>
              <button id="button-print-cod-takeover" *ngIf="allowProtocolsPrintingForSelectedShipments && parcel.hasCod" type="button" (click)="printCodTakeoverProtocolForParcel(parcel)"><span class="ic-money-note-coin"></span></button>-->
            </td>
            <td *appColumn="'spacer'"
                class="col-spacer"></td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
    <div class="grid-footer">
      <div class="multi-select-commands">
        <button *ngIf="!isCustomerService"
                id="button-print-labels-selected"
                type="button"
                class="bottom-button"
                (click)="printLabelsForSelected()">
          <span class="ic-printer"></span><span class="label">{{ 'parcels_print_labels_for_selected' | shpLocalize }}</span>
        </button>
        <button id="button-print-acceptance-selected"
                *ngIf="isProtocolsPrintingButtonVisible"
                type="button"
                class="bottom-button"
                (click)="printAcceptanceProtocolForSelected()">
          <span class="ic-file-tasks-check"></span><span class="label"
                                                         [innerHtml]="'parcels_print_acceptance_protocol_for_selected' | shpLocalize"></span>
        </button>
        <!--<button id="button-print-cod-selected"
                *ngIf="allowProtocolsPrintingForSelectedShipments"
                type="button"
                class="bottom-button"
                (click)="printCodTakeoverProtocolForSelected()">
          <span class="ic-money-note-coin"></span><span class="label"
                                                        [innerHtml]="'parcels_print_cod_takeover_protocol_for_selected' | shpLocalize"></span>
        </button>-->
        <button id="button-export-selected"
                type="button"
                class="bottom-button"
                (click)="exportSelected()">
          <span class="ic-data-upload-8"></span><span class="label">{{ 'parcels_export_selected' | shpLocalize }}</span>
        </button>
        <button id="button-delete-selected"
                type="button"
                class="bottom-button"
                (click)="deleteSelected()">
          <span class="ic-bin"></span><span class="label">{{ 'parcels_delete_selected' | shpLocalize }}</span></button>
        <button *ngIf="isImpersonatingShipperAdminUser"
                type="button"
                class="bottom-button"
                (click)="undeleteSelected()">
          <span class="ic-sign-recycle"></span><span class="label">{{ 'parcels_undelete_selected' | shpLocalize }}</span>
        </button>
        <!--<button id="button-set-pickup-date"
                type="button"
                class="bottom-button"
                (click)="setPickupDate()">
          <span class="ic-calendar-pencil"></span><span class="label">{{ 'parcels_set_pickup_date' | shpLocalize }}</span>
        </button>-->
      </div>
      <div class="grid-pager">
        <div class="pager"
             *ngIf="filter.pageSize < totalParcelsCount">
          <button id="button-pager-first"
                  class="first"
                  type="button"
                  (click)="goToPage(0)"
                  *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
          <button id="button-pager-prev"
                  class="prev"
                  type="button"
                  (click)="goToPage(filter.pageIndex - 1)"
                  *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
          <button id="button-pager-next"
                  class="next"
                  type="button"
                  (click)="goToPage(filter.pageIndex + 1)"
                  *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
          <button id="button-pager-last"
                  class="last"
                  type="button"
                  (click)="goToPage(lastPageIndex)"
                  *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
          <span class="numbers">{{ filter.pageIndex * filter.pageSize + 1 }}
            - {{ (filter.pageIndex + 1) * filter.pageSize < totalParcelsCount ? (filter.pageIndex + 1) * filter.pageSize : totalParcelsCount }}
            /{{ totalParcelsCount }}</span>
        </div>
      </div>
      <div class="grid-options">
        <label *ngIf="filter.pageSize > totalParcelsCount && totalParcelsCount > 0">{{ 'total_items' | shpLocalize }}: {{ totalParcelsCount }}</label>
        <label for="items-per-page">{{ 'items_per_page' | shpLocalize }}</label> <select id="items-per-page" class="items-per-page"
                                                                                         [(ngModel)]="parcelsPerPage">
        <option *ngFor="let value of itemsPerPageOptions"
                value="{{ value }}">{{ value }}</option>
      </select>
      </div>
    </div>
  </div>
</div>
