<div class="wizard__demo-label">
  <div class="wizard__demo-label__description pr-2">
    <h1>{{ 'wizard_demo_label_headline' | shpLocalize }}</h1>
    <div class="mt-2">
      {{ 'wizard_demo_label_text' | shpLocalize }}
    </div>
    <ol class="mt-2 pl-2">
      <li
        *ngFor="let item of labelAreas; index as idx"
        [class.wizard__demo-label__list-item--active]="activePart === idx"
        (mouseenter)="onPartMouseenter(idx)"
        (mouseleave)="onPartMouseleave()">{{ item.text | shpLocalize }}</li>
    </ol>
    <div class="mt-auto">
      <button type="button" class="wizard__message-popup__nav-btn" (click)="dismiss()">{{ 'action_wizard_back' | shpLocalize }}</button>
      <button type="button" class="wizard__message-popup__nav-btn" (click)="close()">{{ 'action_wizard_continue' | shpLocalize }}</button>
    </div>
  </div>

  <div class="wizard__demo-label__example ml-2">
    <div class="wizard__demo-label__example__box">
      <img
        class="wizard__demo-label__example__img"
        [style.visibility]="isImgLoaded ? 'visible': 'hidden'"
        src="/assets/img/wizard-demo-label.png"
        (load)="onImageLoad($event)"
        >
      <ng-container *ngIf="isImgLoaded">
        <div
          *ngFor="let item of labelAreas; index as idx"
          class="wizard__demo-label__example__hightlight"
          [ngClass]="item.areaCssClass"
          [class.wizard__demo-label__example__hightlight--active]="activePart === idx"
          (mouseenter)="onPartMouseenter(idx)"
          (mouseleave)="onPartMouseleave()">
          <div class="wizard__demo-label__example__label" [ngClass]="item.labelCssClass">{{ item.label | shpLocalize }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
