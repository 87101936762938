<!-- <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button> -->
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite"
  [class]="options.messageClass" [innerHTML]="message">
</div>
<div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite"
  [class]="options.messageClass" [attr.aria-label]="message">
  {{ message }}
</div>

<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>

<!-- custom area begin -->
<div class="newsfeed-toast__buttons">
  <!-- <button class="newsfeed-toast__button" (click)="remove()"><span class="ic-close-circle"></span></button> -->
  <button class="newsfeed-toast__button" (click)="dismiss($event)"><span class="ic-close-circle"></span></button>
  <button class="newsfeed-toast__button" (click)="read($event)">
    <span
      [class.ic-check-circle-2]="!hasContent"
      [class.ic-view-add]="hasContent"></span>
  </button>
</div>
<!-- custom area end -->