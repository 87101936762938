import { Pipe, PipeTransform } from "@angular/core"

import { SELECTOR_PREFIX } from "../prefix";
import * as Shared from "../index"


/**
 * Vracia lokalizovan� textov� re�azec, ktor� je �pecifikovan� jeho k���om (key).
 * Je mo�n� �pecifikova� �al�ie argumenty, ktor� sa pou��j� v r�mci re�azca.
 */
@Pipe({
  name: SELECTOR_PREFIX + "Localize"
})
export class LocalizePipe implements PipeTransform {
  constructor(private _localizationService: Shared.LocalizationService) { }


  public transform(key: string, ...args: any[]): any {
    return this._localizationService.getLocalizedString(key, ...args);
  }
}