import { Directive, ElementRef, Input, HostListener } from "@angular/core";

import * as Shared from "../index"

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}NumbersInput]`
})

export class InputOnlyNumbersDirective {
  constructor(private el: ElementRef) { }

  @Input('onlyNumbers') onlyNumbers: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    if (this.onlyNumbers) {
      if ([46, 8, 9, 27, 13 /*delete, backspace, tab, escape, enter*/].indexOf(e.keyCode) !== -1 ||
        // Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        // home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57))
        && (e.keyCode < 96 || e.keyCode > 105)
      ) {
        e.preventDefault();
      }
    }
  }
}