<ng-container *ngIf="isAllParcelsHaveSameReferencesCheckboxDisplayed">
  <div *ngIf="!editor.isReadOnly" class="ctl">
    <input id="all-parcel-same-reference" name="allParcelsHaveSameReferences" type="checkbox" [(ngModel)]="editor.allParcelsHaveSameReferences" />
    <label for="all-parcel-same-reference">{{ 'shipment_order_all_parcels_have_same_reference' | shpLocalize }}</label>
  </div>

  <div *ngIf="editor.isReadOnly && editor.allParcelsHaveSameReferences">
    <label class="w-100">{{ 'shipment_order_all_parcels_have_same_reference' | shpLocalize }} <span class="check-mark"></span></label>
  </div>
</ng-container>

<div class="ctl">
  <!-- <label>{{ 'shipment_order_parcels_references' | shpLocalize }}</label> -->
  <div class="ctl-group-multiline">
    <ng-template ngFor let-parcel [ngForOf]="editor.parcelsForReferencesEditing | async" let-i="index">
      <div class="ctl-group cols-2">
        <div class="p-field p-field--full" [class.mr-1]="editor.visibleParcelReferencesCount > 1">
          <span class="p-float-label">
            <input id="referentialInfo1_{{ i }}" [(ngModel)]="parcel.referentialInfo1" type="text" name="referentialInfo1_{{ i }}" maxlength="35" pInputText [readonly]="editor.isReadOnly" autocomplete="off">
            <label for="referentialInfo1_{{ i }}">{{ 'parcel_referential_info_1' | shpLocalize }}</label>
          </span>
        </div>

        <div *ngIf="editor.visibleParcelReferencesCount > 1" class="p-field p-field--full" [class.mr-1]="editor.visibleParcelReferencesCount > 2">
          <span class="p-float-label">
            <input id="referentialInfo2_{{ i }}" [(ngModel)]="parcel.referentialInfo2" type="text" name="referentialInfo2_{{ i }}" maxlength="35" pInputText [readonly]="editor.isReadOnly" autocomplete="off">
            <label for="referentialInfo2_{{ i }}">{{ 'parcel_referential_info_2' | shpLocalize }}</label>
          </span>
        </div>

        <div *ngIf="editor.visibleParcelReferencesCount > 2" class="p-field p-field--full" [class.mr-1]="editor.visibleParcelReferencesCount > 3">
          <span class="p-float-label">
            <input id="referentialInfo3_{{ i }}" [(ngModel)]="parcel.referentialInfo3" type="text" name="referentialInfo3_{{ i }}" maxlength="35" pInputText [readonly]="editor.isReadOnly" autocomplete="off">
            <label for="referentialInfo3_{{ i }}">{{ 'parcel_referential_info_3' | shpLocalize }}</label>
          </span>
        </div>

        <div *ngIf="editor.visibleParcelReferencesCount > 3" class="p-field p-field--full" [class.mr-0]="editor.visibleParcelReferencesCount === 4">
          <span class="p-float-label">
            <input id="referentialInfo4_{{ i }}" [(ngModel)]="parcel.referentialInfo4" type="text" name="referentialInfo4_{{ i }}" maxlength="35" pInputText [readonly]="editor.isReadOnly" autocomplete="off">
            <label for="referentialInfo4_{{ i }}">{{ 'parcel_referential_info_4' | shpLocalize }}</label>
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</div>