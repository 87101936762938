import { Directive, forwardRef } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

import * as Shared from "../index";
import { validateEmail } from "../validators";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}ValidateEmail][ngModel],[${Shared.SELECTOR_PREFIX}ValidateEmail][formControl]`,
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidatorDirective), multi: true }
  ]
})
export class EmailValidatorDirective implements Validator {
  public validate(c: AbstractControl): { [index: string]: any; } {

    return validateEmail(c);
  }
}
