import { Pipe, PipeTransform } from "@angular/core"

// import { SELECTOR_PREFIX } from "../index"
import { Address } from "../models/customer.models";
import { SELECTOR_PREFIX } from "../prefix";


@Pipe({ name: SELECTOR_PREFIX + "FullAddress" })
export class FullAddressPipe implements PipeTransform {
  constructor() { }


  public transform(value: any, ...args: any[]): any {
    if (typeof value === "undefined" || value == null) {
      return null;
    }

    var parts: string[] = [];
    var address: Address = value;

    if (address.name1) {
      parts.push(address.name1);
    }

    if (address.street) {
      parts.push(address.street + " " + address.houseNr);
    }

    if (address.zip && address.place) {
      parts.push(address.zip + " " + address.place);
    }

    if (address.countryCode) {
      parts.push(address.countryCode);
    }

    return parts.join(", ");
  }
}