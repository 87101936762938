<form #customClearanceForm="ngForm" name="{{name}}">
  <h2>{{ 'customs_data' | shpLocalize }} <button id="button-clear-invoice" *ngIf="!isReadOnly" type="button" class="clear-recipient-buttom" (click)="clearInvoice()"><span class="ic-close"></span></button></h2>

  <div class="">

    <div class="ctl" [class.required]="isRequiredValidationOn && !isReadOnly">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <p-dropdown id="{{name}}-shipment-type" [options]="countrySpecificHideTypeOfDocument ? shipmentTypesWithoutDocuments : shipmentTypes" [(ngModel)]="customClearanceEditorData.shipmentTypeCode" name="{{name}}_shipment_type" (onChange)="onShipmentTypeChange($event)" optionValue="code" [showClear]="true" [required]="isRequiredValidationOn" [readonly]="isReadOnly">
            <ng-template pTemplate="selectedItem">
              <div class="shipment-type-item" *ngIf="customClearanceEditorData.shipmentTypeCode"><div>{{ ('shipment_type_code_' + customClearanceEditorData.shipmentTypeCode) | shpLocalize }}</div></div>
            </ng-template>
            <ng-template let-shipmentType pTemplate="item"><div class="shipment-type-item"><div>{{ ('shipment_type_code_' + shipmentType.code) | shpLocalize }}</div></div></ng-template>
          </p-dropdown>
          <label for="{{name}}-shipment-type">{{ 'shipment_type' | shpLocalize }}<ng-container *ngIf="tooltips?.shipmentType">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.shipmentType"></span></ng-container></label>
        </span>
      </div>
    </div>

    <ng-container *ngIf="showCustomsClearanceFields">
      <div class="ctl" [class.required]="isRequiredValidationOn && !isReadOnly" *ngIf="!isBrexitCountryRecipient">
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <shp-universal-number-input dpdFloatingLabelControl [(ngModel)]="invoice.amount" id="{{name}}-invoice-amount" name="{{name}}_customs_value" [min]="0" [max]="999999" [isRequired]="isRequiredValidationOn" [required]="isRequiredValidationOn" [isReadOnly]="isReadOnly"></shp-universal-number-input>
            <label for="{{name}}-invoice-amount">{{ 'customs_value' | shpLocalize }}<ng-container *ngIf="tooltips?.customsValue">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.customsValue"></span></ng-container></label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <select *ngIf="!isReadOnly" id="{{name}}-currency" name="{{name}}_currency" [(ngModel)]="invoice.currencyCode" [required]="isRequiredValidationOn">
              <option *ngFor="let currency of currencies" [value]="currency.code">{{ currency.code }}</option>
            </select>
            <span *ngIf="isReadOnly" class="ml-1 legend">{{ invoice.currencyCode }}</span>
          </span>
        </div>
      </div>

      <!-- <div class="ctl select" [class.required]="isRequiredValidationOn">
        <label>{{ 'customs_value_recipient' | shpLocalize }}</label>
        <shp-universal-number-input [(ngModel)]="invoice.amountEx" name="invoiceAmountEx" [isRequired]="isRequiredValidationOn" [min]="0" [max]="999999" [isReadOnly]="isReadOnly" required></shp-universal-number-input>
        <select *ngIf="!isReadOnly" id="{{name}}-currency-ex" name="{{name}}_currency-ex" [(ngModel)]="invoice.currencyCodeEx" [required]="isRequiredValidationOn">
          <option *ngFor="let currency of currencies" [value]="currency.code">{{ currency.code }}</option>
        </select>
        <span class="legend" *ngIf="isReadOnly">{{ invoice.currencyCodeEx }}</span>
      </div> -->

      <div class="ctl" *ngIf="showExportReason" [class.required]="isRequiredValidationOn && !isReadOnly">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-dropdown id="{{name}}-reasons-for-export" [options]="reasonsForExport | async" [(ngModel)]="invoice.exportReasonCode" name="{{name}}_reasons_for_export" optionValue="code" [showClear]="true" [required]="isRequiredValidationOn" [readonly]="isReadOnly">
              <ng-template pTemplate="selectedItem">
                <div class="" *ngIf="invoice"><div>{{ ('export_reason_code_' + invoice.exportReasonCode) | shpLocalize }}</div></div>
              </ng-template>
              <ng-template let-exportReason pTemplate="item"><div class=""><div>{{ ('export_reason_code_' + exportReason.code) | shpLocalize }}</div></div></ng-template>
            </p-dropdown>
            <label for="{{name}}-reasons-for-export">{{ 'reasons_for_export' | shpLocalize }}<ng-container *ngIf="tooltips?.reasonsForExport">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.reasonsForExport"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" [class.required]="isRequiredValidationOn && !isReadOnly">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-dropdown id="{{name}}-terms-of-delivery" [options]="countryFilteredIncoTerms" [(ngModel)]="customClearanceEditorData.incoTermCode" name="{{name}}_terms_of_delivery" optionValue="code" [showClear]="true" [required]="isRequiredValidationOn" [readonly]="isReadOnly">
              <ng-template pTemplate="selectedItem">
                <div class="inco-term-item" *ngIf="customClearanceEditorData"><div>{{ ('terms_of_delivery_code_' + customClearanceEditorData.incoTermCode) | shpLocalize }}</div></div>
              </ng-template>
              <ng-template let-incoTerm pTemplate="item"><div class="inco-term-item"><div>{{ ('terms_of_delivery_code_' + incoTerm.code) | shpLocalize }}</div></div></ng-template>
            </p-dropdown>
            <label for="{{name}}-terms-of-delivery">{{ 'terms_of_delivery' | shpLocalize }}<ng-container *ngIf="tooltips?.termsOfDelivery">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.termsOfDelivery"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" [class.required]="isRequiredValidationOn && !isReadOnly" *ngIf="!hideClearanceCleared && !countrySpecificHideClearanceCleared">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-dropdown id="{{name}}-clearance-cleared" [options]="clearanceCleareds" [(ngModel)]="customClearanceEditorData.clearenceClearedCode" name="{{name}}_clearance_cleared" [autoDisplayFirst]="false" optionValue="code" [showClear]="true" [required]="isRequiredValidationOn" [readonly]="isReadOnly || disableClearanceCleared">
              <ng-template pTemplate="selectedItem">
                <div class="clearance-cleared-item" *ngIf="customClearanceEditorData.clearenceClearedCode"><div>{{ ('clearance_cleared_code_' + customClearanceEditorData.clearenceClearedCode) | shpLocalize }}</div></div>
              </ng-template>
              <ng-template let-clearanceCleared pTemplate="item"><div class="clearance-cleared-item"><div>{{ ('clearance_cleared_code_' + clearanceCleared.code) | shpLocalize }}</div></div></ng-template>
            </p-dropdown>
            <label for="{{name}}-clearance-cleared">{{ 'clearance_cleared' | shpLocalize }}<ng-container *ngIf="tooltips?.clearanceCleared">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.clearanceCleared"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" [class.required]="isRequiredValidationOn && !isReadOnly" *ngIf="!hidePrealertStatus && !countrySpecificHidePrealertStatus">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-dropdown id="{{name}}-prealert-status" [options]="prealertStatuses" [(ngModel)]="customClearanceEditorData.prealertStatusCode" name="{{name}}_prealert_status" optionValue="code" [showClear]="true" [required]="isRequiredValidationOn" [readonly]="isReadOnly">
              <ng-template pTemplate="selectedItem">
                <div class="" *ngIf="customClearanceEditorData"><div>{{ ('prealert_status_code_' + customClearanceEditorData.prealertStatusCode) | shpLocalize }}</div></div>
              </ng-template>
              <ng-template let-incoTerm pTemplate="item"><div class=""><div>{{ ('prealert_status_code_' + incoTerm.code) | shpLocalize }}</div></div></ng-template>
            </p-dropdown>
            <label for="{{name}}-prealert-status">{{ 'prealert_status' | shpLocalize }}<ng-container *ngIf="tooltips?.prealertStatus">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.prealertStatus"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" [class.required]="isInvoiceNumberRequired && isRequiredValidationOn && !isReadOnly">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="{{name}}-recipient-invoice-number" [(ngModel)]="invoice.number" type="text" name="{{name}}_invoice_number" maxlength="35" pInputText [readonly]="isReadOnly" [required]="isInvoiceNumberRequired && isRequiredValidationOn">
            <label for="{{name}}-recipient-invoice-number">{{ 'invoice_number' | shpLocalize }}<ng-container *ngIf="tooltips?.invoiceNumber">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.invoiceNumber"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl ctl--date" [class.required]="isDateOfIssueRequired && isRequiredValidationOn && !isReadOnly">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-calendar name="{{name}}_dateOfIssue" [(ngModel)]="invoice.dateOfIssue" [showIcon]="true" inputId="delivery-date" dateFormat="dd/mm/yy" [required]="isDateOfIssueRequired && isRequiredValidationOn" [disabled]="isReadOnly" icon="ic-calendar-1" name="pickupDate"></p-calendar>
            <label for="delivery-date">{{ 'invoice_date' | shpLocalize }}<ng-container *ngIf="tooltips?.invoiceDate">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.invoiceDate"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="isExportMrnFieldVisible" [class.required]="isMrnRequired && !isReadOnly">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="{{name}}-export-declaration-mrn" [(ngModel)]="invoice.mrn" type="text" name="{{name}}_export_declaration_mrn" maxlength="209" pInputText [readonly]="isReadOnly" [required]="isMrnRequired && isRequiredValidationOn">
            <label for="{{name}}-export-declaration-mrn">{{ 'export_declaration_mrn' | shpLocalize }}<ng-container *ngIf="tooltips?.exportDeclarationMrn">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.exportDeclarationMrn"></span></ng-container></label>
          </span>
        </div>
      </div>

      <!-- <div class="ctl">
        <label for="invoice-lines">{{ 'additional_invoice_lines' | shpLocalize }}<ng-container *ngIf="tooltips?.additionalInvoiceLines">&nbsp;<span class="ic-interface-question-mark" [shp-tooltip]="tooltips.additionalInvoiceLines"></span></ng-container></label>
        <wj-input-number id="invoice-lines" [(ngModel)]="invoiceLines" [isReadOnly]="isReadOnly" name="invoiceLines" [format]="'F0'" [min]="0" [step]="1" [showSpinner]="!isReadOnly"></wj-input-number>
      </div> -->

      <div class="ctl mb-0" *ngIf="!isReadOnly && !countrySpecificHideTypeOfDocument">
        <input id="shipment-contains-accompanying-documents" name="hasAccompanyingDocs" type="checkbox" [(ngModel)]="customClearanceEditorData.hasAccompanyingDocs" />
        <label for="shipment-contains-accompanying-documents">{{ 'contains_accompanying_documents' | shpLocalize }}<ng-container *ngIf="tooltips?.containsDocuments">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.containsDocuments"></span></ng-container></label>
      </div>

      <div class="mb-2" *ngIf="!countrySpecificHideTypeOfDocument">
        <label for="type-of-documents">{{ 'type_of_documents' | shpLocalize }}<ng-container *ngIf="tooltips?.typeOfDocuments">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.typeOfDocuments"></span></ng-container></label>
        <p-listbox
          *ngIf="!isReadOnly && accompanyingDocsTypes && accompanyingDocsTypes.length > 0"
          [disabled]="!customClearanceEditorData.hasAccompanyingDocs"
          id="type-of-documents"
          name="typeOfDocuments"
          [showToggleAll]="false"
          (onChange)="onAccompanyingDocsTypesChanged($event)"
          [options]="availableAccompanyingDocsTypes"
          [(ngModel)]="customClearanceEditorData.accompDocsTypeCodes" [metaKeySelection]="false" [checkbox]="true" [filter]="false" [multiple]="true" optionLabel="name" optionValue="code" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}">
        </p-listbox>
        <textarea *ngIf="isReadOnly" [readonly]="true" [(ngModel)]="accompayingDocsNames" name="accompanying-docs-types"></textarea>
      </div>

      <div class="ctl">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <textarea id="comment" [(ngModel)]="invoice.comment" name="{{name}}_comment" pInputTextarea [readonly]="isReadOnly" maxlength="70"></textarea>
            <label for="comment">{{ 'recipient_comment' | shpLocalize }}<ng-container *ngIf="tooltips?.recipientComment">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.recipientComment"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div *ngIf="isBrexitCountryRecipient" class="ctl">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="{{name}}-consignor-hmrc-number" [(ngModel)]="customClearanceEditorData.hmrcNumber" type="text" name="{{name}}_consignor_hmrc_number" maxlength="35" pInputText [readonly]="isReadOnly">
            <label for="{{name}}-consignor-hmrc-number">{{ 'sender_hmrc_number' | shpLocalize }}<ng-container *ngIf="tooltips?.senderHmrcNumber">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.senderHmrcNumber"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" [class.required]="isEoriAndVatRequired && !isReadOnly">
        <div *ngIf="!isReadOnly" class="p-field p-field--half">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="{{name}}-country-code" name="{{name}}_country_code" [(ngModel)]="countryCode">
              <option *ngFor="let country of countries" [value]="country.code">{{ country.code }}</option>
            </select>
            <label for="{{name}}-country-code">{{ 'eori_country_code' | shpLocalize }}</label>
          </span>
        </div>
        <div class="p-field p-field--half" [class.p-field--full]="isReadOnly">
          <span class="p-float-label">
            <input *ngIf="!isReadOnly" type="text" [(ngModel)]="eoriNumberWithoutCountryCode" #eoriControl="ngModel" shpValidateAlphanumeric maxlength="35" id="{{name}}-eori-number" pInputText name="{{name}}_eori_number" [class.ng-invalid]="form?.form?.errors?.eoriOrVatRequired || form?.form?.errors?.eoriAndVatRequired || eoriControl.invalid">
            <input *ngIf="isReadOnly" type="text" [(ngModel)]="customClearanceEditorData.eoriNumber" id="{{name}}-eori-number" pInputText [readonly]="true" name="{{name}}_eori_number">
            <label for="{{name}}-eori-number">{{ 'sender_eori' | shpLocalize }}<ng-container *ngIf="tooltips?.senderEori">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.senderEori"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="eoriControl?.errors?.alphaNumeric">
        <label></label>
        <em class="error">{{ 'error_eori_invalid_format' | shpLocalize }}</em>
      </div>

      <div class="ctl" [class.required]="isEoriAndVatRequired && !isReadOnly">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="{{name}}-consignor-vat" [(ngModel)]="customClearanceEditorData.vatNumber" #vatControl="ngModel" shpValidateAlphanumeric type="text" name="{{name}}_consignor_vat" maxlength="35" [class.ng-invalid]="form?.form?.errors?.eoriOrVatRequired || form?.form?.errors?.eoriAndVatRequired || vatControl.invalid" pInputText [readonly]="isReadOnly">
            <label for="{{name}}-consignor-vat">{{ 'sender_vat' | shpLocalize }}<ng-container *ngIf="tooltips?.senderVat">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.senderVat"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="vatControl.errors?.alphaNumeric">
        <label></label>
        <em class="error">{{ 'error_vat_invalid_format' | shpLocalize }}</em>
      </div>

      <div class="ctl" *ngIf="form?.form?.errors?.eoriAndVatRequired">
        <label></label>
        <em class="error">{{ 'error_sender_eori_and_vat_is_required' | shpLocalize }}</em>
      </div>

      <div class="ctl" *ngIf="form?.form?.errors?.eoriOrVatRequired">
        <label></label>
        <em class="error">{{ 'error_sender_eori_or_vat_is_required' | shpLocalize }}</em>
      </div>

      <div class="ctl">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="{{name}}-consignor-web" [(ngModel)]="customClearanceEditorData.webSite" type="text" name="{{name}}_consignor_web" maxlength="100" pInputText [readonly]="isReadOnly">
            <label for="{{name}}-consignor-web">{{ 'sender_web' | shpLocalize }}<ng-container *ngIf="tooltips?.senderWeb">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.senderWeb"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="!isReadOnly">
        <input id="shipment-sender-invoicing-address" name="senderInvoicingAddress" type="checkbox" [(ngModel)]="isCustomSenderInvoiceAddress" />
        <label for="shipment-sender-invoicing-address">{{ 'custom_sender_invoicing_address' | shpLocalize }}</label>
      </div>

      <div class="ctl" *ngIf="!isReadOnly">
        <input id="shipment-receiver-invoicing-address" name="receiverInvoicingAddress" type="checkbox" [(ngModel)]="isCustomReceiverInvoiceAddress" />
        <label for="shipment-receiver-invoicing-address">{{ 'custom_recipient_invoicing_address' | shpLocalize }}</label>
      </div>
    </ng-container>

    <div class="ctl">
      <label>{{ 'helpfull_links' | shpLocalize }}:</label>
      <ul>
        <!-- <li *ngIf="helpLinkShippingPlanner"><a href="{{ helpLinkShippingPlanner }}" target="_blank">{{ 'shipping_planner' | shpLocalize }}</a></li>
        <li *ngIf="helpLinkCustomsRegulations"><a href="{{ helpLinkCustomsRegulations }}" target="_blank">{{ 'customs_regulations' | shpLocalize }}</a></li>
        <li *ngIf="helpLinkCommercialInvoices"><a href="{{ helpLinkCommercialInvoices }}" target="_blank">{{ 'commercial_invoice' | shpLocalize }}</a></li> -->
        <li *ngFor="let link of helpLinks"><a [href]="link.url" target="_blank">{{ link.displayName }}</a></li>
        <li><a href="#" (click)="showAbbreviationsAndExplanations($event)">{{ 'abbreviations_and_explanations' | shpLocalize }}</a></li>
      </ul>
    </div>
  </div>
</form>
