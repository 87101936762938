import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { saveAs } from "file-saver";
import * as _ from "lodash";
import { DialogRef, ModalComponent } from "ngx-modialog-7";

import { ImportProfile } from "../../import-profiles/models/import-profiles.models";
import { DEFAULT_CONCVERT_TO_CSV_OPS, FILE_TYPE_CSV } from "../../import-profiles/services/import-profiles.service";
import * as Shared from "../../shared/index";
import { FileData, ShipperSettingsService } from "../../shared/index";
import * as LogModel from "../../shared/models/log-entry.model";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import * as ShipmentsModel from "../models/shipment.model";
import { ShipmentService } from "../services/shipments.service";

const SHIPMENTS_IMPORT_DIALOG_DEFAULT_PRODUCT_ID_LAST_USED_VALUE = "shipments-import-dialog-default-product-id-last-used-value";
const SHIPMENTS_IMPORT_DIALOG_IMPORT_PROFILE_ID_LAST_USED_VALUE = "shipments-import-dialog-import-profile-id-last-used-value";
const SHIPMENTS_IMPORT_DIALOG_ENCODING_LAST_USED_VALUE = "shipments-import-dialog-encoding-last-used-value";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments-import-dialog",
  templateUrl: "./shipments-import-dialog.component.html"
})
export class ShipmentsImportDialogComponent implements ModalComponent<ShipmentsModel.ShipmentsImportDialogModalContext> {
  public defaultProductId: number;
  public errorFileName: string = null;
  // TODO: Extrahovať encodingy ako konštanty.
  public importEncoding = "utf-8";
  public importProfile = -1;
  public importedEntriesCount = 0;
  public invalidEntriesCount = 0;
  public isBusy = false;
  public isCentralShipper: boolean;
  public isImportQueued: boolean;
  public isInValidationMode: boolean;
  public isProcessFinishedWithError: boolean;
  /**
   * Záznam z importu.
   */
  public logEntries: Shared.LogEntry[] = [];
  public readFileErrors: string[];
  /**
   * Názov aktuálne vybraného súboru z disku.
   */
  public selectedFileName = "";
  public statusMessage = "";
  public stringErrorMessage = "";
  public totalImportEntriesCount = 0;

  private _fileData: FileData = null;
  /**
   * JS objekt reprezentujúci údaje o vybranom súbore.
   */
  private _selectedFile: File = null;

  constructor(
    public dialog: DialogRef<ShipmentsModel.ShipmentsImportDialogModalContext>,
    private _router: Router,
    private _loggingService: Shared.LoggingService,
    private _localizationService: Shared.LocalizationService,
    private _shipmentsService: ShipmentService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _shipperSettingsService: ShipperSettingsService,
    private _shipperFileReader: Shared.ShipperFileReaderService,
    private _wizardService: WizardService
  ) {
    dialog.setCloseGuard(this);

    if (dialog.context.products && dialog.context.products.length > 0) {
      if (dialog.context.defaultProductId) {
        this.defaultProductId = dialog.context.defaultProductId;
      } else {
        this.defaultProductId = dialog.context.products[0].id;
      }
    }

    this.loadLastUsedSettings();
    this.isCentralShipper = this._shipperSettingsService.isCentralShipper;
  }

  get importProfiles(): ImportProfile[] {
    return this.dialog.context.importProfiles ?? [];
  }

  get isWizardActive(): boolean {
    return this._wizardService.isActive;
  }

  /**
   * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
   */
  public beforeDismiss(): boolean | Promise<boolean> {
    return this._wizardService.isActive;
  }

  /**
   * Vracia, či nie je možné dialóg zatvoriť cez .close().
   */
  public beforeClose(): boolean | Promise<boolean> { return false; }

  public goToArchive() {
    this._router.navigate(["/", "shipments", "import-archive"]);
  }

  public close() {
    this.saveLastUsedSettings();
    this.dialog.close(this.totalImportEntriesCount > 0);
  }

  private loadLastUsedSettings() {
    // Posledne použitý default produkt.
    // Aby sme mohli vôbec vybrať nejaký default produkt, tak musíme nejaké produkty mať.
    if (this.dialog.context.products && this.dialog.context.products.length > 0) {
      const defaultProductIdString = localStorage.getItem(SHIPMENTS_IMPORT_DIALOG_DEFAULT_PRODUCT_ID_LAST_USED_VALUE);

      if (defaultProductIdString) {
        const defaultProductId = Number(defaultProductIdString);

        if (defaultProductId && !isNaN(defaultProductId)) {
          // Otestujeme, či taký produkt vôbec máme dostupný.
          const product = this.dialog.context.products.find(p => p.id === defaultProductId);

          if (product) {
            this.defaultProductId = product.id;
          }
        } else {
          this.defaultProductId = null;
        }
      } else {
        this.defaultProductId = null;
      }
    }

    // Posledne použité kódovanie.
    const encoding = localStorage.getItem(SHIPMENTS_IMPORT_DIALOG_ENCODING_LAST_USED_VALUE);

    if (encoding) {
      this.importEncoding = encoding;
    }

    // Posledne použitý importný profil.
    if (this.importProfiles.length) {
      const importProfileIdString = localStorage.getItem(SHIPMENTS_IMPORT_DIALOG_IMPORT_PROFILE_ID_LAST_USED_VALUE);

      if (importProfileIdString) {
        const importProfileId = Number(importProfileIdString);

        if (importProfileId && !isNaN(importProfileId)) {
          // Otestujeme, či taký importný profil vôbec máme dostupný.
          const importProfile = this.importProfiles.find(p => p.id === importProfileId);

          if (importProfile) {
            this.importProfile = importProfile.id;
          }
        }
      }
    }
  }

  private saveLastUsedSettings() {
    localStorage.setItem(SHIPMENTS_IMPORT_DIALOG_DEFAULT_PRODUCT_ID_LAST_USED_VALUE,
        this.defaultProductId ? this.defaultProductId.toString() : null);
    localStorage.setItem(SHIPMENTS_IMPORT_DIALOG_ENCODING_LAST_USED_VALUE, this.importEncoding);
    localStorage.setItem(SHIPMENTS_IMPORT_DIALOG_IMPORT_PROFILE_ID_LAST_USED_VALUE, this.importProfile.toString());
  }

  /**
   * Handler, ktorý sa má bindovať na event zmeny vybraného súboru cez input type=file.
   */
  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }

  private setSelectedFile = (inputValue: any) => {
    if (inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this.selectedFileName = this._selectedFile.name;
      this.readFileErrors = null;
    } else {
      this.selectedFileName = "";
      this._selectedFile = null;
    }
  }

  downloadErrorFile() {
    this._shipmentsService.downloadShipmentsImportErrorFile(this.dialog.context.customerDetailId, this.errorFileName)
      .subscribe(blob => {
        const filename = this.errorFileName;

        saveAs(blob, filename, true);
      }, ex => {
        const exception = this._exceptionsHandlerService.getExceptionInfo(ex);

        this.stringErrorMessage = this._localizationService.getLocalizedExceptionString(exception);
      });
  }

  public import = (isValidation: boolean) => {
    if (this._selectedFile === null) {
      return;
    }

    this.isBusy = true;

    let importEncoding = this.importEncoding;
    if (importEncoding === "") {
      const foundImportProfile = this.importProfiles.find(e => e.id === this.importProfile);
      importEncoding = foundImportProfile.encoding;
    }

    this._shipperFileReader.readShipmentImportFile(this._selectedFile, importEncoding)
      .subscribe(result => {
        this._fileData = result;

        // Reset hodnôt nastavovaných po každom pokuse o import.
        this.logEntries = [];
        this.importedEntriesCount = 0;
        this.invalidEntriesCount = 0;
        this.isImportQueued = false;


        this.isInValidationMode = isValidation;

        const payload: ShipmentsModel.ShipmentsImportParametersModel = {
          settings: {
            customerDetailId: this.dialog.context.customerDetailId,
            daysOffset: 365,
            exportAllShipments: false,
            manualExportShipmentsSetType: null,
            orderIds: null,
            parcelIds: null,
            rememberShipmentsWereExported: false,
            senderCustomId: this.dialog.context.addressCustomId,
            tenantId: this.dialog.context.tenantId,
            importProfileId: this.importProfile,
            csvColumnDelimiter: null,
            csvRowDelimiter: null,
            defaultProductId: this.defaultProductId
          },
          // content: converted[0],
          content: this._fileData.content,
          isValidation
        };

        // it is reconverted file from XLS, XLSX, etc... to CSV
        // it was use default converted setting
        // let know the server how he can decode
        // if (converted[1] != FILE_TYPE_CSV) {
        if (this._fileData.extension !== FILE_TYPE_CSV) {
          payload.settings.csvColumnDelimiter = DEFAULT_CONCVERT_TO_CSV_OPS.FS;
          payload.settings.csvRowDelimiter = DEFAULT_CONCVERT_TO_CSV_OPS.RS;
        }

        // 2. send content to server
        this._shipmentsService.importShipmentOrders(payload).subscribe(importOutput => {
          if (!this.isCentralShipper || !importOutput.isInQueue) {
            // Chceme iba warningy a chybové hlášky.
            this.logEntries = _.filter(importOutput.logEntries, l => l.severity < LogModel.EventSeverity.Info);
            this.isBusy = false;

            // Uložíme si počty úspešne naimportovaných a chybných riadkov.
            this.importedEntriesCount = importOutput.itemsProcessedSuccessfullyCount;
            this.invalidEntriesCount = importOutput.itemsProcessedWithErrorCount;
            this.isProcessFinishedWithError = importOutput.isProcessFinishedWithError;

            // Vzhľadom na to, že centrálne inštalácie Shippera môžu byť load-balancované,
            // tak pre istotu neponúkame súbor na stiahnutie, lebo nemáme zatiaľ ich centrálne úložisko.
            if (!this._shipperSettingsService.isCentralShipper) {
              this.errorFileName = importOutput.errorFileName;
            } else {
              this.errorFileName = null;
            }

            // AK robíme viac importov, tak ak sa niečo podarilo naimportovať, tak si zvýšime counter, aby sme vedeli,
            // že musíme urobiť refresh záznamov po zatvorení dialógového okna.
            this.totalImportEntriesCount += this.importedEntriesCount;
          } else {
            this.isBusy = false;
            this.isImportQueued = true;
          }
        },
          (ex: Shared.ExceptionInfo) => {
            if (ex.key === "Import_error") {
              // Zobrazujeme len generickú hlášku, lebo tu fakt netušíme, čo nám príde zo servera.
              this.readFileErrors = [
                this._localizationService.getLocalizedString("shipments_import_error_invalid_file")
              ];
            } else {
              this.readFileErrors = [
                this._shipperFileReader.getErrorDescription(ex.key, ex.params.length ? ex.params[0] : [])
              ];
            }

            this._loggingService.logErrorData(ex, "Import failed.");

            this.isBusy = false;
          });
      }, (err) => {
        this.readFileErrors = err.errors;
        this._fileData = err.fileData;
        this.isBusy = false;
      });
  }

  canUseEncodingFromProfile() {
    if (!this.importProfiles.length) {
      return false;
    }

    const foundImportProfile = this.importProfiles.find(e => e.id === this.importProfile);
    return foundImportProfile != null && foundImportProfile.encoding !== "";
  }
}
