import {Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";

// import * as wjNg2Input from "wijmo/wijmo.angular2.input";
import { WjMultiSelect } from '@grapecity/wijmo.angular2.input';

import * as Shared from "../../../shared/index";
import * as LogModel from "../../models/log.model";
import {LogService} from "../../services/log.service";
import {BehaviorSubject} from "rxjs";
import {ToastrService} from "ngx-toastr";

const DEFAULT_PAGE_SIZE = 20;
const LOG_FILTER_KEY = "log-filter";


export interface MultiselectItem {
  isSelected: boolean;
  value: number;
  text: string;
}


@Component({
  selector: Shared.SELECTOR_PREFIX + "-log-viewer",
  templateUrl: "./log-viewer.component.html"
})
export class LogViewerComponent extends Shared.RoutedPageComponentBase implements OnInit {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _logService: LogService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _localizationService: Shared.LocalizationService,
    private _toastr: ToastrService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router);
  }


  // Musíme si odložiť referenciu na dropdowny z Wijmo, lebo z nejakého divného dôvodu
  // neaktualizujú svoj text, keď sa zmení zoznam vyznačených hodnôť.
  @ViewChild("severitiesMultiselect")
  severitiesMultiselect: WjMultiSelect;

  @ViewChild("categoriesMultiselect")
  categoriesMultiselect: WjMultiSelect;

  isBusy$ = new BehaviorSubject<boolean>(false);

  currentPageIndex = 0;
  totalEntriesCount = 0;
  lastPageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;

  // Hodnoty filtra, ktoré už sa používajú pri získavaní záznamov.
  usedEventCodeFilter: string = null;
  usedCategoriesFilter: LogModel.EventCategory[] = [];
  usedSeveritiesFilter: LogModel.EventSeverity[] = [];
  usedTimestampFrom: Date = null;
  usedTimestampTo: Date = null;

  // Hodnoty pre nastavenie v UI, nemusia byť ešte aplikované.
  severitiesFilter: MultiselectItem[] = [];

  categoriesFilter: MultiselectItem[] = [];
  timestampFrom: Date = null;
  timestampTo: Date = null;
  eventCodeFilter = "";

  logEntries$ = new BehaviorSubject<LogModel.LogEntry[]>([]);

  dataToDisplay: any = null;

  public currentYear = new Date().getFullYear();

  ngOnInit() {
    this.initializeMultiselects();

    this.loadFilter();
    this.loadLog();
  }
  /**
   * Potrebujeme z enumov pre EventSeverity a EventCategory vytvoriť podkladové hodnoty
   * pre jednotlivé multi-select dropdowny.
   */
  private initializeMultiselects() {
    this.severitiesFilter = [
      this.getEventSeverityMultiselectItem(LogModel.EventSeverity.Verbose),
      this.getEventSeverityMultiselectItem(LogModel.EventSeverity.Info),
      this.getEventSeverityMultiselectItem(LogModel.EventSeverity.Warning),
      this.getEventSeverityMultiselectItem(LogModel.EventSeverity.Error)
    ];

    this.categoriesFilter = [
      this.getEventCategoryMultiselectItem(LogModel.EventCategory.BusinessLogic),
      this.getEventCategoryMultiselectItem(LogModel.EventCategory.Infrastructure)
    ];
  }

  private getEventSeverityMultiselectItem(severity: LogModel.EventSeverity): MultiselectItem {
    return {
      isSelected: false,
      value: severity,
      text: this.localizationService.getLocalizedString(`event_severity_${severity.toString()}`)
    }
  }

  private getEventCategoryMultiselectItem(category: LogModel.EventCategory): MultiselectItem {
    return {
      isSelected: false,
      value: category,
      text: this.localizationService.getLocalizedString(`event_category_${category.toString()}`)
    }
  }

  private getFilter(): LogModel.LogEntryQueryParameters {
    return {
      pageIndex: this.currentPageIndex,
      pageSize: this.pageSize,
      componentId: null,
      timestampFrom: this.usedTimestampFrom,
      timestampTo: this.usedTimestampTo,
      categories: this.usedCategoriesFilter,
      eventCode: this.usedEventCodeFilter,
      severities: this.usedSeveritiesFilter
    };
  }

  /**
   * Metóda slúžiaca na uloženie aplikovaných nastavení filtra
   * do permanentého úložiska (LocalStorage).
   */
  private storeFilter() {
    localStorage.setItem(LOG_FILTER_KEY, JSON.stringify(this.getFilter()));
  }

  /**
   * Načíta nastavenia filtra uložené do permanentného úložiska (LocalStorage).
   * Nastaví z nich aj zobrazené hodnoty filtra a aj aktívne hodnoty filtra.
   */
  private loadFilter() {
    const filterJson = localStorage.getItem(LOG_FILTER_KEY);

    if (filterJson != null) {
      // Deserializujeme si uložený filter.
      const filter = JSON.parse(filterJson) as LogModel.LogEntryQueryParameters;

      // Nesmieme zabudnúť, že dátumy a časy sú uložené ako text a musíme z toho dostať Date objekty.
      Shared.StringHelpers.convertDateStringsToDates(filter);

      this.usedEventCodeFilter = filter.eventCode;
      this.eventCodeFilter = filter.eventCode;

      this.usedTimestampFrom = filter.timestampFrom;
      this.timestampFrom = filter.timestampFrom;

      this.usedTimestampTo = filter.timestampTo;
      this.timestampTo = filter.timestampTo;

      // this.usedCategoriesFilter = filter.categories;
      filter.categories.forEach(category => {
        this.categoriesFilter.forEach(c => {
          // if (c.value === category) {
          //   c.isSelected = true;
          // }

          if (c.value === category) {
            this.usedCategoriesFilter.push(c.value);
          }
        });
      });

      // this.usedSeveritiesFilter = filter.severities;
      filter.severities.forEach(severity => {
        this.severitiesFilter.forEach(s => {
          if (s.value === severity) {
            // s.isSelected = true;
            this.usedSeveritiesFilter.push(s.value);
          }
        });
      });

      // Musíme zavolať explicitne refresh na multi-select dropdowny, lebo Wijmo ich neaktualizuje
      // automaticky pri zmene kolekcie, ktorú zobrazujú.
      // this.severitiesMultiselect.refresh();
      // this.categoriesMultiselect.refresh();
    }
  };


  displayData(data: any) {
    this.dataToDisplay = data;
  }


  goToPage(pageIndex: number) {
    this.currentPageIndex = pageIndex;
    this.loadLog();
  }


  private loadLog() {
    this.isBusy$.next(true);

    this._logService.getLogEntries(this.getFilter())
      .subscribe(
        {
          next: (results: LogModel.PagedResult<LogModel.LogEntry>) => {
            this.logEntries$.next(results.items);

            this.totalEntriesCount = results.totalSize;
            this.currentPageIndex = results.pageIndex;
            this.lastPageIndex = Math.ceil(this.totalEntriesCount / this.pageSize) - 1;

            this.isBusy$.next(false);
          },
          error: err => {
            this._toastr.error(this._localizationService.getLocalizedString(err.key, err.params));
            this.isBusy$.next(false);
          }
        }
      );
  };

  applyFilter() {
    this.usedEventCodeFilter = this.eventCodeFilter;

    // this.usedSeveritiesFilter = [];
    // this.usedCategoriesFilter = [];

    this.usedTimestampFrom = this.timestampFrom;
    this.usedTimestampTo = this.timestampTo;

    this.currentPageIndex = 0;

    // this.severitiesFilter.forEach(s => {
    //   if (s.isSelected) {
    //     this.usedSeveritiesFilter.push(s.value);
    //   }
    // });

    // this.categoriesFilter.forEach(c => {
    //   if (c.isSelected) {
    //     this.usedCategoriesFilter.push(c.value);
    //   }
    // });

    this.storeFilter();
    this.loadLog();
  }

  /**
   * Metóda, ktorá poskladá zobrazený výber závažností pre použitie vo Wijmo MultiSelect.
   */
  getSelectedSeveritiesText = () => {
    const selectedItemTexts: string[] = [];

    this.severitiesFilter.forEach(s => {
      if (s.isSelected) {
        selectedItemTexts.push(s.text.substr(0, 3) + ".");
      }
    });

    return selectedItemTexts.join(", ");
  };


  /**
   * Metóda, ktorá poskladá zobrazený výber kategórií pre použitie vo Wijmo MultiSelect.
   */
  getSelectedCategoriesText = () => {
    const selectedItemTexts: string[] = [];

    this.categoriesFilter.forEach(c => {
      if (c.isSelected) {
        selectedItemTexts.push(c.text.substr(0, 3) + ".");
      }
    });

    return selectedItemTexts.join(", ");
  };


  /**
   * Vyčistí filter (aj zobrazený aj aktívny).
   * Tiež uloží tieto nastavenia do permanentného lokálneho úložiska.
   */
  clearFilter() {
    this.eventCodeFilter = "";
    this.usedEventCodeFilter = "";
    this.usedSeveritiesFilter = [];
    this.usedCategoriesFilter = [];

    this.usedTimestampTo = null;
    this.timestampTo = null;
    this.usedTimestampFrom = null;
    this.timestampFrom = null;

    // this.severitiesFilter.forEach(s => { s.isSelected = false; });
    // this.categoriesFilter.forEach(c => { c.isSelected = false; });

    // Musíme zavolať explicitne refresh na multi-select dropdowny, lebo Wijmo ich neaktualizuje
    // automaticky pri zmene kolekcie, ktorú zobrazujú.
    // this.severitiesMultiselect.refresh();
    // this.categoriesMultiselect.refresh();

    this.storeFilter();
    this.applyFilter();
  }
}