import * as Models from "../models/shipment.model";
import * as Shared from "../../shared/index";

import { Subject, BehaviorSubject, of } from "rxjs";
import { auditTime, catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from "rxjs/operators";

import * as _ from "lodash";
import * as moment from "moment";
import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ShipmentService } from "../services/shipments.service";
import { StringHelpers, ContextService } from "../../shared/index";
import { ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";


const DEFAULT_PAGE_SIZE = 20;
const FILTER_STORAGE_KEY = "accepted-protocols-filter";


const defaultColumnFilter: Models.AcceptanceProtocolHistoryFilter = {
  dateFrom: "",
  dateTo: "",
  protocolTypeId: null
}


const defaultFilter: Models.AcceptanceProtocolHistoryGridFilter = {
  orderAscending: false,
  orderByFieldName: "CreationDateTimeUtc",
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE
}


@Component({
  selector: Shared.SELECTOR_PREFIX + "-acceptance-protocol-history",
  templateUrl: "./acceptance-protocol-history.component.html",
})
export class AcceptanceProtocolHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  columnFilter: FormGroup;
  filter: Models.AcceptanceProtocolHistoryGridFilter;
  isBusy = false;
  isFiltered = false;
  itemsPerPageOptions = [20, 30, 50, 100, 150, 200, 500];
  lastPageIndex: number;
  multipleSelectModel = false;
  protocolTypes: Models.ProtocolType[];
  protocols$ = new BehaviorSubject<Models.AcceptanceProtocolGridView[]>([]);
  statusMessage: string;
  totalCount: number;
  isLoadingErrorPending = false;


  private _filterChanges$ = new Subject<void>();
  private _destroy$ = new Subject<void>();


  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private localizationService: Shared.LocalizationService,
    private loggingService: Shared.LoggingService,
    private exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private shipmentService: ShipmentService,
    private contextService: ContextService) { }


  get isOrderAscending(): boolean {
    return this.filter.orderAscending;
  }


  set isOrderAscending(value: boolean) {
    this.filter.orderAscending = value;
    this._filterChanges$.next();
  }


  get orderBy(): string {
    return this.filter.orderByFieldName;
  }


  set orderBy(value: string) {
    this.filter.orderByFieldName = value;
    this._filterChanges$.next();
  }


  get pageSize(): number {
    return this.filter.pageSize;
  }


  set pageSize(value: number) {
    this.filter.pageSize = value;
    this._filterChanges$.next();
  }


  get pageIndex(): number {
    return this.filter.pageIndex;
  }


  set pageIndex(value: number) {
    this.filter.pageIndex = value;
    this._filterChanges$.next();
  }


  ngOnInit() {
    this.getProtocolTypes();
    this.initFilter();
    this.initColumnFilter();
    this.initDataStream();
  }


  ngAfterViewInit() {
    /**
     * Initial data load.
     * NOTE: this is called in `ngAfterViewInit` hook
     * because otherwise <wj-input-date> component has
     * trouble synchronising with `FormGroup`.
     */
    this.columnFilter.patchValue(this.filter);
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Reloads data with current filter settings.
   */
  refresh = () => {
    this._filterChanges$.next();
  }


  /**
   * Resets column filter `FormGroup` value.
   */
  resetColumnFilter() {
    this.columnFilter.reset();
  }


  /**
   * Handles order direction and field.
   */
  setItemsOrder(fieldName: string) {
    if (this.orderBy === fieldName) {
      this.isOrderAscending = !this.isOrderAscending;
    } else {
      this.orderBy = fieldName;
      this.isOrderAscending = true;
    }
  }


  goToPage(index: number) {
    this.pageIndex = index;
  }


  onRowSelect(id: number) {
    this.updateMultipleSelectModel();
  }


  onMultipleSelect() {
    this.areAllRowsSelected() ? this.resetSelection() : this.selectAll();
  }


  printAcceptanceProtocol(id: number) {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_acceptance_protocol");

    this.shipmentService.getPdfFromApi("GET", `${Shared.API_URL}/protocols/${id}/body`)
      .then(result => {
        if (!result.type.toLowerCase().startsWith("application/json")) {
          if (result.size > 0) {
            const mediaType = "application/pdf";
            const blob = new Blob([result], { type: mediaType });
            const filename = `${moment(this.getProtocolById(id).creationDateTimeUtc).format("YYYY-MM-DD_HH-mm")}_protocol.pdf`;

            saveAs(blob, filename, true);
          } else {
            this.toastr.success(this.localizationService.getLocalizedString("protocol_sent_to_printer"));
          }
        } else {
          this.toastr.success(this.localizationService.getLocalizedString("protocol_sent_to_printer"));
        }

        this.isBusy = false;
      })
      .catch(reason => {
        this.isBusy = false;
        this.loggingService.logErrorData(reason, "Error printing protocol in acceptance protocol history.");

        const exception = this.exceptionsHandlerService.getExceptionInfo(reason);
        this.toastr.error(this.localizationService.getLocalizedExceptionString(exception));
      });
  }


  private initFilter() {
    this.loadFilter();

    this._filterChanges$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      this.saveFilter();
    });
  }

  /**
   * Loads filter from local storage or use default one.
   */
  private loadFilter() {
    let filterData = localStorage.getItem(FILTER_STORAGE_KEY);
    let filter;

    if (filterData) {
      filter = JSON.parse(filterData);
      filter = StringHelpers.convertDateStringsToDates(filter);
    }

    this.filter = filter || { ...defaultFilter };
  }

  /**
   * Saves filter to local storage.
   * Called when filter changes.
   */
  private saveFilter() {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(this.filter));
  }


  /**
   * Initializes column filter `FormGroup`.
   */
  private initColumnFilter() {
    this.columnFilter = this.fb.group(defaultColumnFilter);

    this.columnFilter.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(value => {
      this.applyColumnFilter(value);
      this.setIsFilterd(value);
    });
  }

  /**
   * Merges columns filter `FormGroup` value with filter.
   * Called when value changes.
   */
  private applyColumnFilter(value: any) {
    this.filter = {
      ...this.filter,
      ...value
    };

    this._filterChanges$.next();
  }

  /**
   * Sets `isFilterd` to `true` if column filter has some value filled out.
   * Called when value changes.
   */
  private setIsFilterd(value: any) {
    this.isFiltered = _.values(value).some(columnValue => columnValue);
  }


  /**
   * Loads protocols types.
   */
  private getProtocolTypes() {
    this.shipmentService.getProtocolsTypes()
      .subscribe((protocolTypes: Models.ProtocolType[]) => {
        protocolTypes.forEach(type => type.name = _.snakeCase(type.name));
        this.protocolTypes = protocolTypes;
      });
  }


  private initDataStream() {
    this.contextService.currentAddress.pipe(filter(a => a != null)).subscribe(a => {
      this.filter.customerAddressId = a.id;
      this._filterChanges$.next();
    });

    this._filterChanges$.pipe(
      takeUntil(this._destroy$),
      filter(() => !this.isBusy),
      auditTime(33),
      tap(() => {
        this.isLoadingErrorPending = false;
        this.isBusy = true;
        this.statusMessage = this.localizationService.getLocalizedString("status_message_loading_acceptance_protocol_history");
      }),
      switchMap(() => {
        return this.shipmentService.searchAcceptanceProtocols(this.filter).pipe(
          catchError(() => {
            this.isLoadingErrorPending = true;

            return of({
              items: [],
              pageIndex: 1,
              pageSize: this.pageSize,
              totalSize: 0
            });
          })
        )
      }),
      tap((response: Shared.PagedResult<Models.AcceptanceProtocol>) => {
        this.isBusy = false
        this.totalCount = response.totalSize;
        this.lastPageIndex = Math.ceil(this.totalCount / this.pageSize) - 1;

        /** Load last page if returned list is empty but there are data on previous pages. */
        if (this.totalCount && !response.items.length) {
          this.goToPage(this.lastPageIndex);
        }
      }),
      map(response => {
        return (response.items as Models.AcceptanceProtocol[]).map(item => this.protocolToGridView(item));
      })
    ).subscribe(this.protocols$);
  }

  /**
   * Transforms `AcceptanceProtocol` from response to model used in component template.
   */
  private protocolToGridView(protocol: Models.AcceptanceProtocol): Models.AcceptanceProtocolGridView {
    return {
      ...protocol,
      isSelected: false,
      protocolTypeName: _.snakeCase(protocol.protocolTypeName)
    };
  }


  /**
   * Gets data from loaded protocols by id.
   */
  private getProtocolById(id: number): Models.AcceptanceProtocol {
    return this.protocols$.getValue().find(protocol => protocol.id === id);
  }


  private selectAll() {
    this.protocols$.getValue().forEach(item => item.isSelected = true);
  }


  private resetSelection() {
    this.protocols$.getValue().forEach(item => item.isSelected = false);
  }


  private areAllRowsSelected(): boolean {
    return this.protocols$.getValue().every(item => item.isSelected);
  }


  private updateMultipleSelectModel() {
    this.multipleSelectModel = this.areAllRowsSelected();
  }
}