export function getMimeType(ext) {
  switch (ext) {
    case 'bmp':
        return 'image/bmp';
    case 'png':
        return 'image/png';
    case 'gif':
        return 'image/gif';
    case 'pdf':
        return 'application/pdf';
    case 'jpe':
    case 'jpg':
    case 'jpeg':
        return 'image/jpeg';
    case 'tif':
    case 'tiff':
        return 'image/tiff';
    case 'zip':
        return 'application/zip';
    case 'doc':
        return 'application/msword';
    case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    default:
        return 'Unknown filetype';
  }
}


export function fixBinary(bin) {
  let length = bin.length;
  let buf = new ArrayBuffer(length);
  let arr = new Uint8Array(buf);

  for (let i = 0; i < length; i++) {
    arr[i] = bin.charCodeAt(i);
  }

  return buf;
}