export class StringHelpers {
  /**
   * Formátovanie stringu ako v C# String.Format(...).
   *
   * @param format "Šablóna" textu.
   * @param args Hodnoty, ktoré sa majú vložiť do textu na jednotlivé pozície.
   */
  public static format(format: string, ...args: any[]): string {
    var i: number;

    for (i = 0; i < args.length; i++) {
      format = format.replace(new RegExp("\\{" + i + "\\}", "gm"), args[i]);
    }

    return format;
  }


  private static regexIso8601 = /^((((\d{4})(-((0[1-9])|(1[012])))(-((0[1-9])|([12]\d)|(3[01]))))(T((([01]\d)|(2[0123]))((:([012345]\d))((:([012345]\d))(\.(\d+))?)?)?)([zZ]|([\+\-](([01]\d)|(2[0123]))(:([012345]\d))?)))?)|(((\d{4})((0[1-9])|(1[012]))((0[1-9])|([12]\d)|(3[01])))(T((([01]\d)|(2[0123]))(([012345]\d)(([012345]\d)(\d+)?)?)?)([zZ]|([\+\-](([01]\d)|(2[0123])):?([012345]\d)?)))?))$/;
  // /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
  private static regexIso8601Time = /^(([0-1][0-9])|([2][0-3])):([0-5][0-9]):([0-5][0-9])$/;
  private static regexIso8601Time2 = /P((([0-9]*\.?[0-9]*)Y)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)W)?(([0-9]*\.?[0-9]*)D)?)?(T(([0-9]*\.?[0-9]*)H)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)S)?)?/;
  // var regexIso8601 = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,})(Z|([\-+])(\d{2}):(\d{2}))?)?)?)?$/;

  /**
   * Prekonvertuje textové reťazce zodpovedajúce ISO 8601 formátu alebo časy zodpovedajúce formátu HH:MM:SS
   * na dátumové hodnoty.
   * @param input Objekt, ktorého atribúty sa majú konvertovať.
   */
  public static convertDateStringsToDates(input) {
    // Return null for undefined or null input.
    if (typeof input === "undefined" || input == null) {
      return null;
    }

    // Ignore things that aren't objects.
    if (typeof input !== "object") {
      return input;
    }

    // Go trought every property/array entry.
    for (var key in input) {
      if (!input.hasOwnProperty(key)) {
        continue;
      }

      var value = input[key];
      var match;

      // Check for string properties which look like dates.
      if (typeof value === "string" && (match = value.match(StringHelpers.regexIso8601))) {
        // We force all the dates to UTC. Else we get stupid dates in IE.
        if (typeof match[27] === "undefined" || match[27] === "") {
          match[0] += "Z";
        }

        var possibleDate = Date.parse(match[0]);
        if (!isNaN(possibleDate)) {
          input[key] = new Date(possibleDate);
        }
      } else if (typeof value === "string" && (match = value.match(StringHelpers.regexIso8601Time))) {
        var fullDate: string = "1900-01-01T" + match[0] + "Z";
        var anotherPossibleDate = Date.parse(fullDate);
        if (!isNaN(anotherPossibleDate)) {
          input[key] = new Date(anotherPossibleDate);
        }
      } else if (typeof value === "object") {
        // Recurse into object.
        StringHelpers.convertDateStringsToDates(value);
      }
    }

    return input;
  }

  public static join(separator: string, ...values: string[]) {
    if (typeof values === "undefined" || values === null) {
      return "";
    }

    return values.filter(s => typeof s !== "undefined" && s !== null && s !== "").join(separator);
  }
}
