export interface User {
  login: string;
  name: string;
  roleKeys: string[];
  tenantId: number;
  id: number;
  shipperAdminUserId: number;
  lastLoginDateTime: Date;
  lastPasswordChangeDateTimeUtc: Date;
  isFirstLogin: boolean;
}


export interface UserRelatedItemModel {
  userId: number;
  itemId: number;
  itemName: string;
  isItemEnabled: boolean;
}


export const DEMO_USER_LOGIN = "demo@demo";