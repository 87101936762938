import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import * as _ from "lodash";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Subject, Subscription } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { BusinessUnitSettingsService, PagedResult } from "../../shared/index";
import * as Models from "../models/recipient.model";
import { RecipientsService } from "../services/recipients.service";
import { RecipientsExportDialogComponent } from "./recipients-export-dialog.component";

const DEFAULT_RECIPIENTS_PER_PAGE = 20;
const RECIPIENTS_PER_PAGE_STORAGE_KEY = "recipients-per-page";
const FILTER_STORAGE_KEY = "recipients-filter";

export interface RecipientViewModel extends Models.RecipientForGridView {
  isSelected: boolean;
}

@Component({
  selector: Shared.SELECTOR_PREFIX + "-recipients-grid",
  templateUrl: "./recipients-grid.component.html"
})
export class RecipientsGridComponent extends Shared.RoutedPageComponentBase implements OnInit {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _recipientsService: RecipientsService,
    private _modal: Modal,
    private _toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private _context: Shared.ContextService,
    private _buSettings: BusinessUnitSettingsService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
    this.loadFilter();
  }

  private _isAdmin = false;
  private _areRecipientsAddressSpecific = false;


  public get showRecipientToPickupAddressAsignment() {
    return this._isAdmin && this._areRecipientsAddressSpecific;
  }


  @ViewChild("header")
  public header;


  @ViewChild("content")
  public content;

  private _filterFormSubscription: Subscription = null;
  private _recipientsLoadingSubscription: Subscription = null;

  // Sem si môžeme pred refreshom zoznamu uloťiž označené záznamy, aby sme ich vedeli po
  // aktualizácii zoznamu znovu označiť.
  private _selectedIdsTemporaryStore: number[] = [];

  private _customerDetailId: number = null;
  private _addressId: number = null;


  @Input() public isBusy = false;

  @Input()
  public set canUndelete(value: boolean) {
    this._canUndelete = value;
  }

  public get canUndelete() {
    return this._canUndelete;
  }

  private _canUndelete = false;
  private _showDeleted = false;

  public filterForm: FormGroup;
  public filter: Models.RecipientsFilter = null;
  public isFiltered = false;
  public statusMessage = "";

  /**
   * Zoznam typov adresátorv.
   */
  public recipientTypes: string[] = [
    Models.CustomerRecipientTypeCode.B2B,
    Models.CustomerRecipientTypeCode.B2C
  ];

  /**
   * Zoznam všetkych adresátorv.
   */
  public recipients: BehaviorSubject<RecipientViewModel[]> = new BehaviorSubject([]);


  /**
   * Adresát, pre ktorého sú aktuálne zobrazené detaily.
   */
  public selectedRecipient: RecipientViewModel = null;

  componentDestroyed$: Subject<boolean> = new Subject();

  public filterText: FormControl = new FormControl("");
  public noRecipientsInDb = false;
  public noRecipientsMatchingFilter = false;
  public isMultiselectMode = true;
  public totalCount = 0;
  public lastPageIndex = 0;
  public itemsPerPageOptions = [20, 30, 50, 100, 150, 200, 500];
  public hasLoadingFailed = false;
  public areAllVisibleSelected = false;

  public get showDeleted() {
    return this._canUndelete ? this._showDeleted : false;
  }

  public set showDeleted(value: boolean) {
    this._showDeleted = value;
    this.filter.showDeletedRecipients = this._showDeleted;
    this.loadRecipients();
  }

  public get parcelsPerPage(): number {
    return this.filter.pageSize;
  }

  public set parcelsPerPage(value: number) {
    this.filter.pageSize = value;
    localStorage.setItem(RECIPIENTS_PER_PAGE_STORAGE_KEY, value.toString());

    this.saveFilter();
    this.loadRecipients();
  }

  public get orderBy() {
    if (this.filter) {
      return this.filter.orderByFieldName;
    }

    return null;
  }


  public get orderAscending() {
    if (this.filter) {
      return this.filter.orderAscending;
    }

    return false;
  }


  private getDefaultFilter(): Models.RecipientsFilter {
    return {
      orderAscending: null,
      orderByFieldName: null,
      pageIndex: 0,
      pageSize: this.getStoredParcelsPerPageOrDefault(),
      searchText: "",
      extendedSearch: true,
      tenantId: null,
      showDeletedRecipients: this._showDeleted,

      customerRecipientTypeCode: null,
      name: "",
      address: "",
      contactInformation: "",
      referenceNumber: "",

      customerDetailId: this._customerDetailId,
      addressId: this._addressId
    };
  }

  private getStoredParcelsPerPageOrDefault = (): number => {
    let parcelsPerPage = +localStorage.getItem(RECIPIENTS_PER_PAGE_STORAGE_KEY);

    if (parcelsPerPage == null || parcelsPerPage === 0) {
      parcelsPerPage = DEFAULT_RECIPIENTS_PER_PAGE;
    } else {
      parcelsPerPage = +parcelsPerPage;
    }

    return parcelsPerPage;
  }

  private createFilterForm(): void {
    this.filterForm = this._formBuilder.group({
      name: this.filter.name,
      address: this.filter.address,
      contactInformation: this.filter.contactInformation,
      customerRecipientTypeCode: this.filter.customerRecipientTypeCode,
      referenceNumber: this.filter.referenceNumber
    });
  }

  private updateFilterFormValues(): void {
    this.filterForm.setValue({
      name: this.filter.name,
      address: this.filter.address,
      contactInformation: this.filter.contactInformation,
      customerRecipientTypeCode: this.filter.customerRecipientTypeCode,
      referenceNumber: this.filter.referenceNumber
    });
  }

  private saveSelectedIds() {
    this.recipients.getValue().forEach(r => {
      if (r.isSelected) {
        this._selectedIdsTemporaryStore.push(r.id);
      }
    });
  }

  private restoreSelectedIds() {
    this.recipients.getValue().forEach(r => {
      if (this._selectedIdsTemporaryStore.find(id => id === r.id)) {
        r.isSelected = true;
      }
    });

    this.checkAreAllVisibleSelected();
  }

  private clearSelectedIdsFromTemporaryStore() {
    this._selectedIdsTemporaryStore = [];
  }

  private loadFilter() {
    const filterJson = localStorage.getItem(FILTER_STORAGE_KEY);

    if (filterJson) {
      const filter = JSON.parse(filterJson);

      this.filter = filter;
    } else {
      this.filter = this.getDefaultFilter();
    }

    this.filterText.setValue(this.filter.searchText);

    this.filter.addressId = this._addressId;
    this.filter.customerDetailId = this._customerDetailId;
  }


  private saveFilter() {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(this.filter));
  }

  private setFilterListener = () => {
    if (this._filterFormSubscription) {
      this._filterFormSubscription.unsubscribe();
    }

    this._filterFormSubscription = this.filterForm.valueChanges.pipe(
      debounceTime(400)
    ).subscribe(formValues => {
      Object.assign(this.filter, formValues);

      this.saveFilter();
      this.loadRecipients();
    });
  }



  private setIsFiltered = (filter: Models.RecipientsFilter) => {
    this.isFiltered =
      !(
        (filter.name == null || filter.name === "") &&
        (filter.address == null || filter.address === "") &&
        (filter.contactInformation == null || filter.contactInformation === "") &&
        (filter.customerRecipientTypeCode == null || filter.customerRecipientTypeCode === "") &&
        (filter.referenceNumber == null || filter.referenceNumber === "")
      );
  }

  private loadRecipients = () => {
    this.isBusy = true;
    this.hasLoadingFailed = false;

    this.statusMessage = this.localizationService.getLocalizedString("status_message_loading_recipients");


    if (this._recipientsLoadingSubscription != null) {
      this._recipientsLoadingSubscription.unsubscribe();
    }

    const filter = _.cloneDeep(this.filter);

    this.setIsFiltered(filter);

    this._recipientsLoadingSubscription = this._recipientsService.getFilteredRecipients(this.filter).pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe((result: PagedResult<Models.RecipientForGridView>) => {
      const recipients = result.items;

      this.totalCount = result.totalSize;
      this.lastPageIndex = Math.ceil(this.totalCount / this.filter.pageSize) - 1;

      const recipientViewModels: RecipientViewModel[] = [];

      for (const recipient of recipients) {
        const recipientViewModel = recipient as RecipientViewModel;

        recipientViewModel.isSelected = false;

        recipientViewModels.push(recipientViewModel);
      }

      this.recipients.next(recipientViewModels);

      this.restoreSelectedIds();
      this.clearSelectedIdsFromTemporaryStore();

      this.noRecipientsInDb = this.filter.searchText == "" && result.items.length === 0;
      this.noRecipientsMatchingFilter = this.filter.searchText != "" && result.items.length === 0;

      this.checkAreAllVisibleSelected();

      this.isBusy = false;

      // Korekcia, ak sme preliezli cez koniec.
      if (this.totalCount > 0 && this.totalCount <= this.filter.pageIndex * this.filter.pageSize) {
        this.goToPage(this.lastPageIndex);
      }
    }, ex => {
      this.loggingService.logErrorData(ex, "Error loading recipients");

      this.hasLoadingFailed = true;

      this.isBusy = false;
    });
  }

  private getSelectedRecipientIds() {
    const selectedIds: number[] = [];

    this.recipients.getValue().forEach(r => {
      if (r.isSelected) {
        selectedIds.push(r.id);
      }
    });

    return selectedIds;
  }

  public scrollGridContent = () => {
    this.header.nativeElement.scrollLeft = this.content.nativeElement.scrollLeft;
  }

  public clearFilter() {
    this.filter = this.getDefaultFilter();
    this.updateFilterFormValues();
    this.loadRecipients();
  }

  public setOrderBy(fieldName: string) {
    if (this.filter.orderByFieldName === fieldName) {
      this.filter.orderAscending = !this.filter.orderAscending;
    } else {
      this.filter.orderByFieldName = fieldName;
      this.filter.orderAscending = true;
    }

    this.loadRecipients();
  }

  public toggleMultiSelect = () => {
    this.isMultiselectMode = !this.isMultiselectMode;
  }

  public select = (recipient: RecipientViewModel) => {
    this.selectedRecipient = recipient;
  }

  public applyFilter = () => {
    this.filter.pageIndex = 0;

    this.loadRecipients();
  }


  public goToPage = (pageIndex: number) => {
    this.filter.pageIndex = pageIndex;
    this.loadRecipients();
  }


  public editRecipient = (recipient: RecipientViewModel) => {
    this.router.navigate(["/recipients/" + recipient.id]);
  }


  public deleteRecipient = (recipient: RecipientViewModel) => {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("recipient_delete_confirmation_question"))
      .open()
      .result.then(
        value => {
          if (value) {
            this.isBusy = true;
            this._recipientsService.deleteRecipient(recipient.id)
              .subscribe(
                () => {
                  // Odstránime adresáta zo zoznamu.
                  const recipients = this.recipients.getValue();
                  const index = recipients.indexOf(recipient);

                  if (index > -1) {
                    recipients.splice(index, 1);
                  }

                  // Nastavíme aktuálne označený záznam na žiaden.
                  this.selectedRecipient = null;

                  this.isBusy = false;

                  this._toastr.success(this.localizationService.getLocalizedString("recipient_deleted"));

                  // Obnovíme zoznam.
                  this.saveSelectedIds();

                  this.loadRecipients();
                },
                ex => {
                  this.isBusy = false;
                  this._toastr.error(this.localizationService.getLocalizedString("recipient_delete_failed"));

                  this.loggingService.logErrorData(ex, "Recipient delete failed");
                });
          }
        },
        () => {
          // ... nič nerobíme.
        });
  }



  public undeleteRecipient = (recipient: RecipientViewModel) => {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("recipient_undelete_confirmation_question"))
      .open()
      .result.then(
        value => {
          if (value) {
            this.isBusy = true;
            this._recipientsService.undeleteRecipient(recipient.id)
              .subscribe(
                () => {
                  // Odstránime adresáta zo zoznamu.
                  const recipients = this.recipients.getValue();
                  const index = recipients.indexOf(recipient);

                  if (index > -1) {
                    recipients.splice(index, 1);
                  }

                  // Nastavíme aktuálne označený záznam na žiaden.
                  this.selectedRecipient = null;

                  this.isBusy = false;

                  this._toastr.success(this.localizationService.getLocalizedString("recipient_undeleted"));

                  // Obnovíme zoznam.
                  this.saveSelectedIds();

                  this.loadRecipients();
                },
                ex => {
                  this.isBusy = false;
                  this._toastr.error(this.localizationService.getLocalizedString("recipient_undelete_failed"));

                  this.loggingService.logErrorData(ex, "Recipient undelete failed");
                });
          }
        },
        () => {
          // ... nič nerobíme.
        });
  }


  public duplicateRecipient = () => {
    this.showNotImplementedAlert();
  }


  public createNewShipmentForRecipient = (recipient: RecipientViewModel) => {
    this.router.navigate(["/shipments/0"], { queryParams: { recipientId: recipient.id } });
  }


  private showNotImplementedAlert = () => {
    this._modal.alert().body(this.localizationService.getLocalizedString("not_implemented_yet")).open();
  }


  public handleKeyPress = (event: KeyboardEvent, recipient: RecipientViewModel) => {
    if (!event.altKey && !event.ctrlKey && event.keyCode === 13) {
      this.select(recipient);

    } else if (!event.altKey && !event.ctrlKey && event.keyCode === 32 && this.isMultiselectMode) {
      // Zmena výberu v multi-select móde (mód musí byť aktívny).
      this.toggleSelection(recipient);
    }
  }


  public toggleSelection = (recipient: RecipientViewModel) => {
    recipient.isSelected = !recipient.isSelected;

    this.checkAreAllVisibleSelected();
  }


  public selectionChanged = ($event: Event, recipient: RecipientViewModel) => {
    this.toggleSelection(recipient);
  }

  private checkAreAllVisibleSelected = () => {
    const areAllSelected = _.every(this.recipients.getValue(), (r: RecipientViewModel) => r.isSelected);

    this.areAllVisibleSelected = areAllSelected;
  }


  public toggleAllVisibleSelected = () => {
    const recipients = this.recipients.getValue();

    if (this.areAllVisibleSelected) {
      // Všetky záznamy sú označené, tak ich všetky odznačíme.
      recipients.forEach(r => { r.isSelected = false; });

      this.areAllVisibleSelected = false;
    } else {
      // Nie všetky sú označené, tak ich všetky nastavíme na označené.
      recipients.forEach(r => { r.isSelected = true; });

      this.areAllVisibleSelected = true;
    }
  }


  public cancelMultiSelectOperations = () => {
    this.isMultiselectMode = false;
  }


  public exportRecipients = () => {
    const selectedIds = this.getSelectedRecipientIds();

    if (selectedIds.length === 0) {
      return; // Nič nerobíme, ak nič nie je označené.
    }

    const modalContext = new Models.ExportDialogModalContext(selectedIds);

    this._modal.open(RecipientsExportDialogComponent, overlayConfigFactory(modalContext));
  }


  public deleteRecipients = () => {
    const selectedIds = this.getSelectedRecipientIds();

    if (selectedIds.length === 0) {
      return; // Nič nerobíme, ak nič nie je označené.
    }


    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("recipients_delete_confirmation_question"))
      .open()
      .result.then(value => {
        if (value) {
          this.isBusy = true;
          this._recipientsService.deleteRecipients(selectedIds)
            .subscribe(
              () => {
                // Odstránime adresáta zo zoznamu.
                const recipients = this.recipients.getValue();

                selectedIds.forEach(selectedId => {
                  const index = _.findIndex(recipients, r => r.id === selectedId);

                  if (index > -1) {
                    recipients.splice(index, 1);
                  }
                });


                // Nastavíme aktuálne označený záznam na žiaden.
                if (this.selectedRecipient) {
                  if (_.find(selectedIds, id => id === this.selectedRecipient.id)) {
                    this.selectedRecipient = null;
                  }
                }

                this.isBusy = false;

                this._toastr.success(this.localizationService.getLocalizedString("recipients_deleted"));

                // Obnovíme zoznam.
                this.saveSelectedIds();
                this.loadRecipients();
              },
              ex => {
                this.isBusy = false;
                this._toastr.error(this.localizationService.getLocalizedString("recipients_delete_failed"));

                this.loggingService.logErrorData(ex, "Recipient delete failed");
              });
        }
      },
        () => {
          // ... nič nerobíme.

        });
  }


  private initialize() {
    combineLatest([
      this.authenticationService.isAdmin$,
      this._buSettings.getAreRecipientsAddressSpecific()
    ]).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((result: [boolean, boolean]) => {
      this._isAdmin = result[0];
      this._areRecipientsAddressSpecific = result[1];
    });

    return this._context.currentAddress.pipe(
        takeUntil(this.componentDestroyed$),
        filter(a => a != null)
      ).subscribe(a => {
        this._customerDetailId = a.customerDetailId;
        this._addressId = a.id;

        this.loadFilter();

        this.setFilterListener();

        this.loadRecipients();
      });
  }


  public ngOnInit() {
    this.createFilterForm();

    this.initialize();

    this._recipientsService.shouldRefreshRecipients.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(_ => {
      this.loadRecipients();
    });
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this._filterFormSubscription) {
      this._filterFormSubscription.unsubscribe();
    }

    if (this._recipientsLoadingSubscription) {
      this._recipientsLoadingSubscription.unsubscribe();
    }
  }
}
