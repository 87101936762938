import { ConnectedPosition } from "@angular/cdk/overlay";
import { ElementRef, InjectionToken, Type } from "@angular/core";
import { Subject } from 'rxjs';

import { WizardService } from "./services/wizard.service";

export enum WizardCssClass {
  Active = "wizard--active",
  Arrow = "wizard__arrow",
  ArrowAnimated = "wizard__arrow--animated",
  ArrowBottom = "wizard__arrow--bottom",
  ArrowLeft = "wizard__arrow--left",
  ArrowRight = "wizard__arrow--right",
  ArrowTop = "wizard__arrow--top",
  EnableScroll = "wizard--enable-scroll",
  Label = "wizard__label",
  MessagePopup = "wizard__message-popup",
  MessagePopupGeneric = "wizard__message-popup--generic",
  MessagePopupCustom = "wizard__message-popup--custom",
  MessagePopupBackdrop = "wizard__message-popup__backdrop",
  Overlay = "wizard__overlay",
  OverlayAnimated = "wizard__overlay--animated",
  OverlayAutoScrolling = "wizard__overlay--auto-scrolling",
  OverlayHasArea = "wizard__overlay--has-area",
  OverlayTarget = "wizard__overlay__target",
  Popup = "wizard__popup",
  PopupBackdrop = "wizard__popup__backdrop",
  PopupBackdropHidden = "wizard__popup__backdrop--hidden",
  Chapters = "wizard__chapters"
}

export interface WizardGlobalPosition {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  offsetX?: string;
  offsetY?: string;
}

export interface WizardBoundries {
  /** Max top position (default: 0). */
  top: number;
  /** Max right position (default: 0). */
  // right?: number;
  /** Max bottom position (default: 0). */
  bottom: number;
  /** Max left position (default: 0). */
  // left?: number;
}



export interface WizardOverlayOffest {
  x?: number;
  y?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  width?: number;
  height?: number;
}

export interface WizardOverlay {
  /**
   * Focus overlay on tagged element. @see `WizardTagDirective`
   *
   * If more tags are provided or tag is used for multiple elements
   * a rectangle containing all elements is calculated.
   */
  tag: string | string[];
  /** Overlay boundries. @see `WizardBoundries` */
  boundries?: Partial<WizardBoundries>;
  offset?: number | WizardOverlayOffest;
}



/** Data for Wizard popup. */
export interface WizardPopup<T = {[key: string]: any}> {
  /**
   * Popup will be positioned next to a tagged element.
   * Position can be set using `connectedPosition`
   * @see `WizardTagDirective`
   */
  connectedToElementTag?: string;
  /** Additional classes for popup. */
  popupClass?: string | string[];
  /**
   * Postion for globaly positioned popup. If not set
   * popup is centered to the middle.
   */
  position?: WizardGlobalPosition;
  /** Applies when `fixedToElementTag` is set. */
  connectedPositions?: ConnectedPosition[];
  /** Visible popup area boundries. @see WizardBoundries */
  connectedPositionBoundries?: Partial<WizardBoundries>;
  /** Component to render in the popup. */
  popupComponent?: Type<any>;
  /** Data for the `popupComponent`. */
  popupComponentData?: T;
}



export enum WizardArrowPosition {
  Top,
  Right,
  Bottom,
  Left
}

/** Data for Wizard arrow. */
export interface WizardArrow {
  /**
   * Element tag next to which should the arrow show.
   * @see `WizardTagDirective`
   */
  tag: string;
  /** Text displayed next to the arrow. */
  text?: string;
  /** Position of the arrow (default: Right). */
  position?: WizardArrowPosition;
  /** Apply animation CSS class (default: true). */
  isAnimated?: boolean;
}



export interface WizardLabel {
  /**
   * Element tag next to which should the label show.
   * @see `WizardTagDirective`
   */
  tag: string;
  text: string;
  positions: ConnectedPosition[];
  /** Additional classes for label. */
  labelClass?: string | string[];
}



/** Data for Wizard message popup */
export interface WizardMessagePopup<T = {[key: string]: any}> {
  message?: string;
  component?: Type<WizardMessageComponent>;
  componentData?: T;
  dismissLabel?: string;
  closeLabel?: string;
  /** Display dismiss button (default: true). */
  hasDismissButton?: boolean;
  /** Display close button (default: true). */
  hasCloseButton?: boolean;
}

/** Base class for custom message components. */
export class WizardMessageComponent {
  constructor(protected wizardService: WizardService) { }

  close(result: boolean | string = true) {
    this.wizardService.closeMessagePopup(result);
  }

  dismiss() {
    this.wizardService.dismissMessagePopup();
  }
}



export interface WizardForceNavigation {
  /** Target URL. */
  url: string;
  /**
   * Test regex to determine if navigation is necessary
   * (in case other child routes also display desired elements).
   */
  test?: RegExp;
}

/** Wizard step data. */
export interface WizardStep<T = string> {
  name: T;
  /**
   * Force navigation to specific URL for step.
   * @see `WizardForceNavigation`
   */
  forceNavigation?: string | WizardForceNavigation;
  /**
   * While Wizard is active page input is disabled.
   * Enable tagged elements.
   * @see `WizardTagDirective`
   */
  enabledSectionTags?: string[];
  /** Focus Wizard overal to tagged element. */
  overlay?: WizardOverlay;
  popup?: WizardPopup;
  arrows?: WizardArrow[];
  labels?: WizardLabel[];
}



export interface WizardChapter<T = string, T2 = string> {
  name: T;
  steps: Set<T2>;
  iconClass: string;
  label: string;
  /** Show in control panel (default: true). */
  isDisplayedInControlPanel?: boolean;
  firstStepName: T2;
  lastStepName: T2;
}



export interface WizardTaggedElement {
  tag: string;
  elementRef: ElementRef;
}



export abstract class WizardDataProvider<T = string, T2 = string> {
  abstract initialStep: T;

  abstract getStep(name: T): WizardStep<T>;

  abstract getNextStep(name: T): WizardStep<T>;

  getNextStepName(name: T): T {
    return this.getNextStep(name)?.name;
  }

  abstract getPreviousStep(name: T): WizardStep<T>;

  getPreviousStepName(name: T): T {
    return this.getPreviousStep(name)?.name;
  }

  abstract getChapters(): WizardChapter<T2, T>[];

  abstract getChapter(name: T2): WizardChapter<T2, T>;

  abstract getNextChapter(name: T2): WizardChapter<T2, T>;

  getNextChapterName(name: T2): T2 {
    return this.getNextChapter(name)?.name;
  }

  abstract getPreviousChapter(name: T2): WizardChapter<T2, T>;

  getPreviousChapterName(name: T2): T2 {
    return this.getPreviousChapter(name)?.name;
  }

  getChapterForStep(step: T): WizardChapter<T2, T> {
    return this.getChapters().find(s => s.steps.has(step));
  }

  getChapterNameForStep(step: T): T2 {
    return this.getChapterForStep(step)?.name;
  }
}

export const WIZARD_DATA_PROVIDER = new InjectionToken<WizardDataProvider>("wizardDataProvider");



export interface WizardState {
  avaiableChapters: Set<string>;
  currentStep?: WizardStep;
  currentChapter?: WizardChapter;
  isActive: boolean;
  messagePopup?: WizardMessagePopup;
}

export enum WizardEventType {
  Close,
  Finish,
  Start,
  StepChange
}

export type WizardEventPayload = {[key: string]: any};

export enum WizardStepChangeType {
  CompleteStep = "CompleteStep",
  GoToStep = "GoToStep",
  GoToNextStep = "GoToNextStep",
  GoToPreviousStep = "GoToPreviousStep",
  GoToChapter = "GoToChapter",
  GoToNextChapter = "GoToNextChapter",
  GoToPreviousChapter = "GoToPreviousChapter",
  CompleteCurrentChapter = "CompleteCurrentChapter"
}

/** Data of a Wizard step change event. */
export interface WizardStepChange<T = string, T2 = string> {
  type: WizardStepChangeType;
  customType: string;
  oldStep: T;
  oldChapter: T2;
  targetStep: T;
  beforeLeaveStep: T;
  canActivateStep: T;
  finalStep: T;
  finalChapter: T2;
}

export interface WizardEvent {
  type: WizardEventType;
  payload?: WizardEventPayload;
}
