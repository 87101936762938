import {Component, OnDestroy, OnInit} from "@angular/core"
import {Router} from "@angular/router"

import * as Shared from "../../shared/index"
import { ImportProfilesService } from "../services/import-profiles.service";
import { PollyCollection } from "../../shared/index";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments",
  templateUrl: "./import-profiles.component.html"
})
export class ImportProfilesComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  public pollyCollection = PollyCollection.ImportProfiles;
  public wizardStepNames = WizardStepName;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    private _dynamicsImportsService: ImportProfilesService,
    router: Router
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }

  ngOnInit(): void {
    const urlParts = this.router.url.split('/').filter(p => Boolean(p));
    const profile = urlParts[1];
    this._dynamicsImportsService.changeImportProfile(profile);
  }

  public changeImportProfile(type: string) {
    this._dynamicsImportsService.changeImportProfile(type);
  }

  public ngOnDestroy() {
    // musíme nastaviť hodnotu na predvolenú: shipments
    this._dynamicsImportsService.changeImportProfile("shipments");
  }
}
