import { EntityDataBaseInteractive } from "../../shared/models/base.model"
import { Address } from "../../shipments/models/shipment.model";
import { ModalOpenContext } from "ngx-modialog-7";
import { ImportProfile } from "../../import-profiles/models/import-profiles.models";
import { AutomatedShipmentsImportSourceType } from "./automated-shipments-import-source-type";

export interface ParcelDimensions {
  widthInCentimeters: number;
  heightInCentimeters: number;
  lengthInCentimeters: number;
  weightInKilograms: number;
}


export interface PudoApiSettings {
  apiEndpointUrl: string;
  apiKey: string;
  isLocalPudoCacheEnabled: boolean;
}


export interface Setting<T> {
  name: string;
  tooltip: string;
  value: T;
  isVisible: boolean;
}


export enum AcceptanceProtocolSortOrder {
  ConsigneeZip,
  ConsigneeCountry,
  ConsigneeName,
  Product,
  Reference1,
  ParcelNr
}


export interface ImportSettings {
  sourcePath: string;
  targetPath: string;
  useSemaphoreFiles: boolean;
}


export enum PaperSize {
  A4,
  A6
}


export enum ZebraLanguage {
  EPL,
  ZPL
}


export enum PrinterType {
  Zebra,
  Laser,
  Pdf,
  Image,
  PdfLocalFolder,
}


export enum ProtocolPrintingType {
  Laser,
  Pdf,
  PdfLocalFolder,
}

export enum LabelPart
{
  None,
  Reference1,
  Reference2,
  CustomerReference,
  Note,
  CodAmount,
  Purpose
}

export enum LabelSortOrder {
  CreationTime,
  ConsigneeZip,
  ConsigneeCountry,
  ConsigneeName,
  Product,
  Reference1,
  ParcelNr
}


export interface PrintingSettings {
  startPrintingLabelsOnPosition: number;
  rememberLastPrintedPosition: boolean;
  showLabelPositionDialog: boolean;
  printerForLabel: string;
  printerForProtocol: string;
  labelFormat: PaperSize;
  labelZebraLanguage: ZebraLanguage;
  labelPrintingType: PrinterType;
  labelPrintingTypePdfLocalFolder: string;
  protocolPrintingType: ProtocolPrintingType;
  protocolPrintingTypePdfLocalFolder: string;
  printingDpi: number;
  printerLeftOffset: number;
  printerTopOffset: number;
  moveTheNoteOnTheLabelBeforeTheSendersAddress: boolean;
  printCustomersReferenceIdOnTheLabelsAndAcceptanceProtocols: boolean;
  printNoteOnAcceptanceProtocols: boolean;
  printNoteOnLabels: boolean;
  senderSpecificNoteOnTheLabelBeforeTheSendersAddress: string;
  moveNoteOnLabelBeforeSenderAddress: boolean;
  partOfLabelToBePrintedLarger: LabelPart;
  printCustomersReferenceOnAcceptanceProtocols: boolean;
  printReference2OnAcceptanceProtocols: boolean;
  labelSortOrder: LabelSortOrder;
}


export interface TenantSettings {
  depot_code: Setting<string>;
  business_unit_code: Setting<string>;
  country_code: Setting<string>;
  currency_code: Setting<string>;
  pudoApiSettings: Setting<PudoApiSettings>;
  pudo_download_enabled: Setting<boolean>;
  pudo_download_is_ftp: Setting<boolean>;
  pudo_download_protocol: Setting<string>;
  pudo_download_port: Setting<number>;
  pudo_download_ftp_host: Setting<string>;
  pudo_download_ftp_username: Setting<string>;
  pudo_download_ftp_password: Setting<string>;
  pudo_download_path: Setting<string>;
  pudo_list_name: Setting<string>;
  sync_shipments_manually: Setting<boolean>;
  sync_shipments_to_endpoint: Setting<string>;
  sync_shipments_path: Setting<string>;
  sync_shipments_local_path: Setting<string>;
  sync_shipments_use_semaphore_files: Setting<boolean>;
  sync_shipments_continually: Setting<boolean>;
  sync_shipments_periodicity_in_minutes: Setting<number>;
  sync_shipments_hour: Setting<number>;
  sync_shipments_minute: Setting<number>;
  sync_shipments_protocol: Setting<string>;
  sync_shipments_port: Setting<number>;
  sync_shipments_ftp_host: Setting<string>;
  sync_shipments_ftp_user: Setting<string>;
  sync_shipments_time_mode: Setting<string>;
  sync_shipments_nminutes_after_save: Setting<number>;
  sync_collection_requests_hour: Setting<number>;
  sync_collection_requests_minute: Setting<number>;
  automated_shipments_export: Setting<boolean>;
  automated_shipments_export_time_mode: Setting<string>;
  automated_shipments_export_path: Setting<string>;
  automated_shipments_export_file_mask: Setting<string>;
  automated_shipments_export_file_type: Setting<string>;
  automated_shipments_export_periodicity_in_minutes: Setting<number>;
  automated_shipments_export_hour: Setting<number>;
  automated_shipments_export_minute: Setting<number>;
  automated_shipments_import: Setting<boolean>;
  automated_shipments_import_source: Setting<AutomatedShipmentsImportSourceType>;
  automated_shipments_import_sftp_server: Setting<string>;
  automated_shipments_import_sftp_port: Setting<number>;
  automated_shipments_import_sftp_login: Setting<string>;
  automated_shipments_import_sftp_password: Setting<string>;
  automated_shipments_import_path: Setting<string>;
  automated_shipments_import_error_path: Setting<string>;
  automated_shipments_import_periodicity_in_minutes: Setting<number>;
  automated_shipments_import_periodicity_in_seconds: Setting<number>;
  automated_shipments_import_use_semaphore_files: Setting<boolean>;
  automated_shipments_import_sender_Id: Setting<string>;
  automated_shipments_import_bank_account_Id: Setting<string>;
  automated_shipments_import_profile_code: Setting<string>;
  automated_shipments_import_delete_file_after_processing: Setting<boolean>;
  automated_shipments_import_print_labels_after_processing: Setting<boolean>;
  automated_shipments_import_move_to_archive: Setting<boolean>;
  automated_shipments_import_archive_path: Setting<string>;
  automated_shipments_import_include_parcelnrs_in_archive: Setting<boolean>;
  automated_shipments_import_semaphore_files_in_archive: Setting<boolean>;
  automated_shipments_import_disable_special_order_validation: Setting<boolean>;
  disable_online_mode: Setting<boolean>;
  all_parcels_in_a_shipment_have_the_same_dimensions: Setting<boolean>;
  all_parcels_in_a_shipment_have_the_same_references: Setting<boolean>;
  parcel_default_dimensions: Setting<ParcelDimensions>;
  shipment_default_note: Setting<string>;
  sms_notification_for_each_shipment: Setting<boolean>;
  e_mail_notification_for_each_shipment: Setting<boolean>;
  is_card_pay_allowed: Setting<boolean>;
  default_product_code: Setting<string>;
  default_customer_recipient_type_code: Setting<string>;
  parcel_number_ranges_warning_threshold: Setting<number>;
  compact_mode: Setting<boolean>;
  sort_order_for_acceptance_protocol: Setting<AcceptanceProtocolSortOrder>;
  current_Routing_Tables_version: Setting<string>;
  // routing_db_path: Setting<string>;
  // routing_db_path_is_ftp: Setting<boolean>;
  // routing_db_protocol: Setting<string>;
  // routing_db_port: Setting<number>;
  // routing_db_ftp_host: Setting<string>;
  // routing_db_ftp_username: Setting<string>;
  // routing_db_ftp_password: Setting<string>;
  // routing_db_file_prefix: Setting<string>;
  routing_db_local_path: Setting<string>;
  select_application_language: Setting<string>;
  automated_import_setting: Setting<ImportSettings>;
  printingSettings: Setting<PrintingSettings>;
  number_of_shipment_references: Setting<number>;
  number_of_parcel_references: Setting<number>;
  default_parcel_reference_1: Setting<string>;
  default_parcel_reference_2: Setting<string>;
  default_cod_payment_type_id: Setting<number>;
  printing_settings_availability: Setting<boolean>;
  parcel_default_dimensions_availability: Setting<boolean>;
  automated_recipients_import: Setting<boolean>;
  automated_recipients_import_path: Setting<string>;
  automated_recipients_import_periodicity_in_minutes: Setting<number>;
  automated_recipients_import_use_semaphore_files: Setting<boolean>;
  automated_recipients_import_delete_file_after_processing: Setting<boolean>;
  use_proxy_server: Setting<boolean>;
  proxy_address: Setting<string>;
  proxy_port: Setting<string>;
  configurations_editing_availability: Setting<boolean>;
  default_recipient_language_code: Setting<string>;
  extended_recipient_search_turned_on: Setting<boolean>;
  minimal_recipient_search_char_count: Setting<number>;
  defaultReturnAddress: Setting<Address>;
  defaultCustomSenderAddress: Setting<Address>;
  return_to_shipment_list_after_saving: Setting<boolean>;
  return_to_recipient_list_after_saving: Setting<boolean>;
  automated_shipments_deletion_after_x_days: Setting<number>;
  add_or_update_recipient_after_order_save: Setting<boolean>;
  enable_products_and_services_availability_calculation: Setting<boolean>;
  default_sender_eori_number: Setting<string>;
  default_sender_vat_number: Setting<string>;
  default_sender_hmrc_number: Setting<string>;
  is_Eod_parcel_export_for_central_shipper_enabled: Setting<boolean>;
  can_only_admin_update_shipper: Setting<boolean>;
  empty_return_sender: Setting<boolean>;
  hide_username_on_protocol: Setting<boolean>;
}


export interface TenantSettingsModel {
  printingSettingsVisibleProperties: string[];
  tenantSettings: TenantSettings;
}


export interface ConfigurationModel {
  configurationJson: string;
  password: string;
}

export interface ActivateConfigurationModel {
  activationCode: string;
}


export interface ConfigurationDetailsModel {
  id: number;
  addressName: string;
  addressStreet: string;
  addressHouseNr: string;
  addressCity: string;
  addressCountryCode: string;
  addressZip: string;
  customerName: string;
  customerInvoicingNr: string;
  customerDelisId: string;
  validFromDateTimeUtc: Date;
  validToDateTimeUtc: Date;
  parcelNumberRanges: ParcelNumberRange[];
  stateId: string;
  versionNumber: number;
  groupId: number;
}


export interface ParcelNumberRange extends EntityDataBaseInteractive {
  customerDetailId: number;
  intervalNumber: string;
  parcelNumberFrom: string;
  parcelNumberTo: string;
  rangeCount: number;
  lastUsedNumber: string;
}


/**
 * Stavy konfigurácie podľa Dpd.Shipper.Common.Data.CaseAssets.Policies.
 */
export class ConfigurationState {
  public static Active = "Active";
  public static Disabled = "Disabled";
  public static Deleted = "Deleted";
}


export interface PrinterRule extends EntityDataBaseInteractive {
  tenantId: number;
  customerDetailId: number;
  userId: number;
  addressId: number;
  productId: number;
  path: string;
  priority: number;
  isEnabled: boolean;
  printingTypeCode: string;
  printerTypeCode: string;
  printerLanguageCode: string;
  printerName: string;
  dpi: number;
  paperSize: string;
  leftOffset: number;
  topOffset: number;
  firstLabelPosition: number;
  localFolder: string;
}


export interface ShipmentsImportPath extends EntityDataBaseInteractive {
  tenantId: number;
  customerDetailId: number;
  path: string;
  importProfileId: number;
  useSemaphoreFiles: boolean;
}


export const ProtocolsPrintingTypeCode = "Protocol";
export const LabelsPrintingTypeCode = "Label";


export class ShipmentsImportPathEditorDialogContext extends ModalOpenContext {
  constructor(
    public shipmentsImportPath: ShipmentsImportPath,
    public defaultImportProfile: ImportProfile,
    public importProfiles: ImportProfile[]
  ) {
    super();
  }
}


export class SyncShipmentsTimeMode {
  public static readonly SpecificTime = "SpecificTime";
  public static readonly NMinutesAfterSave = "NMinutesAfterSave";
  public static readonly Continually = "Continually";
  public static readonly Manually = "Manually";
}


export class AutomatedShipmentsExport {
  public static readonly Continually = "Continually";
  public static readonly ContinuallyWithPrintedProtocol = "ContinuallyWithPrintedProtocol";
  public static readonly ExportWithEodProtocolPrinting = "ExportWithEodProtocolPrinting";
  public static readonly None = "None";
  public static readonly SpecificTime = "SpecificTime";
}


export class BankAccountsUploadDialogModalContext extends ModalOpenContext {
  constructor(
    public customerDetailId: number,
    public tenantId: number,
    public bankAccountId: number
  ) { super(); }
}

export class SyncShipmentsEndpoint {
  public static readonly Ftp = "(S)FTP";
  public static readonly Api = "API";
}
