<shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
<div class="content-panel settings">
  <div class="settings-categories-menu" *ngIf="settings">
    <div class="d-flex align-items-center">
      <h1>{{ 'settings' | shpLocalize }}</h1>
      <button *shpPollyDialogTrigger="pollyCollection" id="settings-faq" class="clear-round-button" title="{{ 'action_open_faq_dialog' | shpLocalize }}"><span class="ic-interface-question-mark"></span></button>
    </div>
    <ul>
      <li *ngIf="isBasicSettingsAvailable" wizardTag="settingsNavBasic" [wizardCompleteStep]="wizardStepNames.ClickBasicSettings"><a [routerLink]="['/settings/basic']" [routerLinkActive]="['selected']">{{ 'settings_basic' | shpLocalize }}</a></li>
      <li *ngIf="isPrintSettingsAvailable" wizardTag="settingsNavPrint" [wizardCompleteStep]="wizardStepNames.ClickPrintSettings"><a [routerLink]="['/settings/print']" [routerLinkActive]="['selected']">{{ 'settings_print' | shpLocalize }}</a></li>
      <li *ngIf="isPrinterRulesSettingsAvailable"><a [routerLink]="['/settings/printer-rules']" [routerLinkActive]="['selected']">{{ 'settings_printer_rules' | shpLocalize }}</a></li>
      <li *ngIf="isSyncSettingsAvailable"><a [routerLink]="['/settings/sync']" [routerLinkActive]="['selected']">{{ 'settings_sync' | shpLocalize }}</a></li>
      <li *ngIf="isAutomaticExportSettingsAvailable"><a [routerLink]="['/settings/automatic-export']" [routerLinkActive]="['selected']">{{ 'settings_automatic_export' | shpLocalize }}</a></li>
      <li *ngIf="isEodExportSettingsAvailable"><a [routerLink]="['/settings/eod-export']" [routerLinkActive]="['selected']">{{ 'settings_eod_export' | shpLocalize }}</a></li>
      <li *ngIf="isAutomaticImportSettingsAvailable"><a [routerLink]="['/settings/automatic-import']" [routerLinkActive]="['selected']">{{ 'settings_automatic_import' | shpLocalize }}</a></li>
      <li *ngIf="isAutomaticRecImportSettingsAvailable"><a [routerLink]="['/settings/automatic-recipients-import']" [routerLinkActive]="['selected']">{{ 'settings_automatic_rec_import' | shpLocalize }}</a></li>
      <li *ngIf="isRoutingSettingsAvailable"><a [routerLink]="['/settings/routing']" [routerLinkActive]="['selected']">{{ 'settings_routing' | shpLocalize }}</a></li>
      <li *ngIf="isPickupAddressesSettingsAvailable"><a [routerLink]="['/settings/pickup-addresses']" [routerLinkActive]="['selected']">{{ 'settings_pickup_addresses' | shpLocalize }}</a></li>
      <li *ngIf="isBankAccountsSettingsAvailable"><a [routerLink]="['/settings/bank-accounts']" [routerLinkActive]="['selected']">{{ 'settings_bank_accounts' | shpLocalize }}</a></li>
      <li *ngIf="isConfigurationSettingsAvailable"><a [routerLink]="['/settings/configurations']" [routerLinkActive]="['selected']">{{ 'settings_configuration' | shpLocalize }}</a></li>
      <li *ngIf="isUserManagementAvailable" wizardTag="settingsNavUserManagement" [wizardCompleteStep]="wizardStepNames.ClickUserManagement"><a [routerLink]="['/settings/user-management']" [routerLinkActive]="['selected']">{{ 'user_management' | shpLocalize }}</a></li>
      <li><a [routerLink]="['/settings/password']" [routerLinkActive]="['selected']">{{ 'change_password' | shpLocalize }}</a></li>
      <li *ngIf="isInfoSettingsAvailable"><a [routerLink]="['/settings/info']" [routerLinkActive]="['selected']">{{ 'settings_info' | shpLocalize }}</a></li>
      <li *ngIf="isInstructionsAvailable"><a [routerLink]="['/settings/instructions']" [routerLinkActive]="['selected']">{{ 'settings_instructions' | shpLocalize }}</a></li>
      <li *ngIf="isLogAvailable"><a [routerLink]="['/settings/log']" [routerLinkActive]="['selected']">{{ 'settings_log' | shpLocalize }}</a></li>
      <li *ngIf="isBackupRestoreAvailable"><a [routerLink]="['/settings/backup-restore']" [routerLinkActive]="['selected']">{{ 'settings_backup_restore' | shpLocalize }}</a></li>
      <li *ngIf="isEmailTemplatesEditorAvailable && isEmailTemplatesEditorVisible"><a [routerLink]="['/settings/email-templates-editor']" [routerLinkActive]="['selected']">{{ 'email_templates_editor' | shpLocalize }}</a></li>
      <li *ngIf="isAdmin && !isCentralShipper"><a [routerLink]="['/settings/email-server-configuration']" [routerLinkActive]="['selected']">{{ 'email_server_configuration' | shpLocalize }}</a></li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>