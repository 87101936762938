import { Routes } from "@angular/router"

import { ImportProfilesComponent } from "./components/import-profiles.component"
import { ListComponent as DynamicImportsListComponent } from "./components/list.component"
import { EditorComponent as DynamicImportsEditorComponent } from "./components/editor.component"

import { AuthorizationGuard } from "../shared/authorization-guard"
import { UnsavedChangesGuard } from "../shared/unsaved-changes-guard"

import { TransformationsListComponent } from "./components/transformations-list.component";
import { TransformationEditorComponent } from "./components/transformation-editor.component";


export const IMPORT_PROFILES_ROUTES: Routes = [
  {
    path: "import-profiles",
    component: ImportProfilesComponent,
    canActivate: [AuthorizationGuard],
    children: [
      {
        path: "shipmentsCR",
        children: [
          { path: "list", component: DynamicImportsListComponent, canActivate: [AuthorizationGuard] },
          { path: "new", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
          { path: "view/:importProfileId", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard] },
          { path: "edit/:importProfileId", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
          { path: "", redirectTo: "list", pathMatch: "full" }
        ]
      },
      {
        path: "shipments",
        children: [
          { path: "list", component: DynamicImportsListComponent, canActivate: [AuthorizationGuard] },
          { path: "new", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
          { path: "view/:importProfileId", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard] },
          { path: "edit/:importProfileId", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
          { path: "", redirectTo: "list", pathMatch: "full" }
        ]
      },
      {
        path: "recipients",
        children: [
          { path: "list", component: DynamicImportsListComponent, canActivate: [AuthorizationGuard] },
          { path: "new", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
          { path: "view/:importProfileId", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard] },
          { path: "edit/:importProfileId", component: DynamicImportsEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
          { path: "", redirectTo: "list", pathMatch: "full" }
        ]
      },
      {
        path: "transformations",
        children: [
          { path: "list", component: TransformationsListComponent, canActivate: [AuthorizationGuard] },
          { path: ":id", component: TransformationEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
          { path: "", redirectTo: "list", pathMatch: "full" }
        ]
      },
      { path: "", redirectTo: "shipments", pathMatch: "full" }
    ]
  }
];