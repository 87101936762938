<div class="top">
  <h1>{{ 'sender_scan' | shpLocalize }}</h1>

  <button id="button-back-top" class="top-button" (click)="goBackToList()"><span class="ic-arrow-left-1"></span><span class="label">{{ 'recipient_back_to_list' | shpLocalize }}</span></button>
</div>

<div class="content-panel shipments" id="senderscan">

  <form #f="ngForm" name="f" [class.submitted]="f.submitted" (submit)="getShipments()" novalidate>
    <div id="senderscan-form">
      <div class="form-field">
          <label for="sender-scan-value">{{ 'scanned_value' | shpLocalize }}</label>
          <input [(ngModel)]="senderScanValue" type="text" class="light" id="sender-scan-value" name="sender_scan_value" [shpFocus]="focusTriggeringEvent" />
      </div>
      <div class="form-field">
        <label for="sender-scan-value-type">{{ 'value_type' | shpLocalize }}</label>
        <select [(ngModel)]="senderScanValueType" (ngModelChange)="saveFormDefaults()" id="sender-scan-value-type" name="sender_scan_value_type">
          <option [ngValue]="''">-</option>
          <option *ngFor="let type of senderScanValueTypes" [ngValue]="type.code">{{ type.name | shpLocalize }}</option>
        </select>
      </div>
      <div class="form-field">
          <label for="sender-scan-action">{{ 'predefined_action' | shpLocalize }}</label>
          <select [(ngModel)]="senderScanActionType" (ngModelChange)="saveFormDefaults()" id="sender-scan-action-type" name="sender_scan_action_type">
            <option [ngValue]="''">-</option>
            <option *ngFor="let action of senderScanActionTypes" [ngValue]="action.code">{{ action.name | shpLocalize }}</option>
          </select>
      </div>
      <div class="spacer"></div>
      <div class="form-field">
        <button id="process" type="submit" [disabled]="this.formInvalid()"><span class="ic-sc"></span> {{ 'execute' | shpLocalize }}</button>
      </div>
    </div>
  </form>

  <div class="shipments-list list">
    <div class="items" [class.multi-select]="isMultiselectMode">
        <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>

        <div class="scroll-part" [class.has-pager]="filter.pageSize < totalShipmentsCount">
          <div class="shipment list-item" *ngFor="let shipment of shipments | async; let i = index" [class.odd]="i % 2 === 1"
                [class.all-labels-generated]="shipment.areAllLabelsGenerated && !shipment.isCollectionRequest || shipment.stateId === 'ReadyForExport'"
                [class.acceptance-protocol-generated]="(shipment.areAllAcceptanceProtocolsGenerated && !shipment.isCollectionRequest) || shipment.stateId === 'ReadyForExport'"
                [class.sent]="shipment.stateId === 'Transport'">
            <div class="selector">
              <!-- <input tabindex="-1" type="checkbox" [ngModel]="shipment.isSelected" (change)="selectionChanged($event, shipment)" id="shipment-is-selected-{{ shipment.id }}" /><label [attr.for]="'shipment-is-selected-' + shipment.id"></label> -->
            </div>
            <div class="content">
              <div class="shipment-details">
                <div class="shipment-state">
                  <span [class.labels-printed]="shipment.stateId === 'LabelsPrinted'"
                  [class.ready-for-export]="shipment.stateId === 'ReadyForExport'"
                  [class.transport]="shipment.stateId === 'Transport'"
                  class="state">{{ 'order_state_' + shipment.stateId | shpLocalize }}</span>
                </div>
                <div>
                  <!-- <div *ngIf="shipment.isCollectionRequest">{{ 'shipment_filter_collection_request' | shpLocalize }} </div> -->
                  <!-- <div *ngIf="!shipment.isCollectionRequest"> -->
                  <div>
                    {{ shipment.productName }}
                    <span *ngIf="shipment.additionalServiceNames.length > 0">
                      + <span *ngFor="let name of shipment.additionalServiceNames; let i = index"><span *ngIf="i > 0">, </span>{{ name }}</span>
                    </span>
                  </div>
                  <div><span class="ic-18 ic-shipping-truck-delivery-time"></span> {{ shipment.pickupDate | shpLocalizeDate:"L" }} ({{ 'order_created_on' | shpLocalize }}: {{ shipment.creationDateTimeUtc | shpLocalizeDate }})</div>
                </div>
              </div>

              <div class="addresses">
                <div class="address">
                  <h2>{{ 'shipment_sender' | shpLocalize }}</h2>
                  <h3>{{shipment.senderName}}</h3>
                  <span>{{shipment.senderStreet}}, {{shipment.senderZip}}, {{shipment.senderCity}}, {{ shipment.senderCountryName }}</span>
                </div>
                <div class="address">
                  <h2>{{ 'shipment_receiver' | shpLocalize }}</h2>
                  <h3>{{shipment.receiverName}}</h3>
                  <span>{{shipment.receiverStreet}}, {{shipment.receiverZip}}, {{shipment.receiverCity}}, {{ shipment.receiverCountryName }}, {{ shipment.receiverReference }}</span>
                </div>
              </div>

              <div *ngIf="shipment.isCollectionRequest">
                <h2>{{ 'shipment_parcels' | shpLocalize }}: {{ shipment.parcelsCount }}</h2>
              </div>

              <div *ngIf="!shipment.isCollectionRequest">
                <h2>{{ 'shipment_parcels' | shpLocalize }}</h2>
                <div class="shipment-parcel-numbers">
                  <div class="parcel" *ngFor="let parcel of shipment.parcels">
                    <a href="{{ getTrackingByParcelNrUrl(parcel.parcelNr) }}" target="_blank" class="parcel-number-label">{{ parcel.parcelNr }}</a>
                    <span class="parcel-ref-info">
                      <span class="reference" *ngIf="parcel.referentialInfo1"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo1) }}" target="_blank">{{ parcel.referentialInfo1 }}</a></span>
                      <span class="reference" *ngIf="parcel.referentialInfo2"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo2) }}" target="_blank">{{ parcel.referentialInfo2 }}</a></span>
                      <span class="reference" *ngIf="parcel.referentialInfo3"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo3) }}" target="_blank">{{ parcel.referentialInfo3 }}</a></span>
                      <span class="reference" *ngIf="parcel.referentialInfo4"><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo4) }}" target="_blank">{{ parcel.referentialInfo4 }}</a></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="actions">
              <button id="button-view-edit-shipment" class="right-button" type="button" (click)="viewOrEditShipmentOrderById(shipment.id)"><span class="ic-box-edit"></span><span class="label">{{ 'shipment_edit' | shpLocalize }}</span></button>
              <button id="button-print-labels" class="right-button" type="button" (click)="startLabelsGeneration(shipment)"><span class="ic-printer"></span><span class="label">{{ 'shipment_print_labels' | shpLocalize }}</span></button>
            </div>
          </div>
        </div>
        <div class="pager-part" *ngIf="filter.pageSize < totalShipmentsCount">
          <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
          <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(filter.pageIndex - 1)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
          <button id="button-pager-next" class="next" type="button" (click)="goToPage(filter.pageIndex + 1)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
          <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
          <span class="numbers">{{ filter.pageIndex * filter.pageSize + 1 }} - {{ (filter.pageIndex + 1) * filter.pageSize < totalShipmentsCount ? (filter.pageIndex + 1) * filter.pageSize : totalShipmentsCount }}/{{ totalShipmentsCount }}</span>
        </div>
        <!-- <div class="multi-select-commands">
          <button id="button-print-labels-selected" type="button" class="bottom-button" (click)="printLabelsForSelected()"><span class="ic-printer"></span><span class="label">{{ 'shipments_print_labels_for_selected' | shpLocalize }}</span></button>
        </div> -->

        <div class="list-empty-message" *ngIf="totalShipmentsCount === 0">
          <div class="message-content">
            {{ 'message_no_shipment_orders_in_db' | shpLocalize }}
          </div>
        </div>
      </div>
  </div>

</div>
