<div class="settings-category">
  <h1>{{ 'settings_info' | shpLocalize }}</h1>
  <table class="settings-info-values">
    <tbody>
      <tr>
        <td>{{ 'application_version' | shpLocalize }}</td>
        <td>{{ appVersion }}</td>
      </tr>
      <tr *ngIf="!isCentralShipper">
        <td>{{ 'installation_id' | shpLocalize }}</td>
        <td *ngIf="installationId > 0">{{ installationId }}</td>
        <td *ngIf="installationId === 0">{{ 'installation_id_unknown' | shpLocalize }}</td>
      </tr>
      <tr *ngIf="!isCentralShipper">
        <td>{{ 'application_mode' | shpLocalize }}</td>
        <td><span *ngIf="!settings?.disable_online_mode.value">{{ 'online' | shpLocalize }}</span><span *ngIf="settings?.disable_online_mode.value">{{ 'offline' | shpLocalize }}</span></td>
      </tr>
    </tbody>
  </table>
</div>