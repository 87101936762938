import { Component, OnInit, OnDestroy } from "@angular/core"

import { combineLatest, Subject } from "rxjs"
import { map, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index"
import { ApiHeartbeatService, NewsfeedService } from "../../shared/index";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-unread-notifications-counter",
  templateUrl: "./unread-notifications-counter.component.html",
})
export class UnreadNotificationsCounterCompoenent implements OnInit, OnDestroy {
  public count = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private heartbeatService: ApiHeartbeatService,
    private newsfeedService: NewsfeedService) { }

    public ngOnInit(): void {
      combineLatest([
        this.heartbeatService.unreadCount$,
        this.newsfeedService.unreadCount$,
        // (hCount, nCount) => hCount + nCount
      ]).pipe(
        map(([hCount, nCount]: [number, number]) => hCount + nCount),
        takeUntil(this.destroy$)
      ).subscribe(count => this.count = count);
    }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}