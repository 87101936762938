import { Pipe, PipeTransform } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"

import { LocalizationService, SELECTOR_PREFIX } from "../../shared/index"
import { NotificationMessage } from "../models/notifications.model"


@Pipe({ name: SELECTOR_PREFIX + "LocalizeNotificationMessage" })
export class LocalizeNotificationMessagePipe implements PipeTransform {
  public transform(value, ...args: any[]) {

    if (typeof value === "undefined" || value == null) {
      return null;
    }

    try {
      const message: NotificationMessage = JSON.parse(value);

      let localized = this._localizationService.getLocalizedString(message.key, ...message.params);
      localized = localized.replace(/(\\n)/g, "<br />");
      localized = localized.replace(/(?:\r\n|\r|\n)/g, "<br />");

      return this._domSanitizer.bypassSecurityTrustHtml(localized);
      //return localized;
    } catch (e) {
      return value;
    }
  }


  constructor(
    private _localizationService: LocalizationService,
    private _domSanitizer: DomSanitizer
  ) {

  }
}