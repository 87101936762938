import { Component } from "@angular/core";
import { Observable } from "rxjs";

import * as Shared from "../../shared/index";
import { AppContacts } from "../models/app-contacts.model";
import { SupportService } from "../services/support.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-contact",
  templateUrl: "./contact.component.html"
})
export class ContactComponent {
  info: AppContacts = {};

  constructor(private supportService: SupportService) { }

  public ngOnInit() {
    this.supportService.loadContactInfo().subscribe(result => this.info = result, () => { });
  }
}
