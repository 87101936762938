import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { saveAs } from "file-saver";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";

import * as Shared from "../../shared/index";
import { ImportProfileTransformation } from "../models/transformations.models";
import { ImportProfilesService } from "../services/import-profiles.service";
import { TransformationImportDialogComponent } from "./transformation-import-dialog.component";
import { LoggingService, RoutedPageComponentBase, GlobalEventsStreamService, LocalizationService, AuthenticationService, ExceptionsHandlerService } from "../../shared/index";


@Component({
  templateUrl: "./transformations-list.component.html"
})
export class TransformationsListComponent extends RoutedPageComponentBase implements OnInit {
  public allowAddingNewImportProfiles = false;

  private _transformations: ImportProfileTransformation[] = null;
  private _isBusy = false;

  constructor(
    loggingService: LoggingService,
    globalEventsStreamService: GlobalEventsStreamService,
    localizationService: LocalizationService,
    authenticationService: AuthenticationService,
    router: Router,
    private _exceptionsHandlerService: ExceptionsHandlerService,
    private _importProfilesService: ImportProfilesService,
    private _modal: Modal,
    private _toastr: ToastrService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public get isBusy() { return this._isBusy; }

  public get transformations() { return this._transformations; }


  public ngOnInit() {
    this._businessUnitSettingsService.getDisallowAddingNewImportProfiles()
      .subscribe(value => this.allowAddingNewImportProfiles = !value);

    this.loadTransformations();
  }


  private loadTransformations() {
    this._isBusy = true;

    this._importProfilesService.getTransformations().subscribe(transformations => {
      this._transformations = transformations;
      this._isBusy = false;
    }, ex => {
      const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(exceptionInfo, "Error loading transformations.");
      this._isBusy = false;
    });
  }


  public delete(transformation: ImportProfileTransformation) {
    this._modal
      .confirm()
      .message(this.localizationService.getLocalizedString("confirm_transformation_deleting"))
      .okBtn(this.localizationService.getLocalizedString("yes"))
      .cancelBtn(this.localizationService.getLocalizedString("no"))
      .open()
      .result.then(confirmed => {
        if (confirmed) {
          this._isBusy = true;
          this._importProfilesService.deleteTransformation(transformation.id).subscribe(() => {
            this._isBusy = false;

            this._toastr.success(this.localizationService.getLocalizedString("transfiormation_successfully_deleted"));

            this.loadTransformations();
          }, ex => {
            const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
            this.loggingService.logErrorData(exceptionInfo, "Error deleting transformation.");

            this._toastr.error(this.localizationService.getLocalizedString("error_deleting_transformation") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
            this._isBusy = false;
          });
        }
      }, ex => { });

  }


  public export(transformation: ImportProfileTransformation) {
    this._isBusy = true;

    this._importProfilesService.getTransformationExport(transformation.id)
      .subscribe(exportModel => {
        this._isBusy = false;

        // Máme aj obsah... Asi...
        if (exportModel.outputText && exportModel.outputText.length > 0) {
          // Musíme vygenrovať BLOB a uložiť ho.
          const filename = `transformation-${transformation.code}.csv`;
          const blob = new Blob(["\ufeff", exportModel.outputText], { type: "text/csv;charset=utf-8" });

          saveAs(blob, filename);
        }
      },
      ex => {
        const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(ex, "Recipients export failed.");
        this._toastr.error(this.localizationService.getLocalizedString("error_exporting_transformation") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
        this._isBusy = false;
      });
  }


  public import() {
    this._modal
      .open(TransformationImportDialogComponent, overlayConfigFactory({}))
      .result.then(wasAnythingImported => {
        if (wasAnythingImported) {
          this.loadTransformations();
        }
      }, ex => { });
  }
}
