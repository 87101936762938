import { Component, OnInit } from "@angular/core";

import { DialogRef, ModalComponent } from "ngx-modialog-7";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { finalize, map, take } from "rxjs/operators";

import { SettingsService } from "../../settings/services/settings.service";
import { UpdateInfo } from "../models/update-info.model";
import { AuthenticationService } from "../services/authentication.service";
import { LocalizationService } from "../services/localization.service";
import { ShipperTenantVersionInfoService } from "../services/shipper-tenant-version-info.service";
import { UpdateService } from "../services/update.service";

@Component({
  templateUrl: "./app-update-info-dialog.component.html"
})
export class AppUpdateInfoDialog implements OnInit, ModalComponent<{updateInfo: UpdateInfo, isDeprecatedView: boolean}> {
  isBusy = false;
  isManualDownloadShown = false;

  private _updateContent: string;
  private _canUpdate = false;

  constructor(
    public dialog: DialogRef<{updateInfo: UpdateInfo, isDeprecatedView: boolean}>,
    private _authenticationService: AuthenticationService,
    private _localizationService: LocalizationService,
    private _updateService: UpdateService,
    private _settingsService: SettingsService,
    private _ShipperTenantVersionInfoService: ShipperTenantVersionInfoService,
    private _toastr: ToastrService) { }

  get version(): string {
    return this.updateInfo.version;
  }

  get updateInfo(): UpdateInfo {
    return this.dialog.context.updateInfo;
  }

  get isDeprecatedView(): boolean {
    return this.dialog.context.isDeprecatedView;
  }

  get hasUpdateConent(): boolean {
    return this._updateContent != null && this._updateContent.length > 0;
  }

  get updateContent(): string {
    return this._updateContent;
  }

  get canUpdate(): boolean {
    return this._canUpdate;
  }

  ngOnInit() {
    this.isBusy = true;

    combineLatest([
      this._authenticationService.isAdmin$.pipe(take(1)),
      this._settingsService.activeConfigurationSettings$.pipe(take(1)),
    ]).pipe(
      map(([isAdmin, settings]) =>
          isAdmin && settings.can_only_admin_update_shipper.value || !settings.can_only_admin_update_shipper.value),
      finalize(() => this.isBusy = false)
    ).subscribe(canUpdate => this._canUpdate = canUpdate);

    this._updateContent = this._ShipperTenantVersionInfoService.getChangeLogContent(this.updateInfo.versionInfoTexts);

    this.isManualDownloadShown = !this.hasUpdateConent;
  }

  install() {
    this.isBusy = true;

    this._updateService.startInstallation()
      .subscribe({
        next: isSuccess => {
          this.isBusy = false;

          if (isSuccess) {
            this.dialog.close(true);
          } else {
            this.dialog.close(false);

            this._toastr.error(this._localizationService.getLocalizedString("auto_install_failed"));
          }
        },
        error: (e) => {
          this.isBusy = false;
          console.error(e);
        }
      });
  }

  close() {
    this.dialog.close(false);
  }

  toogleManualDownload() {
    this.isManualDownloadShown = !this.isManualDownloadShown;
  }
}
