<div class="set-pickup-date-dialog dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
  <h1 [innerHtml]="'set_pickup_date' | shpLocalize"></h1>
  <!--<div class="message error" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>-->
  <div class="ctl">
    <div class="p-field p-field--full">
      <span class="p-float-label">
        <p-calendar [(ngModel)]="newPickupDate" [showIcon]="true" inputId="new-pickup-date" dateFormat="dd/mm/yy" (onMonthChange)="onPickupMonthChanged($event)" #ccc [disabledDates]="_unavailableDays" [disabledDays]="disabledDays" [minDate]="minDate" icon="ic-calendar-1" name="new-pickup-date"></p-calendar>
        <label for="new-pickup-date">{{ 'new_pickup_date' | shpLocalize }}</label>
      </span>
    </div>
  </div>
  <div class="buttons">
    <button id="button-set-pickup-date" type="button" (click)="setPickupDate()">{{ 'set' | shpLocalize }}</button>
    <button id="button-close" type="button" (click)="close()">{{ 'cancel' | shpLocalize }}</button>
  </div>
</div>
