<h2>{{ 'customer' | shpLocalize }}</h2>
<!-- <h2>{{ 'customer' | shpLocalize }} <button id="button-clear-recipient" *ngIf="!isReadOnly" type="button" class="clear-recipient-buttom" (click)="clearCustomer()"><span class="ic-close"></span></button></h2> -->

<form [formGroup]="form" [class.form-ignore-ng-invalid]="isAddressReadonly" entityValidatorPrefix="dpd_api_customer_">
  <div class="mdl-grid compact">
    <div class="ctl mdl-cell mdl-cell--12-col d-flex align-items-stretch">
      <div class="p-field flex-grow-1">
        <span class="p-float-label">
          <input id="dpd-api-customer-search"
            [(ngModel)]="customerNumberQuery"
            [ngModelOptions]="{standalone: true}"
            type="text"
            name="dpd_api_customer_customer_number"
            pInputText
            autocomplete="off"
            [readonly]="isReadOnly">
          <label for="dpd-api-customer-search">{{ 'customer_number' | shpLocalize }}</label>
        </span>
      </div>
      <div *ngIf="!isReadOnly">
        <button
          type="button"
          class="h-100"
          (click)="findCustomerAddressByCustomerNumber()"
          [disabled]="!canSearch">{{ 'action_search' | shpLocalize }}</button>
      </div>
    </div>
  </div>

  <h2>{{ 'customer_address' | shpLocalize }}</h2>

  <!-- TODO: error / no result message -->

  <div class="mdl-grid compact">
    <!-- name -->
    <div class="ctl mdl-cell mdl-cell--12-col" [class.required]="!isReadOnly && !isAddressReadonly">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input type="text" formControlName="name" maxlength="{{ disableValidation ? 100 : 35 }}" id="dpd-api-customer-name" name="dpd_api_customer_address_name" pInputText [readonly]="isReadOnly || isAddressReadonly" autocomplete="off"/>
          <label for="dpd-api-customer-name2">{{ 'recipient_organization_name' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <!-- name2 -->
    <!-- <div class="ctl mdl-cell mdl-cell--12-col">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="dpd-api-customer-name2" formControlName="name2" type="text" name="dpd_api_customer_address_name2" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly || isAddressReadonly" autocomplete="off">
          <label for="dpd-api-customer-name2">{{ 'recipient_organization_name' | shpLocalize }} 2</label>
        </span>
      </div>
    </div> -->

    <!-- country -->
    <div *ngIf="!isReadOnly && !isAddressReadonly" class="ctl mdl-cell mdl-cell--12-col required">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <p-dropdown
            id="dpd-api-customer-country"
            name="dpd_api_customer_address_country"
            [options]="countries"
            formControlName="country"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [autoDisplayFirst]="false"
            [showClear]="true"
            [readonly]="isReadOnly || isAddressReadonly">
            <ng-template let-country pTemplate="item">
              <div class="country-item">
                <div>{{country.name}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <label for="dpd-api-customer-country">{{ 'recipient_country' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <div *ngIf="isReadOnly || isAddressReadonly" class="ctl mdl-cell mdl-cell--12-col">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="dpd-api-customer-email" type="text" [value]="form.get('country')?.value ? form.get('country')?.value?.name : ''" pInputText readonly="readonly">
          <label for="dpd-api-customer-email">{{ 'recipient_country' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <!-- lang code -->
    <!-- <div *ngIf="!isReadOnly && !isAddressReadonly" class="ctl mdl-cell mdl-cell--4-col">
      <div class="p-field p-field--full">
        <span class="p-float-label" dpdFloatingLabel>
          <select formControlName="languageCode" id="dpd-api-customer-language-code" name="dpd_api_customer_address_language_code">
            <option [ngValue]="''">-</option>
            <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
          </select>
          <label for="dpd-api-customer-language-code">{{ 'language' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <div *ngIf="isReadOnly || isAddressReadonly" class="ctl mdl-cell mdl-cell--4-col">
      <input type="text" [value]="form.get('languageCode')?.value ? ('language_' + form.get('languageCode')?.value | shpLocalize) : ''" readonly="readonly"/>
    </div> -->

    <!-- zip -->
    <div class="ctl mdl-cell mdl-cell--6-col" [class.required]="!isReadOnly && !isAddressReadonly">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <p-autoComplete
            id="dpd-api-customer-zip"
            formControlName="zip"
            [suggestions]="filteredZips"
            (completeMethod)="filterZip($event)"
            (onSelect)="onZipSelected($event)"
            name="dpd_api_customer_address_zip"
            [minLength]="1"
            autocomplete="none"
            [readonly]="isReadOnly || isAddressReadonly">
            <ng-template let-zip pTemplate="item">
              <div class="zip-item">
                <div><b>{{zip.zip}}</b></div>
                <div><small>{{zip.cityName}}</small></div>
              </div>
            </ng-template>
          </p-autoComplete>
          <label for="dpd-api-customer-zip">{{ 'recipient_zip' | shpLocalize }}</label>
        </span>
        <em class="error" *ngIf="form.get('zip')?.hasError('zip')">{{ 'error_zip_invalid' | shpLocalize }}</em>
        <em class="error" *ngIf="form.get('zip')?.hasError('zipFormat')">{{ 'error_zip_expected_format' | shpLocalize:form.get('zip')?.errors?.zipFormat.expectedFormat }}</em>
        <em class="error" *ngIf="form.get('zip')?.hasError('noSpaces')">{{'address_zip_cannot_contain_spaces' | shpLocalize }}</em>
      </div>
    </div>

    <!-- house # -->
    <div class="ctl mdl-cell mdl-cell--6-col">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="dpd-api-customer-house-nr" formControlName="houseNr" type="text" name="dpd_api_customer_address_house_nr" maxlength="{{ disableValidation ? 100 : 8 }}" pInputText [readonly]="isReadOnly || isAddressReadonly" autocomplete="off">
          <label for="dpd-api-customer-house-nr">{{ 'recipient_house_number' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <!-- street -->
    <div class="ctl mdl-cell mdl-cell--6-col" [class.required]="!isReadOnly && !isAddressReadonly">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="dpd-api-customer-street" formControlName="street" type="text" name="dpd_api_customer_address_street" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly || isAddressReadonly" autocomplete="off">
          <label for="dpd-api-customer-street">{{ 'recipient_street' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <!-- city -->
    <div class="ctl mdl-cell mdl-cell--6-col" [class.required]="!isReadOnly && !isAddressReadonly">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <p-autoComplete
            id="dpd-api-customer-city"
            formControlName="city"
            [suggestions]="filteredCities"
            (completeMethod)="filterCities($event)"
            maxlength="{{ disableValidation ? 100 : 35 }}"
            name="dpd_api_customer_address_city"
            [minLength]="2"
            autocomplete="none"
            [readonly]="isReadOnly || isAddressReadonly"></p-autoComplete>
          <label for="dpd-api-customer-city">{{ 'recipient_city' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <!-- sreet detail -->
    <!-- <div class="ctl mdl-cell mdl-cell--12-col">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="dpd-api-customer-street-detail" formControlName="streetDetail" type="text" name="dpd_api_customer_address_street_detail" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly || isAddressReadonly" autocomplete="off">
          <label for="dpd-api-customer-street-detail">{{ 'recipient_street_detail' | shpLocalize }}</label>
        </span>
      </div>
    </div> -->

    <!-- email -->
    <div *ngIf="!isReadOnly || !isAddressReadonly || form.get('email')?.value" class="ctl mdl-cell mdl-cell--12-col">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="dpd-api-customer-email" formControlName="email" type="text" name="dpd_api_customer_address_email" maxlength="50" pInputText [readonly]="isReadOnly || isAddressReadonly">
          <label for="dpd-api-customer-email">{{ 'recipient_email' | shpLocalize }}</label>
        </span>
        <em id="dpd-api-customer-emai-help" class="p-error error" *ngIf="form.get('email')?.touched && form.get('email')?.hasError('invalidEmail')">{{ 'invalid_email' | shpLocalize }}</em>
      </div>
    </div>

    <!-- phone -->
    <div *ngIf="!isReadOnly || !isAddressReadonly || form.get('phone')?.value" class="ctl mdl-cell mdl-cell--12-col">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="dpd-api-customer-phone" formControlName="phone" type="text" name="dpd_api_customer_address_phone" maxlength="{{ disableValidation ? 100 : 30 }}" pInputText [readonly]="isReadOnly || isAddressReadonly">
          <label for="dpd-api-customer-phone">{{ 'recipient_phone' | shpLocalize }}</label>
        </span>
      </div>
    </div>
  </div>
</form>
