<div class="settings-category">
  <h1>{{ 'settings_log' | shpLocalize }}</h1>

  <shp-progress-overlay [isActive]="isBusy$ | async"></shp-progress-overlay>

  <div class="log-filter">
    <div class="event-code">
      <!-- <input id="input-event-code" type="text" placeholder="{{ 'text_in_log_entry_code' | shpLocalize }}" [(ngModel)]="eventCodeFilter" /> -->
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="input-event-code" type="text" [(ngModel)]="eventCodeFilter" pInputText name="event_code">
          <label for="input-event-code">{{ 'text_in_log_entry_code' | shpLocalize }}</label>
        </span>
      </div>
    </div>
    <div class="event-categories">
      <!-- <wj-multi-select [(itemsSource)]="categoriesFilter"
                       [headerFormat]="'{count}'"
                       [displayMemberPath]="'text'"
                       [checkedMemberPath]="'isSelected'"
                       placeholder="{{ 'event_category' | shpLocalize }}"
                       [headerFormatter]="getSelectedCategoriesText"
                       #categoriesMultiselect>
      </wj-multi-select> -->
      <div class="p-field">
        <span class="p-float-label">
          <p-multiSelect [options]="categoriesFilter" [(ngModel)]="usedCategoriesFilter" id="event_category" optionLabel="text" optionValue="value" [filter]="false"></p-multiSelect>
          <label for="event_severit">{{ 'event_category' | shpLocalize }}</label>
        </span>
      </div>
    </div>
    <div class="event-severities">
      <!-- <wj-multi-select [(itemsSource)]="severitiesFilter"
                       [headerFormat]="'{count}'"
                       [displayMemberPath]="'text'"
                       [checkedMemberPath]="'isSelected'"
                       placeholder="{{ 'event_severity' | shpLocalize }}"
                       [maxHeaderItems]="6"
                       [headerFormatter]="getSelectedSeveritiesText"
                       #severitiesMultiselect>
      </wj-multi-select> -->
      <div class="p-field">
        <span class="p-float-label">
          <p-multiSelect id="event_severit" [options]="severitiesFilter" [(ngModel)]="usedSeveritiesFilter" optionLabel="text" optionValue="value" [filter]="false"></p-multiSelect>
          <label for="event_severit">{{ 'event_severity' | shpLocalize }}</label>
        </span>
      </div>
    </div>
    <div class="event-datetime">
      <div class="p-field">
        <span class="p-float-label">
          <p-calendar [(ngModel)]="timestampFrom" [showIcon]="true" inputId="timestampFrom" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="15" icon="ic-calendar-1"></p-calendar>
          <label for="timestampFrom">{{ 'date_and_time_from' | shpLocalize }}</label>
        </span>
      </div>
      <!-- <wj-input-date-time [(value)]="timestampFrom" [isRequired]="false" placeholder="{{ 'date_and_time_from' | shpLocalize }}"></wj-input-date-time> -->
    </div>
    <div class="event-datetime">
      <!-- <wj-input-date-time [(value)]="timestampTo" [isRequired]="false" placeholder="{{ 'date_and_time_to' | shpLocalize }}"></wj-input-date-time> -->
      <div class="p-field">
        <span class="p-float-label">
          <p-calendar [(ngModel)]="timestampTo" [showIcon]="true" inputId="timestamp" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="15" icon="ic-calendar-1"></p-calendar>
          <label for="timestamp">{{ 'date_and_time_to' | shpLocalize }}</label>
        </span>
      </div>
    </div>
    <div class="commands">
      <button id="button-apply-filter" type="button" class="icon-button" (click)="applyFilter()" title="{{ 'apply_filter' | shpLocalize }}"><span class="ic-content-filter"></span></button>
      <button id="button-clear-filter" type="button" class="icon-button" (click)="clearFilter()" title="{{ 'clear_filter' | shpLocalize }}"><span class="ic-close"></span></button>
    </div>
  </div>

  <div class="log-list" [class.has-pager]="pageSize < totalEntriesCount">
    <table>
      <thead>
        <tr>
          <th class="col-timestamp">{{ "timestamp" | shpLocalize }}</th>
          <th class="col-event-category" title="{{ 'event_category' | shpLocalize }}">{{ "event_category" | shpLocalize }}</th>
          <th class="col-component-id" title="{{ 'component_id' | shpLocalize }}">{{ "component_id" | shpLocalize }}</th>
          <th class="col-event-code" title="{{ 'event_code' | shpLocalize }}">{{ "event_code" | shpLocalize }}</th>
          <th class="col-event-data" title="{{ 'event_data' | shpLocalize }}">{{ "event_data" | shpLocalize }}</th>
          <th class="col-event-severity" title="{{ 'event_severity' | shpLocalize }}">{{ "event_severity" | shpLocalize }}</th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let p of logEntries$ | async; let i = index"
          [class.odd]="i % 2 === 1">
        <td class="col-timestamp">{{ p.timestamp | shpLocalizeDate:"L HH:mm:ss.SSS" }}</td>
        <td class="col-event-category">{{ "event_category_" + p.category | shpLocalize }}</td>
        <td class="col-component-id"><span title="{{ p.componentId }}">{{ p.componentId }}</span></td>
        <td class="col-event-code">{{ p.eventMessage }} <br />{{p.eventCode}}</td>
        <td class="col-event-data">
          <button *ngIf="p.eventData!==null" type="button" title="{{ 'show_exception' | shpLocalize}}"
                  (click)="displayData(p.eventData)" class="copy-to-clipboard">
            <span class="ic-file-notes-view"></span>
          </button>
        </td>
        <td class="col-event-severity {{ 'event-severity-' + p.severity }}">
              <span title="{{ 'event_severity_' + p.severity | shpLocalize }}" class="ic-24"
                    [class.ic-close-circle]="p.severity == 0"
                    [class.ic-interface-alert-circle]="p.severity == 1"
                    [class.ic-interface-information]="p.severity == 2"
                    [class.ic-programming-reel]="p.severity == 3"></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="log-pager pager" *ngIf="pageSize < totalEntriesCount">
    <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="currentPageIndex > 0"><span class="ic-navigation-left-2"></span></button>
    <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(currentPageIndex - 1)" *ngIf="currentPageIndex > 0"><span class="ic-navigation-left-3"></span></button>
    <button id="button-pager-next" class="next" type="button" (click)="goToPage(currentPageIndex + 1)" *ngIf="currentPageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
    <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="currentPageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
    <span class="numbers">{{ currentPageIndex + 1 }}/{{ lastPageIndex + 1 }}</span>
  </div>

  <div class="exception-display-dialog" *ngIf="dataToDisplay!==null">
    <div class="dialog-content">
      <h3>{{ "event_data" | shpLocalize }}</h3>
      <div class="exception">

        <div class="exception-text">
          {{ dataToDisplay | json }}
        </div>
      </div>
      <div class="toolbar">
        <button id="button-exception-close" type="button" (click)="dataToDisplay=null"><span class="ic-close"></span></button>
      </div>
    </div>
  </div>
</div>