import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors, Validators } from "@angular/forms";

import { Observable, of } from "rxjs";

import { ShipmentService } from "../../shipments/services/shipments.service";
import * as Shared from "../index";
import { ShipperSettingsService } from "../services/shipper-settings.service";
import { validateCustomsTariffNumber } from "../validators";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}ValidateCustomsTariffNumber]`,
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => CustomsTariffNumberValidatorDirective), multi: true }
  ]
})
export class CustomsTariffNumberValidatorDirective implements AsyncValidator, OnChanges, OnInit {
  @Input() customsTariffNumberShippingCountryCode: string;
  @Input() customsTariffNumberDeliveryCountryCode: string;

  private validatorFn: AsyncValidatorFn;
  private control: AbstractControl | null;

  constructor(
    private shipperSettingsService: ShipperSettingsService,
    private shipmentService: ShipmentService,
  ) { }

  ngOnInit() {
    this.validatorFn = this.shipperSettingsService.isCustomsTariffNumberValidationEnabled ?
      validateCustomsTariffNumber(this.shipmentService, () => this.countriesFn(), 1000) :
      () => of(Validators.nullValidator);
  }

  ngOnChanges(changes: SimpleChanges) {
    const hasOriginCountryChanged =
      "customsTariffNumberShippingCountryCode" in changes &&
      changes.customsTariffNumberShippingCountryCode.currentValue !== changes.customsTariffNumberShippingCountryCode.previousValue;
    const hasDestinationCountryChanged =
      "customsTariffNumberDeliveryCountryCode" in changes &&
      changes.customsTariffNumberDeliveryCountryCode.currentValue !== changes.customsTariffNumberDeliveryCountryCode.previousValue;

    if (this.control && (hasOriginCountryChanged || hasDestinationCountryChanged)) {
      this.control.updateValueAndValidity();
    }
  }

  countriesFn(): {shippingCountryCode: string, deliveryCountryCode: string} {
    return {
      shippingCountryCode: this.customsTariffNumberShippingCountryCode,
      deliveryCountryCode: this.customsTariffNumberDeliveryCountryCode
    };
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    this.control = control;
    return this.validatorFn(control);
  }
}
