export enum Localization {
  SK = 0,
  EN = 1,
  CS = 2,
  DE = 3,
  PL = 4,
  RU = 5,
  IT = 6,
  FR = 7,
  NL = 8,
  BE = 9 // Flemish (Belgian Dutch)
}