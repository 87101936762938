<div class="top">
  <h1>{{ 'import_archive' | shpLocalize }}</h1>
</div>

<div class="content-panel shipments-import-archive">
  <div class="grid">
    <div class="my-2 d-flex">
      <button class="ml-1" (click)="goToShipments()" [disabled]="isBusy[isBusyTypes.LIST]">
        <span class="ic-arrow-return"></span> {{ 'back' | shpLocalize }}
      </button>
      <button class="ml-1" (click)="refresh()" [disabled]="isBusy[isBusyTypes.LIST]">
        <span class="ic-synchronize-1"></span> {{ 'refresh_list' | shpLocalize }}
      </button>
    </div>

    <shp-progress-overlay [isActive]="isBusy[isBusyTypes.LIST]"></shp-progress-overlay>
    <shp-retry-overlay [isVisible]="currentLoadStatus === loadStatuses.FAIL" errorMessage="{{ 'import_archive_loading_error' | shpLocalize }}" [reloadFunction]="retry"></shp-retry-overlay>

    <!-- header filters begin -->
    <div class="grid-header">
      <table>
        <thead>
          <tr>
            <!-- <th class="button-cell">
              <div class="d-flex">
                <div class="table-header-multi-select-box">
                  <input type="checkbox" id="select-all" [(ngModel)]="multipleSelectModel" (ngModelChange)="onMultipleSelect()" />
                  <label for="select-all" title="Vybrať všetky"></label>
                </div>
                <button [disabled]="isBusy[isBusyTypes.LIST]" type="button" (click)="refresh()" title="Obnoviť zoznam"><span class="ic-synchronize-1"></span></button>
              </div>
            </th> -->

            <th class="col-date">{{ 'date_of_import' | shpLocalize }}</th>
            <th class="col-generic">{{ 'job_state' | shpLocalize }}</th>
            <th class="col-generic">{{ 'duration' | shpLocalize }}</th>
            <th class="col-commands">{{ 'import_errors' | shpLocalize }}</th>
            <th class="col-spacer"></th>
          </tr>

          <tr class="filters" [formGroup]="columnFilter">
            <!-- <th class="button-cell">
              <div class="d-flex">
                <button *ngIf="isFiltered && !isBusy[isBusyTypes.LIST]" type="button" (click)="resetColumnFilter()" title="Zrušiť filter"><span class="ic-close"></span></button>
              </div>
            </th> -->

            <th class="col-date">
              <div class="filter">
                <div class="d-flex">
                  <div class="p-field">
                    <span class="p-float-label">
                      <p-calendar formControlName="creationDateTimeUtcFrom" [showIcon]="true" appendTo="body" inputId="creationDateTimeUtcFrom" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="15" icon="ic-calendar-1"></p-calendar>
                      <label for="creationDateTimeUtcFrom">{{ 'filter_from' | shpLocalize }}</label>
                    </span>
                  </div>
                  <div class="p-field">
                    <span class="p-float-label">
                      <p-calendar formControlName="creationDateTimeUtcTo" [showIcon]="true" appendTo="body" inputId="creationDateTimeUtcTo" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="15" icon="ic-calendar-1"></p-calendar>
                      <label for="creationDateTimeUtcTo">{{ 'filter_to' | shpLocalize }}</label>
                    </span>
                  </div>
                </div>
              </div>
            </th>

            <th class="col-generic">
              <div class="filter">
                <div class="p-field p-field--full">
                  <span class="p-float-label">
                    <p-multiSelect id="event_severit" appendTo="body" [options]="jobStates$ | async" formControlName="stateIds" optionLabel="value" optionValue="key" [filter]="false"></p-multiSelect>
                    <label for="event_severit">{{ 'event_severity' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </th>
            <th></th>
            <th class="col-commands button-cell">
              <div class="d-flex">
                <button *ngIf="isFiltered && !isBusy[isBusyTypes.LIST]" type="button" (click)="resetColumnFilter()" title="{{ 'clear_filter' | shpLocalize}}"><span class="ic-close"></span></button>
              </div>
            </th>
            <th class="col-spacer">
            </th>

          </tr>
        </thead>
      </table>
    </div>
    <!-- header filters end -->

    <div class="grid-content">
      <ng-container [ngSwitch]="currentLoadStatus">
        <div class="list-empty-message" *ngSwitchCase="loadStatuses.EMPTY_WITH_FILTER">
          <div class="message-content">
            {{ 'message_no_imports_matching_filter' | shpLocalize }}
          </div>
        </div>
        <div class="list-empty-message" *ngSwitchCase="loadStatuses.EMPTY">
          <div class="message-content">
            {{ 'message_no_imports_in_db' | shpLocalize }}
          </div>
        </div>
      </ng-container>
      <!-- table body begin -->
      <table>
        <tbody>
          <tr *ngFor="let job of jobs$ | async">
            <!-- <td>
              <input type="checkbox" id="is-selected-{{ order.id }}" [(ngModel)]="order.isSelected" (ngModelChange)="onRowSelect(order.id)" />
              <label [attr.for]="'is-selected-' + order.id" class="pt-0"></label>
            </td> -->
            <td class="col-date">{{ job.creationDateTimeUtc | shpLocalizeDate:"L HH:mm" }}</td>
            <td class="col-generic">{{ computeJobStateLocalizationKey(job.stateId) | shpLocalize }}</td>
            <td class="col-generic">{{ getDuration(job.executionStartDateTimeUtc, job.executionEndDateTimeUtc) }}</td>

            <td class="col-commands">
              <div class="d-flex align-item-center">
                <i *ngIf="job.logJobContentId && (job.stateId === 'Error' || job.stateId === 'Finished')" class="dpd-ic-rgb dpd-ic-warning"></i>
                <button *ngIf="job.logJobContentId" (click)="downloadLog(job.logJobContentId)" type="button" title="Log"><span class="ic-data-upload-2"></span></button>
                <button *ngIf="job.stateId === 'Error' || job.stateId === 'Cancelled'" (click)="executeJob(job.id)" type="button" title="{{ 'execute_import' | shpLocalize }}"><span class="ic-video-control-play"></span></button>
                <button *ngIf="job.stateId === 'Waiting'" (click)="cancelJob(job.id)" type="button" title="{{ 'cancel_import' | shpLocalize }}"><span class="ic-remove-2"></span></button>
              </div>
            </td>
            <td class="col-spacer"></td>
          </tr>
        </tbody>
      </table>
      <!-- table body end -->
    </div>

    <div class="grid-footer">
      <div class="multi-select-commands"></div>
      <div class="grid-pager">
        <div class="pager" *ngIf="pageSize < totalCount">
          <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
          <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(pageIndex - 1)" *ngIf="pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
          <button id="button-pager-next" class="next" type="button" (click)="goToPage(pageIndex + 1)" *ngIf="pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
          <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
          <span class="numbers">{{ pageIndex * pageSize + 1 }} - {{ (pageIndex + 1) * pageSize < totalCount ? (pageIndex + 1) * pageSize : totalCount }}/{{ totalCount }}</span>
        </div>
      </div>
      <div class="grid-options">
        <label for="items-per-page">{{ 'items_per_page' | shpLocalize }}</label>
        <select id="items-per-page" class="items-per-page" [(ngModel)]="pageSize">
          <option *ngFor="let value of itemsPerPageOptions" value="{{ value }}">{{ value }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
