import { Pipe, PipeTransform } from "@angular/core"
import * as moment from "moment"

import { SELECTOR_PREFIX } from "../prefix";

@Pipe({ name: SELECTOR_PREFIX + "Duration" })
export class DurationPipe implements PipeTransform {

  private padNumberTo00 = (n: number): string => {
    var s = "0" + n.toString();
    return s.slice(-2);
  }

  public transform(value, ...args: any[]) : any {
    if (typeof value === "undefined" || value == null) {
      return null;
    }

    if (!isNaN(value)) {
      var durationInSeconds = parseInt(value);
      var duration = moment.duration(durationInSeconds, "s");

      var days = duration.days();
      var hours = this.padNumberTo00(duration.hours() as number);
      var minutes = this.padNumberTo00(duration.minutes() as number);
      var seconds = this.padNumberTo00(duration.seconds() as number);

      return (days > 0 ? days + "." : "") + hours + ":" + minutes + ":" + seconds;
    }

    return null;
  }
}