import { Routes, RouterModule } from "@angular/router"

import { ShipmentsCompactComponent } from "./components/shipments-compact.component"
import { ShipmentsGridComponent } from "./components/shipments-grid.component"
import { ShipmentComponent } from "./components/shipment.component"
import { ShipmentEditorComponent } from "./components/shipment-editor.component"
import { ShipmentsComponent } from "./components/shipments.component"
import { ShipmentsListComponent } from "./components/shipments-list.component"
import { SenderScanComponent } from "./components/sender-scan.component"
import { AcceptanceProtocolHistoryComponent } from "./components/acceptance-protocol-history.component";
import { ShipmentsArchiveComponent } from "./components/shipments-archive.component";
import { ImportArchiveComponent } from "./components/import-archive.component";

import { AuthorizationGuard } from "../shared/authorization-guard"
import { UnsavedChangesGuard } from "../shared/unsaved-changes-guard"

import { AppDataResolver } from "../shared/app-data-resolver"


export const SHIPMENTS_ROUTES: Routes = [
  {
    path: "shipments",
    component: ShipmentsComponent,
    canActivate: [AuthorizationGuard],
    children: [
      { path: "", component: ShipmentsListComponent, canActivate: [AuthorizationGuard] },
      { path: "acceptance-protocol-history", component: AcceptanceProtocolHistoryComponent, canActivate: [AuthorizationGuard] },
      { path: "import-archive", component: ImportArchiveComponent, canActivate: [AuthorizationGuard]},
      { path: "archive", component: ShipmentsArchiveComponent, canActivate: [AuthorizationGuard]},
      { path: "senderscan", component: SenderScanComponent, canActivate: [AuthorizationGuard] },
      { path: ":shipmentOrderId", component: ShipmentEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] },
    ]
  }
];