import { Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";

import { Subscription } from 'rxjs';

import * as Shared from "../index";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}Focus]`
})
export class FocusDirective implements OnInit, OnDestroy {

  @Input(Shared.SELECTOR_PREFIX + "Focus") focusEvent: EventEmitter<boolean>;

  private subscription: Subscription | null;

  constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.subscription = this.focusEvent.subscribe(() => {
      // this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);

      this.element.nativeElement.focus();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
