import { Pipe, PipeTransform } from "@angular/core";
import { SELECTOR_PREFIX } from "../../shared/index";
import { CustomerDetail } from "../../shared/models/customer.models";


@Pipe({
  name: SELECTOR_PREFIX + "CustomerId"
})
export class CustomerIdPipe implements PipeTransform {
  public transform(value, ...args: any[]) {
    if (typeof value === "undefined" || value == null) {
      return null;
    }


    if (!isNaN(value)) {
      // Ako prvý argument očakávame zoznam customer detailov, v ktorých hľadáme ten s ID z value.
      let id = +value;

      let customerDetails: CustomerDetail[] = args[0];

      if (customerDetails) {
        let customerDetail = customerDetails.find(cd => cd.id === id);

        if (customerDetail) {
          return `${customerDetail.name} (${customerDetail.delisId})`;
        }
      }
    }

    return null;
  }
}