import { Component, OnInit } from "@angular/core";

import * as Shared from "../../shared/index";
import { BUSINESS_UNIT_CODE_NL, ContextService } from "../../shared/index";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { SupportService } from "../services/support.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-support",
  templateUrl: "./support.component.html"
})
export class SupportComponent implements OnInit {
  isContactDisplayed = false;
  isFaqDisplayed = false;
  isWizardDisplayed = false;

  constructor(
      private supportService: SupportService,
      private contextService: ContextService,
      private wizardService: WizardService) { }

  ngOnInit() {
    this.supportService.loadContactInfo().subscribe(result => {
      this.isContactDisplayed = Object.keys(result).some(k => Boolean(result[k]));
    });

    this.contextService.businessUnitCode.subscribe(buCode => {
      this.isFaqDisplayed = buCode === BUSINESS_UNIT_CODE_NL;
      this.isWizardDisplayed = buCode === BUSINESS_UNIT_CODE_NL;
    });
  }

  startWizard() {
    this.wizardService.start();
  }
}
