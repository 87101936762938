import { Component } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";
import * as moment from "moment";

import * as Shared from "../../../shared/index";
import { SettingsService } from "../../services/settings.service";
import { FormBuilder } from "@angular/forms";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-backup-restore",
  templateUrl: "./backup-restore.component.html"
})

export class BackupRestoreComponent {

  constructor(
    private _loggingService: Shared.LoggingService,
    private _settingsService: SettingsService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _localizationService: Shared.LocalizationService,
    private _fb: FormBuilder,
    private _toastr: ToastrService
  ) {
  }

  public isBusy = false;

  public backupDatabase() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;

    this._settingsService.getZipFromApi("GET", `${Shared.API_URL}/backup-restore/backup-database`)
      .then(result => {
        if (result.type.toLowerCase().startsWith("application/octet-stream")) {
          // Dostali sme určite Blob.
          if (result.size > 0) {

            this.isBusy = false;

            // Mali by sme mať dostať súboru.
            const mediaType = "application/octet-stream";
            const blob = new Blob([result], { type: mediaType });
            const timestamp = moment().format("YYYYMMDD-HHmmss");
            const filename = `DPD-${timestamp}.zip`;

            saveAs(blob, filename, true);
          } else {
            this.isBusy = false;
            // Súbor má nulovú veľkosť.
            this._toastr.success(this._localizationService.getLocalizedString("backup_database_failed"));
          }
        } else {
          this.isBusy = false;
          // Súbor nemá správny dátový typ
          this._toastr.success(this._localizationService.getLocalizedString("backup_database_failed"));
        }
      }, reason => {
        this.isBusy = false;

        this._loggingService.logErrorData(reason, "Error backuping database.");

        var exception = this._exceptionsHandlerService.getExceptionInfo(reason);

        this._toastr.error(this._localizationService.getLocalizedExceptionString(exception));
      });

  }

  /**
   * JS objekt reprezentujúci údaje o vybranom súbore.
   */
  private _selectedFile: File = null;
  public selectedFileName = "";

  /**
  * Handler, ktorý sa má bindovať na event zmeny vybraného súboru cez input type=file.
  */
  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }


  private setSelectedFile(inputValue: any) {
    if (inputValue && inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this.selectedFileName = this._selectedFile.name;
    } else {
      this.selectedFileName = "";
      this._selectedFile = null;
      (document.getElementById("selected-file") as any).value = "";
    }
  }

  public uploadDbFile() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;

    if (this._selectedFile) {
      this._settingsService.restoreDatabaseFile(`${Shared.API_URL}/backup-restore/restore-database`, this._selectedFile).subscribe(() => {
        this.setSelectedFile(null);

        this._toastr.success(this._localizationService.getLocalizedString("database_zip_successfully_restored"));
        this.isBusy = false;
      }, ex => {
        this._loggingService.logErrorData(ex, "Error restoring database.")

        var exception = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._toastr.error(this._localizationService.getLocalizedExceptionString(exception));
        this.isBusy = false;
      });
    }
  }
}