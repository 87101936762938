import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";


import * as Shared from "../../shared/index"
import * as NotificationsModel from "../models/notifications.model"
import * as UriHelper from "../../shared/uri-helper"


@Injectable()
export class NotificationsService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient
  ) { super(loggingService); }


  public getUnreadNotificationsCount(customerDetailId: number) {
    return this.processRequest<number>(this._http.get(`${Shared.API_URL}/customer-details/${customerDetailId}/notifications/unread-count`));
  }


  public getNotificaions(customerDetailId: number, parameters: Shared.EntitiesListDisplaySettings) {
    return this.processRequest<Shared.PagedResult<NotificationsModel.Notification>>(
      this._http.get(`${Shared.API_URL}/customer-details/${customerDetailId}/notifications?${UriHelper.encodeAsQueryString(parameters)}`));
  }


  public markNotificaionAsRead(customerDetailId: number, notificaitonId: number) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/customer-details/${customerDetailId}/notifications/${notificaitonId}/is-read`, null));
  }


  public markNotificationsAsRead(customerDetailId: number) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/customer-details/${customerDetailId}/notifications/is-read`, null));
  }


  public deleteNotifications(customerDetailId: number) {
    return this.processRequest<boolean>(this._http.delete(`${Shared.API_URL}/customer-details/${customerDetailId}/notifications`));
  }
}