<div class="settings-category">
  <form #f (submit)="save()" *ngIf="settings" class="">
    <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>

    <h1>{{ 'settings_routing' | shpLocalize }}</h1>
    <div class="ctl inline-progress-overlay-container">
      <label for="routing-current-version">{{ 'settings_routing_current_version' | shpLocalize }}</label>
      <div style="width: 100%"
           class="inline-progress-overlay-container">
        <shp-progress-overlay [isActive]="isLoadingDbVersions"></shp-progress-overlay>

        <input type="text"
               readonly="readonly"
               name="routingCurrentVersion"
               id="routing-current-version"
               [(ngModel)]="currentGeoRoutingDbVersion" />
      </div>
    </div>

    <div *ngIf="isAvailableGeoRoutingDbVersion"
         class="ctl">
      <label for="routing-available-version">{{ 'settings_routing_available_version' | shpLocalize }}</label>
      <div style="width: 100%"
           class="inline-progress-overlay-container">
        <shp-progress-overlay [isActive]="isLoadingDbVersions"></shp-progress-overlay>

        <input type="text"
               readonly="readonly"
               name="routingAvailableVersion"
               id="routing-available-version"
               [(ngModel)]="availableGeoRoutingDbVersion" />
      </div>
    </div>

    <div class="ctl" *ngIf="settings?.routing_db_local_path.isVisible">
      <label for="routing-local-path">{{ 'settings_routing_db_local_path' | shpLocalize }}</label>
      <input type="text"
             name="routingDbLocalPath"
             id="routing-local-path"
             [(ngModel)]="settings.routing_db_local_path.value" />
    </div>

    <div class="commands-toolbar" >
      <button id="button-save"
              class="mr-2"
              *ngIf="settings.routing_db_local_path.isVisible"
              type="submit">
        <span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}
      </button>
      <button id="button-run-db-version-check"
              *ngIf="isAvailableGeoRoutingDbVersion"
              type="button"
              (click)="forceRdbDownload()">
        <span class="ic-server-download-1"></span> {{ 'force_rdb_download' | shpLocalize }}
      </button>
    </div>
  </form>
  <form #rdbUpload="ngForm" *ngIf="settings && settings.routing_db_local_path.isVisible" (submit)="uploadRdbFile()">
    <shp-progress-overlay [isActive]="isUploadingRdb"></shp-progress-overlay>
    <h3 class="pad">{{ 'manual_rdb_upload' | shpLocalize }}</h3>
    <div class="ctl">
      <label for="selected-file">{{ 'rdb_zip_file' | shpLocalize }}</label>
      <div class="file-input-group">
        <input id="input-filename" type="text" [(ngModel)]="selectedFileName" name="selectedFileName" readonly="readonly" />
        <input type="file" name="selectedFile" id="selected-file" (change)="selectedFileChanged($event)" />
        <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
      </div>
    </div>
    <div class="ctl buttons">
      <label>&nbsp;</label>
      <button id="button-rdb-upload" [disabled]="!rdbUpload.form.valid || selectedFileName.length === 0" type="submit">{{ 'upload_rdb_zip_file' | shpLocalize }}</button>
    </div>
  </form>
</div>