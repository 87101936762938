import { ModalComponent, DialogRef, CloseGuard } from "ngx-modialog-7";
import { Component } from "@angular/core";
import { ShipmentType } from "../models/shipment.model";

@Component({
  templateUrl: "./shipment-type-dialog.component.html"
})
export class ShipmentTypeDialogComponent implements ModalComponent<{}> {
  constructor(public dialog: DialogRef<{}>) {
    // dialog.setCloseGuard(this);
    dialog.overlayRef.instance.documentKeypress = () => {
      return false;
    }
  }
  shipmentType = ShipmentType;

  private _type: ShipmentType = null;


  onClick(type: ShipmentType): void {
    this._type = type;
    this.dialog.close(type);
  }

  close() {
  }

  dismiss() {
  }

  onKeyUp(value) {
  }

  // beforeDismiss(): boolean {
  //   return this._type ? true : false;
  // }

  // beforeClose(): boolean {
  //   return this._type ? true : false;
  // }
}
