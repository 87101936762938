<ul class="wizard__chapters__list">
  <li *ngFor="let chapter of chapters; index as idx; last as isLast"
    class="wizard__chapters__chapter"
    [class.wizard__chapters__chapter--disabled]="!availableChapters.has(chapter.name)"
    [class.wizard__chapters__chapter--active]="chapter.name === activeChapterName"
    (click)="onChapterClicked(chapter)">
    <div
      class="wizard__chapters__chapter-index">
      {{ idx + 1 }}
    </div>
    <div
      *ngIf="!isLast"
      class="wizard__chapters__line"
      [class.wizard__chapters__line--active]="availableChapters.has(chapters[idx].name) && availableChapters.has(chapters[idx+1].name)"></div>
    <div class="wizard__chapters__label">
      <span class="wizard__chapters__icon" [ngClass]="chapter.iconClass"></span>
      <span>{{ (chapter.label) }}</span>
    </div>
  </li>
</ul>