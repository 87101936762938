import { ConnectedPosition, FlexibleConnectedPositionStrategy, GlobalPositionStrategy, Overlay, OverlayRef, PositionStrategy, ScrollDispatcher } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import { ElementRef, Inject, Injectable, Injector, Renderer2, RendererFactory2 } from "@angular/core";
import { Router } from "@angular/router";

import * as _ from "lodash";
import { Subject, timer } from "rxjs";
import { debounceTime, filter, take, takeUntil } from "rxjs/operators";

import { WizardArrowComponent } from "../components/wizard-arrow.component";
import { WizardLabelComponent } from "../components/wizard-label.component";
import { WizardMessagePopupComponent } from "../components/wizard-message-popup.component";
import { WizardPopupComponent } from "../components/wizard-popup.component";
import { WizardArrow, WizardArrowPosition, WizardCssClass, WizardGlobalPosition, WizardLabel, WizardPopup, WizardStep, WizardTaggedElement } from "../models";
import { getFisrtItemFromSet } from "../utils";
import { WizardPopupPositionStrategy } from "../wizard-popup-position-strategy";
import { WizardOverlayEvent, WizardOverlayService } from "./wizard-overlay.service";
import { WizardService } from "./wizard.service";

/**
 * When displayed `WizardArrow` to `ElementRef` can be m:n.
 * Use pairs to identify coresponding OverlayRef.
 */
interface ArrowElementRefPair {
  element: ElementRef;
  arrow: WizardArrow;
}

/**
 * Groups `OverlayRef` with coresponding `ElementRef` and `WizardArrow`.
 */
interface ActiveArrow extends ArrowElementRefPair {
  overlayRef: OverlayRef;
}

interface LabelElementRefPair {
  element: ElementRef;
  label: WizardLabel;
}

interface ActiveLabel extends LabelElementRefPair {
  overlayRef: OverlayRef;
}

interface ActivePopup {
  overlayRef: OverlayRef;
  popup: WizardPopup;
}

const defaultConnectedPositions: Readonly<ConnectedPosition>[] = [{
  originX: "end",
  originY: "center",
  overlayX: "start",
  overlayY: "center"
}];

const arrowConnectedPositions: Map<WizardArrowPosition, Readonly<ConnectedPosition>> = new Map([
  [WizardArrowPosition.Top, {
    originX: "center",
    originY: "top",
    overlayX: "center",
    overlayY: "bottom"
  }],
  [WizardArrowPosition.Right, {
    originX: "end",
    originY: "center",
    overlayX: "start",
    overlayY: "center"
  }],
  [WizardArrowPosition.Bottom, {
    originX: "center",
    originY: "bottom",
    overlayX: "center",
    overlayY: "top"
  }],
  [WizardArrowPosition.Left, {
    originX: "start",
    originY: "center",
    overlayX: "end",
    overlayY: "center"
  }],
]);

@Injectable({
  providedIn: "root"
})
export class WizardRenderService {
  private activeArrows: ActiveArrow[] = [];
  private activeLabels: ActiveLabel[] = [];
  private activeMessagePopup: OverlayRef = null;
  private activePopup: ActivePopup = null;
  private activePopupDispose$ = new Subject<void>();
  private currentStep: WizardStep | null = null;
  private render$ = new Subject<void>();
  private renderer: Renderer2;
  private taggedElements = new Map<string, Set<ElementRef>>();
  private window: Window;

  constructor(
    injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private scrollDispatcher: ScrollDispatcher,
    private overlay: Overlay,
    private wizardService: WizardService,
    private wizardOverlayService: WizardOverlayService
  ) {
    this.window = document.defaultView;
    this.renderer = injector.get(RendererFactory2).createRenderer(null, null);

    this.initRenderElementsStream();
    this.observeWizardState();
  }

  registerTaggedElement(el: WizardTaggedElement) {
    if (!this.taggedElements.has(el.tag)) {
      this.taggedElements.set(el.tag, new Set([el.elementRef]));
    } else {
      this.taggedElements.get(el.tag).add(el.elementRef);
    }

    this.renderElements();
  }

  unregisterTaggedElement(el: WizardTaggedElement) {
    this.taggedElements.get(el.tag)?.delete(el.elementRef);
    this.renderElements();
  }

  renderElements() {
    this.render$.next();
  }

  private disposeAll() {
    this.wizardOverlayService.detach();

    this.activeArrows.forEach(a => a.overlayRef.dispose());
    this.activeArrows.length = 0;

    this.activeLabels.forEach(l => l.overlayRef.dispose());
    this.activeLabels.length = 0;

    this.activePopup?.overlayRef.dispose();
    this.activePopup = null;
    this.activePopupDispose$.next();

    this.activeMessagePopup?.dispose();
    this.activeMessagePopup = null;
  }

  private initRenderElementsStream() {
    this.render$.pipe(
      debounceTime(100),
      filter(() => this.wizardService.isActive)
    ).subscribe(() => {
      this.renderOverlay();
      this.renderArrows();
      this.renderLabels();
      this.renderPopup();
      this.renderMessagePopup();
    });
  }

  private observeWizardState() {
    this.wizardService.state$.subscribe(state => {
      this.currentStep = state.currentStep;

      if (state.isActive) {
        // WizardStep.forceNavigation navigation.
        this.handleForceNavigation();

        // Rerender elements.
        this.renderElements();

        this.renderer.addClass(this.document.body, WizardCssClass.Active);
      } else {
        this.disposeAll();
        this.renderer.removeClass(this.document.body, WizardCssClass.Active);
      }
    });
  }

  private getElementsForTag(tag: string): Set<ElementRef> {
    return this.taggedElements.get(tag) ?? new Set();
  }

  private getFirstElementForTag(tag: string): ElementRef | null {
    return getFisrtItemFromSet(this.getElementsForTag(tag));
  }

  private getElementsForTags(tags: string[]): Set<ElementRef> {
    const result: Set<ElementRef> = new Set();

    tags.forEach(tag => {
      const elements = this.getElementsForTag(tag);
      elements.forEach(element => result.add(element));
    });

    return result;
  }

  private getGlobalPositionStrategy(position: WizardGlobalPosition = {}): GlobalPositionStrategy {
    const strategy = this.overlay.position().global();

    if (!position.top && !position.bottom) {
      strategy.centerVertically(position.offsetY);
    } else {
      if (position.top) {
        strategy.top(position.top);
      }

      if (position.bottom) {
        strategy.bottom(position.bottom);
      }
    }

    if (!position.left && !position.right) {
      strategy.centerHorizontally(position.offsetX);
    } else {
      if (position.left) {
        strategy.left(position.left);
      }

      if (position.right) {
        strategy.right(position.right);
      }
    }

    return strategy;
  }

  private getConnectedPositionStrategyForElement(
      element: ElementRef,
      positions: ConnectedPosition[] = defaultConnectedPositions): FlexibleConnectedPositionStrategy {
    return this.overlay.position()
      .flexibleConnectedTo(element)
      .withPositions(positions)
      .withFlexibleDimensions(false);
  }

  private handleForceNavigation() {
    const forceNavigation = this.currentStep?.forceNavigation;

    if (!forceNavigation) {
      return;
    }

    const targetUrl = typeof forceNavigation === "string" ? forceNavigation : forceNavigation.url;
    const testRegex = typeof forceNavigation === "string" ? null : forceNavigation.test;
    const currentUrl = this.router.url;

    if (targetUrl === currentUrl || testRegex?.test(currentUrl)) {
      return;
    }

    this.router.navigate(targetUrl.split("/"));
  }

  private renderOverlay() {
    const overlay = this.currentStep?.overlay;

    this.wizardOverlayService.attach();

    if (!overlay) {
      this.wizardOverlayService.attachTarget([]);
      return;
    }

    this.wizardOverlayService.events$.pipe(
      filter(e => e === WizardOverlayEvent.TargetAttached),
      take(1),
      takeUntil(this.render$)
    ).subscribe(() => {
      this.wizardOverlayService.setOffset(overlay.offset ?? 5);
      this.wizardOverlayService.setBoundries(overlay.boundries);
    });

    if (overlay.tag) {
      const elements = typeof overlay.tag === "string" ?
        this.getElementsForTag(overlay.tag) :
        this.getElementsForTags(overlay.tag);

      if (elements.size) {
        this.wizardOverlayService.attachTarget([...elements].map(e => e.nativeElement));
      } else {
        /**
         * No elements found. Elements can be absent due to navigation, ngIf
         * conditions, etc. Wait 500ms for render$ to emit again before actually setting
         * empty set as target.
         */
        timer(500).pipe(
          take(1),
          takeUntil(this.render$)
        ).subscribe(() => this.wizardOverlayService.attachTarget([]));
      }
    }
  }

  private renderArrows() {
    const arrows = this.currentStep?.arrows ?? [];
    const arrowElementRefPairs: ArrowElementRefPair[] = [];

    // Fill arrowElementRefPairs.
    arrows.forEach(arrow => {
      // Set defualt values.
      arrow.position ??= WizardArrowPosition.Right;
      arrow.isAnimated ??= true;

      this.getElementsForTag(arrow.tag).forEach(element => arrowElementRefPairs.push({element, arrow}));
    });

    // Dispose unnecessary arrows.
    const arrowsToDispose = this.activeArrows.filter(activeArrow =>
        !arrowElementRefPairs.some(pair => pair.arrow === activeArrow.arrow && pair.element === activeArrow.element));

    arrowsToDispose.forEach(a => {
      a.overlayRef.dispose();
      _.remove(this.activeArrows, a);
    });

    arrowElementRefPairs.forEach(pair => this.renderArrow(pair));
  }

  private renderArrow(pair: ArrowElementRefPair) {
    const existing = this.activeArrows.find(activeArrow =>
      activeArrow.element === pair.element && activeArrow.arrow === pair.arrow);

    if (existing) {
      return;
    }

    const positionStrategy = this.getConnectedPositionStrategyForElement(
        pair.element, [arrowConnectedPositions.get(pair.arrow.position)]);

    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    const componentRef = overlayRef.attach(new ComponentPortal(WizardArrowComponent));
    componentRef.instance.arrow = pair.arrow;
    this.activeArrows.push({overlayRef, element: pair.element, arrow: pair.arrow});
  }

  private renderLabels() {
    const labels = this.currentStep?.labels ?? [];
    const labelElementRefPairs: LabelElementRefPair[] = [];

    // Fill arrowElementRefPairs.
    labels.forEach(label => {
      this.getElementsForTag(label.tag).forEach(element => labelElementRefPairs.push({element, label}));
    });

    // Dispose unnecessary labels.
    const arrowsToDispose = this.activeLabels.filter(activeLabel =>
        !labelElementRefPairs.some(pair => pair.label === activeLabel.label && pair.element === activeLabel.element));

    arrowsToDispose.forEach(a => {
      a.overlayRef.dispose();
      _.remove(this.activeLabels, a);
    });

    labelElementRefPairs.forEach(pair => this.renderLabel(pair));
  }

  private renderLabel(pair: LabelElementRefPair) {
    const existing = this.activeLabels.find(activeLabel =>
      activeLabel.element === pair.element && activeLabel.label === pair.label);

    if (existing) {
      return;
    }

    const positionStrategy = this.getConnectedPositionStrategyForElement(
        pair.element, pair.label.positions);

    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    const componentRef = overlayRef.attach(new ComponentPortal(WizardLabelComponent));
    componentRef.instance.text = pair.label.text;
    componentRef.instance.labelClass = pair.label.labelClass;
    this.activeLabels.push({overlayRef, element: pair.element, label: pair.label});
  }

  private renderPopup() {
    const popup = _.cloneDeep(this.currentStep?.popup);
    const isEqual = _.isEqual(this.activePopup?.popup, popup);

    if (!popup || !isEqual) {
      this.activePopup?.overlayRef.dispose();
      this.activePopup = null;
      this.activePopupDispose$.next();
    }

    if (!popup || isEqual) {
      return;
    }

    const positionStrategy = this.resolvePopupPositionStrategy(popup);

    if (!positionStrategy) {
      return;
    }

    const overlayRef = this.overlay.create({
      hasBackdrop: false,
      backdropClass: WizardCssClass.PopupBackdrop,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    overlayRef.attach(new ComponentPortal(WizardPopupComponent));
    this.activePopup = {overlayRef, popup};

    this.observePopupOrigin();
  }

  private resolvePopupPositionStrategy(popupData: WizardPopup = {}): PositionStrategy | null {
    if (!popupData.connectedToElementTag) {
      return this.getGlobalPositionStrategy(popupData.position ?? {offsetY: "-125px"});
    }

    const element = this.getFirstElementForTag(popupData.connectedToElementTag);

    if (!element) {
      return;
    }

    return new WizardPopupPositionStrategy(this.overlay, element, this.renderer)
      .withPositions(popupData.connectedPositions)
      .withBoundries(popupData.connectedPositionBoundries)
      .withScrollableContainers(this.scrollDispatcher.getAncestorScrollContainers(element));
  }

  private observePopupOrigin() {
    const element = this.getFirstElementForTag(this.activePopup.popup.connectedToElementTag)?.nativeElement;
    // @todo - install polyfill
    if ("ResizeObserver" in this.window && element) {
      const resizeObserver = new ResizeObserver(() => this.activePopup.overlayRef.updatePosition());

      resizeObserver.observe(element);

      this.activePopupDispose$.pipe(take(1)).subscribe(() => resizeObserver.disconnect());
    }
  }

  private renderMessagePopup() {
    const message = this.wizardService.stateSnapshot.messagePopup;

    if (!message) {
      this.activeMessagePopup?.dispose();
      this.activeMessagePopup = null;
      return;
    }

    if (this.activeMessagePopup) {
      return;
    }

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: WizardCssClass.MessagePopupBackdrop,
      positionStrategy: this.getGlobalPositionStrategy({offsetY: "-125px"})
    });

    overlayRef.attach(new ComponentPortal(WizardMessagePopupComponent));
    this.activeMessagePopup = overlayRef;
  }
}
