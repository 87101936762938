export const shipmentEditorValidationTexts = {
  // basic data
  customerPersonalizedNotificationRecipients: {
    label: "email_recipients"
  },
  pickupDate: {
    label: "shipment_order_pickup_date"
  },
  product: {
    label: "product",
    errors: {
      required: "error_description_shipment_editor_product_required"
    }
  },

  // parcels
  parcelLength: {
    label: "parcel_length"
  },
  parcelWidth: {
    label: "parcel_width"
  },
  parcelHeight: {
    label: "parcel_height"
  },
  parcelWeight: {
    label: "parcel_weight"
  },
  natureOfGoods: {
    label: "nature_of_goods"
  },
  customsTariffNumber: {
    label: "hs_code",
    errors: {
      customsTariffNumber: "invalid_customer_tariff_number"
    }
  },
  itemsNumber: {
    label: "pcs_label"
  },
  customsAmount: {
    label: "amount_line"
  },
  grossWeight: {
    label: "gross_weight"
  },
  originCountryCode: {
    label: "country_of_origin"
  },
  limitDate: {
    label: "expiration_date"
  },
  tempMin: {
    label: "minimal_temparature"
  },
  tempMax: {
    label: "maximal_temparature"
  },
  collection_request_weight: {
    label: "parcel_weight"
  },


  // recipient forms
  recipient: {
    label: "",
    errors: {
      emailOrPhoneRequired: "error_email_or_phone_required"
    }
  },
  recipient_name2: {
    label: "recipient_organization_name"
  },
  recipient_contactPerson: {
    label: "recipient_organization_contact_name"
  },
  recipient_street: {
    label: "recipient_street"
  },
  recipient_house_nr: {
    label: "recipient_house_number"
  },
  recipient_street_detail: {
    label: "recipient_street_detail"
  },
  recipient_country: {
    label: "recipient_country"
  },
  recipient_language_code: {
    label: "recipient_language"
  },
  recipient_zip: {
    label: "recipient_zip",
    errors: {
      zip: "error_zip_invalid",
      noSpaces: "address_zip_cannot_contain_spaces",
      zipFormat: "error_zip_expected_format"
    }
  },
  recipient_city: {
    label: "recipient_city",
    errors: {
      city: "error_city_not_found"
    }
  },
  recipient_state: {
    label: "recipient_state"
  },
  eori_country_code: {
    label: "eori_country_code"
  },
  eori_number: {
    label: "recipient_eori",
    errors: {
      alphaNumeric: "error_eori_invalid_format"
    }
  },
  recipient_vat_number: {
    label: "recipient_vat",
    errors: {
      alphaNumeric: "error_vat_invalid_format"
    }
  },
  recipient_reference: {
    label: "recipient_reference"
  },
  recipient_email: {
    label: "recipient_email"
  },
  recipient_phone: {
    label: "recipient_phone"
  },
  recipient_comment: {
    label: "recipient_comment"
  },

  // pudo
  pudo_country: {
    label: "pudo_country"
  },
  pudo_street: {
    label: "pudo_street"
  },
  pudo_zip: {
    label: "pudo_zip",
    errors: {
      zip: "error_zip_invalid",
      noSpaces: "address_zip_cannot_contain_spaces",
      zipFormat: "error_zip_expected_format"
    }
  },
  pudo_city: {
    label: "pudo_city"
  },
  pudo_id: {
    label: "pudo_id"
  },
  pudo_selected_pudo: {
    label: "pudo_selected_pudo"
  },

  // additional services -> COD
  codBankAccount: {
    label: "cod_bank_account",
    errors: {
      nonExistForeignBankAccount: ""
    }
  },
  codAmount: {
    label: "cod_amount"
  },
  codCurrency: {
    label: "currency"
  },
  codCollectionType: {
    label: "cod_collection_type"
  },

  // additional services -> ID check
  idCheckName: {
    label: "id_check_name"
  },
  idCheckIdCardNumber: {
    label: "id_check_card_nr"
  },

  // additional services -> SWAP
  swapAbcAddressName: {
    label: "recipient_person_name"
  },
  swapAbcAddressStreet: {
    label: "recipient_street"
  },
  swapAbcAddressCity: {
    label: "recipient_city"
  },
  swapAbcAddressZip: {
    label: "recipient_zip"
  },


  // customs clearance
  shipment_order_custom_clearance: {
    label: "customs_clearance",
    errors: {
      eoriAndVatRequired: "error_sender_eori_and_vat_is_required",
      eoriOrVatRequired: "error_sender_eori_or_vat_is_required"
    }
  },
  shipment_order_custom_clearance_invoice_number: {
    label: "invoice_number"
  },
  shipment_order_custom_clearance_customs_value: {
    label: "customs_value"
  },
  shipment_order_custom_clearance_currency: {
    label: "currency"
  },
  shipment_order_custom_clearance_dateOfIssue: {
    label: "invoice_date"
  },
  shipment_order_custom_clearance_comment: {
    label: "recipient_comment"
  },
  shipment_order_custom_clearance_eori_number: {
    label: "sender_eori",
    errors: {
      alphaNumeric: "error_eori_invalid_format"
    }
  },
  shipment_order_custom_clearance_consignor_vat: {
    label: "sender_vat",
    errors: {
      alphaNumeric: "error_vat_invalid_format"
    }
  },
  shipment_order_custom_clearance_consignor_web: {
    label: "sender_web"
  },
  shipment_order_custom_clearance_country_code: {
    label: "country_code"
  },

  // DPD precise product
  deliveryWindow: {
    label: "delivery_window",
    errors: {
      required: "error_description_shipment_editor_deliveryWindow_required"
    }
  },

  // Generic address
  // DPD API customer search
  address_name: {
    label: "recipient_organization_name"
  },
  address_name2: {
    label: "recipient_organization_name"
  },
  address_street: {
    label: "recipient_street"
  },
  address_house_nr: {
    label: "recipient_house_number"
  },
  address_street_detail: {
    label: "recipient_street_detail"
  },
  address_country: {
    label: "recipient_country"
  },
  address_language_code: {
    label: "recipient_language"
  },
  address_zip: {
    label: "recipient_zip",
    errors: {
      zip: "error_zip_invalid",
      noSpaces: "address_zip_cannot_contain_spaces",
      zipFormat: "error_zip_expected_format"
    }
  },
  address_city: {
    label: "recipient_city",
    errors: {
      city: "error_city_not_found"
    }
  },
  address_email: {
    label: "recipient_email"
  },
  address_phone: {
    label: "recipient_phone"
  },
};
