<ng-template #lengthInput>
  <div class="ctl" [class.required]="areDimensionEditorsMandatory" *ngIf="!hideDimensionEditors || isDpdPreciseProduct">
    <div class="p-field p-field--full">
      <span class="p-float-label">
        <p-inputNumber
          [(ngModel)]="parcel.length"
          [disabled]="isReadOnly"
          [maxFractionDigits]="0"
          [max]="999"
          [min]="0"
          [required]="areDimensionEditorsMandatory"
          [showButtons]="true"
          [step]="1"
          decrementButtonClass="p-button-secondary"
          decrementButtonIcon="ic-subtract-2"
          incrementButtonClass="p-button-secondary"
          incrementButtonIcon="ic-add-2"
          inputId="parcels-length-{{index}}"
          mode="decimal"
          name="parcelLength{{index}}"
          suffix=" cm"></p-inputNumber>
        <label for="parcels-length-{{index}}">{{ 'parcel_length' | shpLocalize }}</label>
      </span>
    </div>
  </div>
</ng-template>

<div class="shipment-parcel__item" *ngIf="!areDimensionsFieldsHidden && areCustomsClearanceFieldsHidden">
  <div class="shipment-parcel__index" *ngIf="!isSameDimensionsForAllParcels"><span>{{ (index + 1) }}</span></div>
  <div class="w-100 compact">
    <div>
      <!-- <label *ngIf="showLabels" class="required">{{ (!hideDimensionEditors || isDpdPreciseProduct ? 'shipment_order_parcels_dimensions' : 'parcel_weight') | shpLocalize }}</label> -->
      <div class="ctl-group cols-4 w-100">
        <ng-container *ngIf="isParcelLengthFirstDimension">
          <ng-template [ngTemplateOutlet]="lengthInput"></ng-template>
        </ng-container>

        <div class="ctl" [class.required]="areDimensionEditorsMandatory" *ngIf="!hideDimensionEditors || isDpdPreciseProduct">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                [(ngModel)]="parcel.width"
                [disabled]="isReadOnly"
                [maxFractionDigits]="0"
                [max]="999"
                [min]="0"
                [required]="areDimensionEditorsMandatory"
                [showButtons]="true"
                [step]="1"
                decrementButtonClass="p-button-secondary"
                decrementButtonIcon="ic-subtract-2"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="ic-add-2"
                inputId="parcels-width-{{index}}"
                mode="decimal"
                name="parcelWidth{{index}}"
                suffix=" cm"></p-inputNumber>
              <label for="parcels-width-{{index}}">{{ 'parcel_width' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl" [class.required]="areDimensionEditorsMandatory" *ngIf="!hideDimensionEditors || isDpdPreciseProduct">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                [(ngModel)]="parcel.height"
                [disabled]="isReadOnly"
                [maxFractionDigits]="0"
                [max]="999"
                [min]="0"
                [required]="areDimensionEditorsMandatory"
                [showButtons]="true"
                [step]="1"
                decrementButtonClass="p-button-secondary"
                decrementButtonIcon="ic-subtract-2"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="ic-add-2"
                inputId="parcels-height-{{index}}"
                mode="decimal"
                name="parcelHeight{{index}}"
                suffix=" cm"></p-inputNumber>
              <label for="parcels-height-{{index}}">{{ 'parcel_height' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <ng-container *ngIf="!isParcelLengthFirstDimension">
          <ng-template [ngTemplateOutlet]="lengthInput"></ng-template>
        </ng-container>

        <div class="ctl required">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                (ngModelChange)="onWeightChange()"
                [(ngModel)]="parcel.weight"
                [disabled]="isReadOnly"
                [maxFractionDigits]="2"
                [max]="999"
                [minFractionDigits]="2"
                [min]="0"
                [showButtons]="true"
                [step]="1"
                decrementButtonClass="p-button-secondary"
                decrementButtonIcon="ic-subtract-2"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="ic-add-2"
                inputId="parcels-weight-{{index}}"
                mode="decimal"
                name="parcelWeight{{index}}"
                required
                suffix=" kg"></p-inputNumber>
              <label for="parcels-weight-{{index}}">{{ 'parcel_weight' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="shipment-parcel__item" [class.mt-3]="isSameDimensionsForAllParcels && areCustomsClearanceFieldsHidden" *ngIf="!areCustomsClearanceFieldsHidden || showGoodsFields">
  <div class="shipment-parcel__index"><span *ngIf="isSameDimensionsForAllParcels">{{ (index + 1) }}</span></div>
  <div class="w-100">
    <ng-container *ngIf="!areCustomsClearanceFieldsHidden">
      <div class="ctl">
        <label *ngIf="showLabels">{{ 'customs' | shpLocalize }}</label>
        <div class="d-flex w-100">
          <div class="ctl flex-grow-1 mb-0" [class.required]="areCustomsBoundFieldsRequired">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="nature-of-goods-{{index}}" [(ngModel)]="parcel.content" maxlength="35" type="text" name="natureOfGoods{{index}}" pInputText autocomplete="none" [required]="areCustomsBoundFieldsRequired" [readonly]="isReadOnly">
                <label for="nature-of-goods-{{index}}">{{ 'nature_of_goods' | shpLocalize }}<ng-container *ngIf="tooltips?.natureOfGoods">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.natureOfGoods"></span></ng-container></label>
              </span>
            </div>
          </div>
          <div class="ctl flex-grow-1 ml-1 mb-0" [class.required]="areCustomsBoundFieldsRequired">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input
                  #customsTariffNumberControl="ngModel"
                  id="customs-tariff-numbers-{{index}}"
                  [(ngModel)]="parcel.customsTariffNumber"
                  minlength="8"
                  maxlength="8"
                  type="text"
                  name="customsTariffNumber{{index}}"
                  pInputText
                  pKeyFilter="int"
                  autocomplete="none"
                  [required]="areCustomsBoundFieldsRequired"
                  [readonly]="isReadOnly"
                  shpValidateCustomsTariffNumber
                  [customsTariffNumberShippingCountryCode]="senderCountryCode"
                  [customsTariffNumberDeliveryCountryCode]="recipientCountryCode">
                <label for="customs-tariff-numbers-{{index}}">{{ 'hs_code' | shpLocalize }}<ng-container *ngIf="tooltips?.hsCode">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.hsCode"></span></ng-container></label>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="customsTariffNumberControl?.errors?.minlength" class="ctl">
        <em class="error">{{ 'error_description_hscode_minlength' | shpLocalize:customsTariffNumberControl?.errors?.minlength.requiredLength }}</em>
      </div>
      <div *ngIf="customsTariffNumberControl?.errors?.customsTariffNumber" class="ctl">
        <!-- Using inner html because the text may contain links. -->
        <em class="error" [innerHTML]="customsTariffNumberControl?.errors?.customsTariffNumber"></em>
      </div>

      <div class="ctl">
        <label *ngIf="showLabels"></label>
        <div class="d-flex">
          <div class="ctl mb-0" [class.required]="areCustomsBoundFieldsRequired">
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <shp-universal-number-input
                  (ngModelChange)="onCustomsAmountChange()"
                  [(ngModel)]="parcel.amountLine"
                  [isReadOnly]="isReadOnly"
                  [isRequired]="areCustomsBoundFieldsRequired"
                  [max]="999999"
                  [min]="isBrexitCountryRecipient ? 0.01 : 0"
                  [required]="areCustomsBoundFieldsRequired"
                  dpdFloatingLabelControl
                  id="customs-amount-{{index}}"
                  name="customsAmount{{index}}"></shp-universal-number-input>
                <label for="customs-amount-{{index}}">{{ 'amount_line' | shpLocalize }}<ng-container *ngIf="tooltips?.amountLine">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.amountLine"></span></ng-container></label>
              </span>
            </div>
          </div>

          <span class="legend">{{customsAmountCurrencyCode}}</span>

          <div class="ctl mb-0" [class.required]="areCustomsBoundFieldsRequired">
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <shp-universal-number-input
                  [(ngModel)]="parcel.grossWeight"
                  [isReadOnly]="isReadOnly"
                  [isRequired]="areCustomsBoundFieldsRequired"
                  [max]="999"
                  [min]="0"
                  [required]="areCustomsBoundFieldsRequired"
                  dpdFloatingLabelControl
                  id="gross-weight-{{index}}"
                  name="grossWeight{{index}}"></shp-universal-number-input>
                <label for="gross-weight-{{index}}">{{ 'gross_weight' | shpLocalize }}<ng-container *ngIf="tooltips?.grossWeight">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.grossWeight"></span></ng-container></label>
              </span>
            </div>
          </div>

          <span class="legend">kg</span>

          <div class="ctl mb-0 w-25" [class.required]="areCustomsBoundFieldsRequired">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-inputNumber
                  [(ngModel)]="parcel.itemsNumber"
                  [disabled]="isReadOnly"
                  [maxFractionDigits]="0"
                  [min]="1"
                  [required]="areCustomsBoundFieldsRequired"
                  [showButtons]="true"
                  [step]="1"
                  decrementButtonClass="p-button-secondary"
                  decrementButtonIcon="ic-subtract-2"
                  id="items-number-{{index}}"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="ic-add-2"
                  mode="decimal"
                  name="itemsNumber{{index}}"
                  suffix=" {{ 'pcs_label' | shpLocalize }}"></p-inputNumber>
                <label for="items-number-{{index}}"><ng-container *ngIf="tooltips?.itemsNumber">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.itemsNumber"></span></ng-container></label>
              </span>
            </div>
          </div>
          <!-- <span class="legend mr-0">{{ 'pcs_label' | shpLocalize }}</span> -->
        </div>
      </div>

      <div class="ctl" [class.required]="areCustomsBoundFieldsRequired">
        <label *ngIf="showLabels"></label>
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-dropdown
              [(ngModel)]="parcel.originCountryCode"
              [filter]="true"
              [options]="countries"
              [readonly]="isReadOnly"
              [required]="areCustomsBoundFieldsRequired"
              [showClear]="true"
              filterBy="name"
              id="origin-country-code-{{index}}"
              name="originCountryCode{{index}}"
              optionLabel="name"
              optionValue="code"></p-dropdown>
            <label for="origin-country-code-{{index}}">{{ 'country_of_origin' | shpLocalize }}<ng-container *ngIf="tooltips?.countryOfOrigin">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.countryOfOrigin"></span></ng-container></label>
          </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showGoodsFields">

      <div class="ctl required" [class.mt-2]="!isSameDimensionsForAllParcels">
        <label *ngIf="showLabels">{{ 'expiration_date' | shpLocalize }}</label>
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-calendar [(ngModel)]="parcel.limitDate" [showIcon]="true" inputId="expiration-date-{{index}}" dateFormat="dd/mm/yy" [minDate]="minLimitDate" icon="ic-calendar-1" required name="limitDate{{index}}" [disabled]="isReadOnly"></p-calendar>
            <label for="expiration-date-{{index}}">{{ 'expiration_date' | shpLocalize }}<ng-container *ngIf="tooltips?.expirationDate">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.expirationDate"></span></ng-container></label>
          </span>
        </div>
      </div>

      <div class="ctl">
        <label *ngIf="showLabels">{{ 'storage_temparature' | shpLocalize }}</label>
        <div class="d-flex w-100">
          <div class="ctl mb-0 flex-grow-1" [class.required]="isRequiredValidationOn">
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <p-inputNumber
                  [(ngModel)]="parcel.tempMin"
                  [isReadOnly]="isReadOnly"
                  [isRequired]="isRequiredValidationOn"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="2"
                  [min]="temperatureMin"
                  [max]="temperatureMax"
                  [required]="areCustomsBoundFieldsRequired"
                  [showButtons]="true"
                  decrementButtonClass="p-button-secondary"
                  decrementButtonIcon="ic-subtract-2"
                  id="min-temp-{{index}}"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="ic-add-2"
                  name="tempMin{{index}}"
                  suffix=" °C"></p-inputNumber>
                <label for="min-temp-{{index}}">{{ 'minimal_temparature' | shpLocalize }}<ng-container *ngIf="tooltips?.minimalTemparature">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.minimalTemparature"></span></ng-container></label>
              </span>
            </div>
          </div>

          <span class="legend mx-1">-</span>

          <div class="ctl mb-0 flex-grow-1" [class.required]="isRequiredValidationOn">
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <p-inputNumber
                  [(ngModel)]="parcel.tempMax"
                  [isReadOnly]="isReadOnly"
                  [isRequired]="isRequiredValidationOn"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="2"
                  [min]="temperatureMin"
                  [max]="temperatureMax"
                  [required]="areCustomsBoundFieldsRequired"
                  [showButtons]="true"
                  decrementButtonClass="p-button-secondary"
                  decrementButtonIcon="ic-subtract-2"
                  id="max-temp-{{index}}"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="ic-add-2"
                  name="tempMax{{index}}"
                  suffix=" °C"></p-inputNumber>
                <label for="max-temp-{{index}}">{{ 'maximal_temparature' | shpLocalize }}<ng-container *ngIf="tooltips?.maximalTemparature">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="tooltips.maximalTemparature"></span></ng-container></label>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="ctl">
        <label *ngIf="showLabels">{{ 'description' | shpLocalize }}</label>
        <!-- <input [(ngModel)]="parcel.description" placeholder="{{ 'description' | shpLocalize }}" maxlength="100" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="description-{{index}}" [(ngModel)]="parcel.description" maxlength="100" type="text" name="description{{index}}" pInputText [readonly]="isReadOnly" autocomplete="none">
            <label for="description-{{index}}">{{ 'description' | shpLocalize }}</label>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
