import { animate, animation, style, transition, trigger, useAnimation } from "@angular/animations";

const slideInAnimation = animation([
  style({transform: "translate{{axis}}({{ from }})", opacity: 0}),
  animate("{{ timing }}", style({transform: "translate{{axis}}(0)", opacity: 1}))
]);

const slideOutAnimation = animation([
  animate(
    "{{ timing }}",
    style({transform: "translate{{axis}}({{ to }})", opacity: 0})
  )
]);

const fadeInAnimation = animation([
  style({opacity: 0}),
  animate("{{ timing }}", style({opacity: 1}))
]);

const fadeOutAnimation = animation([
  animate("{{ timing }}", style({opacity: 0}))
]);

const wizardOverlay = trigger("wizardOverlay", [
  transition(":enter", [
    useAnimation(fadeInAnimation, {params: {timing: ".24s linear"}})
  ]),
  transition(":leave", [
    useAnimation(fadeOutAnimation, {params: {timing: ".24s linear"}})
  ])
]);

const wizardPopup = trigger("wizardPopup", [
  transition(":enter", [
    useAnimation(slideInAnimation, {params: {timing: ".24s ease-out", from: "10px", axis: "Y"}}),
  ]),
  transition(":leave", [
    useAnimation(slideOutAnimation, {params: {timing: ".24s ease-out", to: "10px", axis: "Y"}}),
  ])
]);

const wizardArrowTop = trigger("wizardArrowTop", [
  transition(":enter", [
    useAnimation(slideInAnimation, {params: {timing: ".24s ease-out", from: "-30px", axis: "Y"}})
  ]),
  transition(":leave", [
    useAnimation(fadeOutAnimation, {params: {timing: ".24s"}})
  ])
]);

const wizardArrowRight = trigger("wizardArrowRight", [
  transition(":enter", [
    useAnimation(slideInAnimation, {params: {timing: ".24s ease-out", from: "30px", axis: "X"}})
  ]),
  transition(":leave", [
    useAnimation(fadeOutAnimation, {params: {timing: ".24s"}})
  ])
]);

const wizardArrowBottom = trigger("wizardArrowBottom", [
  transition(":enter", [
    useAnimation(slideInAnimation, {params: {timing: ".24s ease-out", from: "30px", axis: "Y"}})
  ]),
  transition(":leave", [
    useAnimation(fadeOutAnimation, {params: {timing: ".24s"}})
  ])
]);

const wizardArrowLeft = trigger("wizardArrowLeft", [
  transition(":enter", [
    useAnimation(slideInAnimation, {params: {timing: ".24s ease-out", from: "-30px", axis: "X"}})
  ]),
  transition(":leave", [
    useAnimation(fadeOutAnimation, {params: {timing: ".24s"}})
  ])
]);

const wizardLabel = trigger("wizardLabel", [
  transition(":enter", [
    useAnimation(fadeInAnimation, {params: {timing: ".24s"}})
  ]),
  transition(":leave", [
    useAnimation(fadeOutAnimation, {params: {timing: ".24s"}})
  ])
]);

export const animations = {
  wizardOverlay,
  wizardPopup,
  wizardArrowTop,
  wizardArrowRight,
  wizardArrowBottom,
  wizardArrowLeft,
  wizardLabel
};
