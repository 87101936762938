import { Component } from "@angular/core";

import { DialogRef, ModalComponent } from "ngx-modialog-7";
import { ImportProfilesService } from "../services/import-profiles.service";
import { LocalizationService, ExceptionsHandlerService } from "../../shared/index";
import { ToastrService } from "ngx-toastr";


@Component({
  templateUrl: "./transformation-import-dialog.component.html"
})
export class TransformationImportDialogComponent implements ModalComponent<any> {
  private _isBusy = false;
  private _selectedFileName = "";
  private _selectedFile: File = null;
  private _overwriteIfCodeExists = false;
  private _statusMessage = "";
  private _wasAnytingImported = false;


  public get isBusy() { return this._isBusy; }


  public get statusMessage() { return this._statusMessage; }


  public get selectedFileName() { return this._selectedFileName; }


  public get dialog() { return this._dialog; }


  public get overwriteIfCodeExists() { return this._overwriteIfCodeExists; }
  public set overwriteIfCodeExists(value: boolean) { this._overwriteIfCodeExists = value; }


  public import() {

    if (this._selectedFile == null) {
      return;
    }

    this._isBusy = true;


    var importRunner = (content: string) => {
      this._importProfileService.importImportProfileTransformation({
        overwriteIfCodeExists: this.overwriteIfCodeExists,
        content: content
      }).subscribe(importOutput => {
        this._wasAnytingImported = true;
        this._isBusy = false;
        this._toastr.success(this._localizationService.getLocalizedString("transformation_successfully_imported"));
      }, ex => {
        // Handled by global exception handler
        // let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        // this._toastr.error(this._localizationService.getLocalizedString("error_importing_transformation") + ": " + this._localizationService.getLocalizedExceptionString(exceptionInfo));

        this._isBusy = false;
      })
    }

    var fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      importRunner(<string>fileReader.result);
    }

    fileReader.onerror = () => {
      this._isBusy = false;
    }

    fileReader.onabort = () => {
      this._isBusy = false;
    }

    fileReader.readAsText(this._selectedFile);
  }


  public close() {
    this.dialog.close(this._wasAnytingImported);
  }


  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }


  private setSelectedFile(inputValue: any) {
    if (inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this._selectedFileName = this._selectedFile.name;
    } else {
      this._selectedFileName = "";
      this._selectedFile = null;
    }
  }


  constructor(
    private _dialog: DialogRef<any>,
    private _importProfileService: ImportProfilesService,
    private _exceptionsHandlerService: ExceptionsHandlerService,
    private _localizationService: LocalizationService,
    private _toastr: ToastrService
  ) {
  }
}