import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { DialogRef, overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ImportProfile } from "../../import-profiles/models/import-profiles.models";
import { ImportProfilesService } from "../../import-profiles/services/import-profiles.service";
import * as Shared from "../../shared/index";
import { PollyCollection } from "../../shared/index";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import * as Models from "../models/recipient.model";
import { RecipientsService } from "../services/recipients.service";
import { DeleteAllRecipientsDialogComponent } from "./delete-all-recipients-dialog.component";
import { RecipientsExportDialogComponent } from "./recipients-export-dialog.component";
import { RecipientsImportDialogComponent } from "./recipients-import-dialog.component";


const FILTER_STORAGE_KEY = "recipients-filter";
const IS_COMPACT_VIEW_SELECTED_STORAGE_KEY = "is-comapact-view-recipients-selected";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-recipients-list",
  templateUrl: "./recipients-list.component.html"
})
export class RecipientsListComponent extends Shared.RoutedPageComponentBase implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _recipientsService: RecipientsService,
    private _importProfilesService: ImportProfilesService,
    private _modal: Modal,
    private _wizardService: WizardService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  private _importProfiles: ImportProfile[] = null;
  private _importDialogRef: DialogRef<any> = null;
  private _destroy$ = new Subject<void>();

  public isBusy = false;

  public isImpersonatingShipperAdminUser = false;
  public pollyCollection = PollyCollection.Recipients;
  public wizardStepNames = WizardStepName;

  public get isCompactViewSelected(): boolean {
    const storageStringValue = localStorage.getItem(IS_COMPACT_VIEW_SELECTED_STORAGE_KEY);

    if (storageStringValue == null) {
      return true;
    }

    return JSON.parse(storageStringValue);
  }

  public set isCompactViewSelected(value: boolean) {
    localStorage.setItem(IS_COMPACT_VIEW_SELECTED_STORAGE_KEY, JSON.stringify(value));
  }


  public ngOnInit() {
    this._importProfilesService.loadListOfImportProfilesByType("Recipients").subscribe((importProfiles) => {
      this._importProfiles = importProfiles;
    });

    // Zistíme, či sme prihlásený ako administrátor v kontexte zákazníka
    this.authenticationService.impersonatingShipperAdminUserLogin$.subscribe((login: string) => {
      this.isImpersonatingShipperAdminUser = login != null;
    });
  }

  public ngAfterViewInit() {
    this._wizardService.state$.pipe(
      takeUntil(this._destroy$),
    ).subscribe(() => {
      const importDialogWizardSteps = [
        WizardStepName.ImportRecipients,
        // WizardStepName.ImportRecipients2
      ];

      if (this._wizardService.isOnStep(importDialogWizardSteps)) {
        this.openImportRecipientsDialog();
      } else {
        this._importDialogRef?.destroy();
        this._importDialogRef = null;
      }
    });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._importDialogRef?.destroy();
  }


  public addRecipient() {
    this.router.navigate(["/recipients/0"]);
  }


  public openImportRecipientsDialog() {
    if (this._importDialogRef) {
      return;
    }

    this._importDialogRef = this._modal.open(RecipientsImportDialogComponent, overlayConfigFactory(
      new Models.RecipientsImportDialogModalContext(this._importProfiles)));

    this._importDialogRef
      .result
      .then(refresh => {
        if (refresh) {
          this._recipientsService.notifyShouldRefreshRecipients();
        }
      })
      .catch(() => { })
      .finally(() => this._importDialogRef = null);
  }


  public showConfirmDeleteAllRecipientsDialog() {
    this._modal.open(DeleteAllRecipientsDialogComponent, overlayConfigFactory({}))
      .result
      .then(refresh => {
        if (refresh) {
          this._recipientsService.notifyShouldRefreshRecipients();
        }
      })
      .catch(() => { });
  }


  public exportAllRecipients() {
    const modalContext = new Models.ExportDialogModalContext(null);

    this._modal.open(RecipientsExportDialogComponent, overlayConfigFactory(modalContext));
  }


  private showNotImplementedAlert = () => {
    this._modal.alert().body(this.localizationService.getLocalizedString("not_implemented_yet")).open();
  }
}
