<div class="top">
  <h1>{{ 'pickup_points' | shpLocalize }}</h1>
  <button id="button-import" class="top-button" (click)="import()"><span class="ic-data-download-8"></span><span class="label">{{ 'pickup_points_import' | shpLocalize }}</span></button>
</div>
<div class="content-panel">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="pudo-list list">
    <div class="filter">
      <input type="text" id="search-box" class="search-box" [formControl]="filterText" placeholder="{{ 'search_placeholder' | shpLocalize }}" />
    </div>

    <div class="items">
      <div class="scroll-part">
        <div class="list-item pudo" *ngFor="let pudo of filteredPudos | async; let index = index" [class.odd]="index % 2 == 1" [class.selected]="selectedPudo?.id == pudo.id">
          <div class="content" tabindex="0" (click)="select(pudo)" (keypress)="handleKeyPress($event, pudo)">
            <span class="pudo-name">{{ pudo.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="no-item-selected" *ngIf="selectedPudo == null">
      <div class="message">
        {{ 'pickup_points_list_select_pickup_point_advice' | shpLocalize }}
      </div>
    </div>

    <div class="selected-pudo item-overview" *ngIf="selectedPudo != null">
      <shp-progress-overlay [isActive]="selectedPudo.isLoadingAdditionalData"></shp-progress-overlay>

      <div class="shipment-commands item-commands">
        <button id="button-create-shipment" class="left-button" type="button" (click)="createNewShipmentForPudo(selectedPudo)"><span class="ic-box-add"></span><span class="label">{{ 'pudo_new_order' | shpLocalize }}</span></button>
      </div>

      <div class="item-details pudo-details">
        <h1>{{ selectedPudo.name }}</h1>
        <div class="address" *ngIf="selectedPudo.address">
          <h2>{{ 'pickup_point_address' | shpLocalize }}</h2>
          <div class="property street">
            <div class="label">{{ 'address_street' | shpLocalize }}</div>
            <div class="value">{{ selectedPudo.address.street }} {{ selectedPudo.address.houseNr }}</div>
          </div>
          <div class="property city">
            <div class="label">{{ 'address_city' | shpLocalize }}</div>
            <div class="value">{{ selectedPudo.address.city }} {{ selectedPudo.address.stateCode ? selectedPudo.address.stateCode : "" }}</div>
          </div>
          <div class="property zip">
            <div class="label">{{ 'address_zip' | shpLocalize }}</div>
            <div class="value">{{ selectedPudo.address.city.zip }}</div>
          </div>
          <div class="property country">
            <div class="label">{{ 'address_country' | shpLocalize }}</div>
            <div class="value">{{ selectedPudo.address.countryName }}</div>
          </div>
        </div>

        <div class="pudo-available-services">
          <h2>{{ 'pickup_point_services' | shpLocalize }}</h2>
          <ul>
            <li *ngFor="let service of selectedPudo.services">
              {{ service.name }}
            </li>
          </ul>
        </div>

        <div class="pudo-opening-hours">
          <h2>{{ 'pickup_point_opening_hours' | shpLocalize }}</h2>
          <ul *ngIf="selectedPudo.openingHours">
            <li *ngFor="let weekday of [1,2,3,4,5,6,0]">
              <span class="weekday-name">{{ 'weekday_' + weekday | shpLocalize }}</span>
              <span *ngIf="selectedPudo.openingHours[weekday]">
                {{ selectedPudo.openingHours[weekday].openingTime | shpLocalizeDate:"LT" }} - <span *ngIf="selectedPudo.openingHours[weekday].startDowntimeDateTime">
                {{ selectedPudo.openingHours[weekday].startDowntimeDateTime | shpLocalizeDate:"LT" }}, {{ selectedPudo.openingHours[weekday].endDowntimeDateTime | shpLocalizeDate:"LT" }}
                - </span> {{ selectedPudo.openingHours[weekday].closingTime | shpLocalizeDate:"LT" }}

              </span>
              <span *ngIf="selectedPudo.openingHours[weekday] == null">-</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>