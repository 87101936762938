import { ComponentRef } from "@angular/core";

export function getFisrtItemFromSet<T>(set: Set<T>): T {
  return set?.values().next().value ?? null;
}

export function hasRectArea(rect: {width: number, height: number}): boolean {
  return Boolean(rect.width && rect.height);
}

export function removePx(value: string): number | null {
  if (value === "") {
    return null;
  }

  return Number(value.slice(0, -2));
}

export function toCssClassArray(classes: string | string[]): string[] {
  return (Array.isArray(classes) ? classes : [classes]).filter(c => Boolean(c));
}

export function isElementScrolledOutsideView(element: DOMRect, scrollContainers: DOMRect[]) {
  return scrollContainers.some(containerBounds => {
    const outsideAbove = element.bottom < containerBounds.top;
    const outsideBelow = element.top > containerBounds.bottom;
    const outsideLeft = element.right < containerBounds.left;
    const outsideRight = element.left > containerBounds.right;

    return outsideAbove || outsideBelow || outsideLeft || outsideRight;
  });
}

export function isElementClippedByScrolling(element: DOMRect, scrollContainers: DOMRect[]) {
  return scrollContainers.some(scrollContainerRect => {
    const clippedAbove = Math.ceil(element.top) < scrollContainerRect.top;
    const clippedBelow = Math.ceil(element.bottom) > scrollContainerRect.bottom;
    const clippedLeft = Math.ceil(element.left) < scrollContainerRect.left;
    const clippedRight = Math.ceil(element.right) > scrollContainerRect.right;

    return clippedAbove || clippedBelow || clippedLeft || clippedRight;
  });
}

export function isElementScrolledToStart(element: DOMRect, scrollContainers: DOMRect[]) {
  return scrollContainers.every(scrollContainerRect =>
      Math.ceil(element.top) === scrollContainerRect.top);
}

export function setComponentData(componentRefrence: ComponentRef<any>, data: {[key: string]: any}) {
  const componentInstance = componentRefrence.instance;

  if (!componentInstance || !data) {
    return;
  }

  Object.keys(data).forEach(key => {
    const propertyDescriptor = Object.getOwnPropertyDescriptor(componentInstance, key) ??
      Object.getOwnPropertyDescriptor(Object.getPrototypeOf(componentInstance), key);

    if (propertyDescriptor?.writable || propertyDescriptor?.set) {
      componentInstance[key] = data[key];
    }
  });
}
