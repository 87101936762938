import { Component, OnInit } from "@angular/core";

import { combineLatest } from "rxjs";
import { filter, mergeMap, take } from "rxjs/operators";

import { DialogRef, ModalComponent, CloseGuard } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as Shared from "../../shared/index";
import { ShipmentService } from "../services/shipments.service";

import { ToastrService } from "ngx-toastr";

import * as _ from "lodash";
import * as moment from "moment";
import { SetPickupDateDialogModalContext } from "../models/shipment.model";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-set-pickup-date-dialog",
  templateUrl: "./set-pickup-date-dialog.component.html"
})
export class SetPickupDateDialogComponent implements CloseGuard, ModalComponent<SetPickupDateDialogModalContext>, OnInit {
  private _isBusy = false;
  private _statusMesage = "";
  private _newPickupDate = new Date();
  private _isChangingCrPickupDate = false;

  public disabledDays = [1];

  public get isBusy() { return this._isBusy; }
  public get statusMessage() { return this._statusMesage; }


  public get newPickupDate() { return this._newPickupDate; }
  public set newPickupDate(value: Date) { this._newPickupDate = value; }

  public get minDate() {
    // CRkám nemôžeme nastaviť pickup date na dnešok.
    if (this._isChangingCrPickupDate) {
      return moment().startOf("day").add(1, "day").toDate();
    }

    return moment().startOf("day").toDate();
  }


  public _unavailableDays = [];
  private _unavailableDaysNumbers: string[] = [];
  private _ctrl: any;
  private _isSaturdayPickupAvailable = false;


  public ngOnInit() {
    this._businessUnitSettingsService.getIsSaturdayPickupAllowed()
      .subscribe(isAllowed => {
        this._isSaturdayPickupAvailable = isAllowed;

        if (!this._isChangingCrPickupDate) {
          this.disabledDays.push(6);
        }

        this.loadUnavailableDays(moment().year(), moment().month(), true, this.newPickupDate);
      });
  }

  public onPickupMonthChanged(value) {
    this.loadUnavailableDays(value.year, value.month);
  }

  public initUnavailableDays(ctrl) {
    const self = this;

    this._ctrl = ctrl;
    this.loadUnavailableDays(moment().year(), moment().month() + 1, true, this.newPickupDate);

    ctrl.calendar.displayMonthChanged.addHandler(function (s) {
      self.loadUnavailableDays(moment(s.displayMonth).year(), moment(s.displayMonth).month() + 1);
    });

    ctrl.calendar.formatItem.addHandler(function (s, e) {
      const day = moment(e.data).day();
      const ddd = moment(e.data).format("DDD");

      if (day === 0 || (day === 6 && !self._isSaturdayPickupAvailable) || self._unavailableDaysNumbers.indexOf(ddd) > -1) {
        e.item.classList.add("wj-state-disabled");
      }
    });
  }


  private loadUnavailableDays(year, month, adjustPickupDate = false, pickupDate = null): any {
    this._contextService.businessUnitCode.pipe(
      filter(buCode => buCode != null),
      take(1),
      mergeMap(buCode => this._shipmentService.getHolidays(buCode, year, month))
    ).subscribe(result => {
      const holidays: any = result;

      this._unavailableDays = [];
      this._unavailableDaysNumbers = [];

      holidays.forEach(holiday => {
        const date = moment.utc(holiday.eventDateUtc);
        this._unavailableDays.push(moment(date.format("YYYY-MM-DD"), "YYYY-MM-DD").toDate());
        this._unavailableDaysNumbers.push(date.format("DDD"));
      });

      // this._ctrl.calendar.invalidate();

      if (adjustPickupDate) {
        let _pickupDate = pickupDate;
        let __unavailableDays = this._unavailableDaysNumbers;

        while (moment(_pickupDate).day() === 0 || (moment(_pickupDate).day() === 6 && !this._isSaturdayPickupAvailable) || __unavailableDays.indexOf(moment(_pickupDate).format("DDD")) > -1) {
          const _currentMonth = moment(_pickupDate).month();

          _pickupDate = moment(_pickupDate).add(1, "day").toDate();

          if (moment(_pickupDate).month() !== _currentMonth) {
            this.loadUnavailableDays(moment(_pickupDate).year(), moment(_pickupDate).month() + 1, true, _pickupDate);
            break;
          }
        }

        this.newPickupDate = _pickupDate;
      }
    }, ex => {
      this._log.logErrorData(ex, "Error loading unavailable days");

      var exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

      this._toastr.error(`${this._localizationService.getLocalizedString("error_loading_unavailable_days")}:\n ${this._localizationService.getLocalizedExceptionString(exceptionInfo)}`);
    });
  }


  public setPickupDate() {
    if (this._newPickupDate) {
      this._isBusy = true;

      this._shipmentsService.setShipmentOrdersPickupDate(this.dialog.context.orderIds, this.newPickupDate).subscribe(
        () => {
          this._isBusy = false;
          this.dialog.close(true);
        },
        ex => {
          this._log.logErrorData(ex, "Setting new shipment orders pickup date failed.");
          let exception = this._exceptionsHandlerService.getExceptionInfo(ex);
          this._toastr.error(this._localizationService.getLocalizedExceptionString(exception));
        });
    }
  }


  public close() {
    this.dialog.close();
  }


  /**
   * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
   */
  public beforeDismiss(): boolean | Promise<boolean> { return true; }


  constructor(
    public dialog: DialogRef<SetPickupDateDialogModalContext>,
    private _contextService: Shared.ContextService,
    private _shipmentsService: ShipmentService,
    private _localizationService: Shared.LocalizationService,
    private _log: Shared.LoggingService,
    private _modal: Modal,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _toastr: ToastrService,
    private _shipmentService: ShipmentService,
  ) {
    dialog.setCloseGuard(this);

    this._isChangingCrPickupDate = this.dialog.context.anyOrderIsCr;
    this._newPickupDate = this.minDate;
  }
}