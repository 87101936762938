import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

// import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
// import { WjInputModule } from '@grapecity/wijmo.angular2.input';

import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';

import { Ng2CompleterModule } from "ng2-completer";

import { PickupOrdersComponent } from "./components/pickup-orders.component";
import { PickupOrdersListComponent } from "./components/pickup-orders-list.component";
import { PickupOrderEditorComponent } from "./components/pickup-order-editor.component";
import { RegularPickupOrdersListComponent } from "./components/regular-pickup-orders-list.component";
import { OneTimePickupOrdersListComponent } from "./components/one-time-pickup-orders-list.component";
import { SharedModule } from "../shared/shared.module";
import { PickupOrdersService } from "./services/pickup-orders.service";


let components = [
  PickupOrdersComponent,
  PickupOrdersListComponent,
  PickupOrderEditorComponent,
  RegularPickupOrdersListComponent,
  OneTimePickupOrdersListComponent
];

@NgModule({
  imports: [
    // HttpModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // WjInputModule,
    // WjCoreModule,
    InputTextareaModule,
    AutoCompleteModule,
    InputNumberModule,
    DropdownModule,
    InputTextModule,
    CalendarModule,
    SharedModule,
    Ng2CompleterModule
  ],
  providers: [
    PickupOrdersService
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class PickupOrdersModule {
}