<div class="daily-procedure-dialog dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
  <h1 [innerHtml]="'daily_procedure' | shpLocalize"></h1>
  <div class="message error" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
  <div *ngIf="!isSophia" class="ctl">
    <div class="p-field p-field--full">
      <span class="p-float-label">
        <input id="notExportedOrdersCount" value="{{notExportedOrdersCount + notExportedCrCount}}" type="text" name="notExportedOrdersCount" pInputText [readonly]="true">
        <label for="notExportedOrdersCount">{{ 'not_exported_orders_count' | shpLocalize }}</label>
      </span>
    </div>
  </div>
  <div class="ctl">
    <div class="p-field p-field--full">
      <span class="p-float-label">
        <input id="input-not-printed-labels-count" [(ngModel)]="notPrintedLabelsCount" type="text" name="notPrintedLabelsCount" pInputText [readonly]="true">
        <label for="input-not-printed-labels-count">{{ 'not_printed_labels_count' | shpLocalize }}</label>
      </span>
    </div>
  </div>

  <div class="ctl" *ngIf="!isEodContinuous">
    <div class="p-field p-field--full">
      <span class="p-float-label">
        <p-calendar [(ngModel)]="forDate" [showIcon]="true" inputId="for-date" dateFormat="dd/mm/yy" icon="ic-calendar-1" required name="forDate"></p-calendar>
        <label for="delivery-date">{{ 'for_date' | shpLocalize }}</label>
      </span>
    </div>
  </div>

  <div class="ctl">
    <div class="p-field p-field--full">
      <span class="p-float-label">
        <p-inputNumber
          [(ngModel)]="protocolCopiesNumber"
          [min]="1"
          [step]="1"
          [showButtons]="true"
          name="protocolCopiesNumber"
          id="protocol-copies-number"
          mode="decimal"
          decrementButtonClass="p-button-secondary"
          incrementButtonClass="p-button-secondary"
          incrementButtonIcon="ic-add-2"
          decrementButtonIcon="ic-subtract-2"
        ></p-inputNumber>
        <label for="protocol-copies-number">{{ 'number_of_protocol_copies' | shpLocalize }}</label>
      </span>
    </div>
  </div>

  <div class="ctl" *ngIf="isEodContinuous && hasMoreThanOneAddressEnabled">
    <input type="checkbox" id="print-for-all-pickup-addresses" name="printForAllPickupAddresses" [(ngModel)]="isAllAddressesPrintingSelected" />
    <label for="print-for-all-pickup-addresses">{{ 'print_for_all_pickup_addresses' | shpLocalize }}</label>
    <!-- <label for="print-for-all-pickup-addresses"></label> -->
  </div>

  <div class="buttons">
    <button id="button-generate-labels" type="button" (click)="startLabelsGeneration()">{{ 'generate_labels' | shpLocalize }}</button>
    <button *ngIf="isEodContinuous" id="button-generate-acceptance" type="button" (click)="generateProtocols()">{{ 'protocols' | shpLocalize }}</button>
    <button *ngIf="!isEodContinuous" id="button-generate-acceptance" type="button" (click)="generateAcceptanceProtocol()">{{ 'generate_acceptance_protocol' | shpLocalize }}</button>
    <button *ngIf="!isEodContinuous" id="button-generate-cod" type="button" (click)="generateCodList()">{{ 'generate_cod_list' | shpLocalize }}</button>
    <button *ngIf="isExportCityServiceDataAvailable && !isSophia" id="button-export-city-service-data" type="button" (click)="exportCityServiceData()">{{ 'export_city_service_data' | shpLocalize }}</button>
    <button *ngIf="!isSophia && notExportedCrCount > 0" id="button-mark-ready" type="button" (click)="markAllCRsAsReadyForExport()">{{ 'mark_all_crs_as_ready_for_export' | shpLocalize }}</button>
    <button *ngIf="!isSophia && !isCentralShipper" id="button-export" type="button" (click)="manualExportToDpd()">{{ 'export_to_dpd' | shpLocalize }}</button>
    <a class="button" [routerLink]="['/shipments/acceptance-protocol-history']" (click)="close()">{{ 'acceptance_protocol_history' | shpLocalize }}</a>
    <button id="button-close" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>

  <div class="import-result" *ngIf="logEntries?.length > 0">
    <!-- jednotlivé log záznamy -->
    <h2>{{ 'export_to_dpd_log' | shpLocalize }}</h2>
    <div class="log-entry" *ngFor="let entry of logEntries">
      <span class="ic-remove-circle-1 error" *ngIf="entry.severity === 0"></span>
      <span class="ic-interface-alert-triangle warning" *ngIf="entry.severity === 1"></span>
      {{ entry.eventMessage }}
    </div>
  </div>
</div>