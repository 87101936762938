<form #part1form="ngForm" name="part1form">
  <div wizardTag="importProfileEditorBasicData1">
    <div class="ctl ctl-label-15em-width" [class.missingtext]="profile.name_missing__">
      <label for="dynamic-edit-name">{{ 'import_profile_edit_name' | shpLocalize }} *</label>
      <input type="text"
             name="dynamicEditName"
             id="dynamic-edit-name"
             [(ngModel)]="profile.name"
             required
             [class.missingcontrol]="profile.name_missing__"
             [readOnly]="viewOnly" />
    </div>

    <div class="ctl ctl-label-15em-width"  [class.missingtext]="profile.code_missing__">
      <label for="dynamic-edit-code">{{ 'import_profile_edit_code' | shpLocalize }} *</label>
      <input type="text"
             name="dynamicEditCode"
             placeholder="{{ 'import_profile_edit_code_placeholder' | shpLocalize }}"
             id="dynamic-edit-code"
             [(ngModel)]="profile.code"
             required
             [class.missingcontrol]="profile.code_missing__"
             [readOnly]="viewOnly" />
    </div>
  </div>

  <div wizardTag="importProfileEditorBasicData2">
    <div class="ctl ctl-label-15em-width">
      <label for="dynamic-edit-description">{{ 'import_profile_edit_description' | shpLocalize }}</label>
      <input type="text"
            name="dynamicEditDescription"
            id="dynamic-edit-description"
            [(ngModel)]="profile.description"
            [readOnly]="viewOnly" />
    </div>

    <div class="ctl ctl-label-15em-width" *ngIf="!viewOnly">
      <label for="dynamic-edit-customer">{{ 'import_profile_edit_customer' | shpLocalize }}</label>
      <select name="dynamicEditCustomer" id="dynamic-edit-customer" [(ngModel)]="profile.customerDetailId">
        <option *ngIf="!isCustomerLevelAccess" [ngValue]="null">-</option>
        <option [ngValue]="customer.id" *ngFor="let customer of customers">{{customer.name}}</option>
      </select>
    </div>

    <div class="ctl ctl-label-15em-width">
      <label for="dynamic-edit-determiner-column">{{ 'import_profile_edit_determiner_column' | shpLocalize }}</label>
      <div class="flex-grow-1">
        <select name="dynamicEditDeterminerColumn" id="dynamic-edit-determiner-column" class="w-100" [(ngModel)]="profile.csvColumnDelimiter" #columnDelimiterModel="ngModel" shpDifferentFrom="decimalSeparator,multipleValuesDelimiter" [disabled]="viewOnly">
          <option [ngValue]="''">(automatic)</option>
          <option [ngValue]="';'">;</option>
          <option [ngValue]="'|'">|</option>
          <option ngValue='"'>"</option>
          <option [ngValue]="'\t'">tab</option>
        </select>
        <div *ngIf="columnDelimiterModel.errors?.differentFrom">{{ 'delimiters_must_be_unique' | shpLocalize }}</div>
      </div>
    </div>


    <div class="ctl ctl-label-15em-width">
      <label for="dynamic-edit-determiner-row">{{ 'import_profile_edit_determiner_row' | shpLocalize }}</label>
      <select name="dynamicEditDeterminerRow" id="dynamic-edit-determiner-row" [(ngModel)]="profile.csvRowDelimiter" [disabled]="viewOnly">
        <option [ngValue]="''">(automatic)</option>
        <option [ngValue]="'\r\n'">(end of line - Windows)</option>
        <option [ngValue]="'\n'">(end of line - Linux)</option>
      </select>
    </div>

    <div class="ctl ctl-label-15em-width" [class.missingtext]="profile.decimalSeparator_missing__">
      <label for="decimal-separator">{{ 'decimal_separator' | shpLocalize }} *</label>
      <div class="flex-grow-1">
        <select name="decimalSeparator" id="decimal-separator" class="w-100" [(ngModel)]="profile.csvDecimalSeparator" #decimalSeparatorModel="ngModel" required shpDifferentFrom="dynamicEditDeterminerColumn,multipleValuesDelimiter" [disabled]="viewOnly" [class.missingcontrol]="profile.decimalSeparator_missing__">
          <option selected [ngValue]="','">,</option>
          <option [ngValue]="'.'">.</option>
        </select>
        <div *ngIf="decimalSeparatorModel.errors?.differentFrom">{{ 'delimiters_must_be_unique' | shpLocalize }}</div>
      </div>
    </div>

    <div class="ctl ctl-label-15em-width" [class.missingtext]="profile.multipleValuesDelimiter_missing__">
      <label for="multiple-values-delimiter">{{ 'multiple_values_delimiter' | shpLocalize }} *</label>
      <div class="flex-grow-1">
        <select name="multipleValuesDelimiter" id="multiple-values-delimiter" class="w-100" [(ngModel)]="profile.csvMultipleValuesDelimiter" #multipleValuesDelimiterModel="ngModel" required shpDifferentFrom="dynamicEditDeterminerColumn,decimalSeparator" [disabled]="viewOnly" [class.missingcontrol]="profile.multipleValuesDelimiter_missing__">
          <option [ngValue]="','">,</option>
          <option [ngValue]="';'">;</option>
          <option [ngValue]="'|'">|</option>
        </select>
        <div *ngIf="multipleValuesDelimiterModel.errors?.differentFrom">{{ 'delimiters_must_be_unique' | shpLocalize }}</div>
      </div>
    </div>

    <div class="ctl ctl-label-15em-width">
      <label for="dynamic-edit-is-strict">{{ 'import_profile_edit_is_strict' | shpLocalize }}</label>
      <input type="checkbox"
            name="dynamicEditIsStrict" id="dynamic-edit-is-strict" [(ngModel)]="profile.isStrict" *ngIf="!viewOnly" />
      <div class="fake-check-box" *ngIf="viewOnly">
        <span class="check-mark" *ngIf="profile.isStrict"></span>
        <span class="cross-mark" *ngIf="!profile.isStrict"></span>
      </div>
      <label for="dynamic-edit-is-strict"></label>
    </div>

    <div class="ctl ctl-label-15em-width">
      <label for="dynamic-edit-encoding">{{ 'import_profile_encoding' | shpLocalize }}</label>
      <select name="dynamicEditEncoding" id="dynamic-edit-encoding" [(ngModel)]="profile.encoding" [disabled]="viewOnly">
        <option [ngValue]="''">(automatic)</option>
        <option [ngValue]="'utf-8'">UTF-8</option>
        <option [ngValue]="'windows-1250'">Windows-1250 (Central European - Latin 2)</option>
        <option [ngValue]="'windows-1252'">Windows-1252 (Western European - Latin 1)</option>
      </select>
    </div>

    <div class="ctl ctl-label-15em-width">
      <label for="dynamic-edit-contains-header">{{ 'import_profile_edit_contains_header' | shpLocalize }}</label>
      <input type="checkbox"
            name="dynamicEditContainsHeader"
            id="dynamic-edit-contains-header"
            [(ngModel)]="profile.fileIncludesHeader"
            *ngIf="!viewOnly" />
      <div class="fake-check-box" *ngIf="viewOnly">
        <span class="check-mark" *ngIf="profile.fileIncludesHeader"></span>
        <span class="cross-mark" *ngIf="!profile.fileIncludesHeader"></span>
      </div>
      <label for="dynamic-edit-contains-header"></label>
    </div>
  </div>
</form>
