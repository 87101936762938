import { Component, OnInit, EventEmitter, Output, Input, ViewChild, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";

import { Subscription, combineLatest } from "rxjs";

import * as Shared from "../../shared/index";
import { UsersService } from "../services/users.service";
import { LoginData } from "../models/login-data.model";
import { NewsfeedService, User } from "../../shared/index";
import * as UserModel from "../models/user.model";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-login",
  templateUrl: "./login.component.html"
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private _logging: Shared.LoggingService,
    private _localizationService: Shared.LocalizationService,
    private _authenticationService: Shared.AuthenticationService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _usersService: UsersService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _newsfeedService: NewsfeedService
  ) {
  }

  @ViewChild("passwordForm")
  public form: NgForm;

  @Input()
  public exception: Shared.ExceptionInfo = null;
  public isBusy = false;
  public statusMessage = "";

  @Output()
  public processLogin: EventEmitter<LoginData> = new EventEmitter<LoginData>();


  // Polia formulára.
  @Input()
  public login: string = "";
  public password: string = "";


  public displayMessage = "";
  public displayMessageType = "";

  // Môže byť login/request/reset
  public formType = "";

  public newPassword: string = null;
  public newPasswordConfirmation: string = null;


  public canRequest = false;


  public _resetToken: string = null;


  private _resetPasswordModel: UserModel.ResetPasswordModel = null;


  private _routeParamsChangeSubscription: Subscription = null;

  // Submit formulára.
  public logIn = (event) => {
    event.preventDefault();

    this.resetDisplayMessage();

    this.processLogin.emit({ login: this.login.trim(), password: this.password.trim() });
  }


  private resetDisplayMessage = () => {
    this.displayMessage = "";
    this.displayMessageType = "";
  }

  private resetErrorMessage = () => {
    this.exception = null;
  }

  public requestPassword = (event) => {
    this.isBusy = true;

    this.resetDisplayMessage();

    this._usersService.requestPasswordChange(this.login).subscribe(value => {
      this.changeFormType("login");

      this.isBusy = false;

      this.displayMessageType = "Info";
      this.displayMessage = this._localizationService.getLocalizedString("request_password_change_successfully_sent");
    }, ex => {
      this.isBusy = false;

      this.displayMessageType = "Error";
      this.displayMessage = this._localizationService.getLocalizedString("request_password_change_not_successfully_sent");

      this._logging.logDebugData(ex, "Error requesting password change.");
    });
  }


  public resetPassword = (event) => {
    this.isBusy = true;

    this.resetDisplayMessage();

    if (this.form.valid) {
      this._usersService.resetPassword({
        resetToken: this._resetToken,
        login: this.login,
        password: this.newPassword,
        passwordRepeat: this.newPasswordConfirmation
      })
        .subscribe(() => {
          this.changeFormType("login");

          this.isBusy = false;

          this.displayMessageType = "Info";
          this.displayMessage = this._localizationService.getLocalizedString("password_successfully_changed");
        },
          ex => {
            this.isBusy = false;

            this.displayMessageType = "Error";
            this.displayMessage = this._localizationService.getLocalizedString("request_password_change_not_successfully_sent");

            this._logging.logDebugData(ex, "Error changing password.");
          })
    }
  }


  public changeFormType = (type, event?) => {
    if (event) {
      event.preventDefault();
    }

    this.resetDisplayMessage();

    this.formType = type;

    this.resetErrorMessage();

    return false;
  }


  public ngOnInit(): void {
    this._newsfeedService.pause();

    if (this._shipperSettingsService.isCentralShipper) {
      this.canRequest = true;
    }

    this._routeParamsChangeSubscription = this._route.queryParamMap.subscribe(params => {
      const resteToken = params.get("t");

      if (resteToken) {
        this.formType = "reset";
        this._resetToken = resteToken;
      } else {
        this.formType = "login";
      }
    });
  }


  public ngOnDestroy(): void {
    if (this._routeParamsChangeSubscription != null) {
      this._routeParamsChangeSubscription.unsubscribe();
      this._routeParamsChangeSubscription = null;
    }

    this._newsfeedService.start();
  }
}
