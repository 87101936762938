import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { DialogRef, ModalComponent } from "ngx-modialog-7";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

import { ShipmentsImportPathEditorDialogContext, ShipmentsImportPath } from "../models/settings.model";
import { SettingsService } from "../services/settings.service";
import { ExceptionsHandlerService, LoggingService, LocalizationService } from "../../shared/index";
import { ImportProfile } from "../../import-profiles/models/import-profiles.models";


@Component({
  templateUrl: "./shipments-import-path-editor-dialog.component.html"
})
export class ShipmentsImportPathEditorDialogComponent implements ModalComponent<ShipmentsImportPathEditorDialogContext> {
  private _form: FormGroup = null;
  private _shipmentsImportPath: ShipmentsImportPath = null;
  private _isBusy = false;
  private _importProfiles: ImportProfile[] = null;
  private _statusMessage = "";


  public get dialog() { return this._dialog; }
  public get form() { return this._form; }
  public get isBusy() { return this._isBusy; }
  public get importProfiles() { return this._importProfiles; }
  public get statusMessage() { return this._statusMessage; }


  public save() {
    this._isBusy = true;

    let modifiedShipmentsImportPath = _.cloneDeep(this._shipmentsImportPath);
    Object.assign(modifiedShipmentsImportPath, this._form.value);

    this._settingsService.saveShipmentsImportPath(modifiedShipmentsImportPath).subscribe(
      updatedShipmentsImportPath => {
        this._isBusy = false;
        this.dialog.close(updatedShipmentsImportPath);
        this._toastr.success(this._localization.getLocalizedString("shipments_import_path_successfully_saved"));
      }, ex => {
        let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
        this._log.logErrorData(ex);

        this._toastr.error(this._localization.getLocalizedString("error_saving_shipment_import_path") + ": " + this._localization.getLocalizedExceptionString(exceptionInfo));

        this._isBusy = false;
      });

  }


  public cancel() {
    // Nechceme nič uložiť.
    this.dialog.close(null);
  }


  private initializeForm() {
    this._form = this._fb.group({
      path: [this._shipmentsImportPath.path, [Validators.required, Validators.minLength(2)]],
      importProfileId: [this._shipmentsImportPath.importProfileId],
      useSemaphoreFiles: [this._shipmentsImportPath.useSemaphoreFiles]
    });
  }


  constructor(
    private _settingsService: SettingsService,
    private _dialog: DialogRef<ShipmentsImportPathEditorDialogContext>,
    private _exceptionsHandlerService: ExceptionsHandlerService,
    private _log: LoggingService,
    private _localization: LocalizationService,
    private _fb: FormBuilder,
    private _toastr: ToastrService
  ) {
    this._shipmentsImportPath = this._dialog.context.shipmentsImportPath;
    this._importProfiles = this._dialog.context.importProfiles;
    this.initializeForm();
  }
}