import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { AuthenticationService, LOGIN_URL } from "./index";


@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this._authenticationService.isAuthenticated) {
      return true;
    }

    // Nie sme autentifikovaný, ideme na login stránku.
    this._router.navigate([LOGIN_URL]);

    // Vrátime false, aby sme sa nenavigovali tam, kde sme pôvodne mali ísť.
    return false;
  }
}