<div class="content-panel shipments" wizardTag="shipmnetsOverview">
  <shp-filter-list-view
    [allSelected]="areAllVisibleSelected"
    [anyCRExists]="anyCRExists"
    [filter]="filter"
    [showDeletedFilter]="isImpersonatingShipperAdminUser"
    [isCustomerService]="isCustomerService"
    (toggleAllVisibleSelected)="toggleAllVisibleSelected()"
    (filterValueChanged)="filterValueChanged()"></shp-filter-list-view>
  <div class="shipments-list list">

    <div class="filter">
      <input type="text" id="search-box" class="search-box" [formControl]="filterText" placeholder="{{ 'search_placeholder' | shpLocalize }}" />
    </div>
    <div class="items" [class.multi-select]="isMultiselectMode">
      <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>

      <div class="scroll-part" [class.has-pager]="filter.pageSize < totalShipmentsCount">
        <div class="shipment list-item" *ngFor="let shipment of shipments | async; let i = index" [class.odd]="i % 2 === 1"
             [class.all-labels-generated]="shipment.stateId === 'ReadyForExport' || shipment.stateId === 'LabelsPrinted'"
             [class.acceptance-protocol-generated]="shipment.stateId === 'ReadyForExport'"
             [class.selected]="shipment == selectedShipment"
             [class.sent]="shipment.stateId === 'Transport' || shipment.stateId === 'Ordered' || shipment.stateId === 'Delivered' || shipment.stateId === 'Cancelled' || shipment.stateId === 'CollectionFailure' || shipment.stateId === 'Closed' || shipment.stateId === 'DeliveryNotPossible' || shipment.stateId === 'CancelNotPossible' ">
          <div class="selector relative">
            <div class="spread d-flex flex-direction-column">
              <div class="mb-auto">
                <input tabindex="-1" type="checkbox" [ngModel]="shipment.isSelected" (change)="selectionChanged(shipment)" id="shipment-is-selected-{{ shipment.id }}" /><label [attr.for]="'shipment-is-selected-' + shipment.id"></label>
              </div>
              <div *ngIf="shipment.exportDateTimeUtc" class="order-exported-indicator" title="{{ 'date_of_export' | shpLocalize }}: {{ shipment.exportDateTimeUtc | shpLocalizeDate }}">
                <span class="ic-data-upload-8"></span>
              </div>
            </div>
          </div>
          <div tabindex="0" class="content" (click)="select(shipment)" (keypress)="handleShipmentKeyPress($event, shipment)" id="shipment-list-item-{{ shipment.id }}">
            <div class="cr-mark" *ngIf="shipment.isCollectionRequest">
              <strong>CR</strong>
            </div>
            <div class="addresses">
              <div class="address">
                <h3>{{shipment.receiverName}}</h3>
                <span>{{shipment.receiverStreet}}, {{shipment.receiverZip}}, {{shipment.receiverCity}}, {{ shipment.receiverCountryName }}</span>
              </div>
              <div class="address" *ngIf="shipment.isCollectionRequest">
                <h3>{{shipment.senderName}}</h3>
                <span>{{shipment.senderStreet}}, {{shipment.senderZip}}, {{shipment.senderCity}}, {{ shipment.senderCountryName }}</span>
              </div>
            </div>
            <div class="shipment-date">{{shipment.creationDateTimeUtc | shpLocalizeDate:"L" }} <span *ngIf="isPickupDateVisible && shipment.pickupDate">({{ 'shipment_order_pickup_date' | shpLocalize }}: {{ shipment.pickupDate | shpLocalizeDate:"L" }})</span></div>
            <div class="">{{shipment.orderNr}}</div>
            <div class="shipment-parcel-numbers">
              <a href="{{ getTrackingByParcelNrUrl(parcelNumber) }}" target="_blank" class="parcel-number-label" *ngFor="let parcelNumber of shipment.parcelNumbers">{{ parcelNumber }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="pager-part" *ngIf="filter.pageSize < totalShipmentsCount">
        <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
        <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(filter.pageIndex - 1)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
        <button id="button-pager-next" class="next" type="button" (click)="goToPage(filter.pageIndex + 1)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
        <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
        <span class="numbers">{{ filter.pageIndex * filter.pageSize + 1 }} - {{ (filter.pageIndex + 1) * filter.pageSize < totalShipmentsCount ? (filter.pageIndex + 1) * filter.pageSize : totalShipmentsCount }}/{{ totalShipmentsCount }}</span>
      </div>
      <div class="multi-select-commands">
        <button *ngIf="!isCustomerService" id="button-print-labels" type="button" class="bottom-button" (click)="printLabelsForSelected()"><span class="ic-printer"></span><span class="label">{{ 'shipments_print_labels_for_selected' | shpLocalize }}</span></button>
        <button *ngIf="isPrintReturnLabelActionVisible && !isCustomerService" id="button-print-return-labels" type="button" class="bottom-button" (click)="printReturnLabelsForSelected()"><span class="ic-wrap-text"></span><span class="label">{{ 'shipment_print_return_labels_for_selected' | shpLocalize }}</span></button>
        <button *ngIf="allowProtocolsPrintingForSelectedShipments && !isCustomerService" id="button-print-acceptance" type="button" class="bottom-button" (click)="printAcceptanceProtocolForSelected()"><span class="ic-file-tasks-check"></span><span class="label" [innerHtml]="'shipments_print_acceptance_protocol_for_selected' | shpLocalize"></span></button>
        <!--<button id="button-prind-cod" *ngIf="allowProtocolsPrintingForSelectedShipments" type="button" class="bottom-button" (click)="printCodTakeoverProtocolForSelected()"><span class="ic-money-note-coin"></span><span class="label" [innerHtml]="'shipments_print_cod_takeover_protocol_for_selected' | shpLocalize"></span></button>-->
        <button id="button-cr-order-confirmation-protocols" *ngIf="allowProtocolsPrintingForSelectedShipments" type="button" class="bottom-button" (click)="printCrOrderConfirmationProtocolForSelected()"><span class="ic-file-check-2"></span><span class="label">{{ 'print_cr_order_confirmation_protocol' | shpLocalize }}</span></button>
        <button id="button-export" type="button" class="bottom-button" (click)="exportSelected()"><span class="ic-data-upload-8"></span><span class="label" [innerHtml]="'shipments_export_selected' | shpLocalize"></span></button>
        <button id="button-delete" type="button" class="bottom-button" (click)="deleteSelected()"><span class="ic-bin"></span><span class="label">{{ 'shipments_delete_selected' | shpLocalize }}</span></button>
        <button *ngIf="isImpersonatingShipperAdminUser" id="button-undelete" type="button" class="bottom-button" (click)="undeleteSelected()"><span class="ic-sign-recycle"></span><span class="label">{{ 'shipments_undelete_selected' | shpLocalize }}</span></button>
        <!--<button id="button-set-pickup-date" type="button" class="bottom-button" (click)="setPickupDate()"><span class="ic-calendar-pencil"></span><span class="label">{{ 'shipments_set_pickup_date' | shpLocalize }}</span></button>-->
      </div>

      <div class="list-empty-message" *ngIf="totalShipmentsCount === 0 && !isFiltered">
        <div class="message-content">
          {{ 'message_no_shipment_orders_in_db' | shpLocalize }}
        </div>
      </div>

      <div class="list-empty-message" *ngIf="totalShipmentsCount === 0 && isFiltered">
        <div class="message-content">
          {{ 'message_no_shipment_orders_matching_filter' | shpLocalize }}
        </div>
      </div>

      <div class="list-empty-message" *ngIf="hasLoadError">
        <div class="message-content">
          {{ 'massage_shipment_order_load_fail' | shpLocalize }}
        </div>
      </div>
    </div>
    <div class="no-item-selected" *ngIf="selectedShipment == null">
      <div class="message">
        {{ 'shipments_list_select_shipment_advice' | shpLocalize }}
      </div>
    </div>
    <div class="selected-shipment item-overview" *ngIf="selectedShipment != null">
      <div class="shipment-commands item-commands">
        <ng-container *ngIf="selectedShipment.stateId != 'Deleted'">
          <button id="shipment-command-view-edit" class="left-button" type="button" (click)="viewOrEditShipmentOrder(selectedShipment)"><span class="ic-box-edit"></span><span class="label">{{ 'shipment_edit' | shpLocalize }}</span></button>
          <button id="shipment-command-print-labels" class="left-button" *ngIf="!selectedShipment.isCollectionRequest" type="button" (click)="printLabelsForCurrent()"><span class="ic-printer"></span><span class="label">{{ 'shipment_print_labels' | shpLocalize }}</span></button>
          <button id="shipment-command-print-return-labels" class="left-button" *ngIf="isPrintReturnLabelActionVisible && selectedShipment.isReturnShipment" type="button" (click)="printReturnLabelsForCurrent()"><span class="ic-wrap-text"></span><span class="label">{{ 'shipment_print_return_labels' | shpLocalize }}</span></button>
          <button id="shipment-command-print-acceptance" *ngIf="allowProtocolsPrintingForSelectedShipments && !selectedShipment.isCollectionRequest && !selectedShipment.isReturnShipment" class="left-button" type="button" (click)="printAcceptanceProtocolForCurrent()"><span class="ic-file-tasks-check"></span><span class="label">{{ 'shipment_print_acceptance_protocol' | shpLocalize }}</span></button>
          <button id="shipment-command-print-cod" *ngIf="allowProtocolsPrintingForSelectedShipments && selectedShipment.hasCod && !selectedShipment.isCollectionRequest" class="left-button" type="button" (click)="printCodTakeoverProtocolForCurrent()"><span class="ic-money-note-coin"></span><span class="label">{{ 'shipment_print_cod_list' | shpLocalize }}</span></button>
          <button id="button-cr-order-confirmation-protocols" type="button" class="left-button" *ngIf="isCrOrderConfirmationProtocolPrintButtonVisible(selectedShipment)" (click)="printCrOrderConfirmationProtocolForCurrent()"><span class="ic-file-check-2"></span><span class="label">{{ 'print_cr_order_confirmation_protocol_single' | shpLocalize }}</span></button>
          <button id="shipment-command-export" class="left-button" type="button" (click)="exportShipmentOrder(selectedShipment)"><span class="ic-data-upload-8"></span><span class="label">{{ 'shipment_export' | shpLocalize }}</span></button>
          <button id="shipment-command-duplicate" class="left-button" type="button" (click)="duplicateShipmentOrder(selectedShipment)"><span class="ic-file-copy"></span><span class="label">{{ 'duplicate_shipment_order' | shpLocalize }}</span></button>
          <button *ngIf="canDelete(selectedShipment)" id="shipment-command-delete" class="left-button" type="button" (click)="delete(selectedShipment)"><span class="ic-bin"></span><span class="label">{{ 'shipment_delete' | shpLocalize }}</span></button>
          <button *ngIf="!selectedShipment.isCollectionRequest && selectedShipment.isCustomsClearance && this.useMiniportalIntegration && this.isCentralShipper && selectedShipment.stateId != 'New' && selectedShipment.stateId != 'InPreparation' && selectedShipment.stateId != 'Deleted'" id="button-miniportal" type="button" class="left-button" (click)="openMiniportal()"><span class="ic-cabinet-1"></span><span class="label">{{ 'shipments_open_miniportal' | shpLocalize }}</span></button>
        </ng-container>
        <button id="shipment-command-undelete" *ngIf="isImpersonatingShipperAdminUser && selectedShipment.stateId == 'Deleted'" class="left-button" type="button" (click)="undelete(selectedShipment)"><span class="ic-sign-recycle"></span><span class="label">{{ 'shipment_undelete' | shpLocalize }}</span></button>
      </div>
      <div class="shipment-details item-details">
        <div class="base-data">
          <h1>
            <span *ngIf="!selectedShipment.isCollectionRequest">{{ 'shipment' | shpLocalize }} {{ selectedShipment.orderNr }}</span>
            <span *ngIf="selectedShipment.isCollectionRequest">{{ 'shipment_filter_collection_request' | shpLocalize }} </span>
          </h1>
          <span [class.labels-printed]="selectedShipment.stateId === 'LabelsPrinted'"
                [class.ready-for-export]="selectedShipment.stateId === 'ReadyForExport'"
                [class.transport]="selectedShipment.stateId === 'Transport' || selectedShipment.stateId === 'Ordered' || selectedShipment.stateId === 'Delivered' || selectedShipment.stateId === 'Cancelled' || selectedShipment.stateId === 'CollectionFailure' || selectedShipment.stateId === 'Closed'"
                class="state">{{ 'order_state_' + selectedShipment.stateId | shpLocalize }}</span>
          <!-- <div class="services" *ngIf="!selectedShipment.isCollectionRequest"> -->
          <div class="services">
            {{ selectedShipment.productName }}
            <span *ngIf="selectedShipment.additionalServiceNames.length > 0">
              + <span *ngFor="let name of selectedShipment.additionalServiceNames; let i = index"><span *ngIf="i > 0">, </span>{{ name }}</span>
            </span>
          </div>
          <div class="shipment-date">
            <span *ngIf="isPickupDateVisible || selectedShipment.isCollectionRequest"><span class="ic-18 ic-shipping-truck-delivery-time"></span> {{ selectedShipment.pickupDate | shpLocalizeDate:"L" }} ({{ 'order_created_on' | shpLocalize }}: {{ selectedShipment.creationDateTimeUtc | shpLocalizeDate }})</span>
            <span *ngIf="!isPickupDateVisible && !selectedShipment.isCollectionRequest"><span class="ic-18 ic-shipping-truck-delivery-time"></span> {{ selectedShipment.creationDateTimeUtc | shpLocalizeDate:"L" }}</span>
            <span *ngIf="selectedShipment.exportDateTimeUtc"><span class="ic-18 ic-data-upload-8"></span> {{ selectedShipment.exportDateTimeUtc | shpLocalizeDate }}</span>
          </div>
        </div>
        <div class="sender address">
          <h2>{{ 'shipment_sender' | shpLocalize }}</h2>
          <div class="property name">
            <div class="label">{{ 'address_name' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.senderName }}</div>
          </div>
          <div class="property name" *ngIf="selectedShipment.senderContactPerson">
            <div class="label">{{ 'address_contact_person' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.senderContactPerson }}</div>
          </div>
          <div class="property street">
            <div class="label">{{ 'address_street' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.senderStreet }}</div>
          </div>
          <div class="property street" *ngIf="selectedShipment.senderStreetDetail">
            <div class="label">{{ 'address_street_detail' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.senderStreetDetail }}</div>
          </div>
          <div class="property city">
            <div class="label">{{ 'address_city' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.senderCity }}</div>
          </div>
          <div class="property zip">
            <div class="label">{{ 'address_zip' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.senderZip }}</div>
          </div>
          <div class="property country">
            <div class="label">{{ 'address_country' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.senderCountryName }}</div>
          </div>
        </div>

        <div class="receiver address">
          <h2>{{ 'shipment_receiver' | shpLocalize }}</h2>
          <div class="property name">
            <div class="label">{{ 'address_name' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverName }}</div>
          </div>
          <div class="property name" *ngIf="selectedShipment.receiverContactPerson">
            <div class="label">{{ 'recipient_organization_contact_name' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverContactPerson }}</div>
          </div>
          <div class="property street">
            <div class="label">{{ 'address_street' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverStreet }}</div>
          </div>
          <div class="property street" *ngIf="selectedShipment.receiverStreetDetail">
            <div class="label">{{ 'recipient_street_detail' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverStreetDetail }}</div>
          </div>
          <div class="property city">
            <div class="label">{{ 'address_city' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverCity }}</div>
          </div>
          <div class="property zip">
            <div class="label">{{ 'address_zip' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverZip }}</div>
          </div>
          <div class="property country">
            <div class="label">{{ 'address_country' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverCountryName }}</div>
          </div>

          <div class="property country">
            <div class="label">{{ 'address_email' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverEmail }}</div>
          </div>

          <div class="property country">
            <div class="label">{{ 'address_phone' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverPhone }}</div>
          </div>

          <div class="property country">
            <div class="label">{{ 'address_reference' | shpLocalize }}</div>
            <div class="value">{{ selectedShipment.receiverReference }}</div>
          </div>
        </div>

        <div *ngIf="selectedShipment.isCollectionRequest && isCustomerRefUsedForCollectionRequest" class="base-data">
          <h2>
            {{ 'customer_reference_nr' | shpLocalize }}:
            <a href="{{ getTrackingByReferenceUrl(selectedShipment.customerReferenceNr) }}" target="_blank">{{ selectedShipment.customerReferenceNr }}</a>
          </h2>
        </div>

        <div *ngIf="selectedShipment.isCollectionRequest" class="parcels">
          <h2>{{ 'shipment_parcels' | shpLocalize }}: {{ selectedShipment.parcelsCount }}</h2>
        </div>

        <div *ngIf="!selectedShipment.isCollectionRequest" class="parcels">
          <h2>{{ 'shipment_parcels' | shpLocalize }}</h2>
          <div class="parcel" *ngFor="let parcel of selectedShipment.parcels; let index = index" [class.odd]="index % 2 === 1">
            <span class="parcel-number"><a href="{{ getTrackingByParcelNrUrl(parcel.parcelNr) }}" target="_blank">{{ parcel.parcelNr }}</a></span>
            <span *ngIf="parcel.referentialInfo1" class="reference">{{ 'parcel_referential_info_1' | shpLocalize }}: <span><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo1) }}" target="_blank">{{ parcel.referentialInfo1 }}</a></span></span>
            <span *ngIf="parcel.referentialInfo2" class="reference">{{ 'parcel_referential_info_2' | shpLocalize }}: <span><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo2) }}" target="_blank">{{ parcel.referentialInfo2 }}</a></span></span>
            <span *ngIf="parcel.referentialInfo3" class="reference">{{ 'parcel_referential_info_3' | shpLocalize }}: <span><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo3) }}" target="_blank">{{ parcel.referentialInfo3 }}</a></span></span>
            <span *ngIf="parcel.referentialInfo4" class="reference">{{ 'parcel_referential_info_4' | shpLocalize }}: <span><a href="{{ getTrackingByReferenceUrl(parcel.referentialInfo4) }}" target="_blank">{{ parcel.referentialInfo4 }}</a></span></span>
          </div>
        </div>
        <!--{{ selectedShipment | json }}-->

      </div>
    </div>
  </div>
</div>