import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import * as _ from "lodash";
import { combineLatest, of } from "rxjs";
import { filter, finalize, switchMapTo, take } from "rxjs/operators";

import { ShipperWizardTestLabelPopupComponent } from "../components/wizard-components/shipper-wizard-test-label-popup.component";
import { API_URL, AuthenticationService, BUSINESS_UNIT_CODE_NL, LocalizationService } from "../index";
import { WizardUserRecord } from "../models/wizard-user-record.model";
import { WizardEventType, WizardMessagePopup, WizardStepChange, WizardStepChangeType } from "../modules/wizard/models";
import { WizardService } from "../modules/wizard/services/wizard.service";
import { ContextService } from "./context.service";
import { GlobalEventsStreamService, UserLoginEvent } from "./global-events-stream.service";
import { ShipperWizardDataProvider, ShipperWizardPopup, WizardChapterName, WizardStepName } from "./shipper-wizard-steps";

export enum ShipperWizardUserAction {
  ChangedBasicSettings,
  ChangedPrintSettings,
  SavedPrintSettings,
  SavedUser,
  SavedRecipient
}

type RegOption<T> = T | ((nextStepName?: string) => T);
type WizardMessagePopupRegOptions = {[K in keyof Omit<WizardMessagePopup, "component">]: RegOption<WizardMessagePopup[K]>};

interface RegisterNextStepPopupOptions extends WizardMessagePopupRegOptions {
  component: WizardMessagePopup["component"];
  dismissValue: RegOption<boolean | WizardStepName>;
  closeValue: RegOption<boolean | WizardStepName>;
  skipCondition: ((nextStepName?: string) => boolean);
  finally: () => void;
}

function getRegOptionValue<T>(
    option: RegOption<T>,
    defaultValue: T,
    nextStepName: string): T {
  const fn = _.isFunction(option) ?
    option :
    typeof option !== "undefined" ?
      () => option as T :
      () => defaultValue;

  return fn(nextStepName);
}

@Injectable({
  providedIn: "root"
})
export class ShipperWizardService {
  canGoToNextStep = true;
  hasTestLabelError = false;
  hasUserCompletedMandatorySteps = false;
  hasUserEditorUnsavedListChanges = false;
  importProfileSaveError: string | null = null;

  private completedChapters = new Set<WizardChapterName>();
  private completedSteps = new Set<WizardStepName>();
  private currentUserRecord: WizardUserRecord = null;
  private isSavingRecord = false;
  private userActions = new Set<ShipperWizardUserAction>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private contextService: ContextService,
    private localizationService: LocalizationService,
    private globalEventsStreamService: GlobalEventsStreamService,
    private wizardService: WizardService,
    private wizardDataProvider: ShipperWizardDataProvider
  ) {
    this.observeWizardState();
    this.observeWizardEvents();
    this.observeAuth();
    this.observeUserLoginEvents();

    this.registerNextStepPopups();

    this.wizardDataProvider.stepModified$.subscribe(() => {
      this.wizardService.reloadCurrentStep();
    });
  }

  addUserAction(name: ShipperWizardUserAction) {
    if (this.wizardService.isActive) {
      this.userActions.add(name);
    }
  }

  setCreatedUser(id: number) {
    if (!this.wizardService.isActive) {
      return;
    }

    this.wizardDataProvider.updateUserEditorForcedNavigation({
      url: `/settings/user-management/users/${id}`
    });

    this.wizardDataProvider.omittedSteps.delete(WizardStepName.EditUser);
    // this.wizardDataProvider.alteredPreviousSequence
    //   .set(WizardStepName.EditUser, WizardStepName.NewUser);

  }

  clearCreatedUser() {
    if (!this.wizardService.isActive) {
      return;
    }

    this.hasUserEditorUnsavedListChanges = false;
    this.wizardDataProvider.restoreUserEditorForcedNavigation();
    this.wizardDataProvider.omittedSteps.add(WizardStepName.EditUser);
    // this.wizardDataProvider.alteredPreviousSequence.delete(WizardStepName.EditUser);
  }

  setCreatedImportProfileId(id: number) {
    if (!this.wizardService.isActive) {
      return;
    }

    this.wizardDataProvider.updateImportProfileEditorForcedNavigation({
      url: `/import-profiles/shipments/edit/${id}`,
      test: /^\/import-profiles\/shipments\/edit\/\d+/
    });
  }

  /* #region  Popups registration */
  private registerNextStepPopups() {
    // Basic settings - no changes message.
    this.registerNextStepPopup(WizardStepName.SettingsBasic, {
      skipCondition: () => this.userActions.has(ShipperWizardUserAction.ChangedBasicSettings),
      finally: () => this.userActions.add(ShipperWizardUserAction.ChangedBasicSettings)
    });

    // Print settings - test label message.
    this.registerNextStepPopup(WizardStepName.SettingsPrintTestLabel, {
      component: ShipperWizardTestLabelPopupComponent,
      dismissValue: WizardStepName.SettingsPrint,
      skipCondition: () => this.hasTestLabelError
    });

    // User management.
    this.registerNextStepPopup(WizardStepName.NewUser, {
      skipCondition: (nextStepName) => nextStepName === WizardStepName.EditUser ||
        this.userActions.has(ShipperWizardUserAction.SavedUser),
      finally: () => this.userActions.add(ShipperWizardUserAction.SavedUser)
    });

    // User management - unsaved changes in step 2.
    this.registerNextStepPopup(WizardStepName.EditUser, {
      skipCondition: () => !this.hasUserEditorUnsavedListChanges,
      closeLabel: "action_wizard_continue_anyway",
      hasDismissButton: true,
    });

    // Recipient editor.
    this.registerNextStepPopup(WizardStepName.NewRecipient, {
      skipCondition: () => this.userActions.has(ShipperWizardUserAction.SavedRecipient),
      finally: () => this.userActions.add(ShipperWizardUserAction.SavedRecipient)
    });

    // Import profile save.
    this.registerNextStepPopup(WizardStepName.ClickSaveImportProfile, {
      message: () => !this.importProfileSaveError ?
        "wizard_popup_message_ClickSaveImportProfile_success" :
        this.importProfileSaveError === "import_profile_with_same_code_already_exists" ?
          "wizard_popup_message_ClickSaveImportProfile_fail_code" :
          "wizard_popup_message_ClickSaveImportProfile_fail",
      hasDismissButton: () => Boolean(this.importProfileSaveError),
      dismissLabel: "action_wizard_edit_import_profile",
      dismissValue: WizardStepName.ImportProfileBasicData1,
      closeLabel: () => this.importProfileSaveError ?
        "action_wizard_continue_anyway" :
        "action_wizard_continue"
    });
  }

  private registerNextStepPopup(
      stepName: WizardStepName,
      options: Partial<RegisterNextStepPopupOptions> = {}) {
    const skipConditionFn = _.isFunction(options.skipCondition) ?
      options.skipCondition :
      () => false;

    this.wizardService.registerBeforeNextStep(
      stepName,
      (nextStepName) => {
        if (skipConditionFn(nextStepName)) {
          return of(true).toPromise();
        }

        const messagePopup: WizardMessagePopup = {
          component: options.component,
          componentData: getRegOptionValue(options.componentData, null, nextStepName),
          message: getRegOptionValue(options.message, `wizard_popup_message_${stepName}`, nextStepName),
          hasCloseButton: getRegOptionValue(options.hasCloseButton, true, nextStepName),
          closeLabel: getRegOptionValue(options.closeLabel, "action_wizard_continue", nextStepName),
          hasDismissButton: getRegOptionValue(options.hasDismissButton, options.dismissValue != null, nextStepName),
          dismissLabel: getRegOptionValue(options.dismissLabel, "action_wizard_back", nextStepName)
        };

        this.localizeMessagePopup(messagePopup);

        return this.wizardService.addMessagePopup(messagePopup)
          .then(() => getRegOptionValue(options.closeValue, true, nextStepName))
          .catch(() => getRegOptionValue(options.dismissValue, false, nextStepName))
          .finally(() => {
            if (options.finally) {
              options.finally();
            }
          });
      }
    );
  }

  private localizeMessagePopup(messagePopup: WizardMessagePopup) {
    messagePopup.message = this.localizationService.getLocalizedString(messagePopup.message).replace(/\\n/g, "\n");
    messagePopup.closeLabel = this.localizationService.getLocalizedString(messagePopup.closeLabel);
    messagePopup.dismissLabel = this.localizationService.getLocalizedString(messagePopup.dismissLabel);
  }
  /* #endregion */

  private observeWizardState() {
    this.wizardService.state$.subscribe(() => {
      this.setHasUserCompletedMandatorySteps();
    });
  }

  private setHasUserCompletedMandatorySteps() {
    this.hasUserCompletedMandatorySteps = [
      WizardChapterName.Welcome,
      WizardChapterName.Settings,
      WizardChapterName.AddressBook
    ].every(chapterName => this.wizardService.stateSnapshot.avaiableChapters.has(chapterName));
  }

  private observeWizardEvents() {
    this.wizardService.events$.subscribe(event => {
      switch (event.type) {
        case WizardEventType.Start:
          this.resetWizard();
          break;
        case WizardEventType.Close:
          this.globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.Wizard);
          break;
        case WizardEventType.Finish:
          this.completeChapter(WizardChapterName.End);
          this.resetWizard();
          this.router.navigate(["shipments"]);
          this.globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.Wizard);
          break;
        case WizardEventType.StepChange:
          this.onStepWizardChange(event.payload);
          break;
        default:
          break;
      }
    });
  }

  private resetWizard() {
    this.userActions.clear();
    this.completedSteps.clear();
    this.completedChapters.clear();
    this.wizardDataProvider.alteredNextSequence.clear();
    this.wizardDataProvider.alteredPreviousSequence.clear();

    this.canGoToNextStep = true;

    this.wizardDataProvider.omittedChapters.delete(WizardChapterName.ImportProfiles);
  }

  /* #region  User navigation */
  private onStepWizardChange(change) {
    // Assume user can go to next step when step changes.
    // Evaluate in a component class.
    this.canGoToNextStep = true;

    this.checkChapterComplete(change);

    this.alterSequenceOnGoToImportProfiles(change);
    this.removeImportProfilesFromCompletedChaptersOnGoToImportProfiles(change);

    this.reincludeImportProfiles(change);
    this.restoreSequenceAfterGoToImportProfiles(change);
  }

  private checkChapterComplete(change: WizardStepChange<WizardStepName, WizardChapterName>) {
    if (!change.oldStep || this.completedChapters.has(change.oldChapter)) {
      return;
    }

    // Complete chapter immediately when user exits it.
    if (change.type === WizardStepChangeType.CompleteCurrentChapter) {
      this.completeChapter(change.oldChapter);
      return;
    }

    this.completedSteps.add(change.oldStep);

    const chapter = this.wizardDataProvider.getChapter(change.oldChapter);

    const isComplete = [...chapter.steps]
      .filter(s => !this.wizardDataProvider.omittedSteps.has(s))
      .every(s => this.completedSteps.has(s));

    if (isComplete) {
      this.completeChapter(chapter.name);
    }
  }

  /** Marks chapter as complete. */
  private completeChapter(name: WizardChapterName) {
    if (this.completedChapters.has(name)) {
      return;
    }

    const chapterSteps = this.wizardDataProvider.getChapter(name)?.steps;

    chapterSteps.forEach(step => this.completedSteps.add(step));
    this.completedChapters.add(name);

    this.saveUserRecord();

    if (name === WizardChapterName.ImportProfiles) {
      this.wizardDataProvider.omittedChapters.add(WizardChapterName.ImportProfiles);
    }
  }

  /** Removes ImportProfiles from completed chapters when user skips there. */
  private removeImportProfilesFromCompletedChaptersOnGoToImportProfiles(change: WizardStepChange<WizardStepName>) {
    if (change.customType !== "skipToImportProfiles") {
      return;
    }

    const importProfiles = this.wizardDataProvider.getChapter(WizardChapterName.ImportProfiles);
    this.completedChapters.delete(WizardChapterName.ImportProfiles);
    importProfiles.steps.forEach(step => this.completedSteps.delete(step));
  }

  /** Alters sequence of first/last step of ImportProfiles chapter when user skips there. */
  private alterSequenceOnGoToImportProfiles(change: WizardStepChange<WizardStepName>) {
    if (change.customType !== "skipToImportProfiles") {
      return;
    }

    const importProfiles = this.wizardDataProvider.getChapter(WizardChapterName.ImportProfiles);
    const alternativePreviousStep = change.oldStep;
    const alternativeNextStep = this.wizardDataProvider.getNextStep(alternativePreviousStep)?.name;

    // Navigate back to the old step when user goes back from ImportProfiles.
    this.wizardDataProvider.alteredPreviousSequence.set(importProfiles.firstStepName, alternativePreviousStep);
    // Navigate to the next step (of the old step) when user finishes the ImportProfiles.
    this.wizardDataProvider.alteredNextSequence.set(importProfiles.lastStepName, alternativeNextStep);

    [...importProfiles.steps.values()]
      .map(stepName => this.wizardDataProvider.getStep(stepName))
      .filter(step => Boolean(step.popup?.popupComponentData?.navigation))
      .forEach(step => (step.popup as ShipperWizardPopup).popupComponentData.navigation.shouldExitOnChapterNavigation = true);
  }

  /**
   * Since user can skip to ImportProfiles the chapter is omitted once it's complete (@see completeChapter).
   *
   * ImportProfiles are reincluded when
   * - ImportProfiles were entered directly
   * - user got to the End chapter which is after the ImportProfiles
   */
  private reincludeImportProfiles(change: WizardStepChange<WizardStepName>) {
    const importProfiles = this.wizardDataProvider.getChapter(WizardChapterName.ImportProfiles);
    const end = this.wizardDataProvider.getChapter(WizardChapterName.End);

    if (end.steps.has(change.finalStep) || importProfiles.steps.has(change.finalStep)) {
      this.wizardDataProvider.omittedChapters.delete(WizardChapterName.ImportProfiles);
    }
  }

  /**
   * Restores sequnce after @see alterSequenceOnGoToImportProfiles.
   *
   * Sequence is restore when user leaves Import profiles.
   */
  private restoreSequenceAfterGoToImportProfiles(change: WizardStepChange<WizardStepName>) {
    const importProfilesSet = this.wizardDataProvider.getChapter(WizardChapterName.ImportProfiles);

    if (importProfilesSet.steps.has(change.finalStep)) {
      return;
    }

    this.wizardDataProvider.alteredNextSequence.delete(importProfilesSet.lastStepName);
    this.wizardDataProvider.alteredPreviousSequence.delete(importProfilesSet.firstStepName);

    [...importProfilesSet.steps.values()]
      .map(stepName => this.wizardDataProvider.getStep(stepName))
      .filter(step => Boolean(step.popup?.popupComponentData?.navigation))
      .forEach(step => (step.popup as ShipperWizardPopup).popupComponentData.navigation.shouldExitOnChapterNavigation = false);
  }
  /* #endregion */

  private saveUserRecord() {
    if (this.isSavingRecord) {
      return;
    }

    const savedChapters = this.parseCompletedChapters(this.currentUserRecord);
    const completedChapters = [...this.completedChapters];

    if (completedChapters.every(s => savedChapters.includes(s))) {
      return;
    }

    this.isSavingRecord = true;

    const model: Partial<WizardUserRecord> = {
      ...this.currentUserRecord ?? {},
      wizardData: JSON.stringify(_.uniq([
        ...savedChapters,
        ...completedChapters
      ]))
    };

    this.http.put<WizardUserRecord>(`${API_URL}/users/wizard`, model).pipe(
      finalize(() => this.isSavingRecord = false)
    ).subscribe(record => this.currentUserRecord = record, () => { });
  }

  private parseCompletedChapters(record: Partial<WizardUserRecord>): WizardChapterName[] {
    let result;
    try {
      result = JSON.parse(record?.wizardData);
    } catch (error) {
      result = [];
    }

    return result;
  }

  private observeAuth() {
    this.authenticationService.user.pipe(
      filter(u => Boolean(u))
    ).subscribe(() => {
      this.clearCreatedUser();

      this.importProfileSaveError = null;
      this.wizardDataProvider.restoreImportProfileEditorForcedNavigation();

      this.wizardService.setAvailableChapters([]);
      this.hasUserCompletedMandatorySteps = false;

      this.resetWizard();
      this.loadUserRecord();
    });
  }

  private loadUserRecord() {
    this.currentUserRecord = null;

    this.http.get<WizardUserRecord>(`${API_URL}/users/wizard`)
      .subscribe(record => {
        const completedChapters = this.parseCompletedChapters(record);

        if (completedChapters.length) {
          const chapters = this.wizardDataProvider.getChapters();
          const firstNotCompletedChapter = chapters.find(chapter => !completedChapters.includes(chapter.name));

          this.wizardService.setAvailableChapters([
            ...completedChapters,
            // Enable also the first chapter which was not completed.
            firstNotCompletedChapter?.name
          ]);
        } else {
          this.wizardService.setAvailableChapters([WizardChapterName.Welcome]);
        }

        this.currentUserRecord = record;
      }, () => { });
  }

  private observeUserLoginEvents() {
    this.globalEventsStreamService.userLoginEvents$.pipe(
      filter(event => event.current === UserLoginEvent.Wizard),
      switchMapTo(
        combineLatest([
          this.authenticationService.user,
          this.contextService.businessUnitCode
        ]).pipe(take(1))
      )
    ).subscribe(([user, buCode]) => {
      if (user?.isFirstLogin && buCode === BUSINESS_UNIT_CODE_NL) {
        this.wizardService.start();
      } else {
        this.globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.Wizard);
      }
    });
  }
}
