import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { BehaviorSubject, forkJoin } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";

import * as Shared from "../../../shared/index";
import * as SettingsModel from "../../models/settings.model";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import * as ConfigModel from "../../../shared/models/config.models";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { ShipmentService } from "../../../shipments/services/shipments.service";
import { PrinterType, PrintingType, PrinterLanguage, PaperSize } from "../../../shared/models/config.models";
import { PrinterRule } from "../../models/settings.model";
import { UsersService } from "../../../user/services/users.service";
import { User } from "../../../user/models/user.model";
import { Address, CustomerDetail } from "../../../shared/models/customer.models";


@Component({
  templateUrl: "./printer-rules-settings.component.html"
})
export class PrinterRulesSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  private _printers: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _printerTypes: BehaviorSubject<PrinterType[]> = new BehaviorSubject(null);
  private _printingTypes: BehaviorSubject<PrintingType[]> = new BehaviorSubject(null);
  private _printerLanguages: BehaviorSubject<PrinterLanguage[]> = new BehaviorSubject(null);
  private _paperSizes: BehaviorSubject<PaperSize[]> = new BehaviorSubject(null);
  private _importPaths: BehaviorSubject<string[]> = new BehaviorSubject(null);
  private _users: BehaviorSubject<User[]> = new BehaviorSubject(null);
  private _products: BehaviorSubject<ConfigModel.Product[]> = new BehaviorSubject(null);
  private _addresses: BehaviorSubject<Address[]> = new BehaviorSubject(null);
  private _customerDetails: BehaviorSubject<CustomerDetail[]> = new BehaviorSubject(null);

  private _labelsPrinterRules: PrinterRule[] = null;
  private _protocolsPrinterRules: PrinterRule[] = null;


  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    private _shipmentService: ShipmentService,
    private _usersService: UsersService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    toastr: ToastrService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }


  public get labelsPrinterRules() { return this._labelsPrinterRules; }
  public get protocolsPrinterRules() { return this._protocolsPrinterRules; }

  public get printers() { return this._printers.asObservable(); }
  public get printingTypes() { return this._printingTypes.asObservable(); }
  public get printerTypes() { return this._printerTypes.asObservable(); }
  public get printerLanguages() { return this._printerLanguages.asObservable(); }
  public get paperSizes() { return this._paperSizes.asObservable(); }
  public get importPaths() { return this._importPaths.asObservable(); }
  public get users() { return this._users.asObservable(); }
  public get products() { return this._products.asObservable(); }
  public get addresses() { return this._addresses.asObservable(); }
  public get customerDetails() { return this._customerDetails.asObservable(); }


  private loadPrintersAndLookups() {
    this.isBusy = true;
    return forkJoin([
      this.settingsService.getPrinters(),
      this.settingsService.getPrintingTypes(),
      this.settingsService.getPrinterTypes(),
      this.settingsService.getPrinterLanguages(),
      this.settingsService.getPaperSizes(),
      this.settingsService.getShipmentsImportPaths(),
      this.contextService.currentCustomerDetail.pipe(take(1),mergeMap(cd => this._skdataConfigService.getAllowedProductsAndAdditionalServices(cd.id)) ),
      this.contextService.addresses.pipe(take(1)),
      this.contextService.customerDetails.pipe(take(1)),
      this._usersService.getUsers(this.tenantId)
    ]).pipe(
      map((result: any[]) => {
        this._printers.next(result[0]);
        this._printingTypes.next(result[1]);
        this._printerTypes.next(result[2]);
        this._printerLanguages.next(result[3]);
        this._paperSizes.next(result[4]);
        this._importPaths.next(result[5].map(p => p.path));
        this._products.next(result[6].filter(p => p.isProduct));
        this._addresses.next(result[7]);
        this._customerDetails.next(result[8]);
        this._users.next(result[9]);

        return true;
      })
    )
  }


  /**
   * Načíta pravidlá pre tlačiarne a rozdelí ich na dve skupiny,
   * podľa toho, či ide o tlač štítkov alebo protokolov.
   */
  private loadPrinterRules() {
    this.settingsService.getPrinterRules().subscribe(printerRules => {
      // Rozdelíme ich na 2 skupiny.

      let labelsPrinterRules = printerRules.filter(r => r.printingTypeCode === SettingsModel.LabelsPrintingTypeCode);
      let protocolsPrinterRules = printerRules.filter(r => r.printingTypeCode === SettingsModel.ProtocolsPrintingTypeCode);

      this._labelsPrinterRules = _.orderBy(labelsPrinterRules, "priority", "desc");
      this._protocolsPrinterRules = _.orderBy(protocolsPrinterRules, "priority", "desc");

      this.isBusy = false;
    }, ex => {
      let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex);
      this.toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo));
      this.isBusy = false;
    });
  }


  protected afterSettingsLoaded() {
    this.loadPrintersAndLookups().subscribe(loaded => {
      this.loadPrinterRules();
    }, ex => {
      let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex);
      this.toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo));
      this.isBusy = false;
    });
  }

}