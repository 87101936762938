<div class="" *ngIf="isInitialized" [class.menu-collapsed]="isMenuCollapsed">
  <div class="dpd-shipper-logo" (click)="toggleMenuSize()"><span>DPD</span></div>
  <div class="menu">
    <shp-navigation></shp-navigation>
    <shp-app-info></shp-app-info>
    <shp-current-user></shp-current-user>
    <div class="collapse-toggle" (click)="toggleMenuSize()"><span class="" [class.ic-navigation-left-3]="!isMenuCollapsed" [class.ic-navigation-right-3]="isMenuCollapsed"></span></div>
    <shp-login-popup></shp-login-popup>
  </div>
  <router-outlet></router-outlet>
  <div *ngIf="isWizardActive" class="wizard__bottom-panel">
    <wizard-chapters></wizard-chapters>
  </div>
  <button *ngIf="isWizardCloseBtnDisplayed" class="wizard__close-btn wizard--enabled-section" (click)="closeWizard()"><span class="ic-close"></span></button>
</div>