import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-view-settings",
  templateUrl: "./view-settings.component.html"
})
export class ViewSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    toastr: ToastrService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }

}