import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, filter, finalize } from "rxjs/operators";

import * as Shared from "../../../shared/index";
import { ParcelCsvExportProfileEditorModel } from "../../models/parcel-csv-export-profile-with-fields.model";
import { ParcelCsvExportProfileService } from "../../services/parcel-csv-export-profile.service";
import { SettingsService } from "../../services/settings.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-parcel-export-settings-file-content.component",
  templateUrl: "./parcel-export-settings-file-content.component.html",
})
export class ParcelExportSettingsFileContentComponent extends TenantSettingsEditorBase implements OnInit {
  model: ParcelCsvExportProfileEditorModel;
  isBusyExportProfile = false;

  constructor(
      loggingService: Shared.LoggingService,
      globalEventsStreamService: Shared.GlobalEventsStreamService,
      localizationService: Shared.LocalizationService,
      authenticationService: Shared.AuthenticationService,
      router: Router,
      settingsService: SettingsService,
      contextService: Shared.ContextService,
      exceptionsHandlerService: Shared.ExceptionsHandlerService,
      toastr: ToastrService,
      private _parcelCsvExportProfileService: ParcelCsvExportProfileService) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }


  ngOnInit() {
    super.ngOnInit();
    this.observeCustomerChanges();
  }


  saveExportProfile() {
    this.isBusyExportProfile = true;

    this._parcelCsvExportProfileService.saveExportProfile(this.model).pipe(
      finalize(() => this.isBusyExportProfile = false)
    ).subscribe(() => this.loadProfile(), () => {});
  }


  private observeCustomerChanges() {
    this.contextService.currentCustomerDetail.pipe(
      filter(c => Boolean(c)),
      distinctUntilChanged()
    ).subscribe(() => this.loadProfile());
  }


  private loadProfile() {
    this.isBusyExportProfile = true;

    this._parcelCsvExportProfileService.getExportProfile().pipe(
      finalize(() => this.isBusyExportProfile = false)
    ).subscribe(profile => {
      this.model = profile ? profile : {};
    });
  }
}
