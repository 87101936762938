import { AfterContentInit, Directive, HostBinding, OnDestroy } from "@angular/core";

import { Dropdown } from "primeng/dropdown";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "p-dropdown"
})
export class PrimengDropdownDirective implements AfterContentInit, OnDestroy {
  /**
   * CSS p-inputwrapper-filled class was used to move a floating label away.
   * However, it's bound to the value of the selected option. If an empty value
   * has a text it's overlaid by the label.
   *
   * Use this class to style the floating label for the dropdown instead.
   */
  @HostBinding("class.has-selected-option-label")
  get hasInputText(): boolean {
    return this.dropdown.selectedItemTemplate ?
      this.hasLabelTemplateText :
      Boolean(this.dropdown.label?.length);
  }

  labelElement: HTMLElement;

  private mutationObserver: MutationObserver | null = null;
  private hasLabelTemplateText = false;

  constructor(private dropdown: Dropdown) { }

  ngAfterContentInit() {
    // Observe DOM changes when using selectedItem template.
    Promise.resolve().then(() => {
      if (this.dropdown.selectedItemTemplate) {
        this.labelElement =
            this.dropdown.el.nativeElement.querySelector(".p-dropdown-label");

        this.mutationObserver = new MutationObserver(mutationList => {
          const hasChanges = mutationList.some(m => m.type === "childList");

          if (hasChanges) {
            this.setHasLabelTemplateText();
          }
        });

        this.setHasLabelTemplateText();

        this.mutationObserver.observe(this.labelElement, {
          childList: true,
          attributes: false
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private setHasLabelTemplateText() {
    this.hasLabelTemplateText = Boolean(this.labelElement.textContent.trim().length);
  }
}
