import { Directive, Input } from "@angular/core";

import { ReplaySubject } from "rxjs";

@Directive({
  selector: "[appTable]"
})
export class TableDirective {
  columnChanges$ = new ReplaySubject(1);
    
  @Input() 
  set displayColumns(value: string[]) {
    this._displayColumns = value;
    this.columnChanges$.next(value);
  }

  get displayColumns(): string[] {
    return this._displayColumns;
  }

  private _displayColumns: string[];


  constructor() { }
}