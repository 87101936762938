<div class="settings-category user-editor">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <h2>{{ 'change_password' | shpLocalize }}</h2>
  <div *ngIf="hasUnchagedPassword && !isOldPassword" class="m-2 mt-0 warning">{{ (isUnchangedInitialPasswordAllowed ? 'change_password_on_first_login' : 'change_initial_password') | shpLocalize }}</div>
  <div *ngIf="!hasUnchagedPassword && isOldPassword" class="m-2 mt-0 warning">{{ 'change_password_is_old' | shpLocalize }}</div>
  <div class="error" *ngIf="exceptionInfo != null">{{ exceptionInfo | shpExceptionInfo }}</div>
  <div class="error" *ngFor="let passwordValidationExceptionInfo of passwordValidationExceptionInfos">{{ passwordValidationExceptionInfo | shpExceptionInfo }}</div>

  <form (submit)="savePassword()" #passwordForm="ngForm" autocomplete="off" novalidate>
    <div class="ctl required">
      <label for="old-password">{{ 'user_current_password' | shpLocalize }}</label>
      <input type="password" [(ngModel)]="oldPassword" maxlength="50" id="old-password"
             #password="ngModel" name="oldPassword" />
    </div>
    <div class="ctl">
      <label for="user-password">{{ 'user_new_password' | shpLocalize }}</label>
      <input type="password" [(ngModel)]="newPassword" maxlength="50" id="user-password"
             #password="ngModel" name="userPassword" shpValidateEqual="userPasswordConfirmm" reverse="true" />
    </div>
    <div class="ctl">
      <label for="user-password-confirm">{{ 'user_new_password_confirm' | shpLocalize }}</label>
      <input type="password" [(ngModel)]="newPasswordConfirmation" maxlength="50" id="user-password-confirm"
             name="userPasswordConfirmm" #confirmPassword="ngModel" shpValidateEqual="userPassword" />
    </div>
    <div class="ctl" *ngIf="confirmPassword.errors?.validateEqual">
      <em class="error">{{ 'password_mismatch' | shpLocalize }}</em>
    </div>
    <div class="ctl buttons">
      <label>&nbsp;</label>
      <button id="button-change-password" [disabled]="!passwordForm.form.valid" type="submit"><span class="ic-lock-1"></span> {{ 'change_password' | shpLocalize }}</button>
    </div>
  </form>
</div>
