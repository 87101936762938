import { Component, OnDestroy, OnInit } from "@angular/core";

import { DialogRef, overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { Subject, Subscription } from "rxjs";
import { filter, skip, switchMapTo, takeUntil } from "rxjs/operators";

import * as Globals from "../globals";
import * as Shared from "../index";
import { AuthenticationService, GlobalEventsStreamService, UserLoginEvent } from "../index";
import { newUpdateInfo, UpdateInfo } from "../models/update-info.model";
import { UpdateService } from "../services/update.service";
import { AppUpdateInfoDialog } from "./app-update-info-dialog.component";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-app-info",
  templateUrl: "./app-info.component.html"
})
export class AppInfoComponent implements OnInit, OnDestroy {
  appFullVersion = Globals.APP_VERSION;
  /** Do verzie tu chceme zobrazovať, len major a minor verziu. */
  appVersion = Globals.APP_VERSION.split(".").slice(0, 2).join(".");
  autoInstallFailedTimerId = null;
  hasAutoInstallFailed = false;
  isAutoInstallInProgress = false;
  isConnected = true;
  isNewVersionAvailable = false;
  isSophia = false;

  private _hasNotifiedAboutNewVersion = false;
  private _updateInfo: UpdateInfo = null;
  private _updateInfoDialogRef: DialogRef<UpdateInfo>;
  private _updateInfoSubscription: Subscription;
  private _destroy$ = new Subject<void>();


  constructor(
    private _localizationService: Shared.LocalizationService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _hbService: Shared.ApiHeartbeatService,
    private _modal: Modal,
    private _updateService: UpdateService,
    private _authenticationService: AuthenticationService,
    private _globalEventsStreamService: GlobalEventsStreamService
  ) { }


  get updateInfo(): UpdateInfo {
    return this._updateInfo;
  }


  get isUpdateAvailable() {
    return this._updateInfo && this._updateInfo.isUpdateAvailable;
  }


  get disconnectedMessage(): string {
    if (this.hasAutoInstallFailed) {
      return this._localizationService.getLocalizedString("auto_install_failed");
    }

    if (this.isAutoInstallInProgress) {
      return this._localizationService.getLocalizedString("auto_install_in_progress");
    }

    return this._localizationService.getLocalizedString("backend_unreachable");
  }


  ngOnInit(): void {
    this.isSophia = this._shipperSettingsService.isSophia;

    this._hbService.offline$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(value => this.isConnected = !value);

    this.observeNewVersion();
    this.observeUpdateInfo();
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  showUpdateInfoDialog(isDeprecatedView: boolean = false) {
    if (!this.isUpdateAvailable || this._updateInfoDialogRef) {
      return;
    }

    this._updateInfoDialogRef = this._modal.open(
      AppUpdateInfoDialog,
      overlayConfigFactory({updateInfo: this._updateInfo, isDeprecatedView})
    );

    this._updateInfoDialogRef
      .result
      .then(result => {
        if (result) {
          this.handleAutoInstallationHasStarted();
        }
      })
      .finally(() => {
        this._updateInfoDialogRef = null;
        this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.NewUpdateAvailable);
      });
  }


  private observeNewVersion() {
    this._globalEventsStreamService.userLoginEvents$.pipe(
      takeUntil(this._destroy$),
      filter(e => e.current === UserLoginEvent.NewVersionAvailable),
      switchMapTo(
        this._hbService.version$.pipe(
          // Kill on logout.
          takeUntil(this._authenticationService.user.pipe(skip(1)))
        )
      )
    ).subscribe(version => {
      if (!this._hasNotifiedAboutNewVersion && this.appFullVersion !== version) {
        this.notifyAboutNewVersion();
      } else {
        this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.NewVersionAvailable);
      }
    });
  }


  private observeUpdateInfo() {
    this._updateInfoSubscription = this._globalEventsStreamService.userLoginEvents$.pipe(
      takeUntil(this._destroy$),
      filter(e => e.current === UserLoginEvent.NewUpdateAvailable),
      switchMapTo(
        this._updateService.updateInfo.pipe(
          // Kill on logout.
          takeUntil(this._authenticationService.user.pipe(skip(1)))
        )
      ),
      // Ignore "default" values.
      filter(info => info.currentVersion !== newUpdateInfo().currentVersion)
    ).subscribe(updateInfo => {
      this._updateInfo = updateInfo;

      this.isUpdateAvailable ?
        this.showUpdateInfoDialog() :
        this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.NewUpdateAvailable);
    });
  }


  private notifyAboutNewVersion() {
    if (this.autoInstallFailedTimerId != null) {
      clearTimeout(this.autoInstallFailedTimerId);
      this.autoInstallFailedTimerId = null;
    }

    this._hasNotifiedAboutNewVersion = true;
    this.isNewVersionAvailable = true;

    this._modal.confirm()
      .body(this._localizationService.getLocalizedString("new_version_message"))
      .open()
      .result
      .then(result => {
        if (result) {
          // Vynútime načítanie zo servera.
          location.reload();
        }
      })
      .finally(() => this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.NewVersionAvailable));
  }


  private handleAutoInstallationHasStarted() {
    this.isConnected = false;
    this.isAutoInstallInProgress = true;

    this._hbService.installationHasStarted();

    if (this._updateInfoSubscription != null) {
      this._updateInfoSubscription.unsubscribe();
      this._updateInfoSubscription = null;
    }

    this.autoInstallFailedTimerId = setTimeout(
      () => this.hasAutoInstallFailed = true,
      5 * 60 * 1000 // Wait 5 minutes
    );
  }
}
