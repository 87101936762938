<form #f (submit)="save()" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category">
    <div class="settings-wrapper">
      <h2>{{ 'settings_view' | shpLocalize }}</h2>
      <div class="ctl" *ngIf="settings.compact_mode.isVisible">
        <label for="view-compact-mode">{{ 'settings_view_compact_mode' | shpLocalize }}</label>
        <input type="checkbox"
              name="viewCompactMode" id="view-compact-mode" [(ngModel)]="settings.compact_mode.value" />
        <label for="view-compact-mode"></label>
      </div>
    </div><!-- .settings-wrapper -->
    <div class="commands-toolbar">
      <button id="button-save" type="submit"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
    </div>
  </div>
</form>