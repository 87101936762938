<form novalidate (submit)="save()">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
    <div class="settings-category pickup-addresses">
    <h2>
      {{ 'pickup_addresses' | shpLocalize }}
    </h2>
    <span *ngIf="canAddPickupAddress" class="commands">
      <button id="button-add-pickup-address" type="button" (click)="addPickupAddress()" ><span class="ic-add-2"></span> {{ 'add' | shpLocalize }}</button>
    </span>
    <div class="pickup-address header">
      <div class="view">
        <div class="conditions">
          <div class="commands"></div>
          <div class="address-custom-id ctl">{{ 'address_id' | shpLocalize }}</div>
          <div class="address-name ctl">{{ 'address' | shpLocalize }}</div>
          <!--<div class="address-masking-address ctl">{{ 'masking_address' | shpLocalize }}</div>-->
          <div class="address-contact ctl">{{ 'configuration_contacts' | shpLocalize }}</div>
          <div class="address-state ctl">{{ 'state' | shpLocalize }}</div>
        </div>
      </div>
    </div>
    <div *ngFor="let pickupAddress of pickupAddresses; let i = index; let odd = odd;">
        <shp-pickup-address-editor  [pickupAddress]="pickupAddress"
                                    [countries]="countries"
                                    [customerDetail]="customerDetail | async"
                                    [currentAddress]="currentAddress | async"
                                    [isEditEnabled]="!isEditing"
                                    [isOdd]="odd"
                                    (onEditStart)="onEditStart($event)"
                                    (onEditEnd)="onEditEnd($event)"
                                    (onDelete)="delete($event)"
                                    (onUndelete)="undelete($event)"
                                    (onSendToApprove)="sendToApprove($event)"></shp-pickup-address-editor>
      </div>
    </div>
  </form>