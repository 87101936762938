import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash';

import * as Shared from "../../shared/index";

const APP_DND_LIST_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DragAndDropListComponent),
  multi: true
};

@Component({
  selector: Shared.SELECTOR_PREFIX + '-drag-and-drop-list',
  templateUrl: './drag-and-drop-list.component.html',
  providers: [APP_DND_LIST_VALUE_ACCESSOR],
  exportAs: 'dndList',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragAndDropListComponent implements ControlValueAccessor, OnChanges {

  @Input() sourceList: Array<any> = [];
  @Input() targetList: Array<any> = [];
  @Input() valueKey: string;
  @Input() viewKey: string;
  @Output() targetListChange = new EventEmitter<any>();

  _sourceList: Array<any> = [];
  _targetList: Array<any> = [];
  dropZoneId = _.uniqueId('drop-');

  private selfEmit = false;

  constructor(private _cdr: ChangeDetectorRef) { }

  onChange: (value: any) => void = () => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this.targetList = value;
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('sourceList' in changes) {
      this._sourceList = _.cloneDeep(this.sourceList);
    }

    if ('targetList' in changes) {
      if (!this.selfEmit) {
        this.value = this.targetList;
        this.onChange(this.value);
      }

      this.selfEmit = false;
    }
  }

  get value(): any[] {
    return this.valueKey ?
      this._targetList.map(val => val[this.valueKey]) :
      [...this._targetList];
  }

  set value(list: any[]) {
    this._sourceList = _.cloneDeep(this.sourceList);

    if (!list || !list.length || !this._sourceList.length) {
      this._targetList = [];
    } else {
      const isPrimitive = typeof list[0][this.viewKey] === 'undefined';

      this._targetList = list.reduce((acc, targetElement) => {
        const el = isPrimitive ?
                   this._sourceList.find(sourceElement => targetElement === sourceElement[this.valueKey]) :
                   this._sourceList.find(sourceElement => targetElement[this.viewKey] === sourceElement[this.viewKey]);
        if (el) {
          return [...acc, el];
        } else {
          return acc;
        }
      }, []);

    }
    this._sourceList = _.differenceBy(this._sourceList, this._targetList, this.viewKey);
    this._cdr.markForCheck();
  }

  removeItem(item): void {
    _.pull(this._targetList, item);
    this._sourceList.push(item);

    if (!this._targetList.length) {
      this._targetList = [];
    }

    this.orderChange();
  }

  orderChange(): void {
    this.onChange(this.value);
    this.emitNewValue();
  }

  getItemDisplayValue(item): string {
    return this.viewKey ? item[this.viewKey] : item;
  }

  private emitNewValue(): void {
    this.selfEmit = true;
    this.targetListChange.emit(this.value);
  }
}
