import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { AuthenticationService, GlobalEventsStreamService, LocalizationService, LoggingService, NavigationEvents, SELECTOR_PREFIX } from "./shared/index";
import { WizardService } from "./shared/modules/wizard/services/wizard.service";
import { ShipperWizardService } from "./shared/services/shipper-wizard.service";
import { UpdateService } from "./shared/services/update.service";


const IS_MENU_COLLAPSED_STORAGE_KEY = "is-menu-collapsed";


@Component({
  selector: SELECTOR_PREFIX + "-app-container",
  templateUrl: "./app-container.component.html",
  providers: [],
  viewProviders: []
})
export class ShipperAppContainerComponent {
  public hasInitializationFailed = false;
  public isInitialized = false;
  /**
   * Ak sa aplikácia premiestňuje v rámci navigácia, tak je to true, ináč false.
   * Zobrazuje sa podľa toho progress bar.
   */
  public isNavigating = true;

  constructor(
    private _router: Router,
    private _loggingService: LoggingService,
    private _globalEventsStreamService: GlobalEventsStreamService,
    private _localizationService: LocalizationService,
    private _authenticationService: AuthenticationService,
    private _updateService: UpdateService,
    private _wizardService: WizardService,
    private _shipperWizardService: ShipperWizardService
  ) {
    this.initialize();
  }

  public get isWizardActive(): boolean {
    return this._wizardService.isActive;
  }

  public get isWizardCloseBtnDisplayed(): boolean {
    return this.isWizardActive && this._shipperWizardService.hasUserCompletedMandatorySteps;
  }

  public get isMenuCollapsed() {
    const isCollapsedString = localStorage.getItem(IS_MENU_COLLAPSED_STORAGE_KEY);

    let isCollapsed = false;
    if (isCollapsedString != null) {
      isCollapsed = JSON.parse(isCollapsedString);
    }

    return isCollapsed;
  }

  public set isMenuCollapsed(value: boolean) {
    localStorage.setItem(IS_MENU_COLLAPSED_STORAGE_KEY, JSON.stringify(value));
  }

  public toggleMenuSize = () => {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  public closeWizard() {
    this._wizardService.close();
  }

  private initialize = () => {
    this._localizationService.setComponentsLocalization();

    this._globalEventsStreamService.navigationEventStream.subscribe(value => {
      switch (value) {
        case NavigationEvents.START_NAVIGATION:
          this.isNavigating = true;
          break;
        case NavigationEvents.END_NAVIGATION:
          this.isNavigating = false;
          break;
      }
    });

    this._loggingService.logDebug("Application initialized.");
    this.isInitialized = true;
  }
}
