import { BreakpointObserver } from "@angular/cdk/layout";
import { ConnectedPosition } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";

import * as _ from "lodash";
import { Subject } from "rxjs";
import { take } from 'rxjs/operators';

import { AuthenticationService, LocalizationService, ShipperSettingsService } from "..";
import { environment } from "../../../environments/environment";
import { UsersService } from "../../user/services/users.service";
import { ShipperWizardPopupComponent } from "../components/wizard-components/shipper-wizard-popup.component";
import { WizardChaptersPreviewComponent } from "../components/wizard-components/wizard-chapters-preview.component";
import { ShipperWizardNavigationComponent } from "../components/wizard-components/wizard-navigation.component";
import { enumKeys } from "../helper-functions";
import { WizardArrow, WizardArrowPosition, WizardBoundries, WizardChapter, WizardDataProvider, WizardForceNavigation, WizardLabel, WizardPopup, WizardStep } from "../modules/wizard/models";

/**
 * Enum for wizard steps.
 */
export enum WizardStepName {
  Welcome = "Welcome",
  // Settings
  SettingsIntro = "SettingsIntro",
  ClickSettings = "ClickSettings",
  ClickBasicSettings = "ClickBasicSettings",
  SettingsBasicIntro = "SettingsBasicIntro",
  SettingsBasic = "SettingsBasic",
  ClickPrintSettings = "ClickPrintSettings",
  SettingsPrintIntro = "SettingsPrintIntro",
  SettingsPrint = "SettingsPrint",
  SettingsPrintTestLabel = "SettingsPrintTestLabel",
  ClickUserManagement = "ClickUserManagement",
  UserManagementIntro = "UserManagementIntro",
  ClickAddUser = "ClickAddUser",
  NewUser = "NewUser",
  EditUser = "EditUser",
  // Address book
  RecipientsIntro = "RecipientsIntro",
  ClickRecipients = "ClickRecipients",
  ClickAddRecipient = "ClickAddRecipient",
  NewRecipient = "NewRecipient",
  NewRecipientInShipmentEditor = "NewRecipientInShipmentEditor",
  ImportRecipientsIntro = "ImportRecipientsIntro",
  ClickRecipientsImport = "ClickRecipientsImport",
  ClickImportRecipients = "ClickImportRecipients",
  ImportRecipients = "ImportRecipients",
  // ImportRecipients2 = "ImportRecipients2",
  // Shipments
  ShipmentsIntro = "ShipmentsIntro",
  ClickShipments = "ClickShipments",
  ClickNewShipment = "ClickNewShipment",
  NewShipmentIntro = "NewShipmentIntro",
  NewShipmentRecipient = "NewShipmentRecipient",
  NewShipmentBaseData1 = "NewShipmentBaseData1",
  NewShipmentBaseData2 = "NewShipmentBaseData2",
  NewShipmentParcels = "NewShipmentParcels",
  NewShipmentCustoms = "NewShipmentCustoms",
  NewShipmentSave = "NewShipmentSave",
  NewShipmnetOverview = "NewShipmnetOverview",
  ImportShipmentsIntro = "ImportShipmentsIntro",
  // ClickShipmentsImport = "ClickShipmentsImport",
  ClickImportShipments = "ClickImportShipments",
  ImportShipments = "ImportShipments",
  ImportShipments2 = "ImportShipments2",
  // Pickup
  PickupIntro = "PickupIntro",
  ClickPickups = "ClickPickups",
  ClickNewPickup = "ClickNewPickup",
  NewPickup = "NewPickup",
  // Import profiles
  ImportProfilesIntro = "ImportProfilesIntro",
  ClickImportProfiles = "ClickImportProfiles",
  ClickImportProfilesShipments = "ClickImportProfilesShipments",
  ClickAddImportProfile = "ClickAddImportProfile",
  ImportProfileIntro = "ImportProfileIntro",
  ImportProfileBasicData1 = "ImportProfileBasicData1",
  ImportProfileBasicData2 = "ImportProfileBasicData2",
  ImportProfileClickMapping = "ImportProfileClickMapping",
  ImportProfileMappingFile = "ImportProfileMappingFile",
  ImportProfileMappingRecipient = "ImportProfileMappingRecipient",
  ImportProfileMappingParcels = "ImportProfileMappingParcels",
  ClickSaveImportProfile = "ClickSaveImportProfile",

  // Doto - remove once the final version of the wizard is approved (also from localise).
  // EditImportProfile = "EditImportProfile",
  // ClickShipmentsImportProfiles = "ClickShipmentsImportProfiles",
  // ClickImportShipmentsImportProfiles = "ClickImportShipmentsImportProfiles",
  // ImportProfilesImportShipments = "ImportProfilesImportShipments",
  // ImportProfilesImportComplete = "ImportProfilesImportComplete",
  // ImportArchive = "ImportArchive",
  // ImportProfilesEnd = "ImportProfilesEnd",

  // End
  End = "End"
}

const adminSteps = new Set<WizardStepName>([
  WizardStepName.ClickUserManagement,
  WizardStepName.UserManagementIntro,
  WizardStepName.ClickAddUser,
  WizardStepName.NewUser,
  WizardStepName.EditUser
]);

const defaultOmittedSteps = new Set<WizardStepName>([
  ...adminSteps,
  // WizardStepName.EditImportProfile
]);

const localShipperOmittedSteps = new Set<WizardStepName>([
  // WizardStepName.ImportProfilesImportComplete,
  // WizardStepName.ImportArchive,
]);

/**
 * Main chapters of wizard steps.
 */
export enum WizardChapterName {
  Welcome = "Welcome",
  Settings = "Settings",
  AddressBook = "AddressBook",
  Shipments = "Shipments",
  Pickups = "Pickups",
  ImportProfiles = "ImportProfiles",
  End = "End"
}

export const wizardChapterIconClasses = new Map<string, string>([
  [WizardChapterName.Welcome, "icon ic-box-1"],
  [WizardChapterName.Settings, "icon ic-settings-1"],
  [WizardChapterName.AddressBook, "icon ic-real-estate-address-book-1"],
  [WizardChapterName.Shipments, "icon ic-box-1"],
  [WizardChapterName.Pickups, "icon ic-truck"],
  [WizardChapterName.ImportProfiles, "icon ic-file-download-2"],
  [WizardChapterName.End, "icon ic-box-1"]
]);


type ShipperWizardNavigationInputs = Pick<ShipperWizardNavigationComponent,
  "isPreviousDisplayed" |
  "isNextDisplayed" |
  "isChapterNavigationDisplayed" |
  "shouldExitOnChapterNavigation" |
  "finishLabel" |
  "nextLabel" |
  "previousLabel">;

type ShipperWizardPopupInputs = Pick<ShipperWizardPopupComponent,
  "headline" |
  "iconClass" |
  "text" |
  "completeChapterLabel" |
  "hasCompleteChapterButton" |
  "goToStepButtons" |
  "specialElements"> & {
    navigation: Partial<ShipperWizardNavigationInputs>
  };

export type ShipperWizardPopup = WizardPopup<Partial<ShipperWizardPopupInputs>>;

const popupTextIcons = new Map<string, string>([
  ["iconListView", "ic-content-view-headline"],
  ["iconGridView", "ic-grid"],
  ["iconSelectPickupAddress", "ic-move-left-right-1"],
  ["iconImportArchiveLog", "ic-data-upload-2"]
]);

const defautlUserEditorForcedNavigation: WizardForceNavigation = {
  url: "/settings/user-management/users/0",
  test: /^\/settings\/user-management\/users\//
};

const defaultImportProfileEditorForcedNavigation: WizardForceNavigation = {
  url: "/import-profiles/shipments/new",
  test: /^\/import-profiles\/shipments\/(new|edit\/\d+)/,
};

const avaiablePositions: {[name: string]: ConnectedPosition} = {
  centerTop: {
    originX: "center",
    originY: "top",
    overlayX: "center",
    overlayY: "bottom",
    offsetY: -25,
  },
  centerBottom: {
    originX: "center",
    originY: "bottom",
    overlayX: "center",
    overlayY: "top",
    offsetY: 25
  },
  leftTop: {
    originX: "start",
    originY: "top",
    overlayX: "end",
    overlayY: "top",
    offsetX: -25,
    offsetY: 25
  },
  leftCenter: {
    originX: "start",
    originY: "center",
    overlayX: "end",
    overlayY: "center",
    offsetX: -25,
  },
  rightTop: {
    originX: "end",
    originY: "top",
    overlayX: "start",
    overlayY: "top",
    offsetX: 25,
    offsetY: 25
  },
  rightCenter: {
    originX: "end",
    originY: "center",
    overlayX: "start",
    overlayY: "center",
    offsetX: 25,
  }
};

@Injectable({
  providedIn: "root"
})
export class ShipperWizardDataProvider extends WizardDataProvider<WizardStepName, WizardChapterName> {
  initialStep = WizardStepName.Welcome;

  alteredNextSequence = new Map<WizardStepName, WizardStepName>();
  alteredPreviousSequence = new Map<WizardStepName, WizardStepName>();
  omittedChapters = new Set<WizardChapterName>();
  omittedSteps = new Set<WizardStepName>([...defaultOmittedSteps]);

  stepModified$ = new Subject<void>();

  private nextSequence = new Map<WizardStepName, WizardStepName>();
  private previousSequence = new Map<WizardStepName, WizardStepName>();
  private chapters = new Map<WizardChapterName, WizardChapter<WizardChapterName, WizardStepName>>();
  private steps = new Map<WizardStepName, WizardStep<WizardStepName>>();

  private userEditorForcedNavigation: WizardForceNavigation = {...defautlUserEditorForcedNavigation};
  private importProfileEditorForcedNavigation: WizardForceNavigation = {...defaultImportProfileEditorForcedNavigation};

  private breakPoints = [
    "(max-width: 1024px)",
    "(min-width: 1025px) and (max-width: 1599px)",
    "(min-width: 1600px)"
  ];

  private responsivePositions: Map<WizardStepName, Map<string, any>> = new Map();

  constructor(
      private breakpointObserver: BreakpointObserver,
      private localizationService: LocalizationService,
      private shipperSettingsService: ShipperSettingsService,
      private authenticationService: AuthenticationService,
      private userService: UsersService) {
    super();
    this.initWelcome();
    this.initSettings();
    this.initAddressBook();
    this.initShipments();
    this.initPickups();
    this.initImportProfiles();
    this.initEnd();

    this.initStandardSequence();
    this.setInitialOmittedSteps();

    this.observeAuth();
    this.observeBreakPoints();
  }

  /* #region WizardDataProvider implementation */
  getStep(name: WizardStepName): WizardStep<WizardStepName> | null {
    return this.steps.get(name);
  }

  getNextStep(name: WizardStepName): WizardStep<WizardStepName> | null {
    let nextStepName = this.nextSequence.get(name);

    if (this.alteredNextSequence.has(name)) {
      nextStepName = this.alteredNextSequence.get(name);
    }

    if (!nextStepName) {
      return null;
    } else if (!this.isStepOmitted(nextStepName)) {
      return this.getStep(nextStepName);
    } else {
      return this.getNextStep(nextStepName);
    }
  }

  getPreviousStep(name: WizardStepName): WizardStep<WizardStepName> | null {
    let previousStepName = this.previousSequence.get(name);

    if (this.alteredPreviousSequence.has(name)) {
      previousStepName = this.alteredPreviousSequence.get(name);
    }

    if (!previousStepName) {
      return null;
    } else if (!this.isStepOmitted(previousStepName)) {
      return this.getStep(previousStepName);
    } else {
      return this.getPreviousStep(previousStepName);
    }
  }

  getChapters(): WizardChapter<WizardChapterName, WizardStepName>[] {
    return [...this.chapters.values()];
  }

  getChapter(name: WizardChapterName): WizardChapter<WizardChapterName, WizardStepName> | null {
    return this.chapters.get(name);
  }

  getNextChapter(name: WizardChapterName): WizardChapter<WizardChapterName, WizardStepName> | null {
    const chapters = this.getChapters();
    const idx = chapters.findIndex(set => set.name === name);
    const nextChapterName = chapters[idx + 1]?.name ?? null;

    if (!nextChapterName) {
      return null;
    } else if (!this.isChapterOmitted(nextChapterName)) {
      return this.getChapter(nextChapterName);
    } else {
      return this.getNextChapter(nextChapterName);
    }
  }

  getPreviousChapter(name: WizardChapterName): WizardChapter<WizardChapterName, WizardStepName> | null {
    const chapters = this.getChapters();
    const idx = chapters.findIndex(set => set.name === name);
    const previousChapterName = chapters[idx - 1]?.name ?? null;

    if (!previousChapterName) {
      return null;
    } else if (!this.isChapterOmitted(previousChapterName)) {
      return this.getChapter(previousChapterName);
    } else {
      return this.getPreviousChapter(previousChapterName);
    }
  }
  /* #endregion */

  updateUserEditorForcedNavigation(navigation: Partial<WizardForceNavigation>) {
    _.merge(this.userEditorForcedNavigation, navigation);
  }

  restoreUserEditorForcedNavigation() {
    _.merge(this.userEditorForcedNavigation, defautlUserEditorForcedNavigation);
  }

  updateImportProfileEditorForcedNavigation(navigation: Partial<WizardForceNavigation>) {
    _.merge(this.importProfileEditorForcedNavigation, navigation);
  }

  restoreImportProfileEditorForcedNavigation() {
    _.merge(this.importProfileEditorForcedNavigation, defaultImportProfileEditorForcedNavigation);
  }

  private isStepOmitted(name: WizardStepName): boolean {
    if (this.omittedSteps.has(name)) {
      return true;
    }

    const chapterName = this.getChapterNameForStep(name);
    if (this.isChapterOmitted(chapterName)) {
      return true;
    }

    return false;
  }

  private isChapterOmitted(name: WizardChapterName): boolean {
    return this.omittedChapters.has(name);
  }

  /* #region Data helpers */
  private createPopupBase(name: WizardStepName): Partial<ShipperWizardPopup> {
    return {
      popupComponentData: {
        iconClass: wizardChapterIconClasses.get(this.getChapterNameForStep(name)),
        headline: `wizard_popup_headline_${name}`,
        text: `wizard_popup_text_${name}`,
        navigation: {
          previousLabel: "action_wizard_previous",
          nextLabel: "action_wizard_next",
          finishLabel: "action_wizard_finish"
        }
      },
      popupComponent: ShipperWizardPopupComponent
    };
  }

  private createOverviewPopup(name: WizardStepName, data: Partial<ShipperWizardPopup> = {}): WizardPopup {
    const popupClass = [
      "wizard__popup--overview",
      ...(Array.isArray(data.popupClass) ? data.popupClass : [data.popupClass])
    ].filter(c => Boolean(c));

    return {
      ..._.merge(this.createPopupBase(name), data),
      popupClass
    };
  }

  private createDescriptionPopup(name: WizardStepName, data: Partial<ShipperWizardPopup> = {}): WizardPopup {
    const popupClass = [
      "wizard__popup--description",
      ...(Array.isArray(data.popupClass) ? data.popupClass : [data.popupClass])
    ].filter(c => Boolean(c));

    return {
      position: {
        top: "10%",
        right: "10%",
      },
      ..._.merge(this.createPopupBase(name), data),
      popupClass
    };
  }

  private createConnectedDescriptionPopup(
      name: WizardStepName,
      data: Partial<ShipperWizardPopup> & {
        connectedToElementTag: string;
        responsivePositions?: [string, ConnectedPosition][]}): WizardPopup {
    const popupClass = [
      "wizard__popup--description",
      ...(Array.isArray(data.popupClass) ? data.popupClass : [data.popupClass])
    ].filter(c => Boolean(c));

    if (data.responsivePositions) {
      this.responsivePositions.set(name, new Map(data.responsivePositions));
    }

    return {
      ..._.merge(this.createPopupBase(name), data),
      popupClass
    };
  }

  private registerStep(step: WizardStep<WizardStepName>) {
    this.localizeArrows(step.arrows);
    this.localizeLabels(step.labels);

    if (step.popup?.popupComponentData) {
      this.localizePopup(step.popup.popupComponentData);
      this.addIconElementsForPopupText(step.popup.popupComponentData);
    }

    if (!environment.production && this.steps.has(step.name)) {
      console.warn(`Wizard: Multiple registrations of the step ${step.name}.`);
    }

    this.steps.set(step.name, step);
  }

  private localizePopup(inputs: Partial<ShipperWizardPopupInputs>) {
    inputs.headline = this.localizationService.getLocalizedString(inputs.headline);
    inputs.text = this.localizationService.getLocalizedStringWithLinks(inputs.text).replace(/\\n/g, "\n");
    inputs.navigation.previousLabel = this.localizationService.getLocalizedString(inputs.navigation.previousLabel);
    inputs.navigation.nextLabel = this.localizationService.getLocalizedString(inputs.navigation.nextLabel);
    inputs.navigation.finishLabel = this.localizationService.getLocalizedString(inputs.navigation.finishLabel);

    inputs.completeChapterLabel = this.localizationService.getLocalizedString(inputs.completeChapterLabel);
    inputs.goToStepButtons?.forEach(i => i.label = this.localizationService.getLocalizedString(i.label));
  }

  private addIconElementsForPopupText(inputs: Partial<ShipperWizardPopupInputs>) {
    for (const [tag, iconClass] of popupTextIcons) {
      inputs.text = inputs.text
        .replace(new RegExp(`\{${tag}\}`, "g"), `<span class="${iconClass}"></span>`);
    }
  }

  private localizeArrows(arrows: WizardArrow[]) {
    arrows?.forEach(arrow => {
      if (arrow.text) {
        arrow.text = this.localizationService.getLocalizedString(arrow.text);
      }
    });
  }

  private localizeLabels(labels: WizardLabel[]) {
    labels?.forEach(label => {
      if (label.text) {
        label.text = this.localizationService.getLocalizedString(label.text);
      }
    });
  }

  private registerOverviewStep(data: {
      name: WizardStepName,
      forceNavigation?: string | WizardForceNavigation,
      inputs?: Partial<ShipperWizardPopupInputs>}) {
    this.registerStep({
      name: data.name,
      forceNavigation: data.forceNavigation,
      popup: this.createOverviewPopup(data.name, {popupComponentData: data.inputs})
    });
  }

  private registerClickStep(data: {
      name: WizardStepName,
      forceNavigation?: string | WizardForceNavigation,
      tag: string,
      arrow?: Pick<WizardArrow, "position" | "text">}) {
    this.registerStep({
      name: data.name,
      forceNavigation: data.forceNavigation,
      enabledSectionTags: [data.tag],
      overlay: {
        tag: data.tag
      },
      arrows: [{
        position: data.arrow?.position,
        tag: data.tag,
        text: data.arrow?.text ?? `wizard_arrow_${data.name}`
      }]
    });
  }

  private registerChapter(
      name: WizardChapterName,
      steps: Set<WizardStepName>,
      isDisplayedInControlPanel: boolean = true) {
    this.chapters.set(name, {
      name,
      steps,
      iconClass: wizardChapterIconClasses.get(name),
      label: this.localizationService.getLocalizedString(`wizard_set_name_${name}`),
      firstStepName: steps.values().next().value,
      lastStepName: [...steps].pop(),
      isDisplayedInControlPanel
    });
  }
  /* #endregion */

  /* #region Init */
  private initWelcome() {
    this.registerChapter(WizardChapterName.Welcome, new Set([
      WizardStepName.Welcome
    ]));

    this.registerOverviewStep({
      name: WizardStepName.Welcome,
      inputs: {
        specialElements: [WizardChaptersPreviewComponent],
        navigation: {
          isPreviousDisplayed: false,
          isChapterNavigationDisplayed: false
        }
      }
    });
  }

  private initSettings() {
    this.registerChapter(WizardChapterName.Settings, new Set([
      WizardStepName.SettingsIntro,
      WizardStepName.ClickSettings,
      WizardStepName.ClickBasicSettings,
      WizardStepName.SettingsBasicIntro,
      WizardStepName.SettingsBasic,
      WizardStepName.ClickPrintSettings,
      WizardStepName.SettingsPrintIntro,
      WizardStepName.SettingsPrint,
      WizardStepName.SettingsPrintTestLabel,
      WizardStepName.ClickUserManagement,
      WizardStepName.UserManagementIntro,
      WizardStepName.ClickAddUser,
      WizardStepName.NewUser,
      WizardStepName.EditUser
    ]));

    const settingsPrintPopup = this.createDescriptionPopup(WizardStepName.SettingsPrint, {
      position: {
        bottom: "250px",
        right: "10%",
      },
      popupComponentData: {
        headline: `wizard_popup_headline_${WizardStepName.SettingsPrintIntro}`,
        navigation: {
          isNextDisplayed: false,
          isPreviousDisplayed: false
        }
      }
    });

    this.registerOverviewStep({
      name: WizardStepName.SettingsIntro,
    });

    this.registerClickStep({
      name: WizardStepName.ClickSettings,
      tag: "mainNavSettings",
      arrow: { text: "wizard_arrow_nav_settings" }
    });

    this.registerClickStep({
      name: WizardStepName.ClickBasicSettings,
      forceNavigation: {
        url: "/settings",
        test: /^\/settings\//
      },
      tag: "settingsNavBasic",
      arrow: { text: "wizard_arrow_nav_settings_basic" }
    });

    this.registerOverviewStep({
      name: WizardStepName.SettingsBasicIntro,
    });

    this.registerStep({
      name: WizardStepName.SettingsBasic,
      forceNavigation: "/settings/basic",
      enabledSectionTags: ["basicSettings"],
      overlay: {
        tag: "basicSettings"
      },
      popup: this.createDescriptionPopup(WizardStepName.SettingsBasic, {
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.SettingsBasicIntro}`,
          navigation: {
            isNextDisplayed: false,
            isPreviousDisplayed: false
          }
        }
      }),
      arrows: [
        { position: WizardArrowPosition.Right, tag: "saveBasicSettings", text: "wizard_arrow_save_settings" }
      ]
    });

    this.registerClickStep({
      name: WizardStepName.ClickPrintSettings,
      forceNavigation: {
        url: "/settings",
        test: /^\/settings\//
      },
      tag: "settingsNavPrint",
      arrow: { text: "wizard_arrow_nav_settings_print" }
    });

    this.registerOverviewStep({
      name: WizardStepName.SettingsPrintIntro,
    });

    this.registerStep({
      name: WizardStepName.SettingsPrint,
      forceNavigation: "/settings/print",
      enabledSectionTags: ["printSettings"],
      overlay: {
        tag: "printSettings"
      },
      popup: settingsPrintPopup,
      arrows: [
        { position: WizardArrowPosition.Left, tag: "savePrintSettings", text: "wizard_arrow_save_settings" }
      ]
    });

    this.registerStep({
      name: WizardStepName.SettingsPrintTestLabel,
      forceNavigation: "/settings/print",
      enabledSectionTags: ["printSettings"],
      overlay: {
        tag: "printSettings"
      },
      popup: settingsPrintPopup,
      arrows: [
        { position: WizardArrowPosition.Right, tag: "printTestLabel", text: "wizard_arrow_print_test_label" }
      ]
    });

    this.registerClickStep({
      name: WizardStepName.ClickUserManagement,
      forceNavigation: {
        url: "/settings",
        test: /^\/settings\//
      },
      tag: "settingsNavUserManagement",
      arrow: { text: "wizard_arrow_nav_settings_user_management" }
    });

    this.registerOverviewStep({
      name: WizardStepName.UserManagementIntro,
      forceNavigation: "/settings/user-management",
    });

    this.registerClickStep({
      name: WizardStepName.ClickAddUser,
      forceNavigation: "/settings/user-management",
      tag: "addNewUser",
      arrow: { position: WizardArrowPosition.Top, text: "wizard_arrow_click_add_user" }
    });

    this.registerStep({
      name: WizardStepName.NewUser,
      forceNavigation: this.userEditorForcedNavigation,
      enabledSectionTags: ["userEditor"],
      overlay: {
        tag: "userEditor"
      },
      popup: this.createDescriptionPopup(WizardStepName.NewUser, {
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.UserManagementIntro}`,
          navigation: {
            isNextDisplayed: false,
            isPreviousDisplayed: false
          }
        }
      })
    });

    this.registerStep({
      name: WizardStepName.EditUser,
      forceNavigation: this.userEditorForcedNavigation,
      enabledSectionTags: ["userEditorSettings"],
      overlay: {
        tag: "userEditorSettings"
      },
      popup: this.createDescriptionPopup(WizardStepName.EditUser, {
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.UserManagementIntro}`
        }
      })
    });
  }

  private initAddressBook() {
    const shipmentEditorBoundries: Partial<WizardBoundries> = {
      top: 83, // top bar
      bottom: 158 // step bar + bottom toolbar
    };

    this.registerChapter(WizardChapterName.AddressBook, new Set([
      WizardStepName.RecipientsIntro,
      WizardStepName.ClickRecipients,
      WizardStepName.ClickAddRecipient,
      WizardStepName.NewRecipient,
      WizardStepName.NewRecipientInShipmentEditor,
      WizardStepName.ImportRecipientsIntro,
      WizardStepName.ClickRecipientsImport,
      WizardStepName.ClickImportRecipients,
      WizardStepName.ImportRecipients,
      // WizardStepName.ImportRecipients2
    ]));

    this.registerOverviewStep({
      name: WizardStepName.RecipientsIntro
    });

    this.registerClickStep({
      name: WizardStepName.ClickRecipients,
      tag: "mainNavRecipients",
      arrow: { text: "wizard_arrow_nav_recipients" }
    });

    this.registerClickStep({
      name: WizardStepName.ClickAddRecipient,
      forceNavigation: "/recipients",
      tag: "addNewRecipient",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_add_recipient" }
    });

    this.registerStep({
      name: WizardStepName.NewRecipient,
      forceNavigation: "/recipients/0",
      enabledSectionTags: ["recipientEditor"],
      overlay: {
        tag: "recipientEditor"
      },
      popup: this.createDescriptionPopup(WizardStepName.NewRecipient, {
        popupComponentData: {
          navigation: {
            isNextDisplayed: false,
            isPreviousDisplayed: false,
          }
        }
      }),
      arrows: [
        { position: WizardArrowPosition.Top, tag: "saveRecipient", text: "wizard_arrow_save" },
        // { position: WizardArrowPosition.Left, tag: "backToRecipientList", text: "wizard_arrow_back_to_list" }
      ]
    });

    this.registerStep({
      name: WizardStepName.NewRecipientInShipmentEditor,
      forceNavigation: "/shipments/0",
      overlay: {
        tag: "saveRecipientToAddressBook",
        boundries: shipmentEditorBoundries
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewRecipientInShipmentEditor, {
        connectedToElementTag: "saveRecipientToAddressBook",
        connectedPositions: [{
          originX: "center",
          originY: "top",
          overlayX: "start",
          overlayY: "bottom",
          offsetY: -15
        }],
        connectedPositionBoundries: shipmentEditorBoundries,
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.NewRecipient}`
        }
      }),
      arrows: [
        // {position: WizardArrowPosition.Top, tag: "saveRecipientToAddressBookCheckbox", isAnimated: false}
      ]
    });

    this.registerOverviewStep({
      name: WizardStepName.ImportRecipientsIntro
    });

    this.registerClickStep({
      name: WizardStepName.ClickRecipientsImport,
      tag: "mainNavRecipients",
      arrow: { text: "wizard_arrow_nav_recipients" }
    });

    this.registerClickStep({
      name: WizardStepName.ClickImportRecipients,
      forceNavigation: "/recipients",
      tag: "importRecipients",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_recipients_import" }
    });

    this.registerStep({
      name: WizardStepName.ImportRecipients,
      forceNavigation: "/recipients",
      // overlay: {
      //   tag: "importRecipientDialogParams",
      //   offset: {x: 20, y: 10}
      // },
      overlay: {
        tag: "importRecipientDialog"
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportRecipients, {
        connectedToElementTag: "importRecipientDialog",
        connectedPositions: [avaiablePositions.rightCenter],
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.ImportRecipientsIntro}`,
          goToStepButtons: [{
            stepName: WizardStepName.ImportProfilesIntro,
            customEventType: "skipToImportProfiles",
            label: "action_wizard_go_to_ImportProfilesIntro"
          }]
        }
      })
    });

    // this.registerStep({
    //   name: WizardStepName.ImportRecipients2,
    //   forceNavigation: "/recipients",
    //   overlay: {
    //     tag: "importRecipientDialogButtons",
    //     offset: {x: 20, y: 10}
    //   },
    //   popup: this.createConnectedDescriptionPopup(WizardStepName.ImportRecipients2, {
    //     connectedToElementTag: "importRecipientDialog",
    //     connectedPositions: [{
    //       originX: "end",
    //       originY: "center",
    //       overlayX: "start",
    //       overlayY: "center",
    //       offsetX: 20,
    //     }],
    //     popupComponentData: {
    //       headline: `wizard_popup_headline_${WizardStepName.ImportRecipientsIntro}`
    //     }
    //   })
    // });
  }

  private initShipments() {
    const shipmentEditorBoundries: Partial<WizardBoundries> = {
      top: 83, // top bar
      bottom: 158 // step bar + bottom toolbar
    };

    // cover the borders of panel elements
    const shipmentEditorPanelElementOverlayOffset = {
      width: -1,
      height: -1
    };

    this.registerChapter(WizardChapterName.Shipments, new Set([
      WizardStepName.ShipmentsIntro,
      WizardStepName.ClickShipments,
      WizardStepName.ClickNewShipment,
      WizardStepName.NewShipmentIntro,
      WizardStepName.NewShipmentRecipient,
      WizardStepName.NewShipmentBaseData1,
      WizardStepName.NewShipmentBaseData2,
      WizardStepName.NewShipmentParcels,
      WizardStepName.NewShipmentCustoms,
      WizardStepName.NewShipmentSave,
      WizardStepName.NewShipmnetOverview,
      WizardStepName.ImportShipmentsIntro,
      // WizardStepName.ClickShipmentsImport,
      WizardStepName.ClickImportShipments,
      WizardStepName.ImportShipments,
      WizardStepName.ImportShipments2
    ]));

    this.registerOverviewStep({
      name: WizardStepName.ShipmentsIntro,
    });

    this.registerClickStep({
      name: WizardStepName.ClickShipments,
      tag: "mainNavShipments",
      arrow: { text: "wizard_arrow_nav_shipments" }
    });

    this.registerClickStep({
      name: WizardStepName.ClickNewShipment,
      forceNavigation: "/shipments",
      tag: "newShipment",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_new_shipment" }
    });

    this.registerOverviewStep({
      name: WizardStepName.NewShipmentIntro,
    });

    this.registerStep({
      name: WizardStepName.NewShipmentRecipient,
      forceNavigation: "/shipments/0",
      overlay: {
        tag: "shipmentEditorRecipient",
        boundries: shipmentEditorBoundries,
        offset: shipmentEditorPanelElementOverlayOffset
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewShipmentRecipient, {
        connectedToElementTag: "shipmentEditorRecipient",
        connectedPositionBoundries: shipmentEditorBoundries,
        responsivePositions: [
          [this.breakPoints[0], avaiablePositions.centerBottom],
          [this.breakPoints[1], avaiablePositions.rightTop],
          [this.breakPoints[2], avaiablePositions.rightTop]
        ]
      })
    });

    this.registerStep({
      name: WizardStepName.NewShipmentBaseData1,
      forceNavigation: "/shipments/0",
      overlay: {
        tag: "shipmentEditorBaseData1",
        boundries: shipmentEditorBoundries,
        offset: {x: 24}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewShipmentBaseData1, {
        connectedToElementTag: "shipmentEditorBaseData1",
        connectedPositionBoundries: shipmentEditorBoundries,
        responsivePositions: [
          [this.breakPoints[0], avaiablePositions.centerBottom],
          [this.breakPoints[1], {...avaiablePositions.leftTop, offsetX: -45}],
          [this.breakPoints[2], {...avaiablePositions.rightTop, offsetX: 45}]
        ],
      })
    });

    this.registerStep({
      name: WizardStepName.NewShipmentBaseData2,
      forceNavigation: "/shipments/0",
      overlay: {
        tag: "shipmentEditorBaseData2",
        boundries: shipmentEditorBoundries,
        offset: {x: 24}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewShipmentBaseData2, {
        connectedToElementTag: "shipmentEditorBaseData2",
        connectedPositionBoundries: shipmentEditorBoundries,
        responsivePositions: [
          [this.breakPoints[0], avaiablePositions.centerBottom],
          [this.breakPoints[1], {...avaiablePositions.leftCenter, offsetX: -45}],
          [this.breakPoints[2], {...avaiablePositions.rightCenter, offsetX: 45}]
        ],
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.NewShipmentBaseData1}`,
          text: this.shipperSettingsService.isCentralShipper ?
            `wizard_popup_text_${WizardStepName.NewShipmentBaseData2}_central` :
            `wizard_popup_text_${WizardStepName.NewShipmentBaseData2}`,
        }
      })
    });

    this.registerStep({
      name: WizardStepName.NewShipmentParcels,
      forceNavigation: "/shipments/0",
      overlay: {
        tag: "shipmentEditorParcels",
        boundries: shipmentEditorBoundries,
        offset: shipmentEditorPanelElementOverlayOffset
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewShipmentParcels, {
        connectedToElementTag: "shipmentEditorParcels",
        connectedPositionBoundries: shipmentEditorBoundries,
        responsivePositions: [
          [this.breakPoints[0], avaiablePositions.centerBottom],
          [this.breakPoints[1], avaiablePositions.rightTop],
          [this.breakPoints[2], avaiablePositions.leftTop]
        ]
      })
    });

    this.registerStep({
      name: WizardStepName.NewShipmentCustoms,
      forceNavigation: "/shipments/0",
      overlay: {
        tag: ["shipmentEditorCustoms", "shipmentEditorCustomsParcels"],
        boundries: shipmentEditorBoundries,
        offset: shipmentEditorPanelElementOverlayOffset
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewShipmentCustoms, {
        connectedToElementTag: "shipmentEditorCustoms",
        connectedPositionBoundries: shipmentEditorBoundries,
        responsivePositions: [
          [this.breakPoints[0], avaiablePositions.centerBottom],
          [this.breakPoints[1], avaiablePositions.leftTop],
          [this.breakPoints[2], avaiablePositions.rightTop]
        ]
      })
    });

    this.registerStep({
      name: WizardStepName.NewShipmentSave,
      forceNavigation: "/shipments/0",
      overlay: {
        tag: "shipmentEditorButtons",
        offset: {x: 20, y: 10}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewShipmentSave, {
        connectedToElementTag: "shipmentEditorButtons",
        connectedPositions: [avaiablePositions.centerTop],
        connectedPositionBoundries: shipmentEditorBoundries
      })
    });

    this.registerStep({
      name: WizardStepName.NewShipmnetOverview,
      forceNavigation: "/shipments",
      overlay: {
        tag: "shipmnetsOverview"
      },
      popup: this.createDescriptionPopup(WizardStepName.NewShipmnetOverview, {
        popupClass: "wizard__popup--shipment-overview",
        position: {
          top: "10%",
          left: "700px",
        },
      })
    });

    this.registerOverviewStep({
      name: WizardStepName.ImportShipmentsIntro,
    });

    // this.registerClickStep({
    //   name: WizardStepName.ClickShipmentsImport,
    //   tag: "mainNavShipments",
    //   arrow: { text: "wizard_arrow_nav_shipments" }
    // });

    this.registerClickStep({
      name: WizardStepName.ClickImportShipments,
      forceNavigation: "/shipments",
      tag: "importShipments",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_import_shipments" }
    });

    this.registerStep({
      name: WizardStepName.ImportShipments,
      forceNavigation: "/shipments",
      overlay: {
        tag: "importShipmentsDialogParams",
        offset: {x: 20, y: 10}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportShipments, {
        connectedToElementTag: "importShipmentsDialog",
        connectedPositions: [avaiablePositions.rightCenter],
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.ImportShipmentsIntro}`,
          goToStepButtons: [{
            stepName: WizardStepName.ImportProfilesIntro,
            customEventType: "skipToImportProfiles",
            label: "action_wizard_go_to_ImportProfilesIntro"
          }]
        }
      })
    });

    this.registerStep({
      name: WizardStepName.ImportShipments2,
      forceNavigation: "/shipments",
      overlay: {
        tag: "importShipmentsDialogButtons",
        offset: {x: 20, y: 10}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportShipments2, {
        connectedToElementTag: "importShipmentsDialog",
        connectedPositions: [avaiablePositions.rightCenter],
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.ImportShipmentsIntro}`,
          text: this.shipperSettingsService.isCentralShipper ?
            `wizard_popup_text_${WizardStepName.ImportShipments2}_central` :
            `wizard_popup_text_${WizardStepName.ImportShipments2}`,
          goToStepButtons: [{
            stepName: WizardStepName.ImportProfilesIntro,
            customEventType: "skipToImportProfiles",
            label: "action_wizard_go_to_ImportProfilesIntro"
          }]
        }
      })
    });
  }

  private initPickups() {
    const newPickupLabelPositions: ConnectedPosition[] = [{
      originX: "center",
      originY: "top",
      overlayX: "start",
      overlayY: "top",
      offsetY: 10
    }];

    const pickupEditorBoundries: Partial<WizardBoundries> = {
      top: 83, // top bar
      bottom: 158 // step bar + bottom toolbar
    };

    this.registerChapter(WizardChapterName.Pickups, new Set([
      WizardStepName.PickupIntro,
      WizardStepName.ClickPickups,
      WizardStepName.ClickNewPickup,
      WizardStepName.NewPickup
    ]));

    this.registerOverviewStep({
      name: WizardStepName.PickupIntro,
    });

    this.registerClickStep({
      name: WizardStepName.ClickPickups,
      tag: "mainNavPickups",
      arrow: { text: "wizard_arrow_nav_pickup_orders" }
    });

    this.registerClickStep({
      name: WizardStepName.ClickNewPickup,
      forceNavigation: "/pickup-orders",
      tag: "newPickup",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_new_pickup_order" }
    });

    this.registerStep({
      name: WizardStepName.NewPickup,
      forceNavigation: "pickup-orders/0",
      overlay: {
        tag: "pickupEditor"
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.NewPickup, {
        popupClass: "wizard__popup--xl",
        connectedToElementTag: "pickupEditorContactPerson",
        connectedPositions: [{...avaiablePositions.centerBottom, offsetY: -40}],
        connectedPositionBoundries: pickupEditorBoundries,
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.PickupIntro}`
        }
      }),
      labels: [
        { positions: newPickupLabelPositions, tag: "pickupEditorPickupAddress", text: "1", labelClass: "wizard__label--number" },
        { positions: newPickupLabelPositions, tag: "pickupEditorContactPerson", text: "2", labelClass: "wizard__label--number" },
        { positions: newPickupLabelPositions, tag: "pickupEditorDetails", text: "3", labelClass: "wizard__label--number" }
      ]
    });
  }

  private initImportProfiles() {
    const importProfilesOverlayBoundries: Partial<WizardBoundries> = {
      top: 113, // top bar + tabs
      bottom: 110 // step bar
    };

    const importProfilesPopupBoundries: Partial<WizardBoundries> = {
      top: importProfilesOverlayBoundries.top + 10,
      bottom: importProfilesOverlayBoundries.bottom + 10
    };

    this.registerChapter(WizardChapterName.ImportProfiles, new Set([
      WizardStepName.ImportProfilesIntro,
      WizardStepName.ClickImportProfiles,
      WizardStepName.ClickImportProfilesShipments,
      WizardStepName.ClickAddImportProfile,
      WizardStepName.ImportProfileIntro,
      WizardStepName.ImportProfileBasicData1,
      WizardStepName.ImportProfileBasicData2,
      WizardStepName.ImportProfileClickMapping,
      WizardStepName.ImportProfileMappingFile,
      WizardStepName.ImportProfileMappingRecipient,
      WizardStepName.ImportProfileMappingParcels,
      WizardStepName.ClickSaveImportProfile,
      // WizardStepName.EditImportProfile,
      // WizardStepName.ClickShipmentsImportProfiles,
      // WizardStepName.ClickImportShipmentsImportProfiles,
      // WizardStepName.ImportProfilesImportShipments,
      // WizardStepName.ImportProfilesImportComplete,
      // WizardStepName.ImportArchive,
      // WizardStepName.ImportProfilesEnd,
    ]));

    this.registerOverviewStep({
      name: WizardStepName.ImportProfilesIntro,
      inputs: {
        hasCompleteChapterButton: true,
        completeChapterLabel: "action_wizard_cancel_import_profiles"
      }
    });

    this.registerClickStep({
      name: WizardStepName.ClickImportProfiles,
      tag: "mainNavImportProfiles",
      arrow: { text: "wizard_arrow_nav_import_profiles" }
    });

    this.registerClickStep({
      name: WizardStepName.ClickImportProfilesShipments,
      forceNavigation: "/import-profiles",
      tag: "importProfilesNavShipments",
      arrow: { text: "wizard_arrow_nav_import_profiles_shipment" }
    });

    this.registerClickStep({
      name: WizardStepName.ClickAddImportProfile,
      forceNavigation: "/import-profiles/shipments/list",
      tag: "newImportProfile",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_new_import_profile" }
    });

    this.registerOverviewStep({
      name: WizardStepName.ImportProfileIntro
    });

    this.registerStep({
      name: WizardStepName.ImportProfileBasicData1,
      forceNavigation: this.importProfileEditorForcedNavigation,
      enabledSectionTags: ["importProfileEditorBasicData1"],
      overlay: {
        tag: "importProfileEditorBasicData1",
        boundries: importProfilesOverlayBoundries,
        offset: {x: 14}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportProfileBasicData1, {
        connectedToElementTag: "importProfileEditorBasicData1",
        connectedPositions: [avaiablePositions.centerBottom],
        connectedPositionBoundries: importProfilesPopupBoundries
      })
    });

    this.registerStep({
      name: WizardStepName.ImportProfileBasicData2,
      forceNavigation: this.importProfileEditorForcedNavigation,
      enabledSectionTags: ["importProfileEditorBasicData2"],
      overlay: {
        tag: "importProfileEditorBasicData2",
        boundries: importProfilesOverlayBoundries,
        offset: {x: 14}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportProfileBasicData2, {
        connectedToElementTag: "importProfileEditorBasicData2",
        connectedPositions: [{
          originX: "center",
          originY: "center",
          overlayX: "start",
          overlayY: "center"
        }],
        connectedPositionBoundries: importProfilesPopupBoundries,
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.ImportProfileBasicData1}`
        }
      })
    });

    this.registerClickStep({
      name: WizardStepName.ImportProfileClickMapping,
      forceNavigation: this.importProfileEditorForcedNavigation,
      tag: "importProfileMappingButton",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_import_profile_mapping" }
    });

    this.registerStep({
      name: WizardStepName.ImportProfileMappingFile,
      forceNavigation: this.importProfileEditorForcedNavigation,
      enabledSectionTags: ["importProfileMappingFile"],
      overlay: {
        tag: "importProfileMappingFile",
        boundries: importProfilesOverlayBoundries,
        offset: {x: 14}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportProfileMappingFile, {
        connectedToElementTag: "importProfileMappingFile",
        connectedPositions: [avaiablePositions.centerBottom],
        connectedPositionBoundries: importProfilesPopupBoundries
      })
    });

    this.registerStep({
      name: WizardStepName.ImportProfileMappingRecipient,
      forceNavigation: this.importProfileEditorForcedNavigation,
      enabledSectionTags: ["importProfileMappingRecipient"],
      overlay: {
        tag: "importProfileMappingRecipient",
        boundries: importProfilesOverlayBoundries,
        offset: {x: 14}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportProfileMappingRecipient, {
        connectedToElementTag: "importProfileMappingRecipient",
        connectedPositions: [{
          originX: "center",
          originY: "top",
          overlayX: "start",
          overlayY: "top",
          offsetY: 25
        }],
        connectedPositionBoundries: importProfilesPopupBoundries,
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.ImportProfileMappingFile}`
        }
      })
    });

    this.registerStep({
      name: WizardStepName.ImportProfileMappingParcels,
      forceNavigation: this.importProfileEditorForcedNavigation,
      enabledSectionTags: ["importProfileMappingParcels"],
      overlay: {
        tag: "importProfileMappingParcels",
        boundries: importProfilesOverlayBoundries,
        offset: {x: 14}
      },
      popup: this.createConnectedDescriptionPopup(WizardStepName.ImportProfileMappingParcels, {
        connectedToElementTag: "importProfileMappingParcels",
        connectedPositions: [{
          originX: "center",
          originY: "top",
          overlayX: "start",
          overlayY: "top",
          offsetY: 25
        }],
        connectedPositionBoundries: importProfilesPopupBoundries,
        popupComponentData: {
          headline: `wizard_popup_headline_${WizardStepName.ImportProfileMappingFile}`
        }
      })
    });

    this.registerClickStep({
      name: WizardStepName.ClickSaveImportProfile,
      forceNavigation: this.importProfileEditorForcedNavigation,
      tag: "saveImportProfile",
      arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_save_import_profile" }
    });

    // this.registerStep({
    //   name: WizardStepName.EditImportProfile,
    //   forceNavigation: this.importProfileEditorForcedNavigation,
    //   enabledSectionTags: ["importProfileEditorToolBar", "importProfileEditor"],
    //   overlay: {
    //     tag: ["importProfileEditorToolBar", "importProfileEditor"]
    //   },
    //   popup: this.createDescriptionPopup(WizardStepName.EditImportProfile, {
    //     popupClass: "wizard__popup--lg",
    //     position: {
    //       right: "30px",
    //       bottom: "140px"
    //     },
    //     popupComponentData: {
    //       navigation: {
    //         isNextDisplayed: false,
    //         isPreviousDisplayed: false,
    //       },
    //       goToStepButtons: [{
    //         stepName: (current: string) => this.exitSet(current as WizardStepName),
    //         label: "action_wizard_cancel_edit_import_profile"
    //       }]
    //     }
    //   })
    // });

    // this.registerClickStep({
    //   name: WizardStepName.ClickShipmentsImportProfiles,
    //   tag: "mainNavShipments",
    //   arrow: { text: "wizard_arrow_nav_shipments" }
    // });

    // this.registerClickStep({
    //   name: WizardStepName.ClickImportShipmentsImportProfiles,
    //   forceNavigation: "/shipments",
    //   tag: "importShipments",
    //   arrow: { position: WizardArrowPosition.Bottom, text: "wizard_arrow_click_import_shipments" }
    // });

    // this.registerStep({
    //   name: WizardStepName.ImportProfilesImportShipments,
    //   forceNavigation: "/shipments",
    //   overlay: {
    //     tag: "importShipmentsDialog"
    //   },
    //   enabledSectionTags: ["importShipmentsDialog"],
    //   popup: this.createConnectedDescriptionPopup(WizardStepName.ImportProfilesImportShipments, {
    //     connectedToElementTag: "importShipmentsDialog",
    //     connectedPositions: [{
    //       originX: "end",
    //       originY: "center",
    //       overlayX: "start",
    //       overlayY: "center",
    //       offsetX: 20,
    //     }],
    //     popupComponentData: {
    //       headline: `wizard_popup_headline_${WizardStepName.ImportProfilesImportShipments}`,
    //       isNextDisplayed: false
    //     }
    //   })
    // });

    // this.registerStep({
    //   name: WizardStepName.ImportProfilesImportComplete,
    //   forceNavigation: "/shipments",
    //   overlay: {
    //     tag: "importShipmentsDialog"
    //   },
    //   enabledSectionTags: ["goToImportArchive"],
    //   popup: this.createConnectedDescriptionPopup(WizardStepName.ImportProfilesImportComplete, {
    //     connectedToElementTag: "importShipmentsDialog",
    //     connectedPositions: [{
    //       originX: "end",
    //       originY: "center",
    //       overlayX: "start",
    //       overlayY: "center",
    //       offsetX: 20,
    //     }],
    //     popupComponentData: {
    //       headline: `wizard_popup_headline_${WizardStepName.ImportProfilesImportShipments}`,
    //       isNextDisplayed: false
    //     }
    //   }),
    //   arrows: [
    //     {tag: "goToImportArchive", position: WizardArrowPosition.Top, text: "wizard_arrow_go_to_import_archive"}
    //   ]
    // });

    // this.registerStep({
    //   name: WizardStepName.ImportArchive,
    //   forceNavigation: "/shipments/import-archive",
    //   enabledSectionTags: ["importArchive"],
    //   overlay: {
    //     tag: "importArchive"
    //   },
    //   popup: this.createConnectedDescriptionPopup(WizardStepName.ImportArchive, {
    //     connectedToElementTag: "importArchive",
    //     connectedPositions: [{
    //       originX: "start",
    //       originY: "bottom",
    //       overlayX: "start",
    //       overlayY: "bottom",
    //       offsetX: 30,
    //       offsetY: -70
    //     }]
    //   })
    // });

    // this.registerOverviewStep({
    //   name: WizardStepName.ImportProfilesEnd,
    //   forceNavigation: "/shipments",
    //   inputs: {
    //     headline: `wizard_popup_headline_${WizardStepName.ImportProfilesImportShipments}`
    //   }
    // });
  }

  private initEnd() {
    this.registerChapter(WizardChapterName.End, new Set([
      WizardStepName.End
    ]));

    this.registerOverviewStep({
      name: WizardStepName.End
    });
  }

  private initStandardSequence() {
    const nextSequence = enumKeys(WizardStepName) as WizardStepName[];

    nextSequence.forEach((stepName, idx) => {
      this.nextSequence.set(stepName, nextSequence[idx + 1] ?? null);
    });

    const previousSequence = enumKeys(WizardStepName).reverse() as WizardStepName[];

    // Call to action steps (no navigation buttons in popups)
    const exludedPreviousSequnceSteps = new Set([
      WizardStepName.SettingsBasic,
      WizardStepName.SettingsPrint,
      WizardStepName.SettingsPrintTestLabel,
      WizardStepName.NewUser,
      WizardStepName.NewRecipient,
    ]);

    previousSequence.forEach((stepName, idx) => {
      // Find closest not "Click" step as previous step otherwise user couldn't get past them.
      const previousStep = previousSequence
        .find((step, stepIdx) => !exludedPreviousSequnceSteps.has(step) &&
          !step.includes("Click") &&
          stepIdx > idx);

      this.previousSequence.set(stepName, previousStep ?? null);
    });
  }

  private setInitialOmittedSteps() {
    const isCentral = this.shipperSettingsService.isCentralShipper;

    if (!isCentral) {
      localShipperOmittedSteps.forEach(s => this.omittedSteps.add(s));
    }
  }
  /* #endregion */


  private observeAuth() {
    this.authenticationService.user.subscribe(user => {
      if (user) {
        this.setAdminSteps();
      }
    });
  }

  private setAdminSteps() {
    this.authenticationService.isAdmin$.subscribe(isAdmin => {
      adminSteps.forEach(step => {
        if (isAdmin) {
          this.omittedSteps.delete(step);
        } else {
          this.omittedSteps.add(step);
        }
      });

      this.omittedSteps.add(WizardStepName.EditUser);
    });
  }

  private observeBreakPoints() {
    this.breakpointObserver.observe(this.breakPoints).subscribe(state => {
      this.responsivePositions.forEach((positions, name) => {
        const step = this.steps.get(name);

        // Ensure that the popup is connected to an element.
        if (step?.popup?.connectedToElementTag) {
          const activePositions = [];

          this.breakPoints.forEach(bp => {
            if (state.breakpoints[bp]) {
              const bpPosition = positions.get(bp);

              if (bpPosition) {
                activePositions.unshift(positions.get(bp));
              }
            }
          });

          step.popup.connectedPositions = activePositions;
        }
      });

      this.stepModified$.next();
    });
  }
}
