import { Component, HostBinding, HostListener, OnDestroy } from "@angular/core";

import * as _ from "lodash";
import { Subject } from "rxjs";

import { animations } from "../animations";
import { WizardCssClass } from "../models";
import { WizardOverlayService } from "../services/wizard-overlay.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "wizard-overlay",
  templateUrl: "./wizard-overlay.component.html",
  animations: [
    animations.wizardOverlay
  ],
  host: {
    class: WizardCssClass.Overlay,
    "[@wizardOverlay]": "true"
  }
})
export class WizardOverlayComponent implements OnDestroy {
  isTransitionAnimated = false;

  private readonly destroy$ = new Subject<void>();

  private isEnterAnimationDone = false;

  constructor(private wizardOverlayService: WizardOverlayService) { }

  @HostBinding(`class.${WizardCssClass.OverlayAnimated}`)
  get isAnimated(): boolean {
    return this.isTransitionAnimated &&
      this.isEnterAnimationDone &&
      this.wizardOverlayService.hasDisplayedArea;
  }

  @HostBinding(`class.${WizardCssClass.OverlayAutoScrolling}`)
  get isAutoScrolling(): boolean {
    return this.wizardOverlayService.isAutoScrolling;
  }

  @HostBinding(`class.${WizardCssClass.OverlayHasArea}`)
  get hasArea(): boolean {
    return this.wizardOverlayService.hasDisplayedArea;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener("@wizardOverlay.done")
  onEnterAnimationDone() {
    this.isEnterAnimationDone = true;
  }

  @HostListener("transitionend")
  onTransitionEnd() {
    this.isTransitionAnimated = false;
  }
}
