import { Component, forwardRef, Input } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import * as Shared from "../../shared/index";
import { UserRelatedItemModel } from "../../shared/models/user.model";
import { UserRelatedItemListEditorBase } from "./user-related-item-list-editor-base";

const baseProvider = {
  provide: UserRelatedItemListEditorBase,
  useExisting: forwardRef(() => UserRelatedProductItemListEditorComponent)
};

@Component({
  selector: Shared.SELECTOR_PREFIX + "-user-related-product-item-list-editor",
  templateUrl: "./user-related-item-list-editor-base.html",
  providers: [baseProvider]
})
export class UserRelatedProductItemListEditorComponent extends UserRelatedItemListEditorBase {
  constructor(
    loggingService: Shared.LoggingService,
    exceptionHandlerService: Shared.ExceptionsHandlerService,
    localizationService: Shared.LocalizationService,
    skdataConfigService: Shared.SkdataConfigService,
    toastr: ToastrService
  ) {
    super(
      (userId: number) => skdataConfigService.getAllProductItemsForUser(userId),
      (items: UserRelatedItemModel[]) => skdataConfigService.setAllProductItemsForUser(items),
      "user_access_control_allowed_products_saved",
      loggingService,
      exceptionHandlerService,
      localizationService,
      toastr);
  }

  @Input()
  public set userId(newId: number) {
    super.setUserId(newId);
  }

}
