<nav>
  <ul>
    <!-- Shipments -->
    <li *ngIf="isVisible(shipperMainMenuItems.Shipments)" wizardTag="mainNavShipments" [wizardCompleteStep]="[wizardStepNames.ClickShipments, wizardStepNames.ClickShipmentsImport]">
      <a [routerLink]="['/shipments']" [ngClass]="{ 'disabled': hasUnchangedPassword }" id="menu-shipmets" [routerLinkActive]="['active']"><span class="icon ic-box-1"></span><span class="title">{{ 'shipments' | shpLocalize }}</span></a>
    </li>

    <!-- Pickup Orders -->
    <li *ngIf="isVisible(shipperMainMenuItems.PickupOrders)" wizardTag="mainNavPickups" [wizardCompleteStep]="wizardStepNames.ClickPickups">
      <a [routerLink]="['/pickup-orders']" [ngClass]="{ 'disabled': hasUnchangedPassword }" id="menu-pickup-orders" [routerLinkActive]="['active']"><span class="icon ic-truck"></span><span class="title">{{ 'pickup_orders' | shpLocalize }}</span></a>
    </li>

    <!-- Recipients -->
    <li *ngIf="isVisible(shipperMainMenuItems.Recipients)" wizardTag="mainNavRecipients" [wizardCompleteStep]="[wizardStepNames.ClickRecipients, wizardStepNames.ClickRecipientsImport]">
      <a [routerLink]="['/recipients']" [ngClass]="{ 'disabled': hasUnchangedPassword }" id="menu-recipients" [routerLinkActive]="['active']"><span class="icon ic-real-estate-address-book-1"></span><span class="title">{{ 'recipients' | shpLocalize }}</span></a>
    </li>

    <!-- Settings -->
    <li *ngIf="isVisible(shipperMainMenuItems.Settings)" wizardTag="mainNavSettings" [wizardCompleteStep]="wizardStepNames.ClickSettings">
      <a [routerLink]="['/settings']" [ngClass]="{ 'disabled': hasUnchangedPassword }" id="menu-settings" [routerLinkActive]="['active']"><span class="icon ic-settings-1"></span><span class="title">{{ 'settings' | shpLocalize }}</span></a>
    </li>

    <!-- Notifications -->
    <li *ngIf="isVisible(shipperMainMenuItems.Notifications)">
      <a [routerLink]="['/messages']" [ngClass]="{ 'disabled': hasUnchangedPassword }" id="menu-messages" [routerLinkActive]="['active']"><span class="icon ic-chat-bubble-square-1"></span><span class="title">{{ 'notifications' | shpLocalize }}</span><shp-unread-notifications-counter></shp-unread-notifications-counter></a>
    </li>

    <!-- Import profiles -->
    <li *ngIf="isVisible(shipperMainMenuItems.ImportProfiles)" wizardTag="mainNavImportProfiles" [wizardCompleteStep]="wizardStepNames.ClickImportProfiles">
      <a [routerLink]="['/import-profiles']" [ngClass]="{ 'disabled': hasUnchangedPassword }" id="menu-import-profiles" [routerLinkActive]="['active']"><span class="icon ic-file-download-2"></span><span class="title">{{ 'import_profiles' | shpLocalize }}</span></a>
    </li>

    <!-- Support -->
    <li *ngIf="isVisible(shipperMainMenuItems.Support)">
      <a [routerLink]="['/support']" [ngClass]="{ 'disabled': hasUnchangedPassword }" id="menu-help"><span class="icon ic-location-pin-question-mark-1"></span><span class="title">{{ 'support' | shpLocalize }}</span></a>
    </li>
  </ul>
</nav>
