import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import * as Shared from "../../shared/index"
import { Recipient, RecipientsFilter, RecipientsImportModel, RecipientsExportParametersModel, ExportModel, ImportOutput, CityCheckParameters, RecipientForGridView } from "../models/recipient.model"
import { PagedResult } from "../../shared/index";
import { encodeAsQueryString } from "../../shared/uri-helper";
import { CityAndZipCheckResult } from "../../shipments/models/shipment.model";
import { SettingsService } from "../../settings/services/settings.service";
import { TenantSettings } from "src/app/settings/models/settings.model";

@Injectable()
export class RecipientsService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient,
    private _settingsService: SettingsService
  ) {
    super(loggingService);
  }

  private _shouldRefreshRecipients: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public shouldRefreshRecipients = this._shouldRefreshRecipients.asObservable();


  public getRecipients(customerDetailId?: number) {
    return this.processRequest<Recipient[]>(this._http.get(Shared.API_URL + "/recipients" + (customerDetailId ? `/customer-detail/${customerDetailId}` : "")));
  }


  public getFilteredRecipients(filter: RecipientsFilter) {
    this._settingsService.activeConfigurationSettings$.pipe(
      map((settings: TenantSettings) => settings.extended_recipient_search_turned_on.value)
    ).subscribe(value => {
      if (filter) {
        filter.extendedSearch = value;
      }
    });

    return this.processRequest<PagedResult<RecipientForGridView>>(this._http.get(Shared.API_URL + "/recipients/filtered?" + encodeAsQueryString(filter)));
  }


  public getRecipient(id: number) {
    return this.processRequest<Recipient>(this._http.get(Shared.API_URL + "/recipients/" + id));
  }


  public addRecipient(recipient: Recipient) {
    return this.processRequest<Recipient>(this._http.post(Shared.API_URL + "/recipients", recipient));
  }


  public updateRecipient(recipient: Recipient) {
    return this.processRequest<Recipient>(this._http.put(Shared.API_URL + "/recipients/" + recipient.id, recipient));
  }


  public deleteRecipient(id: number) {
    return this.processRequest(this._http.delete(Shared.API_URL + "/recipients/" + id));
  }


  public deleteRecipients(ids: number[]) {
    return this.processRequest(this._http.post(Shared.API_URL + "/recipients/deleted", ids));
  }


  public undeleteRecipient(id: number) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/recipients/undelete/${id}`, null));
  }


  public importReciepients(model: RecipientsImportModel) {
    return this.processRequest<ImportOutput>(this._http.post(`${Shared.API_URL}/recipients/import`, model));
  }


  public checkZipAndCity(model: CityCheckParameters) {
    return this.processRequest<CityAndZipCheckResult>(this._http.post(`${Shared.API_URL}/georouting/check-city-and-zip`, model));
  }


  public deleteAllRecipients(confirmationText: string, addressId: number, customerDetailId: number) {
    return this.processRequest<number>(this._http.delete(`${Shared.API_URL}/recipients?deleteConfirmationText=${confirmationText}&addressId=${addressId}&customerDetailId=${customerDetailId}`));
  }


  public exportReciepients(model: RecipientsExportParametersModel, type: string) {
    if (type.toLowerCase() == "xml") {
      return this.processRequest<ExportModel>(this._http.post(`${Shared.API_URL}/recipients/xml`, model));
    } else {
      return this.processRequest<ExportModel>(this._http.post(`${Shared.API_URL}/recipients/csv`, model));
    }
  }


  public notifyShouldRefreshRecipients() {
    this._shouldRefreshRecipients.next(true);
  }
}
