import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import * as _ from "lodash";
import { filter, map, mergeMapTo, switchMapTo, take } from "rxjs/operators";
import { fromEvent } from "rxjs";

import { BUSINESS_UNIT_CODE_NL, ShipperSettingsService } from "../index";
import { ContextService } from "./context.service";
import { environment } from "../../../environments/environment";

declare global {
  interface Window {
    dataLayer: any[] | null;
  }
}

declare let gtag: (...args: any[]) => void;

enum GoogleAnalyticsTagType {
  GTAG,
  GTM
}

interface GoogleAnalyticsBUSettings {
  key: string;
  type: GoogleAnalyticsTagType;
}

export const googleAnalyticsBUSettings = new Map<string, GoogleAnalyticsBUSettings>([
  [BUSINESS_UNIT_CODE_NL, {
    type: GoogleAnalyticsTagType.GTAG,
    key: "G-3BYD4ZJ4P4",
  }],
  // [BUSINESS_UNIT_CODE_CZ, {}],
  // [BUSINESS_UNIT_CODE_SK, {}],
  // [BUSINESS_UNIT_CODE_BE, {}],
  // [BUSINESS_UNIT_CODE_LU, {}],
  // [BUSINESS_UNIT_CODE_CH, {}]
]);

@Injectable({
  providedIn: "root"
})
export class GoogleAnalyticsService {

  constructor(
      private _contextService: ContextService,
      private _shipperSettingsService: ShipperSettingsService,
      private router: Router) {
    if (environment.production) {
      this.init();
    }
  }

  private init() {
    this._shipperSettingsService.settingsLoaded$.pipe(
      take(1),
      filter(() => this._shipperSettingsService.isCentralShipper),
      switchMapTo(this._contextService.businessUnitCode),
      map(buCode => googleAnalyticsBUSettings.get(buCode)),
      filter(settings => Boolean(settings)),
      take(1)
    ).subscribe(settings => {
      switch (settings.type) {
        case GoogleAnalyticsTagType.GTAG:
          this.initGTAGScript(settings.key);
          break;
        case GoogleAnalyticsTagType.GTM:
          this.initGTMScript(settings.key);
          break;
        default:
          break;
      }
    });
  }

  private initGTAGScript(key: string) {
    const script = document.createElement("script");
    const headEl = document.getElementsByTagName("head")[0];

    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;

    gtag("js", new Date());
    gtag("config", key, {send_page_view: false});

    fromEvent(script, "load").pipe(
      mergeMapTo(this.router.events),
      filter(e => e instanceof NavigationEnd)
    ).subscribe(() => {
      gtag("event", "page_view", {
        page_location: window.location.href,
        page_path: window.location.pathname,
        send_to: key
      });
    });

    headEl.appendChild(script);
  }

  private initGTMScript(key: string) {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${key}`;

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });

    document.head.insertBefore(script, document.head.firstChild);
  }
}
