import { Directive, forwardRef, Input } from "@angular/core";
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator, AsyncValidator } from "@angular/forms";

import * as Shared from "../index"
import { phoneNumberValidator } from "../validators";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}ValidatePhone]`,
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => PhoneNumberValidatorDirective), multi: true }
  ]
})
export class PhoneNumberValidatorDirective implements AsyncValidator {
  constructor(
    private businessUnitSettingsService: Shared.BusinessUnitSettingsService
  ) {

  }


  @Input() phoneRegionCode: any;
  @Input() countryCode: string;

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.businessUnitSettingsService.getIsPhoneNumberValidationMandatory(this.countryCode).pipe(
      map(
        isRequired => {
          if (isRequired) {
            return (control.value && this.phoneRegionCode && this.countryCode) ? phoneNumberValidator(this.phoneRegionCode.countryCode)(control) : null;
          } else {
            return null;
          }
        }),
      catchError(() => of(null))
    );
  }
}