import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import * as Shared from "../../shared/index";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipment",
  templateUrl: "./shipment.component.html"
})
export class ShipmentComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _route: ActivatedRoute
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  private _paramsChangeSubscription: Subscription = null;


  public shipmentId: number = null;


  public ngOnInit() {
    this._paramsChangeSubscription = this._route.params.subscribe(params => {
      this.shipmentId = params["shipmentId"];
    });
  }

  public ngOnDestroy() {
    if (this._paramsChangeSubscription != null) {
      this._paramsChangeSubscription.unsubscribe();
    }
  }
}