<div class="top">
  <h1>{{ 'news_and_notifications' | shpLocalize }}</h1>
  <button type="button" id="mark-notifications-as-read" class="top-button" (click)="markNotificationsAsRead()"><span class="ic-email-check"></span><span class="label" [innerHtml]="'notifications_mark_as_read' | shpLocalize"></span></button>
  <button *ngIf="currentView === 'notifications'" type="button" id="delete-notifications" class="top-button" (click)="deleteNotifications()"><span class="ic-bin"></span><span class="label" [innerHtml]="'notifications_delete_all' | shpLocalize"></span></button>
</div>


<div class="content-panel notifications">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>

  <div class="list">
    <div class="list-tabs">
      <button [class.active]="currentView === 'newsfeed'" (click)="changeView('newsfeed')">{{ 'newsfeed' | shpLocalize }}</button>
      <button [class.active]="currentView === 'notifications'" (click)="changeView('notifications')">{{ 'notifications' | shpLocalize }}</button>
    </div>

    <ng-container *ngIf="currentView === 'newsfeed'">
      <div class="items">
        <div class="scroll-part">
          <div *ngFor="let message of newsfeedMessages; index as i" [class.odd]="i % 2 === 1"
               class="notification list-item newsfeed-list-item"
               (click)="showNewsfeedMessageContent(message)"
               [class.newsfeed-list-item--hight-priority]="message.priority === 1"
               [class.read]="message.isRead">
            <div class="content"
              [class.warning]="message.priority === 1"
              [class.info]="message.priority === 0">
              <!-- <span class="notification-date">{{ notification.notificationDateUtc | shpLocalizeDate }}</span> -->
              <h2 class="my-0">{{ message.title }}</h2>
              <!-- Don't show abstract here: https://jira.fores.group/browse/INSIGHTS-373 -->
              <!-- <span class="notification-message-trimmed" [innerHtml]="message.abstract"></span>-->
            </div>
          </div>

          <div class="list-empty-message" *ngIf="newsfeedMessages?.length === 0">
            <div class="message-content">
              {{ 'no_newsfeed_messages_for_user' | shpLocalize }}
            </div>
          </div>
        </div>
      </div>

      <div class="item-overview" *ngIf="selectedMessage">
        <!-- <span class="notification-date"><span class="ic-calendar-1"></span> {{ selectedNotification.notificationDateUtc | shpLocalizeDate }}</span>-->
        <div class="notification-message">
          <h2 class="mt-0">{{ selectedMessage.title }}</h2>
          <div [innerHtml]="selectedMessage.content ? selectedMessage.content : selectedMessage.abstract"></div>
        </div>
      </div>

      <div class="no-item-selected" *ngIf="!selectedMessage">
        <div class="message">
          {{ 'notifications_list_select_notification_advice' | shpLocalize }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentView === 'notifications'">
      <div class="items">
        <div class="scroll-part" [class.has-pager]="parameters.pageSize < totalCount">
          <div class="notification list-item" *ngFor="let notification of notifications | async; let i = index" [class.odd]="i % 2 === 1"
               (click)="showNotificationDetails(notification)"
               [class.read]="notification.readDateUtc">
            <div class="content"
                 [class.error]="notification.severityCode === 'Error'"
                 [class.warning]="notification.severityCode === 'Warning'"
                 [class.info]="notification.severityCode === 'Info'">
              <span class="notification-date">{{ notification.notificationDateUtc | shpLocalizeDate }}</span>
              <span class="notification-message-trimmed" [innerHtml]="notification.message | shpLocalizeNotificationMessage">
              </span>
            </div>
          </div>

          <div class="list-empty-message" *ngIf="totalCount === 0">
            <div class="message-content">
              {{ 'message_no_notifications' | shpLocalize }}
            </div>
          </div>
        </div>

        <div class="pager-part" *ngIf="parameters.pageSize < totalCount">
          <button id="button-first" class="first" type="button" (click)="goToPage(0)" *ngIf="parameters.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
          <button id="button-prev" class="prev" type="button" (click)="goToPage(parameters.pageIndex - 1)" *ngIf="parameters.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
          <button id="button-next" class="next" type="button" (click)="goToPage(parameters.pageIndex + 1)" *ngIf="parameters.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
          <button id="button-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="parameters.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
          <span class="numbers">{{ parameters.pageIndex + 1 }}/{{ lastPageIndex + 1 }}</span>
        </div>
      </div>

      <div class="item-overview" *ngIf="selectedNotification != null">
        <span class="notification-date"><span class="ic-calendar-1"></span> {{ selectedNotification.notificationDateUtc | shpLocalizeDate }}</span>
        <div class="notification-message"
             [class.error]="selectedNotification.severityCode === 'Error'"
             [class.warning]="selectedNotification.severityCode === 'Warning'"
             [class.info]="selectedNotification.severityCode === 'Info'"><div [innerHtml]="selectedNotification.message | shpLocalizeNotificationMessage"></div></div>
      </div>

      <div class="no-item-selected" *ngIf="selectedNotification == null">
        <div class="message">
          {{ 'notifications_list_select_notification_advice' | shpLocalize }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
