<div class="dialog">
  <h1 class="text-left">{{ 'email_server_configuration_test' | shpLocalize }}</h1>
  
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>

  <form class="mt-2" [formGroup]="form">
    <div class="ctl required">
      <label for="test-email-sender-address">{{ 'test_email_sender_address' | shpLocalize }}</label>
      <input formControlName="senderEmailAddress" type="text" id="test-email-sender-address" name="senderEmailAddress" />
    </div>
    
    <div class="ctl required">
      <label for="test-email-recipient-address">{{ 'test_email_recipient_address' | shpLocalize }}</label>
      <input formControlName="recipientEmailAddress" type="text" id="test-email-recipient-address" name="recipientEmailAddress" />
    </div>
    
    <div class="ctl required">
      <label for="test-email-subject">{{ 'test_email_subject' | shpLocalize }}</label>
      <input formControlName="subject" type="text" id="test-email-subject" name="subject" />
    </div>

    <div class="ctl">
      <label for="test-email-content">{{ 'test_email_content' | shpLocalize }}</label>
      <textarea formControlName="content" type="text" id="test-email-content" name="content"></textarea>
    </div>
  </form>

  <div *ngIf="testEmailStatusMessage" class="d-flex mt-2 mb-2">
    <div class="bu-settings-email-server-test-dialog__label-placeholder"></div>
    <div>
      <div>{{ testEmailStatusMessage | shpLocalize }}</div>
      <div *ngIf="testEmailErrorMessage">{{ testEmailErrorMessage }}</div>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" (click)="dismiss()">{{ 'close' | shpLocalize }}</button>
    <button type="button" class="ml-1" [disabled]="form.invalid" (click)="sendTestEmail()">{{ 'send_test_email' | shpLocalize }}</button>
  </div>
</div>
      
      
      