import { Pipe, PipeTransform } from "@angular/core";
import { SELECTOR_PREFIX } from "../../shared/index";
import { ImportProfile } from "../../import-profiles/models/import-profiles.models";


@Pipe({
  name: SELECTOR_PREFIX + "ImportProfileId"
})
export class ImportProfileIdPipe implements PipeTransform {
  public transform(value, ...args: any[]) {
    if (typeof value === "undefined" || value == null) {
      return null;
    }


    if (!isNaN(value)) {
      // Ako prvý argument očakávame zoznam customer detailov, v ktorých hľadáme ten s ID z value.
      let id = +value;

      let importProfiles: ImportProfile[] = args[0];

      if (importProfiles) {
        let importProfile = importProfiles.find(ip => ip.id === id);

        if (importProfile) {
          return `${importProfile.name} (${importProfile.code})`;
        }
      }
    }

    return null;
  }
}