import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { forkJoin, Subscription } from "rxjs";
import { filter, map, mergeMap, take } from "rxjs/operators";

import * as _ from "lodash";

import { ToastrService } from "ngx-toastr";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import * as ConfigModel from "../../../shared/models/config.models";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { RemoteData, CompleterService } from "ng2-completer";
import { getEmptyAddress } from "../../../shipments/models/shipment.model";
import { ShipperSettingsService } from "../../../shared/index";
import { NgModel } from "@angular/forms";
import { ShipperWizardService, ShipperWizardUserAction } from "../../../shared/services/shipper-wizard.service";
import { WizardStepName } from "../../../shared/services/shipper-wizard-steps";
import { WizardService } from "../../../shared/modules/wizard/services/wizard.service";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-basic-settings",
  templateUrl: "./basic-settings.component.html"
})
export class BasicSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    private _completerService: CompleterService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _shipperSettingsService: ShipperSettingsService,
    private _shipperWizardService: ShipperWizardService,
    wizardService: WizardService,
    toastr: ToastrService) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr,
      wizardService);
  }

  private _productsLoaderSubscription: Subscription = null;
  private _languagesSubscription: Subscription = null;


  public products: ConfigModel.Product[];

  public isServiceWithSmsCodeAvailable = false;

  public languages: string[];
  public citiesDataService: RemoteData = null;
  public countries: Shared.Country[] = null;
  public countriesByCode: Shared.Country[] = null;

  // @AdamT ToDo: add type
  public addressTypes = [
    {
      id: 'defaultReturnAddress',
      title: this.localizationService.getLocalizedString('default_return_recipient'),
      enabled: true,
      config: {
        excludeComments: false,
      }
    },
    {
      id: 'defaultCustomSenderAddress',
      title: this.localizationService.getLocalizedString('default_custom_sender'),
      enabled: false,
      config: {
        excludeComments: true,
      }
    }
  ];

  public hasAddressTypeValues(addressType: string): boolean {
    var selectedValue = this.settings[addressType].value;
    if (selectedValue !== null && selectedValue !== undefined) {
      return true;
    }
    return false;
  }

  public addressesAreValid()  {
    for (let type of this.addressTypes)
    {    
      var address = this.settings[type.id]?.value;
      if (address !== null && address !== undefined)
      {
        if (!address.name1 || !address.zip || !address.city || !address.street)
        {
          return false;          
        }
      }
    }
    
    return true;    
  }

  public isSophia: boolean = false;


  public ngOnInit() {
    super.ngOnInit();

    this.isSophia = this._shipperSettingsService.isSophia;

    this.citiesDataService = this._completerService.remote(null, null, "cityNameLocal");
    this.citiesDataService.urlFormater((term: string) => {
      return `${Shared.API_URL}/countries/${this.settings.defaultReturnAddress.value.country.code}/${term}`;
    });

  }

  public get eoriNumberWithoutCountryCode() {
    return this._eoriNumberWithoutCountryCode;
  }

  public set eoriNumberWithoutCountryCode(value) {
    this._eoriNumberWithoutCountryCode = value;
    this.settings.default_sender_eori_number.value = this.eoriNumber;
  }

  public get eoriCountryCode() {
    return this._eoriCountryCode;
  }

  public set eoriCountryCode(value) {
    this._eoriCountryCode = value;
    this.settings.default_sender_eori_number.value = this.eoriNumber;
  }

  private _eoriNumberWithoutCountryCode: string = "";
  private _eoriCountryCode: string = "";

  public get eoriNumber() {
    if (this._eoriCountryCode !== "" && this._eoriNumberWithoutCountryCode && this._eoriNumberWithoutCountryCode.length) {
      return this._eoriCountryCode + this._eoriNumberWithoutCountryCode;
    } else {
      return "";
    }
  }

  public set eoriNumber(value) {
    if (value && value.length > 2) {
      this._eoriNumberWithoutCountryCode = value.slice(2, value.length);
      this._eoriCountryCode = value.slice(0, 2);
    }
  }

  @ViewChild("eoriControl")
  public eoriControl: NgModel;

  @ViewChild("vatControl")
  public vatControl: NgModel;


  protected loadLookups() {
    return forkJoin([
      this._skdataConfigService.getLanguages(),
      this.contextService.currentCustomerDetail.pipe(
        filter(value => value != null),
        take(1),
        mergeMap(value => {
          return this._skdataConfigService.getAllowedProductsAndAdditionalServices(value.id);
        })
      ),
      this._skdataConfigService.getCountries(),
      this._businessUnitSettingsService.getIsCustomSenderAddressAllowed()
    ]).pipe(
      map(result => {
        let languages = result[0];
        this.languages = languages.sort();

        var products = result[1];
        this.products = _.sortBy(_.filter(products, (p: ConfigModel.Product) => p.isProduct), ["ordinal"]);

        var smsCodedAdditionalService = _.find(products, (p: ConfigModel.Product) => !p.isProduct && p.code.toLowerCase() === "sms");
        this.isServiceWithSmsCodeAvailable = !!smsCodedAdditionalService;

        let countries = result[2];
        this.countries = countries;
        this.countriesByCode = _.orderBy(countries, "code");

        this.addressTypes.map( addressType => {
          if (addressType.id === 'defaultCustomSenderAddress') {
            addressType.enabled = result[3];
          }
        });

        return true;
      })
    );
  }


  protected afterSettingsLoaded() {
    this.addressTypes.forEach( addressType => {
      if (this.settings[addressType.id].value && this.settings[addressType.id].value.countryCode) {
        // FIX: Prestávame používať companyName, tak prekopírujeme to do name1 a premažeme.
        if (this.settings[addressType.id].value.companyName) {
          this.settings[addressType.id].value.name1 = this.settings[addressType.id].value.companyName;
          this.settings[addressType.id].value.companyName = null;
        }

        this.settings[addressType.id].value.country = this.countries.find(c => c.code === this.settings[addressType.id].value.countryCode);
      }
    });

    this.eoriNumber = this.settings.default_sender_eori_number.value;
  }


  public countryChanged( addressType: string ) {
    if (this.settings[addressType].value.country) {
      this.settings[addressType].value.countryCode = this.settings[addressType].value.country.code;
    } else {
      this.settings[addressType].value.countryCode = null;
    }
  }


  public setDefaultAddress( addressType: string ) {
    this.settings[addressType].value = getEmptyAddress();

    this.settings[addressType].value.countryCode = this.settings.country_code.value;
    this.settings[addressType].value.country = this.countries.find(c => c.code === this.settings[addressType].value.countryCode);
  }


  public removeDefaultAddress( addressType: string ) {
    this.settings[addressType].value = null;
  }

  public onSubmit() {
    if (!this.addressesAreValid()) {
      this.toastr.error(this.localizationService.getLocalizedString("error_description_generic_required"));
    } else {
      if (this.hasChanges()) {
        this._shipperWizardService.addUserAction(ShipperWizardUserAction.ChangedBasicSettings);
      }

      this.save();
    }
  }

  public ngOnDestroy() {
    if (this._languagesSubscription != null) {
      this._languagesSubscription.unsubscribe();
    }

    if (this._productsLoaderSubscription != null) {
      this._productsLoaderSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }
}
