<form #f (submit)="save()" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category">
    <h2>{{ 'settings_proxy' | shpLocalize }}</h2>
    <div class="ctl" *ngIf="settings.use_proxy_server.isVisible">
      <label for="proxy-use-proxy-server">{{ 'settings_proxy_use_proxy_server' | shpLocalize }}</label>
      <input type="checkbox"
             name="proxyUseProxyServer" id="proxy-use-proxy-server" [(ngModel)]="settings.use_proxy_server.value" />
      <label for="proxy-use-proxy-server"></label>
    </div>
    <div class="ctl" *ngIf="settings.proxy_address.isVisible">
      <label for="proxy-server">{{ 'settings_proxy_address' | shpLocalize }}</label>
      <input type="text"
             name="proxyServer" id="proxy-server" [(ngModel)]="settings.proxy_address.value" />
    </div>
    <div class="ctl" *ngIf="settings.proxy_port.isVisible">
      <label for="proxy-port">{{ 'settings_proxy_port' | shpLocalize }}</label>
      <input type="text"
             name="proxyPort" id="proxy-port" [(ngModel)]="settings.proxy_port.value" />
    </div>
    <div class="commands-toolbar">
      <button id="button-save" type="submit"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
    </div>
  </div>
</form>