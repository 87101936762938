<form #recipientform="ngForm" [name]="name + '_recipient'" [entityValidatorPrefix]="name + '_'">
  <h2>{{ name | shpLocalize }} <button id="button-clear-recipient" *ngIf="!isReadOnly" type="button" class="clear-recipient-buttom" (click)="clearRecipient()"><span class="ic-close"></span></button></h2>

  <div class="mdl-grid compact">

    <div class="mdl-cell mdl-cell--12-col ctl" *ngIf="((selectedProduct?.isDeliveryToHomeAddressProduct && selectedProduct?.isDeliveryToBusinessAddressProduct)) && !isReadOnly && !useB2BAddressFieldsOnly">
      <div class="radio-group">
        <div class="radio-group">
          <input type="radio" [disabled]="!selectedProduct?.isDeliveryToHomeAddressProduct && selectedProduct" id="{{name}}-recipient-customer-type-b2c" value="{{ b2c }}" name="{{name}}_recipient_type" [(ngModel)]="recipient.customerRecipientTypeCode" /><label class="radio-label" [attr.for]="name + '-recipient-customer-type-b2c'">{{ 'recipient_type_b2c' | shpLocalize }}</label>
          <input type="radio" [disabled]="!selectedProduct?.isDeliveryToBusinessAddressProduct && selectedProduct" id="{{name}}-recipient-customer-type-b2b" value="{{ b2b }}" name="{{name}}_recipient_type" [(ngModel)]="recipient.customerRecipientTypeCode" /><label class="radio-label" [attr.for]="name + '-recipient-customer-type-b2b'">{{ 'recipient_type_b2b' | shpLocalize }}</label>
        </div>
      </div>
    </div>
    <div class="ctl required mdl-cell mdl-cell--12-col" *ngIf="!isReadOnly">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <p-autoComplete id="{{name}}-recipient-name" [(ngModel)]="recipient.name" [suggestions]="filteredRecipients" (completeMethod)="filterRecipients($event)" (onSelect)="onRecipientSelected($event)" maxlength="{{ disableValidation ? 100 : 35 }}" name="{{name}}_recipient_name" [minLength]="minSearchLength" autocomplete="none" required>
            <ng-template let-recipient pTemplate="item"><div class="recipient-item"><div><b>{{recipient.name}}</b></div><div><small>{{recipient.details}}</small></div></div></ng-template>
          </p-autoComplete>
          <label for="{{name}}-recipient-name">{{ (recipient.customerRecipientTypeCode == b2c ? 'recipient_person_name' : 'recipient_organization_name') | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="isReadOnly">
      <input type="text" [(ngModel)]="recipient.name" maxlength="{{ disableValidation ? 100 : 35 }}" id="{{name}}-recipient-name" name="{{name}}_recipient_name" readonly="readonly" />
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="(!isReadOnly || !!recipient.name2)">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="{{name}}-recipient-name2" [(ngModel)]="recipient.name2" type="text" name="{{name}}_recipient_name2" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly" autocomplete="off">
          <label for="{{name}}-recipient-name2">{{ 'recipient_organization_name' | shpLocalize }} 2</label>
        </span>
      </div>
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="!hideFieldsIrrelevantForSender && (useB2BAddressFieldsOnly || recipient.customerRecipientTypeCode == b2b) && (!isReadOnly || !!recipient.contactPerson)">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="{{name}}-recipient-contactPerson" [(ngModel)]="recipient.contactPerson" type="text" name="{{name}}_recipient_contactPerson" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly" autocomplete="off">
          <label for="{{name}}-recipient-contactPerson">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <ng-container>
      <ng-template #street let-span="span" let-required="required" let-readonly="readonly">
        <div class="ctl mdl-cell mdl-cell--{{span}}-col" [class.required]="!isReadOnly && required">
          <div *ngIf="!isCityServiceSelected || isReadOnly" class="p-field p-field--full">
            <span class="p-float-label">
              <input id="{{name}}-recipient-street" [class.ignore-ng-invalid]="required && readonly" [(ngModel)]="recipient.street" type="text" name="{{name}}_recipient_street" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly || readonly" [required]="required" autocomplete="off" [pattern]="streetRegEx">
              <label for="{{name}}-recipient-street">{{ 'recipient_street' | shpLocalize }}</label>
            </span>
          </div>
          <ng2-completer *ngIf="isCityServiceSelected && !isReadOnly" [(ngModel)]="recipient.street" id="{{name}}-recipient-street" name="{{name}}_recipient_street" [minSearchLength]="2"
                         [dataService]="streetsDataService" maxlength="{{ disableValidation ? 100 : 35 }}" [required]="required" [disableInput]="readonly" placeholder="{{ 'recipient_street' | shpLocalize }}"></ng2-completer>
            <!-- <div class="p-field p-field--full" *ngIf="isCityServiceSelected && !isReadOnly">
            <span class="p-float-label">
              <p-autoComplete id="{{name}}}-recipient-street" [(ngModel)]="recipient.street" [suggestions]="filteredZips" (completeMethod)="filterZip($event)" (onSelect)="onZipSelected($event)" name="{{name}}_recipient_street" field="zip" [minLength]="2" autocomplete="none" maxlength="{{ disableValidation ? 100 : 35 }}" required>
                <ng-template let-zip pTemplate="item"><div class="zip-item"><div><b>{{zip.zip}}</b></div><div><small>{{zip.cityName}}</small></div></div></ng-template>
              </p-autoComplete>
              <label for="{{name}}}-recipient-street">{{ 'recipient_street' | shpLocalize }}</label>
            </span>
            </div> -->
          <em class="error" *ngIf="isSpecialOrder && isSpecialOrderCountrySelected && !isSpecialOrderAddressEditEnabled && isZipAndHouseNrCombinationInvalid">{{'error_cannot_find_combination_zip_house_nr' | shpLocalize }}</em>
        </div>
      </ng-template>
    </ng-container>

    <ng-container>
      <ng-template #houseNr let-span="span" let-required="required">
        <div class="ctl mdl-cell mdl-cell--{{span}}-col" [class.required]="!isReadOnly && required">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input
                [(ngModel)]="recipient.houseNr"
                [formControl]="addressHouseNr"
                [readonly]="isReadOnly"
                autocomplete="off"
                id="{{name}}-recipient-house-nr"
                name="{{name}}_recipient_house_nr"
                pInputText
                type="text">
              <label for="{{name}}-recipient-house-nr">{{ 'recipient_house_number' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </ng-template>
    </ng-container>

  <ng-container>
    <ng-template #streetDetail>
      <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="(useB2BAddressFieldsOnly || recipient.customerRecipientTypeCode == b2b) && (!isReadOnly || recipient.streetDetail)">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="{{name}}-recipient-street-detail" [(ngModel)]="recipient.streetDetail" type="text" name="{{name}}_recipient_street_detail" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly" autocomplete="off">
            <label for="{{name}}-recipient-street-detail">{{ 'recipient_street_detail' | shpLocalize }}</label>
          </span>
        </div>
      </div>
    </ng-template>
  </ng-container>

    <ng-container>
      <ng-template #country>
        <div class="ctl mdl-cell mdl-cell--8-col" [class.mdl-cell--12-col]="hideFieldsIrrelevantForSender" *ngIf="!isReadOnly">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-dropdown
                (ngModelChange)="onCounrySelect()"
                [(ngModel)]="recipient.country"
                [filter]="true"
                [formControl]="addressCountry"
                [options]="countries"
                [showClear]="true"
                filterBy="name"
                id="{{name}}-recipient-country"
                optionLabel="name">
                <ng-template let-country pTemplate="item"><div class="country-item"><div>{{country.name}}</div></div></ng-template>
              </p-dropdown>
              <label for="{{name}}-recipient-country">{{ 'recipient_country' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div *ngIf="isReadOnly && recipient.country"
          class="ctl mdl-cell"
          [class.mdl-cell--8-col]="recipient.languageCode"
          [class.mdl-cell--12-col]="!recipient.languageCode">
          <input type="text" [(ngModel)]="recipient.country.name" id="{{name}}-recipient_country" name="{{name}}_recipient_country" readonly="readonly" />
        </div>
      </ng-template>
    </ng-container>

    <ng-container>
     <ng-template #language>
      <div class="ctl mdl-cell mdl-cell--4-col" *ngIf="!hideFieldsIrrelevantForSender && !isReadOnly">
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select [(ngModel)]="recipient.languageCode" id="{{name}}-recipient-language-code" name="{{name}}_recipient_language_code">
              <option [ngValue]="''">-</option>
              <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
            </select>
            <label for="{{name}}-recipient-language-code">{{ 'language' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div *ngIf="isReadOnly && recipient.languageCode"
        class="ctl mdl-cell"
        [class.mdl-cell--4-col]="recipient.country"
        [class.mdl-cell--12-col]="!recipient.country">
        <input type="text" value="{{ ('language_' + recipient.languageCode) | shpLocalize }}  ({{ recipient.languageCode }})" readonly="readonly" id="{{name}}-recipient-language-code" name="{{name}}_recipient_language_code" />
      </div>
     </ng-template>
    </ng-container>


    <ng-container>
      <ng-template #zip let-span="span" let-required="required">
        <div class="ctl mdl-cell mdl-cell--{{span}}-col" *ngIf="isReadOnly">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="{{name}}-recipient-zip" [(ngModel)]="recipient.zip" type="text" name="{{name}}_recipient_zip" maxlength="14" pInputText [readonly]="true" autocomplete="off">
              <label for="{{name}}-recipient-zip">{{ 'recipient_zip' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl mdl-cell mdl-cell--{{span}}-col" [class.required]="!isReadOnly && required" *ngIf="!isReadOnly">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-autoComplete
                (completeMethod)="filterZip($event)"
                (onSelect)="onZipSelected($event)"
                [(ngModel)]="recipient.zip"
                [attr.name]="name + '_recipient_zip'"
                [formControl]="addressZip"
                [minLength]="1"
                [suggestions]="filteredZips"
                autocomplete="none"
                id="{{name}}-recipient-zip">
                <ng-template let-zip pTemplate="item"><div class="zip-item"><div><b>{{zip.zip}}</b></div><div><small>{{zip.cityName}}</small></div></div></ng-template>
              </p-autoComplete>
              <label for="{{name}}-recipient-zip">{{ 'recipient_zip' | shpLocalize }}</label>
            </span>
            <em class="error" *ngIf="addressZip.hasError('zip')">{{ 'error_zip_invalid' | shpLocalize }}</em>
            <em class="error" *ngIf="addressZip.hasError('zipFormat')">{{ 'error_zip_expected_format' | shpLocalize:addressZip.errors.zipFormat.expectedFormat }}</em>
            <em class="error" *ngIf="addressZip.hasError('noSpaces')">{{'address_zip_cannot_contain_spaces' | shpLocalize }}</em>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container>
      <ng-template #city let-span="span" let-required="required" let-readonly="readonly">
        <div class="ctl mdl-cell mdl-cell--{{span}}-col" *ngIf="isReadOnly">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input
                [(ngModel)]="recipient.city"
                [formControl]="addressCity"
                [readonly]="true"
                autocomplete="none"
                id="{{name}}-recipient-city-field"
                name="{{name}}_recipient_city"
                pInputText
                type="text">
              <label for="{{name}}-recipient-city-field">{{ 'recipient_city' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl mdl-cell mdl-cell--{{span}}-col" *ngIf="!isReadOnly" [class.required]="!isReadOnly && required">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-autoComplete
                (completeMethod)="filterCities($event)"
                (onSelect)="onCitySelected($event)"
                [(ngModel)]="recipient.city"
                [attr.name]="name + '_recipient_city'"
                [class.ignore-ng-invalid]="required && readonly"
                [formControl]="addressCity"
                [minLength]="2"
                [readonly]="readonly"
                [suggestions]="filteredCities"
                autocomplete="none"
                id="{{name}}-recipient-city">
                <ng-template let-city pTemplate="item"><div class="city-item"><div><b>{{city.cityNameLocal}}</b></div><div><small>{{city.beginPostCode}}{{city.endPostCode? " - " + city.endPostCode:""}}</small></div></div></ng-template>
              </p-autoComplete>
              <label for="{{name}}-recipient-city">{{ 'recipient_city' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #enableSpecialOrderAddressEdit>
      <div *ngIf="!isReadOnly" class="ctl mdl-cell mdl-cell--12-col">
        <input id="{{name}}-enable-special-order-address-edit" [(ngModel)]="isSpecialOrderAddressEditEnabled" [ngModelOptions]="{standalone: true}" (ngModelChange)="onIsSpecialOrderAddressEditEnabled()" type="checkbox">
        <label for="{{name}}-enable-special-order-address-edit">{{ 'label_enable_special_order_address_edit' | shpLocalize }}</label>
      </div>
    </ng-template>

    <ng-template #specialOrder>
      <ng-container *ngTemplateOutlet = "country"></ng-container>
      <ng-container *ngTemplateOutlet = "language"></ng-container>
      <ng-container *ngIf="isSpecialOrderCountrySelected">
        <ng-container *ngTemplateOutlet = "zip; context:{span:6, required:true}"></ng-container>
        <ng-container *ngTemplateOutlet = "houseNr; context:{span:6, required:true}"></ng-container>
        <ng-container *ngTemplateOutlet = "enableSpecialOrderAddressEdit"></ng-container>
        <ng-container *ngTemplateOutlet = "street; context:{span:6, readonly: !isSpecialOrderAddressEditEnabled, required:true}"></ng-container>
        <ng-container *ngTemplateOutlet = "city; context:{span:6, readonly: !isSpecialOrderAddressEditEnabled, required:true}"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isSpecialOrderCountrySelected">
        <ng-container *ngTemplateOutlet = "street; context:{span:8, required:true}"></ng-container>
        <ng-container *ngTemplateOutlet = "houseNr; context:{span:4}"></ng-container>
        <ng-container *ngTemplateOutlet = "streetDetail"></ng-container>
        <ng-container *ngTemplateOutlet = "zip; context:{span:5, required:true}"></ng-container>
        <ng-container *ngTemplateOutlet = "city; context:{span:7, required:true}"></ng-container>
      </ng-container>
    </ng-template>

    <ng-template #defaultOrder>
      <ng-container *ngTemplateOutlet = "street; context:{span:8, required:true}"></ng-container>
      <ng-container *ngTemplateOutlet = "houseNr; context:{span:4}"></ng-container>
      <ng-container *ngTemplateOutlet = "streetDetail"></ng-container>
      <ng-container *ngTemplateOutlet = "country"></ng-container>
      <ng-container *ngTemplateOutlet = "language"></ng-container>
      <ng-container *ngTemplateOutlet = "zip; context:{span:5, required:true}"></ng-container>
      <ng-container *ngTemplateOutlet = "city; context:{span:7, required:true}"></ng-container>
    </ng-template>


    <ng-container *ngIf="isSpecialOrder; then specialOrder else defaultOrder"></ng-container>



    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="recipient?.country?.isStateDeliveryRequired" [class.required]="!isReadOnly">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="{{name}}-recipient-state" [(ngModel)]="recipient.stateCode" maxlength="2" type="text" required name="{{name}}_recipient_state" [readonly]="isReadOnly" pInputText autocomplete="off">
          <label for="{{name}}-recipient-state">{{ 'recipient_state_code' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <ng-container *ngIf="isCustomsClearance && (isReceiverBusiness || showAllCustomsFields)">
      <div class="ctl mdl-cell mdl-cell--12-col" [class.required]="!isReadOnly && isEoriNumberRequired">
        <div *ngIf="!isReadOnly" class="p-field p-field--half">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="{{name}}-eori-country-code" name="{{name}}_eori_country_code" [(ngModel)]="eoriNumberCountryCode" (ngModelChange)="onEoriNumberChange()" [required]="isEoriNumberRequired">
              <option *ngFor="let country of countriesByCode" [value]="country.code">{{ country.code }}</option>
            </select>
            <label for="{{name}}-eori-country-code">{{ 'eori_country_code' | shpLocalize }}</label>
          </span>
        </div>
        <div class="p-field p-field--half" [class.p-field--full]="isReadOnly">
          <span class="p-float-label">
            <input *ngIf="!isReadOnly" type="text" [(ngModel)]="eoriNumberWithoutCountryCode" #eoriControl="ngModel" pInputText [required]="isEoriNumberRequired" (ngModelChange)="onEoriNumberChange()" shpValidateAlphanumeric maxlength="35" id="{{name}}-eori-number"  name="{{name}}_eori_number"/>
            <input *ngIf="isReadOnly" type="text" [(ngModel)]="recipient.eoriNumber" pInputText id="{{name}}-eori-number" [readonly]="true" name="{{name}}_reori_number" />
            <label for="{{name}}-eori-number">{{ (isPresentedAsSender ? 'sender_eori' : 'recipient_eori') | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="eoriControl?.errors?.alphaNumeric">
        <label></label>
        <em class="error">{{ 'error_eori_invalid_format' | shpLocalize }}</em>
      </div>

      <div class="ctl mdl-cell mdl-cell--12-col">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="{{name}}-recipient-vat-number" [(ngModel)]="recipient.vatNumber" #vatControl="ngModel" type="text" name="{{name}}_recipient_vat_number" pInputText [readonly]="isReadOnly" shpValidateAlphanumeric autocomplete="off">
            <label for="{{name}}-recipient-vat-number">{{ 'vat' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="vatControl.errors?.alphaNumeric">
        <label></label>
        <em class="error">{{ 'error_vat_invalid_format' | shpLocalize }}</em>
      </div>
    </ng-container>

    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="!hideFieldsIrrelevantForSender && !hideFieldsIrrelevantForPickup && (!isReadOnly || !!recipient.referenceNumber)">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="{{name}}-recipient-reference" [(ngModel)]="recipient.referenceNumber" type="text" name="{{name}}_recipient_reference" maxlength="{{ disableValidation ? 100 : 35 }}" pInputText [readonly]="isReadOnly">
          <label for="{{name}}-recipient-reference">{{ 'recipient_reference' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" [class.required]="isRecipientEmailRequired" *ngIf="!isReadOnly || !!recipient.email || isRecipientEmailRequired || isEmailOrPhoneRequired">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="{{name}}-recipient-email" [(ngModel)]="recipient.email" #recipienEmailCtrl="ngModel" type="text" name="{{name}}_recipient_email" [required]="isRecipientEmailRequired" shpValidateEmail [maxlength]="isCollectionRequest ? 40 : 100" pInputText [readonly]="isReadOnly">
          <label for="{{name}}-recipient-email">{{ 'recipient_email' | shpLocalize }}</label>
        </span>
        <em id="{{name}}-recipient-emai-help" class="p-error error" *ngIf="recipienEmailCtrl.touched && recipienEmailCtrl.errors?.invalidEmail">{{ 'invalid_email' | shpLocalize }}</em>
      </div>
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="isReadOnly && (!!recipient.phone || isRecipientPhoneRequired || isEmailOrPhoneRequired)">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <input id="{{name}}-recipient-phone" [(ngModel)]="recipient.phone" type="text" name="{{name}}_recipient_phone" [required]="isRecipientPhoneRequired" maxlength="{{ disableValidation ? 100 : 30 }}" pInputText [readonly]="isReadOnly">
          <label for="{{name}}-recipient-phone">{{ 'recipient_phone' | shpLocalize }}</label>
        </span>
      </div>
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" [class.required]="isBrexitCountryRecipient || isRecipientPhoneRequired" *ngIf="!isReadOnly">
      <shp-phone-number-input [(ngModel)]="recipient.phone" id="{{ name }}-recipient-phone" #phoneNumberCtrl="ngModel" #phoneNumberInput="phoneNumberInput" shpValidatePhone [countryCode]="selectedCountryCode" [phoneRegionCode]="phoneNumberInput.prefix" [required]="isBrexitCountryRecipient || isRecipientPhoneRequired" maxlength="{{ disableValidation ? 100 : 30 }}" [defaultCountryCode]="selectedCountryCode" name="{{name}}_recipient_phone"></shp-phone-number-input>
      <em class="error" *ngIf="phoneNumberCtrl.touched && phoneNumberCtrl.errors?.wrongNumber">{{ 'error_invalid_phone_for_selected_region' | shpLocalize }}</em>
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="isEmailOrPhoneRequired">
      <em class="error" *ngIf="recipientform.errors?.emailOrPhoneRequired">{{ 'error_email_or_phone_required' | shpLocalize }}</em>
    </div>

    <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="!hideFieldsIrrelevantForSender &&!isReadOnly || !!recipient.comment">
      <div class="p-field p-field--full">
        <span class="p-float-label">
          <textarea id="{{name}}-recipient-comment" [(ngModel)]="recipient.comment" name="{{name}}_recipient_comment" rows="5" pInputTextarea [readonly]="isReadOnly" maxlength="{{ disableValidation ? 100 : 70 }}"></textarea>
          <label for="{{name}}-recipient-comment">{{ 'recipient_comment' | shpLocalize }}</label>
        </span>
      </div>
    </div>

  </div>
</form>
<div *ngIf="isSaveAddressCheckboxDisplayed" wizardTag="saveRecipientToAddressBook">
  <div class="ctl">
    <input id="save-address-to-address-book-checkbox" type="checkbox" [(ngModel)]="isSaveAddressChecked" [disabled]="isReadOnly || null" wizardTag="saveRecipientToAddressBookCheckbox">
    <label for="save-address-to-address-book-checkbox">{{ 'save_address_to_address_book' | shpLocalize }}</label>
  </div>
</div>
