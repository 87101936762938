import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import * as _ from "lodash";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { ApiServiceBase, API_URL, BusinessUnitSettingsService, LoggingService, ShipperTenantVersionInfoService } from "../../shared";
import { ShipperTenantVersionInfoWithTexts } from "../../shared/models/shipper-tenant-version-info-with-texts.model";
import { AppContacts } from "../models/app-contacts.model";
import { ChangeLogItem } from "../models/change-log-item.model";

@Injectable()
export class SupportService extends ApiServiceBase {
  constructor(
      loggingService: LoggingService,
      private _http: HttpClient,
      private _buSettingsService: BusinessUnitSettingsService,
      private _shipperTenantVersionInfoService: ShipperTenantVersionInfoService) {
    super(loggingService);
  }

  loadContactInfo(): Observable<AppContacts> {
    return combineLatest([
      this._buSettingsService.getSetting("SupportContactName"),
      this._buSettingsService.getSetting("SupportContactEmail"),
      this._buSettingsService.getSetting("SupportContactPhone"),
      this._buSettingsService.getSetting("CustomerServiceName"),
      this._buSettingsService.getSetting("CustomerServiceEmail"),
      this._buSettingsService.getSetting("CustomerServicePhone"),
      this._buSettingsService.getSetting("SalesName"),
      this._buSettingsService.getSetting("SalesEmail"),
      this._buSettingsService.getSetting("SalesPhone")
    ]).pipe(
      map(([supportContactName, supportContactEmail, supportContactPhone, customerServiceName, customerServiceEmail, customerServicePhone, salesName, salesEmail, salesPhone]) => ({supportContactName, supportContactEmail, supportContactPhone, customerServiceName, customerServiceEmail, customerServicePhone, salesName, salesEmail, salesPhone}))
    );
  }

  loadChangeLog(): Observable<ChangeLogItem[]> {
    return this._shipperTenantVersionInfoService.canLoadChangeLog$.pipe(
      switchMap(() => this.processRequest<ShipperTenantVersionInfoWithTexts[]>(this._http.get(`${API_URL}/change-log`))),
      map(result => this.processShipperTenantVersionsInfo(result))
    );
  }

  private processShipperTenantVersionsInfo(versionsInfo: ShipperTenantVersionInfoWithTexts[]): ChangeLogItem[] {
    return versionsInfo.map(item => ({
      version: item.versionInfo.version,
      versionUri: item.versionInfo.version.replace(/\./g, "-"),
      releaseDate: item.versionInfo.releaseDate,
      content: this._shipperTenantVersionInfoService.getChangeLogContent(item.texts)
    }))
    .filter(item => Boolean(item.content))
    .sort((a, b) => this._shipperTenantVersionInfoService.compareVersions(b.version, a.version));
  }
}
