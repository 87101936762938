import {
  Component,
  EventEmitter,
  Host,
  Input,
  Optional,
  Output
} from "@angular/core";
import { FormArray, NgForm } from "@angular/forms";

import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";

import * as Shared from "../../shared/index";
import * as ShipmentsModel from "../models/shipment.model";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipment-editor-parcels",
  templateUrl: "./shipment-editor-parcels.component.html",
  host: {
    class: "shipment-editor__parcels"
  },
  exportAs: "shipmentEditorParcels"
})
export class ShipmentEditorParcelsComponent {
  @Input() areDimensionEditorsMandatory = true;
  @Input() countries: Shared.Country[];
  @Input() customsAmountCurrencyCode: string;
  @Input() hideDimensionEditors: boolean;
  @Input() isBrexitCountryRecipient = false;
  @Input() isCustomsClearance = false;
  @Input() isCustomsClearanceBoundFieldsRequiredValidationOn = false;
  @Input() isDpdPreciseProduct: boolean;
  @Input() isParcelLengthFirstDimension: boolean;
  @Input() isReadOnly = false;
  @Input() isSameDimensionsForAllParcels = false;
  @Input() minLimitDate = new Date();
  @Input() parcels = new BehaviorSubject<ShipmentsModel.OrderEditorParcel[]>([]);
  @Input() recipientCountryCode: string;
  @Input() selectedProduct = null;
  @Input() senderCountryCode = "";
  @Input() showGoodsFields = false;
  @Input() showLabels = false;
  @Input() tooltips: any;
  @Input() buCode: string;


  public array = new FormArray([]);


  @Output() parcelCustomsAmountChange = new EventEmitter<void>();
  @Output() parcelWeightChange = new EventEmitter<void>();


  constructor(@Optional() @Host() public form: NgForm) {
    if (form) {
      form.form.addControl("parcels", this.array);
    }
  }


  onParcelWeightChange() {
    this.parcelWeightChange.emit();
  }


  onParcelCustomsAmountChange() {
    this.parcelCustomsAmountChange.emit();
  }
}
