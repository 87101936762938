<div class="settings-category user-editor" cdkScrollable wizardScrollable>
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div>
    <h2 *ngIf="!isNewUser">{{ 'user' | shpLocalize }}</h2>
    <h2 *ngIf="isNewUser">{{ 'new_user' | shpLocalize }}</h2>

    <div wizardTag="userEditor">
      <div class="error" *ngIf="exceptionInfo != null">{{ exceptionInfo | shpExceptionInfo }}</div>
      <div class="error" *ngFor="let passwordValidationExceptionInfo of passwordValidationExceptionInfos">{{ passwordValidationExceptionInfo | shpExceptionInfo }}</div>
    </div>

    <form [formGroup]="userForm" autocomplete="off" novalidate wizardTag="userEditor">
      <div class="ctl required">
        <label for="user-name">{{ 'user_name' | shpLocalize }}</label>
        <input type="text" formControlName="name" id="user-name" name="userName" />
      </div>

      <div class="ctl required">
        <label for="user-login-name">{{ 'user_login_name' | shpLocalize }}</label>
        <input type="text" formControlName="loginName" [readonly]="isSelf || !isNewUser" id="user-login-name" name="userLoginName" />
        <span class="tenant-code">@{{ tenantCode }}</span>
      </div>

      <div class="ctl" *ngIf="isCustomerLevelTenant">
        <label for="customer-detail-id">{{ 'customer' | shpLocalize }}</label>
        <select formControlName="customerDetailId" name="customerDetailId" id="customer-detail-id">
          <option [ngValue]="customer.id" *ngFor="let customer of customers">{{customer.name}}</option>
        </select>
      </div>

      <!-- readonly master admin role -->
      <div *ngIf="isEditedUserMasterAdmin" class="ctl">
        <label for="user-is-admin">{{ 'user_is_master_admin' | shpLocalize }}</label>
        <input type="checkbox" [attr.checked]="isEditedUserMasterAdmin || null" disabled id="user-is-admin"/>
        <label for="user-is-admin"></label>
      </div>

      <!-- Shipper roles -->
      <ng-container *ngIf="isShipperRoleEditorDisplayed">
        <div *ngIf="canManageShipperUsers && !isSelf; else readonlyShipperRoles" class="ctl">
          <ng-container *ngIf="isInsightsDisplayed; else isAdminCheckbox">
            <label>{{ 'label_shipper_roles' | shpLocalize }}</label>

            <div class="radio-group flex-direction-column">
              <input
                type="radio"
                formControlName="shipperMainRole"
                shpDeselectable
                [canDeselect]="hasEditedUserInsightsAccess || canManageInsightsUsers"
                id="shipper-main-role-{{userRoles.TenantUser}}"
                [value]="userRoles.TenantUser"
                name="shipperMainRole"/><label class="radio-label w-auto" for="shipper-main-role-{{userRoles.TenantUser}}">{{ ('role_' + userRoles.TenantUser) | shpLocalize }}</label>
              <input
                type="radio"
                formControlName="shipperMainRole"
                shpDeselectable
                [canDeselect]="hasEditedUserInsightsAccess || canManageInsightsUsers"
                id="shipper-main-role-{{userRoles.TenantAdmin}}"
                [value]="userRoles.TenantAdmin"
                name="shipperMainRole"/><label class="radio-label w-auto" for="shipper-main-role-{{userRoles.TenantAdmin}}">{{ ('role_' + userRoles.TenantAdmin) | shpLocalize }}</label>
            </div>
          </ng-container>

          <ng-template #isAdminCheckbox>
            <label for="user_is_admin">{{ 'user_is_admin' | shpLocalize }}</label>
            <input type="checkbox"
              formControlName="shipperMainRole"
              shpSetCheckboxValues
              [trueValue]="userRoles.TenantAdmin"
              [falseValue]="userRoles.TenantUser"
              id="user-is-admin"
              name="shipperMainRole" />
            <label class="user-is-admin" for="user-is-admin"></label>
          </ng-template>
        </div>
      </ng-container>

      <!-- customer tenant roles -->
      <ng-container *ngIf="isCustomerRoleEditorDisplayed">
        <div *ngIf="canManageCustomerUsers && !isSelf; else readonlyIsCustomerAdmin" class="ctl">
          <label for="user-is-customer-admin">{{ 'user_is_customer_admin' | shpLocalize }}</label>
          <input type="checkbox"
            formControlName="customerMainRole"
            shpSetCheckboxValues
            [trueValue]="userRoles.CustomerAdmin"
            [falseValue]="userRoles.CustomerUser"
            id="user-is-customer-admin"
            name="customerMainRole" />
          <label class="is-customer-admin-label" for="user-is-customer-admin"></label>
        </div>
      </ng-container>

      <!-- Insights roles -->
      <ng-container *ngIf="isInsightsDisplayed">
        <div *ngIf="canManageInsightsUsers && !isSelf; else readonlyInsightsRoleList" class="ctl">
          <label>{{ 'label_insights_roles' | shpLocalize }}</label>
          <div class="d-flex flex-direction-column">
            <div class="radio-group">
              <input
                type="radio"
                formControlName="insightsMainRole"
                shpDeselectable
                id="insights-main-role-{{userRoles.InsightsUser}}"
                [value]="userRoles.InsightsUser"
                name="insightsMainRole"/><label class="radio-label w-auto" for="insights-main-role-{{userRoles.InsightsUser}}">{{ ('role_' + userRoles.InsightsUser) | shpLocalize }}</label>
            </div>

            <div *ngIf="isEditedUserInsightsUser" class="ml-4">
              <p-listbox
                id="insights-additional-roles"
                formControlName="insightsRoles"
                name="insightsRoles"
                [showToggleAll]="false"
                [options]="insightsRoleOptions"
                [checkbox]="true"
                [multiple]="true">
                <ng-template let-role let-i="index" pTemplate="item">
                  {{ role.label | shpLocalize }}
                </ng-template>
              </p-listbox>
            </div>

            <div class="radio-group">
              <input
                type="radio"
                formControlName="insightsMainRole"
                shpDeselectable
                id="insights-main-role-{{userRoles.InsightsAdmin}}"
                [value]="userRoles.InsightsAdmin"
                name="insightsMainRole"/><label class="radio-label w-auto" for="insights-main-role-{{userRoles.InsightsAdmin}}">{{ ('role_' + userRoles.InsightsAdmin) | shpLocalize }}</label>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="ctl" *ngIf="userForm.errors?.atLeastOne">
        <em class="error">{{ 'message_form_required_role' | shpLocalize }}</em>
      </div>

      <div class="ctl">
        <label for="user-is-disabled">{{ 'user_is_disabled' | shpLocalize }}</label>
        <input type="checkbox" formControlName="isDisabled" id="user-is-disabled" name="userIsDisabled" />
        <label for="user-is-disabled"></label>
      </div>

      <div class="ctl required">
        <label for="user-email">{{ 'user_email' | shpLocalize }}</label>
        <input type="text" formControlName="email" id="user-email" name="userEmail"/>
      </div>

      <div class="ctl" *ngIf="userForm.get('email')?.errors?.invalidEmail">
        <em class="error">{{ 'invalid_email' | shpLocalize }}</em>
      </div>

      <ng-container *ngIf="isNewUser">
        <div class="ctl required">
          <label for="user-password">{{ 'user_password' | shpLocalize }}</label>
          <input type="password" formControlName="password" id="user-password" name="userPassword"/>
        </div>
        <div class="ctl required">
          <label for="user-password-confirm">{{ 'user_password_confirm' | shpLocalize }}</label>
          <input type="password" formControlName="passwordConfirmation" id="user-password-confirm" name="userPasswordConfirm"/>
        </div>
        <div class="ctl" *ngIf="userForm.get('passwordConfirmation')?.errors?.matches">
          <em class="error">{{ 'password_mismatch' | shpLocalize }}</em>
        </div>
      </ng-container>

      <div class="ctl buttons">
        <label>&nbsp;</label>
        <button id="button-save" [disabled]="userForm.invalid" (click)="saveUser()"><span class="ic-floppydisk"></span> {{ 'save' | shpLocalize }}</button>
        <button id="button-cancel" (click)="goBackToListOfUsers()" type="button">{{ 'cancel' | shpLocalize }}</button>
      </div>
    </form>

    <div *ngIf="!isNewUser || isUserSettingsEditorDisplayedForWizard" wizardTag="userEditorSettings">
      <div>
        <h3>{{ 'change_password' | shpLocalize }}</h3>
        <form [formGroup]="passwordForm" autocomplete="off" novalidate>
          <div class="ctl">
            <label for="user-password">{{ 'user_password' | shpLocalize }}</label>
            <input type="password" formControlName="password" id="user-password" name="userPassword"/>
          </div>

          <div class="ctl">
            <label for="user-password-confirm">{{ 'user_password_confirm' | shpLocalize }}</label>
            <input type="password" formControlName="passwordConfirmation" id="user-password-confirm" name="userPasswordConfirm"/>
          </div>

          <div class="ctl" *ngIf="passwordForm.get('passwordConfirmation')?.errors?.matches">
            <em class="error">{{ 'password_mismatch' | shpLocalize }}</em>
          </div>

          <div class="ctl buttons">
            <label>&nbsp;</label>
            <button id="button-change-password" (click)="savePassword()" [disabled]="passwordForm.invalid || passwordForm.disabled"><span class="ic-lock-1"></span> {{ 'change_password' | shpLocalize }}</button>
          </div>
        </form>
      </div>

      <ng-container *ngIf="isRelatedItemsSectionDisplayed">
        <div *ngIf="isUserRelatedAddressesDisplayed">
          <h3>{{ 'user_access_control_address_items_title' | shpLocalize }}</h3>
          <shp-user-related-address-item-list-editor [userId]="user?.id"></shp-user-related-address-item-list-editor>
        </div>

        <div *ngIf="isUserRelatedBandAccoutnsDisplayed">
          <h3>{{ 'user_access_control_bank_account_items_title' | shpLocalize }}</h3>
          <shp-user-related-bank-account-item-list-editor [userId]="user?.id"></shp-user-related-bank-account-item-list-editor>
        </div>

        <div *ngIf="isUserRelatedProductsDisplayed">
          <h3 editor-title>{{ 'user_access_control_allowed_products_title' | shpLocalize }}</h3>
          <shp-user-related-product-item-list-editor [userId]="user?.id"></shp-user-related-product-item-list-editor>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #readonlyIsMasterAdmin>
  <div class="ctl">
    <label for="user-is-admin">{{ 'user_is_master_admin' | shpLocalize }}</label>
    <input type="checkbox" [attr.checked]="isEditedUserMasterAdmin || null" disabled id="user-is-admin"/>
    <label for="user-is-admin"></label>
  </div>
</ng-template>

<ng-template #readonlyShipperRoles>
  <div *ngIf="!isInsightsDisplayed; else readonlyShipperRolesList" class="ctl">
    <label for="user-is-admin">{{ 'user_is_admin' | shpLocalize }}</label>
    <input type="checkbox" [attr.checked]="isEditedUserShipperAdmin || null" disabled id="user-is-admin"/>
    <label for="user-is-admin"></label>
  </div>
</ng-template>

<ng-template #readonlyShipperRolesList>
  <div class="ctl">
    <label class="align-self-baseline">{{ 'label_shipper_roles' | shpLocalize }}</label>
    <ul *ngIf="userShipperDisplayRoles.length" class="ctl-list ">
      <li *ngFor="let role of userShipperDisplayRoles">{{ ('role_' + role) | shpLocalize }}</li>
    </ul>
    <label *ngIf="!userShipperDisplayRoles.length" class="w-auto">{{ 'no_user_roles_selected' | shpLocalize }}</label>
  </div>
</ng-template>

<ng-template #readonlyIsCustomerAdmin>
  <div class="ctl">
    <label for="user-is-customer-admin">{{ 'user_is_customer_admin' | shpLocalize }}</label>
    <input type="checkbox" [attr.checked]="isEditedUserCustomerAdmin || null" disabled id="user-is-customer-admin"/>
    <label for="user-is-customer-admin"></label>
  </div>
</ng-template>

<ng-template #readonlyInsightsRoleList>
  <div class="ctl">
    <label class="align-self-baseline">{{ 'label_insights_roles' | shpLocalize }}</label>
    <ul *ngIf="userInsightsDisplayRoles.length" class="ctl-list ">
      <li *ngFor="let role of userInsightsDisplayRoles">{{ ('role_' + role) | shpLocalize }}</li>
    </ul>
    <label *ngIf="!userInsightsDisplayRoles.length" class="w-auto">{{ 'no_user_roles_selected' | shpLocalize }}</label>
  </div>
</ng-template>
