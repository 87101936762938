import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { PrimengDropdownDirective } from './directives/primeng-dropdown.directive';

@NgModule({
  declarations: [
    PrimengDropdownDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PrimengDropdownDirective
  ]
})
export class CustomPrimengModule { }
