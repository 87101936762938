<div class="top">
  <h1>{{ 'recipients' | shpLocalize }}</h1>
  <button type="button" [disabled]="isCompactViewSelected" id="switch-to-compact-view" (click)="isCompactViewSelected = true" class="top-button"><span class="ic-content-view-headline"></span><span class="label" [innerHtml]="'recipients_list' | shpLocalize"></span></button>
  <button type="button" [disabled]="!isCompactViewSelected" id="switch-to-grid-view" (click)="isCompactViewSelected = false" class="top-button"><span class="ic-grid"></span><span class="label" [innerHtml]="'recipients_grid' | shpLocalize"></span></button>
  <button class="top-button" (click)="addRecipient()" id="add-recipient" wizardTag="addNewRecipient" [wizardCompleteStep]="wizardStepNames.ClickAddRecipient"><span class="ic-add-2"></span><span class="label">{{ 'recipient_add' | shpLocalize }}</span></button>
  <button class="top-button" (click)="openImportRecipientsDialog()" id="show-import-recipients" wizardTag="importRecipients" [wizardCompleteStep]="wizardStepNames.ClickImportRecipients"><span class="ic-data-download-8"></span><span class="label">{{ 'recipients_import' | shpLocalize }}</span></button>
  <button class="top-button" (click)="exportAllRecipients()" id="show-export-recipients"><span class="ic-data-upload-8"></span><span class="label">{{ 'recipients_export_all' | shpLocalize }}</span></button>
  <button class="top-button" type="button" (click)="showConfirmDeleteAllRecipientsDialog()" id="show-confirm-delete-all-recipients-dialog"><span class="ic-bin"></span><span class="label" [innerHtml]="'delete_all_recipients' | shpLocalize"></span></button>
  <button class="top-button" *shpPollyDialogTrigger="pollyCollection" id="recipients-faq"><span class="ic-interface-question-mark"></span><span class="label">{{ 'action_open_faq_dialog' | shpLocalize }}</span></button>
</div>

<div *ngIf="isCompactViewSelected">
  <shp-recipients-compact
        [isBusy]="isBusy"
        [canUndelete]="isImpersonatingShipperAdminUser"
        ></shp-recipients-compact>
</div>
<div *ngIf="!isCompactViewSelected">
  <shp-recipients-grid
        [isBusy]="isBusy"
        [canUndelete]="isImpersonatingShipperAdminUser"
        ></shp-recipients-grid>
</div>