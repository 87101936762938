<div class="top">
  <h1>{{ 'shipments_archive' | shpLocalize }}</h1>
</div>

<div class="content-panel">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="grid">
    <div class="grid-header" #header>
      <table>
        <thead>
          <tr>
            <th *ngFor="let column of columns" [style.width.px]="column.width">{{ column.field | shpLocalize }}</th>
          </tr>
          <tr class="filters" [formGroup]="columnsFilter">
            <th *ngFor="let column of columns" [ngClass]="{ 'col-commands': column.type === 'clear', 'col-action': column.type === 'clear' || column.type === 'action' }">

              <div *ngIf="column.filter" class="filter">
                <ng-container *ngFor="let filter of filters">
                  <div *ngIf="filter.columnId === column.id" class="ctl">

                    <div *ngIf="filter.type === 'string'" class="p-field p-field--full">
                      <span class="p-float-label">
                        <input [formControlName]="filter.id" [inputId]="filter.id" type="text" [name]="filter.id" pInputText autocomplete="off" (input)="onFiltersChanged()">
                        <label [attr.for]="filter.id">{{ filter.field | shpLocalize }}</label>
                      </span>
                    </div>                   

                    <span *ngIf="filter.type === 'date'" class="p-float-label">
                      <p-calendar [formControlName]="filter.id" [showIcon]="true" appendTo="body" [inputId]="filter.id" [monthNavigator]="false" [yearNavigator]="false" yearRange="2010:{{currentYear}}" dateFormat="dd.mm.yy" icon="ic-calendar-1" (onBlur)="onFiltersChanged($event)"></p-calendar>
                      <label [attr.for]="filter.id">{{ filter.field | shpLocalize }}</label>
                    </span>

                  </div>
                </ng-container>
              </div>

              <button *ngIf="column.type === 'clear'" type="button" name="clearFilter" (click)="clearColumnsFilter()" title="{{ 'clear_filter' | shpLocalize}}"><span class="ic-close"></span></button>
              <button *ngIf="column.type === 'action'" type="button" name="clearFilter" (click)="exportToCSV()" title="{{ 'export_csv' | shpLocalize}}"><span class="ic-data-upload-8"></span></button>

            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="scrollbar-spacer-cover"></div>
    <div class="grid-content" (scroll)="scrollGridContent($event)">

      <div class="list-empty-message" *ngIf="loadingFailed">
        <div class="message-content message-content-center d-flex flex-direction-column align-items-center">
          <div>{{ 'load_shipments_failed' | shpLocalize }}</div>
          <button type="button" class="reload-button" (click)="reloadShipments()"><span class="ic-synchronize-1"></span> {{ 'reload' | shpLocalize }}</button>
        </div>
      </div>

      <div class="list-empty-message" *ngIf="noShippments">
        <div class="message-content message-content-center d-flex flex-direction-column align-items-center">
          <div>{{ 'message_no_shipment_orders_in_db' | shpLocalize }}</div>
          <button type="button" class="reload-button" (click)="reloadShipments()"><span class="ic-synchronize-1"></span> {{ 'reload' | shpLocalize }}</button>
        </div>
      </div>

      <div class="list-empty-message" *ngIf="!isBusy && noMatchingFilter">
        <div class="message-content">
          <div>{{ 'message_no_shipment_orders_matching_filter' | shpLocalize }}</div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th *ngFor="let column of columns" [style.width.px]="column.width">{{ column.field | shpLocalize }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let shipment of shipments">
            <td class="col-clear"></td>
            <td class="col-custom-id"><span *ngFor="let parcelNumber of shipment.parcelNumbers">{{ parcelNumber }}</span></td>
            <td class="col-delis-id">
              {{ shipment.receiverName }}<br/>
              {{ shipment.receiverStreet }}<br/>
              {{ shipment.receiverZip }} {{ shipment.receiverCity }}<br/>
              {{ shipment.receiverCountryName }}
            </td>
            <td class="col-datetime">{{ shipment.creationDateTimeUtc | shpLocalizeDate }}</td>
            <td class="col-spacer"></td>
          </tr>
          <tr *ngIf="loadingRows" class="tr--loading">
            <td [attr.colspan]="columns.length">
              <div class="inline-progress-overlay-container">
                <shp-progress-overlay [isActive]="true"></shp-progress-overlay>
              </div>
            </td>
          </tr>
          <tr *ngIf="loadingRowsFailed" class="tr--loading">
            <td [attr.colspan]="columns.length">
              <p>{{ 'load_shipments_failed' | shpLocalize }}</p>
              <button type="button" class="reload-button" (click)="reloadShipments()"><span class="ic-synchronize-1"></span> {{ 'retry' | shpLocalize }}</button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="grid-footer">
      <div class="grid-footer-actions">
        <button type="button" (click)="purgeShipments()" *ngIf="!isCentral">{{ 'purge_the_archive' | shpLocalize }}</button>
      </div>
      <div class="grid-infinite">
        <button class="first" type="button" (click)="loadShipments()"><span class="ic-navigation-show-more-horizontal"></span></button>
      </div>
    </div>
  </div>
</div>
