import { AfterViewInit, Directive, ElementRef, HostBinding, OnDestroy, Optional } from "@angular/core";
import { AbstractControl, ControlContainer, NgControl } from "@angular/forms";

import { fromEvent, Subscription } from "rxjs";
import { startWith } from "rxjs/operators";

import { isEmptyInputValue } from "../form-helpers";

@Directive({
  selector: "[dpdFloatingLabel]"
})
export class FloatingLabelDirective implements AfterViewInit, OnDestroy {

  @HostBinding("class.p-input-filled")
  get hasFilledClass() {
    return this.isFilled || this.isFocused;
  }

  private ctrlSub: Subscription | null = null;
  private isFilled = false;
  private isFocused = false;
  private mutationObserver: MutationObserver | null = null;
  private select: HTMLSelectElement;

  constructor(private hostEl: ElementRef) { }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.select = this.hostEl.nativeElement.querySelector("select");

      if (this.select) {
        // Observe value changes.
        this.ctrlSub = fromEvent(this.select, "change").pipe(
          startWith(0),
        ).subscribe(() => this.setSelectIsFilled());

        // Observe options (elements) changes.
        this.mutationObserver = new MutationObserver(mutationList => {
          const hasChanges = mutationList.some(m => m.type === "childList");

          if (hasChanges) {
            this.setSelectIsFilled();
          }
        });

        this.mutationObserver.observe(this.select, {
          childList: true,
          attributes: false
        });
      }
    });
  }

  ngOnDestroy() {
    this.unregisterControl();

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  registerControl(ctrl: AbstractControl, el: HTMLElement) {
    if (this.ctrlSub) {
      return;
    }

    this.ctrlSub = new Subscription();

    this.ctrlSub.add(
      ctrl.valueChanges.pipe(
        startWith(ctrl.value)
      ).subscribe(v => this.setControlIsFilled(v))
    );

    const focusableChild = el.querySelector("input"); // TODO: other elements.

    if (focusableChild) {
      this.ctrlSub.add(
        fromEvent(focusableChild, "focus").subscribe(() => this.isFocused = true)
      );

      this.ctrlSub.add(
        fromEvent(focusableChild, "blur").subscribe(() => this.isFocused = false)
      );
    }
  }

  unregisterControl() {
    if (this.ctrlSub) {
      this.ctrlSub.unsubscribe();
      this.ctrlSub = null;
    }
  }

  setControlIsFilled(value: any) {
    this.isFilled = !isEmptyInputValue(value);
  }

  setSelectIsFilled() {
    const selected = this.select.selectedOptions;

    this.isFilled = selected?.length && (Boolean(selected[0].value) || selected[0].text !== "");
  }
}

/**
 * Directive to connect control to FloatingLabelDirective.
 */
@Directive({
  selector: `[dpdFloatingLabelControl][ngModel], [dpdFloatingLabelControl][formControl], [dpdFloatingLabelControl][formControlName]`
})
export class FloatingLabelControlDirective implements AfterViewInit, OnDestroy {
  constructor(
      @Optional() private floatingLabel: FloatingLabelDirective,
      @Optional() private ngControl: NgControl,
      @Optional() private container: ControlContainer,
      public host: ElementRef,
  ) { }

  ngAfterViewInit() {
    if (this.floatingLabel) {
      Promise.resolve().then(() => {
        const controlDirective: NgControl | ControlContainer = this.ngControl || this.container;
        const control = controlDirective.control;

        if (control) {
          this.floatingLabel.registerControl(control, this.host.nativeElement);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.floatingLabel) {
      this.floatingLabel.unregisterControl();
    }
  }
}
