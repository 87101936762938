import { Component, OnInit, OnDestroy } from "@angular/core";

import { Router } from "@angular/router";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import * as Globals from "../../../shared/globals";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-info-settings",
  templateUrl: "./info-settings.component.html"
})
export class InfoSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    toastr: ToastrService,
    private _shipperSettingsService: Shared.ShipperSettingsService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      _settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }

  public isCentralShipper = true;

  public ngOnInit(): void {
    super.ngOnInit();

    this.isCentralShipper = this._shipperSettingsService.isCentralShipper;

    this._settingsService.getInstallationId()
      .subscribe(
      (result: number) => {
        this.installationId = result;
      });
  }


  public appVersion = Globals.APP_VERSION;


  public installationId = 0;


  public ngOnDestroy(): void { super.ngOnDestroy(); }
}