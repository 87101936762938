import { Injectable } from "@angular/core";
import { forkJoin, Observable, throwError } from "rxjs";

import * as _ from "lodash";
import * as Shared from "../index";
import * as xlsx from "xlsx";
import { catchError, map } from "rxjs/operators";

export enum READ_METHOD {
  ARRAY_BUFFER = "readAsArrayBuffer",
  BINARY_STRING = "readAsBinaryString",
  DATA_URL = "readAsDataURL",
  TEXT = "readAsText"
}

// settup determiner of columns to ';' , default is ','
// https://github.com/SheetJS/js-xlsx/blob/d7ecca0e8b3dc04183fca309e5e3d87f6c4f8197/bits/90_utils.js#L137
export const DEFAULT_CONCVERT_TO_CSV_OPS = {
  FS: ';',
  RS: '\r\n'
}

const FILE_TYPE_CSV = "csv";


export interface FileData {
  file: File;
  content: any;
  extension?: string;
}


@Injectable()
export class ShipperFileReaderService {

  constructor(
    private _localizationService: Shared.LocalizationService,
    private _businessSettingsService: Shared.BusinessUnitSettingsService) { }


    readShipmentImportFile(file: File, encoding?: string): Observable<FileData> {
      const result = forkJoin([
        this.readFileAsCsvString(file, encoding),
        this._businessSettingsService.getCentralShipperShipmentsImportFileSizeLimit(),
        this._businessSettingsService.getCentralShipperShipmentsImportFileLinesLimit(),
      ]).pipe(
        map((result: [Shared.FileData, number, number]) => this.validateCsvString(result)),
        catchError(err => {
          return throwError(err);
        })
      )
  
      return result;
    }
  
  
    readRecipientImportFile(file: File, encoding?: string): Observable<FileData> {
      const result = forkJoin([
        this.readFileAsCsvString(file, encoding),
        this._businessSettingsService.getCentralShipperRecipientsImportFileSizeLimit(),
        this._businessSettingsService.getCentralShipperRecipientsImportFileLinesLimit(),
      ]).pipe(
        map((result: [Shared.FileData, number, number]) => this.validateCsvString(result)),
        catchError(err => {
          return throwError(err);
        })
      )
  
      return result;
    }


  getErrorDescription(key: string, data: any): string {
    let params = [];

    if (_.isPlainObject(data)) {
      params = _.values(data);
    }

    return this._localizationService.getLocalizedString(key, ...params);
  }


  readAsText(file: File, encoding?: string): Observable<FileData> {
    return this.readFile(file, READ_METHOD.TEXT, encoding);
  }


  readAsBinaryString(file: File): Observable<FileData> {
    return this.readFile(file, READ_METHOD.BINARY_STRING);
  }


  private readFile(file: File, readMethod: READ_METHOD = READ_METHOD.TEXT, encoding?: string): Observable<FileData> {
    const ob = new Observable<FileData>((observer) => {
      const fileReader = new FileReader();

      if (!fileReader) {
        observer.error(
          this._localizationService.getLocalizedString("error_description_unable_to_read_file"));
        return () => { };
      }

      fileReader.onloadend = () => {
        const content = fileReader.result;

        if (content) {
          observer.next({
            file,
            content
          });

        } else {
          observer.error(
            this._localizationService.getLocalizedString("error_description_unable_to_process_file"));
        }

        observer.complete();
      }

      fileReader.onerror = (err) => {
        observer.error(err);
      }

      switch (readMethod) {
        case READ_METHOD.ARRAY_BUFFER:
          fileReader.readAsArrayBuffer(file);
          break;
        case READ_METHOD.BINARY_STRING:
          fileReader.readAsBinaryString(file);
          break;
        case READ_METHOD.DATA_URL:
          fileReader.readAsDataURL(file);
          break;
        case READ_METHOD.TEXT:
        default:
          fileReader.readAsText(file, encoding);
          break;
      }

      return () => fileReader.abort();
    });

    return ob;
  }


  readFileAsCsvString(file: File, encoding?: string): Observable<FileData> {
    const normalizedFileName = file.name.toLocaleLowerCase();
    const isCsv = !(normalizedFileName.endsWith(".xls") || normalizedFileName.endsWith(".xlsx")); // Hocičo, čo nie je xls(x) je CSV.

    let result;

    if (isCsv) {
      result = this.readAsText(file, encoding).pipe(
        map(fileData => {
          return {
            ...fileData,
            extension: FILE_TYPE_CSV
          };
        })
      )
    } else {
      result = this.readAsBinaryString(file).pipe(
        map(data => {
          try {
            // 1. step parse content as xlsx, xlx, etc
            // by https://github.com/SheetJS/js-xlsx
            const workbook = xlsx.read(data.content, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            // Get worksheet
            const worksheet = workbook.Sheets[firstSheetName];
            // 2. step sheet data convert to csv string
            const csvFileContent = xlsx.utils.sheet_to_csv(worksheet, DEFAULT_CONCVERT_TO_CSV_OPS);

            const fileNameParts = normalizedFileName.split(".");

            data.extension = fileNameParts[fileNameParts.length - 1];
            data.content = csvFileContent;

            return data
          }
          catch (ex) {
            throw this._localizationService.getLocalizedString("error_description_unable_to_parse_xlsx_file");
          }
        })
      )
    }

    return result;
  }


  private getStringContentLineCount(content: string): number {
    return content.match(/.+$/gm).length;
  }


  private validateCsvString([fileData, maxSize, maxLines]: [FileData, number, number]) {
    const file = fileData.file;
    const content = fileData.content;
    const lineCount = this.getStringContentLineCount(content);
    const charactersCount = content.length;

    let errors = new Map<string, any>();

    if (maxSize && charactersCount > maxSize * 1000) {
      errors.set("error_description_generic_file_max_size", { max: maxSize * 1000, actual: charactersCount });
    }

    if (maxLines && lineCount > maxLines) {
      errors.set("error_description_generic_file_max_lines", { max: maxLines, actual: lineCount });
    }

    if (!errors.size) {
      return fileData;
    } else {
      throw {
        errors: this.getErrorDescriptions(errors),
        fileData
      };
    }
  }


  private getErrorDescriptions(errors: Map<string, any>): string[] {
    const errorDescriptions = [];

    errors.forEach((value, key) => {
      errorDescriptions.push(this.getErrorDescription(key, value));
    });

    return errorDescriptions;
  }
}
