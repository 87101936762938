import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import * as _ from "lodash";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { animationFrameScheduler, BehaviorSubject, combineLatest, merge, Observable, of, race, ReplaySubject, scheduled, throwError, timer } from "rxjs";
import { auditTime, catchError, delay, delayWhen, distinctUntilChanged, filter, finalize, map, mapTo, mergeMap, skip, skipWhile, switchMap, switchMapTo, take, takeUntil, tap } from "rxjs/operators";

import { API_URL } from "../api-url";
import { NewsfeedMessageDialogComponent } from "../components/newsfeed-message-dialog.component";
import { NewsfeedToast } from "../components/newsfeed-toast.component";
import { hasModelChanges } from "../form-helpers";
import { AppNewsfeedMessage } from "../models/app-newsfeed-message.model";
import { NewsfeedMessageForUser } from "../models/newsfeed-message-for-user.model";
import { User } from "../models/user.model";
import { encodeAsQueryString } from "../uri-helper";
import { ApiServiceBase } from "./api-service-base";
import { AuthenticationService } from "./authentication.service";
import { ContextService } from "./context.service";
import { GlobalEventsStreamService, UserLoginEvent } from "./global-events-stream.service";
import { LocalizationService } from "./localization.service";
import { LoggingService } from "./logging.service";
import { ShipperSettingsService } from "./shipper-settings.service";

export enum NewsfeedOperations {
  List,
  UnseenList,
  UserChange
}

export interface NewsfeedParameters {
  businessUnitCode: string;
  depotCodes: string[];
}

export interface UnseenMessagesResponse {
  messages: NewsfeedMessageForUser[];
  unreadCount: number;
}

const CHECK_INTERVAL_IN_SECONDS_FOR_OFFLINE_SHIPPER = 900000; // (15min -> 15 * 60 * 1000)
const CHECK_INTERVAL_IN_SECONDS_FOR_CENTRAL_SHIPPER = 900000; // (15min -> 15 * 60 * 1000)

const toastsConfing: Partial<IndividualConfig> = {
  enableHtml: true,
  closeButton: false,
  timeOut: 0,
  extendedTimeOut: 0,
  tapToDismiss: false,
  positionClass: "toast-center-center",
  toastComponent: NewsfeedToast
};

const emptyResponse: UnseenMessagesResponse = {
  messages: [],
  unreadCount: 0
};

@Injectable()
export class NewsfeedService extends ApiServiceBase {
  private _renderer: Renderer2;

  private _activeToasts: Map<string, ActiveToast<NewsfeedToast>> = new Map();
  private _currentMessagesUserId: number = null;
  private _isMessageListLoaded = false;
  private _isPaused = false;
  private _processedUnseenMessagesPerUser: Map<number, Set<string>> = new Map();
  private _canDisplayPopup = true;

  private _activeOperations$ = new BehaviorSubject<Set<NewsfeedOperations>>(new Set<NewsfeedOperations>());
  private _messages$ = new BehaviorSubject<AppNewsfeedMessage[]>([]);
  private _parameters$ = new ReplaySubject<NewsfeedParameters>(1);
  private _unreadCount$ = new BehaviorSubject<number>(null);

  constructor(
    private _domSanitizer: DomSanitizer,
    private _http: HttpClient,
    private _toastr: ToastrService,
    private _modal: Modal,
    private _localizationService: LocalizationService,
    private _authenticationService: AuthenticationService,
    private _contextService: ContextService,
    private _shipperSettingsService: ShipperSettingsService,
    private _globalEventsStreamService: GlobalEventsStreamService,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
    loggingService: LoggingService,
  ) {
    super(loggingService);
    this._renderer = rendererFactory.createRenderer(null, null);
    this.observeUser();
    this.initParameters();
    this.initUnseenMessagesStream();
  }


  get messages$(): Observable<AppNewsfeedMessage[]> {
    return this._messages$.pipe(tap(() => this.loadList()));
  }


  get unreadCount$(): Observable<number> {
    return this._unreadCount$.pipe(distinctUntilChanged());
  }


  get activeOperations$(): Observable<Set<NewsfeedOperations>> {
    return  this._activeOperations$.asObservable();
  }


  private get parameters$(): Observable<NewsfeedParameters> {
    return this._parameters$.asObservable();
  }


  private get requestBuster$(): Observable<any> {
    return merge(
      this._authenticationService.user.pipe(skip(1)),
      this.parameters$.pipe(skip(1)),
    );
  }


  private get processedMessages(): Set<string> {
    return this._processedUnseenMessagesPerUser.get(this._currentMessagesUserId);
  }


  pause() {
    this._isPaused = true;
  }


  start() {
    this._isPaused = false;
  }


  /** Sends request (on user's action) to mark the message as read. */
  markMessagesAsRead(messageIds: string[]) {
    if (!messageIds.length) {
      return;
    }

    /**
     * On the off chance that other user logged in while
     * request was active check user's ID in response callback.
     */
    const sendForUserId = this._currentMessagesUserId;

    /**
     * Using `delayWhen` operator for better unread count synchronization.
     * Sent mark as read requests only when unseen list request is not pending.
     */
    of(0).pipe(
      delayWhen(() =>
        this._activeOperations$.getValue().has(NewsfeedOperations.UnseenList) ?
          this._activeOperations$.pipe(filter(o => !o.has(NewsfeedOperations.UnseenList))) :
          of(0)
      ),
      mergeMap(() => this.processRequest(this._http.put(`${API_URL}/newsfeed/mark-as-read`, messageIds)))
    ).subscribe(() => {
      if (sendForUserId === this._currentMessagesUserId) {
        this.markLoadedMessagesAsRead(messageIds);
      }
    }, () => { });
  }


  markAllAsRead() {
    this._messages$.pipe(
      take(1),
      map(messages => messages
        .filter(m => !m.isRead)
        .map(m => m.messageId)
      ),
    ).subscribe(messageIds => this.markMessagesAsRead(messageIds));
  }


  clearToasts() {
    this._activeToasts.forEach(t => this._toastr.remove(t.toastId));
    this._activeToasts.clear();
  }


  public getZipWarnings(countryCode: string, zip: string): Observable<AppNewsfeedMessage[]> {
    return this.parameters$.pipe(
      take(1),
      map(params => ({
        businessUnitCode: params.businessUnitCode,
        countryCode,
        zip
      })),
      switchMap(params => this.processRequest<NewsfeedMessageForUser[]>(this._http.get(`${API_URL}/newsfeed/zip-warnings?${encodeAsQueryString(params)}`))),
      map(warnings => warnings.map(w => this.mapMessageToAppMessage(w)))
    );
  }


  /** Initializes stream of relevant parameters for newsfeed. */
  private initParameters() {
    combineLatest([
      // Bu code
      this._contextService.businessUnitCode.pipe(
        filter(b => Boolean(b)),
      ),
      // Depot codes
      this._contextService.addresses.pipe(
        filter(addresses => Boolean(addresses.length)),
        map(addresses => _.uniq(addresses.map(a => a.depotCode))),
      )
    ]).pipe(
      map(([businessUnitCode, depotCodes]) => ({
        businessUnitCode,
        depotCodes,
      })),
      auditTime(0),
      distinctUntilChanged((a, b) => !hasModelChanges(a, b))
    ).subscribe(this._parameters$);
  }


  private observeUser() {
    this._authenticationService.user.pipe(
      skipWhile(u => !u),
      // Clear toasts on logout.
      tap(u => {
        if (!u) {
          this.clearToasts();
        }

        this._canDisplayPopup = !u?.isFirstLogin ?? true;
      })
    ).subscribe(u => this.changeUser(u));
  }


  /** Set newsfeed context for currently logged user. */
  private changeUser(user: User) {
    // No user is logged in or user didn't change (e.g. was logged out and logged back in).
    if (!user || !user.id || this._currentMessagesUserId === user.id) {
      return;
    }

    this._currentMessagesUserId = user.id;

    // Prepare record of user's processed unseen messages.
    if (!this.processedMessages) {
      this._processedUnseenMessagesPerUser.set(user.id, new Set());
    }

    // Clear messages of previous user.
    this._messages$.next([]);
    this._isMessageListLoaded = false;
    this._unreadCount$.next(0);
  }


  private initUnseenMessagesStream() {
    const interval = this._shipperSettingsService.isCentralShipper ?
      CHECK_INTERVAL_IN_SECONDS_FOR_CENTRAL_SHIPPER :
      CHECK_INTERVAL_IN_SECONDS_FOR_OFFLINE_SHIPPER;

    this._globalEventsStreamService.userLoginEvents$.pipe(
      filter(event => event.current === UserLoginEvent.Newsfeed),
      // Init timer
      switchMapTo(
        timer(0, interval).pipe(
          // Kill timer on logout
          takeUntil(this._authenticationService.user.pipe(skip(1)))
        )
      ),
      switchMapTo(this.parameters$),
      filter(() => !this._isPaused),
      switchMap(params => race(
          this.getUnseenMessages(params),
          // Cancel request when parameters change.
          this.requestBuster$.pipe(mapTo(emptyResponse))
        )
      ),
      tap(result => this._unreadCount$.next(result.unreadCount)),
      map(result => result.messages.map(m => this.mapMessageToAppMessage(m))),
      tap(messages => this.displayUnseenMessages(messages))
    ).subscribe(messages => this.addLoadedMessages(messages));
  }


  /**
   * Loads messages for current user's list.
   * Called on subscribe to `messages$`.
   */
  private loadList() {
    if (this._isMessageListLoaded ||
        this._activeOperations$.getValue().has(NewsfeedOperations.List)) {
      return;
    }

    return this.parameters$.pipe(
      take(1),
      mergeMap(params => race(
          this.getMessages(params),
          // Cancel request when parameters change.
          this.requestBuster$.pipe(mapTo([] as NewsfeedMessageForUser[]))
        )
      ),
      map(messages => messages.map(m => this.mapMessageToAppMessage(m))),
      tap(() => this._isMessageListLoaded = true)
    ).subscribe(messages => this.addLoadedMessages(messages), () => { });
  }


  /** Loads unseen messages for current user. */
  private getUnseenMessages(params: NewsfeedParameters): Observable<UnseenMessagesResponse> {
    this.addActiveOperation(NewsfeedOperations.UnseenList);

    return this.processRequest<UnseenMessagesResponse>(this._http.get(`${API_URL}/newsfeed/unseen?${encodeAsQueryString(params)}`)).pipe(
      finalize(() => this.removeActiveOperation(NewsfeedOperations.UnseenList)),
      catchError(() => of(emptyResponse))
    );
  }


  /** Loads messages for current user. */
  private getMessages(params: NewsfeedParameters): Observable<NewsfeedMessageForUser[]> {
    this.addActiveOperation(NewsfeedOperations.List);

    return this.processRequest<NewsfeedMessageForUser[]>(this._http.get(`${API_URL}/newsfeed?${encodeAsQueryString(params)}`)).pipe(
      finalize(() => this.removeActiveOperation(NewsfeedOperations.List)),
      catchError(error => {
        // @todo: Handle error.
        return throwError(error);
      })
    );
  }


  /** Merges new messages with loaded messages and emits. */
  private addLoadedMessages(messages: AppNewsfeedMessage[]) {
    this._messages$.pipe(
      take(1),
      map(m => _.chain(messages)
        .unionBy(m, "messageId")
        .orderBy(["priority", "modificationDateTimeUtc"], ["desc", "desc"])
        .value()
      )
    ).subscribe(m => this._messages$.next(m));
  }


  /** Takes care of displaying unseen messages in toastrs. */
  private displayUnseenMessages(messages: AppNewsfeedMessage[]) {
    if (!this._canDisplayPopup) {
      this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.Newsfeed);
      return;
    }

    const toastAnimationTime = Number(this._toastr.toastrConfig.easeTime);
    const avaiableToastSpots = this._toastr.toastrConfig.maxOpened - this._toastr.currentlyActive;

    messages
      // Ignore already processed messages.
      .filter(m => !this.processedMessages.has(m.messageId))
      .sort((a, b) => b.priority - a.priority ||
        b.modificationDateTimeUtc.getTime() - a.modificationDateTimeUtc.getTime()
      )
      .forEach((m, idx) => {
        /**
         * Using delay because of {@link https://github.com/scttcper/ngx-toastr/issues/742}.
         * Messages will be added to available spots immediately.
         * The rest of the messages will be added after animation
         * finishes and "ToastrService.currentlyActive" updates
         * so that the "maxOpened" rule is applied.
         *
         * `Scheduler.animationFrame` ensures that this applies also
         * when page loads while browser/tab is not visible.
         *
         * Note: issue was fixed for higher version of Angular.
         * {@link https://github.com/scttcper/ngx-toastr/pull/743}
         */
        const addDelay = idx < avaiableToastSpots ? 0 : toastAnimationTime;

        scheduled(of(0), animationFrameScheduler).pipe(
          delay(addDelay)
        ).subscribe(() => this.addMessageToast(m));

        this.processedMessages.add(m.messageId);
      });

    // If no toasts are active resolve Newsfeed login event.
    scheduled(of(0), animationFrameScheduler).pipe(
      // Wait for toast animation.
      delay(toastAnimationTime),
      delay(0)
    ).subscribe(() => this.tryResolveNewsfeedLoginEvent());
  }


  private addMessageToast(message: AppNewsfeedMessage) {
    const toastr = this._toastr.info(message.abstract, message.title, toastsConfing);
    toastr.portal.instance.newsfeedMessage = message;

    this._activeToasts.set(message.messageId, toastr);

    toastr.onHidden.subscribe(() => {
      this._activeToasts.delete(message.messageId);
    });

    toastr.onAction.subscribe(a => {
      switch (a.action) {
        case "dismiss":
          this.markMessagesAsSeen([message.messageId]);
          toastr.onHidden.subscribe(() => this.tryResolveNewsfeedLoginEvent());
          break;
        case "read":
          this.markMessagesAsRead([message.messageId]);

          if (message.content) {
            this.openMessageContentDialog(message);
          }
          break;
        default:
          break;
      }
    });
  }


  private removeMessageToast(messageId: string) {
    const toast = this._activeToasts.get(messageId);

    if (toast) {
      this._toastr.remove(toast.toastId);
    }
  }


  private mapMessageToAppMessage(message: NewsfeedMessageForUser): AppNewsfeedMessage {
    const lang = this._localizationService.currentLocalizationCode.toLowerCase();
    const translations = this.mapMessageTranslationsToObject(message);

    const abstract = _.get(translations, `${lang}.abstract`, _.get(translations, `en.abstract`, null));
    const content = _.get(translations, `${lang}.content`, _.get(translations, `en.content`, null));

    return {
      messageId: message.message.messageId,
      priority: message.message.priority,
      modificationDateTimeUtc: message.message.modificationDateTimeUtc,
      isRead: message.isRead,
      title: _.get(translations, `${lang}.title`, _.get(translations, `en.title`, null)),
      abstract: abstract ? this._domSanitizer.bypassSecurityTrustHtml(abstract) as string : null,
      content: content ? this._domSanitizer.bypassSecurityTrustHtml(content) as string : null
    };
  }


  private mapMessageTranslationsToObject(message: NewsfeedMessageForUser): any {
    const translationsObj = message.translations.reduce((acc, curr) => {
      _.set(acc, `${curr.languageCode}.${curr.messagePart}`, curr.content);
      return acc;
    }, {});

    return translationsObj;
  }


  /** Sends request to mark the messages as seen. */
  private markMessagesAsSeen(messageIds: string[]) {
    if (!messageIds.length) {
      return;
    }

    this.processRequest(this._http.put(`${API_URL}/newsfeed/mark-as-seen`, messageIds))
      .subscribe(() => { }, () => { });
  }


  /** Updates `isRead` property of loaded messages and unreadCount. */
  private markLoadedMessagesAsRead(messageIds: string[]) {
    combineLatest([
      this._messages$,
      this._unreadCount$
    ]).pipe(
      take(1),
      tap(([messages, count]) => {
        messages
          // Filters out messages that were marked as read.
          .filter(m => _.includes(messageIds, m.messageId))
          .forEach(m => {
            // Set flag value.
            m.isRead = true;
            // Close opened toast.
            this.removeMessageToast(m.messageId);
            // If read message changes display toast again.
            this.processedMessages.delete(m.messageId);
            // Chance stored unread count.
            count--;
          });

        this._messages$.next(messages);
        this._unreadCount$.next(Math.max(0, count));
      })
    ).subscribe();
  }


  private openMessageContentDialog(message: AppNewsfeedMessage) {
    this._renderer.addClass(this.document.body, "hide-toasts");

    this._modal.open(NewsfeedMessageDialogComponent, overlayConfigFactory({
      newsfeedMessage: message
    }))
    .result
    .finally(() => {
      this._renderer.removeClass(this.document.body, "hide-toasts");
      this.tryResolveNewsfeedLoginEvent();
    });
  }


  private tryResolveNewsfeedLoginEvent() {
    if (!this._activeToasts.size) {
      this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.Newsfeed);
    }
  }


  private addActiveOperation(operation: NewsfeedOperations) {
    this._activeOperations$.pipe(
      take(1),
      delay(0),
      tap(o => o.add(operation))
    ).subscribe(o => this._activeOperations$.next(o));
  }


  private removeActiveOperation(operation: NewsfeedOperations) {
    this._activeOperations$.pipe(
      take(1),
      delay(0),
      tap(o => o.delete(operation))
    ).subscribe(o => this._activeOperations$.next(o));
  }
}
