<div class="content-panel recipients">
  <div class="grid">
    <shp-progress-overlay [isActive]="isBusy" [message]=""></shp-progress-overlay>
    <shp-retry-overlay [isVisible]="hasLoadingFailed"
                       errorMessage="{{ 'recipients_loading_error' | shpLocalize }}"
                       [reloadFunction]="applyFilter"></shp-retry-overlay>
    <form class="grid-header" #header [formGroup]="filterForm">
      <table>
        <thead>
          <tr>
            <th class="col-selector">
              <input type="checkbox" [ngModel]="areAllVisibleSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllVisibleSelected()" id="select-all-toggle" />
              <label for="select-all-toggle" title="{{ 'select_all' | shpLocalize }}"></label>
            </th>
            <th class="col-address can-order" (click)="setOrderBy('Name')" [class.is-order]="orderBy == 'Name'" [class.ascending]="orderAscending">{{ 'recipient_person_name' | shpLocalize }}</th>
            <th class="col-recipient-address can-order" (click)="setOrderBy('Address')" [class.is-order]="orderBy == 'Address'" [class.ascending]="orderAscending">{{ 'parcel_recipient_address' | shpLocalize }}</th>
            <th class="col-address can-order" (click)="setOrderBy('ContactInformation')" [class.is-order]="orderBy == 'ContactInformation'" [class.ascending]="orderAscending">{{ 'contacts' | shpLocalize }}</th>
            <th class="col-service can-order" (click)="setOrderBy('CustomerRecipientTypeCode')" [class.is-order]="orderBy == 'CustomerRecipientTypeCode'" [class.ascending]="orderAscending">{{ 'recipient_type' | shpLocalize }}</th>
            <th class="col-reference can-order" (click)="setOrderBy('ReferenceNumber')" [class.is-order]="orderBy == 'ReferenceNumber'" [class.ascending]="orderAscending">{{ 'recipient_reference' | shpLocalize }}</th>
            <th class="col-commands"></th>
            <th class="col-spacer"></th>
          </tr>
          <!-- filtre -->
          <tr class="filters">
            <th class="col-selector"></th>
            <th class="col-address">
              <div class="filter">
                <div class="ctl">
                  <input id="input-filter-name" type="text" formControlName="name" name="name" placeholder="{{ 'filter' | shpLocalize }}" />
                </div>
              </div>
            </th>
            <th class="col-recipient-address">
              <div class="filter">
                <div class="ctl">
                  <input id="input-filter-address" type="text" formControlName="address" name="address" placeholder="{{ 'filter' | shpLocalize }}" />
                </div>
              </div>
            </th>
            <th class="col-address">
              <div class="filter">
                <div class="ctl">
                  <input id="input-filter-contact" type="text" formControlName="contactInformation" name="contactInformation" placeholder="{{ 'filter' | shpLocalize }}" />
                </div>
              </div>
            </th>
            <th class="col-service">
              <div class="filter">
                <div class="ctl">
                  <select id="input-filter-type" formControlName="customerRecipientTypeCode" name="customerRecipientTypeCode">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let recipientType of recipientTypes" [ngValue]="recipientType">{{ ((recipientType == 'B2B') ? 'recipient_type_b2b' : 'recipient_type_b2c') | shpLocalize }}</option>
                  </select>
                </div>
              </div>
            </th>
            <th class="col-reference">
              <div class="filter">
                <div class="ctl">
                  <input id="input-filter-reference" type="text" formControlName="referenceNumber" name="referenceNumber" placeholder="{{ 'filter' | shpLocalize }}" />
                </div>
              </div>
            </th>
            <th class="col-global-commands"><button id="button-filter-clear" type="button" name="clearFilter" (click)="clearFilter()"><span class="ic-close"></span></button></th>
            <th class="col-spacer">
              <div class="filter" *ngIf="canUndelete">
                <div class="ctl">
                  <input type="checkbox" [(ngModel)]="showDeleted" [ngModelOptions]="{standalone: true}" id="show-deleted" />
                  <label for="show-deleted" >{{ 'recipients_filter_deleted' | shpLocalize }}</label>
                </div>
              </div>
            </th>
          </tr>
        </thead>
      </table>
    </form>
    <div class="scrollbar-spacer-cover"></div>
    <div class="grid-content" (scroll)="scrollGridContent()" #content>
      <div class="list-empty-message" *ngIf="totalCount === 0 && !isFiltered">
        <div class="message-content">
          {{ 'recipients_none_in_database' | shpLocalize }}
        </div>
      </div>

      <div class="list-empty-message" *ngIf="totalCount === 0 && isFiltered">
        <div class="message-content">
          {{ 'recipients_none_matching_filter' | shpLocalize }}
        </div>
      </div>

      <table>
        <tbody>
          <tr *ngFor="let recipient of recipients | async">
            <td class="col-selector" [class.deleted]="recipient.stateId == 'Deleted'"><input type="checkbox" [ngModel]="recipient.isSelected" (change)="selectionChanged($event, recipient)" id="is-selected-{{ recipient.id }}" /><label [attr.for]="'is-selected-' + recipient.id"></label></td>
            <td class="col-address">
              {{ recipient.name }} {{ recipient.name2 }}
              <span *ngIf="showRecipientToPickupAddressAsignment && !recipient.addressId" class="recipient-global-marker">G</span>
            </td>
            <td class="col-recipient-address">
              {{ recipient.street }} {{ recipient.houseNr }}<br />
              {{ recipient.zip }} {{ recipient.city }}<br />
              {{ recipient.countryName }}
            </td>
            <td class="col-address">
              {{ recipient.contactPerson }}<br />
              {{ recipient.phone }}<br />
              {{ recipient.email }}
            </td>
            <td class="col-service">
              {{ ((recipient.customerRecipientTypeCode == 'B2B') ? 'recipient_type_b2b' : 'recipient_type_b2c') | shpLocalize }}
            </td>
            <td class="col-reference">
              {{ recipient.referenceNumber }}
            </td>
            <td class="col-commands recipients-buttons">
              <ng-container *ngIf="recipient.stateId != 'Deleted'">
                <button id="button-edit-recipient" type="button" (click)="editRecipient(recipient)" title="{{ 'recipient_edit' | shpLocalize }}"><span class="ic-file-edit-1"></span></button>
                <button id="button-create-shipment" type="button" (click)="createNewShipmentForRecipient(recipient)" title="{{ 'recipient_new_order' | shpLocalize }}"><span class="ic-box-add"></span></button>
                <button id="button-delete-recipient" type="button" (click)="deleteRecipient(recipient)" title="{{ 'recipient_delete' | shpLocalize }}"><span class="ic-bin" ></span></button>
              </ng-container>
              <button id="button-undelete" *ngIf="recipient.stateId == 'Deleted' && canUndelete" type="button" (click)="undeleteRecipient(recipient)" title="{{ 'recipient_undelete' | shpLocalize }}"><span class="ic-sign-recycle"></span></button>
            </td>
            <td class="col-spacer"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="grid-footer">
      <div class="multi-select-commands">
        <button id="button-export-recipients" type="button" class="bottom-button" (click)="exportRecipients()"><span class="ic-data-upload-8"></span><span class="label">{{ 'recipients_export_selected' | shpLocalize }}</span></button>
        <button id="button-delete-recipients" type="button" class="bottom-button" (click)="deleteRecipients()"><span class="ic-bin"></span><span class="label">{{ 'recipients_delete_selected' | shpLocalize }}</span></button>
      </div>
      <div class="grid-pager">
        <div class="pager" *ngIf="filter.pageSize < totalCount">
          <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
          <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(filter.pageIndex - 1)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
          <button id="button-pager-next" class="next" type="button" (click)="goToPage(filter.pageIndex + 1)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
          <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
          <span class="numbers">{{ filter.pageIndex * filter.pageSize + 1 }} - {{ (filter.pageIndex + 1) * filter.pageSize < totalCount ? (filter.pageIndex + 1) * filter.pageSize : totalCount }}/{{ totalCount }}</span>
        </div>
      </div>
      <div class="grid-options">
        <label *ngIf="filter.pageSize > totalCount && totalCount > 0">{{ 'total_items' | shpLocalize }}: {{ totalCount }}</label>
        <label for="items-per-page">{{ 'items_per_page' | shpLocalize }}</label> <select id="items-per-page" class="items-per-page" [(ngModel)]="parcelsPerPage">
          <option *ngFor="let value of itemsPerPageOptions" value="{{ value }}">{{ value }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
