import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { ReplaySubject } from "rxjs";
import { delay, delayWhen } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { LocalizationService, ShipperSettingsService } from "../../shared/index";
import { ChangeLogItem } from "../models/change-log-item.model";
import { SupportService } from "../services/support.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-change-log",
  templateUrl: "./app-change-log.component.html",
  host: {
    class: "change-log"
  }
})
export class AppChangeLogComponent implements OnInit {
  changeLog$ = new ReplaySubject<ChangeLogItem[]>(1);

  constructor(
      private route: ActivatedRoute,
      private localizationService: LocalizationService,
      private shipperSettingsService: ShipperSettingsService,
      private supporService: SupportService) { }

  ngOnInit() {
    this.loadChangeLog();

    this.route.fragment.pipe(
      delayWhen(() => this.changeLog$.pipe(delay(0)))
    ).subscribe(version => {
        const anchor = document.getElementById(version);

        if (anchor) {
          anchor.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
        }
      });
  }

  get currentLanguageCode() {
    return this.localizationService.currentLocalizationCode;
  }

  get isCentral() {
    return this.shipperSettingsService.isCentralShipper;
  }

  get isOffline() {
    return !this.shipperSettingsService.isCentralShipper;
  }

  private loadChangeLog() {
    this.supporService.loadChangeLog().subscribe(this.changeLog$);
  }
}
