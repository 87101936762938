<div class="top">
  <h1>{{ 'acceptance_protocol_history' | shpLocalize }}</h1>
</div>

<div class="content-panel acceptance-protocol-history">
  <div class="grid">
    <div class="pickup-order-type-header">
      <h2></h2>
    </div>
    <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
    <shp-retry-overlay [isVisible]="isLoadingErrorPending" errorMessage="{{ 'acceptance_protocol_history_loading_error' | shpLocalize }}" [reloadFunction]="refresh"></shp-retry-overlay>

    <!-- header filters begin -->
    <form class="grid-header">
      <table>
        <thead>
          <tr>
            <!-- <th class="col-selector">
              <input type="checkbox" id="select-all" name="selectAll" [(ngModel)]="multipleSelectModel" (ngModelChange)="onMultipleSelect()" />
              <label for="select-all" title="{{ 'select_all' | shpLocalize }}"></label>
            </th> -->
            <th class="can-order col-medium" [class.is-order]="orderBy == 'CreationDateTimeUtc'" [class.ascending]="isOrderAscending" (click)="setItemsOrder('CreationDateTimeUtc')">
              {{
              'acceptance_protocol_creation_date' | shpLocalize
              }}
            </th>
            <th class="can-order col-medium" [class.is-order]="orderBy == 'ProtocolTypeId'" [class.ascending]="isOrderAscending" (click)="setItemsOrder('ProtocolTypeId')">{{ 'protocol_type' | shpLocalize }}</th>
            <th class="col-medium">{{ 'address' | shpLocalize }}</th>
            <th class="can-order col-commands"></th>
            <th class="col-spacer"></th>
          </tr>
          <tr class="filters" [formGroup]="columnFilter">
            <!-- <th class="col-selector"></th> -->
            <th class="col-medium">
              <div class="filter">
                <div class="ctl">
                  <div class="p-field p-field--full">
                    <span class="p-float-label">
                      <p-calendar formControlName="dateFrom" [showIcon]="true" appendTo="body" inputId="dateFrom" dateFormat="dd/mm/yy" icon="ic-calendar-1" name="dateFromFilter"></p-calendar>
                      <label for="dateFrom">{{ 'filter_from' | shpLocalize }}</label>
                    </span>
                  </div>
                  <div class="p-field p-field--full">
                    <span class="p-float-label">
                      <p-calendar formControlName="dateTo" [showIcon]="true" appendTo="body" inputId="dateTo" dateFormat="dd/mm/yy" icon="ic-calendar-1" name="dateToFilter"></p-calendar>
                      <label for="dateTo">{{ 'filter_to' | shpLocalize }}</label>
                    </span>
                  </div>
                </div>
              </div>
            </th>
            <th class="col-medium">
              <div class="filter">
                <div class="ctl">
                  <select name="protocolTypeFitler" formControlName="protocolTypeId">
                    <option value="">-</option>
                    <option *ngFor="let protocolType of protocolTypes" [value]="protocolType.id">{{ protocolType.name | shpLocalize }}</option>
                  </select>
                </div>
              </div>
            </th>
            <th class="col-medium">

            </th>
            <th class="col-global-commands">
              <button id="button-filter-clear" type="button" name="resetColumnFilter" (click)="resetColumnFilter()"><span class="ic-close"></span></button>
            </th>
            <th class="col-spacer"></th>
          </tr>
        </thead>
      </table>
    </form>
    <!-- header filters end -->

    <div class="scrollbar-spacer-cover"></div>
    <div class="grid-content">
      <div class="list-empty-message" *ngIf="totalCount === 0 && !isFiltered">
        <div class="message-content">
          {{ 'message_no_protocols_in_db' | shpLocalize }}
        </div>
      </div>
      <div class="list-empty-message" *ngIf="totalCount === 0 && isFiltered">
        <div class="message-content">
          {{ 'message_no_protocols_matching_filter' | shpLocalize }}
        </div>
      </div>

      <!-- table body begin -->
      <table>
        <tbody>
          <tr *ngFor="let protocol of protocols$ | async">
            <!-- <td class="col-selector">
              <input type="checkbox" id="is-selected-{{ protocol.id }}" [(ngModel)]="protocol.isSelected" (change)="onRowSelect(protocol.id)" />
              <label [attr.for]="'is-selected-' + protocol.id"></label>
            </td> -->
            <td class="col-medium">{{ protocol.creationDateTimeUtc | shpLocalizeDate }}</td>
            <td class="col-medium">{{ protocol.protocolTypeName | shpLocalize }}</td>
            <td class="col-medium">{{ protocol.customerAddressName }}</td>
            <td class="col-commands"><button type="button" title="{{ 'attachment_command_download' | shpLocalize }}" (click)="printAcceptanceProtocol(protocol.id)"><span class="ic-file-download-2"></span></button></td>
            <td class="col-spacer"></td>
          </tr>
        </tbody>
      </table>
      <!-- table body end -->
    </div>

    <div class="grid-footer">
      <div class="multi-select-commands"></div>
      <div class="grid-pager">
        <div class="pager" *ngIf="pageSize < totalCount">
          <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
          <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(pageIndex - 1)" *ngIf="pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
          <button id="button-pager-next" class="next" type="button" (click)="goToPage(pageIndex + 1)" *ngIf="pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
          <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
          <span class="numbers">{{ pageIndex * pageSize + 1 }} - {{ (pageIndex + 1) * pageSize < totalCount ? (pageIndex + 1) * pageSize : totalCount }}/{{ totalCount }}</span>
        </div>
      </div>
      <div class="grid-options">
        <label for="items-per-page">{{ 'items_per_page' | shpLocalize }}</label> <select id="items-per-page" class="items-per-page" [(ngModel)]="pageSize">
          <option *ngFor="let value of itemsPerPageOptions" value="{{ value }}">{{ value }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
