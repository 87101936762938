import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"

import { Observable } from "rxjs";

import * as Shared from "../../shared/index"
import * as LogModel from "../models/log.model"


@Injectable()
export class LogService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient
  ) {
    super(loggingService);
  }


   /**
   * 
   * @param parameters Filter na záznamy logov.
   */
  public getLogEntries(parameters: LogModel.LogEntryQueryParameters): Observable<LogModel.PagedResult<LogModel.LogEntry>> {
    return this.processRequest<LogModel.PagedResult<LogModel.LogEntry>>(this._http.get(Shared.API_URL + "/logs?" + Shared.encodeAsQueryString(parameters)));
  }
}