import { FormGroup } from "@angular/forms";
import { TransformationEditorComponent } from "../transformation-editor.component";

import { Subject } from "rxjs";
import { filter, pluck, startWith, takeUntil } from "rxjs/operators";

/**
 * @description
 * A base class that all import profile transformation data form components extend.
 * It takes care of registering transformation data form to the parent form.
 */
export abstract class ImportProfileTransformationDataBase {
  /**  */
  abstract transformationDataName: string;
  abstract form: FormGroup;


  private destroy$ = new Subject<void>();


  constructor(private parent: TransformationEditorComponent) { }


  protected connectForm() {
    if (!this.parent) {
      throw new Error(`Class that extends ImportProfileTransformationDataBase must be used with a parent TransformationEditorComponent component.`);
    }

    /** Register control to parent form for validation and other purposes. */
    this.parent.form.setControl(this.transformationDataName, this.form);

    /** Update model on changes. */
    this.form.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      this.parent.model[this.transformationDataName] = value;
    });

    /** Reflect model changes to form. */
    this.parent.modelUpdates$.pipe(
     takeUntil(this.destroy$),
     startWith(this.parent.model),
     pluck(this.transformationDataName),
     filter(value => !!value)
    ).subscribe(value => {
      this.form.patchValue(value);
    });
  }

  
  disconnectForm() {
    this.destroy$.next();
    this.destroy$.complete();
    this.parent.model[this.transformationDataName] = null;
    this.parent.form.removeControl(this.transformationDataName);
  }
}