<div class="support-category">

  <div class="change-log__version">
    <h3 id="2.3.30.0" class="change-log__headline">2.3.30.0 ({{ '2024-07-03' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>For some services, the phone number will now be printed on the label.</li>
        <li>Enabled new customs section for CH users.</li>
        <li *ngIf="!isOffline">UI changes for CH users.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.29.0" class="change-log__headline">2.3.29.0 ({{ '2024-04-03' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fixed bugs with data filling for parcels to the USA.<</li>
        <li>Fixed bugs with date choosing for pickup order.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2.3.28.0" class="change-log__headline">2.3.28.0 ({{ '2023-10-21' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Value of the parcel on UK labels is removed for BE and NL users.</li>
        <li *ngIf="!isOffline">Enabled customs section for CH users.</li>
        <li>Added ability to print label in PNG format (E-label) for NL users.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.27.0" class="change-log__headline">2.3.27.0 ({{ '2023-07-15' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>New validations added.</li>
        <li>Added ability to select Collection request date for some countries for NL users.</li>
        <li>Removed limit of 20 parcels in one shipment for NL users.</li>
        <li *ngIf="isOffline">Added ability to print directly on a printer by the Self-hosted API. </li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.26.1" class="change-log__headline">2.3.26.1 ({{ '2023-03-28' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Option to disable validation of postcode and building number combination for Netherlands addresses in shipment editor and imports.</li>
        <li>Minor bug fixes.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.26.0" class="change-log__headline">2.3.26.0 ({{ '2023-02-17' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Dimensions editor for parcels for some business units.</li>
        <li>Validation and street auto-complete by zip code and building number for NL recipients.</li>
        <li *ngIf="isOffline">Automatic database backup when updating Shipper to new version.</li>
        <li>Guide to introduce Shipper features to new users.</li>
        <li>Mandatory password change after first login.</li>
        <li>Minor bug fixes, icons and layout changes.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.25.2" class="change-log__headline">2.3.25.2 ({{ '2022-09-02' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix tracking link.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.25.1" class="change-log__headline">2.3.25.1 ({{ '2022-07-29' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix reuse of recyclable parcel number range in PostgreSql version.</li>
        <li *ngIf="!isOffline">Allow to use separate database for parcels data.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.25.0" class="change-log__headline">2.3.25.0 ({{ '2022-05-31' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Parallelized import of shipments.</li>
        <li>Recipient reference in the acceptance protocol.</li>
        <li>Password policy.</li>
        <li>Collection request confirmation e-mail.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.24.2" class="change-log__headline">2.3.24.2 ({{ '2022-05-10' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix the obligation of the City field.</li>
        <li>Fix missing line number in the recipient import.</li>
        <li *ngIf="isOffline">Fix in the automatic shipments import in PostgreSql version.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.24.1" class="change-log__headline">2.3.24.1 ({{ '2021-12-15' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Various fixes and improvements in parcel number assignment.</li>
        <li>Fix the log of recipients' import errors and warnings.</li>
        <li *ngIf="isOffline">Fix printing in the automatic shipments import.</li>
        <li *ngIf="isOffline">Fix the schedule in the automatic shipments export.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2.3.24.0" class="change-log__headline">2.3.24.0 ({{ '2021-07-12' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Auto-archive of old shipments.</li>
        <li>Improved calculation of products and services availability.</li>
        <li>Updated user interface design of main controls and inputs.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 class="change-log__headline">2.3.23.1 ({{ '2021-06-30' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Use DPD logo instead of Seur logo on parcel label to Portugal.</li>
        <li>Improvements in FAQ section.</li>
        <li>Various fixes in PostgreSQL database handling.</li>
        <li>Use recipient email address in Order info email if none is specified.</li>
        <li *ngIf="isOffline">Limit size for LOG database.</li>
        <li>Fix printing service text on parcel label.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 class="change-log__headline">2.3.22.11 ({{ '2021-05-11' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix issue preventing retrieval of parcel ships list for Ireland and Northern Ireland.</li>
        <li>Fix OSORT calculation in routing.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 class="change-log__headline">2.3.22.10 ({{ '2021-05-05' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Fix occasional issue with a freshly downloaded routing database not being used.</li>
        <li *ngIf="isOffline">Fix issue with data not being cleaned up properly in SQLite DB of Shipper when shipment orders are deleted.</li>
        <li *ngIf="isOffline">Fix possibility to import invalid values in some fields.</li>
        <li>Fix performance of products and services availability calculation.</li>
        <li>Fix editing of recipients by non-admin user for customers of DPD business units where per address list of recipients isn't used.</li>
      </ng-container>
    </ul>
  </div>

  <div *ngFor="let item of changeLog$ | async" class="change-log__version">
    <h3 [id]="item.versionUri" class="change-log__headline">{{ item.version }} ({{ item.releaseDate | shpLocalizeDate:'L' }})</h3>
    <div [innerHtml]="item.content"></div>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-22-9" class="change-log__headline">2.3.22.9 ({{ '2021-03-13' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Fix occasional error with access to routing database.</li>
        <li>Fix routing calculation for countries with variable lenght ZIP codes.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-22-8" class="change-log__headline">2.3.22.8 ({{ '2021-01-21' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Fix parcel number missing from archived file from automatic shipments import if adding of parcel numbers is set.</li>
        <li>Fix sender EORI being cut off at 10 characters instead of 20.</li>
        <li>Fix displaying Clearance cleared field value in customs data (when customs is enabled) for already saved shipment orders.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version" *ngIf="isOffline">
    <h3 id="2-3-22-7" class="change-log__headline">2.3.22.7 ({{ '2021-01-12' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix unexpected errors mainly in automated imports when SQLite is used as DB.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-22-4" class="change-log__headline">2.3.22.4 ({{ '2021-01-05' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Make parcel contents field as optional for non EU countries (except UK).</li>
        <li>Fix email address validation.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-22-3" class="change-log__headline">2.3.22.3 ({{ '2021-01-04' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Fix background installer service for automatic update.</li>
        <li>Fix missing parcel count for return shipment orders.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-22-2" class="change-log__headline">2.3.22.2 ({{ '2020-12-31' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix possibility to define invalid terms of delivery for customs clearance data.</li>
        <li>Fix some missing Dutch translations.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-22-1" class="change-log__headline">2.3.22.1 ({{ '2020-12-29' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>For some DPD business units validations of phone numbers and emails have been turned off. Consult your DPD contact for details.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-22-0" class="change-log__headline">2.3.22.0 ({{ '2020-12-29' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Small layout changes in order creation screen.</li>
        <li>For cross-border shipment orders the recipient email is mandatory.</li>
        <li>Recipient phone number is validated using basic rules available for destination country (like proper area/network code, proper length, ...).</li>
        <li>Recipient email's domain is validated against DNS entries of given domain (if the domain exists and if it has mail server record(s)).</li>
        <li>Various changes in entry and import of customs data due changes required for BREXIT (May not be relevant for business unit with disabled customs data entry in Shipper).</li>
        <li *ngIf="isOffline">Optimization of data synchronization process using API Sync.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-21-0" class="change-log__headline">2.3.21.0 ({{ '2020-11-25' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Added newsfeed in messages section. Also news from newsfeed are displayed as popups.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-20-0" class="change-log__headline">2.3.20.0 ({{ '2020-11-04' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Use Seur and Postnord logo for labels of parcel shop shipments for required countries.</li>
        <li *ngIf="isOffline">Exported PICKUPDIFF files now contain DelisID of customer in file name.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-8" class="change-log__headline">2.3.19.8 ({{ '2020-10-07' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix wrong pickup date of Collection Request transferred to DPD after cloning an existing order.</li>
        <li *ngIf="isOffline">Fix wrong parcel number ranges import with new configuration in certain cases.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-7" class="change-log__headline">2.3.19.7 ({{ '2020-10-06' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Fix communication with Shipper Admin about instruction applicatoin errors.</li>
        <li>Fix error when printing protocol for selected parcels in parcel's grid.</li>
        <li *ngIf="isOffline">Make clearing SMTP server settings possible.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-6" class="change-log__headline">2.3.19.6 ({{ '2020-10-02' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Fix various issues with configuration import/activation.</li>
        <li>Fix limit of HS Code field</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-5" class="change-log__headline">2.3.19.5 ({{ '2020-09-22' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Truncate name and street in shipments import instead of throwin error when field is too long.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-4" class="change-log__headline">2.3.19.4 ({{ '2020-09-07' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Remove button to print label for single Collection Request parcel in parcels grid as CR labels shouldn't be printed in Shipper.</li>
        <li>Fix error saving shipment order in certain cases when small parcel version of product does exist but isn't available in destination.</li>
        <li>In shipments list display pickup date without the unnecessary time part.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version" *ngIf="isOffline">
    <h3 id="2-3-19-3" class="change-log__headline">2.3.19.3 ({{ '2020-09-01' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>In Settings -> Basic add posibility to enable or disable products and additional services availability calculation. By default it is disabled.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-2" class="change-log__headline">2.3.19.2 ({{ '2020-08-27' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Duplicating an order now doesn't set the pickup date if no pickup date was specified in original order.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-1" class="change-log__headline">2.3.19.1 ({{ '2020-08-26' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Encoding in shipments import dialog is correctly used after it is changed.</li>
        <li>Encoding in recipients import dialog is correctly used after it is changed.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-19-0" class="change-log__headline">2.3.19.0 ({{ '2020-07-30' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Collection request parcels are now shown parcels grid if it is enabled for customer's business unit.</li>
        <li>It is possible to automatically create pickup request (if there is none defined for specified date) from order creation screen if this functionality is enabled for customer's business unit.</li>
        <li>It's possible to display up to 500 parcels per page in parcel's grid.</li>
        <li>Save button on shipment order creation screen is enabled even there are errors and when it is pressed the list of errors is displayed.</li>
        <li>Empty values can be transformed in transformations.</li>
        <li *ngIf="isCentral">Limit to uploaded file size is in place in Central Shipper.</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix application of limit for small parcel weight. Upper limit wasn't included in the range for small parcel.</li>
        <li *ngIf="isCentral">In non-admin user editing products that are allowed only for Shipper Web Services for given customer aren't shown.</li>
      </ng-container>
    </ul>
  </div>

  <div *ngIf="isOffline" class="change-log__version">
    <h3 id="2-3-16-2" class="change-log__headline">2.3.16.2 ({{ '2020-06-08' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix missing field to enter password for automatic shipments import from (S)FTP.</li>
      </ng-container>
    </ul>

  </div>

  <div class="change-log__version">
    <h3 id="2-3-16-1" class="change-log__headline">2.3.16.1 ({{ '2020-05-20' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fix calculation of small parcel codes.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-16-0" class="change-log__headline">2.3.16.0 ({{ '2020-02-14' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>It is possible to specify reference number for return product.</li>
        <li>Parcels grid columns are adjustable.</li>
        <li *ngIf="isOffline">Now Shipper automatically delete the downloaded routing database ZIP files a few days after import.</li>
        <li *ngIf="isOffline">Added export all shipments dialog to specify export filter.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-14-0" class="change-log__headline">2.3.14.0</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Added option to specify when to automatically export shipments to CVS files (Settings -> Automatic shipments export).</li>
        <li>It is now possible to specify parcel shop ID when filtering parcel shops in shipment order.</li>
        <li>It is possible to specify reference number for return product.</li>
        <li *ngIf="isOffline">It is possible to print to output printed label and protocol PDFs to specified directory.</li>
        <li>Default return address is now per pickup address.</li>
        <li>Tab and quotation marks separated import files now possible (must be specified in import profile).</li>
        <li *ngIf="isOffline">Configurations are now activated using an activation code (configuration file import is still possible, but not prefered).</li>
        <li>If it is set by DPD, customs fields can display tooltips with detailed description of the field.</li>
        <li *ngIf="isOffline">Shipper now can notify about new version and automatically download and install it (user has to agree to proceed).</li>
        <li>Additional help links are displayed if DPD specifies them.</li>
        <li *ngIf="isOffline">It is now possible to specify order information email template for different languages.</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Various small fixes in PostgreSQL database handling.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-12-3" class="change-log__headline">2.3.12.3 ({{ '2020-01-07' | shpLocalizeDate:'L' }}, hotfix)</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Fixed Shipments and CR imports not working (Object reference not set to an instance of an object.).</li>
        <li>Fixed missing filename from GEODATA FILE header line.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-12-2" class="change-log__headline">2.3.12.2 ({{ '2019-12-11' | shpLocalizeDate:'L' }}, hotfix)</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Added possibility to use sender address data in email template for order information email (@Model.SenderAddress.Name1, .Name2, .Street, .HouseNr, .AddressLine2, .City, .Zip, .StateCode, .CountryCode).</li>
        <li>Added possibility to easily insert tracking link URL in email template for order information email (@Model.GetShipmentTrackingLink()).</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Added missing prealert_status_code and clearance_cleared_code for shipment imports.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-12-1" class="change-log__headline">2.3.12.1 ({{ '2019-11-25' | shpLocalizeDate:'L' }}, hotfix)</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Temporary removal of house number requirement validation for addresses in NL.</li>
      </ng-container>
    </ul>
  </div>

  <div class="change-log__version">
    <h3 id="2-3-12-0" class="change-log__headline">2.3.12.0 ({{ '2019-11-12' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Dimensions order is now length, width, height.</li>
        <li>Collection requests go into "Prepared for export" state after saving. Saving needs to be confirmed.</li>
        <li *ngIf="isOffline">It's now possible to use PostgreSQL as database for Shipper's data.</li>
        <li>Building number is now mandatory for recipient in Netherlands.</li>
        <li *ngIf="isOffline">Setting mail server is now possible in Shipper's settings.</li>
        <li *ngIf="isOffline">Email designer for notification email added.</li>
        <li *ngIf="isOffline">Bundled Georouting database updated to version 19110406.</li>
        <li *ngIf="isOffline">Geodata export flow type changed to SHIPNOT (was SHIPNOTCUS).</li>
        <li>Removed page numbering from protocols.</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>CustomerReferenceNr is now available for use in shipment imports (for collection requests).</li>
        <li>Printing of protocol for multiple pickup addesses is now working.</li>
        <li>Currency in case of COD is correctly set upon import of shipment.</li>
        <li>Remove "Send order confirmation" checkbox when collection request or (Shop)Return product is selected.</li>
        <li>Fix double return label printing in case of (Shop)Return as additional service.</li>
        <li>Use correct central tracking link at various places.</li>
        <li>Remove logging of sensitive informations for (S)FTP connection attempts.</li>
        <li>Remove unnecessary checking of remote (S)FTP location even there is no data to sync to DPD.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-11-0" class="change-log__headline">2.3.11.0 ({{ '2019-09-20' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Switching from (Shop)Return to classic shipment handles addresses correctly.</li>
        <li>In parcels list SO and AS codes are displayed instead of service shortcuts.</li>
        <li>Changelog added in support screen.</li>
        <li>Email designer for personalised email messages.</li>
        <li>Several UI color modes added.</li>
        <li *ngIf="isOffline">Bundled GRDB updated to 19090204.</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Changed SFTP component to fix certain issues when synchronizing data to DPD.</li>
        <li>Certain numeric fields now properly update form state so buttons are enabled/disabled already when editing the value of the field and not just after the field looses focus.</li>
        <li>Decimal separator setting from import profile is now properly used.</li>
        <li *ngIf="!isOffline">Deleted parcels aren't visible if filter for not printed parcels is selected.</li>
        <li>Recipient is now properly saved in recipients list even in setting the saving of recipients is disabled, but in order the saving of recipient is set in the order itself.</li>
        <li>Transformations export and import now works correctly.</li>
        <li>"Send return label by email" setting is now properly loaded after opening an existing shipment order.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-10-1" class="change-log__headline">2.3.10.1 ({{ '2019-07-25' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Parcel numbers can be reused only after 6 months.</li>
        <li>Acceptance protocols are correctly saved into archive for currently selected pickup address. Admin user sees all the protocols, normal users only for the current pickup address.</li>
        <li *ngIf="!isOffline">
          Shipment imports are queued and processed asynchronously. User can check in the import history for the status of import. At the moment, due performance
          reasons, notification about import completion isn't enabled.
        </li>
        <li>Setting to include note on acceptance protocol is removed.</li>
        <li>In case of printing on A4 it's possible to disable the displaying of printing position selection dialog and use every time the position set in settings.</li>
        <li>Collection request sender and ShopReturn receiver addresses are saved to address book and it's possible to specify for the address where it can be used.</li>
        <li>Added checkbox to specify if receiver's address should be saved to address book.</li>
        <li>It's possible to send return label per email if email settings are configured.</li>
        <li>Non-admin user has more printing settings available.</li>
        <li>It's possible to send notificaiton email with tracking link from Shipper.</li>
        <li>It's possible to specify a multiplier transformation - used mainly in weight import as by default Shipper needs dekagrams, this way users may import kilograms or even grams.</li>
        <li>GeoRouting AS code lookup updated to reflect fixes in official API from GeoPost.</li>
        <li *ngIf="isOffline">Database locked errors handling and performance improvements for larger customers.</li>
        <li>It's possible now to import customs clearance data in shipments import.</li>
        <li>For return labels from certain countries the logo of the local DPD partner is used.</li>
        <li *ngIf="isOffline">Bundled GRDB updated to 19070104.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-7-1" class="change-log__headline">2.3.7.1 ({{ '2019-05-05' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Customs clearance fields for other countries than UK work as before the BREXIT changes.</li>
        <li *ngIf="isOffline">Currency codes in exported MPSEXPDATA and GEODATA files are now uppercase.</li>
        <li *ngIf="isOffline">Bundled GRDB updated to 19050602.</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Parcels count is now properly checked in import.</li>
        <li *ngIf="isOffline">Restore functionality of printer rules for protocols.</li>
        <li *ngIf="isOffline">When deleting order remove also export entry for SQLite DB - it could happen that new Collection requests weren't exported as order ID was reused by SQLite DB and there was already an export entry.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-7-0" class="change-log__headline">2.3.7.0 ({{ '2019-04-16' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isCentral">User is presented with recommendation to change his password after first log in.</li>
        <li *ngIf="isCentral">Password reset is possible using request to reset password which sends user an email containing a link to form where he can reset his password.</li>
        <li>User is able to print collection request confirmation protocol.</li>
        <li>For non-CR orders pickup date field isn't shown</li>
        <li>Actual export status of data of the order is shown as icon in the orders/parcels list.</li>
        <li>It is possible to limit countries, from which collection request can be done.</li>
        <li>Default pickup order date is tomorrow.</li>
        <li>For documents shipments to UK all customs fields are shown.</li>
        <li>Option to export PUDOID into additional fields in MPSEXPDATA added. Additional fields filled are HEADER:RCOMMENT (doesn't overwrite existing value), HEADER:MPSCREF4 and PARCEL:CREF4 (in these 2 it overwrites).</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Generated parcel labels should be more in-line with GeoLabel specification (especially regarding the barcode and missing texts on label).</li>
        <li>Shipper is now properly filling recipient name 1 and 2 in Aztec 2D Code data.</li>
        <li>Linebreaks are properly sanitized in PICKUPDIFF data export.</li>
        <li *ngIf="isOffline">Only import paths belonging to currently selected configuration are shown in settings.</li>
        <li>GeoRouting algorithm is fixed so it wont return invalid SO+AS combinations.</li>
        <li>Validation, if a parcel is a small parcel is now done correctly.</li>
        <li>EORI number made optional when creating a recipient in recipients list.</li>
        <li>Fixed validation of parcels when "all parcels have the same dimension" is checked and customs clearance is not active.</li>
        <li *ngIf="isOffline">Added missing address lines on additional return label when printing on thermal printer.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-6-0" class="change-log__headline">2.3.6.0. - 2.3.6.2</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Demo label is now unreadable using barcode scanner to limit its usage.</li>
        <li>(Un)select all pickup addresses in user editing.</li>
        <li>Recipients search can be set to search only in name 1 and name 2 fields. Also added possibility to specify how many characters will start the autocomplete.</li>
        <li>Added references on shipment level.</li>
        <li>After saving the recipient, user will be redirected to the list of recipients by default.</li>
        <li>Additional services are moved next to the products selection.</li>
        <li *ngIf="isOffline">The list of shipments is automatically reloaded if new shipments are imported using automatic import.</li>
        <li *ngIf="isOffline">It is possible to specify that shipments should be deleted automatically after specified number of days.</li>
        <li>Preparation for BREXIT - added/changed handling of customs data.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-5-2" class="change-log__headline">2.3.5.2 ({{ '2019-02-13' | shpLocalizeDate:'L' }}, hotfix)</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>When looking for existing recipient by ref. number when importing recipients, Shipper now considers also the tenant.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-5-1" class="change-log__headline">2.3.5.1 ({{ '2019-02-11' | shpLocalizeDate:'L' }}, hotfix)</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Heartbeat interval increased to 3 minutes for Central Shipper and 10 seconds for Offline Shipper (up from 3 seconds).</li>
        <li>Loading of certain settings relevant for UI functionality has been optimized - the settings are loaded only once when the user logs in or changes the selected pickup address.</li>
        <li>Updated localization string with data from lokalize.co tool available at 11th February at 22:00.</li>
        <li>0 parcel weight is now considered as not entered parcel weight - parcel then can't be considered small parcel.</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Export of CRDATA01 now correctly contains house numbers for ordering and recipient addresses street fields.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-5-0" class="change-log__headline">2.3.5.0 ({{ '2019-02-05' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>When creating a new version of a configuration, all previous added parcel number ranges are visible for all pickup addresses in the new configuration as they were visible in the previous configuration. It is possible to include or exclude them in the actual configuration using the include check box.</li>
        <li>Acceptance protocols archive (accessible trough EOD dialog).</li>
        <li>Added a button for marking all messages as "read" and deleting messages after 90 days (offline only).</li>
        <li>Added more contextual names for saved parcel label DPF files.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-4-2" class="change-log__headline">2.3.4.2 ({{'2019-01-15' | shpLocalizeDate:'L' }}, hotfix)</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Protocols for selected shipments/parcels are now correctly printed.</li>
        <li>Labels now aren't printed twice on thermal printer when "Remember last A4 paper print position" isn't checked.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-4-1" class="change-log__headline">2.3.4.1 ({{'2019-01-09' | shpLocalizeDate:'L' }}, hotfix)</h3>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>Added some missing translations.</li>
        <li>Fixed generation of the tracking link for collection requests with reference.</li>
      </ng-container>
    </ul>
  </div>


  <div class="change-log__version">
    <h3 id="2-3-4-0" class="change-log__headline">2.3.4.0 ({{'2019-01-07' | shpLocalizeDate:'L' }})</h3>
    <h4 class="change-log__sub-headline">{{ 'changes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li>It's now possible to have recipients bound to single pick-up address. There is a setting in Global/Customer setting -> Business logic in Shipper Admin for BUs that requested this functionality. Customer's administrator can move recipients between the pickup addresses or make them global.</li>
        <li *ngIf="isOffline">It's possible to back-up and restore the DPD.db database file of Offline Shipper by admin user. The functionality can be found in Settings -> Backup and Restore.</li>
        <li>When "Remember last A4 paper printing position" isn't selected, an dialog to specify position is displayed before printing when printing on A4 paper on laser printer or PDF.</li>
        <li>It's possible to show customer reference field in collection request order (Global setting in UI part).</li>
        <li *ngIf="isCentral">For BU who requested in, in collection request unique WebNr is generated for each order.</li>
        <li>It's possible to show Creation date column and filter by it in Shipments grid view (parcels list) and Creation date in shipments compact view. This is turned on by Global setting -> UI -> Show creation date and time in shipments list and parcels grid.</li>
        <li>GRDB updated to version 19010706. Also thanks to this, offline installer is smaller by nearly 100 MB.</li>
        <li *ngIf="isOffline">It's possible to notify about new version of Shipper (installer) to download.</li>
      </ng-container>
    </ul>
    <h4 class="change-log__sub-headline">{{ 'fixes' | shpLocalize }}</h4>
    <ul class="change-log__list" [ngSwitch]="currentLanguageCode">
      <ng-container *ngSwitchDefault>
        <li *ngIf="isOffline">Optimized senderscan search speed in case of search by parcel reference.</li>
        <li>Order editor screen will load even when COD additional service isn't defined or was deleted.</li>
        <li>Reload of page should now always load new Shipper version if user clicked "Reload" in the notification message box about new version available. We were unable to reproduce this issue, but the changes should ensure this.</li>
      </ng-container>
    </ul>
  </div>
</div>
