import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, BehaviorSubject, Observable, combineLatest } from "rxjs";

import * as _ from "lodash";

import { DialogRef, ModalComponent, ModalOpenContext } from "ngx-modialog-7";

import * as Shared from "../../shared/index";
import * as ContextModel from "../../shared/models/context.model";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-pickup-address-selector-dialog",
  templateUrl: "./pickup-address-selector.component.html"
})
export class PickupAddressSelectorDialogComponent implements ModalComponent<ModalOpenContext>, OnInit {
  public isBusy = false;


  /**
  * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
  */
  public beforeDismiss(): boolean | Promise<boolean> { return true; }


  /**
   * Vracia, či nie je možné dialóg zatvoriť cez .close().
   */
  public beforeClose(): boolean | Promise<boolean> { return false; }

  private _contexts: BehaviorSubject<ContextModel.Context[]> = new BehaviorSubject([]);
  public contexts: Observable<ContextModel.Context[]> = this._contexts.asObservable();

  public selectedContext: ContextModel.Context = null;
  private _originalSelectedContext: ContextModel.Context = null;

  private _contextSubscription: Subscription = null;


  public ngOnInit() {
    this._contextSubscription = combineLatest([
      this._contextService.addresses,
      this._contextService.customerDetails,
      this._contextService.currentAddress
    ]).subscribe(result => {
        // Musíme vyskladať kontexty.
        let contexts: ContextModel.Context[] = [];

        let currentAddress = result[2];

        let addresses = result[0];
        let customerDetails = result[1];

        addresses.forEach(address => {
          let customerDetail = _.find(customerDetails, cd => cd.id === address.customerDetailId);

          if (customerDetail != null) {
            let newContext: ContextModel.Context = { customerDetail: customerDetail, address: address };

            contexts.push(newContext);

            // O aktuálne vybranom kontexte potrebujeme vedieť.
            if (newContext.address.id === currentAddress.id) {
              this.selectedContext = newContext;
              this._originalSelectedContext = newContext;
            }
          }
        });

        this._contexts.next(contexts);
      });
  }


  public ngOnDestroy() {
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
  }


  public close = () => {
    this.dialog.close(this.selectedContext);
  }


  public cancel = () => {
    this.dialog.close(this._originalSelectedContext);
  }


  public selectContext(newContext: ContextModel.Context) {
    this.selectedContext = newContext;
  }


  constructor(
    public dialog: DialogRef<ModalOpenContext>,
    private _loggingService: Shared.LoggingService,
    private _localizationService: Shared.LocalizationService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _authenticationService: Shared.AuthenticationService,
    private _router: Router,
    private _contextService: Shared.ContextService
  ) {

  }
}