<ul class="language-bar">
  <li *ngIf="isLocalizationAllowed('EN')">
    <button title="English" id="lang-switch-en" (click)="switchToEnglish()"><span class="flag flag-gb"></span></button>
  </li>
  <li *ngIf="isLocalizationAllowed('DE')">
    <button title="Deutsch" id="lang-switch-de" (click)="switchToGerman()"><span class="flag flag-de"></span></button>
  </li>
  <li *ngIf="isLocalizationAllowed('NL')">
    <button title="Dutch" id="lang-switch-nl" (click)="switchToDutch()"><span class="flag flag-nl"></span></button>
  </li>
  <li *ngIf="isLocalizationAllowed('BE')">
    <button title="Dutch (Belgium)" id="lang-switch-be" (click)="switchToBelgianDutch()"><span class="flag flag-be"></span></button>
  </li>
  <li *ngIf="isLocalizationAllowed('FR')">
    <button title="Français" id="lang-switch-fr" (click)="switchToFrench()"><span class="flag flag-fr"></span></button>
  </li>
  <li *ngIf="isLocalizationAllowed('IT')">
    <button title="Italiano" id="lang-switch-it" (click)="switchToItalian()"><span class="flag flag-it"></span></button>
  </li>
  <li *ngIf="isLocalizationAllowed('CS')">
    <button title="Čeština" id="lang-switch-cs" (click)="switchToCzech()"><span class="flag flag-cz"></span></button>
  </li>
  <li *ngIf="isLocalizationAllowed('SK')">
    <button title="Slovenčina" id="lang-switch-sk" (click)="switchToSlovak()"><span class="flag flag-sk"></span></button>
  </li>
</ul>
