import { Component, Input } from "@angular/core";

import { SELECTOR_PREFIX } from "../prefix";


@Component({
  selector: SELECTOR_PREFIX + "-retry-overlay",
  templateUrl: "./retry-overlay.component.html"
})
export class RetryOverlayCompoent {
  @Input()
  public errorMessage = "";


  @Input()
  public reloadFunction: Function = null;


  @Input()
  public isVisible = false;


  public executeReload = () => {
    if (this.reloadFunction != null) {
      this.reloadFunction();
    }
  }
}