import { Component, OnDestroy, OnInit } from "@angular/core";

import { DialogRef, ModalComponent } from "ngx-modialog-7";
import { combineLatest, Subject } from "rxjs";
import { filter, finalize, takeUntil } from "rxjs/operators";


import * as Shared from "../../shared/index";
import { AuthenticationService } from "../../shared/index";
import * as CustomerModel from "../../shared/models/customer.models";
import { ShipmentService } from "../services/shipments.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-export-all-shipments-dialog",
  templateUrl: "./export-all-shipments-dialog.component.html"
})
export class ExportAllShipmentsDialogComponent implements ModalComponent<any>, OnInit, OnDestroy {
  isBusy = false;
  statusMessage = "";
  isCustomerService = false;

  private _currentAddress: CustomerModel.Address = null;
  private _destroy$ = new Subject<void>();
  private _tenantId: number = null;

  constructor(
    public dialog: DialogRef<any>,
    private _authenticationService: AuthenticationService,
    private _contextService: Shared.ContextService,
    private _shipmentsService: ShipmentService,
    private _localizationService: Shared.LocalizationService
  ) {}


  public ngOnInit() {
    combineLatest([
      this._contextService.tenantId.pipe(filter(v => v != null)),
      this._contextService.currentAddress.pipe(filter(v => v != null)),
      this._authenticationService.isCustomerService$
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(results => {
      [
        this._tenantId,
        this._currentAddress,
        this.isCustomerService
      ] = results;
    });
  }


  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  public close() {
    this.dialog.close();
  }


  public exportParcels(exportSetType: string) {
    this.isBusy = true;
    this.statusMessage = this._localizationService.getLocalizedString("status_message_generating_csv_export");

    this._shipmentsService.exportParcelsAsMultipleCsv({
      customerDetailId: this._currentAddress.customerDetailId,
      tenantId: this._tenantId,
      daysOffset: 365,
      exportAllShipments: true,
      manualExportShipmentsSetType: exportSetType,
      parcelIds: null,
      orderIds: null,
      rememberShipmentsWereExported: false,
      senderCustomId: this._currentAddress.customId,
      csvColumnDelimiter: null,
      csvRowDelimiter: null,
      importProfileId: null
    }).pipe(
      finalize(() => {
        this.isBusy = false;
        this.statusMessage = "";
      })
    ).subscribe(() => { }, () => { });
  }
}
