/**
 * Záznam z aplikačného logu (je to z COP-u).
 */
export interface LogEntry {
  timestamp: Date;
  category: EventCategory;
  componentId: string;
  eventCode: string;
  eventData: Object;
  severity: EventSeverity;
  threadId: number;
  exeName: string;
  machineName: string;
  processExecutionId: string;
}


export enum EventSeverity {
  Error,
  Warning,
  Info,
  Verbose
}


export enum EventCategory {
  BusinessLogic,
  Infrastructure
}