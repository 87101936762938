import { Pipe, PipeTransform } from "@angular/core"

import * as Shared from "../index"
import { SELECTOR_PREFIX } from "../prefix";


@Pipe({
  name: SELECTOR_PREFIX + "LocalizedPartOfString"
})
export class LocalizedPartOfStringPipe implements PipeTransform {
  constructor(private _localizationService: Shared.LocalizationService) { }


  public transform(value: string): string {
    return this._localizationService.getLocalizedPartOfString(value);
  }
}