import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { filter, map, mergeMap, shareReplay, switchMap, take } from "rxjs/operators";

import * as Shared from "../../shared/index"

import { ImportProfile, ImportProfileContainer, ImportProfileFieldDescription, ImportProfileGroup, ImportProfileImportModel } from "../models/import-profiles.models"

// import * as csvpapaparse from "papaparse";

import { Papa } from "ngx-papaparse";
import * as xlsx from "xlsx";
import { ImportProfileTransformation, ImportProfileTransformationModel, ImportProfileTransformationImportModel } from "../models/transformations.models";
import { ExportModel } from "../../shipments/models/shipment.model";
import { CodeBasedConfigDataBase } from "../../shared/models/config.models";
import { ContextService } from '../../shared/index';

export const TYPE_CODE_CONSTANT = "ConstantValue";
export const TYPE_CODE_FIELD = "Field";
export const FILE_TYPE_CSV = "csv";


export interface CsvPapaParse {
  data: any;
  error: any;
  meta: any;
}

const CACHE_SIZE = 1;

interface ImportProfilesServiceCache {
  transformationTypes?: Observable<CodeBasedConfigDataBase[]>;
}

export const IMPORT_PROFILE_TYPES = {
  shipments: { prefix: "shipments", name: "Shipments" },
  shipmentsCR: { prefix: "shipmentsCR", name: "ShipmentsCR" },
  recipients: { prefix: "recipients", name: "Recipients" }
};

// settup determiner of columns to ';' , default is ','
// https://github.com/SheetJS/js-xlsx/blob/d7ecca0e8b3dc04183fca309e5e3d87f6c4f8197/bits/90_utils.js#L137
export const DEFAULT_CONCVERT_TO_CSV_OPS = {
  FS: ';',
  RS: '\r\n'
}


@Injectable()
export class ImportProfilesService extends Shared.ApiServiceBase {
  constructor(loggingService: Shared.LoggingService, private _contextService: ContextService, private _http: HttpClient, private papa: Papa) {
    super(loggingService);

    this.papa.badDelimiters.forEach((item, idx) => {
      if (item === '"') {
        this.papa.badDelimiters[idx] = '';
      }
    });
  }

  private _cache: ImportProfilesServiceCache = {};

  private _importProfileType = new BehaviorSubject<any>(IMPORT_PROFILE_TYPES.shipments);
  public currentImportProfile = this._importProfileType.asObservable();

  public changeImportProfile(type: string) {
    this._importProfileType.next(IMPORT_PROFILE_TYPES[type]);
  }

  public doDelete(importProfileId) {
    return this.processRequest<Array<ImportProfile>>(this._http.delete(`${Shared.API_URL}/import-profile/${importProfileId}`));
  }

  public emptyImportProfile(type: string) {
    return this.processRequest<ImportProfile>(this._http.get(`${Shared.API_URL}/import-profile/empty/${type}`));
  }

  public saveNewImportProfile(importProfile: ImportProfileContainer) {

    return this.processRequest<ImportProfileContainer>(this._http.post(`${Shared.API_URL}/import-profile/new`, importProfile));
  }

  public updateImportProfile(importId: number, importProfile: ImportProfileContainer) {
    return this.processRequest<ImportProfileContainer>(this._http.post(`${Shared.API_URL}/import-profile/${importId}`, importProfile));
  }

  public loadImportProfile(importId: number) {
    return this.processRequest<ImportProfileContainer>(this._http.get(`${Shared.API_URL}/import-profile/${importId}`));
  }

  public loadListOfImportProfiles() {
    return this.processRequest<Array<ImportProfile>>(this._http.get(`${Shared.API_URL}/import-profile/list`));
  }

  public loadListOfImportProfilesByType(typeCode: string) {
    return forkJoin([
      this._contextService.currentCustomerDetail.pipe(
        filter(value => value != null),
        map(cd => cd.id),
        take(1)
      ),
      this._contextService.isCustomerLevelTenant$.pipe(take(1)),
      this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/users/isadmin`))
    ]).pipe(
      switchMap(([cdId, isCustomerLevelTenant, isAdmin]: [number, boolean, boolean]) => {
        return isCustomerLevelTenant && !isAdmin
          ? this.loadListOfImportProfilesForCustomerId(cdId, typeCode)
          : this.processRequest<Array<ImportProfile>>(this._http.get(`${Shared.API_URL}/import-profile/list/${typeCode}`))
      })
    )

    //return this.processRequest<Array<ImportProfile>>(this._http.get(`${Shared.API_URL}/import-profile/list/${typeCode}`));
  }

  public loadListOfImportProfilesForCustomerId(customerId: number, typeCode: string) {
    return this.processRequest<Array<ImportProfile>>(this._http.get(`${Shared.API_URL}/import-profile/${customerId}/list/${typeCode}`));
  }

  public generateEditGroupsFromFieldMappings(groups: Array<ImportProfileGroup>) {
    let fieldValues = [];

    // user have to option select nothing
    // othervise is not change keep konfig empty
    fieldValues.push('');

    groups.forEach((group: ImportProfileGroup) => {
      group.fieldMappings.forEach((field: ImportProfileFieldDescription) => {

        if (field.mappingTypeCode == "ConstantValue") {
          field.valueConstant = field.value;
          field.valueField = '';

        } else {
          field.valueField = field.value;
          field.valueConstant = '';

          if (field.value && fieldValues.indexOf(field.value) == -1) {
            fieldValues.push(field.value);
          }
        }

      });
    });

    return fieldValues;
  }


  public parseCSV(csvFileContent, config) {
    return new Observable<CsvPapaParse>((observer) => {

      const parserConfig = {
        ...config,
        complete: (parsed) => {
          observer.next(parsed);
        }
      };

      this.papa.parse(csvFileContent, parserConfig);
    });
  }


  public convertFileToCSVString(selectedFile: File, selectedFileName: string, encoding: string) {
    return new Observable<any[]>((observer) => {
      // Cez FileReader (IE 10+, ostatné prehliadače OK) si načítame súbor z disku.
      var fileReader = new FileReader();

      var normalizedFileName = selectedFileName.toLocaleLowerCase();
      var isCsv = !(normalizedFileName.endsWith(".xls") || normalizedFileName.endsWith(".xlsx")); // Hocičo, čo nie je xls(x) je CSV.


      if (!fileReader) {
        observer.error();
        return;
      }

      fileReader.onloadend = () => {

        let csvFileContent;

        if (isCsv) {
          // if is type CSV take just conent
          csvFileContent = fileReader.result;
          observer.next([csvFileContent, FILE_TYPE_CSV]);
        } else {
          try {
            // 1. step parse content as xlsx, xlx, etc
            // by https://github.com/SheetJS/js-xlsx
            let workbook = xlsx.read(fileReader.result, { type: "binary" });
            let first_sheet_name = workbook.SheetNames[0];

            // Get worksheet
            let worksheet = workbook.Sheets[first_sheet_name];

            // 2. step sheet data convert to csv string
            csvFileContent = xlsx.utils.sheet_to_csv(worksheet, DEFAULT_CONCVERT_TO_CSV_OPS);
            var extension = "";

            var fileNameParts = normalizedFileName.split(".");

            extension = fileNameParts[fileNameParts.length - 1];

            observer.next([csvFileContent, extension]);
          }
          catch (ex) {
            this.loggingService.logErrorData(ex);

            observer.error();
          }
        }
      }

      fileReader.onerror = () => {

        observer.error();
      }

      fileReader.onabort = () => {
        observer.error();
      }

      if (isCsv) {
        fileReader.readAsText(selectedFile, encoding);
      } else {
        // the XLSX library doesn't hadle text output
        fileReader.readAsBinaryString(selectedFile);
      }
    });
  }


  public saveDynamicImport = (model: any) => {
    return this.processRequest<any>(this._http.post(Shared.API_URL + "/import-profile/dynamic-imports", model));
  }

  public getImportProfileExport(importId: number) {
    return this.processRequest<ExportModel>(this._http.get(`${Shared.API_URL}/import-profile/${importId}/export`));
  }

  public importImportProfile(model: ImportProfileImportModel) {
    return this.processRequest<any>(this._http.post(`${Shared.API_URL}/import-profile/import`, model));
  }


  // TRANSFORMÁCIE

  public getTransformationTypes(): Observable<CodeBasedConfigDataBase[]> {
    if (!this._cache.transformationTypes) {
      this._cache.transformationTypes =
          this.processRequest<CodeBasedConfigDataBase[]>(
            this._http.get(`${Shared.API_URL}/import-profile/transformation-types`)
          ).pipe(
            shareReplay(CACHE_SIZE)
          )
    }

    return this._cache.transformationTypes;
  }

  /**
   * Vráti transformácie pre aktuálneho tenanta.
   */
  public getTransformations() {
    return this.processRequest<ImportProfileTransformation[]>(this._http.get(`${Shared.API_URL}/import-profile/transformations`));
  }


  public getTransformation(id: number) {
    return this.processRequest<ImportProfileTransformationModel>(this._http.get(`${Shared.API_URL}/import-profile/transformations/${id}`));
  }


  public getTransformationExport(id: number) {
    return this.processRequest<ExportModel>(this._http.get(`${Shared.API_URL}/import-profile/transformations/${id}/export`));
  }


  public saveImportProfileTransformation(model: ImportProfileTransformationModel) {
    if (model.transformation.id === 0) { // Nová
      return this.processRequest<ImportProfileTransformationModel>(this._http.post(`${Shared.API_URL}/import-profile/transformations/`, model));
    } else { // Existujúca
      return this.processRequest<ImportProfileTransformationModel>(this._http.put(`${Shared.API_URL}/import-profile/transformations/${model.transformation.id}`, model));
    }
  }


  public importImportProfileTransformation(model: ImportProfileTransformationImportModel) {
    return this.processRequest<any>(this._http.post(`${Shared.API_URL}/import-profile/transformations/import`, model));
  }


  public deleteTransformation(id: number) {
    return this.processRequest<any>(this._http.delete(`${Shared.API_URL}/import-profile/transformations/${id}`));
  }
}
