import { Location } from "@angular/common";
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { default as DeepDiff } from "deep-diff";
import * as _ from "lodash";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { EMPTY, merge, Subject } from "rxjs";
import { auditTime, distinctUntilChanged, filter, finalize, map, mergeMap, startWith, take, takeUntil } from "rxjs/operators";

import { atLeastOneFrom, validateEmail, validateMatches } from "../..//shared/validators";
import { getMergedModelWithFormValue, toggleControl } from "../../shared/form-helpers";
import { DEMO_TENANT_CODE } from "../../shared/globals";
import * as Shared from "../../shared/index";
import { ShipperSettingsService } from "../../shared/index";
import * as CustomerModels from "../../shared/models/customer.models";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import { ShipperWizardService, ShipperWizardUserAction } from "../../shared/services/shipper-wizard.service";
import * as UserModel from "../models/user.model";
import { UsersService } from "../services/users.service";
import { UserRelatedItemListEditorBase } from "./user-related-item-list-editor-base";

const additionalInsightsRoleOptions: SelectItem[] = [...UserModel.additionalInsightsRoles].map(r => ({value: r, label: "role_" + r}));

@Component({
  selector: Shared.SELECTOR_PREFIX + "-user-editor",
  templateUrl: "./user-editor.component.html"
})
export class UserEditorComponent extends Shared.RoutedPageComponentBase implements OnInit, AfterViewInit, OnDestroy {
  currentCutomerDetailId = 0;
  currentUserId = 0;
  customers: CustomerModels.CustomerDetail[] = [];
  exceptionInfo: Shared.ExceptionInfo = null;
  insightsRoleOptions = additionalInsightsRoleOptions;
  isBusy = false;
  isUserSettingsEditorDisplayedForWizard = false;
  passwordForm: FormGroup;
  passwordValidationExceptionInfos: Shared.ExceptionInfo[] = [];
  tenantCode = "";
  user: UserModel.UserModel | null = null;
  userForm: FormGroup;
  userInsightsDisplayRoles: UserModel.UserRole[] = [];
  userShipperDisplayRoles: UserModel.UserRole[] = [];
  userRoles = UserModel.UserRole;

  isAdmin = false;
  isMasterAdmin = false;
  isCentralShipper = false;
  isCusomterService = false;
  isCustomerLevelTenant = false;
  isInsightsAllowed = false;
  isEditedUserMasterAdmin = false;

  canManageCustomerServiceUsers = false;
  canManageCustomerUsers = false;
  canManageInsightsUsers = false;
  canManageShipperUsers = false;

  @ViewChildren(UserRelatedItemListEditorBase)
  relatedItemsListEditors: QueryList<UserRelatedItemListEditorBase>;

  private readonly destroy$ = new Subject<void>();
  private modelSnapshot: any | null = null;
  private hasModelChanges = false;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _fb: FormBuilder,
    private _shipperSettingsService: ShipperSettingsService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _modal: Modal,
    private _usersService: UsersService,
    private _exceptionHandlerService: Shared.ExceptionsHandlerService,
    private _toastr: ToastrService,
    private _contextService: Shared.ContextService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _wizardService: WizardService,
    private _shipperWizardService: ShipperWizardService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
    this.buildUserForm();
    this.buildPasswordForm();
  }

  get isNewUser() {
    return this.user == null;
  }

  get isSelf(): boolean {
    return this.user?.id === this.currentUserId;
  }

  get isShipperRoleEditorDisplayed(): boolean {
    return (!this.isCustomerLevelTenant || this.isCustomerLevelTenant && this.canManageShipperUsers) &&
      !this.isCusomterService &&
      !this.isEditedUserMasterAdmin;
  }

  get isCustomerRoleEditorDisplayed(): boolean {
    return this.isCustomerLevelTenant &&
      !this.isCusomterService &&
      !this.isEditedUserMasterAdmin;
  }

  get isInsightsDisplayed(): boolean {
    return this.isInsightsAllowed &&
      this.isCentralShipper &&
      !this.isCustomerLevelTenant &&
      !this.isCusomterService &&
      !this.isEditedUserMasterAdmin;
  }

  get isRelatedItemsSectionDisplayed(): boolean {
    return !this.isNewUser &&
      !this.isEditedUserMasterAdmin &&
      !this.isEditedUserShipperAdmin &&
      !this.isEditedUserCustomerAdmin &&
      !this.isCusomterService;
  }

  get isUserRelatedAddressesDisplayed(): boolean {
    return (this.hasEditedUserShipperAccess && this.canManageShipperUsers ||
        this.hasEditedUserCustomerAccess && this.canManageCustomerUsers ||
        this.hasEditedUserInsightsAccess && this.canManageInsightsUsers) &&
      !this.isEditedUserInsightsAdmin;
  }

  get isUserRelatedBandAccoutnsDisplayed(): boolean {
    return this.hasEditedUserShipperAccess && this.canManageShipperUsers ||
      this.hasEditedUserCustomerAccess && this.canManageCustomerUsers;
  }

  get isUserRelatedProductsDisplayed(): boolean {
    return this.hasEditedUserShipperAccess && this.canManageShipperUsers ||
      this.hasEditedUserCustomerAccess && this.canManageCustomerUsers;
  }

  get isEditedUserShipperAdmin(): boolean {
    return this.userForm.get("shipperMainRole")?.value === UserModel.UserRole.TenantAdmin;
  }

  get isEditedUserShipperUser(): boolean {
    return this.userForm.get("shipperMainRole")?.value === UserModel.UserRole.TenantUser;
  }

  get hasEditedUserShipperAccess(): boolean {
    return this.isEditedUserMasterAdmin ||
      (Boolean(this.userForm.get("shipperMainRole")?.value) ?? false);
  }

  get isEditedUserCustomerAdmin(): boolean {
    return this.userForm.get("customerMainRole")?.value === UserModel.UserRole.CustomerAdmin;
  }

  get hasEditedUserCustomerAccess(): boolean {
    return Boolean(this.userForm.get("customerMainRole")?.value) ?? false;
  }

  get isEditedUserInsightsAdmin(): boolean {
    return this.userForm.get("insightsMainRole")?.value === UserModel.UserRole.InsightsAdmin;
  }

  get isEditedUserInsightsUser(): boolean {
    return this.userForm.get("insightsMainRole")?.value === UserModel.UserRole.InsightsUser;
  }

  get hasEditedUserInsightsAccess(): boolean {
    return this.isEditedUserMasterAdmin ||
      this.isEditedUserInsightsAdmin ||
      this.isEditedUserInsightsUser;
  }

  ngOnInit(): void {
    this.isCentralShipper = this._shipperSettingsService.isCentralShipper;

    this._businessUnitSettingsService.getShowInsightsInShipper()
      .subscribe(result => this.isInsightsAllowed = result);

    this.authenticationService.isCustomerService$.pipe(take(1))
      .subscribe(value => this.isCusomterService = value);

    this.authenticationService.isAdmin$.pipe(take(1))
      .subscribe(value => this.isAdmin = value);

    this.authenticationService.isMasterAdmin$.pipe(take(1))
      .subscribe(value => this.isMasterAdmin = value);

    this.authenticationService.currentUserAllowedOperations$.pipe(take(1))
      .subscribe(operations => {
        this.canManageShipperUsers =
            operations.has(UserModel.UserOperation.ManageShipperUsers);
        this.canManageCustomerUsers =
            operations.has(UserModel.UserOperation.ManageCustomerUsers);
        this.canManageCustomerServiceUsers =
            operations.has(UserModel.UserOperation.ManageCustomerServiceUsers);
        this.canManageInsightsUsers =
            operations.has(UserModel.UserOperation.ManageInsightsUsers);
      });

    this._contextService.isCustomerLevelTenant$.pipe(take(1))
      .subscribe(value => {
        this.isCustomerLevelTenant = value;

        if (value) {
          this.loadCustomers();
        }
      });

    this.authenticationService.user.pipe(
      takeUntil(this.destroy$),
      filter(u => Boolean(u))
    ).subscribe(user => {
      this.tenantCode = user.login.split("@")[1];

      if (this.tenantCode === DEMO_TENANT_CODE) {
        this.hasUnsavedChanges = () => false;
        this.goBackToListOfUsers();
      }

      this.currentUserId = Number(user.id);
    });

    this._contextService.currentCustomerDetail.pipe(
      takeUntil(this.destroy$),
      filter(ca => Boolean(ca))
    ).subscribe(ca => this.currentCutomerDetailId = ca.id);

    this.observeRoute();
  }


  ngAfterViewInit() {
    this.observeWizard();
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  hasUnsavedChanges = (): boolean => {
    if (this._wizardService.isActive) {
      return false;
    }

    return this.hasModelChanges;
  }


  goBackToListOfUsers() {
    if (this._wizardService.isActive) {
      this._wizardService.completeStep(WizardStepName.NewUser);
    } else {
      this.router.navigate(["/settings/user-management"]);
    }
  }


  private buildUserForm() {
    this.userForm = this._fb.group({
      name: ["", {validators: [
        Validators.required,
        Validators.maxLength(50)
      ]}],
      loginName: ["", {validators: [
        Validators.required,
        Validators.maxLength(35)
      ]}],
      customerDetailId: null,
      email: ["", {validators: [
        Validators.required, // Only for central
        validateEmail,
        Validators.maxLength(100)
      ]}],
      languageCode: "EN",
      password: ["", {validators: [
        Validators.required,
        Validators.maxLength(50)
      ]}],
      passwordConfirmation: ["", {validators: [
        Validators.required,
        validateMatches("password")
      ]}],
      shipperMainRole: null,
      customerMainRole: null,
      insightsMainRole: null,
      insightsRoles: [[]],
      isDisabled: false
    });

    this.observeMainRoleChanges();

    this.setModelSnapshot();
    this.userForm.valueChanges.subscribe(() => this.setHasModelChanges());
  }


  private setUserFormValidators() {
    const groupValidators = [];

    if (this.isInsightsDisplayed &&
        (this.canManageShipperUsers || this.canManageInsightsUsers)) {
      groupValidators.push(atLeastOneFrom("shipperMainRole", "insightsMainRole"));
    }

    this.userForm.setValidators(groupValidators);

    const emailCtrl = this.userForm.get("email");
    const emailValidators = [
      validateEmail,
      Validators.maxLength(100)
    ];

    if (this.isCentralShipper) {
      emailValidators.push(Validators.required);
    }

    emailCtrl?.setValidators(emailValidators);
  }


  private getDisabledControlRules(): {[key: string]: boolean} {
    const isSelfOrMaster = this.isSelf || this.isEditedUserMasterAdmin;

    const disabledRules = {
      password: !this.isNewUser,
      passwordConfirmation: !this.isNewUser,
      loginName: !this.isNewUser || isSelfOrMaster,
      // email: this.isEditedUserMasterAdmin,
      shipperMainRole: isSelfOrMaster || !this.canManageShipperUsers,
      customerMainRole: isSelfOrMaster || !this.canManageCustomerUsers,
      insightsMainRole: isSelfOrMaster || !this.canManageInsightsUsers,
      insightsRoles: isSelfOrMaster || !this.canManageInsightsUsers,
      isDisabled: isSelfOrMaster ||
        (this.hasEditedUserInsightsAccess && !this.canManageInsightsUsers),
      customerDetailId: !this.canManageCustomerUsers || !this.hasEditedUserCustomerAccess
    };

    return disabledRules;
  }


  private toggleControl(key: string) {
    const rules = this.getDisabledControlRules();
    toggleControl(this.userForm.get(key), !rules[key]);
  }


  private toggleControls() {
    const rules = this.getDisabledControlRules();

    Object.keys(rules).forEach(key => {
      const ctrl = this.userForm.get(key);
      toggleControl(ctrl, !rules[key]);
    });
  }


  private observeMainRoleChanges() {
    const shipperMainRoleCtrl = this.userForm.get("shipperMainRole");
    const customerMainRoleCtrl = this.userForm.get("customerMainRole");
    const insightsMainRoleCtrl = this.userForm.get("insightsMainRole");

    merge(
      shipperMainRoleCtrl?.valueChanges.pipe(distinctUntilChanged()) ?? EMPTY,
      customerMainRoleCtrl?.valueChanges.pipe(distinctUntilChanged()) ?? EMPTY,
      insightsMainRoleCtrl?.valueChanges.pipe(distinctUntilChanged()) ?? EMPTY,
    ).pipe(
      auditTime(0)
    ).subscribe(() => {
      this.toggleControl("isDisabled");
      this.toggleControl("customerDetailId");
      this.toggleFormForEditingOnlyInsightsUsers();
      this.togglePasswordForm();
    });

    shipperMainRoleCtrl?.valueChanges.pipe(
      filter(() => this.isCustomerLevelTenant)
    ).subscribe(value => {
        if (value === UserModel.UserRole.TenantUser) {
          shipperMainRoleCtrl.setValue(null);

          if (!customerMainRoleCtrl.value) {
            customerMainRoleCtrl.setValue(UserModel.UserRole.CustomerUser);
          }
        } else if (value === UserModel.UserRole.TenantAdmin) {
          customerMainRoleCtrl.setValue(null);
        }
    });

    customerMainRoleCtrl?.valueChanges.pipe(
      filter(() => this.isCustomerLevelTenant)
    ).subscribe(value => {
      if (value && shipperMainRoleCtrl.value) {
        shipperMainRoleCtrl.setValue(null);
      }
    });
  }


  private toggleFormForEditingOnlyInsightsUsers() {
    if (!this.isInsightsDisplayed) {
      return;
    }

    if (!this.hasEditedUserShipperAccess && !this.canManageInsightsUsers) {
      this.userForm.disable();
      this.toggleControl("shipperMainRole");
    } else {
      this.userForm.enable();
      this.toggleControls();
    }
  }


  private updateForm() {
    if (this.isNewUser) {
      this.userForm.reset(this.computeFormModelForNewUser());
    } else {
      this.userForm.patchValue(this.computeFormModelForUser());
    }

    this.setUserFormValidators();
    this.toggleControls();

    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();
    this.setModelSnapshot();
  }


  private setHasModelChanges() {
    this.hasModelChanges = this.isNewUser ?
      this.userForm.dirty :
      Boolean(DeepDiff.diff(this.modelSnapshot, this.userForm.getRawValue())?.length);
  }


  private setModelSnapshot() {
    this.modelSnapshot = this.userForm.getRawValue();
    this.setHasModelChanges();
  }


  private buildPasswordForm() {
    this.passwordForm = this._fb.group({
      password: ["", {validators: [
        Validators.required,
        Validators.maxLength(50)
      ]}],
      passwordConfirmation: ["", {validators: [
        Validators.required,
        validateMatches("password")
      ]}],
    });
  }


  private togglePasswordForm() {
    const isDisabled =
        this.isInsightsDisplayed && !this.hasEditedUserShipperAccess && !this.canManageInsightsUsers ||
        this.isEditedUserMasterAdmin && !this.isSelf ||
        this.isUserSettingsEditorDisplayedForWizard;

    toggleControl(this.passwordForm, !isDisabled);
  }


  private loadCustomers() {
    this._contextService.customerDetails.subscribe(customers => {
      this.customers = customers;
    });
  }


  private observeRoute() {
    this._route.params.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      const userId = Number(params.userId);

      if (userId === 0) {
        this.initializeNewUser();
      } else {
        this.loadExistingUser(userId);
      }
    });
  }


  private initializeNewUser() {
    this._businessUnitSettingsService.getDisallowAddingNewUsers().subscribe(result => {
      if (result) {
        this.hasUnsavedChanges = () => false;
        this.goBackToListOfUsers();
      } else {
        this.updateForm();
      }
    });
  }


  private loadExistingUser(userId: number) {
    this.isBusy = true;
    this.exceptionInfo = null;
    this.passwordValidationExceptionInfos = [];

    this._usersService.getUser(userId).pipe(
      take(1),
      finalize(() => this.isBusy = false)
    ).subscribe(
      user => this.onUserLoad(user),
      ex => {
        this.loggingService.logErrorData(ex, "Error loading user");

        // Go to create new user if not found.
        if (this._wizardService.isActive) {
          this.router.navigate(["/settings/user-management/users/0"]);
        }
    });
  }


  private onUserLoad(user: UserModel.UserModel) {
    this.user = user;

    this.userShipperDisplayRoles = _.intersection(
      [...UserModel.shipperMainRoles],
      this.user.roles ?? []
    );

    this.userInsightsDisplayRoles = _.intersection(
      [...UserModel.insightsMainRoles, ...UserModel.additionalInsightsRoles],
      this.user.roles ?? []
    );

    // Don't show DPD Insights User role if the user has other Insights roles.
    // if (this.userInsightsDisplayRoles.length > 1 &&
    //     this.userInsightsDisplayRoles.includes(UserModel.UserRole.InsightsUser)) {
    //   _.pull(this.userInsightsDisplayRoles, UserModel.UserRole.InsightsUser);
    // }

    this.isEditedUserMasterAdmin = user.roles.includes(UserModel.UserRole.MasterAdmin);

    this.updateForm();
  }


  private computeFormModelForNewUser(): any {
    const result: {[key: string]: any} = {
      languageCode: "EN",
    };

    if (this.isCustomerLevelTenant) {
      result.customerDetailId = this.currentCutomerDetailId;
      result.shipperMainRole = null;
      result.customerMainRole = UserModel.UserRole.CustomerUser;
      result.insightsMainRole = null;
      result.insightsRoles = [];
    } else if (this.isCusomterService) {
      result.customerDetailId = null;
      result.shipperMainRole = null;
      result.customerMainRole = null;
      result.insightsMainRole = null;
      result.insightsRoles = [];
    } else {
      result.customerDetailId = null;
      result.shipperMainRole = UserModel.UserRole.TenantUser;
      result.customerMainRole = null;
      result.insightsMainRole = null;
      result.insightsRoles = [];
    }

    return result;
  }


  private computeFormModelForUser(): any {
    const userRoles = this.user?.roles ?? [];

    const result = {
      name: this.user?.name,
      loginName: this.user?.loginName,
      email: this.user?.email,
      languageCode: this.user?.languageCode,
      // shipperMainRole: _.intersection([...shipperMainRoles], userRoles)[0] ?? null,
      shipperMainRole: userRoles.includes(UserModel.UserRole.TenantAdmin) ?
        UserModel.UserRole.TenantAdmin :
        userRoles.includes(UserModel.UserRole.TenantUser) ?
          UserModel.UserRole.TenantUser :
          null,
      customerMainRole: userRoles.includes(UserModel.UserRole.CustomerAdmin) ?
        UserModel.UserRole.CustomerAdmin :
        userRoles.includes(UserModel.UserRole.CustomerUser) ?
          UserModel.UserRole.CustomerUser :
          null,
      insightsMainRole: userRoles.includes(UserModel.UserRole.InsightsAdmin) ?
        UserModel.UserRole.InsightsAdmin :
        userRoles.includes(UserModel.UserRole.InsightsUser) ?
          UserModel.UserRole.InsightsUser :
          null,
      insightsRoles: userRoles.filter(r => UserModel.additionalInsightsRoles.has(r as UserModel.UserRole)),
      roles: userRoles,
      customerDetailId: Boolean(this.user.customerDetailIds?.length) ?
        this.user.customerDetailIds[0] :
        this.currentCutomerDetailId,
      isDisabled: Boolean(this.user.disabledSinceDateUtc)
    };

    return result;
  }


  private computeUserModel(): UserModel.UserModel {
    const value = this.userForm.getRawValue();

    const model: Partial<UserModel.UserModel> = {
        name: value.name,
        loginName: value.loginName,
        email: value.email,
        languageCode: value.languageCode,
        disabledSinceDateUtc: value.isDisabled && !this.user?.disabledSinceDateUtc ? new Date() : null,
        password: "",
        roles: this.computeUserModelRoles(),
        customerDetailIds: value.customerDetailId ?
          [value.customerDetailId] :
          [this.currentCutomerDetailId],
        customerDetailId: this.currentCutomerDetailId
    };

    return getMergedModelWithFormValue(this.user, model);
  }


  private computeUserModelRoles(): UserModel.UserRole[] {
    // Master admin - cannot overwrite.
    if (this.isEditedUserMasterAdmin) {
      return [];
    }

    // Customer service (DPD CH) - only one role.
    if (this.isCusomterService) {
      return [UserModel.UserRole.CustomerService];
    }

    const value = this.userForm.getRawValue();

    // Customer tenant (DPD CH)
    if (this.isCustomerLevelTenant && this.canManageCustomerUsers) {
      switch (true) {
        case this.canManageShipperUsers &&
             value.shipperMainRole === UserModel.UserRole.TenantAdmin:
          return [UserModel.UserRole.TenantAdmin];
        case value.customerMainRole === UserModel.UserRole.CustomerAdmin:
          return [UserModel.UserRole.CustomerAdmin];
        default:
          return [UserModel.UserRole.CustomerUser];
      }
    }

    const roleIds: UserModel.UserRole[] =  [];

    // Insights + Shipper (DPD NL)
    if (this.isInsightsDisplayed) {
      if (value.shipperMainRole && this.canManageShipperUsers) {
        roleIds.push(value.shipperMainRole);
      }

      if (value.insightsMainRole && this.canManageInsightsUsers) {
        roleIds.push(value.insightsMainRole);

        if (value.insightsMainRole !== UserModel.UserRole.InsightsAdmin &&
            Array.isArray(value.insightsRoles)) {
          roleIds.push(...value.insightsRoles);
        }
      }

      return roleIds;
    }

    // Regular Shipper
    if (this.canManageShipperUsers) {
      const role = value.shipperMainRole === UserModel.UserRole.TenantAdmin ?
        UserModel.UserRole.TenantAdmin :
        UserModel.UserRole.TenantUser;

      roleIds.push(role);
      return roleIds;
    }

    return roleIds;
  }


  private computeNewUserModel(): UserModel.UserModel {
    const password = this.userForm.get("password")?.value;

    return {
      ...this.computeUserModel(),
      password
    };
  }


  deleteUser() {
    if (this.isNewUser) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("user_delete_confirmation_question"))
      .open()
      .result.then(value => {
        if (value) {
          this.isBusy = true;
          this.exceptionInfo = null;
          this.passwordValidationExceptionInfos = [];

          this._usersService.deleteUser(this.user?.id).subscribe(() => {
              // Nechceme notifikáciu o prípadných zmenách.
              this.hasUnsavedChanges = () => false;

              this.isBusy = false;

              this.goBackToListOfUsers();
              this._toastr.success(this.localizationService.getLocalizedString("user_deleted"));
            },
            ex => {
              this.isBusy = false;

              this.loggingService.logErrorData(ex, "User deleting failed");

              this.exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);
            });
        }
      }, () => {
        // ... nič nerobíme.
      });
  }


  saveUser() {
    this.isBusy = true;
    this.exceptionInfo = null;
    this.passwordValidationExceptionInfos = [];

    if (this.isNewUser) {
      this.createUser();
    } else {
      this.updateUser();
    }

    return false;
  }


  private createUser() {
    const userModel = this.computeNewUserModel();

    this._usersService.addUser(userModel).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(user => {
      this._location.replaceState("/settings/user-management/users/" + user.id);
      this.onUserLoad(user);

      this._shipperWizardService.addUserAction(ShipperWizardUserAction.SavedUser);
      this._shipperWizardService.setCreatedUser(user.id);
      this._wizardService.completeStep(WizardStepName.NewUser);
    },
    ex => {
      this.loggingService.logErrorData(ex, "User saving failed");

      this.passwordValidationExceptionInfos = this._usersService.resolvePasswordValidationExceptions(ex, this._exceptionHandlerService);

      if (!this.passwordValidationExceptionInfos) {
        this.exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);
      }
    });
  }

  private updateUser() {
    const userModel = this.computeUserModel();

    this._usersService.updateUser(userModel).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(user => {
      this._toastr.success(this.localizationService.getLocalizedString("user_saved"));

      this.onUserLoad(user);

      this._wizardService.goToNextStep();
    },
    ex => {
      this.loggingService.logErrorData(ex, "User saving failed");

      this.passwordValidationExceptionInfos = this._usersService.resolvePasswordValidationExceptions(ex, this._exceptionHandlerService);

      if (!this.passwordValidationExceptionInfos) {
        this.exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);
      }
    });
  }


  /** Uloží iba heslo používateľa. Použité iba pri už existujúcom používateľovi. */
  savePassword() {
    const newPassword = this.passwordForm.get("password")?.value;

    if (this.isNewUser || !newPassword) {
      return;
    }

    this.isBusy = true;
    this.exceptionInfo = null;
    this.passwordValidationExceptionInfos = [];

    this._usersService.changeUserPassword({
      userId: this.user.id,
      customerDetailId: this.currentCutomerDetailId,
      oldPassword: "",
      newPassword
    }).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => {
      this.passwordForm.reset();

      this._toastr.success(this.localizationService.getLocalizedString("user_password_changed"));
    }, ex => {
      this.loggingService.logErrorData(ex, "User's password saving failed");

      this.passwordValidationExceptionInfos = this._usersService.resolvePasswordValidationExceptions(ex, this._exceptionHandlerService);

      if (!this.passwordValidationExceptionInfos) {
        this.exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);
      }
    });
  }


  private observeWizard() {
    this._wizardService.state$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(state => {
      const currentStepName = state.currentStep?.name;
      this.isUserSettingsEditorDisplayedForWizard = currentStepName === WizardStepName.EditUser;
      this.togglePasswordForm();
    });

    this.relatedItemsListEditors.changes.pipe(
      startWith(0),
      filter(() => this._wizardService.isActive),
      mergeMap(() => {
        const emitters = this.relatedItemsListEditors.map(i => i.selectionChange);
        return merge(...emitters).pipe(takeUntil(this.relatedItemsListEditors.changes));
      }),
      map(() => this.relatedItemsListEditors.some(i => i.hasChanges)),
      takeUntil(this.destroy$),
    ).subscribe(hasChanges => this._shipperWizardService.hasUserEditorUnsavedListChanges = hasChanges);
  }
}
