import { ModalOpenContext } from "ngx-modialog-7";

import { Country, EntityDataBaseInteractive, EntitiesListDisplaySettings } from "../../shared/index";
import { ShipperObjectDataParserSettings } from "../../shared/models/shipper-object-data-parser-settings.model";
import { LogEntry } from "../../shared/models/log-entry.model";
import { ImportProfile } from "../../import-profiles/models/import-profiles.models";

export interface Recipient extends EntityDataBaseInteractive {
  customerRecipientTypeCode: string;
  name: string;
  name2: string;
  contactPerson: string;
  street: string;
  streetDetail: string;
  houseNr: string;
  zip: string;
  city: string;
  stateCode: string;
  countryCode: string;
  phone: string;
  fax: string;
  email: string;
  referenceNumber: string;
  comment: string;
  languageCode: string;
  addressId: number;
  vatNumber: string;
  eoriNumber: string;
  isUsedAsReceiver?: boolean;
  isUsedAsReturnReceiver?: boolean;
  isUsedAsCollectionRequestSender?: boolean;

  customerDetailId?: number;
  country?: Country;
}

export interface RecipientForGridView extends Recipient {
  countryName: string;
  canDelete: boolean;
  canUndelete: boolean;
}

/**
 * Obsahuje konštanty pre kódy typov adresátov (osoba, organizácia).
 */
export class CustomerRecipientTypeCode {
  public static B2B = "B2B";
  public static B2C = "B2C";
}


export interface RecipientsImportModel {
  settings: RecipientDataParserSettings;
  content: string;
}


export interface RecipientDataParserSettings extends ShipperObjectDataParserSettings {
  isEnabledUpdateByReferenceNumber: boolean;
  tenantId: number;
  importProfileId: number;
  pickupAddressId: number;
  customerDetailId: number;
  csvColumnDelimiter?: string;
  csvRowDelimiter?: string;
}


export interface RecipientsExportParametersModel {
  recipientIds: number[];
  pickupAddressId: number;
  customerDetailId: number;
}


export interface ExportModel {
  outputText: string;
  logEntries: LogEntry[];
}


export class ExportDialogModalContext extends ModalOpenContext {
  constructor(
    public recipientIds: number[]
  ) { super(); }
}


export class RecipientsImportDialogModalContext extends ModalOpenContext {
  constructor(
    public importProfiles: ImportProfile[]
  ) { super(); }
}


export interface ImportOutput {
  itemsProcessedSuccessfullyCount: number;
  itemsProcessedWithErrorCount: number;
  logEntries: LogEntry[];
}


export interface CityCheckParameters {
  countryCode: string;
  cityName: string;
  postCode: string;
  refDate: Date;
}


export interface RecipientsFilter extends EntitiesListDisplaySettings {
  tenantId: number;
  customerDetailId: number;
  addressId: number;
  searchText: string;
  extendedSearch: boolean;

  customerRecipientTypeCode: string;
  name: string;
  address: string;
  contactInformation: string;
  referenceNumber: string;

  showDeletedRecipients?: boolean; 
}
