import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";

import { SELECTOR_PREFIX, LoggingService, GlobalEventsStreamService, LocalizationService, Localization, NavigationEvents, AuthenticationService, ColorThemeService, PasswordService } from "./shared/index";
import { NavigationComponent } from "./shared/components/navigation.component";
import { CurrentUserComponent } from "./user/components/current-user.component";
import { AppInfoComponent } from "./shared/components/app-info.component";
import { ShipperTooltipService } from "./shared/services/shipper-tooltip.service";
import { NewsfeedService } from "./shared/services/newsfeed.service";
import { PollyService } from "./shared/services/polly.service";
import { ShipperWizardService } from "./shared/services/shipper-wizard.service";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";

@Component({
  selector: SELECTOR_PREFIX + "-app",
  templateUrl: "./app.component.html",
  providers: [],
  viewProviders: []
})
export class ShipperAppComponent {
  constructor(
    private _router: Router,
    private _loggingService: LoggingService,
    private _globalEventsStreamService: GlobalEventsStreamService,
    private _localizationService: LocalizationService,
    private _authenticationService: AuthenticationService,
    viewContainerRef: ViewContainerRef,
    private _colorThemeService: ColorThemeService,
    // private _shipperTooltipService: ShipperTooltipService,
    private _newsfeedService: NewsfeedService,
    private _passwordService: PasswordService,
    private _pollyService: PollyService,
    private _shipperWizardService: ShipperWizardService,
    private _googleAnalyticsService: GoogleAnalyticsService
  ) {
    this._colorThemeService.initTheme();
  }


  public ngOnInit(): any {

  }
}