import { Component } from "@angular/core";

import { DialogRef, ModalComponent } from "ngx-modialog-7";

@Component({
  templateUrl: "./new-version-info-dialog.component.html"
})
export class NewVersionInfoDialog implements ModalComponent<{version: string}> {
  constructor(public dialog: DialogRef<{version: string}>) {}

  get version(): string {
    return this.dialog.context.version;
  }

  close() {
    this.dialog.close();
  }
}
