import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import * as Shared from "../../shared/index"
import { AdminInstructionForGridView, AdminInstructionsSearchParameters } from "../models/instructions.model";
import { encodeAsQueryString, PagedResult } from "../../shared/index";

@Injectable()
export class InstructionsService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient
  ) {
    super(loggingService);
  }


  public searchAdminInstructions(params: AdminInstructionsSearchParameters) {
    var encodedParameters = encodeAsQueryString(params);
    if (params.isInformationSent == null || params.isInformationSent.toString() == "null"){
      let enparams = encodedParameters.split('&');
      enparams = enparams.filter(param => param !== "isInformationSent=null");
      encodedParameters = enparams.join('&');
    }
    return this.processRequest<PagedResult<AdminInstructionForGridView>>(this._http.get(`${Shared.API_URL}/admin-instructions/?${encodedParameters}`));
  }

  public getInstructionTypes() {
    return this.processRequest<string[]>(this._http.get(`${Shared.API_URL}/admin-instructions/instruction-types/`));
  }
}