import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import * as _ from "lodash";

import { ValidatorFn} from "@angular/forms";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Observable, of, Subscription, timer } from "rxjs";
import { distinctUntilChanged, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { ShipmentService } from "../shipments/services/shipments.service";
import { isEmptyInputValue } from "./form-helpers";

const emailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

function subscribeToConditionControl(control: AbstractControl, conditionControl: AbstractControl): void {
  conditionControl.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
    control.updateValueAndValidity({
      onlySelf: true,
    });
  });
}

export function validateEmail(c: AbstractControl): { [index: string]: any; } {

  if (typeof c.value === "undefined" || c.value == null || c.value === "") {
    return null;
  }

  return emailRegexp.test(c.value) ? null : { invalidEmail: true };
}


export function validateIban(c: AbstractControl): { [index: string]: any; } {
  if (typeof c.value === "undefined" || c.value == null || c.value === "") {
    return null;
  }

  const clearIban = c.value.split(" ").join("");
  return isValidIBANNumber(clearIban) ? null : { invalidIban: true };

}

// IBAN validator: https://gist.github.com/Bloggerschmidt/8bfeb84eeecd8b41b42c89ff26f5aa01
function isValidIBANNumber(input) {
  const CODE_LENGTHS = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
  };

  let iban = input.toUpperCase().replace(/[^A-Z0-9]/g, ""),
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
          digits;

  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
  }

  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
      return letter.charCodeAt(0) - 55;
  });

  return mod97(digits);
}

function mod97(string) {
  let checksum = string.slice(0, 2), fragment;

  for (let offset = 2; offset < string.length; offset += 7) {
      fragment = checksum + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
}


export function validateMultipleEmails(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const emails = control.value
    .match(/[^,;\s]+/g);

  const invalidEmails = emails.filter(email => !emailRegexp.test(email));

  return invalidEmails.length ? {invalidEmails} : null;
}


export function uniqueMapTransformationSourceValue(control: FormArray): ValidationErrors | null {
  const value = control.value;

  const sourceValueIndexes = value.reduce((acc, curr, idx) => {
    const sourceValue = curr.sourceValue;
    acc[sourceValue] = acc[sourceValue] ? [...acc[sourceValue], idx] : [idx];
    return acc;
  }, {});

  const duplicates = _.pickBy(sourceValueIndexes, item => item.length > 1);

  if (_.isEmpty(duplicates)) {
    return null;
  }

  const indexes = Object.keys(duplicates).reduce((acc, key) => {
    const duplicateIndexes = duplicates[key];

    duplicateIndexes.forEach((duplicateIdx, idx) => {
      acc[duplicateIdx] = {
        value: key,
        duplicatesAt: _.without(duplicateIndexes, duplicateIdx),
        isFirst: !!idx
      };
    });

    return acc;
  }, {});

  const result = {
    uniqueMapTransformationSourceValue: {
      duplicates,
      ...indexes
    }
  };

  return result;
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function phoneNumberValidator(regionCode: string = undefined): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeof control.value === "undefined" || control.value == null || control.value === "") {
      return null;
    }

    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        control.value, regionCode
      );

      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (e) { }

    return validNumber ? null : { wrongNumber: { value: control.value } };
  };
}


export function validateAlphaNumeric(control: AbstractControl): ValidationErrors | null {
  if (typeof control.value === "undefined" || control.value == null || control.value === "") {
    return null;
  }

  const hasError = Validators.pattern(/^[a-zA-Z0-9]+$/)(control);

  return hasError ? {alphaNumeric: true} : null;
}

export function validatePropertyNoSpaces(path: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = _.get(control.value, path);

    const containsSpace = typeof value === "string" &&
      value.length &&
      value.includes(" ");

    return containsSpace ? {noSpaces: true} : null;
  };
}

function containsStringSpaces(str: string): boolean {
  return typeof str === "string" && str.length && str.includes(" ");
}

export function validateNoSpaces(control: AbstractControl): ValidationErrors | null {
  return containsStringSpaces(control.value) ? {noSpaces: true} : null;
}

/** Special no spaces validator for zip controls which can store the value in an object. */
export function validateZipNoSpaces(control: AbstractControl): ValidationErrors | null {
  const zip = _.isPlainObject(control.value) ? control.value.zip : control.value;

  return containsStringSpaces(zip) ? {noSpaces: true} : null;
}

export function validateZip(
    shipmentService: ShipmentService,
    countryCtrl: string,
    cityCtrl: string,
    debounceTime: number = 1000): AsyncValidatorFn {
  let lastValues;
  let lastResult;

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!(control instanceof FormControl) ||
        !control.parent.get(countryCtrl)?.value) {
      return of(null);
    }

    const values = {
      cityName: control.parent.get(cityCtrl)?.value,
      countryCode: control.parent.get(countryCtrl)?.value?.code,
      postCode: control.value
    };

    if (_.isEqual(lastValues, values)) {
      return of(lastResult);
    }

    return timer(debounceTime).pipe(
      switchMap(() => shipmentService.checkZipAndCity({
        ...values,
        refDate: new Date()
      })),
      mergeMap(result => {
        return result.isZipValid ?
          of(null) :
          of({zip: {valid: false}});
      }),
      tap(result => {
        lastValues = values;
        lastResult = result;
      })
    );
  };
}

export function validateCity(
    shipmentService: ShipmentService,
    countryCtrl: string,
    zipCtrl: string,
    debounceTime: number = 1000): AsyncValidatorFn {
  let lastValues;
  let lastResult;

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!(control instanceof FormControl) ||
        !control.parent.get(countryCtrl)?.value) {
      return of(null);
    }

    const values = {
      cityName: control.value,
      countryCode: control.parent.get(countryCtrl)?.value?.code,
      postCode: control.parent.get(zipCtrl)?.value,
    };

    if (_.isEqual(lastValues, values)) {
      return of(lastResult);
    }

    return timer(debounceTime).pipe(
      switchMap(() => shipmentService.checkZipAndCity({
        ...values,
        refDate: new Date()
      })),
      mergeMap(result => {
        return result.isCityValid ?
          of(null) :
          of({city: {valid: false}});
      }),
      tap(result => {
        lastValues = values;
        lastResult = result;
      })
    );
  };
}

export function validateZipFormat(patterns: string[] | (() => string[])): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    const zip = _.isPlainObject(control.value) ? control.value.zip : control.value;

    if (isEmptyInputValue(zip)) {
      return null;
    }

    let expectedFormat = (_.isFunction(patterns) ? patterns() : patterns);

    if (!Array.isArray(expectedFormat)) {
      return null;
    }

    expectedFormat = expectedFormat.filter(p => Boolean(p));

    if (!expectedFormat.length) {
      return null;
    }

    const regexArr = expectedFormat.map(p => {
      const str = p
        .replace(/\A/g, "\\w")
        .replace(/\N/g, "\\d")
        .replace(/[\?\B\O]/g, "[\\d\\w]");

      return new RegExp("^" + str + "$");
    });

    return regexArr.some(r => r.test(zip)) ? null : {
      zipFormat: {
        expectedFormat
      }
    };
  };
}


export function validateMatches(path: string): ValidatorFn {
  let conditionControl: AbstractControl | null = null;

  return (control: AbstractControl): ValidationErrors | null => {
    if (!conditionControl) {
      conditionControl = control.root.get(path);

      if (conditionControl) {
        subscribeToConditionControl(control, conditionControl);
      }
    }

    if (conditionControl && conditionControl.value !== control.value) {
      return {matches: true};
    }

    return null;
  };
}

interface RequiredIfOptions {
  fromRoot: boolean;
}

export function requiredIf(
    path: string,
    conditionValue: any,
    options?: RequiredIfOptions) {
  let conditionControl: AbstractControl | null = null;

  return (control: AbstractControl) => {
    if (!conditionControl) {
      conditionControl = options && options.fromRoot ?
      control.root.get(path) :
      (control.parent && control.parent.get(path));

      if (conditionControl) {
        subscribeToConditionControl(control, conditionControl);
      }
    }

    if (conditionControl && conditionControl.value == conditionValue) {
      return Validators.required(control);
    }

    return null;
  };
}

export function atLeastOneFrom(...args: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormGroup)) {
      return null;
    }

    const hasAtLeastOne = args.some(arg => {
      const ctrl = control.get(arg);
      return ctrl && !isEmptyInputValue(ctrl.value);
    });

    return hasAtLeastOne ? null : {atLeastOne: true};
  };
}

/**
 * Validate customs tariff number (HS-code) using API (BREXIT-108).
 * When origin or destination country changes `updateValueAndValidity` method should be called.
 */
export function validateCustomsTariffNumber(
    shipmentService: ShipmentService,
    countriesFn: () => {shippingCountryCode: string, deliveryCountryCode: string},
    debounceTime: number = 1000): AsyncValidatorFn {
  let lastValues;
  let lastResult;

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const countries = countriesFn();

    if (!(control instanceof FormControl) ||
        // destination and origin country are required.
        !countries.shippingCountryCode ||
        !countries.deliveryCountryCode ||
        // Send validation request only for valid format.
        control.value?.length !== 8) {
      return of(null);
    }

    const values = {
      hsCode: control.value,
      ...countries
    };

    if (_.isEqual(lastValues, values)) {
      return of(lastResult);
    }

    return timer(debounceTime).pipe(
      switchMap(() => shipmentService.validateCustomsTariffNumber(values)),
      map(result => result.isValid ? null : {customsTariffNumber: result.message}),
      tap(result => {
        lastValues = values;
        lastResult = result;
      })
    );
  };
}
