<div class="dialog recipients-export-dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
  <h1>{{ (isExportingAll ? 'recipients_export_all' : 'recipients_export_selected') | shpLocalize }}</h1>
  <div class="message" *ngIf="exportFailed">
    <div class="error">{{ 'recipients_export_error_occured' | shpLocalize }}</div>
  </div>
  <div class="buttons center">
    <button id="button-export-xml" type="button" (click)="export('xml')">{{ 'export_xml' | shpLocalize }}</button>
    <button id="button-export-csv" type="button" (click)="export('csv')">{{ 'export_csv' | shpLocalize }}</button>
    <button id="button-close" type="button" (click)="close()">{{ 'cancel' | shpLocalize }}</button>
  </div>
</div>