<div class="recipients-import-dialog dialog" wizardTag="importRecipientDialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>

  <h1 wizardTag="importRecipientDialogParams">{{ 'recipients_import' | shpLocalize }}</h1>

  <div class="panel message">
    <ng-container *ngIf="readFileErrors">
      <div *ngFor="let error of readFileErrors" class="error">{{ error }}</div>
    </ng-container>
  </div>

  <div class="ctl">
    <label for="selected-file">{{ 'file_to_import' | shpLocalize }}</label>
    <div class="file-input-group">
      <input id="input-filename" type="text" [(ngModel)]="selectedFileName" readonly="readonly" />
      <input type="file" name="selectedFile" id="selected-file" #fileInput (change)="selectedFileChanged($event); fileInput.value = null" />
      <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
    </div>
  </div>

  <div class="ctl" *ngIf="importProfiles.length || isWizardActive">
    <label for="select-import-profile">{{ 'import_select_profile' | shpLocalize }}</label>
    <select id="select-import-profile" name="select-import-profile" [(ngModel)]="importProfile">
      <option [value]="-1">{{ 'import_profile_default' | shpLocalize }}</option>
      <option [value]="imp.id" *ngFor="let imp of dialog.context.importProfiles">{{imp.name}}</option>
    </select>
  </div>

  <div class="ctl">
    <label for="select-import-encoding">{{ 'import_encoding' | shpLocalize }}</label>
    <select id="select-import-encoding" name="select-import-encoding" [(ngModel)]="importEncoding">
      <option *ngIf="canUseEncodingFromProfile()"
              [value]="''">{{ 'import_encoding_from_profile' | shpLocalize }}</option>
      <option [value]="'utf-8'">UTF-8</option>
      <option [value]="'windows-1250'">Windows-1250</option>
      <option [value]="'windows-1252'">Windows-1252</option>
    </select>
  </div>

  <div class="ctl" wizardTag="importRecipientDialogParams">
    <label for="overwrite-existing-by-reference">{{ 'recipients_import_overwrite_existing_by_reference' | shpLocalize }}</label>
    <input type="checkbox" [(ngModel)]="overwriteExistingByReference" id="overwrite-existing-by-reference" name="overwriteExistingByReference" />
    <label for="overwrite-existing-by-reference"></label>
  </div>

  <div class="buttons" wizardTag="importRecipientDialogButtons">
    <button id="button-import" type="button" [disabled]="readFileErrors" (click)="import()">{{ 'import' | shpLocalize }}</button>
    <button id="button-close" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>

  <div class="message import-result-numbers" *ngIf="importedEntriesCount > 0 || invalidEntriesCount > 0">
    <div class="info">{{ ((invalidEntriesCount > 0) ? 'import_partially_successful' : 'import_successful') | shpLocalize }}</div>
    <span class="count">{{ 'number_of_imported_lines' | shpLocalize }}: <strong>{{ importedEntriesCount }}</strong></span>
    <span class="count">{{ 'number_of_not_imported_lines' | shpLocalize }}: <strong>{{ invalidEntriesCount }}</strong></span>
  </div>

  <div class="import-result" *ngIf="logEntries?.length > 0">
    <!-- jednotlivé log záznamy -->
    <h2>{{ 'errors_and_warnings' | shpLocalize }}</h2>
    <div class="log-entry" *ngFor="let entry of logEntries">
      <span class="ic-remove-circle-1 error" *ngIf="entry.severity === 0"></span>
      <span class="ic-interface-alert-triangle warning" *ngIf="entry.severity === 1"></span>
      <span *ngIf="entry.eventData?.lineNumber">{{ 'line' | shpLocalize }} {{ entry.eventData?.lineNumber }}: </span>
      {{ entry.eventMessage }}
    </div>
  </div>
</div>