<div class="dialog shipments-import-dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>

  <h1 [innerHtml]="'import_profile_' + type | shpLocalize"></h1>

  <div class="ctl">
    <label for="selected-file">{{ 'file_to_import' | shpLocalize }}</label>
    <div class="file-input-group">
      <input id="input-filename" type="text" [ngModel]="selectedFileName" readonly="readonly" />
      <input type="file" name="selectedFile" id="selected-file" (change)="selectedFileChanged($event)" />
      <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
    </div>
  </div>

  <div class="ctl">
    <label for="overwrite-if-code-exists">{{ 'overwrite_if_code_exists' | shpLocalize }}</label>
    <input type="checkbox" [(ngModel)]="overwriteIfCodeExists" id="overwrite-if-code-exists" name="overwriteIfCodeExists" />
    <label for="overwrite-if-code-exists"></label>
  </div>

  <div class="buttons">
    <button id="button-import" type="button" (click)="import()">{{ 'import' | shpLocalize }}</button>
    <button id="button-close" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>
</div>