import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

import { merge, Subject } from "rxjs";
import { auditTime, filter, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import { ShipperWizardService } from "../../shared/services/shipper-wizard.service";
import { ImportProfile, ImportProfileContainer } from "../models/import-profiles.models";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-dynamic-import-editor-part1",
  templateUrl: "./part1.editor.component.html"
})
export class Part1EditorComponent implements AfterViewInit, OnDestroy {
  dynamic: ImportProfile = {} as ImportProfile;
  isBusy = false;

  @Input() isCustomerLevelAccess: boolean;
  @Input() customers = [];
  @Input() customerDetailId: number;
  @Input() profile: ImportProfileContainer = {} as ImportProfileContainer;
  @Input() viewOnly: boolean;

  @ViewChild("part1form") part1form: NgForm;

  private destroy$ = new Subject<void>();

  constructor(
    private _wizardService: WizardService,
    private _shipperWizardService: ShipperWizardService
  ) { }

  ngAfterViewInit() {
    // Check mandatory fields for the "basic data 1" wizard step.
    merge(
      this.part1form.valueChanges,
      this._wizardService.state$
    ).pipe(
      takeUntil(this.destroy$),
      filter(() => this._wizardService.isOnStep(WizardStepName.ImportProfileBasicData1)),
      // Delay so the ngModel directive updates the model.
      auditTime(0)
    ).subscribe(() => {
      this._shipperWizardService.canGoToNextStep =
        ["code", "name"].every(field => Boolean(this.profile[field]));
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doPristine(){
    // this.part1form.form.markAsPristine();
    const formValue = this.part1form.form.getRawValue();
    this.part1form.reset(formValue);
  }

  hasUnsavedChanges(): boolean {
    return this.part1form.dirty;
  }

  formInvalid() {
    return !this.part1form.form.valid;
  }
}
