<div class="settings-category no-toolbar">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <h1>{{ 'settings_configuration' | shpLocalize }}</h1>

  <div class="error" *ngIf="exception != null">{{ exception | shpExceptionInfo }}</div>

  <div class="configuration-detail" *ngFor="let configurationDetail of configurationDetails | async" [class.disabled]="configurationDetail.stateId === 'Disabled'">
    <span class="configuration-name">{{ configurationDetail.customerName }} <span *ngIf="configurationDetail.groupId">{{ configurationDetail.groupId }}-{{ configurationDetail.versionNumber }}</span></span>
    <span class="configuration-address" *ngIf="configurationDetail.addressName">{{ configurationDetail.addressName }}, {{ configurationDetail.addressStreet }} {{ configurationDetail.addressHouseNr }}, {{ configurationDetail.addressCity }}, {{ configurationDetail.addressZip }} {{ configurationDetail.addressCountryCode }}</span>
    <span class="value configuration-date"><span>{{ 'configuration_valid_from' | shpLocalize }}</span> {{ configurationDetail.validFromDateTimeUtc | shpLocalizeDate }}</span>
    <span class="value configuration-date"><span>{{ 'configuration_valid_to' | shpLocalize }}</span> {{ configurationDetail.validToDateTimeUtc | shpLocalizeDate }}</span>
    <span class="value configuration-invoicing-nr"><span>{{ 'configuration_invoicing_nr' | shpLocalize }}</span> {{ configurationDetail.customerInvoicingNr }}</span>
    <span class="value configuration-delis-id"><span>{{ 'configuration_delis_id' | shpLocalize }}</span> {{ configurationDetail.customerDelisId }}</span>


    <ul class="parcel-ranges">
      <li *ngFor="let parcelRange of configurationDetail.parcelNumberRanges" class="parcel-range">
        <span class="value"><span>{{ 'parcel_range_from' | shpLocalize }}</span> {{ parcelRange.parcelNumberFrom }}</span>
        <span class="value"><span>{{ 'parcel_range_to' | shpLocalize }}</span> {{ parcelRange.parcelNumberTo }}</span>
        <span class="value"><span>{{ 'parcel_range_total_numbers' | shpLocalize }}</span> {{ parcelRange.rangeCount }}</span>
        <span class="value"><span>{{ 'parcel_range_last_used' | shpLocalize }}</span> {{ parcelRange.lastUsedNumber }}</span>
        <span class="value reusable"><ng-container *ngIf="parcelRange.isRecyclable"><span>{{ 'parcel_number_range_is_reusable' | shpLocalize }}</span> <span class="check-mark"></span></ng-container></span>
        <span class="value"><ng-container *ngIf="allowedParcelRangeAllocationToAddress"><span>{{ 'address' | shpLocalize }}</span> {{ parcelRange.addressId | shpAddressId:configurationDetail.addresses }}</ng-container></span>
      </li>
    </ul>

    <h3>{{ 'pickup_addresses' | shpLocalize }}</h3>
    <ul class="addresses">
      <li *ngFor="let address of configurationDetail.addresses" class="address">
        <span class="address-custom-id">{{ address.customId }}</span>
        <span class="address-depot-code">{{ address.depotCode  }}</span>
        <span class="address-full">{{ address | shpFullAddress }}</span>
      </li>
    </ul>

    <h3>{{ 'bank_accounts' | shpLocalize }}</h3>
    <ul class="bank-accounts">
      <li *ngFor="let ba of configurationDetail.bankAccounts" class="bank-account">
        <span class="bank-account-custom-id">{{ ba.customId }}</span>
        <span class="bank-account-bic">{{ ba.bic }}</span>
        <span class="bank-account-iban">{{ ba.iban}}</span>
      </li>
    </ul>

    <ng-container *ngIf="configurationDetail.note && isConfigurationNoteVisible">
      <h3>{{ 'note' | shpLocalize }}</h3>
      <div>
        {{ configurationDetail.note }}
      </div>
    </ng-container>

    <div class="commands">
      <button id="button-disable-configuration" *ngIf="configurationDetail.stateId === 'Active' && canDisableConfiguration" class="" type="button" (click)="disable(configurationDetail)"><span class="ic-file-block-2"></span></button>
      <button id="button-enable-configuration" *ngIf="configurationDetail.stateId === 'Disabled' && canDisableConfiguration" class="" type="button" (click)="enable(configurationDetail)"><span class="ic-file-check-2"></span></button>
      <button id="button-delete-configuration" *ngIf="canDeleteConfiguration" type="button" (click)="delete(configurationDetail)"><span class="ic-bin"></span></button>
    </div>
  </div>

  <div *ngIf="canAddConfiguration">
    <h2>{{ 'upload_new_configuration' | shpLocalize }}</h2>
    <div class="info" *ngIf="isDemoUser">{{ 'demo_user_configuration_upload_message' | shpLocalize }}</div>
    <div class="error" *ngIf="uploadException != null">{{ uploadException | shpExceptionInfo }}</div>

    <form #activationCodeForm="ngForm" (submit)="activateConfiguration()" autocomplete="off">
      <div class="ctl required">
        <label for="activation-code">{{ 'activation_code' | shpLocalize}}</label>
        <input type="text" [(ngModel)]="activationCode" required name="activationCode" id="activation-code" />
      </div>
      <div class="ctl buttons">
        <label>&nbsp;</label>
        <button id="button-upload-configuration-file" [disabled]="canActivateConfiguration" type="submit">{{ 'activate' | shpLocalize }}</button>
      </div>
    </form>

    <div class="mdl-grid compact">
      <div class="mdl-cell mdl-cell--12-col">
        <div id="toggle-manual-upload"
             class="editor-part-active"
             (click)="toogleManualUpload()">
          <span *ngIf="isManualUploadShown" class="ic-minus ic-2x"></span>
          <span *ngIf="!isManualUploadShown" class="ic-plus ic-2x"></span>
          <span class="editor-part-name">{{ 'manual_upload_options' | shpLocalize }}</span>
        </div>
      </div>
    </div>
    <form *ngIf="isManualUploadShown" #f="ngForm" (submit)="uploadConfigurationFile()" autocomplete="off">
      <div class="ctl required">
        <label for="selected-file">{{ 'configuration_file' | shpLocalize }}</label>
        <div class="file-input-group">
          <input id="input-filename" type="text" [(ngModel)]="selectedFileName" name="selectedFileName" readonly="readonly" />
          <input type="file" name="selectedFile" id="selected-file" (change)="selectedFileChanged($event)" />
          <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
        </div>
      </div>
      <div class="ctl required">
        <label for="selected-file-password">{{ 'password' | shpLocalize}}</label>
        <input type="password" [(ngModel)]="filePassword" required name="filePassword" id="selected-file-password" />
      </div>
      <div class="ctl buttons">
        <label>&nbsp;</label>
        <button id="button-upload-configuration-file" [disabled]="!f.form.valid || selectedFileName.length === 0" type="submit">{{ 'upload_configuration_file' | shpLocalize }}</button>
      </div>
    </form>
  </div>
</div>

