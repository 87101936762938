<button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite"
  [class]="options.messageClass" [innerHTML]="message">
</div>
<div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite"
  [class]="options.messageClass" [attr.aria-label]="message">
  {{ message }}
</div>

<!-- custom area begin -->
<div class="toast-entity-errors">
  <div *ngFor="let error of errors" (click)="callFocusAction(error)" class="toast-entity-error">
    <div class="toast-error-label">
      <ng-container *ngIf="error.focusAction"><span class="ic-navigation-left-3"></span>&nbsp;</ng-container>{{ error.label }}
    </div>
    <div>
      <ul class="toast-entity-error-descriptions">
        <li *ngFor="let description of error.descriptions" class="toast-entity-error-description">
        {{description}}
        </li>
      </ul> 
  </div>
</div>
<!-- custom area end -->

<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>