import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { combineLatest, merge, ReplaySubject, Subject } from "rxjs";
import { auditTime, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { BusinessUnitSettingsService } from "../../shared/index";
import { PaperSize, PrinterLanguage, PrinterType, PrintingType } from "../../shared/models/config.models";
import { Address, AddressForGridView } from "../../shared/models/customer.models";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import * as SettingsModel from "../models/settings.model";
import { PrinterRule, ShipmentsImportPath } from "../models/settings.model";

@Injectable()
export class SettingsService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient,
    private _tokenStorageService: Shared.TokensStorageService,
    private _contextService: Shared.ContextService,
    private _businessUnitSettingsService: BusinessUnitSettingsService,
    private _wizardService: WizardService
  ) {
    super(loggingService);
    this.initActiveConfigurationSettings();
  }


  /**
   * Keeps currently active configuration settings.
   * Emits new value when settings change.
   */
  public activeConfigurationSettings$ = new ReplaySubject<SettingsModel.TenantSettings>(1);


  /**
   * Emits when configuration settings successfully update.
   */
  public activeConfigurationChanges$ = new Subject<void>();


  /**
   * Vráti kompletné nastavenia pre daný CustomerDetail.
   * @param customerDetailId Identifikátor CustomerDetail-u pre ktorý získavame nastavenia.
   * @param addressId Adresa, v ktorej kontexte pracujeme.
   */
  public getConfigurationSettings(customerDetailId: number, addressId: number) {
    return this.processRequest<SettingsModel.TenantSettings>(
      this._http.get(`${Shared.API_URL}/customer-details/${customerDetailId}/${addressId}/settings`),
      (settings: any /* SettingsModel.TenantSettings */) => {
        this.initializePossiblyMissingSettings(settings as SettingsModel.TenantSettings);
        return settings;
      });
  }


  /**
   * Vráti nastavenia pre daný CustomerDetail vrátane zoznamu dostupných properties v rámci PrintingSettings.
   * @param customerDetailId Identifikátor CustomerDetail-u pre ktorý získavame nastavenia.
   * @param addressId Adresa, v ktorej kontexte pracujeme.
   */
  public getConfigurationSettingsModel(customerDetailId: number, addressId: number) {
    return this.processRequest<SettingsModel.TenantSettingsModel>(
      this._http.get(`${Shared.API_URL}/customer-details/${customerDetailId}/${addressId}/settings-model`),
      (settingsModel: any /* SettingsModel.TenantSettingsModel */) => {
        this.initializePossiblyMissingSettings((settingsModel as SettingsModel.TenantSettingsModel).tenantSettings);
        return settingsModel;
      });
  }


  /**
   * Uloží kompletné nastavenia pre daný CustomerDetail.
   * @param customerDetailId Identifikátor CustomerDetail-u pre ktorý ukladáme nastavenia.
   * @param addressId Adresa, v ktorej kontexte pracujeme.
   * @param settings Objekt obsahujúci kompletné nastavenia.
   */
  public updateConfigurationSettings(customerDetailId: number, addressId: number, settings: SettingsModel.TenantSettings) {
    return this.processRequest<SettingsModel.TenantSettings>(this._http.put(`${Shared.API_URL}/customer-details/${customerDetailId}/${addressId}/settings`, settings)).pipe(
      tap(() => {
        this._wizardService.completeStep([WizardStepName.SettingsBasic, WizardStepName.SettingsPrint]);
        this.activeConfigurationChanges$.next();
        // Some settings are stored as BU settings.
        this._businessUnitSettingsService.reload();
      })
    );
  }


  /**
   * Initializes `activeConfigurationSettings$` stream.
   */
  private initActiveConfigurationSettings() {
    /** Observe context changes that can affect settings. */
    const activeSettingsParamChanges$ = combineLatest([
      this._contextService.currentCustomerDetail.pipe(filter(val => !!val)),
      this._contextService._currentAddress.pipe(filter(val => !!val)),
    ]).pipe(
      map(([customerDetail, address]) => [customerDetail.id, address.id])
    );

    merge(
      activeSettingsParamChanges$,
      /** Reaload settings on update using last params. */
      this.activeConfigurationChanges$.pipe(
        withLatestFrom(activeSettingsParamChanges$, (change, params) => params)
      )
    ).pipe(
      auditTime(10),
      switchMap(value => this.getConfigurationSettings(value[0], value[1]))
    ).subscribe(this.activeConfigurationSettings$);
  }


  /**
   * Vráti zoznam tlačiarní, ktoré sú k dispozícii na strane servera.
   */
  public getPrinters() {
    return this.processRequest<string[]>(this._http.get(`${Shared.API_URL}/printers`));
  }


  public getCanUseDemoModeAndUploadConfigurations() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/settings/can-use-demo-mode-and-upload-configuration`));
  }


  public getHasUserOldPassword(customerDetailId: number, userId: number) {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/settings/is-old-password/${customerDetailId}/userId/${userId}`));
  }


  public getHasUserUnchangedPassword(customerDetailId: number, userId: number) {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/settings/has-unchanged-password/${customerDetailId}/userId/${userId}`));
  }


  public getIsOnlyDemoTenantAvailable() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/settings/is-only-demo-tenant-available`));
  }


  public getHasAnyActiveNonDemoTenant() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/settings/has-any-active-non-demo-tenant`));
  }


  public uploadConfiguration(model: SettingsModel.ConfigurationModel) {
    return this.processRequest<boolean>(this._http.post(`${Shared.API_URL}/import-configuration`, model));
  }

  public activateConfiguration(model: SettingsModel.ActivateConfigurationModel) {
    return this.processRequest<boolean>(this._http.post(`${Shared.API_URL}/activate-configuration`, model));
  }


  public getActiveConfigurationDetails() {
    return this.processRequest<SettingsModel.ConfigurationDetailsModel[]>(this._http.get(`${Shared.API_URL}/configurations`));
  }


  public setConfigurationDisabled(configurationId: number, value: boolean) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/configurations/${configurationId}/is-disabled`, { value }));
  }


  public deleteConfiguration(configurationId: number) {
    return this.processRequest<boolean>(this._http.delete(`${Shared.API_URL}/configurations/${configurationId}`));
  }


  public currentUserCanDeleteConfiguration() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/configurations/current-user-can-delete-configuration`));
  }


  public currentUserCanAddConfiguration() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/configurations/current-user-can-add-configuration`));
  }


  public currentUserCanDisableConfiguration() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/configurations/current-user-can-disable-configuration`));
  }


  public testSyncSettingsConnection(customerDetailId: number) {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/shipments/${customerDetailId}/is-export-to-dpd-available`));
  }


  public forceRdbDownload() {
    return this.processRequest<boolean>(this._http.post(`${Shared.API_URL}/georouting/force-rdb-download`, null));
  }


  private initializePossiblyMissingSettings(settings: SettingsModel.TenantSettings) {
    if (!settings.default_recipient_language_code) {
      settings.default_recipient_language_code = {
        name: "",
        value: "EN",
        isVisible: true,
        tooltip: ""
      };
    }
  }


  /**
   * Vráti true, ak sú údaje pre sťahovanie RDB OK, ináč vráti chybu, ktorá nastala pri snahe o použitie údajov.
   * @param customerDetailId Identifikátor zákazníka.
   */
  public testRdbDownloadLocationAccess(customerDetailId: number) {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/settings/${customerDetailId}/is-georouting-db-path-valid`));
  }


  public getPrintingTypes() {
    return this.processRequest<PrintingType[]>(this._http.get(`${Shared.API_URL}/printing-types`));
  }


  public getPrinterTypes() {
    return this.processRequest<PrinterType[]>(this._http.get(`${Shared.API_URL}/printer-types`));
  }


  public getPrinterLanguages() {
    return this.processRequest<PrinterLanguage[]>(this._http.get(`${Shared.API_URL}/printer-languages`));
  }


  public getPaperSizes() {
    return this.processRequest<PaperSize[]>(this._http.get(`${Shared.API_URL}/paper-sizes`));
  }


  // Printer Rules

  /**
   * Vráti všetky pravidlá pre tlač aktuálneho tenanta.
   */
  public getPrinterRules() {
    return this.processRequest<PrinterRule[]>(this._http.get(`${Shared.API_URL}/printer-rules`));
  }


  public savePrinterRule(rule: PrinterRule) {
    if (rule.id === 0) { // Nové pravidlo
      return this.processRequest<PrinterRule>(this._http.post(`${Shared.API_URL}/printer-rules`, rule));
    } else {
      return this.processRequest<PrinterRule>(this._http.put(`${Shared.API_URL}/printer-rules/${rule.id}`, rule));
    }
  }


  public deletePrinterRule(id: number) {
    return this.processRequest<any>(this._http.delete(`${Shared.API_URL}/printer-rules/${id}`));
  }


  public swapPriorityWith(id: number, swapWithId: number) {
    return this.processRequest<any>(this._http.put(`${Shared.API_URL}/printer-rules/${id}/priority-swap/${swapWithId}`, {}));
  }


  // Import Paths

  public getShipmentsImportPaths() {
    return this.processRequest<ShipmentsImportPath[]>(this._http.get(`${Shared.API_URL}/shipments-import-paths`));
  }


  public saveShipmentsImportPath(path: ShipmentsImportPath) {
    if (path.id === 0) { // Nové pravidlo
      return this.processRequest<ShipmentsImportPath>(this._http.post(`${Shared.API_URL}/shipments-import-paths`, path));
    } else {
      return this.processRequest<ShipmentsImportPath>(this._http.put(`${Shared.API_URL}/shipments-import-paths/${path.id}`, path));
    }
  }


  public deleteShipmentsImportPath(id: number) {
    return this.processRequest<any>(this._http.delete(`${Shared.API_URL}/shipments-import-paths/${id}`));
  }


  // Pickup addresses

  public getCustomerPickupAddresses(customerDetailId: number) {
    return this.processRequest<AddressForGridView[]>(this._http.get(`${Shared.API_URL}/customers/${customerDetailId}/pickup-addresses`));
  }


  public saveCustomerPickupAddress(customerDetailId: number, address: Address) {
    if (address.id === 0) { // New pickup address
      return this.processRequest<Address>(this._http.post(`${Shared.API_URL}/customers/${customerDetailId}/pickup-addresses`, address));
    } else {
      return this.processRequest<Address>(this._http.put(`${Shared.API_URL}/customers/${customerDetailId}/pickup-addresses/${address.id}`, address));
    }
  }


  public deleteCustomerPickupAddress(customerDetailId: number, id: number) {
    return this.processRequest<any>(this._http.delete(`${Shared.API_URL}/customers/${customerDetailId}/pickup-addresses/${id}`));
  }


  public undeleteCustomerPickupAddress(customerDetailId: number, id: number) {
    return this.processRequest<any>(this._http.put(`${Shared.API_URL}/customers/${customerDetailId}/pickup-addresses/undelete/${id}`, null));
  }


  public sendToApprovalCustomerPickupAddress(customerDetailId: number, id: number) {
    return this.processRequest<any>(this._http.put(`${Shared.API_URL}/customers/${customerDetailId}/pickup-addresses/send-to-approval/${id}`, null));
  }

  public getInstallationId() {
    return this.processRequest<number>(this._http.get(`${Shared.API_URL}/installation-id`));
  }


  public getZipFromApi = (method: string, url: string, data: any = null): Promise<Blob> => {
    const promise = new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(method, url, true);
      xhr.responseType = "blob"; // Toto je d�le�it�, in�� dostaneme zmr�en� d�ta.
      xhr.setRequestHeader("Authorization", "bearer " + this._tokenStorageService.getAccessToken());
      xhr.setRequestHeader("Content-Type", "application/octet-stream");

      xhr.onreadystatechange = function() {
        // 4 znamen�, �e proces downloadu skon�il... ot�zne je, �e ako.
        if (xhr.readyState == 4) {
          if (xhr.status == 202 || xhr.status == 200) {
            resolve(xhr.response);
          }

          // 5XX - nejak� chyba na strane servera.
          if (xhr.status.toString().startsWith("5") || xhr.status.toString().startsWith("4")) {
            // Nastala chyba, mali by sme asi nie�o k tomu poveda�.
            // Sk�sime to deserializova�, je to JSON s chybou.

            const reader = new FileReader();

            reader.onloadend = (ev: ProgressEvent): any => {
              try {
                const o = JSON.parse(reader.result as string);
                reject(o);
              } catch (ex) {
                reject();
              }
            };

            reader.readAsText(xhr.response);
          }

          this.isBusy = false;
        }
      }.bind(this);

      xhr.send(data);
    });

    return promise;
  }

  public restoreDatabaseFile(url: string, file: File) {
    const formData = new FormData();

    formData.append("zipDatabaseFile", file, file.name);

    return this.processRequest<boolean>(this._http.post(url, formData));
  }

  public uploadZipFile(url: string, file: File) {
    const formData = new FormData();

    formData.append("zipFile", file, file.name);

    return this.processRequest<boolean>(this._http.post(url, formData));
  }

  public getIsDbBackupAndRestoreAllowed() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/backup-restore/is-allowed`));
  }
}
