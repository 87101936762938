import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { DialogRef, overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { combineLatest, forkJoin, Subject, Subscription } from "rxjs";
import { filter, finalize, take, takeUntil } from "rxjs/operators";

import { ImportProfile } from "../../import-profiles/models/import-profiles.models";
import { IMPORT_PROFILE_TYPES } from "../../import-profiles/services/import-profiles.service";
import { SettingsService } from "../../settings/services/settings.service";
import * as Shared from "../../shared/index";
import { CustomerService, PollyCollection, ShipperSettingsService } from "../../shared/index";
import { Product } from "../../shared/models/config.models";
import * as CustomerModel from "../../shared/models/customer.models";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import { SkdataConfigService } from "../../shared/services/skdata-config.service";
import * as ShipmentModel from "../models/shipment.model";
import { ShipmentService } from "../services/shipments.service";
import { DailyProcedureDialogComponent } from "./daily-procedure-dialog.component";
import { DeleteAllShipmentsDialogComponent } from "./delete-all-shipments-dialog.component";
import { ExportAllShipmentsDialogComponent } from "./export-all-shipments-dialog.component";
import { ShipmentsImportDialogComponent } from "./shipments-import-dialog.component";

const IS_COMPACT_VIEW_SELECTED_STORAGE_KEY = "is-comapact-view-selected";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments-list",
  templateUrl: "./shipments-list.component.html"
})
export class ShipmentsListComponent extends Shared.RoutedPageComponentBase implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _modal: Modal,
    private _contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    private _shipmentsService: ShipmentService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _settingsService: SettingsService,
    private _customerService: CustomerService,
    private _shipperSettingsService: ShipperSettingsService,
    private _wizardService: WizardService) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public get isCompactViewSelected(): boolean {
    // Always display compact view for the "shipment overview" wizard step.
    if (this._wizardService.isOnStep(WizardStepName.NewShipmnetOverview)) {
      return true;
    }

    const storageStringValue = localStorage.getItem(IS_COMPACT_VIEW_SELECTED_STORAGE_KEY);

    if (storageStringValue == null) {
      return true;
    }

    return JSON.parse(storageStringValue);
  }


  public set isCompactViewSelected(value: boolean) {
    localStorage.setItem(IS_COMPACT_VIEW_SELECTED_STORAGE_KEY, JSON.stringify(value));
  }

  public get isStopCredit(): boolean {
    return this._shipperSettingsService.isSophia && this._currentCustomerDetail ? this._currentCustomerDetail.stopCredit : false;
  }

  public get isSophia(): boolean {
    return this._shipperSettingsService.isSophia;
  }


  public isBusy = false;
  public statusMessage = "";

  public isCustomerService = false;

  private _tenantId: number = null;
  private _currentAddress: CustomerModel.Address = null;
  private _currentCustomerDetail: CustomerModel.CustomerDetail = null;

  private _importProfiles: ImportProfile[] = null;
  private _defaultProductId: number = null;

  private _importDialogRef: DialogRef<any> = null;

  private _destroy$ = new Subject<void>();

  public isSenderScanVisible = false;
  public isDeleteAllShipmentsVisible = false;
  public products: Product[] = null;
  public pollyCollection = PollyCollection.Shipments;
  public wizardStepNames = WizardStepName;
  private dataForImportDailogLoad = new Subject<void>();

  public showDailyProcedureDialog = () => {
    this._modal.open(DailyProcedureDialogComponent, overlayConfigFactory({}));
  }


  private showNotImplementedAlert = () => {
    this._modal.alert().body(this.localizationService.getLocalizedString("not_implemented_yet")).open();
  }

  public initOpenImportOrdersDialog() {
    if (this._importProfiles && this.products) {
      this.openImportOrdersDialog();
    } else {
      this.dataForImportDailogLoad.subscribe(() => this.openImportOrdersDialog());
    }
  }

  public openImportOrdersDialog() {
    if (this.isStopCredit) {
      this._customerService.openStopCreditModal();
      return;
    }

    if (this._importDialogRef) {
      return;
    }

    this._importDialogRef = this._modal.open(ShipmentsImportDialogComponent, overlayConfigFactory(
      new ShipmentModel.ShipmentsImportDialogModalContext(
        this._currentAddress.customerDetailId,
        this._currentAddress.customId,
        this._tenantId,
        this._importProfiles,
        this.products,
        this._defaultProductId
      )
    ));

    this._importDialogRef
      .result
      .then(refresh => {
        if (refresh) {
          // Mus�me nejako notifikova� grid/compact view, �e sa nie�o zmenilo.
          this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
        }
      })
      .catch(() => { })
      .finally(() => this._importDialogRef = null);
  }


  public showConfirmDeleteAllShipmentsDialog() {
    this._modal.open(DeleteAllShipmentsDialogComponent, overlayConfigFactory({}))
      .result.then(refresh => {
        if (refresh) {
          this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
        }
      })
      .catch(() => { });
  }


  public openExportAllParcelsDialog() {
    this._modal.open(ExportAllShipmentsDialogComponent, overlayConfigFactory({}))
      .result.then(() => { }, ex => { });
  }

  public ngOnInit() {
    combineLatest([
      this._contextService.tenantId,
      this._contextService.currentAddress,
      this._contextService.currentCustomerDetail
    ]).pipe(
      takeUntil(this._destroy$),
      filter(value => {
        return value[0] != null && value[1] != null;
      })
    ).subscribe(value => {
        this.customerAndTendandLoaded(value);
      });

    this._businessUnitSettingsService.getIsSenderScanVisible()
      .subscribe(value => this.isSenderScanVisible = value);

    this._businessUnitSettingsService.getIsDeleteAllShipmnetsVisible()
      .subscribe(value => this.isDeleteAllShipmentsVisible = value);

    this.authenticationService.isCustomerService$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(value => this.isCustomerService = value);
  }

  public ngAfterViewInit() {
    this._wizardService.state$.pipe(
      takeUntil(this._destroy$),
    ).subscribe(() => {
      const importDialogWizardSteps = [
        WizardStepName.ImportShipments,
        WizardStepName.ImportShipments2
      ];

      if (this._wizardService.isOnStep(importDialogWizardSteps)) {
        this.initOpenImportOrdersDialog();
      } else {
        this._importDialogRef?.destroy();
        this._importDialogRef = null;
      }
    });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._importDialogRef?.destroy();
  }

  public customerAndTendandLoaded(value) {
    this._tenantId = value[0];
    this._currentAddress = value[1];
    this._currentCustomerDetail = value[2];

    // Načítame všetky povolené produkty pre aktuálneho zákazníka a nastavenie default produktu.

    forkJoin([
      this._skdataConfigService.getAllowedProductsAndAdditionalServices(this._currentAddress.customerDetailId),
      this._settingsService.activeConfigurationSettings$.pipe(take(1)),
      this._shipmentsService.loadCustomImportsProfilesByType(this._currentAddress.customerDetailId, IMPORT_PROFILE_TYPES.shipments.name),
      this._shipmentsService.loadCustomImportsProfilesByType(this._currentAddress.customerDetailId, IMPORT_PROFILE_TYPES.shipmentsCR.name)
    ]).subscribe((result) => {
      const products = result[0];
      const settings = result[1];

      this.products = products.filter(p => p.isProduct);

      if (settings.default_product_code.value) {
        const defaultProduct = products.find(p => p.code === settings.default_product_code.value);
        if (defaultProduct) {
          this._defaultProductId = defaultProduct.id;
        }
      }

      const importProfiles = result[2].concat(result[3]);

      this._importProfiles = importProfiles;
      this.dataForImportDailogLoad.next();
    });
  }
}
