import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { from, Observable } from "rxjs";
import { tap, map } from "rxjs/operators";

import * as _ from "lodash"

import { ApiServiceBase, LoggingService, Country, API_URL, City } from "./../index"
import * as Config from "../models/config.models"

import { UserRelatedItemModel } from "../models/user.model"

// Models
import { ZipCityPair } from "../models/zip-city-pair.model";
import { CityZipRange} from "../models/zip-city-range.model";
import { StreetCityPair } from '../models/street-city-pair.model';

@Injectable()
export class SkdataConfigService extends ApiServiceBase {
  constructor(
    loggingService: LoggingService,
    private _http: HttpClient
  ) {
    super(loggingService);
  }


  public getCountries() {
    return this.processRequest<Country[]>(this._http.get(API_URL + "/countries"))
  }


  public getCities(countryCode: string, searchTerm: string) {
    return this.processRequest<City[]>(this._http.get(`${API_URL}/countries/${countryCode}/${searchTerm}`));
  }

  public getZipsByCity(countryCode: string, searchTerm: string) {
    return this.processRequest<CityZipRange[]>(this._http.get(`${API_URL}/georouting/countries/${countryCode}/zips/${searchTerm}/city-zips`));
  }

  public getCitiesByZip(countryCode: string, searchTerm: string) {
    return this.processRequest<ZipCityPair[]>(this._http.get(`${API_URL}/georouting/countries/${countryCode}/zips/${searchTerm}/city-names`));
  }
  public getStreetAndCity(zip: string, houseNr: string) {
    return this.processRequest<StreetCityPair>(this._http.post(`${API_URL}/addresses-provider/city-street-pair`, { zip: zip, houseNr: houseNr }));
  }


  /**
  * Vr�ti zoznam v�etk�ch produktov a doplnkov�ch slu�ieb pre aktu�lny d�tum.
  */
  public getProductsAndAdditionalService() {
    return this.processRequest<Config.Product[]>(this._http.get(API_URL + "/products"));
  }


  public getAllowedProductsAndAdditionalServices(customerDetailId: number) {
    return this.processRequest<Config.Product[]>(this._http.get(API_URL + "/products/customer-details/" + customerDetailId));
  }


  public getAllProductItemsForUser(userId: number): Observable<UserRelatedItemModel[]> {
    return this.processRequest<UserRelatedItemModel[]>(this._http.get(`${API_URL}/products/user/${userId}`));
  }


  public setAllProductItemsForUser(items: UserRelatedItemModel[]): Observable<UserRelatedItemModel[]> {
    return this.processRequest<UserRelatedItemModel[]>(this._http.post(`${API_URL}/products/user/${items[0].userId}`, items));
  }


  public getIsCodForCrAllowed(customerDetailId: number) {
    return this.processRequest<boolean>(this._http.get(API_URL + "/products/" + customerDetailId + "/is-cod-for-cr-allowed"));
  }


  /**
   * Vr�ti aktu�lne pou�it� verziu GeoRoutingovej DB, ktor� je ulo�en� priamo v DB.
   * Nevracia verziu z n�zvu s�boru v pr�pade SQLite datab�zy Shippera.
   */
  public getCurrentGeoRoutingDbVersion() {
    return this.processRequest<string>(this._http.get(`${API_URL}/georouting/version-number-current`));
  }

  public getAvailableGeoRoutingDbVersion() {
    return this.processRequest<string>(this._http.get(`${API_URL}/georouting/version-number-available`));
  }


  private _callingCodesCache: Config.InternationalCallingCode[] = null;


  /**
   * Vr�ti zoznam v�etk�ch volac�ch k�dov v�etk�ch kraj�n (jedna krajina ich m�e ma� aj viac).
   * Matching treba robi� od najdlh�ieho po najkrat��, lebo niektor� krajiny (men�ie teritori�lne �zemia, ktor� sa ber� ako zvl᚝ krajiny)
   * patria pod volac� k�d inej krajiny (be�ne s� to �zemia USA).
   */
  public getInternationalCountryCallingCodes() {
    if (this._callingCodesCache) {
      return from([_.cloneDeep(this._callingCodesCache)]);
    }

    return this.processRequest<Config.InternationalCallingCode[]>(this._http.get(`${API_URL}/calling-codes`)).pipe(
      tap(result => {
        this._callingCodesCache = result;
      }),
      map(result => {
        return _.cloneDeep(result);
      })
    )
  }


  private _languages: string[] = null;


  /**
   * Vr�ti zoznam v�etk�ch jazykov pou�ite�n�ch hlavne ako jazyk adres�ta (pre notifik�cie).
   */
  public getLanguages() {
    if (this._languages) {
      return from([this._languages]);
    }

    return this.processRequest<string[]>(this._http.get(`${API_URL}/allowed-localizations`)).pipe(
      tap(result => {
        this._languages = result;
      })
    )
  }


  public uploadRoutindDbZipFile(file: File) {
    var formData = new FormData();

    formData.append("routingDbFile", file, file.name);

    return this.processRequest<boolean>(this._http.post(`${API_URL}/georouting`, formData));
  }


  public getBanks() {
    return this.processRequest<Config.Bank[]>(this._http.get(`${API_URL}/banks`));
  }
}
