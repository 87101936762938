import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import * as _ from "lodash";

import * as Shared from "../../shared/index";
import * as ShipmentsModel from "../models/shipment.model";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-filter-list-view",
  templateUrl: "./filter-list-view.component.html"
})
export class FilterListViewComponent implements OnInit {
  public exportedFilter = false;
  public isSophia = false;
  public notExportedFilter = false;

  @Input() public filter: ShipmentsModel.ShipmentsCompatViewSearchParameters;

  @Input() public allSelected: boolean;

  @Input() public anyCRExists;

  @Input() public isCustomerService = false;


  @Input()
  public set showDeletedFilter(value: boolean) {
    this._showDeletedFilter = value;
  }

  public get showDeletedFilter() {
    return this._showDeletedFilter;
  }


  public set deliveryStateFilter(value: boolean) {
    this.filter.isStatePreparedForDeliveryVisible = value;
    this.filter.isStateSentToDPDVisible = value;
  }

  public get deliveryStateFilter() {
    return this.filter.isStatePreparedForDeliveryVisible && this.filter.isStateSentToDPDVisible;
  }

  @Output("toggleAllVisibleSelected") toggleAllVisibleSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("filterValueChanged") filterValueChangedEmitter: EventEmitter<any> = new EventEmitter<any>();


  private _showDeletedFilter = false;


  constructor(
    private _shipperSettingsService: Shared.ShipperSettingsService
  ) {

  }


  public ngOnInit() {
    this.isSophia = this._shipperSettingsService.isSophia;
    this.initExportedFiltersValues();
  }


  public toggleAllVisibleSelected() {
    this.toggleAllVisibleSelectedEmitter.next();
  }


  public filterValueChanged() {
    this.filterValueChangedEmitter.next(this.filter);
  }


  private initExportedFiltersValues() {
    this.exportedFilter = this.filter.isExported === true;
    this.notExportedFilter = this.filter.isExported === false;
  }


  public exportedFilterChange() {
    // xor
    if ((this.exportedFilter || this.notExportedFilter) &&
        !(this.exportedFilter && this.notExportedFilter)) {
      this.filter.isExported = this.exportedFilter;
    } else {
      this.filter.isExported = null;
    }

    this.filterValueChangedEmitter.next(this.filter);
  }
}
