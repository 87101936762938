import { Component, OnInit, HostListener } from "@angular/core";

import { combineLatest } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";

import { DialogRef, ModalComponent, ModalOpenContext } from "ngx-modialog-7";

import * as Shared from "../../shared/index";
import { ContextService } from "../../shared/index";
import { SupportedLanguages } from "../../shared/languages";
import { Link } from "../../shared/models/link.model";

interface OwnManualLinks {
  localizationKey: string;
  countryCode: string;
  links: Link[];
}

@Component({
  selector: Shared.SELECTOR_PREFIX + "-app-manual",
  templateUrl: "./app-manual.component.html",
  host: {
    class: "support-manuals"
  }
})
export class AppManualComponent {
  public isMultipleManualLinksAllowed = false;
  public links: Link[] = null;
  public manuals: Link[] = null;
  public ownManualLinks: OwnManualLinks[] = [];
  public ownManualLinksCurrentLanguage: Link[];

  constructor(
    private _contextService: ContextService,
    private _buSettingsService: Shared.BusinessUnitSettingsService,
    private _linkService: Shared.LinkService,
    private _shipperSettingsService: Shared.ShipperSettingsService
  ) { }

  public get flagCode() { return (this.languageCode != 'SK' ? 'gb' : 'sk') }
  public get isSophia() { return this._shipperSettingsService.isSophia; }
  public get languageCode() { return "EN"; }

  public ngOnInit() {
    combineLatest([
      this._linkService.getManuals(this.languageCode, this.isSophia),
      this._contextService.businessUnitCode.pipe(
        filter(value => value != null),
        mergeMap(value => {
          return this._linkService.getLinks(value, this.languageCode);
        }),
      ),
      this._buSettingsService.getIsMultipleManualLinksAllowed(),
      this._buSettingsService.getOwnManualLinks(),
    ]).subscribe(
      settings => {
        this.manuals = settings[0];
        this.links = settings[1];
        this.isMultipleManualLinksAllowed = settings[2];

        this.proccesOwnManualLinks(settings[3]);
      }
    );
  }

  private proccesOwnManualLinks(links: Map<string, Link[]>) {
    links.forEach((links, code) => {
      if (this.languageCode.toLowerCase() === code) {
        this.ownManualLinksCurrentLanguage = links;
      } else {
        const language = SupportedLanguages.find(l => l.code === code);
        if (language) {
          this.ownManualLinks.push({
            localizationKey: language.localizationKey,
            countryCode: language.countryCode,
            links: links
          });
        }
      }
    });
  }

  hasOwnManualForCurrentLanguage() {
    return this.ownManualLinksCurrentLanguage != null && this.ownManualLinksCurrentLanguage.length > 0;
  }
}
