import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup, NgModel, ValidationErrors, Validators } from "@angular/forms";
import { NgForm } from "@angular/forms";

import * as _ from "lodash";
import { CompleterItem, CompleterService, LocalData, RemoteData } from "ng2-completer";
import { ToastrService } from "ngx-toastr";
import { merge, NEVER, Observable, of, Subject } from "rxjs";
import { auditTime, catchError, debounceTime, delay, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators";
import { RecipientsService } from "src/app/recipients/services/recipients.service";
import { Address } from "src/app/shared/models/customer.models";

import * as RecipientModel from "../../recipients/models/recipient.model";
import { RecipientForGridView } from "../../recipients/models/recipient.model";
import * as SettingsModel from "../../settings/models/settings.model";
import { SettingsService } from "../../settings/services/settings.service";
import * as Shared from "../../shared/index";
import { BUSINESS_UNIT_CODE_CH, Country, SkdataConfigService } from "../../shared/index";
import * as ConfigModel from "../../shared/models/config.models";
import { ZipCityPair } from "../../shared/models/zip-city-pair.model";
import { CityZipRange } from "../../shared/models/zip-city-range.model";

import { NewsfeedService } from "../../shared/services/newsfeed.service";
import { validateZipFormat, validateZipNoSpaces } from "../../shared/validators";
import { CustomClearanceService } from "../services/custom-clearance.service";
import { ShipmentService } from "../services/shipments.service";
import { RECEIVER_TYPE_BUSINESS, RECEIVER_TYPE_PRIVATE, ShipmentEditorComponent } from "./shipment-editor.component";
import { isEmptyInputValue } from "src/app/shared/form-helpers";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-recipient-view",
  templateUrl: "./recipient-view.component.html",
  providers: [CompleterService]
})
export class RecipientViewComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() recipient: RecipientModel.Recipient = {} as RecipientModel.Recipient;

  @Input() disableValidation = false;

  @Input() isSpecialOrder = false;

  @Input() isReadOnly: boolean;

  /** Identificator for form.addControl(this.name + "-recipientAddressCountry",... */
  @Input() name: string;

  @Input() hideFieldsIrrelevantForSender = false;

  @Input() useB2BAddressFieldsOnly = false;

  @Input() hideFieldsIrrelevantForPickup: boolean;

  @Input() isCollectionRequest = false;

  /** Cities data service */
  @Input() citiesDataService: RemoteData = null;

  /** is needed for setUpAddressChangeListeners() */
  @Input() suspendRoutingValidation: boolean;

  @Input() selectedProduct: ConfigModel.OrderEditorProductViewModel;

  @Input() recipientsDataService;

  @Input() isRecipientEmailRequired: boolean;

  @Input() citiesCountry;

  /** this settings is needed by getEmptyRecipient() */
  @Input() settings: SettingsModel.TenantSettings;

  @Input() languages: string[];

  @Input() minSearchLength = 4;

  @Input() isCustomsClearance = false;

  @Input() showAllCustomsFields = false;

  @Input() recipientType = RECEIVER_TYPE_PRIVATE;

  @Input() isSaveAddressCheckboxDisplayed: boolean;

  @Input() isEmailOrPhoneRequired: boolean;

  @Input() isBrexitCountryRecipient: boolean;

  private _zipIsInvalid;

  private _cityIsInvalid;

  @Input() isRecipientPhoneRequired: boolean;

  @Input() isPresentedAsSender = false;


  /** Zoznam krajín. */
  @Input()
  set countries(value: Shared.Country[]) {
    if (Array.isArray(value)) {
      this._countries = value;
      this.countriesByCode = _.sortBy(value, "code");
    }
  }

  get countries(): Shared.Country[] {
    return this._countries;
  }


  @Input()
  set zipIsInvalid(value: boolean) {
    this._zipIsInvalid = value;

    window.setTimeout(() => {
      this.addressZip.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      this.addressCity.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    }, 10);
  }

  @Input()
  set cityIsInvalid(value: boolean) {
    this._cityIsInvalid = value;

    window.setTimeout(() => {
      this.addressZip.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      this.addressCity.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    }, 10);
  }


  @Input()
  get isSaveAddressChecked() {
    return this._isSaveAddressChecked;
  }

  set isSaveAddressChecked(value) {
    this._isSaveAddressChecked = value;
    this.isSaveAddressCheckedChange.emit(this._isSaveAddressChecked);
  }


  @Input() public streetRegEx: string = null;

  @Input() public isCustomsClearanceBoundFieldsRequiredValidationOn = false;

  @Output() isSaveAddressCheckedChange = new EventEmitter<boolean>();

  @Output() recipientAddressCountryChange = new EventEmitter<Country>();

  @Output() recipientZipChange = new EventEmitter<string>();

  @Output("setProductsAndAdditionalServicesAvailability")
  setProductsAndAdditionalServicesAvailabilityEmitter = new EventEmitter();

  b2b = RecipientModel.CustomerRecipientTypeCode.B2B;
  b2c = RecipientModel.CustomerRecipientTypeCode.B2C;
  countriesByCode: Shared.Country[] = null;
  eoriNumberCountryCode = "";
  eoriNumberWithoutCountryCode = "";
  filteredCities: CityZipRange[];
  filteredRecipients: RecipientForGridView[];
  filteredZips: ZipCityPair[];
  isCityServiceSelected = false;
  isZipAndHouseNrCombinationInvalid = false;
  /** Aktuálne sa sem nastavuje z ContextService currentAddress. */
  selectedSenderAddress: Address = null;
  specialOrderCountryCode = "NL";
  streetsDataService: LocalData = null;
  zipCityPairsDataService: RemoteData = null;
  zipWarnings: string[] = null;
  isSpecialOrderAddressEditEnabled = false;

  // Potrebujeme niektoré formulárové polia sledovať,
  // aby sme vyvolali validáciu aj na zmenu adresy.
  addressZip = new FormControl("");
  addressCity = new FormControl("");
  addressHouseNr = new FormControl("");
  addressCountry = new FormControl();

  private _countries: Shared.Country[] = null;
  private _isSaveAddressChecked;
  private _destroy$ = new Subject<void>();
  private _recipientChange$ = new Subject<void>();
  private _checkStreetAndCity$ = new Subject<void>();

  @ViewChild("recipientform") form: NgForm;

  @ViewChild("eoriControl") eoriControl: NgModel;

  constructor(
    private _loggingService: Shared.LoggingService,
    private _localizationService: Shared.LocalizationService,
    private _shipmentService: ShipmentService,
    private _recipientsService: RecipientsService,
    private _contextService: Shared.ContextService,
    private _skDataConfigService: SkdataConfigService,
    private _completerService: CompleterService,
    private _settingsService: SettingsService,
    private _customsClearenceService: CustomClearanceService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _toastr: ToastrService,
    private _editor: ShipmentEditorComponent,
    private _newsfeedService: NewsfeedService
  ) {

  }


  get selectedCountryCode(): string {
    return this.recipient?.country?.code ?? this.settings?.country_code?.value ?? "";
  }


  get reasonsForExport(): Observable<ConfigModel.ReasonForExport[]> {
    return this._customsClearenceService.reasonsForExport;
  }


  get isReceiverBusiness() {
    return this.recipientType === RECEIVER_TYPE_BUSINESS;
  }

  
  get isEoriNumberRequired(): boolean {
    return this.isCustomsClearanceBoundFieldsRequiredValidationOn &&
      this.isReceiverBusiness;
  }


  get isSpecialOrderCountrySelected(): boolean {
    return this.recipient.country?.code === this.specialOrderCountryCode;
  }


  ngOnChanges(changes: SimpleChanges) {
    const selectedProduct: SimpleChange = changes.selectedProduct;

    if (selectedProduct && selectedProduct.currentValue) {
      if (selectedProduct.currentValue.code === ConfigModel.WellKnownProductCodes.CityService) {
        this.isCityServiceSelected = true;

        const _countrySK = _.find(this.countries, (c: Shared.Country) => c.code === "SK");

        if (!_.includes(this.recipient.city.toLowerCase(), "bratislava") || this.recipient.country != _countrySK) {
          this.recipient.city = "BRATISLAVA";
          this.recipient.country = _.find(this.countries, (c: Shared.Country) => c.code === "SK");
          this.recipient.zip = "";
        }

        this.addressCity.disable();
        this.addressCountry.disable();

        if (!this.streetsDataService) {
          this._shipmentService.getCityServiceStreets().subscribe(data => {

            this.streetsDataService = this._completerService.local(data, "name", "name");

          }, ex => {
            this._loggingService.logErrorData(ex, "Error loading City Service streets");

            const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

            this._toastr.error(`${this._localizationService.getLocalizedString("error_loading_city_service_streets")}:\n ${this._localizationService.getLocalizedExceptionString(exceptionInfo)}`);
          });
        }
      } else {
        this.isCityServiceSelected = false;

        this.addressCity.enable();
        this.addressCountry.enable();
      }
    }

    if ("recipient" in changes) {
      this.setEoriNumberModels();
      this._recipientChange$.next();
    }

    if ("disableValidation" in changes &&
        changes.disableValidation.previousValue !== changes.disableValidation.currentValue) {
      this.setAddressValidators();
    }
  }


  ngOnInit() {
    // this.citiesDataService = this._completerService.remote(null, null, "cityNameLocal");
    // this.citiesDataService.urlFormater((term: string) => {
    //   let countryCode = this.recipient.country ? this.recipient.country.code : this.citiesCountry.code;

    //   return `${Shared.API_URL}/countries/${countryCode}/${term}`;
    // });

    // this.zipCityPairsDataService = this._completerService.remote(null, null, "zip").descriptionField("cityName");
    // this.zipCityPairsDataService.urlFormater((term: string) => {
    //   let countryCode = this.recipient.country ? this.recipient.country.code : this.citiesCountry.code;

    //   return `${Shared.API_URL}/georouting/countries/${countryCode}/zips/${term}/city-names`;
    // });


    this._settingsService.activeConfigurationSettings$.pipe(
      map(settings => settings.minimal_recipient_search_char_count.value)
    ).subscribe(value => this.minSearchLength = value);

    this._contextService.currentAddress.pipe(
      takeUntil(this._destroy$),
    ).subscribe(senderAddress => this.selectedSenderAddress = senderAddress);

    this.addressCountry.valueChanges.pipe(
      // Use auditTime to ensure the value is correct.
      // When recipient model is changed the wrong use of ngModel and FormControl directives causes
      // the "previous" value of the control to be emited what would reset the new recipient country
      // to the previous value.
      // SHPS-2522
      auditTime(0),
    ).subscribe(c => {
      if (c && typeof c.code === "string") {
        this.recipient.countryCode = c?.code;
        this.recipient.country = c;
      }
      this.recipientAddressCountryChange.emit(c);
    });

    this.setAddressValidators();
  }

  ngAfterViewInit(): void {

    // Z nejakého dôvodu nepatria nasledujúce kontrolky pod centrálny formulár
    // a ich dirty flag nevplýva na dirty flag formulára.
    // Keď ich pridáme medzi kontrolky formulára takto explicitne, tak je to ok.
    this.form.form.addControl(this.name + "_recipient_country", this.addressCountry);
    this.form.form.addControl(this.name + "_recipient_zip", this.addressZip);
    this.form.form.addControl(this.name + "_recipient-city", this.addressCity);
    this.form.form.addControl(this.name + "_recipient_house_nr", this.addressHouseNr);

    this.setUpAddressChangeListeners();

    if (this.isEmailOrPhoneRequired) {
      this.form.form.setValidators(this.validateEmailOrPhoneFilledOut.bind(this));
    }

    this.checkZipWarning();
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  onEoriNumberChange() {
    if (this.eoriNumberCountryCode && this.eoriNumberWithoutCountryCode) {
      this.recipient.eoriNumber = this.eoriNumberCountryCode + this.eoriNumberWithoutCountryCode;
    } else {
      this.recipient.eoriNumber = "";
    }
  }


  onCounrySelect() {
    if (!this.eoriNumberWithoutCountryCode) {
      this.eoriNumberCountryCode = this.recipient.country?.code;
    }
  }


  private setEoriNumberModels() {
    if (this.recipient.eoriNumber) {
      this.eoriNumberWithoutCountryCode = this.recipient.eoriNumber.slice(2);
      this.eoriNumberCountryCode = this.recipient.eoriNumber.slice(0, 2);
    }
  }


  updateValueAndValidity() {
    this.addressZip.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.addressCity.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }


  private setUpAddressChangeListeners() {
    this.addressCountry.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(() => this.setAddressValidators());

    let isRecipientInitCityAndStreetResolved = true;

    // Load city and street for special orders - on recipinet change.
    this._recipientChange$.pipe(
      startWith(0),
      tap(() => isRecipientInitCityAndStreetResolved = false),
      // Async - update control values.
      delay(0),
      map(() => this.calculateGetCityAndStreetParameters()),
      map(p => this.shouldCheckCityAndStreetPair(p))
    ).subscribe(shouldCheck => {
      isRecipientInitCityAndStreetResolved = !shouldCheck;
      this.isZipAndHouseNrCombinationInvalid &&= shouldCheck;

      if (shouldCheck) {
        this._checkStreetAndCity$.next();
      }
    });

    // Load city and street for special orders - on input changes.
    merge(
      this.addressZip.valueChanges,
      this.addressHouseNr.valueChanges
    ).pipe(
      // Ignore changes while the recipient initializes or load is disabled.
      filter(() => isRecipientInitCityAndStreetResolved && !this.isSpecialOrderAddressEditEnabled),
      debounceTime(600),
      map(() => this.calculateGetCityAndStreetParameters()),
      distinctUntilChanged((c, p) => _.isEqual(c, p)),
    ).subscribe(() => this._checkStreetAndCity$.next()),

    // Load city and street for special orders.
    this._checkStreetAndCity$.pipe(
      debounceTime(0),
      map(() => this.calculateGetCityAndStreetParameters()),
      filter(v => this.shouldCheckCityAndStreetPair(v)),
      tap(() => this.isZipAndHouseNrCombinationInvalid = false),
      switchMap(v => {
        return this._skDataConfigService.getStreetAndCity(v.zip, v.houseNr).pipe(
          // Cancel on new request
          takeUntil(this._checkStreetAndCity$),
          catchError(() => {
            this.isZipAndHouseNrCombinationInvalid = true;

            return of({street: "", city: ""});
          }),
          switchMap(result => {
            if (isRecipientInitCityAndStreetResolved) {
              return of(result);
            } else {
              isRecipientInitCityAndStreetResolved = true;
              this.isSpecialOrderAddressEditEnabled =
                result.city !== this.addressCity.value ||
                result.street !== this.recipient.street;
              return NEVER;
            }
          }),
        );
      }))
      .subscribe(value => {
        this.addressCity.setValue(value.city);
        this.recipient.street = value.street;
      }, () => { });

    merge(
      this.addressZip.valueChanges,
      this.addressCity.valueChanges,
      this.addressCountry.valueChanges
    ).pipe(
      map(() => ({
        city: this.addressCity.value,
        zip: this.addressZip.value,
        country: this.addressCountry.value
      })),
      distinctUntilChanged((c, p) => _.isEqual(c, p)),
      debounceTime(600)
    ).subscribe(() => {
      this.checkZipWarning();

      if (!this.suspendRoutingValidation) {
        this.setProductsAndAdditionalServicesAvailabilityEmitter.emit();
      }
    });

    this.addressZip.valueChanges.pipe(
      debounceTime(600)
    ).subscribe((zip) => this.recipientZipChange.emit(zip));
  }


  private calculateGetCityAndStreetParameters(): {[key: string]: any} {
    return {
      city: this.addressCity.value,
      zip: this.addressZip.value?.zip ?? this.addressZip.value,
      street: this.recipient.street,
      houseNr: this.addressHouseNr.value
    };
  }


  private shouldCheckCityAndStreetPair(parameters: {[key: string]: string}): boolean {
    return this.isSpecialOrder &&
      this.isSpecialOrderCountrySelected &&
      Boolean(parameters.houseNr) &&
      Boolean(parameters.zip);
  }


  onIsSpecialOrderAddressEditEnabled() {
    if (!this.isSpecialOrderAddressEditEnabled) {
      this._checkStreetAndCity$.next();
    }
  }


  zipCityPairSelected(selected: CompleterItem) {
    if (selected && this.specialOrderCountryCode !== this.recipient.countryCode) {
      const zipCityPairModel = selected.originalObject as ZipCityPair;

      this.recipient.city = zipCityPairModel.cityName;
    }
  }


  onRecipientSelected(selected: RecipientModel.Recipient) {
    if (selected) {
      this.setRecipient(selected);
    }
  }


  onZipSelected(selected: ZipCityPair) {
    if (typeof selected === "object") {
      if (this.specialOrderCountryCode !== this.recipient.country.code ||
          !this.isSpecialOrder) {
        this.recipient.city = selected.cityName;
        this.addressCity.setValue(selected.cityName);
      }
      this.recipient.zip = selected.zip;
      this.addressZip.setValue(selected.zip);
    }
  }


  onCitySelected(selected: CityZipRange) {
    if (typeof selected === "object") {
      this.recipient.city = selected.cityNameLocal;
      this.addressCity.setValue(selected.cityNameLocal);

      if (selected.beginPostCode) {
        this.recipient.zip = selected.beginPostCode;
        this.addressZip.setValue(selected.beginPostCode);
      }
    }
  }


  private setRecipient(recipient: RecipientModel.Recipient) {
    const newRecipient = {
      ...this.recipient,
      ...recipient,
      country: this.countries.find(c => c.code === recipient.countryCode)
    };

    window.setTimeout(() => {
      _.assign(this.recipient, newRecipient);
      this.setEoriNumberModels();
      this._recipientChange$.next();
    }, 10);
  }


  clearRecipient() {
    const emptyRecipient = this._editor.getEmptyRecipient();

    _.assign(this.recipient, emptyRecipient);

    this.isSpecialOrderAddressEditEnabled = false;

    this.form.form.markAsDirty();
  }


  hasUnsavedChanges = (): boolean => {
    return this.form.dirty;
  }


  formInvalid() {
    return !this.form.form.valid;
  }


  private validateEmailOrPhoneFilledOut(control: FormGroup): ValidationErrors | null {
    const controlNames = ["_recipient_email", "_recipient_phone"];

    const valid = controlNames.some(name => {
      const childControl = control.get(`${this.name}${name}`);

      if (!childControl) {
        return false;
      }

      const value = childControl.value;
      return !isEmptyInputValue(value);
    });

    return valid ? null : { emailOrPhoneRequired: true };
  }


  filterCities(event): void {
    const query = event.query;
    const countryCode = this.recipient.country ? this.recipient.country.code : this.citiesCountry.code;

    this._skDataConfigService.getZipsByCity(countryCode, query)
      .subscribe(cities => this.filteredCities = cities, () => { });
  }


  filterRecipients(event): void {

    const query = event.query;

    const filter: RecipientModel.RecipientsFilter = {
      searchText: query,
      addressId: this.selectedSenderAddress.id,
      customerDetailId: this.selectedSenderAddress.customerDetailId,

      tenantId: null,
      extendedSearch: null,
      customerRecipientTypeCode: null,
      name: null,
      address: null,
      contactInformation: null,
      referenceNumber: null,
      orderByFieldName: null,
      orderAscending: null,
      pageIndex: null,
      pageSize: null
    };

    this._recipientsService.getFilteredRecipients(filter)
      .subscribe(result => this.filteredRecipients = result.items, () => { });
  }


  filterZip(event): void {
    const query = event.query;
    const countryCode = this.recipient.country ? this.recipient.country.code : this.citiesCountry.code;

    this._skDataConfigService.getCitiesByZip(countryCode, query)
      .subscribe(result => this.filteredZips = result, () => { });
  }


  checkZipWarning() {
    this.zipWarnings = null;

    const zip = this.recipient?.zip;
    const countryCode = this.recipient.country?.code;

    if (!zip || !countryCode) {
      return;
    }

    this._newsfeedService.getZipWarnings(countryCode, zip).subscribe(zipWarnings => {
      this.zipWarnings = zipWarnings.map(zp => zp.title);

      if (this.zipWarnings.length < 1) {
        this.zipWarnings = null;
      }
    });
  }


  /**
   * Sets validators for address fields FormControls.
   *
   * * addressZip
   * * addressCity
   * * addressHouseNr
   * * addressCountry
   *
   * Note:
   * (TFS: #10515)
   * When combining validator directives (e.g. "required") in the template and
   * reactive forms (e.g. [FromControl]), what should not be done, validators
   * are added to the FormControl and never removed. Therefore conditions
   * in the template (e.g. [required]="someBool") don't work.
   */
  private setAddressValidators() {
    this.addressZip.setValidators([
      Validators.required,
      this.validateZip,
      validateZipNoSpaces,
      validateZipFormat(() => this.getZipFormatsForSelectedCountry())
    ]);
    this.addressZip.updateValueAndValidity({emitEvent: false});

    this.addressCity.setValidators([
      Validators.required,
      Validators.maxLength(this.disableValidation ? 100 : 35),
      this.validateCity
    ]);
    this.addressCity.updateValueAndValidity({emitEvent: false});

    this.addressHouseNr.setValidators([
      this.isSpecialOrderCountrySelected ? Validators.required : Validators.nullValidator,
      Validators.maxLength(this.disableValidation ? 100 : 8)
    ]);
    this.addressHouseNr.updateValueAndValidity({emitEvent: false});

    this.addressCountry.setValidators([Validators.required]);
    this.addressCountry.updateValueAndValidity({emitEvent: false});
  }


  private getZipFormatsForSelectedCountry(): string[] {
    return this.countries.find(c => c.code === this.selectedCountryCode)?.postCodePattern?.split(",") ?? [];
  }

  /**
   * Validačná funkcia pre FormControl zobrazujúci ZIP.
   * Sama validáciu nepúšťa, len aktualizuje hodnotu podľa
   * toho, ako prebehla validácia v rámci setProductsAndAdditionalServicesAvailability().
   */
  validateZip = () => {
    if (this._zipIsInvalid) {
      return {
        zip: {
          valid: false
        }
      };
    }

    return null;
  }

  /**
   * Validačná funkcia pre FormControl zobrazujúci mesto.
   * Sama validáciu nepúšťa, len aktualizuje hodnotu podľa
   * toho, ako prebehla validácia v rámci setProductsAndAdditionalServicesAvailability().
   */
  validateCity = () => {
    if (this._cityIsInvalid) {
      return {
        city: {
          valid: false
        }
      };
    }

    return null;
  }
}
