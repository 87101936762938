<div class="dialog shipments-import-dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>

  <h1 [innerHtml]="'upload_bank_account_attachment' | shpLocalize"></h1>

  <form novalidate [formGroup]="form">
    <div class="panel message" *ngIf="uploadFailedInvalidFile">
      <div class="error">{{ 'bank_account_upload_error_invalid_file' | shpLocalize }}</div>
    </div>

    <div class="ctl">
      <label for="selected-file">{{ 'file_to_upload' | shpLocalize }}</label>
      <div class="file-input-group">
        <input id="input-filename" type="text" formControlName="selectedFileName" readonly="readonly" />
        <input type="file" formControlName="selectedFile" id="selected-file" (change)="selectedFileChanged($event)" />
        <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
      </div>
    </div>

    <div class="ctl">
      <label for="attachment-name">{{ 'name' | shpLocalize }}</label>
      <input type="text" formControlName="name" id="attachment-name" />
    </div>

    <div class="ctl">
      <label for="attachment-description">{{ 'description' | shpLocalize }}</label>
      <input type="text" formControlName="description" id="attachment-description" />
    </div>
  </form>

  <div class="buttons">
    <button id="button-import" [disabled]="!form || !form.valid" type="button" (click)="uploadFile()">{{ 'upload' | shpLocalize }}</button>
    <button id="button-close" [disabled]="isBusy" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>
</div>