import { Component, OnDestroy, SimpleChange, SimpleChanges, ViewChild, Input, EventEmitter, Output, ComponentFactoryResolver } from "@angular/core";
import { CompleterService } from "ng2-completer";
import { NgForm, NgModel } from "@angular/forms";

import { forkJoin, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as Shared from "../../shared/index";
import * as ConfigModel from "../../shared/models/config.models";

import * as _ from "lodash";
import { Invoice, CustomClearanceEditorData, ShipmentTypeCodeDocuments } from "../models/custom-clearance.model";
import { amendValueForFormattedFormControl } from "../../shared/form-helpers";
import { CustomClearanceService } from "../services/custom-clearance.service";
import { BUSINESS_UNIT_CODE_CH } from "../../shared/index";
import { RECEIVER_TYPE_BUSINESS, ShipmentEditorComponent } from "./shipment-editor.component";
import { Link } from "../../shared/models/link.model";


/**
 * Mark documents that are relevant for specific countries.
 * Property represents documnet code. Array list of countries
 * that use the document.
 */
const countryExlusiveAccompanyingDocsTypes = {
  F: ["TR"]
};

@Component({
  selector: Shared.SELECTOR_PREFIX + "-custom-clearance-view",
  templateUrl: "./custom-clearance-view.component.html",
  providers: [CompleterService]
})
export class CustomClearanceViewComponent implements OnDestroy {

  private _invoiceLines: number = 0;
  private _eoriNumberWithoutCountryCode: string = "";
  private _countryCode: string = "";
  private _isCustomSenderInvoiceAddress: boolean = false;
  private _isCustomReceiverInvoiceAddress: boolean = false;
  private _abbreviationsAndExplanationsText: string = "";
  private _destroy$ = new Subject<void>();

  public helpLinkCommercialInvoices: string = "";
  public helpLinkShippingPlanner: string = "";
  public helpLinkCustomsRegulations: string = "";
  public helpLinks: Link[] = [];
  public availableAccompanyingDocsTypes: any[] = [];
  public requiredVatAndEoriForNonEuCountries = false;

  public showExportReason: boolean;
  public defaultExportReason: string;

  public selectedAccompanyingDocsTypes: any[] = [];

  @Input("invoice")
  public invoice: Invoice = {} as Invoice;

  @Input("customClearanceEditorData")
  public customClearanceEditorData: CustomClearanceEditorData;

  @Input()
  public invoiceAdditionalLines: number = 0;
  @Output()
  public invoiceAdditionalLinesChange: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public customSenderInvoiceAddress: boolean = false;
  @Output()
  public customSenderInvoiceAddressChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public customReceiverInvoiceAddress: boolean = false;
  @Output()
  public customReceiverInvoiceAddressChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public isReadOnly: boolean;

  private _recipientCountryCode: string;
  private _senderCountryCode: string;

  @Input()
  public set recipientCountryCode(value: string) {
    this._recipientCountryCode = value;

    this.getCountryFilteredIncoTerms();
    this.getCountrySpecificHideClearanceCleared();
    this.getCountrySpecificHidePrealertStatus();
    this.getCountrySpecificHideTypeOfDocument();
    this.toggleExportReason();
    this.getIsEuCountryRecipient();
  }

  public get recipientCountryCode() {
    return this._recipientCountryCode;
  }


  @Input()
  public set senderCountryCode(value: string){
    this._senderCountryCode = value;

    this.getIsEuCountrySender();
  }

  public get senderCountryCode(){
    return this._senderCountryCode;
  }


  @Input("recipientCurrencyCode")
  public recipientCurrencyCode = "";

  @Input("countries")
  public set countries(value: Shared.Country[]) {
    if (Array.isArray(value)) {
      this._countries = _.sortBy(value, "code");
    }
  }

  public get countries(): Shared.Country[] {
    return this._countries;
  }

  private _countries: Shared.Country[] = null;

  @Input("name")
  public name: string;

  @Input("shipmentTypes")
  public shipmentTypes: ConfigModel.ShipmentType[] = null;

  public shipmentTypesWithoutDocuments: ConfigModel.ShipmentType[] = null;

  @Input("clearanceCleareds")
  public clearanceCleareds: ConfigModel.ClearanceCleared[] = null;

  @Input("prealertStatuses")
  public prealertStatuses: ConfigModel.PrealertStatus[] = null;


  private _incoTerms: ConfigModel.IncoTerm[] = null;

  @Input("incoTerms")
  public set incoTerms(value: ConfigModel.IncoTerm[]) {
    this._incoTerms = value;

    this.getCountryFilteredIncoTerms();
  }

  public countryFilteredIncoTerms: ConfigModel.IncoTerm[] = [];

  private getCountryFilteredIncoTerms() {
    if (this._recipientCountryCode && this._incoTerms !== null) {
      this._businessUnitSettingsService.getCountrySpecificIncoTerms(this._recipientCountryCode).subscribe(csi => {
        if (csi !== null && csi !== "") {
          const csiSplit = _.split(csi, ",");

          this.countryFilteredIncoTerms = this._incoTerms.filter(inco => csiSplit.indexOf(inco.code) > -1);
        } else {
          this.countryFilteredIncoTerms = this._incoTerms;
        }

        if (this.customClearanceEditorData && this.customClearanceEditorData.incoTermCode) {

          if (!_.find(this.countryFilteredIncoTerms, inco => inco.code === this.customClearanceEditorData.incoTermCode)) {
            if (this.countryFilteredIncoTerms.length === 0) {
              this.customClearanceEditorData.incoTermCode = null;
            } else {
              this.customClearanceEditorData.incoTermCode = this.countryFilteredIncoTerms[0].code;
            }
          }
        }

        if (this.form) {
          this.form.form.updateValueAndValidity();
        }

      });
    }
    else {
      this.countryFilteredIncoTerms = [];
    }
  }

  @Input("additionalLines")
  public additionalLines: number = 0;

  @Input("currencies")
  public currencies: ConfigModel.Currency[] = null;

  @Input("accompanyingDocsTypes")
  public accompanyingDocsTypes: any[] = null;

  @Input("disableClearanceCleared")
  public disableClearanceCleared: boolean = false;

  @Input("disablePrealertStatus")
  public disablePrealertStatus: boolean = false;

  @Input("hideClearanceCleared")
  public hideClearanceCleared: boolean;

  public countrySpecificHideClearanceCleared: boolean = false;

  private getCountrySpecificHideClearanceCleared() {
    if (this._recipientCountryCode) {
      this._businessUnitSettingsService.getCountrySpecificHideClearanceCleared(this._recipientCountryCode).subscribe(val => {
        this.countrySpecificHideClearanceCleared = val;

        if (this.form) {
          this.form.form.updateValueAndValidity();
        }
      });
    }
  }

  @Input("hidePrealertStatus")
  public hidePrealertStatus: boolean = false;

  public countrySpecificHidePrealertStatus: boolean = false;

  private getCountrySpecificHidePrealertStatus() {
    if (this._recipientCountryCode) {
      this._businessUnitSettingsService.getCountrySpecificHidePrealertStatus(this._recipientCountryCode).subscribe(val => {
        this.countrySpecificHidePrealertStatus = val;

        if (this.form) {
          this.form.form.updateValueAndValidity();
        }
      });
    }
  }

  public countrySpecificHideTypeOfDocument: boolean = false;

  private getCountrySpecificHideTypeOfDocument() {
    if (this._recipientCountryCode) {
      this._businessUnitSettingsService.getCountrySpecificHideTypeOfDocument(this._recipientCountryCode).subscribe(val => {
        this.countrySpecificHideTypeOfDocument = val;

        this.updateShipmentTypes();

        if (this.form) {
          this.form.form.updateValueAndValidity();
        }
      });
    }
  }

  @Input()
  public isInvoiceNumberRequired = false;

  @Input()
  public isDateOfIssueRequired = false;

  @Input() public recipientType = RECEIVER_TYPE_BUSINESS;

  @Input() public tooltips: any;

  @Input()
  public isRequiredValidationOn = true;
  
  @Input()
  public isExportMrnFieldVisible = false;

  @Output() shipmentTypeChange = new EventEmitter<string>();

  @ViewChild("customClearanceForm")
  public form: NgForm;

  @ViewChild("eoriControl")
  public eoriControl: NgModel;

  public get isBrexitCountryRecipient() {
    return this.recipientCountryCode === "GB";
  }

  public isEuCountryRecipient: boolean = false;
  public isEuCountrySender: boolean = false;

  public get invoiceLines() {
    return this._invoiceLines;
  }

  public set invoiceLines(value: number) {
    this._invoiceLines = value;
    this.invoiceAdditionalLinesChange.emit(this.invoiceLines);
  }

  public get eoriNumberWithoutCountryCode() {
    return this._eoriNumberWithoutCountryCode;
  }

  public set eoriNumberWithoutCountryCode(value) {
    this._eoriNumberWithoutCountryCode = value;
    this.customClearanceEditorData.eoriNumber = this.eoriNumber;
  }

  public get countryCode() {
    return this._countryCode;
  }

  public set countryCode(value) {
    this._countryCode = value;
    this.customClearanceEditorData.eoriNumber = this.eoriNumber;
  }

  public get eoriNumber() {
    if (this._countryCode !== "" && this._eoriNumberWithoutCountryCode && this._eoriNumberWithoutCountryCode.length) {
      return this._countryCode + this._eoriNumberWithoutCountryCode;
    } else {
      return "";
    }
  }

  public set eoriNumber(value) {
    if (value && value.length > 2) {
      this._eoriNumberWithoutCountryCode = value.slice(2, value.length);
      this._countryCode = value.slice(0, 2);
    }
  }
  @Input()
  public get isCustomSenderInvoiceAddress() {
    return this._isCustomSenderInvoiceAddress;
  }

  public set isCustomSenderInvoiceAddress(value: boolean) {
    this._isCustomSenderInvoiceAddress = value;
    this.customSenderInvoiceAddressChange.emit(this.isCustomSenderInvoiceAddress);
  }
  @Input()
  public get isCustomReceiverInvoiceAddress() {
    return this._isCustomReceiverInvoiceAddress;
  }

  public set isCustomReceiverInvoiceAddress(value: boolean) {
    this._isCustomReceiverInvoiceAddress = value;
    this.customReceiverInvoiceAddressChange.emit(this.isCustomReceiverInvoiceAddress);
  }

  public get accompayingDocsNames() {
    let accompayingDocsNames = "";
    const customClearanceEditorData = this.customClearanceEditorData;
    if (this.isReadOnly && customClearanceEditorData && customClearanceEditorData !== undefined) {
      const docs = _.filter(this.accompanyingDocsTypes, function (adt) { return _.includes(customClearanceEditorData.accompDocsTypeCodes, adt.code); });
      docs.forEach(d => accompayingDocsNames += d.name + "\n");
    }

    return accompayingDocsNames;
  }

  public get reasonsForExport(): Observable<ConfigModel.ReasonForExport[]> {
    return this._customClearanceService.reasonsForExport;
  }


  public get showCustomsClearanceFields(): boolean {
    return this.customClearanceEditorData.shipmentTypeCode !== ShipmentTypeCodeDocuments;
  }


  public get isReceiverBusiness() {
    return this.recipientType === RECEIVER_TYPE_BUSINESS;
  }

  // TODO check for future how EoriAndVatRequired and EoriOrVatRequired should behave with new BU settings for various BUs (CH, NL, BE)
  public get isEoriAndVatRequired(): boolean {
    return this.isRequiredValidationOn && this.requiredVatAndEoriForNonEuCountries && (!this.isEuCountrySender || !this.isEuCountryRecipient) && this.customClearanceEditorData.shipmentTypeCode !== ShipmentTypeCodeDocuments;
  }

  public get isMrnRequired(): boolean {
    let hasSelectedT1OrExportDeclarationDocuments = _.some(this.customClearanceEditorData.accompDocsTypeCodes, d => d === 'I' || d === 'C');    
    return hasSelectedT1OrExportDeclarationDocuments;
  }

  constructor(
    private _loggingService: Shared.LoggingService,
    private _localizationService: Shared.LocalizationService,
    private _contextService: Shared.ContextService,
    private _customClearanceService: CustomClearanceService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _modal: Modal,
    private _editor: ShipmentEditorComponent
  ) {

  }


  private populateAccompDocsTypeCodes() {
    this.customClearanceEditorData.accompDocsTypeCodes = _.filter(_.map(this.accompanyingDocsTypes, (ad) => { if (ad.checked) { return ad.code; } }));
  }

  public accompanyingDocsChanged = () => {
    this.populateAccompDocsTypeCodes();
  }

  public onAccompanyingDocsTypesChanged(event) {
    this.customClearanceEditorData.accompDocsTypeCodes = event.value;
  }

  public ngOnChanges(changes: SimpleChanges) {
    // const invoiceAdditionalLines: SimpleChange = changes.invoiceAdditionalLines;
    // const recipientCurrencyCode: SimpleChange = changes.recipientCurrencyCode;
    const customSenderInvoiceAddress: SimpleChange = changes.customSenderInvoiceAddress;
    const customReceiverInvoiceAddress: SimpleChange = changes.customReceiverInvoiceAddress;
    const customClearanceEditorData: SimpleChange = changes.customClearanceEditorData;
    const recipientCountryCode: SimpleChange = changes.recipientCountryCode;

    // if (invoiceAdditionalLines && invoiceAdditionalLines.currentValue) {
    //   this.invoiceLines = invoiceAdditionalLines.currentValue;
    // }

    // if (recipientCurrencyCode && recipientCurrencyCode.currentValue &&
    //     !(this.invoice && this.invoice.amountEx)) {
    //   this.invoice.currencyCodeEx = recipientCurrencyCode.currentValue;
    // }

    if (customSenderInvoiceAddress && customSenderInvoiceAddress.currentValue) {
      this.isCustomSenderInvoiceAddress = customSenderInvoiceAddress.currentValue;
    }

    if (customReceiverInvoiceAddress && customReceiverInvoiceAddress.currentValue) {
      this.isCustomReceiverInvoiceAddress = customReceiverInvoiceAddress.currentValue;
    }

    if (customClearanceEditorData && customClearanceEditorData.currentValue) {
      this.eoriNumber = customClearanceEditorData.currentValue.eoriNumber;

      if (!Boolean(this.eoriNumber)) {
        this.countryCode = this._editor.settings?.country_code.value;
      }

      this.accompanyingDocsTypes?.map(adt => {
        if (_.includes(customClearanceEditorData.currentValue.accompDocsTypeCodes, adt.code)) {
          // adt.checked = true;
          this.selectedAccompanyingDocsTypes.push(adt);
        }
      });
    }

    if (recipientCountryCode && recipientCountryCode.currentValue) {
      this.setAvailableAvailableAccompanyingDocsTypes();
      this.toggleExportReason();
    }
  }

  private updateShipmentTypes()
  {
    this.shipmentTypesWithoutDocuments = this.shipmentTypes?.slice();
    this.shipmentTypesWithoutDocuments?.shift();
  }


  public ngOnInit() {
    if (this.invoice === null) {
      this.invoice = this.getEmptyInvoice();
    }

    this.invoice.exportReasonCode = this.defaultExportReason;

    this.invoice.dateOfIssue = amendValueForFormattedFormControl(this.invoice.dateOfIssue);

    this.invoiceLines = this.invoiceAdditionalLines;
    this.isCustomSenderInvoiceAddress = this.customSenderInvoiceAddress;
    this.isCustomReceiverInvoiceAddress = this.customReceiverInvoiceAddress;

    this.updateShipmentTypes();

    forkJoin([
      this._businessUnitSettingsService.getHelpLinkCommercialInvoicesForCustomClearance(),
      this._businessUnitSettingsService.getHelpLinkShippingPlannerForCustomClearance(),
      this._businessUnitSettingsService.getHelpLinkCustomsRegulationsForCustomClearance(),
      this._businessUnitSettingsService.getOwnCustomsClearanceHelpLinks(),
      this._businessUnitSettingsService.getRequiredVatAndEoriForNonEuCountries()
    ]).subscribe((result: any[]) => {
      this.helpLinkCommercialInvoices = result[0];
      this.helpLinkShippingPlanner = result[1];
      this.helpLinkCustomsRegulations = result[2];
      this.helpLinks = result[3].get(this._localizationService.currentLocalizationCode.toLowerCase() || "en");
      this.requiredVatAndEoriForNonEuCountries = result[4];
    }, ex => {
      this._loggingService.logErrorData(ex);
    });


    const newLine = "<br />";
    let abbreviationsAndExplanationsText = `L = ${this._localizationService.getLocalizedString("parcel_length")}` + newLine;
    abbreviationsAndExplanationsText += `W = ${this._localizationService.getLocalizedString("parcel_width")}` + newLine;
    abbreviationsAndExplanationsText += `H = ${this._localizationService.getLocalizedString("parcel_height")}` + newLine;
    abbreviationsAndExplanationsText += `HI = ${this._localizationService.getLocalizedString("highter_insurance")}` + newLine;
    abbreviationsAndExplanationsText += `HI value = ${this._localizationService.getLocalizedString("highter_insurance_value")}` + newLine;
    abbreviationsAndExplanationsText += `CTN = ${this._localizationService.getLocalizedString("ctn_explanation")}` + newLine;
    abbreviationsAndExplanationsText += `HS-code = ${this._localizationService.getLocalizedString("hs_code_explanation")}` + newLine;
    abbreviationsAndExplanationsText += `TARIC = ${this._localizationService.getLocalizedString("taric_explanation")}` + newLine;
    abbreviationsAndExplanationsText += `EORI = ${this._localizationService.getLocalizedString("eori_explanation")}` + newLine;
    abbreviationsAndExplanationsText += `VAT = ${this._localizationService.getLocalizedString("vat_explanation")}` + newLine;
    abbreviationsAndExplanationsText += `LQ = ${this._localizationService.getLocalizedString("lq_explanation")}` + newLine;

    this._abbreviationsAndExplanationsText = abbreviationsAndExplanationsText;

    this._contextService.businessUnitCode.pipe(
      takeUntil(this._destroy$)
    ).subscribe(code => {

        if (this.form) {
          this.form.form.updateValueAndValidity();
        }
      });
  }

  public ngAfterViewInit(): void {
    this.initEoriOrVatValidation();
  }


  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  private getEmptyInvoice = (): Invoice => {
    const invoice: Invoice = {
      id: 0,
      createdByUser: "",
      modifiedByUser: "",
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      isNew: true,
      isPersistent: false,

      number: "",
      dateOfIssue: new Date(),
      orderId: 0,
      amount: this.invoice ? this.invoice.amount : 0, // tento atribút je read only a počíta sa z InvoiceLine
      currencyCode: "",
      comment: "",
      amountEx: 0,
      currencyCodeEx: "",
      isInvoceAddressDifferent: false,
      exportReasonCode: this.defaultExportReason,
      sellerEoriNumber: "",
      sellerVatNumber: "",
      payerVatNumber: "",
      payerEoriNumber: "",
      mrn: ""
    };
    return invoice;
  }


  /**
   * Filters accompanying document types according to current recipient country.
   */
  private setAvailableAvailableAccompanyingDocsTypes() {
    this.availableAccompanyingDocsTypes = this.accompanyingDocsTypes.filter(doc => {
      const exlusiveCountries = countryExlusiveAccompanyingDocsTypes[doc.code];

      /**
       * If list of countries exists for the documnet code
       * country must be included in the list.
       * Otherwise document automatically passes.
       */
      return exlusiveCountries && exlusiveCountries.length ?
        exlusiveCountries.includes(this.recipientCountryCode) :
        true;
    });
  }

  private toggleExportReason() {
    this._businessUnitSettingsService.getCountrySpecificShowExportReason(this.recipientCountryCode)
      .subscribe(value => this.showExportReason = value);

    this._businessUnitSettingsService.getCountrySpecificDefaultExportReason(this.recipientCountryCode)
      .subscribe(value => {
        this.defaultExportReason = value === "" ? "01" : value;
        this.invoice.exportReasonCode = this.defaultExportReason;
      });
  }

  private getIsEuCountryRecipient() {
    this._customClearanceService.getIsEuCountry(this.recipientCountryCode)
      .subscribe(value => {
        this.isEuCountryRecipient = value;
        
        console.log('Is EU Country Recipient: ',  this.isEuCountryRecipient);
        this.form.form.updateValueAndValidity();
      });
  }

  private getIsEuCountrySender() {
    this._customClearanceService.getIsEuCountry(this.senderCountryCode)
      .subscribe(value => {
        this.isEuCountrySender = value;

        console.log('Is EU Country Sender: ', this.isEuCountrySender);
        this.form.form.updateValueAndValidity();
      });
  }

  public showAbbreviationsAndExplanations = (event?) => {
    if (event) {
      event.preventDefault();
    }

    this._modal.alert().body(this._abbreviationsAndExplanationsText).open();

    return false;
  }


  public clearInvoice() {
    const emptyInvoice = this.getEmptyInvoice();

    _.assign(this.invoice, emptyInvoice);

    // Z nejakého dôvodu assign povyše nespôsobí nastavenie formulára na dirty, takže to vyvoláme manuálne.
    this.form.form.markAsDirty();
  }

  public hasUnsavedChanges = (): boolean => {
    return this.form.dirty;
  }

  public onShipmentTypeChange({ value }) {
    this.shipmentTypeChange.emit(value);
  }

  private initEoriOrVatValidation() {
    this.form.form.setValidators(() => {
      if (!this.isEoriAndVatRequired) {
        return null;
      }

      const vat = this.isReadOnly ?
        this.customClearanceEditorData.vatNumber :
        this.form.form.get(`${this.name}_consignor_vat`)?.value;

      const eori = this.isReadOnly ?
        this.customClearanceEditorData.eoriNumber :
        this.form.form.get(`${this.name}_eori_number`)?.value;

      const hasVat = Boolean(vat);
      const hasEori = Boolean(eori);

      if (this.isEoriAndVatRequired) {
        const isValid = this.customClearanceEditorData && hasEori && hasVat;
        return isValid ? null : {eoriAndVatRequired: true};
      }
    });
  }

  formInvalid() {
    return !this.form.form.valid;
  }
}
