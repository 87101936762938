import { Routes, RouterModule } from "@angular/router"

import { PickupPointsComponent } from "./pickup-points/components/pickup-points.component"
import { NotificationsComponent } from "./notifications/components/notifications.component"

import { SHIPMENTS_ROUTES } from "./shipments/shipments.routes"
import { RECIPIENTS_ROUTES } from "./recipients/recipients.routes"
import { SETTINGS_ROUTES } from "./settings/settings.routes"
import { IMPORT_PROFILES_ROUTES } from "./import-profiles/import-profiles.routes"
import { PICKUP_ORDERS_ROUTES } from "./pickup-orders/pickup-orders.routes"

import { AuthorizationGuard } from "./shared/authorization-guard"
import { GlobalActivationGuard } from "./shared/global-activation-guard"
import { UnchangedPasswordGuard } from "./shared/unchanged-password.guard"
import { ShipperAppContainerComponent } from "./app-container.component"


import { LoginPageComponent } from "./user/components/login-page.component";
import { SUPPORT_ROUTES } from "./support/support.routes"


/**
 * Dictionary koreňových routov aplikácie.
 */
const appRoutes: Routes = [
{
  path: "",
  canActivate: [GlobalActivationGuard, UnchangedPasswordGuard],
  canActivateChild: [UnchangedPasswordGuard],
  children: [
  {
      path: "",
      component: ShipperAppContainerComponent,
        children: [
          //{ path: "dashboard", component: DashboardComponent, canActivate: [AuthorizationGuard] },
          ...RECIPIENTS_ROUTES,
          ...SHIPMENTS_ROUTES,
          ...SETTINGS_ROUTES,
          ...IMPORT_PROFILES_ROUTES,
          ...PICKUP_ORDERS_ROUTES,
          ...SUPPORT_ROUTES,
          { path: "pickup-points", component: PickupPointsComponent, canActivate: [AuthorizationGuard] },
          { path: "login", component: LoginPageComponent },
          { path: "messages", component: NotificationsComponent, canActivate: [AuthorizationGuard] },
          { path: "", redirectTo: "/shipments", pathMatch: "full" }
        ]
      }]
  }
];


export const routing = RouterModule.forRoot(appRoutes);