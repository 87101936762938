import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import * as _ from "lodash";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, Subject, Subscription } from "rxjs";
import { filter, finalize, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { SenderScanActionType, SenderScanValueType } from "../../shared/models/config.models";
import * as CustomerModels from "../../shared/models/customer.models";
import * as Models from "../models/shipment.model";
import { ShipmentService } from "../services/shipments.service";


const DEFAULT_SHIPMENTS_PER_PAGE = 20;
const FILTER_STORAGE_KEY = "senderscan-filter";
const DEFAULTS_STORAGE_KEY = "senderscan-defaults";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-sender-scan",
  templateUrl: "./sender-scan.component.html"
})
export class SenderScanComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy, AfterViewInit {
  focusTriggeringEvent = new Subject<void>();
  isBusy = false;
  isMultiselectMode = false;
  languageCode = Shared.getTrackingLocale(Shared.Localization[this.localizationService.currentLocalization]);
  languageCodeIso = Shared.getTrackingLanguageIso(Shared.Localization[this.localizationService.currentLocalization]);
  lastPageIndex = 0;
  senderScanActionType = "";
  senderScanActionTypes: SenderScanActionType[] = null;
  senderScanValue = "";
  senderScanValueType = "";
  senderScanValueTypes: SenderScanValueType[] = null;
  statusMessage = "";
  totalShipmentsCount = 0;

  /**
   * Zoznam shipmentov prístupný cez Observable/Subject, ktorý poskytuje
   * úvodnú hodnotu pri každom subscribnutí.
   */
  shipments: BehaviorSubject<Models.OrderForGridView[]> = new BehaviorSubject([]);

  /** Aktuálne nastavenie prvkov formulára. */
  formDefaults: Models.SenderScanFormDefaults = {
    senderScanValueType: "",
    senderScanActionType: ""
  };

  /** Aktuálne platný filter záznamov zásielok. */
  filter: Models.SenderScanSearchParameters = {
    filter: "",
    parcelReference: "",
    recipientReference: "",
    orderAscending: false,
    orderByFieldName: "CreationDateTimeUtc",
    pageIndex: 0,
    pageSize: DEFAULT_SHIPMENTS_PER_PAGE,
    customerAddressCustomId: null,
    customerDetailId: null,
    isCollectionRequest: null,
    isNormalRequest: null,
    isStateInLabelsPrintedVisible: null,
    isStateInPreparationVisible: null,
    isStatePreparedForDeliveryVisible: null,
    isStateSentToDPDVisible: null,
    isExported: null
  };

  private _currentAddress: CustomerModels.Address = null;
  private _delisId = "";
  private _destroy$ = new Subject<void>();
  private _trackingByReferenceUrl = "";
  private _trackingUrl = "";

  /**
   * Tu máme uloženú referenciu na subscription v prípade, ak sa niečo načítava.
   * Ak zmeníme filter a chceme znovu načítať zoznam, tak vieme s pomocou tejto
   * referencie sa unsubscribnúť z už prebiehajúceho načítavania.
   */
  private _shipmentsSubscription: Subscription = null;


  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _shipmentService: ShipmentService,
    private _contextService: Shared.ContextService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  ngOnInit() {
    this._businessUnitSettingsService.getIsSenderScanVisible().subscribe(is => {
      if (!is) {
        this.goBackToList();
      }
    });

    this._shipmentService.getSenderScanValueTypes()
      .subscribe(result => this.senderScanValueTypes = result);

    this._shipmentService.getSenderScanActionTypes()
      .subscribe(result => this.senderScanActionTypes = result);

    this.loadFilter();
    this.loadFormDefaults();

    combineLatest([
      this._contextService.currentAddress.pipe(filter(ca => ca != null)),
      this._contextService.currentCustomerDetail.pipe(filter(cd => cd != null)),
      this._businessUnitSettingsService.getCentralTrackingByReferenceUrl(),
      this._businessUnitSettingsService.getTrackingUrl(),
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(result => {
      this._currentAddress = result[0];

      this._delisId = result[1]?.delisId;
      this._trackingByReferenceUrl = result[2];
      this._trackingUrl = result[3];
    });
  }


  ngAfterViewInit(): void {
    this.focusTriggeringEvent.next();
  }


  ngOnDestroy() {

  }


  getTrackingByParcelNrUrl(parcelNr: string) {
    return this._trackingUrl
      .replace("{parcelNr}", parcelNr)
      .replace("{languageCode}", this.languageCode)
      .replace("{languageCodeIso}", this.languageCodeIso);
  }


  getTrackingByReferenceUrl(reference: string) {
    return this._trackingByReferenceUrl
      .replace("{delisId}", this._delisId)
      .replace("{reference}", encodeURIComponent(reference).replace("%2F", "~2F"))
      .replace("{languageCode}", this.languageCode)
      .replace("{languageCodeIso}", this.languageCodeIso);
  }


  goBackToList() {
    this.router.navigate(["shipments"]);
  }


  formInvalid() {
  }


  goToPage(pageIndex: number) {
    this.filter.pageIndex = pageIndex;
    this.loadShipments();
  }


  getShipments() {
    const senderScanValue = this.senderScanValue.trim();

    if (senderScanValue == null || senderScanValue === "") {
      return;
    }

    this.filter.filter = null;
    this.filter.parcelReference = null;
    this.filter.recipientReference = null;

    switch (this.senderScanValueType) {
      case "parcel":
        this.filter.parcelReference = senderScanValue;
        break;
      case "recipient":
        this.filter.recipientReference = senderScanValue;
        break;
      default:
        this.filter.parcelReference = senderScanValue;
        this.filter.recipientReference = senderScanValue;
        break;
    }

    this.loadShipments();
  }


  private loadShipments() {
    if (this._currentAddress == null) {
      return;
    }

    // Uložíme si aktuálny filter, aby sme ho mohli použiť po návrate na túto stránku.
    this.saveFilter();

    // Ak niečo načítavame, tak to zrušíme (presnejšie, budeme ignorovať výsledok, keďže request sa zrušiť nedá.
    this._shipmentsSubscription?.unsubscribe();

    this.isBusy = true;

    this.statusMessage = this.localizationService.getLocalizedString("status_message_loading_shipment_orders");

    this.filter.customerDetailId = this._currentAddress.customerDetailId;
    this.filter.customerAddressCustomId = this._currentAddress.customId;

    this._shipmentsSubscription = this._shipmentService.searchShipments(this.filter)
      .subscribe((shipments: Shared.PagedResult<Models.OrderForGridView>) => {

        this.shipments.next(shipments.items);

        this.totalShipmentsCount = shipments.totalSize;
        this.lastPageIndex = Math.ceil(this.totalShipmentsCount / this.filter.pageSize) - 1;

        this.isBusy = false;

        if (this.totalShipmentsCount > 0 && this.totalShipmentsCount <= this.filter.pageIndex * this.filter.pageSize) {
          this.goToPage(this.lastPageIndex);
        }

        if (this.totalShipmentsCount === 1) {
          const shipment = shipments.items[0];

          switch (this.senderScanActionType) {
            case "print":
              this.startLabelsGeneration(shipment);
              this.senderScanValue = "";
              this.focusTriggeringEvent.next();
              this.shipments.next([]);
              break;
            case "edit":
              this.viewOrEditShipmentOrderById(shipment.id);
              break;
          }
        }
      }, (ex) => {
        this.loggingService.logErrorData(ex, "Error loading shipments");
      });
  }


  private loadFilter() {
    const filterJson = localStorage.getItem(FILTER_STORAGE_KEY);

    if (filterJson) {
      const filter = JSON.parse(filterJson);

      this.filter = filter;
    } else {
      // this.filter = this.getEmptyFilter();
    }
  }


  private saveFilter() {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(this.filter));
  }


  private loadFormDefaults() {
    const defaultsJson = localStorage.getItem(DEFAULTS_STORAGE_KEY);

    if (defaultsJson) {
      const defaults = JSON.parse(defaultsJson);

      this.formDefaults = defaults;

      this.senderScanValueType = this.formDefaults.senderScanValueType;
      this.senderScanActionType = this.formDefaults.senderScanActionType;
    }
  }


  saveFormDefaults() {
    this.formDefaults.senderScanValueType = this.senderScanValueType;
    this.formDefaults.senderScanActionType = this.senderScanActionType;

    localStorage.setItem(DEFAULTS_STORAGE_KEY, JSON.stringify(this.formDefaults));
  }


  viewOrEditShipmentOrderById(id: number) {
    this.router.navigate(["/shipments/", id], { queryParams: { fromSenderScan: true } });
  }


  startLabelsGeneration(shipment: Models.OrderForGridView) {
    const filename = `${shipment.parcels.length === 1 && shipment.parcels[0].parcelNr ? shipment.parcels[0].parcelNr + "_" : ""}${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`;

    this._shipmentService.printLabelsForOderIds(
      [shipment.id],
      filename,
      () => {
        this.isBusy = true;
        this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_labels");
      }
    ).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => { }, () => { });

  }
}
