import { EntityDataBaseInteractive, EntityDataBase, IdBasedConfigData, EntityValidityInterval } from "../index"

export interface BusinessUnitSetting extends EntityDataBase {
  businessUnitCode: string;
  settingKey: string;
  value: string;
}

export interface Product extends EntityValidityInterval {
  id: number;
  businessUnitCode: string;
  code: string;
  name: string;
  isProduct: boolean;
  ordinal: number;
  validFromUtc: Date;
  validToUtc: Date;
  isDeliveryToPudoProduct: boolean;
  isDeliveryToHomeAddressProduct: boolean;
  isDeliveryToBusinessAddressProduct: boolean;
  isCollectionRequestProduct: boolean;

  serviceCodesInProduct: string[];
}


export interface OrderEditorProductViewModel extends Product {
  /**
   * Značí, či je produkt vybraný v rozhaní.
   */
  isSelected: boolean;

  /**
   * Značí, či je produkt možné vybrat v rozhraní.
   */
  isSelectable: boolean;


  /**
   * Značí, či je produkt možné dostupny pre zvolene adrasy.
   * Toto by sa malo zisťovať vilaním funkcionality API, ktorá
   * pre aktuálne vybrané produkty a doplnkové služby, vráti
   * všetky dovolené produkty a doplnkové služby.
   */
  isAvailable: boolean;


  /**
   * Značí, či je produkt vôbec povolený pre vybratie aktuálnym CustomerDetail-om.
   * Je to nadradené isAvailable.
   */
  isAllowed: boolean;


  /**
   * Dodatočné údaje pre nastavenie produktu alebo doplnkovej služby
   * v rámci editácie objednávky.
   * Nastavuje si ich komponent pri tvorbe zoznamu produktov a doplnkových
   * služieb zobrzených následne v UI.
   */
  data: any;
}


/**
 * Zoznam kódov prodduktov a doplnkových služieb, ako ich evidujeme v DB.
 * Je to potrebné kvôli identifikácii potreby editácie dodatočných atribútov
 * produktu alebo doplnkovej služby.
 */
export class WellKnownProductCodes {
  public static DpdParcelShop = "PSD";
  public static DpdHome = "B2C";
  public static DpdClassic = "B2B";
  public static Ddp10 = "AM2";
  public static Dpd12 = "AM1";
  public static Dpd18Dpdg = "PM2";
  public static Cod = "COD";
  public static Swap = "SWAP";
  public static SaturdayDelivery = "Sat";
  public static IDCheck = "ID";
  public static Tyres = "Tyr";
  public static Sms = "SMS";
  public static Return = "RET";
  public static ReturnService = "2CRET";
  public static DpdPrecise = "PRECISE";
  public static CityService = "SD";
  public static Fresh = "FRESH";
  public static AgeCheck = "AGE";
}


export class WellKnownServiceCodes {
  public static Fresh = "534";
  public static Frozen = "535";
  public static Ambient = "537";
  public static AgeCheck = "015";
}

export const ProductsTemparatureRanges = new Map([
  [WellKnownServiceCodes.Frozen, [-30, -18]],
  [WellKnownServiceCodes.Fresh, [0, 4]],
  [WellKnownServiceCodes.Ambient, [4, 30]]
]);


/**
 * Zoznam menu položie a ich kódov.
 */
export class ShipperMainMenuItem {
  public static readonly Shipments = "shipments";
  public static readonly PickupOrders = "pickup_orders";
  public static readonly Recipients = "recipients";
  public static readonly Settings = "settings";
  public static readonly Notifications = "notifications";
  public static readonly ImportProfiles = "import_profiles";
  public static readonly Support = "support";
}


/**
 * Medzinárodná predvoľba tel. čísla krajiny.
 */
export interface InternationalCallingCode extends EntityDataBase {
  countryCode: string;
  callingCode: string;

  countryName?: string; // Použité pre populuvanie na strane klienta.
  textForSearch: string;
}


export interface CodeBasedConfigData extends CodeBasedConfigDataBase {
  resourceKey: string;
}


export interface CodeBasedConfigDataBase {
  code: string;
  name: string;
}


export interface PrintingType extends CodeBasedConfigData {

}


export interface PrinterType extends CodeBasedConfigData {

}


export interface PrinterLanguage extends CodeBasedConfigData {

}


export interface PaperSize extends CodeBasedConfigData {

}

export interface SenderScanValueType extends CodeBasedConfigData {

}

export interface SenderScanActionType extends CodeBasedConfigData {

}

export interface Bank extends EntityDataBase {
  bankName: string;
  bankCode: string;
  abbr: string;
  swiftCode: string;
  countryCode: string;
}

export interface ShipmentType extends CodeBasedConfigData {
  validFrom: Date;
  validTo: Date;
}

export interface ClearanceCleared extends CodeBasedConfigData {

}

export interface PrealertStatus extends CodeBasedConfigData {
  validFrom: Date;
  validTo: Date;
}

export interface IncoTerm extends CodeBasedConfigData {
  validFrom: Date;
  validTo: Date;
}

export interface AccompanyingDocsType extends CodeBasedConfigData {
  validFrom: Date;
  validTo: Date;
}

export interface Currency extends CodeBasedConfigData {
  numericalCode: string;
  validFrom: Date;
  validTo: Date;
}

export interface ReasonForExport extends CodeBasedConfigData { }

export interface SenderReceiverType extends CodeBasedConfigData { }

export interface SenderDocumentType extends CodeBasedConfigData { }
