import { Inject, Injectable } from "@angular/core";

import * as _ from "lodash";
import { BehaviorSubject, from, NEVER, Observable, of, Subject, throwError } from "rxjs";
import { filter, mergeMap, switchMap, take, tap } from "rxjs/operators";

import { WizardDataProvider, WizardEvent, WizardEventType, WizardMessagePopup, WizardState, WizardStep, WizardStepChange, WizardStepChangeType, WizardChapter, WIZARD_DATA_PROVIDER} from "../models";

const initialState: WizardState = {
  isActive: false,
  avaiableChapters: new Set()
};

const initialStepChange: WizardStepChange = {
  type: null,
  customType: null,
  oldStep: null,
  oldChapter: null,
  targetStep: null,
  beforeLeaveStep: null,
  canActivateStep: null,
  finalStep: null,
  finalChapter: null
};

@Injectable({
  providedIn: "root"
})
export class WizardService {
  private _events$ = new Subject<WizardEvent>();
  private _state$ = new BehaviorSubject<WizardState>(initialState);
  private beforeNextStep = new Map<string, (nextStepName?: string) => Promise<boolean | string>>();
  private canActiveStep = new Map<string, (previousStepName?: string) => Promise<boolean | string>>();
  private changeStep$ = new Subject<WizardStepChange>();
  private popupMessageAction$ = new Subject<boolean | string>();

  constructor(
      @Inject(WIZARD_DATA_PROVIDER) private wizardDataProvider: WizardDataProvider) {
    this.observeChangeStep();
  }

  get events$(): Observable<WizardEvent> {
    return this._events$.asObservable();
  }

  get state$(): Observable<Readonly<WizardState>> {
    return this._state$.asObservable();
  }

  get stateSnapshot(): Readonly<WizardState> {
    return this._state$.value;
  }

  get isActive(): boolean {
    return this.stateSnapshot.isActive;
  }

  get currentStep(): Readonly<WizardStep> {
    return this.stateSnapshot.currentStep;
  }

  get currentChapter(): Readonly<WizardChapter> {
    return this.stateSnapshot.currentChapter;
  }

  /** Starts Wizard on initial step. Initializes available chapters if necessary. */
  start() {
    const avaiableChapters = this.stateSnapshot.avaiableChapters;

    if (!avaiableChapters.size) {
      avaiableChapters.add(this.wizardDataProvider.getChapterForStep(this.wizardDataProvider.initialStep).name);
    }

    this.emitEvent(WizardEventType.Start);

    this.updateState({
      avaiableChapters,
      currentStep: this.wizardDataProvider.getStep(this.wizardDataProvider.initialStep),
      currentChapter: this.wizardDataProvider.getChapterForStep(this.wizardDataProvider.initialStep),
      isActive: true
    });
  }

  /** Closes Wizard. */
  close() {
    this.emitEvent(WizardEventType.Close);

    this.updateState({
      isActive: false,
      messagePopup: null
    });
  }

  /** Closes Wizard and sets initial step as the current step. */
  finish() {
    this.emitEvent(WizardEventType.Finish);

    this.updateState({
      currentStep: this.wizardDataProvider.getStep(this.wizardDataProvider.initialStep),
      currentChapter: this.wizardDataProvider.getChapterForStep(this.wizardDataProvider.initialStep),
      isActive: false,
      messagePopup: null
    });
  }

  /** Checks if Wizard is active and on provided step. */
  isOnStep(name: string | string[]): boolean {
    if (!this.isActive) {
      return false;
    }

    const currentStepName = this.currentStep?.name;
    return Array.isArray(name) ? name.some(n => n === currentStepName) : name === currentStepName;
  }

  /** Cheks if Wizard is active and not on provided step. */
  isNotOnStep(name: string | string[]): boolean {
    if (!this.isActive) {
      return false;
    }

    const currentStepName = this.currentStep?.name;
    return Array.isArray(name) ? name.every(n => n !== currentStepName) : name !== currentStepName;
  }

  /**
   * Reloads data for the current step using @see WizardDataProvider.getStep.
   * Use when the step data modify while the step is active.
   */
  reloadCurrentStep() {
    this.updateState({
      currentStep: this.wizardDataProvider.getStep(this.stateSnapshot.currentStep?.name)
    });
  }

  /** Checks if Wizard is on provided step and goes to next one. */
  completeStep(name: string | string[]) {
    if (typeof name === "string" && name === this.currentStep?.name ||
        Array.isArray(name) && name.find(n => n === this.currentStep?.name)) {

      const nextStepName = this.wizardDataProvider.getNextStepName(this.currentStep?.name);

      this.initStepChange({
        type: WizardStepChangeType.CompleteStep,
        targetStep: nextStepName
      });
    }
  }

  /**
   * Navigates to provided step.
   *
   * Use with caution if `WizardDataProvider` implements some restrictions.
   * Register CanActivateStep handler if necessary. @see registerCanActivateStep
   */
  goToStep(name: string, customEventType: string = null) {
    this.initStepChange({
      type: WizardStepChangeType.GoToStep,
      customType: customEventType,
      targetStep: name
    });
  }

  /** Navigates to next step in the sequenece. @see WizardDataProvider.getNextStep */
  goToNextStep() {
    const nextStepName = this.wizardDataProvider.getNextStepName(this.currentStep?.name);

    this.initStepChange({
      type: WizardStepChangeType.GoToNextStep,
      targetStep: nextStepName
    });
  }

  /** Navigates to previous step in the sequenece. @see WizardDataProvider.getPreviousStep */
  goToPreviousStep() {
    const previousName = this.wizardDataProvider.getPreviousStepName(this.currentStep?.name);

    this.initStepChange({
      type: WizardStepChangeType.GoToPreviousStep,
      targetStep: previousName
    });
  }

  /**
   * Navigates to the first step of the provided chapter if it's available.
   *
   * Use with caution if `WizardDataProvider` implements some restrictions.
   * Register CanActivateStep handler if necessary. @see registerCanActivateStep
   */
  goToChapter(name: string, customEventType: string = null) {
    this.initStepChange({
      type: WizardStepChangeType.GoToChapter,
      customType: customEventType,
      targetStep: this.wizardDataProvider.getChapter(name)?.firstStepName
    });
  }

  /** Navigates to next chapter in the sequenece. @see WizardDataProvider.getNextChapterName */
  goToNextChapter() {
    const nextChapter = this.wizardDataProvider.getNextChapter(this.currentChapter?.name);

    this.initStepChange({
      type: WizardStepChangeType.GoToNextChapter,
      targetStep: nextChapter?.firstStepName
    });
  }

  /** Navigates to previous chapter in the sequenece. @see WizardDataProvider.getPreviousChapterName */
  goToPreviousChapter() {
    const previousChapter = this.wizardDataProvider.getPreviousChapter(this.currentChapter?.name);

    this.initStepChange({
      type: WizardStepChangeType.GoToPreviousChapter,
      targetStep: previousChapter?.firstStepName
    });
  }

  /**
   * Navigates to the next step in the sequence after the last step of the current chapter.
   *
   * The difference from @see goToNextChapter is that the next step is
   * obtained by calling @see WizardDataProvider.getNextStepName for the
   * last step of the current chapter. Therefore it can potentionally navigate to
   * any step while @see goToNextChapter always navigates to the first step of
   * the next chapter.
   */
  completeCurrentChapter(customEventType: string = null) {
    this.initStepChange({
      type: WizardStepChangeType.CompleteCurrentChapter,
      customType: customEventType,
      targetStep: this.wizardDataProvider.getNextStepName(this.currentChapter?.lastStepName)
    });
  }

  /** Sets available chapters. Adds chapter of the current step if necessary. */
  setAvailableChapters(chapters: Iterable<string>) {
    const currentChapterName = this.wizardDataProvider.getChapterNameForStep(this.currentStep?.name);

    chapters = [currentChapterName, ...chapters].filter(s => Boolean(s));

    this.updateState({
      avaiableChapters: new Set(chapters)
    });
  }

  /** Add popup message. */
  addMessagePopup(messagePopup: WizardMessagePopup): Promise<boolean | string> {
    this.updateState({messagePopup});
    return this.popupMessageAction$.pipe(
      take(1),
      mergeMap(result => result ? of(result) : throwError(null))
    ).toPromise();
  }

  /** Removes popup message. */
  clearMessagePopup() {
    if (this.stateSnapshot.messagePopup) {
      this.updateState({messagePopup: null});
    }
  }

  /** Dismiss popup message. */
  dismissMessagePopup() {
    if (this.stateSnapshot.messagePopup) {
      this.clearMessagePopup();
      this.popupMessageAction$.next(false);
    }
  }

  /** Close popup message. */
  closeMessagePopup(result: boolean | string = true) {
    if (this.stateSnapshot.messagePopup) {
      this.clearMessagePopup();
      this.popupMessageAction$.next(result);
    }
  }

  /**
   * Registers BeforeNextStep handler. Handler fires when navigating to a step
   * that is evaluated as next in the sequence. Only one handler can be registered per step.
   *
   * Bahavior based on returned value:
   * - false: navigation is canceled
   * - true: continues to desired step
   * - string: navigates to provided step
   */
  registerBeforeNextStep(name: string | string[], fn: (nextStepName?: string) => Promise<boolean | string>) {
    if (Array.isArray(name)) {
      name.forEach(n => this.beforeNextStep.set(n, fn));
    } else {
      this.beforeNextStep.set(name, fn);
    }
  }

  /**
   * Registers CanActivateStep handler. Only one handler can be registered per step.
   *
   * Bahavior based on returned value:
   * - false: navigation is canceled
   * - true: continues to desired step
   * - string: navigates to provided step
   */
  registerCanActivateStep(name: string | string[], fn: (previousStepName?: string) => Promise<boolean | string>) {
    if (Array.isArray(name)) {
      name.forEach(n => this.canActiveStep.set(n, fn));
    } else {
      this.canActiveStep.set(name, fn);
    }
  }

  private emitEvent(event: WizardEventType | WizardEvent) {
    if (typeof event === "number") {
      event = {type: event};
    }

    this._events$.next(event);
  }

  private updateState(state: Partial<WizardState>) {
    this._state$.next({
      ...this.stateSnapshot,
      ...state
    });
  }

  private initStepChange(change: Partial<WizardStepChange>) {
    this.changeStep$.next({
      ...initialStepChange,
      oldStep: this.currentStep?.name,
      oldChapter: this.currentChapter?.name,
      ...change
    });
  }

  private observeChangeStep() {
    this.changeStep$.pipe(
      filter(change => this.isActive &&
          Boolean(change.targetStep) &&
          change.targetStep !== this.currentStep?.name
      ),
      switchMap(change => this.handleBeforeNextStep(change)),
      switchMap(change => this.handleCanActivateStep(change)),
      tap(change => {
        const finalStep =
            change.canActivateStep ?? change.beforeLeaveStep ?? change.targetStep;
        change.finalStep = finalStep;
        change.finalChapter = this.wizardDataProvider.getChapterNameForStep(finalStep);
      }),
      tap(() => this.clearMessagePopup())
    ).subscribe(change => this.changeStep(change));
  }

  private handleBeforeNextStep(change: WizardStepChange): Observable<WizardStepChange> {
    let nextStepInSequenceName = this.wizardDataProvider.getNextStepName(this.currentStep?.name);
    const previousStepInSequenceName = this.wizardDataProvider.getPreviousStepName(this.currentStep?.name);
    const beforeFn = this.beforeNextStep.get(this.currentStep?.name);

    if (!beforeFn || change.targetStep === previousStepInSequenceName) {
      return of(change);
    }

    const checkedStepNames = new Set();
    let isNextStepAfterCurrent = change.targetStep === nextStepInSequenceName;

    while (!isNextStepAfterCurrent && nextStepInSequenceName && !checkedStepNames.has(nextStepInSequenceName)) {
      checkedStepNames.add(nextStepInSequenceName);
      nextStepInSequenceName = this.wizardDataProvider.getNextStep(nextStepInSequenceName)?.name;
      isNextStepAfterCurrent = change.targetStep === nextStepInSequenceName;
    }

    if (!isNextStepAfterCurrent) {
      return of(change);
    }

    return from(beforeFn(change.targetStep)).pipe(
      mergeMap(result => typeof result === "string" ?
        of({...change, beforeLeaveStep: result}) :
        result ?
          of(change) :
          NEVER
      )
    );
  }

  private handleCanActivateStep(change: WizardStepChange): Observable<WizardStepChange> {
    const beforeActiveFn = this.canActiveStep.get(change.targetStep);

    if (!beforeActiveFn) {
      return of(change);
    }

    return from(beforeActiveFn(this.currentStep?.name)).pipe(
      mergeMap(result => typeof result === "string" ?
        of({...change, canActivateStep: result}) :
        result ?
          of(change) :
          NEVER
      )
    );
  }

  private changeStep(change: WizardStepChange) {
    const avaiableChapters = this.stateSnapshot.avaiableChapters;
    const chapter = this.wizardDataProvider.getChapterForStep(change.finalStep);

    avaiableChapters.add(chapter.name);

    this.emitEvent({
      type: WizardEventType.StepChange,
      payload: change
    });

    this.updateState({
      currentStep: this.wizardDataProvider.getStep(change.finalStep),
      currentChapter: this.wizardDataProvider.getChapterForStep(change.finalStep),
      avaiableChapters
    });
  }
}
