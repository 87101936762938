import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import { forkJoin, from, Subscription } from "rxjs";
import { finalize, map, mergeMap, take } from "rxjs/operators";

import * as _ from "lodash";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import { RoutedPageComponentBase } from "../../shared/routed-page-component-base";
import * as Shared from "../../shared/index";
import { PickupOrderForGridView } from "../models/pickup-order-for-grid-view.model";
import { PickupOrdersService } from "../services/pickup-orders.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-regular-pickup-orders-list",
  templateUrl: "./regular-pickup-orders-list.component.html"
})
export class RegularPickupOrdersListComponent extends RoutedPageComponentBase implements OnInit, OnDestroy {
  public regularPickupOrder: PickupOrderForGridView = null;
  public canEdit: boolean = false;

  private _loadingErrorOccured = false;
  private _listLoadingSubscription: Subscription = null;
  private _isLoadingList = false;
  private _isDeleting = false;
  private _customerPickupAddressId: number = null;

  @ViewChild("header")
  private _header;

  @ViewChild("content")
  private _content;

  @Input("customerDetailId")
  private _customerDetailId: number = null;

  @Input()
  public set customerPickupAddressId(value: number) {
    this._customerPickupAddressId = value;
    this.reloadList();
  }

  @Input("cityserviceAvailable")
  private _cityserviceAvailable: boolean = false;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _modal: Modal,
    private _contextService: Shared.ContextService,
    private _pickupOrdersServices: PickupOrdersService,
    private _fb: FormBuilder,
    private _toastr: ToastrService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  private timeFromTo(fromTime: Date, toTimeDate): string {
    if (fromTime == null && toTimeDate == null) {
      return "";
    }

    return (
      (fromTime ? moment(fromTime).local().format("HH:mm") : "")
      + " - "
      + (toTimeDate ? moment(toTimeDate).local().format("HH:mm") : "")
    )
  }

  private initializeAuth() {
    return forkJoin([
      this.authenticationService.impersonatingShipperAdminUserLogin$.pipe(take(1))
    ]).pipe(
      mergeMap(result => {
        // Ak sme prihlásený ako Admin v kontexte Používateľa, tak pôvodné policy ignorujeme
        return  result[0] == null ? this._pickupOrdersServices.canCurrentUserEditRegularPickupOrdersAsync(this._customerDetailId) : from([true])
      }),
      map((canEdit: boolean) => {
        this.canEdit = canEdit;

        return true;
      })
    )
  }

  public get isLoadingList() {
    return this._isLoadingList;
  }


  public get isDeleting() {
    return this._isDeleting;
  }


  public get loadingErrorOccured() {
    return this._loadingErrorOccured;
  }

  public get cityserviceAvailable() {
    return this._cityserviceAvailable;
  }

  public get pickupOrderTime() {
    if(this.regularPickupOrder && this.regularPickupOrder.details.length > 0) {
      return this.timeFromTo(this.regularPickupOrder.details[0].fromTime1, this.regularPickupOrder.details[0].toTime1);
    } else {
      return "";
    }
  }


  public editRegularPickupOrder(id: number) {
    this.router.navigate(["pickup-orders", id], { queryParams: { regular: 1 } });
  }


  public addRegularPickupOrder() {
    this.router.navigate(["pickup-orders", "0"], { queryParams: { regular: 1 } });
  }


  public deletePickupOrder(id: number) {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("regular_pickup_delete_confirmation_question"))
      .open()
      .result.then(
        value => {
          if (value) {
            this._isDeleting = true;
            this._pickupOrdersServices.deletePickupOrder(id).subscribe(() => {
              this._isDeleting = false;
              this._toastr.success(this.localizationService.getLocalizedString("regular_pickup_deleted"));
              this.reloadList();
            },
              ex => {
                this._isDeleting = false;
                this._toastr.error(this.localizationService.getLocalizedString("regular_pickup_delete_failed"));

                this.loggingService.logErrorData(ex, "Regular pickup delete failed");
              });
          };
      });
  }


  public reloadList = () => {
    if (this._listLoadingSubscription) {
      this._listLoadingSubscription.unsubscribe();
    }

    this._isLoadingList = true;
    this._loadingErrorOccured = false;

    this._listLoadingSubscription = this._pickupOrdersServices.getRegularPickupOrder(this._customerDetailId, this._customerPickupAddressId).pipe(
      finalize(() => this._isLoadingList = false)
    ).subscribe(
        regularPickupOrder => {
          this.regularPickupOrder = regularPickupOrder;
        }, ex => {
          this._loadingErrorOccured = true;
          this.loggingService.logErrorData(ex, "Loading regular pickup orders failed");

          var exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

          this._toastr.error(`${this.localizationService.getLocalizedString("regular_pickup_orders_loading_failed")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}}`);
        });
  }


  public ngOnDestroy() {
    if (this._listLoadingSubscription) {
      this._listLoadingSubscription.unsubscribe();
    }
  }


  public ngOnInit() {
    this.initializeAuth().subscribe(result => {
      this.reloadList();
    });
  }
}