<div class="printer-rules-list">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <h3>
    {{ (labelsPrintingRule ? 'labels' : 'protocols') | shpLocalize }}
    <span class="commands"><button id="button-add-printer-rule" type="button" [disabled]="isEditing" (click)="addPrinterRule()"><span class="ic-add-2"></span> {{ 'add' | shpLocalize }}</button></span>
  </h3>
  <div class="printer-rule header">
    <div class="view">
      <div class="conditions">
        <div class="commands"></div>
        <div class="customer ctl">{{ 'customer' | shpLocalize }}</div>
        <div class="address ctl">{{ 'address' | shpLocalize }}</div>
        <div class="product ctl">{{ 'product' | shpLocalize }}</div>
        <div class="user ctl">{{ 'user' | shpLocalize }}</div>
        <div class="path ctl" *ngIf="labelsPrintingRule">{{ 'automated_shipments_import_path' | shpLocalize }}</div>
        <div class="enabled ctl">{{ 'printer_rule_enabled' | shpLocalize }}</div>
      </div>
    </div>
  </div>
  <div *ngFor="let rule of printerRules; let i = index">
    <shp-printer-rule-editor [printingTypeCode]="printingTypeCode"
                             [printerRule]="rule"
                             [isFirst]="i === 0"
                             [isLast]="i === printerRules.length - 1"
                             [printers]="printers"
                             [printingTypes]="printingTypes"
                             [printerTypes]="printerTypes"
                             [printerLanguages]="printerLanguages"
                             [paperSizes]="paperSizes"
                             [importPaths]="importPaths"
                             [users]="users"
                             [products]="products"
                             [addresses]="addresses"
                             [customerDetails]="customerDetails"
                             [isEditEnabled]="!isEditing"
                             [componentId]="printingTypeCode + '-' + i"
                             (onEditStart)="onEditStart($event)"
                             (onEditEnd)="onEditEnd($event)"
                             (onDelete)="delete($event)"
                             (onIncreasePriority)="increasePriority($event)"
                             (onDecreasePriority)="decreasePriority($event)"></shp-printer-rule-editor>
  </div>
</div>
