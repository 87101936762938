import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ComponentRef, Input, Type } from "@angular/core";

import { WizardService } from "../../modules/wizard/services/wizard.service";
import { setComponentData } from "../../modules/wizard/utils";
import { WizardStepName } from "../../services/shipper-wizard-steps";

@Component({
  templateUrl: "./shipper-wizard-popup.component.html"
})
export class ShipperWizardPopupComponent  {
  specialComponents: Map<ComponentPortal<any>, {[key: string]: any}> = new Map();

  /** Headline for popup header. */
  @Input() headline = "";
  /** Icon class for popup header icon. */
  @Input() iconClass = "";
  /** Text displayed in popup. */
  @Input() text = "";

  @Input() completeChapterLabel = "";
  /** Display complete chapter button (default: false). */
  @Input() hasCompleteChapterButton = false;

  @Input() navigation: {[key: string]: any} = {};

  @Input() goToStepButtons: {stepName: string, customEventType?: string, label: string}[] = [];

  /**
   * List of elements that will be appended to popup after `text`.
   * @example ```
   *
   * MyComponent1 {}
   *
   * MyComponent2 {
   *   greeting: string;
   * }
   *
   * let myStep = {
   *  ...
   *  specialElements: [
   *    MyComponent1,
   *    [MyComponent2, {greeting: 'Hello'}]
   *  ]
   * }
   * ```
   */
  @Input()
  set specialElements(value: (Type<any> | [Type<any>, {[key: string]: any}])[]) {
    this.fillSpecialComponentPortals(value);
  }

  constructor(private wizardService: WizardService) { }

  goToStep(stepName: WizardStepName, customType: string = null) {
    this.wizardService.goToStep(stepName, customType);
  }

  completeChapter() {
    this.wizardService.completeCurrentChapter();
  }

  onPortalAttach(ref: ComponentRef<any>, data: {[key: string]: any}) {
    setComponentData(ref, data);
  }

  private fillSpecialComponentPortals(components: (Type<any> | [Type<any>, {[key: string]: any}])[] = []) {
    this.specialComponents = new Map(
      components.map(c => [new ComponentPortal(c[0] ?? c), c[1] ?? {}])
    );
  }
}
