import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";

import { ApiServiceBase, LoggingService, API_URL, AuthenticationService, LocalizationService } from "./../index"
import * as CustomerModels from "../models/customer.models"
import { UserRelatedItemModel } from "../models/user.model"
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { DpdApiCustomerData } from "../models/DpdApi/dpd-api-customer-data.model";
import { delay } from 'rxjs/operators';

/**
 * Service, ktorý okrem sprístupnenia API metód na získavanie údajov customera pre pre tenant (CustomerDetail, adresy)
 * spístupňuje aj aktuálne vybrané hodnoty.
 */
@Injectable()
export class CustomerService extends ApiServiceBase {
  constructor(
    loggingService: LoggingService,
    private _authenticationService: AuthenticationService,
    private _localizationService: LocalizationService,
    private _modal: Modal,
    private _http: HttpClient
  ) {
    super(loggingService);
  }


  public getCustomerDetailsForTenant = (tenantId: number) => {
    return this.processRequest<CustomerModels.CustomerDetail[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details"));
  }


  public getConfigurationsForTenant = (tenantId: number) => {
    return this.processRequest<CustomerModels.Configuration[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/configurations"));
  }


  public getCustomerDetailAddresses = (tenantId: number, customerDetailId: number) => {
    return this.processRequest<CustomerModels.Address[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/addresses"));
  }


  public getCustomerDetailBankAccounts = (tenantId: number, customerDetailId: number) => {
    return this.processRequest<CustomerModels.BankAccountForGridView[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts"));
  }


  public getCustomerDetailAllBankAccounts = (tenantId: number, customerDetailId: number) => {
    return this.processRequest<CustomerModels.BankAccountForGridView[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/all"));
  }


  public getCustomerDetailContactPersons = (tenantId: number, customerDetailId: number) => {
    return this.processRequest<CustomerModels.ContactPerson[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/contact-persons"));
  }

  public getAllowedCustomerDetailContactPersons = (tenantId: number, customerDetailId: number) => {
    return this.processRequest<CustomerModels.ContactPerson[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/allowed-contact-persons"));
  }


  public getCustomerDetailsAddressesForTenant = (tenantId: number) => {
    return this.processRequest<CustomerModels.Address[]>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/addresses"));
  }


  public getCustomerDetailAdressItemsForUser = (userId: number): Observable<UserRelatedItemModel[]> => {
    return this.processRequest<UserRelatedItemModel[]>(this._http.get(API_URL + "/users/" + userId + "/address-items"));
  }


  public setCustomerDetailAdressItemsForUser = (addressItems: UserRelatedItemModel[]): Observable<UserRelatedItemModel[]> => {
    return this.processRequest<UserRelatedItemModel[]>(this._http.post(API_URL + "/users/" + addressItems[0].userId + "/address-items", addressItems));
  }


  public GetCustomerBankAccountItemsForUserAsync = (userId: number): Observable<UserRelatedItemModel[]> => {
    return this.processRequest<UserRelatedItemModel[]>(this._http.get(API_URL + "/users/" + userId + "/bank-account-items"));
  }


  public setCustomerDetailBankAccountItemsForUser = (bankAccountItems: UserRelatedItemModel[]): Observable<UserRelatedItemModel[]> => {
    return this.processRequest<UserRelatedItemModel[]>(this._http.post(API_URL + "/users/" + bankAccountItems[0].userId + "/bank-account-items", bankAccountItems));
  }

  /* Bankové účty */

  public deleteCustomerBankAccount = (tenantId: number, customerDetailId: number, idBankAccount: number): Observable<any> => {
    return this.processRequest<any>(this._http.delete(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/" + idBankAccount));
  }

  public undeleteCustomerBankAccount = (tenantId: number, customerDetailId: number, idBankAccount: number): Observable<any> => {
    return this.processRequest<any>(this._http.put(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/undelete/" + idBankAccount, null));
  }

  public activateCustomerBankAccount = (tenantId: number, customerDetailId: number, idBankAccount: number): Observable<any> => {
    return this.processRequest<any>(this._http.put(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/activate/" + idBankAccount, null));
  }

  public deactivateCustomerBankAccount = (tenantId: number, customerDetailId: number, idBankAccount: number): Observable<any> => {
    return this.processRequest<any>(this._http.put(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/deactivate/" + idBankAccount, null));
  }

  public saveCustomerBankAccount = (tenantId: number, customerDetailId: number, bankAccount: CustomerModels.BankAccount) => {
    return this.processRequest<CustomerModels.BankAccount[]>(this._http.post(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts", bankAccount));
  }

  public getBankAccountAttachment = (tenantId: number, customerDetailId: number, idBankAccount: number, attachmentId: number) => {
    return this.processRequest<CustomerModels.BankAccountAttachment>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/" + idBankAccount + "/attachments/" + attachmentId));
  }

  public getBankAccountAcceptation = (customerDetailId: number, bankAccountId: number) => {
    return this.processRequest(this._http.get(API_URL + "/bank-account-acceptance-protocol/customer-details/" + customerDetailId + "/bank-account/" + bankAccountId, { responseType: 'blob' }));
  }

  public uploadBankAccountAttachment = (tenantId: number, customerDetailId: number, idBankAccount: number, model: CustomerModels.BankAccountAttachment) => {
    return this.processRequest<any>(this._http.post(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/" + idBankAccount + "/attachments", model));
  }

  public deleteBankAccountAttachment = (tenantId: number, customerDetailId: number, idBankAccount: number, attachmentId: number): Observable<any> => {
    return this.processRequest<any>(this._http.delete(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/bank-accounts/" + idBankAccount + "/attachments/" + attachmentId));
  }

  public canAddBankAccount = (tenantId: number, customerDetailId: number) => {
    return this.processRequest<boolean>(this._http.get(API_URL + "/tenants/" + tenantId + "/customer-details/" + customerDetailId + "/can-add-bank-account"));
  }

  public canAddPickupAddress = (customerDetailId: number) => {
    return this.processRequest<boolean>(this._http.get(API_URL + "/customers/" + customerDetailId + "/can-add-pickup-address"));
  }

  public getCustomerAddressByCustomerNumberFromDpdApi(customerNumber: string): Observable<DpdApiCustomerData> {
    return this.processRequest<DpdApiCustomerData>(this._http.get(`${API_URL}/customers/dpd-api-address-by-customer-number/${customerNumber}`));
  }

  private _stopCreditModalRef = null;

  public openStopCreditModal() {
    if (this._stopCreditModalRef !== null) {
      this._stopCreditModalRef.dismiss();
      this._stopCreditModalRef = null;
    }

    this._stopCreditModalRef = this._modal.alert().body(this._localizationService.getLocalizedString("stop_credit_customer_alert", "<a href=\"http://www.mojkurier.sk\" target=\"_blank\">www.mojkurier.sk</a>")).open();
  }

  public getIsCustomerLevelTenant(tenantId: number) {
    return this.processRequest<boolean>(this._http.get(API_URL + "/tenants/" + tenantId + "/is-customer-level"));
  }

}
