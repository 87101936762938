export enum EventSeverity {
  Error,
  Warning,
  Info,
  Verbose
}


export enum EventCategory {
  BusinessLogic,
  Infrastructure
}


export interface LogEntry {
  timestamp: Date;
  category: EventCategory;
  componentId: string; // URI
  eventCode: string;
  eventMessage: string;
  eventParameters: string; // JSON serialized parameters.
  eventData: Object;
  severity: EventSeverity;
  threadId: number;
  exeName: string;
  machineName: string;
}


export interface PagedQueryParameters {
  pageIndex: number;
  pageSize: number;
}


export interface LogEntryQueryParameters extends PagedQueryParameters {
  componentId: string; // URI
  timestampFrom: Date;
  timestampTo: Date;
  eventCode: string;
  categories: EventCategory[];
  severities: EventSeverity[];
}


export interface PagedResult<T> {
  items: T[];
  pageIndex: number;
  pageSize: number;
  totalSize: number;
}