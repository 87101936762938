/**
 * 
 * @param object Ľubovoľný objekt, ktorý chceme dostať do query string.
 */
export function encodeAsQueryString(object: any): string {
  if (!object) {
    return "";
  }

  return Object.keys(object)
    .map(key => {
      if (object[key] === null || typeof object[key] === 'undefined') {
        return null;
      }

      if (object[key].constructor === Array) {
        var arrayParam: string[] = [];

        object[key].forEach(e => {
          arrayParam.push(encodeURIComponent(key) + "=" + encodeURIComponent(e));
        });

        return arrayParam.join("&");
      }

      if (object[key].constructor === Date) {
        return encodeURIComponent(key) + "=" + encodeURIComponent((object[key] as Date).toISOString());
      }

      return encodeURIComponent(key) + "=" + encodeURIComponent(object[key]);
    })
    .filter(item => item != null)
    .join("&");
}