import { Component } from "@angular/core";
import { trigger, state, style, transition, animate } from "@angular/animations";

import { Toast, ToastPackage, ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";
import * as Shared from "../../shared/index";
import { SELECTOR_PREFIX } from "../prefix";

@Component({
  selector: SELECTOR_PREFIX + "-extended-error-toastr",
    templateUrl: "./extended-error-toastr.component.html",
    animations: [
        trigger('flyInOut', [
          state('inactive', style({ opacity: 0 })),
          state('active', style({ opacity: 1 })),
          state('removed', style({ opacity: 0 })),
          transition(
            'inactive => active',
            animate('{{ easeTime }}ms {{ easing }}')
          ),
          transition(
            'active => removed',
            animate('{{ easeTime }}ms {{ easing }}')
          )
        ])
      ]
})
export class ExtendedErrorToastrComponent extends Toast {
    // private expandBtnCopy: string = this.localizationService.getLocalizedString('button_label_more_details');
    // private collapsedBtnCopy: string = this.localizationService.getLocalizedString('button_label_less_details');
    // public btnCopy: string = this.expandBtnCopy;
    public btnCopy: string = 'More details';
    public downloadCopy: string = 'Please, download the error log file and contact technical support';

    public expanded: boolean = false;

    public payload: string = null;

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
        // private localizationService: LocalizationService
    ) {
        super(toastrService, toastPackage);
    }

    /**
     * Toggle expanded/collapsed state on toastr body
     */
    public expand(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Export error log to .txt file
     */
    public download() {
        if (this.payload.length) {
            const blob = new Blob([this.payload], {type: "text/plain;charset=utf-8"});
            saveAs(blob, "error-log.txt");
        }
    }
}
