<form #f (submit)="save(); saveExportProfile();" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy || isBusyExportProfile"></shp-progress-overlay>
  <div class="settings-category">
    <div class="settings-wrapper">
      <h2>{{ 'settings_eod_export' | shpLocalize }}</h2>

      <div class="ctl" *ngIf="settings.is_eod_parcel_export_for_central_shipper_enabled.isVisible">
        <label for="is-eod-parcel-export-for-central-shipper-enabled">{{ 'is_eod_parcel_export_enabled' | shpLocalize }}</label>
        <input type="checkbox"
               name="isEodParcelExportForCentralShipperEnabled" id="is-eod-parcel-export-for-central-shipper-enabled" [(ngModel)]="settings.is_eod_parcel_export_for_central_shipper_enabled.value" />
        <label for="is-eod-parcel-export-for-central-shipper-enabled"></label>
      </div>

      <h2 class="my-3">{{ 'settings_export_file' | shpLocalize }}</h2>

      <shp-parcel-export-settings-fields [model]="model" #editor="exportSettings"></shp-parcel-export-settings-fields>
    </div>
    <div class="commands-toolbar">
      <button id="button-save" type="submit" [disabled]="!editor?.isValid"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
    </div>
  </div>
</form>
