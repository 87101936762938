import { Routes } from "@angular/router"

import { AuthorizationGuard } from "../shared/authorization-guard"
import { UnsavedChangesGuard } from "../shared/unsaved-changes-guard"
import { PickupOrdersComponent } from "./components/pickup-orders.component";
import { PickupOrdersListComponent } from "./components/pickup-orders-list.component";
import { PickupOrderEditorComponent } from "./components/pickup-order-editor.component";

export const PICKUP_ORDERS_ROUTES: Routes = [
  {
    path: "pickup-orders",
    component: PickupOrdersComponent,
    canActivate: [AuthorizationGuard],
    children: [
      { path: "", component: PickupOrdersListComponent, canActivate: [AuthorizationGuard] },
      { path: ":pickupOrderId", component: PickupOrderEditorComponent, canActivate: [AuthorizationGuard], canDeactivate: [UnsavedChangesGuard] }
    ]
  }
];