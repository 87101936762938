<shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
<div class="settings-category bank-account-editor">
  <h2>{{ 'new_bank_account' | shpLocalize }}</h2>
  <form [formGroup]="form" class="fields" *ngIf="form">

    <div class="ctl">
      <label for="name">{{ 'bank_account_name' | shpLocalize }}</label>
      <input type="text" id="name" formControlName="name" />
    </div>

    <div class="ctl required">
      <label for="iban">{{ 'iban' | shpLocalize }}</label>
      <input type="text" id="iban" formControlName="iban" autocomplete="off" ibanInputFormatter />
    </div>

    <div class="ctl">
      <label for="swiftCode">{{ 'bank' | shpLocalize }}</label>
      <select formControlName="swiftCode" (change)="selectBank($event)">
        <option [value]="null">-</option>
        <option *ngFor="let bank of banks" [value]="bank.swiftCode">{{ bank.bankName }}</option>
      </select>
    </div>

    <div class="ctl" *ngIf="isUnknownBankSelected | async">
      <label for="bankName"></label>
      <input type="text" id="bankName" formControlName="bankName" placeholder="{{ 'bank_account_editor_insert_bank_name' | shpLocalize }}" />
    </div>

    <div class="ctl required">
      <label for="bic">{{ 'bic' | shpLocalize }}</label>
      <input type="text" id="bic" formControlName="bic" [readOnly]="!isBicReadOnly" />
    </div>

    <div class="ctl buttons">
      <label>&nbsp;</label>
      <button id="button-save" [disabled]="!form.valid" (click)="saveBankAccount()"><span class="ic-floppydisk"></span> {{ 'save' | shpLocalize }}</button>
      <button id="button-cancel" (click)="goBackToListOfBankAccounts()" type="button">{{ 'cancel' | shpLocalize }}</button>
    </div>
  </form>
</div>
