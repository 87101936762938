import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";

import { BehaviorSubject, forkJoin } from "rxjs";
import { take, map, finalize } from "rxjs/operators";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { ToastrService } from "ngx-toastr";
import { Address, AddressForGridView, CustomerDetail, CustomerAddressTypeCode } from "../../../shared/models/customer.models";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-pickup-addresses-settings",
  templateUrl: "./pickup-addresses-settings.component.html"
})
export class PickupAddressesSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {

  public pickupAddresses: AddressForGridView[] = null;
  public customerDetail: BehaviorSubject<CustomerDetail> = new BehaviorSubject(null);
  public currentAddress: BehaviorSubject<Address> = new BehaviorSubject(null);
  public countries: Shared.Country[] = [];
  public isEditing = false;
  public canAddPickupAddress = false;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    private _customerService: Shared.CustomerService,
    private _modal: Modal,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    toastr: ToastrService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }

  private loadCustomerDetailAndLookups() {
    this.isBusy = true;
    return forkJoin([
      this._skdataConfigService.getCountries(),
      this.contextService.currentCustomerDetail.pipe(take(1)),
      this.contextService.currentAddress.pipe(take(1))
    ]).pipe(
      map(result => {
        this.countries = result[0];
        this.customerDetail.next(result[1]);
        this.currentAddress.next(result[2]);

        this._customerService.canAddPickupAddress(this.customerDetail.value.id).subscribe(result => {
          this.canAddPickupAddress = result;
        });

        return true;
      })
    )
  }

  private loadPickupAddresses() {
    this.settingsService.getCustomerPickupAddresses(this.customerDetail.value.id).subscribe(addresses => {
      this.pickupAddresses = addresses;
      this.isBusy = false;
    }, ex => {
      let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex);
      this.toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo));
      this.isBusy = false;
    });
  }

  protected afterSettingsLoaded() {
    this.loadCustomerDetailAndLookups().subscribe(loaded => {
      this.loadPickupAddresses();
    }, ex => {
      let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex);
      this.toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo));
      this.isBusy = false;
    });
  }

  private deleteCustomerPickupAddressCore(pickupAddress: AddressForGridView) {
    this.isBusy = true;
    this.settingsService.deleteCustomerPickupAddress(this.customerDetail.value.id, pickupAddress.id).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => {
        this.toastr.success(this.localizationService.getLocalizedString("pickup_address_successfully_deleted"));
        this.loadPickupAddresses();
      }, ex => {
        let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(exceptionInfo, "Error deleting pickup address.");

        this.toastr.error(this.localizationService.getLocalizedString("error_deleting_pickup_address") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
        this.isBusy = false;
      });
  }

  private undeleteCustomerPickupAddressCore(pickupAddress: AddressForGridView) {
    this.isBusy = true;
    this.settingsService.undeleteCustomerPickupAddress(this.customerDetail.value.id, pickupAddress.id).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => {
        this.toastr.success(this.localizationService.getLocalizedString("pickup_address_successfully_undeleted"));
        this.loadPickupAddresses();
      }, ex => {
        let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(exceptionInfo, "Error undeleting pickup address.");

        this.toastr.error(this.localizationService.getLocalizedString("error_undeleting_pickup_address") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
        this.isBusy = false;
      });
  }

  public addPickupAddress() {

    var newAddress: AddressForGridView = {
      id: 0,
      isNew: true,
      isPersistent: false,
      customerDetailId: this.customerDetail.value.id,
      depotCode: "",
      addressNr: "",
      addressTypeCode: CustomerAddressTypeCode.Pickup,
      addressTypeCodeAdditional: "",
      addressForm: "",
      addressFormDescription: "",
      addressFormName: "",
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      street: "",
      street2: "",
      houseNr: "",
      zip: "",
      pOBoxZip: "",
      pOBoxText: "",
      place: "",
      countryCode: this.settings.country_code.value,
      phone: "",
      fax: "",
      web: "",
      email: "",
      customerAddressType: "",
      customerAddressTypeDescription: "",
      turnoverTaxId: "",
      latitude: 0,
      longitude: 0,
      height: 0,
      customId: "",

      maskingName: "",
      maskingName2: "",
      maskingStreet: "",
      maskingStreet2: "",
      maskingHouseNr: "",
      maskingZip: "",
      maskingCity: "",
      maskingCountryCode: null,
      createdByUser: null,
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      modifiedByUser: null,

      canDelete: true,
      canApprove: false,
      canReject: false,
      canUndelete: false,
      stateId: "InPreparation"
    }

    this.pickupAddresses.splice(0, 0, newAddress as AddressForGridView);
  }


  public delete(pickupAddress: AddressForGridView) {
    this._modal
      .confirm()
      .message(this.localizationService.getLocalizedString("confirm_pickup_address_deleting"))
      .okBtn(this.localizationService.getLocalizedString("yes"))
      .cancelBtn(this.localizationService.getLocalizedString("no"))
      .open()
      .result.then(confirmed => {
        if (confirmed) {
          this.deleteCustomerPickupAddressCore(pickupAddress);
        }
      }, ex => { });
  }


  public undelete(pickupAddress: AddressForGridView) {
    this._modal
      .confirm()
      .message(this.localizationService.getLocalizedString("confirm_pickup_address_undeleting"))
      .okBtn(this.localizationService.getLocalizedString("yes"))
      .cancelBtn(this.localizationService.getLocalizedString("no"))
      .open()
      .result.then(confirmed => {
        if (confirmed) {
          this.undeleteCustomerPickupAddressCore(pickupAddress);
        }
      }, ex => { });
  }


  public onEditStart(pickupAddress: AddressForGridView) {
    this.isEditing = true;
  }


  public onEditEnd(pickupAddress: AddressForGridView) {
    this.isEditing = false;

    // Ak sme neuložili, tak odstránime.
    if (pickupAddress.id === 0) {
      let index = this.pickupAddresses.indexOf(pickupAddress);
      if (index > -1) {
        this.pickupAddresses.splice(index, 1);
      }
    }
  }



  public ngOnInit(): void { super.ngOnInit(); }

  public ngOnDestroy(): void { super.ngOnDestroy(); }
}