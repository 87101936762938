import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { debounceTime, finalize, tap } from "rxjs/operators";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";

import { CompleterService, CompleterItem, RemoteData } from "ng2-completer";
import * as Shared from "../../../shared/index";
import { SettingsService } from "../../services/settings.service";
import { Address, CustomerDetail, AddressForGridView } from "../../../shared/models/customer.models";
import { validateEmail } from "../../../shared/validators";
import { RecipientsService } from "../../../recipients/services/recipients.service";
import { ZipCityPair } from "../../../shared/models/zip-city-pair.model";
import { CityAndZipCheckResult } from "../../../shipments/models/shipment.model";
import { SkdataConfigService } from "../../../shared/index";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-pickup-address-editor",
  templateUrl: "./pickup-address-editor.component.html"
})
export class PickupAddressEditorComponent implements OnInit {
  @ViewChild("firstControl")
  private _firstControl: ElementRef;

  private _customerDetail: CustomerDetail = null;
  private _currentAddress: Address = null;
  private _isOdd = false;
  private _pickupAddress: AddressForGridView = null;
  private _form: FormGroup = null;

  private _isEditEnabled = true;
  private _isEdited = false;
  private _isBusy = false;


  private startEditing() {
    this.initializeForm();

    this._isEdited = true;
    this.onEditStart.emit(this._pickupAddress);
  }


  private selectFirstControl() {
    window.setTimeout(() => {
      if (this._firstControl) {
        this._firstControl.nativeElement.focus();
      }
    }, 10);
  }


  @Input() public set pickupAddress(value: AddressForGridView) {
    if (this._pickupAddress != value) {
      this._pickupAddress = value;

      // Keď prvotne zobrazíme nový záznam, skúsime dať focus do prvého editačného elementu.
      if (value && value.id === 0) {
        window.setTimeout(() => {
          this.startEditing();
          this.selectFirstControl();
        }, 10);
      }
    }
  }
  private _countries: Shared.Country[] = [];

  @Input() public set countries(value: Shared.Country[]) { this._countries = value; }
  @Input() public set customerDetail(value: CustomerDetail) { this._customerDetail = value; }
  @Input() public set currentAddress(value: Address) { this._currentAddress = value; }

  @Input() public set isOdd(value: boolean) { this._isOdd = value; }

  @Input() public set isEditEnabled(value: boolean) { this._isEditEnabled = value; }

  private get _isCurrentAddress(): boolean {
    return (this._currentAddress && this._currentAddress.id === this._pickupAddress.id);
  }

  public get pickupAddress() { return this._pickupAddress; }
  public get countries() { return this._countries; }
  public get customerDetail() { return this._customerDetail; }
  public get currentAddress() { return this._currentAddress; }

  public get isOdd() { return this._isOdd; }

  public get isEdited() { return this._isEdited; }
  public get isEditEnabled() { return this._isEditEnabled; }
  public get isBusy() { return this._isBusy; }
  public get form() { return this._form; }

  public zipCityPairsDataService: RemoteData = null;

  public isValidatingCityAndZip = false;

  public isCityInvalid = false;
  public isZipInvalid = false;
  public isZipNotInCity = false;

  public filteredZips: ZipCityPair[];


  @Output() public onDelete: EventEmitter<Address> = new EventEmitter();
  @Output() public onUndelete: EventEmitter<Address> = new EventEmitter();
  @Output() public onSendToApprove: EventEmitter<Address> = new EventEmitter();
  @Output() public onEditStart: EventEmitter<Address> = new EventEmitter();
  @Output() public onEditEnd: EventEmitter<Address> = new EventEmitter();


  public ngOnInit() {
    // this.zipCityPairsDataService = this._completerService.remote(null, null, "zip").descriptionField("cityName");
    // this.zipCityPairsDataService.urlFormater((term: string) => {
    //   let countryCode = this._pickupAddress.countryCode; // Predpokladáme, že to je vyplnené vždy.

    //   return `${Shared.API_URL}/georouting/countries/${countryCode}/zips/${term}/city-names`;
    // });
  }


  public zipCityPairSelected(selected: CompleterItem) {
    if (selected) {
      var zipCityPairModel = selected.originalObject as ZipCityPair;

      this._form.controls.place.setValue(zipCityPairModel.cityName);
    }
  }


  public onZipSelected(selected: ZipCityPair) {

    if (typeof selected == 'object') {
      this._form.controls.place.setValue(selected.zip);
      this._form.controls.place.setValue(selected.cityName);
    }
  }

  public filterZip(event): void {
    let query = event.query;
    let countryCode = this._pickupAddress.countryCode;

    this._skDataConfigService.getCitiesByZip( countryCode, query ).pipe(
      tap((response: ZipCityPair[]) => this.filteredZips = response )
    ).subscribe();
  }

  private initializeForm() {

    var that = this;

    this._form = this._fb.group({
      name1: [this._pickupAddress.name1, Validators.required],
      name2: [this._pickupAddress.name2],
      street: [this._pickupAddress.street, Validators.required],
      street2: [this._pickupAddress.street2],
      houseNr: [this._pickupAddress.houseNr, Validators.required],
      zip: [this._pickupAddress.zip, Validators.required],
      place: [this._pickupAddress.place, Validators.required],
      countryCode: [this._pickupAddress.countryCode, Validators.required],
      phone: [this._pickupAddress.phone],
      email: [this._pickupAddress.email, validateEmail],
      web: [this._pickupAddress.web],
      maskingName: [this._pickupAddress.maskingName],
      maskingName2: [this._pickupAddress.maskingName2],
      maskingStreet: [this._pickupAddress.maskingStreet],
      maskingHouseNr: [this._pickupAddress.maskingHouseNr],
      maskingStreet2: [this._pickupAddress.maskingStreet2],
      maskingZip: [this._pickupAddress.maskingZip],
      maskingCity: [this._pickupAddress.maskingCity],
      maskingCountryCode: [this._pickupAddress.maskingCountryCode]
    });

    this._form.controls.countryCode.disable();


    this._form.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(
      () => {
        this.validateZipAndCity();
    });
  }

  private savePickupAddressCore() {
    this._isBusy = true;

    let pickupAddressEdited = _.cloneDeep(this._pickupAddress);
    Object.assign(pickupAddressEdited, this._form.value);

    this._settingsService.saveCustomerPickupAddress(this.customerDetail.id, pickupAddressEdited).pipe(
      finalize(() => this._isBusy = false)
    ).subscribe(savedPickupAddress => {
        Object.assign(this._pickupAddress, savedPickupAddress);
        this._isEdited = false;
        this.onEditEnd.emit(this._pickupAddress);
      }, ex => {
        let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._log.logErrorData(ex);
        this._toastr.error(this._localizationService.getLocalizedString("error_saving_pickup_address") + ": " + this._localizationService.getLocalizedExceptionString(exceptionInfo));
      });
  }

  public edit() {
    if (this._isCurrentAddress) {
      this._modal
        .alert()
        .body(this._localizationService.getLocalizedString("cant_edit_actual_pickup_address"))
        .open();

      return;
    } else {
      this.startEditing();
    }
  }

  public cancelEdit() {
    this._isEdited = false;
    this.onEditEnd.emit(this._pickupAddress);
  }


  public delete() {
    if (this._isCurrentAddress) {
      this._modal
        .alert()
        .body(this._localizationService.getLocalizedString("cant_delete_actual_pickup_address"))
        .open();

      return;
    } else {
      this.onDelete.emit(this.pickupAddress);
    }
  }

  public undelete() {
    this.onUndelete.emit(this.pickupAddress);
  }

  public sendToApprove() {
    this.onSendToApprove.emit(this.pickupAddress);
  }

  public save() {

    if (this._pickupAddress && this._pickupAddress.id === 0) {
      this.savePickupAddressCore();
    } else {
      this._modal
        .confirm()
        .message(this._localizationService.getLocalizedString("confirm_edit_existing_pickup_address"))
        .okBtn(this._localizationService.getLocalizedString("yes"))
        .cancelBtn(this._localizationService.getLocalizedString("no"))
        .open()
        .result.then(confirmed => {
          if (confirmed) {
            this.savePickupAddressCore();
          }
        }, ex => { });
    }
  }


  private validateZipAndCity() {
    this.isValidatingCityAndZip = true;
    this.isCityInvalid = false;
    this.isZipInvalid = false;
    this.isZipNotInCity = false;

    this._recipientsService.checkZipAndCity({
      cityName: this._form.controls.place.value,
      countryCode: this._form.controls.countryCode.value,
      postCode: this._form.controls.zip.value,
      refDate: null
    })
      .subscribe((result: CityAndZipCheckResult) => {
        this.isCityInvalid = !result.isCityValid && result.cityValidationRequirementTypeId == "VB";
        this.isZipNotInCity = !result.isZipInCity && result.cityValidationRequirementTypeId == "VB";
        this.isZipInvalid = !result.isZipValid && result.zipValidationRequirementTypeId == "VB";

        this.isValidatingCityAndZip = false;
      },
        (ex) => {
          var exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

          switch (exceptionInfo.key) {
            case Shared.ExceptionKeys.InvalidZip:
              this.isZipInvalid= true;
              break;

            case Shared.ExceptionKeys.InvalidZipForCity:
              this.isZipNotInCity = true;
              break;

            case Shared.ExceptionKeys.InvalidCity:
              this.isCityInvalid = true;
              break;

            default:
              break;
          }
        });
  }


  constructor(
    private _log: Shared.LoggingService,
    private _settingsService: SettingsService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _localizationService: Shared.LocalizationService,
    private _modal: Modal,
    private _fb: FormBuilder,
    private _toastr: ToastrService,
    private _completerService: CompleterService,
    private _recipientsService: RecipientsService,
    private _skDataConfigService: SkdataConfigService,
  ) {
  }
}
