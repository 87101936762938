import { Component } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";

import { DialogRef, ModalComponent, CloseGuard } from "ngx-modialog-7";
import { ToastrService } from "ngx-toastr";

import * as Shared from "../../shared/index";
import * as SettingsModel from "../models/settings.model";
import * as CustomerModels from "../../shared/models/customer.models";

import * as _ from "lodash";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-bank-accounts-upload-dialog",
  templateUrl: "./bank-accounts-upload-dialog.component.html"
})
export class BankAccountsUploadDialogComponent implements ModalComponent<SettingsModel.BankAccountsUploadDialogModalContext> {
  public isBusy = false;
  public statusMessage = "";


  /**
  * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
  */
  public beforeDismiss(): boolean | Promise<boolean> { return true; }


  /**
   * Vracia, či nie je možné dialóg zatvoriť cez .close().
   */
  public beforeClose(): boolean | Promise<boolean> { return false; }


  public refresh = false;
  public uploadFailedInvalidFile = false;
  public stringErrorMessage = "";


  public exception: Shared.ExceptionInfo = null;
  public uploadException: Shared.ExceptionInfo = null;


  /**
   * Názov aktuálne vybraného súboru z disku.
   */
  public selectedFileName = "";


  private _form: FormGroup = null;


  public get form() { return this._form; }


  /**
   * JS objekt reprezentujúci údaje o vybranom súbore.
   */
  private _selectedFile: File = null;


  /**
   * Id bankového účtu ku ktorému uploadujeme súbor
   */
  private _bankAccountId: number = null;
  private _customerDetailId: number = null;
  private _tenantId: number = null;


  /**
   * Handler, ktorý sa má bindovať na event zmeny vybraného súboru cez input type=file.
   */
  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }


  private setSelectedFile = (inputValue: any) => {
    if (inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this._form.controls.selectedFileName.setValue(this._selectedFile.name);
      this._form.controls.name.setValue(this._selectedFile.name.substr(0, this._selectedFile.name.lastIndexOf('.')));
    } else {
      this._form.controls.selectedFileName.setValue("");
      this._form.controls.name.setValue("");
      this._form.controls.description.setValue("");
      this._selectedFile = null;
    }
  }


  public uploadFile() {
    if (this._selectedFile == null) {
      return;
    }

    this.isBusy = true;
    this.exception = null;

    // Cez FileReader (IE 10+, ostatné prehliadače OK) si načítame súbor z disku.
    var fileReader = new FileReader();

    fileReader.onloadend = () => {
      this.upload(fileReader.result[1]);
    }

    fileReader.onerror = () => {
      this.isBusy = false;
    }

    fileReader.onabort = () => {
      this.isBusy = false;
    }

    fileReader.readAsDataURL(this._selectedFile);
  }


  public upload = (fileContent: Blob) => {
    const formValue = this._form.value;

    const model: CustomerModels.BankAccountAttachment = {
      bankAccountId: this._bankAccountId,
      name: formValue.name,
      description: formValue.description,
      fileName: formValue.selectedFileName,
      attachment: fileContent
    };

    this._customerService.uploadBankAccountAttachment(this._tenantId, this._customerDetailId, this._bankAccountId, model)
      .subscribe(() => {
        this.isBusy = false;
        this.uploadException = null;
        this.exception = null;

        this.refresh = true;
        this.close();

        this._toastr.success(this._localizationService.getLocalizedString("message_attachment_uploaded_successfully"));

        this._form.controls.selectedFileName.setValue("");
        this._form.controls.name.setValue("");
        this._form.controls.description.setValue("");

        this._selectedFile = null;
      },
      (ex: any) => {
        // Niečo zlé sa stalo, zalogujeme a informujeme o tom.
        this._loggingService.logErrorData(ex, "Error uploading configuration.");

        var exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        if (exceptionInfo.key === Shared.ExceptionKeys.GeneralException) {
          // Trocha to konkretizujeme.
          exceptionInfo.key = Shared.ExceptionKeys.ErrorUploadingConfiguration;
        }

        this.uploadFailedInvalidFile = true;

        this.uploadException = exceptionInfo;

        this.isBusy = false;
      });

    return false;
  }


  public close = () => {
    this.dialog.close(this.refresh);
  }


  private initializeForm() {
    this._form = this._fb.group({
      selectedFileName: ["", Validators.required],
      selectedFile: [null, Validators.required],
      name: [""],
      description: [""]
    });
  }


  constructor(
    public dialog: DialogRef<SettingsModel.BankAccountsUploadDialogModalContext>,
    private _loggingService: Shared.LoggingService,
    private _localizationService: Shared.LocalizationService,
    private _customerService: Shared.CustomerService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _toastr: ToastrService,
    private _fb: FormBuilder
  ) {
    if (this.dialog.context.bankAccountId) {
      this._bankAccountId = this.dialog.context.bankAccountId;
      this._tenantId = this.dialog.context.tenantId;
      this._customerDetailId = this.dialog.context.customerDetailId;
    }

    this.initializeForm();
  }
}