import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { WizardArrowComponent } from "./components/wizard-arrow.component";
import { WizardChaptersComponent } from "./components/wizard-chapters.component";
import { WizardLabelComponent } from "./components/wizard-label.component";
import { WizardMessagePopupComponent } from "./components/wizard-message-popup.component";
import { WizardOverlayComponent } from "./components/wizard-overlay.component";
import { WizardPopupComponent } from "./components/wizard-popup.component";
import { WizardCompleteStepDirective } from "./directives/wizard-complete-step.directive";
import { WizardScrollableDirective } from "./directives/wizard-scrollable.directive";
import { WizardTagDirective } from "./directives/wizard-tag.directive";

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    RouterModule,
    ScrollingModule,
  ],
  declarations: [
    WizardArrowComponent,
    WizardChaptersComponent,
    WizardCompleteStepDirective,
    WizardLabelComponent,
    WizardMessagePopupComponent,
    WizardOverlayComponent,
    WizardPopupComponent,
    WizardScrollableDirective,
    WizardTagDirective
  ],
  exports: [
    WizardChaptersComponent,
    WizardCompleteStepDirective,
    WizardScrollableDirective,
    WizardTagDirective
  ]
})
export class WizardModule {

}
