import { Injectable } from "@angular/core"

import * as _ from "lodash";
import { fromEvent, Observable, ReplaySubject } from "rxjs";
import { filter, mapTo, take } from "rxjs/operators";

import { ContextService } from "./context.service";
import { Localization } from "../index"
import { LocalizationService } from "./localization.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

declare var Polly;

export enum PollyCollection {
  Shipments,
  NewShpmentOrder,
  PickupOrders,
  Recipients,
  Settings,
  ImportProfiles
};

const enabledBusinessUnits = ["010"];

const localizations = new Map<Localization, string>([
  [Localization.EN, "nRTCKu6T74sNFnk3P"],
  [Localization.NL, "tAcaxy5BmHWsdkbDG"]
]);

const urls = new Map<Localization, string>([
  [Localization.EN, "https://shipperfaq-en.dpd.nl"],
  [Localization.NL, "https://shipperfaq-nl.dpd.nl"]
]);

const collections = new Map<Localization, Map<PollyCollection, string>>([
  [Localization.EN, new Map([
    [PollyCollection.Shipments, "oAQdbTzKQdK4tXph5"],
    [PollyCollection.NewShpmentOrder, "RF5ByNTBzrs4nDab9"],
    [PollyCollection.PickupOrders, "cG8tCk8sY2cbykMGm"],
    [PollyCollection.Recipients, "t46jJfozYcLupaGmw"],
    [PollyCollection.Settings, "Pv2vW9FemmoD59hMD"],
    [PollyCollection.ImportProfiles, "9uJwzzdHZoLBcx6WJ"]
  ])],
  [Localization.NL, new Map([
    [PollyCollection.Shipments, "xDxQZfLE6LcpyhP76"],
    [PollyCollection.NewShpmentOrder, "ncDyjtzdQ2PsYq4qc"],
    [PollyCollection.PickupOrders, "KsYPAFKyBr7rGiv6f"],
    [PollyCollection.Recipients, "ZewNgzizCt7N64BNQ"],
    [PollyCollection.Settings, "jRXNPxApp65nko4pa"],
    [PollyCollection.ImportProfiles, "K2r7MJdEee5NCoyGe"]
  ])]
]);

@Injectable()
export class PollyService {
  private _isPollyEnabled = false;
  public _isPollyLoaded$ = new ReplaySubject<boolean>(1);

  constructor(
      private _co: ContextService,
      private _localizaionService: LocalizationService,
      private _sanitizer: DomSanitizer) {
    this.init();
  }

  get isPollyLoaded$(): Observable<boolean> {
    return this._isPollyLoaded$.asObservable();
  }

  get isPollyEnabled(): boolean {
    return this._isPollyEnabled;
  }

  openDialog(collection: PollyCollection) {
    if (!this._isPollyEnabled) {
      return;
    }

    this._isPollyLoaded$.subscribe(() => {
      const currentLocalizationCollections = collections.get(this._localizaionService.currentLocalization) || collections.get(Localization.EN);
      const collectionKey = currentLocalizationCollections.get(collection);

      if (Boolean(collectionKey)) {
        Polly.openHelp({topic: collectionKey});
      }
    });
  }

  getPollyUrl(): SafeUrl {
    const url = urls.get(this._localizaionService.currentLocalization) ||
      urls.get(Localization.EN);

    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private init() {
    this._co.businessUnitCode.pipe(
      filter(v => Boolean(v)),
      take(1)
    ).subscribe(buCode => {
      if (_.includes(enabledBusinessUnits, buCode)) {
        this._isPollyEnabled = true;
        this.appendScript();
        this.appendStyle();
      }
    });
  }

  private appendScript() {
    const key = localizations.get(this._localizaionService.currentLocalization) || localizations.get(Localization.EN);
    const el = document.createElement("script");
    const headEl = document.getElementsByTagName("head")[0];

    el.async = true;
    el.src = `https://cdn.polly.help/p/${key}/main.js`;
    fromEvent(el, "load").pipe(mapTo(true)).subscribe(this._isPollyLoaded$);

    headEl.appendChild(el);
  }

  private appendStyle() {
    const key = localizations.get(this._localizaionService.currentLocalization) || localizations.get(Localization.EN);
    const el = document.createElement("link");
    const headEl = document.getElementsByTagName("head")[0];

    el.rel = "stylesheet";
    el.href = `https://cdn.polly.help/p/${key}/main.css`;
    headEl.appendChild(el);
  }
}