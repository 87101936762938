import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { saveAs } from "file-saver";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, forkJoin } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import * as SettingsModel from "../../models/settings.model";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { ShipmentService } from "../../../shipments/services/shipments.service";
import { PrinterType, PrintingType, PrinterLanguage, PaperSize } from "../../../shared/models/config.models";
import { ShipperSettingsService, BusinessUnitSettingsService } from "../../../shared/index";
import { Observable } from 'rxjs';

import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WizardService } from "../../../shared/modules/wizard/services/wizard.service";
import { ShipperWizardService, ShipperWizardUserAction } from "../../../shared/services/shipper-wizard.service";
import { WizardStepName } from "../../../shared/services/shipper-wizard-steps";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-print-settings",
  templateUrl: "./print-settings.component.html"
})
export class PrintSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    private buSettingsService: BusinessUnitSettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    private _shipmentService: ShipmentService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    toastr: ToastrService,
    private _shipperSettingsService: ShipperSettingsService,
    private _wizardService: WizardService,
    private _shipperWizardService: ShipperWizardService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr,
      _wizardService);
  }

  private _isSophia = false;
  public get isSophia(): boolean { return this._isSophia; }
  public canDisplayPrintReturnFormSetting: boolean;

  public printers: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public printerTypes: BehaviorSubject<PrinterType[]> = new BehaviorSubject(null);
  public printingType: BehaviorSubject<PrintingType[]> = new BehaviorSubject(null);
  public printerLanguages: BehaviorSubject<PrinterLanguage[]> = new BehaviorSubject(null);
  public paperSizes: BehaviorSubject<PaperSize[]> = new BehaviorSubject(null);
  public buCode: string;
  public isCentralShipper = this._shipperSettingsService.isCentralShipper;
  public isELabelPngAvailable = false;

  private loadPrintersAndLookups() {
    return forkJoin([
      this.settingsService.getPrinters(),
      this.settingsService.getPrintingTypes(),
      this.settingsService.getPrinterTypes(),
      this.settingsService.getPrinterLanguages(),
      this.settingsService.getPaperSizes()
    ]).pipe(
      map(result => {
        const printers = result[0];
        this.printers.next(printers);

        this.printingType.next(result[1]);
        this.printerTypes.next(result[2]);
        this.printerLanguages.next(result[3]);
        this.paperSizes.next(result[4]);

        return true;
      })
    );
  }


  public printTestLabel() {
    if (this.hasUnsavedChanges()) {
      this.toastr.error(this.localizationService.getLocalizedString("save_settings_before_printing_test_label"));
      return;
    }

    this._shipperWizardService.hasTestLabelError = false;

    this._shipmentService.getPdfFromApi("GET", `${Shared.API_URL}/shipments/labels/test/${this.customerDetailId}`)
      .then(result => {
        if (!result.type.toLowerCase().startsWith("application/json")) {
          // Dostali sme určite Blob.
          if (result.size > 0) {

            // Mali by sme mať obsah PDF súboru.
            let mediaType = "application/pdf";
            let filename = `test_label_${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`;

            // Only E-Label is returned as PNG
            if (result.type.toLowerCase().startsWith("image/png")) {
              mediaType = "image/png";
              filename = filename.replace('.pdf', '.png'); // Simple hack to replace file extension
            }

            const blob = new Blob([result], { type: mediaType });

            saveAs(blob, filename, true);
          } else {
            // Tlačili sme.
            this.toastr.success(this.localizationService.getLocalizedString("labels_sent_to_printer"));
          }
        } else {
          this.toastr.success(this.localizationService.getLocalizedString("labels_sent_to_printer"));
        }

        this.isBusy = false;
        this._wizardService.completeStep(WizardStepName.SettingsPrintTestLabel);
      }, reason => {
        this.isBusy = false;

        this.loggingService.logErrorData(reason, "Error printing test label.");

        const exception = this.exceptionsHandlerService.getExceptionInfo(reason);

        this.toastr.error(this.localizationService.getLocalizedExceptionString(exception));

        this._shipperWizardService.hasTestLabelError = true;
        this._wizardService.completeStep(WizardStepName.SettingsPrintTestLabel);
      });
  }


  public ngOnInit() {
    super.ngOnInit();
    this._isSophia = this._shipperSettingsService.isSophia;

    forkJoin([
      this.buSettingsService.getPrintReturnForm().pipe(take(1)),
      this.buSettingsService.getIsPrintReturnConfirmationFormOptionVisible().pipe(take(1))
    ]).subscribe(result => {
      this.canDisplayPrintReturnFormSetting = result.every(val => !!val);
    });

    this.buSettingsService.getIsELabelPngAvailable().pipe(take(1))
      .subscribe(result => this.isELabelPngAvailable = result);

    this.contextService.businessUnitCode.pipe(
      filter(buCode => !!buCode),
      take(1)
    ).subscribe(buCode => {
      this.buCode = buCode;
    });
  }


  private loadPrinterRules() {

  }

  protected afterSettingsLoaded() {
    this.loadPrintersAndLookups().subscribe(loaded => {

    }, ex => {
      const exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex);
      this.toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo));
      this.isBusy = false;
    });
  }

  isPrinterForLabelVisible() {
    return this.isPrintingSettingsPropertyVisible("printerForLabel")
            && !this.isLabelPrintingTypePdfLocalFolderSelected();
  }

  isPrintingLabelPrintingTypePdfLocalFolder() {
    return this.isPrintingSettingsPropertyVisible("labelPrintingType")
           && this.isLabelPrintingTypePdfLocalFolderSelected();
  }

  private isLabelPrintingTypePdfLocalFolderSelected() {
    return this.settings.printingSettings.value.labelPrintingType == SettingsModel.PrinterType.PdfLocalFolder;
  }

  isPrinterForProtocolVisible() {
    return this.isPrintingSettingsPropertyVisible("printerForProtocol")
             && !this.isPrintingProtocolPrintingTypePdfLocalFolder();
  }

  isPrintingProtocolPrintingTypePdfLocalFolder() {
    return this.isPrintingSettingsPropertyVisible("protocolPrintingType")
            && this.isProtocolPrintingTypePdfLocalFolderSelected();
  }

  isLabelSortOrderVisible() {
    return this.isPrintingSettingsPropertyVisible("labelSortOrder")
           && (this.buCode === "036" || this.buCode === "034");
  }

  private isProtocolPrintingTypePdfLocalFolderSelected() {
    return this.settings.printingSettings.value.protocolPrintingType == SettingsModel.ProtocolPrintingType.PdfLocalFolder;
  }

  isHideUsernameOnProtocolVisible() {
    return this.buSettingsService.getIsHideUsernameOnProtocolVisible()
    /*&& this.buCode === "010";*/
  }

  public set isHideUsernameOnProtocolSelected(value: boolean) {
    if (value) {
      this.settings.hide_username_on_protocol.value = value;
    }
  }

  public onSubmit() {
    this._shipperWizardService.addUserAction(ShipperWizardUserAction.SavedPrintSettings);
    this.save();
  }
}
