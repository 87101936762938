import { Pipe, PipeTransform } from "@angular/core"
import * as moment from "moment"

// Services
import { LocalizationService } from "../services/localization.service";

// Utils
import { SELECTOR_PREFIX } from "../prefix";

// import { LocalizationService } from "../index"


@Pipe({ name: SELECTOR_PREFIX + "LocalizeDate" })
export class LocalizeDatePipe implements PipeTransform {
  constructor(private _localizationService: LocalizationService) { }


  public transform(value: any, ...args: any[]): any {
    if (typeof value === "undefined" || value == null) {
      return null;
    }

    let format = "L LT";

    if (args.length > 0) {
      let formatParameter = args[0];
      if (formatParameter && typeof formatParameter === "string") {
        format = formatParameter;
      }
    }

    let momentDate;

    switch (true) {
      case value instanceof Date:
        momentDate = moment(value);
        return momentDate.format(format);
      case typeof value === "string":
        momentDate = moment(value);
        return momentDate.format(format);
      default:
        return null
    }
  }
}