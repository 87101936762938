import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from "rxjs";
import { auditTime, filter, map, skip, skipWhile, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { SettingsService } from "src/app/settings/services/settings.service";

import { AuthenticationService, BusinessUnitSettingsService, CHANGE_PASSWORD_URL, ContextService } from "../index";
import { DEMO_USER_LOGIN } from "../models/user.model";
import { GlobalEventsStreamService, UserLoginEvent } from "./global-events-stream.service";

interface PasswordServiceState {
  isPasswordChangeRequired: boolean;
  isPasswordChangeRequiredPending: boolean;
  isResolvePasswordChangeUserLoginEventPending: boolean;
  hasUserChangedPasswordInThisSession: boolean;
}

const initialState: PasswordServiceState = {
  isPasswordChangeRequired: false,
  isPasswordChangeRequiredPending: false,
  isResolvePasswordChangeUserLoginEventPending: false,
  hasUserChangedPasswordInThisSession: false
};

@Injectable()
export class PasswordService {
  private _state$ = new BehaviorSubject<PasswordServiceState>(initialState);

  readonly state$: Observable<Readonly<PasswordServiceState>> = this._state$.pipe(auditTime(0));

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _businessUnitSettingsService: BusinessUnitSettingsService,
    private _settingsService: SettingsService,
    private _contextService: ContextService,
    private _globalEventsStreamService: GlobalEventsStreamService
  ) {
    this.initIsPasswordChangeRequiredStream();
    this.observeUser();
  }

  get stateSnapshot(): Readonly<PasswordServiceState> {
    return this._state$.getValue();
  }

  getHasCurrentUserUnchangedPassword(): Observable<boolean> {
    if (this.stateSnapshot.hasUserChangedPasswordInThisSession) {
      return of(false);
    }

    return combineLatest([
      this._contextService.currentAddress.pipe(filter(a => Boolean(a))),
      this._authenticationService.user.pipe(filter(u => Boolean(u))),
      this._authenticationService.impersonatingShipperAdminUserLogin$
    ]).pipe(
      take(1),
      switchMap(([ca, user, impersonatingAdminUserLogin]) => {
        switch (true) {
          // `lastPasswordChangeDateTimeUtc` has value. Return false.
          case Boolean(user.lastPasswordChangeDateTimeUtc):
          case Boolean(impersonatingAdminUserLogin):
          case user?.login === DEMO_USER_LOGIN:
            return of(false);
          case user.isFirstLogin:
            return of(true);
          // When user is reincialized from the stored token the `lastPasswordChangeDateTimeUtc`
          // can be null even if the password was changed. That's why an API call is necessary.
          case !user.lastPasswordChangeDateTimeUtc:
          default:
            return this._settingsService.getHasUserUnchangedPassword(ca.customerDetailId, user.id);
        }
      }),
      // Cancel the request in case user logs out.
      takeUntil(this._authenticationService.user.pipe(skip(1)))
    );
  }

  getHasCurrentUserOldPassword(): Observable<boolean> {
    if (this.stateSnapshot.hasUserChangedPasswordInThisSession) {
      return of(false);
    }

    return combineLatest([
      this._contextService.currentAddress.pipe(filter(a => Boolean(a))),
      this._authenticationService.user.pipe(filter(u => Boolean(u))),
      this._authenticationService.impersonatingShipperAdminUserLogin$
    ]).pipe(
      take(1),
      switchMap(([ca, user, impersonatingAdminUserLogin]) => {
        // Return false for these cases.
        if (user.isFirstLogin ||
           !user.lastPasswordChangeDateTimeUtc ||
           Boolean(impersonatingAdminUserLogin) ||
           user?.login === DEMO_USER_LOGIN) {
          return of(false);
        }

        return this._settingsService.getHasUserOldPassword(ca.customerDetailId, user.id);
      }),
      // Cancel the request in case user logs out.
      takeUntil(this._authenticationService.user.pipe(skip(1)))
    );
  }

  onPasswordChange(): void {
    this.updateState({
      isPasswordChangeRequired: false,
      hasUserChangedPasswordInThisSession: true
    });

    // If user changed password as part of login event
    // leave the page. SHIPPER-990
    this._globalEventsStreamService.userLoginEvents$.pipe(
      take(1)
    ).subscribe(s => {
      if (s.current === UserLoginEvent.ChangePassowrd) {
        this._router.navigate(["/"]);
      }
    });

    this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.ChangePassowrd);
  }

  private resolvePasswordChange() {
    this.updateState({isResolvePasswordChangeUserLoginEventPending: true});

    forkJoin([
      this.getHasCurrentUserOldPassword(),
      this.getHasCurrentUserUnchangedPassword()
    ]).pipe(
      take(1),
    ).subscribe(([isPasswordOld, hasUnchangedPassword]) => {
      if (isPasswordOld || hasUnchangedPassword) {
        this.resolvePasswordChangeFromEditor();
      } else {
        // Password won't be changed. Resolve user login event.
        this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.ChangePassowrd);
      }

      this.updateState({isResolvePasswordChangeUserLoginEventPending: false});
    });
  }

  /**
   * Navigate users to the password change page and synchronize
   * the ChangePassword event with other user login events.
   */
   private resolvePasswordChangeFromEditor() {
    // Wait for the ChangePassword event.
    this._globalEventsStreamService.userLoginEvents$.pipe(
      filter(e => e.current === UserLoginEvent.ChangePassowrd),
      // Cancel when user logs out.
      takeUntil(this._authenticationService.user.pipe(filter(u => !Boolean(u)))),
    ).subscribe(() => {
      // Navigate to the password change page.
      this._router.navigate([CHANGE_PASSWORD_URL]);

      // When users leave the password change page
      // the ChangePassword event can be considered resolved.
      this._router.events.pipe(
        skipWhile(e => !(e instanceof NavigationEnd && e.url === CHANGE_PASSWORD_URL)),
        filter(e => e instanceof NavigationEnd),
        skip(1),
        take(1),
        // Cancel when user logs out.
        takeUntil(this._authenticationService.user.pipe(skip(1)))
      ).subscribe(() => {
        this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.ChangePassowrd);
      });
    });
  }

  private initIsPasswordChangeRequiredStream() {
    // No user is logged in.
    this._authenticationService.user.pipe(
      filter(u => !u),
    ).subscribe(() => this.updateState({
      isPasswordChangeRequired: false,
      isPasswordChangeRequiredPending: false,
      hasUserChangedPasswordInThisSession: false
    }));

    // Resolve from API.
    this._authenticationService.user.pipe(
      filter(u => Boolean(u)),
      tap(() => this.updateState({isPasswordChangeRequiredPending: true})),
      // Get IsUnchangedInitialPasswordAllowed setting.
      switchMap(() => this._businessUnitSettingsService.getIsUnchangedInitialPasswordAllowed()),
      // Resolve has user changed the password.
      // If unchanged password is allowed return false, else obtain result from API.
      switchMap(isAllowed => isAllowed ? of(false) : this.getHasCurrentUserUnchangedPassword()),
    ).subscribe(result => this.updateState({
      isPasswordChangeRequired: result,
      isPasswordChangeRequiredPending: false
    }));
  }

  private observeUser() {
    this._authenticationService.user.pipe(
      filter(u => Boolean(u))
    ).subscribe(() => this.resolvePasswordChange());
  }

  private updateState(state: Partial<PasswordServiceState>) {
    this._state$.pipe(
      take(1),
      map(current => ({
        ...current,
        ...state
      }))
    ).subscribe(s => this._state$.next(s));
  }
}
