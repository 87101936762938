<div class="settings-category no-toolbar user-management">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-wrapper">
    <h2>{{ 'user_management' | shpLocalize }}</h2>
    <div *ngFor="let user of users$ | async" class="user" [class.disabled]="user.disabledSinceDateUtc">
      <!--  Používam *ngIf="canCreateUser", lebo je to vlastne ekvivalent, či je demo mód v tomto prípade. -->
      <span class="commands m-0">
        <button *ngIf="canCreateUser" id="button-edit-user" type="button" (click)="editUser(user)" title="{{ 'edit_user' | shpLocalize }}">
          <span class="ic-pencil-1"></span>
        </button>
        <button *ngIf="canCreateUser && currentUser.login != user.loginName && allowAddingNewUsers" id="button-delete-user" type="button" (click)="deleteUser(user)" [disabled]="!user.canDelete" title="{{ user.deleteTooltip | shpLocalize }}">
          <span class="ic-bin"></span>
        </button>
      </span>
      <span class="data">
        <span class="user-name">{{ user.name }} <span class="user-login-name">({{ user.loginName }})</span></span>
        <span class="user-role"><span *ngFor="let role of user.displayRoles">{{ 'role_' + role | shpLocalize }}</span></span>
      </span>
    </div>
  </div>
  <div class="commands-toolbar">
    <button id="button-add-user" *ngIf="canCreateUser && allowAddingNewUsers" type="button" (click)="addUser()" wizardTag="addNewUser" [wizardCompleteStep]="wizardStepNames.ClickAddUser"><span class="ic-user-add"></span> {{ 'add_user' | shpLocalize }}</button>
  </div>
</div>