import { Component, OnInit, Input } from "@angular/core";

import * as Shared from "../../../shared/index";

// Services
import { RemoteData, CompleterService } from "ng2-completer";

// Models
import { Address } from "../../../shipments/models/shipment.model";
import { City, SkdataConfigService } from "../../../shared/index";
import { tap } from "rxjs/operators";

@Component({
    selector: Shared.SELECTOR_PREFIX + "-address-form",
    templateUrl: "./address-form.component.html"
})
export class AddressFormComponent implements OnInit {
    // public citiesDataService: RemoteData = null;
    public settings: Address = null;
    public filteredCountries: any[];

    @Input()
    countries: Shared.Country[];

    @Input('settings')
    set setSettings( settings: Address ) {
        this.settings = settings;
    }

    @Input()
    config: { [ey: string]: boolean };

    constructor(
        localizationService: Shared.LocalizationService,
        private _completerService: CompleterService,
        private _skDataConfigService: SkdataConfigService
    ) {
    }

    ngOnInit() {
        // this.citiesDataService = this._completerService.remote(null, null, "cityNameLocal");
        // this.citiesDataService.urlFormater((term: string) => {
        //   return `${Shared.API_URL}/countries/${this.settings.country.code}/${term}`;
        // });
    }

    public countryChanged() {
        if (this.settings.country) {
          this.settings.countryCode = this.settings.country.code;
        } else {
          this.settings.countryCode = null;
        }
      }

    public filteredCities: any[];

    public filterCities(event): void {
      let query = event.query;
      let countryCode = this.settings.country ? this.settings.country.code : this.settings.countryCode;

      this._skDataConfigService.getCities( countryCode, query ).pipe(
        tap((cities: City[]) => this.filteredCities = cities.map( (city: City) => city.cityNameLocal ) )
      ).subscribe();
    }
}
