import { Component, forwardRef, Input } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import * as Shared from "../../shared/index";
import { UserRelatedItemModel } from "../../shared/models/user.model";
import { UserRelatedItemListEditorBase } from "./user-related-item-list-editor-base";

const baseProvider = {
  provide: UserRelatedItemListEditorBase,
  useExisting: forwardRef(() => UserRelatedAddressItemListEditorComponent)
};

@Component({
  selector: Shared.SELECTOR_PREFIX + "-user-related-address-item-list-editor",
  templateUrl: "./user-related-item-list-editor-base.html",
  providers: [baseProvider]
})
export class UserRelatedAddressItemListEditorComponent extends UserRelatedItemListEditorBase {
  constructor(
    loggingService: Shared.LoggingService,
    exceptionHandlerService: Shared.ExceptionsHandlerService,
    localizationService: Shared.LocalizationService,
    customerService: Shared.CustomerService,
    toastr: ToastrService) {

    super(
      (userId: number) => customerService.getCustomerDetailAdressItemsForUser(userId),
      (items: UserRelatedItemModel[]) => customerService.setCustomerDetailAdressItemsForUser(items),
      "user_access_control_address_items_saved",
      loggingService,
      exceptionHandlerService,
      localizationService,
      toastr);
  }

  @Input()
  public set userId(newId: number) {
    super.setUserId(newId);
  }
}
