
const ACCESS_TOKEN_STORAGE_KEY = "api_access_token";
const REFRESH_TOKEN_STORAGE_KEY = "api_refresh_token";

export class TokensStorageService {
  private getToken(tokenStorageKey: string) {
    var token = localStorage.getItem(tokenStorageKey);
    return token;
  }


  private setToken(tokenStorageKey: string, token: string) {
    localStorage.setItem(tokenStorageKey, token);
  }


  private hasToken(tokenStorageKey: string): boolean {
    var token = this.getToken(tokenStorageKey);

    return token != null;
  }


  private clearToken(tokenStorageKey: string) {
    localStorage.removeItem(tokenStorageKey);
  }


  public getAccessToken(): string {
    return this.getToken(ACCESS_TOKEN_STORAGE_KEY);
  }


  public setAccessToken(token: string) {
    this.setToken(ACCESS_TOKEN_STORAGE_KEY, token);
  }


  public hasAccessToken(): boolean {
    return this.hasToken(ACCESS_TOKEN_STORAGE_KEY);
  }


  public clearAccessToken() {
    this.clearToken(ACCESS_TOKEN_STORAGE_KEY);
  }


  public getRefreshToken(): string {
    return this.getToken(REFRESH_TOKEN_STORAGE_KEY);
  }


  public setRefreshToken(token: string) {
    this.setToken(REFRESH_TOKEN_STORAGE_KEY, token);
  }


  public hasRefreshToken(): boolean {
    return this.hasToken(REFRESH_TOKEN_STORAGE_KEY);
  }


  public clearRefreshToken() {
    this.clearToken(REFRESH_TOKEN_STORAGE_KEY);
  }
}