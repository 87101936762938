<div class="support-category contact">
  <h1 *ngIf="info?.supportContactName || info?.supportContactEmail || info?.supportContactPhone">{{ 'it_support_contact' | shpLocalize }}</h1>
  <div *ngIf="info?.supportContactName"><strong>{{ 'support_contact_name' | shpLocalize }}:</strong> {{ info?.supportContactName }}</div>
  <div *ngIf="info?.supportContactEmail"><strong>{{ 'support_contact_email' | shpLocalize }}:</strong> <a target="_blank" href="mailto:{{info?.supportContactEmail}}">{{ info?.supportContactEmail }}</a></div>
  <div *ngIf="info?.supportContactPhone"><strong>{{ 'support_contact_phone' | shpLocalize }}:</strong> {{ info?.supportContactPhone}}</div>

  <h1 *ngIf="info?.customerServiceName || info?.customerServiceEmail || info?.customerServicePhone">{{ 'customer_service' | shpLocalize }}</h1>
  <div *ngIf="info?.customerServiceName"><strong>{{ 'support_contact_name' | shpLocalize }}:</strong> {{ info?.customerServiceName }}</div>
  <div *ngIf="info?.customerServiceEmail"><strong>{{ 'support_contact_email' | shpLocalize }}:</strong> <a target="_blank" href="mailto:{{info?.customerServiceEmail}}">{{ info?.customerServiceEmail }}</a></div>
  <div *ngIf="info?.customerServicePhone"><strong>{{ 'support_contact_phone' | shpLocalize }}:</strong> {{ info?.customerServicePhone}}</div>

  <h1 *ngIf="info?.salesName || info?.salesEmail || info?.salesPhone">{{ 'sales' | shpLocalize }}</h1>
  <div *ngIf="info?.salesName"><strong>{{ 'support_contact_name' | shpLocalize }}:</strong> {{ info?.salesName }}</div>
  <div *ngIf="info?.salesEmail"><strong>{{ 'support_contact_email' | shpLocalize }}:</strong> <a target="_blank" href="mailto:{{info?.salesEmail}}">{{ info?.salesEmail }}</a></div>
  <div *ngIf="info?.salesPhone"><strong>{{ 'support_contact_phone' | shpLocalize }}:</strong> {{ info?.salesPhone}}</div>
</div>