import { Directive, Input, HostListener } from "@angular/core";

import * as Shared from "../index";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}ScrollToElement]`
})
export class ScrollToElementDirective {

  @Input(Shared.SELECTOR_PREFIX + "ScrollToElement") element: () => HTMLElement | HTMLElement;

  @HostListener("click")
  scrollToElement() {
    let el: HTMLElement = typeof this.element === "function" ? this.element() : this.element;

    if (!el) {
      return;
    }

    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    el.focus();
  }
}