<h1 class="icon-title ic-lock-1 ic-5x"></h1>
<div class="error" *ngIf="exception != null">{{ exception | shpExceptionInfo }}</div>

<div class="panel wide" *ngIf="displayMessage"
  [class.error]="displayMessageType === 'Error'"
  [class.info]="displayMessageType === 'Info'">
  {{ displayMessage }}
</div>

<form *ngIf="formType === 'login'" role="form" #f (submit)="logIn($event)" name="loginForm">
  <div class="ctl">
    <input id="input-login" placeholder="{{ 'login_name' | shpLocalize }}" type="text" [(ngModel)]="login" name="loginName" maxlength="100" required />
  </div>
  <div class="ctl">
    <input id="input-password" placeholder="{{ 'password' | shpLocalize }}" type="password" [(ngModel)]="password" name="password" />
  </div>
  <div class="ctl">
    <button id="button-submit" type="submit">{{ 'log_in' | shpLocalize }}</button>
  </div>
  <div *ngIf="canRequest" class="forgotten-password">
    <a href="#" (click)="changeFormType('request', $event)">{{ 'forgotten_password' | shpLocalize }}</a>
  </div>
</form>

<form *ngIf="formType === 'request'" role="form" (submit)="requestPassword($event)" name="requestForm">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="ctl required">
    <input id="input-login" placeholder="{{ 'login_name' | shpLocalize }}" type="text"
           [(ngModel)]="login" name="loginName" maxlength="100" required />
  </div>
  <div class="ctl">
    <button id="button-submit-request" type="submit"><span class="ic-lock-1"></span> {{ 'request_password_change' | shpLocalize }}</button>
  </div>
  <div class="forgotten-password">
    <a href="#" (click)="changeFormType('login', $event)">{{ 'back_to_login' | shpLocalize }}</a>
  </div>
</form>

<form *ngIf="formType === 'reset'" (submit)="resetPassword()" #passwordForm="ngForm" autocomplete="off" novalidate>
  <div class="ctl required">
    <input id="input-login" placeholder="{{ 'login_name' | shpLocalize }}" type="text"
          [(ngModel)]="login" name="loginName" maxlength="100" required />
  </div>
  <div class="ctl required">
    <input type="password" [(ngModel)]="newPassword" maxlength="50" id="user-password" placeholder="{{ 'user_new_password' | shpLocalize }}"
          #password="ngModel" name="userPassword" shpValidateEqual="userPasswordConfirmm" reverse="true" />
  </div>
  <div class="ctl required">
    <input type="password" [(ngModel)]="newPasswordConfirmation" maxlength="50" id="user-password-confirm" placeholder="{{ 'user_new_password_confirm' | shpLocalize }}"
          name="userPasswordConfirmm" #confirmPassword="ngModel" shpValidateEqual="userPassword" />
  </div>
  <div class="ctl" *ngIf="confirmPassword.errors?.validateEqual">
    <em class="error" >{{ 'password_mismatch' | shpLocalize }}</em>
  </div>
  <div class="ctl">
    <button id="button-change-password" [disabled]="!passwordForm.form.valid" type="submit"><span class="ic-lock-1"></span> {{ 'change_password' | shpLocalize }}</button>
  </div>
</form>

<shp-language-bar></shp-language-bar>