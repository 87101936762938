import { CdkScrollable, ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, PositionStrategy } from "@angular/cdk/overlay";
import { OverlayReference } from "@angular/cdk/overlay/overlay-reference";
import { ElementRef, Renderer2 } from "@angular/core";

import { WizardBoundries } from "./models";
import { hasRectArea, isElementScrolledOutsideView, removePx } from "./utils";

export const defaultPopupBoundries: Readonly<WizardBoundries> = {
  top: 0,
  bottom: 0
};

export class WizardPopupPositionStrategy implements PositionStrategy {
  flexibleStrategy: FlexibleConnectedPositionStrategy;

  private scrollables: CdkScrollable[] = [];
  private overlayRef: OverlayReference;
  private boundries = {...defaultPopupBoundries};

  constructor(
      private overlay: Overlay,
      private element: ElementRef,
      private renderer: Renderer2) {
    this.flexibleStrategy = this.overlay.position()
      .flexibleConnectedTo(element)
      .withPush(false)
      .withFlexibleDimensions(false);
  }

  attach(overlayRef: OverlayReference) {
    this.overlayRef = overlayRef;
    this.flexibleStrategy.attach(overlayRef);
  }

  /** Updates the position of the overlay element. */
  apply() {
    if (!hasRectArea(this.element.nativeElement.getBoundingClientRect())) {
      return;
    }

    this.toggle();

    this.flexibleStrategy.apply();

    this.applyBoundries();
  }

  detach() {
    this.flexibleStrategy.detach();
  }

  dispose() {
    this.flexibleStrategy.dispose();
  }

  withPositions(positions: ConnectedPosition[]): this {
    this.flexibleStrategy.withPositions(positions);

    return this;
  }

  withScrollableContainers(scrollables: CdkScrollable[]): this {
    this.scrollables = scrollables;
    this.flexibleStrategy.withScrollableContainers(scrollables);
    return this;
  }

  withBoundries(boundries: Partial<WizardBoundries>) {
    this.boundries = {
      ...defaultPopupBoundries,
      ...boundries
    };

    return this;
  }

  private applyBoundries() {
    const overlayElement = this.overlayRef.overlayElement;
    const overlayRect = overlayElement.getBoundingClientRect();

    const styleTop = removePx(overlayElement.style.top);
    const styleBottom = removePx(overlayElement.style.bottom);

    const offsetY = styleTop != null ?
      overlayRect.top - styleTop :
      overlayRect.bottom + styleBottom - window.innerHeight;

    const topBoundry = this.boundries.top - offsetY;
    const bottomBoundry = window.innerHeight - this.boundries.bottom - offsetY;

    let top = overlayRect.top - offsetY;
    let bottom = overlayRect.bottom - offsetY;

    if (top > topBoundry && bottom < bottomBoundry) {
      return;
    }

    if (bottom >= bottomBoundry) {
      top = top - (bottom - bottomBoundry);
      bottom = bottomBoundry;
    }

    if (top <= topBoundry) {
      top = topBoundry;
      bottom = null;
    }

    if (bottom) {
      this.renderer.setStyle(overlayElement, "bottom", window.innerHeight - bottom + "px");
      this.renderer.removeStyle(overlayElement, "top");
    } else {
      this.renderer.setStyle(overlayElement, "top", top + "px");
      this.renderer.removeStyle(overlayElement, "bottom");
    }
  }

  private toggle() {
    const el = this.element.nativeElement;
    const elRect = el.getBoundingClientRect();
    const scrollContainerRects =
        this.scrollables.map(s => s.getElementRef().nativeElement.getBoundingClientRect());

    const isScrolledOutsideView = isElementScrolledOutsideView(elRect, scrollContainerRects);

    if (isScrolledOutsideView) {
      this.renderer.setStyle(this.overlayRef.overlayElement, "opacity", 0);
      this.renderer.setStyle(this.overlayRef.overlayElement, "pointerEvents", "none");
    } else {
      this.renderer.removeStyle(this.overlayRef.overlayElement, "opacity", 1);
      this.renderer.removeStyle(this.overlayRef.overlayElement, "pointerEvents");
    }
  }
}
