import { Component, OnDestroy, OnInit } from "@angular/core";

import { DialogRef, ModalComponent } from "ngx-modialog-7";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { ImportProfile } from "../../import-profiles/models/import-profiles.models";
import { FILE_TYPE_CSV } from "../../import-profiles/services/import-profiles.service";
import * as Shared from "../../shared/index";
import { DEFAULT_CONCVERT_TO_CSV_OPS, FileData } from "../../shared/index";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import * as RecipientModels from "../models/recipient.model";
import { RecipientsService } from "../services/recipients.service";

const RECIPIENTS_IMPORT_DIALOG_IMPORT_PROFILE_ID_LAST_USED_VALUE = "recipients-import-dialog-import-profile-id-last-used-value";
const RECIPIENTS_IMPORT_DIALOG_ENCODING_LAST_USED_VALUE = "recipients-import-dialog-encoding-last-used-value";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-recipients-import-dialog",
  templateUrl: "./recipients-import-dialog.component.html"
})
export class RecipientsImportDialogComponent implements ModalComponent<RecipientModels.RecipientsImportDialogModalContext>, OnInit, OnDestroy {
  public importEncoding = "utf-8";
  public importFailedInvalidFile = false;
  public importProfile = -1;
  public importedEntriesCount = 0;
  public invalidEntriesCount = 0;
  public isBusy = false;
  /**
   * Záznam z importu (aj úspešné hlášky).
   */
  public logEntries: Shared.LogEntry[] = [];
  public overwriteExistingByReference = true;
  public readFileErrors: string[];
  public selectedFileName = "";
  public statusMessage = "";
  public totalImportEntriesCount = 0;

  private _addressId: number = null;
  private _customerDetailId: number = null;
  private _destroy$ = new Subject<void>();
  private _fileData: FileData = null;
  private _selectedFile: File = null;

  constructor(
    public dialog: DialogRef<RecipientModels.RecipientsImportDialogModalContext>,
    private _recipientsService: RecipientsService,
    private _localizationService: Shared.LocalizationService,
    private _context: Shared.ContextService,
    private _shipperFileReader: Shared.ShipperFileReaderService,
    private _wizardService: WizardService,
  ) {
    this.loadLastUsedSettings();
    dialog.setCloseGuard(this);
  }

  get importProfiles(): ImportProfile[] {
    return this.dialog.context.importProfiles ?? [];
  }

  get isWizardActive(): boolean {
    return this._wizardService.isActive;
  }

  public ngOnInit() {
    this._context.currentAddress.pipe(
      takeUntil(this._destroy$),
      filter(a => a != null)
    ).subscribe(a => {
      this._customerDetailId = a.customerDetailId;
      this._addressId = a.id;
    });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
   */
  public beforeDismiss(): boolean | Promise<boolean> {
    return this._wizardService.isActive;
  }

  /**
   * Vracia, či nie je možné dialóg zatvoriť cez .close().
   */
  public beforeClose(): boolean | Promise<boolean> { return false; }

  public close = () => {
    this.saveLastUsedSettings();
    this.dialog.close(this.importedEntriesCount > 0);
  }

  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }

  private setSelectedFile = (inputValue: any) => {
    if (inputValue.files && inputValue.files.length > 0) {
      this.selectedFileName = inputValue.files[0].name;
      this._selectedFile = inputValue.files[0];
      this.readFileErrors = null;
    } else {
      this.selectedFileName = "";
      this._selectedFile = null;
    }
  }

  private loadLastUsedSettings() {
    // Posledne použitý importný profil.
    if (this.importProfiles.length) {
      const importProfileIdString = localStorage.getItem(RECIPIENTS_IMPORT_DIALOG_IMPORT_PROFILE_ID_LAST_USED_VALUE);

      if (importProfileIdString) {
        const importProfileId = Number(importProfileIdString);

        if (importProfileId && !isNaN(importProfileId)) {
          // Otestujeme, či taký importný profil vôbec máme dostupný.
          const importProfile = this.importProfiles.find(p => p.id === importProfileId);

          if (importProfile) {
            this.importProfile = importProfile.id;
          }
        }
      }

      // Posledne použité kódovanie.
      const encoding = localStorage.getItem(RECIPIENTS_IMPORT_DIALOG_ENCODING_LAST_USED_VALUE);

      if (encoding) {
        this.importEncoding = encoding;
      }
    }
  }

  private saveLastUsedSettings() {
    localStorage.setItem(RECIPIENTS_IMPORT_DIALOG_IMPORT_PROFILE_ID_LAST_USED_VALUE, this.importProfile.toString());
    localStorage.setItem(RECIPIENTS_IMPORT_DIALOG_ENCODING_LAST_USED_VALUE, this.importEncoding);
  }

  public import = () => {
    if (this._selectedFile === null) {
      return;
    }

    this.isBusy = true;
    this.readFileErrors = null;
    let importEncoding = this.importEncoding;

    if (importEncoding === "") {
      const foundImportProfile = this.importProfiles.find(e => e.id === this.importProfile);
      importEncoding = foundImportProfile.encoding;
    }

    // console.log("Recipients encoding: ", importEncoding);

    this._shipperFileReader.readRecipientImportFile(this._selectedFile, importEncoding)
      .subscribe(result => {
        this._fileData = result;

        const importProfile = this.importProfile;

        // Reset hodnôt nastavovaných po každom pokuse o import.
        this.logEntries = [];
        this.importedEntriesCount = 0;
        this.invalidEntriesCount = 0;
        this.importFailedInvalidFile = false;

        const payload: RecipientModels.RecipientsImportModel = {
          settings: {
            tenantId: 0, // prepíše server
            isEnabledUpdateByReferenceNumber: this.overwriteExistingByReference,
            importProfileId: importProfile,
            customerDetailId: this._customerDetailId,
            pickupAddressId: this._addressId
          },
          content: this._fileData.content
        };

        // it is reconverted file from XLS, XLSX, etc... to CSV
        // it was use default converted setting
        // let know the server how he can decode
        // if (converted[1] != FILE_TYPE_CSV) {
        if (this._fileData.extension !== FILE_TYPE_CSV) {
          payload.settings.csvColumnDelimiter = DEFAULT_CONCVERT_TO_CSV_OPS.FS;
          payload.settings.csvRowDelimiter = DEFAULT_CONCVERT_TO_CSV_OPS.RS;
        }

        this._recipientsService.importReciepients(payload).subscribe(importOutput => {
          this.logEntries = importOutput.logEntries;
          this.isBusy = false;

          // Uložíme si počty úspešne naimportovaných a chybných riadkov.
          // TODO: Zobraziť to v UI.
          this.importedEntriesCount = importOutput.itemsProcessedSuccessfullyCount;
          this.invalidEntriesCount = importOutput.itemsProcessedWithErrorCount;

          this.totalImportEntriesCount += this.importedEntriesCount;
        }, (ex: Shared.ExceptionInfo) => {
          if (ex.key === "Import_error") {
            // Zobrazujeme len generickú hlášku
            this.readFileErrors = [
              this._localizationService.getLocalizedString("recipients_import_error_invalid_file")
            ];
          } else {
            this.readFileErrors = [
              this._shipperFileReader.getErrorDescription(ex.key, ex.params.length ? ex.params[0] : [])
            ];
          }

          this.isBusy = false;
        });
      }, (err) => {
        this.readFileErrors = err.errors;
        this._fileData = err.fileData;
        this.isBusy = false;
      });
  }

  canUseEncodingFromProfile() {
    if (!this.importProfiles.length) {
      return false;
    }

    const foundImportProfile = this.importProfiles.find(e => e.id === this.importProfile);
    return foundImportProfile != null && foundImportProfile.encoding !== "";
  }
}
