import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import * as _ from "lodash"
import * as moment from "moment"
import * as numeral from "numeral"

// import { ApiServiceBase, LoggingService, TextResource, Localization, StringHelpers, API_URL, ROOT_URL, ExceptionInfo } from "./../index"

import "numeral/locales";
import { ApiServiceBase } from "./api-service-base"
import { LoggingService } from "./logging.service"
import { TextResource } from "../models/text-resource.model"
import { Localization } from "../localization.enum"
import { ROOT_URL } from "../root-url"
import { ExceptionInfo } from "../exceptions"
import { API_URL } from "../api-url"
import { StringHelpers } from "../string-helpers"
import { AuthenticationService } from './authentication.service';

@Injectable()
export class LocalizationService extends ApiServiceBase {
  constructor(
    loggingService: LoggingService,
    private _authenticationService: AuthenticationService,
    private _http: HttpClient
  ) {
    super(loggingService);
  }


  /**
 * Načítané textové reťazce zo servera.
 * Ak je hodnota NULL, tak ešte žiadne nie sú načítané.
 */
  private _textResources: TextResource[] = null;


  private static currentLocalizatonLocalStorageKey = "current.localization";

  private _allowedLocalizations: string[] = ["EN", "DE", "NL", "BE", "FR", "IT", "SK"];

  public get allowedLocalizations(): string[] {
    return this._allowedLocalizations;
  }


  public getBrowserSupportedLanguageFromGlobalVariable() {
    return (window as any).defaultLanguage;
  }


  public get currentLocalization(): Localization {
    let stringValue: string = this.currentCultureCode;

    return Localization[stringValue] as Localization;
  }


  public set currentLocalization(value: Localization) {
    localStorage.setItem(LocalizationService.currentLocalizatonLocalStorageKey, Localization[value]);

    this.setComponentsLocalization();
  }


  public get currentCultureCode(): string {
    const stringValue: string = localStorage.getItem(LocalizationService.currentLocalizatonLocalStorageKey);

    if (typeof stringValue === "undefined" || stringValue === null || stringValue.length === 0) {
      localStorage.setItem(LocalizationService.currentLocalizatonLocalStorageKey, Localization[this.getBrowserSupportedLanguageFromGlobalVariable()]);

      return this.getBrowserSupportedLanguageFromGlobalVariable();
    }

    return stringValue;
  }

  public get currentLocalizationCode(): string {
    return localStorage.getItem(LocalizationService.currentLocalizatonLocalStorageKey);
  }


  private getNumeralCultureCode(genericCultureCode: string) {
    switch (genericCultureCode) {
      case "nl":
        return "nl-NL";
      default:
        return genericCultureCode;
    }
  }


  private getCultureCode(localization: Localization) {
    switch (localization) {
      case Localization.BE:
        return "nl-BE";
      case Localization.EN:
        return "en-GB";
      default:
        return Localization[this.currentLocalization].toLowerCase();
    }
  }


  public setComponentsLocalization() {
    const cultureCode = this.getCultureCode(this.currentLocalization);

    numeral.locale(this.getNumeralCultureCode(cultureCode));
    const wijmoLocaleStringCode = cultureCode;
    moment.locale(cultureCode);

    // Načítam si správny súbor pre Wijmo lokalizáciu a globalizáciu.
    // Toto sa nie vždy prejaví pri prvom zobrazení stránky, ale akonáhle sa používateľ
    // preklikne sekciami, už je to OK. Zatiaľ to nebudeme riešiť.
    const s = document.createElement("script");
    //s.setAttribute("type", "text/javascript");
    s.setAttribute("src", ROOT_URL + "/assets/lib/wijmo/locales/wijmo.culture." + wijmoLocaleStringCode + ".min.js");

    document.getElementsByTagName("head")[0].appendChild(s);
  }

  /**
 * Vráti hodnotu textového reťazca definovanú kľúčom v aktuálnom jazyku.
 * Ak hodnota neexistuje, vráti samotný kľúč.
 *
 * @param key Kľúč textového reťazca, ktorého lokalizovanú hodnotu chceme získať.
 */
  public getLocalizedExceptionString(exceptionInfo: ExceptionInfo): string {
    if (!exceptionInfo.params) {
      exceptionInfo.params = [];
    }

    return this.getLocalizedString(exceptionInfo.key, ...exceptionInfo.params);
  }

  /**
   * Vráti hodnotu textového reťazca definovanú kľúčom v aktuálnom jazyku.
   * Ak hodnota neexistuje, vráti samotný kľúč.
   *
   * @param key Kľúč textového reťazca, ktorého lokalizovanú hodnotu chceme získať.
   */
  public getLocalizedString(key: string, ...params: any[]): string {
    const resource = _.find(this._textResources, tr => tr.key === key);

    if (resource) {
      const currentLocalization = this.currentLocalization;

      let localizedValue = "";

      switch (currentLocalization) {
        case Localization.EN:
          localizedValue = resource.valueEn;
          break;

        case Localization.SK:
          localizedValue = resource.valueSk;
          break;

        case Localization.DE:
          localizedValue = resource.valueDe;
          break;

        case Localization.FR:
          localizedValue = resource.valueFr;
          break;

        case Localization.IT:
          localizedValue = resource.valueIt;
          break;

        case Localization.CS:
          localizedValue = resource.valueCs;
          break;

        case Localization.NL:
          localizedValue = resource.valueNl;
          break;

        case Localization.BE:
          localizedValue = resource.valueBe;
          break;

        default:
          localizedValue = resource.valueEn;
      }

      // Ak v danom jazyku lokalizovaný reťazec nie je, fallback na angličtinu.
      if (!localizedValue) {
        localizedValue = resource.valueEn;
      }

      // Ak to nemáme ani v angličtine, zobrazíme key reťazca.
      if (!localizedValue) {
        localizedValue = key;
      }

      // Neposúvame hneď params, lebo StringHelper.format by to bral tak, že máme parametre a snažil by sa nahradzovať.
      if (typeof params === "undefined" || params == null || params.length === 0) {
        return localizedValue;
      } else {
        return StringHelpers.format(localizedValue, ...params);
      }
    }

    return key;
  }


  /**
   * Gets localized part from multi language string
   * (e.g. @EN: excellent product @DE: ausgezeichnetes Produkt)
   * for current language.
   */
  getLocalizedPartOfString(source: string): string {
    const langTagRegex = /\n*@[A-Z]{2}: /;
    const langTagPos = source.search(langTagRegex);

    /** Check if source contains a language tag. */
    if (langTagPos < 0) {
      return source;
    }

    /** Look for current language translation. */
    let start = source.indexOf(`@${this.currentLocalizationCode}: `);

    /** Fallback to english if translation for current language is missing. */
    if (start < 0) {
      start = source.indexOf('@EN: ');
    }

    /** Fallback to any language if english translation is missing. */
    if (start < 0) {
      start = langTagPos;
    }

    if (start >= 0) {
      source = source.substring(start + 5);
    }

    let end = source.search(langTagRegex);

    if (end >= 0) {
      source = source.substring(0, end);
    }

    return source.trim();
  }


  public getLocalizedStringWithLinks(key: string, ...params: any[]): string {
    const localized = this.getLocalizedString(key, ...params);

    const result = localized.replace(/\[link:.+\|.*\]/g, (match) => {
      const url = match.slice(match.indexOf("|") + 1, -1);
      const text = match.slice(6, match.indexOf("|"));
      const target = url.startsWith("/") ? "" : "target=\"_blank\"";

      return url ? `<a href="${url}" ${target}>${text}</a>` : text;
    });

    return result;
  }


  public initializeLocalizationData(): Promise<boolean> {
    if (this._textResources !== null) {
      return Promise.resolve(true);
    }

    const p = new Promise<boolean>((resolve, reject) => {
      this.processRequest<TextResource[]>(this._http.get(API_URL + "/text-resources"))
        .subscribe(
          data => {
            this._textResources = data;

            resolve(true);
          },
          ex => {
            this.loggingService.logErrorData(ex);

            resolve(false);
          });
    });

    this._authenticationService.user.subscribe(user => {
      if (user === null) {
        this.processRequest<string[]>(this._http.get(API_URL + "/languages"))
          .subscribe(
            data => {
              this._allowedLocalizations = data;
            },
            ex => {
              this.loggingService.logErrorData(ex);
            }
          );
      } else {
        this.processRequest<string[]>(this._http.get(API_URL + "/allowed-localizations"))
          .subscribe(
            data => {
              this._allowedLocalizations = data;
            },
            ex => {
              this.loggingService.logErrorData(ex);
            }
          );
      }
    });


    return p;
  }
}
