import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { BehaviorSubject, Subject } from "rxjs";
import { filter, finalize, map, takeUntil, tap } from "rxjs/operators";

import { Modal, overlayConfigFactory } from "ngx-modialog-7";

import * as NotificationsModel from "../models/notifications.model";
import * as Shared from "../../shared/index";
import { AppNewsfeedMessage } from "../../shared/models/app-newsfeed-message.model";
import { DeleteAllNotificationsDialogComponent } from "./delete-all-notifications-dialog.component";
import { NewsfeedOperations, NewsfeedService } from "../../shared/index";
import { NotificationsService } from "../services/notifications.service";

enum NotificationsComponentOperations {
  LoadingNotifications,
  LoadingNewsfeed
}

@Component({
  selector: Shared.SELECTOR_PREFIX + "-notifications",
  templateUrl: "./notifications.component.html"
})
export class NotificationsComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  exception: Shared.ExceptionInfo = null;
  lastPageIndex = 0;
  newsfeedMessages: AppNewsfeedMessage[] = [];
  notifications: BehaviorSubject<NotificationsModel.Notification[]> = new BehaviorSubject([]);
  selectedMessage = null;
  /**
   * Aktuálne vybraná notifikácie pre zobrazenie detailov.
   */
  selectedNotification: NotificationsModel.Notification = null;
  totalCount = 0;
  currentView = "newsfeed";

  parameters: Shared.EntitiesListDisplaySettings = {
    orderAscending: true,
    orderByFieldName: "",
    pageIndex: 0,
    pageSize: 20
  };


  private _destroy$ = new Subject<void>();
  private _customerDetailId: number = null;
  private _activeOperations = new Set<NotificationsComponentOperations>();

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _modal: Modal,
    private _contextService: Shared.ContextService,
    private _notificationsService: NotificationsService,
    private _newsfeedService: NewsfeedService,
    private _exceptionHandlingService: Shared.ExceptionsHandlerService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  get isBusy(): boolean {
    return this._activeOperations.size > 0;
  }


  ngOnInit() {
    this.initNotificationsStream();
    this.observeNewsfeedActions();
    this.initNewsfeedStream();
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  changeView(view: string) {
    this.currentView = view;
  }


  goToPage(pageIndex: number) {
    this.parameters.pageIndex = pageIndex;

    this.refreshNotifications();
  }


  showNewsfeedMessageContent(message: AppNewsfeedMessage) {
    this.selectedMessage = message;

    if (!message.isRead) {
      this._newsfeedService.markMessagesAsRead([message.messageId]);
    }
  }


  showNotificationDetails(notification: NotificationsModel.Notification) {
    this.selectedNotification = notification;

    // Ak ju doteraz používateľ nečítal, tak ju nastavíme ako prečítanu.
    if (notification.readDateUtc == null) {
      this._notificationsService.markNotificaionAsRead(this._customerDetailId, notification.id)
        .subscribe(
        result => {
          // Nastavíme dummy dátum, momentálne v klientovi neukazujeme, že kedy bola notifikácia prečítaná, iba či bola.
          notification.readDateUtc = new Date();
        }, ex => {
          // Neriešime, iba zalogujeme.
          this.loggingService.logErrorData(ex, "Error marking notification as read.");
        });
    }
  }


  deselectNotification() {
    this.selectedNotification = null;
  }


  markNotificationsAsRead() {
    this._notificationsService.markNotificationsAsRead(this._customerDetailId)
      .subscribe(() => {
        const notifications = this.notifications.getValue();
        const readTime = new Date();

        notifications.forEach(notification => notification.readDateUtc = readTime);
      },
      ex => { });

    this._newsfeedService.markAllAsRead();
  }

  deleteNotifications() {
    this._modal.open(DeleteAllNotificationsDialogComponent, overlayConfigFactory({}))
      .result.then(() => {
        this.refreshNotifications();
      },
      ex => { });
  }


  private initNotificationsStream() {
    this._contextService.currentCustomerDetail.pipe(
      takeUntil(this._destroy$),
      filter(cd => cd != null),
      tap(cd => this._customerDetailId = cd.id)
    ).subscribe(cd => {
      // Zresetujeme stránkovanie a celkový počet záznamov.
      this.parameters.pageIndex = 0;
      this.totalCount = 0;
      this.lastPageIndex = 0;

      this.selectedNotification = null;

      this.refreshNotifications();
    });
  }


  private initNewsfeedStream() {
    this._newsfeedService.messages$.pipe(
      takeUntil(this._destroy$),
      tap(messages => {
        if (this.selectedMessage) {
          this.selectedMessage = messages.find(m => m.messageId === this.selectedMessage.messageId);
        }
      })
    ).subscribe(messages => this.newsfeedMessages = messages, () => { });
  }

  private observeNewsfeedActions() {
    this._newsfeedService.activeOperations$.pipe(
      map(a => a.has(NewsfeedOperations.List))
    ).subscribe(isListLoading => {
      isListLoading ?
        this._activeOperations.add(NotificationsComponentOperations.LoadingNewsfeed) :
        this._activeOperations.delete(NotificationsComponentOperations.LoadingNewsfeed);
    });
  }

  private refreshNotifications() {
    this._activeOperations.add(NotificationsComponentOperations.LoadingNotifications);
    this.exception = null;

    this._notificationsService.getNotificaions(this._customerDetailId, this.parameters).pipe(
      finalize(() => this._activeOperations.delete(NotificationsComponentOperations.LoadingNotifications))
    ).subscribe(notifications => {
      // Kvôli stránkoniu potrebujeme počet všetkých záznamov a tiež index poslednej strany.
      this.totalCount = notifications.totalSize;
      this.lastPageIndex = Math.ceil(this.totalCount / this.parameters.pageSize) - 1;

      this.notifications.next(notifications.items);
    },
    ex => {
      this.loggingService.logErrorData(ex, "Error loading notifications.");
      this.exception = this._exceptionHandlingService.getExceptionInfo(ex);
    });
  }
}
