import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { forkJoin, Subject } from "rxjs";
import { filter, finalize, switchMap, take, takeUntil } from "rxjs/operators";


import * as Shared from "../../shared/index";
import { BusinessUnitSettingsService, PasswordService } from "../../shared/index";
import { UsersService } from "../services/users.service";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-password-change",
  templateUrl: "./password-change.component.html"
})
export class PasswordChangeComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  public exceptionInfo: Shared.ExceptionInfo = null;
  public hasUnchagedPassword = false;
  public isBusy = false;
  public isFirstLogin = false;
  public isOldPassword = false;
  public isUnchangedInitialPasswordAllowed = false;
  public maxAgePassword = 0;
  public newPassword = "";
  public newPasswordConfirmation = "";
  public oldPassword = "";
  public passwordValidationExceptionInfos: Shared.ExceptionInfo[] = [];

  @ViewChild("passwordForm")
  public form: NgForm;

  private _destroy$ = new Subject<void>();


  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _usersService: UsersService,
    private _usersPasswordService: PasswordService,
    private _exceptionHandlerService: Shared.ExceptionsHandlerService,
    private _toastr: ToastrService,
    private _contextService: Shared.ContextService,
    private _businessUnitSettingsService: BusinessUnitSettingsService,
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public ngOnInit(): void {
    this.authenticationService.user.pipe(filter(u => Boolean(u))).pipe(
      takeUntil(this._destroy$)
    ).subscribe(user => {
      this.isFirstLogin = user.isFirstLogin;

      if (user) {
        forkJoin([
          this._usersPasswordService.getHasCurrentUserOldPassword(),
          this._usersPasswordService.getHasCurrentUserUnchangedPassword(),
          this._businessUnitSettingsService.getIsUnchangedInitialPasswordAllowed()
        ]).subscribe(result => {
          [
            this.isOldPassword,
            this.hasUnchagedPassword,
            this.isUnchangedInitialPasswordAllowed
          ] = result;
        });
      }
    });
  }


  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }


  public savePassword() {
    if (this.form.valid) {
      this.isBusy = false;
      this.exceptionInfo = null;
      this.passwordValidationExceptionInfos = [];

      this._contextService.currentCustomerDetail.pipe(
        take(1),
        switchMap(cd => this._usersService.setCurrentUserPassword({
          userId: 0,
          customerDetailId: cd.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        })),
        finalize(() => this.isBusy = false)
      ).subscribe(() => {
        this.form.reset();

        this._toastr.success(this.localizationService.getLocalizedString("password_successfully_changed"));

        this.isOldPassword = false;
        this.hasUnchagedPassword = false;
        this._usersPasswordService.onPasswordChange();
      }, error => {
        this.loggingService.logErrorData(error, "Error changing current user's password.");

        this.passwordValidationExceptionInfos =
            this._usersService.resolvePasswordValidationExceptions(error, this._exceptionHandlerService);

        if (!this.passwordValidationExceptionInfos) {
          this.exceptionInfo = this._exceptionHandlerService.getExceptionInfo(error);
        }
      });
    }

    return false;
  }
}
