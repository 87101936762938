import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { saveAs } from "file-saver";

// RxJs
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, pluck, startWith, switchMap, tap, filter } from 'rxjs/operators';

// Services
import { ExceptionsHandlerService, ContextService, ShipperSettingsService } from '../../shared';
import { ShipmentService } from '../services/shipments.service';
// Services

import * as CustomerModel from "../../shared/models/customer.models";
import { ExportFileListModel } from '../models/shipment.model';
import { forEach } from 'lodash';

const DEFAULT_PAGE_SIZE = 203;

const FILTER_QUERY_BASE = {
  orderByFieldName: 'creationDateTimeUtc',
  orderAscending: true,
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE
}

interface Column {
  id: string;
  field: string;
  type: string | Date;
  filter?: boolean;
  width?: string;
  filterKeys?: any[];
}

interface ArchiveFilter {
  id: string;
  field: string;
  type: string;
  columnId: string;
}

@Component({
  templateUrl: "shipments-archive.component.html",
  host: {
    class: "shipments-archive"
  }
})
export class ShipmentsArchiveComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions$: Subscription = new Subscription();
  private loadShipments$ = new BehaviorSubject<boolean>(null);
  private filtersChanged$ = new BehaviorSubject<boolean>(null);

  public shipments: any[] = [];
  public loadingShipments: boolean = false;

  public filterBase = FILTER_QUERY_BASE;

  public columnsFilter: FormGroup;
  private defaultFilter;
  private savedFilter;

  public isBusy: boolean = false;
  public isEmpty: boolean = false;
  public failed: boolean = false;

  public currentYear = new Date().getFullYear();

  public columns: Column[] = [
    { id: '',                         field: '',                          type: 'clear',  filter: false, width: '40' },
    { id: 'parcelNr',                 field: 'parcel_number',             type: 'string', filter: true,  width: '240' },
    { id: 'parcel_recipient_address', field: 'parcel_recipient_address',  type: 'string', filter: true,  width: '240' },
    { id: 'order_created_on',         field: 'order_created_on',          type: 'date',   filter: true,  width: '240' },
    { id: '',                         field: '',                          type: 'action', filter: false },
  ];

  public filters = [
    { id: 'parcelReference',  field: 'filter',        type: 'string', columnId: 'parcelNr' },
    { id: 'address',          field: 'filter',        type: 'string', columnId: 'parcel_recipient_address'},
    { id: 'dateFrom',         field: 'filter_from',   type: 'date',   columnId: 'order_created_on' },
    { id: 'dateTo',           field: 'filter_to',     type: 'date',   columnId: 'order_created_on' },
  ];

  private paggination = {
    pageIndex: 1,
    pageSize: 20,
    totalSize: 0
  };

  private _tenantId: number = null;
  private _currentAddress: CustomerModel.Address = null;

  public get currentAddress(): CustomerModel.Address {
    return this._currentAddress;
  }

  public get totalShipments(): number {
    return this.shipments.length;
  }

  public get loadingFailed(): boolean {
    return !this.loadingShipments && this.failed && !this.totalShipments;
  }

  public get loadingRows(): boolean {
    return this.loadingShipments && this.totalShipments > 0;
  }

  public get loadingRowsFailed(): boolean {
    return this.loadingShipments && this.failed && this.totalShipments > 0;
  }

  public get noShippments(): boolean {
    return !this.loadingShipments && !this.isBusy && !this.failed && !this.totalShipments && !this.hasActiveFilters;
  }

  public get noMatchingFilter(): boolean {
    return !this.loadingShipments && !this.isBusy && !this.failed && !this.totalShipments && this.hasActiveFilters;
  }

  public get hasActiveFilters(): boolean {
    return Object.values(this.columnsFilter.value).some(key => (key !== null && key !== ''));;
  }

  @ViewChild( 'header', { static: true } ) header: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private _shipmentsService: ShipmentService,
    private _contextService: ContextService,
    private _exceptionHandlerService: ExceptionsHandlerService,
    private _shipperSettingsService: ShipperSettingsService,
  ) {
  }

  public ngOnInit(): void {
    this.isBusy = true;

    this.initFilter();
    this.clearTable();
    this.initCustomerDetailsStream();
  }

  public ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  public ngAfterViewInit(): void {
  }

  private initFilter(): void {
    const columnsFilter = this.filters.reduce((obj, filter) => Object.assign(obj, { [filter.id]: '' } ), {} );

    this.defaultFilter = {
      ...columnsFilter,
    };

    this.columnsFilter = this._formBuilder.group({...columnsFilter, ...this.savedFilter});
  }

  private initDataStream(): void {
    this.subscriptions$.add(
      combineLatest([
        this.loadShipments$,
        this.filtersChanged$.pipe(
          tap(value => {
            this.columnsFilter.get("dateFrom").setErrors(null);
            this.columnsFilter.get("dateTo").setErrors(null);
            this.loadingShipments = true;
          }),
          debounceTime(600),
          tap(value => {
              this.isBusy = true;
              this.clearTable();
              this.resetPaggination();
          }),
        )
        // this.columnsFilter.valueChanges.pipe(
        //   tap(value => {
        //     this.loadingShipments = true;
        //   }),
        //   startWith(this.columnsFilter.value),
        //   distinctUntilChanged(),
        //   debounceTime(2000),
        //   tap(value => {
        //     this.isBusy = true;
        //   }),
        //   tap(value => {
        //     this.clearTable();
        //     this.resetPaggination();
        //   }),
        // )
      ]).pipe(
        map(([refresh]) => {
          this.failed = false;

          const customerParams = {
            customerDetailId: this._currentAddress.customerDetailId,
            senderCustomId: this._currentAddress.customId,
          };

          const paggination = {...this.paggination};
          delete paggination.totalSize;

          return {
            ...customerParams,
            ...this.columnsFilter.value,
            ...paggination,
          };
        }),
        switchMap((filter) => {
          return this._shipmentsService.searchArchivedShipments( filter).pipe(
            catchError( error => {
              this.failed = true;

              return of({
                ...this.paggination,
                items: []
              });
            }),
          )
        }),
        tap((response) => {
          this.isBusy = false;
          this.loadingShipments = false;

          this.paggination = {
            ...this.paggination,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalSize: response.totalSize
          }
        }),
        pluck('items'),
      ).subscribe((shipments: any[]) => {
        this.shipments = [
          ...this.shipments,
          ...shipments
        ];
      })
    )
  }

  /**
   * Required as a part of filter for CSV export
   */
  private initCustomerDetailsStream(): void {
    this.subscriptions$.add(
      combineLatest([
        this._contextService.tenantId,
        this._contextService.currentAddress,
      ]).pipe(
        filter(value => value[0] != null && value[1] != null),
        tap( (value: [number, CustomerModel.Address]) => {
          [
            this._tenantId,
            this._currentAddress,
          ] = value;

          this.initDataStream();
        })
      ).subscribe()
    )
  }

  public clearTable(): void {
    this.shipments = [];
  }

  /**
   * Resets filter by patching `FormGroup` values with defaults.
   */
  public clearColumnsFilter(): void {
    this.columnsFilter.patchValue({ ...this.defaultFilter });
    this.filtersChanged$.next(true);
  }

  public resetPaggination(): void {
    this.paggination = {
      pageIndex: 1,
      pageSize: 20,
      totalSize: 0
    };
  }

  public loadShipments(): void {

    if ( !this.loadingShipments && this.totalShipments < this.paggination.totalSize ) {

      if ( Math.ceil(this.paggination.totalSize / this.paggination.pageSize) > this.paggination.pageIndex ) {
        this.loadingShipments = true;
        this.paggination.pageIndex =  this.paggination.pageIndex + 1;
        this.loadShipments$.next(true);
      }
    }
  }

  public reloadShipments(): void {

    if ( !this.loadingShipments ) {
      this.loadingShipments = true;
      this.loadShipments$.next(false);
    }
  }

  public exportToCSV(): void {

    const dateRangeFilter = {
      ...this.columnsFilter.value,
    }
    delete dateRangeFilter.parcelReference;
    delete dateRangeFilter.address;

    this.validateExportFilter(['dateFrom', 'dateTo']);

    if ( !this.columnsFilter.valid ) {
      return;
    }

    const customerParams = {
      customerDetailId: this._currentAddress.customerDetailId,
      senderCustomId: this._currentAddress.customId,
    };

    const exportFilter = {
      customerDetailId: this._currentAddress.customerDetailId,
      tenantId: this._tenantId,
      daysOffset: 365,
      exportAllShipments: false,
      manualExportShipmentsSetType: null,
      orderIds: null,
      parcelIds: null,
      rememberShipmentsWereExported: false,
      senderCustomId: this._currentAddress.customId,
      csvColumnDelimiter: null,
      csvRowDelimiter: null,
      importProfileId: null,
      defaultProductId: null,
      ...dateRangeFilter
    };

    this.isBusy = true;
    this._shipmentsService.exportArchivedShipments(exportFilter).subscribe( (exportModel: ExportFileListModel) => {
      this.isBusy = false;

      if (exportModel) {
        for (let i = 0; i < exportModel.outputFile.length; i++) {
          let file = exportModel.outputFile[i];
          if (file.outputText && file.outputText.length > 0) {
            let blob = new Blob(["\ufeff", file.outputText], { type: "text/csv;charset=utf-8" });
            saveAs(blob, file.outputName);
          }
        }
      }
    });
  }

  public purgeShipments(): void {
    this.isBusy = true;

    const customerParams = {
      customerDetailId: this._currentAddress.customerDetailId,
      senderCustomId: this._currentAddress.customId,
    };

    this._shipmentsService.purgeArchivedShipments( customerParams ).pipe(
      catchError( (error) => {
        this.isBusy = false;
        return of();
      }),
      tap( (response) => {
        this.isBusy = false;
      }),
    ).subscribe();
  }

  public scrollGridContent(event): void {

    if (Math.ceil(event.srcElement.scrollHeight / 2) < event.srcElement.scrollTop) {
      this.loadShipments()
    }

    if ( !this.header ) {
      return;
    }

    this.header.nativeElement.scrollLeft = event.srcElement.scrollLeft;
  }

  private validateExportFilter( filterKeys: string[] ): void {

    if (filterKeys.length) {

      filterKeys.forEach((key) => {

        if ( !this.columnsFilter.get(key).value || this.columnsFilter.get(key).value.toString().trim() === "" ) {
          this.columnsFilter.get(key).setErrors({required: true});
        }
      });
    }
  }

  public onFiltersChanged(event): void {
    if ( this.columnsFilter.pristine ) {
      return;
    }

    this.filtersChanged$.next(true);
  }

  get isCentral() {
    return this._shipperSettingsService.isCentralShipper;
  }
}
