<shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
<div class="top">
  <h1>{{ 'pickup_orders' | shpLocalize }}</h1>
  <button id="button-back-top" class="top-button" (click)="backToList()"><span class="ic-arrow-left-1"></span><span class="label">{{ 'back' | shpLocalize }}</span></button>
</div>
<div class="content-panel pickup-order-editor" wizardTag="pickupEditor">
  <form *ngIf="model && (isNew || isRegular || isInPreparation) && !isInConfirmMode" [formGroup]="form" class="data" cdkScrollable wizardScrollable>
      <div class="panels group">
      <div class="panel" wizardTag="pickupEditorPickupAddress">
        <h2>{{ 'pickup_address' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-name" [value]="pickupAddress.name1" type="text" name="customer-name" pInputText [readonly]="true">
              <label for="customer-name">{{ 'address_name' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-name-2" [value]="pickupAddress.name2" type="text" name="customer-name-2" pInputText [readonly]="true">
              <label for="customer-name-2">{{ 'address_name' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-street" [value]="(pickupAddress.street + ' ' + pickupAddress.houseNr )" type="text" name="customer-street" pInputText [readonly]="true">
              <label for="customer-street">{{ 'address_street' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-zip" [value]="pickupAddress.zip" type="text" name="customer-zip" pInputText [readonly]="true">
              <label for="customer-zip">{{ 'address_zip' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-city" [value]="pickupAddress.place" type="text" name="customer-city" pInputText [readonly]="true">
              <label for="customer-city">{{ 'address_city' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="panel" wizardTag="pickupEditorContactPerson">
        <h2>{{ 'pickup_order_contact' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-autoComplete id="contact-person" formControlName="contactPerson" [suggestions]="_contactPersons" (completeMethod)="filterContactPerson($event)" (onSelect)="contactPersonSelected($event)" name="contact-person" [minLength]="2" [maxlength]="35" autocomplete="none" required>
                <ng-template let-person pTemplate="item"><div>{{ person.name }}</div></ng-template>
              </p-autoComplete>
              <label for="contact-person">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
            </span>
            <em class="error" *ngIf="form.controls['contactPerson'].hasError('maxlength')">{{'input_max_allowed_characters' | shpLocalize:form.controls['contactPerson'].errors.maxlength.requiredLength }}</em>
          </div>

        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="email" formControlName="email" type="text" name="email" [maxlength]="50" pInputText>
              <label for="email">{{ 'email' | shpLocalize }}</label>
            </span>
            <em class="error" *ngIf="form.controls['email'].hasError('maxlength')">{{'input_max_allowed_characters' | shpLocalize:form.controls['email'].errors.maxlength.requiredLength }}</em>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="phone" formControlName="phone" type="text" name="phone" pInputText>
              <label for="phone">{{ 'phone' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="panel" *ngIf="!isRegular" wizardTag="pickupEditorDetails">
        <h2>{{ 'pickup_order_details' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-calendar *ngIf="!isInPreparation" formControlName="fromDate" [showIcon]="true" inputId="pickup-date" dateFormat="dd/mm/yy" (onMonthChange)="onPickupMonthChanged($event)" [disabledDates]="_unavailableDays" [disabledDays]="disabledDays" [minDate]="minDate" icon="ic-calendar-1" name="pickupDate"></p-calendar>
              <p-calendar *ngIf="isInPreparation" formControlName="fromDate" [showIcon]="true" inputId="pickup-date" dateFormat="dd/mm/yy" (onMonthChange)="onPickupMonthChanged($event)" [disabledDates]="_unavailableDays" [disabledDays]="disabledDays" [minDate]="form.get('fromDate').value" icon="ic-calendar-1" name="pickupDate"></p-calendar>
              <label for="pickup-date">{{ 'shipment_order_pickup_date' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl-msg-invalid" *ngIf="form.get('fromDate').errors && form.get('fromDate').dirty && form.get('fromDate').errors.invalid">
          {{ 'pickup_order_invalid_date' | shpLocalize }}
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber formControlName="parcelsCount" [min]="1" [step]="1" [showButtons]="true" [maxFractionDigits]="0"
                name="parcels-count" inputId="parcels-count"
                mode="decimal"
                decrementButtonClass="p-button-secondary"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="chevron-up"
                decrementButtonIcon="chevron-down"
              ></p-inputNumber>
              <label for="parcels-count">{{ 'shipment_order_parcels_count' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <textarea id="note" formControlName="note" name="note" rows="3" pInputTextarea></textarea>
              <label for="note">{{ 'note' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl" *ngIf="cityserviceAvailable">
          <input type="checkbox" formControlName="isSameDay" id="isSameDay" />
          <label for="isSameDay">CityService</label>
          <!-- <label for="isSameDay">&nbsp;</label> -->
        </div>
      </div>
      <div class="panel" *ngIf="isRegular">
        <h2>{{ 'pickup_order_details' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-calendar formControlName="fromDate" [showIcon]="true" [minDate]="minDate" inputId="pickup-date" dateFormat="dd/mm/yy" name="pickup-date"></p-calendar>
              <label for="pickup-date">{{ 'configuration_valid_from' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <textarea id="note" formControlName="note" name="note" rows="3" pInputTextarea></textarea>
              <label for="note">{{ 'note' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl" *ngIf="cityserviceAvailable">
          <input type="checkbox" formControlName="isSameDay" id="isSameDay" />
          <label for="isSameDay">CityService</label>
          <!-- <label for="isSameDay">&nbsp;</label> -->
        </div>
        <div class="ctl weekdays">
          <label>{{ 'delivery_window_days' | shpLocalize }}</label>
          <input type="checkbox" formControlName="monday" id="monday" />
          <label for="monday">{{ 'weekday_short_1' | shpLocalize  }}</label>
          <input type="checkbox" formControlName="tuesday" id="tuesday" />
          <label for="tuesday">{{ 'weekday_short_2' | shpLocalize  }}</label>
          <input type="checkbox" formControlName="wednesday" id="wednesday" />
          <label for="wednesday">{{ 'weekday_short_3' | shpLocalize  }}</label>
          <input type="checkbox" formControlName="thursday" id="thursday" />
          <label for="thursday">{{ 'weekday_short_4' | shpLocalize  }}</label>
          <input type="checkbox" formControlName="friday" id="friday" />
          <label for="friday">{{ 'weekday_short_5' | shpLocalize  }}</label>
          <input type="checkbox" formControlName="saturday" id="saturday" />
          <label for="saturday">{{ 'weekday_short_6' | shpLocalize  }}</label>
        </div>

        <div class="ctl">
          <label for="from">{{ 'requested_pickup_time' | shpLocalize  }}</label>
          <select formControlName="from" (change)="updateActiveHourRange()">
            <option *ngFor="let hourFrom of hoursFrom" [value]="hourFrom.hour" [disabled]="hourFrom.disabled">{{ formatHour(hourFrom.hour) }}</option>
          </select>
          &nbsp;
          <select formControlName="to" (change)="updateActiveHourRange()">
            <option *ngFor="let hourTo of hoursTo" [ngValue]="hourTo.hour" [disabled]="hourTo.disabled">{{ formatHour(hourTo.hour) }}</option>
          </select>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="model && ((!isNew && !isRegular && (!isInPreparation || (isInConfirmMode && isInPreparation))) || isInConfirmMode)" class="data">
    <div class="panels group">
      <div class="panel wide warning" *ngIf="isNew || isInPreparation">{{ 'pickup_order_review_before_save_message' | shpLocalize }}</div>
      <div class="panel">
        <h2>{{ 'pickup_address' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-name" [value]="pickupAddress.name1" type="text" name="customer-name" pInputText [readonly]="true">
              <label for="customer-name">{{ 'address_name' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-name-2" [value]="pickupAddress.name2" type="text" name="customer-name-2" pInputText [readonly]="true">
              <label for="customer-name-2">{{ 'address_name' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-street" [value]="(pickupAddress.street + ' ' + pickupAddress.houseNr )" type="text" name="customer-street" pInputText [readonly]="true">
              <label for="customer-street">{{ 'address_street' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-zip" [value]="pickupAddress.zip" type="text" name="customer-zip" pInputText [readonly]="true">
              <label for="customer-zip">{{ 'address_zip' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="customer-city" [value]="pickupAddress.place" type="text" name="customer-city" pInputText [readonly]="true">
              <label for="customer-city">{{ 'address_city' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="panel">
        <h2>{{ 'pickup_order_contact' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="contact-person" value="{{ model.pickupOrder.contactPerson }}" type="text" name="contact-person" pInputText readonly>
              <label for="contact-person">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="email" value="{{ model.pickupOrder.email }}" type="text" name="email" pInputText readonly>
              <label for="email">{{ 'email' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="phone" value="{{ model.pickupOrder.phone }}" type="text" name="phone" pInputText readonly>
              <label for="phone">{{ 'phone' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="panel" *ngIf="!isRegular">
        <h2>{{ 'pickup_order_details' | shpLocalize }}</h2>
        <div class="ctl" *ngIf="model.pickupOrder.stateId && !isNew">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="state" [value]="( 'pickup_order_state_' + model.pickupOrder.stateId )" type="text" name="state" pInputText [readonly]="true">
              <label for="state">{{ 'state' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-calendar [(ngModel)]="model.pickupOrder.fromDate" [showIcon]="false" [readonlyInput]="true" [disabled]="true" inputId="pickup-date" dateFormat="dd/mm/yy" name="pickup-date"></p-calendar>
              <label for="pickup-date">{{ 'shipment_order_pickup_date' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="parcels-count" [value]="model.pickupOrder.parcelsCount" type="number" name="state" pInputText [readonly]="true">
              <label for="parcels-count">{{ 'shipment_order_parcels_count' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <textarea id="note" [value]="model.pickupOrder.note" name="note" rows="3" pInputTextarea [readonly]="true"></textarea>
              <label for="note">{{ 'note' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl" *ngIf="cityserviceAvailable">
          <label for="cityservice">CityService</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="isCityService"></span>
            <span class="cross-mark" *ngIf="!isCityService"></span>
          </div>
        </div>
      </div>
      <div class="panel" *ngIf="isRegular">
        <h2>{{ 'pickup_order_details' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-calendar [(ngModel)]="model.pickupOrder.fromDate" [showIcon]="false" [readonlyInput]="true" [disabled]="true" inputId="pickup-date" dateFormat="dd/mm/yy" name="pickup-date"></p-calendar>
              <label for="pickup-date">{{ 'configuration_valid_from' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <textarea id="note" [value]="model.pickupOrder.note" name="note" rows="3" pInputTextarea [readonly]="true"></textarea>
              <label for="note">{{ 'note' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl" *ngIf="cityserviceAvailable">
          <label for="cityservice">CityService</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="isCityService"></span>
            <span class="cross-mark" *ngIf="!isCityService"></span>
          </div>
        </div>
        <div class="ctl weekdays">
          <label>{{ 'delivery_window_days' | shpLocalize }}</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="selectedDays[1]"></span>
            <span class="cross-mark" *ngIf="!selectedDays[1]"></span>
          </div>
          <label for="monday">{{ 'weekday_short_1' | shpLocalize  }}</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="selectedDays[2]"></span>
            <span class="cross-mark" *ngIf="!selectedDays[2]"></span>
          </div>
          <label for="tuesday">{{ 'weekday_short_2' | shpLocalize  }}</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="selectedDays[3]"></span>
            <span class="cross-mark" *ngIf="!selectedDays[3]"></span>
          </div>
          <label for="wednesday">{{ 'weekday_short_3' | shpLocalize  }}</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="selectedDays[4]"></span>
            <span class="cross-mark" *ngIf="!selectedDays[4]"></span>
          </div>
          <label for="thursday">{{ 'weekday_short_4' | shpLocalize  }}</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="selectedDays[5]"></span>
            <span class="cross-mark" *ngIf="!selectedDays[5]"></span>
          </div>
          <label for="friday">{{ 'weekday_short_5' | shpLocalize  }}</label>
          <div class="fake-checkbox">
            <span class="check-mark" *ngIf="selectedDays[6]"></span>
            <span class="cross-mark" *ngIf="!selectedDays[6]"></span>
          </div>
          <label for="saturday">{{ 'weekday_short_6' | shpLocalize  }}</label>
        </div>

        <div class="ctl">
          <label for="from">{{ 'requested_pickup_time' | shpLocalize  }}</label>
          <select [value]="selectedHourFrom" [disabled]="true" (change)="updateActiveHourRange()">
            <option *ngFor="let hourFrom of hoursFrom" [value]="hourFrom.hour" [disabled]="hourFrom.disabled">{{ formatHour(hourFrom.hour) }}</option>
          </select>
          &nbsp;
          <select [value]="selectedHourTo" [disabled]="true" (change)="updateActiveHourRange()">
            <option *ngFor="let hourTo of hoursTo" [value]="hourTo.hour" [disabled]="hourTo.disabled">{{ formatHour(hourTo.hour) }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="commands toolbar">
    <button id="button-confirmation" *ngIf="(isNew || isRegular || isInPreparation) && !isInConfirmMode" [disabled]="!form.valid || (isRegular && !someWeekDayIsChecked)"  (click)="showConfirmation()"><span class="ic-arrow-right-1"></span><span class="label">{{ 'pickup_order_continue' | shpLocalize }}</span></button>
    <button id="button-save" *ngIf="(isNew || isRegular || isInPreparation) && isInConfirmMode" (click)="save()"><span class="ic-floppydisk"></span><span class="label">{{ 'save' | shpLocalize }}</span></button>
    <button id="button-back" *ngIf="(isNew || isRegular || isInPreparation) && isInConfirmMode" (click)="back()"><span class="ic-arrow-left-1"></span><span class="label">{{ 'back' | shpLocalize }}</span></button>
  </div>
</div>
