<form novalidate (submit)="save()">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category">
    <div class="settings-wrapper settings-wrapper--full printer-rules">
      <h1>{{ 'settings_printer_rules' | shpLocalize }}</h1>
      <div>
        <shp-printer-rules-editor [printingTypeCode]="'Protocol'"
                                  [printerRules]="protocolsPrinterRules"
                                  [printers]="printers | async"
                                  [printingTypes]="printingTypes | async"
                                  [printerTypes]="printerTypes | async"
                                  [printerLanguages]="printerLanguages | async"
                                  [paperSizes]="paperSizes | async"
                                  [importPaths]="importPaths | async"
                                  [users]="users | async"
                                  [products]="products | async"
                                  [addresses]="addresses | async"
                                  [tenantId]="tenantId"
                                  [customerDetailId]="customerDetailId"
                                  [customerDetails]="customerDetails | async"></shp-printer-rules-editor>
        <shp-printer-rules-editor [printingTypeCode]="'Label'"
                                  [printerRules]="labelsPrinterRules"
                                  [printers]="printers | async"
                                  [printingTypes]="printingTypes | async"
                                  [printerTypes]="printerTypes | async"
                                  [printerLanguages]="printerLanguages | async"
                                  [paperSizes]="paperSizes | async"
                                  [importPaths]="importPaths | async"
                                  [users]="users | async"
                                  [products]="products | async"
                                  [addresses]="addresses | async"
                                  [tenantId]="tenantId"
                                  [customerDetailId]="customerDetailId"
                                  [customerDetails]="customerDetails | async"></shp-printer-rules-editor>
    </div>
    </div>
  </div>>
</form>