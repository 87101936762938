import { Directive, Optional, ElementRef, HostListener, Self, Input } from '@angular/core';

import * as Shared from "../index"
import { NgControl } from '@angular/forms';

interface formatterFn {
  (value: any): any
}

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}formatOnBlur]`
})
export class FromatInputDirective {
  @Input(`${Shared.SELECTOR_PREFIX}formatOnBlur`) formatter: formatterFn;

  constructor(
      @Optional() @Self() private ngControl: NgControl,
      private elementRef: ElementRef) {
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    if (typeof this.formatter !== "function") {
      return;
    }

    if (this.ngControl) {
      this.ngControl.control.setValue(
        this.formatter(this.ngControl.value), {
          onlySelf: true,
          emitEvent: false
        }); 
    } else {
      /** @todo implement formatting for common HTML input. */
    }

  }
}