import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import * as _ from "lodash";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Subject } from "rxjs";
import { filter, finalize, map, take, takeUntil, tap } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import { ShipperWizardService } from "../../shared/services/shipper-wizard.service";
import { ImportProfileContainer, ImportProfileField } from "../models/import-profiles.models";
import { ImportProfilesService } from "../services/import-profiles.service";
import { Part1EditorComponent } from "./part1.editor.component";
import { Part2EditorComponent } from "./part2.editor.component";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-dynamic-import-editor",
  templateUrl: "./editor.component.html",
  styleUrls: []
})
export class EditorComponent extends Shared.RoutedPageComponentBase implements OnInit, AfterViewInit, OnDestroy {

  public storeId: number = null;
  public isBusy = false;

  public typePrefix: string;
  public type: string;

  @ViewChild("f") public form: NgForm;
  @ViewChild("part1editor") public part1editor: Part1EditorComponent;
  @ViewChild("part2editor") public part2editor: Part2EditorComponent;

  public profile: ImportProfileContainer = {} as ImportProfileContainer;

  public importProfileId;

  public customers = [];

  public showPart = 1;

  public isCustomerLevelAccess = false;

  /**
   * groups for part2
   */
  public editorGroups = [];

  /**
   * keep loaded groups no changed,
   * to compare if is change
   */
  public editorInitialGroups = [];

  /**
   * selector values in part2
   */
  public editorValues: Array<string> = [];

  /**
   * Názov aktuálne vybraného súboru z disku.
   */
  public selectedFileName = "";

  public currentCustomerDetailId: number;
  public tenantId: number;

  public viewOnly = false;


  /**
   * JS objekt reprezentujúci údaje o vybranom súbore.
   */
  private _selectedFile: File = null;

  public groupListWithMissingFields: any = null;

  public wizardStepNames = WizardStepName;

  private _destroy$ = new Subject<void>();

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    private _contextService: Shared.ContextService,
    private _exceptionHandlerService: Shared.ExceptionsHandlerService,
    router: Router,
    private _importProfilesService: ImportProfilesService,
    private _modal: Modal,
    private route: ActivatedRoute,
    private _toastr: ToastrService,
    private _wizardService: WizardService,
    private _shipperWizardService: ShipperWizardService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }

  get isWizardActive(): boolean {
    return this._wizardService.isActive;
  }

  get isSaveButtonEnabledForWizard(): boolean {
    return this._wizardService.isOnStep(WizardStepName.ClickSaveImportProfile);
  }

  public goBackToList() {
    this.router.navigate(["/import-profiles/" + this.typePrefix + "/list"]);
  }


  public ngOnInit() {
    this._importProfilesService.currentImportProfile.subscribe(importProfile => {
      this.type = importProfile.name;
      this.typePrefix = importProfile.prefix;
    });
    // 1. get all customers
    // 2. get current customer - for new object
    // 3. register for change route - get id of profile
    // 4. load data for id, or empty and use current customer
    this.loadCustomersAndLoadCurrentCustomer();
  }

  public ngAfterViewInit() {
    this._wizardService.state$.pipe(
      takeUntil(this._destroy$),
    ).subscribe(() => {
      const mappingSteps = [
        WizardStepName.ImportProfileMappingFile,
        WizardStepName.ImportProfileMappingRecipient,
        WizardStepName.ImportProfileMappingParcels,
        WizardStepName.ClickSaveImportProfile
      ];

      this.showPart = this._wizardService.isOnStep(mappingSteps) ? 2 : 1;
    });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  public registerForRouteParamsChangedAndLoadData() {

    this.route.params.subscribe(params => {
      this.importProfileId = params.importProfileId;
      this.loadData();
    });
  }

  public loadCustomersAndLoadCurrentCustomer() {
    this._contextService.customerDetails.subscribe(customers => {
      this.customers = customers;
      this.loadCurrentCustomerAndRegisterForRouteParamsChanged();
    });
  }

  public loadCurrentCustomerAndRegisterForRouteParamsChanged() {
    combineLatest([
      this._contextService.currentCustomerDetail.pipe(filter(cd => cd != null)),
      this._contextService.tenantId.pipe(filter(tid => tid != null)),
      this._contextService.isCustomerLevelTenant$.pipe(take(1)),
      this.authenticationService.isAdmin$.pipe(take(1))
    ]).subscribe(result => {
      // Zresetujeme str�nkovanie a celkov� po�et z�znamov.
      this.currentCustomerDetailId = result[0].id;
      this.tenantId = result[1];
      this.isCustomerLevelAccess = result[2] && !result[3];
      this.registerForRouteParamsChangedAndLoadData();
    });
  }


  public loadData() {
    if (this.importProfileId) {
      this._importProfilesService.loadImportProfile(this.importProfileId).subscribe((importProfile: ImportProfileContainer) => {
        this.processLoadedData(importProfile);

        // Musíme nastavenie ako pristine volať takto, aby sa to vykonalo až po update hodnôt.
        window.setTimeout(() => {
          this.doPristine();
        }, 100);
      });
    } else {
      // is new, so just get the structure from server
      this._importProfilesService.emptyImportProfile(this.type).subscribe((importProfile: ImportProfileContainer) => {
        // prefill the customer in view of editor1
        importProfile.customerDetailId = this.currentCustomerDetailId;
        importProfile.tenantId = this.tenantId;
        this.processLoadedData(importProfile);

        // Musíme nastavenie ako pristine volať takto, aby sa to vykonalo až po update hodnôt.
        window.setTimeout(() => {
          this.doPristine();
        }, 100);
      });
    }

  }


  public cloneEditorGroupsToInitalGroups() {

    this.editorInitialGroups = [];
    if (this.profile && this.profile.groups) {

      this.profile.groups.forEach((group) => {

        const cgroup = _.clone(group);

        cgroup.fieldMappings = [];
        group.fieldMappings.forEach((field) => {

          const cfield = _.clone(field);
          cgroup.fieldMappings.push(cfield);
        });

        this.editorInitialGroups.push(cgroup);

      });
    }
  }

  public processLoadedData(importProfile: ImportProfileContainer) {

    // ak je profil globalny, nepovolime jeho editaciu
    this.viewOnly = importProfile.tenantId ? false : true;

    this.profile = importProfile;

    // delimitering
    if (this.profile.csvRowDelimiter == null) {
      this.profile.csvRowDelimiter = "";
    }

    // delimitering
    if (this.profile.csvColumnDelimiter == null) {
      this.profile.csvColumnDelimiter = "";
    }

    if (this.profile.csvDecimalSeparator == null) {
      this.profile.csvDecimalSeparator = "";
    }

    if (this.profile.csvMultipleValuesDelimiter == null) {
      this.profile.csvMultipleValuesDelimiter = "";
    }

    if (this.profile.encoding == null) {
      this.profile.encoding = "";
    }

    this.editorGroups = this.profile.groups;
    // keep data in unchanged state for compare if the main data changed
    this.cloneEditorGroupsToInitalGroups();

    this.editorValues = this._importProfilesService.generateEditGroupsFromFieldMappings(this.editorGroups);
  }


  public onDelete() {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("import_profiles_delete_confirmation_question_detail"))
      .open()
      .result.then(
        value => {
          if (value) {

            this._importProfilesService.doDelete(this.importProfileId).subscribe(() => {
              // Nechceme notifik�ciu o pr�padn�ch zmen�ch.
              this.hasUnsavedChanges = () => false;
              this.router.navigate(["import-profiles/" + this.typePrefix + "/list"]);
              this._toastr.success(this.localizationService.getLocalizedString("import_profiles_deleted"));
            },
              ex => {
                this.isBusy = false;
                this._toastr.error(this.localizationService.getLocalizedString("import_profiles_delete_failed"));

                this.loggingService.logErrorData(ex, "Recipient delete failed");
              });
          }
        });
  }

  public onCheck() {

  }

  public doPristine = (): void => {
    // keep data in unchanged state for compare if the main data changed
    this.cloneEditorGroupsToInitalGroups();

    this.part1editor.doPristine();
    this.part2editor.doPristine();
  }

  public hasUnsavedChanges = (): boolean => {
    if (this._wizardService.isActive) {
      return false;
    }

    let part1hasUnsavedChanges;
    let part2hasUnsavedChanges;

    if (this.part1editor && this.part2editor) {
      part1hasUnsavedChanges = this.part1editor.hasUnsavedChanges();
      part2hasUnsavedChanges = this.part2editor.hasUnsavedChanges();
    }

    // console.warn(this.part1editor);
    // console.warn(this.part2editor);

    return part1hasUnsavedChanges || part2hasUnsavedChanges;
  }

  public formInvalid() {
    const part1formInvalid = this.part1editor?.formInvalid() ?? true;
    const part2formInvalid = this.part2editor?.formInvalid() ?? true;

    const result = !this.form?.form.valid || part1formInvalid || part2formInvalid;

    return result;
  }

  public onShowPart(partId) {
    this.showPart = partId;
  }

  public onFileChanged(fileData) {
    this._selectedFile = fileData.file;
    this.selectedFileName = fileData.fileName;

    const catFileName = this.selectedFileName.split(".");
    const fileType = catFileName[catFileName.length - 1].toLowerCase();

    this.import(fileType);
  }

  public import = (fileType) => {
    if (this._selectedFile == null) {
      return;
    }

    this.isBusy = true;

    // Default posielame UTF-8 ako encoding, snáď to nenarobí paseku. Ak je to problém, treba pridať
    // do UI špecifikovanie kódovanie súboru.
    this._importProfilesService.convertFileToCSVString(this._selectedFile, this.selectedFileName, "utf-8").subscribe((converted) => {

      // parse text to data model
      this._importProfilesService.parseCSV(converted[0], {
        header: this.profile.fileIncludesHeader,
        delimiter: this.profile.csvColumnDelimiter,
        delimitersToGuess: [";", "|", "\"", "\t"]
      }).subscribe((csvData) => {
        // convert data to fields names
        this.importRunner(csvData);
      });

      this.isBusy = false;
    }, () => {
      this.isBusy = false;
    });

  }

  private importRunner = (parsed: any) => {

    // do not clean the editorValues
    // user already could have setup some fields
    // then he try uppload test file again
    // and we arrase his previous setting
    if (!this.editorValues || !this.editorValues.length) {
      this.editorValues = [];

      // user have to option select nothing
      // othervise is not change keep konfig empty
      this.editorValues.push("");
    }


    if (parsed.data && parsed.data.length > 0) {

      for (const dataName in parsed.data[0]) {

        // because we didn't clean whole Array
        // add please just field what are not present
        const dataNameAlreadyPresent = this.editorValues.some(x => x == dataName);

        if (!dataNameAlreadyPresent) {
          this.editorValues.push(dataName);
        }
      }

      // force change detection.
      this.editorValues = [...this.editorValues];
    }

    // NOTE: don't change user setting
    // because user will upload file from part2
    // but this user setting is on part1
    // and maybe he would like to keep (automatic) in both setting
    // --
    // this.profile.csvColumnDelimiter = parsed.meta.delimiter;
    // this.profile.csvRowDelimiter = parsed.meta.linebreak;

  }


  private checkIfSomeMissing() {
    let anymissing: boolean = false;

    if (!this.profile.name) {
      this.profile["name_missing__"] = true;
      anymissing = true;
    } else {
      delete this.profile["name_missing__"];
    }

    if (!this.profile.code) {
      this.profile["code_missing__"] = true;
      anymissing = true;
    } else {
      delete this.profile["code_missing__"];
    }

    if (!this.profile.csvDecimalSeparator) {
      this.profile["decimalSeparator_missing__"] = true;
      anymissing = true;
    } else {
      delete this.profile["decimalSeparator_missing__"];
    }

    if (!this.profile.csvMultipleValuesDelimiter) {
      this.profile["multipleValuesDelimiter_missing__"] = true;
      anymissing = true;
    } else {
      delete this.profile["multipleValuesDelimiter_missing__"];
    }

    this.profile.groups.forEach((group) => {
      let groupMissing: boolean = false;

      group.fieldMappings.forEach((field: ImportProfileField) => {

        let aisNaN = +field.value;
        if (!this.profile.fileIncludesHeader && field.isMandatory && (
          (field.mappingTypeCode === "Field" && isNaN(parseInt(field.value)))
          ||
          (field.mappingTypeCode === "ConstantValue" && (field.value == null || field.value == ""))
        )) {
          // file is maped by index because is not have header
          // take just numeric values of fields
          field.missing__ = true;
          groupMissing = true;
          anymissing = true;
        } else if (field.isMandatory && (field.value == null || field.value == "")) {
          // field is mandatory and not contain any value yet
          field.missing__ = true;
          groupMissing = true;
          anymissing = true;
        } else if (field.missing__) {
          // it seems all is correct
          // but in previous test (when user tryit to save)
          // was set up as missing__
          // now it seems is fixed so
          // for save process delete it!
          delete field.missing__;
        }

      })

      if (groupMissing) {

        // sorry Peter :-)
        group["missing__"] = true;
      } else {
        delete group["missing__"];
      }

    });


    return anymissing;
  }


  /**
   * Return null if nothing missing or list of boolean values
   * the list is size of this.editorGroups and each value
   * represent missing for index of group
   * @example [false, false, true, true, false] - will represent group with index 2,3 have missing, other are fine
   * @return if any missing [false, false, true, true, false]
   */
  public getGroupIndexWhatHaveAnyMandatoryMissing() {
    const missingGroupList = [];
    let anyMissing = false;

    this.editorGroups.forEach((group, groupIndex: number) => {
      // check if any field in group dont have indicator missing
      const hasMissing = group.fieldMappings.some((field) => {
        return field["missing__"];
      });

      missingGroupList.push(hasMissing);

      if (hasMissing) {
        anyMissing = true;
      }
    });

    return anyMissing ? missingGroupList : null;
  }


  private getGroupsEnabledState() {
    const groupsEnabledState: boolean[] = [];

    for (let i = 0; i < this.editorGroups.length; i++) {
      groupsEnabledState.push(this.editorGroups[i].enabled);
    }

    return groupsEnabledState;
  }


  private setGroupsEnabledState(groupsEnabledState: boolean[]) {
    for (let i = 0; i < groupsEnabledState.length; i++) {
      this.editorGroups[i].enabled = groupsEnabledState[i];
    }
  }


  public onSave() {
    if (this.checkIfSomeMissing()) {

      this.groupListWithMissingFields = this.getGroupIndexWhatHaveAnyMandatoryMissing();

      // when is name not present show the part 1 with name
      if (this.profile.name_missing__) {
        this.onShowPart(1);
      } else {

        // in case name is present probably some field missing
        // show the parts with missing
        this.onShowPart(2);
      }

      this._shipperWizardService.importProfileSaveError = "invalid";
      this._wizardService.completeStep(WizardStepName.ClickSaveImportProfile);

      // some of data missing
      // not continue in saving process
      return;
    }


    // if we are here, nothing is missing,
    // three missing fields are not topic of the day
    this.groupListWithMissingFields = null;

    this.isBusy = true;

    if (!this.importProfileId) {
      this._importProfilesService.saveNewImportProfile(this.profile).pipe(
        finalize(() => {
          this.isBusy = false;
          this._wizardService.completeStep(WizardStepName.ClickSaveImportProfile);
        })
      ).subscribe((savedImportProfile: ImportProfileContainer) => {
        this.importProfileId = savedImportProfile.id;
        const groupsEnabledState = this.getGroupsEnabledState();

        this.processLoadedData(savedImportProfile);

        this._shipperWizardService.importProfileSaveError = null;
        this._shipperWizardService.setCreatedImportProfileId(this.importProfileId);

        // Musíme nastavenie ako pristine volať takto, aby sa to vykonalo až po update hodnôt.
        window.setTimeout(() => {
          this.doPristine();
          this.setGroupsEnabledState(groupsEnabledState);
        }, 100);
      }, ex => {
        ex = this._exceptionHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(ex, "Error saving import profile");
        this._toastr.error(`${this.localizationService.getLocalizedString("error_saving_import_profile")}:\n${this.localizationService.getLocalizedExceptionString(ex)}`);
        this._shipperWizardService.importProfileSaveError = ex.key;
      });
    } else {
      this._importProfilesService.updateImportProfile(this.importProfileId, this.profile).pipe(
        finalize(() => {
          this.isBusy = false;
          this._wizardService.completeStep(WizardStepName.ClickSaveImportProfile);
        })
      ).subscribe((savedImportProfile: ImportProfileContainer) => {
        const groupsEnabledState = this.getGroupsEnabledState();

        this.processLoadedData(savedImportProfile);

        this._shipperWizardService.importProfileSaveError = null;

        // Musíme nastavenie ako pristine volať takto, aby sa to vykonalo až po update hodnôt.
        window.setTimeout(() => {
          this.doPristine();
          this.setGroupsEnabledState(groupsEnabledState);
        }, 100);
      }, ex => {
        ex = this._exceptionHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(ex, "Error saving import profile");
        this._toastr.error(`${this.localizationService.getLocalizedString("error_saving_import_profile")}:\n${this.localizationService.getLocalizedExceptionString(ex)}`);
        this._shipperWizardService.importProfileSaveError = ex.key;
      });
    }
  }
}
