<div class="top" *ngIf="isReady">
  <h1>{{ typePrefix + '_import_profiles' | shpLocalize }}</h1>
  <button *ngIf="allowAddingNewImportProfiles" id="button-add" class="top-button" [routerLink]="['/import-profiles/' + typePrefix + '/new']" wizardTag="newImportProfile" [wizardCompleteStep]="wizardStepNames.ClickAddImportProfile"><span class="ic-add-2"></span><span class="label">{{ 'add_import_profile' | shpLocalize}}</span></button>
  <button id="button-import" class="top-button" (click)="import()"><span class="ic-file-download-2"></span><span class="label">{{ 'import' | shpLocalize}}</span></button>
</div>
<div class="content-panel" *ngIf="isReady">
  <div class="grid">
    <div class="grid-header no-filter">
      <table>
        <thead>
          <tr>
            <th class="col-import-profile-name">{{ 'import_profile_edit_name' | shpLocalize }}</th>
            <th class="col-import-profile-customer">{{ 'import_profile_edit_customer' | shpLocalize }}</th>
            <th class="col-import-profile-description">{{ 'import_profile_edit_description' | shpLocalize }}</th>
            <th class="col-commands"></th>
            <th class="col-spacer"></th>
          </tr>
        </thead>
      </table>
      <div class="scrollbar-spacer-cover"></div>
    </div>
    <div class="grid-content">
      <table>
        <tbody>
          <tr *ngFor="let importProfile of data" [class]="setRowColoring(importProfile)">
            <td class="col-import-profile-name">{{ importProfile.name }}</td>
            <td class="col-import-profile-customer">{{ importProfile.customerName }}</td>
            <td class="col-import-profile-description">{{ importProfile.description }}</td>
            <td class="col-commands">
              <button *ngIf="importProfile.tenantId === null" id="button-view" type="button" [routerLink]="['/import-profiles/' + typePrefix + '/view', importProfile.id]"><span class="ic-box-view"></span></button>
              <ng-container *ngIf="importProfile.tenantId !== null">
                <button id="button-edit" type="button" [routerLink]="['/import-profiles/' + typePrefix + '/edit', importProfile.id]" title="{{ 'edit' | shpLocalize }}"><span class="ic-box-edit"></span></button>
                <button id="button-delete" type="button" (click)="deleteImportProfile(importProfile)"><span class="ic-bin" title="{{ 'delete' | shpLocalize }}"></span></button>
                <button id="button-export" type="button" (click)="export(importProfile)"><span class="ic-file-upload-2" title="{{ 'export' | shpLocalize }}"></span></button>
              </ng-container>
            </td>
            <td class="col-spacer"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
