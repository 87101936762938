import { Component, Input } from "@angular/core";
import { CompleterService } from "ng2-completer";

import * as Shared from "../../shared/index";

import * as _ from "lodash";
import { CustomClearanceService } from "../services/custom-clearance.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-custom-clearance-miniportal-view",
  templateUrl: "./custom-clearance-miniportal-view.component.html",
  providers: [CompleterService]
})

export class CustomClearanceMiniportalViewComponent {

  @Input("shipmentOrderId")
  public shipmentOrderId: number;

  constructor(
    private _customClearanceService: CustomClearanceService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService
  ) { }


  public miniportalUrl = "";

  public ngOnInit() {
    this._businessUnitSettingsService.getMiniportalUrl()
    .subscribe(value => this.miniportalUrl = value);
  }

  public openMiniportal()
  {
    this._customClearanceService.getMiniportalToken(this.shipmentOrderId).subscribe(token =>{      
      var url = this.miniportalUrl + token;
      window.open(url, '_blank');
    } );    
  }
}