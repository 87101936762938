/**
 * Verzia klientskej aplikácie, treba zachovať synchronizáciu s Dpd.Shipper.Tenant.Data.Globals.Version.
 */
export const APP_VERSION = "2.3.31.0";


/**
 * Používa sa pre rôzne identifikácie prístupnosti príkazov v UI.
 */
export const DEMO_TENANT_CODE = "demo";


export const TRACKING_URL_BASE = "https://tracking.dpd.de/status/{languageCode}/parcel/{parcelNr}";
export const TRACKING_URL_BY_REF_DEFAULT_TEMPLATE = "https://tracking.dpd.de/status/{languageCode}/user/{delisId}/ref/{reference}";


export function getTrackingLocale(languageCode: string): string {
  switch (languageCode) {
    case "EN":
      return "en";

    case "DE":
      return "de";

    case "IT":
      return "it";

    case "CS":
      return "cs";

    case "SK":
      return "sk";

    case "FR":
      return "fr";

    case "BE":
      return "nl";

    case "NL":
      return "nl";

    default:
      return "en";
  }
}

export function getTrackingLanguageIso(languageCode: string): string {
  switch (languageCode) {
    case "EN":
      return "en";

    case "DE":
      return "de";

    case "IT":
      return "it";

    case "CS":
      return "cs";

    case "SK":
      return "sk";

    case "FR":
      return "fr";

    case "BE":
      return "nl";

    case "NL":
      return "nl";

    default:
      return "en";
  }
}

export const BUSINESS_UNIT_CODE_NL = "010";
export const BUSINESS_UNIT_CODE_CZ = "015";
export const BUSINESS_UNIT_CODE_SK = "016";
export const BUSINESS_UNIT_CODE_BE = "034";
export const BUSINESS_UNIT_CODE_LU = "035";
export const BUSINESS_UNIT_CODE_CH = "036";

export const LOGIN_URL = "/login";
export const CHANGE_PASSWORD_URL = "/settings/password";
