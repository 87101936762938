import { Injectable, Inject, Renderer2, RendererFactory2, Injector } from "@angular/core"
import { DOCUMENT } from "@angular/common";
import { ReplaySubject } from "rxjs";

const colorThemeClasses = [
  "theme-default",
  "theme-dark",
  "theme-high-contrast",
  "theme-color-blind",
];

const defaultTheme = "theme-default";

@Injectable()
export class ColorThemeService {

  htmlEl;
  themes: string[] = colorThemeClasses;
  currentTheme: string;
  currentTheme$ = new ReplaySubject<string>(1);

  private _renderer: Renderer2;

  constructor(
      private injector: Injector,
      @Inject(DOCUMENT) private document: Document) {
    this._renderer =
      (injector.get(RendererFactory2) as RendererFactory2).createRenderer(null, null);    

    this.htmlEl = document.getElementsByTagName("html")[0];
  }
  
  
  initTheme() {
    this.currentTheme = localStorage.getItem("color-theme") || defaultTheme;
    this.currentTheme$.next(this.currentTheme);
    this._renderer.addClass(this.htmlEl, this.currentTheme);
  }


  setCurrentTheme(name: string) {
    this._renderer.removeClass(this.htmlEl, this.currentTheme);
    this.currentTheme = name;
    this.currentTheme$.next(this.currentTheme);
    localStorage.setItem("color-theme", name);
    this._renderer.addClass(this.htmlEl, name);
  }
}