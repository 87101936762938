import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { ModalModule } from "ngx-modialog-7";
import { BootstrapModalModule } from "ngx-modialog-7/plugins/bootstrap";

// import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
// import { WjInputModule } from '@grapecity/wijmo.angular2.input';

import { InputTextareaModule } from "primeng/inputtextarea";
import { AutoCompleteModule } from "primeng/autocomplete";
import { MultiSelectModule } from "primeng/multiselect";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { KeyFilterModule } from "primeng/keyfilter";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { TooltipModule } from "primeng/tooltip";
import { ListboxModule } from "primeng/listbox";

import { Ng2CompleterModule } from "ng2-completer";

import { DailyProcedureDialogComponent } from "./components/daily-procedure-dialog.component";
import { ShipmentEditorComponent } from "./components/shipment-editor.component";
import { ShipmentComponent } from "./components/shipment.component";
import { ShipmentsCompactComponent } from "./components/shipments-compact.component";
import { ShipmentsImportDialogComponent } from "./components/shipments-import-dialog.component";
import { ShipmentsArchiveComponent } from "./components/shipments-archive.component";
import { ShipmentsGridComponent } from "./components/shipments-grid.component";
import { ShipmentsListComponent } from "./components/shipments-list.component";
import { ShipmentsComponent } from "./components/shipments.component";
import { SenderScanComponent } from "./components/sender-scan.component";

import { ShipmentService } from "./services/shipments.service";
import { CustomClearanceService } from "./services/custom-clearance.service";
import { SharedModule } from "../shared/shared.module";

import { RecipientViewComponent } from "./components/recipient-view.component";
import { FilterListViewComponent } from "./components/filter-list-view.component";
import { SetPickupDateDialogComponent } from "./components/set-pickup-date-dialog.component";
import { CustomClearanceViewComponent } from "./components/custom-clearance-view.component";
import { CustomClearanceMiniportalViewComponent } from "./components/custom-clearance-miniportal-view.component";
import { DeleteAllShipmentsDialogComponent } from "./components/delete-all-shipments-dialog.component";
import { PrintingPositionSelectorDialogComponent } from "./components/printing-position-selector-dialog.component";
import { AcceptanceProtocolHistoryComponent } from "./components/acceptance-protocol-history.component";
import { ShipmentsAdditionalServicesComponent } from "./components/shipments-additional-services.component";
import { ShipmentsEditorParcelReferencesComponent } from "./components/shipments-editor-parcel-references.component";
import { ShipmentEditorParcelComponent } from "./components/shipment-editor-parcel.component";
import { ShipmentEditorParcelsComponent } from "./components/shipment-editor-parcels.component";
import { ImportArchiveComponent } from "./components/import-archive.component";
import { JobService } from "./services/job-service";
import { ExportAllShipmentsDialogComponent } from "./components/export-all-shipments-dialog.component";
import { ShipmentTypeDialogComponent } from "./components/shipment-type-dialog.component";
import { HttpClientModule } from "@angular/common/http";
import { DpdApiCustomerComponent } from "./components/dpd-api-customer.component";
import { ShipmentsAdditionalFieldsComponent } from "./components/shipment-additional-fields.component";

const components = [
  AcceptanceProtocolHistoryComponent,
  DailyProcedureDialogComponent,
  ShipmentEditorComponent,
  ShipmentComponent,
  ShipmentsCompactComponent,
  ShipmentsImportDialogComponent,
  ShipmentsGridComponent,
  ShipmentsListComponent,
  ShipmentsComponent,
  SenderScanComponent,
  RecipientViewComponent,
  FilterListViewComponent,
  SetPickupDateDialogComponent,
  CustomClearanceViewComponent,
  CustomClearanceMiniportalViewComponent,
  DeleteAllShipmentsDialogComponent,
  PrintingPositionSelectorDialogComponent,
  ShipmentsAdditionalServicesComponent,
  ShipmentsAdditionalFieldsComponent,
  ShipmentsEditorParcelReferencesComponent,
  ShipmentEditorParcelComponent,
  ShipmentEditorParcelsComponent,
  ImportArchiveComponent,
  ShipmentsArchiveComponent,
  ExportAllShipmentsDialogComponent,
  ShipmentTypeDialogComponent,
  DpdApiCustomerComponent
];

const services = [
  ShipmentService,
  CustomClearanceService,
  JobService
];


@NgModule({
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    CommonModule,
    RouterModule,
    InputTextareaModule,
    AutoCompleteModule,
    MultiSelectModule,
    InputNumberModule,
    DropdownModule,
    InputTextModule,
    ListboxModule,
    CalendarModule,
    KeyFilterModule,
    TooltipModule,
    // WjCoreModule,
    // WjInputModule,
    Ng2CompleterModule
  ],
  declarations: [
    ...components
  ],
  providers: [
    ...services
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    DailyProcedureDialogComponent,
    ShipmentsImportDialogComponent,
    SetPickupDateDialogComponent,
    DeleteAllShipmentsDialogComponent,
    PrintingPositionSelectorDialogComponent,
    ExportAllShipmentsDialogComponent
  ]
})
export class ShipmentsModule {

}
