import { EntityDataBaseInteractive } from "./base.model"


export interface CustomerDetail extends EntityDataBaseInteractive {
  masterId: number;
  depotCode: string;
  customerNumber: string;
  sapContractNumber: string;
  compositeCustomerNumber: string;
  salesId: string;
  delisId: string;
  invoicingCycle: number;
  customerInvoicingNr: string;
  statusCode: string;
  name: string;
  validFromDateTimeUtc: Date;
  validToDateTimeUtc: Date;
  eoriNumber: string;
  stopCredit?: boolean;
  icDph: string;
  webSite: string;
  hmrcNumber?: string;
}


export interface Address extends EntityDataBaseInteractive {
  customerDetailId: number;
  depotCode: string;
  addressNr: string;
  addressTypeCode: string;
  addressTypeCodeAdditional: string;
  addressForm: string;
  addressFormDescription: string;
  addressFormName: string;
  name1: string;
  name2: string;
  name3: string;
  name4: string;
  street: string;
  street2: string;
  houseNr: string;
  zip: string;
  pOBoxZip: string;
  pOBoxText: string;
  place: string;
  countryCode: string;
  phone: string;
  fax: string;
  web: string;
  email: string;
  customerAddressType: string;
  customerAddressTypeDescription: string;
  turnoverTaxId: string;
  latitude: number;
  longitude: number;
  height: number;
  customId: string;

  maskingName: string;
  maskingName2: string;
  maskingStreet: string;
  maskingStreet2: string;
  maskingHouseNr: string;
  maskingZip: string;
  maskingCity: string;
  maskingCountryCode: string;
}


export interface AddressForGridView extends Address {
  canApprove: boolean;
  canReject: boolean;
  canDelete: boolean;
  canUndelete: boolean;

  stateId: string;
}


export interface Configuration extends EntityDataBaseInteractive {
  tenantId: number;
  customId: string;
  customerDetailId: number;
  customerCustomId: string;
  validFromUtc: Date;
  validToUtc: Date;
  stateId: string;
  settingsJson: string;
  configurationBundleJson: string;
  isTemplate: boolean;
  businessUnitCode: string;
}


export interface BankAccount extends EntityDataBaseInteractive {
  customerDetailId: number;
  externalSystemId: string;
  name: string;
  bankName: string;
  accountNumber: string;
  iban: string;
  bic: string;
  countryCode: string;
  customId: string;
  stateId: string;
}


export interface BankAccountAttachment {
  bankAccountId: number;
  name: string;
  description: string;
  fileName: string;
  attachment: Blob;
}


export interface BankAccountForGridView extends BankAccount {
  countryName: string;
  customerName: string;
  customerDelisId: string;
  canApprove: boolean;
  canReject: boolean;
  canDelete: boolean;
  canUndelete: boolean;
  canDeactivate: boolean;
  canActivate: boolean;
}


export interface ContactPerson extends EntityDataBaseInteractive {
  customerDetailId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  note: string;

  name?: string;
}


export class CustomerAddressTypeCode {
  public static Pickup = "A";
}