<div *ngIf="user != null" class="current-user">
  <span class="ic-user user-icon"></span>
  <div class="current-user-data">
    <div class="current-user-data-content">
      <span class="user-name">{{ user?.name }} <span class="shipper-admin-user" *ngIf="shipperAdminUserLogin">({{ shipperAdminUserLogin }})</span></span>
      <div class="user-commands" *ngIf="showUserCommands">
        <div class="ctl">
          <button id="button-logout" type="button" (click)="logOut()" title="{{ 'log_out' | shpLocalize }}">{{ "log_out" | shpLocalize }}</button>
        </div>
      </div>

      <div>
        <shp-color-theme-select></shp-color-theme-select>
      </div>

      <div *ngIf="!isCustomerService" class="business-unit-selection">
        <label for="current-business-unit">{{ "pickup_address" | shpLocalize }}</label>
        <div *ngIf="currentContext">
          <div class="context-address">{{ currentContext.address.name1 }}<br />{{ currentContext.address.name2 }}<br *ngIf="currentContext.address.name2" />{{ currentContext.address.street }} {{ currentContext.address.houseNr }}<br />{{ currentContext.address.street2 }}<br *ngIf="currentContext.address.street2" />{{ currentContext.address.zip }} {{ currentContext.address.place }}</div>
          <div class="context-detail-name">{{ currentContext.customerDetail.name }}<br /><em>{{ currentContext.customerDetail.delisId }}</em></div>
        </div>
        <button id="button-change-pickup-address" *ngIf="contextsCount > 1" class="icon" (click)="changePickupAddress()" title="{{ 'select_pickup_address' | shpLocalize }}"><span class="ic-move-left-right-1"></span></button>
      </div>

      <div class="mt-1">
        <shp-language-bar></shp-language-bar>
      </div>
    </div>
  </div>
</div>