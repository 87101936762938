<form #f (submit)="save()" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category">
    <h2>{{ 'settings_pudo' | shpLocalize }}</h2>
    <div class="ctl" *ngIf="settings.pudo_download_is_ftp.isVisible">
      <label for="pudo-download-is-ftp">{{ 'settings_pudo_download_is_ftp' | shpLocalize }}</label>
      <input type="checkbox"
             name="pudoDownloadIsFtp" id="pudo-download-is-ftp" [(ngModel)]="settings.pudo_download_is_ftp.value" />
      <label for="pudo-download-is-ftp"></label>
    </div>
    <div class="ctl" *ngIf="settings.pudo_download_protocol.isVisible">
      <label for="pudo-download-protocol">{{ 'settings_pudo_download_protocol' | shpLocalize }}</label>
      <input type="text"
             name="pudoDownloadProtocol" id="pudo-download-protocol" [(ngModel)]="settings.pudo_download_protocol.value" />
    </div>
    <div class="ctl" *ngIf="settings.pudo_download_path.isVisible">
      <label for="pudo-download-path">{{ 'settings_pudo_download_path' | shpLocalize }}</label>
      <input type="text"
             name="pudoDownloadPath" id="pudo-download-path" [(ngModel)]="settings.pudo_download_path.value" />
    </div>
    <div class="ctl" *ngIf="settings.pudo_list_name.isVisible">
      <label for="pudo-list-name">{{ 'settings_pudo_list_name' | shpLocalize }}</label>
      <input type="text"
             name="pudoListName" id="pudo-list-name" [(ngModel)]="settings.pudo_list_name.value" readonly="readonly" />
    </div>
  </div>
</form>