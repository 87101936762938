import { Component } from "@angular/core";

import * as Shared from "../../shared/index";
import { ShipmentEditorComponent } from "./shipment-editor.component";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments-editor-parcel-references",
  templateUrl: "./shipments-editor-parcel-references.component.html",
  host: {
    "class": "shipment-editor__percel-references"
  }
})
export class ShipmentsEditorParcelReferencesComponent {
  constructor(public editor: ShipmentEditorComponent) { }

  get isAllParcelsHaveSameReferencesCheckboxDisplayed(): boolean {
    return !(this.editor.isBrexitCountryRecipient && this.editor.brexitParcelsCountReadOnly);
  }
}
