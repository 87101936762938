import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import { ToastrService } from "ngx-toastr";

import { ImportProfileTransformationModel, ImportProfileTransformationField } from "../models/transformations.models";
import { LoggingService, RoutedPageComponentBase, GlobalEventsStreamService, LocalizationService, AuthenticationService, ContextService, ExceptionsHandlerService } from "../../shared/index";
import { ImportProfilesService } from "../services/import-profiles.service";

import { CompleterService } from "ng2-completer";
import { uniqueMapTransformationSourceValue } from "../../shared/validators";
import { Subject, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";

const DEFAULT_TRANSFORMATION_TYPE = "MapValues";

@Component({
  templateUrl: "./transformation-editor.component.html"
})
export class TransformationEditorComponent extends RoutedPageComponentBase implements OnInit, OnDestroy {
  private _isBusy = false;
  private _id: number = null;
  private _model: ImportProfileTransformationModel = null;
  private _form: FormGroup = null;
  private _isReadOnly = false;

  private _tenantId: number = null;
  private _paramsChangeSubscription: Subscription = null;
  private _loadingSubscription: Subscription = null;


  public transformationTypes = [];

  public get isBusy() { return this._isBusy; }


  public get canDelete() {
    return this._model && this._model.transformation.id != 0 && this._model.transformation.tenantId;
  }


  public get form() { return this._form; }


  public get selectedTransformationType(): string {
    return this.form ?
           this._form.get("transformation.transformationTypeCode").value :
           DEFAULT_TRANSFORMATION_TYPE;
  }


  public get isReadOnly() { return this._isReadOnly; }


  public get model() { return this._model; }

  public set model(value: ImportProfileTransformationModel) {
    this._model = value;
    this.modelUpdates$.next(value);
  }


  public modelUpdates$ = new Subject<ImportProfileTransformationModel>();


  private initializeContext() {
    return this._contextService.tenantId.pipe(
      filter(id => id != null),
      take(1),
      map((id: number) => {
        this._tenantId = id;
        return true;
      })
    );
  }


  private initializeNew() {
    this._isReadOnly = false;
    this._model = this.getEmptyModel();
    this.fillFormFromModel();
  }


  private loadExisting() {
    this._isBusy = true;

    this.clearLoadingSubscription();

    this._loadingSubscription = this._importProfilesService.getTransformation(this._id).subscribe(transformationModel => {
      this._model = transformationModel;
      this.fillFormFromModel();
    }, ex => {
      let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex, "Error loading transformation");

      this._toastr.error(
        this.localizationService.getLocalizedString("error_loading_transformation") + ": " +
        this.localizationService.getLocalizedExceptionString(exceptionInfo));
    });
  }


  public hasUnsavedChanges = () => {
    return this._form && this._form.dirty;
  }


  public save() {
    this._isBusy = true;

    Object.assign(this._model.transformation, this._form.controls.transformation.value);
    // Staré hodnoty zahodíme.
    this._model.fields = this._form.controls.fields.value;

    this._importProfilesService.saveImportProfileTransformation(this._model).subscribe(updatedModel => {
      this._model = updatedModel;
      this._id = updatedModel.transformation.id;

      this._location.replaceState("/import-profiles/transformations/" + updatedModel.transformation.id);

      this.fillFormFromModel();

      this._toastr.success(this.localizationService.getLocalizedString("transformation_successfully_saved"));
      this._isBusy = false;
    }, ex => {
      let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex, "Error saving transformation");

      this._toastr.error(this.localizationService.getLocalizedString("error_saving_transformation") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
      this._isBusy = false;
    });
  }


  public delete() {
    this._modal
      .confirm()
      .message(this.localizationService.getLocalizedString("confirm_transformation_deleting"))
      .okBtn(this.localizationService.getLocalizedString("yes"))
      .cancelBtn(this.localizationService.getLocalizedString("no"))
      .open()
      .result.then(confirmed => {
        if (confirmed) {
          this._isBusy = true;
          this._importProfilesService.deleteTransformation(this._id).subscribe(() => {
            this._isBusy = false;

            this._toastr.success(this.localizationService.getLocalizedString("transfiormation_successfully_deleted"));

            this.router.navigate(["/import-profiles/transformations"]);
          }, ex => {
            let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
            this.loggingService.logErrorData(exceptionInfo, "Error deleting transformation.");

            this._toastr.error(this.localizationService.getLocalizedString("error_deleting_transformation") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
            this._isBusy = false;
          });
        }
      }, ex => { });
  }


  private initializeNewOrLoadExisting() {
    if (this._id === 0) {
      this.initializeNew();
    } else {
      this.loadExisting();
    }
  }


  public ngOnInit() {
    this._importProfilesService.getTransformationTypes().subscribe(res => {
      this.transformationTypes = res
    }, err => { });

    this.initializeContext().subscribe(initialized => {
      this._paramsChangeSubscription = this._route.params.subscribe(params => {
        try {
          this._id = +params["id"];
        } catch (ex) {
          this._id = 0;
        }

        this.initializeNewOrLoadExisting();
      });
    });
  }


  private clearLoadingSubscription() {
    if (this._loadingSubscription) {
      this._loadingSubscription.unsubscribe();
      this._loadingSubscription = null;
    }
  }


  public ngOnDestroy() {
    if (this._paramsChangeSubscription) {
      this._paramsChangeSubscription.unsubscribe();
      this._paramsChangeSubscription = null;
    }

    this.clearLoadingSubscription();
  }


  private getEmptyModel(): ImportProfileTransformationModel {
    return {
      fields: [],
      transformation: {
        code: "",
        description: "",
        tenantId: this._tenantId,
        id: 0,
        isNew: true,
        isPersistent: false,
        customerDetailId: null,
        name: "",
        userId: null,
        isUnknownSourceValueAllowed: true,
        createdByUser: null,
        creationDateTimeUtc: null,
        modificationDateTimeUtc: null,
        modifiedByUser: null,
        transformationTypeCode: DEFAULT_TRANSFORMATION_TYPE
      }
    }
  }


  private getEmptyField(): ImportProfileTransformationField {
    return {
      destinationValue: "",
      id: 0,
      isNew: true,
      importProfileTransformationId: this._model.transformation.id,
      isPersistent: false,
      sourceValue: ""
    }
  }


  private initializeForm() {
    this._form = this._fb.group({
      transformation: this._fb.group({
        code: ["", [Validators.required, Validators.maxLength(255)]],
        name: ["", [Validators.required, Validators.maxLength(255)]],
        description: ["", [Validators.maxLength(1000)]],
        isUnknownSourceValueAllowed: [true],
        transformationTypeCode: [DEFAULT_TRANSFORMATION_TYPE, [Validators.required, Validators.maxLength(20)]]
      }),
      fields: this._fb.array([], uniqueMapTransformationSourceValue)
    });

    /**
     * Remove fields when transformation type changes.
     * Only mapValues type requires fields.
     */
    this.form.get("transformation.transformationTypeCode").valueChanges.pipe(
      filter(value => value !== "MapValues")
    ).subscribe(() => this.removeFieldsFromForm());
  }


  public addEmptyFieldToForm() {
    this.addFieldToFrom(this.getEmptyField());
  }


  private addFieldToFrom(field: ImportProfileTransformationField) {
    var fields = this._form.controls.fields as FormArray;
    fields.insert(0, this._fb.group({
      id: [field.id],
      importProfileTransformationId: [field.importProfileTransformationId],
      sourceValue: [field.sourceValue],
      destinationValue: [field.destinationValue, Validators.required]
    }));
  }


  private removeFieldsFromForm() {
    const fieldsArray = this.form.get("fields") as FormArray;

    while (fieldsArray.length !== 0) {
      fieldsArray.removeAt(0)
    }
  }


  private removeFieldFromForm(index: number) {
    var fields = this._form.controls.fields as FormArray;
    fields.removeAt(index);

    this._form.markAsDirty();
  }


  private fillFormFromModel() {
    this._form.controls.transformation.patchValue({
      code: this._model.transformation.code,
      name: this._model.transformation.name,
      description: this._model.transformation.description,
      isUnknownSourceValueAllowed: this._model.transformation.isUnknownSourceValueAllowed,
      transformationTypeCode: this._model.transformation.transformationTypeCode
    });


    let fields = this._form.controls.fields as FormArray;

    let fieldsCount = fields.length;

    for (let i = 0; i < fieldsCount; i++) {
      fields.removeAt(0);
    }

    this._model.fields.forEach(field => {
      this.addFieldToFrom(field);
    });

    this._form.markAsPristine();

    this._isReadOnly = !this._model.transformation.tenantId;
    if (this._isReadOnly) {
      this._form.disable();
    } else {
      this._form.enable();
    }
  }


  constructor(
    loggingService: LoggingService,
    globalEventsStreamService: GlobalEventsStreamService,
    localizationService: LocalizationService,
    authenticationService: AuthenticationService,
    router: Router,
    private _location: Location,
    private _fb: FormBuilder,
    private _exceptionsHandlerService: ExceptionsHandlerService,
    private _contextService: ContextService,
    private _importProfilesService: ImportProfilesService,
    private _modal: Modal,
    private _route: ActivatedRoute,
    private _toastr: ToastrService,
    private _completerService: CompleterService,
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);

    this.initializeForm();
  }
}