<div class="delete-all-shipments-dialog dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
  <h1>{{ 'confirm_deleting_all_recipients' | shpLocalize }}</h1>
  <p>{{ 'confirm_deleting_all_recipients_instructions' | shpLocalize:requiredDeleteVerificationText }}</p>
  <form (submit)="deleteAllRecipients()" [formGroup]="f">
    <div class="ctl">
      <input autocomplete="off" id="delete-all-recipients-verification-text" type="text" formControlName="deleteVerificationText" />
    </div>
    <div class="buttons">
      <button [disabled]="!f.valid" id="delete-all-recipients-button" type="submit">{{ 'delete' | shpLocalize }}</button>
      <button id="button-close" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
    </div>
  </form>
</div>