import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, Renderer2 } from "@angular/core";
import { mapTo, take } from "rxjs/operators";

import * as Shared from "../index";
import { PollyCollection, PollyService } from "../index";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}PollyDialogTrigger]`
})
export class PollyDialogTriggerDirective implements OnInit {
  @Input(Shared.SELECTOR_PREFIX + 'PollyDialogTrigger') collection: PollyCollection;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private pollyService: PollyService
  ) { }

  ngOnInit() {
    this.pollyService._isPollyLoaded$.pipe(
      take(1),
      mapTo(this.pollyService.isPollyEnabled)
    ).subscribe(isEnabled => {
      if (isEnabled) {
        this.display();
      }
    });
  }

  openDialog() {
    this.pollyService.openDialog(this.collection);
  }

  private display(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);

    const el = this.templateRef.elementRef.nativeElement.previousElementSibling;
    this.renderer.listen(el, 'click', () => this.openDialog());
  }
}
