import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subject } from "rxjs";
import { delay, filter, map, takeUntil, tap } from "rxjs/operators";
import { isEmptyInputValue } from "../../shared/form-helpers";

import * as Shared from "../../shared/index";
import { WizardOverlayService } from "../../shared/modules/wizard/services/wizard-overlay.service";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import { ShipperWizardService } from "../../shared/services/shipper-wizard.service";
import {
  ImportProfileContainer,
  ImportProfileField,
  ImportProfileFieldDescription
} from "../models/import-profiles.models";
import { ImportProfileTransformation } from "../models/transformations.models";
import {
  ImportProfilesService,
  TYPE_CODE_CONSTANT,
  TYPE_CODE_FIELD
} from "../services/import-profiles.service";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-dynamic-import-editor-part2",
  templateUrl: "./part2.editor.component.html",
  styleUrls: []
})
export class Part2EditorComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input("editorValues") public set editorValues(value: string[]) {
    this.fieldNameOptions = value.map(v => ({label: v, value: v}));
  }

  constructor(
    private _dynamicImportsService: ImportProfilesService,
    private _wizardOverlayService: WizardOverlayService,
    private _wizardService: WizardService,
    private _shipperWizardService: ShipperWizardService
  ) { }

  @Input() dynamic = {};
  @Input() customers = [];
  @Input() show = false;
  @Input() fieldMappings: Array<ImportProfileField> = [] as Array<ImportProfileField>;

  // for recognize if is header present
  // and we will show title for mapings
  @Input() public profile: ImportProfileContainer = {} as ImportProfileContainer;

  @ViewChild("part2form") public part2form: NgForm;

  public editorSourceFields = [{ name: "RName", inUse: false },
  { name: "RStreet", inUse: false },
  { name: "RHouseNr", inUse: false },
  { name: "RZip", inUse: false },
  { name: "RCountry", inUse: false }];

  @Input() public editorGroups = [];
  // keep unchanged groups
  @Input() public editorInitialGroups = [];

  @Input() public groupListWithMissingFields: any;

  @Input() public viewOnly: boolean;

  @Output() public fileChange = new EventEmitter();

  public mapGroups = {};

  public isBusy = false;

  public transformations: ImportProfileTransformation[] = [{ code: "", id: null, name: "-" } as any];

  /**
   * Názov aktuálne vybraného súboru z disku.
   */
  public selectedFileName = "";


  /**
   * JS objekt reprezentujúci údaje o vybranom súbore.
   */
  private _selectedFile: File = null;

  public userAlreadyHaveBeenInPart2 = false;

  public fieldNameOptions: {value: string; label: string}[] = [];

  public cities;

  private destroy$ = new Subject<void>();

  public ngOnChanges() {
    if (!this.show || !this.editorGroups) {
      return;
    }

    // animate show fields
    window.setTimeout(() => {
      if (this.groupListWithMissingFields) {
        this.groupListWithMissingFields.forEach((missing, groupIndex) => {
          const group = this.editorGroups[groupIndex];

          // check if group is the same status as missing
          // 'misssing' should be show, others should be hide
          if (group.show !== missing) {
            // if is show and is not missing - hide please
            // if is not show and missing - show it please
            this.doShowGroup(groupIndex, missing);
          }
        });

        this.userAlreadyHaveBeenInPart2 = true;
      } else if (this.editorGroups.length && this.userAlreadyHaveBeenInPart2 === false) {
        // user go first time to part2 show first two group
        this.userAlreadyHaveBeenInPart2 = true;

        this.doShowGroup(0);
        this.doShowGroup(1);

        if (this._wizardService.isActive) {
          this.doShowGroup(3);
          setTimeout(() => this._wizardOverlayService.scrollIntoView());
        }
      }
    }, 500);
  }


  public ngOnInit() {
    this._dynamicImportsService.getTransformations().subscribe(transformations => {
      this.transformations.push(...transformations);
    });

    this.cities = [
      {name: "New York", code: "NY"},
      {name: "Rome", code: "RM"},
      {name: "London", code: "LDN"},
      {name: "Istanbul", code: "IST"},
      {name: "Paris", code: "PRS"}
    ];

    this._wizardService.state$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.setWizardCanGoToNextStep());
  }

  public ngAfterViewInit() {
    this.part2form.valueChanges.pipe(
      filter(() => this._wizardService.isActive),
      // Delay so the ngModel directive updates the model.
      delay(0)
    ).subscribe(() => this.setWizardCanGoToNextStep());
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public doPristine() {
    const formValue = this.part2form.form.getRawValue();
    this.part2form.reset(formValue);
  }

  public hasUnsavedChanges = (): boolean => {
    return this.part2form.dirty;
  }

  public formInvalid() {
    return !this.part2form.form.valid;
  }

  public getEditorGroupWizardTag(name: string): string | undefined {
    const wizardEditorGroupTags = {
      recipient: "importProfileMappingRecipient",
      parcels: "importProfileMappingParcels",
    };

    return wizardEditorGroupTags[name];
  }

  public doShowGroup(groupIndex: number, show: boolean = true) {
    if (this.editorGroups && groupIndex < this.editorGroups.length && this.editorGroups[groupIndex]) {

      const group = this.editorGroups[groupIndex];
      // let element = document.getElementById(PART_IN_GROUP + groupIndex);

      // if (show) {
      //  this.slideDown(element, 400, group.fieldMappings.length * 45, () => { });
      // } else {
      //  this.slideDown(element, 400, 0, () => { });
      // }

      group.enabled = show;
    }
  }

  public onShowGroup(group, groupIndex) {
    // if you try to close
    // check other if any still open
    // don't let user close all groups
    if (group.enabled) {
      const anyOtherStillEnabled = this.editorGroups.some((otherGroup) => {
        return otherGroup !== group && otherGroup.enabled;
      });

      if (!anyOtherStillEnabled) {
        return;
      }
    }

    //    this.doShowGroup(groupIndex, !group.enabled);
    group.enabled = !group.enabled;
  }


  // http://stackoverflow.com/questions/3795481/javascript-slidedown-without-jquery
  slideDown(element, duration, finalheight, callback) {
    const s = element.style;
    const currentHeight = Number(s.height.substr(0, s.height.length - 2)) || 0;

    let y = currentHeight;
    const framerate = 10;

    const heightmove = Math.abs(currentHeight - finalheight);
    const interval = duration / framerate;
    const totalframes = duration / interval;
    const heightincrement = heightmove / totalframes;

    const tweenIn = () => {
      y += heightincrement;
      s.height = y + "px";
      if (y < finalheight) {
        window.setTimeout(tweenIn, interval);
      }
    };

    const tweenOut = () => {
      y -= heightincrement;

      if (y < 0) {
        y = 0;
      }

      s.height = y + "px";
      if (y > finalheight) {
        window.setTimeout(tweenOut, interval);
      }
    };

    if (finalheight > 0) {
      tweenIn();
    } else {
      tweenOut();
    }
  }

  /**
   * link main value from valueField
   */
  onFieldChanged(field: ImportProfileFieldDescription) {
    // remove info about constant
    // keep just one value live
    // in this time it is valueField main value
    if (field.valueConstant) {
      field.valueConstant = "";
    }

    if (field.mappingTypeCode !== TYPE_CODE_FIELD) {
      field.mappingTypeCode = TYPE_CODE_FIELD;
    }

    field.value = field.valueField;
  }


  onTransformationChanged(field: ImportProfileFieldDescription) {

  }


  /**
   * check values from editorInitialGroups what is not changed after load
   * if is the value in editor changed
   */
  public checkFieldChanged(field, groupIndex: number, fieldIndex: number) {
    if (!this.editorInitialGroups[groupIndex] || !this.editorInitialGroups[groupIndex].fieldMappings[fieldIndex]) {
      throw new Error("Initial value doesn't exist");
    }

    const initial = this.editorInitialGroups[groupIndex].fieldMappings[fieldIndex];

    const changed = (field.valueField && initial.value !== field.valueField) ||
      (!field.valueField && initial.value !== field.valueConstant);

    // check if some of values not the same as before
    return changed;
  }

  /**
   * link main value from valueConstant
   */
  public onConstChanged(field: ImportProfileFieldDescription) {
    // remove info about constant
    // keep just one value live
    // in this time it is valueConstant main value
    if (field.valueConstant) {
      field.valueField = "";
    } else {
      // if user edit the valueConstant to empty
    }

    if (field.mappingTypeCode !== TYPE_CODE_CONSTANT) {
      field.mappingTypeCode = TYPE_CODE_CONSTANT;
    }

    field.value = field.valueConstant;
  }

  public onEditorIndexChanged(field, $event) {

  }

  public onEditorTextChanged(field: ImportProfileFieldDescription, groupIndex: number, fieldIndex: number) {
    // field changed do actions and mark as dirty
    if (this.checkFieldChanged(field, groupIndex, fieldIndex)) {
      field.mappingTypeCode = TYPE_CODE_FIELD;
      field.value = field.valueField;
      field.valueConstant = "";
      this.part2form.form.markAsDirty();
    }
  }

  private setSelectedFile = (inputValue: any) => {
    if (inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this.selectedFileName = this._selectedFile.name;

      // this.import();
      this.fileChange.next({ file: this._selectedFile, fileName: this.selectedFileName });
    } else {
      this.selectedFileName = "";
      this._selectedFile = null;
    }

    this.setWizardCanGoToNextStep();
  }


  public selectedConfigFileChanged($event) {
    this.setSelectedFile($event.target);
  }

  // Checks if user can go to the next step while wizard is active.
  private setWizardCanGoToNextStep() {
    switch (true) {
      // For the "select file" wizard step the next button is disabled until a file is selected.
      case this._wizardService.isOnStep(WizardStepName.ImportProfileMappingFile):
        this._shipperWizardService.canGoToNextStep = Boolean(this._selectedFile);
        break;
      // Check if all mandatory recipient fields are filled out.
      case this._wizardService.isOnStep(WizardStepName.ImportProfileMappingRecipient):
        this._shipperWizardService.canGoToNextStep = this.profile
          ?.groups
          ?.find(g => g.name === "recipient")
          ?.fieldMappings
          ?.filter(f => f.isMandatory)
          ?.every(f => !isEmptyInputValue(f.value)) ?? true;
        break;
      // Check if all mandatory parcel fields are filled out.
      case this._wizardService.isOnStep(WizardStepName.ImportProfileMappingParcels):
        this._shipperWizardService.canGoToNextStep = this.profile
          ?.groups
          ?.find(g => g.name === "parcels")
          ?.fieldMappings
          ?.filter(f => f.isMandatory)
          ?.every(f => !isEmptyInputValue(f.value)) ?? true;
        break;
      default:
        break;
    }
  }
}
