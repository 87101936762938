<shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
<div class="settings-category bank-accounts">
  <h2>{{ 'bank_accounts' | shpLocalize }}</h2>
  <div *ngIf="canAddBankAccount" class="commands">
    <button id="button-add-bank-account" type="button" [routerLink]="['/settings/bank-accounts/new']"><span class="ic-add-2"></span> {{ 'add' | shpLocalize }}</button>
  </div>
  <table class="nice">
    <thead>
      <tr>
        <th></th>
        <th class="col-custom-identifier">{{ 'account_id' | shpLocalize }}</th>
        <th class="col-bank-account-name">{{ 'bank_account_name' | shpLocalize }}</th>
        <th class="col-iban">{{ 'iban' | shpLocalize }}</th>
        <th class="col-bic">{{ 'bic' | shpLocalize }}</th>
        <th class="col-bank-account-state">{{ 'state' | shpLocalize }}</th>
        <th class="col-commands"></th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-bankAccount [ngForOf]="bankAccounts" let-i="index">
        <tr *ngIf="bankAccount.stateId != 'Deleted' || isImpersonatingShipperAdminUser" [class.show-attachments]="showAttachments[bankAccount.id]" [class]="setRowColoring(bankAccount)">
          <td class="col-commands no-bg">
            <button type="button" (click)="toogleAttachments(bankAccount.id)"><span [class]="showAttachments[bankAccount.id] ? 'ic-subtract-square-1' : 'ic-add-square-1'"></span></button>
          </td>
          <td class="col-custom-identifier"><span title="{{ bankAccount.customId }}">{{ bankAccount.customId }}</span></td>
          <td class="col-bank-account-name"><span title="{{ bankAccount.name }}">{{ bankAccount.name }}</span></td>
          <td class="col-iban"><span title="{{ bankAccount.iban }}">{{ bankAccount.iban | iban }}</span></td>
          <td class="col-bic"><span title="{{ bankAccount.bic }}">{{ bankAccount.bic }}</span></td>
          <td class="col-bank-account-state">
            <span class="check-mark" *ngIf="bankAccount.stateId == 'Valid' || bankAccount.stateId == 'Approved' || bankAccount.stateId == ''" title="{{ 'bank_account_valid' | shpLocalize }}"></span>
            <span *ngIf="bankAccount.stateId == 'Deleted'" class="bank-account-state deleted">{{ 'bank_account_state_deleted' | shpLocalize }}</span>
            <span *ngIf="bankAccount.stateId == 'Inactive'" class="bank-account-state inactive">{{ 'bank_account_state_inactive' | shpLocalize }}</span>
            <span *ngIf="bankAccount.stateId == 'InPreparation'" class="bank-account-state in-preparation">{{ 'bank_account_state_in_preparation' | shpLocalize }}</span>
            <span *ngIf="bankAccount.stateId == 'WaitingForApproval'" class="bank-account-state waiting-for-approval">{{ 'bank_account_state_waiting_for_approval' | shpLocalize }}</span>
            <span *ngIf="bankAccount.stateId == 'SentForApproval'" class="bank-account-state waiting-for-approval">{{ 'bank_account_state_sent_for_approval' | shpLocalize }}</span>
            <span *ngIf="bankAccount.stateId == 'NotApproved'" class="bank-account-state not-approved">{{ 'bank_account_state_not_approved' | shpLocalize }}</span>
          </td>
          <td class="col-commands">
            <button *ngIf="bankAccount.stateId == 'WaitingForApproval'" id="button-upload-signed-form" type="button" (click)="upload(bankAccount)" title="{{ 'upload_bank_account_attachment' | shpLocalize }}" class="contains-exclamation"><span *ngIf="bankAccount.attachmentsWithoutBodies.length == 0" class="highlight">!</span><span class="ic-file-upload-1"></span></button>
            <button *ngIf="bankAccount.stateId == 'WaitingForApproval'" id="button-download-signed-form" type="button" (click)="downloadBankAccountAcceptation(bankAccount)" title="{{ 'bank_account_request_document' | shpLocalize }}"><span class="ic-file-download-1"></span></button>
            <button *ngIf="bankAccount.canActivate" id="button-activate-bank-account" type="button" (click)="activate(bankAccount)" title="{{ 'bank_account_command_activate' | shpLocalize }}"><span class="ic-audio-control-play"></span></button>
            <button *ngIf="bankAccount.canDeactivate" id="button-deactivate-bank-account" type="button" (click)="deactivate(bankAccount)" title="{{ 'bank_account_command_deactivate' | shpLocalize }}"><span class="ic-audio-control-stop"></span></button>
            <button *ngIf="bankAccount.canDelete" id="button-delete-bank-account" type="button" (click)="delete(bankAccount)" title="{{ 'bank_account_command_delete' | shpLocalize }}"><span class="ic-bin"></span></button>
            <button *ngIf="bankAccount.canUndelete" id="button-undelete-bank-account" type="button" (click)="undelete(bankAccount)" title="{{ 'bank_account_command_undelete' | shpLocalize }}"><span class="ic-sign-recycle"></span></button>
          </td>
        </tr>
        <tr *ngIf="bankAccount.stateId != 'Deleted' || isImpersonatingShipperAdminUser" class="attachments" [class.show]="showAttachments[bankAccount.id]">
          <td colspan="7">
            <span [class]="bankAccount.attachmentsWithoutBodies.length > 0 ? 'header': 'no-att'">
              {{ (bankAccount.attachmentsWithoutBodies.length > 0 ? 'attachments' : 'no_attachments_found') | shpLocalize }}
            </span>
            <table *ngIf="bankAccount.attachmentsWithoutBodies.length > 0">
              <thead>
                <tr>
                  <th class="">{{ 'name' | shpLocalize }}</th>
                  <th class="">{{ 'description' | shpLocalize }}</th>
                  <th class="">{{ 'filename' | shpLocalize }}</th>
                  <th class="col-commands"></th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-attachment [ngForOf]="bankAccount.attachmentsWithoutBodies" let-j="index">
                  <tr>
                    <td class="col-name">{{ attachment.name }}</td>
                    <td class="col-description">{{ attachment.description }}</td>
                    <td class="col-filename">{{ attachment.fileName }}</td>
                    <td class="col-commands">
                      <button id="button-download-attachment" type="button" (click)="downloadAttachment(bankAccount.id, attachment.id)" title="{{ 'attachment_command_download' | shpLocalize }}"><span class="ic-file-download-1"></span></button>
                      <button *ngIf="bankAccount.stateId == 'WaitingForApproval'" id="button-delete-attachment" type="button" (click)="deleteAttachment(bankAccount.id, attachment.id)" title="{{ 'attachment_command_delete' | shpLocalize }}"><span class="ic-bin"></span></button>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </td>
        </tr>
      </ng-template>
      <tr *ngIf="bankAccounts && bankAccounts.length === 0">
        <td colspan="7">
          <div class="message warning">
            <div class="message-content">
              {{ 'no_bank_accounts_defined' | shpLocalize }}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
