import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Optional, Self } from "@angular/core";
import { NgControl } from "@angular/forms";

import { Subject } from "rxjs";
import { auditTime, takeUntil } from "rxjs/operators";

import * as Shared from "../index";

@Directive({
  selector: `input[type=radio][${Shared.SELECTOR_PREFIX}Deselectable]`
})
export class DeselectableRadioButtonDirective implements OnInit, OnDestroy {
  @Input() canDeselect = true;

  @Input() value: any = null;

  private destroy$ = new Subject<void>();
  private isChecked = false;

  constructor(
    private elementRef: ElementRef,
    @Self() @Optional() private ngControl: NgControl
  ) { }

  get hostEl(): HTMLInputElement {
    return this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.isChecked = this.hostEl.checked;

    if (this.ngControl) {
      this.ngControl.valueChanges.pipe(
        takeUntil(this.destroy$),
        auditTime(0)
      ).subscribe(() => this.isChecked = this.hostEl.checked);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener("focus")
  onInput() {
    this.isChecked = this.hostEl.checked;
  }

  @HostListener("click")
  onClick() {
    if (!this.canDeselect || this.hostEl.disabled || !this.isChecked) {
      this.isChecked = this.hostEl.checked;
      return;
    }

    this.hostEl.checked = false;
    this.isChecked = this.hostEl.checked;

    if (this.ngControl?.value === this.value) {
      this.ngControl.control.setValue(null);
    }
  }
}
