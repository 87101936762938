import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { saveAs } from "file-saver";
import * as _ from "lodash";
import * as moment from "moment";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, of, Subject, Subscription } from "rxjs";
import { debounceTime, filter, finalize, map, skip, takeUntil } from "rxjs/operators";

import { AppTableSettingsDialog } from "../../shared/components/table-settings-dialog.component";
import * as Shared from "../../shared/index";
import { ApiHeartbeatService, StringHelpers } from "../../shared/index";
import * as CustomerModels from "../../shared/models/customer.models";
import * as Models from "../models/shipment.model";
import { CustomClearanceService } from "../services/custom-clearance.service";
import { ShipmentService } from "../services/shipments.service";
import { SetPickupDateDialogComponent } from "./set-pickup-date-dialog.component";


export interface ParcelViewModel extends Models.ParcelForGridView {
  isSelected: boolean;
  hasCod: boolean;
  serviceCode: string;
}


const DEFAULT_PARCELS_PER_PAGE = 20;
const FILTER_STORAGE_KEY = "parcels-filter";
const PARCELS_PER_PAGE_STORAGE_KEY = "parcels-per-page";
const TABLE_SETTINGS_STORAGE_KEY = "shipments-table-settings";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments-grid",
  templateUrl: "./shipments-grid.component.html"
})
export class ShipmentsGridComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  allowProtocolsPrintingForSelectedShipments = false;
  anyCRExists = false;
  areAllParcelsSelected = false;
  currentYear = new Date().getFullYear();
  customFilter: any = {};
  exportedFilter = false;
  filter: Models.ParcelsGridViewSearchParameters = null;
  isCentralShipper = this._shipperSettingsService.isCentralShipper;
  isCustomerService = false;
  isFiltered = false;
  isImpersonatingShipperAdminUser = false;
  isPrintReturnLabelActionVisible = false;
  isShipmentGridSettingsVisible = false;
  isShipmentPickupDateTimeVisible = false;
  isSophia = false;
  itemsPerPageOptions = [20, 30, 50, 100, 150, 200, 500];
  languageCode = Shared.getTrackingLocale(Shared.Localization[this.localizationService.currentLocalization]);
  languageCodeIso = Shared.getTrackingLanguageIso(Shared.Localization[this.localizationService.currentLocalization]);
  lastPageIndex = 0;
  loadingErrorOccured = false;
  miniportalUrl = "";
  notExportedFilter = false;
  parcels: BehaviorSubject<ParcelViewModel[]> = new BehaviorSubject([]);
  totalParcelsCount = 0;
  useMiniportalIntegration = false;


  @Input() isBusy = false;

  @Input() statusMessage = "";


  @ViewChild("header") header;

  @ViewChild("content") content;

  displayColumns = [
    "select",
    "pickupDate",
    "creationDateTimeUtc",
    "serviceSetCode",
    "parcelNr",
    "receiver",
    "referentialInfo",
    "weight",
    "cod",
    "commands",
    "spacer",
  ];

  columnsList = [
    // {value: "select", viewValue: ""},
    { value: "pickupDate", viewValue: "shipment_order_pickup_date", filterKeys: ["pickupDateFrom", "pickupDateTo"] },
    { value: "creationDateTimeUtc", viewValue: "order_created_on", filterKeys: ["creationDateFrom", "creationDateTo"] },
    { value: "serviceSetCode", viewValue: "parcel_service_abbr", filterKeys: ["serviceCode"] },
    { value: "parcelNr", viewValue: "parcel_number", filterKeys: ["parcelNr"] },
    { value: "receiver", viewValue: "parcel_recipient_address", filterKeys: ["recipientsAddress"] },
    { value: "referentialInfo", viewValue: "parcel_reference", filterKeys: ["reference"] },
    { value: "weight", viewValue: "parcel_weight", filterKeys: ["weightFrom", "weightTo"] },
    { value: "cod", viewValue: "parcel_cod_amount", filterKeys: ["codFrom", "codTo"] },
    { value: "sender", viewValue: "test_email_sender_address", filterKeys: ["senderAddress"] },
    { value: "shipmentReference", viewValue: "shipment_reference", filterKeys: ["shipmentReference"] },
    { value: "length", viewValue: "parcel_length", filterKeys: ["lengthFrom", "lengthTo"] },
    { value: "width", viewValue: "parcel_width", filterKeys: ["widthFrom", "widthTo"] },
    { value: "height", viewValue: "parcel_height", filterKeys: ["heightFrom", "heightTo"] },
    { value: "note", viewValue: "note", filterKeys: ["note"] },
    { value: "codPurpose", viewValue: "cod_purpose", filterKeys: ["codPurpose"] },
    // {value: "commands", viewValue: ""},
    // {value: "spacer", viewValue: ""},
  ];

  private fixedColumns = [
    "select",
    "commands",
    "spacer",
  ];

  /** Akcie vykonate�n� ponad vybran� z�znamy. */
  groupActions: string[] = [
    "",
    Models.Actions.PrintLabels,
    Models.Actions.Export,
    // Models.Actions.PrintCodList,
    Models.Actions.Delete
  ];

  /**
   * Aktu�lne vybran� akcia, ktor� chceme vykona� s vybran�mi z�znamami.
   */
  selectedGroupAction = this.groupActions[0];

  private _currentAddress: CustomerModels.Address = null;
  private _delisId = "";
  private _destroy$ = new Subject<void>();
  private _filterChanges$ = new Subject<void>();
  private _isShipmentCreationDateTimeVisible = false;
  private _isShipmentOrdersChangedSubscriptionInitialized = false;
  private _tenantId: number = null;
  private _trackingByReferenceUrl = "";
  private _trackingUrl = "";

  /**
   * Subscription, ktor� vznikne, ke� za�neme na��tava� bal��ky pod�a filtra.
   * Sl��i hlavne na to, aby sme vedeli zru�i� na��tavanie, ke� sa zmen� filter nejako
   * a ideme na��tava� veci znovu.
   */
  private _parcelsLoadingSubscription: Subscription = null;


  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _shipmentsService: ShipmentService,
    private _contextService: Shared.ContextService,
    private _modal: Modal,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _toastr: ToastrService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _heartbeatService: ApiHeartbeatService,
    private _customClearanceService: CustomClearanceService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }

  get isShipmentCreationDateTimeVisible() {
    return this._isShipmentCreationDateTimeVisible;
  }

  get isProtocolsPrintingButtonVisible(): boolean {
    // Customer service can currently only create CR orders, so the button is useless.
    return this.allowProtocolsPrintingForSelectedShipments && !this.isCustomerService;
  }


  get orderBy() {
    if (this.filter) {
      return this.filter.orderByFieldName;
    }

    return null;
  }


  get orderAscending() {
    if (this.filter) {
      return this.filter.orderAscending;
    }

    return false;
  }


  /**
   * Vr�ti aktu�lne nastavenie pre po�et z�znamov na stranu.
   * @returns number
   */
  get parcelsPerPage(): number {
    return this.filter.pageSize;
  }


  /**
   * Nastav� po�et z�znamov na stranu, tie� vyvol� aktualiz�ciu z�znamov.
   * @param value Po�et z�znamov, ktor� chceme zobrazi� na jednu stranu.
   */
  set parcelsPerPage(value: number) {
    this.filter.pageSize = value;
    localStorage.setItem(PARCELS_PER_PAGE_STORAGE_KEY, value.toString());

    // Po zamene zavol�me refresh.
    this.refreshParcels();
  }


  get deliveryStateFilter() {
    return this.filter.isStatePreparedForDeliveryVisible && this.filter.isStateSentToDPDVisible;
  }


  set deliveryStateFilter(value: boolean) {
    this.filter.isStatePreparedForDeliveryVisible = value;
    this.filter.isStateSentToDPDVisible = value;
  }


  ngOnInit() {
    this.isSophia = this._shipperSettingsService.isSophia;

    this.loadFilter();

    this._contextService.currentAddress.pipe(
      takeUntil(this._destroy$),
      filter(a => a != this._currentAddress)
    ).subscribe(currentAddress => {
      this._currentAddress = currentAddress;
      this.refreshParcels();
      this.setAnyCRExists();
    });

    combineLatest([
      this._contextService.tenantId,
      this._contextService.currentCustomerDetail.pipe(filter(cd => cd != null)),
      this.authenticationService.impersonatingShipperAdminUserLogin$.pipe(map(login => login != null)),
      this.authenticationService.isCustomerService$,
      // Bu settings
      this._businessUnitSettingsService.getCentralTrackingByReferenceUrl(),
      this._businessUnitSettingsService.getTrackingUrl(),
      !this.isSophia ? this._businessUnitSettingsService.getIsShipmentCreationDateTimeVisible() : of(false),
      this._businessUnitSettingsService.getIsPrintReturnLabelActionVisible(),
      this._businessUnitSettingsService.getIsShipmentGridSettingsVisible(),
      this._businessUnitSettingsService.getIsShipmentPickupDateTimeVisible(),
      this._businessUnitSettingsService.getCreatePickupOrdersWithShipmentOrders(),
      this._businessUnitSettingsService.getInitializeDummyParcelsForCollectionRequest(),
      this._businessUnitSettingsService.getUseMiniportalIntegration(),
      this._businessUnitSettingsService.getMiniportalUrl(),
      this._businessUnitSettingsService.getAllowProtocolsPrintingForSelectedShipmentsSettingValue()
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(([
        tenantId,
        currentCustomerDetail,
        isImpersonatingShipperAdminUser,
        isCustomerService,
        // Bu settings
        trackingByReferenceUrl,
        trackingUrl,
        isShipmentCreationDateTimeVisible,
        isPrintReturnLabelActionVisible,
        isShipmentGridSettingsVisible,
        isShipmentPickupDateTimeVisible,
        createPickupOrdersWithShipmentOrders,
        initializeDummyParcelsForCollectionRequest,
        useMiniportalIntegration,
        miniportalUrl,
        allowProtocolsPrintingForSelectedShipments
      ]) => {
      this._tenantId = tenantId;
      this._delisId = currentCustomerDetail.delisId;
      this.isImpersonatingShipperAdminUser = isImpersonatingShipperAdminUser;
      this.isCustomerService = isCustomerService;

      this._trackingByReferenceUrl = trackingByReferenceUrl;
      this._trackingUrl = trackingUrl;
      this._isShipmentCreationDateTimeVisible = isShipmentCreationDateTimeVisible;
      this.isPrintReturnLabelActionVisible = isPrintReturnLabelActionVisible;
      this.isShipmentGridSettingsVisible = isShipmentGridSettingsVisible;
      this.isShipmentPickupDateTimeVisible = isShipmentPickupDateTimeVisible ||
        createPickupOrdersWithShipmentOrders ||
        initializeDummyParcelsForCollectionRequest;
      this.useMiniportalIntegration = useMiniportalIntegration;
      this.miniportalUrl = miniportalUrl;
      this.allowProtocolsPrintingForSelectedShipments = allowProtocolsPrintingForSelectedShipments;

      this.loadColumnsSettings();
      this.removeHiddenColumns();
    });

    this._shipmentsService.shouldRefreshShipmentOrdersOrParcels.pipe(
      takeUntil(this._destroy$)
    ).subscribe(value => {
      if (this._isShipmentOrdersChangedSubscriptionInitialized) {
        if (value) {
          this.refreshParcels();
          this.setAnyCRExists();
        }
      } else {
        this._isShipmentOrdersChangedSubscriptionInitialized = true;
      }
    });

    this._filterChanges$.pipe(
      takeUntil(this._destroy$),
      debounceTime(600)
    ).subscribe(() => this.applyFilter());

    this.initAutoRefresh();
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  /**
   * Nainicializuje pr�zny filter.
   */
  private getDefaultFilter = (): Models.ParcelsGridViewSearchParameters => {
    const filter: Models.ParcelsGridViewSearchParameters = {
      codFrom: null,
      codTo: null,
      orderByFieldName: "",
      orderAscending: false,
      pageIndex: 0,
      pageSize: this.getStoredParcelsPerPageOrDefault(),
      pickupDate: null,
      parcelNr: null,
      recipientsAddress: null,
      reference: null,
      serviceText: null,
      weightFrom: null,
      weightTo: null,
      serviceCode: null,
      customerAddressCustomId: null,
      customerDetailId: null,
      pickupDateFrom: null,
      pickupDateTo: null,
      creationDateFrom: null,
      creationDateTo: null,
      isStateInLabelsPrintedVisible: false,
      isStateInPreparationVisible: false,
      isStatePreparedForDeliveryVisible: false,
      isStateSentToDPDVisible: false,
      isExported: null,
      senderAddress: null,
      shipmentReference: null,
      lengthFrom: null,
      lengthTo: null,
      widthFrom: null,
      widthTo: null,
      heightFrom: null,
      heightTo: null,
      note: null,
      codPurpose: null,
      showNormalRequest: false,
      showCollectionRequest: false
    };

    // Sort si chceme ponechať, ak nejaký je.
    if (this.filter) {
      filter.orderByFieldName = this.filter.orderByFieldName;
      filter.orderAscending = this.filter.orderAscending;
    }

    return filter;
  }


  setOrderBy(fieldName: string) {
    if (this.filter.orderByFieldName === fieldName) {
      this.filter.orderAscending = !this.filter.orderAscending;
    } else {
      this.filter.orderByFieldName = fieldName;
      this.filter.orderAscending = true;
    }

    this.refreshParcels();
  }


  /**
   * Vr�ti ulo�en� hodnotu po�tu z�znamov na stranu a ak sme e�te �iadnu neukladali,
   * tak vr�ti default hodnotu.
   */
  private getStoredParcelsPerPageOrDefault = (): number => {
    let parcelsPerPage = +localStorage.getItem(PARCELS_PER_PAGE_STORAGE_KEY);

    if (parcelsPerPage == null || parcelsPerPage === 0) {
      parcelsPerPage = DEFAULT_PARCELS_PER_PAGE;
    } else {
      parcelsPerPage = +parcelsPerPage;
    }

    return parcelsPerPage;
  }


  private syncExportedFiltersValues() {
    this.exportedFilter = this.filter.isExported === true;
    this.notExportedFilter = this.filter.isExported === false;
  }


  exportedFilterChange() {
    // xor
    if ((this.exportedFilter || this.notExportedFilter) &&
      !(this.exportedFilter && this.notExportedFilter)) {
      this.filter.isExported = this.exportedFilter;
    } else {
      this.filter.isExported = null;
    }

    this.filterValueChanged();
  }

  clearFilter() {
    this.filter = this.getDefaultFilter();
    this.deliveryStateFilter = false;
    this.syncExportedFiltersValues();
    this.filterValueChanged();
  }


  private loadFilter() {
    const filterJson = localStorage.getItem(FILTER_STORAGE_KEY);

    if (filterJson) {
      let filter = JSON.parse(filterJson);

      filter = StringHelpers.convertDateStringsToDates(filter);

      this.filter = filter;
    } else {
      this.filter = this.getDefaultFilter();
    }

    this.syncExportedFiltersValues();
  }


  private saveFilter() {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(this.filter));
  }


  filterValueChanged() {
    this._filterChanges$.next();
  }


  goToPage(pageIndex: number) {
    this.filter.pageIndex = pageIndex;
    this.refreshParcels();
  }


  handleInputKeypress(event: KeyboardEvent) {
    if (!event.altKey && !event.ctrlKey && event.keyCode === 13) {
      this.applyFilter();
    }
  }


  applyFilter() {
    // Resetujeme str�nkovanie.
    this.filter.pageIndex = 0;

    this.refreshParcels();
  }


  private setIsFiltered(filter: Models.ParcelsGridViewSearchParameters) {
    this.isFiltered =
      !(
        filter.codFrom == null &&
        filter.codTo == null &&
        (filter.parcelNr == null || filter.parcelNr === "") &&
        (filter.pickupDate == null) &&
        (filter.recipientsAddress == null || filter.recipientsAddress === "") &&
        (filter.serviceCode == null || filter.serviceCode === "") &&
        (filter.serviceText == null || filter.serviceText === "") &&
        (filter.reference == null || filter.reference === "") &&
        (filter.weightFrom == null) &&
        (filter.weightTo == null) &&
        (filter.isStateInLabelsPrintedVisible === false) &&
        (filter.isStateInPreparationVisible === false) &&
        (filter.isStatePreparedForDeliveryVisible === false) &&
        (filter.isStateSentToDPDVisible === false) &&
        (filter.senderAddress == null || filter.senderAddress === "") &&
        (filter.shipmentReference == null || filter.shipmentReference === "") &&
        (filter.lengthFrom == null) &&
        (filter.lengthTo == null) &&
        (filter.widthFrom == null) &&
        (filter.widthTo == null) &&
        (filter.heightFrom == null) &&
        (filter.heightTo == null) &&
        (filter.note == null || filter.note === "") &&
        (filter.codPurpose == null || filter.codPurpose === "")
      );
  }


  private setParcelHasCod(parcel: ParcelViewModel) {
    // Medzi skratkami slu�ieb m�me re�azec COD (overujeme v�etko mal� p�smen�).
    parcel.hasCod = parcel.serviceAbbr.toLowerCase().indexOf("cod") >= 0;
  }


  /**
   * Vykon� obnovu zoznamu bal��kov na z�klade aktu�lneho filtra.
   */
  private refreshParcels() {
    if (this._currentAddress == null || !this.filter) {
      return;
    }

    this.isBusy = true;
    this.loadingErrorOccured = false;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_loading_parcels");

    // AK u� prebieha nejak� na��tavanie, zru��me ho.
    this._parcelsLoadingSubscription?.unsubscribe();

    const filter = _.cloneDeep(this.filter);

    this.setIsFiltered(filter);

    // KG na dkg.
    if (filter.weightFrom != null) {
      filter.weightFrom = filter.weightFrom * 100;
    }

    if (filter.weightTo != null) {
      filter.weightTo = filter.weightTo * 100;
    }

    filter.customerDetailId = this._currentAddress.customerDetailId;
    filter.customerAddressCustomId = this._currentAddress.customId;

    this.saveFilter();
    this.resetHiddenColumsFilterValues(filter);

    this._parcelsLoadingSubscription = this._shipmentsService.searchParcels(filter)
      .subscribe(
      (queryResult: Shared.PagedResult<Models.ParcelForGridView>) => {
        this.totalParcelsCount = queryResult.totalSize;
        this.lastPageIndex = Math.ceil(this.totalParcelsCount / this.filter.pageSize) - 1;

        // Pracujeme nad ViewModelom, lebo potrebujeme navy�e atrib�t isSelected.
        const parcelViewModels: ParcelViewModel[] = [];

        queryResult.items.forEach(p => {
          const parcelViewModel = p as ParcelViewModel;

          parcelViewModel.isSelected = false;
          parcelViewModels.push(parcelViewModel);

          parcelViewModel.serviceCode = StringHelpers.join(
            ":",
            parcelViewModel.serviceSetCode,
            parcelViewModel.serviceSetASCode
          );

          this.setParcelHasCod(parcelViewModel);
        });

        this.parcels.next(parcelViewModels);

        // Refreshneme nastavenie "select-all" checkboxu.
        this.checkIfAreAllParcelsSelected();

        this.cleanUpAfterParcelsRefresh();

        if (this.totalParcelsCount > 0 && this.totalParcelsCount <= this.filter.pageIndex * this.filter.pageSize) {
          this.goToPage(this.lastPageIndex);
        }
      }, ex => {
        this.loggingService.logErrorData(ex, "Error loading parcels");

        this.loadingErrorOccured = true;

        this.cleanUpAfterParcelsRefresh();
      });
  }


  private resetHiddenColumsFilterValues(filter: any) {
    const ignoredFilterKeys = this.columnsList.reduce((arr, col) => {
      if (!_.includes(this.displayColumns, col.value)) {
        arr = [...arr, ...col.filterKeys];
      }

      return arr;
    }, []);

    ignoredFilterKeys.forEach(colName => {
      filter[colName] = null;
    });

  }


  private cleanUpAfterParcelsRefresh() {
    this.isBusy = false;
    this._parcelsLoadingSubscription = null;
  }


  toggleSelection(parcel: ParcelViewModel, $event?: Event) {
    parcel.isSelected = !parcel.isSelected;

    if ($event) {
      $event.stopPropagation();
    }

    this.checkIfAreAllParcelsSelected();
  }


  selectionChanged(parcel: ParcelViewModel) {
    this.toggleSelection(parcel);
  }


  /**
   * Over�, �i s� v�etky bal�ky vybran� a pod�a toho nastav� hodnotu areAllParcelsSelected.
   */
  private checkIfAreAllParcelsSelected() {
    this.areAllParcelsSelected = this.parcels.getValue().every(p => p.isSelected);
  }


  /**
   * Prep�nanie, �i s� v�etky z�znamy ozna�en� alebo nie.
   * Ak s� aktu�lne v�etky ozna�en�, tak ich odzna�� a ak
   * nie s�, tak ich v�etky nastav� ako ozna�en�.
   */
  toggleAllSelected() {
    this.areAllParcelsSelected = !this.areAllParcelsSelected;
    this.parcels.getValue().forEach(s => s.isSelected = this.areAllParcelsSelected);
  }

  openColumnSettings() {
    this._modal
      .open(AppTableSettingsDialog, overlayConfigFactory({
        columnsList: this.columnsList,
        displayColumns: _.without(this.displayColumns, ...this.fixedColumns)
      }))
      .result
      .then(result => {
        if (result === "reset") {
          this.displayColumns = ["select", ...this.columnsList.map(col => col.value), "commands", "spacer"];
        } else {
          this.displayColumns = _.uniq(["select", ...result, "commands", "spacer"]);
        }

        this.saveColumnsSettings();
      })
      .catch(() => { });
  }

  private loadColumnsSettings() {
    if (!this.isShipmentGridSettingsVisible) {
      return;
    }

    const tableSettings = localStorage.getItem(TABLE_SETTINGS_STORAGE_KEY);

    if (tableSettings) {
      this.displayColumns = _.uniq(tableSettings.split(","));
    }
  }

  private removeHiddenColumns() {
    if (!this.isShipmentPickupDateTimeVisible) {
      console.log("Removing pickup date column.");
      const pickUpDateCol = this.columnsList.find(col => col.value === "pickupDate");
      _.pull(this.columnsList, pickUpDateCol);
      _.pull(this.displayColumns, "pickupDate");
    }

    if (!this.isShipmentCreationDateTimeVisible) {
      const pickUpDateCol = this.columnsList.find(col => col.value === "creationDateTimeUtc");
      _.pull(this.columnsList, pickUpDateCol);
      _.pull(this.displayColumns, "creationDateTimeUtc");
    }

    // force change
    this.displayColumns = [...this.displayColumns];
  }

  private saveColumnsSettings() {
    localStorage.setItem(TABLE_SETTINGS_STORAGE_KEY, this.displayColumns.join(","));
  }

  /**
   * Find parcel by id from loaded parcels.
   */
  private findParcelById(id: number): Models.Parcel {
    return this.parcels.value.find(parcel => parcel.id === id);
  }


  private getSelectedShipmentIds() {
    return _.chain(this.parcels.getValue())
      .filter(p => p.isSelected && !p.isCollectionRequest)
      .map(p => p.shipmentId)
      .uniq()
      .value();
  }


  private getSelectedShipmentOrderIds(excludeCr: boolean) {
    return _.chain(this.parcels.getValue())
      .filter(p => p.isSelected && (!excludeCr || !p.isCollectionRequest))
      .map(p => p.shipmentId)
      .uniq()
      .value();
  }


  private getSelectedParcelIds() {
    return this.parcels.getValue()
      .filter(p => p.isSelected)
      .map(p => p.id);
  }


  private getSelectedNormalOrderParcelIds() {
    return this.parcels.getValue()
      .filter(p => p.isSelected && !p.isCollectionRequest)
      .map(p => p.id);
  }


  private getSelectedCrOrderIds() {
    return this.parcels.getValue()
    .filter(p => p.isSelected && p.isCollectionRequest)
    .map(p => p.orderId);
  }


  exportSelected() {
    const crOrderIds = this.getSelectedCrOrderIds();
    const normalParcelIds = this.getSelectedNormalOrderParcelIds();

    if (!crOrderIds.length && !normalParcelIds.length) {
      return;
    }

    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_csv_export");

    this._shipmentsService.exportParcelsAsMultipleCsv({
      customerDetailId: this._currentAddress.customerDetailId,
      tenantId: this._tenantId,
      daysOffset: 365,
      exportAllShipments: false,
      manualExportShipmentsSetType: null,
      orderIds: crOrderIds,
      parcelIds: normalParcelIds,
      rememberShipmentsWereExported: false,
      senderCustomId: this._currentAddress.customId,
      csvColumnDelimiter: null,
      csvRowDelimiter: null,
      importProfileId: null
    }).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => { }, () => { });
  }


  printCodTakeoverProtocolForParcel(parcel: ParcelViewModel) {
    if (!parcel?.shipmentId) {
      return;
    }

    this.printCodTakeoverProtocol([parcel.shipmentId]);
  }


  printCodTakeoverProtocolForSelected() {
    const selectedShipmentIds = this.getSelectedShipmentIds();

    if (!selectedShipmentIds.length) {
      return;
    }

    this.printCodTakeoverProtocol(selectedShipmentIds);
  }


  printCodTakeoverProtocol(shipmentIds: number[]) {
    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_cod_takeover_protocol");

    this._shipmentsService.printCodTakeoverProtocolForShipmentIds(shipmentIds).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.refreshParcels(), () => { });
  }

  printAcceptanceProtocolForParcel(parcel: ParcelViewModel) {
    if (!parcel?.shipmentId) {
      return;
    }

    this.printAcceptanceProtocol([parcel.shipmentId]);
  }


  printAcceptanceProtocolForSelected() {
    const selectedShipmentIds = this.getSelectedShipmentIds();

    if (!selectedShipmentIds.length) {
      return;
    }

    this.printAcceptanceProtocol(selectedShipmentIds);
  }


  printAcceptanceProtocol(forOrderIds: number[]) {
    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_acceptance_protocol");

    this._shipmentsService.printAcceptanceProtocolForShipmentIds(forOrderIds).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.refreshParcels(), () => { });
  }


  printLabelsForSelected() {
    const selectedOrderIds = this.getSelectedNormalOrderParcelIds();

    if (!selectedOrderIds.length) {
      return;
    }

    this.printLabels(selectedOrderIds);
  }


  printLabelsForParcel(parcel: ParcelViewModel) {
    if (!parcel?.id || parcel?.isCollectionRequest) {
      return;
    }

    this.printLabels([parcel.id]);
  }


  printLabels(parcelIds: number[]) {
    const filename = `${parcelIds.length === 1 ? this.findParcelById(parcelIds[0])?.parcelNr + "_" : ""}${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`;

    this._shipmentsService.printLabelsForParcelIds(
      parcelIds,
      filename,
      () => {
        this.isBusy = true;
        this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_labels");
      }
    ).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.refreshParcels(), () => { });
  }


  deleteSelected() {
    const selectedParcelIds = this.getSelectedParcelIds();

    if (!selectedParcelIds.length) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("parcels_delete_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;

          this.statusMessage = selectedParcelIds.length === 1
            ? this.localizationService.getLocalizedString("deleting_parcel")
            : this.localizationService.getLocalizedString("deleting_parcels");

          this._shipmentsService.deleteParcels(selectedParcelIds)
            .subscribe(
            () => {
              this._toastr.success(this.localizationService
                .getLocalizedString(this.isSophia ? "deleting_parcels_not_printed_labels_successful" : "deleting_parcels_successful"));

              this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
              this.setAnyCRExists();

              this.isBusy = false;
            },
            ex => {
              this.loggingService.logErrorData(ex, "Error deleting parcels.");

              this._toastr.error(this.localizationService.getLocalizedString("error_deleting_parcels"));

              this.isBusy = false;
            });
        }
      });
  }


  undeleteSelected() {
    const selectedParcelIds = this.getSelectedParcelIds();

    if (!selectedParcelIds.length) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("parcels_undelete_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;

          this.statusMessage = selectedParcelIds.length === 1
            ? this.localizationService.getLocalizedString("undeleting_parcel")
            : this.localizationService.getLocalizedString("undeleting_parcels");

          this._shipmentsService.undeleteParcels(selectedParcelIds)
            .subscribe(
            () => {
              this._toastr.success(this.localizationService
                .getLocalizedString("undeleting_parcels_successful"));

              this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
              this.setAnyCRExists();

              this.isBusy = false;
            },
            ex => {
              this.loggingService.logErrorData(ex, "Error undeleting parcels.");

              this._toastr.error(this.localizationService.getLocalizedString("error_undeleting_parcels"));

              this.isBusy = false;
            });
        }
      });
  }


  private setAnyCRExists() {
    if (!this._currentAddress) {
      return;
    }

    this._shipmentsService.getAnyCRExists(this._currentAddress.customerDetailId, this._currentAddress.customId).subscribe(exists => {
      this.anyCRExists = exists;

      // Ak sme mali vyznačené, že chceme vidieť iba CRka a žiadne už nemáme, tak resetneme filter tak, aby sme vedeli vidieť normálne
      // zásielky a vyvoláme ich načítanie.
      if (!exists && this.filter.showCollectionRequest && !this.filter.showCollectionRequest) {
        this.filter.showCollectionRequest = false;
        this.filter.showNormalRequest = false;

        window.setTimeout(() => { this.refreshParcels(); }, 100);
      }
    });
  }


  executeSelectedGroupAction() {
    switch (this.selectedGroupAction) {
      case Models.Actions.PrintLabels:
        this.printLabelsForSelected();
        break;
      case Models.Actions.Export:
        this.exportSelected();
        break;
      case Models.Actions.Delete:
        this.deleteSelected();
        break;
    }
  }


  /**
   * Event, ktor� treba nabindova� na scroll event scrollovania obsahu gridu.
   */
  scrollGridContent() {
    this.header.nativeElement.scrollLeft = this.content.nativeElement.scrollLeft;
  }


  editShipmentOrder(parcel: Models.ParcelForGridView) {
    this.router.navigate(["/shipments/", parcel.orderId]);

    return false;
  }


  deleteParcel(parcel: Models.ParcelForGridView) {
    if (typeof parcel === "undefined" || parcel == null) {
      return;
    }

    const deleteService = parcel.isCollectionRequest ? this._shipmentsService.deleteOrder(parcel.orderId) : this._shipmentsService.deleteParcel(parcel.id);
    const question = parcel.isCollectionRequest ? "cr_parcel_delete_confirmation_question" : "parcel_delete_confirmation_question";
    const statusMessage = parcel.isCollectionRequest ? "deleting_shipment_order" : "deleting_parcel";
    const successMessage = parcel.isCollectionRequest ? "deleting_shipment_order_successful" : "deleting_parcel_successful";
    const failMessage = parcel.isCollectionRequest ? "error_deleting_shipment_order" : "error_deleting_parcel";

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString(question))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this.statusMessage = this.localizationService.getLocalizedString(statusMessage);

          deleteService.subscribe(
            () => {
              this._toastr.success(this.localizationService.getLocalizedString(successMessage));

              this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
              this.setAnyCRExists();

              this.isBusy = false;
            },
            ex => {
              this.loggingService.logErrorData(ex, "Error deleting parcel.");

              this._toastr.error(`${this.localizationService.getLocalizedString(failMessage)}:\n${this.localizationService.getLocalizedExceptionString(ex)}`);

              this.isBusy = false;
            });
        }
      });

    return false;
  }


  undeleteParcel(parcel: Models.ParcelForGridView) {
    if (typeof parcel === "undefined" || parcel == null) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("parcel_undelete_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this.statusMessage = this.localizationService.getLocalizedString("undeleting_parcel");

          this._shipmentsService.undeleteParcel(parcel.id)
            .subscribe(
            () => {
              this._toastr.success(this.localizationService.getLocalizedString("undeleting_parcel_successful"));

              this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
              this.setAnyCRExists();

              this.isBusy = false;
            },
            ex => {
              this.loggingService.logErrorData(ex, "Error undeleting parcel.");

              this._toastr.error(this.localizationService.getLocalizedString("error_undeleting_parcel"));

              this.isBusy = false;
            });
        }
      });

    return false;
  }


  private getIsAnySelectedShipmentOrderOrderCr() {
    return this.parcels.getValue().some(p => p.isSelected && p.isCollectionRequest);
  }


  setPickupDate() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(false);
    const isAnyShipmentOrderCr = this.getIsAnySelectedShipmentOrderOrderCr();

    if (!selectedOrderIds.length) {
      return;
    }

    this._modal.open(
      SetPickupDateDialogComponent,
      overlayConfigFactory(new Models.SetPickupDateDialogModalContext(selectedOrderIds, isAnyShipmentOrderCr))
    ).result.then(refresh => {
      if (refresh) {
        this.refreshParcels();
      }
    }, () => { });
  }


  getTrackingByParcelNrUrl(parcelNr: string) {
    return this._trackingUrl
      .replace("{parcelNr}", parcelNr)
      .replace("{languageCode}", this.languageCode)
      .replace("{languageCodeIso}", this.languageCodeIso);
  }


  getTrackingByReferenceUrl(reference: string) {
    return this._trackingByReferenceUrl
      .replace("{delisId}", this._delisId)
      .replace("{reference}", encodeURIComponent(reference).replace("%2F", "~2F"))
      .replace("{languageCode}", this.languageCode)
      .replace("{languageCodeIso}", this.languageCodeIso);
  }


  /**
   * Observes total parcels count changes from heartbeat service.
   * Reloades parcels if value is different from current total count.
   * Only runs when no filter is applied.
   */
  private initAutoRefresh() {
    this._heartbeatService.parcelsCount$.pipe(
      /** Ignore first emit. The list is loaded. */
      skip(1),
      takeUntil(this._destroy$),
      filter(count => !this.isFiltered && count !== this.totalParcelsCount)
    ).subscribe(() => {
        this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
      });
  }

  openMiniportal(parcel) {
    this._customClearanceService.getMiniportalToken(parcel.id).subscribe(token => {
      const url = this.miniportalUrl + token;
      window.open(url, "_blank");
    });
  }
}
