import { Subject } from "rxjs";


export class RouteEventEmitter extends Subject<string> {
  constructor() {
    super();
  }


  public emit(value: string) { super.next(value); }
}