import { Component, Input } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as _ from "lodash";

import * as Shared from "../../../shared/index";
import * as ConfigModel from "../../../shared/models/config.models";
import { SettingsService } from "../../services/settings.service";
import { PrinterType, PrintingType, PrinterLanguage, PaperSize } from "../../../shared/models/config.models";
import { PrinterRule, LabelsPrintingTypeCode } from "../../models/settings.model";
import { User } from "../../../user/models/user.model";
import { Address, CustomerDetail } from "../../../shared/models/customer.models";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-printer-rules-editor",
  templateUrl: "./printer-rules-editor.component.html"
})
export class PrinterRulesEditorComponent {
  private _isBusy = false;
  private _printers: string[] = [];
  private _printerTypes: PrinterType[] = [];
  private _printingTypes: PrintingType[] = [];
  private _printerLanguages: PrinterLanguage[] = [];
  private _paperSizes: PaperSize[] = [];
  private _importPaths: string[] = [];
  private _users: User[] = [];
  private _products: ConfigModel.Product[] = [];
  private _addresses: Address[] = [];
  private _customerDetails: CustomerDetail[] = [];

  private _tenantId: number = null;
  private _customerDetailId: number = null;

  private _printerRules: PrinterRule[] = [];

  private _printingTypeCode: string = "";


  private _isEditing = false;

  public get labelsPrintingRule() { return this.printingTypeCode === LabelsPrintingTypeCode; }

  @Input() public set printingTypeCode(value: string) { this._printingTypeCode = value; }
  @Input() public set printerRules(value: PrinterRule[]) { this._printerRules = value; }
  @Input() public set printers(value: string[]) { this._printers = value; }
  @Input() public set printingTypes(value: PrintingType[]) { this._printingTypes = value; }
  @Input() public set printerTypes(value: PrinterType[]) { this._printerTypes = value; }
  @Input() public set printerLanguages(value: PrinterLanguage[]) { this._printerLanguages = value; }
  @Input() public set paperSizes(value: PaperSize[]) { this._paperSizes = value; }
  @Input() public set importPaths(value: string[]) { this._importPaths = value; }
  @Input() public set users(value: User[]) { this._users = value; }
  @Input() public set products(value: ConfigModel.Product[]) { this._products = value; }
  @Input() public set addresses(value: Address[]) { this._addresses = value; }
  @Input() public set tenantId(value: number) { this._tenantId = value; }
  @Input() public set customerDetailId(value: number) { this._customerDetailId = value; }
  @Input() public set customerDetails(value: CustomerDetail[]) { this._customerDetails = value; }


  public get printerRules() { return this._printerRules; }

  public get printingTypeCode() { return this._printingTypeCode; }
  public get printers() { return this._printers; }
  public get printingTypes() { return this._printingTypes; }
  public get printerTypes() { return this._printerTypes; }
  public get printerLanguages() { return this._printerLanguages; }
  public get paperSizes() { return this._paperSizes; }
  public get importPaths() { return this._importPaths; }
  public get users() { return this._users; }
  public get products() { return this._products; }
  public get addresses() { return this._addresses; }
  public get tenantId() { return this._tenantId; }
  public get customerDetailId() { return this._customerDetailId; }
  public get customerDetails() { return this._customerDetails; }

  public get isEditing() { return this._isEditing; }


  public get isBusy() { return this._isBusy; }


  public addPrinterRule() {
    var maxPriority = _.max(this._printerRules.map(r => r.priority));

    var newRule: PrinterRule = {
      id: 0,
      isNew: true,
      isPersistent: false,
      addressId: null,
      printerName: '',
      printerLanguageCode: null,
      customerDetailId: null,
      tenantId: this._tenantId,
      dpi: 203,
      firstLabelPosition: 1,
      isEnabled: true,
      leftOffset: 0,
      topOffset: 0,
      paperSize: null,
      path: "",
      priority: maxPriority + 1,
      printerTypeCode: null,
      printingTypeCode: this._printingTypeCode,
      productId: null,
      userId: null,
      createdByUser: null,
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      modifiedByUser: null,
      localFolder: ''
    }

    this._printerRules.splice(0, 0, newRule);
  }


  public delete(rule: PrinterRule) {
    this._modal
      .confirm()
      .message(this._localizationService.getLocalizedString("confirm_printer_rule_deleting"))
      .okBtn(this._localizationService.getLocalizedString("yes"))
      .cancelBtn(this._localizationService.getLocalizedString("no"))
      .open()
      .result.then(confirmed => {
        if (confirmed) {
          this._isBusy = true;
          this._settingsService.deletePrinterRule(rule.id).subscribe(() => {
            this._isBusy = false;

            this._toastr.success(this._localizationService.getLocalizedString("printer_rule_successfully_deleted"));

            this._printerRules.splice(this._printerRules.indexOf(rule), 1);
          }, ex => {
            let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
            this._log.logErrorData(exceptionInfo, "Error deleting printer rule.");

            this._toastr.error(this._localizationService.getLocalizedString("error_deleting_printer_rule") + ": " + this._localizationService.getLocalizedExceptionString(exceptionInfo));
            this._isBusy = false;
          });
        }
      }, ex => { });
  }


  public increasePriority(rule: PrinterRule) {
    var index = this._printerRules.indexOf(rule);

    if (index > 0) {
      var nextRuleWithHigherPriorty = this._printerRules[index - 1];

      this._isBusy = true;

      this._settingsService.swapPriorityWith(rule.id, nextRuleWithHigherPriorty.id).subscribe(swapped => {
        let higherPriorityValue = nextRuleWithHigherPriorty.priority;

        nextRuleWithHigherPriorty.priority = rule.priority;
        rule.priority = higherPriorityValue;

        this._printerRules = _.orderBy(this._printerRules, "priority", "desc");
        this._isBusy = false;
      }, ex => {
        let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
        this._log.logErrorData(exceptionInfo, "Error changing printer rule priority.");

        this._toastr.error(this._localizationService.getLocalizedString("error_changing_rule_priority") + ": " + this._localizationService.getLocalizedExceptionString(exceptionInfo));
        this._isBusy = false;
      });
    }
  }


  public decreasePriority(rule: PrinterRule) {
    var index = this._printerRules.indexOf(rule);

    if (index < this._printerRules.length - 1) {
      var nextRuleWithHigherPriorty = this._printerRules[index + 1];

      this._isBusy = true;

      this._settingsService.swapPriorityWith(rule.id, nextRuleWithHigherPriorty.id).subscribe(swapped => {
        let higherPriorityValue = nextRuleWithHigherPriorty.priority;

        nextRuleWithHigherPriorty.priority = rule.priority;
        rule.priority = higherPriorityValue;

        this._printerRules = _.orderBy(this._printerRules, "priority", "desc");
        this._isBusy = false;
      }, ex => {
        let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
        this._log.logErrorData(exceptionInfo, "Error changing printer rule priority.");

        this._toastr.error(this._localizationService.getLocalizedString("error_changing_rule_priority") + ": " + this._localizationService.getLocalizedExceptionString(exceptionInfo));
        this._isBusy = false;
      });
    }
  }


  public onEditStart(rule: PrinterRule) {
    this._isEditing = true;
  }


  public onEditEnd(rule: PrinterRule) {
    this._isEditing = false;

    // Ak sme neuložili, tak odstránime.
    if (rule.id === 0) {
      let index = this._printerRules.indexOf(rule);
      if (index > -1) {
        this._printerRules.splice(index, 1);
      }
    }
  }


  constructor(
    private _log: Shared.LoggingService,
    private _settingsService: SettingsService,
    private _modal: Modal,
    private _localizationService: Shared.LocalizationService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _toastr: ToastrService
  ) {
  }
}