import { Component } from "@angular/core";

// import * as Shared from "../index";
import { SELECTOR_PREFIX } from "../prefix";
import { LocalizationService } from "../services/localization.service";
import { Localization } from "../localization.enum";


@Component({
  selector: SELECTOR_PREFIX + "-language-bar",
  templateUrl: "./language-bar.component.html"
})
export class LanguageBarComponent {
  constructor(private _localizationService: LocalizationService) {
  }


  public isLocalizationAllowed(localizationCode: string) {
    return this._localizationService.allowedLocalizations.findIndex(i => i === localizationCode) >= 0;
  }


  public switchToEnglish() {
    this.setLocalizationAndReload(Localization.EN);
  }


  public switchToSlovak() {
    this.setLocalizationAndReload(Localization.SK);
  }


  public switchToCzech() {
    this.setLocalizationAndReload(Localization.CS);
  }


  public switchToDutch() {
    this.setLocalizationAndReload(Localization.NL);
  }


  public switchToBelgianDutch() {
    this.setLocalizationAndReload(Localization.BE);
  }


  public switchToGerman() {
    this.setLocalizationAndReload(Localization.DE);
  }


  public switchToFrench() {
    this.setLocalizationAndReload(Localization.FR);
  }


  public switchToItalian() {
    this.setLocalizationAndReload(Localization.IT);
  }


  private setLocalizationAndReload(localization: Localization) {
    if (this._localizationService.currentLocalization === localization) {
      return;
    }

    this._localizationService.currentLocalization = localization;
    location.reload();
  }

}
