import { Pipe, PipeTransform } from "@angular/core"

import { LocalizationService, ExceptionInfo } from "../index"
import { SELECTOR_PREFIX } from "../prefix";


@Pipe({ name: SELECTOR_PREFIX + "ExceptionInfo" })
export class ExceptionInfoPipe implements PipeTransform {
  public transform(value, ...args: any[]) {

    if (typeof value === "undefined" || value == null) {
      return null;
    }

    var exceptionInfo: ExceptionInfo = value as ExceptionInfo;

    return this._localizationService.getLocalizedExceptionString(exceptionInfo);
  }


  constructor(
    private _localizationService: LocalizationService
  ) {

  }
}