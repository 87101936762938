<div class="settings-category">
  <form #f class="">
    <h2>{{ 'settings_backup_restore' | shpLocalize }}</h2>
    <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>

    <h3 class="pad">{{ 'backup_database' | shpLocalize }}</h3>
    <div class="commands-toolbar">
      <button id="button-backup-database" type="button" (click)="backupDatabase()" class="inline-progress-overlay-container">{{ 'backup_database' | shpLocalize }}</button>
    </div>
  </form>
  <br />
  <form #rdbUpload="ngForm" (submit)="uploadDbFile()">
    <h3 class="pad">{{ 'restore_database' | shpLocalize }}</h3>
    <div class="ctl">
      <label for="selected-file">{{ 'database_zip_file' | shpLocalize }}</label>
      <div class="file-input-group">
        <input id="input-filename" type="text" [(ngModel)]="selectedFileName" name="selectedFileName" readonly="readonly" />
        <input type="file" name="selectedFile" id="selected-file" (change)="selectedFileChanged($event)" />
        <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
      </div>
    </div>
    <div class="ctl buttons">
      <label>&nbsp;</label>
      <button id="button-rdb-upload" [disabled]="!rdbUpload.form.valid || selectedFileName.length === 0" type="submit">{{ 'restore_database' | shpLocalize }}</button>
    </div>
  </form>
</div>