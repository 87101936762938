import { Injectable } from "@angular/core";

import { BehaviorSubject, forkJoin, Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import * as Shared from "./../index";
import * as CustomerModels from "../models/customer.models";
import * as SettingsModel from "../../settings/models/settings.model";

import * as _ from "lodash";


const CURRENT_ADDRESS_ID_STORAGE_KEY = "current-address-id";


/**
 * Service sprístupňujúci aktuálny kontext, v ktorom používateľ pracuje v rámci Shippera.
 */
@Injectable()
export class ContextService {
  private _customerDetails: BehaviorSubject<CustomerModels.CustomerDetail[]> = new BehaviorSubject([]);


  /**
   * Zoznam všetkých CustomerDetails pre tenant aktuálneho požívateľa.
   */
  public customerDetails: Observable<CustomerModels.CustomerDetail[]> = this._customerDetails.asObservable();


  // private _currentCustomerDetail: BehaviorSubject<CustomerModels.CustomerDetail> = new BehaviorSubject(null);

  private _addresses: BehaviorSubject<CustomerModels.Address[]> = new BehaviorSubject([]);


  /**
   * Všetky prístupné adresy pre používateľa.
   */
  public addresses: Observable<CustomerModels.Address[]> = this._addresses.asObservable();



  public _currentAddress: BehaviorSubject<CustomerModels.Address> = new BehaviorSubject(null);


  private _isCustomerLevelTenant: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isCustomerLevelTenant$: Observable<boolean> = this._isCustomerLevelTenant.asObservable();


  /**
   * Aktuálne používaný CustomerDetail.
   * Pre jeho korektné populovanie predpokladáme nastavenie this._customerDetails.
   */
  public currentCustomerDetail: Observable<CustomerModels.CustomerDetail> = this._currentAddress.pipe(
    map(address => {
      if (address == null) {
        return null;
      }

      // Nájdeme si aktuálny customerDetail.
      let currentCustomerDetail = _.find(this._customerDetails.getValue(), cd => cd.id === address.customerDetailId);

      return currentCustomerDetail;
    })
  );



  /**
   * Aktuálne vybraná adresa.
   */
  public currentAddress: Observable<CustomerModels.Address> = this._currentAddress.asObservable();

  private _tenantId: BehaviorSubject<number> = new BehaviorSubject(null);
  private _businessUnitCode: BehaviorSubject<string> = new BehaviorSubject(null);

  public currentCustomerDetailAddresses: Observable<CustomerModels.Address[]> = combineLatest(
    this._currentAddress,
    this._addresses,
  ).pipe(
    map(([currentAddress, addresses]) => addresses.filter(a => a.customerDetailId === currentAddress.customerDetailId))
  );

  /**
   * Aktuálny identigfikátor tenanta.
   */
  public tenantId: Observable<number> = this._tenantId.asObservable();


  /**
   * Aktuálny business unit code tenanta.
   */
  public businessUnitCode: Observable<string> = this._businessUnitCode.asObservable();


  private resetValuesToDefaults = () => {
    this._addresses.next([]);
    this._customerDetails.next([]);
    this._currentAddress.next(null);
    this._tenantId.next(null);
    this._businessUnitCode.next(null);
    this._isCustomerLevelTenant.next(false);
  }


  private getStoredCurrentAddressId = (): number => {
    let value = localStorage.getItem(CURRENT_ADDRESS_ID_STORAGE_KEY);

    let numberValue: number;

    try {
      numberValue = parseInt(value);
    } catch (e) {
      numberValue = null;
    }


    return numberValue;
  }


  private setStoredCurrentAddressId = (value: number) => {
    localStorage.setItem(CURRENT_ADDRESS_ID_STORAGE_KEY, value.toString());
  }


  /**
   * Metóda, ktorú môžeme volať v prípade, ak napríklad nahráme konfiguráciu a checme reflektovať zmeny.
   */
  public forceDataRefresh = () => {
    this._authenticationService.touchUser();
  }


  /**
   * Načíta všetky možné CustomerDetails and Address pre aktuálneho používateľa, ak je non-null.
   * V prípade, že nejaké CustomerDetail a Address máme, tak ak sme si uložili do localStorage
   * identifikátor vybranej adresy, tak sa ju posnažíme vybrať ako aktuálnu. Ináč vyberieme prvú.
   */
  private loadCustomerDetailsAndAddresses = (user: Shared.User) => {
    if (typeof user === "undefined" || user == null) {
      // Používateľ bol pravdepodobne odhlásený.
      // Zresetujeme hodnoty na defaultne.
      this.resetValuesToDefaults();

      return;
    }

    this._tenantId.next(user.tenantId);

    let now = new Date();

    forkJoin([
      this._customerService.getCustomerDetailsForTenant(user.tenantId),
      this._customerService.getConfigurationsForTenant(user.tenantId),
      this._customerService.getIsCustomerLevelTenant(user.tenantId)
    ]).subscribe(
      result => {
        let isCustomerLevelTenant = result[2];
        let configurations = result[1];
        let customerDetails = result[0];

        let activeCustomerDetails: CustomerModels.CustomerDetail[] = [];

        this._isCustomerLevelTenant.next(isCustomerLevelTenant);

        if (configurations != null && customerDetails != null) {
          configurations.forEach(c => {
            // Chceme iba aktívne konfigurácie.
            if (c.stateId !== SettingsModel.ConfigurationState.Active) {
              return;
            }

            // Nájdeme si customer detail.
            let customerDetail = customerDetails.find(cd => cd.id === c.customerDetailId);

            if (customerDetail) {
              this._businessUnitCode.next(c.businessUnitCode);
              activeCustomerDetails.push(customerDetail);
            }
          });
        }

        activeCustomerDetails = _.uniqBy(activeCustomerDetails, a => a.id);

        this._customerDetails.next(activeCustomerDetails);

        if (activeCustomerDetails != null) {
          // Musíme získať aj všetky adresy.
          this._customerService.getCustomerDetailsAddressesForTenant(user.tenantId).subscribe(
            addresses => {
              // Adresy musíme najprv prefiltrovať podľa prípustných CustomerDetail-ov
              // a typu adresy (checme iba A).
              addresses = _.filter(addresses, address => {
                let customerDetailForAddress = activeCustomerDetails.find(cd => cd.id === address.customerDetailId);

                if (customerDetailForAddress) {
                  return address.addressTypeCode === CustomerModels.CustomerAddressTypeCode.Pickup;
                }

                return false;
              });

              // Načítané adresy si uložíme do nášho BehaviorSubjectu,
              // čo automaticky notifikuje subscriberov o zmene.
              this._addresses.next(addresses);

              // Predpokladáme, že máme aspoň nejakú adresu - Shipper Admin by
              // nemal dovoliť vygenerovať konfiguráciu bez aspoň jednej pickup adresy.

              // Ak máme iba jednu adresu, aj ju hneď vyberieme ako aktuálnu.
              if (addresses.length === 1) {
                this._currentAddress.next(addresses[0]);
              } else {
                // Máme viac adries, tak a pozrieme do local storage, či nemám už
                // nejaký identifikátor odložený.
                let storedCurrentAddressId = this.getStoredCurrentAddressId();

                if (storedCurrentAddressId != null) {
                  let currentAddress = _.find(addresses, a => a.id === storedCurrentAddressId);

                  // Ak sme dačo našli, tak to nastavíme ako aktuálnu adresu, ináč
                  // dáme prvú ako aktuálnu.
                  if (currentAddress != null) {
                    this._currentAddress.next(currentAddress);
                  } else {
                    this._currentAddress.next(addresses[0]);
                  }
                } else {
                  // Nič sme ešte nikdy nevyberali, tak vyberieme prvú adresu.
                  this._currentAddress.next(addresses[0]);
                }
              }
            }
          );
        }
      },
      ex => {
        this._logginsService.logErrorData(ex, "Error loading customer details.");

        // TODO: Čo v takomto stave?
      });
  }


  public setCurrentAddress = (address: CustomerModels.Address) => {
    this._logginsService.logDebugData(address, "Current pickup address changed.");

    if (address != null) {
      this.setStoredCurrentAddressId(address.id);
    }

    this._currentAddress.next(address);
  }


  constructor(
    private _authenticationService: Shared.AuthenticationService,
    private _customerService: Shared.CustomerService,
    private _logginsService: Shared.LoggingService
  ) {
    // Primárne počávame na zmenu používateľa.
    this._authenticationService.user.subscribe(user => {
      this.loadCustomerDetailsAndAddresses(user);
    });
  }
}
