import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { ReactiveFormsModule, FormsModule } from "@angular/forms"
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "../shared/shared.module";
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from "primeng/inputtext";

import { CurrentUserComponent } from "./components/current-user.component";
import { LoginComponent } from "./components/login.component";
import { LoginPopupComponent } from "./components/login-popup.component";
import { LoginPageComponent } from "./components/login-page.component";
import { PasswordChangeComponent } from "./components/password-change.component";
import { UserEditorComponent } from "./components/user-editor.component";
import { UserRelatedAddressItemListEditorComponent } from "./components/user-related-address-item-list-editor.component";
import { UserRelatedProductItemListEditorComponent } from "./components/user-related-product-item-list-editor.component";
import { UserRelatedBankAccountItemListEditorComponent } from "./components/user-related-bank-account-item-list-editor.component";

import { UsersService } from "./services/users.service";


let components = [
  CurrentUserComponent,
  LoginComponent,
  LoginPopupComponent,
  LoginPageComponent,
  PasswordChangeComponent,
  UserEditorComponent,
  UserRelatedAddressItemListEditorComponent,
  UserRelatedProductItemListEditorComponent,
  UserRelatedBankAccountItemListEditorComponent,
];

let services = [
  UsersService
]

@NgModule({
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    RouterModule,
    DropdownModule,
    InputTextModule,
    ListboxModule,

  ],
  declarations: [
    ...components
  ],
  providers: [
    ...services
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    
  ]
})
export class UserModule {

}
