import { Injectable } from "@angular/core"
import { Router, CanActivateChild, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs";

import { AuthenticationService, LocalizationService, ShipperSettingsService } from "./index"


@Injectable()
export class GlobalActivationGuard implements CanActivateChild, CanActivate {
  constructor(
    private _authenticationService: AuthenticationService,
    private _localizationService: LocalizationService,
    private _settings: ShipperSettingsService,
    private _router: Router
  ) {

  }
  

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> |
    Promise<boolean> | boolean {

    var p = new Promise<boolean>((resolve, reject) => {
      Promise.all(
        [
          this._authenticationService.reinitializeFromTokenStorage(),
          this._localizationService.initializeLocalizationData()
        ]
      ).then(value => {
        resolve(true);
      }, ex => {
        resolve(true);
      })
    });

    return p;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean>
    | boolean {
    var p = new Promise<boolean>((resolve, reject) => {
      Promise.all(
        [
          this._settings.loadStartupSettings(),
          this._authenticationService.reinitializeFromTokenStorage(),
          this._localizationService.initializeLocalizationData()
        ]
      ).then(value => {
        resolve(true);
      }, ex => {
        resolve(true);
      })
    });

    return p;
  }
}