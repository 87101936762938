<form #part2form="ngForm" name="part2form">
  <div class="mdl-grid compact" *ngIf="!viewOnly" wizardTag="importProfileMappingFile">
    <div class="mdl-cell mdl-cell--12-col ">
      <div class="ctl ">
        <label for="selected-file">{{ 'import_profiles_file_with_fields' | shpLocalize }}</label>
        <div class="file-input-group">
          <input id="input-filename" type="text" [(ngModel)]="selectedFileName" name="selectedFileName" readonly="readonly" />
          <input type="file" name="selectedFile" id="selected-file" (change)="selectedConfigFileChanged($event)" />
          <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
        </div>
      </div>
    </div>
  </div>

  <div class="mdl-grid compact">

    <div class="mdl-cell mdl-cell--3-col">
      {{'import_profiles_field_name_title' | shpLocalize }}
    </div>
    <div class="mdl-cell mdl-cell--3-col">
      <span *ngIf="profile.fileIncludesHeader">
        {{'import_profiles_field_value_title' | shpLocalize }}
      </span>
      <span *ngIf="!profile.fileIncludesHeader">
        {{'import_profiles_field_index_title' | shpLocalize }}
      </span>
    </div>
    <div class="mdl-cell mdl-cell--3-col">
      {{'transformation' | shpLocalize }}
    </div>
    <div class="mdl-cell mdl-cell--3-col">
      {{'import_profiles_constant_title' | shpLocalize }}
    </div>
  </div>
  <div>
    <div class="mdl-grid compact" *ngFor="let group of editorGroups; let groupIndex = index;" [wizardTag]="getEditorGroupWizardTag(group.name)">
      <div class="mdl-cell mdl-cell--12-col ">
        <div id="toggle-group-{{groupIndex}}" class="editor-part-active" (click)="onShowGroup(group, groupIndex)">
          <span *ngIf="group.enabled" class="ic-minus ic-2x"></span>
          <span *ngIf="!group.enabled" class="ic-plus ic-2x"></span>
          <span class="editor-part-name" [class.missingtext]="group.missing__">{{('import_profiles_group_' + group.name) | shpLocalize }}</span>
        </div>
        <div id="part-in-part-{{groupIndex}}" *ngIf="group.enabled" class="editor-part2-group">
          <div class="mdl-grid compact editor-grid-part" *ngFor="let field of group.fieldMappings;let idx = index" [class.missingtext]="field.missing__">
            <div class="mdl-cell mdl-cell--3-col editor-col-part">

              {{('import_profiles_field_' + field.fieldName) | shpLocalize }}
              <span *ngIf="field.isMandatory">*</span>
            </div>
            <div class="mdl-cell mdl-cell--3-col ctl">
              <!-- <wj-combo-box id="field-name-{{idx}}-{{groupIndex}}"
                            [class.missingcontrol]="field.missing__"
                            [isRequired]="field.isMandatory"
                            [isEditable]="true"
                            [(text)]="field.valueField"
                            [itemsSource]="editorValues"
                            (selectedIndexChanged)="onEditorIndexChanged(field, groupIndex, idx)"
                            (textChanged)="onEditorTextChanged(field, groupIndex, idx)"
                            [showDropDownButton]="!viewOnly"
                            [isReadOnly]="viewOnly">
              </wj-combo-box> -->
              <p-dropdown [options]="fieldNameOptions" [(ngModel)]="field.valueField" inputId="field-name-{{idx}}-{{groupIndex}}"
                          [required]="field.isMandatory" [editable]="true" [readonly]="viewOnly"
                          name="field-name-{{idx}}-{{groupIndex}}" (onChange)="onEditorTextChanged(field, groupIndex, idx)">
              </p-dropdown>
            </div>
            <div class="mdl-cell mdl-cell--3-col ctl" [class.missingcontrol]="field.missing__">
              <select id="select-transformation-{{idx}}-{{groupIndex}}" [(ngModel)]="field.transformationId" name="field_transformation_input_{{idx}}_{{groupIndex}}" (change)="onTransformationChanged(field)"  [disabled]="viewOnly">
                <option *ngFor="let transformation of transformations" [ngValue]="transformation.id">{{ transformation.name }}</option>
              </select>
            </div>
            <div class="mdl-cell mdl-cell--3-col ctl" [class.missingcontrol]="field.missing__">
              <input id="input-value-constant-{{idx}}-{{groupIndex}}" name="field_constant_input_{{idx}}_{{groupIndex}}" type="text" [(ngModel)]="field.valueConstant" (change)="onConstChanged(field)" [readOnly]="viewOnly" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>