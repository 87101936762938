import { Directive, forwardRef } from "@angular/core"
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from "@angular/forms"

import * as Shared from "../index"
import { validateAlphaNumeric } from "../validators";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}ValidateAlphanumeric][ngModel],[${Shared.SELECTOR_PREFIX}ValidateAlphanumeric][formControl]`,
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => AlphanumericValidatorDirective), multi: true }
  ]
})
export class AlphanumericValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return validateAlphaNumeric(control);
  }
}