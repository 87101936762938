<div class="d-flex">
  <div *ngFor="let setName of setNames; odd as isOdd; first as isFirst; last as isLast;" class="wizard__chapters-preview__label-box">
    <div
      class="wizard__chapters-preview__label"
      [class.wizard__chapters-preview__label--first]="isFirst"
      [class.wizard__chapters-preview__label--last]="isLast"
      [style.display]="isOdd ? 'none' : 'block'">{{ 'wizard_set_name_' + setName | shpLocalize }}</div>
  </div>
</div>

<div class="d-flex">
  <div *ngFor="let setName of setNames; first as isFirst; last as isLast;" class="wizard__chapters-preview__segment">
    <div class="wizard__chapters-preview__point"></div>
    <div
      class="wizard__chapters-preview__line"
      [class.wizard__chapters-preview__line--first]="isFirst"
      [class.wizard__chapters-preview__line--last]="isLast"></div>
  </div>
</div>

<div class="d-flex">
  <div *ngFor="let setName of setNames; odd as isOdd; first as isFirst; last as isLast;" class="wizard__chapters-preview__label-box">
    <div
      class="wizard__chapters-preview__label"
      [class.wizard__chapters-preview__label--first]="isFirst"
      [class.wizard__chapters-preview__label--last]="isLast"
      [style.display]="!isOdd ? 'none' : 'block'">{{ 'wizard_set_name_' + setName | shpLocalize }}</div>
  </div>
</div>
