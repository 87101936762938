import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";

import * as _ from "lodash";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Subject, Subscription } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { BusinessUnitSettingsService, PagedResult } from "../../shared/index";
import * as Models from "../models/recipient.model";
import { RecipientsService } from "../services/recipients.service";
import { RecipientsExportDialogComponent } from "./recipients-export-dialog.component";


const FILTER_STORAGE_KEY = "recipients-list-filter";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-recipients-compact",
  templateUrl: "./recipients-compact.component.html"
})
export class RecipientsCompactComponent extends Shared.RoutedPageComponentBase implements OnInit {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _recipientsService: RecipientsService,
    private _modal: Modal,
    private _toastr: ToastrService,
    private _context: Shared.ContextService,
    private _buSettings: BusinessUnitSettingsService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public get showRecipientToPickupAddressAsignment() {
    return this._isAdmin && this._areRecipientsAddressSpecific;
  }


  @Input()
  public set canUndelete(value: boolean) {
    this._canUndelete = value;
  }

  public get canUndelete() {
    return this._canUndelete;
  }

  public get showDeleted() {
    return this._canUndelete ? this._showDeleted : false;
  }

  public set showDeleted(value: boolean) {
    this._showDeleted = value;
    this.filter.showDeletedRecipients = this._showDeleted;
    this.loadRecipients();
  }

  private _recipientsLoadingSubscription: Subscription = null;
  private _filterTextSubscription: Subscription = null;
  private _filterSubscription: Subscription = null;
  private componentDestroyed$: Subject<boolean> = new Subject();

  private _isAdmin = false;
  private _areRecipientsAddressSpecific = false;


  @Input() public isBusy = false;

  private _customerDetailId: number = null;
  private _addressId: number = null;


  public filter: Models.RecipientsFilter = null;

  public totalCount = 0;

  public lastPageIndex = 0;

  // Sem si môžeme pred refreshom zoznamu uloťiž označené záznamy, aby sme ich vedeli po
  // aktualizácii zoznamu znovu označiť.
  private _selectedIdsTemporaryStore: number[] = [];

  private _canUndelete = false;

  private _showDeleted = false;


  // Aby sme hodnoty mali prístupné vo view.
  public b2b = Models.CustomerRecipientTypeCode.B2B;
  public b2c = Models.CustomerRecipientTypeCode.B2C;


  public filterText: FormControl = new FormControl("");

  private filter$: BehaviorSubject<Models.RecipientsFilter> = new BehaviorSubject<Models.RecipientsFilter>(null);


  public noRecipientsInDb = false;
  public noRecipientsMatchingFilter = false;


  /**
   * Ak je true, tak UI by malo sprístupniť
   * checkboxy pri každom zázname, aby sa dal
   * označiť aj viac ako jeden záznam.
   */
  public isMultiselectMode = true;



  /**
   * Ak sa pri poslednom načítavaní adresátov vyskytla chyba,
   * tak táto hodnota je true.
   */
  public hasLoadingFailed = false;


  /**
   * Zoznam všetkych adresátorv.
   */
  public recipients: BehaviorSubject<RecipientViewModel[]> = new BehaviorSubject([]);


  /**
   * Filtrovaný zoznam adresátov.
   */
  public filteredRecipients: BehaviorSubject<RecipientViewModel[]> = new BehaviorSubject([]);


  public alphabet: string[] = null;


  /**
   * Adresát, pre ktorého sú aktuálne zobrazené detaily.
   */
  public selectedRecipient: RecipientViewModel = null;


  public areAllVisibleSelected = false;


  private saveSelectedIds() {
    this.recipients.getValue().forEach(r => {
      if (r.isSelected) {
        this._selectedIdsTemporaryStore.push(r.id);
      }
    });
  }


  private restoreSelectedIds() {
    this.recipients.getValue().forEach(r => {
      if (this._selectedIdsTemporaryStore.find(id => id === r.id)) {
        r.isSelected = true;
      }
    });

    this.checkAreAllVisibleSelected();
  }


  private clearSelectedIdsFromTemporaryStore() {
    this._selectedIdsTemporaryStore = [];
  }


  private getDefaultFilter(): Models.RecipientsFilter {
    return {
      orderAscending: null,
      orderByFieldName: null,
      pageIndex: 0,
      pageSize: 30,
      searchText: "",
      extendedSearch: true,
      tenantId: null,
      showDeletedRecipients: this._showDeleted,

      customerRecipientTypeCode: null,
      name: "",
      address: "",
      contactInformation: "",
      referenceNumber: "",

      addressId: this._addressId,
      customerDetailId: this._customerDetailId
    };
  }


  public clearFilter() {
    this.filter = this.getDefaultFilter();
  }


  private loadFilter() {
    const filterJson = localStorage.getItem(FILTER_STORAGE_KEY);

    if (filterJson) {
      const filter = JSON.parse(filterJson);

      this.filter = filter;
    } else {
      this.filter = this.getDefaultFilter();
    }

    this.filterText.setValue(this.filter.searchText);

    this.filter.addressId = this._addressId;
    this.filter.customerDetailId = this._customerDetailId;
  }


  private saveFilter() {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(this.filter));
  }


  public toggleMultiSelect = () => {
    this.isMultiselectMode = !this.isMultiselectMode;
  }


  public select = (recipient: RecipientViewModel) => {
    this.selectedRecipient = recipient;
  }


  private loadRecipients = () => {
    this.isBusy = true;
    this.hasLoadingFailed = false;

    if (this._recipientsLoadingSubscription != null) {
      this._recipientsLoadingSubscription.unsubscribe();
    }

    this._recipientsService.getFilteredRecipients(this.filter).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((result: PagedResult<Models.RecipientForGridView>) => {
      const recipients = result.items;

      this.totalCount = result.totalSize;
      if (this.filter) {
        this.lastPageIndex = Math.ceil(this.totalCount / this.filter.pageSize) - 1;
      }

      const recipientViewModels: RecipientViewModel[] = [];

      for (const recipient of recipients) {
        const recipientViewModel = recipient as RecipientViewModel;

        recipientViewModel.isSelected = false;

        recipientViewModels.push(recipientViewModel);
      }

      this.recipients.next(recipientViewModels);

      this.restoreSelectedIds();
      this.clearSelectedIdsFromTemporaryStore();

      this.noRecipientsInDb = this.filter.searchText == "" && result.items.length === 0;
      this.noRecipientsMatchingFilter = this.filter.searchText != "" && result.items.length === 0;

      this.checkAreAllVisibleSelected();

      this.isBusy = false;

      // Korekcia, ak sme preliazli cez koniec.
      if (this.totalCount > 0 && this.totalCount <= this.filter.pageIndex * this.filter.pageSize) {
        this.goToPage(this.lastPageIndex);
      }
    }, ex => {
      this.loggingService.logErrorData(ex, "Error loading recipients");

      this.hasLoadingFailed = true;

      this.isBusy = false;
    });
  }


  private emitFilterChange() {
    const oldValue = this.filter;
    this.filter = {} as any;

    Object.assign(this.filter, oldValue);

    this.filter$.next(this.filter);
  }


  public goToPage = (pageIndex: number) => {
    this.filter.pageIndex = pageIndex;
    this.emitFilterChange();
  }


  private setFilterListener = () => {
    // Kontrolku na filtrovanie musíme takto zabaliť, lebo interne
    // nepožíva BehaviourSubject, takže prvé naplnenie hodnoty pre combineLatest
    // v setFilterListener anstane až po zmene textu v kontrolke.
    // Takto to funguje v poriadku.
    if (this._filterTextSubscription) {
      this._filterTextSubscription.unsubscribe();
    }

    this._filterTextSubscription = this.filterText.valueChanges.pipe(
      debounceTime(400)
    ).subscribe(val => {
        const text = val as string;
        this.filter.searchText = text;
        this.emitFilterChange();
      });

    if (this._filterSubscription) {
      this._filterSubscription.unsubscribe();
    }

    this._filterSubscription = this.filter$.subscribe(() => {
      this.saveFilter();
      this.loadRecipients();
    });
  }


  private initialize() {
    combineLatest([
      this.authenticationService.isAdmin$,
      this._buSettings.getAreRecipientsAddressSpecific()
    ]).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(result => {
      this._isAdmin = result[0];
      this._areRecipientsAddressSpecific = result[1];
    });

    return this._context.currentAddress.pipe(
      filter(a => a != null),
      takeUntil(this.componentDestroyed$)
    ).subscribe(a => {
      this._customerDetailId = a.customerDetailId;
      this._addressId = a.id;

      this.loadFilter();

      this.setFilterListener();

      // this.loadRecipients();
    });
  }


  public ngOnInit() {
    this.initialize();

    this._recipientsService.shouldRefreshRecipients.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(_ => {
      this.loadRecipients();
    });
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this._filterTextSubscription) {
      this._filterTextSubscription.unsubscribe();
    }

    if (this._filterSubscription) {
      this._filterSubscription.unsubscribe();
    }

    if (this._recipientsLoadingSubscription) {
      this._recipientsLoadingSubscription.unsubscribe();
    }
  }


  public editRecipient = (recipient: RecipientViewModel) => {
    this.router.navigate(["/recipients/" + recipient.id]);
  }


  public deleteRecipient = (recipient: RecipientViewModel) => {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("recipient_delete_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this._recipientsService.deleteRecipient(recipient.id)
            .subscribe(
            () => {
              // Odstránime adresáta zo zoznamu.
              const recipients = this.recipients.getValue();
              const index = recipients.indexOf(recipient);

              if (index > -1) {
                recipients.splice(index, 1);
              }

              // Nastavíme aktuálne označený záznam na žiaden.
              this.selectedRecipient = null;

              this.isBusy = false;

              this._toastr.success(this.localizationService.getLocalizedString("recipient_deleted"));

              // Obnovíme zoznam.
              this.saveSelectedIds();

              this.loadRecipients();
            },
            ex => {
              this.isBusy = false;
              this._toastr.error(this.localizationService.getLocalizedString("recipient_delete_failed"));

              this.loggingService.logErrorData(ex, "Recipient delete failed");
            });
        }
      },
      () => {
        // ... nič nerobíme.
      });
  }


  public undeleteRecipient = (recipient: RecipientViewModel) => {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("recipient_undelete_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this._recipientsService.undeleteRecipient(recipient.id)
            .subscribe(
            () => {
              // Odstránime adresáta zo zoznamu.
              const recipients = this.recipients.getValue();
              const index = recipients.indexOf(recipient);

              if (index > -1) {
                recipients.splice(index, 1);
              }

              // Nastavíme aktuálne označený záznam na žiaden.
              this.selectedRecipient = null;

              this.isBusy = false;

              this._toastr.success(this.localizationService.getLocalizedString("recipient_undeleted"));

              // Obnovíme zoznam.
              this.saveSelectedIds();

              this.loadRecipients();
            },
            ex => {
              this.isBusy = false;
              this._toastr.error(this.localizationService.getLocalizedString("recipient_undelete_failed"));

              this.loggingService.logErrorData(ex, "Recipient undelete failed");
            });
        }
      },
      () => {
        // ... nič nerobíme.
      });
  }


  public duplicateRecipient = () => {
    this.showNotImplementedAlert();
  }


  public createNewShipmentForRecipient = (recipient: RecipientViewModel) => {
    this.router.navigate(["/shipments/0"], { queryParams: { recipientId: recipient.id } });
  }


  private showNotImplementedAlert = () => {
    this._modal.alert().body(this.localizationService.getLocalizedString("not_implemented_yet")).open();
  }


  public handleKeyPress = (event: KeyboardEvent, recipient: RecipientViewModel) => {
    if (!event.altKey && !event.ctrlKey && event.keyCode === 13) {
      this.select(recipient);

    } else if (!event.altKey && !event.ctrlKey && event.keyCode === 32 && this.isMultiselectMode) {
      // Zmena výberu v multi-select móde (mód musí byť aktívny).
      this.toggleSelection(recipient);
    }
  }


  public toggleSelection = (recipient: RecipientViewModel) => {
    recipient.isSelected = !recipient.isSelected;

    this.checkAreAllVisibleSelected();
  }


  public selectionChanged = ($event: Event, recipient: RecipientViewModel) => {
    this.toggleSelection(recipient);
  }


  private checkAreAllVisibleSelected = () => {
    const areAllSelected = _.every(this.recipients.getValue(), (r: RecipientViewModel) => r.isSelected);

    this.areAllVisibleSelected = areAllSelected;
  }


  public toggleAllVisibleSelected = () => {
    const recipients = this.recipients.getValue();

    if (this.areAllVisibleSelected) {
      // Všetky záznamy sú označené, tak ich všetky odznačíme.
      recipients.forEach(r => { r.isSelected = false; });

      this.areAllVisibleSelected = false;
    } else {
      // Nie všetky sú označené, tak ich všetky nastavíme na označené.
      recipients.forEach(r => { r.isSelected = true; });

      this.areAllVisibleSelected = true;
    }
  }


  public cancelMultiSelectOperations = () => {
    this.isMultiselectMode = false;
  }


  private getSelectedRecipientIds() {
    const selectedIds: number[] = [];

    this.recipients.getValue().forEach(r => {
      if (r.isSelected) {
        selectedIds.push(r.id);
      }
    });

    return selectedIds;
  }


  public exportRecipients = () => {
    const selectedIds = this.getSelectedRecipientIds();

    if (selectedIds.length === 0) {
      return; // Nič nerobíme, ak nič nie je označené.
    }

    const modalContext = new Models.ExportDialogModalContext(selectedIds);

    this._modal.open(RecipientsExportDialogComponent, overlayConfigFactory(modalContext));
  }


  public deleteRecipients = () => {
    const selectedIds = this.getSelectedRecipientIds();

    if (selectedIds.length === 0) {
      return; // Nič nerobíme, ak nič nie je označené.
    }


    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("recipients_delete_confirmation_question"))
      .open()
      .result.then(value => {
        if (value) {
          this.isBusy = true;
          this._recipientsService.deleteRecipients(selectedIds)
            .subscribe(
            () => {
              // Odstránime adresáta zo zoznamu.
              const recipients = this.recipients.getValue();

              selectedIds.forEach(selectedId => {
                const index = _.findIndex(recipients, r => r.id === selectedId);

                if (index > -1) {
                  recipients.splice(index, 1);
                }
              });


              // Nastavíme aktuálne označený záznam na žiaden.
              if (this.selectedRecipient) {
                if (_.find(selectedIds, id => id === this.selectedRecipient.id)) {
                  this.selectedRecipient = null;
                }
              }

              this.isBusy = false;

              this._toastr.success(this.localizationService.getLocalizedString("recipients_deleted"));

              // Obnovíme zoznam.
              this.saveSelectedIds();
              this.loadRecipients();
            },
            ex => {
              this.isBusy = false;
              this._toastr.error(this.localizationService.getLocalizedString("recipients_delete_failed"));

              this.loggingService.logErrorData(ex, "Recipient delete failed");
            });
        }
      },
      () => {
        // ... nič nerobíme.

      });
  }
}

export interface RecipientViewModel extends Models.RecipientForGridView {
  isSelected: boolean;
}
