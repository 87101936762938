import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";

import { filter, finalize } from "rxjs/operators";
import { combineLatest } from "rxjs";

import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import { ToastrService } from "ngx-toastr";

import { saveAs } from "file-saver";
import { getMimeType, fixBinary } from "../../../shared/file-helpers";

import * as Shared from "../../../shared/index";
import * as SettingsModel from "../../models/settings.model";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { ShipmentService } from "../../../shipments/services/shipments.service";
import * as CustomerModel from "../../../shared/models/customer.models";
import { BankAccountsUploadDialogComponent } from "../bank-accounts-upload-dialog.component";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-bank-accounts-settings",
  templateUrl: "./bank-accounts-settings.component.html"
})
export class BankAccountsSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _shipmentsService: ShipmentService,
    private _skdataConfigService: SkdataConfigService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _customerService: Shared.CustomerService,
    private _modal: Modal,
    toastr: ToastrService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }


  private _tenantId: number = null;
  private _currentAddress: CustomerModel.Address = null;


  public bankAccounts: CustomerModel.BankAccountForGridView[] = null;

  public showAttachments = [];

  public isImpersonatingShipperAdminUser = false;

  public canAddBankAccount = false;


  private loadBankAccounts() {
    this.isBusy = true;
    this._customerService.getCustomerDetailAllBankAccounts(this._tenantId, this._currentAddress.customerDetailId).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(
        result => {
          this.bankAccounts = result;
        }, ex => {
          let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
          this.loggingService.logErrorData(ex);
          this.toastr.error(this.localizationService.getLocalizedString("error_loading_bank_accounts") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
        });
  }

  public activate(bankAccount: CustomerModel.BankAccountForGridView) {
    this.isBusy = true;

    this._customerService.activateCustomerBankAccount(this.tenantId, this.customerDetailId , bankAccount.id).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(deleted => {
        this.loadBankAccounts();

        this.toastr.success(this.localizationService.getLocalizedString("bank_account_successfully_activated"));
      }, ex => {
        let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(ex);

        this.toastr.error(this.localizationService.getLocalizedString("error_activating_bank_account") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
      });
  }

  public deactivate(bankAccount: CustomerModel.BankAccountForGridView) {
    this.isBusy = true;

    this._customerService.deactivateCustomerBankAccount(this.tenantId, this.customerDetailId , bankAccount.id).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(deleted => {
        this.loadBankAccounts();

        this.toastr.success(this.localizationService.getLocalizedString("bank_account_successfully_deactivated"));
      }, ex => {
        let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
        this.loggingService.logErrorData(ex);

        this.toastr.error(this.localizationService.getLocalizedString("error_deactivating_bank_account") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
      });
  }

  public delete(bankAccount: CustomerModel.BankAccountForGridView) {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("confirm_bank_account_deleting"))
      .open().result.then(confirmed => {
        if (confirmed) {
          this.isBusy = true;

          this._customerService.deleteCustomerBankAccount(this.tenantId, this.customerDetailId , bankAccount.id).pipe(
            finalize(() => this.isBusy = false)
          ).subscribe(deleted => {
              this.loadBankAccounts();

              this.toastr.success(this.localizationService.getLocalizedString("bank_account_successfully_deleted"));
            }, ex => {
              let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
              this.loggingService.logErrorData(ex);

              this.toastr.error(this.localizationService.getLocalizedString("error_deleting_bank_account") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
            });
        }
      }, ex => { });
  }

  public undelete(bankAccount: CustomerModel.BankAccountForGridView) {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("confirm_bank_account_undeleting"))
      .open().result.then(confirmed => {
        if (confirmed) {
          this.isBusy = true;

          this._customerService.undeleteCustomerBankAccount(this.tenantId, this.customerDetailId , bankAccount.id).pipe(
            finalize(() => this.isBusy = false)
          ).subscribe(undeleted => {
              this.loadBankAccounts();

              this.toastr.success(this.localizationService.getLocalizedString("bank_account_successfully_undeleted"));
            }, ex => {
              let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
              this.loggingService.logErrorData(ex);

              this.toastr.error(this.localizationService.getLocalizedString("error_undeleting_bank_account") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
            });
        }
      }, ex => { });
  }

  setRowColoring(scan: CustomerModel.BankAccount): string {
    if (scan.stateId === "Deleted") {
      return "deletedRow";
    } else {
      return "";
    }
  }


  public downloadBankAccountAcceptation(bankAccount: CustomerModel.BankAccountForGridView) {
    this.isBusy = true;

    this._customerService.getBankAccountAcceptation(this._currentAddress.customerDetailId, bankAccount.id)
      .subscribe(bankAccountAcceptation => {
        this.isBusy = false;

        if (bankAccountAcceptation) {
          const blob = new Blob([(<any>bankAccountAcceptation)], { type: '"application/pdf' });
          saveAs(blob, 'bank-account-acceptation.pdf');
        }
      },
      ex => {
        this.isBusy = false;
        this.loggingService.logErrorData(ex, "Download attachment failed.");

        this.toastr.error(this.localizationService.getLocalizedString("error_downloading_attachment"));
      });
  }


  public upload(bankAccount: CustomerModel.BankAccountForGridView) {
    this._modal.open(BankAccountsUploadDialogComponent, overlayConfigFactory(
      new SettingsModel.BankAccountsUploadDialogModalContext(this._currentAddress.customerDetailId, this.tenantId, bankAccount.id)))
      .result.then(refresh => {
        if (refresh) {
          this.loadBankAccounts();
        }
      }, ex => { });
  }


  public downloadAttachment(bankAccountId: number, attachmentId: number) {
    this.isBusy = true;

    this._customerService.getBankAccountAttachment(this._currentAddress.customerDetailId, this.tenantId, bankAccountId, attachmentId)
      .subscribe(bankAccountAttachment => {
        this.isBusy = false;

        if (bankAccountAttachment.attachment && bankAccountAttachment.fileName.length > 0) {
          const filename = bankAccountAttachment.fileName;
          const ext = filename.split('.').pop();

          const mimeType = getMimeType(ext);

          const base64: any = bankAccountAttachment.attachment;
          const binary = fixBinary(atob(base64));

          const blob = new Blob([binary], { type: mimeType });

          saveAs(blob, filename);
        }
      },
      ex => {
        this.isBusy = false;
        this.loggingService.logErrorData(ex, "Download attachment failed.");

        this.toastr.error(this.localizationService.getLocalizedString("error_downloading_attachment"));
      });
  }


  public deleteAttachment(bankAccountId: number, attachmentId: number) {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("confirm_attachment_deleting"))
      .open().result.then(confirmed => {
        if (confirmed) {
          this.isBusy = true;

          this._customerService.deleteBankAccountAttachment(this._currentAddress.customerDetailId, this.tenantId, bankAccountId, attachmentId).pipe(
            finalize(() => this.isBusy = false)
          ).subscribe(deleted => {
              this.loadBankAccounts();

              this.toastr.success(this.localizationService.getLocalizedString("attachment_successfully_deleted"));
            }, ex => {
              let exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
              this.loggingService.logErrorData(ex);

              this.toastr.error(this.localizationService.getLocalizedString("error_deleting_attachment") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));
            });
        }
      }, ex => { });
  }


  public toogleAttachments(id) {
    if (this.showAttachments[id]) {
      this.showAttachments[id] = false;
    } else {
      this.showAttachments[id] = true;
    }
  }


  public ngOnInit() {
    combineLatest([
      this.contextService.tenantId,
      this.contextService.currentAddress
    ]).pipe(
      filter(value => {
        return value[0] != null && value[1] != null;
      })
    ).subscribe(value => {
      this._tenantId = value[0];
      this._currentAddress = value[1];

      this._customerService.canAddBankAccount(this._tenantId, this._currentAddress.customerDetailId).subscribe(result => {
        this.canAddBankAccount = result;
      });

      this.loadBankAccounts();
    });

    super.ngOnInit();

    // Zistíme, či sme prihlásený ako administrátor v kontexte zákazníka
    this.authenticationService.impersonatingShipperAdminUserLogin$.subscribe((login: string) => {
      this.isImpersonatingShipperAdminUser = login != null;
    });
  }
}