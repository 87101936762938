import { Routes } from "@angular/router";

import { AuthorizationGuard } from "../shared/authorization-guard";
import { AppChangeLogComponent } from "./components/app-change-log.component";
import { AppManualComponent } from "./components/app-manual.component";
import { ContactComponent } from "./components/contact.component";
import { FaqPageComponent } from "./components/faq-page.component";
import { SupportComponent } from "./components/support.component";

export const SUPPORT_ROUTES: Routes = [
  {
    path: "support",
    component: SupportComponent,
    canActivate: [AuthorizationGuard],
    children: [
      { path: "manual", component: AppManualComponent, canActivate: [AuthorizationGuard]},
      { path: "faq", component: FaqPageComponent, canActivate: [AuthorizationGuard]},
      { path: "contact", component: ContactComponent, canActivate: [AuthorizationGuard]},
      { path: "change-log", component: AppChangeLogComponent, canActivate: [AuthorizationGuard]},
      { path: "", redirectTo: "manual", pathMatch: "full" }
    ]
  }
];
