import { Component, OnDestroy, Input, ViewChild, Output, EventEmitter, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from "@angular/forms";

import { Subscription } from "rxjs";

import { ToastrService } from "ngx-toastr";

import * as Shared from "../../../shared/index";
import * as ConfigModel from "../../../shared/models/config.models";
import { SettingsService } from "../../services/settings.service";
import { PrinterType, PrintingType, PrinterLanguage, PaperSize } from "../../../shared/models/config.models";
import { PrinterRule, LabelsPrintingTypeCode } from "../../models/settings.model";
import { User } from "../../../user/models/user.model";
import { Address, CustomerDetail } from "../../../shared/models/customer.models";
import * as _ from 'lodash';

@Component({
  selector: Shared.SELECTOR_PREFIX + "-printer-rule-editor",
  templateUrl: "./printer-rule-editor.component.html"
})
export class PrinterRuleEditorComponent implements OnDestroy {
  @ViewChild("firstControl")
  private _firstControl: ElementRef;

  private _printers: string[] = [];
  private _printerTypes: PrinterType[] = [];
  private _printingTypes: PrintingType[] = [];
  private _printerLanguages: PrinterLanguage[] = [];
  private _paperSizes: PaperSize[] = [];
  private _importPaths: string[] = [];
  private _users: User[] = [];
  private _products: ConfigModel.Product[] = [];
  private _addresses: Address[] = [];
  private _customerDetails: CustomerDetail[] = [];
  private _filteredAddresses: Address[] = [];
  private _componentId = "";;

  private _customerDetailIdChangeSubscription: Subscription = null;

  private _isFirst = false;
  private _isLast = false;

  private _printerRule: PrinterRule = null;
  private _form: FormGroup = null;

  private _isEditEnabled = true;

  private _printingTypeCode: string = "";

  private _isEdited = false;
  private _isBusy = false;


  private startEditing() {
    this.initializeForm();

    this._isEdited = true;
    this.onEditStart.emit(this._printerRule);
  }


  private selectFirstControl() {
    window.setTimeout(() => {
      if (this._firstControl) {
        this._firstControl.nativeElement.focus();
      }
    }, 10);
  }


  @Input() public set printerRule(value: PrinterRule) {
    if (this._printerRule != value) {
      this._printerRule = value;

      // Keď prvotne zobrazíme nový záznam, skúsime dať focus do prvého editačného elementu.
      if (value && value.id === 0) {
        window.setTimeout(() => {
          this.startEditing();
          this.selectFirstControl();
        }, 10);
      }

      this.setPrinterNameAndLocalFolderRequiredStatus();
    }
  }

  @Input() public set printingTypeCode(value: string) { this._printingTypeCode = value; }
  @Input() public set printers(value: string[]) { this._printers = value; }
  @Input() public set printingTypes(value: PrintingType[]) { this._printingTypes = value; }
  @Input() public set printerTypes(value: PrinterType[]) { this._printerTypes = value; }
  @Input() public set printerLanguages(value: PrinterLanguage[]) { this._printerLanguages = value; }
  @Input() public set paperSizes(value: PaperSize[]) { this._paperSizes = value; }
  @Input() public set importPaths(value: string[]) { this._importPaths = value; }
  @Input() public set users(value: User[]) { this._users = value; }
  @Input() public set products(value: ConfigModel.Product[]) { this._products = value; }
  @Input() public set addresses(value: Address[]) { this._addresses = value; }
  @Input() public set customerDetails(value: CustomerDetail[]) { this._customerDetails = value; }

  @Input() public set isFirst(value: boolean) { this._isFirst = value; }
  @Input() public set isLast(value: boolean) { this._isLast = value; }

  @Input() public set isEditEnabled(value: boolean) { this._isEditEnabled = value; }

  @Input() public set componentId(value: string) { this._componentId = value; }


  public get labelsPrintingRule() { return this.printingTypeCode === LabelsPrintingTypeCode; }


  public get printerRule() { return this._printerRule; }

  public get printingTypeCode() { return this._printingTypeCode; }
  public get printers() { return this._printers; }
  public get printingTypes() { return this._printingTypes; }
  public get printerTypes() { return this._printerTypes; }
  public get printerLanguages() { return this._printerLanguages; }
  public get paperSizes() { return this._paperSizes; }
  public get importPaths() { return this._importPaths; }
  public get users() { return this._users; }
  public get products() { return this._products; }
  public get addresses() { return this._addresses; }
  public get filteredAddresses() { return this._filteredAddresses; }
  public get customerDetails() { return this._customerDetails; }

  public get isFirst() { return this._isFirst; }
  public get isLast() { return this._isLast; }

  public get isEdited() { return this._isEdited; }

  public get isEditEnabled() { return this._isEditEnabled; }

  public get isBusy() { return this._isBusy; }


  public get componentId() { return this._componentId; }


  public get form() { return this._form; }

  get isLocalFolderInputVisible(): boolean {
    return this._form.get('printerTypeCode').value === 'PdfLocal';
  }

  @Output() public onDelete: EventEmitter<PrinterRule> = new EventEmitter();
  @Output() public onIncreasePriority: EventEmitter<PrinterRule> = new EventEmitter();
  @Output() public onDecreasePriority: EventEmitter<PrinterRule> = new EventEmitter();
  @Output() public onEditStart: EventEmitter<PrinterRule> = new EventEmitter();
  @Output() public onEditEnd: EventEmitter<PrinterRule> = new EventEmitter();


  public delete() { this.onDelete.emit(this.printerRule); }
  public increasePriority() { this.onIncreasePriority.emit(this.printerRule); }
  public decreasePriority() { this.onDecreasePriority.emit(this.printerRule); }

  private requiredIfZebraPrintingSelected(control: AbstractControl): ValidationErrors | null {
    let value = control.value;

    if (!this._form) {
      return null;
    }

    let printerTypeCode = this._form.controls.printerTypeCode.value;

    if (printerTypeCode === "Zebra" && !value) {
      return { "required": true }
    }

    return null;
  }


  private requiredIfNonZebraPrintingSelected(control: AbstractControl): ValidationErrors | null {
    let value = control.value;

    if (!this._form) {
      return null;
    }

    let printerTypeCode = this._form.controls.printerTypeCode.value;

    if (printerTypeCode != "Zebra" && !value) {
      return { "required": true }
    }

    return null;
  }


  private initializeForm() {
    this.filterAddresses(this._printerRule.customerDetailId);

    var that = this;

    this._form = this._fb.group({
      customerDetailId: [this._printerRule.customerDetailId],
      userId: [this._printerRule.userId],
      addressId: [this._printerRule.addressId],
      productId: [this._printerRule.productId],
      path: [this._printerRule.path],
      isEnabled: [this._printerRule.isEnabled],
      printerTypeCode: [this._printerRule.printerTypeCode, [Validators.required]],
      printerLanguageCode: [this._printerRule.printerLanguageCode, this.labelsPrintingRule ? [that.requiredIfZebraPrintingSelected.bind(that)] : []],
      printerName: [this._printerRule.printerName, [Validators.required]],
      dpi: [this._printerRule.dpi, this.labelsPrintingRule ? [Validators.required] : []],
      paperSize: [this._printerRule.paperSize, this.labelsPrintingRule ? [that.requiredIfNonZebraPrintingSelected.bind(that)] : []],
      leftOffset: [this._printerRule.leftOffset, this.labelsPrintingRule ? [Validators.min(-500), Validators.max(500)] : []],
      topOffset: [this._printerRule.topOffset, this.labelsPrintingRule ? [Validators.min(-500), Validators.max(500)] : []],
      firstLabelPosition: [this._printerRule.firstLabelPosition, this.labelsPrintingRule ? [Validators.min(1), Validators.max(4)] : []],
      localFolder: [this._printerRule.localFolder]
    });

    this._form.controls.printerTypeCode.valueChanges.subscribe(() => {
      const controls = this._form.controls;

      controls.paperSize.updateValueAndValidity();
      controls.printerLanguageCode.updateValueAndValidity();

      this.setPrinterNameAndLocalFolderRequiredStatus();
    });

    this.clearCustomerDetailIdChangeSubscription();

    this._customerDetailIdChangeSubscription = this._form.controls.customerDetailId.valueChanges.subscribe(id => {
      this.filterAddresses(id);

      // Musíme overiť, či aktuálne vybraná adresa spadá pod vybraného zákazníka, ak nie, tak nastavíme addressId na null.
      let addressId = this._form.controls.addressId.value;
      if (addressId) {
        let address = this._filteredAddresses.find(a => a.id === addressId);

        if (!address) {
          this._form.controls.addressId.setValue(null);
        }
      }
    });

    this.setPrinterNameAndLocalFolderRequiredStatus();
  }

  private setPrinterNameAndLocalFolderRequiredStatus() {
    if (this._form) {
      const controls = this._form.controls;

      let requiredControl;
      let notRequiredControl;
      if (controls.printerTypeCode.value === "PdfLocal") {
        notRequiredControl = controls.printerName;
        requiredControl = controls.localFolder
      } else {
        notRequiredControl = controls.localFolder;
        requiredControl = controls.printerName;
      }

      requiredControl.setValidators([Validators.required]);
      notRequiredControl.setValidators([]);

      requiredControl.updateValueAndValidity();
      notRequiredControl.updateValueAndValidity();

      this._form.updateValueAndValidity();
    }
  }


  private filterAddresses(customerDetailId: number) {
    if (!customerDetailId) {
      this._filteredAddresses = this._addresses;
    } else {
      this._filteredAddresses = this._addresses.filter(a => a.customerDetailId === customerDetailId);
    }
  }


  public edit() {
    this.startEditing();
  }


  public cancelEdit() {
    this._isEdited = false;
    this.onEditEnd.emit(this._printerRule);
  }


  public save() {
    this._isBusy = true;

    let printerRuleEdited = _.cloneDeep(this._printerRule);
    Object.assign(printerRuleEdited, this._form.value);

    this._settingsService.savePrinterRule(printerRuleEdited).subscribe(savedPrinterRule => {
      Object.assign(this._printerRule, savedPrinterRule);

      this._isEdited = false;

      this.onEditEnd.emit(this._printerRule);
      this._isBusy = false;
    }, ex => {
      let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

      this._log.logErrorData(ex);

      this._toastr.error(this._localization.getLocalizedString("error_saving_printer_rule") + ": " + this._localization.getLocalizedExceptionString(exceptionInfo));

      this._isBusy = false;
    });
  }


  private clearCustomerDetailIdChangeSubscription() {
    if (this._customerDetailIdChangeSubscription) {
      this._customerDetailIdChangeSubscription.unsubscribe();
      this._customerDetailIdChangeSubscription = null;
    }
  }


  public ngOnDestroy() {
    this.clearCustomerDetailIdChangeSubscription();
  }

  constructor(
    private _log: Shared.LoggingService,
    private _settingsService: SettingsService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _localization: Shared.LocalizationService,
    private _fb: FormBuilder,
    private _toastr: ToastrService
  ) {
  }
}
