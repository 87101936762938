import { Injectable } from "@angular/core";
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { from, Observable, of } from "rxjs";
import { catchError, finalize, take, tap } from "rxjs/operators";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as Shared from "./index";


@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<Shared.RoutedPageComponentBase> {
  constructor(
    private _modal: Modal,
    private _authenticationService: Shared.AuthenticationService,
    private _localizationService: Shared.LocalizationService
  ) { }

  public canDeactivate(
    component: Shared.RoutedPageComponentBase,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable<boolean> | boolean {

    if (!component.hasUnsavedChanges() || 
        !this._authenticationService.isAuthenticated) {
      return true;
    }

    this._authenticationService.isPeriodicalLogginStatusCheckActive = false;
    const modal = this._modal.confirm()
      .body(this._localizationService.getLocalizedString("unsaved_changes_confirm_navigation_question"))
      .open();

    return from(modal.result).pipe(
      tap(() => component.removeHandleWindowUnload()),
      catchError(() => of(false)),
      take(1),
      finalize(() => this._authenticationService.isPeriodicalLogginStatusCheckActive = true)
    );
  }
}