<ng-container *ngIf="componentPortal; else genericContent">
  <ng-template [cdkPortalOutlet]="componentPortal" (attached)="onPortalAttach($event)"></ng-template>
</ng-container>

<ng-template #genericContent>
  <div class="wizard__message-popup__body">
    <div class="wizard__message-popup__body__text">{{ message }}</div>
  </div>
  <div *ngIf="hasDismissButton || hasCloseButton" class="wizard__message-popup__footer">
    <button *ngIf="hasDismissButton" type="button" class="wizard__message-popup__nav-btn" (click)="dismiss()">{{ dismissLabel }}</button>
    <button *ngIf="hasCloseButton" type="button" class="wizard__message-popup__nav-btn" (click)="close()">{{ closeLabel }}</button>
  </div>
</ng-template>