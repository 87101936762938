import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ComponentRef, HostBinding, OnInit, Type } from "@angular/core";

import { filter, takeWhile } from "rxjs/operators";

import { animations } from "../animations";
import { WizardCssClass, WizardMessageComponent, WizardMessagePopup } from "../models";
import { WizardService } from "../services/wizard.service";
import { setComponentData } from "../utils";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "wizard-message-popup",
  templateUrl: "./wizard-message-popup.component.html",
  animations: [
    animations.wizardPopup
  ],
  host: {
    "[@wizardPopup]": "true"
  }
})
export class WizardMessagePopupComponent extends WizardMessageComponent implements WizardMessagePopup, OnInit {
  closeLabel: string;
  componentData: {[key: string]: any} = null;
  componentPortal: ComponentPortal<any> = null;
  dismissLabel: string;
  hasCloseButton = true;
  hasDismissButton = true;
  message: string;

  @HostBinding("class") popupClasses: string[] = [WizardCssClass.MessagePopup];

  set component(value: Type<WizardMessageComponent>) {
    this._component = value;
    this.componentPortal = value ? new ComponentPortal(value) : null;
  }

  get component(): Type<WizardMessageComponent> {
    return this._component;
  }

  private _component: Type<WizardMessageComponent> = null;

  constructor(wizardService: WizardService) {
    super(wizardService);
  }

  ngOnInit() {
    this.observeWizard();
  }

  onPortalAttach(ref: ComponentRef<any>) {
    setComponentData(ref, this.componentData);
  }

  private observeWizard() {
    this.wizardService.state$.pipe(
      takeWhile(state => Boolean(state.messagePopup)),
      filter(state => Boolean(state.messagePopup))
    ).subscribe(state => {
      const message = state.messagePopup;

      this.closeLabel = message.closeLabel;
      this.component = message.component;
      this.componentData = message.componentData;
      this.dismissLabel = message.dismissLabel;
      this.hasCloseButton = message.hasCloseButton ?? true;
      this.hasDismissButton = message.hasDismissButton ?? true;
      this.message = message.message;

      this.popupClasses = [
        WizardCssClass.MessagePopup,
        this.componentPortal ? WizardCssClass.MessagePopupCustom : WizardCssClass.MessagePopupGeneric
      ];
    });
  }
}
