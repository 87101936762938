import { LoggingLevel } from "./../logging-level"


const LOGGING_LEVEL_SETTINGS_KEY = "logging_level";
const DEFAULT_LOGGING_LEVEL = LoggingLevel.Info;


/**
 * Slu�ba poskytuj�ca pr�stup k r�znym nastaveniam aplik�cie.
 */
export class SettingsService {
  public get loggingLevel(): LoggingLevel {
    var storedLogginLevel = localStorage.getItem(LOGGING_LEVEL_SETTINGS_KEY) as string;

    // Ak e�te nem�me ni� ulo�en�, tak vraciame 
    // default hodnotu, ale neuklad�me ju.
    if (storedLogginLevel == null) {
      return DEFAULT_LOGGING_LEVEL;
    }

    // M�me string obsahuj�ci ��slo, ktor� reprezentuje hodnotu z enumu LogginLevel.
    try {
      return parseInt(storedLogginLevel);
    }
    catch (e) {
      // Chybu ignorujeme, pravdepodobne je to nejak� blb� nastavenie.
      // Nechceme ani ni� logova� cez LogginService, lebo by sme sa mohli zacykli�.
      return DEFAULT_LOGGING_LEVEL;
    }
  }


  public set loggingLevel(value: LoggingLevel) {
    localStorage.setItem(LOGGING_LEVEL_SETTINGS_KEY, value.toString());
  }
}