import { Directive, TemplateRef, ViewContainerRef, Input, InjectionToken, Optional, Inject, Host } from "@angular/core";

import { RowDirective, ROW_COMPONENT } from "./row.directive"

// export const ROW_COMPONENT =
//     new InjectionToken<RowDirective>("ROW_COMPONENT");

@Directive({
  selector: "[appColumn]"
})
export class ColumnDirective {
  name: string;
  context: columnContext = new columnContext();

  @Input() 
  set appColumn(name: any) {
    this.name = name
  }

  constructor(
      private _viewContainer: ViewContainerRef,
      private _templateRef: TemplateRef<columnContext>,
      @Host() @Optional() @Inject(ROW_COMPONENT) private _parent: RowDirective) {
  }

  get template(): TemplateRef<columnContext>|null {
    return this._templateRef;
  }

  get viewContainer(): ViewContainerRef {
    return this._viewContainer;
  }

  ngAfterViewInit() {
    if (this._parent) {
      this._parent.registerColumn(this);
    }  
  }

  ngOnDestroy() {
    if (this._parent) {
      this._parent.removeColumn(this);
    }
  }
}

export class columnContext { };
 
