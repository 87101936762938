<!-- <form #tel="ngForm" class="telephone-number-input control" id="{{ id }}-form">
  <wj-combo-box name="prefix" id="{{ id }}-prefix" [(ngModel)]="prefix" [itemsSource]="callingCodesSortedForSelect" headerPath="callingCode" dropDownCssClass="telephone-number-input" class="prefix-input" selectedValuePath="callingCode" (blur)="onBlur()">
    <ng-template wjItemTemplate let-cc="item" let-itemIndex="itemIndex"><span class="flag flag-{{ cc.countryCode.toLowerCase() }}"></span><strong>{{ cc.countryName }}</strong> {{ cc.callingCode }}</ng-template>
  </wj-combo-box>
  <input class="number-input" id="{{ id }}-number" maxlength="25" type="text" (keydown)="numberPartKeyDown($event)" name="number" placeholder="{{ 'recipient_phone' | shpLocalize }}" [(ngModel)]="number" (blur)="onBlur()"/>
</form> -->
<form #tel="ngForm" class="telephone-number-input control" id="{{ id }}-form">
  <div class="p-field p-field--full">
    <span class="p-float-label">
      <p-dropdown id="{{ id }}-prefix" [options]="callingCodesSortedForSelect" [(ngModel)]="prefix" name="prefix" optionLabel="callingCode" [filter]="true" filterBy="callingCode,countryName" [showClear]="true" (onBlur)="onBlur()">
        <ng-template let-cc pTemplate="item"><div class="country-item"><span class="flag flag-{{ cc.countryCode.toLowerCase() }}"></span><strong>{{ cc.countryName }}</strong> {{ cc.callingCode }}</div></ng-template>
      </p-dropdown>
      <label for="{{ id }}-prefix">{{ 'prefix' | shpLocalize }}</label>
    </span>
  </div>
  <div class="p-field p-field--full">
    <span class="p-float-label">
      <input id="{{ id }}-number" class="number-input" maxlength="25" type="text" (keydown)="numberPartKeyDown($event)" name="number" [(ngModel)]="number" (blur)="onBlur()" pInputText>
      <label for="{{ id }}-number">{{ 'recipient_phone' | shpLocalize }}</label>
    </span>
  </div>
</form>
