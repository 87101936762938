<div class="dialog app-update-info-dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="'installation_in_progress' | shpLocalize"></shp-progress-overlay>
  <h1>{{ 'shipper_update_available' | shpLocalize:version }}</h1>

  <div *ngIf="hasUpdateConent" class="info-dialog-content mb-3">
    <div [innerHtml]="updateContent"></div>
  </div>

  <div *ngIf="!canUpdate">
    {{ 'contact_admin_to_update_shipper_message' | shpLocalize }}
  </div>

  <ng-container *ngIf="canUpdate">
    <div id="toggle-manual-upload" class="editor-part-active" (click)="toogleManualDownload()">
      <span *ngIf="isManualDownloadShown" class="ic-minus ic-2x"></span>
      <span *ngIf="!isManualDownloadShown" class="ic-plus ic-2x"></span>
      <span class="editor-part-name">{{ 'install_other_options' | shpLocalize }}</span>
    </div>

    <div *ngIf="isManualDownloadShown" class="ml-4">
      {{ 'you_can_download_it_from' | shpLocalize}}
      <br />
      <a target="_blank" [href]="updateInfo.updateUrl">{{ updateInfo.updateUrl }}</a>.
      <br />
      <br />
      {{ 'update_instructions' | shpLocalize}}
    </div>
  </ng-container>

  <div class="d-flex mt-3">
    <button *ngIf="canUpdate" id="button-install" class="flex-fill" type="button" (click)="install()">{{ 'install_now' | shpLocalize }}</button>
    <button id="button-close" class="flex-fill" [class.ml-2]="canUpdate" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>
</div>