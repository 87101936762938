import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

import { animationFrameScheduler, interval } from "rxjs";
import { filter, take } from "rxjs/operators";

import { animations } from "../animations";
import { WizardArrow, WizardArrowPosition, WizardCssClass } from "../models";

const arrowAnimationDelay = 5000;
const maxArrowAnimationIterationCount = 5;

@Component({
  // tslint:disable-next-line: component-selector
  selector: "wizard-arrow",
  templateUrl: "./wizard-arrow.component.html",
  animations: [
    animations.wizardArrowTop,
    animations.wizardArrowRight,
    animations.wizardArrowBottom,
    animations.wizardArrowLeft
  ],
  host: {
    class: WizardCssClass.Arrow,
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardArrowComponent {
  @Input()
  set arrow(value: WizardArrow) {
    this._arrow = value;
    this.isArrowAnimated = this.arrow.isAnimated;
  }

  get arrow(): WizardArrow {
    return this._arrow;
  }

  @HostBinding(`class.${WizardCssClass.ArrowAnimated}`) isArrowAnimated = true;

  @HostBinding(`class.${WizardCssClass.ArrowTop}`)
  get positionTop(): boolean {
    return this.arrow?.position === WizardArrowPosition.Top;
  }

  @HostBinding(`class.${WizardCssClass.ArrowRight}`)
  get positionRight(): boolean {
    return this.arrow?.position === WizardArrowPosition.Right;
  }

  @HostBinding(`class.${WizardCssClass.ArrowBottom}`)
  get positionBottom(): boolean {
    return this.arrow?.position === WizardArrowPosition.Bottom;
  }

  @HostBinding(`class.${WizardCssClass.ArrowLeft}`)
  get positionLeft(): boolean {
    return this.arrow?.position === WizardArrowPosition.Left;
  }

  private _arrow: WizardArrow;
  private arrowAnimationIterationCount = 1;

  onArrowAnimationEnd() {
    if (!this.arrow.isAnimated) {
      return;
    }

    this.isArrowAnimated = false;

    if (this.arrowAnimationIterationCount++ <= maxArrowAnimationIterationCount) {
      interval(arrowAnimationDelay, animationFrameScheduler).pipe(
        take(1),
        filter(() => this.arrow.isAnimated)
      ).subscribe(() => this.isArrowAnimated = true);
    }
  }
}
