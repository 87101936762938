import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { forkJoin, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

import * as _ from "lodash";
import * as Shared from "../../../shared/index";

import { InstructionsService } from "../../services/instructions.service";
import { AdminInstructionsSearchParameters, AdminInstructionForGridView } from "../../models/instructions.model";
import { getObjectHasOnlyDefaultFilterValues, defaultPagingProperties } from "../../../shared/filter-helpers";
import { FormGroup, FormBuilder } from "@angular/forms";
import { amendValueForModelFromFormattedFormControl, amendValueForFormattedFormControl } from "../../../shared/form-helpers";
import { ToastrService } from "ngx-toastr";

const DEFAULT_PAGE_SIZE = 20;
const FILTER_KEY = "instructions-filter";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-instructions-viewer",
  templateUrl: "./instructions-viewer.component.html"
})
export class InstructionsViewerComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _instructionsService: InstructionsService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _fb: FormBuilder,
    private _toastr: ToastrService,) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router);

    this.loadFilterFromLocalStorage();
    this.setupFilterForm();
  }

  private _instructionsPagedResults: Shared.PagedResult<AdminInstructionForGridView> = null;
  private _filter: AdminInstructionsSearchParameters = null;
  private _isFiltered: boolean = false;
  private _loadingErrorOccured = false;

  private _lastPageIndex = 0;

  private _filterFormChangeSubscription: Subscription = null;
  private _listLoadingSubscription: Subscription = null;

  private _filterForm: FormGroup;

  private _isLoadingList = false;
  private _isInitializing = true;

  public instructionTypes: string[] = null;

  public getEmptyFilter(): AdminInstructionsSearchParameters {
    let defaultFilter: AdminInstructionsSearchParameters = {
      guid: null,
      businessUnitCode: "",
      tenantCode: "",
      customerDelisId: "",
      configurationGroupId: null,
      instructionTypeCode: "",
      validFromUtc: null,
      validToUtc: null,
      stateId: null,
      applicationDateTimeFromUtc: null,
      applicationDateTimeToUtc: null,
      isInformationSent: null,
      orderAscending: false,
      orderByFieldName: "Id",
      pageIndex: 0,
      pageSize: 20
    };

    return defaultFilter;
  }

  private setIsFiltered() {
    this._isFiltered = !getObjectHasOnlyDefaultFilterValues(this._filter, defaultPagingProperties);
  }


  private loadFilterFromLocalStorage() {
    var filterJsonString = localStorage.getItem(FILTER_KEY);

    if (filterJsonString) {
      this._filter = JSON.parse(filterJsonString);
    } else {
      this._filter = this.getEmptyFilter();
    }
  }


  private saveFilterToLocalStorage() {
    localStorage.setItem(FILTER_KEY, JSON.stringify(this._filter));
  }


  public get isLoadingList() {
    return this._isLoadingList;
  }


  public get isInitializing() {
    return this._isInitializing;
  }


  public get loadingErrorOccured() {
    return this._loadingErrorOccured;
  }


  public get filter() {
    return this._filter;
  }


  public get filterForm() {
    return this._filterForm;
  }


  public get lastPageIndex() {
    return this._lastPageIndex;
  }


  public get total() {
    if (this._instructionsPagedResults) {
      return this._instructionsPagedResults.totalSize;
    }

    return null;
  }


  public get isFiltered() {
    return this._isFiltered;
  }


  public get instructions() {
    if (this._instructionsPagedResults) {
      return this._instructionsPagedResults.items;
    }

    return null;
  }


  public clearFilter() {
    this._filter = this.getEmptyFilter();
    this.saveFilterToLocalStorage();
    this.updateFilterFormValuesFromFilter();
  }




  public goToPage(index: number) {
    this._filter.pageIndex = index;

    this.reloadInstructions();
  }



  private setupFilterChangeListener() {
    this._filterFormChangeSubscription = this._filterForm.valueChanges.pipe(debounceTime(500)).subscribe(
      values => {
        Object.assign(this._filter, values);

        // this._filter.validFromUtc = amendValueForModelFromFormattedFormControl(this._filter.validFromUtc);
        // this._filter.validToUtc = amendValueForModelFromFormattedFormControl(this._filter.validToUtc);
        // this._filter.applicationDateTimeFromUtc = amendValueForModelFromFormattedFormControl(this._filter.applicationDateTimeFromUtc);
        // this._filter.applicationDateTimeToUtc = amendValueForModelFromFormattedFormControl(this._filter.applicationDateTimeToUtc);

        this.setIsFiltered();
        this.reloadInstructions();
      });
  }


  private setupFilterForm() {
    this._filterForm = this._fb.group({
      // validFromUtc: amendValueForFormattedFormControl(this._filter.validFromUtc),
      // validToUtc: amendValueForFormattedFormControl(this._filter.validToUtc),
      // applicationDateTimeFromUtc: amendValueForFormattedFormControl(this._filter.applicationDateTimeFromUtc),
      // applicationDateTimeToUtc: amendValueForFormattedFormControl(this._filter.applicationDateTimeToUtc),
      validFromUtc: this._filter.validFromUtc,
      validToUtc: this._filter.validToUtc,
      applicationDateTimeFromUtc: this._filter.applicationDateTimeFromUtc,
      applicationDateTimeToUtc: this._filter.applicationDateTimeToUtc,
      instructionTypeCode: this._filter.instructionTypeCode,
      stateId: this._filter.stateId,
      isInformationSent: this._filter.isInformationSent,
      guid: this._filter.guid,
      pageSize: this._filter.pageSize
    });

    this.setupFilterChangeListener();
  }


  private updateFilterFormValuesFromFilter() {
    this._filterForm.setValue({
      // validFromUtc: amendValueForFormattedFormControl(this._filter.validFromUtc),
      // validToUtc: amendValueForFormattedFormControl(this._filter.validToUtc),
      // applicationDateTimeFromUtc: amendValueForFormattedFormControl(this._filter.applicationDateTimeFromUtc),
      // applicationDateTimeToUtc: amendValueForFormattedFormControl(this._filter.applicationDateTimeToUtc),
      validFromUtc: this._filter.validFromUtc,
      validToUtc: this._filter.validToUtc,
      applicationDateTimeFromUtc: this._filter.applicationDateTimeFromUtc,
      applicationDateTimeToUtc: this._filter.applicationDateTimeToUtc,
      instructionTypeCode: this._filter.instructionTypeCode,
      stateId: this._filter.stateId,
      isInformationSent: this._filter.isInformationSent,
      guid: this._filter.guid,
      pageSize: this._filter.pageSize
    });
  }


  public reloadInstructions = () => {
    if (this._listLoadingSubscription) {
      this._listLoadingSubscription.unsubscribe();
    }

    this.saveFilterToLocalStorage();

    this._isLoadingList = true;
    this._loadingErrorOccured = false;

    var filter = _.cloneDeep(this._filter);

    this._listLoadingSubscription = this._instructionsService.searchAdminInstructions(filter).subscribe(

      instructionsPagedResult => {
        this._instructionsPagedResults = instructionsPagedResult;

        this._lastPageIndex = Math.ceil(this.total / this.filter.pageSize) - 1;

        this._isLoadingList = false;

        if (this.total > 0 && this.lastPageIndex < this.filter.pageIndex) {
          this.goToPage(this.lastPageIndex);
        }
      }, ex => {
        this._isLoadingList = false;
        this._loadingErrorOccured = true;
        this.loggingService.logErrorData(ex, "Loading instructions failed");

        var exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._toastr.error(`${this.localizationService.getLocalizedString("instructions_loading_failed")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}}`);
      });
  }

  private loadLookups() {
    return forkJoin([
      this._instructionsService.getInstructionTypes(),
    ]).pipe(
      map(result => {
        [ this.instructionTypes ] = result;

        return true;
      })
    );
  }


  public ngOnDestroy() {
    if (this._listLoadingSubscription) {
      this._listLoadingSubscription.unsubscribe();
    }

    if (this._filterFormChangeSubscription) {
      this._filterFormChangeSubscription.unsubscribe();
    }
  }


  public ngOnInit() {
    this.loadLookups().subscribe(loaded => {
      this.reloadInstructions();
    }, ex => {
      this.loggingService.logErrorData(ex);
      this._toastr.error(this.localizationService.getLocalizedString("error_instructions_lookups") + ": " + this.localizationService.getLocalizedExceptionString(ex));
    });
  }

}