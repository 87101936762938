import { Component, OnInit, OnDestroy } from "@angular/core";
import { AbstractControl, FormGroup, FormBuilder, ValidationErrors } from "@angular/forms"

import { filter } from "rxjs/operators";

import { DialogRef, ModalComponent, CloseGuard } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as Shared from "../../shared/index";
import * as CustomerModels from "../../shared/models/customer.models";
import { ShipmentService } from "../services/shipments.service";

import { ToastrService } from "ngx-toastr";

import * as _ from "lodash";
import { ExceptionKeys } from "../../shared/index";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-delete-all-shipments-dialog",
  templateUrl: "./delete-all-shipments-dialog.component.html"
})
export class DeleteAllShipmentsDialogComponent implements CloseGuard, ModalComponent<any>, OnInit, OnDestroy {
  private _f: FormGroup;
  private _isBusy = false;
  private _statusMessage = "";
  private _somethingDeleted = false;

  private _currentAddress: CustomerModels.Address = null;

  public requiredDeleteVerificationText = "DELETE";


  public get f() {
    return this._f;
  }


  public get isBusy() {
    return this._isBusy;
  }


  public get statusMessage() {
    return this._statusMessage;
  }


  private validateDeleteVerificationText(control: AbstractControl): ValidationErrors | null {
    if (!control) {
      return null;
    }

    var value = control.value;

    if (value != this.requiredDeleteVerificationText) {
      return {
        invalidDeleteVerificationText: true
      }
    }

    return null;
  }


  private initializeForm() {
    this._f = this._fb.group({
      "deleteVerificationText": this._fb.control("", [this.validateDeleteVerificationText.bind(this)])
    });
  }


  public deleteAllShipments() {
    this._isBusy = true;

    this._shipmentsService.deleteAllOrdersForPickupAddress(
      this._currentAddress.customerDetailId,
      this._currentAddress.customId,
      this.f.get("deleteVerificationText").value)
      .subscribe(deletedCount => {
        this._toastr.success(this._localizationService.getLocalizedString("all_shipments_for_current_pickup_address_successfully_deleted"));

        this._somethingDeleted = deletedCount > 0;

        this.close();
      }, ex => {
        ex = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._log.logErrorData(ex, "Error deleting all shipments for current pickup address");
        this._isBusy = false;

        this._toastr.error(`${this._localizationService.getLocalizedString("error_deleting_all_shipments_for_current_pickup_address")}:\n${this._localizationService.getLocalizedExceptionString(ex)}`);
      });
  }


  /**
   * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
   */
  public beforeDismiss(): boolean | Promise<boolean> { return true; }


  /**
   * Vracia, či nie je možné dialóg zatvoriť cez .close().
   */
  public beforeClose(): boolean | Promise<boolean> { return false; }


  public ngOnInit() {
    this._isBusy = true;

    this._contextService.currentAddress.pipe(filter(a => a != null)).subscribe(a => {
      this._currentAddress = a;
      this._isBusy = false;
    }, ex => {
      this._log.logErrorData(ex, "Loading  current address failed.")

      this._toastr.error(this._localizationService.getLocalizedString(ExceptionKeys.GeneralException));

      this._isBusy = false;
    });
  }


  public ngOnDestroy() {

  }


  public close() {
    if (this._somethingDeleted) {
      this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
    }

    this.dialog.close();
  }


  constructor(
    public dialog: DialogRef<any>,
    private _contextService: Shared.ContextService,
    private _shipmentsService: ShipmentService,
    private _localizationService: Shared.LocalizationService,
    private _modal: Modal,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _skDataConfigService: Shared.SkdataConfigService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _toastr: ToastrService,
    private _log: Shared.LoggingService,
    private _fb: FormBuilder
  ) {
    dialog.setCloseGuard(this);

    this.initializeForm();
  }
}