<div class="content-panel import-profiles">
  <div class="import-profiles-menu">
    <div class="d-flex align-items-center">
      <h1>{{ 'import_profiles' | shpLocalize }}</h1>
      <button *shpPollyDialogTrigger="pollyCollection" id="import-profiles-faq" class="clear-round-button" title="{{ 'action_open_faq_dialog' | shpLocalize }}"><span class="ic-interface-question-mark"></span></button>
    </div>
    <ul>
      <li wizardTag="importProfilesNavShipments" [wizardCompleteStep]="wizardStepNames.ClickImportProfilesShipments"><a [routerLink]="['/import-profiles/shipments']" [routerLinkActive]="['selected']" (click)="changeImportProfile('shipments')">{{ 'shipments_import_profiles' | shpLocalize }}</a></li>
      <li><a [routerLink]="['/import-profiles/shipmentsCR']" [routerLinkActive]="['selected']" (click)="changeImportProfile('shipmentsCR')">{{ 'shipmentsCR_import_profiles' | shpLocalize }}</a></li>
      <li><a [routerLink]="['/import-profiles/recipients']" [routerLinkActive]="['selected']" (click)="changeImportProfile('recipients')">{{ 'recipients' | shpLocalize }}</a></li>
      <li><a [routerLink]="['/import-profiles/transformations']" [routerLinkActive]="['selected']">{{ 'transformations' | shpLocalize }}</a></li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>