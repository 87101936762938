import { Injectable } from "@angular/core"
import { LoggingLevel } from "../logging-level";
import { SettingsService } from "./settings.service";

// import { LoggingLevel, SettingsService } from "./../index"


/**
 * Služba na logovanie chýb do konzoly.
 */
@Injectable()
export class LoggingService {
  constructor(private _settingsService: SettingsService) {

  }


  /**
     * Zalogujeme text, úroveň a čas.
     */
  private logMessage = (severity: LoggingLevel, message: string) => {

    // Logujeme iba v prípade, ak úroven je vyššia, ako aktuálne nastavenie zaznamenávania chýba.
    if (this._settingsService.loggingLevel <= severity) {
      var timestamp: Date = new Date();
      console.log(timestamp.toISOString() + " " + LoggingLevel[severity] + ": " + message);
    }
  };


  /**
   * Zalogujeme objekt, úroveň a čas. 
   */
  private logData = (severity: LoggingLevel, data: any, message?: string) => {
    if (this._settingsService.loggingLevel <= severity) {
      var timestamp: Date = new Date();

      if (typeof message === "undefined" || message == null) {
        message = "";
      }

      console.log(timestamp.toISOString() + " " + LoggingLevel[severity] + ": " + message);
      console.log(data);
    }
  };


  public logInfoData = (data: any, message?: string) => {
    this.logData(LoggingLevel.Info, data, message);
  };


  public logErrorData = (data: any, message?: string) => {
    this.logData(LoggingLevel.Error, data, message);
  };


  public logWarningData = (data: any, message?: string) => {
    this.logData(LoggingLevel.Warning, data, message);
  };


  public logDebugData = (data: any, message?: string) => {
    this.logData(LoggingLevel.Debug, data, message);
  };


  public logInfo = (message: string) => {
    this.logMessage(LoggingLevel.Info, message);
  };


  public logError = (message: string) => {
    this.logMessage(LoggingLevel.Error, message);
  };


  public logWarning = (message: string) => {
    this.logMessage(LoggingLevel.Warning, message);
  };


  public logDebug = (message: string) => {
    this.logMessage(LoggingLevel.Debug, message);
  };

}