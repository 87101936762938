import { Directive, forwardRef, Input, OnInit, OnDestroy, Injector } from "@angular/core"
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors, ControlContainer, NgControl } from "@angular/forms"

import { Subject } from "rxjs";
import { takeUntil, debounceTime, map, distinctUntilChanged } from "rxjs/operators";

import * as Shared from "../index";
import * as _ from "lodash";

const DIFFRENT_FROM_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DifferentFromValidatorDirective),
  multi: true
}

/**
 * @description
 * A directive that validates if control value differs from values of 
 * specified controls.
 * 
 * @example
 * 
 * ### Name1 and Name2 must be diffrent
 *
 * ```html
 * <input name="name1" ngModel shpDifferentFrom="name2">
 * <input name="name2" ngModel shpDifferentFrom="name1">
 * ```
 * 
 * ### Comparing multiple controls
 * 
 * ```html
 * <input name="player1" ngModel shpDifferentFrom="player2,player3">
 * <input name="player2" ngModel shpDifferentFrom="player1,player3">
 * <input name="player3" ngModel shpDifferentFrom="player1,player2">
 * ```
 */
@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}DifferentFrom][formControlName],[${Shared.SELECTOR_PREFIX}DifferentFrom][formControl],[${Shared.SELECTOR_PREFIX}DifferentFrom][ngModel]`,
  providers: [DIFFRENT_FROM_VALIDATOR]
})
export class DifferentFromValidatorDirective implements Validator, OnInit, OnDestroy {
  @Input(`${Shared.SELECTOR_PREFIX}DifferentFrom`)
  set differentFrom(value: string) {
    this.controlNames = value.split(",");
  }


  private controlNames: string[] = [];
  private destroy$ = new Subject<void>();
  private ngControl: NgControl;


  constructor(
      private injector: Injector,
      private controlContainer: ControlContainer) {
  }


  public ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
    this.observeComparingControls();
  }


  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  public validate(control: AbstractControl): ValidationErrors {
    const isValid = this.controlNames.every(controlName => {
      const comparingControl = this.controlContainer.control.get(controlName);

      return this.isControlValueDifferent(control, comparingControl);
    });

    return isValid ? null : {differentFrom: true};
  }


  private isControlValueDifferent(
      control: AbstractControl, comparingControl: AbstractControl): boolean {
    /** Ignore if compared control is undefined or has no value. */
    if (!comparingControl || 
        comparingControl && (comparingControl.value == null || 
                             comparingControl.value === "")) {
      return true;
    }

    return control.value !== comparingControl.value;
  }


  /** Update control validity when compared controls change value.*/
  private observeComparingControls() {
    this.controlContainer.control.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(50),
      map(value => _.pick(value, this.controlNames)),
      distinctUntilChanged((newValues, oldValues) => _.isEqual(oldValues, newValues))
    ).subscribe(() => {
        this.ngControl.control.updateValueAndValidity({
          onlySelf: true
        });
      });
  }
}