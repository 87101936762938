import { Component, Inject, OnDestroy, OnInit } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { WizardChapter, WizardCssClass, WizardDataProvider, WIZARD_DATA_PROVIDER } from "../models";
import { WizardService } from "../services/wizard.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "wizard-chapters",
  templateUrl: "./wizard-chapters.component.html",
  host: {
    class: WizardCssClass.Chapters
  }
})
export class WizardChaptersComponent implements OnInit, OnDestroy {
  activeChapterName: string;
  availableChapters: Set<string> = new Set();
  chapters: WizardChapter[] = [];

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(WIZARD_DATA_PROVIDER) private wizardDataProvider: WizardDataProvider,
    private wizardService: WizardService) { }

  ngOnInit() {
    this.chapters = this.wizardDataProvider.getChapters().filter(s => s.isDisplayedInControlPanel !== false);
    this.observeState();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChapterClicked(chapter: WizardChapter) {
    if (!this.wizardService.stateSnapshot.avaiableChapters.has(chapter.name)) {
      return;
    }

    this.wizardService.goToChapter(chapter.name);
  }

  private observeState() {
    this.wizardService.state$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(state => {
      this.activeChapterName = state.currentChapter?.name;
      this.availableChapters = state.avaiableChapters;
    });
  }
}
