import { Injectable, Injector } from "@angular/core"
import { DefaultGlobalConfig, ToastrService } from "ngx-toastr";

import { Observable, of } from "rxjs";

// Components
import { ExtendedErrorToastrComponent } from "../components/extended-error-toastr.component";

import * as Exceptions from "../exceptions"
//import { LocalizationService } from "./localization.service"

import * as Shared from "./../index";
import * as Globals from "../globals";
import { SettingsService } from "../../settings/services/settings.service";

/**
 * Služba, ktorá má pomôcť handlingu chýb v aplikácii.
 */
@Injectable()
export class ExceptionsHandlerService {
  private localizationService = null;
  private settingsService = null;

  private installationID: number = null;
  /**
   * Funkcia sa snaží pretaviť ľubovoľný objekt, o ktorom predpokladáme, že obsahuje nejakú chybu,
   * do rozumného ExceptionInfo objektu.
   * 
   * @param ex Ľubovoľný objekt, ktorý obdržíme pri nejakej chybe.
   */
  public getExceptionInfo(ex: any): Exceptions.ExceptionInfo {

    if (ex.key && ex.message) {
      // Ak máme na došlom objekte atribúty key a message, ide pravdepodobne o objekt typu ExceptionInfo, rovno ho vrátime.
      return ex as Exceptions.ExceptionInfo;
    }


    var exceptionInfo: Exceptions.ExceptionInfo = {
      httpStatusCode: null,
      key: Exceptions.ExceptionKeys.GeneralException,
      message: "",
      params: [],
      stackTrace: ""
    };

    // Máme stack trace?
    if (ex.stack) {
      exceptionInfo.stackTrace = ex.stack;
    }

    if (ex.message) {
      exceptionInfo.message = ex.message;
    }
    
    // Teraz máme hromadu prípadov, že čo sa mohlo stať.
    

    // Ak už nevieme čo, tak vrátime generickú chybu.
    return exceptionInfo;
  }


  /**
   * Metóda slúži na spracovanie chýb, ktoré sú kritické pre beh aplikácie.
   * Zobrazí informáciu o chybe s možnosťou reloadu celej stránky, lebo aplikácia v aktuálnom stave nevie
   * pokračovať.
   */
  public handleFatalException(exceptionInfo: Exceptions.ExceptionInfo) {
    
  }

  public handleException( exception: any, request?: any, url?: string, title?: string, description?: string  ): void {

    if (exception.key !== Exceptions.ExceptionKeys.GeneralException)
      return;

    if (!this.localizationService) {
      this.localizationService = this.injector.get(Shared.LocalizationService);
    }

    if (!this.settingsService) {
      this.settingsService = this.injector.get(SettingsService);
    }

    const excInfo = this.getExceptionInfo(exception);
    const titleLocalize: string = title && title.length ? this.localizationService.getLocalizedString(title) : this.localizationService.getLocalizedString('unexpected_error_title');
    const descLocalize: string = description && description.length ? this.localizationService.getLocalizedString(description) : this.localizationService.getLocalizedString('unexpected_error_description');

    const toastrOptions = new DefaultGlobalConfig();
          toastrOptions.closeButton = true;
          toastrOptions.tapToDismiss = false;
          toastrOptions.disableTimeOut = false;
          toastrOptions.timeOut = 20000;
          toastrOptions.toastComponent = ExtendedErrorToastrComponent;

    this.getInstallationId().subscribe((installationID: number) => {

        const toastrData = {
          url,
          request,
          exception: excInfo,
          version: Globals.APP_VERSION,
          installationID
        };

        this._loggingService.logErrorData( excInfo );
        let extendedToastr = this._toastr.error( descLocalize, titleLocalize, toastrOptions );
        let extendedToastrComponent = extendedToastr.portal.instance;
            extendedToastrComponent.payload = JSON.stringify(toastrData);
      });
  }


  private getInstallationId(): Observable<number> {

    if (!this.settingsService) {
      this.settingsService = this.injector.get(SettingsService);
    }

    if (!this.installationID) {
      return this.settingsService.getInstallationId().map(( value: number ) => {
        this.installationID = value;
        return this.installationID;
      }).catch((error) => {
        return this.installationID;
      })
    }

    return of(this.installationID);
  }


  private handleUnhandledExceptions(e: ErrorEvent) {
    // Získame si rozumný popis.
    var exceptionInfo = this.getExceptionInfo(e.error);

    // Takéto chyby pokladáme za fatálne.
    this.handleFatalException(exceptionInfo);

    // Nech sa ďalej chyba nerieši.
    e.cancelBubble = true;
    e.preventDefault();
  }


  constructor(
    private injector: Injector,
    private _loggingService: Shared.LoggingService,
    private _toastr: ToastrService,
  ) {
    // Zavesíme sa na chyby v celom okne, takto odchytíme aj nehandlované chyby.

    window.addEventListener("error", this.handleUnhandledExceptions.bind(this));
  }
}
