import { Directive, HostBinding } from "@angular/core";

import { Subject } from "rxjs";

import { WizardCssClass } from "../models";
import { WizardService } from "../services/wizard.service";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[wizardScrollable]"
})
export class WizardScrollableDirective {
  private readonly destroy$ = new Subject<void>();

  constructor(private wizardService: WizardService) { }

  @HostBinding(`class.${WizardCssClass.EnableScroll}`)
  get isActive(): boolean {
    return this.wizardService.isActive;
  }
}
