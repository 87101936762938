import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ApiServiceBase, LoggingService, API_URL, PagedResult } from "../../shared/index";
import { PickupOrdersGridViewSearchParameters } from "../models/pickup-orders-grid-view-search-parameters.model";
import { PickupOrderForGridView } from "../models/pickup-order-for-grid-view.model";
import * as UriHelper from "../../shared/uri-helper"
import { PickupOrderModel } from "../models/pickup-order-model.model";

@Injectable()
export class PickupOrdersService extends ApiServiceBase {
  constructor(
    logging: LoggingService,
    private _http: HttpClient
  ) {
    super(logging);
  }


  public searchPickupOrders(parameters: PickupOrdersGridViewSearchParameters) {
    return this.processRequest<PagedResult<PickupOrderForGridView>>(this._http.get(`${API_URL}/pickups?${UriHelper.encodeAsQueryString(parameters)}`));
  }


  public searchOneTimePickupOrders(parameters: PickupOrdersGridViewSearchParameters) {
    parameters.isRegularPickup = false;
    return this.searchPickupOrders(parameters);
  }


  public getRegularPickupOrder(customerDetailId: number, addressId: number) {
    return this.processRequest<PickupOrderForGridView>(this._http.get(`${API_URL}/pickups/${customerDetailId}/${addressId}/regular-pickup-order`));
  }


  public getPickupOrder(id: number) {
    return this.processRequest<PickupOrderModel>(this._http.get(`${API_URL}/pickups/${id}`));
  }


  public addPickupOrder(model: PickupOrderModel) {
    return this.processRequest<PickupOrderModel>(this._http.post(`${API_URL}/pickups`, model));
  }


  public canCurrentUserEditRegularPickupOrdersAsync(customerDetailId: number) {
    return this.processRequest<boolean>(this._http.get(`${API_URL}/pickups/${customerDetailId}/current-user-can-edit-regular-pickup-orders`));
  }


  public updateRegularPickupOrder(id: number, model: PickupOrderModel) {
    return this.processRequest<PickupOrderModel>(this._http.put(`${API_URL}/pickups/${id}`, model));
  }


  public deletePickupOrder(id: number) {
    return this.processRequest<any>(this._http.delete(`${API_URL}/pickups/${id}`));
  }

  public getUnavailableDays(addressCustomId: string, year: number, month: number) {
    return this.processRequest<any>(this._http.get(`${API_URL}/pickups/unavailable-days/${addressCustomId}/${year}/${month}`));
  }

  public getUnavailablePickupDays(pickupAddressId: number, customerDetailId: number, year: number, month: number) {
    return this.processRequest<any>(this._http.get(`${API_URL}/pickups/unavailable-days/address/${pickupAddressId}/customer/${customerDetailId}/${year}/${month}`));
  }

  public getHolidays(businessUnitCode: string, year: number, month: number) {
    return this.processRequest<any>(this._http.get(`${API_URL}/business-units/${businessUnitCode}/holidays/${year}/${month}`));
  }

  public canCurrentUserAddPickupOrder(customerDetailId: number, addressId: number) {
    return this.processRequest<boolean>(this._http.get(`${API_URL}/pickups/${customerDetailId}/${addressId}/current-user-can-add-pickup-order`));
  }
}
