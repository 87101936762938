import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { saveAs } from "file-saver";
import * as moment from "moment";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, EMPTY, from, Observable, of, throwError } from "rxjs";
import { catchError, filter, first, map, mergeMap, switchMap, take, tap } from "rxjs/operators";

import { ImportProfile } from "../../import-profiles/models/import-profiles.models";
import * as Shared from "../../shared/index";
import { ContextService, ExceptionKeys, ShipperSettingsService } from "../../shared/index";
import { Product, SenderScanActionType, SenderScanValueType } from "../../shared/models/config.models";
import * as UriHelper from "../../shared/uri-helper";
import { PrintingPositionSelectorDialogComponent } from "../components/printing-position-selector-dialog.component";
import { HsCodeValidationParameters } from '../models/hs-code-validation-parameters.model';
import { HsCodesValidationApiServiceResponse } from '../models/hs-codes-validation-api-service-response.model';
import { DeliveryWindow } from "../models/routing.model";
import * as Model from "../models/shipment.model";
import { CityAndZipCheckResult, CityCheckParameters, NonWorkingDayModel, NonWorkingDaysParameters } from "../models/shipment.model";

@Injectable()
export class ShipmentService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _contextService: ContextService,
    private _http: HttpClient,
    private _tokenStorageService: Shared.TokensStorageService,
    private _modal: Modal,
    private _toastr: ToastrService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _localizationService: Shared.LocalizationService,
    private _shipperSettingsService: ShipperSettingsService
  ) { super(loggingService); }


  private _shouldRefreshShipmentOrdersOrParcels: BehaviorSubject<boolean> = new BehaviorSubject(false);


  public shouldRefreshShipmentOrdersOrParcels = this._shouldRefreshShipmentOrdersOrParcels.asObservable();


  /**
   * Vr�ti zoznam objedn�vok/z�sielok sp��aj�cich �pecifikovan� filter.
   */
  public searchShipments(filter: Model.ShipmentsCompatViewSearchParameters): Observable<Shared.PagedResult<Model.OrderForGridView>> {
    return this.processRequest<Shared.PagedResult<Model.OrderForGridView>>(this._http.get(Shared.API_URL + "/shipments?" + UriHelper.encodeAsQueryString(filter)));
  }


  /**
   * Vr�ti zoznam bal�kov sp��aj�ci �pecifikovan� filter.
   * @param filter Filter ponad bal�ky.
   */
  public searchParcels(filter: Model.ParcelsGridViewSearchParameters): Observable<Shared.PagedResult<Model.ParcelForGridView>> {
    return this.processRequest<Shared.PagedResult<Model.ParcelForGridView>>(this._http.get(Shared.API_URL + "/parcels?" + UriHelper.encodeAsQueryString(filter)));
  }

  public getDynamicFields(id: number): Observable<any> {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/products/${id}/dynamic-fields`));
  }


  public getAllowedProductsAndAdditionalServices(input: Partial<Model.AdditionalServicesCalculatorParameters>) {
    if (!this.areAdditionalServicesCalculatorParametersValid(input)) {
      return throwError("");
    }
    return this.processRequest<Product[]>(this._http.post(Shared.API_URL + "/products", input));
  }

  private areAdditionalServicesCalculatorParametersValid(input: Partial<Model.AdditionalServicesCalculatorParameters>): boolean {
    return Boolean(input.dateUtc) &&
      Boolean(input.destinationPostCode) &&
      Boolean(input.destinationCountryCode) &&
      Boolean(input.originDepotCode) &&
      (input.isCollectionRequest ? Boolean(input.collectingAddressCountryCode) : true) &&
      (input.isCollectionRequest ? Boolean(input.collectingAddressZip) : true);
  }


  public createOrder(data: Model.NewOrderModel) {
    return this.processRequest<Model.Order>(this._http.post(Shared.API_URL + "/orders", data));
  }


  public getOrder(orderId: number) {
    return this.processRequest<Model.OrderEditorModel>(this._http.get(`${Shared.API_URL}/orders/${orderId}`));
  }


  public updateOrder(data: Model.OrderEditorModel) {
    return this.processRequest<Model.Order>(this._http.put(`${Shared.API_URL}/orders/${data.order.id}`, data));
  }


  public deleteOrder(orderId: number) {
    return this.processRequest<boolean>(this._http.delete(`${Shared.API_URL}/orders/${orderId}`));
  }


  public undeleteOrder(orderId: number) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/orders/undelete/${orderId}`, null));
  }


  public deleteOrders(orderIds: number[]) {
    return this.processRequest<boolean>(this._http.delete(`${Shared.API_URL}/orders/${orderIds.join(",")}`));
  }


  public undeleteOrders(orderIds: number[]) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/orders/undelete-multiple/${orderIds.join(",")}`, null));
  }


  public deleteParcel(parcelId: number) {
    return this.processRequest<boolean>(this._http.delete(`${Shared.API_URL}/parcels/${parcelId}`));
  }


  public undeleteParcel(parcelId: number) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/parcels/undelete/${parcelId}`, null));
  }


  public deleteParcels(parcelIds: number[]) {
    return this.processRequest<boolean>(this._http.post(`${Shared.API_URL}/parcels/delete`, parcelIds));
  }


  public undeleteParcels(parcelIds: number[]) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/parcels/undelete-multiple/${parcelIds.join(",")}`, null));
  }


  public getCurrencyCodes(model: Model.P0PropertiesRequestModel) {
    if (!this.isP0PropertiesRequestModelValid(model)) {
      return of([]);
    }

    return this.processRequest<string[]>(this._http.post(`${Shared.API_URL}/orders/currency-codes`, model));
  }

  private isP0PropertiesRequestModelValid(model: Model.P0PropertiesRequestModel): boolean {
    return Boolean(model.receiverAddress?.countryCode) &&
      Boolean(model.receiverAddress?.zip) &&
      Boolean(model.receiverAddress?.city);
  }


  public getPdfFromApi = (method: string, url: string, data: any = null): Promise<Blob> => {
    const promise = new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(method, url, true);
      xhr.responseType = "blob"; // Toto je d�le�it�, in�� dostaneme zmr�en� d�ta.
      xhr.setRequestHeader("Authorization", "bearer " + this._tokenStorageService.getAccessToken());
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

      xhr.onreadystatechange = function() {
        // 4 znamen�, �e proces downloadu skon�il... ot�zne je, �e ako.
        if (xhr.readyState == 4) {
          if (xhr.status == 202 || xhr.status == 200) {
            resolve(xhr.response);
          }

          // 5XX - nejak� chyba na strane servera.
          if (xhr.status.toString().startsWith("5") || xhr.status.toString().startsWith("4")) {
            // Nastala chyba, mali by sme asi nie�o k tomu poveda�.
            // Sk�sime to deserializova�, je to JSON s chybou.

            const reader = new FileReader();

            reader.onloadend = (): any => {
              try {
                const o = JSON.parse(reader.result as string);
                reject(o);
              } catch (ex) {
                reject();
              }
            };

            reader.readAsText(xhr.response);
          }

          this.isBusy = false;
        }
      }.bind(this);

      xhr.send(JSON.stringify(data));
    });

    return promise;
  }


  public printProtocols(url: string): Observable<Model.PrintProtocolsResponse> {
    return this.processRequest<Model.PrintProtocolsResponse>(this._http.get(url));
  }


  public importShipmentOrders = (model: Model.ShipmentsImportParametersModel) => {
    return this.processRequest<Model.ImportOutput>(this._http.post(Shared.API_URL + "/shipments/import-csv", model));
  }


  public exportParcelsAsCsv = (model: Model.ShipmentDataParserSettings) => {
    return this.processRequest<Model.ExportModel>(this._http.post(Shared.API_URL + "/shipments/export-csv", model));
  }

  public exportParcelsAsMultipleCsv(model: Model.ShipmentDataParserSettings) {
    return this.processRequest<Model.ExportFileListModel>(this._http.post(Shared.API_URL + "/shipments/export-multi-csv", model)).pipe(
      tap(exportModel => {
        for (let i = 0; i < exportModel.outputFile.length; i++)
        {
          const file = exportModel.outputFile[i];
          if (file.outputText && file.outputText.length > 0) {
            const blob = new Blob(["\ufeff", file.outputText], { type: "text/csv;charset=utf-8" });
            saveAs(blob, file.outputName);
          }
        }
      }),
      catchError(error => {
        this.loggingService.logErrorData(error, "Error exporting all shipment parcels.");

        return throwError(error);
      })
    );
  }

  /**
   * Zavol� manu�lny export GeoData �dajov do DPD pre aktu�lneho tenanta.
   */
  public exportShipmentsToDpd(customerDetailId: number) {
    return this.processRequest<Shared.LogEntry[]>(this._http.post(Shared.API_URL + `/shipments/export-geodata/${customerDetailId ? customerDetailId : ""}`, null));
  }


  public exportCityServiceData(customerDetailId: number) {
    return this.processRequest<Shared.LogEntry[]>(this._http.post(`${Shared.API_URL}/city-service/${customerDetailId}/export`, null));
  }


  public notifyShouldRefreshShipmentOrdersOrParcels() {
    this._shouldRefreshShipmentOrdersOrParcels.next(true);
  }


  public downloadShipmentsImportErrorFile(customerDetailId: number, filename: string) {
    return this.processBlobRequest(this._http.get(`${Shared.API_URL}/shipments/import-csv-error-file/${customerDetailId}/${filename}`, { responseType: "blob" }));
  }


  public loadCustomImportsProfiles(customerDetailId: number) {
    return this.processRequest<ImportProfile[]>(this._http.get(`${Shared.API_URL}/import-profile/${customerDetailId}/list`));
  }


  public loadCustomImportsProfilesByType(customerDetailId: number, typeCode: string) {
    return this.processRequest<ImportProfile[]>(this._http.get(`${Shared.API_URL}/import-profile/${customerDetailId}/list/${typeCode}`));
  }


  /**
   * Označí všetky collection requesty daného zákazníka ako pripravené na odoslanie v rámci sychronizácie s DPD a exportu do súborov.
   * @param customerDetailId Identifikátor zákazníka (teda presnejšie jeho detailu).
   */
  public markCollectionRequestsAsReadyForExportToDpd(customerDetailId: number, addressCustomId: string) {
    return this.processRequest<number>(this._http.post(`${Shared.API_URL}/orders/${customerDetailId}/mark-cr-ready-for-export/${addressCustomId}`, {}));
  }


  /**
   * Zduplikuje špecifikovanú objednávku a vráti ID jej duplikátu.
   * @param customerDetailId Identifikátor detailu zákazníka.
   * @param orderId Identifikátor objednávky zásielky, ktorú chceme zduplikovať.
   */
  public duplicateShipmentOrder(customerDetailId: number, orderId: number) {
    return this.processRequest<number>(this._http.post(`${Shared.API_URL}/orders/${customerDetailId}/${orderId}/copy`, {}));
  }


  /**
   * Vráti, či pre daného zákazníka a zvozovú adresu existuje aspoň jeden collection request.
   * @param customerDetailId Identifikátor zákazníka.
   * @param addressCustomId Vlastný identifikátor zvozovej adresy.
   */
  public getAnyCRExists(customerDetailId: number, addressCustomId: string) {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/orders/${customerDetailId}/addresses/${addressCustomId}/cr-order-exists`));
  }


  /**
   * Vráti počet objednávok, ktoré ešte neboli synchronizované s DPD.
   * @param customerDetailId Identifikátor zákazníka.
   * @param addressCustomId Vlastný identifikátor zvozovej adresy.
   */
  public getNotExportedShipmentsCount(customerDetailId: number, addressCustomId: string) {
    return this.processRequest<number>(this._http.get(`${Shared.API_URL}/orders/${customerDetailId}/addresses/${addressCustomId}/not-exported-shipments-count`));
  }

  /**
   * Vráti počet CR, ktoré ešte neboli synchronizované s DPD.
   * @param customerDetailId Identifikátor zákazníka.
   * @param addressCustomId Vlastný identifikátor zvozovej adresy.
   */
   public getNotExportedCrCount(customerDetailId: number, addressCustomId: string) {
    return this.processRequest<number>(this._http.get(`${Shared.API_URL}/orders/${customerDetailId}/addresses/${addressCustomId}/not-exported-cr-count`));
  }

  /**
   * Vráti počet štítkov, ktoré ešte neboli vytlačené DPD.
   * @param customerDetailId Identifikátor zákazníka.
   * @param addressCustomId Vlastný identifikátor zvozovej adresy.
   */
  public getNotPrintedLabelsCount(customerDetailId: number, addressCustomId: string, date: Date) {
    const dateString = moment(date).format("YYYY-MM-DD");

    return this.processRequest<number>(this._http.get(`${Shared.API_URL}/orders/${customerDetailId}/addresses/${addressCustomId}/not-printed-labels-count/${dateString}`));
  }


  public checkZipAndCity(parameters: CityCheckParameters) {
    return this.processRequest<CityAndZipCheckResult>(this._http.post(`${Shared.API_URL}/georouting/check-city-and-zip`, parameters));
  }


  public getNonWorkingDays(parameters: NonWorkingDaysParameters) {
    return this.processRequest<NonWorkingDayModel[]>(this._http.post(`${Shared.API_URL}/georouting/get-nonworkingdays`, parameters));
  }

  public deleteAllOrdersForPickupAddress(customerDetailId: number, senderAddressCustomId: string, confirmationText: string) {
    return this.processRequest<number>(this._http.delete(`${Shared.API_URL}/orders/${customerDetailId}/addresses/${senderAddressCustomId}?deleteConfirmationText=${confirmationText}`));
  }


  public getSenderScanValueTypes(): Observable<SenderScanValueType[]> {
    return of([
      {
        resourceKey: "",
        code: "recipient",
        name: "recipient_reference_number"
      },
      {
        resourceKey: "",
        code: "parcel",
        name: "parcel_reference_number"
      }
    ]);
  }


  public getSenderScanActionTypes(): Observable<SenderScanActionType[]> {
    return of([
      {
        resourceKey: "",
        code: "print",
        name: "action_print_labels"
      },
      {
        resourceKey: "",
        code: "edit",
        name: "action_edit_shipment"
      }
    ]);
  }


  public getValidDpdPreciseDeliveryWindows(validForDate: Date, customerDetailId: number, countryCode: string, zip: string) {
    return this.processRequest<DeliveryWindow[]>(this._http.get(`${Shared.API_URL}/precise/delivery-windows/${moment(validForDate).format("YYYYMMDDHHmm")}/customers/${customerDetailId}/${countryCode}-${zip}`));
  }


  public setShipmentOrdersPickupDate(shipmentOrderIds: number[], newPickupDate: Date) {
    return this.processRequest<any>(this._http.post(`${Shared.API_URL}/orders/pickup-date`, { orderIds: shipmentOrderIds, newPickupDate }));
  }


  public getHolidays(businessUnitCode: string, year: number, month: number) {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/business-units/${businessUnitCode}/holidays/${year}/${month}`));
  }

  public getCityServiceDeliveryWindows() {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/city-service/delivery-windows`));
  }

  public getCityServiceStreets() {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/city-service/streets`));
  }

  public getMinimalOrderDate(customerDetailId: number, addressCustomId: string, isCollectionRequest: boolean = false) {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/orders/${customerDetailId}/${addressCustomId}/minimal-order-date/${isCollectionRequest}`));
  }

  public getMaximalCROrderDate(customerDetailId: number) {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/orders/${customerDetailId}/maximal-cr-order-date`));
  }

  public getProtocolsTypes(): Observable<Model.ProtocolType[]> {
    return this.processRequest<Model.ProtocolType[]>(this._http.get(`${Shared.API_URL}/protocols/protocol-types`));
  }


  public getProtocolGenerationType() {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/protocols/protocol-generation-types`));
  }


  public searchAcceptanceProtocols(filter: Model.AcceptanceProtocolHistoryGridFilter): Observable<Shared.PagedResult<Model.AcceptanceProtocol>> {
    return this.processRequest<Shared.PagedResult<Model.AcceptanceProtocol>>(this._http.get(Shared.API_URL + "/protocols/filtered?" + UriHelper.encodeAsQueryString(filter)));
  }


  public printAcceptanceProtocol(id: number) {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/protocols/${id}/body`));
  }

  public getReceiverTypes() {
    /** @todo replace with API call once created. */
    // return this.processRequest<ConfigModel.SenderReceiverType>(this._http.get(`${Shared.API_URL}/`));

    return of([
      {resourceKey: "", code: "P", name: "sender_receiver_type_private"},
      {resourceKey: "", code: "B", name: "sender_receiver_type_business"},
    ]);
  }

  public getSenderDocumnetType() {
    /** @todo replace with API call once created. */
    // return this.processRequest<ConfigModel.SenderDocumentType>(this._http.get(`${Shared.API_URL}/`));

    return of([
      {resourceKey: "", code: "P", name: "sender_document_type_passport"},
      {resourceKey: "", code: "C", name: "sender_document_type_cnpj"},
    ]);
  }

  public hasProductReturnService(productId: number, serviceIds: number[] = []): Observable<boolean> {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/products/contains-return-service?productId=${productId}${serviceIds.length ? "&serviceIds=" + serviceIds : ""}`));
  }

  public validateCustomsTariffNumber(params: HsCodeValidationParameters): Observable<HsCodesValidationApiServiceResponse> {
    const isValidResponse: HsCodesValidationApiServiceResponse = {
      isValid: true,
      serviceExceptionCode: "",
      exceptionParameters: [],
      message: ""
    };

    const fallbackResponse: HsCodesValidationApiServiceResponse = {
      isValid: true,
      serviceExceptionCode: "",
      exceptionParameters: [],
      message: this._localizationService.getLocalizedString(
        "error_customs_tariff_number_api_validation_bad_response",
        params.hsCode
      )
    };

    if (!this._shipperSettingsService.isCustomsTariffNumberValidationEnabled) {
      return of(isValidResponse);
    }

    return this.processRequest<HsCodesValidationApiServiceResponse>(
      this._http.get(`${Shared.API_URL}/orders/validate-customs-tariff-number?${UriHelper.encodeAsQueryString(params)}`)
    ).pipe(
      tap(response => {
        if (response.serviceExceptionCode) {
          const shipperKey = `${response.serviceExceptionCode}_shipper`;

          // Try to get translation for Shipper (may contain links).
          response.message = this._localizationService.getLocalizedStringWithLinks(
            shipperKey, ...response.exceptionParameters
          );

          // Fallback to regular message.
          if (response.message === shipperKey || !response.message) {
            response.message = this._localizationService.getLocalizedString(
              response.serviceExceptionCode, ...response.exceptionParameters
            );
          }
        }
      }),
      catchError(() => {
        return of(fallbackResponse);
      })
    );
  }

  public searchArchivedShipments(filter): Observable<Shared.PagedResult<Model.ParcelForGridView>> {
    return this.processRequest<Shared.PagedResult<Model.ParcelForGridView>>(this._http.get(`${Shared.API_URL}/shipments/archived?` + UriHelper.encodeAsQueryString(filter)));
  }

  public exportArchivedShipments(model: Model.ArchiveShipmentDataParserSetting): Observable<Model.ExportFileListModel> {
    return this._http.post<Model.ExportFileListModel>(Shared.API_URL + "/shipments/export-multi-csv/archived", model);
  }

  public purgeArchivedShipments(params): Observable<boolean> {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/shipments/archived/purge?` + UriHelper.encodeAsQueryString(params)));
  }


  private getLabelPositionOnA4(
      url: string,
      parameters: Omit<Model.ShipmentLabelsPrintRequestParameters, "requestedPrintingStartPositionOnA4">): Observable<number | null> {
    return this.processRequest<boolean>(this._http.post(url, parameters)).pipe(
      catchError(() => of(null)),
      switchMap(shouldAsk => {
        if (!shouldAsk) {
          return of(null);
        }

        return from(
          this._modal.open(
            PrintingPositionSelectorDialogComponent,
            overlayConfigFactory({})
          ).result
        ).pipe(
           mergeMap(position => position ? of(position) : EMPTY)
        );
      })
    );
  }


  public printLabels(
      url: string,
      parameters: Omit<Model.ShipmentLabelsPrintRequestParameters, "requestedPrintingStartPositionOnA4" | "onlyCheckIfRequiresPositionSpecification">,
      filename: string,
      onRequestStart: () => void = () => { }): Observable<Blob> {
    return this.getLabelPositionOnA4(url, {
      ...parameters,
      onlyCheckIfRequiresPositionSpecification: true
    }).pipe(
      tap(() => onRequestStart()),
      switchMap(position => from(
        this.getPdfFromApi("POST", url, {
          ...parameters,
          onlyCheckIfRequiresPositionSpecification: false,
          requestedPrintingStartPositionOnA4: position
        }))
      ),
      tap(result => {
        if (!result.type.toLowerCase().startsWith("application/json")) {
          // Dostali sme urcite Blob.
          if (result.size > 0) {
            // Mali by sme mat obsah PDF suboru.
            let mediaType = "application/pdf";

            // Only E-Label is returned as PNG
            if (result.type.toLowerCase().startsWith("image/png")) {
              mediaType = "image/png";
              filename = filename.replace('.pdf', '.png'); // Simple hack to replace file extension
            }

            const blob = new Blob([result], { type: mediaType });

            saveAs(blob, filename, true);
          } else {
            // Tlacili sme.
            this._toastr.success(this._localizationService.getLocalizedString("labels_sent_to_printer"));
          }
        } else {
          // Tlacili sme.
          this._toastr.success(this._localizationService.getLocalizedString("labels_sent_to_printer"));
        }
      }),
      catchError(error => {
        this.loggingService.logErrorData(error, "Error printing labels for selected shipment orders.");

        if (error.key) {
          this._toastr.error(this._localizationService.getLocalizedExceptionString(error));
        } else {
          this._toastr.error(this._localizationService.getLocalizedString("shipments_list_error_printing_labels"));
        }

        return throwError(error);
      })
    );
  }


  public printLabelsForOderIds(ids: number[], filename: string, onRequestStart: () => void = () => { }): Observable<Blob> {
    return this._contextService.currentAddress.pipe(filter(ca => Boolean(ca))).pipe(
      take(1),
      map(ca => ({
        customerDetailId: ca.customerDetailId,
        orderIds: ids,
        parcelIds: null,
        senderAddressId: ca.id
      })),
      switchMap(parameters => this.printLabels(`${Shared.API_URL}/shipments/labels`, parameters, filename, onRequestStart))
    );
  }


  public printLabelsForParcelIds(ids: number[], filename: string, onRequestStart: () => void = () => { }): Observable<Blob> {
    return this._contextService.currentAddress.pipe(filter(ca => Boolean(ca))).pipe(
      take(1),
      switchMap(ca => {
        const parameters = {
          customerDetailId: ca.customerDetailId,
          orderIds: null,
          parcelIds: ids,
          senderAddressId: ca.id
        };

        const url = `${Shared.API_URL}/labels/customer-details/${ca.customerDetailId}/addresses/${ca.customId}/parcels`;

        return this.printLabels(url, parameters, filename, onRequestStart);
      })
    );
  }


  public printReturnLabelsForOrderIds(ids: number[], onRequestStart: () => void = () => { }): Observable<Blob> {
    return this._contextService.currentAddress.pipe(filter(ca => Boolean(ca))).pipe(
      take(1),
      map(ca => ({
        customerDetailId: ca.customerDetailId,
        orderIds: ids,
        parcelIds: null,
        senderAddressId: ca.id
      })),
      tap(() => onRequestStart()),
      switchMap(parameters => this.printLabels(`${Shared.API_URL}/shipments/return-labels`, parameters, "return-labels.pdf"))
    );
  }


  public printProtocol(method: string, url: string, filename: string, data?: any): Observable<Blob> {
    return from(this.getPdfFromApi(method, url, data)).pipe(
      tap(result => {
        if (!result.type.toLowerCase().startsWith("application/json")) {
          // Dostali sme urcite Blob.
          if (result.size > 0) {
            // Mali by sme mat obsah PDF suboru.
            const mediaType = "application/pdf";
            const blob = new Blob([result], { type: mediaType });
            filename ||= "protocol.pdf";

            saveAs(blob, filename, true);
          } else {
            // Tlacili sme.
            this._toastr.success(this._localizationService.getLocalizedString("protocol_sent_to_printer"));
          }
        } else {
          // Aj sem sme sa mohli dostat, ked sme tlacili.
          this._toastr.success(this._localizationService.getLocalizedString("protocol_sent_to_printer"));
        }
      }),
      catchError(error => {
        if (error.key) {
          this._toastr.error(this._localizationService.getLocalizedExceptionString(error));
        } else {
          this._toastr.error(this._localizationService.getLocalizedString(ExceptionKeys.GeneralException));
        }

        return throwError(error);
      })
    );
  }


  public printAcceptanceProtocolForOrderIds(ids: number[]): Observable<Blob> {
    return this._contextService.currentAddress.pipe(
      filter(ca => Boolean(ca)),
      take(1),
      map(ca => `${Shared.API_URL}/acceptance-protocol/${this._localizationService.currentCultureCode}/customer-details/${ca.customerDetailId}/orders/1`),
      switchMap(url => this.printProtocol("POST", url, "acceptance-protocol.pdf", ids)),
      catchError(error => {
        this.loggingService.logErrorData(error, "Error printing acceptance protocol for selected shipment orders.");

        return throwError(error);
      })
    );
  }


  public printAcceptanceProtocolForShipmentIds(ids: number[]): Observable<Blob> {
    return this._contextService.currentAddress.pipe(
      filter(ca => Boolean(ca)),
      take(1),
      map(ca => `${Shared.API_URL}/acceptance-protocol/${this._localizationService.currentCultureCode}/customer-details/${ca.customerDetailId}/1`),
      switchMap(url => this.printProtocol("POST", url, "acceptance-protocol.pdf", ids)),
      catchError(error => {
        this.loggingService.logErrorData(error, "Error printing acceptance protocol for selected shipment orders.");

        return throwError(error);
      })
    );
  }


  public printCodTakeoverProtocolForOrderIds(ids: number[]): Observable<Blob> {
    return this._contextService.currentAddress.pipe(
      filter(ca => Boolean(ca)),
      take(1),
      map(ca => `${Shared.API_URL}/cod-takeover-protocol/${this._localizationService.currentCultureCode}/customer-details/${ca.customerDetailId}/orders/1`),
      switchMap(url => this.printProtocol("POST", url, "cod-takeover-protocol.pdf", ids)),
      catchError(error => {
        this.loggingService.logErrorData(error, "Error printing COD takeover protocol for selected shipment orders.");

        return throwError(error);
      })
    );
  }

  public printCodTakeoverProtocolForShipmentIds(ids: number[]): Observable<Blob> {
    return this._contextService.currentAddress.pipe(
      filter(ca => Boolean(ca)),
      take(1),
      map(ca => `${Shared.API_URL}/cod-takeover-protocol/${this._localizationService.currentCultureCode}/customer-details/${ca.customerDetailId}/1`),
      switchMap(url => this.printProtocol("POST", url, "cod-takeover-protocol.pdf", ids)),
      catchError(error => {
        this.loggingService.logErrorData(error, "Error printing COD takeover protocol for selected shipment orders.");

        return throwError(error);
      })
    );
  }

  public printCrOrderConfirmationProtocol(ids: number[]): Observable<any> {
    return this._contextService.currentAddress.pipe(
      filter(ca => Boolean(ca)),
      take(1),
      map(ca => `${Shared.API_URL}/cr-order-confirmation-protocol/${this._localizationService.currentCultureCode}/customer-details/${ca.customerDetailId}/orders/1`),
      switchMap(url => this.printProtocol("POST", url, "cr-order-confirmation-protocol.pdf", ids)),
      catchError(error => {
        this.loggingService.logErrorData(error, "Error printing cr confirmation protocol for selected shipment orders.");

        return throwError(error);
      }),
    );
  }
}
