import { Directive, HostListener, Input } from "@angular/core";

import { WizardService } from "../services/wizard.service";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[wizardCompleteStep]"
})
export class WizardCompleteStepDirective {
  @Input("wizardCompleteStep") steps: string | string;

  constructor(private wizardService: WizardService) { }

  @HostListener("click")
  onClick() {
    this.wizardService.completeStep(this.steps);
  }
}
