import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

import { animations } from "../animations";
import { WizardCssClass } from "../models";
import { toCssClassArray } from "../utils";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "wizard-label",
  templateUrl: "./wizard-label.component.html",
  animations: [
    animations.wizardLabel
  ],
  host: {
    "[@wizardLabel]": "true"
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardLabelComponent {
  @Input() text: string;

  @Input()
  set labelClass(value: string | string[]) {
    this.applyPopupClasses(value);
  }

  @HostBinding("class") labelClasses: string[] = [WizardCssClass.Label];

  private applyPopupClasses(labelClass: string | string[]) {
    this.labelClasses = [
      WizardCssClass.Label,
      ...toCssClassArray(labelClass)
    ];
  }
}
