<div class="dialog new-version-info-dialog">
  <h1>{{ 'new_version_of_shipper_is_available_dialog_title' | shpLocalize:version }}</h1>

  <div>
    <a [routerLink]="['/support/change-log']" [fragment]="version" (click)="close()">{{ 'new_version_change_log_link' | shpLocalize }}</a>
  </div>

  <div class="d-flex mt-3">
    <button id="button-close" class="flex-fill" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>
</div>