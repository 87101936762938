<div class="regular-pickups">
  <div class="grid">
    <div class="pickup-order-type-header">
      <h2>{{ 'regular_pickup_order' | shpLocalize }}</h2>
      <button *ngIf="regularPickupOrder == null && canEdit" class="top-button" (click)="addRegularPickupOrder()" id="add-pickup-order"><span class="ic-add-2"></span><span class="label" [innerHtml]="'pickup_order_add' | shpLocalize"></span></button>
    </div>
    <shp-progress-overlay [isActive]="isLoadingList" [message]="'loading_regular_pickup_orders' | shpLocalize"></shp-progress-overlay>
    <shp-progress-overlay [isActive]="isDeleting" [message]="'deleting_pickup_orders' | shpLocalize"></shp-progress-overlay>
    <shp-retry-overlay [isVisible]="loadingErrorOccured"
                       errorMessage="{{ 'pickup_orders_loading_error' | shpLocalize }}"
                       [reloadFunction]="reloadList"></shp-retry-overlay>
    <div class="grid-header">
      <table>
        <thead>
          <tr>
            <th class="col-date">{{ 'configuration_valid_from' | shpLocalize }}</th>
            <th class="col-address">{{ 'pickup_order_contact' | shpLocalize }}</th>
            <th class="col-date">{{ 'state' | shpLocalize }}</th>
            <th *ngIf="cityserviceAvailable" class="col-date">CityService</th>
            <th class="col-date">{{ 'requested_pickup_time' | shpLocalize }}</th>
            <th class="col-day-short">{{ 'weekday_short_1' | shpLocalize }}</th>
            <th class="col-day-short">{{ 'weekday_short_2' | shpLocalize }}</th>
            <th class="col-day-short">{{ 'weekday_short_3' | shpLocalize }}</th>
            <th class="col-day-short">{{ 'weekday_short_4' | shpLocalize }}</th>
            <th class="col-day-short">{{ 'weekday_short_5' | shpLocalize }}</th>
            <th class="col-day-short">{{ 'weekday_short_6' | shpLocalize }}</th>
            <th class="col-commands">&nbsp;</th>
            <th class="col-spacer">&nbsp;</th>
          </tr>
        </thead>
      </table>
    </div>

    <div class="grid-content auto-scrollbars">
      <table *ngIf="regularPickupOrder">
        <tbody>
          <tr>
            <td class="col-date">{{ regularPickupOrder.fromDate | shpLocalizeDate:"L" }}</td>
            <td class="col-address">
              {{ regularPickupOrder.contactPerson }}<br />
              {{ regularPickupOrder.email }}<br />
              {{ regularPickupOrder.phone }}
            </td>
            <td class="col-date">{{ 'pickup_order_state_' + regularPickupOrder.stateId | shpLocalize }}</td>
            <td *ngIf="cityserviceAvailable" class="col-date">
              <span *ngIf="regularPickupOrder.isSameDay == true" class="check-mark"></span>
              <span *ngIf="regularPickupOrder.isSameDay == false" class="cross-mark"></span>
            </td>
            <td class="col-date">{{ pickupOrderTime }}</td>
            <td class="col-day-short">
              <span *ngIf="regularPickupOrder.monday == true" class="check-mark"></span>
              <span *ngIf="regularPickupOrder.monday == false" class="cross-mark"></span>
            </td>
            <td class="col-day-short">
              <span *ngIf="regularPickupOrder.tuesday == true" class="check-mark"></span>
              <span *ngIf="regularPickupOrder.tuesday == false" class="cross-mark"></span>
            </td>
            <td class="col-day-short">
              <span *ngIf="regularPickupOrder.wednesday == true" class="check-mark"></span>
              <span *ngIf="regularPickupOrder.wednesday == false" class="cross-mark"></span>
            </td>
            <td class="col-day-short">
              <span *ngIf="regularPickupOrder.thursday == true" class="check-mark"></span>
              <span *ngIf="regularPickupOrder.thursday == false" class="cross-mark"></span>
            </td>
            <td class="col-day-short">
              <span *ngIf="regularPickupOrder.friday == true" class="check-mark"></span>
              <span *ngIf="regularPickupOrder.friday == false" class="cross-mark"></span>
            </td>
            <td class="col-day-short">
              <span *ngIf="regularPickupOrder.saturday == true" class="check-mark"></span>
              <span *ngIf="regularPickupOrder.saturday == false" class="cross-mark"></span>
            </td>
            <td class="col-commands">
              <button *ngIf="canEdit" id="button-show-details" type="button" (click)="editRegularPickupOrder(regularPickupOrder.id)" title="{{ 'pickup_order_edit' | shpLocalize }}"><span class="ic-pencil-1"></span></button>
              <button *ngIf="canEdit" id="button-show-details" type="button" (click)="deletePickupOrder(regularPickupOrder.id)" title="{{ 'pickup_order_delete' | shpLocalize }}"><span class="ic-bin"></span></button>
            </td>
            <td class="col-spacer"></td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="regularPickupOrder == null">
        <tbody>
          <tr>
            <td>{{ 'message_no_regular_pickup_orders_in_db' | shpLocalize }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>