import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { debounceTime, finalize, map } from "rxjs/operators";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { validateIban } from "../../../shared/validators";
import * as _ from "lodash";

import * as Shared from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import * as ConfigModel from "../../../shared/models/config.models";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import * as CustomerModel from "../../../shared/models/customer.models";


const DymmySwiftCode = "DUMMY";
const UnknownBankData: ConfigModel.Bank = { bankName: "unknown_bank", swiftCode: DymmySwiftCode, bankCode: "0000", abbr: "", countryCode: "SK", id: 0, isNew: false, isPersistent: true };
const AvailableCountryCodes = ["SK", "CZ", "PL", "HU"];

@Component({
  selector: Shared.SELECTOR_PREFIX + "-bank-account-editor",
  templateUrl: "./bank-account-editor.component.html"
})
export class BankAccountEditorComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _customerService: Shared.CustomerService,
    private _formBuilder: FormBuilder,
    private _modal: Modal,
    toastr: ToastrService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);

    this.createForm();
  }


  private _bankAccount: CustomerModel.BankAccount = null;
  private _ibanTypeCheckSubscription: Subscription = null;

  private _knownBankCountryCodes: string[] = null;

  public isBusy: boolean = false;
  public badIban: boolean = false;
  public form: FormGroup = null;
  public banks: ConfigModel.Bank[] = null;
  public isUnknownBankSelected: BehaviorSubject<boolean> = new BehaviorSubject(false);


  private getEmptyBankAccount = (): CustomerModel.BankAccount => {
    var bankAccount: CustomerModel.BankAccount = {
      customerDetailId: this.customerDetailId,
      externalSystemId: "",
      name: "",
      bankName: "",
      iban: "",
      bic: "",
      accountNumber: "",
      countryCode: "",
      customId: "",
      stateId: null,

      id: 0,
      isNew: true,
      isPersistent: true,
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      createdByUser: null,
      modifiedByUser: null
    }

    return bankAccount;
  }

  private createForm() {
    this.form = this._formBuilder.group({
      customerDetailId: [this.customerDetailId],
      name: [""],
      iban: ["", validateIban],
      bic: ["", Validators.required],
      swiftCode: [""],
      accountNumber: [""],
      bankName: [null, Validators.required],
      countryCode: [null]
    });

    this._ibanTypeCheckSubscription = this.form.controls.iban.valueChanges.pipe(debounceTime(100)).subscribe(value => {
      if (value) {
        var adjustedIban = value.split(" ").join("");
        var bankElement = this.unknownBankData;
        var countryCode = ""
        var bankCodeLength = 4;
        if(adjustedIban.length >= 8) {
          countryCode = adjustedIban.substring(0, 2);
        }
        if(countryCode !== "" && this._knownBankCountryCodes.indexOf(countryCode) > -1) {
          var bankCode = adjustedIban.substring(4, 4 + bankCodeLength);
          bankElement = _.find(this.banks, { bankCode: bankCode, countryCode: countryCode });
        }
        this.populateBankData(bankElement ? bankElement : this.unknownBankData);
      }
    });
  }


  private setFormValue(bankAccount: CustomerModel.BankAccount) {
    this.form.patchValue({
      name: bankAccount.name,
      iban: bankAccount.iban,
      bic: bankAccount.bic,
      swiftCode: bankAccount.bic,
      accountNumber: bankAccount.accountNumber,
      bankName: bankAccount.bankName,
      countryCode: bankAccount.countryCode
    });

    this.form.markAsPristine();
  }


  private parseCountryCode(iban: string) {
    var adjustedIban = iban.split(" ").join("");
    if(adjustedIban.length >= 4) {
      return adjustedIban.substring(0, 2);
    } else {
      return "SK";
    }
  }


  public get isNew() {
    return this._bankAccount && this._bankAccount.id === 0;
  }


  public get unknownBankData(): ConfigModel.Bank {
    let bankData = UnknownBankData;
    bankData.bankName = this.localizationService.getLocalizedString(UnknownBankData.bankName);
    return bankData;
  }


  public get isBicReadOnly(): boolean {
    var value = true;
    this.isUnknownBankSelected.subscribe(val => {
      value = val;
    });

    return value;
  }


  public saveBankAccount() {
    var bankAccount = this._bankAccount;
    Object.assign(bankAccount, this.form.value);
    bankAccount.iban.replace(" ", "");  // odstránime medzery z IBAN
    bankAccount.customerDetailId = this.customerDetailId;  // nastavíme customerDetailId
    bankAccount.countryCode = this.parseCountryCode(bankAccount.iban); // nastavíme contryCode z IBANu

    this.isBusy = true;
    if(this.isNew) {
      this._customerService.saveCustomerBankAccount(this.tenantId, this.customerDetailId, bankAccount).pipe(
        finalize(() => this.isBusy = false)
      ).subscribe(
          result => this.bankAccountSavingSucceeded(result),
          error => this.bankAccountSavingFailed(error)
        );
    }
  }

  public goBackToListOfBankAccounts() {
    this.router.navigate(["/settings/bank-accounts"]);
  }


  public selectBank(event: any) {
    var bicCode = event.target.value as string;
    var bankElement = _.find(this.banks, { swiftCode: bicCode });
    this.populateBankData(bankElement);
  }



  private populateBankNameForm(bankName: string = "", bic: string = "", countryCode: string = null) {
    this.form.patchValue({
      bankName: bankName,
      bic: bic,
      countryCode: countryCode
    });
  }

  private populateBankNameSelectBox(bic: string = "") {
    this.form.patchValue({
      swiftCode: bic
    });
  }

  private populateBankData(bank: ConfigModel.Bank) {
    this.isUnknownBankSelected.next(bank && bank.swiftCode === DymmySwiftCode ? true : false);
    if(bank && bank.swiftCode !== DymmySwiftCode) {
      this.populateBankNameForm(bank.bankName, bank.swiftCode, bank.countryCode);
      this.populateBankNameSelectBox(bank.swiftCode);
    } else {
      this.populateBankNameForm();
      this.populateBankNameSelectBox(DymmySwiftCode);
    }
  }


  private bankAccountSavingSucceeded(result: any): void {
    this.toastr.success(this.localizationService.getLocalizedString("bank_account_successfully_created"), null, { timeOut: 30000, extendedTimeOut: 30000 });
    this.goBackToListOfBankAccounts();
  }

  private bankAccountSavingFailed(error: any): void {
    this.loggingService.logErrorData(error);
    this.toastr.error(this.localizationService.getLocalizedString("error_saving_bank_account") + ": " + this.localizationService.getLocalizedExceptionString(error));
  }

  protected loadLookups() {
    return forkJoin([
      this._skdataConfigService.getBanks(),
    ]).pipe(
      map(result => {
        [ this.banks ] = result;
        let countryCodes = this.banks.map(b =>b.countryCode);
        this._knownBankCountryCodes = _.filter(countryCodes);
        this.banks.push(this.unknownBankData);

        return true;
      })
    );
  }


  public ngOnInit() {
    super.ngOnInit();

    this.isBusy = true;

    this.loadLookups().subscribe(loaded => {
      this._bankAccount = this.getEmptyBankAccount();
      this.setFormValue(this._bankAccount);
      this.isBusy = false;
    }, ex => {
      this.isBusy = false;
      this.loggingService.logErrorData(ex);
      this.toastr.error(this.localizationService.getLocalizedString("error_loading_bank_account_lookups") + ": " + this.localizationService.getLocalizedExceptionString(ex));
    });
  }

}