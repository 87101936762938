<div class="additional-service" *ngFor="let service of editor.additionalServices">
  <!-- zobrazujeme iba povolené služby v prípade, ak editujeme -->
  <!-- <div *ngIf="(service.isAllowed || isReadOnly) && !(editor.isCollectionRequest && !(service.code === 'COD'))"> -->
  <div *ngIf="(service.isAllowed || isReadOnly)">
    <div class="ctl" *ngIf="!isReadOnly">
      <input type="checkbox" [(ngModel)]="service.isSelected" name="{{ 'service_' + service.code }}" (ngModelChange)="onServiceSelect()"
             [disabled]="!service.isAvailable" id="{{ 'service-' + service.code}}" /><label [attr.for]="'service-' + service.code">{{ service.name }}</label>
    </div>
    <h3 *ngIf="isReadOnly && service.isSelected">{{ service.name }}</h3>
    <div class="additiona-service-data" *ngIf="(service.data || service.code === '2CRET') && service.isSelected " [ngSwitch]="service.code">

      <div *ngSwitchCase="'COD'" class="">
        <form>
          <ng-container *ngIf="!editor.isEnabledCodForEachParcel">
            <!-- typ povolenej platby adresátom -->
            <div class="ctl" *ngIf="isReadOnly">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input type="text" readonly="readonly" id="cod-collection-type" name="codCollectionType" value="{{ 'cod_collection_type_' + service.data.collectionTypeId | shpLocalize }}" pInputText />
                  <label for="cod-collection-type">{{ 'cod_collection_type' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl required" *ngIf="!isReadOnly">
              <div class="p-field p-field--full">
                <span class="p-float-label" dpdFloatingLabel>
                  <select id="cod-collection-type" [(ngModel)]="service.data.collectionTypeId" name="codCollectionType" required>
                    <option *ngFor="let collectionTypeId of editor.availableCollectionTypeIds" [ngValue]="collectionTypeId">{{ 'cod_collection_type_' + collectionTypeId | shpLocalize }}</option>
                  </select>
                  <label for="cod-collection-type">{{ 'cod_collection_type' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <!-- číslo BÚ -->
            <div class="ctl" *ngIf="isReadOnly">
              <!-- zobrazujeme iba IBAN, BIC a CustomId vybraného účtu -->
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input type="text" readonly="readonly" id="cond-bank-account" name="codBankAccount" value="{{ selectedBankAccount == null ? ('cod_no_bank_accounts_defined' | shpLocalize) : (selectedBankAccount.iban ? selectedBankAccount.iban + ' (' + selectedBankAccount.bic + ', ' + selectedBankAccount.customId + ')' : selectedBankAccount.accountNumber + ' (' + selectedBankAccount.name + ', ' + selectedBankAccount.customId + ')') }}" pInputText />
                  <label for="cond-bank-account">{{ 'cod_bank_account' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl" *ngIf="!isReadOnly">
              <div *ngIf="availableBankAccounts.length === 0" class="p-field p-field--full">
                <span class="p-float-label">
                  <input type="text"
                        *ngIf="availableBankAccounts.length === 0"
                        [readonly]="form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount"
                        [class.ng-touched]="form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount"
                        [class.ng-invalid]="form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount"
                        value="{{ (form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount ? 'cod_no_foreign_bank_accounts_defined' : 'cod_no_bank_accounts_defined') | shpLocalize }}"
                        id="cod-bank-account" pInputText />
                  <label for="cod-bank-account">{{ 'cod_bank_account' | shpLocalize }}</label>
                </span>
              </div>
              <div *ngIf="availableBankAccounts.length > 0" class="p-field p-field--full">
                <span class="p-float-label" dpdFloatingLabel>
                  <select id="cod-bank-account" name="codBankAccount" [(ngModel)]="selectedBankAccount" *ngIf="availableBankAccounts.length > 0">
                    <option *ngFor="let bankAccount of availableBankAccounts" [ngValue]="bankAccount">{{ bankAccount.iban ? bankAccount.iban + " (" + bankAccount.bic + ", " + bankAccount.customId + ")" : bankAccount.accountNumber + " (" + bankAccount.name + ", " + bankAccount.customId + ")" }}</option>
                  </select>
                  <label for="cod-bank-account">{{ 'cod_bank_account' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl" [class.required]="!isReadOnly">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <p-inputNumber [(ngModel)]="service.data.amount" id="cod-amount" name="codAmount" [isRequired]="true" required [min]="0" [max]="999999" [disabled]="isReadOnly"
                    [showButtons]="false" [maxFractionDigits]="2" [minFractionDigits]="2" mode="decimal"></p-inputNumber>
                  <label for="cod-amount">{{ 'cod_amount' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl" [class.required]="!isReadOnly" *ngIf="!isReadOnly">
              <div class="p-field p-field--full">
                <span class="p-float-label" dpdFloatingLabel>
                  <select [(ngModel)]="service.data.currency" id="cod-currency" name="codCurrency" required>
                    <option *ngFor="let currency of editor.availableCurrencyCodes" [ngValue]="currency">{{ currency.toUpperCase() }}</option>
                  </select>
                  <label for="cod-amount">{{ 'currency' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl" *ngIf="isReadOnly">
              <label for="cod-amount">{{ 'currency' | shpLocalize }}</label>
              <input readonly="readonly" value="{{ service.data.currency.toUpperCase() }}" />
            </div>
            <div class="ctl">
              <label for="cod-purpose">{{ 'cod_purpose' | shpLocalize }}</label>
              <input shpNumbersInput [onlyNumbers]="editor.isSophia" type="text" [(ngModel)]="service.data.purpose" maxlength="15" [readonly]="isReadOnly" id="cod-purpose" name="codPurpose" />
            </div>
          </ng-container>
          <ng-container *ngIf="editor.isEnabledCodForEachParcel">
            <div class="ctl" *ngIf="isReadOnly">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input type="text" readonly="readonly" id="cod-collection-type" name="codCollectionType" value="{{ 'cod_collection_type_' + service.data.collectionTypeId | shpLocalize }}" pInputText />
                  <label for="cod-collection-type">{{ 'cod_collection_type' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl required" *ngIf="!isReadOnly">
              <div class="p-field p-field--full">
                <span class="p-float-label" dpdFloatingLabel>
                  <select id="cod-collection-type" name="codCollectionType" required [(ngModel)]="service.data[0].collectionTypeId">
                    <option *ngFor="let collectionTypeId of editor.availableCollectionTypeIds" [ngValue]="collectionTypeId">{{ 'cod_collection_type_' + collectionTypeId | shpLocalize }}</option>
                  </select>
                  <label for="cod-collection-type">{{ 'cod_collection_type' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl" *ngIf="isReadOnly">
              <!-- zobrazujeme iba IBAN, BIC a CustomId vybraného účtu -->
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input type="text" readonly="readonly" id="cond-bank-account" name="codBankAccount" value="{{ selectedBankAccount == null ? ('cod_no_bank_accounts_defined' | shpLocalize) : (selectedBankAccount.iban ? selectedBankAccount.iban + ' (' + selectedBankAccount.bic + ', ' + selectedBankAccount.customId + ')' : selectedBankAccount.accountNumber + ' (' + selectedBankAccount.name + ', ' + selectedBankAccount.customId + ')') }}" pInputText />
                  <label for="cod-bank-account">{{ 'cod_bank_account' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl" *ngIf="!isReadOnly">
              <div *ngIf="availableBankAccounts.length === 0" class="p-field p-field--full">
                <span class="p-float-label">
                  <input type="text"
                        *ngIf="availableBankAccounts.length === 0"
                        [readonly]="form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount"
                        [class.ng-touched]="form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount"
                        [class.ng-invalid]="form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount"
                        value="{{ (form?.controls.service_COD && form?.controls.service_COD.errors && form?.controls.service_COD.errors.nonExistForeignBankAccount ? 'cod_no_foreign_bank_accounts_defined' : 'cod_no_bank_accounts_defined') | shpLocalize }}"
                        id="cod-bank-account" pInputText />
                  <label for="cond-bank-account">{{ 'cod_bank_account' | shpLocalize }}</label>
                </span>
              </div>

              <div *ngIf="availableBankAccounts.length > 0" class="p-field p-field--full">
                <span class="p-float-label" dpdFloatingLabel>
                  <select id="cod-bank-account" name="codBankAccount" [(ngModel)]="selectedBankAccount">
                    <option *ngFor="let bankAccount of availableBankAccounts" [ngValue]="bankAccount">{{ bankAccount.iban ? bankAccount.iban + " (" + bankAccount.bic + ", " + bankAccount.customId + ")" : bankAccount.accountNumber + " (" + bankAccount.name + ", " + bankAccount.customId + ")" }}</option>
                  </select>
                  <label for="cod-bank-account">{{ 'cod_bank_account' | shpLocalize }}</label>
                </span>
              </div>

            </div>
            <div class="ctl" [class.required]="!isReadOnly" *ngIf="!isReadOnly">
              <div class="p-field p-field--full">
                <span class="p-float-label" dpdFloatingLabel>
                  <select [(ngModel)]="service.data[0].currency" id="cod-currency" name="codCurrency" required>
                    <option *ngFor="let currency of editor.availableCurrencyCodes" [ngValue]="currency">{{ currency.toUpperCase() }}</option>
                  </select>
                  <label for="cod-currency">{{ 'currency' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl" *ngIf="isReadOnly">
              <label for="cod-amount">{{ 'currency' | shpLocalize }}</label>
              <input readonly="readonly" value="{{ service.data[0].currency.toUpperCase() }}" />
            </div>
            <div class="ctl" *ngIf="!editor.isForcedCodForEachParcel">
              <input id="all-parcel-own-cods" name="allParcelsHaveOwnCod" type="checkbox" [(ngModel)]="editor.allParcelsHaveOwnCod" />
              <label for="all-parcel-own-cods">{{ 'shipment_order_all_parcels_have_own_cod' | shpLocalize }}</label>
            </div>
            <div class="ctl" [class.required]="!isReadOnly" entityValidatorArray>
              <div class="ctl-group-multiline">
                <ng-template ngFor let-cod [ngForOf]="service.data" let-i="index">
                  <div class="ctl">
                    <div class="p-field p-field--full">
                      <span class="p-float-label">
                        <p-inputNumber [(ngModel)]="cod.amount" id="cod-amount-{{i}}" name="codAmount{{i}}" [isRequired]="true" [min]="0" [max]="999999" [disabled]="isReadOnly"
                          [showButtons]="false" [maxFractionDigits]="2" [minFractionDigits]="2" mode="decimal"></p-inputNumber>
                        <label for="cod-amount-{{i}}">{{ 'cod_amount' | shpLocalize }}</label>
                      </span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="ctl">
              <div class="ctl-group-multiline">
                <ng-template ngFor let-cod [ngForOf]="service.data" let-i="index">
                  <div class="ctl">
                    <div class="p-field p-field--full">
                      <span class="p-float-label">
                        <input shpNumbersInput [onlyNumbers]="editor.isSophia" type="text" [(ngModel)]="cod.purpose" #purpose="ngModel" maxlength="15" [readonly]="isReadOnly" id="cod-purpose-{{i}}" name="codPurpose" pInputText />
                        <label for="cod-purpose-{{i}}">{{ 'cod_purpose' | shpLocalize }}</label>
                      </span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </form>
      </div>

      <div *ngSwitchCase="'ID'">
        <form>
          <div class="ctl" [class.required]="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input type="text" id="id-check-name" maxlength="35" required [readonly]="isReadOnly" [(ngModel)]="service.data.name" pInputText name="idCheckName">
                <label for="id-check-name">{{ 'id_check_name' | shpLocalize }}</label>
              </span>
            </div>
          </div>
          <div class="ctl" [class.required]="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input type="text" id="id-check-id-card-number" [readonly]="isReadOnly" required [(ngModel)]="service.data.idCardNumber" pInputText name="idCheckIdCardNumber">
                <label for="id-check-id-card-number">{{ 'id_check_card_nr' | shpLocalize }}</label>
              </span>
            </div>
          </div>
        </form>
      </div>

      <div *ngSwitchCase="'2CRET'">
        <shp-recipient-view [name]="'return_recipient'"
                            [recipient]="editor.returnRecipient"
                            [countries]="editor.countries"
                            [isReadOnly]="isReadOnly"
                            [suspendRoutingValidation]="editor._suspendRoutingValidation"
                            (setProductsAndAdditionalServicesAvailability)="editor.validateReturnRecipientZipAndCity()"
                            [selectedProduct]="editor.selectedProduct"
                            [recipientsDataService]="editor.recipientsDataService"
                            [settings]="editor.settings"
                            [zipIsInvalid]="editor.returnRecipientZipIsInvalid"
                            [cityIsInvalid]="editor.returnRecipientCityIsInvalid"
                            [languages]="editor.languages"
                            [useB2BAddressFieldsOnly]="true"
                            [isRecipientEmailRequired]="false">
        </shp-recipient-view>
      </div>

      <div *ngSwitchCase="'SWAP'" class="">
        <form>
          <div class="ctl">
            <div *ngIf="isReadOnly" class="p-field p-field--full">
              <span class="p-float-label">
                <input id="swap-type" [(ngModel)]="editor.swapTypeSelected" [ngModelOptions]="{standalone: true}" type="text" [readonly]="isReadOnly" name="swaptype" pInputText autocomplete="off">
                <label for="swap-type">{{ 'swap_type' | shpLocalize }}</label>
              </span>
            </div>

            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select id="swap-type" [(ngModel)]="editor.swapTypeSelected" *ngIf="!isReadOnly" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let swaptype of editor.listOfSwapTypes" [ngValue]="swaptype">{{swaptype}}</option>
                </select>
                <label for="swap-type">{{ 'swap_type' | shpLocalize }}</label>
              </span>
            </div>

          </div>
          <div class="ctl" *ngIf="!editor.isSameNumberOfSwapParcelsRequiredAsOriginalParcels" [class.required]="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-inputNumber [(ngModel)]="service.data.parcelsCount" [min]="1" [max]="editor.parcelsCount" [step]="1" [showButtons]="true" [maxFractionDigits]="0" [isReadOnly]="isReadOnly" [required]="true"
                  name="swapParcelsCount" inputId="swap-parcels-count"
                  mode="decimal"
                  decrementButtonClass="p-button-secondary"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="ic-add-2"
                  decrementButtonIcon="ic-subtract-2"
                ></p-inputNumber>
                <label for="swap-parcels-count">{{ 'swap_parcels_count' | shpLocalize }}</label>
              </span>
            </div>
          </div>
          <div class="ctl">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="swap-note" [(ngModel)]="service.data.note" type="text" [readonly]="isReadOnly" name="swapNote" pInputText autocomplete="off">
                <label for="swap-note">{{ 'swap_note' | shpLocalize }} 2</label>
              </span>
            </div>
          </div>

          <!-- "C" : address for "ABC" -->
          <!-- type: {{service.data.type}} -->
          <div *ngIf="editor.swapTypeSelected=='ABC'">
            <div class="ctl required">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-name" [(ngModel)]="editor.swapAbcAddress.name1" type="text" [readonly]="isReadOnly" name="swapAbcAddressName" required pInputText autocomplete="off">
                  <label for="swap-abc-address-name">{{ 'recipient_person_name' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-name-2" [(ngModel)]="editor.swapAbcAddress.name2" type="text" [readonly]="isReadOnly" name="swapAbcAddressName_2" pInputText autocomplete="off">
                  <label for="swap-abc-address-name-2">{{ 'recipient_person_name' | shpLocalize }} 2</label>
                </span>
              </div>
            </div>
            <div class="ctl">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-contact-person" [(ngModel)]="editor.swapAbcAddress.contactPerson" type="text" [readonly]="isReadOnly" name="swapAbcAddressContactPerson" pInputText autocomplete="off">
                  <label for="swap-abc-address-contact-person">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl required">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-street" [(ngModel)]="editor.swapAbcAddress.street" type="text" [readonly]="isReadOnly" name="swapAbcAddressStreet" required pInputText autocomplete="off">
                  <label for="swap-abc-address-street">{{ 'recipient_street' | shpLocalize }}</label>
                </span>
              </div>
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-houseNr" [(ngModel)]="editor.swapAbcAddress.houseNr" type="text" [readonly]="isReadOnly" name="swapAbcAddressHouseNr" pInputText autocomplete="off">
                  <label for="swap-abc-address-houseNr">{{ 'recipient_house_number' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-street-detail" [(ngModel)]="editor.swapAbcAddress.addressLine2" type="text" [readonly]="isReadOnly" name="swapAbcAddressStreetDetail" pInputText autocomplete="off">
                  <label for="swap-abc-address-street-detail">{{ 'recipient_street_detail' | shpLocalize }}</label>
                </span>
              </div>
            </div>
            <div class="ctl required">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-city" [(ngModel)]="editor.swapAbcAddress.city" type="text" [readonly]="isReadOnly" name="swapAbcAddressCity" required pInputText autocomplete="off">
                  <label for="swap-abc-address-city">{{ 'recipient_city' | shpLocalize }} 2</label>
                </span>
              </div>
            </div>
            <div class="ctl required">
              <div class="p-field p-field--full">
                <span class="p-float-label">
                  <input id="swap-abc-address-zip" [(ngModel)]="editor.swapAbcAddress.zip" type="text" [readonly]="isReadOnly" name="swapAbcAddressZip" required pInputText autocomplete="off">
                  <label for="swap-abc-address-zip">{{ 'recipient_zip' | shpLocalize }}</label>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
