<form #f (submit)="onSubmit()" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category" wizardTag="printSettings">
    <div class="settings-wrapper">
      <h1>{{ 'settings_print' | shpLocalize }}</h1>

      <div class="settings-wrapper-columns">

        <div class="settings-wrapper-column">
          <h3 class="ctl">{{ 'settings_print_labels' | shpLocalize }}</h3>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('labelPrintingType')">
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printingLabelPrintingType" id="printing-label-printing-type" [(ngModel)]="settings.printingSettings.value.labelPrintingType">
                  <option value="0" *ngIf="!isCentralShipper">Zebra</option>
                  <option value="1" *ngIf="!isCentralShipper">Laser</option>
                  <option value="2">PDF</option>
                  <option value="3" *ngIf="isELabelPngAvailable">PNG (E-Label)</option>
                  <option value="4" *ngIf="!isCentralShipper">{{ 'settings_print_pdf_to_local_folder' | shpLocalize }}</option>
                </select>
                <label for="printing-label-printing-type">{{ 'settings_printing_label_printing_type' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingLabelPrintingTypePdfLocalFolder()">
            <label for="printing-label-printing-type-pdf-local-folder">{{ 'settings_print_protocols_local_folder' | shpLocalize}}</label>
            <input type="text"
                  name="printingLabelPrintingTypePdfLocalFolder"
                  maxlength="1024"
                  id="printing-label-printing-type-pdf-local-folder"
                  [(ngModel)]="settings.printingSettings.value.labelPrintingTypePdfLocalFolder" />
          </div>

          <div class="ctl" *ngIf="isPrinterForLabelVisible()">
            <!-- <label for="printer-for-labels">{{ 'settings_printing_printer_for_labels' | shpLocalize }}</label>
            <select name="printerForLabels" id="printer-for-labels" [(ngModel)]="settings.printingSettings.value.printerForLabel">
              <option *ngFor="let printer of printers | async" [ngValue]="printer">{{ printer }}</option>
            </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printerForLabels" id="printer-for-labels" [(ngModel)]="settings.printingSettings.value.printerForLabel">
                  <option *ngFor="let printer of printers | async" [ngValue]="printer">{{ printer }}</option>
                </select>
                <label for="printer-for-labels">{{ 'settings_printing_printer_for_labels' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('labelFormat')">
            <!-- <label for="printing-paper-size">{{ 'settings_printing_paper_size' | shpLocalize }}</label>
            <select name="printingPaperSize" id="printing-paper-size" [(ngModel)]="settings.printingSettings.value.labelFormat">
              <option value="0">A4</option>
              <option value="1">A6</option>
            </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printingPaperSize" id="printing-paper-size" [(ngModel)]="settings.printingSettings.value.labelFormat">
                  <option value="0">A4</option>
                  <option value="1">A6</option>
                </select>
                <label for="printing-paper-size">{{ 'settings_printing_paper_size' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('labelZebraLanguage')">
            <!-- <label for="printing-zebra-language">{{ 'settings_printing_zebra_language' | shpLocalize }}</label>
            <select name="printingZebraLanguage" id="printing-zebra-language" [(ngModel)]="settings.printingSettings.value.labelZebraLanguage">
              <option value="0">EPL</option>
              <option value="1">ZPL</option>
            </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printingZebraLanguage" id="printing-zebra-language" [(ngModel)]="settings.printingSettings.value.labelZebraLanguage">
                  <option value="0">EPL</option>
                  <option value="1">ZPL</option>
                </select>
                <label for="printing-zebra-language">{{ 'settings_printing_zebra_language' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('startPrintingLabelsOnPosition')">
            <!-- <label for="printing-start-position">{{ 'settings_printing_start_position' | shpLocalize }}</label>
            <select name="printingStartPosition" id="printing-start-position" [(ngModel)]="settings.printingSettings.value.startPrintingLabelsOnPosition">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printingStartPosition" id="printing-start-position" [(ngModel)]="settings.printingSettings.value.startPrintingLabelsOnPosition">
                  <option value="1">{{ 'settings_printing_position_upper_left' | shpLocalize }}</option>
                  <option value="2">{{ 'settings_printing_position_upper_right' | shpLocalize }}</option>
                  <option value="3">{{ 'settings_printing_position_bottom_left' | shpLocalize }}</option>
                  <option value="4">{{ 'settings_printing_position_bottom_right' | shpLocalize }}</option>
                </select>
                <label for="printing-start-position">{{ 'settings_printing_start_position' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('rememberLastPrintedPosition')">
            <input type="checkbox" name="printingRememberLastPrintedPosition" id="printing-remember-last-printed-position" [(ngModel)]="settings.printingSettings.value.rememberLastPrintedPosition" />
            <label for="printing-remember-last-printed-position" class="w-100">{{ 'settings_printing_remember_last_printed_position' | shpLocalize }}</label>
            <!-- <label for="printing-remember-last-printed-position"></label> -->
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('showLabelPositionDialog')">
            <input type="checkbox" name="printingShowLabelPositionDialog" id="printing-show-label-position-dialog" [(ngModel)]="settings.printingSettings.value.showLabelPositionDialog" />
            <label for="printing-show-label-position-dialog" class="w-100">{{ 'settings_printing_show_label_position_dialog' | shpLocalize }}</label>
            <!-- <label for="printing-show-label-position-dialog"></label> -->
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('printingDpi')">
            <!-- <label for="printing-dpi">{{ 'settings_printing_dpi' | shpLocalize }}</label>
            <wj-input-number [(value)]="settings.printingSettings.value.printingDpi" name="printingDpi" id="printing-dpi" [step]="1" [format]="'F0'" [showSpinner]="true"
                            [min]="200" [max]="600"></wj-input-number> -->
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-inputNumber
                  [(ngModel)]="settings.printingSettings.value.printingDpi"
                  [min]="0"
                  [max]="600"
                  [step]="1"
                  [showButtons]="true"
                  [maxFractionDigits]="0"
                  name="printingDpi"
                  id="printing-dpi"
                  mode="decimal"
                  decrementButtonClass="p-button-secondary"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="ic-add-2"
                  decrementButtonIcon="ic-subtract-2"
                ></p-inputNumber>
                <label for="printing-dpi">{{ 'settings_printing_dpi' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('printerLeftOffset')">
            <!-- <label for="printing-left-offset">{{ 'settings_printing_left_offset' | shpLocalize }}</label> -->
            <!-- <wj-input-number [(value)]="settings.printingSettings.value.printerLeftOffset" [isRequired]="false" name="printingLeftOffset" id="printing-left-offset"
                            [showSpinner]="true" [step]="1" [min]="-50" [max]="50" [format]="'N0'"></wj-input-number> -->
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-inputNumber
                  [(ngModel)]="settings.printingSettings.value.printerLeftOffset"
                  [min]="-50"
                  [max]="50"
                  [step]="1"
                  [showButtons]="true"
                  [maxFractionDigits]="0"
                  name="printingLeftOffset"
                  id="printing-left-offset"
                  mode="decimal"
                  decrementButtonClass="p-button-secondary"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="ic-add-2"
                  decrementButtonIcon="ic-subtract-2"
                ></p-inputNumber>
                <label for="printing-left-offset">{{ 'settings_printing_left_offset' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('printerTopOffset')">
            <!-- <label for="printing-top-offset">{{ 'settings_printing_top_offset' | shpLocalize }}</label> -->
            <!-- <wj-input-number [(value)]="settings.printingSettings.value.printerTopOffset" [isRequired]="false" name="printingTopOffset" id="printing-top-offset"
                            [showSpinner]="true" [step]="1" [min]="-50" [max]="50" [format]="'N0'"></wj-input-number> -->
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-inputNumber
                  [(ngModel)]="settings.printingSettings.value.printerTopOffset"
                  [min]="-50"
                  [max]="50"
                  [step]="1"
                  [showButtons]="true"
                  [maxFractionDigits]="0"
                  name="printingTopOffset"
                  id="printing-top-offset"
                  mode="decimal"
                  decrementButtonClass="p-button-secondary"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="ic-add-2"
                  decrementButtonIcon="ic-subtract-2"
                ></p-inputNumber>
                <label for="printing-top-offset">{{ 'settings_printing_top_offset' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('printNoteOnLabels')">
            <input type="checkbox" name="printingPrintNoteOnLabels" id="printing-print-note-on-labels" [(ngModel)]="settings.printingSettings.value.printNoteOnLabels" />
            <label for="printing-print-note-on-labels" class="w-100">{{ 'settings_printing_print_note_on_labels' | shpLocalize }}</label>
            <!-- <label for="printing-print-note-on-labels"></label> -->
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('senderSpecificNoteOnTheLabelBeforeTheSendersAddress')">
            <!-- <label for="printing-sender-specific-note-on-labels">{{ 'settings_printing_sender_specific_note_on_labels' | shpLocalize }}</label>
            <input type="text" name="printingSenderSpecificNoteOnLabels" maxlength="35" id="printing-sender-specific-note-on-labels" [(ngModel)]="settings.printingSettings.value.senderSpecificNoteOnTheLabelBeforeTheSendersAddress" /> -->
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="printing-sender-specific-note-on-labels" type="text" name="printingSenderSpecificNoteOnLabels" pInputText maxlength="35" [(ngModel)]="settings.printingSettings.value.senderSpecificNoteOnTheLabelBeforeTheSendersAddress">
                <label for="printing-sender-specific-note-on-labels">{{ 'settings_printing_sender_specific_note_on_labels' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('moveNoteOnLabelBeforeSenderAddress')">
            <input type="checkbox" name="printingSenderSpecificNoteBeforeAddress" id="printing-sender-specific-note-before-address" [(ngModel)]="settings.printingSettings.value.moveNoteOnLabelBeforeSenderAddress" />
            <label for="printing-sender-specific-note-before-address" class="w-100">{{ 'settings_printing_sender_specific_note_befire_address' | shpLocalize }}</label>
            <!-- <label for="printing-sender-specific-note-before-address"></label> -->
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('partOfLabelToBePrintedLarger')">
            <!-- <label for="printing-part-larger">{{ 'settings_printing_part_of_labels_larger' | shpLocalize }}</label>
            <select name="printingPartOfLabelToBePrintedLarger" id="printing-part-larger" [(ngModel)]="settings.printingSettings.value.partOfLabelToBePrintedLarger">
              <option value="0">{{ 'label_part_none' | shpLocalize }}</option>
              <option value="1">{{ 'label_part_reference1' | shpLocalize }}</option>
              <option value="2">{{ 'label_part_reference2' | shpLocalize }}</option>
              <option value="4">{{ 'label_part_note' | shpLocalize }}</option>
              <option value="5">{{ 'label_part_cod_amount' | shpLocalize }}</option>
              <option *ngIf="isSophia" value="6">{{ 'label_part_purpose' | shpLocalize }}</option>
            </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printingPartOfLabelToBePrintedLarger" id="printing-part-larger" [(ngModel)]="settings.printingSettings.value.partOfLabelToBePrintedLarger">
                  <option value="0">{{ 'label_part_none' | shpLocalize }}</option>
                  <option value="1">{{ 'label_part_reference1' | shpLocalize }}</option>
                  <option value="2">{{ 'label_part_reference2' | shpLocalize }}</option>
                  <option value="6">{{ 'label_part_note' | shpLocalize }}</option>
                  <option value="7">{{ 'label_part_cod_amount' | shpLocalize }}</option>
                  <option *ngIf="isSophia" value="8">{{ 'label_part_purpose' | shpLocalize }}</option>
                </select>
                <label for="printing-part-larger">{{ 'settings_printing_part_of_labels_larger' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('printReturnConfirmationForm') && canDisplayPrintReturnFormSetting">
            <input type="checkbox" name="printReturnConfirmationForm" id="print_return_confirmation_form" [(ngModel)]="settings.printingSettings.value.printReturnConfirmationForm" />
            <label for="print_return_confirmation_form" class="w-100">{{ 'settings_printing_print_print_return_confirmation_form' | shpLocalize }}</label>
            <!-- <label for="print_return_confirmation_form"></label> -->
          </div>

          <div class="ctl" *ngIf="isLabelSortOrderVisible">
            <!-- <label for="label-sort-order">{{ 'settings_printing_label_sort_order' | shpLocalize }}</label>
            <select name="labelSortOrder" id="label-sort-order" [(ngModel)]="settings.printingSettings.value.labelSortOrder">
              <option value="0">{{ 'sort_order_creation_datetime' | shpLocalize }}</option>
              <option value="1">{{ 'sort_order_consignee_zip' | shpLocalize }}</option>
              <option value="2">{{ 'sort_order_consignee_country' | shpLocalize }}</option>
              <option value="3">{{ 'sort_order_consignee_name' | shpLocalize }}</option>
              <option value="4">{{ 'sort_order_product' | shpLocalize }}</option>
              <option value="5">{{ 'sort_order_reference1' | shpLocalize }}</option>
              <option value="6">{{ 'sort_order_parcelnr' | shpLocalize }}</option>
            </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="labelSortOrder" id="label-sort-order" [(ngModel)]="settings.printingSettings.value.labelSortOrder">
                  <option value="0">{{ 'sort_order_creation_datetime' | shpLocalize }}</option>
                  <option value="1">{{ 'sort_order_consignee_zip' | shpLocalize }}</option>
                  <option value="2">{{ 'sort_order_consignee_country' | shpLocalize }}</option>
                  <option value="3">{{ 'sort_order_consignee_name' | shpLocalize }}</option>
                  <option value="4">{{ 'sort_order_product' | shpLocalize }}</option>
                  <option value="5">{{ 'sort_order_reference1' | shpLocalize }}</option>
                  <option value="6">{{ 'sort_order_parcelnr' | shpLocalize }}</option>
                </select>
                <label for="label-sort-order">{{ 'settings_printing_label_sort_order' | shpLocalize }}</label>
              </span>
            </div>
          </div>
        </div>
        <!-- END - .settings-wrapper-column -->

        <div class="settings-wrapper-column">
          <h3 class="ctl">{{ 'settings_print_protocols' | shpLocalize }}</h3>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('protocolPrintingType')">
            <!-- <label for="printing-protocol-printing-type">{{ 'settings_printing_protocol_printing_type' | shpLocalize }}</label>
    <select name="printingProtocolPrintingType" id="printing-protocol-printing-type" [(ngModel)]="settings.printingSettings.value.protocolPrintingType">
      <option value="0">Laser</option>
      <option value="1">PDF</option>
      <option value="2">{{ 'settings_print_pdf_to_local_folder' | shpLocalize }}</option>
    </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printingProtocolPrintingType" id="printing-protocol-printing-type" [(ngModel)]="settings.printingSettings.value.protocolPrintingType">
                  <option value="0">Laser</option>
                  <option value="1">PDF</option>
                  <option value="2">{{ 'settings_print_pdf_to_local_folder' | shpLocalize }}</option>
                </select>
                <label for="printing-protocol-printing-type">{{ 'settings_printing_protocol_printing_type' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingProtocolPrintingTypePdfLocalFolder()">
            <!-- <label for="printing-protocol-printing-type-pdf-local-folder">{{ 'settings_print_protocols_local_folder' | shpLocalize}}</label>
    <input type="text" name="printingProtocolPrintingTypePdfLocalFolder" maxlength="1024" id="printing-protocol-printing-type-pdf-local-folder" [(ngModel)]="settings.printingSettings.value.protocolPrintingTypePdfLocalFolder" /> -->
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="printing-protocol-printing-type-pdf-local-folder" type="text" name="printingProtocolPrintingTypePdfLocalFolder" pInputText maxlength="1024" [(ngModel)]="settings.printingSettings.value.protocolPrintingTypePdfLocalFolder">
                <label for="printing-protocol-printing-type-pdf-local-folder">{{ 'settings_print_protocols_local_folder' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrinterForProtocolVisible()">
            <!-- <label for="printer-for-protocols">{{ 'settings_printing_printer_for_protocols' | shpLocalize }}</label>
    <select name="printerForProtocols" id="printer-for-protocols" [(ngModel)]="settings.printingSettings.value.printerForProtocol">
      <option *ngFor="let printer of printers | async" [ngValue]="printer">{{ printer }}</option>
    </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printerForProtocols" id="printer-for-protocols" [(ngModel)]="settings.printingSettings.value.printerForProtocol">
                  <option *ngFor="let printer of printers | async" [ngValue]="printer">{{ printer }}</option>
                </select>
                <label for="printer-for-protocols">{{ 'settings_printing_printer_for_protocols' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('printCustomersReferenceOnAcceptanceProtocols')">
            <input type="checkbox" name="printingPrintCustomersReferenceId" id="printing-customers-reference-id" [(ngModel)]="settings.printingSettings.value.printCustomersReferenceOnAcceptanceProtocols" />
            <label for="printing-customers-reference-id" class="w-100">{{ 'settings_printing_print_customers_reference_id' | shpLocalize }}</label>
            <!-- <label for="printing-customers-reference-id"></label> -->
          </div>

          <div class="ctl" *ngIf="isPrintingSettingsPropertyVisible('printReference2OnAcceptanceProtocols')">
            <input type="checkbox" name="printingPrintReference2OnAcceptanceProtocols" id="printing-print-reference2-on-acceptance-protocols" [(ngModel)]="settings.printingSettings.value.printReference2OnAcceptanceProtocols" />
            <label for="printing-print-reference2-on-acceptance-protocols" class="w-100">{{ 'settings_printing_print_reference2_on_acceptance_protocols' | shpLocalize }}</label>
            <!-- <label for="printing-print-reference2-on-acceptance-protocols"></label> -->
          </div>

          <div class="ctl" *ngIf="isHideUsernameOnProtocolVisible">
            <input type="checkbox" id="hide-username-option-id" name="hideUsernameOptionOnProtocols" [(ngModel)]="settings.hide_username_on_protocol.value" />
            <label for="hide-username-option-id" class="w-100">{{ 'settings_hide_username_on_protocol' | shpLocalize }}</label>
            <!-- <label for="printing-customers-reference-id"></label> -->
          </div>

          <div class="ctl" *ngIf="settings.sort_order_for_acceptance_protocol.isVisible">
            <!-- <label for="printing-sort-acceptance-protocol">{{ 'settings_printing_sort_acceptance_protocol' | shpLocalize }}</label>
    <select name="printingSortOrderForAcceptanceProtocol" id="printing-sort-acceptance-protocol" [(ngModel)]="settings.sort_order_for_acceptance_protocol.value">
      <option value="0">{{ 'sort_order_consignee_zip' | shpLocalize }}</option>
      <option value="1">{{ 'sort_order_consignee_country' | shpLocalize }}</option>
      <option value="2">{{ 'sort_order_consignee_name' | shpLocalize }}</option>
      <option value="3">{{ 'sort_order_product' | shpLocalize }}</option>
      <option value="4">{{ 'sort_order_reference1' | shpLocalize }}</option>
      <option value="5">{{ 'sort_order_parcelnr' | shpLocalize }}</option>
    </select> -->
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select name="printingSortOrderForAcceptanceProtocol" id="printing-sort-acceptance-protocol" [(ngModel)]="settings.sort_order_for_acceptance_protocol.value">
                  <option value="0">{{ 'sort_order_consignee_zip' | shpLocalize }}</option>
                  <option value="1">{{ 'sort_order_consignee_country' | shpLocalize }}</option>
                  <option value="2">{{ 'sort_order_consignee_name' | shpLocalize }}</option>
                  <option value="3">{{ 'sort_order_product' | shpLocalize }}</option>
                  <option value="4">{{ 'sort_order_reference1' | shpLocalize }}</option>
                  <option value="5">{{ 'sort_order_parcelnr' | shpLocalize }}</option>
                </select>
                <label for="printing-sort-acceptance-protocol">{{ 'settings_printing_sort_acceptance_protocol' | shpLocalize }}</label>
              </span>
            </div>
          </div>
        </div>
        <!-- END - .settings-wrapper-column -->

      </div>
      <!-- END - .settings-wrapper-columns -->

    </div><!-- .settings-wrapper -->
    <div class="commands-toolbar">
      <button id="button-save" type="submit" class="mr-2" wizardTag="savePrintSettings"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
      <button id="button-print-test-label" type="button" wizardTag="printTestLabel" (click)="printTestLabel()"><span class="ic-printer"></span> {{ 'print_test_label' | shpLocalize }}</button>
    </div>
  </div>
</form>
