import { Component, OnInit } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { PollyService } from "../../shared";

@Component({
  templateUrl: "./faq-page.component.html"
})
export class FaqPageComponent implements OnInit {
  url: SafeUrl = null;

  constructor(private pollyService: PollyService) {}

  ngOnInit() {
    this.url = this.pollyService.getPollyUrl();
  }
}
