import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { WizardTaggedElement } from "../models";
import { WizardRenderService } from "../services/wizard-render.service";
import { WizardService } from "../services/wizard.service";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[wizardTag]"
})
export class WizardTagDirective implements WizardTaggedElement, OnInit, OnDestroy {
  @Input("wizardTag")
  set tag(value: string) {
    if (this._tag) {
      this.wizardRenderService.unregisterTaggedElement(this);
    }

    this._tag = value;

    if (this._tag) {
      this.wizardRenderService.registerTaggedElement(this);
    }

    this.toggleEnabled();
  }

  get tag(): string {
    return this._tag;
  }

  @HostBinding("class.wizard--enabled-section") isEnabled = false;

  private _tag: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
      public elementRef: ElementRef,
      private wizardService: WizardService,
      private wizardRenderService: WizardRenderService) {
  }

  ngOnInit() {
    this.observeWizard();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.wizardRenderService.unregisterTaggedElement(this);
  }

  private observeWizard() {
    this.wizardService.state$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.toggleEnabled());
  }

  private toggleEnabled() {
    const currentStep = this.wizardService.currentStep;
    const currentStepEnabledTags = new Set(currentStep?.enabledSectionTags);

    this.isEnabled = currentStepEnabledTags.has(this._tag);
  }
}
