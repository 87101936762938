import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { IbanPipe } from "../pipes/iban.pipe";

@Directive({ selector: "[ibanInputFormatter]" })
export class IbanInputFormatterDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private ibanPipe: IbanPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.ibanPipe.transform(this.el.value);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.el.value = this.ibanPipe.parse(value);
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = this.ibanPipe.transform(value);
  }

}