import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import * as Shared from '../../shared/index';
import * as Models from '../models/shipment.model';
import { Localization, LocalizationService } from '../../shared/index';

@Component({
  selector: Shared.SELECTOR_PREFIX + '-shipments-additional-fields',
  templateUrl: './shipment-additional-fields.component.html',
  host: {
    class: 'additional-fields',
  },
})
export class ShipmentsAdditionalFieldsComponent implements OnChanges {
  @Input() dynamicFieldsResults: Models.AdditionalField[] = []; 
  @Input() predefinedFieldsValues: Models.ShipmentProductDynamicFieldOption[] = []; 
  @Output() dynamicFieldsAvailable = new EventEmitter<boolean>();
  
  collectedFieldOptions: Models.ShipmentProductDynamicFieldOption[] = [];
  additionalFieldValues: { [key: number]: any } = {};

  constructor(
    private _localizationService: LocalizationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dynamicFieldsResults'] && changes['dynamicFieldsResults'].currentValue) {
      this.initializeFieldValues(); 
    }
  }

  private initializeFieldValues() {
    this.dynamicFieldsResults.forEach(field => {
      const predefinedValue = this.predefinedFieldsValues.find(
        option => option.productDynamicFieldId === field.id
      );
      
      if (predefinedValue) {
        if (field.fieldType === 0) { // Checkbox
          this.additionalFieldValues[field.id] = predefinedValue.value === "false" ? false : true;
        } else if (field.fieldType === 2) { // Dropdown
          const isValidOption = field.fieldOptions.some(option => option.optionValue === predefinedValue.value);
          this.additionalFieldValues[field.id] = isValidOption ? predefinedValue.value : null;
        } else if (field.fieldType === 3) { // Input
          this.additionalFieldValues[field.id] = predefinedValue.value;
        }
      } else {
        this.additionalFieldValues[field.id] = field.fieldType === 0 ? false : '';
      }
    });
  }

  public collectFieldValues(): Models.ShipmentProductDynamicFieldOption[] {
    const collectedData: Models.ShipmentProductDynamicFieldOption[] =
      this.dynamicFieldsResults
        .map((field): Models.ShipmentProductDynamicFieldOption | null => {
          const fieldValue = this.additionalFieldValues[field.id];
          
          const predefinedField = this.predefinedFieldsValues.find(
            option => option.productDynamicFieldId === field.id
          );
  
          if (predefinedField) {
            return {
              productDynamicFieldId: field.id,
              value: field.fieldType === 0 ? (fieldValue ? 'true' : 'false') : fieldValue,
              shipmentId: predefinedField.shipmentId,
              creationDateTimeUtc: predefinedField.creationDateTimeUtc,
              modificationDateTimeUtc: predefinedField.modificationDateTimeUtc,
              createdByUser: predefinedField.createdByUser,
              modifiedByUser: predefinedField.modifiedByUser,
              id: predefinedField.id,
              isNew: predefinedField.isNew,
              isPersistent: predefinedField.isPersistent,
            } as Models.ShipmentProductDynamicFieldOption;
          } else {
            return {
              productDynamicFieldId: field.id,
              value: field.fieldType === 0 ? (fieldValue ? 'true' : 'false') : fieldValue,
            } as Models.ShipmentProductDynamicFieldOption;
          }
        })
        .filter(
          (field) => field !== null
        ) as Models.ShipmentProductDynamicFieldOption[];
  
    this.collectedFieldOptions = collectedData;
    return collectedData;
  }

  public getLocalizedTitle(field: any): string {
    let localizedTitle: string;

    switch (this._localizationService.currentLocalization) {
      case Localization.SK:
        localizedTitle = field.titleSk;
        break;
      case Localization.EN:
        localizedTitle = field.titleEn;
        break;
      case Localization.DE:
        localizedTitle = field.titleDe;
        break;
      case Localization.FR:
        localizedTitle = field.titleFr;
        break;
      case Localization.IT:
        localizedTitle = field.titleIt;
        break;
      case Localization.CS:
        localizedTitle = field.titleCs;
        break;
      case Localization.NL:
        localizedTitle = field.titleNl;
        break;
      case Localization.BE:
        localizedTitle = field.titleBe;
        break;
      default:
        localizedTitle = field.titleEn;
    }

    return localizedTitle && localizedTitle.trim().length > 0
      ? localizedTitle
      : field.titleEn;
  }
}
