<div class="dnd-list">
  <div class="dnd-list__box dnd-list__box--source">
    <div class="dnd-list__body" dnd-sortable-container [dropZones]="[dropZoneId]" [sortableData]="_sourceList">
      <ul class="dnd-list__list">
        <li *ngFor="let source of _sourceList; let idx = index" class="dnd-list__item" 
          dnd-sortable
          [sortableIndex]="idx"
          (onDropSuccess)="orderChange()">
          {{getItemDisplayValue(source) | shpLocalize }}
        </li>
      </ul>
    </div>
  </div>

  <div class="dnd-list__box dnd-list__box--target">
    <div class="dnd-list__body" dnd-sortable-container [dropZones]="[dropZoneId]" [sortableData]="_targetList">
      <ul class="dnd-list__list">
        <li *ngFor="let target of _targetList; let idx = index" class="dnd-list__item"
          dnd-sortable
          [sortableIndex]="idx"
          (onDropSuccess)="orderChange()"
          (onDragEnd)="orderChange()">
          {{getItemDisplayValue(target) | shpLocalize }}
          <button class="dnd-list__list-button" (click)="removeItem(target)">
            <span class="ic-close"></span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>