export function isDefaultFilterValue(value: any) {
  // Číslo 0 nepokladáme za prázdnu/default hodnotu pre filtrovacie políčko.
  return typeof value === "undefined" || value == null || value === "";
}


export function getObjectHasOnlyDefaultFilterValues(o: any, ignoredAttributeNames: string[] = []) {
  for (var key in o) {
    if (ignoredAttributeNames.indexOf(key) == -1) {
      if (!isDefaultFilterValue(o[key])) {
        return false;
      }
    }
  }

  return true;
}


export const defaultPagingProperties: string[] = ["orderAcending", "orderByFieldName", "pageIndex", "pageSize"];