import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { distinctUntilChanged, filter, finalize, takeUntil } from "rxjs/operators";

import * as Shared from "../../../shared/index";
import { BusinessUnitSettingsService } from "../../../shared/index";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { ParcelCsvExportProfileEditorModel } from "../../models/parcel-csv-export-profile-with-fields.model";
import * as SettingsModel from "../../models/settings.model";
import { ParcelCsvExportProfileService } from "../../services/parcel-csv-export-profile.service";
import { SettingsService } from "../../services/settings.service";
import { ParcelExportSettingsFieldsComponent } from "./parcel-export-settings-fields.component";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-automatic-export-settings",
  templateUrl: "./automatic-export-settings.component.html"
})
export class AutomaticExportSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {

  timeModes: string[] = [
    SettingsModel.AutomatedShipmentsExport.None,
    SettingsModel.AutomatedShipmentsExport.Continually,
    SettingsModel.AutomatedShipmentsExport.ContinuallyWithPrintedProtocol,
    SettingsModel.AutomatedShipmentsExport.SpecificTime
  ];

  exportProfileModel: ParcelCsvExportProfileEditorModel;
  isBusyExportProfile = false;
  isCustomShipmentsExportContentEnabled = false;

  @ViewChild("editor") exportSettingsEditor: ParcelExportSettingsFieldsComponent;

  constructor(
      loggingService: Shared.LoggingService,
      globalEventsStreamService: Shared.GlobalEventsStreamService,
      localizationService: Shared.LocalizationService,
      authenticationService: Shared.AuthenticationService,
      router: Router,
      settingsService: SettingsService,
      contextService: Shared.ContextService,
      private _skdataConfigService: SkdataConfigService,
      exceptionsHandlerService: Shared.ExceptionsHandlerService,
      toastr: ToastrService,
      private _parcelCsvExportProfileService: ParcelCsvExportProfileService,
      private _businessUnitSettingsService: BusinessUnitSettingsService) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }


  ngOnInit() {
    super.ngOnInit();

    this._businessUnitSettingsService.getIsCustomShipmentsExportContentEnabled()
      .subscribe(val => {
        this.isCustomShipmentsExportContentEnabled = val;

        if (val && !_.includes(this.timeModes, SettingsModel.AutomatedShipmentsExport.ExportWithEodProtocolPrinting)) {
          this.timeModes.push(SettingsModel.AutomatedShipmentsExport.ExportWithEodProtocolPrinting);
        } else {
          _.remove(this.timeModes, SettingsModel.AutomatedShipmentsExport.ExportWithEodProtocolPrinting);
        }

        this.observeCustomerChanges();
      });
  }

  afterSettingsLoaded() {
    /**
     * Backward compatibility (Backlog item 5319)
     * If export mode is not set determine the value from setting "automated_shipments_export".
     */
    if (!this.settings.automated_shipments_export_time_mode.value) {
      this.settings.automated_shipments_export_time_mode.value = this.settings.automated_shipments_export.value ?
        SettingsModel.AutomatedShipmentsExport.ContinuallyWithPrintedProtocol :
        SettingsModel.AutomatedShipmentsExport.None;
    }
  }


  saveExportProfile() {
    if (!this.isCustomShipmentsExportContentEnabled) {
      return;
    }

    this.isBusyExportProfile = true;

    this._parcelCsvExportProfileService.saveExportProfile(this.exportProfileModel).pipe(
      finalize(() => this.isBusyExportProfile = false)
    ).subscribe(() => this.loadProfile(), () => {});
  }


  private observeCustomerChanges() {
    this.contextService.currentCustomerDetail.pipe(
      takeUntil(this.destroy$),
      filter(c => Boolean(c)),
      distinctUntilChanged()
    ).subscribe(() => this.loadProfile());
  }

  private loadProfile() {
    if (!this.isCustomShipmentsExportContentEnabled) {
      return;
    }

    this.isBusyExportProfile = true;

    this._parcelCsvExportProfileService.getExportProfile().pipe(
      finalize(() => this.isBusyExportProfile = false)
    ).subscribe(profile => {
      this.exportProfileModel = profile ? profile : {};
    });
  }
}
