import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import * as _ from "lodash";
import * as moment from "moment";
import { CompleterData, CompleterService } from "ng2-completer";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { combineLatest, forkJoin, of, Subscription, timer } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, take } from "rxjs/operators";

import * as Shared from "../../shared/index";
import * as ConfigModel from "../../shared/models/config.models";
import { Address, ContactPerson, CustomerDetail } from "../../shared/models/customer.models";
import { RoutedPageComponentBase } from "../../shared/routed-page-component-base";
import { validateEmail } from "../../shared/validators";
import { PickupOrderDetail } from "../models/pickup-order-detail.model";
import { PickupOrderModel } from "../models/pickup-order-model.model";
import { PickupOrdersService } from "../services/pickup-orders.service";

const PickupHourMin = 9;  // minimálny zvozový čas
const PickupHourMax = 17; // maximálny zvozový čas
const PickupHourDifference = 2; // minimálny rozdiel medzi časmi.
const PickupHourGranularity = 0.5; // granularita
const tempDataLocalStorageKey = "PickupOrderEditorComponent.TempData";

interface hourDropdown {
  hour: number;
  disabled: boolean;
}

@Component({
  templateUrl: "./pickup-order-editor.component.html"
})
export class PickupOrderEditorComponent extends RoutedPageComponentBase implements OnInit {
  private _isBusy = true;
  private _model: PickupOrderModel = null;
  private _id: number = null;
  private _customerDetail: CustomerDetail = null;
  private _pickupAddress: Address = null;
  private _paramsChangeSubscription: Subscription = null;
  private _form: FormGroup = null;
  private _isInConfirmMode = false;
  private _minDate: Date;
  private _contactPersons: ContactPerson[] = null;
  private _contactPersonsCompleterData: CompleterData = null;
  private _unavailableDays = [];
  private _unavailableDaysNumbers: string[] = [];
  private _ctrl: any;
  private _sameDayThresholdHour = 12;
  private _sameDayThresholdMinute = 59;
  private _businessUnitCode: string = null;
  private _isRegular = false;
  public _currentPickupOrderDate: Date = null;

  private _isSaturdayPickupAvailable = false;
  private _isSundayPickupAvailable = false;

  private _isImpersonatingShipperAdminUser = false;

  private _numberOfMonthsToCheck = 24;

  private _isLoadedFromLocalStorage = false;

  public hoursFrom: hourDropdown[] = null;
  public hoursTo: hourDropdown[] = null;

  public disabledDays: number[] = [];

  public cityserviceAvailable = false;

  public maxInputCharacters = 35;

  public get isNew() {
    return this._model && this._model.pickupOrder.id === 0;
  }


  public get isInPreparation() {
    return this._model && this._model.pickupOrder.stateId === "InPreparation";
  }


  public get isInConfirmMode() {
    return this._isInConfirmMode;
  }


  public get form() {
    return this._form;
  }


  public get isBusy() {
    return this._isBusy;
  }


  public get model() {
    return this._model;
  }


  public get pickupAddress() {
    return this._pickupAddress;
  }


  public get contactPersonsCompleterData() {
    return this._contactPersonsCompleterData;
  }

  public get isRegular() {
    return this._isRegular;
  }

  public get isCityService() {
    return this.form && this.form.value.isSameDay ? this.form.value.isSameDay : false;
  }

  public get someWeekDayIsChecked() {
    return this.selectedDays.some(d => d == true);
  }

  public get selectedDays() {
    const value: boolean[] = [
      this.form ? this.form.value.sunday : false,
      this.form ? this.form.value.monday : false,
      this.form ? this.form.value.tuesday : false,
      this.form ? this.form.value.wednesday : false,
      this.form ? this.form.value.thursday : false,
      this.form ? this.form.value.friday : false,
      this.form ? this.form.value.saturday : false
    ];

    return value;
  }


  public get selectedHourFrom(): number {
    return this.form.value.from;
  }

  public get selectedHourTo(): number {
    return this.form.value.to;
  }


  public updateActiveHourRange() {
    const limitTo = +this.selectedHourFrom + PickupHourDifference;
    const limitFrom = +this.selectedHourTo - PickupHourDifference;

    this.hoursTo = this.hoursTo.map(h => {
      return { hour: h.hour, disabled: (h.hour < limitTo ? true : false) };
    });

    this.hoursFrom = this.hoursFrom.map(h => {
      return { hour: h.hour, disabled: (h.hour > limitFrom ? true : false) };
    });

  }


  public formatHour = (hour: number): string => {
    if (this.isInt(hour)) {
      return hour + ":00";
    } else {
      return (hour | 0) + ":" + ((60 * (hour % 1)) | 0).toString();
    }
  }


  private pickupHourDropdownValues() {
    let pickupHourMin = PickupHourMin - PickupHourGranularity;
    return Array((PickupHourMax - PickupHourMin + 1) / PickupHourGranularity)
      .fill({ hour: 0, active: false })
      .map(() => {
        pickupHourMin = pickupHourMin + PickupHourGranularity;
        return { hour: pickupHourMin, disabled: false };
      });
  }

  private isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  private validateDate(control: AbstractControl) {
    if (this._isRegular) {
      return of(null);
    }

    const day = moment(control.value).day();
    const month = moment(control.value).month() + 1;
    const year = moment(control.value).year();
    const ddd = moment(control.value).format("DDD");

    return timer(1000).pipe(
      switchMap(() =>
        this._contextService.currentAddress.pipe(
          filter(value => value != null),
          take(1),
          mergeMap(value => {
            return this._pickupOrdersServices.getUnavailablePickupDays(value.id, value.customerDetailId, year, month);
          }),
        )
      ),
      map(result => {
        const days = result;
        const unavailableDays = [];

        days.forEach(d => unavailableDays.push(moment(d).format("DDD")));

        if (this._currentPickupOrderDate) {
          unavailableDays.splice(unavailableDays.findIndex(d => d === moment(this._currentPickupOrderDate).format("DDD")), 1);
        }

        if ((day === 0 && !this._isSundayPickupAvailable) ||
            (day === 6 && !this._isSaturdayPickupAvailable) ||
            unavailableDays.indexOf(ddd) > -1) {
          return {invalid: true};
        }

        return null;
      }),
      catchError(ex => {
        this.loggingService.logErrorData(ex, "Error loading unavailable days");

        const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._toastr.error(`${this.localizationService.getLocalizedString("error_loading_unavailable_days")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);

        return of({invalid: true});
      })
    );
  }

  private setUpForm() {
    this._form = this._fb.group({
      contactPerson: ["", {
        validators: [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(this.maxInputCharacters)
        ]
      }],
      email: ["", {validators: [validateEmail, Validators.maxLength(50)]}],
      phone: [""],
      note: [""],
      parcelsCount: [0, [Validators.min(0), Validators.max(100)]],
      fromDate: [new Date(), [], this.validateDate.bind(this)],
      isSameDay: [false],

      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      from: [PickupHourMin],
      to: [PickupHourMax]
    });
  }


  private setFormValue(model: PickupOrderModel) {
    const from = model.details && model.details.length > 0 && model.details[0].fromTime1 ? model.details[0].fromTime1.getHours() : PickupHourMin;
    const to = model.details && model.details.length > 0 && model.details[0].toTime1 ? model.details[0].toTime1.getHours() : PickupHourMax;

    this._form.setValue({
      contactPerson: model.pickupOrder.contactPerson,
      email: model.pickupOrder.email,
      phone: model.pickupOrder.phone,
      note: model.pickupOrder.note,
      parcelsCount: model.pickupOrder.parcelsCount,
      fromDate: model.pickupOrder.fromDate,
      isSameDay: model.pickupOrder.isSameDay,

      monday: this.extractDayFromPickupOrderDetails(model.details, 1),
      tuesday: this.extractDayFromPickupOrderDetails(model.details, 2),
      wednesday: this.extractDayFromPickupOrderDetails(model.details, 3),
      thursday: this.extractDayFromPickupOrderDetails(model.details, 4),
      friday: this.extractDayFromPickupOrderDetails(model.details, 5),
      saturday: this.extractDayFromPickupOrderDetails(model.details, 6),
      from,
      to
    });

    this._form.markAsPristine();
  }


  private extractDayFromPickupOrderDetails(pickupDetails: PickupOrderDetail[], dayId: number) {
    return _.some(pickupDetails, { dayId });
  }


  public hasUnsavedChanges = () => {
    return this._form.dirty;
  }


  private updateModelFromForm() {
    const value = this._form.value;

    Object.assign(this._model.pickupOrder, value);
  }


  public showConfirmation() {
    this._isInConfirmMode = true;

    this.updateModelFromForm();
    this.populateDetailsModelFromFormValues();

    this.saveTemporaryDataToLocalStorage(this.form.value);
  }


  public save() {
    this._isBusy = true;

    // Wijmo hack
    this._model.pickupOrder.fromDate = moment(this._model.pickupOrder.fromDate).set("hour", 12).toDate();
    const pickupDetails = this.populateDetailsModelFromFormValues();

    if (this.isNew) {
      this._pickupOrdersServices.addPickupOrder(this._model).subscribe(model => {
        this._isBusy = false;
        this.clearTemporaryDataFromLocalStorage();
        this._form.markAsPristine();

        this._model = model;

        this._toastr.success(this.localizationService.getLocalizedString("pickup_order_saved"));

        this.backToList();

      }, ex => {
        this.loggingService.logErrorData(ex, "Error saving pickup order");
        this._isBusy = false;

        const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._toastr.error(`${this.localizationService.getLocalizedString("error_saving_pickup_order")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
      });
    } else {
      this._pickupOrdersServices.updateRegularPickupOrder(this._id, this._model).subscribe(model => {
        this._isBusy = false;
        this.clearTemporaryDataFromLocalStorage();
        this._form.markAsPristine();

        this._model = model;

        this._toastr.success(this.localizationService.getLocalizedString("pickup_order_saved"));

        this.backToList();

      }, ex => {
        this.loggingService.logErrorData(ex, "Error saving pickup order");
        this._isBusy = false;

        const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._toastr.error(`${this.localizationService.getLocalizedString("error_saving_pickup_order")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
      });
    }

  }


  public back() {
    if (this._isInConfirmMode) {
      this._isInConfirmMode = false;
    }
  }


  public backToList() {
    this.router.navigate(["pickup-orders"]);
  }


  private getTempDataLocalStorageKey() {
    return (this.isRegular ? tempDataLocalStorageKey + ".regular" : tempDataLocalStorageKey + ".onetime") + `.${this._pickupAddress.id}`;
  }


  private saveTemporaryDataToLocalStorage(data: any) {
    // Wijmo hack
    // model.pickupOrder.fromDate = moment(model.pickupOrder.fromDate).set("hour", 12).toDate();
    localStorage.setItem(this.getTempDataLocalStorageKey(), JSON.stringify(data));
  }


  private loadTemporaryDataFromLocalStorage() {
    const dataJson = localStorage.getItem(this.getTempDataLocalStorageKey());

    if (dataJson) {
      const data = JSON.parse(dataJson);

      data.fromDate = new Date(data.fromDate);

      return data;
    }

    return null;
  }


  private clearTemporaryDataFromLocalStorage() {
    localStorage.removeItem(this.getTempDataLocalStorageKey());
  }


  private checkStopCredit() {
    if (this._shipperSettingsService.isSophia) {
      this._contextService.currentCustomerDetail.pipe(filter(cd => cd != null)).subscribe(cd => {
        if (cd.stopCredit) {
          this._customerService.openStopCreditModal();
          setTimeout(() => {
            this.router.navigate(["/pickup-orders"]);
          }, 500);
        }
      });
    }
  }


  private loadExistingOrInitializeNew() {
    if (this._id === 0) {
      // Overíme, či sme si niečo nerozeditovali a neodložili do local storage-u.
      const formData = this.loadTemporaryDataFromLocalStorage();

      if (formData) {
        this._modal.confirm()
          .body(this.localizationService.getLocalizedString("temporary_pickup_order_data_available_message"))
          .okBtn(this.localizationService.getLocalizedString("yes"))
          .cancelBtn(this.localizationService.getLocalizedString("no"))
          .open()
          .result.then(confirmed => {

            this.setEmptyNewModel();
            this.loadUnavailableDays(moment().year(), moment().month() + 1, true, this._form.value.fromDate);

            this.form.setValue(formData);

            this.updateModelFromForm();
            this.populateDetailsModelFromFormValues();

            // Savenutý model mohol byť pre inú adresu a zákazníka, aktualizujeme teda údaje na aktuálne.
            this._model.pickupOrder.customerPickupAddressId = this._pickupAddress.id;
            this._model.pickupOrder.customerDetailId = this._customerDetail.id;
            this._model.pickupOrder.customerDelisId = this._customerDetail.delisId;
            this._model.pickupOrder.depotCode = this._pickupAddress.depotCode;
            this._model.pickupOrder.customerNr = this._customerDetail.customerNumber;

            this._isLoadedFromLocalStorage = true;
            this._isBusy = false;
          }, () => {
            this.setEmptyNewModel();
            this.loadUnavailableDays(moment().year(), moment().month() + 1, true, this._form.value.fromDate);
          });
        } else {
          this.setEmptyNewModel();
          this.loadUnavailableDays(moment().year(), moment().month() + 1, true, this._form.value.fromDate);
        }
      } else {
      this.loadExisting();
    }
  }


  private setEmptyNewModel() {
    this.clearTemporaryDataFromLocalStorage();

    this._model = this.getNewModel();
    this.setFormValue(this._model);
    this._isBusy = false;
  }


  private loadExisting() {
    this._pickupOrdersServices.getPickupOrder(this._id).subscribe(model => {
      this._currentPickupOrderDate = model.pickupOrder.fromDate;
      this.setFormValue(model);
      this._model = model;

      if (!this._model.pickupOrder.isRegular) {
        this._isRegular = false;
      }
      this._isBusy = false;
    }, ex => {
      this.loggingService.logErrorData(ex, "Error loading pickup order");
      this._isBusy = false;

      const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

      this._toastr.error(`${this.localizationService.getLocalizedString("error_loading_pickup_order")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
    });
  }

  public onPickupMonthChanged(value) {
    this.loadUnavailableDays(value.year, value.month);
  }

  public initUnavailableDays(ctrl) {
    const self = this;
    this._ctrl = ctrl;

    this.loadUnavailableDays(moment().year(), moment().month() + 1, true, this._form.value.fromDate);

    ctrl.calendar.displayMonthChanged.addHandler(s => {
      self.loadUnavailableDays(moment(s.displayMonth).year(), moment(s.displayMonth).month() + 1);
    });

    ctrl.calendar.formatItem.addHandler((s, e) => {
      const day = moment(e.data).day();
      const ddd = moment(e.data).format("DDD");

      if ((day === 0 && !self._isSundayPickupAvailable) || (day === 6 && !self._isSaturdayPickupAvailable) || self._unavailableDaysNumbers.indexOf(ddd) > -1) {
        e.item.classList.add("wj-state-disabled");
      }
    });
  }


  private loadUnavailableDays(year, month, adjustFromDate = false, fromDate = null): any {
    this._pickupOrdersServices.getUnavailablePickupDays(
      this._pickupAddress.id, this._pickupAddress.customerDetailId, year, month)
    .subscribe(result => {
      const days = result;

      this._unavailableDays = [];

      days.forEach(day => {
        const date = moment.utc(day);
        this._unavailableDays.push(moment(date.format("YYYY-MM-DD"), "YYYY-MM-DD").toDate());
        this._unavailableDaysNumbers.push(date.format("DDD"));
      });

      if (this._currentPickupOrderDate) {
        this._unavailableDays.splice(this._unavailableDaysNumbers.findIndex(day => day === moment(this._currentPickupOrderDate).format("DDD")), 1);
      }

      // this._ctrl.calendar.invalidate();

      if (adjustFromDate && this.isNew && !this._isLoadedFromLocalStorage) {
        let _fromDate = fromDate;
        let __unavailableDays = this._unavailableDaysNumbers;

        _fromDate = moment(_fromDate).add(1, "day").toDate();

        while ((moment(_fromDate).day() === 0 && !this._isSundayPickupAvailable) || (moment(_fromDate).day() === 6 && !this._isSaturdayPickupAvailable) || __unavailableDays.indexOf(moment(_fromDate).format("DDD")) > -1) {
          const _currentMonth = moment(_fromDate).month();
          _fromDate = moment(_fromDate).add(1, "day").toDate();

          if (moment(_fromDate).month() !== _currentMonth && this._numberOfMonthsToCheck > 0) {
            this._numberOfMonthsToCheck--;
            this.loadUnavailableDays(moment(_fromDate).year(), moment(_fromDate).month() + 1, true, _fromDate);
            break;
          }
        }

        this._form.patchValue({
          fromDate: _fromDate
        });
      }
    }, ex => {
      this.loggingService.logErrorData(ex, "Error loading unavailable days");

      const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

      this._toastr.error(`${this.localizationService.getLocalizedString("error_loading_unavailable_days")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
    });
  }


  private getNewModel(): PickupOrderModel {
    const firstContactPerson = this._contactPersons && this._contactPersons.length ? this._contactPersons[0] : null;

    return {
      details: [],
      pickupOrder: {
        adHocAddressId: null,
        customerDetailId: this._customerDetail.id,
        customerPickupAddressId: this._pickupAddress.id,
        contactPerson: firstContactPerson ? firstContactPerson.name : "",
        createdByUser: null,
        creationDateTimeUtc: null,
        customerDelisId: this._customerDetail.delisId,
        customerNr: this._customerDetail.customerNumber,
        depotCode: this._pickupAddress.depotCode,
        dispSys: "",
        email: firstContactPerson ? firstContactPerson.email : "",
        fromDate: this._minDate,
        id: 0,
        isNew: true,
        isPersistent: false,
        modificationDateTimeUtc: null,
        modifiedByUser: null,
        note: firstContactPerson ? firstContactPerson.note : "",
        parcelsCount: 1,
        partnerReference: "",
        phone: firstContactPerson ? firstContactPerson.phone : "",
        pickupOrderTypeId: 1,
        stateId: "New",
        isRegular: this._isRegular,
        isSameDay: false
      }
    };
  }

  private getEmptyPickupOrderDetail = (): PickupOrderDetail => {
    const pickupOrderDetail: PickupOrderDetail = {
      pickupOrderId: 0,
      tourNumber: null,
      quantity: null,
      dayId: null,
      fromTime1: null,
      toTime1: null,
      fromTime2: null,
      toTime2: null,
      isScanRequired: null,
      isPlanRequired: null,
      displayTextInDispatcher: null,
      driverComment: "",
      collectTransportCostsAtPickUp: null,

      id: 0,
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      createdByUser: null,
      modifiedByUser: null,
      isNew: true,
      isPersistent: false
    };

    return pickupOrderDetail;
  }

  private createDetailModel(dayId: number, pickupOrderId: number, from: Date, to: Date, pickupOrderDetailId: number) {
    const detail = this.getEmptyPickupOrderDetail();
    detail.fromTime1 = from;
    detail.toTime1 = to;
    detail.pickupOrderId = pickupOrderId;
    detail.dayId = dayId;
    detail.id = pickupOrderDetailId;
    return detail;
  }

  private populateDetailsModelFromFormValues() {
    const today = new Date();
    const from = new Date(today.getFullYear(), today.getMonth(), today.getDate(), this.form.value.from, ((60 * (this.form.value.from % 1)) | 0), 0);
    const to = new Date(today.getFullYear(), today.getMonth(), today.getDate(), this.form.value.to, ((60 * (this.form.value.to % 1)) | 0), 0);
    const details: PickupOrderDetail[] = [];

    const pickupOrderDetails = this._model.details;
    const pickupOrderId = this._model.pickupOrder.id;

    if (this.form.value.monday) {
      const pickupOrderElement = _.find(pickupOrderDetails, { dayId: 1 });
      details.push(this.createDetailModel(1, pickupOrderId, from, to, pickupOrderElement ? pickupOrderElement.id : 0));
    }
    if (this.form.value.tuesday) {
      const pickupOrderElement = _.find(pickupOrderDetails, { dayId: 2 });
      details.push(this.createDetailModel(2, pickupOrderId, from, to, pickupOrderElement ? pickupOrderElement.id : 0));
    }
    if (this.form.value.wednesday) {
      const pickupOrderElement = _.find(pickupOrderDetails, { dayId: 3 });
      details.push(this.createDetailModel(3, pickupOrderId, from, to, pickupOrderElement ? pickupOrderElement.id : 0));
    }
    if (this.form.value.thursday) {
      const pickupOrderElement = _.find(pickupOrderDetails, { dayId: 4 });
      details.push(this.createDetailModel(4, pickupOrderId, from, to, pickupOrderElement ? pickupOrderElement.id : 0));
    }
    if (this.form.value.friday) {
      const pickupOrderElement = _.find(pickupOrderDetails, { dayId: 5 });
      details.push(this.createDetailModel(5, pickupOrderId, from, to, pickupOrderElement ? pickupOrderElement.id : 0));
    }
    if (this.form.value.saturday) {
      const pickupOrderElement = _.find(pickupOrderDetails, { dayId: 6 });
      details.push(this.createDetailModel(6, pickupOrderId, from, to, pickupOrderElement ? pickupOrderElement.id : 0));
    }

    this._model.details = details;
  }



  public contactPersonSelected = (selected: ContactPerson) => {
    if (typeof selected == "object") {
      // var contactPerson = selected.originalObject as ContactPerson;

      this._form.controls.contactPerson.setValue(selected.name);
      this.setContactPerson(selected);
    }
  }


  private setContactPerson(data: ContactPerson) {
    this._form.controls.email.setValue(data.email);
    this._form.controls.phone.setValue(data.phone);
    this._form.controls.note.setValue(data.note);
  }


  private initialize() {
    return forkJoin([
      this._contextService.tenantId.pipe(filter(value => value != null), take(1)),
      this._contextService.currentAddress.pipe(filter(value => value != null), take(1)),
      this._contextService.currentCustomerDetail.pipe(filter(value => value != null), take(1)),
      this._contextService.businessUnitCode.pipe(filter(value => value != null), take(1))
    ]).pipe(
      mergeMap(result => {
        this._pickupAddress = result[1];
        this._customerDetail = result[2];
        this._businessUnitCode = result[3];

        return this._customerService.getCustomerDetailContactPersons(result[0], this._customerDetail.id);
      }),
      map((result) => {
        this._contactPersons = result;

        this._contactPersons.forEach(cp => {
          cp.name = `${cp.firstName} ${cp.lastName}`.trim();
        });

        // this._contactPersonsCompleterData = this._completerService.local(this._contactPersons, "name", "name");

        return true;
      })
    );
  }


  public ngOnInit() {

    this.checkStopCredit();

    forkJoin([
      this._businessUnitSettingsService.getIsSaturdayPickupAllowed(),
      this._businessUnitSettingsService.getIsSundayPickupAllowed()
    ]).subscribe(([isAllowedSaturday, isAllowedSunday]: [boolean, boolean]) => {
      this._isSaturdayPickupAvailable = isAllowedSaturday;
      if (!this._isSaturdayPickupAvailable) {
        this.disabledDays.push(6);
      }
      this._isSundayPickupAvailable = isAllowedSunday;
      if (!this._isSundayPickupAvailable) {
        this.disabledDays.push(0);
      }
    });

    // Zistíme, či sme prihlásený ako administrátor v kontexte zákazníka
    this.authenticationService.impersonatingShipperAdminUserLogin$.subscribe((login: string) => {
      this._isImpersonatingShipperAdminUser = login != null;
    });

    this._paramsChangeSubscription = combineLatest([this._route.params, this._route.queryParams]).subscribe(result => {
      try {
        const params = result[0];
        this._id = +params.pickupOrderId; // Typecast na číslo.

        const queryParams = result[1];

        const regular = +queryParams.regular;
        this._isRegular = regular === 1 ? true : false;

        this._location.replaceState(`/pickup-orders/${this._id}`);

      } catch (ex) {
        this._id = 0;
      }

      this.updateActiveHourRange();

      this.initialize().subscribe(() => {
        this.setCityServiceAvailability().subscribe(() => {
          this.loadExistingOrInitializeNew();
        });
      });
    });
  }


  public get minDate() {
    return this._minDate;
  }


  private setMinDate() {
    const now = moment();

    return combineLatest([
      this._businessUnitSettingsService.getSameDayPickupOrderThresholdHour(),
      this._businessUnitSettingsService.getSameDayPickupOrderThresholdMinute(),
    ]).pipe(
      map((result: [
      string,
      string
    ]) => {

      this._sameDayThresholdHour = +result[0];
      this._sameDayThresholdMinute = +result[1];

      const timeInMinutes = now.hours() * 60 + now.minutes();
      const thresholdTimeInMinutes = this._sameDayThresholdHour * 60 + this._sameDayThresholdMinute;

      this._minDate = timeInMinutes < thresholdTimeInMinutes ?
        now.startOf("day").toDate() :
        now.startOf("day").add(1, "day").toDate();

    }, ex => {

      this._minDate = now.startOf("day").add(1, "day").toDate();

      this.loggingService.logErrorData(ex, "Error loading settings");
      const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
      this._toastr.error(`${this.localizationService.getLocalizedString("error_loading_settings")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
    })
    );
  }

  private setCityServiceAvailability() {
    return combineLatest([this._skdataConfigService.getAllowedProductsAndAdditionalServices(this._customerDetail.id)]).pipe(
      map(
        (result: any[]) => {
          if (result[0].some(p => p.code === ConfigModel.WellKnownProductCodes.CityService)) {
            this.cityserviceAvailable = true;
          }
        }, ex => {

          this.loggingService.logErrorData(ex, "Error loading settings");
          const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
          this._toastr.error(`${this.localizationService.getLocalizedString("error_loading_settings")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
        })
    );
  }

  public filterContactPerson(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this._contactPersons.length; i++) {
        const person = this._contactPersons[i];
        if (person.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(person);
        }
    }

    this._contactPersons = filtered;
  }


  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _contextService: Shared.ContextService,
    private _pickupOrdersServices: PickupOrdersService,
    private _modal: Modal,
    private _location: Location,
    private _customerService: Shared.CustomerService,
    private _completerService: CompleterService,
    private _toastr: ToastrService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _skdataConfigService: Shared.SkdataConfigService,
    private _shipperSettingsService: Shared.ShipperSettingsService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
    this.hoursFrom = this.pickupHourDropdownValues();
    this.hoursTo = this.hoursFrom;
    this._minDate = moment().startOf("day").toDate();
    this.setUpForm();
  }
}
