<div class="pickup-address">
  <div class="view" [class.odd]="isOdd" [class.in-preparation]="pickupAddress.stateId == 'InPreparation'" *ngIf="pickupAddress.id != 0">
    <div class="commands">
      <button id="button-edit" type="button" *ngIf="!isEdited && isEditEnabled" (click)="edit()" title="{{ 'pickup_address_edit' | shpLocalize }}"><span class="ic-pencil-1"></span></button>
      <button id="button-delete" type="button" *ngIf="!isEdited && isEditEnabled && pickupAddress.canDelete" (click)="delete()" title="{{ 'pickup_address_delete' | shpLocalize }}"><span class="ic-bin"></span></button>
      <button id="button-undelete" type="button" *ngIf="!isEdited && isEditEnabled && pickupAddress.canUndelete" (click)="undelete()" title="{{ 'pickup_address_undelete' | shpLocalize }}"><span class="ic-sign-recycle"></span></button>
    </div>
    <div class="conditions">
      <div class="address-custom-id ctl">{{ pickupAddress.customId }}</div>
      <div class="address-name ctl">
        <span>{{ pickupAddress.name1 }} {{ pickupAddress.name2 }}</span>
        <span>{{ pickupAddress.maskingStreet }} {{ pickupAddress.maskingHouseNr }}</span>
        <span>{{ pickupAddress.countryCode }}-{{ pickupAddress.zip }} {{ pickupAddress.place }}</span>
      </div>
      <!--<div class="address-masking-address ctl">
          <span>{{ pickupAddress.maskingName }} {{ pickupAddress.maskingName2 }}</span>
          <span>{{ pickupAddress.maskingStreet }} {{ pickupAddress.maskingHouseNr }}</span>
          <span>{{ pickupAddress.maskingCountryCode }}-{{ pickupAddress.maskingZip }} {{ pickupAddress.maskingPlace }}</span>
        </div>-->
      <div class="address-contact ctl">{{ pickupAddress.email }} {{ pickupAddress.phone }}</div>
      <div class="address-state ctl">
        <span *ngIf="pickupAddress.stateId == 'Approved' || pickupAddress.stateId == 'Valid' || pickupAddress.stateId == 'Exported' || pickupAddress.stateId == ''" class="check-mark" title="{{ 'pickup_address_valid' | shpLocalize }}"></span>
        <span *ngIf="pickupAddress.stateId == 'Deleted' || pickupAddress.stateId == 'DeletedExported'" class="deleted-address">{{ 'pickup_address_deleted' | shpLocalize }}</span>
        <span *ngIf="pickupAddress.stateId == 'New'" class="new-address">{{ 'pickup_address_new' | shpLocalize }}</span>
        <span *ngIf="pickupAddress.stateId == 'WaitingForApproval'" class="waiting-for-approval-address">{{ 'pickup_address_waiting_for_approval' | shpLocalize }}</span>
        <span *ngIf="pickupAddress.stateId == 'SentForApproval'" class="waiting-for-approval-address">{{ 'pickup_address_sent_for_approval' | shpLocalize }}</span>
        <span *ngIf="pickupAddress.stateId == 'NotApproved'" class="not-approved-address">{{ 'pickup_address_not_approved' | shpLocalize }}</span>

        <span *ngIf="pickupAddress.stateId == 'InPreparation'" class="in-preparation-address">
          {{ 'pickup_address_in_preparation' | shpLocalize }}
          <span class="ic-interface-alert-triangle" title="{{ 'pickup_address_in_preparation_state_alert' | shpLocalize }}"></span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="isEdited && form" class="editor">
    <form novalidate [formGroup]="form">
      <h2>{{ 'pickup_address' | shpLocalize }}</h2>

      <div class="address-name ctl required">
        <label>{{ 'address_name' | shpLocalize }}</label>
        <input type="text" id="address-name" formControlName="name1" #firstControl [readOnly]="!isNewOrNotValid" />
      </div>
      <!--<div class="address-name2 ctl">
        <label>{{ 'address_name_2' | shpLocalize }}</label>
        <input type="text" id="address-name2" formControlName="name2" />
      </div>-->
      <div class="ctl-2-col">
        <div class="address-street ctl required">
          <label>{{ 'address_street' | shpLocalize }}</label>
          <input type="text" id="address-street" formControlName="street" />
        </div>
        <div class="address-house-nr ctl required">
          <label>{{ 'address_house_nr' | shpLocalize }}</label>
          <input type="text" id="address-house-nr" formControlName="houseNr" />
        </div>
      </div>
      <!--<div class="address-street-2 ctl">
        <label>{{ 'address_street_2' | shpLocalize }}</label>
        <input type="text" id="address-street-2" formControlName="street2" />
      </div>-->
      <div class="address-zip ctl required">
        <label>{{ 'address_zip' | shpLocalize }}</label>
        <!--<input type="text" id="address-zip" formControlName="zip" />-->
        <!-- <ng2-completer formControlName="zip" id="address-zip"
                       required
                       [dataService]="zipCityPairsDataService"
                       [minSearchLength]="1"
                       (selected)="zipCityPairSelected($event)">
        </ng2-completer> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-autoComplete id="address-zip" formControlName="zip" [suggestions]="filteredZips" (completeMethod)="filterZip($event)" (onSelect)="onZipSelected($event)" name="address_zip" [minLength]="1" autocomplete="none" required>
              <ng-template let-zip pTemplate="item"><div class="zip-item"><div><b>{{zip.zip}}</b></div><div><small>{{zip.cityName}}</small></div></div></ng-template>
            </p-autoComplete>
            <label for="address-zip">{{ 'address_zip' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="address-zip ctl" *ngIf="isZipInvalid">
        <em class="error">{{ 'error_zip_invalid' | shpLocalize }}</em>
      </div>
      <div class="address-city ctl" *ngIf="isZipNotInCity && !isZipInvalid">
        <em class="error">{{ 'error_zip_not_in_city' | shpLocalize }}</em>
      </div>
      <div class="address-city ctl required">
        <label>{{ 'address_city' | shpLocalize }}</label>
        <input type="text" id="address-city" formControlName="place" />
      </div>
      <div class="address-country ctl required">
        <label>{{ 'address_country' | shpLocalize }}</label>
        <select id="select-address-country" formControlName="countryCode">
          <option [ngValue]="null">-</option>
          <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.name }}</option>
        </select>
      </div>
      <div class="address-phone ctl">
        <label>{{ 'address_phone' | shpLocalize }}</label>
        <input type="text" id="address-phone" formControlName="phone" />
      </div>
      <div class="address-email ctl">
        <label>{{ 'address_email' | shpLocalize }}</label>
        <input type="text" id="address-email" formControlName="email" />
      </div>
      <!--<div class="address-web ctl">
        <label>{{ 'address_web' | shpLocalize }}</label>
        <input type="text" id="address-web" formControlName="web" />
      </div>-->
      <!--<h2>{{ 'masking_address' | shpLocalize }}</h2>
      <div class="masking-address-name ctl">
        <label>{{ 'address_name' | shpLocalize }}</label>
        <input type="text" id="masking-address-name" formControlName="maskingName" />
      </div>
      <div class="masking-address-name2 ctl">
        <label>{{ 'address_name_2' | shpLocalize }}</label>
        <input type="text" id="masking-address-name2" formControlName="maskingName2" />
      </div>
      <div class="ctl-2-col">
        <div class="address-street ctl">
          <label>{{ 'address_street' | shpLocalize }}</label>
          <input type="text" id="masking-address-street" formControlName="maskingStreet" />
        </div>
        <div class="address-house-nr ctl">
          <label>{{ 'address_house_nr' | shpLocalize }}</label>
          <input type="text" id="masking-address-house-nr" formControlName="maskingHouseNr" />
        </div>
      </div>
      <div class="masking-address-street-2 ctl">
        <label>{{ 'address_street_2' | shpLocalize }}</label>
        <input type="text" id="masking-address-street-2" formControlName="maskingStreet2" />
      </div>
      <div class="masking-address-zip ctl">
        <label>{{ 'address_zip' | shpLocalize }}</label>
        <input type="text" id="masking-address-zip" formControlName="maskingZip" />
      </div>
      <div class="masking-address-city ctl">
        <label>{{ 'address_city' | shpLocalize }}</label>
        <input type="text" id="masking-address-city" formControlName="maskingCity" />
      </div>
      <div class="masking-address-country ctl">
        <label>{{ 'address_country' | shpLocalize }}</label>
        <select id="select-masking-address-country" formControlName="maskingCountryCode">
          <option [ngValue]="''">-</option>
          <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.name }}</option>
        </select>
      </div>-->

      <div class="commands">
        <button id="button-save" type="button" [disabled]="!form.valid || form.pristine" *ngIf="isEdited" (click)="save()"><span class="ic-floppydisk"></span> {{ 'save' | shpLocalize }}</button>
        <button id="button-cancel" type="button" *ngIf="isEdited" (click)="cancelEdit()"><span class="ic-close"></span> {{ 'cancel' | shpLocalize }}</button>
      </div>
    </form>
  </div>
</div>