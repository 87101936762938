import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { from } from "rxjs";

import { ApiServiceBase, LoggingService, API_URL, StringHelpers } from "./../index";
import { Link } from "../models/link.model";

const MANUAL_PATH: string = '/assets/manuals/';

const SHIPPER_MANUAL: [string, string] = ['DPD_Shipper_Manual_{0}.pdf', 'manual'];
const SHIPPER_IMPORT_MANUAL: [string, string] = ['DPD_Shipper_ImportManual_{0}.pdf', 'import_manual'];
const SHIPPER_COUNTRIES: [string, string] = ['Countries_ISO_{0}.xlsx', 'countries_list_with_iso_codes'];

const SOPHIA_MANUAL: [string, string] = ['DPD_Customer_Portal_Manual_{0}.pdf', 'manual'];
const SOPHIA_IMPORT_MANUAL: [string, string] = ['DPD_Customer_Portal_ImportManual_{0}.pdf', 'import_manual'];

@Injectable()
export class LinkService extends ApiServiceBase {
  constructor(
    loggingService: LoggingService,
    private _http: HttpClient
  ) {
    super(loggingService);
  }


  /**
   * Vráti odkazy pre prihlásenú business unit v požadovanom jazyku s fallbackom na EN.
   * @param languageCode Aktuálne zvolený jazyk.
   */
  public getLinks(businessUnitCode: string, languageCode: string) {
    return this.processRequest<Link[]>(this._http.get(`${API_URL}/links/${businessUnitCode}?languageCode=${languageCode}`));
  }

  public getManuals(languageCode: string, isSophia: boolean = false) {
    let links = [] as Link[];
    let link: Link = null;

    // HardCoded: V režime Sofia zobrazujeme SK aj EN manuály, inak iba EN manuály
    if (!(languageCode === "SK" && !isSophia)) {
      // Manuál
      link = {
        url: MANUAL_PATH + StringHelpers.format(!isSophia ? SHIPPER_MANUAL[0] : SOPHIA_MANUAL[0], languageCode),
        displayName: StringHelpers.format(!isSophia ? SHIPPER_MANUAL[1] : (languageCode === "SK" ? 'Príručka' : 'Manual'), languageCode),
        languageCode: languageCode,
        openInNewWindow: true
      } as Link;
      links.push(link);

      // Manuál pre importy
      link = {
        url: MANUAL_PATH + StringHelpers.format(!isSophia ? SHIPPER_IMPORT_MANUAL[0] : SOPHIA_IMPORT_MANUAL[0], languageCode),
        displayName: StringHelpers.format(!isSophia ? SHIPPER_IMPORT_MANUAL[1] : (languageCode === "SK" ? 'Príručka pre importy' : 'Import manual'), languageCode),
        languageCode: languageCode,
        openInNewWindow: true
      } as Link;
      links.push(link);

      // Tabuľka krajín
      link = {
        url: MANUAL_PATH + StringHelpers.format(SHIPPER_COUNTRIES[0], languageCode),
        displayName: StringHelpers.format((languageCode === "SK" ? 'Zoznam krajín s dvoj a trojpísmenovým ISO kódom a číselným ISO kódom' : 'List of countries with 2- and 3-letter ISO codes and numeric ISO codes'), languageCode),
        languageCode: languageCode,
        openInNewWindow: true
      } as Link;
      links.push(link);

      return from([links]);
    }
  }
}