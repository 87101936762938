
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category">
    <h1>{{ 'email_templates_editor' | shpLocalize }}</h1>

    <div class="container-fluid email-template-editor-box">
      <div class="flex-row">
        <div class="flex-col-6 d-flex">
          <form [formGroup]="form" class="d-flex flex-direction-column flex-grow-1">
            <div class="ctl required ml-0 flex-direction-column">
              <label for="customer-personalized-notification-language">{{ 'message_language' | shpLocalize }}</label>
              <select id="customer-personalized-notification-language" name="customerPersonalizedNotificationLanguage" formControlName="languageCodes">
                <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
              </select>
            </div>
            <div class="ctl required ml-0 flex-direction-column">
              <label for="customer-personalized-notification-title">{{ 'message_subject' | shpLocalize }}</label>
              <input type="text" name="customerPersonalizedNotificationTitle" id="customer-personalized-notification-title" formControlName="content2"/>
            </div>
            <div class="ctl required ml-0 mb-0 flex-direction-column flex-grow-1">
              <label for="customer-personalized-notification-title">{{ 'message_body' | shpLocalize }}</label>
              <div class="relative flex-grow-1">
                <template-content-editor formControlName="content"></template-content-editor>
              </div>
            </div>
            <div>
              <h3>{{ 'placeholders' | shpLocalize }}</h3>
              <ul class="email-template-placeholders">
                <li *ngFor="let placeholder of placeholders">
                  <div class="email-template-placeholder">
                    <span>{{ placeholder.name | shpLocalize }}<ng-container *ngIf="placeholder.desc">&nbsp;<span class="ic-interface-information" [pTooltip]="placeholder.desc | shpLocalize" appendTo="body" escape="false" tooltipPosition="top"></span></ng-container></span>
                    <button (click)="applyText(placeholder.template, placeholder.cursorIndex)"><span class="ic-add-2"></span></button>
                  </div>
                </li>
              </ul>
            </div>
          </form>
        </div>
        <div class="flex-col-6 d-flex flex-direction-column">
          <h3>{{ 'message_preview' | shpLocalize }}</h3>
          <div *ngIf="isPreviewLoading" class="relative flex-grow-1">
              <shp-progress-overlay [isActive]="true"></shp-progress-overlay>
          </div>
          <ng-container *ngIf="!isPreviewLoading">
            <iframe *ngIf="templateIsValid" id="templatePreview" class="template-preview-frame flex-grow-1"></iframe>
            <div class="invalid-template-message" *ngIf="!templateIsValid">
              <div class="invalid-template-message-box">
                <span class="ic-chat-bubble-square-alert-1 ic-24"></span> {{ 'invalid_template_code' | shpLocalize }}
                <div class="preview-error-message">
                  {{ templatePreviewErrorMessage }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="container-fluid email-template-editor-commands">
      <div class="flex-row">
        <div class="flex-col-6 d-flex">
          <button *ngIf="!isNew" [disabled]="form.invalid" type="button" (click)="updateTemplate()"><span class="ic-floppydisk"></span> {{ 'save' | shpLocalize}}</button>
          <button *ngIf="isNew" [disabled]="form.invalid" type="button" (click)="createTemplate()"><span class="ic-floppydisk"></span> {{ 'create' | shpLocalize}}</button>
        </div>
      </div>
    </div>

  </div>
