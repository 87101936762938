import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";

import * as Shared from "./index";

@Injectable()
export class AppDataResolver implements Resolve<any> {
  constructor(
    private _localizationService: Shared.LocalizationService,
    private _authenticationService: Shared.AuthenticationService
  ) { }

  public resolve() {
    return Promise.all(
      [
        this._authenticationService.reinitializeFromTokenStorage(),
        this._localizationService.initializeLocalizationData()
      ]
    );
  }
}
