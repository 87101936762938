import { Directive, ViewContainerRef, Host, Optional, InjectionToken } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export const ROW_COMPONENT =
    new InjectionToken<RowDirective>("ROW_COMPONENT");

import { ColumnDirective } from "./column.directive";
import { TableDirective } from "./table.directive";

@Directive({
  selector: "[appRow]",
  providers: [
    {provide: ROW_COMPONENT, useExisting: RowDirective}
  ]
})
export class RowDirective {
  private _columns: {[name: string]: ColumnDirective} = {};
  private _viewContainer: ViewContainerRef;
  private _destroy$ = new Subject<void>();

  constructor(
      @Host() @Optional() private _table: TableDirective
  ) { }

  ngAfterViewInit() {
    if (this._table) {
      this._table.columnChanges$.pipe(
        takeUntil(this._destroy$)
      ).subscribe(() => this._updateView());
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  registerColumn(column: ColumnDirective): void {
    if (!this._viewContainer) {
        this._viewContainer = column.viewContainer;
    }

    const name = column.name;

    if (this._columns[name]) {
      return;
    }

    this._columns[name] = column;
    this._updateView();
  }

  removeColumn(column: ColumnDirective): void {
    delete (this._columns[column.name]);
    this._updateView();
  }

  private _updateView() {
    if (!this._table || !Array.isArray(this._table.displayColumns)) {
      return;
    }

    this._viewContainer.clear();

    this._table.displayColumns.forEach(columnName => {
      const column = this._columns[columnName];
      
      if (!column) {
        return;
      }

      this._viewContainer.createEmbeddedView(column.template, column.context);
    });
  }

}
