<div class="table-settings-dialog">
  <shp-drag-and-drop-list 
    valueKey="value"
    viewKey="viewValue"
    [sourceList]="columnsList"
    [(ngModel)]="displayColumns"></shp-drag-and-drop-list>

    <div class="ctl buttons mb-0">
      <button id="button-dismiss" type="button" (click)="dismiss()">{{ 'dismiss' | shpLocalize }}</button>
      <button id="button-set-defualt" type="button" (click)="setDefault()">{{ 'set_defualt_value' | shpLocalize }}</button>
      <button id="button-close" type="button" (click)="close()">{{ 'confirm' | shpLocalize }}</button>
    </div> 
</div>