import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as Shared from "../../../shared/index";
import * as SettingsModel from "../../models/settings.model";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import {SyncShipmentsEndpoint} from "../../models/settings.model";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-sync-settings",
  templateUrl: "./sync-settings.component.html"
})
export class SyncSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _skdataConfigService: SkdataConfigService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _modal: Modal,
    toastr: ToastrService) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }

  get isFtpEndpointSelected(): boolean {
    return this.settings != null && this.settings.sync_shipments_to_endpoint.value === SyncShipmentsEndpoint.Ftp;
  }

  get endpointTranslate(): string {
    if (this.settings == null) {
      return "";
    }

    const value = this.settings.sync_shipments_to_endpoint.value;
    switch (value) {
      case SyncShipmentsEndpoint.Api:
        return "settings_sync_shipments_to_api";
      case SyncShipmentsEndpoint.Ftp:
        return "settings_sync_shipments_to_ftp";
    }

    return "";
  }

  public timeModes: string[] = [
    SettingsModel.SyncShipmentsTimeMode.SpecificTime,
    SettingsModel.SyncShipmentsTimeMode.NMinutesAfterSave,
    SettingsModel.SyncShipmentsTimeMode.Continually,
    SettingsModel.SyncShipmentsTimeMode.Manually
  ];


  public isTestingConnection = false;


  public testConnection() {
    if (this.isTestingConnection) {
      return;
    }

    if (this.hasUnsavedChanges()) {
      this.toastr.error(this.localizationService.getLocalizedString("save_settings_before_testing_connection"));
      return;
    }

    this.isTestingConnection = true;

    this.settingsService.testSyncSettingsConnection(this.customerDetailId)
      .subscribe(
      isAvailable => {
        this.isTestingConnection = false;
        // Tu by sme mali dostávať len true hodnotu, ak je niečo zlé, tak server posiela chybu.

        const message = this.settings.sync_shipments_to_endpoint.value === SyncShipmentsEndpoint.Api
          ? "sync_with_dpd_api_test_success"
          : "sync_with_dpd_output_directory_is_accessible";

        this._modal
          .alert()
          .body(this.localizationService.getLocalizedString(message))
          .open();
      }, ex => {
        this.isTestingConnection = false;

        var exception = this.exceptionsHandlerService.getExceptionInfo(ex);

        this._modal.alert().body(this.localizationService.getLocalizedExceptionString(exception)).open();
      });
  }
}