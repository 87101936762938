import { Directive, forwardRef, StaticProvider } from "@angular/core"
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from "@angular/forms"

import * as Shared from "../index"
import { validateMultipleEmails } from "../validators";

export const MULTIPLE_EMAILS_VALIDATOR: StaticProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MultipleEmailValidatorDirective),
    multi: true
  };

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}ValidateMultipleEmail][ngModel],[${Shared.SELECTOR_PREFIX}ValidateMultipleEmail][formControl]`,
  providers: [MULTIPLE_EMAILS_VALIDATOR]
})
export class MultipleEmailValidatorDirective implements Validator {
  private _onChange: () => void;
  
  validate(control: AbstractControl): ValidationErrors | null {
    return validateMultipleEmails(control);
  }

  /**
   * Registers a callback function to call when the validator inputs change.
   *
   * @param fn The callback function
   */
  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
}