import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import * as _ from "lodash";
import { CompleterService, LocalData, RemoteData } from "ng2-completer";
import { of, ReplaySubject } from "rxjs";
import { catchError, filter, map, take } from "rxjs/operators";

import * as SettingsModel from "../../settings/models/settings.model";
import * as Shared from "../../shared/index";
import { CustomerService, SkdataConfigService } from "../../shared/index";
import { DpdApiCustomerData } from "../../shared/models/DpdApi/dpd-api-customer-data.model";
import { ZipCityPair } from "../../shared/models/zip-city-pair.model";
import { validateCity, validateEmail, validateNoSpaces, validateZip, validateZipFormat } from "../../shared/validators";
import { DpdApiCustomerEditorData } from "../models/dpd-api-customer-editor-data.model";
import { ShipmentService } from "../services/shipments.service";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-dpd-api-customer",
  templateUrl: "./dpd-api-customer.component.html",
  providers: [CompleterService]
})
export class DpdApiCustomerComponent implements OnChanges {
  countryOptions: Partial<Shared.Country>[] = [];
  customerNumberQuery = "";
  filteredCities: any[];
  filteredZips: ZipCityPair[];
  form: FormGroup;
  streetsDataService: LocalData = null;
  zipCityPairsDataService: RemoteData = null;


  @Input() citiesCountry;
  @Input() customer: Partial<DpdApiCustomerEditorData> | null = null;
  @Input() disableValidation = false;
  @Input() isAddressReadonly = true;
  @Input() isReadOnly = false;
  @Input() isRecipientEmailRequired: boolean;
  @Input() languages: string[];
  @Input() settings: SettingsModel.TenantSettings;

  @Input()
  set countries(value: Shared.Country[]) {
    if (Array.isArray(value)) {
      this._countries = value;
      this._countries$.next(value);
    }
  }

  get countries(): Shared.Country[] {
    return this._countries;
  }

  get canSearch(): boolean {
    return this.customerNumberQuery.length &&
      this.customerNumberQuery !== this.form.get("customerNumber")?.value;
  }


  @Output() customerChange = new EventEmitter<DpdApiCustomerEditorData>();


  private _countries: Shared.Country[] = null;
  private _countries$ = new ReplaySubject<Shared.Country[]>(1);

  constructor(
      private _fb: FormBuilder,
      private _localizationService: Shared.LocalizationService,
      private _skDataConfigService: SkdataConfigService,
      private _shipmentService: ShipmentService,
      private _customerService: CustomerService) {
    this.buildForm();
  }


  get countryCtrl(): FormControl {
    return this.form.get("country") as FormControl;
  }


  get selectedCountryCode(): string {
    return this.countryCtrl.value?.code;
  }


  ngOnChanges(changes: SimpleChanges) {
    // Set default country if not set.
    if (("settings" in changes || "countries" in changes) &&
        this.settings?.country_code &&
        !this.countryCtrl.value) {
      const country = this.countries?.find(c => c.code === this.settings.country_code.value);

      if (country) {
        this.countryCtrl.setValue(country);
      }
    }

    // Set form value from customer data.
    if ("customer" in changes) {
      this.fillFormWithEditorData();
    }
  }


  onZipSelected(pair: ZipCityPair) {
    if (!pair) {
      return;
    }

    const cityCtrl = this.form.get("city");

    cityCtrl?.setValue(pair.cityName, {
      onlySelf: true
    });

    this.form.get("zip").setValue(pair.zip);
  }


  clearCustomer() {
    this.form.reset({
      country: this.countries.find(c => c.code === this.settings.country_code.value)
    });
  }


  hasUnsavedChanges = (): boolean => {
    return this.form.dirty;
  }


  formInvalid() {
    return this.form.invalid;
  }


  filterZip(event) {
    this._skDataConfigService.getCitiesByZip(this.selectedCountryCode, event.query).pipe(
      catchError(() => of([])),
    ).subscribe(zipCodes => this.filteredZips = zipCodes);
  }


  filterCities(event) {
    this._skDataConfigService.getCities(this.selectedCountryCode, event.query).pipe(
      map(cities => cities.map(c => c.cityNameLocal)),
      catchError(() => of([]))
    ).subscribe(cities => this.filteredCities = cities);
  }


  findCustomerAddressByCustomerNumber() {
    if (this.customerNumberQuery) {
      this._customerService.getCustomerAddressByCustomerNumberFromDpdApi(this.customerNumberQuery)
        .subscribe(result => this.fillFormWithApiResult(result), ex => { });
    }
  }


  private buildForm() {
    this.form = this._fb.group({
      customerNumber: ["", {validators: [Validators.required]}],
      customerDelisId: ["", {validators: [Validators.required]}],
      name: ["", {validators: [Validators.required]}],
      // name2: "",
      street: ["", {validators: [Validators.required]}],
      houseNr: "",
      // streetDetail: "",
      country: [null, {validators: [Validators.required]}],
      // languageCode: "",
      zip: ["", {
        validators: [
          Validators.required,
          validateNoSpaces,
          validateZipFormat(() => this.getZipFormatsForSelectedCountry())
        ],
        asyncValidators: [validateZip(this._shipmentService, "country", "city")]
      }],
      city: ["", {
        validators: [Validators.required],
        asyncValidators: [validateCity(this._shipmentService, "country", "zip")]
      }],
      email: ["", {validators: [validateEmail]}],
      phone: ""
    });

    this.form.get("country")?.valueChanges.subscribe(() => {
      this.form.get("zip").updateValueAndValidity();
      this.form.get("city").updateValueAndValidity();
    });

    this.form.valueChanges.subscribe(v => this.customerChange.emit(v));
  }


  private getZipFormatsForSelectedCountry(): string[] {
    return this.countries.find(c => c.code === this.selectedCountryCode)?.postCodePattern?.split(",") ?? [];
  }


  private fillFormWithApiResult(result: DpdApiCustomerData) {
    // Ensure that countries are provided.
    this._countries$.pipe(
      filter(c => Boolean(c.length)),
      take(1)
    ).subscribe(countries => {
      const country =
          countries.find(c => c.code.toUpperCase() === result.codeAlpha.toUpperCase());

      this.customerNumberQuery = result.custNo;

      this.form.patchValue({
        customerNumber: result.custNo,
        customerDelisId: result.delisUserId,
        name: result.custName.substring(0, 35),
        // name2: "",
        street: result.street,
        houseNr: result.buildingNo,
        // streetDetail: "",
        country,
        // languageCode: "",
        zip: result.zipCode,
        city: result.cityName,
        email: result.emailAddress,
        phone: result.telNo1,
      });
    });
  }


  private fillFormWithEditorData() {
    if (!this.customer) {
      return;
    }

    // Ensure that countries are provided.
    this._countries$.pipe(
      filter(c => Boolean(c.length)),
      take(1)
    ).subscribe(countries => {
      const country = this.customer?.country ?
        countries.find(c => c.code.toUpperCase() === this.customer.country?.code.toUpperCase()) :
        null;

      this.customerNumberQuery = this.customer.customerNumber ?? "";

      this.form.patchValue({
        ...this.customer,
        ...{country}
      }, {emitEvent: false});
    });
  }
}
