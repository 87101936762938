import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ComponentRef, HostBinding, OnInit } from "@angular/core";

import { take } from "rxjs/operators";

import { animations } from "../animations";
import { WizardCssClass, WizardPopup } from "../models";
import { WizardService } from "../services/wizard.service";
import { setComponentData, toCssClassArray } from "../utils";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "wizard-popup",
  templateUrl: "./wizard-popup.component.html",
  animations: [
    animations.wizardPopup
  ],
  host: {
    "[@wizardPopup]": "true"
  }
})
export class WizardPopupComponent implements OnInit {
  popup: WizardPopup = {};
  popupComponentPortal: ComponentPortal<any> = null;

  @HostBinding("class") popupClasses: string[] = [WizardCssClass.Popup];

  constructor(private wizardService: WizardService) { }

  ngOnInit() {
    this.observeWizard();
  }

  onPortalAttach(ref: ComponentRef<any>) {
    setComponentData(ref, this.popup?.popupComponentData);
  }

  private observeWizard() {
    this.wizardService.state$.pipe(
      take(1)
    ).subscribe(state => {
      const step = state.currentStep;
      this.popup = step?.popup;
      this.popupComponentPortal = this.popup?.popupComponent ? new ComponentPortal(this.popup.popupComponent) : null;
      this.applyPopupClasses(this.popup?.popupClass);
    });
  }

  private applyPopupClasses(popupClass: string | string[]) {
    this.popupClasses = [
      WizardCssClass.Popup,
      ...toCssClassArray(popupClass)
    ];
  }
}
