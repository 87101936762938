<ng-content select="[editor-title]"></ng-content>

<!--<div class="ctl item-list-multiple-checkbox">
  <label [for]="id">{{ 'select_all' | shpLocalize }}</label>
  <input [id]="id" name="multipleSelect" type="checkbox" [(ngModel)]="multipleSelectModel" (ngModelChange)="onMultipleSelect()">
  <label [for]="id"></label>
</div>-->

<div class="item-list-ctl">

  <span *ngIf="!items || items.length == 0">{{ 'no_items' | shpLocalize }}</span>

  <p-listbox *ngIf="items && items.length > 0"
             id="related-items__{{ id }}"
             name="related-items__{{ id }}"
             [showToggleAll]="true"
             (onChange)="updateSelectModel($event)"
             [options]="items"
             [(ngModel)]="selectedItems"
             [metaKeySelection]="false"
             [checkbox]="true"
             [filter]="true"
             [filterPlaceHolder] = "'filter' | shpLocalize"
             [multiple]="true"
             optionLabel="itemName"
             ngDefaultControl>
  </p-listbox>
</div>

<div class="item-list-buttons ctl">
  <button id="button-save" [disabled]="!saveChangesEnabled" (click)="saveChanges()">
    <span class="ic-floppydisk"></span> {{ 'save' | shpLocalize }}
  </button>
</div>
