import { Component } from "@angular/core";

import { DialogRef, ModalComponent } from "ngx-modialog-7";
import { ImportProfilesService } from "../services/import-profiles.service";
import { LocalizationService, ExceptionsHandlerService, ContextService } from "../../shared/index";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  templateUrl: "./import-profiles-import-dialog.component.html"
})
export class ImportProfilesImportDialogComponent implements ModalComponent<any> {
  private _isBusy = false;
  private _selectedFileName = "";
  private _selectedFile: File = null;
  private _overwriteIfCodeExists = false;
  private _statusMessage = "";
  private _wasAnytingImported = false;

  public type: string;

  public get isBusy() { return this._isBusy; }


  public get statusMessage() { return this._statusMessage; }


  public get selectedFileName() { return this._selectedFileName; }


  public get dialog() { return this._dialog; }


  public get overwriteIfCodeExists() { return this._overwriteIfCodeExists; }
  public set overwriteIfCodeExists(value: boolean) { this._overwriteIfCodeExists = value; }

  public customerDetailId = null;
  public isCustomerLevelTenant = false;

  public import() {

    if (this._selectedFile == null) {
      return;
    }

    this._isBusy = true;


    var importRunner = (content: string) => {
      let model = {
        overwriteIfCodeExists: this.overwriteIfCodeExists,
        content: content
      } as any;

      if (this.isCustomerLevelTenant) {
        model.customerDetailId = this.customerDetailId;
      }

      this._importProfileService.importImportProfile(model).subscribe(importOutput => {
        this._wasAnytingImported = true;
        this._isBusy = false;
        this._toastr.success(this._localizationService.getLocalizedString("import_profile_successfully_imported"));
      }, ex => {
        let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._toastr.error(this._localizationService.getLocalizedString("error_importing_import_profile") + ": " + this._localizationService.getLocalizedExceptionString(exceptionInfo));

        this._isBusy = false;
      })
    }

    var fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      importRunner(<string>fileReader.result);
    }

    fileReader.onerror = () => {
      this._isBusy = false;
    }

    fileReader.onabort = () => {
      this._isBusy = false;
    }

    fileReader.readAsText(this._selectedFile);
  }


  public close() {
    this.dialog.close(this._wasAnytingImported);
  }


  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }


  private setSelectedFile(inputValue: any) {
    if (inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this._selectedFileName = this._selectedFile.name;
    } else {
      this._selectedFileName = "";
      this._selectedFile = null;
    }
  }


  constructor(
    private _dialog: DialogRef<any>,
    private _importProfileService: ImportProfilesService,
    private _contextService: ContextService,
    private _exceptionsHandlerService: ExceptionsHandlerService,
    private _localizationService: LocalizationService,
    private _toastr: ToastrService
  ) {
    this._importProfileService.currentImportProfile.subscribe(importProfile => {
      this.type = importProfile.prefix;
    });

    forkJoin([
      this._contextService.currentCustomerDetail.pipe(take(1)),
      this._contextService.isCustomerLevelTenant$.pipe(take(1))
    ]).subscribe(([cd, isCustomerLevelTenant]: [any, boolean]) => {
      this.customerDetailId = cd.id;
      this.isCustomerLevelTenant = isCustomerLevelTenant;
    });

  }
}
