<div class="top">
  <h1>{{ 'pickup_orders' | shpLocalize }}</h1>
  <button class="top-button" *shpPollyDialogTrigger="pollyCollection" id="pickup-faq"><span class="ic-interface-question-mark"></span><span class="label">{{ 'action_open_faq_dialog' | shpLocalize }}</span></button>
</div>
<div class="content-panel pickup-orders parcels">
  <shp-progress-overlay [isActive]="isBusy" [message]="'preparing_pickup_orders' | shpLocalize"></shp-progress-overlay>
  <shp-retry-overlay [isVisible]="hasLoadingFailed"
                       errorMessage="{{ 'error_preparing_pickup_orders' | shpLocalize }}"
                       [reloadFunction]="load"></shp-retry-overlay>
  <ng-container *ngIf="!isBusy && !hasLoadingFailed">
    <shp-regular-pickup-orders-list *ngIf="isSophia" [customerDetailId]="customerDetailId" [customerPickupAddressId]="customerPickupAddressId" [cityserviceAvailable]="cityserviceAvailable"></shp-regular-pickup-orders-list>
    <shp-one-time-pickup-orders-list [isSophia]="isSophia" [customerDetailId]="customerDetailId" [customerPickupAddressId]="customerPickupAddressId" [cityserviceAvailable]="cityserviceAvailable" [canCurrentUserAddPickupOrder]="canCurrentUserAddPickupOrder"></shp-one-time-pickup-orders-list>
  </ng-container>
</div>
