<div class="content-panel">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="recipients-list list">
    <div class="filter">
      <input type="text" id="search-box" class="search-box" [formControl]="filterText" placeholder="{{ 'search_placeholder' | shpLocalize }}" />
    </div>
    <div class="items" [class.multi-select]="isMultiselectMode">
      <div class="select-all-toggle">
        <input type="checkbox" [ngModel]="areAllVisibleSelected" (change)="toggleAllVisibleSelected()" id="select-all-toggle" />
        <label for="select-all-toggle">{{ 'select_all' | shpLocalize }}</label>
        <input type="checkbox" *ngIf="canUndelete" [(ngModel)]="showDeleted" id="show-deleted" />
        <label for="show-deleted" *ngIf="canUndelete">{{ 'recipients_filter_deleted' | shpLocalize }}</label>
      </div>
      <div class="scroll-part" [class.has-pager]="filter?.pageSize < totalCount">
        <div class="list-item recipient" *ngFor="let recipient of recipients | async; let index = index" [class.odd]="index % 2 == 1" [class.selected]="selectedRecipient == recipient">
          <div class="selector" [class.deleted]="recipient.stateId == 'Deleted'">
            <input tabindex="-1" type="checkbox" [ngModel]="recipient.isSelected" (change)="selectionChanged($event, recipient)" id="recipient-is-selected-{{ recipient.id }}" /><label [attr.for]="'recipient-is-selected-' + recipient.id"></label>
          </div>
          <div class="content" tabindex="0" (click)="select(recipient)" (keypress)="handleKeyPress($event, recipient)" id="recipient-list-item-{{ recipient.id }}">
            <span class="recipient-name">{{ recipient.name }}</span>
            <span *ngIf="showRecipientToPickupAddressAsignment && !recipient.addressId" class="recipient-global-marker">G</span>
          </div>
        </div>
      </div>
      <div class="pager-part" *ngIf="filter?.pageSize < totalCount">
        <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="filter?.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
        <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(filter?.pageIndex - 1)" *ngIf="filter?.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
        <button id="button-pager-next" class="next" type="button" (click)="goToPage(filter?.pageIndex + 1)" *ngIf="filter?.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
        <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="filter?.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
        <span class="numbers">{{ filter?.pageIndex * filter?.pageSize + 1 }} - {{ (filter?.pageIndex + 1) * filter?.pageSize < totalCount ? (filter?.pageIndex + 1) * filter?.pageSize : totalCount }}/{{ totalCount }}</span>
      </div>
      <div class="multi-select-commands">
        <button id="button-export-recipients" type="button" class="bottom-button" (click)="exportRecipients()"><span class="ic-data-upload-8"></span><span class="label">{{ 'recipients_export_selected' | shpLocalize }}</span></button>
        <button id="button-delete-recipients" type="button" class="bottom-button" (click)="deleteRecipients()"><span class="ic-bin"></span><span class="label">{{ 'recipients_delete_selected' | shpLocalize }}</span></button>
        <!--<button id="button-cancel-multiselect-operations" type="button" class="bottom-button" (click)="cancelMultiSelectOperations()"><span class="ic-close"></span><span class="label">{{ 'cancel' | shpLocalize }}</span></button>-->
      </div>

      <div class="list-empty-message" *ngIf="noRecipientsInDb">
        <div class="message-content">
          {{ 'recipients_none_in_database' | shpLocalize }}
        </div>
      </div>

      <div class="list-empty-message" *ngIf="noRecipientsMatchingFilter">
        <div class="message-content">
          {{ 'recipients_none_matching_filter' | shpLocalize }}
        </div>
      </div>
    </div>

    <div class="no-item-selected" *ngIf="selectedRecipient == null">
      <div class="message">
        {{ 'recipients_list_select_recipient_advice' | shpLocalize }}
      </div>
    </div>

    <div class="selected-recipient item-overview" *ngIf="selectedRecipient != null">
      <div class="item-commands">
        <ng-container *ngIf="selectedRecipient.stateId != 'Deleted'">
          <button id="button-edit-recipient" class="left-button" type="button" (click)="editRecipient(selectedRecipient)"><span class="ic-file-edit-1"></span><span class="label">{{ 'recipient_edit' | shpLocalize }}</span></button>
          <button id="button-create-shipment" class="left-button" type="button" (click)="createNewShipmentForRecipient(selectedRecipient)"><span class="ic-box-add"></span><span class="label">{{ 'recipient_new_order' | shpLocalize }}</span></button>
          <button id="button-delete-recipient" class="left-button" type="button" (click)="deleteRecipient(selectedRecipient)"><span class="ic-bin" ></span><span class="label">{{ 'recipient_delete' | shpLocalize }}</span></button>
        </ng-container>
        <button *ngIf="selectedRecipient.stateId == 'Deleted' && canUndelete" id="button-undelete-recipient" class="left-button" type="button" (click)="undeleteRecipient(selectedRecipient)"><span class="ic-sign-recycle" ></span><span class="label">{{ 'recipient_undelete' | shpLocalize }}</span></button>
      </div>
      <div class="item-details recipient-details">
        <h1 class="m-2">{{ selectedRecipient.name }}</h1>
        <div class="mdl-grid">
          <div class="ctl mdl-cell mdl-cell--12-col">
            <label>{{ 'recipient_type' | shpLocalize }}</label>
            <input type="text" id="recipient-type" readonly="readonly" value="{{ 'recipient_type_' + selectedRecipient.customerRecipientTypeCode.toLowerCase() | shpLocalize }}" />
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col">
            <label for="recipient-name2">{{ 'recipient_organization_name' | shpLocalize }} 2</label>
            <input type="text" [(ngModel)]="selectedRecipient.name2" readonly="readonly" id="recipient-name2" name="recipient_name2" />
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="selectedRecipient.customerRecipientTypeCode == b2b">
            <label for="recipient-contact-person">{{ 'recipient_organization_contact_name' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.contactPerson" readonly="readonly" id="recipient-contact-person" name="recipient_contactPerson" />
          </div>
          <div class="ctl mdl-cell mdl-cell--8-col">
            <label for="recipient-street">{{ 'recipient_street' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.street" readonly="readonly" id="recipient-street" name="recipient_street" />
          </div>
          <div class="ctl mdl-cell mdl-cell--4-col">
            <label for="recipient-street">{{ 'recipient_house_number' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.houseNr" readonly="readonly" id="recipient-house-nr" name="recipient_house_nr" />
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="selectedRecipient.customerRecipientTypeCode == b2b">
            <label for="recipient-street-detail">{{ 'recipient_street_detail' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.streetDetail" readonly="readonly" id="recipient-street-detail" name="recipient_street_detail" />
          </div>
          <div class="ctl mdl-cell mdl-cell--4-col">
            <label for="recipient-zip">{{ 'recipient_zip' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.zip" readonly="readonly" id="recipient-zip" name="recipient_zip" />
          </div>
          <div class="ctl mdl-cell mdl-cell--8-col">
            <label for="recipient-city">{{ 'recipient_city' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.city" readonly="readonly" id="recipient-city" name="recipient_city" />
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col">
            <label for="recipient-state">{{ 'recipient_state_code' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.stateCode" readonly="readonly" id="recipient-state" name="recipient_state"  maxlength="2" />
          </div>
          <div class="ctl mdl-cell mdl-cell--8-col">
            <label for="recipient-country">{{ 'recipient_country' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.countryName" readonly="readonly" id="recipient-country" name="recipient_country_name" />
          </div>
          <div class="ctl mdl-cell mdl-cell--4-col">
            <label for="recipient-country">{{ 'language' | shpLocalize }}</label>
            <input type="text" value="{{ selectedRecipient.languageCode ? (('language_' + selectedRecipient.languageCode) | shpLocalize) : '-' }}" readonly="readonly" id="recipient-language-code" name="recipient_language_code" />
          </div>
          <div class="ctl mdl-cell mdl-cell--4-col">
            <input [(ngModel)]="selectedRecipient.isUsedAsReceiver" id="is-used-as-receiver-checkbox" type="checkbox" disabled name="is_used_as_receiver_checkbox">
            <label for="is-used-as-receiver-checkbox">{{ 'is_used_as_receiver' | shpLocalize }}</label>
          </div>
          <div class="ctl mdl-cell mdl-cell--4-col">
            <input [(ngModel)]="selectedRecipient.isUsedAsReturnReceiver" id="is-used-as-return-receiver-checkbox" type="checkbox" disabled name="is_used_as_return_receiver_checkbox">
            <label for="is-used-as-return-receiver-checkbox">{{ 'is_used_as_return_receiver' | shpLocalize }}</label>
          </div>
          <div class="ctl mdl-cell mdl-cell--4-col">
            <input [(ngModel)]="selectedRecipient.isUsedAsCollectionRequestSender" id="is-used-as-collection-request-sender-checkbox" type="checkbox" disabled name="is_used_as_collection_request_sender_checkbox">
            <label for="is-used-as-collection-request-sender-checkbox">{{ 'is_used_as_collection_request_sender' | shpLocalize }}</label>
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col">
            <label for="recipient-eori-number">{{ 'recipient_eori' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.eoriNumber"  id="recipient-eori-number" readonly="readonly" name="recipient-reori_number" />
          </div>
          <div class="ctl mdl-cell mdl-cell--6-col">
            <label for="recipient-reference">{{ 'recipient_reference' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.referenceNumber" readonly="readonly" id="recipient-reference" name="recipient_reference" />
          </div>
          <div class="ctl mdl-cell mdl-cell--6-col">
            <label for="recipient-email">{{ 'recipient_email' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.email" id="recipient-email" readonly="readonly" name="recipient_email" />
          </div>
          <div class="ctl mdl-cell mdl-cell--6-col">
            <label for="recipient-phone">{{ 'recipient_phone' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.phone" id="recipient-phone" readonly="readonly" name="recipient_phone" />
          </div>
          <div class="ctl mdl-cell mdl-cell--6-col">
            <label for="recipient-fax">{{ 'recipient_fax' | shpLocalize }}</label>
            <input type="text" [(ngModel)]="selectedRecipient.fax" id="recipient-fax" readonly="readonly" name="recipient_fax" />
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col">
            <label for="recipient-comment">{{ 'recipient_comment' | shpLocalize }}</label>
            <textarea rows="5" [(ngModel)]="selectedRecipient.comment" id="recipient-comment" readonly="readonly" name="recipient_comment"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>