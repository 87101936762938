import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import * as _ from "lodash";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { delayWhen, filter, map, switchMapTo, take, tap, withLatestFrom } from "rxjs/operators";

import { LOGIN_URL } from "../globals";
import { DEMO_USER_LOGIN } from "../models/user.model";
import { NavigationEventEmitter } from "./../navigation-event-emitter";
import { RouteEventEmitter } from "./../route-event-emitter";
import { AuthenticationService } from "./authentication.service";

export enum UserLoginEvent {
  /**
   * Password change (initial, old passoword).
   *
   * NOTE: initial password functionality is using guard to prevent navigation
   * to other page than reset password.
   */
  ChangePassowrd,
  /** Start wizard */
  Wizard,
  /** Start loading newsfeed */
  Newsfeed,
  /** Open popup with change log for current version (if user didn't use it yet). */
  NewVersionInfo,
  /** Open popup informing users that new version can be downloaded (local). */
  NewUpdateAvailable,
  /** Open popup informing users that new version is available (central). */
  NewVersionAvailable
}

interface UserLoginEventState {
  current: UserLoginEvent;
  next: UserLoginEvent[];
  resolved: Set<UserLoginEvent>;
}

const regularUserLoginEventsState: UserLoginEventState = {
  current: UserLoginEvent.ChangePassowrd,
  next: [
    UserLoginEvent.Newsfeed,
    UserLoginEvent.NewVersionAvailable,
    UserLoginEvent.NewVersionInfo,
    UserLoginEvent.NewUpdateAvailable
  ],
  resolved: new Set<UserLoginEvent>()
};

const firstUserLoginEventsState: UserLoginEventState = {
  current: UserLoginEvent.ChangePassowrd,
  next: [
    UserLoginEvent.NewVersionInfo,
    UserLoginEvent.Wizard,
    UserLoginEvent.Newsfeed,
    UserLoginEvent.NewVersionAvailable,
    UserLoginEvent.NewUpdateAvailable
  ],
  resolved: new Set<UserLoginEvent>()
};

const noUserLoginEventsState: UserLoginEventState = {
  current: null,
  next: [],
  resolved: new Set<UserLoginEvent>()
};

@Injectable()
export class GlobalEventsStreamService {
  readonly userLoginEvents$: Observable<UserLoginEventState>;

  navigationEventStream: NavigationEventEmitter;
  routeEventStream: RouteEventEmitter;

  private _areUserLoginEventsPending$ = new BehaviorSubject<boolean>(true);
  private _userLoginEvents$ = new ReplaySubject<UserLoginEventState>(1);

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService) {
    this.navigationEventStream = new NavigationEventEmitter();
    this.routeEventStream = new RouteEventEmitter();

    this.initUserLoginEvents();
    this.userLoginEvents$ = this._userLoginEvents$.asObservable();
  }

  initUserLoginEvents() {
    this.authenticationService.user.pipe(
      tap(() => this._areUserLoginEventsPending$.next(true)),
      filter(u => Boolean(u)),
      // Make sure user was navigated from the login page.
      delayWhen(() => this.router.events.pipe(
          filter(e => e instanceof NavigationEnd && e.url !== LOGIN_URL))),
      withLatestFrom(this.authenticationService.impersonatingShipperAdminUserLogin$),
      map(([user, impersonatingAdminUserLogin]) => {
       let newState: UserLoginEventState;
       switch (true) {
        case Boolean(impersonatingAdminUserLogin):
        case user.login === DEMO_USER_LOGIN:
          newState = noUserLoginEventsState;
          break;
        case user.isFirstLogin:
          newState = firstUserLoginEventsState;
          break;
        default:
          newState = regularUserLoginEventsState;
          break;
       }

       return _.cloneDeep(newState);
      }),
    ).subscribe(events => {
      this._userLoginEvents$.next(events);
      this._areUserLoginEventsPending$.next(false);
    });
  }

  resolveUserLoginEvent(event: UserLoginEvent) {
    this._areUserLoginEventsPending$.pipe(
      filter(v => !v),
      switchMapTo(this._userLoginEvents$),
      take(1),
      filter(state => !state.resolved.has(event)),
      map(state => {
        const next = _.without(state.next, event);
        const current = event === state.current ? next.shift() : state.current;

        return {
          current,
          next,
          resolved: new Set([...state.resolved, event])
        };
      })
    ).subscribe(state => this._userLoginEvents$.next(state));
  }
}
