import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";

import * as _ from "lodash";
import * as moment from "moment";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Subject, Subscription } from "rxjs"; 
import { debounceTime, filter, finalize, map, skip, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { ApiHeartbeatService } from "../../shared/index";
import * as CustomerModels from "../../shared/models/customer.models";
import * as Models from "../models/shipment.model";
import { OrderForGridView } from "../models/shipment.model";
import { CustomClearanceService } from "../services/custom-clearance.service";
import { ShipmentService } from "../services/shipments.service";
import { SetPickupDateDialogComponent } from "./set-pickup-date-dialog.component";


const DEFAULT_SHIPMENTS_PER_PAGE = 20;
const FILTER_STORAGE_KEY = "shipments-filter";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments-compact",
  templateUrl: "./shipments-compact.component.html"
})
export class ShipmentsCompactComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  allowProtocolsPrintingForSelectedShipments = false;
  anyCRExists = false;
  areAllVisibleSelected = false;
  customerReferenceNrLink: string;
  filterText: FormControl = new FormControl("");
  hasLoadError = false;
  isCentralShipper = this._shipperSettingsService.isCentralShipper;
  isCustomerRefUsedForCollectionRequest = false;
  isCustomerService = false;
  isFiltered = false;
  isImpersonatingShipperAdminUser = false;
  isPickupDateVisible = false;
  isPrintReturnLabelActionVisible = false;
  isSophia = false;
  languageCode = Shared.getTrackingLocale(Shared.Localization[this.localizationService.currentLocalization]);
  languageCodeIso = Shared.getTrackingLanguageIso(Shared.Localization[this.localizationService.currentLocalization]);
  lastPageIndex = 0;
  miniportalUrl = "";
  totalShipmentsCount = 0;
  useMiniportalIntegration = false;

  /**
   * Zoznam chipmentov prístupný cez Observable/Subject, ktorý poskytuje
   * úvodnú hodnotu pri každom subscribnuť.
   */
  shipments: BehaviorSubject<Models.OrderForGridView[]> = new BehaviorSubject([]);

  /** Aktuálne vyznačená zásielka/objednávka. */
  selectedShipment: Models.OrderForGridView = null;

  /**
   * Ak je true, tak UI by malo spristupnit
   * checkboxy pri kazdom zazname, aby sa dal
   * oznacit aj viac ako jeden zaznam.
   */
  isMultiselectMode = true;

  /**
   * Aktualne platny filter zaznamov zasielok.
   */
  filter: Models.ShipmentsCompatViewSearchParameters = {
    filter: "",
    orderAscending: false,
    orderByFieldName: "CreationDateTimeUtc",
    pageIndex: 0,
    pageSize: DEFAULT_SHIPMENTS_PER_PAGE,
    customerAddressCustomId: null,
    customerDetailId: null,
    isCollectionRequest: null,
    isNormalRequest: null,
    isStateInLabelsPrintedVisible: null,
    isStateInPreparationVisible: null,
    isStatePreparedForDeliveryVisible: null,
    isStateSentToDPDVisible: null,
    isExported: null
  };


  /** True, ak sa vykonava nieco dlho. UI by malo zobrazit nejaky progress bar. */
  @Input() isBusy = false;

  /** Informacia o tom, co sa deje, ked sa nieco dlho vykonava. */
  @Input() statusMessage = "";


  /**
   * Tu mame ulozenu referenciu na subscription v pripade, ak sa nieco nacitava.
   * Ak zmenime filter a chceme znovu nacitat zoznam, tak vieme s pomocou tejto
   * referencie sa unsubscribnut z uz pribehajuceho nacitavania.
   */
  private _shipmentsSubscription: Subscription = null;

  private _currentAddress: CustomerModels.Address = null;
  private _delisId = "";
  private _tenantId: number = null;
  private _trackingByReferenceUrl = "";
  private _trackingUrl = "";
  private destroy$ = new Subject<void>();


  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _modal: Modal,
    private _shipmentService: ShipmentService,
    private _contextService: Shared.ContextService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _toastr: ToastrService,
    private _heartbeatService: ApiHeartbeatService,
    private _customClearanceService: CustomClearanceService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }

  ngOnInit() {
    this.isSophia = this._shipperSettingsService.isSophia;

    // Načítame filter z local storage.
    this.loadFilter();

    this.filterText.setValue(this.filter.filter);

    this.filterText
    .valueChanges.pipe(
      debounceTime(600)
    ).subscribe(value => {
      let text = value as string;

      if (typeof text === "undefined" || text === null) {
        text = "";
      }

      this.isFiltered = text.length > 0;

      this.filter.filter = text;

      this.loadShipments();
    });


    combineLatest([
      this._contextService.currentAddress.pipe(filter(ca => ca != null)),
      this._contextService.currentCustomerDetail.pipe(filter(cd => cd != null)),
      this._contextService.tenantId,
      this.authenticationService.impersonatingShipperAdminUserLogin$.pipe(map(login => login != null)),
      this.authenticationService.isCustomerService$,
      // Bu settings
      this._businessUnitSettingsService.getCentralTrackingByReferenceUrl(),
      this._businessUnitSettingsService.getTrackingUrl(),
      this._businessUnitSettingsService.getIsCustomerRefUsedForCollectionRequest(),
      this._businessUnitSettingsService.getAllowProtocolsPrintingForSelectedShipmentsSettingValue(),
      this._businessUnitSettingsService.getIsShipmentPickupDateTimeVisible(),
      this._businessUnitSettingsService.getIsPrintReturnLabelActionVisible(),
      this._businessUnitSettingsService.getUseMiniportalIntegration(),
      this._businessUnitSettingsService.getMiniportalUrl()
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([
        currentAddress,
        currentCustomerDetail,
        tenantId,
        isImpersonatingShipperAdminUser,
        isCustomerService,
        // Bu settings
        centralTrackingByReferenceUrl,
        trackingUrl,
        isCustomerRefUsedForCollectionRequest,
        allowProtocolsPrintingForSelectedShipmentsSettingValue,
        isShipmentPickupDateTimeVisible,
        isPrintReturnLabelActionVisible,
        useMiniportalIntegration,
        miniportalUrl,
      ]) => {
      this._currentAddress = currentAddress;
      this._delisId = currentCustomerDetail.delisId;
      this._tenantId = tenantId;
      this.isImpersonatingShipperAdminUser = isImpersonatingShipperAdminUser;
      this.isCustomerService = isCustomerService;

      this._trackingByReferenceUrl = centralTrackingByReferenceUrl;
      this._trackingUrl = trackingUrl;
      this.isCustomerRefUsedForCollectionRequest = isCustomerRefUsedForCollectionRequest;
      this.allowProtocolsPrintingForSelectedShipments = allowProtocolsPrintingForSelectedShipmentsSettingValue;
      this.isPickupDateVisible = isShipmentPickupDateTimeVisible;
      this.isPrintReturnLabelActionVisible = isPrintReturnLabelActionVisible;
      this.useMiniportalIntegration = useMiniportalIntegration;
      this.miniportalUrl = miniportalUrl;

      this.loadShipments();
      this.setAnyCRExists();

      this._shipmentService.shouldRefreshShipmentOrdersOrParcels.pipe(
        takeUntil(this.destroy$)
      ).subscribe(value => {
        if (value) {
          this.loadShipments();
          this.setAnyCRExists();
        }
      });
    });

    this.initAutoRefresh();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  getTrackingByParcelNrUrl(parcelNr: string) {
    return this._trackingUrl
      .replace("{parcelNr}", parcelNr)
      .replace("{languageCode}", this.languageCode)
      .replace("{languageCodeIso}", this.languageCodeIso);
  }


  getTrackingByReferenceUrl(reference: string) {
    return this._trackingByReferenceUrl
      .replace("{delisId}", this._delisId)
      .replace("{reference}", encodeURIComponent(reference).replace("%2F", "~2F"))
      .replace("{languageCode}", this.languageCode)
      .replace("{languageCodeIso}", this.languageCodeIso);
  }


  toggleMultiSelect() {
    this.isMultiselectMode = !this.isMultiselectMode;
  }


  toggleSelection(shipment: Models.OrderForGridView) {
    shipment.isSelected = !shipment.isSelected;

    this.checkAreAllVisibleSelected();
  }


  selectionChanged(shipment: Models.OrderForGridView) {
    this.toggleSelection(shipment);
  }


  private checkAreAllVisibleSelected() {
    this.areAllVisibleSelected = this.shipments.getValue().every(s => s.isSelected);
  }


  toggleAllVisibleSelected() {
    this.areAllVisibleSelected = !this.areAllVisibleSelected;
    this.shipments.getValue().forEach(s => s.isSelected = this.areAllVisibleSelected);
  }


  cancelMultiSelectOperations() {
    this.isMultiselectMode = false;
  }


  private setShipmentHasCod(order: Models.OrderForGridView) {
    // Medzi skratkami sluzieb mame retazec COD (overujeme vsetko male pismena).
    order.hasCod = order.servicesText.toLowerCase().indexOf("cod") >= 0;
  }

  filterValueChanged() {
    window.setTimeout(() => this.loadShipments(), 10);
  }


  private loadShipments() {
    if (this._currentAddress == null) {
      return;
    }

    // Uložíme si aktuálny filter, aby sme ho mohli použiť po návrate na túto stránku.
    this.saveFilter();

    // Ak nieco nacitavame, tak to zrusime (presnejsie, budeme ignorovat vysledok, kedze request sa zrusit neda.
    this._shipmentsSubscription?.unsubscribe();

    this.hasLoadError = false;
    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_loading_shipment_orders");

    this.filter.customerDetailId = this._currentAddress.customerDetailId;
    this.filter.customerAddressCustomId = this._currentAddress.customId;

    this._shipmentsSubscription = this._shipmentService.searchShipments(this.filter).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe((shipments: Shared.PagedResult<Models.OrderForGridView>) => {
      if (this.selectedShipment != null) {
        this.selectedShipment = shipments.items.find(shipment => shipment.id === this.selectedShipment.id);
      }

      shipments.items.forEach(s => {
        this.setShipmentHasCod(s);
      });

      this.shipments.next(shipments.items);

      this.totalShipmentsCount = shipments.totalSize;
      this.lastPageIndex = Math.ceil(this.totalShipmentsCount / this.filter.pageSize) - 1;

      this.checkAreAllVisibleSelected();

      if (this.totalShipmentsCount > 0 && this.totalShipmentsCount <= this.filter.pageIndex * this.filter.pageSize) {
        this.goToPage(this.lastPageIndex);
      }
    }, (ex) => {
      this.hasLoadError = true;
      this.loggingService.logErrorData(ex, "Error loading shipments");
    });
  }


  select(shipment: Models.OrderForGridView) {
    this.selectedShipment = shipment;
  }


  handleShipmentKeyPress(event: KeyboardEvent, shipment: Models.OrderForGridView) {
    if (!event.altKey && !event.ctrlKey && event.keyCode === 13) {
      this.select(shipment);
    }
  }


  goToPage(pageIndex: number) {
    this.filter.pageIndex = pageIndex;
    this.loadShipments();
  }


  /**
   * Finds shipmnet by id from loaded shipmnets.
   */
  private findShipmentById(id: number): Models.OrderForGridView {
    return this.shipments.value.find(shipment => shipment.id === id);
  }


  duplicateShipmentOrder(order: Models.OrderForGridView) {
    this._shipmentService.duplicateShipmentOrder(this._currentAddress.customerDetailId, order.id).subscribe((newOrderId: number) => {
      // Dostali sme späť identifikátor duplikátu, tak zobrazíme editáciu.
      this.viewOrEditShipmentOrderById(newOrderId);
    }, ex => {
      this.loggingService.logErrorData(ex, "Order duplication failed.");

      const exception = this._exceptionsHandlerService.getExceptionInfo(ex);

      this._toastr.error(this.localizationService.getLocalizedExceptionString(exception));
    });
  }


  private getSelectedShipmentOrderIds(excludeCr: boolean) {
    return this.shipments.getValue()
      .filter(s => s.isSelected && (!excludeCr || !s.isCollectionRequest))
      .map(s => s.id);
  }


  private getSelectedCrOrdersIds(): number[] {
    return this.shipments.getValue()
      .filter(order => order.isSelected && order.isCollectionRequest)
      .map(order => order.id);
  }


  exportShipmentOrder(order: Models.OrderForGridView) {
    this.exportImpl([order.id]);
  }


  exportSelected() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(false);

    if (!selectedOrderIds.length) {
      return;
    }

    this.exportImpl(selectedOrderIds);
  }


  private exportImpl(selectedOrderIds: number[]) {
    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_shipment_orders_csv_export");

    this._shipmentService.exportParcelsAsMultipleCsv({
      customerDetailId: this._currentAddress.customerDetailId,
      tenantId: this._tenantId,
      daysOffset: 365,
      exportAllShipments: false,
      manualExportShipmentsSetType: null,
      orderIds: selectedOrderIds,
      parcelIds: null,
      rememberShipmentsWereExported: false,
      senderCustomId: this._currentAddress.customId,
      csvColumnDelimiter: null,
      csvRowDelimiter: null,
      importProfileId: null,
      defaultProductId: null
    }).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => { }, () => { });
  }


  printCodTakeoverProtocolForCurrent() {
    if (!this.selectedShipment?.id) {
      return;
    }

    this.printCodTakeoverProtocol([this.selectedShipment.id]);
  }


  printCodTakeoverProtocolForSelected() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(true);

    if (!selectedOrderIds.length) {
      return;
    }

    this.printCodTakeoverProtocol(selectedOrderIds);
  }


  printCodTakeoverProtocol(forOrderIds: number[]) {
    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_cod_takeover_protocol");

    this._shipmentService.printCodTakeoverProtocolForOrderIds(forOrderIds).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.loadShipments(), () => { });
  }

  printAcceptanceProtocolForCurrent() {
    if (!this.selectedShipment?.id) {
      return;
    }

    this.printAcceptanceProtocol([this.selectedShipment.id]);
  }


  printAcceptanceProtocolForSelected() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(true);

    if (!selectedOrderIds.length) {
      return;
    }

    this.printAcceptanceProtocol(selectedOrderIds);
  }


  printAcceptanceProtocol(forOrderIds: number[]) {
    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_acceptance_protocol");

    this._shipmentService.printAcceptanceProtocolForOrderIds(forOrderIds).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.loadShipments(), () => { });
  }


  printCrOrderConfirmationProtocolForCurrent() {
    if (!this.selectedShipment?.id) {
      return;
    }

    this.printCrOrderConfirmationProtocol([this.selectedShipment.id]);
  }


  printCrOrderConfirmationProtocolForSelected() {
    const selectedOrderIds = this.getSelectedCrOrdersIds();

    if (!selectedOrderIds.length) {
      return;
    }

    const areAllExported = this.shipments.getValue().every(order => {
      return !order.isSelected || (order.isSelected && order.isCollectionRequest && order.exportDateTimeUtc != null);
    });

    if (!areAllExported) {
      this._toastr.error(
        this.localizationService.getLocalizedString("cr_order_confirmation_protocol_print_cr_not_exported")
      );

      return;
    }

    this.printCrOrderConfirmationProtocol(selectedOrderIds);
  }


  printCrOrderConfirmationProtocol(orderIds: number[]) {
    this.isBusy = true;

    this._shipmentService.printCrOrderConfirmationProtocol(orderIds).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.loadShipments(), () => { });
  }


  printLabelsForSelected() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(true);

    if (!selectedOrderIds.length) {
      return;
    }

    this.printLabels(selectedOrderIds);
  }


  printLabelsForCurrent() {
    if (!this.selectedShipment?.id) {
      return;
    }

    this.printLabels([this.selectedShipment.id]);
  }

  printLabels(orderIds: number[]) {
    const shipment = orderIds.length === 1 ? this.findShipmentById(orderIds[0]) : null;
    const FilenameParcelNr = shipment && shipment.parcels.length === 1 ? shipment.parcels[0].parcelNr + "_" : "";
    const filename = `${FilenameParcelNr}${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`;

    this._shipmentService.printLabelsForOderIds(
      orderIds,
      filename,
      () => {
        this.isBusy = true;
        this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_labels");
      }
    ).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.loadShipments(), () => { });
  }


  printReturnLabelsForCurrent() {
    if (!this.selectedShipment?.id) {
      return;
    }

    this.printReturnLabels([this.selectedShipment.id]);
  }


  printReturnLabelsForSelected() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(true);

    if (!selectedOrderIds.length) {
      return;
    }

    this.printReturnLabels(selectedOrderIds);
  }


  printReturnLabels(orderIds: number[]) {
    this._shipmentService.printReturnLabelsForOrderIds(
      orderIds,
      () => this.isBusy = true
    ).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.loadShipments(), () => { });
  }


  deleteSelected() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(false);

    if (selectedOrderIds.length === 0) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("shipments_order_delete_confirmation_question"))
      .open()
      .result.then(value => {
        if (value) {
          this.isBusy = true;

          this.statusMessage = (selectedOrderIds.length === 1) ?
            this.localizationService.getLocalizedString("deleting_shipment_order") :
            this.localizationService.getLocalizedString("deleting_shipment_orders");

          this._shipmentService.deleteOrders(selectedOrderIds)
            .subscribe(
              () => {
                this._toastr.success(this.localizationService
                  .getLocalizedString(this.isSophia ? "deleting_shipment_orders_not_printed_labels_successful" : "deleting_shipment_orders_successful"));

                this._shipmentService.notifyShouldRefreshShipmentOrdersOrParcels();
                this.setAnyCRExists();

                this.isBusy = false;
              },
              ex => {
                this.loggingService.logErrorData(ex, "Error deleting shipment orders.");

                this._toastr.error(`${this.localizationService.getLocalizedString("error_deleting_shipment_orders")}:\n${this.localizationService.getLocalizedExceptionString(ex)}`);

                this.isBusy = false;
              });

        }
      });
  }


  undeleteSelected() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(false);

    if (selectedOrderIds.length === 0) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("shipments_order_undelete_confirmation_question"))
      .open()
      .result.then(value => {
        if (value) {
          this.isBusy = true;

          this.statusMessage = (selectedOrderIds.length === 1) ?
            this.localizationService.getLocalizedString("undeleting_shipment_order") :
            this.localizationService.getLocalizedString("undeleting_shipment_orders");

          this._shipmentService.undeleteOrders(selectedOrderIds)
            .subscribe(
              () => {
                this._toastr.success(this.localizationService
                  .getLocalizedString("undeleting_shipment_orders_successful"));

                this._shipmentService.notifyShouldRefreshShipmentOrdersOrParcels();
                this.setAnyCRExists();

                this.isBusy = false;
              },
              ex => {
                this.loggingService.logErrorData(ex, "Error undeleting shipment orders.");

                this._toastr.error(this.localizationService.getLocalizedString("error_undeleting_shipment_orders"));

                this.isBusy = false;
              });

        }
      });
  }


  delete(order: Models.OrderForGridView) {
    if (typeof order === "undefined" || order == null) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("shipment_order_delete_confirmation_question"))
      .open()
      .result.then(
        value => {
          if (value) {
            this.isBusy = true;
            this.statusMessage = this.localizationService.getLocalizedString("deleting_shipment_order");

            this._shipmentService.deleteOrder(order.id)
              .subscribe(
                () => {
                  this._toastr.success(this.localizationService.getLocalizedString("deleting_shipment_order_successful"));

                  if (this.selectedShipment === order) {
                    this.selectedShipment = null;
                  }

                  this._shipmentService.notifyShouldRefreshShipmentOrdersOrParcels();

                  this.setAnyCRExists();
                  this.isBusy = false;
                },
                ex => {
                  this.loggingService.logErrorData(ex, "Error deleting shipment order.");

                  this._toastr.error(`${this.localizationService.getLocalizedString("error_deleting_shipment_order")}:\n${this.localizationService.getLocalizedString(ex.key)}`);


                  this.isBusy = false;
                });
          }
        },
        () => {

        });
  }


  undelete(order: Models.OrderForGridView) {
    if (typeof order === "undefined" || order == null) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("shipment_order_undelete_confirmation_question"))
      .open()
      .result.then(
        value => {
          if (value) {
            this.isBusy = true;
            this.statusMessage = this.localizationService.getLocalizedString("undeleting_shipment_order");

            this._shipmentService.undeleteOrder(order.id)
              .subscribe(
                () => {
                  this._toastr.success(this.localizationService.getLocalizedString("undeleting_shipment_order_successful"));

                  if (this.selectedShipment === order) {
                    this.selectedShipment = null;
                  }

                  this._shipmentService.notifyShouldRefreshShipmentOrdersOrParcels();

                  this.setAnyCRExists();
                  this.isBusy = false;
                },
                ex => {
                  this.loggingService.logErrorData(ex, "Error undeleting shipment order.");

                  this._toastr.error(this.localizationService.getLocalizedString("error_undeleting_shipment_order"));

                  this.isBusy = false;
                });
          }
        },
        () => {

        });
  }

  canDelete(order: Models.OrderForGridView) {
    if (typeof order === "undefined" || order == null) {
      return false;
    }

    if (!this.isSophia || this.isImpersonatingShipperAdminUser) {
      return true;
    }

    if (order.stateId === "Ordered") {
      return false;
    }

    const parcels = order.parcels;
    const someParcelHaveGeneratedLabel = parcels.some(p => (p.stateId !== "Deleted" && p.isLabelGenerated === true));

    return !someParcelHaveGeneratedLabel;
  }


  viewOrEditShipmentOrder(order: Models.OrderForGridView) {
    this.viewOrEditShipmentOrderById(order.id);
  }


  viewOrEditShipmentOrderById(id: number) {
    this.router.navigate(["/shipments/", id]);
  }


  private getEmptyFilter(): Models.ShipmentsCompatViewSearchParameters {
    return {
      customerAddressCustomId: null,
      customerDetailId: null,
      filter: "",
      isCollectionRequest: false,
      isNormalRequest: false,
      isStateInLabelsPrintedVisible: false,
      isStateInPreparationVisible: false,
      isStatePreparedForDeliveryVisible: false,
      isStateSentToDPDVisible: false,
      orderAscending: false,
      orderByFieldName: "CreationDateTimeUtc",
      pageIndex: 0,
      pageSize: DEFAULT_SHIPMENTS_PER_PAGE,
      isExported: null
    };
  }


  private loadFilter() {
    const filterJson = localStorage.getItem(FILTER_STORAGE_KEY);

    if (filterJson) {
      const filter = JSON.parse(filterJson);

      this.filter = filter;
    } else {
      this.filter = this.getEmptyFilter();
    }
  }


  private saveFilter() {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(this.filter));
  }


  private setAnyCRExists() {
    if (!this._currentAddress) {
      return;
    }

    this._shipmentService.getAnyCRExists(this._currentAddress.customerDetailId, this._currentAddress.customId).subscribe(exists => {
      this.anyCRExists = exists;

      // Ak sme mali vyznačené, že chceme vidieť iba CRka a žiadne už nemáme, tak resetneme filter tak, aby sme vedeli vidieť normálne
      // zásielky a vyvoláme ich načítanie.
      if (!exists && this.filter.isCollectionRequest && !this.filter.isNormalRequest) {
        this.filter.isCollectionRequest = false;
        this.filter.isNormalRequest = false;

        window.setTimeout(() => { this.loadShipments(); }, 100);
      }
    });
  }


  private getIsAnySelectedShipmentOrderOrderCr() {
    return this.shipments.getValue().some(s => s.isSelected && s.isCollectionRequest);
  }


  setPickupDate() {
    const selectedOrderIds = this.getSelectedShipmentOrderIds(false);
    const isAnyShipmentOrderCr = this.getIsAnySelectedShipmentOrderOrderCr();

    if (!selectedOrderIds.length) {
      return;
    }

    this._modal.open(
      SetPickupDateDialogComponent,
      overlayConfigFactory(new Models.SetPickupDateDialogModalContext(selectedOrderIds, isAnyShipmentOrderCr))
    ).result.then(refresh => {
      if (refresh) {
        this.loadShipments();
      }
    }, () => { });
  }


  /**
   * Observes total shipments count changes from heartbeat service.
   * Reloads shipments if value is different from current total count.
   * Only runs when no filter is applied.
   */
  private initAutoRefresh() {
    this._heartbeatService.shipmentsCount$.pipe(
      /** Ignore first emit. The list is loaded. */
      skip(1),
      takeUntil(this.destroy$),
      filter(count => !this.isFiltered && count !== this.totalShipmentsCount)
    ).subscribe(() => {
      this._shipmentService.notifyShouldRefreshShipmentOrdersOrParcels();
    });
  }

  isCrOrderConfirmationProtocolPrintButtonVisible(selectedShipment: OrderForGridView) {
    return this.allowProtocolsPrintingForSelectedShipments
      && selectedShipment.isCollectionRequest
      && selectedShipment.exportDateTimeUtc != null;
  }

  openMiniportal() {
    this._customClearanceService.getMiniportalToken(this.selectedShipment.id).subscribe(token => {
      const url = this.miniportalUrl + token;
      window.open(url, "_blank");
    });
  }
}
