import { Component, Input } from "@angular/core"

import { SELECTOR_PREFIX } from "../prefix";


const ANIMATION_LENGTH = 300; // Treba synchronizovať s CSSkom.


@Component({
  selector: SELECTOR_PREFIX + "-progress-overlay",
  templateUrl: "./progress-overlay.component.html"
})
export class ProgressOverlayComponent {
  private _isActive: boolean = false;
  private _message: string = "";
  private _isFullscreen: boolean = false;

  public isAnimating: boolean = false;

  private _animationTimeout = null;



  @Input()
  public set isActive(active: boolean) {
    if (active) {
      this._isActive = active;

      if (this._animationTimeout) {
        clearTimeout(this._animationTimeout);
        this._animationTimeout = null;
      }

      this._animationTimeout = window.setTimeout(() => {
        this.isAnimating = true;
      }, 0);
    } else {
      if (this._animationTimeout) {
        clearTimeout(this._animationTimeout);
        this._animationTimeout = null;
      }

      this.isAnimating = false;

      this._animationTimeout = window.setTimeout(() => {
        this._isActive = active;
      }, ANIMATION_LENGTH);
    }
  }


  public get isActive(): boolean {
    return this._isActive;
  }


  @Input()
  public set message(message: string) {
    this._message = message;
  }


  public get message() {
    return this._message;
  }


  @Input()
  public set isFullscreen(isFullscreen: boolean) {
    this._isFullscreen = isFullscreen;
  }


  public get isFullscreen() {
    return this._isFullscreen;
  }
}