/// <reference types="resize-observer-browser" />
import { ComponentPortal, DomPortalOutlet } from "@angular/cdk/portal";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { DOCUMENT } from "@angular/common";
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector, NgZone, Renderer2, RendererFactory2 } from "@angular/core";

import * as _ from "lodash";
import { fromEvent, interval, merge, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, filter, map, take, tap } from "rxjs/operators";

import { WizardOverlayComponent } from "../components/wizard-overlay.component";
import { WizardBoundries, WizardCssClass, WizardOverlayOffest} from "../models";
import { getFisrtItemFromSet, hasRectArea, isElementClippedByScrolling, isElementScrolledToStart } from "../utils";

export enum WizardOverlayEvent {
  TargetAttached,
  Render,
  PositionChange,
  AutoScrollStart,
  AutoScrollEnd
}

interface WizardOverlayRect {
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface OverlayPositionInfo {
  targetRect: WizardOverlayRect;
  overlayRect: WizardOverlayRect;
  topReferenceElement: HTMLElement;
}

const emptyOverlayRect: Readonly<WizardOverlayRect> = {
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const emptyOverlayInfo: Readonly<OverlayPositionInfo> = {
  targetRect: {...emptyOverlayRect},
  overlayRect: {...emptyOverlayRect},
  topReferenceElement: null
};

export const defaultOverlayBoundries: Readonly<WizardBoundries> = {
  top: 0,
  bottom: 0
};

const defaultOverlayOffset: Readonly<WizardOverlayOffest> = {
  x: 5,
  y: 5,
  top: 5,
  right: 5,
  bottom: 5,
  left: 5,
  width: 0,
  height: 0
};

@Injectable({
  providedIn: "root"
})
export class WizardOverlayService {
  private readonly _events$ = new Subject<WizardOverlayEvent>();
  private readonly render$ = new Subject<void>();
  private readonly window: Window;

  // private mutationObserver: MutationObserver = new MutationObserver(() => this.render$.next());
  private _boundries: WizardBoundries = {...defaultOverlayBoundries};
  private _hasDisplayedArea = false;
  private _isAutoScrolling: boolean;
  private _overlayPositionInfo: OverlayPositionInfo = {...emptyOverlayInfo};
  private _targets: Set<HTMLElement> = new Set();
  private isScrolling = false;
  private offset: WizardOverlayOffest = {...defaultOverlayOffset};
  private overlay: DomPortalOutlet = null;
  private overlayComponentRef: ComponentRef<WizardOverlayComponent> = null;
  private renderer: Renderer2;
  private resizeObserver: ResizeObserver = null;
  private subscription: Subscription;

  constructor(
      private injector: Injector,
      @Inject(DOCUMENT) private document: Document,
      private componentFactoryResolver: ComponentFactoryResolver,
      private appRef: ApplicationRef,
      private ngZone: NgZone,
      private scrollDispatcher: ScrollDispatcher) {
    this.window = document.defaultView;
    this.renderer = injector.get(RendererFactory2).createRenderer(null, null);

    this.initReziseObserver();
  }

  get hasDisplayedArea(): boolean {
    return this._hasDisplayedArea;
  }

  get isAutoScrolling(): boolean {
    return this._isAutoScrolling;
  }

  get events$(): Observable<WizardOverlayEvent> {
    return this._events$.asObservable();
  }

  get hasTargets(): boolean {
    return Boolean(this._targets.size);
  }

  get hasSingleTarget(): boolean {
    return this._targets.size === 1;
  }

  get overlayPositionInfo(): Readonly<OverlayPositionInfo> {
    return this._overlayPositionInfo;
  }

  private get overlayComponent(): WizardOverlayComponent {
    return this.overlayComponentRef?.instance;
  }

  attachTarget(elements: Iterable<HTMLElement>) {
    const elementsSet = new Set([...elements]);

    // Elements in the set are same.
    if (this._targets.size === elementsSet.size &&
        [...elements].every(e => this._targets.has(e))) {
      return;
    }

    if (this.hasTargets) {
      this.disposeTarget();
    }

    if (!elementsSet.size) {
      this.render();
      this.unobserveGlobalChanges();
      return;
    }

    this._targets = elementsSet;

    this.observeGlobalChanges();

    if (this.overlayComponent) {
      this.overlayComponent.isTransitionAnimated = this.hasDisplayedArea;
    }

    this._targets.forEach(element => {
      this.resizeObserver?.observe(element);
      // this.mutationObserver.observe(element, {subtree: true, childList: true});
      this.renderer.addClass(element, WizardCssClass.OverlayTarget);
    });

    this._events$.next(WizardOverlayEvent.TargetAttached);

    this._events$.pipe(
      filter(e => e === WizardOverlayEvent.Render),
      take(1)
    ).subscribe(() => this.scrollIntoView());
  }

  disposeTarget() {
    this.resizeObserver?.disconnect();
    // this.mutationObserver.disconnect();
    this._targets.forEach(element => {
      this.renderer.removeClass(element, WizardCssClass.OverlayTarget);
    });

    this._targets = new Set();
  }

  attach() {
    if (this.overlay) {
      return;
    }

    const bodyPortalOutlet = new DomPortalOutlet(
      this.document.body, this.componentFactoryResolver, this.appRef, this.injector);

    this.overlayComponentRef = bodyPortalOutlet.attach(new ComponentPortal(WizardOverlayComponent));
    this.overlay = bodyPortalOutlet;
  }

  detach() {
    this.disposeTarget();
    this.unobserveGlobalChanges();
    this.overlay?.detach();
    this.overlay = null;
    this.overlayComponentRef = null;
    this._overlayPositionInfo = {...emptyOverlayInfo};
    this._hasDisplayedArea = false;
  }

  setBoundries(boundries: Partial<WizardBoundries>) {
    this._boundries = {
      ...defaultOverlayBoundries,
      ...boundries
    };

    this.render$.next();
  }

  setOffset(offset: number | WizardOverlayOffest) {
    if (offset == null) {
      return;
    }

    if (typeof offset === "number") {
      this.offset.right = this.offset.left = this.offset.top = this.offset.bottom = offset;
      this.render$.next();
      return;
    }

    if (typeof offset.x !== "undefined") {
      offset.right ??= offset.x;
      offset.left ??= offset.x;
    }

    if (typeof offset.y !== "undefined") {
      offset.top ??= offset.y;
      offset.bottom ??= offset.y;
    }

    this.offset = {
      ...defaultOverlayOffset,
      ...offset
    };

    this.render$.next();
  }

  render() {
    if (!this.overlayComponentRef) {
      return;
    }

    this.setOverlayRect();

    /**
     * Prevent render when target has no area displayed. This often happens during
     * navigation or due to parents with display: none;.
     */
    if (this.hasTargets && !hasRectArea(this._overlayPositionInfo.targetRect)) {
      return;
    }

    const el = this.overlayComponentRef.location.nativeElement;
    const overlayRect = this._overlayPositionInfo.overlayRect;

    this.ngZone.run(() => {
      this._hasDisplayedArea = hasRectArea(overlayRect);

      this.overlayComponent.isTransitionAnimated =
          this.overlayComponent.isTransitionAnimated && this.hasDisplayedArea;
    });

    this.renderer.setStyle(el, "width", overlayRect.width + "px");
    this.renderer.setStyle(el, "height", overlayRect.height + "px");
    this.renderer.setStyle(
      el, "transform", `translate3d(${overlayRect.left}px, ${overlayRect.top}px, 0px)`);

    this._events$.next(WizardOverlayEvent.Render);
  }

  scrollIntoView() {
    if (!this.hasTargets) {
      return;
    }

    this.setTargetRect();
    this.setTopReferenceElement();

    if (!hasRectArea(this._overlayPositionInfo.targetRect) ||
        !this._overlayPositionInfo.topReferenceElement) {
      return;
    }

    const el = this._overlayPositionInfo.topReferenceElement;
    const elRect = el.getBoundingClientRect();
    const scrollContainerRects = this.scrollDispatcher
      .getAncestorScrollContainers(el)
      .map(scrollable => scrollable.getElementRef().nativeElement.getBoundingClientRect());

    const isClippedByScrolling = isElementClippedByScrolling(elRect, scrollContainerRects);
    const isScrolledToStart = isElementScrolledToStart(elRect, scrollContainerRects);

    if (!isClippedByScrolling || isScrolledToStart) {
      return;
    }

    this._isAutoScrolling = true;

    let sub = this.scrollDispatcher.scrolled(25).pipe(
      filter(() => this._isAutoScrolling),
      debounceTime(50)
    ).subscribe(() => {
      this._isAutoScrolling = false;
      sub.unsubscribe();
      sub = null;
      this._events$.next(WizardOverlayEvent.AutoScrollEnd);
    });

    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this._events$.next(WizardOverlayEvent.AutoScrollStart);
  }

  private initReziseObserver() {
    // @todo - install polyfill
    if ("ResizeObserver" in this.window) {
      this.resizeObserver = new ResizeObserver(() => this.render$.next());
    }
  }

  private observeGlobalChanges() {
    if (this.subscription) {
      return;
    }

    this.subscription = new Subscription();

    this.subscription.add(
      this.scrollDispatcher.scrolled(25).pipe(
        tap(() => this.isScrolling = true),
        debounceTime(50)
      ).subscribe(() => this.isScrolling = false)
    );

    this.ngZone.runOutsideAngular(() => {
      this.subscription.add(
        interval(500).pipe(
          filter(() => !this.isScrolling),
          map(() => this.computeTargetRect()),
          filter(rect => this._overlayPositionInfo.targetRect.top !== rect.top ||
            this._overlayPositionInfo.targetRect.left !== rect.left),
          tap(() => this._events$.next(WizardOverlayEvent.PositionChange))
        ).subscribe(() => this.render$.next())
      );

      this.subscription.add(
        merge(
          this.render$.pipe(debounceTime(0)),
          fromEvent(this.window, "resize"),
          fromEvent(this.window, "orientationchange"),
          this.scrollDispatcher.scrolled(0)
        ).subscribe(() => this.render())
      );
    });
  }

  private unobserveGlobalChanges() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  private setOverlayRect() {
    this.setTargetRect();
    const targetRect = this._overlayPositionInfo.targetRect;

    if (!hasRectArea(targetRect)) {
      this._overlayPositionInfo = {...emptyOverlayInfo};
      return;
    }

    const overlayRect = {...emptyOverlayRect};

    const innerTop = this.clampVerticalBorder(targetRect.top);
    const innerBottom = this.clampVerticalBorder(targetRect.bottom + this.offset.height);
    const innerHeight = innerBottom - innerTop;

    overlayRect.top = innerTop - this.offset.top;
    overlayRect.right = targetRect.right + this.offset.right;
    overlayRect.bottom = innerBottom ? innerBottom + this.offset.bottom : 0;
    overlayRect.left = targetRect.left - this.offset.left;
    overlayRect.width = targetRect.width + this.offset.width + this.offset.left + this.offset.right;
    overlayRect.height = innerHeight ? innerHeight + this.offset.top + this.offset.bottom : 0;

    this._overlayPositionInfo.overlayRect = overlayRect;
  }

  private setTargetRect() {
    this._overlayPositionInfo.targetRect = this.computeTargetRect();
  }

  private setTopReferenceElement() {
    if (this.hasSingleTarget) {
      const el = getFisrtItemFromSet(this._targets);
      this._overlayPositionInfo.topReferenceElement = el;
      return;
    }

    this._targets.forEach(element => {
      const rect = element.getBoundingClientRect();

      if (rect.top === this._overlayPositionInfo.targetRect.top) {
        this._overlayPositionInfo.topReferenceElement = element;
      }
    });
  }

  private computeTargetRect(): WizardOverlayRect {
    if (!this.hasTargets) {
      return {...emptyOverlayRect};
    }

    if (this.hasSingleTarget) {
      const el = getFisrtItemFromSet(this._targets);
      return el.getBoundingClientRect();
    }

    let top: number;
    let right: number;
    let bottom: number;
    let left: number;

    this._targets.forEach(element => {
      const rect = element.getBoundingClientRect();
      top = Math.min(top ?? rect.top, rect.top);
      right = Math.max(right ?? rect.right, rect.right);
      bottom = Math.max(bottom ?? rect.bottom, rect.bottom);
      left = Math.min(left ?? rect.left, rect.left);
    });

    return {
      top, left, bottom, right, width: right - left, height: bottom - top
    };
  }

  private clampVerticalBorder(value: number): number {
    return Math.max(this._boundries.top, Math.min(value, this.window.innerHeight - this._boundries.bottom));
  }
}
