import { Component, AfterContentInit, AfterViewInit, EventEmitter, Inject, OnDestroy } from "@angular/core"
import { Router, CanDeactivate } from "@angular/router"

import * as Shared from "./index"


export abstract class RoutedPageComponentBase {
  /**
   * Funkcia, ktorú môžu overridovať dediace komponenty a slúži
   * na identifikáciu, či máme neuložené zmeny a treba
   * @returns boolean
   */
  public hasUnsavedChanges: () => boolean = () => {
    return false;
  };


  /**
   * Overridovateľná funkcia, ktorá definuje, či komponent
   * potrebuje, aby bol používteľ autentifikovaný.
   * @returns {}
   */
  public requiresAuthenticatedUser() {
    return true;
  }


  private handleWindowUnload(e: BeforeUnloadEvent) {
    if (this.hasUnsavedChanges()) {
      e.returnValue = this.localizationService.getLocalizedString("unsaved_changes_confirm_navigation_question");

      return e.returnValue;
    }

    return undefined;
  }


  public removeHandleWindowUnload() {
    window.onbeforeunload = null;
  }


  constructor(
    protected loggingService: Shared.LoggingService,
    protected globalEventsStreamService: Shared.GlobalEventsStreamService,
    protected localizationService: Shared.LocalizationService,
    protected authenticationService: Shared.AuthenticationService,
    protected router: Router
  ) {
    // Zavesíme sa na windows.
    window.onbeforeunload = this.handleWindowUnload.bind(this);
  }
}
