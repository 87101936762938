import { Component } from "@angular/core";

import { DialogRef, ModalComponent } from "ngx-modialog-7";

import * as Shared from "../../shared/index";

import * as _ from "lodash";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-printing-position-selector-dialog",
  templateUrl: "./printing-position-selector-dialog.component.html"
})
export class PrintingPositionSelectorDialogComponent implements ModalComponent<any> {
  private _selectedPosition = 1;
  
  //public set selectedPosition(value: number) {
  //  this._selectedPosition = value;
  //}


  public selectPosition(position: number) {
    this._selectedPosition = position;
  }


  public get selectedPosition() {
    return this._selectedPosition;
  }


  public select() {
    this.dialog.close(this._selectedPosition);
  }


  public cancel() {
    this.dialog.close(null);
  }


  constructor(
    public dialog: DialogRef<any>
  ) {

  }
}