import { Pipe, PipeTransform } from "@angular/core";
import { SELECTOR_PREFIX } from "../../shared/index";
import { Address, CustomerDetail } from "../../shared/models/customer.models";


@Pipe({
  name: SELECTOR_PREFIX + "AddressId"
})
export class AddressIdPipe implements PipeTransform {
  public transform(value, ...args: any[]) {
    if (typeof value === "undefined" || value == null) {
      return null;
    }


    if (!isNaN(value)) {
      // Ako prvý argument očakávame zoznam customer detailov, v ktorých hľadáme ten s ID z value.
      // V druhom potrebujeme customer detaily kvôli DelisID.
      let id = +value;

      let addresses: Address[] = args[0];
      let customerDetails: CustomerDetail[] = args[1];

      if (addresses) {
        let address = addresses.find(a => a.id === id);

        if (address) {

          // Ak nemáme customerDetails, tak nezobrazujeme delisId
          if (customerDetails) {
            let customerDetail = customerDetails.find(cd => cd.id === address.customerDetailId);
            return `${address.name1} (${customerDetail.delisId}), ${address.street} ${address.houseNr}, ${address.place}, ${address.countryCode}-${address.zip}`;
          } else {
            return `${address.name1}, ${address.street} ${address.houseNr}, ${address.place}, ${address.countryCode}-${address.zip}`;
          }
        }
      }
    }

    return null;
  }
}