<div class="top" wizardTag="importProfileEditorToolBar">
  <h1>{{ typePrefix + '_import_profile' | shpLocalize }}</h1>
  <button id="button-back" class="top-button" [disabled]="isWizardActive" (click)="goBackToList()"><span class="ic-arrow-left-1"></span><span class="label">{{ 'recipient_back_to_list' | shpLocalize }}</span></button>

  <button id="button-save" *ngIf="!viewOnly" [disabled]="isWizardActive ? !isSaveButtonEnabledForWizard : !hasUnsavedChanges()" class="top-button" (click)="onSave()" wizardTag="saveImportProfile"><span class="ic-floppydisk"></span><span class="label">{{'import_profiles_btn_save' | shpLocalize}}</span></button>
  <button id="button-delete" *ngIf="!viewOnly && importProfileId != null && !isWizardActive" class="top-button" (click)="onDelete()"><span class="ic-bin"></span><span class="label">{{'import_profiles_btn_delete' | shpLocalize}}</span></button>
  <button id="button-check" *ngIf="!viewOnly && storeId != null && !isWizardActive" class="top-button" (click)="onCheck()"><span class="ic-trash"></span>{{'import_profiles_btn_check' | shpLocalize}}</button>
</div>
<div class="content-panel" wizardTag="importProfileEditor">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="tabs">
    <button id="tab-basic" [ngClass]="{notactive : showPart!=1}" (click)="onShowPart(1)"><span class="ic-trash"></span>{{'import_profiles_main_title' | shpLocalize}}</button>
    <button id="tab-mapping" [ngClass]="{notactive : showPart!=2}" (click)="onShowPart(2)" wizardTag="importProfileMappingButton" [wizardCompleteStep]="wizardStepNames.ImportProfileClickMapping"><span class="ic-trash"></span>{{'import_profiles_mapping_title' | shpLocalize}}</button>
  </div>
  <div class="import-profiles-content" cdkScrollable wizardScrollable>
    <div class="editor-frame">
      <form class="shipment" #f="ngForm" name="f" [class.submitted]="f.submitted" novalidate>
        <shp-dynamic-import-editor-part1 #part1editor
                                         [ngClass]="{parthidden:showPart!=1}"
                                         [profile]="profile"
                                         [customers]="customers"
                                         [customerDetailId]="currentCustomerDetailId"
                                         [viewOnly]="viewOnly"
                                         [isCustomerLevelAccess]="isCustomerLevelAccess"
                                         (onFileChanged)="onFileChanged($event)">
        </shp-dynamic-import-editor-part1>

        <shp-dynamic-import-editor-part2 #part2editor
                                         [dynamic]="profile"
                                         [editorGroups]="editorGroups"
                                         [editorValues]="editorValues"
                                         [fieldMappings]="profile.fieldMappings"
                                         [profile]="profile"
                                         [ngClass]="{parthidden:showPart!=2}"
                                         [show]="showPart == 2"
                                         [editorInitialGroups]="editorInitialGroups"
                                         [groupListWithMissingFields]="groupListWithMissingFields"
                                         [viewOnly]="viewOnly"
                                         (fileChange)="onFileChanged($event)">
        </shp-dynamic-import-editor-part2>
      </form>
    </div>
  </div>
</div>
