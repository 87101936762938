import { Component } from "@angular/core"

import * as Shared from "../../shared/index";
import * as _ from "lodash";
import { Toast, ToastrService, ToastPackage } from "ngx-toastr";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { EntitiyValidationError } from "../services/entity-validator.service";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-entity-validtion-toast",
  templateUrl: "./entity-validation-toast.component.html",
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ]
})
export class EntityValidationToast extends Toast {

  errors: EntitiyValidationError[] = [];

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  callFocusAction(error: EntitiyValidationError) {
    if (error.focusAction && _.isFunction(error.focusAction)) {
      error.focusAction();
    }
  }
}