import { Pipe, PipeTransform } from "@angular/core";
import { SELECTOR_PREFIX } from "../../shared/index";
import { User } from "../../user/models/user.model";


@Pipe({
  name: SELECTOR_PREFIX + "UserId"
})
export class UserIdPipe implements PipeTransform {
  public transform(value, ...args: any[]) {
    if (typeof value === "undefined" || value == null) {
      return null;
    }


    if (!isNaN(value)) {
      // Ako prvý argument očakávame zoznam customer detailov, v ktorých hľadáme ten s ID z value.
      let id = +value;

      let users: User[] = args[0];

      if (users) {
        let user = users.find(u => u.id === id);

        if (user) {
          return `${user.name} (${user.loginName})`;
        }
      }
    }

    return null;
  }
}