import { Component, OnInit } from "@angular/core";

import { DialogRef, ModalComponent, ModalOpenContext } from "ngx-modialog-7";

import { AppNewsfeedMessage } from "../models/app-newsfeed-message.model";

interface NewsfeedMessageContext extends ModalOpenContext {
  newsfeedMessage: AppNewsfeedMessage
}

@Component({
  templateUrl: "./newsfeed-message-dialog.component.html"
})
export class NewsfeedMessageDialogComponent implements ModalComponent<NewsfeedMessageContext>, OnInit {
  message: AppNewsfeedMessage;

  constructor(
    public dialog: DialogRef<NewsfeedMessageContext>
  ) {
    this.message = dialog.context.newsfeedMessage;
  }


  ngOnInit() { }


  // @HostListener('window:popstate', ['$event'])
  close() {
    this.dialog.close();
  }
}