import { Component } from "@angular/core";

import { WizardMessageComponent } from "../../modules/wizard/models";
import { WizardService } from "../../modules/wizard/services/wizard.service";

const labelAreas = [
  {
    text: "wizard_demo_label_area_delivery_address",
    areaCssClass: "wizard__demo-label__example__hightlight--delivery-address",
    label: "1",
    labelCssClass: "wizard__demo-label__example__label--left"
  },
  {
    text: "wizard_demo_label_area_sender_details",
    areaCssClass: "wizard__demo-label__example__hightlight--sender-detail",
    label: "2",
    labelCssClass: "wizard__demo-label__example__label--left"
  },
  {
    text: "wizard_demo_label_area_origin_depot",
    areaCssClass: "wizard__demo-label__example__hightlight--origin-depot",
    label: "3",
    labelCssClass: "wizard__demo-label__example__label--right"
  },
  {
    text: "wizard_demo_label_area_delivery_details",
    areaCssClass: "wizard__demo-label__example__hightlight--delivery-details",
    label: "4",
    labelCssClass: "wizard__demo-label__example__label--left"
  },
  {
    text: "wizard_demo_label_area_parcel_details",
    areaCssClass: "wizard__demo-label__example__hightlight--parcel-details",
    label: "5",
    labelCssClass: "wizard__demo-label__example__label--top"
  },
  {
    text: "wizard_demo_label_area_additional_info",
    areaCssClass: "wizard__demo-label__example__hightlight--additional-info",
    label: "6",
    labelCssClass: "wizard__demo-label__example__label--bottom"
  },
];

@Component({
  templateUrl: "./shipper-wizard-test-label-popup.component.html"
})
export class ShipperWizardTestLabelPopupComponent extends WizardMessageComponent {
  activePart: number = null;
  isImgLoaded = false;
  labelAreas = labelAreas;

  constructor(wizardService: WizardService) {
    super(wizardService);
  }

  onPartMouseenter(part: number) {
    this.activePart = part;
  }

  onPartMouseleave() {
    this.activePart = null;
  }

  onImageLoad(ev) {
    this.isImgLoaded = true;
  }
}
