import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

import { RoutedPageComponentBase } from "../../shared/routed-page-component-base";
import * as Shared from "../../shared/index";
import { PickupOrderForGridView } from "../models/pickup-order-for-grid-view.model";
import { PickupOrdersGridViewSearchParameters } from "../models/pickup-orders-grid-view-search-parameters.model";
import { getObjectHasOnlyDefaultFilterValues, defaultPagingProperties } from "../../shared/filter-helpers";
import { amendValueForFormattedFormControl, amendValueForModelFromFormattedFormControl } from "../../shared/form-helpers";
import { PickupOrdersService } from "../services/pickup-orders.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";

const filterLocalStorageKey = "PickupOrdersListComponent.filter";
const defaultPageSize = 20;

@Component({
  selector: Shared.SELECTOR_PREFIX + "-one-time-pickup-orders-list",
  templateUrl: "./one-time-pickup-orders-list.component.html"
})
export class OneTimePickupOrdersListComponent extends RoutedPageComponentBase implements OnInit, OnDestroy {
  private _pickupOrdersPagedResults: Shared.PagedResult<PickupOrderForGridView> = null;
  private _filter: PickupOrdersGridViewSearchParameters = null;
  private _isFiltered: boolean = false;
  private _loadingErrorOccured = false;
  private _customerPickupAddressCustomId: string = null;
  private _customerPickupAddressId: number = null;

  private _lastPageIndex = 0;

  private _filterFormChangeSubscription: Subscription = null;
  private _listLoadingSubscription: Subscription = null;

  private _filterForm: FormGroup;

  private _isLoadingList = false;
  private _isInitializing = true;

  private _canCurrentUserAddPickupOrder: boolean = false;
  private _isImpersonatingShipperAdminUser: boolean = false;

  public currentYear = new Date().getFullYear();

  public wizardStepNames = WizardStepName;

  @ViewChild("header")
  private _header;

  @ViewChild("content")
  private _content;

  @Input("customerDetailId")
  private _customerDetailId: number = null;

  @Input()
  public set customerPickupAddressCustomId(value: string) {
    this._customerPickupAddressCustomId = value;
    this.reloadList();
  }

  @Input()
  public set customerPickupAddressId(value: number) {
    this._customerPickupAddressId = value;
    this.reloadList();
  }

  @Input("cityserviceAvailable")
  private _cityserviceAvailable: boolean = false;

  @Input("isSophia")
  private _isSophia: boolean = false;

  @Input("canCurrentUserAddPickupOrder")
  public set canCurrentUserAddPickupOrder(value: boolean) {
    this._canCurrentUserAddPickupOrder = value;
  }

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _contextService: Shared.ContextService,
    private _pickupOrdersServices: PickupOrdersService,
    private _fb: FormBuilder,
    private _toastr: ToastrService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);

    this.loadFilterFromLocalStorage();
    this.setupFilterForm();
  }


  private setIsFiltered() {
    this._isFiltered = !getObjectHasOnlyDefaultFilterValues(this._filter, defaultPagingProperties);
  }


  private loadFilterFromLocalStorage() {
    var filterJsonString = localStorage.getItem(filterLocalStorageKey);

    if (filterJsonString) {
      // this._filter = JSON.parse(filterJsonString);
      let filter = JSON.parse(filterJsonString);
      if ( typeof filter.pickupDateFrom === 'string' && filter.pickupDateFrom !== '' ) {
        filter.pickupDateFrom = new Date(filter.pickupDateFrom)
      }

      if ( typeof filter.pickupDateTo === 'string' && filter.pickupDateTo !== '' ) {
        filter.pickupDateTo = new Date(filter.pickupDateTo)
      }

      this._filter = filter;
    } else {
      this._filter = this.getEmptyFilter();
    }
  }


  private saveFilterToLocalStorage() {
    localStorage.setItem(filterLocalStorageKey, JSON.stringify(this._filter));
  }


  public get isLoadingList() {
    return this._isLoadingList;
  }


  public get isInitializing() {
    return this._isInitializing;
  }


  public get loadingErrorOccured() {
    return this._loadingErrorOccured;
  }


  public get cityserviceAvailable() {
    return this._cityserviceAvailable;
  }

  public get canCurrentUserAddPickupOrder() {
    return this._isImpersonatingShipperAdminUser ? true : this._canCurrentUserAddPickupOrder;
  }

  public get filter() {
    return this._filter;
  }


  public get filterForm() {
    return this._filterForm;
  }


  public get lastPageIndex() {
    return this._lastPageIndex;
  }


  public get total() {
    if (this._pickupOrdersPagedResults) {
      return this._pickupOrdersPagedResults.totalSize;
    }

    return null;
  }


  public get isFiltered() {
    return this._isFiltered;
  }


  public get pickupOrders() {
    if (this._pickupOrdersPagedResults) {
      return this._pickupOrdersPagedResults.items;
    }

    return null;
  }


  public get orderBy() {
    if (this.filter) {
      return this.filter.orderByFieldName;
    }

    return null;
  }


  public get orderAscending() {
    if (this.filter) {
      return this.filter.orderAscending;
    }

    return false;
  }


  public get isSophia() {
    return this._isSophia;
  }


  public get isImpersonatingShipperAdminUser() {
    return this._isImpersonatingShipperAdminUser
  }


  public clearFilter() {
    this._filter = this.getEmptyFilter();
    this.saveFilterToLocalStorage();
    this.updateFilterFormValuesFromFilter();
  }


  public scrollGridContent = () => {
    this._header.nativeElement.scrollLeft = this._content.nativeElement.scrollLeft;
  }


  public itemsPerPageOptions = [20, 30, 50, 100, 150, 200, 500];


  public showDetails(id: number) {
    this.router.navigate(["pickup-orders", id]);
  }


  public addPickupOrder() {
    this.router.navigate(["pickup-orders", "0"]);
  }


  private getEmptyFilter(): PickupOrdersGridViewSearchParameters {
    return {
      address: null, // Toto nás v kontexte Shippera netrápi.
      customerAddressCustomId: null, // Toto nás v kontexte Shippera netrápi.
      customerPickupAddressId: null, // Toto nás v kontexte Shippera netrápi.
      customerDetailId: null, // Toto musíme dopĺňať pri requeste.
      customerName: null,
      pickupDateFrom: null,
      pickupDateTo: null,
      orderAscending: null,
      orderByFieldName: null,
      pageIndex: 0,
      pageSize: defaultPageSize,
      pickupOrderTypeId: null, // V kontexte Shippera nás netrápi.
    }
  }


  public goToPage(index: number) {
    this._filter.pageIndex = index;

    this.reloadList();
  }


  public setOrderBy(fieldName: string) {
    if (this.filter.orderByFieldName === fieldName) {
      this.filter.orderAscending = !this.filter.orderAscending;
    } else {
      this.filter.orderByFieldName = fieldName;
      this.filter.orderAscending = true;
    }

    this.reloadList();
  }

  private setupFilterChangeListener() {
    this._filterFormChangeSubscription = this._filterForm.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(
      values => {
        Object.assign(this._filter, values);

        this._filter.pickupDateFrom = amendValueForModelFromFormattedFormControl(this._filter.pickupDateFrom);
        this._filter.pickupDateTo = amendValueForModelFromFormattedFormControl(this._filter.pickupDateTo);

        this.setIsFiltered();
        this.reloadList();
      });
  }


  private setupFilterForm() {
    this._filterForm = this._fb.group({
      pickupDateFrom: amendValueForFormattedFormControl(this._filter.pickupDateFrom),
      pickupDateTo: amendValueForFormattedFormControl(this._filter.pickupDateTo),
      pageSize: this._filter.pageSize
    });

    this.setupFilterChangeListener();
  }


  private updateFilterFormValuesFromFilter() {
    this._filterForm.setValue({
      pickupDateFrom: amendValueForFormattedFormControl(this._filter.pickupDateFrom),
      pickupDateTo: amendValueForFormattedFormControl(this._filter.pickupDateTo),
      pageSize: this._filter.pageSize
    });
  }


  public reloadList = () => {
    if (this._listLoadingSubscription) {
      this._listLoadingSubscription.unsubscribe();
    }

    this.saveFilterToLocalStorage();

    this._isLoadingList = true;
    this._loadingErrorOccured = false;

    var filter = _.cloneDeep(this._filter);

    filter.customerDetailId = this._customerDetailId;
    //filter.customerAddressCustomId = this._customerPickupAddressCustomId;
    filter.customerPickupAddressId = this._customerPickupAddressId;

    this._listLoadingSubscription = this._pickupOrdersServices.searchOneTimePickupOrders(filter).subscribe(

      pickupOrdersPagedResult => {
        this._pickupOrdersPagedResults = pickupOrdersPagedResult;

        this._lastPageIndex = Math.ceil(this.total / this.filter.pageSize) - 1;

        this._isLoadingList = false;

        //if (this.total > 0 && this.total <= this.filter.pageIndex * this.filter.pageSize) {
        if (this.total > 0 && this.lastPageIndex < this.filter.pageIndex) {
          this.goToPage(this.lastPageIndex);
        }
      }, ex => {
        this._isLoadingList = false;
        this._loadingErrorOccured = true;
        this.loggingService.logErrorData(ex, "Loading pickup orders failed");

        var exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

        this._toastr.error(`${this.localizationService.getLocalizedString("pickup_orders_loading_failed")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}}`);
      });
  }


  public ngOnDestroy() {
    if (this._listLoadingSubscription) {
      this._listLoadingSubscription.unsubscribe();
    }

    if (this._filterFormChangeSubscription) {
      this._filterFormChangeSubscription.unsubscribe();
    }
  }


  public ngOnInit() {
     // Zistíme, či sme prihlásený ako administrátor v kontexte zákazníka
     this.authenticationService.impersonatingShipperAdminUserLogin$.subscribe((login: string) => {
      this._isImpersonatingShipperAdminUser = login != null;
    });

    this.reloadList();
  }
}
