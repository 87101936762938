import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";

import { filter } from "rxjs/operators";
import { BehaviorSubject, combineLatest, forkJoin } from "rxjs";

import { ToastrService } from "ngx-toastr";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";

import * as _ from "lodash";

import * as Shared from "../../../shared/index";
import * as SettingsModel from "../../models/settings.model";
import { SettingsService } from "../../services/settings.service";
import { ExceptionsHandlerService } from "../../../shared/services/exceptions-handler.service";
import { LOGIN_URL } from "../../../shared/index";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-configuration-settings",
  templateUrl: "./configuration-settings.component.html"
})
export class ConfigurationSettingsComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _settingsService: SettingsService,
    private _contextService: Shared.ContextService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _exceptionHandlerService: ExceptionsHandlerService,
    private _modal: Modal,
    private _toastr: ToastrService) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router);
  }


  @ViewChild("f")
  public form: NgForm = null;


  /**
  * Názov aktuálne vybraného súboru z disku.
  */
  public selectedFileName = "";


  /**
   * JS objekt reprezentujúci údaje o vybranom súbore.
   */
  private _selectedFile: File = null;


  /**
   * Heslo ku konfiguračnému súboru.
   */
  public filePassword = "";


  public exception: Shared.ExceptionInfo = null;
  public uploadException: Shared.ExceptionInfo = null;


  public isBusy = false;


  public statusMessage = "";


  public isDemoUser = false;


  public canDeleteConfiguration = false;
  public canAddConfiguration = false;
  public canDisableConfiguration = false;
  public allowedParcelRangeAllocationToAddress = false;
  public isConfigurationNoteVisible = false;

  public isManualUploadShown = false;
  public activationCode = '';
  @ViewChild("activationCodeForm")
  public activationCodeForm: NgForm = null;

  /**
  * Handler, ktorý sa má bindovať na event zmeny vybraného súboru cez input type=file.
  */
  public selectedFileChanged($event: Event) {
    this.setSelectedFile($event.target);
  }


  private setSelectedFile(inputValue: any) {
    if (inputValue.files && inputValue.files.length > 0) {
      this._selectedFile = inputValue.files[0];

      this.selectedFileName = this._selectedFile.name;
    } else {
      this.selectedFileName = "";
      this._selectedFile = null;
    }
  }


  public uploadConfigurationFile() {
    if (this._selectedFile == null) {
      return;
    }

    this.isBusy = true;
    this.exception = null;

    // Cez FileReader (IE 10+, ostatné prehliadače OK) si načítame súbor z disku.
    var fileReader = new FileReader();

    fileReader.onloadend = () => {
      this.uploadConfiguration(<string>fileReader.result, this.filePassword);
    }

    fileReader.onerror = () => {
      this.isBusy = false;
    }

    fileReader.onabort = () => {
      this.isBusy = false;
    }

    // Zavoláme získanie textového obsahu súboru, lebo obsahuje JSON.
    fileReader.readAsText(this._selectedFile);
  }


  /**
   * Samotné nahranie načítaného konfiguračného súboru a hesla na server.
   */
  private uploadConfiguration(configurationJson: string, password: string) {
    var model: SettingsModel.ConfigurationModel = {
      password: password,
      configurationJson: configurationJson
    };

    this._settingsService.uploadConfiguration(model)
      .subscribe(() => {
        this.handleConfigurationActivated();

        this._toastr.success(this.localizationService.getLocalizedString("message_configuration_uploaded_successfully"));
      },
      (ex: any) => {
        // Niečo zlé sa stalo, zalogujeme a informujeme o tom.
        this.loggingService.logErrorData(ex, "Error uploading configuration.");

        this.handleConfigurationActivationFailed(ex);
      });

    return false; // Aby neprebehol nejaký submit formulára.
  }


  /**
   * Subject obsahujúci detaily aktívnych konfigurácii.
   */
  public configurationDetails: BehaviorSubject<SettingsModel.ConfigurationDetailsModel[]> = new BehaviorSubject([]);


  public refreshConfigurationDetails() {
    this.isBusy = true;
    this.exception = null;
    this.uploadException = null;

    // HACK: kým nie je transakcia pri nahrávaní konfigurácií, tak je problém, že tam v jednom momente máme 2 v DB.
    // Po chvíli ale je tam už len jedna, tak to riešime takýmto hackom.
    window.setTimeout(() => {
      this._settingsService.getActiveConfigurationDetails()
        .subscribe(
        configurationDetails => {
          // Checme radenie zostupne podľa dátumu platnosti od.
          configurationDetails = _.orderBy(configurationDetails, "validFromDateTimeUtc", "desc");

          this.configurationDetails.next(configurationDetails);

          this.isBusy = false;
        },
        ex => {
          this.loggingService.logErrorData(ex, "Error loading configuration.");

          var exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);

          if (exceptionInfo.key === Shared.ExceptionKeys.GeneralException) {
            // Trocha to konkretizujeme.
            exceptionInfo.key = Shared.ExceptionKeys.ErrorLoadingConfigurations;
          }

          this.exception = exceptionInfo;

          this.isBusy = false;
        });
    }, 1000);
  }


  public disable(configurationDetail: SettingsModel.ConfigurationDetailsModel) {
    // Nedovolíme disablovať poslednú aktívnu konfiguráciu.
    var activeConfigurations = _.filter(this.configurationDetails.getValue(),
      c => {
        return c.stateId == null || c.stateId === SettingsModel.ConfigurationState.Active || c.stateId === "";
      });

    if (activeConfigurations.length === 1) {
      this._modal.alert()
        .body(this.localizationService.getLocalizedString("cant_disable_last_active_configuration"))
        .open();

      return;
    }


    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("configuration_disable_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this.exception = null;

          this._settingsService.setConfigurationDisabled(configurationDetail.id, true)
            .subscribe(
            () => {
              this.isBusy = false;

              this._contextService.forceDataRefresh();
              this.refreshConfigurationDetails();

              this._toastr.success(this.localizationService.getLocalizedString("configuration_disabled"));
            },
            ex => {
              this.isBusy = false;

              this.loggingService.logErrorData(ex, "Configuration disabling failed");

              var exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);

              this.exception = exceptionInfo;
            });
        }
      },
      () => {
        // ... nič nerobíme.

      });
  }


  public enable(configurationDetail: SettingsModel.ConfigurationDetailsModel) {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("configuration_enable_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this.exception = null;

          this._settingsService.setConfigurationDisabled(configurationDetail.id, false)
            .subscribe(
            () => {
              this.isBusy = false;

              this._contextService.forceDataRefresh();
              this.refreshConfigurationDetails();

              this._toastr.success(this.localizationService.getLocalizedString("configuration_enabled"));
            },
            ex => {
              this.isBusy = false;

              this.loggingService.logErrorData(ex, "Configuration enabling failed");

              var exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);

              this.exception = exceptionInfo;
            });
        }
      },
      () => {
        // ... nič nerobíme.
      });
  }


  public delete(configurationDetail: SettingsModel.ConfigurationDetailsModel) {
    if (configurationDetail.stateId !== SettingsModel.ConfigurationState.Disabled) {
      // Nedovolíme odstrániť poslednú aktívnu konfiguráciu.
      var activeConfigurations = _.filter(this.configurationDetails.getValue(), c => {
        return c.stateId == null || c.stateId === SettingsModel.ConfigurationState.Active || c.stateId === "";
      });

      if (activeConfigurations.length === 1) {
        this._modal.alert()
          .body(this.localizationService.getLocalizedString("cant_delete_last_active_configuration"))
          .open();

        return;
      }

    }


    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("configuration_delete_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this.exception = null;

          this._settingsService.deleteConfiguration(configurationDetail.id)
            .subscribe(
            () => {
              this.isBusy = false;

              this._contextService.forceDataRefresh();
              this.refreshConfigurationDetails();

              this._toastr.success(this.localizationService.getLocalizedString("configuration_deleted"));
            },
            ex => {
              this.isBusy = false;

              this.loggingService.logErrorData(ex, "Configuration deleting failed");

              var exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);

              this.exception = exceptionInfo;
            });
        }
      },
      () => {
        // ... nič nerobíme.
      });
  }


  public ngOnInit(): void {
    this.refreshConfigurationDetails();

    this.authenticationService.user.pipe(filter(user => user != null)).subscribe(user => {
      this.isDemoUser = user.login === Shared.DEMO_USER_LOGIN;
    });

    forkJoin([
      this._settingsService.currentUserCanAddConfiguration(),
      this._settingsService.currentUserCanDeleteConfiguration(),
      this._settingsService.currentUserCanDisableConfiguration()
    ]).subscribe(result => {
      [this.canAddConfiguration, this.canDeleteConfiguration, this.canDisableConfiguration] = result;
    });

    combineLatest([
      this._businessUnitSettingsService.getIsParcelRangeAllocationToAddressAllowed(),
      this._businessUnitSettingsService.getIsConfigurationNoteVisible()
    ]).subscribe(result => {
      [
        this.allowedParcelRangeAllocationToAddress,
        this.isConfigurationNoteVisible
      ] = result;
    });
  }


  public ngOnDestroy(): void { }

  get canActivateConfiguration(): boolean {
    return this.activationCodeForm != null &&
           (!this.activationCodeForm.form.valid || this.activationCode.length === 0);
  }

  toogleManualUpload() {
    this.isManualUploadShown = !this.isManualUploadShown;
  }

  activateConfiguration() {
    const model: SettingsModel.ActivateConfigurationModel = {
      activationCode: this.activationCode
    };

    this.isBusy = true;
    this.exception = null;

    this._settingsService.activateConfiguration(model)
      .subscribe(() => {
        this.handleConfigurationActivated();

        this._toastr.success(this.localizationService.getLocalizedString("configuration_activated_success"));
      },
      (ex: any) => {
        // Niečo zlé sa stalo, zalogujeme a informujeme o tom.
        this.loggingService.logErrorData(ex, "Error activating configuration through code.");

        this.handleConfigurationActivationFailed(ex);
      });

    return false; // Aby neprebehol nejaký submit formulára.
  }

  private handleConfigurationActivated() {
    this.isBusy = false;
    this.uploadException = null;
    this.exception = null;

    // Teraz musíme refreshnúť context, aby si načítal aj novú konfiguráciu a ak je demo user, tak ho odhlásime.
    if (this.isDemoUser) {
      this.authenticationService.logOutUser();
      this.router.navigateByUrl(LOGIN_URL);
    } else {
      this._contextService.forceDataRefresh();
      this.refreshConfigurationDetails();

      this.activationCode = "";
      this.selectedFileName = "";
      this._selectedFile = null;

      this.filePassword = "";

      // Aby sme validačné chyby zmazali.
      this.form.reset();
    }
  }

  private handleConfigurationActivationFailed(ex: any) {
    var exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);

    if (exceptionInfo.key === Shared.ExceptionKeys.GeneralException) {
      // Trocha to konkretizujeme.
      exceptionInfo.key = Shared.ExceptionKeys.ErrorUploadingConfiguration;
    }

    this.uploadException = exceptionInfo;

    this.isBusy = false;
  }
}