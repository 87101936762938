<div @wizardArrowTop *ngIf="positionTop" class="wizard__arrow__wrap">
  <ng-template [ngTemplateOutlet]="arrowContent"></ng-template>
</div>

<div @wizardArrowRight *ngIf="positionRight" class="wizard__arrow__wrap">
  <ng-template [ngTemplateOutlet]="arrowContent"></ng-template>
</div>

<div @wizardArrowBottom *ngIf="positionBottom" class="wizard__arrow__wrap">
  <ng-template [ngTemplateOutlet]="arrowContent"></ng-template>
</div>

<div @wizardArrowLeft *ngIf="positionLeft" class="wizard__arrow__wrap">
  <ng-template [ngTemplateOutlet]="arrowContent"></ng-template>
</div>


<ng-template #arrowContent>
  <div class="wizard__arrow__arrow" (animationend)="onArrowAnimationEnd()"></div>
  <div *ngIf="arrow?.text" class="wizard__arrow__text">
    <span>{{ arrow?.text }}</span>
  </div>
</ng-template>