import { Component } from "@angular/core";

import { startWith, debounceTime, take } from "rxjs/operators";

import * as _ from "lodash";
import * as Shared from "../../../shared/index";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal, BSModalContext } from "ngx-modialog-7/plugins/bootstrap"
import { Router } from "@angular/router";
import { validateEmail } from "../../../shared/validators";
import { SettingsService } from "../../services/settings.service";
import { ToastrService } from "ngx-toastr";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { EmailSettingsEditorTestDialogComponent } from "../email-configuration-test-dialog.component";

const DEFAULT_PROTOCOL = "SMTP";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-email-server-configuration",
  templateUrl: "./email-server-configuration.component.html"
})
export class EmailServerConfigurationComponent extends TenantSettingsEditorBase {

  public f: FormGroup;
  public testEmailStatusMessage = "";


  constructor(
    authenticationService: Shared.AuthenticationService,
    contextService: Shared.ContextService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    loggingService: Shared.LoggingService,
    router: Router,
    settingsService: SettingsService,
    toastr: ToastrService,
    private _fb: FormBuilder,
    private _modal: Modal, ) {

    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);

    this.initForm();
  }


  public get isAuthRequired(): boolean {
    return this.f.get("isAuthentificationRequired").value;
  }


  public openTestEmailDialog() {
    this.contextService.currentCustomerDetail.pipe(take(1)).subscribe(cd => {
      this._modal.open(EmailSettingsEditorTestDialogComponent, overlayConfigFactory({
        customerDetailId: cd.id,
        senderEmail: this.f.get("senderEmail").value
      }, BSModalContext))
        .result
        .then(() => { })
        .catch(() => { });
    });    
  }


  private initForm() {
    this.f = this._fb.group({
      protocol: [DEFAULT_PROTOCOL, [Validators.required]],
      host: [null, []],
      port: [null, [Validators.pattern(/^[0-9]*$/)]],
      user: [null, [Validators.required]],
      password: [null, [Validators.required]],
      senderEmail: [null, [validateEmail]],
      isAuthentificationRequired: [null],
      tlsEncryption: [null]
    });

    this.f.get("isAuthentificationRequired").valueChanges.pipe(
      startWith(null)
    ).subscribe(() => this.toggleAuthFields());

    this.f.get("isAuthentificationRequired").enable();

    this.f.valueChanges.pipe(
      debounceTime(100)
    ).subscribe(() => this.updateModel());
  }


  private updateModel() {
    const emailSettings = {};

    _.transform(this.f.value, (result, value: any, key: string) => {
      result[`email_server_${_.snakeCase(key)}`] = { value }
    }, emailSettings);

    _.merge(this.settings, emailSettings);
  }


  private toggleAuthFields() {
    const isAuthentificationRequired =
      this.f.get("isAuthentificationRequired").value && this.f.enabled;

    if (isAuthentificationRequired) {
      this.f.get("user").enable();
      this.f.get("password").enable();
    } else {
      this.f.get("user").disable();
      this.f.get("password").disable();
    }
  }


  protected afterSettingsLoaded() {
    const emailSettings = {};

    _.transform(this.f.getRawValue(), (result, value: any, key: string) => {
      result[key] =
        this.settings[`email_server_${_.snakeCase(key)}`].value;
    }, emailSettings);

    this.f.patchValue({
      ...emailSettings,
      // force the only currently available option
      protocol: DEFAULT_PROTOCOL
    });
  }
}