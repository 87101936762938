import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import * as _ from "lodash";
import { BehaviorSubject, Observable, of, Subject, timer } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, pluck, publishReplay, share, shareReplay, switchMap, tap } from "rxjs/operators";

import { ShipperSettingsService } from "..";
import { API_URL } from "../api-url";
import { Address } from "../models/customer.models";
import { HeartbeatNotification } from "../models/heartbeat-notification.model";
import { ContextService } from "./context.service";
import { LoggingService } from "./logging.service";

const CHECK_INTERVAL_IN_SECONDS = 15;

const offlineNotification: HeartbeatNotification = {
  isOffline: true
};


/**
 * Periodically check server for notifications. Should be provided
 * only in root to avoid unnecessary API calls.
 */
@Injectable()
export class ApiHeartbeatService extends Subject<string | boolean> {
  constructor(
    private _loggingService: LoggingService,
    private _http: HttpClient,
    private _contextService: ContextService,
    private _shipperSettingsService: ShipperSettingsService) {
    super();
    this.initCurrnetCustomerObserver();
    this.initStreams();
    this.initHeartbeat();
  }

  private _heartbeat$ = new BehaviorSubject<HeartbeatNotification>({});

  public get heartbeat$(): Observable<HeartbeatNotification> {
    return this._heartbeat$;
  }

  public unreadCount$: Observable<number>;
  public offline$: Observable<boolean>;
  public parcelsCount$: Observable<number>;
  public shipmentsCount$: Observable<number>;
  public version$: Observable<string>;


  /**
   * Current address id is required to fetch specific notifications.
   * Omitted if its value us `null`.
   */
  private currentAddressId: string;


  /** Observe current address changes and update `currentAddressId`. */
  private initCurrnetCustomerObserver() {
    this._contextService.currentAddress
      .subscribe((address: Address) => {
        this.currentAddressId = address ? "/" + address.id : "";
      });
  }


  /** Initialize main heartbeat stream. */
  private initHeartbeat() {
    const multiplier = this._shipperSettingsService.isCentralShipper ? 4 : 1;

    timer(1000, CHECK_INTERVAL_IN_SECONDS * 1000 * multiplier).pipe(
      switchMap(() => {
        return this._http.get(`${API_URL}/heartbeat${this.currentAddressId}`).pipe(
          map((response: HeartbeatNotification) => {
            try {
              // return response.json();
              return response;
            } catch (ex) {
              this._loggingService.logErrorData(ex, "Error parsing version from API.");
              /** Unexpected response also throws error. */
              throw ex;
            }
          }),
          catchError((error) => {
            if (error.status === 401){
              window.location.reload();
              return;
            }
            else{
              return of(offlineNotification);
            }
          })
        );
      }),
      share()
    ).subscribe({
      next: result => this._heartbeat$.next(result)
    });
  }


  /** Initialize specific heartbeat streams. */
  private initStreams() {
    this.version$ = this._heartbeat$.pipe(
      pluck("version"),
      filter(v => typeof v !== "undefined"),
    ) as Observable<string>;

    this.unreadCount$ = this._heartbeat$.pipe(
      pluck("unreadCount"),
      filter(v => typeof v !== "undefined"),
      distinctUntilChanged(),
    ) as Observable<number>;

    this.parcelsCount$ = this._heartbeat$.pipe(
      pluck("parcelsCount"),
      filter(v => typeof v !== "undefined"),
      distinctUntilChanged(),
    ) as Observable<number>;

    this.shipmentsCount$ = this._heartbeat$.pipe(
      pluck("shipmentsCount"),
      filter(v => typeof v !== "undefined"),
      distinctUntilChanged(),
    ) as Observable<number>;

    this.offline$ = this._heartbeat$.pipe(
      pluck("isOffline"),
      filter(v => typeof v !== "undefined"),
    ) as Observable<boolean>;
  }

  installationHasStarted() {
    this._heartbeat$.next({ isOffline: true });
  }
}
