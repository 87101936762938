<form #f (submit)="save()" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category">
    <div class="settings-wrapper">
      <h1>{{ 'settings_sync' | shpLocalize }}</h1>
      <!-- <div class="ctl" *ngIf="settings.sync_shipments_manually.isVisible">
      <label for="sync-shipments-manually">{{ 'settings_sync_shipments_manually' | shpLocalize }}</label>
      <input type="checkbox"
            name="syncShipmentsManually" id="sync-shipments-manually" [(ngModel)]="settings.sync_shipments_manually.value" />
      <label for="sync-shipments-manually"></label>
    </div> -->
      <div class="ctl" *ngIf="settings.sync_shipments_to_endpoint.isVisible">
        <!-- <label for="sync-shipments-to-endpoint">{{'settings_sync_shipments_endpoint' | shpLocalize}}</label>
        <input type="text" readonly="readonly" name="syncShipmentsToEndpoint" id="sync-shipments-to-endpoint" [value]="endpointTranslate | shpLocalize" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" readonly="readonly" name="syncShipmentsToEndpoint" id="sync-shipments-to-endpoint" pInputText [value]="endpointTranslate | shpLocalize" />
            <label for="sync-shipments-to-endpoint">{{'settings_sync_shipments_endpoint' | shpLocalize}}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="settings.sync_shipments_to_endpoint.value == null">
        <!-- <label for="sync-shipments-path">{{ 'settings_sync_shipments_path' | shpLocalize }}</label>
        <input type="text" name="syncShipmentsLocalPath" id="sync-shipments-local-path" [(ngModel)]="settings.sync_shipments_local_path.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" name="syncShipmentsLocalPath" id="sync-shipments-local-path" pInputText [(ngModel)]="settings.sync_shipments_local_path.value" />
            <label for="sync-shipments-path">{{ 'settings_sync_shipments_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="isFtpEndpointSelected">
        <!-- <label for="sync-shipments-protocol">{{ 'settings_sync_shipments_protocol' | shpLocalize }}</label>
        <input type="text" readonly="readonly" name="syncProtocol" id="sync-shipments-protocol" [(ngModel)]="settings.sync_shipments_protocol.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" readonly="readonly" name="syncProtocol" id="sync-shipments-protocol" pInputText [(ngModel)]="settings.sync_shipments_protocol.value" />
            <label for="sync-shipments-protocol">{{ 'settings_automated_shipments_import_error_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="isFtpEndpointSelected">
        <!-- <label for="sync-shipments-ftp-host">{{ 'settings_sync_shipments_ftp_host' | shpLocalize }}</label>
        <input type="text" readonly="readonly" name="syncFtpHost" id="sync-shipments-ftp-host" [(ngModel)]="settings.sync_shipments_ftp_host.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" readonly="readonly" name="syncFtpHost" id="sync-shipments-ftp-host" pInputText [(ngModel)]="settings.sync_shipments_ftp_host.value" />
            <label for="sync-shipments-ftp-host">{{ 'settings_sync_shipments_ftp_host' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="isFtpEndpointSelected">
        <!-- <label for="sync-shipments-port">{{ 'settings_sync_shipments_port' | shpLocalize }}</label>
        <input type="text" readonly="readonly" name="syncFtpPort" id="sync-shipments-port" [(ngModel)]="settings.sync_shipments_port.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" readonly="readonly" name="syncFtpPort" id="sync-shipments-port" pInputText [(ngModel)]="settings.sync_shipments_port.value" />
            <label for="sync-shipments-port">{{ 'settings_sync_shipments_port' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="isFtpEndpointSelected">
        <!-- <label for="sync-shipments-path">{{ 'settings_sync_shipments_path' | shpLocalize }} (FTP)</label>
        <input type="text" readonly="readonly" name="syncDownloadPath" id="sync-shipments-path" [(ngModel)]="settings.sync_shipments_path.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" readonly="readonly" name="syncDownloadPath" id="sync-shipments-path" pInputText [(ngModel)]="settings.sync_shipments_path.value" />
            <label for="sync-shipments-path">{{ 'settings_sync_shipments_path' | shpLocalize }} (FTP)</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="isFtpEndpointSelected && settings.sync_shipments_use_semaphore_files.isVisible">
        <input type="checkbox" disabled="disabled" name="syncShipmentsUseSemaphoreFiles" id="sync-shipments-use-semaphore-files" [(ngModel)]="settings.sync_shipments_use_semaphore_files.value" />
        <label for="sync-shipments-use-semaphore-files">{{ 'settings_sync_shipments_use_semaphore_files' | shpLocalize }}</label>
        <!-- <label for="sync-shipments-use-semaphore-files"></label> -->
      </div>
      <!-- <div class="ctl" *ngIf="settings.sync_shipments_continually.isVisible">
      <label for="sync-shipments-continually">{{ 'settings_sync_shipments_continually' | shpLocalize }}</label>
      <input type="checkbox"
            name="syncShipmentsContinually" id="sync-shipments-continually" [(ngModel)]="settings.sync_shipments_continually.value" />
      <label for="sync-shipments-continually"></label>
      </div> -->
      <ng-container>
        <div class="ctl" *ngIf="settings.sync_shipments_time_mode.isVisible">
          <!-- <label for="sync-shipments-time-mode">{{ 'settings_sync_shipments_time_mode' | shpLocalize }}</label>
          <select name="syncShipmentsTimeMode" id="sync-shipments-time-mode" [(ngModel)]="settings.sync_shipments_time_mode.value">
            <option *ngFor="let timeMode of timeModes" [ngValue]="timeMode">{{ 'sync_shipments_time_mode_' + timeMode | shpLocalize }}</option>
          </select> -->
          <div class="p-field p-field--full">
            <span class="p-float-label" dpdFloatingLabel>
              <select name="syncShipmentsTimeMode" id="sync-shipments-time-mode" [(ngModel)]="settings.sync_shipments_time_mode.value">
                <option *ngFor="let timeMode of timeModes" [ngValue]="timeMode">{{ 'sync_shipments_time_mode_' + timeMode | shpLocalize }}</option>
              </select>
              <label for="sync-shipments-time-mode">{{ 'settings_sync_shipments_time_mode' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div *ngIf="settings.sync_shipments_time_mode.value === 'SpecificTime'" class="ctl">
          <p class="p-0 m-0"><i>{{ 'data_sync_specified_time_mode_info' | shpLocalize }}</i></p>
        </div>
      </ng-container>
      <div class="ctl" *ngIf="settings.sync_shipments_periodicity_in_minutes.isVisible">
        <!-- <label for="sync-shipments-periodicity">{{ 'settings_sync_shipments_periodicity_in_minutes' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.sync_shipments_periodicity_in_minutes.value" name="syncShipmentsPeriodicity" id="sync-shipments-periodicity" [step]="1" [showSpinner]="true"
                        [min]="0"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.sync_shipments_periodicity_in_minutes.value"
              [min]="0"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="syncShipmentsPeriodicity"
              id="sync-shipments-periodicity"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="sync-shipments-periodicity">{{ 'settings_sync_shipments_periodicity_in_minutes' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.sync_shipments_nminutes_after_save.isVisible">
        <!-- <label for="sync-shipments-nminutes-after-save">{{ 'sync_shipments_n_minutes_after_save' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.sync_shipments_nminutes_after_save.value" name="syncShipmentsNminutesAfterSave" id="sync-shipments-nminutes-after-save" [step]="1" [showSpinner]="true"
                        [min]="0"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.sync_shipments_nminutes_after_save.value"
              [min]="0"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="syncShipmentsNminutesAfterSave"
              id="sync-shipments-nminutes-after-save"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="sync-shipments-nminutes-after-save">{{ 'sync_shipments_n_minutes_after_save' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.sync_shipments_hour.isVisible">
        <!-- <label for="sync-shipments-hour">{{ 'settings_sync_shipments_hour' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.sync_shipments_hour.value" name="syncShipmentsHour" id="sync-shipments-hour" [step]="1" [showSpinner]="true"
                        [min]="0" [max]="23"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.sync_shipments_hour.value"
              [min]="0"
              [max]="23"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="syncShipmentsHour"
              id="sync-shipments-hour"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="sync-shipments-hour">{{ 'settings_sync_shipments_hour' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.sync_shipments_minute.isVisible">
        <!-- <label for="sync-shipments-minute">{{ 'settings_sync_shipments_minute' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.sync_shipments_minute.value" name="syncShipmentsminute" id="sync-shipments-minute" [step]="1" [showSpinner]="true"
                        [min]="0" [max]="59" format="D2"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.sync_shipments_minute.value"
              [min]="0"
              [max]="59"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="syncShipmentsminute"
              id="sync-shipments-minute"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="sync-shipments-minute">{{ 'settings_sync_shipments_minute' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.sync_collection_requests_hour.isVisible">
        <!-- <label for="sync-collection-requests-hour">{{ 'settings_collection_requests_hour' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.sync_collection_requests_hour.value" name="syncCollectionRequestsHour" id="sync-collection-requests-hour" [step]="1" [showSpinner]="true"
                        [min]="0" [max]="23"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.sync_collection_requests_hour.value"
              [min]="0"
              [max]="23"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="syncCollectionRequestsHour"
              id="sync-collection-requests-hour"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="sync-collection-requests-hour">{{ 'settings_collection_requests_hour' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.sync_collection_requests_minute.isVisible">
        <!-- <label for="sync-collection-requests-minute">{{ 'settings_collection_requests_minute' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.sync_collection_requests_minute.value" name="syncCollectionRequestsMinute" id="sync-collection-requests-minute" [step]="1" [showSpinner]="true"
                        [min]="0" [max]="59" format="D2"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.printingSettings.value.printerTopOffset"
              [min]="0"
              [max]="59"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="syncCollectionRequestsMinute"
              id="sync-collection-requests-minute"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="sync-collection-requests-minute">{{ 'settings_collection_requests_minute' | shpLocalize }}</label>
          </span>
        </div>
      </div>
    </div><!-- .settings-wrapper -->
    <div class="commands-toolbar">
      <button id="button-save" type="submit" class="mr-2"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
      <button id="button-test-connection" type="button" (click)="testConnection()" class="inline-progress-overlay-container"><shp-progress-overlay [isActive]="isTestingConnection"></shp-progress-overlay><span class="ic-network-connecting"></span> {{ 'test_sync_settings' | shpLocalize }}</button>
    </div>
  </div>
</form>