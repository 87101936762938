import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";

import { forkJoin, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";

import * as Shared from "../../shared/index";
import * as ConfigModel from "../../shared/models/config.models";
import { PickupOrdersService } from "../services/pickup-orders.service";
import { PollyCollection } from "../../shared/index";

@Component({
  templateUrl: "./pickup-orders-list.component.html"
})
export class PickupOrdersListComponent implements OnInit, OnDestroy, AfterViewInit {

  public isBusy: boolean = false;
  public customerDetailId: number = null;
  public customerPickupAddressId: number = null;
  public customerPickupAddressCustomId: string = null;
  public canCurrentUserAddPickupOrder: boolean = false;
  public cityserviceAvailable: boolean = false;
  public hasLoadingFailed: boolean = false;
  public isSophia: boolean = false;
  public pollyCollection = PollyCollection.PickupOrders;

  private _currentAddressSubscription: Subscription = null;

  constructor(
    loggingService: Shared.LoggingService,
    private _contextService: Shared.ContextService,
    private _skdataConfigService: Shared.SkdataConfigService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _toastr: ToastrService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _localizationService: Shared.LocalizationService,
    private _pickupOrdersServices: PickupOrdersService
  ) { }


  private initialize() {
    return forkJoin([
      this._contextService.currentCustomerDetail.pipe(filter(value => value != null),take(1)),
      this._contextService.currentAddress.pipe(filter(value => value != null),take(1)),
    ]).pipe(
        map(result => {
        this.customerDetailId = result[0].id;
        this.customerPickupAddressId = result[1].id;
        this.customerPickupAddressCustomId = result[1].customId;

        this.refreshAddPickupOrderPermission();

        return true;
      })
    )
  }

  private refreshAddPickupOrderPermission() {
    this._pickupOrdersServices.canCurrentUserAddPickupOrder(this.customerDetailId, this.customerPickupAddressId).subscribe(
      result => {
        this.canCurrentUserAddPickupOrder = result;
      }, ex => {}
    );
  }

  public load() {
    this.isBusy = true;
    this.hasLoadingFailed = false;
    this.isSophia = this._shipperSettingsService.isSophia;

    this.initialize().subscribe(
      result => {
        this._skdataConfigService.getAllowedProductsAndAdditionalServices(this.customerDetailId).subscribe(products => {
          if(products.some(p => p.code === ConfigModel.WellKnownProductCodes.CityService)) {
            this.cityserviceAvailable = true;
          }
        })
        this.isBusy = false;
      }, ex => {
        this.hasLoadingFailed = true;
        this.isBusy = false;
        this._toastr.error(`${this._localizationService.getLocalizedString("error_preparing_pickup_orders")}:\n ${this._localizationService.getLocalizedExceptionString(ex)}`);
      });
  }


  public ngOnInit() {
    this.load();
  }


  public ngOnDestroy() {
    if (this._currentAddressSubscription) {
      this._currentAddressSubscription.unsubscribe();
      this._currentAddressSubscription = null;
    }
  }


  public ngAfterViewInit() {
    this._currentAddressSubscription = this._contextService.currentAddress.subscribe(
      currentAddress => {
        if (currentAddress && this.customerPickupAddressId != currentAddress.id) {
          this.customerPickupAddressId = currentAddress.id;
          this.customerPickupAddressCustomId = currentAddress.customId;
          this.customerDetailId = currentAddress.customerDetailId;
          this.refreshAddPickupOrderPermission();
        }
      });
  }
}