export interface ExceptionInfo {
  message?: string;
  stackTrace?: string;
  httpStatusCode?: number;
  key: string;
  params?: any[];
}


export class ExceptionKeys {
  public static GeneralException = "general_exception";
  public static UserNotFoundException = "UserNotFoundException";
  public static UnauthorizedException = "UnauthorizedException";

  public static ProductUnavailableAtDestination = "error_product_not_available";
  public static InvalidZipForCity = "error_zip_not_in_city";
  public static InvalidCity = "error_city_not_found";
  public static InvalidZip = "error_invalid_zip";
  public static InvalidDestZip = "error_invalid_dest_zip";

  public static ErrorLoadingConfigurations = "error_loading_configurations";
  public static ErrorUploadingConfiguration = "error_uploading_configuration";
}