import { BankAccount } from "../../shared/models/customer.models";
import { Component, ViewChildren, QueryList, AfterViewInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";

import { merge, Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";

import { ShipmentEditorComponent } from "./shipment-editor.component";


const VALID = 'VALID';
const INVALID = 'INVALID';


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments-additional-services",
  templateUrl: "./shipments-additional-services.component.html",
  host: {
    "class": "additional-services"
  }
})
export class ShipmentsAdditionalServicesComponent implements AfterViewInit, OnDestroy {
  @Output() selectedServicesChange = new EventEmitter<any[]>();

  status: string = VALID;


  @ViewChildren(NgForm) servicesForms: QueryList<NgForm>;


  private _destroy$ = new Subject<void>();


  constructor(public editor: ShipmentEditorComponent) { }


  get valid(): boolean { return this.status === VALID; }


  get invalid(): boolean { return this.status === INVALID; }


  get form(): NgForm {
    return this.editor.form
  }


  get isReadOnly(): boolean {
    return this.editor.isReadOnly;
  }

  get availableBankAccounts(): BankAccount[] {
    return this.editor.availableBankAccounts;
  }


  get selectedBankAccount(): BankAccount {
    return this.editor.selectedBankAccount;
  }


  set selectedBankAccount(value: BankAccount) {
    this.editor.selectedBankAccount = value;
  }

  ngAfterViewInit() {
    this.servicesForms.changes.pipe(
      startWith(null),
      takeUntil(this._destroy$)
    ).subscribe(() => {
      this.observeForms();
    });
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  onServiceSelect() {
    this.editor.checkProductsAndAdditionalServicesAvailability();
    this.editor.checkCustomsObligations();
    this.selectedServicesChange.emit();
  }


  private observeForms() {
    merge(...this.servicesForms.map(ngFrom => ngFrom.form.statusChanges)).pipe(
      startWith(null),
      takeUntil(merge(this.servicesForms.changes, this._destroy$))
    ).subscribe(() => this.computeStatus());
  }


  /**
   * Statuses pending and disabled are not taken into consideration.
   */
  private computeStatus() {
    const isValid = this.servicesForms.length ?
        this.servicesForms
          .map(ngFrom => ngFrom.form.status)
          .every(status => status === VALID) :
        true;

    this.status = isValid ? VALID : INVALID;
  }
}
