<div class="toast__header">
	<div class="toast__info">
		<h5 *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">{{ title }}</h5>
		<div *ngIf="message && options.enableHtml" [class]="options.messageClass" role="alert" aria-live="polite" [innerHTML]="message"></div>
		<div *ngIf="message && !options.enableHtml" [class]="options.messageClass" role="alert" aria-live="polite"><p>{{ message }}</p></div>
	</div>

	<button class="expand" (click)="expand()">{{btnCopy}}</button>
	<button class="close" *ngIf="options.closeButton" (click)="remove()" title="Close"><span class="ic-close"></span></button>
</div>

<div *ngIf="expanded" class="toast__body">
	<p>{{downloadCopy}}</p>
	<button class="download" (click)="download()">Download error log</button>
</div>