<div class="dialog pickup-address-selector-dialog">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>

  <h1 [innerHtml]="'select_pickup_address' | shpLocalize"></h1>

  <div class="contexts-list">
    <div *ngFor="let context of contexts | async" class="context" [class.selected]="context === selectedContext" [class.stop-credit]="context.customerDetail.stopCredit" (click)="selectContext(context)">
      <div class="context-address">{{ context.address.name1 }}<br />{{ context.address.street }} {{ context.address.houseNr }}<br />{{ context.address.place }}</div>
      <div *ngIf="context.customerDetail?.stopCredit" class="context-stop-credit">
        <span class="stop-credit-alert-icon" title="{{ 'Stop_credit_customer_cannot_save_order' | shpLocalize }}">&euro;</span>
      </div>
      <div class="context-detail-name">{{ context.customerDetail.name }}<br /><em>{{ context.customerDetail.delisId }}</em></div>
    </div>
  </div>

  <div class="buttons center">
    <button id="button-close" type="button" (click)="close()">{{ 'select' | shpLocalize }}</button>
    <button id="button-cancel" type="button" (click)="cancel()">{{ 'cancel' | shpLocalize }}</button>
  </div>
</div>