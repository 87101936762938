<div class="top">
  <h1>{{ 'transformations' | shpLocalize }}</h1>
  <button *ngIf="allowAddingNewImportProfiles" id="button-add" class="top-button" [routerLink]="['/import-profiles/transformations/0']"><span class="ic-add-2"></span><span class="label">{{ 'add' | shpLocalize}}</span></button>
  <button id="button-import" class="top-button" (click)="import()"><span class="ic-file-download-2"></span><span class="label">{{ 'import' | shpLocalize}}</span></button>
</div>
<div class="content-panel">
  <div class="grid">
    <div class="grid-header no-filter">
      <table>
        <thead>
          <tr>
            <th class="col-import-profile-name">{{ 'transformation_name' | shpLocalize }}</th>
            <th class="col-import-profile-customer">{{ 'transformation_code' | shpLocalize }}</th>
            <th class="col-import-profile-description">{{ 'transformation_description' | shpLocalize }}</th>
            <th class="col-commands"></th>
            <th class="col-spacer"></th>
          </tr>
        </thead>
      </table>
      <div class="scrollbar-spacer-cover"></div>
    </div>
    <div class="grid-content">
      <table>
        <tbody>
          <tr *ngFor="let transformation of transformations">
            <td class="col-import-profile-name">{{ transformation.name }}</td>
            <td class="col-import-profile-customer">{{ transformation.code }}</td>
            <td class="col-import-profile-description">{{ transformation.description }}</td>
            <td class="col-commands">
              <button id="button-edit" type="button" *ngIf="transformation.tenantId" [routerLink]="['/import-profiles/transformations/', transformation.id]" title="{{ 'edit' | shpLocalize }}"><span class="ic-box-edit"></span></button>
              <button id="button-view" type="button" *ngIf="!transformation.tenantId" [routerLink]="['/import-profiles/transformations/', transformation.id]" title="{{ 'view' | shpLocalize }}"><span class="ic-file-view-1"></span></button>
              <button id="button-delete" type="button" *ngIf="transformation.tenantId" (click)="delete(transformation)"><span class="ic-bin"></span></button>
              <button id="button-export" type="button" (click)="export(transformation)"><span class="ic-file-upload-2"></span></button>
            </td>
            <td class="col-spacer"></td>
          </tr>
          <tr *ngIf="transformations && transformations.length === 0">
            <td>
              <div class="message">{{ 'no_transformation_definitions_available' | shpLocalize }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
