import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as Shared from "../../shared/index";
import { ExceptionInfo } from "../../shared/index";
import * as UserModel from "../models/user.model";



@Injectable()
export class UsersService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient
  ) { super(loggingService); }


  public getUsers(tenantId: number) {
    return this.processRequest<UserModel.User[]>(this._http.get(`${Shared.API_URL}/tenants/${tenantId}/users`));
  }


  public getUser(userId: number) {
    return this.processRequest<UserModel.UserModel>(this._http.get(`${Shared.API_URL}/users/${userId}`));
  }


  public addUser(user: UserModel.UserModel) {
    return this.processRequest<UserModel.UserModel>(this._http.post(`${Shared.API_URL}/users`, user));
  }


  public updateUser(user: UserModel.UserModel) {
    return this.processRequest<UserModel.UserModel>(this._http.put(`${Shared.API_URL}/users/${user.id}`, user));
  }


  public deleteUser(userId: number) {
    return this.processRequest<UserModel.UserModel>(this._http.delete(`${Shared.API_URL}/users/${userId}`));
  }


  public changeUserPassword(model: UserModel.ChangePasswordModel) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/users/user-password`, model));
  }


  public setCurrentUserPassword(model: UserModel.ChangePasswordModel) {
    return this.processRequest<boolean>(this._http.put(`${Shared.API_URL}/my-profile/password`, model));
  }


  public getCurrentImpersonatingShipperAdminUser() {
    return this.processRequest<string>(this._http.get(`${Shared.API_URL}/current-impersonating-shipper-admin-user`));
  }

  public checkOnlyDemoUserExists(): Observable<boolean> {
    return this.processRequest<string>(this._http.get(`${Shared.API_URL}/check-only-demo-user-exists`)).pipe(
      map((i) => {
        return false;
      }),
      catchError((e) => {
        return of(true);
      })
    );
  }


  public requestPasswordChange(userName: string) {
    return this.processRequest<any>(this._http.get(`${Shared.API_URL}/request-password-change?userName=${userName}`));
  }


  public resetPassword(model: UserModel.ResetPasswordModel) {
    return this.processRequest<any>(this._http.post(`${Shared.API_URL}/change-password`, model));
  }


  public getUserIsAdmin() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/users/isadmin`));
  }


  public getUserIsCustomerAdmin() {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/users/iscustomeradmin`));
  }


  public getCurrentUserAllowedOperations(): Observable<UserModel.UserOperation[]> {
    return this.processRequest<UserModel.UserOperation[]>(this._http.get(`${Shared.API_URL}/users/allowed-operations`)).pipe(
      catchError(() => of([]))
    );
  }


  public getUserHasOldPassword(userId: number) {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/users/is-old-password/${userId}`));
  }


  public resolvePasswordValidationExceptions(ex: ExceptionInfo, handlerService: Shared.ExceptionsHandlerService) {
    if (!ex || ex.key !== "password_validation_errors") {
      return null;
    }

    return ex.params[0]
      .map((p) => ({ key: p.Code, message: p.Message, params: p.Parameters }))
      .map((p) => handlerService.getExceptionInfo(p));
  }
}
