<div class="wizard__popup__header">
  <span *ngIf="iconClass" class="wizard__popup__header__icon" [ngClass]="iconClass"></span><span>{{ headline }}</span>
</div>
<div class="wizard__popup__body">
  <div class="wizard__popup__body__text" [innerHTML]="text"></div>

  <div *ngFor="let component of specialComponents | keyvalue" class="mt-2">
    <ng-template [cdkPortalOutlet]="component.key" (attached)="onPortalAttach($event, component.value)"></ng-template>
  </div>

  <shp-wizard-navigation
    *ngIf="navigation?.isPreviousDisplayed !== false || navigation?.isNextDisplayed !== false"
    class="mt-2"
    [isPreviousDisplayed]="navigation?.isPreviousDisplayed !== false"
    [isNextDisplayed]="navigation?.isNextDisplayed !== false"
    [isChapterNavigationDisplayed]="navigation?.isChapterNavigationDisplayed !== false"
    [shouldExitOnChapterNavigation]="navigation?.shouldExitOnChapterNavigation"
    [previousLabel]="navigation?.previousLabel"
    [nextLabel]="navigation?.nextLabel"
    [finishLabel]="navigation?.finishLabel"></shp-wizard-navigation>

  <div *ngIf="hasCompleteChapterButton" class="d-flex flex-direction-column mt-2">
    <button type="button" class="wizard__popup__go-to-step-btn" (click)="completeChapter()">{{ completeChapterLabel }}</button>
  </div>

  <div *ngIf="goToStepButtons?.length" class="d-flex flex-direction-column mt-2">
    <button *ngFor="let gotoStepBtn of goToStepButtons" type="button" class="wizard__popup__go-to-step-btn" (click)="goToStep(gotoStepBtn.stepName, gotoStepBtn.customEventType)">{{ gotoStepBtn.label }}</button>
  </div>
</div>
