import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import * as _ from "lodash";
import { forkJoin, from, NEVER, Observable, of, ReplaySubject, Subject, throwError } from "rxjs";
import { catchError, filter, map, pluck, switchMap, take, tap } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { SupportedLanguages } from "../languages";
import { BusinessUnitSetting, BusinessUnitSettings, TestEmailModel } from "../models/business-unit.models";
import { ShipperMainMenuItem } from "../models/config.models";
import { Link } from "../models/link.model";
import { ApiServiceBase, API_URL, LoggingService } from "./../index";


const LINK_LANGUAGE_PLACEHOLDER = "{{LANG}}";

/**
 * Transforms setting observable to emit boolean value.
 *
 * @param observable - UI setting observable.
 * @param defaultBehavior - Boolean value for default behavior
 *                          when setting is not defined.
 */
function settingToBoolean(observable: Observable<any>, defaultBehavior: boolean = false): Observable<boolean> {
  return observable.pipe(
    map(value => {
      return typeof value === "boolean" ? value : defaultBehavior;
    }),
    catchError(() => of(defaultBehavior))
  );
}


function settingToNullableBoolean(observable: Observable<any>): Observable<boolean | null> {
  return observable.pipe(
    map(value => {
      return typeof value === "boolean" ? value : null;
    }),
    catchError(() => of(null))
  );
}


@Injectable()
export class BusinessUnitSettingsService extends ApiServiceBase {
  readonly settings$: Observable<BusinessUnitSettings>;

  private _isLoadPending = false;
  private _settings$ = new ReplaySubject<BusinessUnitSettings>(1);
  private readonly _reload$ = new Subject<void>();

  constructor(
    loggingService: LoggingService,
    private _contextService: Shared.ContextService,
    private _localizationService: Shared.LocalizationService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _http: HttpClient
  ) {
    super(loggingService);
    this.settings$ = this._settings$.asObservable();
    this.initSettings();
  }

  /**
   * Initializes `settings$` stream.
   */
  private initSettings() {
    this._contextService.currentCustomerDetail.subscribe(customer => {
      if (customer) {
        // Reload BU settings when customer detail changes.
        this._reload$.next();
      } else {
        // Prevent settings emit while current customer is `null`.
        this._isLoadPending = true;
      }
    });

    this._reload$.pipe(
      switchMap(() => this._contextService.currentCustomerDetail),
      filter(val => !!val),
      map(customerDetail => customerDetail.id),
      tap(() => this._isLoadPending = true),
      switchMap(customerDetailId => {
        return this.processRequest<BusinessUnitSetting[]>(this._http.get(`${API_URL}/settings/${customerDetailId}`)).pipe(
          catchError(() => NEVER)
        );
      }),
      map((settings: BusinessUnitSetting[]) => this.parseSettingsResponse(settings)),
      tap(() => this._isLoadPending = false)
    ).subscribe(this._settings$);
  }


  reload() {
    this._reload$.next();
  }


  private parseSettingsResponse(settings: BusinessUnitSetting[]) {
    const parsed = settings.reduce((acc, setting) => {
      let value = setting.value;

      // ** Transform boolean values returned as string. */
      if (value.toLowerCase() === "true") {
        value = true;
      } else if (value.toLowerCase() === "false") {
        value = false;
      }

      acc[_.camelCase(setting.settingKey)] = value;
      return acc;
    }, {});

    return parsed;
  }


  /**
   * Vráti údaje požadovaného nastavenia pre BU, pod ktorý spadá aktuálny používateľ.
   * @param settingKey Kľúč nastavenia.
   */
  getSetting(settingKey: string): Observable<any> {
    // console.log("Getting setting value:", settingKey, this.settings$);

    return this._settings$.pipe(
      /** Prevent emit while settings are loading. */
      filter(() => !this._isLoadPending),
      take(1),
      pluck(_.camelCase(settingKey)),
      map(res => typeof res === "undefined" ? "" : res)
    );
  }

  /*
   Vráti údaje nastavenia pre danú krajinu s fallbackom pre nastavenie bez krajiny ak ho nenašlo.
  */
  getCountryAwareSetting(settingsKey: string, countryCode: string): Observable<any> {
    return this.getSetting(`${settingsKey}_${countryCode}`).pipe(
      switchMap(v => v !== ""
        ? of(v) 
        : this.getSetting(settingsKey)
      )
    );
  }

  public getCountrySpecificIncoTerms(countryCode: string) {
    return this.getSetting(`IncoTerms_${countryCode}`);
  }

  public getCountrySpecificHideTypeOfDocument(countryCode: string) {
    return settingToBoolean(this.getSetting(`HideTypeOfDocument_${countryCode}`));
  }

  public getCountrySpecificHideClearanceCleared(countryCode: string) {
    return settingToBoolean(this.getSetting(`HideClearanceCleared_${countryCode}`));
  }

  public getCountrySpecificHidePrealertStatus(countryCode: string) {
    return settingToBoolean(this.getSetting(`HidePrealertStatus_${countryCode}`));
  }

  public getCountrySpecificShowExportReason(countryCode: string) {
    return settingToBoolean(this.getCountryAwareSetting("ShowExportReason", countryCode));
  }

  public getCountrySpecificDefaultExportReason(countryCode: string) {
    return this.getCountryAwareSetting("DefaultExportReason", countryCode);
  }

  /**
   * Vráti údaje požadovaného nastavenia pre customera, pod ktorý spadá aktuálny používateľ.
   * @param settingKey Kľúč nastavenia.
   */
  public getCustomerSetting(customerDetailId: number, settingKey: string) {
    return this.processRequest<string>(this._http.get(`${API_URL}/settings/${customerDetailId}/${settingKey}`));
  }


  public getIsSaturdayPickupAllowed() {
    return settingToBoolean(this.getSetting("IsPickupOrderOnSaturdayAllowed"));
  }


  public getIsSundayPickupAllowed() {
    return settingToBoolean(this.getSetting("IsPickupOrderOnSundayAllowed"));
  }


  public getIsCustomSenderAddressAllowed(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCustomSenderAddressAllowed"));
  }


  public getUseRecipientFirstOrderFormSettingValue(): Observable<boolean> {
    return settingToBoolean(this.getSetting("UseRecipientFirstOrderForm"));
  }


  public getAllowProtocolsPrintingForSelectedShipmentsSettingValue(): Observable<boolean> {
    return settingToBoolean(this.getSetting("AllowProtocolsPrintingForSelectedShipments"));
  }


  public getHideDimensionsEditorForParcelsSettingValue(): Observable<boolean> {
    return settingToBoolean(this.getSetting("HideDimensionsEditorForParcels"));
  }


  public getTrackingUrl(): Observable<string> {
    return this.getSetting("TrackingUrl").pipe(
      map(value => {
        if (!value || value.trim() === "") {
          value = Shared.TRACKING_URL_BASE;
        }

        return value;
      })
    );
  }


  public getAreDimensionEditorsForParcelsMandatorySettingValue(): Observable<boolean> {
    return settingToBoolean(this.getSetting("AreDimensionEditorsForParcelsMandatory"), true);
  }  

  public getFixedCrPickupDateCountries(): Observable<string> {
    return this.getSetting("FixedCrPickupDateCountries");
  }

  public getMaxParcelsCount(): Observable<string> {
    return this.getSetting("MaxParcelsCount");
  }

  public getStreetRegEx(): Observable<string> {
    return this.getSetting("StreetRegEx");
  }

  public getCentralTrackingByReferenceUrl(): Observable<string> {
    return this.getSetting("CentralTrackingByReferenceUrl").pipe(
      map(value => {
        if (!value || value.trim() === "") {
          value = Shared.TRACKING_URL_BY_REF_DEFAULT_TEMPLATE;
        }

        return value;
      })
    );
  }


  public getIsProductsAndServicesCalculationDuringEditingAllowed() {
    return settingToBoolean(this.getSetting("IsProductsAndServicesCalculationDuringEditingAllowed"));
  }


  public getIsEodContinuous(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsEodContinuous"));
  }


  public getIsCRProductRequiredForCREditing(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCrProductRequiredForCrEditing"));
  }


  public getUseB2BAddressFieldsOnly(): Observable<boolean> {
    return settingToBoolean(this.getSetting("UseB2BAddressFieldsOnly"));
  }


  public getIsSenderScanVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsSenderScanVisible"));
  }


  public getIsCustomsClearanceVisible(): Observable<boolean> {
    const ccSettingKey = this._shipperSettingsService.isCentralShipper
      ? 'IsCustomsClearanceEnabledCentral'
      : 'IsCustomsClearanceEnabledOffline';
    return settingToBoolean(this.getSetting(ccSettingKey));
  }


  public getAreRecipientsAddressSpecific(): Observable<boolean> {
    return settingToBoolean(this.getSetting("AreRecipientsAddressSpecific"));
  }


  public getIsSameNumberOfSwapParcelsRequiredAsOriginalParcels(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsSameNumberOfSwapParcelsRequiredAsOriginalParcels"));
  }


  public getDefaultShipmentTypeForCustomClearance(): Observable<string> {
    return this.getSetting("DefaultShipmentType");
  }


  public getDefaultClearanceClearedForCustomClearance(): Observable<string> {
    return this.getSetting("DefaultClearanceCleared");
  }

  public getDefaultPrealertStatusForCustomClearance(): Observable<string> {
    return this.getSetting("DefaultPrealertStatus");
  }


  public getHelpLinkShippingPlannerForCustomClearance(): Observable<string> {
    return this.getSetting("HelpLinkShippingPlanner").pipe(
      map(link => this.getLocalizedHelperLink(link))
    );
  }


  public getHelpLinkCustomsRegulationsForCustomClearance(): Observable<string> {
    return this.getSetting("HelpLinkCustomsRegulations").pipe(
      map(link => this.getLocalizedHelperLink(link))
    );
  }


  public getHelpLinkCommercialInvoicesForCustomClearance(): Observable<string> {
    return this.getSetting("HelpLinkCommercialInvoices").pipe(
      map(link => this.getLocalizedHelperLink(link))
    );
  }

  public getRequiredVatAndEoriForNonEuCountries(): Observable<boolean> {
    return settingToBoolean(this.getSetting("RequiredVatAndEoriForNonEuCountries"));
  }


  public getOwnCustomsClearanceHelpLinks(): Observable<Map<string, Link[]>> {
    return this.getLocalizedLinks("CustomsOwnHelpLinks");
  }


  public getDisableClearanceClearedForCustomClearance(): Observable<boolean> {
    return settingToBoolean(this.getSetting("DisableClearanceCleared"));
  }


  public getHideClearanceClearedForCustomClearance(): Observable<boolean> {
    return settingToBoolean(this.getSetting("HideClearanceCleared"));
  }


  public getDisablePrealertStatusForCustomClearance(): Observable<boolean> {
    return settingToBoolean(this.getSetting("DisablePrealertStatus"));
  }


  public getHidePrealertStatusForCustomClearance(): Observable<boolean> {
    return settingToBoolean(this.getSetting("HidePrealertStatus"));
  }


  public getIsEnabledDomesticCardPay() {
    return settingToBoolean(this.getSetting("IsEnabledDomesticCardPay"));
  }


  public getIsEnabledForeignCardPay() {
    return settingToBoolean(this.getSetting("IsEnabledForeignCardPay"));
  }


  public getIsEnabledIndividuallyCOD(): Observable<boolean> {
    return this.getSetting("CodAmountCanBeSetIndividually").pipe(
      map(setting => {
        return setting == null || setting !== false;
      }),
      catchError(() => {
        return from([false]);
      })
    );
  }


  public getIsForcedIndividuallyCOD(): Observable<boolean> {
    return this.getSetting("CodAmountCanBeSetIndividually").pipe(
      map(setting => {
        return setting === true;
      }),
      catchError(() => {
        return from([false]);
      })
    );
  }


  public getIsParcelRangeAllocationToAddressAllowed(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsParcelRangeAllocationToAddressAllowed"));
  }


  public getIsShipmentCreationDateTimeVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsShipmentCreationDateTimeVisible"));
  }


  public getIsCustomerRefUsedForCollectionRequest(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCustomerRefUsedForCollectionRequest"));
  }


  public getCustomerReferenceNrLink(): Observable<string> {
    return this.getSetting("CustomerReferenceNrLink");
  }


  public getIsAdditionalServicesSectionWithinBaseData(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsAdditionalServicesSectionWithinBaseData"));
  }


  public getIsParcelReferenceDisplayedInReturnRequestPanel(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsParcelReferenceDisplayedInReturnRequestPanel"));
  }


  public getIsShipmentPickupDateTimeVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsShipmentPickupDateTimeVisible"));
  }


  public getCreatePickupOrdersWithShipmentOrders(): Observable<boolean> {
    return settingToBoolean(this.getSetting("CreatePickupOrdersWithShipmentOrders"));
  }


  public getInitializeDummyParcelsForCollectionRequest(): Observable<boolean> {
    return settingToBoolean(this.getSetting("InitializeDummyParcelsForCollectionRequest"));
  }


  public getIsCustomerPersonalizedNotificationAllowed(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCustomerPersonalizedNotificationAllowed"), false);
  }


  /**
   * Sends test email request
   */
  public sendTestEmail(data: TestEmailModel): Observable<any> {
    const url = `${API_URL}/notification-platform/send-test-email`;

    return this.processRequest<any>(this._http.put(url, data)).pipe(
      catchError(err => {
        /** @todo handle error */
        return throwError(err);
      })
    );
  }


  /**
   * Gets reuired settings for mail server and checks
   * if all values are set.
   */
  public isEmailServerSet(): Observable<boolean> {
    return forkJoin([
      this.getSetting("MailServer.Protocol"),
      this.getSetting("MailServer.Host"),
      this.getSetting("MailServer.Port"),
      this.getSetting("MailServer.SenderEmail")
    ]).pipe(
      map((values) => values.every(item => !!item))
    );
  }


  public getEnableSendingReturnLabelByEmail(): Observable<boolean> {
    return settingToBoolean(this.getSetting("EnableSendingReturnLabelByEmail"));
  }


  public getIsPrintReturnLabelActionVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsPrintReturnLabelActionVisible"));
  }


  private getLocalizedHelperLink(link: string): string {
    return link.replace(
      LINK_LANGUAGE_PLACEHOLDER,
      this._localizationService.currentLocalizationCode.toLowerCase());
  }


  public getCrAvailableCountries(): Observable<string> {
    return this.getSetting("CrAvaiableCountries");
  }


  public getIsDeleteAllShipmnetsVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsDeleteAllShipmnetsVisible"));
  }


  public getIsTemplateEditorVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsTemplateEditorVisible"));
  }


  public getIsParcelLengthFirstDimension(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsParcelLengthFirstDimension"));
  }


  public getIsShipmentGridSettingsVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsShipmentGridSettingsVisible"));
  }


  public getCustomsClearanceTooltips(): Observable<any> {
    const settingKeyPrefix = "customsClearanceTooltip";
    const appLang = this._localizationService.currentLocalizationCode.toLowerCase();

    return this._settings$.pipe(
      /** Prevent emit while settings are loading. */
      filter(() => !this._isLoadPending),
      take(1),
      map(settings => {
        const result = {};

        for (const key in settings) {
          if (key.startsWith(settingKeyPrefix)) {
            const lang = _.camelCase(key.slice(key.length - 2));

            if (lang === appLang) {
              const settingKey = _.camelCase(key.slice(settingKeyPrefix.length, key.length - 2));
              _.set(result, settingKey, settings[key]);
            }
          }
        }
        return result;
      })
    );
  }

  public getCheckDefaultWeightRequiredCountry(): Observable<string> {
    return this.getSetting("CheckDefaultWeightRequiredCountry");
  }

  public getIsCrSenderPhoneNumberOrEmailRequired(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCrSenderPhoneNumberOrEmailRequired"));
  }

  public getIsCrRecipientPhoneNumberOrEmailRequired(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCrRecipientPhoneNumberOrEmailRequired"));
  }
      
  public getIsCustomClearanceExportMrnFieldVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCustomClearanceExportMrnFieldVisible"));
  }  
  
  public getIsCustomClearanceRequiredValidationTurnedOff(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCustomClearanceRequiredValidationTurnedOff"));
  }  
  
  public getCustomClearanceRequiredValidationCountryCodes(): Observable<string> {
    return this.getSetting("CustomsRequiredValidationCountryCodes");
  }  
  
  public getCustomClearanceBoundFieldsRequiredValidationCountryCodes(): Observable<string> {
    return this.getSetting("CustomsBoundFieldsRequiredValidationCountryCodes");
  }

  public getIsCrSenderPhoneNumberRequired(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCrSenderPhoneNumberRequired"));
  }

  public getIsCrSenderEmailRequired(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsCrSenderEmailRequired"));
  }

  public getPrintReturnForm(): Observable<boolean> {
    return settingToBoolean(this.getSetting("PrintReturnForm"), true);
  }

  public getIsPrintReturnConfirmationFormOptionVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsPrintReturnConfirmationFormOptionVisible"));
  }

  public getIsConfigurationNoteVisible(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsConfigurationNoteVisible"));
  }

  public getIsMultipleManualLinksAllowed(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsMultipleManualLinksAllowed"));
  }

  public getIsBulkShipper(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsBulkShipper"));
  }

  public getOwnManualLinks(): Observable<Map<string, Link[]>> {
    return this.getLocalizedLinks("OwnManualLink");
  }

  // public getShowInsightsRolesInShipper(): Observable<boolean> {
  //   return settingToBoolean(this.getSetting("ShowInsightsRolesInShipper"));
  // }

  public getShowInsightsInShipper(): Observable<boolean> {
    return settingToBoolean(this.getSetting("ShowInsightsInShipper"));
  }

  public getCentralShipperShipmentsImportFileSizeLimit(): Observable<number> {
    return this.getSetting("CentralShipperShipmentsImportFileSizeLimit").pipe(
      map(value => this._shipperSettingsService.isCentralShipper ? (value ? +value : 200) : null)
    );
  }

  public getCentralShipperShipmentsImportFileLinesLimit(): Observable<number> {
    return this.getSetting("CentralShipperShipmentsImportFileLinesLimit").pipe(
      map(value => this._shipperSettingsService.isCentralShipper ? (value ? +value : 500) : null)
    );
  }

  public getCentralShipperRecipientsImportFileSizeLimit(): Observable<number> {
    return this.getSetting("CentralShipperRecipientsImportFileSizeLimit").pipe(
      map(value => this._shipperSettingsService.isCentralShipper ? (value ? +value : 1000) : null)
    );
  }

  public getCentralShipperRecipientsImportFileLinesLimit(): Observable<number> {
    return this.getSetting("CentralShipperRecipientsImportFileLinesLimit").pipe(
      map(value => this._shipperSettingsService.isCentralShipper ? (value ? +value : 3000) : null)
    );
  }


  public getIsPhoneNumberValidationMandatory(countryCode: string) {
    return forkJoin([
      settingToNullableBoolean(this.getSetting("IsPhoneNumberValidationMandatory")),
      settingToNullableBoolean(this.getCountryAwareSetting("IsPhoneNumberValidationMandatory", countryCode))
    ]).pipe(
      map(values => {
        const globalSetting = values[0];
        const countrySpecificSetting = values[1];

        let isRequired = false;

        if (globalSetting !== null) {
          isRequired = globalSetting;
        }

        if (countrySpecificSetting !== null) {
          isRequired = countrySpecificSetting;
        }

        return isRequired;
      })
    );
  }


  public getIsEmailMandatoryForCrossBorder() {
    return settingToBoolean(this.getSetting("IsEmailMandatoryForCrossBorder"));
  }

  public getCountrySpecificIsEmailMandatoryForCrossBorder(destinationCountryCode: string) {
    return settingToNullableBoolean(this.getCountryAwareSetting("IsEmailMandatoryForCrossBorder", destinationCountryCode));
  }

  public getUseMiniportalIntegration() {
    return settingToBoolean((this.getSetting("UseMiniportalIntegration")));
  }

  public getMiniportalUrl() {
    return this.getSetting("MiniportalUrl");
  }

  public getIsCustomShipmentsExportContentEnabled() {
    return settingToBoolean(this.getSetting("IsCustomShipmentsExportContentEnabled"));
  }

  public getIsSenderAddressProductRuleAllowed() {
    return settingToBoolean(this.getSetting("IsSenderAddressProductRuleAllowed"));
  }

  public getIsUnchangedInitialPasswordAllowed() {
    return settingToBoolean(this.getSetting("IsUnchangedInitialPasswordAllowed"));
  }

  private getLocalizedLinks(buSettingPrefix: string): Observable<Map<string, Link[]>> {
    return forkJoin(
      SupportedLanguages.map(language => this.getSetting(`${buSettingPrefix}.${language.code}`))
    ).pipe(
      map(settingValues => {
        const result = new Map<string, Link[]>();

        SupportedLanguages.forEach((language, idx) => {
          const links = this.convertLocalizedTextToLinks(settingValues[idx]);

          if (links) {
            const linkLocalizationData = {
              languageCode: language.code,
            };

            result.set(
              language.code,
              links.map(l => ({ ...l, ...linkLocalizationData }))
            );
          }
        });

        return result;
      })
    );
  }

  private convertLocalizedTextToLinks(text: string): Link[] | null {
    if (text == null || text.length === 0) {
      return null;
    }

    const links = text
      .split("\n")
      .map(link => {
        const linkParts = link.split("||");
        return {
          url: linkParts[0],
          displayName: linkParts[1]
        };
      });

    return links;
  }


  public getIsHideUsernameOnProtocolVisible() {
    return settingToBoolean(this.getSetting("IsHideUsernameOnProtocolVisible"));
  }

  public getMenuItemsVisibleForUser() {
    return this.getSetting("MenuItemsVisibleForUser").pipe(
      map(items => typeof items === "string" ?
        items.split(",").filter(i => Boolean(i.length)) :
        []
      )
    );
  }


  public getMenuItemsVisibleForCustomerService() {
    // return this.getSetting("MenuItemsVisibleForCustomerService").pipe(
    //   map(items => typeof items === "string" ?
    //     items.split(",").filter(i => Boolean(i.length)) :
    //     []
    //   )
    // );

    return of([
      ShipperMainMenuItem.Shipments,
      ShipperMainMenuItem.Recipients,
      ShipperMainMenuItem.Settings,
      ShipperMainMenuItem.Notifications,
      ShipperMainMenuItem.Support
    ]);
  }

  public getIsMenuItemImportProfilesVisible() {
    return settingToBoolean(this.getSetting("IsMenuItemImportProfilesVisible"));
  }


  public getSameDayPickupOrderThresholdHour() {
    return this.getSetting("SameDayPickupOrderThresholdHour");
  }


  public getSameDayPickupOrderThresholdMinute() {
    return this.getSetting("SameDayPickupOrderThresholdMinute");
  }

  public getDisallowAddingNewImportProfiles() {
    return settingToBoolean(this.getSetting("DisallowAddingNewImportProfiles"));
  }

  public getDisallowAddingNewUsers() {
    return settingToBoolean(this.getSetting("DisallowAddingNewUsers"));
  }

  public getEnableProductsAndServiceAvailabilityCalculation() {
    return settingToBoolean(this.getSetting("EnableProductsAndServiceAvailabilityCalculation"));
  }

  public getIsELabelPngAvailable(): Observable<boolean> {
    return settingToBoolean(this.getSetting("IsELabelPngAvailable"));
  }
}
