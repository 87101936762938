import { APP_INITIALIZER, NgModule, Provider } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { ModalModule } from "ngx-modialog-7";
import { BootstrapModalModule } from "ngx-modialog-7/plugins/bootstrap";
// import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
// import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { ToastrModule } from "ngx-toastr";

import { ShipperAppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { ShipmentsModule } from "./shipments/shipments.module";
import { UserModule } from "./user/user.module";
import { Ng2CompleterModule } from "ng2-completer";
import { DndModule } from "ng2-dnd";

// UI Modules
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ListboxModule } from 'primeng/listbox';

// Komponenty
import { DashboardComponent } from "./dashboard/components/dashboard.component";
import { NotificationsComponent } from "./notifications/components/notifications.component";
import { PickupPointsComponent } from "./pickup-points/components/pickup-points.component";
import { RecipientComponent } from "./recipients/components/recipient.component";
import { RecipientsExportDialogComponent } from "./recipients/components/recipients-export-dialog.component";
import { RecipientsImportDialogComponent } from "./recipients/components/recipients-import-dialog.component";
import { RecipientsListComponent } from "./recipients/components/recipients-list.component";
import { RecipientsCompactComponent } from "./recipients/components/recipients-compact.component";
import { RecipientsGridComponent } from "./recipients/components/recipients-grid.component";
import { RecipientsComponent } from "./recipients/components/recipients.component";
import { SettingsComponent } from "./settings/components/settings.component";
import * as SettingsCategoryEditors from "./settings/components/settings-category-editors/index";
import { ShipperAppContainerComponent } from "./app-container.component";
import { NotificationsService } from "./notifications/services/notifications.service";

import { ImportProfilesComponent } from "./import-profiles/components/import-profiles.component";
import { ListComponent as DynamicImportsListComponent } from "./import-profiles/components/list.component";
import { EditorComponent as DynamicImportsEditorComponent } from "./import-profiles/components/editor.component";
import { Part1EditorComponent as DynamicImportsPart1EditorComponent } from "./import-profiles/components/part1.editor.component";
import { Part2EditorComponent as DynamicImportsPart2EditorComponent } from "./import-profiles/components/part2.editor.component";
import { ImportProfilesImportDialogComponent } from "./import-profiles/components/import-profiles-import-dialog.component";
import { PickupOrdersModule } from "./pickup-orders/pickup-orders.module";

import { ImportProfilesService } from "./import-profiles/services/import-profiles.service";

import { DailyProcedureDialogComponent } from "./shipments/components/daily-procedure-dialog.component";

// Služby
import { SettingsService } from "./settings/services/settings.service";
import { LogService } from "./settings/services/log.service";
import { NotificationsTemplateService } from "./settings/services/notification-templates.service";
import { RecipientsService } from "./recipients/services/recipients.service";
import { PickupPointsService } from "./pickup-points/services/pickup-points.service";
import { InstructionsService } from "./settings/services/instructions.service";
// Pajpy
import { LocalizeNotificationMessagePipe } from "./notifications/pipes/localize-notification-message.pipe";


import { routing } from "./app.routes";
import { TransformationEditorComponent } from "./import-profiles/components/transformation-editor.component";
import { TransformationsListComponent } from "./import-profiles/components/transformations-list.component";
import { TransformationImportDialogComponent } from "./import-profiles/components/transformation-import-dialog.component";
import { AddressIdPipe } from "./settings/pipes/address-id.pipe";
import { CustomerIdPipe } from "./settings/pipes/customer-id.pipe";
import { UserIdPipe } from "./settings/pipes/user-id.pipe";
import { ProductIdPipe } from "./settings/pipes/product-id.pipe";
import { ShipmentsImportPathEditorDialogComponent } from "./settings/components/shipments-import-path-editor-dialog.component";
import { BankAccountsUploadDialogComponent } from "./settings/components/bank-accounts-upload-dialog.component";
import { ImportProfileIdPipe } from "./settings/pipes/import-profile-id.pipe";
import { DeleteAllRecipientsDialogComponent } from "./recipients/components/delete-all-recipients-dialog.component";
import { UpdateService } from "./shared/services/update.service";
import { DeleteAllNotificationsDialogComponent } from "./notifications/components/delete-all-notifications-dialog.component";
import { ImportProfileTransformationDataMultiplicationComponent } from "./import-profiles/components/transformation-data-forms/multiplication.component";
import { EmailSettingsEditorTestDialogComponent } from "./settings/components/email-configuration-test-dialog.component";
import { AppDataResolver, AuthenticationService, BusinessUnitSettingsService, LocalizationService, LoggingService, PasswordService, ShipperSettingsService, TokensStorageService } from "./shared";
import { ParcelCsvExportProfileService } from "./settings/services/parcel-csv-export-profile.service";
import { AppChangeLogComponent } from "./support/components/app-change-log.component";
import { AppManualComponent } from "./support/components/app-manual.component";
import { ContactComponent } from "./support/components/contact.component";
import { FaqPageComponent } from "./support/components/faq-page.component";
import { SupportComponent } from "./support/components/support.component";
import { SupportService } from "./support/services/support.service";
import { WIZARD_DATA_PROVIDER } from "./shared/modules/wizard/models";
// import { ShipperWizardDataProvider } from "./shared/services/shipper-wizard-steps";
import { httpInterceptorProviders } from "./shared/interceptors";
import { ShipperWizardDataProvider } from "./shared/services/shipper-wizard-steps";
import { UsersService } from "./user/services/users.service";

function resolveAppData(appDataResolver: AppDataResolver): () => Promise<any> {
  return () =>  appDataResolver.resolve();
}

function loadStartupSettings(shipperSettingsService: ShipperSettingsService): () => Promise<any> {
  return () => shipperSettingsService.loadStartupSettings();
}

const appInitializers: Provider[] = [
  {provide: APP_INITIALIZER, useFactory: resolveAppData, deps: [AppDataResolver], multi: true},
  {provide: APP_INITIALIZER, useFactory: loadStartupSettings, deps: [ShipperSettingsService], multi: true}
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({maxOpened: 5, newestOnTop: false}),
    ModalModule.forRoot(),
    BootstrapModalModule,
    InputTextareaModule,
    AutoCompleteModule,
    InputNumberModule,
    MultiSelectModule,
    InputTextModule,
    KeyFilterModule,
    CalendarModule,
    TableModule,
    DropdownModule,
    TooltipModule,
    ListboxModule,
    // WjCoreModule,
    // WjInputModule,
    SharedModule,
    UserModule,
    PickupOrdersModule,
    ShipmentsModule,
    CommonModule,
    RouterModule,
    Ng2CompleterModule,
    DndModule.forRoot(),
    routing
  ],
  declarations: [
    ShipperAppComponent,

    DashboardComponent,

    NotificationsComponent,

    PickupPointsComponent,

    RecipientComponent,
    RecipientsComponent,
    RecipientsExportDialogComponent,
    RecipientsImportDialogComponent,
    RecipientsListComponent,
    RecipientsCompactComponent,
    RecipientsGridComponent,
    DeleteAllRecipientsDialogComponent,
    DeleteAllNotificationsDialogComponent,

    SettingsComponent,
    SettingsCategoryEditors.AddressFormComponent,
    SettingsCategoryEditors.AutomaticExportSettingsComponent,
    SettingsCategoryEditors.AutomaticImportSettingsComponent,
    SettingsCategoryEditors.AutomaticRecImportSettingsComponent,
    SettingsCategoryEditors.BackupRestoreComponent,
    SettingsCategoryEditors.BankAccountEditorComponent,
    SettingsCategoryEditors.BankAccountsSettingsComponent,
    SettingsCategoryEditors.BasicSettingsComponent,
    SettingsCategoryEditors.ConfigurationSettingsComponent,
    SettingsCategoryEditors.EmailServerConfigurationComponent,
    SettingsCategoryEditors.EmailTempaltesEditor,
    SettingsCategoryEditors.InfoSettingsComponent,
    SettingsCategoryEditors.InstructionsViewerComponent,
    SettingsCategoryEditors.LogViewerComponent,
    SettingsCategoryEditors.ParcelExportSettingsFieldsComponent,
    SettingsCategoryEditors.ParcelExportSettingsFileContentComponent,
    SettingsCategoryEditors.PickupAddressEditorComponent,
    SettingsCategoryEditors.PickupAddressesSettingsComponent,
    SettingsCategoryEditors.PrintSettingsComponent,
    SettingsCategoryEditors.PrinterRuleEditorComponent,
    SettingsCategoryEditors.PrinterRulesEditorComponent,
    SettingsCategoryEditors.PrinterRulesSettingsComponent,
    SettingsCategoryEditors.ProxySettingsComponent,
    SettingsCategoryEditors.PudoSettingsComponent,
    SettingsCategoryEditors.RoutingSettingsComponent,
    SettingsCategoryEditors.SyncSettingsComponent,
    SettingsCategoryEditors.UserManagementComponent,
    SettingsCategoryEditors.ViewSettingsComponent,

    ShipmentsImportPathEditorDialogComponent,
    BankAccountsUploadDialogComponent,
    EmailSettingsEditorTestDialogComponent,
    ShipperAppContainerComponent,

    ImportProfilesComponent,
    DynamicImportsListComponent,
    DynamicImportsEditorComponent,
    DynamicImportsPart1EditorComponent,
    DynamicImportsPart2EditorComponent,
    ImportProfilesImportDialogComponent,
    TransformationEditorComponent,
    TransformationsListComponent,
    TransformationImportDialogComponent,
    ImportProfileTransformationDataMultiplicationComponent,

    // Support
    AppChangeLogComponent,
    AppManualComponent,
    ContactComponent,
    FaqPageComponent,
    SupportComponent,

    LocalizeNotificationMessagePipe,
    ProductIdPipe,
    UserIdPipe,
    CustomerIdPipe,
    AddressIdPipe,
    ImportProfileIdPipe
    //BSModalContainer
  ],
  bootstrap: [ShipperAppComponent],
  providers: [
    // Jednotlivé služby, ktoré chceme mať ako singletony a nie sú už zadeklarované v nejakom module.
    httpInterceptorProviders,
    appInitializers,
    AuthenticationService,
    LocalizationService,
    LoggingService,
    TokensStorageService,
    AppDataResolver,
    ShipperSettingsService,
    BusinessUnitSettingsService,
    ImportProfilesService,
    InstructionsService,
    LogService,
    NotificationsService,
    NotificationsTemplateService,
    ParcelCsvExportProfileService,
    PickupPointsService,
    RecipientsService,
    SettingsService,
    UpdateService,
    UsersService,
    SupportService,
    PasswordService,
    { provide: WIZARD_DATA_PROVIDER, useExisting: ShipperWizardDataProvider },
  ],
  entryComponents: [
    // Toto tu musí byť, lebo komponenty nie sú nikde použité a nepredparsuje ich template a neukáže ho.
    RecipientsExportDialogComponent,
    RecipientsImportDialogComponent,
    TransformationImportDialogComponent,
    ShipmentsImportPathEditorDialogComponent,
    BankAccountsUploadDialogComponent,
    EmailSettingsEditorTestDialogComponent,
    ImportProfilesImportDialogComponent,
    DeleteAllRecipientsDialogComponent,
    DeleteAllNotificationsDialogComponent
  ]
})
export class ShipperAppModule { }
