import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";


import * as _ from "lodash";
import { auditTime, filter, finalize } from "rxjs/operators";

import { claerFormArray, mergeModelWithFormValue } from "../../../shared/form-helpers";
import * as Shared from "../../../shared/index";
import { ShipperSettingsService } from "../../../shared/index";
import { ParcelCsvExportField } from "../../models/parcel-csv-export-field.model";
import { ParcelCsvExportProfileEditorModel } from "../../models/parcel-csv-export-profile-with-fields.model";
import { ParcelCsvExportProfile } from "../../models/parcel-csv-export-profile.model";
import { ParcelCsvExportProfileService } from "../../services/parcel-csv-export-profile.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-parcel-export-settings-fields",
  templateUrl: "./parcel-export-settings-fields.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "exportSettings"
})
export class ParcelExportSettingsFieldsComponent implements OnChanges, OnInit {
  form: FormGroup;
  parcelExportFields: string[] = [];


  @Input()
  model: ParcelCsvExportProfileEditorModel = this.getEmptyModel();


  constructor(
      private _fb: FormBuilder,
      private _cdr: ChangeDetectorRef,
      private _shipperSettingsService: ShipperSettingsService,
      private _parcelCsvExportProfileService: ParcelCsvExportProfileService) {
    this.buildForm();
  }


  get isCentral(): boolean {
    return this._shipperSettingsService.isCentralShipper;
  }


  get profile(): Partial<ParcelCsvExportProfile> {
    return this.model.profile;
  }


  get fields(): Partial<ParcelCsvExportField>[] {
    return this.model.fields;
  }


  get fieldsControl(): FormArray {
    return this.form.get("fields") as FormArray;
  }


  get isFieldSectionDisplayed(): boolean {
    return this.form.get("profile.shouldUseFields").value;
  }


  get isValid(): boolean {
    return  this.form.valid;
  }


  ngOnChanges(changes: SimpleChanges) {
    if ("model" in changes) {
      if (!this.model) {
        this.model = this.getEmptyModel();
      }

      this.processProfile();
      this.processFields();
      this.updateForm();
    }
  }


  ngOnInit() {
    this.loadFieldsList();
  }


  removeField(idx: number) {
    this.fieldsControl.removeAt(idx);
    this.onOrderChange();
  }


  insertField(idx: number) {
    this.fieldsControl.insert(idx, this.createFieldFormGroup());
    this.onOrderChange();
    this._cdr.detectChanges();
  }


  onOrderChange() {
    this.fieldsControl.updateValueAndValidity();
  }


  private getEmptyModel(): ParcelCsvExportProfileEditorModel {
    return {
      profile: {
        fileIncludesHeader: true,
        generateSemFile: false,
        shouldUseFields: false,
      },
      fields: []
    };
  }


  private buildForm() {
    const emptyProfile = this.getEmptyModel();

    this.form = this._fb.group({
      profile: this._fb.group(emptyProfile.profile),
      fields: this._fb.array([this.createFieldFormGroup()])
    });

    this.form.get("profile.shouldUseFields")?.valueChanges.subscribe(shouldUse => {
      if (shouldUse) {
        this.addInitialField();
      } else {
        this.removeInvalidFields();
      }
    });

    this.form.valueChanges.pipe(
      auditTime(10)
    ).subscribe(value => {
      value = _.cloneDeep(value);
      (value.fields as ParcelCsvExportField[]).forEach((f, idx) => f.ordinal = idx);

      mergeModelWithFormValue(this.model, value);
    });
  }


  private createFieldFormGroup(field: Partial<ParcelCsvExportField> = {}): FormGroup {
    const fieldForm = this._fb.group({
      // preserve entity id
      id: field.id || 0,
      fieldName: [field.fieldName || null, Validators.required],
      exportAs: field.exportAs || "",
      exportValue: {value: field.exportValue || null, disabled: field.fieldName !== "dummy"}
    });

    const exportValueCtrl = fieldForm.get("exportValue");
    fieldForm.get("fieldName").valueChanges
      .subscribe(v => v === "dummy" ? exportValueCtrl.enable() : exportValueCtrl.disable());

    return fieldForm;
  }


  private processProfile() {
    if (!this.model || !this.model.profile) {
      this.model.profile = this.getEmptyModel().profile;
    }
  }


  private processFields() {
    if (!this.model || !Array.isArray(this.model.fields) || !this.model.fields.length) {
      this.model.fields = this.getEmptyModel().fields;
    }

    if (this.model.fields.length > 1) {
      this.model.fields = _.orderBy(this.model.fields, "ordinal");
    }
  }


  private updateForm() {
    this.form.get("profile").patchValue(this.model.profile);
    claerFormArray(this.fieldsControl);
    this.model.fields.forEach(f => this.fieldsControl.push(this.createFieldFormGroup(f)));
    this._cdr.detectChanges();
  }


  private removeInvalidFields() {
    const validFieldControls = this.fieldsControl.controls.filter(c => c.valid);
    claerFormArray(this.fieldsControl);
    validFieldControls.forEach(c => this.fieldsControl.push(c));
  }


  private addInitialField() {
    // Ensure updateForm is done.
    Promise.resolve().then(() => {
      if (this.fieldsControl.length > 0) {
        return;
      }

      this.insertField(0);
    });
  }


  private loadFieldsList() {
    this._parcelCsvExportProfileService.getExportProfileFieldList().pipe(
      finalize(() => this._cdr.detectChanges())
    ).subscribe(list => this.parcelExportFields = list, () => { });
  }
}
