import { Component, OnDestroy, OnInit } from "@angular/core";

import * as _ from "lodash";
import { combineLatest, of, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { auditTime } from "rxjs/operators";

import * as Shared from "../index";
import { PasswordService } from "../index";
import { ShipperMainMenuItem } from "../models/config.models";
import { WizardStepName } from "../services/shipper-wizard-steps";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-navigation",
  templateUrl: "./navigation.component.html"
})
export class NavigationComponent implements OnInit, OnDestroy {
  public hasUnchangedPassword = true;
  public isAdmin = false;
  public isCentralShipper = false;
  public isCustomerService = false;
  public isImportProfilesVisible = false;
  public isPickupSectionVisible = false;
  public isSophia = false;
  public shipperMainMenuItems = ShipperMainMenuItem;
  public showImportProfiles = false;
  public wizardStepNames = WizardStepName;

  private _destroy$: Subject<void> = new Subject();
  private _menuItemsVisibleForUser = new Set<string>();

  constructor(
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _authenticationService: Shared.AuthenticationService,
    private _usersPasswordService: PasswordService,
    private _settingsService: Shared.ShipperSettingsService) { }


  public ngOnInit() {
    this.isCentralShipper = this._settingsService.isCentralShipper;
    this.isSophia = this._settingsService.isSophia;

    this._usersPasswordService.state$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(s => this.hasUnchangedPassword = s.isPasswordChangeRequired);

    combineLatest([
      this._authenticationService.isAdmin$, // 0
      this._authenticationService.isCustomerService$, // 1
    ]).pipe(
      auditTime(0)
    ).subscribe(result => {
      this.isAdmin = result[0];
      this.isCustomerService = result[1];
      this.loadVisibleMenuItems();
    });
    this._businessUnitSettingsService.settings$.subscribe(settings => {
      this.isImportProfilesVisible = settings.isMenuItemImportProfilesVisible;
      this.isPickupSectionVisible = settings.createPickupOrdersWithShipmentOrders;
      console.log()
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public isVisible(item: string): boolean {
    const isVisible = this.isAdmin ||
      this._menuItemsVisibleForUser.size === 0 ||
      this._menuItemsVisibleForUser.has(item);

    switch (item) {
      case ShipperMainMenuItem.PickupOrders:
        return isVisible && this.isPickupSectionVisible;
      case ShipperMainMenuItem.ImportProfiles:
        return isVisible && this.isImportProfilesVisible;
      default:
        return isVisible;
    }
  }

  private loadVisibleMenuItems() {
    let items$;

    switch (true) {
      case this.isAdmin:
        items$ = of([]);
        break;
      case this.isCustomerService:
        items$ = this._businessUnitSettingsService.getMenuItemsVisibleForCustomerService();
        break;
      default:
        items$ = this._businessUnitSettingsService.getMenuItemsVisibleForUser();
        break;
    }

    items$.subscribe(results => this._menuItemsVisibleForUser = new Set(results));
  }
}
