<div class="delete-all-notifications-dialog dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
  <h1>{{ 'confirm_deleting_all_notifications' | shpLocalize }}</h1>
  <p>{{ 'confirm_deleting_all_notifications_instructions' | shpLocalize:requiredDeleteVerificationText }}</p>
  <form (submit)="deleteMessages()" #f="ngForm">
    <div class="ctl">
      <input 
        id="delete-all-notifications-verification-text"
        autocomplete="off"
        type="text"
        name="verificationText"
        ngModel
        required
        [pattern]="requiredDeleteVerificationText"/>
    </div>
    <div class="buttons">
      <button [disabled]="!f.form.valid" id="delete-all-notifications-button" type="submit">{{ 'delete' | shpLocalize }}</button>
      <button id="button-close" type="button" (click)="dismiss()">{{ 'close' | shpLocalize }}</button>
    </div>
  </form>
</div> 