import { ModalComponent, DialogRef } from "ngx-modialog-7";
import { Component } from "@angular/core";

interface AppTableSettingsDialogContext {
  columnsList: any[];
  displayColumns: string[];
}

const defaultValue = [
  "pickupDate",
  "creationDateTimeUtc",
  "serviceSetCode",
  "parcelNr",
  "receiver",
  "referentialInfo",
  "weight",
  "cod",
];

@Component({
  templateUrl: "./table-settings-dialog.component.html",
  styles: [
    `
      shp-drag-and-drop-list {
        display: block;
        max-height: 100%;
        overflow-y: auto;
        padding: 0 16px 16px 0;
        margin: 0 0 16px 0;
      }
    `
  ]
})
export class AppTableSettingsDialog implements ModalComponent<AppTableSettingsDialogContext> {

  public columnsList: any[];
  public displayColumns: string[];

  constructor(
    public dialog: DialogRef<AppTableSettingsDialogContext>,
  ) {
    this.columnsList = dialog.context.columnsList;
    this.displayColumns = dialog.context.displayColumns;
  }

  close() {
    this.dialog.close(this.displayColumns);
  }

  dismiss() {
    this.dialog.dismiss();
  }

  setDefault() {
    this.dialog.close("reset");
  }
}