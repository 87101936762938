import { Router } from "@angular/router";
import { Component, Directive, Injectable, OnDestroy, OnInit } from "@angular/core";

import { forkJoin, from, of, Subject, Subscription } from "rxjs";
import { filter, mergeMap, take, takeUntil, tap } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";

import { default as DeepDiff } from "deep-diff";
import * as _ from "lodash";


import * as Shared from "../../../shared/index";
import * as SettingsModel from "../../models/settings.model";
import { SettingsService } from "../../services/settings.service";
import { ContextService } from "../../../shared/services/context.service";
import { WizardService } from "../../../shared/modules/wizard/services/wizard.service";

@Component({
  template: ''
})
export abstract class TenantSettingsEditorBase extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    protected settingsService: SettingsService,
    protected contextService: ContextService,
    public exceptionsHandlerService: Shared.ExceptionsHandlerService,
    protected toastr: ToastrService,
    protected wizardService?: WizardService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public isBusy = false;


  public hasSavingErrorOccured = false;


  public hasLoadingErrorOccured = false;


  public customerDetailId = null;
  public addressId = null;
  public tenantId = null;

  /**
   * Všetky nastavenia aktuálne vybraného CustomerDetail v jednom objekte.
   */
  public settings: SettingsModel.TenantSettings = null;


  public isPrintingSettingsPropertyVisible = (propertyName: string): boolean =>
    (this._printingSettingsVisibleProperties !== null) &&
    (this._printingSettingsVisibleProperties.findIndex(s => s === propertyName) >= 0);

  // Zoznam názvov editovateľných properties v rámci PrintingSettings nastavenia.
  private _printingSettingsVisibleProperties: string[] = null;

  private _settingsSnapshot: SettingsModel.TenantSettings = null;


  private createSettingsSnapshot() {
    this._settingsSnapshot = _.cloneDeep(this.settings);
  }


  private _settingsLoadingSubscription: Subscription = null;


  protected destroy$ = new Subject<void>();


  protected afterSettingsLoaded() {
  }


  protected loadLookups() {
    return of(true);
  }


  protected loadSettings = () => {
    this.isBusy = true;

    this._settingsLoadingSubscription =
      this.loadLookups().pipe(
        mergeMap(loaded => {
          return forkJoin([
            this.contextService.currentAddress.pipe(filter(ca => ca != null),take(1)),
            this.contextService.tenantId.pipe(filter(id => id != null),take(1))
          ])
        }),
        tap(result => {
          this.customerDetailId = result[0].customerDetailId;
          this.addressId = result[0].id;
          this.tenantId = result[1]
        }),
        mergeMap(result => {
          return this.settingsService.getConfigurationSettingsModel(this.customerDetailId, this.addressId);
        })
      ).subscribe(
        (model: SettingsModel.TenantSettingsModel) => {
          this.settings = model.tenantSettings;

          this._printingSettingsVisibleProperties = model.printingSettingsVisibleProperties;

          this.afterSettingsLoaded();
          this.createSettingsSnapshot();
          this.isBusy = false;
        },
        ex => {
          this.loggingService.logErrorData(ex, "Error loading settings.");

          let exception = this.exceptionsHandlerService.getExceptionInfo(ex);

          this.toastr.error(this.localizationService.getLocalizedExceptionString(exception));

          this.isBusy = false;
        }
      );
  }


  public save = () => {
    this.isBusy = true;
    this.hasSavingErrorOccured = false;

    this.settingsService.updateConfigurationSettings(this.customerDetailId, this.addressId, this.settings)
      .subscribe(() => {
        this.isBusy = false;
        this.toastr.success(this.localizationService.getLocalizedString("settings_successfully_saved"));

        this.createSettingsSnapshot();
      },
      ex => {
        this.hasSavingErrorOccured = true;

        let exception = this.exceptionsHandlerService.getExceptionInfo(ex);

        this.toastr.error(this.localizationService.getLocalizedExceptionString(exception));

        this.isBusy = false;
      });
  }

  public hasUnsavedChanges = (): boolean => {
    if (this.wizardService?.isActive) {
      return false;
    }

    return this.hasChanges();
  }

  public hasChanges(): boolean {
    // S pomocou DeepDiff-u si porovnáme akuálnu hodnotu this.settings s uloženou kópiou.
    const diff = DeepDiff.diff(this.settings, this._settingsSnapshot, (path, key) => { if (key === "country") return true; });
    // var diff = DeepDiff.diff(this.settings, this._settingsSnapshot);

    return Boolean(diff?.length);
  }

  ngOnInit() {
    this.contextService._currentAddress.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.loadSettings();
    });
  }


  ngOnDestroy() {
    if (this._settingsLoadingSubscription != null) {
      this._settingsLoadingSubscription.unsubscribe();
      this._settingsLoadingSubscription = null;
    }

    this.destroy$.next();
    this.destroy$.complete();
  }
}
