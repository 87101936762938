import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs"
import { filter, take, map, mergeMap, shareReplay } from "rxjs/operators";

import * as _ from "lodash";
import * as Shared from "../../shared/index"
import { ParcelCsvExportProfileEditorModel, ParcelCsvExportProfileWithFields } from "../models/parcel-csv-export-profile-with-fields.model"
import { ParcelCsvExportProfile } from "../models/parcel-csv-export-profile.model";

interface ParcelCsvExportProfileServiceCache {
  fields?: Observable<string[]>
}

@Injectable()
export class ParcelCsvExportProfileService extends Shared.ApiServiceBase {
  private _cache: ParcelCsvExportProfileServiceCache = {};

  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient,
    private _contextService: Shared.ContextService  ) {
    super(loggingService);
  }

  getExportProfileFieldList(): Observable<string[]> {
    if (!this._cache.fields) {
      this._cache.fields = this.processRequest<string[]>(this._http.get(`${Shared.API_URL}/parcel-csv-export-profile/fields`)).pipe(
        shareReplay(1)
      )
    }

    return this._cache.fields;
  }

  getExportProfile(): Observable<ParcelCsvExportProfileWithFields> {
    return this._contextService.currentCustomerDetail.pipe(
      filter(cd => !!cd),
      take(1),
      map(cd => cd.id),
      mergeMap(customerDetailId => this.processRequest<ParcelCsvExportProfileWithFields>(
        this._http.get(`${Shared.API_URL}/parcel-csv-export-profile/${customerDetailId}`)
      ))
    )
  }

  saveExportProfile(model: ParcelCsvExportProfileEditorModel): Observable<ParcelCsvExportProfile> {
    return model && model.profile && model.profile.id ?
      this.updateExportProfile(model) :
      this.saveNewExportProfile(model);
  }

  private saveNewExportProfile(model: ParcelCsvExportProfileEditorModel): Observable<ParcelCsvExportProfile> {
    return this._contextService.currentCustomerDetail.pipe(
      filter(cd => !!cd),
      take(1),
      map(cd => cd.id),
      mergeMap(customerDetailId => this.processRequest<ParcelCsvExportProfile>(
        this._http.post(`${Shared.API_URL}/parcel-csv-export-profile/${customerDetailId}`, model)
      ))
    )
  }

  private updateExportProfile(model: ParcelCsvExportProfileEditorModel): Observable<ParcelCsvExportProfile> {
    return this.processRequest<ParcelCsvExportProfile>(this._http.put(`${Shared.API_URL}/parcel-csv-export-profile/${model.profile.id}`, model));
  }
}
