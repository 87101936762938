<div class="export-all-shipments-dialog dialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
  <h1>{{ 'export_parcels' | shpLocalize }}</h1>
  <div class="d-flex flex-direction-column">
    <button (click)="exportParcels('all')">{{ 'export_all_parcels' | shpLocalize }}</button>
    <button *ngIf="!isCustomerService" class="mt-2" (click)="exportParcels('withLabelPrinted')">{{ 'export_parcels_with_label_printed' | shpLocalize }}</button>
    <button class="mt-2" (click)="exportParcels('withProtocolPrinted')">{{ 'export_parcels_with_protocol_printed' | shpLocalize }}</button>
  </div>
  <div class="mt-3 mt-3 d-flex justify-content-end">
    <button id="button-close" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>
</div>