import { Component } from "@angular/core";
import { Router } from "@angular/router";

import * as Shared from "../../shared/index";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipments",
  templateUrl: "./shipments.component.html"
})
export class ShipmentsComponent extends Shared.RoutedPageComponentBase {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }
}
