import { Component, OnInit, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";


import * as _ from "lodash";
import * as numeral from "numeral";

import * as Shared from "../index";
// import { isNumber } from "wijmo/wijmo";
import { debounceTime } from "rxjs/operators";
import { isNumber } from '@grapecity/wijmo';
import { SELECTOR_PREFIX } from "../prefix";


const UNIVERSAL_NUMBER_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UniversalNumberInputComponent),
  multi: true
}


const noop = () => { };


/**
 * Kontrolka podporujúca zadávanie čísla aj s bodkou aj s čiarkou ako desatinným oddeľovačom.
 */
@Component(
  {
    selector: SELECTOR_PREFIX + "-universal-number-input",
    templateUrl: "./universal-number-input.component.html",
    providers: [UNIVERSAL_NUMBER_INPUT_VALUE_ACCESSOR]
  }
)
export class UniversalNumberInputComponent implements ControlValueAccessor, OnInit {
  //private _f: FormGroup;
  private _inputControl: FormControl;
  private _value: number = null;
  private _min: number = null;
  private _max: number = null;

  private _onTouchedCallback: () => void = noop;
  private _onChangeCallback: (_: any) => void = noop;

  private _placeholder = "";
  private _isRequired = false;
  private _isReadOnly = false;


  public get inputControl() {
    return this._inputControl;
  }


  @Input()
  public set min(value: number) {
    if (typeof value === "undefined") {
      value = null;
    }

    this._min = value;
  }


  @Input()
  public set max(value: number) {
    if (typeof value === "undefined") {
      value = null;
    }

    this._max = value;
  }


  @Input()
  public set placeholder(value: string) {
    this._placeholder = value;
  }


  @Input()
  public set isRequired(value: boolean) {
    this._isRequired = value;
  }


  @Input()
  public set isReadOnly(value: boolean) {
    this._isReadOnly = value;
  }


  public get isReadOnly() {
    return this._isReadOnly;
  }


  public get placeholder() {
    return this._placeholder;
  }


  public get isRequired() {
    return this._isRequired;
  }


  private setValueAndNotifyChangeIfRequired(newValue: number) {
    if (newValue !== this._value) {
      this._value = newValue;

      this._onChangeCallback(newValue);
    }
  }


  private normalizeValue(obj: any) {
    //console.log("Normalizing value", obj);

    // Najprv nahradíme , bodkou, ak máme nejaký text.
    if (typeof obj !== "undefined" && obj !== null && typeof obj == "string") {
      obj = obj.toString().replace(",", ".");
    }

    //console.log("Adjusted value", obj, "type: ", typeof obj, isNumber(obj), +obj);

    if (typeof obj === "undefined" || obj === null || obj === "" || !isNumber(+obj)) {
      this.setValueAndNotifyChangeIfRequired(null);

      this._inputControl.setValue("", { onlySelf: true, emitEvent: false });
    } else {
      let numberValue = +obj;

      //console.log("Got number", numberValue);

      if (this._min !== null && numberValue < this._min) {
        numberValue = this._min;
      }

      if (this._max !== null && numberValue > this._max) {
        numberValue = this._max;
      }

      // Ako hodnotu si odložíme číslo
      this.setValueAndNotifyChangeIfRequired(numberValue);

      // Musíme si získať formátované číslo podľa aktuálnych locales.
      var numeralNumber = numeral(numberValue);
      var localizedNumberString = numeralNumber.format("0.00");

      this._inputControl.setValue(localizedNumberString, { onlySelf: true, emitEvent: false });
    }
  }


  public onBlur() {
    // Musíme najprv upratať číslo, čo sme zadali.
    this.normalizeValue(this._inputControl.value);

    //
    this._onTouchedCallback();
  }


  private writeValueInternal(obj: any) {
    this.normalizeValue(obj);
  }


  public writeValue(obj: any): void {
    this.writeValueInternal(obj);
  }


  public registerOnChange(fn: (_: any) => void): void {
    this._onChangeCallback = fn;
  }


  public registerOnTouched(fn: () => void): void {
    this._onTouchedCallback = fn;
  }


  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._inputControl.disable();
    } else {
      this._inputControl.enable();
    }
  }


  private handleInputControlValueChange(value: string) {

    let normalizedValue = value;

    // Najprv nahradíme , bodkou, ak máme nejaký text.
    if (typeof value !== "undefined" && value !== null && typeof value == "string") {
      normalizedValue = value.toString().replace(",", ".");
    }

    let numberValue = Number(normalizedValue);

    if (typeof normalizedValue === "undefined" || normalizedValue === null || normalizedValue === "" || isNaN(numberValue)) {
      this.setValueAndNotifyChangeIfRequired(null);

      this._inputControl.setValue(value, { onlySelf: true, emitEvent: false });
      return;
    }

    // Nevalidna hodnota
    if ((this._min !== null && numberValue < this._min) || (this._max !== null && numberValue > this._max)) {
      this.setValueAndNotifyChangeIfRequired(null);
      return;
    }

    // Ako hodnotu si odložíme číslo, ktore zadal uzivatel (bez normalizacie)
    this.setValueAndNotifyChangeIfRequired(numberValue);

    this._inputControl.setValue(value, { onlySelf: true, emitEvent: false });
  }


  public ngOnInit() {
    this._inputControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.handleInputControlValueChange(value);
    });
  }


  private initializeInputControl() {
    this._inputControl = new FormControl();
  }


  public numberPartKeyDown(e: KeyboardEvent) {
    if (
      // Povoliť: backspace, delete, tab, escape, enter.
      _.find([46, 8, 9, 27, 13], i => e.keyCode === i) ||
      // Povoliť: Ctrl/cmd+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+Z
      (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {

      return;
    }

    //console.log(e);

    // Ak nemáme číslo, tak ručíme default akciu - vypísanie požadovaného znaku.
    if (!_.find(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-", ".", ","], i => e.key === i)) {
      e.preventDefault();
    }
  }


  constructor() {
    this.initializeInputControl();
  }
}