import { Pipe, PipeTransform } from "@angular/core";
import { SELECTOR_PREFIX } from "../../shared/index";
import { User } from "../../user/models/user.model";
import { Product } from "../../shared/models/config.models";


@Pipe({
  name: SELECTOR_PREFIX + "ProductId"
})
export class ProductIdPipe implements PipeTransform {
  public transform(value, ...args: any[]) {
    if (typeof value === "undefined" || value == null) {
      return null;
    }


    if (!isNaN(value)) {
      // Ako prvý argument očakávame zoznam customer detailov, v ktorých hľadáme ten s ID z value.
      let id = +value;

      let products: Product[] = args[0];

      if (products) {
        let product = products.find(p => p.id === id);

        if (product) {
          return `${product.name} (${product.code})`;
        }
      }
    }

    return null;
  }
}