import { Component, OnDestroy, OnInit } from "@angular/core";

import { saveAs } from "file-saver";
import { Base64 } from "js-base64";
import * as _ from "lodash";
import * as moment from "moment";
import { CloseGuard, DialogRef, ModalComponent } from "ngx-modialog-7";
import { ToastrService } from "ngx-toastr";
import { Subscription, throwError } from "rxjs";
import { catchError, filter, finalize, mergeMap, tap } from "rxjs/operators";

import * as Shared from "../../shared/index";
import * as ConfigModel from "../../shared/models/config.models";
import * as CustomerModels from "../../shared/models/customer.models";
import { PrintProtocolsResponse } from "../models/shipment.model";
import { ShipmentService } from "../services/shipments.service";

const PROTOCOL_COPIES_NUMBER_STORAGE_KEY = "shipper.tenant.protocol-copies-number";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-daily-procedure-dialog",
  templateUrl: "./daily-procedure-dialog.component.html"
})
export class DailyProcedureDialogComponent implements CloseGuard, ModalComponent<any>, OnInit, OnDestroy {
  constructor(
    public dialog: DialogRef<any>,
    private _contextService: Shared.ContextService,
    private _shipmentsService: ShipmentService,
    private _localizationService: Shared.LocalizationService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _skDataConfigService: Shared.SkdataConfigService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _toastr: ToastrService
  ) {
    dialog.setCloseGuard(this);
  }


  /**
   * Vracia, či nie je možné dialóg zrušiť stlačením ESC alebo kliknutím mimo jeho plochu.
   */
  public beforeDismiss(): boolean | Promise<boolean> { return true; }


  public isExportToDpdAvailable = false;


  public isExportCityServiceDataAvailable = false;


  public hasMoreThanOneAddressEnabled = false;


  /**
   * Značí, či je EOD v móde exportu/tlače iba nespracovaných záznamov.
   */
  public isEodContinuous = false;


  public notExportedOrdersCount = 0;


  public notPrintedLabelsCount = 0;

  public notExportedCrCount = 0;

  public isAllAddressesPrintingSelected = false;


  public isSophia = false;

  public isCentralShipper = false;


  /**
   * Koľko kópií protokolu (či už akceptačného alebo COD) sa má vytlačiť.
   */
  public get protocolCopiesNumber(): number {
    let stringValue = localStorage.getItem(PROTOCOL_COPIES_NUMBER_STORAGE_KEY);

    if (typeof stringValue === "undefined" || stringValue == null) {
      return 1;
    }

    return parseInt(stringValue);
  }


  public set protocolCopiesNumber(value: number) {
    localStorage.setItem(PROTOCOL_COPIES_NUMBER_STORAGE_KEY, value.toString());
  }


  /**
   * Vracia, či nie je možné dialóg zatvoriť cez .close().
   */
  public beforeClose(): boolean | Promise<boolean> { return false; }


  private _forDate: Date = new Date();

  /**
   * Dátum, pre ktorý chceme generovať dokumenty/štítky.
   */
  public get forDate() {
    return this._forDate;
  }


  public set forDate(value: Date) {
    if (value != this._forDate) {
      this._forDate = value;
      this.loadNotPrintedLabelsCount();
    }
  }


  public isBusy = false;


  public statusMessage = "";


  public errorMessage = null;


  public wasSomethingSuccessfullyGenerated = false;

  /**
   * Záznam z importu.
   */
  public logEntries: Shared.LogEntry[] = [];




  private clearErrorMessage() {
    this.errorMessage = null;
  }


  private loadNotExportedOrdersCount() {
    if (this.isSophia) {
      return;
    }

    this._shipmentsService.getNotExportedShipmentsCount(this._currentAddress.customerDetailId, this._currentAddress.customId).subscribe(count => {
      this.notExportedOrdersCount = count;
    });
  }

  private loadNotExportedCrCount() {
    if (this.isSophia) {
      return;
    }

    this._shipmentsService.getNotExportedCrCount(this._currentAddress.customerDetailId, this._currentAddress.customId).subscribe(count => {
      this.notExportedCrCount = count;
    });
  }

  private loadNotPrintedLabelsCount() {
    this._shipmentsService.getNotPrintedLabelsCount(this._currentAddress.customerDetailId, this._currentAddress.customId, this.forDate).subscribe(count => {
      this.notPrintedLabelsCount = count;
    });
  }


  public startLabelsGeneration() {
    let selectedMoment = moment(this.forDate);

    let url = `${Shared.API_URL}/labels/customer-details/${this._currentAddress.customerDetailId}/addresses/${this._currentAddress.customId}/dates/${selectedMoment.format("YYYY-MM-DD")}`;
    let filename = `labels-${selectedMoment.format("YYYY-MM-DD")}.pdf`;

    if (this.isEodContinuous) {
      if (this.isAllAddressesPrintingSelected) {
        url = `${Shared.API_URL}/labels/customer-details/${this._currentAddress.customerDetailId}/continuous`;
      } else {
        url = `${Shared.API_URL}/labels/customer-details/${this._currentAddress.customerDetailId}/addresses/${this._currentAddress.customId}/continuous`;
      }
      filename = `labels-${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`;
    }

    const parameters = {
      customerDetailId: this._currentAddress.customerDetailId,
      orderIds: null,
      parcelIds: null,
      senderAddressId: null
    };

    this._shipmentsService.printLabels(url, parameters, filename, () => {
      this.isBusy = true;
      this.clearErrorMessage();
      this.logEntries = [];
      this.statusMessage = this._localizationService.getLocalizedString("status_message_generating_labels");
    }).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => {
      this.wasSomethingSuccessfullyGenerated = true;
      this.loadNotPrintedLabelsCount();
    }, ex => {
      if (ex.key) {
        this.errorMessage = this._localizationService.getLocalizedString(ex.key);
      } else {
        this.errorMessage = this._localizationService.getLocalizedString("daily_procedure_error_generating_labels");
      }
    });
  }


  public generateAcceptanceProtocol() {
    this.isBusy = true;
    this.clearErrorMessage();
    this.logEntries = [];
    this.statusMessage = this._localizationService.getLocalizedString("status_message_generating_acceptance_protocol");

    const selectedMoment = moment(this.forDate);
    const url = `${Shared.API_URL}/acceptance-protocol/${this._localizationService.currentCultureCode}/customer-details/${this._currentAddress.customerDetailId}/addresses/${this._currentAddress.customId}/dates/${selectedMoment.format("YYYY-MM-DD")}/${this.protocolCopiesNumber}`;
    const filename = `acceptance-protocol-${selectedMoment.format("YYYY-MM-DD")}.pdf`;

    this._shipmentsService.printProtocol("GET", url, filename).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => {
      this.wasSomethingSuccessfullyGenerated = true;
    }, ex => {
      if (ex.key) {
        this.errorMessage = this._localizationService.getLocalizedString(ex.key);
      } else {
        this.errorMessage = this._localizationService.getLocalizedString("daily_procedure_error_generating_acceptance_protocol");
      }
    });
  }


  public generateProtocols() {
    this.isBusy = true;
    this.clearErrorMessage();
    this.logEntries = [];
    this.statusMessage = this._localizationService.getLocalizedString("status_message_generating_acceptance_protocol");

    const url = this.isAllAddressesPrintingSelected ?
      `${Shared.API_URL}/protocols/${this._localizationService.currentCultureCode}/customer-details/${this._currentAddress.customerDetailId}/continuous/${this.protocolCopiesNumber}` :
      `${Shared.API_URL}/protocols/${this._localizationService.currentCultureCode}/customer-details/${this._currentAddress.customerDetailId}/addresses/${this._currentAddress.customId}/continuous/${this.protocolCopiesNumber}`;
    const filename = `protocols-${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`;

    this._shipmentsService.printProtocols(url)
      .subscribe(data => {
        if (!data.protocolsFile || !data.protocolsFile.data.length) {
          // Tlačili sme.
          this._toastr.success(this._localizationService.getLocalizedString("protocol_sent_to_printer"));
        }

        this.wasSomethingSuccessfullyGenerated = true;
        this.isBusy = false;

        if (!this.isCentralShipper) {
          this.exportToDpd().pipe(
            finalize(() => this.savePrintProtocolsResults(data, filename))
          ).subscribe(() => { }, () => { });
        } else {
          this.savePrintProtocolsResults(data, filename);
        }
      }, reason => {
        // Generovanie sa nepodarilo.
        this.isBusy = false;

        if (reason.key) {
          this.errorMessage = this._localizationService.getLocalizedString(reason.key);
        } else {
          this.errorMessage = this._localizationService.getLocalizedString("daily_procedure_error_generating_acceptance_protocol");
        }
      });
  }

  private savePrintProtocolsResults(data: PrintProtocolsResponse, protocolFileName: string) {
    if (data.protocolsFile) {
      const blob = new Blob([Base64.toUint8Array(data.protocolsFile.data)], { type: `${data.protocolsFile.mediaType};charset=${data.protocolsFile.encoding}` });
      saveAs(blob, protocolFileName, true);
    }

    for (let i = 0; i < data.outputFile.length; i++) {
      let file = data.outputFile[i];
      if (file.outputText && file.outputText.length > 0) {
        let blob = new Blob(["\ufeff", file.outputText], { type: "text/csv;charset=utf-8" });
        saveAs(blob, file.outputName);
      }
    }
  }

  public generateCodList() {
    // V tomto móde sa COD tlačí s akceptačným protokolom.
    if (this.isEodContinuous) {
      return;
    }

    this.isBusy = true;
    this.clearErrorMessage();
    this.logEntries = [];
    this.statusMessage = this._localizationService.getLocalizedString("status_message_generating_cod_takeover_protocol");

    const selectedMoment = moment(this.forDate);
    const url = `${Shared.API_URL}/cod-takeover-protocol/${this._localizationService.currentCultureCode}/customer-details/${this._currentAddress.customerDetailId}/addresses/${this._currentAddress.customId}/dates/${selectedMoment.format("YYYY-MM-DD")}/${this.protocolCopiesNumber}`;
    const filename = `cod-takeover-protocol-${selectedMoment.format("YYYY-MM-DD")}.pdf`;

    this._shipmentsService.printProtocol("GET", url, filename).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => {
      this.wasSomethingSuccessfullyGenerated = true;
    }, ex => {
      if (ex.key) {
        this.errorMessage = this._localizationService.getLocalizedString(ex.key);
      } else {
        this.errorMessage = this._localizationService.getLocalizedString("daily_procedure_error_generating_cod_takeover_protocol");
      }
    });
  }


  public exportCityServiceData() {
    this.isBusy = true;
    this.clearErrorMessage();
    this.logEntries = [];

    this._shipmentsService.exportCityServiceData(this._currentAddress.customerDetailId)
      .subscribe(logEntries => {
        this.logEntries = logEntries;
        this.wasSomethingSuccessfullyGenerated = true; // Aby sme vynútili refresh zoznamu.
        this.isBusy = false;

        this.loadNotExportedOrdersCount();
      }, ex => {
        let exception = this._exceptionsHandlerService.getExceptionInfo(ex);
        this.errorMessage = this._localizationService.getLocalizedExceptionString(exception);
        this.isBusy = false;
      });
  }


  public exportToDpd() {
    this.isBusy = true;
    this.clearErrorMessage();
    this.logEntries = [];

    let customerDetailId = this.isAllAddressesPrintingSelected ? null : this._currentAddress.customerDetailId;

    return this._shipmentsService.exportShipmentsToDpd(customerDetailId).pipe(
      tap(logEntries => {
        this.logEntries = logEntries;
        this.wasSomethingSuccessfullyGenerated = true; // Aby sme vynútili refresh zoznamu.
        this.isBusy = false;

        this.loadNotExportedOrdersCount();
      }),
      catchError(ex => {
        let exception = this._exceptionsHandlerService.getExceptionInfo(ex);
        this.errorMessage = this._localizationService.getLocalizedExceptionString(exception);
        this.isBusy = false;

        return throwError(ex);
      })
    );
  }


  public manualExportToDpd() {
    this.exportToDpd().subscribe(() => { }, () => { });
  }


  public close() {
    if (this.wasSomethingSuccessfullyGenerated) {
      this._shipmentsService.notifyShouldRefreshShipmentOrdersOrParcels();
    }

    this.dialog.close();
  }


  public markAllCRsAsReadyForExport() {
    this.isBusy = true;
    this.clearErrorMessage();
    this.logEntries = [];

    this._shipmentsService.markCollectionRequestsAsReadyForExportToDpd(this._currentAddress.customerDetailId, this._currentAddress.customId).subscribe(n => {
      this.wasSomethingSuccessfullyGenerated = true; // Aby sme vynútili refresh zoznamu.

      this._toastr.success(this._localizationService.getLocalizedString("collection_requests_successfully_marked_for_export", n));

      this.loadNotExportedCrCount();

      this.isBusy = false;
    }, ex => {
      let exception = this._exceptionsHandlerService.getExceptionInfo(ex);

      this.errorMessage = this._localizationService.getLocalizedExceptionString(exception);
      this.isBusy = false;
    });
  }


  private _currentAddressSubscription: Subscription = null;
  private _hasMoreThanOneAddressEnabledSubscription: Subscription = null;
  private _currentAddress: CustomerModels.Address = null;


  public ngOnInit() {
    this.isSophia = this._shipperSettingsService.isSophia;
    this.isCentralShipper = this._shipperSettingsService.isCentralShipper;

    this._currentAddressSubscription = this._contextService.currentAddress
      .subscribe(currentAddress => {
        this._currentAddress = currentAddress;

        this.loadNotExportedOrdersCount();
        this.loadNotPrintedLabelsCount();
        this.loadNotExportedCrCount();
      });

    this._businessUnitSettingsService.getIsEodContinuous()
      .subscribe(value => this.isEodContinuous = value);

    this._hasMoreThanOneAddressEnabledSubscription = this._contextService.addresses.pipe(
      filter(value => value != null)
    ).subscribe(addresses => {
      this.hasMoreThanOneAddressEnabled = addresses.length > 1;
    });

    this._contextService.currentCustomerDetail.pipe(
      filter(cd => cd != null),
      mergeMap((cd: CustomerModels.CustomerDetail) => {
        return this._skDataConfigService.getAllowedProductsAndAdditionalServices(cd.id);
      })
    ).subscribe(products => {
      this.isExportCityServiceDataAvailable = _.some(products, ["code", ConfigModel.WellKnownProductCodes.CityService]);
    });
  }


  public ngOnDestroy() {
    if (this._currentAddressSubscription != null) {
      this._currentAddressSubscription.unsubscribe();
    }

    if (this._hasMoreThanOneAddressEnabledSubscription != null) {
      this._hasMoreThanOneAddressEnabledSubscription.unsubscribe();
    }
  }
}
