import { Component, Inject, Input, OnDestroy, OnInit, Optional } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { WizardPopupComponent } from "../../modules/wizard/components/wizard-popup.component";
import { WizardDataProvider, WIZARD_DATA_PROVIDER } from "../../modules/wizard/models";
import { WizardService } from "../../modules/wizard/services/wizard.service";
import { SELECTOR_PREFIX } from "../../prefix";
import { ShipperWizardService } from "../../services/shipper-wizard.service";


@Component({
  // tslint:disable-next-line: component-selector
  selector: `${SELECTOR_PREFIX}-wizard-navigation`,
  templateUrl: "./wizard-navigation.component.html",
  host: {
    class: "wizard__navigation"
  }
})
export class ShipperWizardNavigationComponent implements OnInit, OnDestroy {
  @Input() isPreviousDisplayed = true;
  @Input() isNextDisplayed = true;

  @Input() isChapterNavigationDisplayed = true;
  @Input() shouldExitOnChapterNavigation = false;

  @Input() finishLabel = "";
  @Input() nextLabel = "";
  @Input() previousLabel = "";

  hasNextStep = false;
  hasPreviousStep = false;
  isOnFinalStep = false;
  hasNextChapter = false;
  hasPreviousChapter = false;

  private destroy$ = new Subject<void>();

  constructor(
    private wizardService: WizardService,
    private shipperWizardService: ShipperWizardService,
    @Inject(WIZARD_DATA_PROVIDER) private wizardDataProvider: WizardDataProvider,
    @Optional() private parentWizardPopup: WizardPopupComponent
  ) { }

  get hasUserCompletedMandatorySteps(): boolean {
    return this.shipperWizardService.hasUserCompletedMandatorySteps;
  }

  get canGoToNextStep(): boolean {
    return this.shipperWizardService.canGoToNextStep;
  }

  ngOnInit() {
    this.observeWizard();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  next() {
    this.wizardService.goToNextStep();
  }

  previous() {
    this.wizardService.goToPreviousStep();
  }

  finish() {
    this.wizardService.finish();
  }

  onNextChapter() {
    this.shouldExitOnChapterNavigation ?
      this.wizardService.completeCurrentChapter() :
      this.wizardService.goToNextChapter();
  }

  onPreviousChapter() {
    this.shouldExitOnChapterNavigation ?
      this.wizardService.completeCurrentChapter() :
      this.wizardService.goToPreviousChapter();
  }

  private observeWizard() {
    this.wizardService.state$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(state => {
      const step = state.currentStep;
      const chapter = state.currentChapter;

      this.hasNextStep = Boolean(this.wizardDataProvider.getNextStep(step?.name));
      this.hasPreviousStep = Boolean(this.wizardDataProvider.getPreviousStep(step?.name));
      this.hasNextChapter = Boolean(this.wizardDataProvider.getNextChapter(chapter?.name));
      this.hasPreviousChapter = Boolean(this.wizardDataProvider.getPreviousChapter(chapter?.name));

      this.chapterIsOnFinalStep();

      if (this.parentWizardPopup) {
        this.destroy$.next();
      }
    });
  }

  private chapterIsOnFinalStep() {
    const currentStepName = this.wizardService.currentStep?.name;
    const currentChapter = this.wizardService.currentChapter;
    const nextChapter = this.wizardDataProvider.getNextChapter(currentChapter?.name);

    this.isOnFinalStep = !nextChapter && currentChapter?.lastStepName === currentStepName;
  }
}
