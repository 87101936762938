import { EntityDataBaseInteractive } from "../../shared/models/base.model";

export interface User extends EntityDataBaseInteractive {
  tenantId: number;
  name: string;
  loginName: string;
  passwordHash: string;
  email: string;
  languageCode: string;
  disabledSinceDateUtc: Date;
  authenticationType: string;
  isAuthenticated: boolean;
  roles: UserRole[];
}


export interface UserModel {
  id: number;
  tenantId: number;
  name: string;
  loginName: string;
  email: string;
  languageCode: string;
  disabledSinceDateUtc: Date;
  password: string;
  roles: UserRole[];
  /** Customer tenant */
  customerDetailIds: number[];
  /** Used for password validation */
  customerDetailId: number;
}

export interface ChangePasswordModel {
  userId: number;
  customerDetailId: number;
  oldPassword: string;
  newPassword: string;
}

export interface ResetPasswordModel {
  resetToken: string;
  login: string;
  password: string;
  passwordRepeat: string;
}

export enum UserRole {
  MasterAdmin = "MasterAdmin",
  TenantAdmin = "TenantAdmin",
  TenantUser = "TenantUser",
  CustomerAdmin = "CustomerAdmin",
  CustomerUser = "CustomerUser",

  // Customer service
  CustomerService = "CustomerService",

  // Insights
  InsightsAdmin = "InsightsAdmin",
  InsightsUser = "InsightsUser",
  InsightsManager = "InsightsManager",
  InsightsFinances = "InsightsFinances",
  InsightsProofOfDelivery = "InsightsProofOfDelivery",
  InsightsCollectionRequests = "InsightsCollectionRequests"
}

export enum UserOperation {
  AccessShipper = "AccessShipper",
  ImportNewTenantConfiguration = "ImportNewTenantConfiguration",
  ManageAdminSettings = "ManageAdminSettings",
  ManageCommonSettings = "ManageCommonSettings",
  ManageShipments = "ManageShipments",
  ManageRecipients = "ManageRecipients",
  ManageConfigurations = "ManageConfigurations",
  ManageUsers = "ManageUsers",
  ManageShipperUsers = "ManageShipperUsers",
  ManageCustomerUsers = "ManageCustomerUsers",
  ManageCustomerServiceUsers = "ManageCustomerServiceUsers",
  ManageInsightsUsers = "ManageInsightsUsers",
  ManageTenants = "ManageTenants",
  ViewConfigurations = "ViewConfigurations",
  ViewUsers = "ViewUsers",
  UseAllCustomerAddresses = "UseAllCustomerAddresses",
  UseAllAllowedProducts = "UseAllAllowedProducts"
}

export const shipperMainRoles: ReadonlySet<UserRole> = new Set([
  UserRole.TenantAdmin,
  UserRole.TenantUser,
  UserRole.CustomerAdmin,
  UserRole.CustomerUser,
  UserRole.CustomerService
]);

export const insightsMainRoles: ReadonlySet<UserRole> = new Set([
  UserRole.InsightsAdmin,
  UserRole.InsightsUser
]);

export const additionalInsightsRoles: ReadonlySet<UserRole> = new Set([
  UserRole.InsightsManager,
  UserRole.InsightsFinances,
  UserRole.InsightsProofOfDelivery,
  UserRole.InsightsCollectionRequests
]);
