import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Modal, overlayConfigFactory } from "ngx-modialog-7";
import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from "rxjs";
import { catchError, filter, finalize, switchMapTo, take, tap } from "rxjs/operators";

import { AppUpdateInfoDialog } from "../components/app-update-info-dialog.component";
import { NewVersionInfoDialog } from "../components/new-version-info-dialog.component";
import { APP_VERSION } from "../globals";
import { newUpdateInfo, UpdateInfo } from "../models/update-info.model";
import { ApiServiceBase, API_URL, AuthenticationService, LoggingService } from "./../index";
import { GlobalEventsStreamService, UserLoginEvent } from "./global-events-stream.service";
import { ShipperTenantVersionInfoService } from "./shipper-tenant-version-info.service";

@Injectable()
export class UpdateService extends ApiServiceBase {
  readonly updateInfo: Observable<UpdateInfo>;

  private _subscription = new Subscription();
  private _updateInfo = new BehaviorSubject<UpdateInfo>(newUpdateInfo());


  constructor(
    loggingService: LoggingService,
    private _http: HttpClient,
    private _authenticationService: AuthenticationService,
    private _modal: Modal,
    private _shipperTenantVersionInfoService: ShipperTenantVersionInfoService,
    private _globalEventsStreamService: GlobalEventsStreamService
  ) {
    super(loggingService);
    this.initUpdateInfoCheck();
    this.initLatestUsedVersionForUserCheck();

    this.updateInfo = this._updateInfo.asObservable();
  }


  private get notificationTimerInMs(): number {
    return this._updateInfo.getValue().notifyEveryMinutes * 60 * 1000;
  }


  /**
   * Shows the "update available" dialog when the current version is deprecated.
   *
   * Note: https://jira.fores.group/browse/SHIPPER-333 - shows when user
   * wants to create a new shipment.
   */
  showVersionDeprecatedDialog() {
    this._updateInfo.pipe(
      take(1)
    ).subscribe(updateInfo => {
      if (!updateInfo.isDeprecated) {
        return;
      }

      this._modal.open(
        AppUpdateInfoDialog,
        overlayConfigFactory({updateInfo, isDeprecatedView: true})
      );
    });
  }


  startInstallation() {
    return this.processRequest(this._http.get(`${API_URL}/start-installation`)).pipe(
      catchError(ex => {
        this.loggingService.logErrorData(ex, "Error getting information about new Shipper version.");
        return Observable.throw(ex);
      })
    );
  }


  /** Initializes check of the update information for the current user. */
  private initUpdateInfoCheck() {
    const subscription = combineLatest([
      this._authenticationService.user,
      timer(0, this.notificationTimerInMs)
    ]).subscribe(([user]) => {
      user ?
        this.getUpdateInfo() :
        this._updateInfo.next(newUpdateInfo());
    });

    this._subscription.add(subscription);
  }


  /** Gets update information from the API. */
  private getUpdateInfo() {
    this.processRequest<UpdateInfo>(this._http.get(`${API_URL}/update-info`))
      .pipe(
        finalize(() => this._shipperTenantVersionInfoService.onAdminApiResponse())
      ).subscribe(
        updateInfo => this.proccessUpdateInfo(updateInfo),
        ex => this.loggingService.logErrorData(ex, "Error getting information about new Shipper version.")
      );
  }


  /**
   * Emits the `UpdateInfo` obtained from the API and reinitializes
   * the update information check if the interval in the obtained
   * `UpdateInfo` differs from the current one.
   */
  private proccessUpdateInfo(updateInfo: UpdateInfo) {
    const currentNotificationTimerInMin = this._updateInfo.getValue().notifyEveryMinutes;

    this._updateInfo.next(updateInfo);

    if (currentNotificationTimerInMin !== updateInfo.notifyEveryMinutes) {
      this._subscription.unsubscribe();
      this._subscription = new Subscription();

      this.initUpdateInfoCheck();
    }
  }


  /** Initializes check of the latest version used by the current user. */
  private initLatestUsedVersionForUserCheck() {
    // Wait for the NewVersionInfo user login event.
    this._globalEventsStreamService.userLoginEvents$.pipe(
      filter(event => event.current === UserLoginEvent.NewVersionInfo),
      switchMapTo(this._authenticationService.user.pipe(take(1))),
      filter(u => Boolean(u)),
    ).subscribe(user => {
      // If this is the user's first login save the record immediately and
      // don't show the "new version information" dialog.
      user.isFirstLogin ?
        this.sendVersionUsedInfo(APP_VERSION) :
        this.resolveLastestUserVersionForUser();
    });
  }


  /**
   * Gets and compares the latest version used by the current user.
   * If the current version is higher opens the "new version information" dialog.
   */
  private resolveLastestUserVersionForUser() {
    this.getLatestUsedVersion().subscribe(latestUsedVersion => {
      const isCurrentVersionHigher =
          this._shipperTenantVersionInfoService.compareVersions(APP_VERSION, latestUsedVersion) > 0;

      if (isCurrentVersionHigher) {
        this.openNewVersionDialog(APP_VERSION);
      } else {
        this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.NewVersionInfo);
      }
    });
  }


  /**
   * Opens the "new version information" dialog.
   *
   * Note: https://jira.fores.group/browse/SHIPPER-326
   */
  private openNewVersionDialog(version: string) {
    this._modal.open(
      NewVersionInfoDialog,
      overlayConfigFactory({ version })
    )
    .result
    .finally(() => this.sendVersionUsedInfo(version));
  }


  /** Gets the latest version used by the current user. */
  private getLatestUsedVersion(): Observable<string> {
    return this.processRequest<string>(this._http.get(`${API_URL}/latest-used-version`)).pipe(
      catchError(() => of(APP_VERSION))
    );
  }


  /** Saves the record of the latest version used by the current user. */
  private sendVersionUsedInfo(version: string) {
    this.processRequest<void>(this._http.post(`${API_URL}/latest-used-version`, {version})).pipe(
      tap(() => this._globalEventsStreamService.resolveUserLoginEvent(UserLoginEvent.NewVersionInfo))
    ).subscribe(() => { }, () => { });
  }
}
