import * as _ from "lodash";

export class SupportedLanguage {
    readonly code: string;
    readonly codeUpper: string;
    readonly codeCamelCase: string;
    readonly localizationKey: string;
    readonly countryCode: string;

    constructor(languageCode: string, countryCode: string) {
        this.code = languageCode;
        this.countryCode = countryCode;
        this.codeUpper = languageCode.toUpperCase();
        this.codeCamelCase = _.camelCase(languageCode);
        this.localizationKey = `language_${countryCode.toUpperCase()}`;
    }
}

export const SupportedLanguages = [
    new SupportedLanguage("en", "en"),
    new SupportedLanguage("de", "de"),
    new SupportedLanguage("nl", "nl"),
    new SupportedLanguage("be", "be"),
    new SupportedLanguage("fr", "fr"),
    new SupportedLanguage("it", "it"),
    new SupportedLanguage("cs", "cz"),
    new SupportedLanguage("sk", "sk"),
];
