import { Component, OnInit } from "@angular/core"

import { finalize } from "rxjs/operators";

import * as Shared from "./../../shared/index"
import * as _ from "lodash";
import { ModalComponent, DialogRef } from "ngx-modialog-7";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ExceptionInfo, BusinessUnitSettingsService } from "./../../shared/index";
import { validateEmail } from "../../shared/validators";

const formControls = {
  senderEmailAddress: [null, [Validators.required, validateEmail]],
  recipientEmailAddress: [null, [Validators.required, validateEmail]],
  subject: [null, [Validators.required]],
  content: [null]
}

@Component({
  selector: Shared.SELECTOR_PREFIX + "-email-configuration-test-dialog",
  templateUrl: "./email-configuration-test-dialog.component.html",
  host: {
    class: "email-configuration-test-dialog"
  }
})
export class EmailSettingsEditorTestDialogComponent implements ModalComponent<any>, OnInit {
  public isBusy = false;
  public form: FormGroup;
  public testEmailStatusMessage: string;
  public testEmailErrorMessage: string;

  constructor(
      public dialog: DialogRef<any>,
      private fb: FormBuilder,
      private buSettingsService: BusinessUnitSettingsService) {
  }


  public ngOnInit() {
    this.createForm();
  }


  public close() {
    this.dialog.close();
  }


  public dismiss() {
    this.dialog.dismiss();
  }


  public sendTestEmail() {
    this.isBusy = true;
    this.testEmailStatusMessage = null;
    this.testEmailErrorMessage = null;
    
    this.buSettingsService.sendTestEmail({
      ...this.form.value,
      customerDetailId: this.dialog.context.customerDetailId
    }).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => {
      this.testEmailStatusMessage = "test_email_status_success";
      }, (err: ExceptionInfo) => {
      this.testEmailStatusMessage = "test_email_status_fail";
      this.testEmailErrorMessage = err.message;
    });
  }

  private createForm() {
    this.form = this.fb.group(formControls);
    this.form.patchValue({
      senderEmailAddress: this.dialog.context.senderEmail
    });
  }
}