import { Pipe, PipeTransform } from "@angular/core"
import * as numeral from "numeral"
import { SELECTOR_PREFIX } from "../prefix";

// import { SELECTOR_PREFIX } from "../index"


@Pipe({ name: SELECTOR_PREFIX + "Numeral" })
export class NumeralPipe implements PipeTransform {
  public transform(value, args: any[]) {
    if (typeof value === "undefined" || value == null) {
      return null;
    }

    if (!isNaN(value) && value !== Infinity) {
      let format = "";

      if (args && args.length > 0) {
        
        let formatParameter = args[0];

        // Niekedy dostaneme argument ako string nezabalen� do pola.
        if (typeof args === "string") {
          formatParameter = args;
        }
        if (formatParameter && typeof formatParameter === "string") {
          format = formatParameter;
        }
      }

      let n = numeral(value);

      return n.format(format);
    }

    return null;
  }
}