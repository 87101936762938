import { Component, OnInit, OnDestroy } from "@angular/core";

import { Subject } from 'rxjs';
import { finalize, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { DialogRef, ModalComponent, CloseGuard } from "ngx-modialog-7";
import { LocalizationService } from "../../shared/index";
import { NotificationsService } from "../services/notifications.service";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-delete-all-notifications-dialog",
  templateUrl: "./delete-all-notifications-dialog.component.html"
})
export class DeleteAllNotificationsDialogComponent implements CloseGuard, ModalComponent<any>, OnInit, OnDestroy {
  requiredDeleteVerificationText = "DELETE";

  private _customerDetailId: number;
  private _destroy$ = new Subject<void>();
  private _isBusy = false;
  private _statusMessage = "";


  constructor(
    public dialog: DialogRef<any>,
    private _contextService: Shared.ContextService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _localizationService: LocalizationService,
    private _log: Shared.LoggingService,
    private _notificationsService: NotificationsService,
    private _toastr: ToastrService
  ) {
    dialog.setCloseGuard(this);
  }


  get isBusy() {
    return this._isBusy;
  }


  get statusMessage() {
    return this._statusMessage;
  }


  ngOnInit() {
    this._contextService.currentCustomerDetail.pipe(
      takeUntil(this._destroy$)
    ).subscribe(cd => this._customerDetailId = cd.id);
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  /**
   * Prevent modal dismiss while delete request is pending.
   */
  beforeDismiss(): boolean | Promise<boolean> { return this.isBusy; }


  beforeClose(): boolean | Promise<boolean> { return false; }


  deleteMessages() {
    if (!this._customerDetailId) {
      this.dismiss();
    }

    this._isBusy = true;

    this._notificationsService.deleteNotifications(this._customerDetailId).pipe(
      finalize(() => this._isBusy = false)
    ).subscribe(() => {
        this._toastr.success(this._localizationService.getLocalizedString("all_notifications_successfully_deleted"))
        this.close();
      },
      ex => {
        ex = this._exceptionsHandlerService.getExceptionInfo(ex);
        this._log.logErrorData(ex, "Error deleting all notifications");
        this._toastr.error(`${this._localizationService.getLocalizedString("error_deleting_all_notifications")}:\n${this._localizationService.getLocalizedExceptionString(ex)}`);
      });
  }


  close() {
    this.dialog.close();
  }

  dismiss() {
    this.dialog.dismiss();
  }
}