import { Injectable } from "@angular/core";

import { Observable, ReplaySubject } from "rxjs";

import { ShipperTenantVersionInfoText } from "../models/shipper-tenant-version-info-text.model";
import { LocalizationService } from "./localization.service";

@Injectable()
export class ShipperTenantVersionInfoService {
  private _canLoadChangeLog$ = new ReplaySubject<boolean>();

  constructor(
    private _localizationService: LocalizationService
  ) { }

  get canLoadChangeLog$(): Observable<boolean> {
    return this._canLoadChangeLog$.asObservable();
  }

  onAdminApiResponse() {
    this._canLoadChangeLog$.next(true);
  }

  compareVersions(a: string, b: string): number {
    if (b == null) {
      if (a != null) {
        return 1;
      }

      return 0;
    }

    const aParts = a.split(".");
    const bParts = b.split(".");

    // 0 = same, 1 = higher, -1 = older
    let result = 0;

    for (let i = 0; i < aParts.length && result === 0; i++) {
      result = Number(aParts[i]) - Number(bParts[i]);
    }

    if (result === 0) {
      return 0;
    }

    return result > 0 ? 1 : -1;
  }

  getChangeLogContent(texts: ShipperTenantVersionInfoText[]): string {
    if (!Array.isArray(texts) || !texts.length) {
      return "";
    }

    const currentCultureCode = this._localizationService.currentCultureCode.toLowerCase();

    let changeLog = texts
        .find(t => t.languageCode === currentCultureCode && t.contentType === "changeLog");

    // Fallback to eng.
    if (!changeLog || !changeLog.content || !changeLog.content.length) {
      changeLog = texts
        .find(t => t.languageCode === "en" && t.contentType === "changeLog");
    }

    return changeLog ? changeLog.content : "";
  }
}
