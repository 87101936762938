<div class="dialog shipments-import-dialog" wizardTag="importShipmentsDialog">
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>

  <h1 [innerHtml]="'import_shipment_orders' | shpLocalize" wizardTag="importShipmentsDialogParams"></h1>

  <div class="panel message" >
    <ng-container *ngIf="readFileErrors">
      <div *ngFor="let error of readFileErrors" class="error">{{ error }}</div>
    </ng-container>
  </div>

  <div class="ctl">
    <label for="selected-file">{{ 'file_to_import' | shpLocalize }}</label>
    <div class="file-input-group">
      <input id="input-filename" type="text" [(ngModel)]="selectedFileName" readonly="readonly" />
      <input type="file" name="selectedFile" id="selected-file" #fileInput (change)="selectedFileChanged($event);" />
      <label for="selected-file"><span class="ic-file-download-2 ic-18"></span> {{ 'select_file' | shpLocalize }}...</label>
    </div>
  </div>

  <div class="ctl" *ngIf="importProfiles.length || isWizardActive">
    <label for="select-import-profile">{{ 'import_select_profile' | shpLocalize }}</label>
    <select id="select-import-profile" name="select-import-profile" [(ngModel)]="importProfile">
      <option [value]="-1">{{ 'import_profile_default' | shpLocalize }}</option>
      <option [value]="imp.id" *ngFor="let imp of dialog.context.importProfiles">{{imp.name}}</option>
    </select>
  </div>

  <div class="ctl">
    <label for="select-default-product">{{ 'import_default_product' | shpLocalize }}</label>
    <select id="select-default-product" name="select-default-product" [(ngModel)]="defaultProductId">
      <option [ngValue]="null">--</option>
      <option [value]="product.id" *ngFor="let product of dialog.context.products">{{product.name}}</option>
    </select>
  </div>

  <div class="ctl" wizardTag="importShipmentsDialogParams">
    <label for="select-import-encoding">{{ 'import_encoding' | shpLocalize }}</label>
    <select id="select-import-encoding" name="select-import-encoding" [(ngModel)]="importEncoding">
      <option *ngIf="canUseEncodingFromProfile()"
              [value]="''">{{ 'import_encoding_from_profile' | shpLocalize }}</option>
      <option [value]="'utf-8'">UTF-8</option>
      <option [value]="'windows-1250'">Windows-1250</option>
      <option [value]="'windows-1252'">Windows-1252</option>
    </select>
  </div>

  <div class="buttons" wizardTag="importShipmentsDialogButtons">
    <button id="button-import" type="button" [disabled]="readFileErrors" (click)="import(false)">{{ 'import' | shpLocalize }}</button>
    <button id="button-validate" type="button"  [disabled]="readFileErrors"  (click)="import(true)">{{ 'validate' | shpLocalize }}</button>
    <button *ngIf="isCentralShipper" id="button-go-to-archive" type="button" (click)="goToArchive()">{{ 'import_archive' | shpLocalize }}</button>
    <button id="button-close" type="button" (click)="close()">{{ 'close' | shpLocalize }}</button>
  </div>

  <div class="message import-result-numbers" *ngIf="!isInValidationMode && (importedEntriesCount > 0 || invalidEntriesCount > 0)">
    <div class="info">{{ ((invalidEntriesCount > 0) ? ((importedEntriesCount == 0) ? 'import_failed' : 'import_partially_successful') : 'import_successful') | shpLocalize }}</div>
    <span class="count">{{ 'number_of_imported_lines' | shpLocalize }}: <strong>{{ importedEntriesCount }}</strong></span>
    <span class="count">{{ 'number_of_not_imported_lines' | shpLocalize }}: <strong>{{ invalidEntriesCount }}</strong></span>
  </div>

  <div *ngIf="isInValidationMode">
    <!-- výsledok validácie -->
    <h2>
      {{ 'validation_result' | shpLocalize }}:
      <span *ngIf="isProcessFinishedWithError">{{ 'validation_result_error' | shpLocalize }}</span>
      <span *ngIf="!isProcessFinishedWithError">{{ 'validation_result_ok' | shpLocalize }}</span>
    </h2>
  </div>

  <div *ngIf="errorFileName">
    <button id="button-error"  type="button" (click)="downloadErrorFile()">{{ 'download_file_with_errors' | shpLocalize }}</button>
  </div>

  <div class="import-result" *ngIf="logEntries?.length > 0">
    <!-- jednotlivé log záznamy -->
    <h2>{{ 'errors_and_warnings' | shpLocalize }}</h2>
    <div class="log-entry" *ngFor="let entry of logEntries">
      <span class="ic-remove-circle-1 error" *ngIf="entry.severity === 0"></span>
      <span class="ic-interface-alert-triangle warning" *ngIf="entry.severity === 1"></span>
      <span *ngIf="entry.eventData?.lineNumber && entry.eventData?.lineNumber >= 0">{{ 'line' | shpLocalize }} {{ entry.eventData?.lineNumber }}: </span>
      {{ entry.eventMessage }}
    </div>
  </div>

  <div class="import-result my-3" *ngIf="isImportQueued">
    {{ 'import_is_queued_message' | shpLocalize }}
  </div>
</div>