<div *ngFor="let field of dynamicFieldsResults">
  <div [ngSwitch]="field.fieldType">
    <!-- Type 0: Checkbox -->
    <div *ngSwitchCase="0" class="d-flex mb-3 align-items-center ">
      <input
        type="checkbox"
        [(ngModel)]="additionalFieldValues[field.id]"
        [name]="'field_' + field.id"
        [id]="'field-' + field.id"
      />
      <label class="text-wrap w-100" [attr.for]="'field-' + field.id">
        {{ getLocalizedTitle(field) }}
      </label>
    </div>

    <!-- Type 1: Multiselect but not paid -->
    <!-- <div *ngSwitchCase="1"></div> -->

    <!-- Type 2: Dropdown -->
    <div *ngSwitchCase="2" class="d-flex mb-3 align-items-center ">
      <label class="text-wrap">{{ getLocalizedTitle(field) }}</label>
      <select class="w-100" [(ngModel)]="additionalFieldValues[field.id]">
        <option
          *ngFor="let option of field.fieldOptions"
          [value]="option.optionValue"
        >
          {{ getLocalizedTitle(option) }}
        </option>
      </select>
    </div>

    <!-- Type 3: Input -->
    <div *ngSwitchCase="3" class="d-flex mb-3 align-items-center ">
      <label class="text-wrap">{{ getLocalizedTitle(field) }}</label>
      <input 
        class="w-100"
        type="text" 
        [(ngModel)]="additionalFieldValues[field.id]" 
        [name]="'field_' + field.id"
        [id]="'field-' + field.id"
      />
    </div>
  </div>
</div>
