<div class="settings-category">
  <h1>{{ 'settings_instructions' | shpLocalize }}</h1>
  <shp-progress-overlay [isActive]="isLoadingList"></shp-progress-overlay>
  <form class="grid" [formGroup]="filterForm">
  <div class="instructions-filter">
    <div class="event-code">
      <input id="input-event-code" type="text" placeholder="{{ 'ID' | shpLocalize }}" formControlName="guid" />
    </div>
    <div class="event-name">
      <div class="ctl">
        <label for="isInformationSent">{{"instruction_is_information_sent" | shpLocalize}}</label>
        <select name="isInformationSent" formControlName="isInformationSent">
          <option value="null"> - </option>
          <option value="true">{{ 'yes' | shpLocalize }}</option>
          <option value="false">{{ 'no' | shpLocalize }}</option>
        </select>
      </div>
      <select name="instructionTypeCode" formControlName="instructionTypeCode">
        <option value="">{{ 'all_instruction_types' | shpLocalize }}</option>
        <option *ngFor="let instructionType of instructionTypes" [ngValue]="instructionType">{{ 'instruction_' + instructionType | shpLocalize }}</option>
      </select>
    </div>
    <div class="event-datetime">
      <!-- <wj-input-date formControlName="validFromUtc" [isRequired]="false" placeholder="{{ 'instruction_valid_from' | shpLocalize }}"></wj-input-date> -->
      <div class="p-field">
        <span class="p-float-label">
          <p-calendar formControlName="validFromUtc" [showIcon]="true" inputId="validFromUtc" dateFormat="dd/mm/yy" icon="ic-calendar-1"></p-calendar>
          <label for="validFromUtc">{{ 'instruction_valid_from' | shpLocalize }}</label>
        </span>
      </div>
      <!-- <wj-input-date formControlName="validToUtc" [isRequired]="false" placeholder="{{ 'instruction_valid_to' | shpLocalize }}"></wj-input-date> -->
      <div class="p-field">
        <span class="p-float-label">
          <p-calendar formControlName="validToUtc" [showIcon]="true" inputId="validToUtc" dateFormat="dd/mm/yy" icon="ic-calendar-1"></p-calendar>
          <label for="validToUtc">{{ 'instruction_valid_to' | shpLocalize }}</label>
        </span>
      </div>
    </div>
    <div class="event-datetime">
      <!-- <wj-input-date formControlName="applicationDateTimeFromUtc" [isRequired]="false" placeholder="{{ 'instruction_applications_date_from' | shpLocalize }}"></wj-input-date> -->
      <div class="p-field">
        <span class="p-float-label">
          <p-calendar formControlName="applicationDateTimeFromUtc" [showIcon]="true" inputId="applicationDateTimeFromUtc" dateFormat="dd/mm/yy" icon="ic-calendar-1"></p-calendar>
          <label for="applicationDateTimeFromUtc">{{ 'instruction_applications_date_from' | shpLocalize }}</label>
        </span>
      </div>
      <!-- <wj-input-date formControlName="applicationDateTimeToUtc" [isRequired]="false" placeholder="{{ 'instruction_applications_date_to' | shpLocalize }}"></wj-input-date> -->
      <div class="p-field">
        <span class="p-float-label">
          <p-calendar formControlName="applicationDateTimeToUtc" [showIcon]="true" inputId="applicationDateTimeToUtc" dateFormat="dd/mm/yy" icon="ic-calendar-1"></p-calendar>
          <label for="applicationDateTimeToUtc">{{ 'instruction_applications_date_to' | shpLocalize }}</label>
        </span>
      </div>
    </div>
    <div class="commands">
      <button id="button-clear-filter" type="button" class="icon-button" (click)="clearFilter()" title="{{ 'clear_filter' | shpLocalize }}"><span class="ic-close"></span></button>
    </div>
  </div>
  <div class="instructions-list" [class.has-pager]="filter.pageSize < total">
    <table>
      <thead>
        <tr>
          <th class="col-name">{{ "ID" | shpLocalize }}</th>
          <th class="col-date">{{ "customer_detail_delis_delis_id" | shpLocalize }}</th>
          <th class="col-date">{{ "instructions_configuration_group_id" | shpLocalize }}</th>
          <th class="col-instruction-type" title="{{ 'instruction_type_code' | shpLocalize }}">{{ "instruction_type_code" | shpLocalize }}</th>
          <th class="col-date" title="{{ 'configuration_valid_from' | shpLocalize }}">{{ "configuration_valid_from" | shpLocalize }}</th>
          <th class="col-status" title="{{ 'state' | shpLocalize }}">{{ "state" | shpLocalize }}</th>
          <th class="col-date" title="{{ 'instruction_application_date' | shpLocalize }}">{{ "instruction_application_date" | shpLocalize }}</th>
          <th class="col-date" title="{{ 'instruction_is_information_sent' | shpLocalize }}">{{ "instruction_is_information_sent" | shpLocalize }}</th>
          <th class="col-name" title="{{ 'instruction_application_error_detail' | shpLocalize }}">{{ "instruction_application_error_detail" | shpLocalize }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-instruction [ngForOf]="instructions" let-i="index">
          <tr [class.odd]="i % 2 === 1">
            <td class="col-name">{{ instruction.guid }}</td>
            <td class="col-date">{{ instruction.customerDelisId }}</td>
            <td class="col-date">{{ instruction.configurationGroupId }}</td>
            <td class="col-instruction-type">{{ 'instruction_' + instruction.instructionTypeCode | shpLocalize}}</td>
            <td class="col-date">{{ instruction.validFromUtc | shpLocalizeDate:"L" }}</td>
            <td class="col-status">{{ 'instruction_' + instruction.stateId | shpLocalize }}</td>
            <td class="col-date">{{ instruction.applicationDateTimeUtc | shpLocalizeDate }}</td>
            <td class="col-date">
              <span *ngIf="instruction.isInformationSent == true" class="check-mark"></span>
              <span *ngIf="instruction.isInformationSent == false" class="cross-mark"></span>
            </td>
            <td class="col-name">{{ instruction.applicationErrorDetail }}</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <div class="log-pager pager" *ngIf="filter.pageSize < total">
    <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
    <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(filter.pageIndex - 1)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
    <button id="button-pager-next" class="next" type="button" (click)="goToPage(filter.pageIndex + 1)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
    <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
    <span class="numbers">{{ filter.pageIndex * filter.pageSize + 1 }} - {{ (filter.pageIndex + 1) * filter.pageSize < total ? (filter.pageIndex + 1) * filter.pageSize : total }}/{{ total }}</span>
  </div>
  </form>
</div>