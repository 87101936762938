import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";

import * as Shared from "../../../shared/index";
import * as SettingsModel from "../../models/settings.model";
import { SkdataConfigService } from "../../../shared/services/skdata-config.service";
import { TenantSettingsEditorBase } from "./tenant-settings-editor-base";
import { SettingsService } from "../../services/settings.service";
import { ImportProfile } from "../../../import-profiles/models/import-profiles.models";
import { ShipmentService } from "../../../shipments/services/shipments.service";
import * as CustomerModel from "../../../shared/models/customer.models";
import { ShipmentsImportPathEditorDialogComponent } from "../shipments-import-path-editor-dialog.component";
import { AutomatedShipmentsImportSourceType } from "../../models/automated-shipments-import-source-type"
import { take } from "rxjs/operators";
import { forkJoin } from 'rxjs';
import { BUSINESS_UNIT_CODE_NL } from "../../../shared/index";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-automatic-import-settings",
  templateUrl: "./automatic-import-settings.component.html"
})
export class AutomaticImportSettingsComponent extends TenantSettingsEditorBase implements OnInit, OnDestroy {
  AutomatedShipmentsImportSourceType = AutomatedShipmentsImportSourceType;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    settingsService: SettingsService,
    contextService: Shared.ContextService,
    private _shipmentsService: ShipmentService,
    private _skdataConfigService: SkdataConfigService,
    exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _customerService: Shared.CustomerService,
    private _modal: Modal,
    toastr: ToastrService,
    private _shipperSettingsService: Shared.ShipperSettingsService
  ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router,
      settingsService,
      contextService,
      exceptionsHandlerService,
      toastr);
  }

  public importProfiles: ImportProfile[] = null;
  public importProfileDefault: ImportProfile;

  public bankAccounts: CustomerModel.BankAccount[] = [];
  public addresses: CustomerModel.Address[] = [];

  public isAddressProviderInUse = false;

  public importPaths: SettingsModel.ShipmentsImportPath[] = null;


  public showShipmentsImportPaths = false;


  protected afterSettingsLoaded() {
    this.isBusy = true;

    this.showShipmentsImportPaths = !this._shipperSettingsService.isCentralShipper;

    forkJoin(
      this.contextService.currentCustomerDetailAddresses.pipe(take(1)),
      this._customerService.getCustomerDetailBankAccounts(this.tenantId, this.customerDetailId),
      this._shipmentsService.loadCustomImportsProfiles(this.customerDetailId),
      this.settingsService.getShipmentsImportPaths(),
      this.contextService.businessUnitCode.pipe(take(1))  
    ).subscribe(result => {
      this.isBusy = false;
      this.addresses = result[0].filter(a => a.addressTypeCode === CustomerModel.CustomerAddressTypeCode.Pickup);
      this.bankAccounts = result[1];
      this.importProfiles = result[2];

      const importPaths = result[3].filter(ip => ip.customerDetailId === this.customerDetailId);

      this.importPaths = importPaths;
            
      this.isAddressProviderInUse =  this._shipperSettingsService.addressProviderInUse === 'NL' && result[4] === BUSINESS_UNIT_CODE_NL;
    }, ex => {
      this.isBusy = false;
      const exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
      this.loggingService.logErrorData(ex);
    });
  }


  public addImportPath() {
    const importPath: SettingsModel.ShipmentsImportPath = {
      id: 0,
      isNew: true,
      path: "",
      importProfileId: null,
      useSemaphoreFiles: false,
      tenantId: this.tenantId,
      customerDetailId: this.customerDetailId,
      createdByUser: null,
      creationDateTimeUtc: null,
      modifiedByUser: null,
      modificationDateTimeUtc: null,
      isPersistent: false
    }

    const dialogContext = new SettingsModel.ShipmentsImportPathEditorDialogContext(importPath, this.importProfileDefault, this.importProfiles);

    this._modal.open(ShipmentsImportPathEditorDialogComponent, overlayConfigFactory(dialogContext)).result.then(savedImportPath => {
      if (savedImportPath) {
        Object.assign(importPath, savedImportPath);

        this.importPaths.push(savedImportPath);
      }
    }, ex => { });

  }



  public deleteImportPath(importPath: SettingsModel.ShipmentsImportPath) {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("confirm_shipment_import_path_deleting"))
      .open().result.then(confirmed => {
        if (confirmed) {
          this.isBusy = true;

          this.settingsService.deleteShipmentsImportPath(importPath.id).subscribe(deleted => {
            const index = this.importPaths.indexOf(importPath);

            if (index > -1) {
              this.importPaths.splice(index, 1);
            }

            this.toastr.success(this.localizationService.getLocalizedString("shipments_import_path_successfully_deleted"));
            this.isBusy = false;
          }, ex => {
            const exceptionInfo = this.exceptionsHandlerService.getExceptionInfo(ex);
            this.loggingService.logErrorData(ex);

            this.toastr.error(this.localizationService.getLocalizedString("error_deleting_shipment_import_path") + ": " + this.localizationService.getLocalizedExceptionString(exceptionInfo));

            this.isBusy = false;
          });
        }
      }, ex => { });
  }


  public editImportPath(importPath: SettingsModel.ShipmentsImportPath) {
    const dialogContext = new SettingsModel.ShipmentsImportPathEditorDialogContext(importPath, this.importProfileDefault, this.importProfiles);

    this._modal.open(ShipmentsImportPathEditorDialogComponent, overlayConfigFactory(dialogContext)).result.then(savedImportPath => {
      if (savedImportPath) {
        Object.assign(importPath, savedImportPath);
      }
    }, ex => { });
  }


  public ngOnInit() {
    super.ngOnInit();

    this.importProfileDefault = { code: null } as any;
  }
}
