import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"

import * as Shared from "../../shared/index"
import * as Model  from "../models/pickup-points.models" 


@Injectable()
export class PickupPointsService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _http: HttpClient
  ) {
    super(loggingService);
  }


  public getActivePickupPoints() {
    return this.processRequest<Model.Pudo[]>(this._http.get(Shared.API_URL + "/pickup-points"));
  }


  public getAllPickupPointServices() {
    return this.processRequest<Model.PudoService[]>(this._http.get(Shared.API_URL + "/pickup-points/services"));
  }


  public getPickupPointServices(pickupPointId: number) {
    return this.processRequest<Model.PudoServiceInPudo[]>(this._http.get(Shared.API_URL + "/pickup-points/" + pickupPointId + "/services"));
  }


  public getPickupPointOpeningHours(pickupPointId: number) {
    return this.processRequest<Model.OpeningHours[]>(this._http.get(Shared.API_URL + "/pickup-points/" + pickupPointId + "/opening-hours"));
  }


  public getPickupPointAddress(addressId: number) {
    return this.processRequest<Model.Address>(this._http.get(Shared.API_URL + "/pickup-points/addresses/" + addressId));
  }


  public getFilteredPickupPoints(filter: Model.PickupPointsFilter) {
    return this.processRequest<Model.Pudo[]>(this._http.post(Shared.API_URL + "/pickup-points", filter));
  }
}