<div class="support-category">
  <h2>{{ 'manuals' | shpLocalize }}</h2>

  <h3>{{ 'language_' + languageCode | shpLocalize }} <span class="flag flag-{{ flagCode }}"></span></h3>
  <ul>
    <li *ngFor="let manual of manuals">
      <a href="{{ manual.url }}" target="{{ manual.openInNewWindow ? '_blank' : '' }}">
        {{ manual.displayName | shpLocalize }}
      </a>
    </li>
    <ng-container *ngIf="hasOwnManualForCurrentLanguage()">
      <li *ngFor="let link of ownManualLinksCurrentLanguage; index as i">
        <a [href]="link.url" target="blank">
          <ng-container
            [ngTemplateOutlet]="link.displayName ? manualLinkWithText : defaultManualLink"
            [ngTemplateOutletContext]="{link: link, index: i}"></ng-container>
        </a>
      </li>
    </ng-container>
  </ul>

  <ng-container *ngFor="let ownManualLink of ownManualLinks">
    <h3>{{ ownManualLink.localizationKey | shpLocalize }} <span class="flag flag-{{ ownManualLink.countryCode }}"></span></h3>
    <ul>
      <li *ngFor="let link of ownManualLink.links; index as i">
        <a [href]="link.url" target="blank">
          <ng-container
            [ngTemplateOutlet]="link.displayName ? manualLinkWithText : defaultManualLink"
            [ngTemplateOutletContext]="{link: link, index: i}"></ng-container>
        </a>
      </li>
    </ul>
  </ng-container>

  <ng-template #manualLinkWithText let-link="link">
    {{link.displayName}}
  </ng-template>

  <ng-template #defaultManualLink let-index="index">
    {{ 'own_manual' | shpLocalize:(index + 1) }}
  </ng-template>

  <br><br>

  <div *ngIf="links && links.length > 0">
    <h2>{{ 'links' | shpLocalize }}</h2>
    <ul>
      <li *ngFor="let link of links"><a href="{{ link.url }}" target="{{ link.openInNewWindow ? '_blank' : '' }}">{{ link.displayName }}</a></li>
    </ul>
  </div>
</div>