import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import * as Shared from "../index";

@Directive({
  selector: `[${Shared.SELECTOR_PREFIX}SetCheckboxValues]`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SetCheckboxValuesDirective),
    multi: true
  }]
})
export class SetCheckboxValuesDirective implements ControlValueAccessor  {
  @Input() trueValue = true;
  @Input() falseValue = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  writeValue(value: any): void {
    if (value === this.trueValue) {
      this.renderer.setProperty(this.elementRef.nativeElement, "checked", true);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, "checked", false);
    }
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, "disabled", isDisabled);
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  @HostListener("change", ["$event"])
  onHostChange(e: any) {
    this.onChange(e.target.checked ? this.trueValue : this.falseValue);
  }

  @HostListener("blur")
  onBlur() {
    this.onTouched();
  }
}
