import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Subject, BehaviorSubject, ReplaySubject, forkJoin, of } from 'rxjs';
import { auditTime, catchError, debounceTime, first, map, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';

import * as _ from "lodash";
import * as moment from "moment";
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { ToastrService } from 'ngx-toastr';

import { saveAs } from "file-saver";

import { JobService } from '../services/job-service';
import { JobsSearchParameters } from '../models/jobs-search-paramenters.model';
import { LocalizationService, StringHelpers, LoggingService, ExceptionsHandlerService } from '../../shared';
import { Router } from '@angular/router';
// import { WjMultiSelect } from 'wijmo/wijmo.angular2.input';


const DEFAULT_PAGE_SIZE = 20;
const FILTER_STORAGE_KEY = "import-archive-filter";
const pageSizes = [10, 20, 50, 100];


const defaultColumnFilter = {
  creationDateTimeUtcFrom: null,
  creationDateTimeUtcTo: null,
  stateIds: []
}


const defaultFilter = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE
}


const defaultPaginationSettings = {
  currentPage: 0,
  itemsPerPage: 0,
  totalItems: 0
}


const LOAD_STATUSES = {
  SUCCESS: 1,
  FAIL: 2,
  PENDING: 3,
  EMPTY: 4,
  EMPTY_WITH_FILTER: 5
}


const ISBUSY_TYPES = {
  LIST: 1,
  CLONE: 2,
  PRINT: 3
}


@Component({
  templateUrl: "import-archive.component.html",
  host: {
    class: "import-archive"
  }
})
export class ImportArchiveComponent implements OnInit, AfterViewInit, OnDestroy {
  areSomeRowsSelected = false;
  columnFilter: FormGroup;
  currentLoadStatus: number;
  isBusy: boolean[] = [false];
  isBusyTypes = ISBUSY_TYPES;
  isFiltered = false;
  isLoadingErrorPending = false;
  itemsPerPageOptions = [20, 30, 50, 100, 150, 200, 500];
  jobStates$ = new ReplaySubject<any[]>(1);
  jobs$ = new BehaviorSubject<any[]>([]);
  lastPageIndex: number;
  loadStatuses = LOAD_STATUSES;
  multipleSelectModel = false;
  pageSizes: number[] = pageSizes;
  paginationSettings = defaultPaginationSettings;
  selectedStates = [];
  statusMessage: string;
  totalCount: number;


  private _destroy$ = new Subject<void>();
  private _filter: JobsSearchParameters;
  private _filterChanges$ = new Subject<any>();

  public currentYear = new Date().getFullYear();
  
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _modal: Modal,
    private _toastr: ToastrService,
    private _localizationService: LocalizationService,
    private _jobService: JobService,
    private _log: LoggingService,
    private _exceptionHandlerService: ExceptionsHandlerService
  ) {
    this.isBusy[ISBUSY_TYPES.LIST] = false;
    this.isBusy[ISBUSY_TYPES.CLONE] = false;
    this.isBusy[ISBUSY_TYPES.PRINT] = false;
  }


  get pageSize(): number {
    return this._filter.pageSize;
  }


  set pageSize(value: number) {
    this._filter.pageSize = value;
    this._filterChanges$.next();
  }


  get pageIndex(): number {
    return this._filter.pageIndex;
  }


  set pageIndex(value: number) {
    this._filter.pageIndex = value;
    this._filterChanges$.next();
  }

  ngOnInit() {
    this.initFilter();
    this.initColumnFilter();
    this.loadColumnFilterSelectOptions();
    this.initDataStream();
  }


  ngAfterViewInit() {
    /**
     * Initial data load.
     * NOTE: this is called in `ngAfterViewInit` hook
     * because otherwise <wj-input-date> component has
     * trouble synchronising with `FormGroup`.
     */
    this.columnFilter.patchValue(this._filter);
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  /**
   * Reloads data with current filter settings.
   */
  refresh() {
    this._filterChanges$.next();
  }


  /**
   * Function to send as a imput to retry overlay component.
   */
  retry = () => {
    this.refresh();
  }


  /**
   * Resets column filter `FormGroup` value.
   */
  resetColumnFilter() {
    this.columnFilter.reset();
    this.selectedStates = [];
  }


  onPageIndexClicked(idx: number) {
    /**
     * Substract 1 since <pagination-controls> component
     * starts with page 1 while API's first page index is 0.
     */
    this.pageIndex = idx - 1;
  }


  goToPage(index: number) {
    this.pageIndex = index;
  }


  onPageSizeClicked(size: number) {
    this.pageSize = size;
  }


  onRowSelect(id: number) {
    this.updateMultipleSelectModel();
  }


  onMultipleSelect() {
    this.areAllRowsSelected() ? this.resetSelection() : this.selectAll();
  }


  syncSelectedStates() {
    this.columnFilter
      .get("stateIds")
      .setValue(this.selectedStates.map(state => state.key));
  }


  executeJob(id: number) {
    this._jobService.executeJob(id)
      .subscribe(() => this.refresh(), (error) => {
        this._toastr.error(error.message);
      });
  }


  cancelJob(id: number) {
    const modal = this._modal
      .confirm()
      .body(this._localizationService.getLocalizedString("import_cancellation_confirmation_question"))
      .okBtn(this._localizationService.getLocalizedString("yes"))
      .cancelBtn(this._localizationService.getLocalizedString("no"))
      .open()
      .result;

    modal.then(value => {
      if (value) {
        this._jobService.cancelJob(id)
          .subscribe(() => this.refresh(), (error) => {
            this._toastr.error(error.message);
          });
      }
    }, () => { });

  }


  computeJobStateLocalizationKey(key: string): string {
    return `job_state_id_key_${_.snakeCase(key)}`;
  }


  getDuration(startTime, endTime): string {
    if (startTime == null || endTime == null) {
      return "";
    }

    startTime = moment(startTime);
    endTime = moment(endTime);

    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours ? hours + "h " : ""}${minutes ? minutes + "m " : ""}${seconds ? seconds + "s" : ""}`;
  }


  goToShipments() {
    this._router.navigate(["/", "shipments"]);
  }


  private initFilter() {
    this.loadFilter();

    this._filterChanges$.pipe(
      takeUntil(this._destroy$),
      tap( values => {
        console.warn(values);
      })
    ).subscribe(() => {
      this.saveFilter();
    });
  }


  /**
   * Saves filter to local storage.
   * Called when filter changes.
   */
  private saveFilter() {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(this._filter));
  }


  /**
   * Loads filter from local storage or use default one.
   */
  private loadFilter() {
    let filterData = localStorage.getItem(FILTER_STORAGE_KEY);
    let filter;

    if (filterData) {
      filter = JSON.parse(filterData);
      filter = StringHelpers.convertDateStringsToDates(filter);
    }

    this._filter = filter || { ...defaultFilter };

    // this.jobStates$.pipe(
    //   first()
    // ).subscribe(states => {
    //   if (!Array.isArray(this._filter.stateIds)) {
    //     return;
    //   }

    //   this.selectedStates = [];

    //   filter.stateIds.forEach(stateId => {
    //     const selectedState = states.find(state => state.key === stateId);
    //     this.selectedStates.push(selectedState);
    //   });
    // });
  }


  /**
   * Initializes column filter `FormGroup`.
   */
  private initColumnFilter() {
    this.columnFilter = this._fb.group(defaultColumnFilter);

    this.columnFilter.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(value => {
      this.applyColumnFilter(value);
      this.setIsFiltered(value);
    });
  }


  /**
   * Loads options for column filter selects.
   */
  private loadColumnFilterSelectOptions() {
    forkJoin([
      // this._jobService.getJobContentTypes(),
      this._jobService.getJobStates(),
      // this._jobService.getJobTypes()
    ]).subscribe(result => {
      const jobStates = result[0];

      jobStates.forEach(state =>
        state.value = this._localizationService.getLocalizedString(
          this.computeJobStateLocalizationKey(state.key)));

      this.jobStates$.next(result[0]);
    });
  }


  /**
   * Merges columns filter `FormGroup` value with filter.
   * Called when value changes.
   */
  private applyColumnFilter(value: any) {
    this._filter = {
      ...this._filter,
      ...value
    };

    this._filterChanges$.next();
  }


  /**
   * Sets `isFilterd` to `true` if column filter has some value filled out.
   * Called when value changes.
   */
  private setIsFiltered(value: any) {
    this.isFiltered = _.values(value).some(columnValue => {
      if (Array.isArray(columnValue)) {
        return !!columnValue.length;
      }

      return !!columnValue;
    });
  }


  private initDataStream() {
    this._filterChanges$.pipe(
      takeUntil(this._destroy$),
      // .filter(() => !this.isBusy[ISBUSY_TYPES.LIST])
      auditTime(33),
      tap(() => {
        this.isBusy[ISBUSY_TYPES.LIST] = true;
        this.currentLoadStatus = this.loadStatuses.PENDING;
      }),
      switchMap(() => {
        return this._jobService.searchImportJobs(this._filter).pipe(
          catchError(() => {
            this.currentLoadStatus = this.loadStatuses.FAIL;

            return of({
              items: [],
              pageIndex: 1,
              pageSize: this.pageSize,
              totalSize: 0
            });
          })
        )
      }),
      tap(response => {

        this.totalCount = response.totalSize;
        this.lastPageIndex = Math.ceil(this.totalCount / this.pageSize) - 1;

        /** Load last page if returned list is empty but there are data on previous pages. */
        if (this.totalCount && !response.items.length) {
          this.goToPage(this.lastPageIndex);
        }

        this.isBusy[ISBUSY_TYPES.LIST] = false;
        // this.paginationSettings = getPaginatorSettings(response);
        this.setLoadStatusOnResponse(response);
      }),
      pluck("items"),
      map((items: any[]) => {
        items = items.map(item => {
          item.isSelected = false
          return item;
        });

        return items;
      })
    ).subscribe(orders => {
      this.jobs$.next(orders);
      this.setAreSomeRowsSelected();
    });
  }


  /**
   * Set data load status on response.
   * @param response
   */
  private setLoadStatusOnResponse(response) {
    /**
     * If load failed status was already set in `catch` operator.
     */
    if (this.currentLoadStatus === this.loadStatuses.FAIL) {
      return;
    }

    if (response.totalSize) {
      this.currentLoadStatus = this.loadStatuses.SUCCESS;
    } else if (this.isFiltered) {
      this.currentLoadStatus = this.loadStatuses.EMPTY_WITH_FILTER;
    } else {
      this.currentLoadStatus = this.loadStatuses.EMPTY;
    }
  }


  private selectAll() {
    this.jobs$.getValue().forEach(item => item.isSelected = true);
  }


  private resetSelection() {
    this.jobs$.getValue().forEach(item => item.isSelected = false);
  }


  private areAllRowsSelected(): boolean {
    return this.jobs$.getValue().every(item => item.isSelected);
  }


  private setAreSomeRowsSelected() {
    this.areSomeRowsSelected =
      this.jobs$.getValue().some(item => item.isSelected);
  }


  private updateMultipleSelectModel() {
    this.multipleSelectModel = this.areAllRowsSelected();
    this.setAreSomeRowsSelected();
  }


  private getSelection(): any[] {
    return this.jobs$.getValue().filter(order => order.isSelected);
  }


  public downloadLog(logContentId: number) {
    this.isBusy[ISBUSY_TYPES.LIST] = true;

    this._jobService.getJobLog(logContentId).subscribe(blob => {

      const filename = `job-log-${logContentId}.txt`;

      saveAs(blob, filename, true);
      this.isBusy[ISBUSY_TYPES.LIST] = false;

    }, ex => {
      this.isBusy[ISBUSY_TYPES.LIST] = false;
      let exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);
      this._log.logErrorData(ex, "Error downloading log for job");

      this._toastr.error(
        this._localizationService.getLocalizedString("error_downloading_job_log") + ": " +
        this._localizationService.getLocalizedExceptionString(exceptionInfo));
    });
  }
}
