import { Component, OnInit, Optional, Host, OnDestroy } from "@angular/core"

import * as Shared from "../../../shared/index"
import { ImportProfileTransformationDataBase } from "./transformation-data.base";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { TransformationEditorComponent } from "../transformation-editor.component";


const controls = {
  multiplyBy: ["", Validators.min(0)]
}


@Component({
  selector: Shared.SELECTOR_PREFIX + "-import-profile-transformation-data-multiplication",
  templateUrl: "./multiplication.component.html"
})
export class ImportProfileTransformationDataMultiplicationComponent extends ImportProfileTransformationDataBase implements OnInit, OnDestroy {
  transformationDataName = "multiplicationData";
  form: FormGroup;


  constructor(
      private _fb: FormBuilder,
      @Optional() @Host() private _parent: TransformationEditorComponent) {
     super(_parent);   
  }


  ngOnInit() {
    this.createForm();
    this.connectForm();
  }


  ngOnDestroy() {
    this.disconnectForm();
  }


  createForm() {
    this.form = this._fb.group(controls);
  }
}