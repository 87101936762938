
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import * as _ from "lodash";
import { overlayConfigFactory } from "ngx-modialog-7";
import { BSModalContext, Modal } from "ngx-modialog-7/plugins/bootstrap";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


import { PickupAddressSelectorDialogComponent } from "../../shared/components/pickup-address-selector-dialog";
import * as Shared from "../../shared/index";
import { CustomerService, LOGIN_URL, NewsfeedService } from "../../shared/index";
import * as ContextModel from "../../shared/models/context.model";
import { UsersService } from "../services/users.service";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-current-user",
  templateUrl: "./current-user.component.html"
})
export class CurrentUserComponent implements OnInit, OnDestroy {
  public currentContext: ContextModel.Context = null;
  public isCustomerService = false;
  public shipperAdminUserLogin: string = null;
  public showUserCommands = true;
  public user: Shared.User;

  private _contexts: BehaviorSubject<ContextModel.Context[]> = new BehaviorSubject([]);
  private _destroy$ = new Subject<void>();

  constructor(
    private _modal: Modal,
    private _authenticationService: Shared.AuthenticationService,
    private _router: Router,
    private _contextService: Shared.ContextService,
    private _userService: UsersService,
    private _localizationService: Shared.LocalizationService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _customerService: CustomerService,
    private _newsfeedService: NewsfeedService
  ) {
  }


  public get contextsCount(): number {
    return this._contexts.getValue().length;
  }

  public contexts: Observable<ContextModel.Context[]> = this._contexts.asObservable();

  /**
   * Metóda, ktorá sa má bindnúť na change event na drop-downe výberu aktuálnej picku adresy.
   */
  public currentContextChanged = (newCurrentContext: ContextModel.Context) => {
    this._contextService.setCurrentAddress(newCurrentContext.address);
  }


  public logOut = () => {
    if (this._shipperSettingsService.isSophia || this._shipperSettingsService.isCentralShipper) {
      this._authenticationService.logOutUser();
      this._router.navigateByUrl(LOGIN_URL);
    } else {
      this._newsfeedService.clearToasts();

      this._modal.confirm()
      .body(this._localizationService.getLocalizedString("question_before_logout"))
      .open()
      .result.then(value => {
        if (value) {
          this._authenticationService.logOutUser();
          this._router.navigateByUrl(LOGIN_URL);
        }
      }, () => { });
    }
  }


  public changePickupAddress() {
    this._modal.open(
      PickupAddressSelectorDialogComponent,
      overlayConfigFactory({}, BSModalContext)
    ).result
    .then((result: ContextModel.Context) => {
      if (result.address.id !== this.currentContext.address.id) {
        this._contextService.setCurrentAddress(result.address);
      }
    }, () => { });
  }


  private showStopCreditUserAlert = () => {
    this._customerService.openStopCreditModal();
  }


  public ngOnInit() {
    this._authenticationService.user.pipe(
      takeUntil(this._destroy$)
    ).subscribe((user: Shared.User) => {
      this.user = user;

      if (user != null) {
        this.showUserCommands = this.user.login !== Shared.DEMO_USER_LOGIN;

        if (user.shipperAdminUserId) {
          this._userService.getCurrentImpersonatingShipperAdminUser().subscribe(l => {
            this.shipperAdminUserLogin = l;
          });
        } else {
          this.shipperAdminUserLogin = null;
        }
      } else {
        this.shipperAdminUserLogin = null;
      }
    });

    combineLatest([
      this._contextService.addresses,
      this._contextService.customerDetails
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(result => {
      // Musíme vyskladať kontexty.
      const contexts: ContextModel.Context[] = [];

      const addresses = result[0];
      const customerDetails = result[1];

      addresses.forEach(address => {
        const customerDetail = _.find(customerDetails, cd => cd.id === address.customerDetailId);

        if (customerDetail != null) {
          contexts.push({ customerDetail, address });
        }
      });

      this._contexts.next(contexts);
    });

    this._contextService.currentAddress.pipe(
      takeUntil(this._destroy$)
    ).subscribe(currentAddress => {
      this.currentContext = _.find(this._contexts.getValue(), c => c.address.id === currentAddress.id);
    });

    if (this._shipperSettingsService.isSophia) {
      this._contextService.currentCustomerDetail.pipe(
        takeUntil(this._destroy$)
      ).subscribe(currentCustomerDetail => {
        if (currentCustomerDetail && currentCustomerDetail.stopCredit) {
          this.showStopCreditUserAlert();
        }
      });
    }

    this._authenticationService.isCustomerService$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(value => this.isCustomerService = value);
  }


  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
