import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from "@angular/core";
import { FormGroup, NgControl } from "@angular/forms";

import * as _ from "lodash";
import * as moment from "moment";
import { Subject } from "rxjs";
import { filter, startWith, takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { BUSINESS_UNIT_CODE_CH } from "../../shared/index";
import * as ConfigModel from "../../shared/models/config.models";
import * as ShipmentsModel from "../models/shipment.model";
import { ShipmentEditorParcelsComponent } from "./shipment-editor-parcels.component";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipment-editor-parcel",
  templateUrl: "./shipment-editor-parcel.component.html",
  host: {
    class: "shipment-editor__parcel"
  }
})
export class ShipmentEditorParcelComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() areCustomsClearanceFieldsHidden = true;
  @Input() isCustomsClearanceBoundFieldsRequiredValidationOn = false;
  @Input() areDimensionEditorsMandatory = true;
  @Input() areDimensionsFieldsHidden = false;
  @Input() countries: Shared.Country[];
  @Input() customsAmountCurrencyCode: string;
  @Input() hideDimensionEditors: boolean;
  @Input() index = 1;
  @Input() isBrexitCountryRecipient = false;
  @Input() isDpdPreciseProduct: boolean;
  @Input() isParcelLengthFirstDimension: boolean;
  @Input() isReadOnly = false;
  @Input() isSameDimensionsForAllParcels = false;
  @Input() minLimitDate = new Date();
  @Input() parcel: ShipmentsModel.OrderEditorParcel;
  @Input() recipientCountryCode: string;
  @Input() senderCountryCode = "";
  @Input() showGoodsFields = false;
  @Input() showLabels = false;
  @Input() tooltips: any;
  @Input() isRequiredValidationOn = true; 

  @Input()
  set selectedProduct(product: ConfigModel.Product) {
    if (!product) {
      return;
    }

    if (!this.showGoodsFields) {
      this.resetTemperatureRange();
    }

    this.setTemperatureRange(product.serviceCodesInProduct);
  }

  @Output() customsAmountChange = new EventEmitter<void>();
  @Output() weightChange = new EventEmitter<void>();


 public get areCustomsBoundFieldsRequired() {
    return this.isRequiredValidationOn && this.isCustomsClearanceBoundFieldsRequiredValidationOn;
  }

  @ViewChildren(NgControl) controls: QueryList<NgControl>;


  temperatureMax = 50;
  temperatureMin = -50;


  private destroy$ = new Subject<void>();
  private formGroup: FormGroup;


  constructor(
      public parent: ShipmentEditorParcelsComponent,
      private contextService: Shared.ContextService) { }


  ngOnChanges(changes: SimpleChanges) {
    if ("minLimitDate" in changes) {
      this.setLimitDate();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.controls.changes.pipe(
      startWith(null),
      filter(() => !!this.parent)
    ).subscribe(() => this.registerControls());
  }


  ngOnDestroy() {
    this.removeFormGroup();
    this.destroy$.next();
    this.destroy$.complete();
  }


  onWeightChange() {
    this.weightChange.emit();
  }


  onCustomsAmountChange() {
    this.customsAmountChange.emit();
  }


  private registerControls() {
    this.formGroup = new FormGroup({});

    this.controls.forEach(control => {
      this.formGroup.addControl(control.name as string, control.control);
    });

    this.removeFormGroup();
    this.registerFormGroup();
  }


  private registerFormGroup() {
    this.parent.array.push(this.formGroup);
  }


  private removeFormGroup() {
    const idx = this.parent.array.controls.indexOf(this.formGroup);
    this.parent.array.removeAt(idx);
  }

  private resetTemperatureRange(): void {
    this.temperatureMin = -50;
    this.temperatureMax = 50;
  }

  private setTemperatureRange(productServiceCodes: string[]): void {
    const tempCode = productServiceCodes.find(sc => ConfigModel.ProductsTemparatureRanges.has(sc));
    const temperature = ConfigModel.ProductsTemparatureRanges.get(tempCode);

    if (temperature) {
      this.temperatureMin = temperature[0];
      this.temperatureMax = temperature[1];
    }
  }

  private setLimitDate() {
    const minDate = moment(this.minLimitDate).startOf("d");
    const currentLimitData = moment(this.parcel?.limitDate);

    if (!currentLimitData.isValid ||
      currentLimitData.startOf("d").isBefore(minDate)) {
      this.parcel.limitDate = minDate.toDate();
    }
  }
}
