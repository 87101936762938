import { Component } from "@angular/core";

import { WizardChapterName } from "../../services/shipper-wizard-steps";

@Component({
  templateUrl: "./wizard-chapters-preview.component.html",
  host: {
    class: "wizard__chapters-preview"
  }
})
export class WizardChaptersPreviewComponent {
  setNames: WizardChapterName[] = [
    WizardChapterName.Settings,
    WizardChapterName.AddressBook,
    WizardChapterName.Shipments,
    WizardChapterName.Pickups,
    WizardChapterName.ImportProfiles,
  ];
}
