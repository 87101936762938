import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { CHANGE_PASSWORD_URL, LOGIN_URL, PasswordService } from "./index";

@Injectable()
export class UnchangedPasswordGuard implements CanActivate {
  constructor(
    private _passwordService: PasswordService,
    private _router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> {

    if (state.url.startsWith(LOGIN_URL)) {
      return true;
    }

    if (state.url === CHANGE_PASSWORD_URL) {
      return true;
    }

    return this._passwordService.state$.pipe(
        filter(s => !s.isPasswordChangeRequiredPending),
        take(1),
        map(s => s.isPasswordChangeRequired ?
          this._router.parseUrl(CHANGE_PASSWORD_URL) :
          true
        )
      );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}
