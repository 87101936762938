import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { CompleterService, CompleterItem, RemoteData } from "ng2-completer";
import { NgForm } from "@angular/forms";
import { Location } from "@angular/common";

import { Subscription, BehaviorSubject, Subject, combineLatest, merge, of, forkJoin } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, takeUntil, tap, debounce, delay, take, switchMap, finalize, auditTime } from "rxjs/operators";

import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService, DefaultGlobalConfig, ActiveToast } from "ngx-toastr";

import { default as DeepDiff } from "deep-diff";
import * as _ from "lodash";
import * as moment from "moment";

import * as Models from '../models/shipment.model';
import * as Shared from "../../shared/index";
import * as ShipmentsModel from "../models/shipment.model";
import * as RecipientModel from "../../recipients/models/recipient.model";
import * as ConfigModel from "../../shared/models/config.models";
import * as PickupPointsModel from "../../pickup-points/models/pickup-points.models";
import { SkdataConfigService } from "../../shared/services/skdata-config.service";
import { ShipmentService } from "../services/shipments.service";
import { CustomClearanceService } from "../services/custom-clearance.service";
import { PickupPointsService } from "../../pickup-points/services/pickup-points.service";
import { RecipientsService } from "../../recipients/services/recipients.service";
import { NewsfeedService } from "../../shared/services/newsfeed.service";
import * as CustomerModel from "../../shared/models/customer.models";
import { SettingsService } from "../../settings/services/settings.service";
import * as SettingsModel from "../../settings/models/settings.model";
import { CustomerService } from "../../shared/services/customer.service";

import { RecipientViewComponent } from "./recipient-view.component";
import { CustomClearanceViewComponent } from "./custom-clearance-view.component";
import { CustomClearanceMiniportalViewComponent } from "./custom-clearance-miniportal-view.component";
import { ZipCityPair } from "../../shared/models/zip-city-pair.model";
import { CityZipRange } from "../../shared/models/zip-city-range.model";
import { DeliveryWindow } from "../models/routing.model";
import { Invoice, InvoiceLine, CustomClearanceEditorData, ShipmentTypeCodeDocuments } from "../models/custom-clearance.model";
import { amendValueForModelFromFormattedFormControl } from "../../shared/form-helpers";
import { LocalizeDatePipe, BUSINESS_UNIT_CODE_CH, EntityFormValidationService, genericEntityFormValidatorErrorDescriptions, Country, PollyCollection, BUSINESS_UNIT_CODE_NL } from "../../shared/index";
import { ShipmentTypeDialogComponent } from "./shipment-type-dialog.component";
import { overlayConfigFactory } from "ngx-modialog-7";
import { ShipmentsAdditionalServicesComponent } from "./shipments-additional-services.component";
import { NotificationsTemplateService } from "../../settings/services/notification-templates.service";
import { EntityValidationToast } from "../../shared/components/entity-validation-toast.component";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import { WizardOverlayService } from "../../shared/modules/wizard/services/wizard-overlay.service";
import { UpdateService } from "../../shared/services/update.service";
import { DpdApiCustomerEditorData } from "../models/dpd-api-customer-editor-data.model";
import { validateZipFormat, validateZipNoSpaces } from "../../shared/validators";
import { shipmentEditorValidationTexts } from './shipment-editor-entity-validator-texts';
import { ShipmentsAdditionalFieldsComponent } from "./shipment-additional-fields.component";

enum ApiValidationItem {
  AvailableProducts,
  AvailableServices,
  DpdPreciseDeliveryWindows
}


const SHIPMENT_ORDER_ID_PARAMETER_KEY = "shipmentOrderId";
const RECIPIENT_ID_PARAMETER_KEY = "recipientId";
const FROM_SENDER_SCAN_PARAMETER_KEY = "fromSenderScan";
export const RECEIVER_TYPE_BUSINESS = "BUSINESS";
export const RECEIVER_TYPE_PRIVATE = "PRIVATE";
const LIMIT_DATA_PICKUP_DATE_DIFF = 7;

/**
 * * recipient - Recipient, Delivery to PUDO, CR recipient, CR dummy
 * * customsClearance -CC, Miniportal
 */
const panelsOrders: {[key: string]: string[]} = {
  default: [
    "basicData",
    "parcelInfo",
    "apiCustomer",
    "recipient",
    "customSender",
    "returnSender",
    "crSender",
    "customsClearance",
    "customsClearanceContent",
    "invoiceSender",
    "invoiceRecipient",
    "preciseProduct",
    "additionalServices"
  ],
  recipientBeforeBasic: [
    "apiCustomer",
    "recipient",
    "basicData",
    "parcelInfo",
    "customSender",
    "returnSender",
    "crSender",
    "customsClearance",
    "customsClearanceContent",
    "invoiceSender",
    "invoiceRecipient",
    "preciseProduct",
    "additionalServices"
  ],
  cr: [
    "basicData",
    "crSender",
    "apiCustomer",
    "recipient",
    "parcelInfo",
    "customSender",
    "returnSender",
    "customsClearance",
    "customsClearanceContent",
    "invoiceSender",
    "invoiceRecipient",
    "preciseProduct",
    "additionalServices"
  ],
  crRecipientBeforeBasic: [
    "crSender",
    "basicData",
    "apiCustomer",
    "recipient",
    "parcelInfo",
    "customSender",
    "returnSender",
    "customsClearance",
    "customsClearanceContent",
    "invoiceSender",
    "invoiceRecipient",
    "preciseProduct",
    "additionalServices"
  ],
  crCustomerService: [
    "apiCustomer",
    "recipient",
    "crSender",
    "basicData",
    "parcelInfo",
    "customSender",
    "returnSender",
    "customsClearance",
    "customsClearanceContent",
    "invoiceSender",
    "invoiceRecipient",
    "preciseProduct",
    "additionalServices"
  ]
}

const tooltipTexts = {
  setEmailServer: "set_email_server_setting",
  setCustomerPersonalizedNotificationTemplate: "set_customer_personalized_notification_template",
  setEmailServerAndCustomerPersonalizedNotificationTemplate: "set_email_server_settings_and_customer_personalized_notification_template"
}


/**
 * Komponent pre editáciu novej alebo existujúcej objednávky/zásielky.
 * Shipment a Order sú v kontexte klienta zameniteľné pojmy a občas používame aj pojem
 * Shipment order, čo ale nezodpovedá tomu, čo máme na strane servera a v DTOčkach na prenos dát medzi
 * klientom a serverom.
 */
@Component({
  selector: Shared.SELECTOR_PREFIX + "-shipment-editor",
  templateUrl: "./shipment-editor.component.html",
  host: {
    class: "shipment-editor"
  },
  providers: [
    CompleterService,
    EntityFormValidationService
  ]
})
export class ShipmentEditorComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _route: ActivatedRoute,
    private _modal: Modal,
    private _skDataConfigService: SkdataConfigService,
    private _shipmentService: ShipmentService,
    private _customClearanceService: CustomClearanceService,
    private _pickupPointsService: PickupPointsService,
    private _contextService: Shared.ContextService,
    private _recipientsService: RecipientsService,
    private _completerService: CompleterService,
    private _settingsService: SettingsService,
    private _customerService: CustomerService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _exceptionsHandlerService: Shared.ExceptionsHandlerService,
    private _shipperSettingsService: Shared.ShipperSettingsService,
    private _location: Location,
    private _toastr: ToastrService,
    private _localizeDatePipe: LocalizeDatePipe,
    private _notificationsTemplateService: NotificationsTemplateService,
    private _entityFormValidationService: EntityFormValidationService,
    private _newsfeedService: NewsfeedService,
    private _wizardService: WizardService,
    private _wizardOverlayService: WizardOverlayService,
    private _updateService: UpdateService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
    this._nextNewParcelId
    this._entityFormValidationService.getErrorLabel = this.getEntityControlErroLabel;
    this._entityFormValidationService.getErrorDescription = this.getEntityControlErrorDesciription;
  }

  @ViewChild(ShipmentsAdditionalFieldsComponent) shipmentsAdditionalFieldsComponent: ShipmentsAdditionalFieldsComponent;
  @ViewChildren(RecipientViewComponent) recipientsViewComponents: QueryList<RecipientViewComponent>;

  @ViewChildren(ShipmentsAdditionalServicesComponent) shipmentsAdditionalServicesComponents: QueryList<ShipmentsAdditionalServicesComponent>;

  @ViewChild("customClearanceView") customClearanceViewComponent: CustomClearanceViewComponent;
  @ViewChild("customClearanceView", { read: ElementRef }) customClearanceViewComponentEl: ElementRef;

  public ShipmentTypeCodeDocuments = ShipmentTypeCodeDocuments;

  @ViewChild("customClearanceMiniportalView") customClearanceMiniportalViewComponent: CustomClearanceMiniportalViewComponent;


  getCustomsClearanceElement = () => this.customClearanceViewComponentEl.nativeElement;

  @ViewChild("f")
  public form: NgForm;

  /** Recipient before base data. */
  public recipientFirstOrderForm = false;
  public panelsOrder: {[panel: string]: number} = {};

  public tooltipTexts = tooltipTexts;
  public customerPersonalizedNotificationTooltipText;

  private _now = moment();
  public minDate = this._now.startOf("day").toDate();
  public minDateCR = null;
  public maxDateCR = null;

  private initCodForCr() {
    if (this.isCodForCrAllowed) {
      if (this.isCollectionRequest) {
        this._availableCurrencyCodes = ["eur"];

        let codService = this.additionalServices.find(s => s.code === ConfigModel.WellKnownProductCodes.Cod);

        if (codService) {
          let codData = codService.data as ShipmentsModel.CodData;
          if (codData && !codData.currency && this._availableCurrencyCodes && this._availableCurrencyCodes.length > 0) {
            codData.currency = this._availableCurrencyCodes[0];
          }
        }
      } else {
        this.additionalServices.forEach(service => {
          service.isSelected = false;
        });
      }
    }
  }

  public isSelectedProductChanged() {
    if (this.hasCustomSenderAddress &&
      !this.isCustomSenderAddressAllowed &&
      this.selectedProductCode !== ConfigModel.WellKnownProductCodes.CityService) {
      this.hasCustomSenderAddress = false;
    }

    if (this.isFreshProductSelected) {
      if (this.freshPickupRequested === null) {
        this.pickupRequested = false;
      }
    }
    
    // Osetrenie prechodu z Pudo produktu do ineho kvoli SHIPPER-979
    if (!this.isDeliveryToPudoProduct && this.selectedPudo)  
    {
      this.checkProductsAndAdditionalServicesAvailability();
    }

    if (this.isDeliveryToPudoProduct) {
      // Najprv vyčistíme staré PUDO dáta, potom sa v ďalšom procese nastavia nové.
      this.clearPudo();
    }
  }


  public languages: string[];

  /**
   * Táto premenná by mala obsahovať aktuálny stav objednávky.
   * Údaj sem treba vžťdy nakopírovať pri načítaní údajov zo servera.
   **/
  public stateId = "New";

  public isCustomerService = false;

  // Recipient (CR recipient) is mandatory
  public get isRecipientEmailRequired(): boolean {
    return this._isRecipientEmailRequiredSetting ||
      this._isRecipientEmailRequiredBrexit ||
      this._isRecipientEmailRequiredCrossBorder;
  };
  // From settings.
  private _isRecipientEmailRequiredSetting = false;
  // For GB with customs clearance
  private _isRecipientEmailRequiredBrexit = false;
  // For corss boarder shipments
  private _isRecipientEmailRequiredCrossBorder = false;

  public hideDimensionEditors = false;
  public areDimensionEditorsMandatory = true;
  public useB2BAddressFieldsOnly = false;
  public isCRProductRequired = false;
  public isCustomSenderAddressAllowed = false;

  // Default sú CR povolené, iba niektoré BU majú cez nastavenie
  // požadované, aby mal zákazník v konfigurácii CR produkt.
  public isCRAvailable = true;

  public isCodForCrAllowed = false;

  public recipientsDataService: RemoteData = null;
  public recipientsDataService2: RemoteData = null;

  private _parametersChangeSubscription: Subscription = null;


  private _selectedPudo: PickupPointsModel.Pudo = null;

  private _availableCurrencyCodes: string[] = [];

  public set isCustomClearance(value: boolean) {
    this._isCustomClearance = value;
    this._isCustomsClearanceChanges$.next(value);
  }

  public get isCustomClearance(): boolean {
    return this._isCustomClearance;
  }

  private _isCustomClearance = false;

  public shipmentTypes: ConfigModel.ShipmentType[] = null;
  public clearanceCleareds: ConfigModel.ClearanceCleared[] = null;
  public prealertStatuses: ConfigModel.PrealertStatus[] = null;
  public incoTerms: ConfigModel.IncoTerm[] = null;
  public currencies: ConfigModel.Currency[] = null;
  public accompanyingDocsTypes: any[] = null // ConfigModel.AccompanyingDocsType[] = null;
  public customSenderInvoiceAddress: boolean = false;
  public customReceiverInvoiceAddress: boolean = false;
  public defaultShipmentType: string = null;
  public defaulClearanceCleared: string = null;
  public defaultPrealertStatus: string = null;
  public disableClearanceCleared: boolean = false;
  public hideClearanceCleared: boolean = false;
  public disablePrealertStatus: boolean = false;
  public hidePrealertStatus: boolean = false;
  public allowCustomsClearance: boolean = false;
  public isEnabledDomesticCardPay: boolean = false;
  public isEnabledForeignCardPay: boolean = false;
  public isEnabledCodForEachParcel: boolean = false;
  public isForcedCodForEachParcel: boolean = false;
  public isCustomerRefUsedForCollectionRequest: boolean = false;
  public isAdditionalServicesSectionWithinBaseData: boolean = false;
  public isParcelReferenceDisplayedInReturnRequestPanel: boolean = false;
  public isShipmentPickupDateTimeVisibleBuSetting: boolean = false;
  public createPickupOrdersWithShipmentOrdersBuSetting: boolean = false;
  public isCustomerPersonalizedNotificationAllowed: boolean = false;
  public enableSendingReturnLabelByEmailBUSetting: boolean = false;
  public isEmailServerSet: boolean = false;
  public isCustomerPersonalizedNotificationTemplateSet: boolean = false;
  public isParcelLengthFirstDimension: boolean = false;
  public customsClearanceTooltips: any;
  public isCrSenderPhoneNumberOrEmailRequired: boolean = false;
  public isCrRecipientPhoneNumberOrEmailRequired: boolean = false;
  public isFormInitialized: boolean = false;
  public isEmailMandatoryForCrossBorderGeneral: boolean = false;
  public isSenderAddressProductRuleAllowed: boolean = false;
  public isCrSenderPhoneNumberRequired: boolean = false;
  public isCrSenderEmailRequired: boolean = false;
  public isProductsAndServiceAvailabilityCalculationEnabled = false;
  public isCustomClearanceExportMrnFieldVisible = false;
  public isCustomsClearanceRequiredValidationTurnedOff = false;
  public isCustomsClearanceRequiredValidationOn = false;
  public isCustomsClearanceBoundFieldsRequiredValidationTurnedOff = false;
  public isCustomsClearanceBoundFieldsRequiredValidationOn = false;
  public customsClearanceRequiredValidationCountryCodes: string[] = [];
  public customsClearanceBoundFieldsRequiredValidationCountryCodes: string[] = [];

  public showMiniportalLink: boolean = false;
  public useMiniportalIntegration: boolean = false;
  public isBulkShipper: boolean = false;

  public pollyCollection = PollyCollection.NewShpmentOrder;

  public checkDefaultWeightRequiredCountry: string;
  public isSpecialOrderInRecipientView = false;
  public brexitParcelsCountReadOnly = false;
  private _isCustomsClearanceChanges$ = new Subject<boolean>();

  public isCustomsDisplayedForWizard = false;

  private _invoiceAdditionalLines: number = 0;
  private _currentCustomerEoriNumber: string = "";
  private _currentCustomerVat: string = "";
  private _currentCustomerWebSite: string = "";
  private _currentCustomerHmrcNumber: string = "";

  private zipWarningsAddress: string[] = null;
  private zipWarningsPickupPoint: string[] = null;

  public dynamicFieldsResults: Models.AdditionalField[] = [];
  public predefinedFieldsValues: Models.ShipmentProductDynamicFieldOption[] = [];

  public get recipientCurrencyCode(): string { return this.recipient?.country?.currencyCode ?? ""; }

  public get availableCurrencyCodes() { return this._availableCurrencyCodes; }

  public get recipientCountryCode(): string { return this.recipient?.country?.code ?? ""; }

  public get senderCountryCode(): string {
    return this.hasCustomSenderAddress ?
      // Custom sender
      this.customSenderAddress?.country?.code ?? "" :
      this.isReturnProductSelected ?
        // Return sender
        this.returnSender?.country?.code ?? "" :
        // Sender (current address)
        this.selectedSenderAddress?.countryCode ?? "";
  }

  public get isInvoiceNumberRequired(): boolean {
    return this.isCustomsClearanceBoundFieldsRequiredValidationOn;
  }

  public get isDateOfIssueRequired(): boolean {
    return this.isCustomsClearanceBoundFieldsRequiredValidationOn;
  }


  public get isSelectedCountryAvaiableForCr(): boolean {
    return this.crAvaiableCountriesBuSetting ?
      _.includes(this.crAvaiableCountriesBuSetting, this.recipientCountryCode) :
      true;
  }


  public get isPickupDateInputVisible(): boolean {
    return this.isShipmentPickupDateTimeVisibleBuSetting || (this.createPickupOrdersWithShipmentOrdersBuSetting && this.pickupRequested) || this.isCollectionRequest;
  }

  public isPickupDateInputDisabled: boolean = false;

  private checkFixedPickupDate(country: Country)
  {
    if (!this.isCollectionRequest || !this.fixedCrPickupDateCountries || !country)
    {
      this.isPickupDateInputDisabled = false;
      return;
    }

    var countries = this.fixedCrPickupDateCountries.split(",");
    this.isPickupDateInputDisabled = countries.indexOf(country.code) > -1;

    if (this.isPickupDateInputDisabled)
    {
      this.pickupDate = this.minDateCR;
    }
  }

  public fixedCrPickupDateCountries: string = null;
  public freshPickupRequested: boolean = null;
  public maxParcelsCount: number = 0;
  public streetRegEx: string = null;

  public freshPickupRequestedChanged() {
    if (this.freshPickupRequested !== null) {
      this.pickupRequested = this.freshPickupRequested;
    }
  }


  public get pickupRequested(): boolean {
    let isPickupDateDefined = typeof this.pickupDate !== "undefined" && this.pickupDate !== null;

    if (this.isFreshProductSelected && this.freshPickupRequested !== null) {
      this.freshPickupRequested = isPickupDateDefined;
    }

    return isPickupDateDefined;
  }

  public set pickupRequested(value: boolean) {
    if (value) {
      if (typeof this.pickupDate === "undefined" || this.pickupDate === null) {
        // this.pickupDate = this.minDate;
        this._unavailableDaysLoad$.next({year: moment().year(), month: moment().month() + 1, pickupDate: this.minDate });
      } else {
        // Don't do anything, there has been a date already set.
      }
    } else {
      this.pickupDate = null;
    }
  }

  public get canRequestPickup(): boolean {
    return this.createPickupOrdersWithShipmentOrdersBuSetting && !this.isCollectionRequest && !this.isReturnProductSelected;
  }

  public get selectedShipmentTypeCode(): string {
    return this.customClearanceEditorData.shipmentTypeCode;
  }

  public get isDeliveryToPudoProduct(): boolean {
    return this.selectedProduct && this.selectedProduct.isDeliveryToPudoProduct
  }

  public get isDeliveryToHomeAddressProduct(): boolean {
    return this.selectedProduct && this.selectedProduct.isDeliveryToHomeAddressProduct
  }

  public get isDeliveryToBusinessAddressProduct(): boolean {
    return this.selectedProduct && this.selectedProduct.isDeliveryToBusinessAddressProduct
  }

  public isEmailMandatoryForCrossBorder: boolean = false;

  /**
   * Aktuálne vybrané odberné miesto.
   * Môže byť null, kým sa nainicializuje zoznam.
   */
  public set selectedPudo(value: PickupPointsModel.Pudo) {
    this._selectedPudo = value;
    this.checkProductsAndAdditionalServicesAvailability();
    this.checkCustomsObligations();
  }


  public get selectedPudo(): PickupPointsModel.Pudo {
    return this._selectedPudo;
  }


  /**
   * V prípade, že máme načítanú existujúcu objednávku, tak tu máme jej "RAW" dáta zo servera.
   * V UI pracujeme s inými dátami, preto sa tieto dáta najprv v loadExistingShipmentOrder pretavia do potrebných štruktúr.
   */
  public existingOrderEditorModel: ShipmentsModel.OrderEditorModel = null;


  private _suspendRoutingValidation = true;


  /**
   * Koľko sa dá z Reference 1 až 4 vlastne editovať.
   */
  public visibleParcelReferencesCount = 2; // 2 je rozmný default.
  public visibleShipmentReferencesCount = 0;
  public referentialInfo1: string = "";
  public referentialInfo2: string = "";
  public referentialInfo3: string = "";
  public referentialInfo4: string = "";


  /**
   * Aktuálne sa sem nastavuje z ContextService currentAddress.
   */
  public selectedSenderAddress: CustomerModel.Address = null;


  public selectedCityServiceDeliveryWindowId: string = null;
  public cityServiceDeliveryWindows: DeliveryWindow[] = null;

  /**
   * Možnosť platby pre COD.
   */
  public availableCollectionTypeIds: number[] = [];


  // Údaje k zobrazovaniu a editácii Collection Requestu.
  private _isCollectionRequest = false;

  public isCollectionRequestSentToMeChanges$ = new Subject<boolean>();

  public set isCollectionRequestSentToMe(value: boolean) {
    this._isCollectionRequestSentToMe = value;
    this.isCollectionRequestSentToMeChanges$.next(Boolean(value));
  }

  public get isCollectionRequestSentToMe(): boolean {
    return this._isCollectionRequestSentToMe;
  }

  private _isCollectionRequestSentToMe = true;

  // default nastavenie dátumu 'limitDate'
  private defaultLimitDate = moment().startOf("day").add(LIMIT_DATA_PICKUP_DATE_DIFF, 'days').toDate();
  private defaultMinLimitData = moment().startOf("day").add(1, 'days').toDate();

  public crProductCode: string = null;

  public crWeight: number = null;
  public crInfo1: string = null;
  public crInfo2: string = null;


  // Return produkt a služba.
  public isReturnProductSelected = false;
  public isReturnServiceSelected = false;

  public returnServiceCode = ConfigModel.WellKnownProductCodes.ReturnService;

  // V prípade returnu ako produktu potrebujeme mať niekde odosielateľa.
  public returnSender: RecipientModel.Recipient = {} as any;


  // V prípade returnu ako služby potrebujeme spätnú adresu niekde mať.
  public returnRecipient: RecipientModel.Recipient = {} as any;

  public defaultParcelWeight: number = null;
  public defaultParcelWidth: number = null;
  public defaultParcelHeight: number = null;
  public defaultParcelLengtht: number = null;

  public dummyMeRecipient: RecipientModel.Recipient = {} as RecipientModel.Recipient;
  public invoicingSender: RecipientModel.Recipient = {} as RecipientModel.Recipient;
  public invoicingReceiver: RecipientModel.Recipient = {} as RecipientModel.Recipient;
  public senderInvoicingAddress: ShipmentsModel.Address = {} as ShipmentsModel.Address;
  public receiverInvoicingAddress: ShipmentsModel.Address = {} as ShipmentsModel.Address;

  public isSaveAddressChecked: boolean;

  public get isCollectionRequest() { return this._isCollectionRequest; }

  public set isCollectionRequest(newValue: boolean) {
    this._isCollectionRequest = newValue;

    // Ak prepíname na collection request a máme vyplneného adresáta, tak ten bude v CR odosielateľ.
    // Späť to už nevraciame.
    if (newValue) {
      if (this.recipient?.name && !this.collectionRequestRecipient?.name) {
        this.collectionRequestRecipient = this.recipient;
        this.recipient = this.getEmptyRecipient();
        // If the recipients gets empty data clear also the PUDO.
        this.clearPudo();
      }

      this.hasCustomSenderAddress = false || this.isCustomerService;
      this.isCustomClearance = false;

      this.checkCustomsObligations();

      if (!this.pickupDate || this.pickupDate < this.minDateCR) {
        this.pickupDate = this.minDateCR;
        this._unavailableDaysLoad$.next({year: moment().year(), month: moment().month() + 1, pickupDate: this.pickupDate });
      }

      if (this.pickupDate && this.pickupDate > this.maxDateCR) {
        this.pickupDate = this.maxDateCR;
        this._unavailableDaysLoad$.next({ year: moment().year(), month: moment().month() + 1, pickupDate: this.pickupDate });
      }

      this.checkFixedPickupDate(this.collectionRequestRecipient.country);
    }

    this.checkProductsAndAdditionalServicesAvailability();
    this.weightChanged();
    this.setPanelsOrder();
    this.initCodForCr();
  }


  private entityValidationToast: ActiveToast<EntityValidationToast>;


  /**
   * Validačná funkcia pre FormControl zobrazujúci ZIP.
   * Sama validáciu nepúšťa, len aktualizuje hodnotu podľa
   * toho, ako prebehla validácia v rámci setProductsAndAdditionalServicesAvailability().
   */
  public validateZip = () => {
    if (this.zipIsInvalid) {
      return {
        zip: {
          valid: false
        }
      };
    }

    return null;
  }


  /**
   * Validačná funkcia pre FormControl zobrazujúci mesto.
   * Sama validáciu nepúšťa, len aktualizuje hodnotu podľa
   * toho, ako prebehla validácia v rámci setProductsAndAdditionalServicesAvailability().
   */
  public validateCity = () => {
    if (this.cityIsInvalid) {
      return {
        city: {
          valid: false
        }
      };
    }

    return null;
  }

  public checkZipWarningAddress()
  {
    this.zipWarningsAddress = null;

    const zip = this.recipient?.zip;
    const countryCode = this.recipient.country?.code;

    if (!zip || !countryCode) {
      return;
    }

    this._newsfeedService.getZipWarnings(countryCode, zip).subscribe(zipWarnings => {
      this.zipWarningsAddress = zipWarnings.map(zp => zp.title);

      if (this.zipWarningsAddress.length < 1)
      {
        this.zipWarningsAddress = null;
      }
    });
  }

  public checkZipWarningPickupPoint()
  {
    this.zipWarningsPickupPoint = null;

    const zip = this.pickupPointZipValue;
    const countryCode = this.pickupPointCountryCodeValue;

    if (!zip || !countryCode) {
      return;
    }

    this._newsfeedService.getZipWarnings(countryCode, zip).subscribe(zipWarnings => {
      this.zipWarningsPickupPoint = zipWarnings.map(zp => zp.title);

      if (this.zipWarningsPickupPoint.length < 1)
      {
        this.zipWarningsPickupPoint = null;
      }
    });
  }


  // Potrebujeme niektoré formulárové polia sledovať,
  // aby sme vyvolali validáciu aj na zmenu adresy.
  public addressZip = new FormControl("", [
    this.validateZip,
    validateZipNoSpaces,
    validateZipFormat(() => this.getZipFormatsForPudoRecipient())
  ]);
  public addressCountry = new FormControl();
  public addressCity = new FormControl("", [this.validateCity]);
  public invoiceLinesControl = new FormGroup({});

  public pudoRecipientAddressZip = new FormControl();
  public pudoRecipientAddressCity = new FormControl();
  public pudoRecipientAddressStreet = new FormControl();
  public pudoRecipientAddressHouseNr = new FormControl();


  public citiesDataService: RemoteData = null;
  public zipCityPairsDataService: RemoteData = null;


  // Aj pri výbere odberného miesta máme "predfilter" na presnejšiu lokalizáciu odberného miesta.
  public pickupPointCity = new FormControl();
  public pickupPointCountry = new FormControl();
  public pickupPointStreet = new FormControl();
  public pickupPointZip = new FormControl("", [
    this.validateZip,
    validateZipNoSpaces,
    validateZipFormat(() => this.getZipFormatsForPudo())
  ]);
  public pickupPointId = new FormControl();

  public pickupPointCityValue: string = null;
  public pickupPointCountryCodeValue: string = null;
  public pickupPointStreetValue: string = null;
  public pickupPointZipValue: string = null;
  public pickupPointIdValue: string = null;

  public hasSelectedProductReturnService = false;
  public sendReturnLabelByEmail = false;
  public excludeReturnLabelFromLabelPrinting = false;

  public get isSendingReturnLabelByEmailEnabled(): boolean {
    return this.enableSendingReturnLabelByEmailBUSetting && this.hasSelectedProductReturnService;
  }

  private _sendCustomerPersonalizedNotification = false;

  public get sendCustomerPersonalizedNotification(): boolean {
    return this._sendCustomerPersonalizedNotification;
  }

  public set sendCustomerPersonalizedNotification(value: boolean) {
    if (value) {
      if (typeof this.customerPersonalizedNotificationLanguageCode === "undefined" || this.customerPersonalizedNotificationLanguageCode === null || this.customerPersonalizedNotificationLanguageCode === "") {
        this.customerPersonalizedNotificationLanguageCode = this.recipient.languageCode;
      }

      if (typeof this.customerPersonalizedNotificationRecipients === "undefined" || this.customerPersonalizedNotificationRecipients === null || this.customerPersonalizedNotificationRecipients === "") {
        this.customerPersonalizedNotificationRecipients = this.recipient.email;
      }
    }

    this._sendCustomerPersonalizedNotification = value;
  }


  public customerPersonalizedNotificationLanguageCode: string;
  public customerPersonalizedNotificationRecipients: string;

  public formatCustomerPersonalizedNotificationRecipients = (value: string) => {
    const emails = value.match(/[^,;\s]+/g);
    return emails ? emails.join(", ") : "";
  }


  /**
   * Sem si odkladáme aktuálne maximálnu hmotnosť medzi balíkmi.
   * Potrebujeme to pre prípadný výber zo zoznamu odberných miest.
   */
  private _currentMaxParcelWeight: BehaviorSubject<number> = new BehaviorSubject(null);

  private destroy$ = new Subject<void>();

  /**
   * Funkcia, ktorá je zavesená na zmenu hodnôt v políčkach váh balíkov.
   */
  public weightChanged() {
    // Musíme tu mať medzeru časovú, ináč ešte nie sú updatované hodnoty v bindingoch.
    window.setTimeout(() => {
      this.setMaxParcelWeight();
    });
  }


  private setMaxParcelWeight() {
    let max = null;

    if (this.isCollectionRequest) {
      max = this.crWeight;
    } else if (this._allParcelsHaveSameDimensions) {
      max = this.parcels[0]?.weight;
    } else {
      const parcelWeights = this.parcels.map(p => p.weight);
      max = Math.max(...parcelWeights);
    }

    if (max !== this._currentMaxParcelWeight.getValue()) {
      this._currentMaxParcelWeight.next(max);
    }
  }


  private setUpPickupPointPrerequisitiesChangeSubscription() {
    combineLatest([
      this.pickupPointCountry.valueChanges,
      this.pickupPointZip.valueChanges,
      this.pickupPointCity.valueChanges,
      this._currentMaxParcelWeight,
      this.pickupPointStreet.valueChanges,
      this.pickupPointId.valueChanges
    ]).pipe(
      debounceTime(600)
    ).subscribe(result => {

      // Aby sme mohli načítať odberné miesta, tak potrebujem mať všetky dáta validné.
      let countryCode = result[0] as string;
      let zip = result[1] as string;
      let city = result[2] as string;
      let weight = result[3];
      let id = result[5] as string;

      this.setCanSelectPickupPoint(countryCode, zip, city, weight, id);
    });

    let psdFilterFillDelay = 1500;

    this.addressCountry.valueChanges.pipe(debounceTime(psdFilterFillDelay), filter(result => !!result))
      .subscribe(result => {
        if (!this.pickupPointCountryCodeValue || this.pickupPointCountryCodeValue === '') {
          this.pickupPointCountryCodeValue = result.code;
        }
      });

    this.addressZip.valueChanges.pipe(debounceTime(psdFilterFillDelay))
      .subscribe(result => {
        if (this.pickupPointZipValue == null || this.pickupPointZipValue === '') {
          this.pickupPointZipValue = result;
        }
      });

    this.addressCity.valueChanges.pipe(debounceTime(psdFilterFillDelay))
      .subscribe(result => {
        if (this.pickupPointCityValue == null || this.pickupPointCityValue === '') {
          this.pickupPointCityValue = result;
        }
      });

    this.pudoRecipientAddressStreet.valueChanges.pipe(debounceTime(psdFilterFillDelay))
      .subscribe(result => {
        if (this.pickupPointStreetValue == null || this.pickupPointStreetValue === '') {
          this.pickupPointStreetValue = result;
        } else if (this.pickupPointStreetValue.trim() == this.recipient.houseNr) {
          // Ak máme len číslo domu zatiaľ vyplnené, doplním ulicu.
          this.pickupPointStreetValue = result + ' ' + this.pickupPointStreetValue;
        }
      });

    this.pudoRecipientAddressHouseNr.valueChanges.pipe(debounceTime(psdFilterFillDelay))
      .subscribe(result => {
        if (this.pickupPointStreetValue == null || this.pickupPointStreetValue === '') {
          this.pickupPointStreetValue = result;
        } else if (this.pickupPointStreetValue.trim() == this.recipient.street) {
          // Ak máme len ulicu zatiaľ vyplnenú, doplním číslo domu.
          this.pickupPointStreetValue = this.pickupPointStreetValue + ' ' + result;
        }
      });
  }


  private isZipForPudoRequired(countryCode: string) {
    if (countryCode && countryCode.toUpperCase() === "IE") {
      return false;
    }

    return true;
  }


  private setCanSelectPickupPoint(
      countryCode: string,
      zip: string,
      city: string,
      weight: number,
      id: string) {
    const hasId = id != null && id !== "";

    this.canSelectPickupPoint = (countryCode !== null && countryCode !== ""
      && (!this.isZipForPudoRequired(countryCode) || (zip !== null && zip !== ""))
      && city !== null && city !== ""
      && weight !== null && weight !== 0)
      && this.isDeliveryToPudoProduct // overime ci je vybrany delivery to pudo product
      || hasId;

    if (this.canSelectPickupPoint) {
      let selectedId = null;

      if (hasId) {
        selectedId = id;
      } else if (this.selectedPudo) {
        selectedId = this.selectedPudo.pudoCode ?
          this.selectedPudo.pudoCode :
          this.selectedPudo.carrierPudoId;
      }

      this.loadPickupPoints(selectedId);
    }
  }


  private _pickupPointsLoadingSubscription: Subscription = null;


  /**
   * Ak zlyhalo načítanie odberných miest, potrebujeme o tom vedieť.
   */
  public pickupPointsLoadingFailed = false;


  private _failedToLoadPickupPointId: string = null;


  private noPudosFound = false;


  public reloadPickupPoints() {
    this.loadPickupPoints(this._failedToLoadPickupPointId);
  }


  private loadPickupPoints(selectedId: string) {
    if (!this.canSelectPickupPoint || (this.selectedProduct && !this.selectedProduct.isDeliveryToPudoProduct)) {
      return; // Safeguard, aby sme náhodou nepokračovali, ak niečo nie je OK.
    }

    if (this._pickupPointsLoadingSubscription != null) {
      this._pickupPointsLoadingSubscription.unsubscribe();
      this._pickupPointsLoadingSubscription = null;
    }


    this.pickupPointsLoadingFailed = false;

    this._pickupPointsLoadingSubscription = this._pickupPointsService.getFilteredPickupPoints({
      city: this.pickupPointCityValue,
      countryCode: this.pickupPointCountryCodeValue,
      customerDetailId: this.selectedSenderAddress.customerDetailId,
      refDate: null,
      weightInGrams: this._currentMaxParcelWeight.getValue() * 1000,
      zip: this.pickupPointZipValue,
      street: this.pickupPointStreetValue,
      id: this.pickupPointIdValue,
    }).subscribe(pickupPoints => {
      this.pickupPoints.next(pickupPoints);

      this.noPudosFound = pickupPoints.length === 0;

      if (selectedId) {
        this.selectedPudo = pickupPoints.find(p => (p.pudoCode === selectedId || p.carrierPudoId === selectedId));
      } else if (!this.noPudosFound) {
        this.selectedPudo = pickupPoints[0];
      } else {
        this.selectedPudo = null;
      }
    }, ex => {
      this.loggingService.logErrorData(ex, "Error loading pickup points.")

      let exception = this._exceptionsHandlerService.getExceptionInfo(ex);

      this._toastr.error(this.localizationService.getLocalizedExceptionString(exception));

      this.pickupPointsLoadingFailed = true;
      this._failedToLoadPickupPointId = selectedId;
    });
  }


  /**
   * Či sú splnené všekty podmienky pre zobrazenie výberu odberného miesta.
   */
  public canSelectPickupPoint = false;


  public isBusy = false;


  public activeValidationItems = new Set<ApiValidationItem>();
  public get isBusyValidating(): boolean {
    return this.activeValidationItems.size > 0;
  }

  public scheduledValidationItems = new Set<ApiValidationItem>();
  public get hasScheduledValidations(): boolean {
    return this.scheduledValidationItems.size > 0;
  }

  public get canSave(): boolean {
    return !this.isBusyValidating && !this.hasScheduledValidations;
  }

  public _unavailableDays = [];
  private _unavailableDaysNumbers: string[] = [];
  private _unavailableDaysCurrentMonth: number;
  private _unavailableDaysCurrentYear: number;
  private _unavailableDaysLoad$ = new BehaviorSubject<{year: number, month: number, pickupDate?: Date}>({year: moment().year(), month: moment().month() + 1 });

  public disabledDays = [];
  private _isSaturdayPickupAvailable = false;
  private _isSundayPickupAvailable = false;

  public onPickupMonthChanged(value) {
    this._unavailableDaysLoad$.next({year: value.year, month: value.month});
  }

  public onPickupShow() {
    // When pickupDate is set we need to reload available days for a year and a month of a currently selected date, if we do not have that state already loaded.
    if (this.pickupDate){
      let month = this.pickupDate.getMonth() + 1;
      let year = this.pickupDate.getFullYear();

      if (this._unavailableDaysCurrentMonth !== month || this._unavailableDaysCurrentYear !== year) {
        this._unavailableDaysLoad$.next({year: year, month: month});
      }
    }
  }


  private loadUnavailableDaysFromConfigSource(
    year: number,
    month: number,
    pickupDate = null) {
    this._contextService.businessUnitCode.pipe(
      filter(buCode => buCode != null),
      take(1),
      mergeMap(buCode =>
         this._shipmentService.getHolidays(buCode, year, month)
      )
    ).subscribe(holidays => {
        let days = _.map(holidays, h => moment.utc(h.eventDateUtc));
        this.loadUnavailableDaysInternal(days,
                                         pickupDate,
                                        (adjustmentPickupDateCandidate) => this.loadUnavailableDaysFromConfigSource(moment(adjustmentPickupDateCandidate).year(), moment(adjustmentPickupDateCandidate).month() + 1, adjustmentPickupDateCandidate));
    }, ex => {
      this.handleUnavailableDaysLoadError(ex);
    });
  }

  private loadUnavailableDaysFromGeoRoutingSource(
    year: number,
    month: number,
    pickupDate = null) {
      if (!this.collectionRequestRecipient.country.code || !this.collectionRequestRecipient.zip){
        return;
      }
      this._shipmentService.getNonWorkingDays(
        {
          countryCode: this.collectionRequestRecipient.country.code,
          postCode: this.collectionRequestRecipient.zip,
          month: month,
          year: year
        })
    .subscribe(nonWorkingDays => {
        let days = _.map(nonWorkingDays, n => moment(n.day));
        // In case no days are found for a given country, we fallback to default config holiday source
        if (!days.length) {
            this.loadUnavailableDaysFromConfigSource(year, month, pickupDate);
            return;
        }
        this.loadUnavailableDaysInternal(days,
                                         pickupDate,
                                         (adjustmentPickupDateCandidate) => this.loadUnavailableDaysFromGeoRoutingSource(moment(adjustmentPickupDateCandidate).year(), moment(adjustmentPickupDateCandidate).month() + 1, adjustmentPickupDateCandidate));

    }, ex => {
        this.handleUnavailableDaysLoadError(ex);
    });
  }

  // Reloads unavailable days and optionally adjust current pickup date to closest valid value.
  // In case monthly boundary is hit by iterating through possible pick up dates, reload of unavailable days source data for new month is neccessary.
  private loadUnavailableDaysInternal(
    unavailableDays: moment.Moment[],
    pickupDateToAdjust: Date = null,
    reloadUnavailableDays: (pickupDateCandidate: Date) => void = null) {
    this._unavailableDays = [];
    this._unavailableDaysNumbers = [];

    unavailableDays.forEach(day => {
      this._unavailableDays.push(moment(day.format("YYYY-MM-DD"), "YYYY-MM-DD").toDate());
      this._unavailableDaysNumbers.push(day.format("DDD"));
    });

    if (pickupDateToAdjust) {
      let [pickupDateFound, pickupDateCandidate] = this.findPickupDate(pickupDateToAdjust);
      if (pickupDateFound){
        // Valid pickup date found, we use it and are done.
        this.pickupDate = pickupDateCandidate;
      } else {
        // Couldn't find valid pickup day in the currently loaded unavailable days config context (monthly data), need to reload the config and start again.
        reloadUnavailableDays(pickupDateCandidate);
      }
    }
  }

  private findPickupDate(pickupDateCandidate: Date): [boolean, Date] {
    // Iterate through pickup day candidates and try to find valid pickup date.
    while ((moment(pickupDateCandidate).day() === 0 && !this._isSundayPickupAvailable) ||
           (moment(pickupDateCandidate).day() === 6 && !this._isSaturdayPickupAvailable) ||
           this._unavailableDaysNumbers.indexOf(moment(pickupDateCandidate).format("DDD")) > -1) {

      const _currentMonth = moment(pickupDateCandidate).month();
      pickupDateCandidate = moment(pickupDateCandidate).add(1, "day").toDate();

      if (moment(pickupDateCandidate).month() !== _currentMonth) {
        // If we haven't yet found valid pickup date and iterated through another month, we need to reload configuration for the new month and start over.
        return [false, pickupDateCandidate];
      }
    }

    // Pickup date found
    return [true, pickupDateCandidate];
  }

  private handleUnavailableDaysLoadError(ex){
    this.loggingService.logErrorData(ex, "Error loading unavailable days");
    let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
    this._toastr.error(`${this.localizationService.getLocalizedString("error_loading_unavailable_days")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
  }

  /**
   * Text, ktorý sa zobrazí pri progress bare.
   */
  public statusMessage = "";


  public b2b = RecipientModel.CustomerRecipientTypeCode.B2B;
  public b2c = RecipientModel.CustomerRecipientTypeCode.B2C;

  /**
   * Identifikátor objednávky, null je iba keď nie je komponent
   * inicializovaný, potom už musí mať číselnú hodnotu podľa parametra
   * posunutého v URL.
   */
  public shipmentOrderId = null;


  /**
   * Identifikátor adresáta, ktorý sa ma nainicializovať, ak ide o novú objednávku.
   */
  private _recipientId = null;


  private _fromSenderScan = false;

  /**
   * Identifikácia, či sme prišli zo stránky sender skenu.
   */
  public get fromSenderScan() { return this._fromSenderScan; }


  public listOfSwapTypes: string[] = [
    ShipmentsModel.SwapTypeCode.AB,
    ShipmentsModel.SwapTypeCode.ABC
  ];

  public swapTypeSelected = ShipmentsModel.SwapTypeCode.AB;

  public swapAbcAddress: ShipmentsModel.Address = {} as ShipmentsModel.Address;

  public isSameNumberOfSwapParcelsRequiredAsOriginalParcels = false;


  /**
   * Značí, či ide o novú alebo existujúcu objednávku.
   */
  public get isNewShipmentOrder() {
    return this.shipmentOrderId == null || this.shipmentOrderId < 1;
  }


  public get canChangeIsCollectionRequest() {
    // Ak už editujeme objednávku, ktorá bola collection request, tak už to nemôžeme odškrtnúť.
    return this.isNewShipmentOrder;
  }


  public get isReadOnly() {
    let retVal = !this.isNewShipmentOrder &&
      this.existingOrderEditorModel != null &&
      !(this.existingOrderEditorModel.order.stateId === ShipmentsModel.ShipmentStates.new ||
        this.existingOrderEditorModel.order.stateId === ShipmentsModel.ShipmentStates.inPreparation ||
        this.existingOrderEditorModel.order.stateId === ShipmentsModel.ShipmentStates.labelsPrinted);

    return retVal;
  }

  public get isSophia() {
    return this._shipperSettingsService.isSophia
  }


  /**
   * Plán doručenie, používa aktuálne len DPD PRECISE.
   */
  public deliveryPlan: ShipmentsModel.DeliveryPlan = {
    deliveryDateFrom: moment().add(1, "day").startOf("day").toDate(),
    deliveryDateTo: moment().add(1, "day").startOf("day").toDate()
  } as ShipmentsModel.DeliveryPlan;




  public deliveryWindows: DeliveryWindow[] = [];


  private initializeDpdPreciseFields() {
    // Ak nie je dátum doručenie zvolený, tak ho nainicializujeme.
    // Default je zajtra, nech nemusíme riešiť cut-off time.
    if (!this.deliveryPlan.deliveryDateFrom) {
      this.deliveryPlan.deliveryDateFrom = moment().add(1, "day").startOf("day").toDate();
      this.deliveryPlan.deliveryDateTo = moment().add(1, "day").startOf("day").toDate();
    }
  }


  private removeDpdPreciseSpecificFieldValues() {
    // Musíme odstrániť rozmery z balíkov, lebo ich default nepotrebujeme.
    if (this.hideDimensionEditors) {
      this.parcels.forEach(p => {
        p.height = null;
        p.width = null;
        p.length = null;
      });

      this.selectedDeliveryWindowId = null;
    }
  }


  public get isDpdPreciseProduct() {
    return this.selectedProduct && this.selectedProductCode == ConfigModel.WellKnownProductCodes.DpdPrecise;
  }


  private _selectedDeliveryWindowId: number = null;


  public get selectedDeliveryWindowId() {
    return this._selectedDeliveryWindowId;
  }


  public set selectedDeliveryWindowId(value: number) {
    this._selectedDeliveryWindowId = value;

    if (value) {
      // Musíme spraviť validáciu, či je to poslateľné na danú lokalitu.
      // this.setProductsAndAdditionalServicesAvailability();
      console.log('selectedDeliveryWindowId')
      this.checkProductsAndAdditionalServicesAvailability();
      console.log('selectedDeliveryWindowId check customs');
      this.checkCustomsObligations();
    }
  }


  private _getValidDpdPreciseDeliveryWindowsSubscription: Subscription = null;




  private _needInitializationFromDeliveryPlan = false;
  private _isInitializingFromDeliveryPlan = false;

  /*
   * Načíta doručovacie intervaly, ktoré sú pre aktuálne zvolený dátum v rámci deliveryPlan-u.
   */
  private getValidDpdPreciseDeliveryWindows() {
    if (this._isInitializingFromDeliveryPlan) {
      return;
    }

    if (this._getValidDpdPreciseDeliveryWindowsSubscription != null) {
      this._getValidDpdPreciseDeliveryWindowsSubscription.unsubscribe();
    }

    if (!this.recipient || !this.recipient.zip || !this.recipient.country) {
      return;
    }

    this.activeValidationItems.add(ApiValidationItem.DpdPreciseDeliveryWindows);

    let now = moment();

    let deliveryDateFromAndTime = moment(this.deliveryPlan.deliveryDateFrom).startOf("day");

    if (now.isAfter(deliveryDateFromAndTime)) {
      deliveryDateFromAndTime.add(now.hours(), "h").add(now.minutes(), "m");
    }

    let that = this;

    this._getValidDpdPreciseDeliveryWindowsSubscription = this._shipmentService.getValidDpdPreciseDeliveryWindows(
      deliveryDateFromAndTime.toDate(),
      this.selectedSenderAddress.customerDetailId,
      this.recipient.country.code,
      this.recipient.zip).subscribe(
        deliveryWindows => {
          this.deliveryWindows = deliveryWindows;

          if (!that._needInitializationFromDeliveryPlan) {
            // Ak v novom zozname doručovacích intervalov doteraz zvolený nemáme, tak nastavíme, že nie je zvolený žiaden.
            let currentDeliveryWindow = that.deliveryWindows.find(dw => dw.id === that.selectedDeliveryWindowId);
            if (!currentDeliveryWindow) {
              that._selectedDeliveryWindowId = null;
            }
          }

          // Pravdepodobne načítavame existujúcu objednávku.
          // Skúsime trafiť doručovacie okno, netrápi nás sameday, lebo ak aj sú 2 okná s rovnakým
          // intervalom, tak naraz ich tu nemôžeme dostať. A ak máme ešte objednávku editovateľnú,
          // tak to nám aspoň zamení v prípade, že sme mali sameday, tak okno nebudeme mať.
          // Pri read-only objednávkach sa toto, čo tu robíme, už neprejaví.
          else {
            that._isInitializingFromDeliveryPlan = true;


            let currentDeliveryWindow = that.deliveryWindows.find(dw => {
              return dw.timeFrom.getHours() === that.deliveryPlan.timeFrameFrom.getHours() && dw.timeFrom.getMinutes() === that.deliveryPlan.timeFrameFrom.getMinutes()
            });

            if (currentDeliveryWindow) {
              that._selectedDeliveryWindowId = currentDeliveryWindow.id;
            } else {
              that.deliveryPlan.timeFrameFrom = null;
              that.deliveryPlan.timeFrameTo = null;
              that.deliveryPlan.deliveryDateFrom = moment().startOf("day").add(1, "day").toDate();
              that.deliveryPlan.deliveryDateTo = moment().startOf("day").add(1, "day").toDate();
            }

            that._needInitializationFromDeliveryPlan = false;
            that._isInitializingFromDeliveryPlan = false;
          }

          that.activeValidationItems.delete(ApiValidationItem.DpdPreciseDeliveryWindows);

          // Môžeme tu mať už vybraný doručovací interval, tak zavoláme celkovú valicáciu produktu a služieb.
          that.checkProductsAndAdditionalServicesAvailability();
          this.checkCustomsObligations();
        },
        ex => {
          that.activeValidationItems.delete(ApiValidationItem.DpdPreciseDeliveryWindows);
          let exceptionInfo = that._exceptionsHandlerService.getExceptionInfo(ex);
          this._toastr.error(this.localizationService.getLocalizedString("error_loading_delivery_windows") + ":\n" + this.localizationService.getLocalizedExceptionString(exceptionInfo));
        }
      );
  }


  // Číselníky

  /**
   * Zoznam všetkých produktov a doplnkových služieb aj s atribútmi
   * pre nastavenie rozhrania na základe ich výberu a povolenia/zakázania.
   */
  public productsAndAdditionalServices: ConfigModel.Product[] = null;


  /**
   * Zoznam krajín.
   * TODO: Filtrovanie podľa platnosti záznamu - to zatiaľ neriešime.
   */
  public countries: Shared.Country[] = [];
  public countriesWithEmpty: Shared.Country[] = [];


  public crAvaiableCountriesBuSetting: string[] = null; // list of country codes
  public crAvaiableCountries: Shared.Country[] = null;


  // Objekty pre editáciu.
  // Netreba zabudnúť, že doplnková služba je forma produktu, takže ich pokrýva ten istý
  // typ v objektovom modeli (aj dátovom).

  /**
   * Zoznam produktov.
   * Produkt môže byť vybraný vždy len jeden.
   */
  public products: ConfigModel.OrderEditorProductViewModel[] = [];


  /**
   * Doplnkové služby.
   * Môže ich byť vybraných viacero.
   */
  public additionalServices: ConfigModel.OrderEditorProductViewModel[] = [];


  /**
   * Aktuálne vybraný produkt.
   * Potrebujeme to mať oddelené kvôli tomu, že od toho záleží veľa
   * parametrov zobrazovaného formulára editácie objednávky.
   */
  public get selectedProduct(): ConfigModel.OrderEditorProductViewModel {
    let selectedProduct = _.find(this.products, (p: ConfigModel.OrderEditorProductViewModel) => p.isSelected);

    return selectedProduct;
  };

  /**
   * Nastaví aktuálne vybraný produkt.
   * @param value Objekt produktu, ktorý sa má vybrať zo zoznamu this.products.
   */
  public set selectedProduct(value: ConfigModel.OrderEditorProductViewModel) {
    let selectedProduct = _.find(this.products, (p: ConfigModel.OrderEditorProductViewModel) => p.isSelected);

    if (selectedProduct != null) {
      selectedProduct.isSelected = false;
    }

    if (value) {
      value.isSelected = true;

      if (value.code === ConfigModel.WellKnownProductCodes.Return) {
        this.onReturnProductSelected();
      } else {
        this.onReturnProductDeselected();
      }

      if (value.code === ConfigModel.WellKnownProductCodes.DpdPrecise) {
        this.initializeDpdPreciseFields();
        this.getValidDpdPreciseDeliveryWindows();
      } else {
        this.removeDpdPreciseSpecificFieldValues();
      }

      if (this.isFreshProductSelected) {
        this.setDefaultFreshProductParcelValues(value.serviceCodesInProduct);
      }

      if (this.isCollectionRequest && this.isDeliveryToPudoProduct) {
        this.setCrPudoProductCombinationRecipient();
      }
    }

    // Musíme revalidovať možnosti výberu doplnkových služieb.
    this.checkProductsAndAdditionalServicesAvailability(false);

    this.checkCustomsObligations();

    this.selectedProductChanges$.next(value);
  }

  private selectedProductChanges$ = new Subject<ConfigModel.Product>();

  private setCrPudoProductCombinationRecipient() {
    this.recipient ??= this.getEmptyRecipient();
    this.recipient.country ??= this.dummyMeRecipient.country;
    this.recipient.countryCode ||= this.dummyMeRecipient.countryCode;
    this.recipient.zip ||= this.dummyMeRecipient.zip;
    this.recipient.city ||= this.dummyMeRecipient.city;

    this.isCollectionRequestSentToMe = false;
  }

  private setDefaultFreshProductParcelValues(productServiceCodes: string[]): void {
    [this.minTempValue, this.maxTempValue] = this.getProductTemperatureRange(productServiceCodes);

    this.parcels.forEach(p => {
      p.tempMin = this.minTempValue;
      p.tempMax = this.maxTempValue;
    });
  }

  private getProductTemperatureRange(productServiceCodes: string[]): number[] {
    const tempCode = productServiceCodes.find(sc => ConfigModel.ProductsTemparatureRanges.has(sc));
    return ConfigModel.ProductsTemparatureRanges.get(tempCode) ?? [0, 4];
  }


  public get recipientType(): string {
    return this.selectedProduct && this.selectedProduct.isDeliveryToBusinessAddressProduct ?
      RECEIVER_TYPE_BUSINESS :
      RECEIVER_TYPE_PRIVATE;
  }


  private getDefaultReturnRecipient() {
    let emptyRecipient = this.getEmptyRecipient();
    let address = this.settings.defaultReturnAddress.value;
    if (address) {
      emptyRecipient.name = address.companyName ? address.companyName : address.name1;
      emptyRecipient.name2 = address.name2;
      emptyRecipient.contactPerson = address.contactPerson;

      emptyRecipient.street = address.street;
      emptyRecipient.streetDetail = address.addressLine2;
      emptyRecipient.houseNr = address.houseNr;
      emptyRecipient.city = address.city;
      emptyRecipient.stateCode = address.stateCode;
      emptyRecipient.countryCode = address.countryCode;
      emptyRecipient.country = this.countries.find(c => c.code === emptyRecipient.countryCode);
      emptyRecipient.referenceNumber = "";
      emptyRecipient.zip = address.zip;
      emptyRecipient.email = address.email;
      emptyRecipient.phone = address.phone;
      emptyRecipient.comment = address.comment;
      emptyRecipient.languageCode = address.languageCode;
    } else {
      // Dávame tam aktuálnu pickup adresu.
      emptyRecipient.name = this.selectedSenderAddress.name1;
      emptyRecipient.name2 = this.selectedSenderAddress.name2;
      emptyRecipient.contactPerson = "";

      emptyRecipient.street = this.selectedSenderAddress.street;
      emptyRecipient.streetDetail = this.selectedSenderAddress.street2 || "";
      emptyRecipient.houseNr = this.selectedSenderAddress.houseNr;
      emptyRecipient.city = this.selectedSenderAddress.place;
      emptyRecipient.stateCode = "";
      emptyRecipient.countryCode = this.selectedSenderAddress.countryCode;
      emptyRecipient.country = this.countries.find(c => c.code === emptyRecipient.countryCode);
      emptyRecipient.referenceNumber = "";
      emptyRecipient.zip = this.selectedSenderAddress.zip;
      emptyRecipient.email = this.selectedSenderAddress.email;
      emptyRecipient.phone = this.selectedSenderAddress.phone;
      emptyRecipient.comment = "";
      emptyRecipient.languageCode = this.settings.default_recipient_language_code.value;
    }

    return emptyRecipient;
  }

  private getDefaultCustomSenderRecipient(): RecipientModel.Recipient {
    const recipient = this.getEmptyRecipient();
    const address = this.settings.defaultCustomSenderAddress.value;

    if (!address) {
      return null;
    }

    recipient.name = address.companyName ? address.companyName : address.name1;
    recipient.name2 = address.name2;
    recipient.contactPerson = address.contactPerson;

    recipient.street = address.street;
    recipient.streetDetail = address.addressLine2;
    recipient.houseNr = address.houseNr;
    recipient.city = address.city;
    recipient.stateCode = address.stateCode;
    recipient.countryCode = address.countryCode;
    recipient.country = this.countries.find(c => c.code === recipient.countryCode);
    recipient.referenceNumber = "";
    recipient.zip = address.zip;
    recipient.email = address.email;
    recipient.phone = address.phone;

    return recipient;
  }


  private getZipFormatsForCountry(code: string): string[] {
    if (!code) {
      return [];
    }

    return this.countries.find(c => c.code === code)?.postCodePattern?.split(",") ?? [];
  }

  private getZipFormatsForPudoRecipient(): string[] {
    return this.getZipFormatsForCountry(this.recipient?.country?.code)
  }

  private getZipFormatsForPudo(): string[] {
    return this.getZipFormatsForCountry(this.pickupPointCountryCodeValue);
  }


  private onReturnProductSelected() {
    this.isReturnProductSelected = true;

    if (this.returnSender && (this.returnSender.name || this.returnSender.street)) {
      // Nič nerobíme.
    } else {
      // Nemáme ešte return sendera, tak ak máme recipienta, tak zoberieme toho a ako recipienta nastavíme default return recipienta.

      this.returnRecipient = this.recipient === null || this.recipient.country == null ? this.getDefaultReturnRecipient() : this.recipient;


      this.returnSender = this.settings.empty_return_sender.value ? this.getEmptyRecipient() : this.recipient;
      this.recipient = this.getDefaultReturnRecipient();

      if (!this.settings.empty_return_sender.value && (!this.returnSender || !(this.returnSender.name || this.returnSender.street))) {
         this.returnSender = this.getDefaultReturnRecipient();
       }
    }

    // Temp fix until return is resolved for customers service.
    this.hasCustomSenderAddress = this.isCustomerService;
  }



  private onReturnProductDeselected() {
    if (this.isReturnProductSelected) {

      if (this.returnRecipient !== null && this.returnRecipient.country != null) {
        this.recipient = this.returnRecipient;
      } else {
        if (this.recipient === null || this.recipient.country == null) {
          this.recipient = this.getDefaultReturnRecipient();
        }
      }

      this.returnSender = null;
    }

    this.isReturnProductSelected = false;
  }


  public get isFreshProductSelected() {
    return (
      typeof this.selectedProduct !== "undefined" &&
      this.selectedProduct !== null &&
      this.selectedProduct.serviceCodesInProduct.find(sc =>
        sc === ConfigModel.WellKnownServiceCodes.Fresh ||
        sc === ConfigModel.WellKnownServiceCodes.Frozen ||
        sc === ConfigModel.WellKnownServiceCodes.Ambient)
    );
  }


  public get selectedProductCode(): string {
    if (typeof this.selectedProduct === "undefined" || this.selectedProduct === null) {
      return null;
    }

    return this.selectedProduct.code;
  }


  public set selectedProductCode(value: string) {
    const product = _.find(this.products, (p: ConfigModel.OrderEditorProductViewModel) => p.code === value);

    this.selectedProduct = product;
  }


  /**
   * Zoznam všetkých editovaných balíkov, aj tých, čo momentálne
   * nie sú zobrazené kvôli tomu, že používateľ má vybrané menšie číslo.
   */
  public parcels: ShipmentsModel.OrderEditorParcel[] = [];
  public cods: ShipmentsModel.CodData[] = [];

  public parcelsForReferencesEditing: BehaviorSubject<ShipmentsModel.OrderEditorParcel[]> = new BehaviorSubject([]);
  public parcelsForDimensionsEditing: BehaviorSubject<ShipmentsModel.OrderEditorParcel[]> = new BehaviorSubject([]);
  public invoiceLinesForEditing: BehaviorSubject<InvoiceLine[]> = new BehaviorSubject([]);
  public parcelCodsEditing: BehaviorSubject<ShipmentsModel.CodData[]> = new BehaviorSubject([]);


  private _parcelsCount: number = 0;


  public get parcelsCount(): number {
    return this._parcelsCount;
  }

  public set parcelsCount(value: number) {
    if (this.maxParcelsCount != 0 && value > this.maxParcelsCount) {
      value = this.maxParcelsCount;
    }

    if (value < 0) {
      value = 1;
    }

    // Ak máme menej balíčkov, ako je nová hodnota, pridáme nové balíčky.
    // Ak máme viac, ako je požadovaný počet, tak nič nerobíme, pre aktálnu editáciu si ich nechávame,
    // kebyže sa používateľ sekne a vyberie omylom menšie číslo ako chcel.
    if (value > this._parcelsCount) {
      if (this.parcels.length < value) {
        while (this.parcels.length < value) {
          this.parcels.push(this.getEmptyParcel());
        }
      }
    }

    if (value > this._parcelsCount) {
      if (this.cods.length < value) {
        while (this.cods.length < value) {
          this.cods.push(this.getEmptyCodData());
        }
      }
    }

    this._parcelsCount = value;

    this.refreshParcelsForEditing();
    this.refreshParcelCodsForEditing();
  }

  public set invoiceAdditionalLines(value: number) {

    if (this.maxParcelsCount != 0 && value > this.maxParcelsCount) {
      value = this.maxParcelsCount;
    }

    if (value < 0) {
      value = 0;
    }

    if (value > this._invoiceAdditionalLines) {
      if (this.invoiceLines.length < value) {
        while (this.invoiceLines.length < value) {
          this.invoiceLines.push(this.getEmptyInvoiceLine());
        }
      }
    }

    this._invoiceAdditionalLines = value;

    this.refreshInvoiceLinesForEditing();
  }

  public get invoiceAdditionalLines() {
    return this._invoiceAdditionalLines;
  }

  /**
   * Nastaví zoznamy, ktoré sú vidno v UI pre editáciu referencií a rozmerov balíčkov.
   */
  private refreshParcelsForEditing = () => {
    let parcelsForReferencesEditing: ShipmentsModel.OrderEditorParcel[] = [];
    let parcelsForDimensionsEditing: ShipmentsModel.OrderEditorParcel[] = [];

    if (this.parcels.length > 0) {
      let index = 0;
      do {
        parcelsForReferencesEditing.push(this.parcels[index]);
        index++;
      } while (!this._allParcelsHaveSameReferences && index < this._parcelsCount);

      index = 0;
      do {
        parcelsForDimensionsEditing.push(this.parcels[index]);
        index++;
      } while ((!this._allParcelsHaveSameDimensions || this.isFreshProductSelected || this.isCustomClearance && this.selectedShipmentTypeCode !== ShipmentTypeCodeDocuments) &&
        index < this._parcelsCount);
    }

    this.parcelsForReferencesEditing.next(parcelsForReferencesEditing);
    this.parcelsForDimensionsEditing.next(parcelsForDimensionsEditing);
  }


  private refreshInvoiceLinesForEditing = () => {
    let invoiceLinesForEditing: InvoiceLine[] = [];

    if (this.invoiceLines.length > 0) {
      let index = 0;
      do {
        invoiceLinesForEditing.push(this.invoiceLines[index]);
        index++;
      } while (index < this._invoiceAdditionalLines);
    }

    if (this._invoiceAdditionalLines == 0) {
      invoiceLinesForEditing = [];
    }


    this.invoiceLinesForEditing.next(invoiceLinesForEditing);
  }


  private refreshParcelCodsForEditing = () => {
    if (!this.isEnabledCodForEachParcel) {
      return;
    }

    let codService = this.additionalServices.find(s => s.code === ConfigModel.WellKnownProductCodes.Cod);

    if (!codService) {
      return;
    }

    let codsForEditing: ShipmentsModel.CodData[] = [];

    if (this.cods.length > 0) {
      let index = 0;
      do {
        codsForEditing.push(this.cods[index]);
        index++;
      } while (this._allParcelsHaveOwnCod && index < this._parcelsCount);
    }

    codService.data = codsForEditing;
  }


  public static requiredValidator = (formControl: FormControl) => {
    if (formControl.value == null || formControl.value.length === 0) {
      return { isEmpty: true };
    }

    return null;
  }


  private _allParcelsHaveSameReferences = false;
  private _allParcelsHaveSameDimensions = false;
  private _allParcelsHaveOwnCod = false;


  public get allParcelsHaveSameReferences() {
    return this._allParcelsHaveSameReferences;
  }


  public set allParcelsHaveSameReferences(value: boolean) {
    this._allParcelsHaveSameReferences = value;

    this.refreshParcelsForEditing();
  }


  public get allParcelsHaveSameDimensions() {
    return this._allParcelsHaveSameDimensions;
  };


  public set allParcelsHaveSameDimensions(value: boolean) {
    this._allParcelsHaveSameDimensions = value;

    this.refreshParcelsForEditing();
  }

  public get allParcelsHaveOwnCod() {
    return this._allParcelsHaveOwnCod;
  };

  public set allParcelsHaveOwnCod(value: boolean) {
    if (this.isForcedCodForEachParcel) {
      this._allParcelsHaveOwnCod = true;
    } else {
      this._allParcelsHaveOwnCod = value;
    }

    this.refreshParcelCodsForEditing();
  }


  public note = "";

  public customerReferenceNr = "";


  public recipient: RecipientModel.Recipient = null;
  public recipientCountryChanges$ = new Subject<Country>();
  public crRecipientCountryChanges$ = new Subject<Country>();
  public crRecipientZipChanges$ = new Subject<string>();

  public collectionRequestRecipient: RecipientModel.Recipient = {} as RecipientModel.Recipient;
  public customSenderAddress: RecipientModel.Recipient = {} as RecipientModel.Recipient;
  public invoice: Invoice = null;
  public invoiceLines: InvoiceLine[] = [];
  public customClearanceEditorData: CustomClearanceEditorData = {} as CustomClearanceEditorData;
  /** Customer data obtained from DPD customer API service form component. Used when logged in as customer service. */
  public customerServiceCustomerEditorData: DpdApiCustomerEditorData = {} as DpdApiCustomerEditorData;

  public allRecipients: RecipientModel.Recipient[] = [];

  public hasCustomSenderAddress = false;
  public customSenderZipIsInvalid = false;
  public customSenderCityIsInvalid = false;
  public hasAdditionalService = false;

  selectedAdditionalServices: ConfigModel.OrderEditorProductViewModel[] = [];

  private _customSenderZipAndCityValidationSubscription: Subscription = null;

  private getCurrentRecipientType(): string {
    if (this.selectedProduct) {
      if (this.selectedProduct.isDeliveryToBusinessAddressProduct) {
        return RecipientModel.CustomerRecipientTypeCode.B2B;
      } else {
        return RecipientModel.CustomerRecipientTypeCode.B2C;
      }
    } else {
      // Sem sa dostaneme aj v prípade CR, lebo sa tomuto komponentu neposúva selectedProduct.
      return RecipientModel.CustomerRecipientTypeCode.B2B;
    }
  }

  public getEmptyRecipient = (): RecipientModel.Recipient => {
    const recipient: RecipientModel.Recipient = {
      id: 0,
      createdByUser: "",
      modifiedByUser: "",
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      isNew: true,
      isPersistent: false,
      countryCode: this.settings.country_code.value,
      name: "",
      name2: "",
      contactPerson: "",
      street: "",
      houseNr: "",
      zip: "",
      city: "",
      comment: "",
      stateCode: "",
      phone: "",
      fax: "",
      email: "",
      vatNumber: "",
      eoriNumber: "",
      customerRecipientTypeCode: this.getCurrentRecipientType(),
      referenceNumber: "",
      streetDetail: "",
      country: _.find(this.countries, (c: Shared.Country) => c.code === this.settings.country_code.value),
      languageCode: this.settings.default_recipient_language_code.value ? this.settings.default_recipient_language_code.value : "",
      addressId: this.selectedSenderAddress.id
    }

    return recipient;
  }


  private getEmptyInvoice = (): Invoice => {
    const invoice: Invoice = {
      id: 0,
      createdByUser: "",
      modifiedByUser: "",
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      isNew: true,
      isPersistent: false,

      number: "",
      dateOfIssue: new Date(),
      orderId: 0,
      amount: 0,
      currencyCode: "EUR",
      comment: "",
      amountEx: 0,
      currencyCodeEx: "",
      isInvoceAddressDifferent: false,
      exportReasonCode: "01",
      sellerEoriNumber: "",
      sellerVatNumber: "",
      payerEoriNumber: "",
      payerVatNumber: "",
      mrn: ""
    }

    return invoice;
  }

  private getEmptyInvoiceLine = (): InvoiceLine => {
    return {
      id: 0,
      createdByUser: "",
      modifiedByUser: "",
      creationDateTimeUtc: null,
      modificationDateTimeUtc: null,
      isNew: true,
      isPersistent: false,

      invoiceId: 0,
      itemsNumber: 1,
      content: "",
      customsTariffNumber: "",
      amountLine: "",
      originCountryCode: "",
      receiverCustomsTariffNumber: "",
      grossWeight: null,
      internalCustomerProductCode: ""
    }
  }

  private getEmptyCodData = (): ShipmentsModel.CodData => {
    const defaultPaymentTypeId = this.settings.default_cod_payment_type_id.value;

    return {
      amount: null,
      purpose: "",
      collectionTypeId: defaultPaymentTypeId,
      currency: null
    }
  }

  private getEmptyCustomClearanceEditorData = (): CustomClearanceEditorData => {
    return {
      incoTermCode: this.incoTerms ? this.incoTerms[0].code : "",
      shipmentTypeCode: this.defaultShipmentType ? this.defaultShipmentType : (this.shipmentTypes ? this.shipmentTypes[0].code : ""),
      clearenceClearedCode: this.defaulClearanceCleared ? this.defaulClearanceCleared : (this.clearanceCleareds ? this.clearanceCleareds[0].code : ""),
      prealertStatusCode: this.defaultPrealertStatus ? this.defaultPrealertStatus : (this.prealertStatuses ? this.prealertStatuses[0].code : ""),
      accompDocsTypeCodes: [],
      eoriNumber: this._currentCustomerEoriNumber ? this._currentCustomerEoriNumber : "",
      vatNumber: this._currentCustomerVat || "",
      hasAccompanyingDocs: false,
      reasonForExport: "01",
      webSite: this._currentCustomerWebSite || "",
      hmrcNumber: this._currentCustomerHmrcNumber || ""
    }
  }


  public clearRecipient() {
    this.recipient = this.getEmptyRecipient();

    this.form.form.markAsDirty();
  }


  public clearPudo() {
    this.pickupPointCityValue = "";
    this.pickupPointCountryCodeValue = null;
    this.pickupPointStreetValue = "";
    this.pickupPointZipValue = "";
    this.pickupPointIdValue = "";
    this.selectedPudo = null;
  }


  public copyRecipientDataToPudoFilter() {
    this.pickupPointCityValue = this.recipient.city;
    this.pickupPointCountryCodeValue = this.recipient.country.code;
    this.pickupPointStreetValue = this.recipient.street + (this.recipient.houseNr != "" ? ` ${this.recipient.houseNr}` : "");
    this.pickupPointZipValue = this.recipient.zip;
  }


  /**
   * Dátum zvozu, default nie je zadaný.
   * NOTE: Ono je to vlastne predpokladaný dátum zvozu (expected prickup date).
   */
  public set pickupDate(value: Date | null) {
    this._pickupDate = value;

    if(value != null){
      this.defaultLimitDate = moment(value).add(LIMIT_DATA_PICKUP_DATE_DIFF, "d").toDate();
      this.defaultMinLimitData = moment(value).add(1, "d").toDate();
    }
  }

  public get pickupDate(): Date | null {
    return this._pickupDate;
  }


  public _pickupDate: Date | null = null;

  /**
   * Zoznam všetkých odberných miest.
   */
  public pickupPoints: BehaviorSubject<PickupPointsModel.Pudo[]> = new BehaviorSubject([]);


  // Objekty objednávky
  // S objektami priamo nepracujeme, slúžia v prípade existujúcej objdnávky
  // na inicializáciu formulára.

  private _user: Shared.User = null;


  /**
   * Bankové kontá pre COD.
   */
  public bankAccounts: CustomerModel.BankAccount[] = [];
  public availableBankAccounts: CustomerModel.BankAccount[] = [];


  /**
   * Vybrané bankové konto.
   * Treba to inicializovať v rámci načítavania.
   * Ak je nová objednávka, tak zoberieme default z konfigurácie,
   * ak editujeme existujúcu, tak pozrieme sa na údaje, ktoré došli ku COD.
   */
  public selectedBankAccount: CustomerModel.BankAccount = null;


  /**
   * Nastaví nový atribút pre všetkých recipientov, ktorý obsahuje
   */
  private generateFullAddressAttributeForRecipients = () => {
    if (this.allRecipients) {
      this.allRecipients.forEach(r => {
        (r as any).fullAddress = `, ${(r.name2) ? r.name2 + ", " : ""} ${(r.contactPerson) ? r.contactPerson + ", " : ""} ${r.street} ${r.houseNr}, ${r.zip} ${r.city}, ${r.countryCode} ${(r.referenceNumber) ? `(${r.referenceNumber})` : ""}`;
      });
    }
  }


  public settings: SettingsModel.TenantSettings = null;


  private setDummyMeAddressFromSenderAddress() {
    this.dummyMeRecipient.name = this.selectedSenderAddress.name1;
    this.dummyMeRecipient.street = this.selectedSenderAddress.street;
    this.dummyMeRecipient.city = this.selectedSenderAddress.place;
    this.dummyMeRecipient.houseNr = this.selectedSenderAddress.houseNr;
    this.dummyMeRecipient.zip = this.selectedSenderAddress.zip;
    this.dummyMeRecipient.phone = this.selectedSenderAddress.phone;
    this.dummyMeRecipient.email = this.selectedSenderAddress.email;
    this.dummyMeRecipient.countryCode = this.selectedSenderAddress.countryCode;
    this.dummyMeRecipient.country = this.countries.find(c => c.code === this.dummyMeRecipient.countryCode);
  }


  private setSenderInvoicingAddressFromOriginalSenderAddress() {
    this.invoicingSender = _.cloneDeep(this.dummyMeRecipient);
    this.invoicingSender.country = this.countries.find(c => c.code === this.invoicingSender.countryCode);
  }

  private setReceiverInvoicingAddressCountryFromRecipientAddress() {
    this.invoicingReceiver = _.cloneDeep(this.dummyMeRecipient);
    this.invoicingReceiver.country = this.countries.find(c => c.code === this.invoicingReceiver.countryCode);
  }

  private setSenderInvoicingFromInvoicingSenderAddress() {
    if (!_.isEmpty(this.senderInvoicingAddress)) {
      this.invoicingSender.name = this.senderInvoicingAddress.name1;
      this.invoicingSender.name2 = this.senderInvoicingAddress.name2;
      this.invoicingSender.street = this.senderInvoicingAddress.street;
      this.invoicingSender.streetDetail = this.senderInvoicingAddress.addressLine2;
      this.invoicingSender.city = this.senderInvoicingAddress.city;
      this.invoicingSender.houseNr = this.senderInvoicingAddress.houseNr;
      this.invoicingSender.zip = this.senderInvoicingAddress.zip;
      this.invoicingSender.phone = this.senderInvoicingAddress.phone;
      this.invoicingSender.email = this.senderInvoicingAddress.email;
      this.invoicingSender.countryCode = this.senderInvoicingAddress.countryCode;
      this.invoicingSender.stateCode = this.senderInvoicingAddress.stateCode;
      this.invoicingSender.country = this.countries.find(c => c.code === this.senderInvoicingAddress.countryCode);

      this.invoicingSender.eoriNumber = this.invoice.sellerEoriNumber;
      this.invoicingSender.vatNumber = this.invoice.sellerVatNumber;
    }
  }

  updateSelectedServices() {
    this.selectedAdditionalServices = this.additionalServices.filter(service => service.isSelected);
  
    if (this.selectedAdditionalServices.length === 0) {
      this.dynamicFieldsResults = [];
      return;
    }
  
    const serviceIds = this.selectedAdditionalServices.map((service) => service.id);
  
    const requests = serviceIds.map((id) => this._shipmentService.getDynamicFields(id));
  
    forkJoin(requests).subscribe(
      (results: Models.AdditionalField[]) => {
        this.dynamicFieldsResults = results.reduce(
          (acc, val) => acc.concat(val),
          []
        );
      },
      (error) => {
        console.error('Error fetching dynamic fields:', error);
      }
    );
  }

  private setReceiverInvoicingFromInvoicingReceiverAddress() {
    if (!_.isEmpty(this.receiverInvoicingAddress)) {
      this.invoicingReceiver.name = this.receiverInvoicingAddress.name1;
      this.invoicingReceiver.name2 = this.receiverInvoicingAddress.name2;
      this.invoicingReceiver.street = this.receiverInvoicingAddress.street;
      this.invoicingReceiver.streetDetail = this.receiverInvoicingAddress.addressLine2;
      this.invoicingReceiver.city = this.receiverInvoicingAddress.city;
      this.invoicingReceiver.houseNr = this.receiverInvoicingAddress.houseNr;
      this.invoicingReceiver.zip = this.receiverInvoicingAddress.zip;
      this.invoicingReceiver.phone = this.receiverInvoicingAddress.phone;
      this.invoicingReceiver.email = this.receiverInvoicingAddress.email;
      this.invoicingReceiver.countryCode = this.receiverInvoicingAddress.countryCode;
      this.invoicingReceiver.stateCode = this.receiverInvoicingAddress.stateCode;
      this.invoicingReceiver.country = this.countries.find(c => c.code === this.receiverInvoicingAddress.countryCode);

      this.invoicingReceiver.eoriNumber = this.invoice.payerEoriNumber;
      this.invoicingReceiver.vatNumber = this.invoice.payerVatNumber;
    }
  }


  private populateSenderInvoicingAddress() {
    this.senderInvoicingAddress.name1 = this.invoicingSender.name;
    this.senderInvoicingAddress.name2 = this.invoicingSender.name2;
    this.senderInvoicingAddress.street = this.invoicingSender.street;
    this.senderInvoicingAddress.city = this.invoicingSender.city;
    this.senderInvoicingAddress.houseNr = this.invoicingSender.houseNr;
    this.senderInvoicingAddress.zip = this.invoicingSender.zip;
    this.senderInvoicingAddress.phone = this.invoicingSender.phone;
    this.senderInvoicingAddress.email = this.invoicingSender.email;
    this.senderInvoicingAddress.countryCode = this.invoicingSender.country.code;
    this.senderInvoicingAddress.stateCode = this.invoicingSender.stateCode;
    this.senderInvoicingAddress.addressLine2 = this.invoicingSender.streetDetail;

    this.invoice.sellerEoriNumber = this.invoicingSender.eoriNumber;
    this.invoice.sellerVatNumber = this.invoicingSender.vatNumber;
  }

  private populateReceiverInvoicingAddress() {
    this.receiverInvoicingAddress.name1 = this.invoicingReceiver.name;
    this.receiverInvoicingAddress.name2 = this.invoicingReceiver.name2;
    this.receiverInvoicingAddress.street = this.invoicingReceiver.street;
    this.receiverInvoicingAddress.city = this.invoicingReceiver.city;
    this.receiverInvoicingAddress.houseNr = this.invoicingReceiver.houseNr;
    this.receiverInvoicingAddress.zip = this.invoicingReceiver.zip;
    this.receiverInvoicingAddress.phone = this.invoicingReceiver.phone;
    this.receiverInvoicingAddress.email = this.invoicingReceiver.email;
    this.receiverInvoicingAddress.countryCode = this.invoicingReceiver.country.code;
    this.receiverInvoicingAddress.stateCode = this.invoicingReceiver.stateCode;
    this.receiverInvoicingAddress.addressLine2 = this.invoicingReceiver.streetDetail;

    this.invoice.payerEoriNumber = this.invoicingReceiver.eoriNumber;
    this.invoice.payerVatNumber = this.invoicingReceiver.vatNumber;
  }


  private setCustomClearanceOrderAttributes() {
    if (this.isCustomClearance === false) {
      this.customSenderInvoiceAddress = false;
      this.customReceiverInvoiceAddress = false;
    } else {
      if (this.recipientCountryCode === 'GB') {
        this._isRecipientEmailRequiredBrexit = true;
      } else {
        this._isRecipientEmailRequiredBrexit = false;
      }
    }
  }


  private getRecipientsSearchUrl(term: string): string {
    return `${Shared.API_URL}/recipients/filtered/?searchText=${term}` +
      `&extendedSearch=${this.settings.extended_recipient_search_turned_on.value}` +
      `&addressId=${this.selectedSenderAddress.id}` +
      `&customerDetailId=${this.selectedSenderAddress.customerDetailId}`;
    //  `&isUsedAsReceiver=true` +
    //  `&isUsedAsReturnReceiver=true` +
    //  `&isUsedAsCRSender=true`;
  }


  /**
   * Načíta číselníky a produkty (+ doplnkové služby).
   */
  public loadLookupsAndSettings = (): Promise<any> => {
    this.isBusy = true;

    const p = new Promise((resolve, reject) => {
      const subscription = combineLatest([
        this._skDataConfigService.getCountries(), // 0
        this._skDataConfigService.getProductsAndAdditionalService(), // 1
        this.authenticationService.user, // 2
        this._contextService.currentAddress, // 3
        forkJoin([
          this._contextService.currentCustomerDetail.pipe(
            filter(value => value !== null),
            map(cd => cd.id),
            take(1)
          ),
          this._contextService.isCustomerLevelTenant$.pipe(take(1)),
        ]).pipe(
          switchMap(([cdId, isCustomerLevelTenant]: [number, boolean]) => {
            const customerDetailId = isCustomerLevelTenant ? cdId : null;
            return this._recipientsService.getRecipients(customerDetailId)
          })
        ), // 4
        this._settingsService.activeConfigurationSettings$, // 5
        combineLatest([
          this._contextService.tenantId,
          this._contextService.currentCustomerDetail
        ]).pipe(
          filter(result => result[0] !== null && result[1] !== null),
          mergeMap(result => {
            return this._customerService.getCustomerDetailBankAccounts(result[0], result[1].id);
          }), // 6
        ),
        this._contextService.currentCustomerDetail.pipe(
          filter(cd => cd !== null),
          mergeMap(cd => {
            this._currentCustomerEoriNumber = cd.eoriNumber;
            this._currentCustomerVat = cd.icDph;
            this._currentCustomerWebSite = cd.webSite;
            this._currentCustomerHmrcNumber = cd.hmrcNumber;
            return this._skDataConfigService.getAllowedProductsAndAdditionalServices(cd.id);
          }), // 7
        ),
        this._businessUnitSettingsService.getHideDimensionsEditorForParcelsSettingValue(), // 8
        this._skDataConfigService.getLanguages(), // 9
        this._businessUnitSettingsService.getUseB2BAddressFieldsOnly(), // 10
        this._businessUnitSettingsService.getIsCRProductRequiredForCREditing(), // 11
        this._businessUnitSettingsService.getIsCustomSenderAddressAllowed(), // 12
        this._businessUnitSettingsService.getUseRecipientFirstOrderFormSettingValue(), // 13
        this._contextService.currentAddress.pipe(
          filter(ca => ca !== null),
          mergeMap(ca => {
            return this._skDataConfigService.getIsCodForCrAllowed(ca.customerDetailId);
          }), // 14
        ),
        this._businessUnitSettingsService.getIsSaturdayPickupAllowed(), // 15
        this._customClearanceService.getClearanceCleareds(), // 16
        this._customClearanceService.getPrealertStatuses(), // 17
        this._customClearanceService.getShippmentTypes(), // 18
        this._customClearanceService.getIncoTerms(), // 19
        this._customClearanceService.getCurriencies(), // 20
        this._customClearanceService.getAccompanyingDocsTypes(), // 21
        this._businessUnitSettingsService.getDefaultShipmentTypeForCustomClearance(), // 22
        this._businessUnitSettingsService.getDefaultClearanceClearedForCustomClearance(), // 23
        this._businessUnitSettingsService.getDefaultPrealertStatusForCustomClearance(), // 24
        this._businessUnitSettingsService.getDisableClearanceClearedForCustomClearance(), // 25
        this._businessUnitSettingsService.getDisablePrealertStatusForCustomClearance(), // 26
        this._businessUnitSettingsService.getHideClearanceClearedForCustomClearance(), // 27
        this._businessUnitSettingsService.getHidePrealertStatusForCustomClearance(), // 28
        this._businessUnitSettingsService.getIsCustomsClearanceVisible(), // 29
        this._businessUnitSettingsService.getIsEnabledDomesticCardPay(), // 30
        this._businessUnitSettingsService.getIsEnabledForeignCardPay(), // 31
        this._businessUnitSettingsService.getIsEnabledIndividuallyCOD(), // 32
        this._businessUnitSettingsService.getIsForcedIndividuallyCOD(), // 33
        this._businessUnitSettingsService.getIsSameNumberOfSwapParcelsRequiredAsOriginalParcels(), // 34
        this._businessUnitSettingsService.getIsCustomerRefUsedForCollectionRequest(), // 35
        this._businessUnitSettingsService.getIsAdditionalServicesSectionWithinBaseData(), // 36
        this._businessUnitSettingsService.getIsParcelReferenceDisplayedInReturnRequestPanel(), // 37
        this._businessUnitSettingsService.getIsShipmentPickupDateTimeVisible(), // 38
        this._businessUnitSettingsService.getCrAvailableCountries(), // 39
        this._businessUnitSettingsService.getIsCustomerPersonalizedNotificationAllowed(), // 40
        this._businessUnitSettingsService.getEnableSendingReturnLabelByEmail(), // 41
        this._businessUnitSettingsService.isEmailServerSet(), // 42
        this._notificationsTemplateService.getIsCustomerPersonalizedNotificationTemplateSet(), // 43
        this._businessUnitSettingsService.getIsParcelLengthFirstDimension(), // 44
        this._businessUnitSettingsService.getCustomsClearanceTooltips(), // 45
        this._businessUnitSettingsService.getIsCrSenderPhoneNumberOrEmailRequired(), // 46
        this._businessUnitSettingsService.getCreatePickupOrdersWithShipmentOrders(), // 47
        this._businessUnitSettingsService.getSameDayPickupOrderThresholdHour(), // 48
        this._businessUnitSettingsService.getSameDayPickupOrderThresholdMinute(),
        this._businessUnitSettingsService.getIsEmailMandatoryForCrossBorder(), // 50,
        this._businessUnitSettingsService.getUseMiniportalIntegration(), // 51
        this._businessUnitSettingsService.getIsSenderAddressProductRuleAllowed(), // 52
        this._businessUnitSettingsService.getIsSundayPickupAllowed(), // 53
        this._businessUnitSettingsService.getIsBulkShipper(), // 54
        this._businessUnitSettingsService.getAreDimensionEditorsForParcelsMandatorySettingValue(), //55
        this._businessUnitSettingsService.getFixedCrPickupDateCountries(), // 56
        this._businessUnitSettingsService.getMaxParcelsCount(), // 57
        this._businessUnitSettingsService.getStreetRegEx(), // 58
        this._businessUnitSettingsService.getCheckDefaultWeightRequiredCountry(), //59
        this._businessUnitSettingsService.getIsCrSenderPhoneNumberRequired(), // 60
        this._businessUnitSettingsService.getIsCrSenderEmailRequired(), // 61
        this._businessUnitSettingsService.getEnableProductsAndServiceAvailabilityCalculation(), // 62
        this._businessUnitSettingsService.getIsCrRecipientPhoneNumberOrEmailRequired(), // 63
        this._businessUnitSettingsService.getIsCustomClearanceExportMrnFieldVisible(), // 64
        this._businessUnitSettingsService.getIsCustomClearanceRequiredValidationTurnedOff(), // 65
        this._businessUnitSettingsService.getCustomClearanceRequiredValidationCountryCodes(), // 66
        this._businessUnitSettingsService.getCustomClearanceBoundFieldsRequiredValidationCountryCodes() // 67
      ]).subscribe(
        (result: [
          Shared.Country[], //0
          ConfigModel.Product[], //1
          Shared.User, //2
          CustomerModel.Address, //3
          RecipientModel.Recipient[], //4
          SettingsModel.TenantSettings, //5
          CustomerModel.BankAccount[], //6
          ConfigModel.Product[], //7
          boolean, //8
          string[], //9
          boolean, //10
          boolean, //11
          boolean, //12
          ConfigModel.ClearanceCleared[], //13
          ConfigModel.PrealertStatus[], //14
          ConfigModel.ShipmentType[], //15
          ConfigModel.IncoTerm[], //16
          ConfigModel.Currency[], //17
          ConfigModel.AccompanyingDocsType[], //18
          string, //19
          string, //20
          string, //21
          boolean, //22
          boolean, //23
          boolean, //24
          boolean, //25
          boolean, //26
          boolean, //27
          boolean, //28
          boolean, //29
          boolean, //30
          boolean, //31
          boolean, //32
          boolean, //33
          boolean, //34
          boolean, //35
          boolean, //36
          boolean, //37
          boolean, //38
          string, //39
          boolean, //40
          boolean, //41
          boolean, //42
          boolean, //43
          boolean, //44
          boolean, //45
          boolean, //46
          boolean, //47
          string, //48
          string, //49
          boolean, //50
          boolean, //51
          boolean, //52
          boolean, //53
          boolean, //54
		  boolean, //55
      string, // 56
      string, // 57
      string, // 58
            string, //59,
            boolean, //60,
            boolean, //61,
            boolean, //62,
            boolean, // 63,
            boolean, // 64,
            boolean, // 65
            string, // 66
            string // 67
        ]) => {
          // Ak niečo je null, tak ešte nemáme správne údaje a čakame na ne.
          if (!result.every(value => value != null)) {
            return;
          } else {
            // Už všetko máme, môžeme prestať čakať na update.
            subscription.unsubscribe();
          }

          this.countries = result[0];
          this.countriesWithEmpty = _.cloneDeep(result[0]);
          this.countriesWithEmpty.unshift({
            name: this.localizationService.getLocalizedString("country_not_specified"),
            code: null
          } as Shared.Country);

          this.citiesDataService = this._completerService.remote(null, null, "cityNameLocal");
          this.citiesDataService.urlFormater((term: string) => {
            return `${Shared.API_URL}/countries/${this.recipient.country.code}/${term}`;
          });

          // this.zipCityPairsDataService = this._completerService.remote(null, null, "zip").descriptionField("cityName");
          // this.zipCityPairsDataService.urlFormater((term: string) => {
          //   let countryCode = this.recipient.country ? this.recipient.country.code : this.settings.country_code.value;

          //   return `${Shared.API_URL}/georouting/countries/${countryCode}/zips/${term}/city-names`;
          // });


          const productsAndAdditionalServices = result[1];
          // var pickupPoints = result[2];

          this._user = result[2];

          this.selectedSenderAddress = result[3];

          this.loadMinCRDate();
          this.loadMaxCRDate();

          // Nastavíme aj dummy adresu pre CR.
          this.setDummyMeAddressFromSenderAddress();

          // Nastavime dummy adresu ako fakturacnu odosielatela
          this.setSenderInvoicingAddressFromOriginalSenderAddress();
          this.setReceiverInvoicingAddressCountryFromRecipientAddress();

          // Adresáti.
          this.allRecipients = result[4];
          this.generateFullAddressAttributeForRecipients();

          if (this.recipientsDataService === null) {
            this.recipientsDataService = this._completerService.remote(null, null, "name");
            this.recipientsDataService.urlFormater(this.getRecipientsSearchUrl.bind(this));
            this.recipientsDataService.descriptionField("details");
            this.recipientsDataService.dataField("items");
            // local(this.allRecipients, "name,name2,contactPerson,street,zip,city,referenceNumber", "name").descriptionField("fullAddress");
          }

          if (this.recipientsDataService2 === null) {
            this.recipientsDataService2 = this._completerService.remote(null, null, "name");
            this.recipientsDataService2.urlFormater(this.getRecipientsSearchUrl.bind(this));
            this.recipientsDataService2.descriptionField("details");
            this.recipientsDataService2.dataField("items");
            // .local(this.allRecipients, "name,name2,contactPerson,street,zip,city,referenceNumber", "name").descriptionField("fullAddress");
          }

          // Nastavenia.
          this.settings = result[5];
          if (this.isNewShipmentOrder) {
            this.isSaveAddressChecked = this.settings.add_or_update_recipient_after_order_save.value;
          }

          // this.loadUnavailableDays(moment().year(), moment().month() + 1);

          this.pickupPointCountryCodeValue = null;

          this.visibleShipmentReferencesCount = this.settings.number_of_shipment_references.value;
          this.visibleParcelReferencesCount = this.settings.number_of_parcel_references.value;

          // Zresetujeme zoznam spôsobov platby.
          this.availableCollectionTypeIds = [];

          this.availableCollectionTypeIds.push(ShipmentsModel.CollectionType.Cash);

          this._isRecipientEmailRequiredSetting = this.settings.e_mail_notification_for_each_shipment.value;

          this._currentCustomerEoriNumber = this.settings.default_sender_eori_number.value ? this.settings.default_sender_eori_number.value : this._currentCustomerEoriNumber;
          this._currentCustomerVat = this.settings.default_sender_vat_number.value ? this.settings.default_sender_vat_number.value : this._currentCustomerVat;
          this._currentCustomerHmrcNumber = this.settings.default_sender_hmrc_number.value ? this.settings.default_sender_hmrc_number.value : this._currentCustomerHmrcNumber;

          this.additionalServices = [];
          this.products = [];

          // Bankové kontá.
          this.bankAccounts = result[6];
          this.availableBankAccounts = this.bankAccounts.slice();

          // Povolené produkty a služby, použijeme pri inicializácii zoznamu produktov a služieb, ak môžeme editovať.
          const allowedProductsAndAdditionalServices = result[7];

          // Či má byť schovaná editácia rozmerov.
          this.hideDimensionEditors = result[8];

          // Či sú polia editácie rozmerov povinné.
          this.areDimensionEditorsMandatory = result[55];

          // Zoznam jazykov.
          this.languages = result[9].sort();

          // Či používame iba B2B fieldy pri adresách.
          this.useB2BAddressFieldsOnly = result[10];

          // Či je potebný CR produkt na to, aby sme CR sprístupnili.
          this.isCRProductRequired = result[11];

          // Či môžeme mať vlastnú sender adresu.
          this.isCustomSenderAddressAllowed = result[12] as any;

          // Ktorý layout formulára sa má použiť - false = product first, true = recipient first.
          this.recipientFirstOrderForm = result[13] as any;
          this.setPanelsOrder();

          // Či je povolená dobierka pre Collection Request
          this.isCodForCrAllowed = result[14] as any;

          // Či je povolený sobotný pickup.
          this._isSaturdayPickupAvailable = result[15] as any;

          if (!this._isSaturdayPickupAvailable) {
            this.disabledDays.push(6);
          }

          // Či je povolený nedelny pickup.
          this._isSundayPickupAvailable = result[53] as any;

          if (!this._isSundayPickupAvailable) {
            this.disabledDays.push(0);
          }

          // Číselníky pre Custom Clearance
          this.clearanceCleareds = result[16] as any;
          this.prealertStatuses = result[17] as any;
          this.shipmentTypes = result[18] as any;
          this.incoTerms = result[19] as any;
          this.currencies = result[20] as any;
          this.accompanyingDocsTypes = result[21] as any;

          // Default hodnoty pre Custom Clearance
          this.defaultShipmentType = result[22] as any;
          this.defaulClearanceCleared = result[23] as any;
          this.defaultPrealertStatus = result[24] as any;

          // vypnute/skryte hodnoty pre Custom Clearance
          this.disableClearanceCleared = result[25] as any;
          this.disablePrealertStatus = result[26] as any;
          this.hideClearanceCleared = result[27] as any;
          this.hidePrealertStatus = result[28] as any;

          // povolene Customs Clearance
          this.allowCustomsClearance = result[29] as any;

          // povolená platba kartou v zahraničí a doma
          this.isEnabledDomesticCardPay = result[30] as any;
          this.isEnabledForeignCardPay = result[31] as any;

          // povolená alebo vyžadovaná dobierka per balík
          this.isEnabledCodForEachParcel = result[32] as any;
          this.isForcedCodForEachParcel = result[33] as any;
          if (this.isForcedCodForEachParcel) {
            this._allParcelsHaveOwnCod = true;
          }

          // vyžadovaný ten istý počet SWAP balíkov, ako pôvodných.
          this.isSameNumberOfSwapParcelsRequiredAsOriginalParcels = result[34] as any;

          // Referenčné číslo zákazníka je zobrazené v objednávke Collection Request.
          this.isCustomerRefUsedForCollectionRequest = result[35] as any;

          this.isAdditionalServicesSectionWithinBaseData = result[36] as boolean;

          this.isParcelReferenceDisplayedInReturnRequestPanel = result[37] as boolean;

          this.isShipmentPickupDateTimeVisibleBuSetting = result[38] as boolean;

          this.isCustomerPersonalizedNotificationAllowed = result[40] as boolean;

          this.enableSendingReturnLabelByEmailBUSetting = result[41] as boolean;

          this.isEmailServerSet = result[42] as boolean;

          this.isCustomerPersonalizedNotificationTemplateSet = result[43] as boolean;

          this.setCustomerPersonalizedNotificationTooltip();

          this.isSenderAddressProductRuleAllowed = result[52] as boolean;

          if (result[39] && !this.isSenderAddressProductRuleAllowed) {
            this.crAvaiableCountriesBuSetting = (result[39] as string).split(",");
            this.crAvaiableCountries = _.intersectionBy(
              this.countries,
              this.crAvaiableCountriesBuSetting.map(code => ({code})),
              "code");
          } else {
            this.crAvaiableCountriesBuSetting = null;
            this.crAvaiableCountries = this.countries;
          }

          this.isParcelLengthFirstDimension = result[44] as boolean;
          this.customsClearanceTooltips = result[45];
          this.isCrSenderPhoneNumberOrEmailRequired = result[46] as boolean;

          this.isCrSenderPhoneNumberRequired = result[60] as boolean;
          this.isCrSenderEmailRequired = result[61] as boolean;

          this.isProductsAndServiceAvailabilityCalculationEnabled = result[62] as boolean;

          this.isCrRecipientPhoneNumberOrEmailRequired = result[63] as boolean;

          this.isCustomClearanceExportMrnFieldVisible = result[64] as boolean;
          this.isCustomsClearanceRequiredValidationTurnedOff = result[65] as boolean;
          this.isCustomsClearanceBoundFieldsRequiredValidationTurnedOff = this.isCustomsClearanceRequiredValidationTurnedOff; // no need to differentiate between the CustomsData section and customs fields in other components at the moment
          this.isCustomsClearanceRequiredValidationOn = !this.isCustomsClearanceRequiredValidationTurnedOff; // inital setting
          this.isCustomsClearanceBoundFieldsRequiredValidationOn = !this.isCustomsClearanceBoundFieldsRequiredValidationTurnedOff; // inital setting

          this.customsClearanceRequiredValidationCountryCodes = (result[66] as string).split(',').map(s => s.trim()).filter(Boolean);
          this.customsClearanceBoundFieldsRequiredValidationCountryCodes = (result[67] as string).split(',').map(s => s.trim()).filter(Boolean);
          this.customsClearanceBoundFieldsRequiredValidationCountryCodes = this.customsClearanceBoundFieldsRequiredValidationCountryCodes.length ? this.customsClearanceBoundFieldsRequiredValidationCountryCodes : ['GB']; // defaults to 'GB' if setting is not set (used for NL and BE)
          //console.log('customClearanceRequiredValidationCountryCodes is ' + this.customsClearanceRequiredValidationCountryCodes);
          //console.log('customClearanceBoundFieldsRequiredValidationCountryCodes is ' + this.customsClearanceBoundFieldsRequiredValidationCountryCodes);

          this.createPickupOrdersWithShipmentOrdersBuSetting = result[47] as boolean;
          this.isEmailMandatoryForCrossBorderGeneral = result[50] as boolean;
          this.useMiniportalIntegration = result[51] as boolean;
          this.isBulkShipper = result[54] as any;
          this.fixedCrPickupDateCountries = result[56] as string;
          this.maxParcelsCount = result[57] ? parseInt(result[57]) : 0 as number;
          this.streetRegEx = result[58] as string;
          this.checkDefaultWeightRequiredCountry = result[59] as string;

          const sameDayThresholdHour = +result[0];
          const sameDayThresholdMinute = +result[1];

          const now = moment();
          const timeInMinutes = now.hours() * 60 + now.minutes();
          const thresholdTimeInMinutes = sameDayThresholdHour * 60 + sameDayThresholdMinute;

          this.minDate = timeInMinutes < thresholdTimeInMinutes ?
            now.startOf("day").toDate() :
            now.startOf("day").add(1, "day").toDate();

          // Uložíme si "čisté" objekty produktov a doplnkových služieb kvôli zasielaniu na server.
          // Tam nepotrebujeme žiadne doplnkové dáta z UI (aspoň nie v tej forme, ako ich máme pri editácii).
          this.productsAndAdditionalServices = _.sortBy(productsAndAdditionalServices, ["ordinal"]);

          // Rozhodíme produkty a doplnkové služby.
          this.productsAndAdditionalServices.forEach(product => {
            const productViewModel = _.cloneDeep(product) as ConfigModel.OrderEditorProductViewModel;

            // Default nie je nič označené ani dostupné.
            // Musíme si ešte načítať reálne údaje objednávky a získať dostupnosť
            // doplnkových služieb podľa vybraného produktu a doplnkových služieb v objednávke.
            productViewModel.isSelected = false;
            productViewModel.isAvailable = false;

            // Vyhľadáme si produkt medzi povolenými pre aktuálneho CustomerDetail a nastavíme podľa toho, či ho vôbec ponúkneme.
            const allowedProduct = allowedProductsAndAdditionalServices.find(p => p.id === product.id);

            productViewModel.isAllowed = !!allowedProduct;

            this.initializeProductDataProperty(productViewModel);

            if (product.code === ConfigModel.WellKnownProductCodes.CityService) {
              this._shipmentService.getCityServiceDeliveryWindows().subscribe(model => {
                this.cityServiceDeliveryWindows = model;
              }, ex => {
                this.loggingService.logErrorData(ex, "Error loading City Service delivery windows");

                const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);

                this._toastr.error(`${this.localizationService.getLocalizedString("error_loading_city_service_delivery_winwows")}:\n ${this.localizationService.getLocalizedExceptionString(exceptionInfo)}`);
              });
            }

            if (productViewModel.isProduct) {
              // Máme produkt.
              this.products.push(productViewModel);
            } else {
              // Ak je to SMS služba a máme nastavené, že sa to má automaticky vyberať, tak to nastavýme ako vybrané.
              // Robíme to ale len pre nové objednávky.
              if (
                this.settings.sms_notification_for_each_shipment.value &&
                productViewModel.code === ConfigModel.WellKnownProductCodes.Sms &&
                this.isNewShipmentOrder) {
                productViewModel.isSelected = true;
              }

              // Máme odplnkovú službu.
              this.additionalServices.push(productViewModel);
            }
          });

          // Máme nainicializované produkty a služby, pozrieme sa, či máme CR produkt, ak ju potrebujeme.
          if (this.isCRProductRequired) {
            const crProduct = this.products.find(p => p.isCollectionRequestProduct);

            if (crProduct) {
              this.isCRAvailable = crProduct.isAllowed;

              // Produkt odstránime zo zoznamov produktov, lebo CR zapíname cez checkbox.
              const crProductIndex = this.products.indexOf(crProduct);

              if (crProductIndex > -1) {
                this.products.splice(crProductIndex, 1);
                this.crProductCode = crProduct.code;
              }
            } else {
              this.isCRAvailable = false;
            }
          }

          resolve(true);

        },
        ex => {
          this.loggingService.logErrorData(ex, "Error loading settings, lookups, products and additional services.");

          this.isBusy = false;

          reject();
        });
    });

    return p;
  }


  /**
   * Volané v prípade, keď sa cez autocomplete vyberie adresát.
   */
  public recipientSelected = (selected: CompleterItem) => {
    const recipient = selected.originalObject as RecipientModel.Recipient;

    this.setRecipient(recipient);
  }


  public zipCityPairSelected(selected: ZipCityPair) {

    if (selected) {
      // const zipCityPairModel = selected.originalObject as ZipCityPair;

      if (typeof selected === 'object') {
        this.addressZip.setValue(selected.zip);
        this.recipient.zip = selected.zip;
        this.addressCity.setValue(selected.cityName);
        this.recipient.city = selected.cityName;
      }
    }
  }

  public onCitySelected(selected: CityZipRange) {
    if (typeof selected === "object") {
      this.recipient.city = selected.cityNameLocal;
      this.addressCity.setValue(selected.cityNameLocal);

      if (selected.beginPostCode) {
        this.recipient.zip = selected.beginPostCode;
        this.addressZip.setValue(selected.beginPostCode);
      }
    }
  }

  public pickupPountZipCityPairSelected(selected: ZipCityPair) {
    if (selected) {
      // const zipCityPairModel = selected.originalObject as ZipCityPair;
      // this.pickupPointZipValue = selected.zip;

      if (typeof selected === 'object') {
        this.pickupPointZip.setValue(selected.zip);
        this.pickupPointZipValue = selected.zip;
        this.pickupPointCity.setValue(selected.cityName);
        this.pickupPointCityValue = selected.cityName;
      }
    }
  }

  public onPickupPlaceCitySelected(selected: CityZipRange) {
    if (typeof selected === "object") {
      this.pickupPointCityValue = selected.cityNameLocal;
      this.pickupPointCity.setValue(selected.cityNameLocal);

      if (selected.beginPostCode) {
        this.pickupPointZipValue = selected.beginPostCode;
        this.pickupPointZip.setValue(selected.beginPostCode);
      }
    }
  }

  private setRecipient(recipient: RecipientModel.Recipient) {
    this.recipient = {
      ...this.recipient,
      ...recipient,
      country: this.countries.find(c => c.code === recipient.countryCode)
    }
  }


  private getCountryNameByCode(code: string): string {
    const country = this.countries.find(c => c.code === code);

    return country ? country.name : code;
  }

  private _validationSubscription: Subscription = null;

  private _productValidationSubscription: Subscription = null;
  private _lastProductValidationParameters: Partial<ShipmentsModel.AdditionalServicesCalculatorParameters> = {};
  private _areAvailableProductLoadedOnce = false;
  private _areAvailableAdditionalServicesLoadedOnce = false;

  private _customClearanceSubscription: Subscription = null;
  private _currentAddressSubscription: Subscription = null;
  private _shouldMarkAsPristine = false;

  // ----- validácia routovania - stavové premenné -----
  public isRoutable = false;
  // @todo - remove this? It will never be true. (BE code is commented out and also in try catch block. The product is just omitted. No error is thrown).
  public productIsUnavailableAtDestination = false;
  public zipIsInvalid = false;
  public cityIsInvalid = false;
  private _productsAndAdditionalServicesAvailabilityCheck$ = new Subject<void>();
  private _shouldSetFormPristineOnNextCheck = false;

  get isProductUnavailableMessageDisplayed() {
    if (!this.isRoutable || !this.selectedProduct || this.hasScheduledValidations || this.isBusyValidating) {
      return false;
    }

    return !this.selectedProduct.isAvailable;
  }

  private initProductsAndAdditionalServicesAvailabilityCheck() {
    this._productsAndAdditionalServicesAvailabilityCheck$.pipe(
      tap(() => {
        this.scheduledValidationItems.add(ApiValidationItem.AvailableProducts);
        this.scheduledValidationItems.add(ApiValidationItem.AvailableServices);
      }),
      // Better UX - don't wait when user can't select any product.
      // @todo - consider also additional services?
      debounce(() => this.products.some(p => p.isSelectable) ? of().pipe(delay(200)) : of())
    ).subscribe(() => {
      this.setProductsAndAdditionalServicesAvailability(this._shouldSetFormPristineOnNextCheck);

      this._shouldSetFormPristineOnNextCheck = false;
      this.scheduledValidationItems.delete(ApiValidationItem.AvailableServices);
      this.scheduledValidationItems.delete(ApiValidationItem.AvailableProducts);
    });
  }

  checkProductsAndAdditionalServicesAvailability(setFormPristine?: boolean) {
    if (setFormPristine) {
      this._shouldSetFormPristineOnNextCheck = true;
    }

    this._productsAndAdditionalServicesAvailabilityCheck$.next();
  }

  /**
   * Metóda zavolá API s aktuálne vybranými službami a API vráti, že čo je všetko ešte povolené
   * a to si nastavíme následne aj pre objekty zobrazované v UI.
   */
  private setProductsAndAdditionalServicesAvailability(setFormPristine?: boolean) {
    if ((this.selectedProduct === null && !this.recipientFirstOrderForm && !this.isCollectionRequest) ||
        this._suspendRoutingValidation ||
        this.isReadOnly ||
        (this.isDpdPreciseProduct && !this.selectedDeliveryWindowId)) {
      return;
    }

    if (setFormPristine) {
      this._shouldMarkAsPristine = true;
    }

    this.statusMessage = this.localizationService.getLocalizedString("status_message_validating_routing");

    if (this.selectedProduct) {

      if (this.selectedProduct.isDeliveryToPudoProduct) {
        //// Ideme na Parcel shop.
        //// Všetko je povolené, lebo nemáme ako zistiť služby.
        // this.additionalServices.forEach(existingAdditionalService => {
        //  existingAdditionalService.isAvailable = true;
        // });

        this.isRoutable = true;
      } else {
        // Produkt nie na Parcel shop.
        if (this.recipient.customerRecipientTypeCode === this.b2b &&
            !this.selectedProduct.isDeliveryToBusinessAddressProduct) {
          this.recipient.customerRecipientTypeCode = this.b2c;
        }

        if (this.recipient.customerRecipientTypeCode === this.b2c &&
            !this.selectedProduct.isDeliveryToHomeAddressProduct) {
          this.recipient.customerRecipientTypeCode = this.b2b;
        }
      }

      if (!this.isCollectionRequest) {
        this._shipmentService.getCurrencyCodes({
          additionalServiceIds: this.additionalServices.filter(s => s.isSelected).map(s => s.id),
          businessUnitCode: null,
          depotCode: this.selectedSenderAddress.depotCode,
          productId: this.selectedProduct.id,
          receiverAddress: {
            countryCode: this.recipient.country.code,
            zip: this.recipient.zip,
            city: this.recipient.city
          } as any,
          countryCode: this.selectedSenderAddress.countryCode
        }).subscribe(currencyCodes => {
          this._availableCurrencyCodes = currencyCodes;

          // Skúsime vybrať prvú menu, ak nie je nastavená žiadna.
          const codService = this.additionalServices.find(s => s.code === ConfigModel.WellKnownProductCodes.Cod);

          if (codService) {
            const codData = codService.data as ShipmentsModel.CodData;

            if (codData?.currency && Boolean(this._availableCurrencyCodes?.length)) {
              codData.currency = this._availableCurrencyCodes[0];
            }

            if ((this.recipient.country.code === this.selectedSenderAddress.countryCode && this.isEnabledDomesticCardPay) ||
                (this.recipient.country.code !== this.selectedSenderAddress.countryCode && this.isEnabledForeignCardPay)) {
              this.addCardPaymentCollectionType();
            } else {
              this.removeCardPaymentCollectionType();
            }
          }
        });
      }
    }

    this.calculateAvailableProducts();
    this.calculateAvailableAdditionalServices();
  }


  private calculateAvailableProducts() {
    // If validation is not enabled and available products were already filled return.
    if (this._areAvailableProductLoadedOnce && !this.isApiProductValidationEnabled()) {
      return;
    }

    const parameters = {
      ...this.getAdditionalServicesCalculatorParametersBase(),
      // dateUtc: new Date(),
      // destinationCity: this.recipient.city,
      // destinationCountryCode: this.recipient.country.code,
      // destinationPostCode: this.recipient.zip,
      destinationPudoId: null,
      destinationPudoCode: "",
      // senderCountryCode: this.hasCustomSenderAddress ? this.customSenderAddress.countryCode : this.selectedSenderAddress.countryCode,
      // senderPostCode: this.hasCustomSenderAddress ? this.customSenderAddress.zip : this.selectedSenderAddress.zip,
      // customerDetailId: this.selectedSenderAddress.customerDetailId,
      // isCleared: true,
      // originDepotCode: this.selectedSenderAddress.depotCode,
      // originSubcode: null,
      requestedProduct: null,
      findProduct: true,
      requestedAdditionalServices: [],
      // deliveryWindowId: this.isDpdPreciseProduct ? this.selectedDeliveryWindowId : null,
      // isCollectionRequest: false,
      // collectingAddressCountryCode: "",
      // collectingAddressZip: ""
    };

    // Send request only if relevant parameters are changed.
    const parametersDiff = DeepDiff.diff(parameters, this._lastProductValidationParameters);

    if (!parametersDiff?.length || parametersDiff?.length === 1 && parametersDiff[0]?.path[0] === "dateUtc") {
      return;
    }

    this._productValidationSubscription?.unsubscribe();

    this.resetRoutingValidationResults();

    this.activeValidationItems.add(ApiValidationItem.AvailableProducts);

    this._productValidationSubscription =
      this._shipmentService.getAllowedProductsAndAdditionalServices(parameters).pipe(
        finalize(() => {
          this.activeValidationItems.delete(ApiValidationItem.AvailableProducts);
          this._lastProductValidationParameters = parameters;
        })
      ).subscribe(availableProducts => {
        // Set products availability.
        this.products.forEach(product => {
          const isAvailable = Boolean(availableProducts.find(p => p.id === product.id));

          product.isAvailable = isAvailable;
          product.isSelectable = isAvailable;

          // if (!isAvailable) {
          //   product.isSelected = false;

          //   // Ak bol vybraný produkt, ktorý nie je dostupný, tak musíme resetnúť vybraný produkt
          //   if (product.code === this.selectedProductCode) {
          //     this.selectedProductCode = null;
          //   }

          //   if (product.code !== this.selectedProductCode) {
          //     product.isSelected = false;
          //   }
          // }
        });

        if (!this.isReadOnly) {
          this._unavailableDaysLoad$.next({year: moment().year(), month: moment().month() + 1, pickupDate: this.pickupDate });
        }

        this._areAvailableProductLoadedOnce = true;

        this.onProductsAvailabilitySuccess();
      },
      ex => {
        this.onProductsAvailabilityError(ex);

        // Ak sme mali niektorý z produktov vybraný, tak pri chybnej validácií adresy musíme produkty "resetnúť"
        this.products.forEach(product => {
          product.isAvailable = false;
          product.isSelectable = false;

          // Ak to bol default produkt z nastavení, tak ho ponecháme ako vybraný
          if (product.isSelected && product.code === this.settings.default_product_code?.value) {
            product.isSelected = true;
          } else {
            product.isSelected = false;
          }
        });
      });
  }

  private calculateAvailableAdditionalServices() {
    // If validation is not enabled and available services were already filled exit.
    if (this._areAvailableAdditionalServicesLoadedOnce && !this.isApiProductValidationEnabled() ||
        !this.selectedProduct && !this.isCollectionRequest ||
        this.isDeliveryToPudoProduct && !this.selectedPudo) {
      return;
    }

    this._validationSubscription?.unsubscribe();

    this.resetRoutingValidationResults();

    this.activeValidationItems.add(ApiValidationItem.AvailableServices);

    this._validationSubscription =
      this._shipmentService.getAllowedProductsAndAdditionalServices({
        ...this.getAdditionalServicesCalculatorParametersBase(),
        destinationPudoId: this.isDeliveryToPudoProduct ? (this.selectedPudo?.id || undefined) : undefined,
        destinationPudoCode: this.isDeliveryToPudoProduct ? (this.selectedPudo?.pudoCode ?? "") : "",
        requestedProduct: this.selectedProduct,
        findProduct: false,
        requestedAdditionalServices: this.additionalServices.filter(s => s.isSelected),
      }).pipe(
        finalize(() => {
          this.setHasSelectedProductReturnService();
          this.activeValidationItems.delete(ApiValidationItem.AvailableServices);
        })
      ).subscribe(availableServices => {
        // Set services availability.
        this.additionalServices.forEach(service => {
          const isAvailable = Boolean(availableServices.find(s => s.id === service.id));

          service.isAvailable = isAvailable;
          service.isSelectable = isAvailable;
          service.isSelected = service.isSelected && isAvailable;
        });

        this._areAvailableAdditionalServicesLoadedOnce = true;

        this.onProductsAvailabilitySuccess();
      },
      ex => {
        this.onProductsAvailabilityError(ex);

        this.additionalServices.forEach(service => {
          service.isAvailable = false;
          service.isSelectable = false;
          service.isSelected = false;
        });
      });
  }

  /**
   * Returns whether API will attempt to validate products
   * or just return full list for the customer in which case
   * no further requests are not necessary.
   */
  private isApiProductValidationEnabled(): boolean {
    return this._shipperSettingsService.isCentralShipper ?
      this._shipperSettingsService.isProductsAndServicesCalculationDuringEditingAllowed :
      this.isProductsAndServiceAvailabilityCalculationEnabled;
  }

  private resetRoutingValidationResults() {
    this.productIsUnavailableAtDestination = false;
    this.zipIsInvalid = false;
    this.cityIsInvalid = false;
    this.isRoutable = false;
  }

  private getAdditionalServicesCalculatorParametersBase(): Partial<ShipmentsModel.AdditionalServicesCalculatorParameters> {
    const senderCountryCode = this.isCollectionRequest ?
      this.collectionRequestRecipient.countryCode :
        this.hasCustomSenderAddress ?
        this.customSenderAddress.countryCode :
        this.isReturnProductSelected && this.returnSender != null ?
          this.returnSender.countryCode :
          this.selectedSenderAddress.countryCode;

    const senderPostCode = this.isCollectionRequest ?
      this.collectionRequestRecipient.zip :
      this.hasCustomSenderAddress ?
        this.customSenderAddress.zip :
        this.isReturnProductSelected && this.returnSender != null ?
          this.returnSender.zip :
          this.selectedSenderAddress.zip;

    return {
      dateUtc: new Date(),
      destinationCity: this.recipient.city,
      destinationCountryCode: this.isCollectionRequest && this.isCollectionRequestSentToMe ?
        this.selectedSenderAddress.countryCode :
        this.recipient.country?.code,
      destinationPostCode: this.isCollectionRequest && this.isCollectionRequestSentToMe ?
        this.selectedSenderAddress.zip :
        this.recipient.zip,
      // destinationPudoId: this.selectedPudo?.id || null,
      // destinationPudoCode: this.selectedPudo?.pudoCode ?? "",
      senderCountryCode: senderCountryCode,
      senderPostCode: senderPostCode,
      customerDetailId: this.selectedSenderAddress.customerDetailId,
      isCleared: true,
      originDepotCode: this.selectedSenderAddress.depotCode,
      originSubcode: null,
      // requestedProduct: this.selectedProduct,
      // findProduct: false,
      // requestedAdditionalServices: this.additionalServices.filter(s => s.isSelected),
      deliveryWindowId: this.isDpdPreciseProduct ? this.selectedDeliveryWindowId : null,
      isCollectionRequest: this.isCollectionRequest,
      collectingAddressCountryCode: this.isCollectionRequest ? this.collectionRequestRecipient.country?.code : "",
      collectingAddressZip: this.isCollectionRequest ? this.collectionRequestRecipient.zip : ""
    };
  }

  private onProductsAvailabilitySuccess() {
    // Vieme routovať.
    this.isRoutable = true;

    // update values and validity in recipients views
    this.recipientsViewComponents.forEach(recipientView => recipientView.updateValueAndValidity());

    // Musíme explicitne zavolať update niektorých vstupných polí, aby sa prejavila zmena vo validite.
    this.addressZip.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.addressCity.updateValueAndValidity({ onlySelf: true, emitEvent: false });

    if (this._shouldMarkAsPristine) {
      this._shouldMarkAsPristine = false;
      this.setFormPristine();
    }

    // If product validation is not enabled API returns both products and aditional services.
    this._areAvailableProductLoadedOnce = true;
  }

  private onProductsAvailabilityError(ex) {
    this.loggingService.logErrorData(ex);

    // Nevieme routovať vybrané produkty do špecifikovanej destinácie.
    this.isRoutable = false;

    // Určime chybu, ktorú sme dostali.
    if (ex.key) {
      switch (ex.key) {
        case Shared.ExceptionKeys.ProductUnavailableAtDestination:
          this.productIsUnavailableAtDestination = true;
          break;

        case Shared.ExceptionKeys.InvalidZipForCity:
        case Shared.ExceptionKeys.InvalidDestZip:
        case Shared.ExceptionKeys.InvalidZip:
          this.zipIsInvalid = true;
          break;

        case Shared.ExceptionKeys.InvalidCity:
          this.cityIsInvalid = true;
          break;

        default:
      }

      // Musíme explicitne zavolať update niektorých vstupných polí, aby sa prejavila zmena vo validite.
      this.addressZip.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.addressCity.updateValueAndValidity({ onlySelf: true, emitEvent: false });

      if (this._shouldMarkAsPristine) {
        this._shouldMarkAsPristine = false;
        this.setFormPristine();
      }
    }
  }

  checkCustomsObligations() {
    if (this._suspendRoutingValidation) {
      return;
    }

    this._customClearanceSubscription =
      this._customClearanceService.checkCustomsObligations({
        senderCountryCode: this.selectedSenderAddress.countryCode,
        senderZipCode: this.selectedSenderAddress.zip,
        receiverCountryCode: this.recipient.country?.code,
        receiverZipCode: this.recipient.zip
      }).subscribe(
        (result: boolean) => {
          this.isCustomClearance = result;
          const isBulkShipper = this.isBulkShipper && this.recipient.country.code === 'GB';
          if (this._shipperSettingsService.isSophia || this.isCollectionRequest || !this.allowCustomsClearance || isBulkShipper || this.isReturnProductSelected /* pre Return produkt vypneme, SHPNOTRETCUS ani neobsahuje INTER sekciu, preto vyhadzujem */) {
            this.isCustomClearance = false;
          }

          this.showMiniportalLink = (this.useMiniportalIntegration && this._shipperSettingsService.isCentralShipper && this.isCustomClearance && this.stateId != 'New' && this.stateId != 'InPreparation' && this.stateId != 'Deleted');

          this.setCustomClearanceOrderAttributes();
        });
  }


  private setHasSelectedProductReturnService() {
    if (!this.selectedProduct) {
      this.hasSelectedProductReturnService = false;
      return;
    }

    const selectedAdditionalServicesIds = this.additionalServices
      .filter(service => service.isSelected)
      .map(service => service.id);

    this.updateSelectedServices();

    this._shipmentService.hasProductReturnService(
      this.selectedProduct.id, selectedAdditionalServicesIds)
      .subscribe(result => {
        this.hasSelectedProductReturnService = result;
      }, () => { });
  }


  private setFormPristine() {
    let oldSuspendValue = this._suspendRoutingValidation;

    this._suspendRoutingValidation = true;

    let formValue = this.form.form.getRawValue();
    this.form.reset(formValue);

    this.recipientsViewComponents.forEach(rvc => {
      let fv = rvc.form.form.getRawValue();
      rvc.form.reset(fv);
    });

    this._suspendRoutingValidation = oldSuspendValue;
  }



  private addCardPaymentCollectionType() {
    if (this.availableCollectionTypeIds.indexOf(ShipmentsModel.CollectionType.Card) == -1) {
      this.availableCollectionTypeIds.unshift(ShipmentsModel.CollectionType.Card); // Na základe SPHRMK-322 dáme na prvé miesto
    }
  }

  private removeCardPaymentCollectionType() {
    let index = this.availableCollectionTypeIds.indexOf(ShipmentsModel.CollectionType.Card);
    if (index > -1) {
      this.availableCollectionTypeIds.splice(index, 1);
    }
  }


  /**
   * Podľa typu (kódu) produktu, doinicializuje atribút data potrebným objektom.
   */
  private initializeProductDataProperty = (product: ConfigModel.OrderEditorProductViewModel) => {
    switch (product.code) {
      case ConfigModel.WellKnownProductCodes.Cod:
        // Potrebujeme model, ktorý obsiahne sumu a iné náležitosti dobierky.
        let codData: ShipmentsModel.CodData = {
          amount: null,
          purpose: "",
          collectionTypeId: ShipmentsModel.CollectionType.Cash,
          currency: null
        }

        if (this.availableCurrencyCodes && this.availableCurrencyCodes.length > 0) {
          codData.currency = this.availableCurrencyCodes[0];
        }

        product.data = [codData];
        break;

      case ConfigModel.WellKnownProductCodes.IDCheck:
        // Potrebujeme model, ktorý obsiahne potrebné číslo OP alebo inej identifikačnej karty.
        let idCheckData: ShipmentsModel.IDCheckData = {
          idCardNumber: null,
          name: ""
        }

        product.data = idCheckData;
        break;

      case ConfigModel.WellKnownProductCodes.Swap:
        // Potrebujeme model pre výmenný balík a jeho náležitosti.
        let swapData: ShipmentsModel.SwapData = {
          parcelsCount: this.isSameNumberOfSwapParcelsRequiredAsOriginalParcels ? 0 : 1,
          note: "",
          type: "AB"
        }

        product.data = swapData;
        break;

      default:
        // Nič nerobíme. Produkt/odplnkovú službu je možné len vybrať alebo nevybrať.
        // V UI by ju teda mal reprezentovať iba checkbox a jej názov.
        break;
    }
  }


  private initializeNewShipmentOrder = () => {
    this._suspendRoutingValidation = true;
    this._parcelsCount = 0;

    this.parcels = [];
    this.parcels.push(this.getEmptyParcel());
    this.parcelsCount = 1;

    this.recipient = this.getEmptyRecipient();
    this.invoice = this.getEmptyInvoice();
    this.invoiceLines = [];
    this.invoiceAdditionalLines = 0;
    this.customClearanceEditorData = this.getEmptyCustomClearanceEditorData();

    this.collectionRequestRecipient = this.getEmptyRecipient();
    this.returnRecipient = this.getDefaultReturnRecipient();
    this.isReturnProductSelected = false;
    this.isReturnServiceSelected = false;

    this.hasSelectedProductReturnService = false;
    this.sendReturnLabelByEmail = false;
    this.excludeReturnLabelFromLabelPrinting = false;

    this.sendCustomerPersonalizedNotification = false;
    this.customerPersonalizedNotificationLanguageCode = "";
    this.customerPersonalizedNotificationRecipients = "";

    this.cods = [];
    this.cods.push(this.getEmptyCodData());

    this.clearPudo();
    this._selectedPudo = null;

    this.referentialInfo1 = "";
    this.referentialInfo2 = "";
    this.referentialInfo3 = "";
    this.referentialInfo4 = "";

    this.isSaveAddressChecked = this.settings ? this.settings.add_or_update_recipient_after_order_save.value : false;

    // Z nastavení musíme zobrať zopár vecí.
    // Default product:
    if (this.settings.default_product_code.value) {
      if (this.isCRAvailable && this.crProductCode === this.settings.default_product_code.value) {
        this.isCollectionRequest = true;
      } else {

        let selectedProduct = _.find(this.products, p => p.code === this.settings.default_product_code.value);

        // Vyberieme, iba keď je povolený.
        if (selectedProduct && selectedProduct.isAllowed) {
          this.selectedProduct = selectedProduct;
          // Nemusíme robiť revalidáciu podľa GeoRoutingu, lebo nemáme nič iné vyplnené (hlavne adresu nemáme).
        }
      }
    } else {
      // Ak máme len jeden produkt, tak ho vyberieme.
      if (this.products.length == 1 && this.products[0].isAllowed) {
        this.selectedProduct = this.products[0];
      }
    }

    // Default bankový účet.
    if (this.settings.automated_shipments_import_bank_account_Id &&
      this.settings.automated_shipments_import_bank_account_Id.value) {
      let bankAccount = this.bankAccounts.find(ba => ba.customId === this.settings.automated_shipments_import_bank_account_Id.value);

      if (bankAccount) {
        this.selectedBankAccount = bankAccount;
      }
    }

    // All parcels have same reference.
    this.allParcelsHaveSameReferences = this.settings.all_parcels_in_a_shipment_have_the_same_references.value;

    // Default references.
    if (this.settings.default_parcel_reference_1.value) {
      this.parcels[0].referentialInfo1 = this.settings.default_parcel_reference_1.value;
    }

    if (this.settings.default_parcel_reference_2.value) {
      this.parcels[0].referentialInfo2 = this.settings.default_parcel_reference_2.value;
    }

    // All parcels have same dimensions.
    this.allParcelsHaveSameDimensions = this.settings.all_parcels_in_a_shipment_have_the_same_dimensions.value;

    // Rozmery.
    // Rozmer nastavujeme, iba ak je hodnota väčšia ako 0.
    if (this.settings.parcel_default_dimensions.value.weightInKilograms &&
      this.settings.parcel_default_dimensions.value.weightInKilograms > 0) {
      this.defaultParcelWeight = this.settings.parcel_default_dimensions.value.weightInKilograms
      this.parcels[0].weight = this.defaultParcelWeight;
    }

    if (this.settings.parcel_default_dimensions.value.widthInCentimeters &&
      this.settings.parcel_default_dimensions.value.widthInCentimeters > 0) {
      this.defaultParcelWidth = this.settings.parcel_default_dimensions.value.widthInCentimeters
      this.parcels[0].width = this.defaultParcelWidth;
    }

    if (this.settings.parcel_default_dimensions.value.heightInCentimeters &&
      this.settings.parcel_default_dimensions.value.heightInCentimeters > 0) {
      this.defaultParcelHeight = this.settings.parcel_default_dimensions.value.heightInCentimeters;
      this.parcels[0].height = this.defaultParcelHeight;
    }

    if (this.settings.parcel_default_dimensions.value.lengthInCentimeters &&
      this.settings.parcel_default_dimensions.value.lengthInCentimeters > 0) {
      this.defaultParcelLengtht = this.settings.parcel_default_dimensions.value.lengthInCentimeters;
      this.parcels[0].length = this.defaultParcelLengtht;
    }

    // Default poznámka.
    this.note = this.settings.shipment_default_note.value;

    // default_cod_payment_type_id
    // Nájdeme si COD produkt a nastavíme predvolený spôsob platby.
    let codProduct = this.additionalServices.find(s => s.code === ConfigModel.WellKnownProductCodes.Cod);

    if (codProduct) {
      let defaultCodData = this.getEmptyCodData();
      codProduct.data = defaultCodData;
    }


    // Default krajina pre PUDO.
    this.pickupPointCountryCodeValue = null;


    if (this._recipientId) {
      // Načítame adresáta s daným ID.
      this._recipientsService.getRecipient(this._recipientId).subscribe(
        recipient => {
          if (recipient) {
            this.setRecipient(recipient);
          }

          this.finishNewShipmentSetup();
        }, () => {
          // Chybu ignorujeme.
          this.finishNewShipmentSetup();
        }
      );
    } else {
      this.finishNewShipmentSetup();
    }

    if (this.isCustomerService) {
      this.handleSelectedShipmentType(ShipmentsModel.ShipmentType.CR);
    }
  }

  private openSelectionDialog() {
    this._modal.open(
      ShipmentTypeDialogComponent,
      overlayConfigFactory({})
    )
    .result
    .then(
      (v) => this.handleSelectedShipmentType(v),
      (v) => this.handleSelectedShipmentType(v)
    );
  }

  public switchShipmentType(): void {
    const newType = this.isCollectionRequest ?
      ShipmentsModel.ShipmentType.NORMAL :
      ShipmentsModel.ShipmentType.CR;

    this.handleSelectedShipmentType(newType);
  }

  private handleSelectedShipmentType(type) {
    this.isCollectionRequest = type === ShipmentsModel.ShipmentType.CR;
  }

  private finishNewShipmentSetup() {
    this.isBusy = false;
    this._suspendRoutingValidation = false;

    this.setProductsAndAdditionalServicesAvailability(true);
  }


  public returnSenderZipIsInvalid = false;
  public returnSenderCityIsInvalid = false;

  public returnRecipientZipIsInvalid = false;
  public returnRecipientCityIsInvalid = false;

  private _returnSenderZipAndCityValidationSubscription: Subscription = null;
  private _returnRecipientZipAndCityValidationSubscription: Subscription = null;

  private get isValidatingReturnSenderZipAndCity(): boolean {
    return this.isReturnProductSelected && !this._suspendRoutingValidation;
  }


  private get isValidatingReturnRecipientZipAndCity(): boolean {
    return this.isReturnServiceSelected && !this._suspendRoutingValidation;
  }


  public crSenderZipIsInvalid = false;
  public crSenderCityIsInvalid = false;

  public crRecipientZipIsInvalid = false;
  public crRecipientCityIsInvalid = false;

  private _crSenderZipAndCityValidationSubscription: Subscription = null;
  private _crRecipientZipAndCityValidationSubscription: Subscription = null;

  private get isValidatingCrSenderZipAndCity(): boolean {
    return this.isCollectionRequest && !this._suspendRoutingValidation;
  }



  public validateReturnRecipientZipAndCity() {
    if (this.isValidatingReturnRecipientZipAndCity) {
      if (this._returnRecipientZipAndCityValidationSubscription) {
        this._returnRecipientZipAndCityValidationSubscription.unsubscribe();
      }

      this._returnRecipientZipAndCityValidationSubscription = this._shipmentService.checkZipAndCity({
        cityName: this.returnRecipient.city,
        countryCode: this.returnRecipient.country.code,
        postCode: this.returnRecipient.zip,
        refDate: new Date()
      }).subscribe(result => {
        this.returnRecipientCityIsInvalid = !result.isCityValid;
        this.returnRecipientZipIsInvalid = !result.isZipValid;
      });
    }
  }


  public validateReturnSenderZipAndCity() {
    if (this.isValidatingReturnSenderZipAndCity) {
      if (this._returnSenderZipAndCityValidationSubscription) {
        this._returnSenderZipAndCityValidationSubscription.unsubscribe();
      }

      this._returnSenderZipAndCityValidationSubscription = this._shipmentService.checkZipAndCity({
        cityName: this.returnSender.city,
        countryCode: this.returnSender.country.code,
        postCode: this.returnSender.zip,
        refDate: new Date()
      }).subscribe(result => {
        this.returnSenderCityIsInvalid = !result.isCityValid;
        this.returnSenderZipIsInvalid = !result.isZipValid;
      });
    }
  }

  public onHasCustomSenderAddressModelChange() {
    this.checkProductsAndAdditionalServicesAvailability();
    this.checkCustomsObligations();
  }

  public onCustomerSenderAddressChange() {
    this.validateCrRecipientZipAndCity();
    this.checkProductsAndAdditionalServicesAvailability();
    this.checkCustomsObligations();
  }

  public validateCustomSenderZipAndCity() {
    if (this._suspendRoutingValidation ||
        // For customer service custom sender is filled from
        // DPD API customer component with its own validation.
        this.isCustomerService) {
      return;
    }

    if (this._customSenderZipAndCityValidationSubscription) {
      this._customSenderZipAndCityValidationSubscription.unsubscribe();
    }

    this._customSenderZipAndCityValidationSubscription = this._shipmentService.checkZipAndCity({
      cityName: this.customSenderAddress.city,
      countryCode: this.customSenderAddress.country?.code,
      postCode: this.customSenderAddress.zip,
      refDate: new Date()
    }).subscribe(result => {
      this.customSenderCityIsInvalid = !result.isCityValid;
      this.customSenderZipIsInvalid = !result.isZipValid;
    });
  }


  public validateCrSenderZipAndCity() {
    if (this.isValidatingCrSenderZipAndCity) {
      if (this._crSenderZipAndCityValidationSubscription) {
        this._crSenderZipAndCityValidationSubscription.unsubscribe();
      }

      this._crSenderZipAndCityValidationSubscription = this._shipmentService.checkZipAndCity({
        cityName: this.collectionRequestRecipient.city,
        countryCode: this.collectionRequestRecipient.country?.code,
        postCode: this.collectionRequestRecipient.zip,
        refDate: new Date()
      }).subscribe(result => {
        this.crSenderCityIsInvalid = !result.isCityValid;
        this.crSenderZipIsInvalid = !result.isZipValid;
      });

      this.checkProductsAndAdditionalServicesAvailability();
      this.checkCustomsObligations();
    }
  }


  public validateCrRecipientZipAndCity() {
    if (!this.isValidatingCrSenderZipAndCity) {
      return;
    }

    if (this._crRecipientZipAndCityValidationSubscription) {
      this._crRecipientZipAndCityValidationSubscription.unsubscribe();
    }

    this._crRecipientZipAndCityValidationSubscription = this._shipmentService.checkZipAndCity({
      cityName: this.recipient.city,
      countryCode: this.recipient.country?.code,
      postCode: this.recipient.zip,
      refDate: new Date()
    }).subscribe(result => {
      this.crRecipientCityIsInvalid = !result.isCityValid;
      this.crRecipientZipIsInvalid = !result.isZipValid;
    });

    this.checkProductsAndAdditionalServicesAvailability();
  }


  private _nextNewParcelId = -1;
  private minTempValue: number = 0;
  private maxTempValue: number = 4;

  private getEmptyParcel(): ShipmentsModel.OrderEditorParcel {
    return {
      id: this._nextNewParcelId--,
      height: this.defaultParcelHeight,
      width: this.defaultParcelWidth,
      length: this.defaultParcelLengtht,
      nr: "",
      referentialInfo1: "",
      referentialInfo2: "",
      referentialInfo3: "",
      referentialInfo4: "",
      weight: this.defaultParcelWeight,
      content: "",
      customsTariffNumber: "",
      amountLine: "",
      originCountryCode: "",
      receiverCustomsTariffNumber: "",
      grossWeight: null,
      itemsNumber: 1,
      internalCustomerProductCode: "",
      customTransportCost: null,
      customTransportCostCurrencyCode: null,
      tempMin: this.minTempValue,
      tempMax: this.maxTempValue,
      description: "",
      limitDate: this.defaultLimitDate
    }
  }


  private initializeDeliveryWindowForExistingOrder() {
    this._needInitializationFromDeliveryPlan = true;
    this.getValidDpdPreciseDeliveryWindows();
  }




  private setSelectedBankAccountBasedOnCodData(cod: ShipmentsModel.Cod) {
    // Nájdeme si bankové konto.
    let bankAccount = _.find(this.bankAccounts,
      ba => {
        return (ba.bic === cod.bic &&
          ba.iban === cod.iban &&
          ba.customId === cod.bankAccountCustomId) ||
          (ba.accountNumber === cod.accountNr &&
            ba.customId === cod.bankAccountCustomId);
      });

    if (bankAccount) {
      this.selectedBankAccount = bankAccount;
    }
  }


  private fillCodsWithEmptyData(count: number = 0) {
    for (let i = 0; i < count; i++) {
      this.cods.push(this.getEmptyCodData());
    }
  }


  private loadExistingShipmentOrder() {
    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("loading_shipment_order");

    this._shipmentService.getOrder(this.shipmentOrderId)
      .subscribe((orderEditorModel: ShipmentsModel.OrderEditorModel) => {
        this._suspendRoutingValidation = true; // Nechceme zatiaľ validovať.

        this.existingOrderEditorModel = orderEditorModel;

        // Nejaké defaulty, aby nezhučali validačné procesy.
        this.recipient = this.getEmptyRecipient();


        // BaseData
        if (orderEditorModel.shipment) {
          this.referentialInfo1 = orderEditorModel.shipment.referentialInfo1;
          this.referentialInfo2 = orderEditorModel.shipment.referentialInfo2;
          this.referentialInfo3 = orderEditorModel.shipment.referentialInfo3;
          this.referentialInfo4 = orderEditorModel.shipment.referentialInfo4;
        }

        this.pickupDate = this.fromTimezonelessDate(orderEditorModel.order.pickupDate);

        if (typeof this.pickupDate === "undefined" || this.pickupDate === null) {
          this.freshPickupRequested = false;
        }
        else {
          this.freshPickupRequested = true;
        }


        // this.selectedCityServiceDeliveryWindowId = 'dw_' + orderEditorModel.order.deliveryWindowId;

        this.note = orderEditorModel.order.content;

        this.customerReferenceNr = orderEditorModel.order.customerReferenceNr;

        this.stateId = orderEditorModel.order.stateId;

        this.deliveryPlan = _.cloneDeep(orderEditorModel.deliveryPlan);

        // this.fixDeliveryPlanDatesAndTimesForDisplay(this.deliveryPlan);

        // Toto nemáme nikde uložené a môže byť problém, keď sa stláča stlačidlo back po Save & New, malo to nastavenú nesprávnu hodnotu,
        // kedžde sa komponent znovupoužíva.
        this.allParcelsHaveSameDimensions = true;
        this.allParcelsHaveSameReferences = true;
        this.allParcelsHaveOwnCod = false;

        if (orderEditorModel.shipmentProductDynamicFieldOptions) {
          this.predefinedFieldsValues = orderEditorModel.shipmentProductDynamicFieldOptions;
        }
        
        // Máme model a musíme z neho vytiahnúť určite informácie, ktoré neobsahuje vo forme
        // s ktoru pracuje klient.
        let selectedProduct = this.products.find(p => p.id === orderEditorModel.order.productId);

        // Máme return, musíme nastaviť adresu odosielateľa.
        if (selectedProduct && selectedProduct.code === ConfigModel.WellKnownProductCodes.Return) {
          this.isReturnProductSelected = true;

          this.returnSender = {} as any;
          this.returnSender.name = orderEditorModel.senderAddress.companyName ? orderEditorModel.senderAddress.companyName : orderEditorModel.senderAddress.name1;

          this.returnSender.name2 = orderEditorModel.senderAddress.name2;
          this.returnSender.contactPerson = orderEditorModel.senderAddress.contactPerson;

          this.returnSender.street = orderEditorModel.senderAddress.street;
          this.returnSender.streetDetail = orderEditorModel.senderAddress.addressLine2;
          this.returnSender.houseNr = orderEditorModel.senderAddress.houseNr;
          this.returnSender.city = orderEditorModel.senderAddress.city;
          this.returnSender.stateCode = orderEditorModel.senderAddress.stateCode;
          this.returnSender.countryCode = orderEditorModel.senderAddress.countryCode;
          this.returnSender.country = this.countries.find(c => c.code === this.returnSender.countryCode);
          this.returnSender.referenceNumber = "";
          this.returnSender.zip = orderEditorModel.senderAddress.zip;
          this.returnSender.email = orderEditorModel.senderAddress.email;
          this.returnSender.phone = orderEditorModel.senderAddress.phone;
          this.returnSender.comment = orderEditorModel.senderAddress.comment;
          this.returnSender.languageCode = orderEditorModel.senderAddress.languageCode;
        }

        // Ak nemáme oprávnenie editovať CR, tak poupravujeme model.
        if (!this.isCRAvailable && !this.isReadOnly && this.existingOrderEditorModel.order.isCollectionRequest) {
          this.existingOrderEditorModel.order.isCollectionRequest = false;
          this.existingOrderEditorModel.isCollectionRequestSentToMe = false;
        } else {
          this._isCollectionRequest = this.existingOrderEditorModel.order.isCollectionRequest;
          this.isCollectionRequestSentToMe = this.existingOrderEditorModel.isCollectionRequestSentToMe;
        }

        this.setPanelsOrder();
        this.initCodForCr();

        if (this.isCollectionRequest) {
          // Nemáme žiaden produkt vybraný.
          // this.selectedProduct = null;


          this.crWeight = orderEditorModel.order.weight !== null ? orderEditorModel.order.weight / 100 : null;
          this.crInfo1 = orderEditorModel.order.partnerReference1;
          this.crInfo2 = orderEditorModel.order.partnerReference2;


          // Musíme naplniť adresu zberu.
          this.collectionRequestRecipient.name = orderEditorModel.pickupAddress.companyName ? orderEditorModel.pickupAddress.companyName : orderEditorModel.pickupAddress.name1;

          this.collectionRequestRecipient.name2 = orderEditorModel.pickupAddress.name2;
          this.collectionRequestRecipient.contactPerson = orderEditorModel.pickupAddress.contactPerson;

          this.collectionRequestRecipient.street = orderEditorModel.pickupAddress.street;
          this.collectionRequestRecipient.streetDetail = orderEditorModel.pickupAddress.addressLine2;
          this.collectionRequestRecipient.houseNr = orderEditorModel.pickupAddress.houseNr;
          this.collectionRequestRecipient.city = orderEditorModel.pickupAddress.city;
          this.collectionRequestRecipient.stateCode = orderEditorModel.pickupAddress.stateCode;
          this.collectionRequestRecipient.countryCode = orderEditorModel.pickupAddress.countryCode;
          this.collectionRequestRecipient.country = this.countries.find(c => c.code === this.collectionRequestRecipient.countryCode);
          this.collectionRequestRecipient.referenceNumber = "";
          this.collectionRequestRecipient.zip = orderEditorModel.pickupAddress.zip;
          this.collectionRequestRecipient.email = orderEditorModel.pickupAddress.email;
          this.collectionRequestRecipient.phone = orderEditorModel.pickupAddress.phone;
          this.collectionRequestRecipient.comment = orderEditorModel.pickupAddress.comment;
          this.collectionRequestRecipient.languageCode = orderEditorModel.pickupAddress.languageCode;
        }

        if (selectedProduct && (selectedProduct.isAllowed || this.isReadOnly)) {
          this.selectedProduct = selectedProduct;
        }

        if (this.isDpdPreciseProduct) {
          this.initializeDeliveryWindowForExistingOrder();
        }

        // Počty balíkov a následne rozmery (ak nejde o collection request).
        if (this.isCollectionRequest) {
          // Fix pre zlé udaje z čias, keď CR nepracoval s počtom balíkov.
          if (!orderEditorModel.order.parcelsCount) {
            orderEditorModel.order.parcelsCount = 1;
          }

          this.parcelsCount = orderEditorModel.order.parcelsCount; // Tu nás následne netrápia údaje balíkov, lebo žiadne nie sú.
        } else {
          this.parcelsCount = orderEditorModel.parcels.length; // Toto nám aj vygeneruje jednotlivé balíčky.

          let previousWeight = null;
          let previousVolume = null;
          let previousReferentialInfo1 = null;
          let previousReferentialInfo2 = null;
          let previousReferentialInfo3 = null;
          let previousReferentialInfo4 = null;

          for (let i = 0; i < this.parcelsCount; i++) {
            const parcelData = orderEditorModel.parcels[i];
            const parcel = this.parcels[i];
            const goodsItem: ShipmentsModel.Goods = orderEditorModel.goods.find(g => g.parcelId === parcelData.id);

            parcel.id = parcelData.id;

            parcel.nr = parcelData.parcelNr;

            parcel.weight = parcelData.declaredWeight / 100;

            parcel.content = parcelData.content;
            parcel.customsTariffNumber = parcelData.customsTariffNumber;
            parcel.itemsNumber = parcelData.itemsNumber;

            parcel.customTransportCost = parcelData.customTransportCost;
            parcel.customTransportCostCurrencyCode = parcelData.customTransportCostCurrencyCode;

            // Rozložíme veľkosti.
            // Veľkosť nemusí byť zadaná alebo je nesprávne zadaná.
            if (parcelData.volume === null || parcelData.volume === "" || parcelData.volume.length < 9) {
              parcelData.volume = "000000000";
            }

            parcel.length = parseInt(parcelData.volume.substr(0, 3));
            parcel.width = parseInt(parcelData.volume.substr(3, 3));
            parcel.height = parseInt(parcelData.volume.substr(6, 3));

            if (goodsItem) {
              parcel.description = goodsItem.description;
              parcel.tempMin = goodsItem.minTemperature;
              parcel.tempMax = goodsItem.maxTemperature;
              parcel.limitDate = goodsItem.limitDate;
            }

            parcel.referentialInfo1 = parcelData.referentialInfo1;
            parcel.referentialInfo2 = parcelData.referentialInfo2;
            parcel.referentialInfo3 = parcelData.referentialInfo3;
            parcel.referentialInfo4 = parcelData.referentialInfo4;

            // Porovnávame rozmery a referencie, či sú stále rovnaké.
            if (previousVolume != null && previousVolume !== parcelData.volume) {
              this.allParcelsHaveSameDimensions = false;
            }

            if (previousWeight != null && previousWeight !== parcelData.declaredWeight) {
              this.allParcelsHaveSameDimensions = false;
            }

            if (previousReferentialInfo1 != null && previousReferentialInfo1 !== parcelData.referentialInfo1) {
              this.allParcelsHaveSameReferences = false;
            }

            if (previousReferentialInfo2 != null && previousReferentialInfo2 !== parcelData.referentialInfo2) {
              this.allParcelsHaveSameReferences = false;
            }

            if (previousReferentialInfo3 != null && previousReferentialInfo3 !== parcelData.referentialInfo3) {
              this.allParcelsHaveSameReferences = false;
            }

            if (previousReferentialInfo4 != null && previousReferentialInfo4 !== parcelData.referentialInfo4) {
              this.allParcelsHaveSameReferences = false;
            }

            previousVolume = parcelData.volume;
            previousWeight = parcelData.declaredWeight;
            previousReferentialInfo1 = parcelData.referentialInfo1;
            previousReferentialInfo2 = parcelData.referentialInfo2;
            previousReferentialInfo3 = parcelData.referentialInfo3;
            previousReferentialInfo4 = parcelData.referentialInfo4;
          }
        }

        orderEditorModel.invoiceLines.forEach(invoiceLine => {
          if (invoiceLine.grossWeight !== null && invoiceLine.grossWeight !== undefined) {
            invoiceLine.grossWeight = invoiceLine.grossWeight / 100;
          }
        });

        const parcelInvoiceLines = orderEditorModel.invoiceLines.filter(invoiceLine => invoiceLine.parcelId);

        parcelInvoiceLines.forEach(invoiceLine => {
          const parcel = this.parcels.find(parcel => parcel.id === invoiceLine.parcelId);

          if (parcel) {
            parcel.content = invoiceLine.content;
            parcel.customsTariffNumber = invoiceLine.customsTariffNumber;
            parcel.itemsNumber = invoiceLine.itemsNumber;
            parcel.amountLine = invoiceLine.amountLine;
            parcel.grossWeight = invoiceLine.grossWeight;
            parcel.originCountryCode = invoiceLine.originCountryCode;
            parcel.receiverCustomsTariffNumber = invoiceLine.customsTariffNumber;// invoiceLine.receiverCustomsTariffNumber;
            parcel.invoiceLineId = invoiceLine.id;
          }
        });

        // Adresát a prípadne PUDO.
        if (orderEditorModel.receiver.pudoId) {
          // Doručenie na pickup point je vždy osobe.
          this.recipient.customerRecipientTypeCode = this.b2c;

          this.recipient.name = orderEditorModel.receiverPersonAddress.name1;
          this.recipient.street = orderEditorModel.receiverPersonAddress.street;
          this.recipient.streetDetail = orderEditorModel.receiverPersonAddress.addressLine2;
          this.recipient.houseNr = orderEditorModel.receiverPersonAddress.houseNr;
          this.recipient.city = orderEditorModel.receiverPersonAddress.city;
          this.recipient.stateCode = orderEditorModel.receiverPersonAddress.stateCode;
          this.recipient.countryCode = orderEditorModel.receiverPersonAddress.countryCode;
          this.recipient.country = this.countries.find(c => c.code === this.recipient.countryCode);
          this.recipient.referenceNumber = orderEditorModel.receiver.shipperRecipientReferenceNumber;
          this.recipient.vatNumber = orderEditorModel.receiver.vatNumber;
          this.recipient.eoriNumber = orderEditorModel.receiver.eoriNumber;
          this.recipient.zip = orderEditorModel.receiverPersonAddress.zip;
          this.recipient.phone = orderEditorModel.receiverPersonAddress.phone;
          this.recipient.email = orderEditorModel.receiverAddress.email;
          this.recipient.comment = orderEditorModel.receiverAddress.comment;
          this.recipient.languageCode = orderEditorModel.receiverAddress.languageCode;

          this.setMaxParcelWeight();

          this.pickupPointCityValue = orderEditorModel.receiverAddress.city;
          this.pickupPointCountryCodeValue = orderEditorModel.receiverAddress.countryCode;
          this.pickupPointZipValue = orderEditorModel.receiverAddress.zip;
          this.pickupPointStreetValue = (orderEditorModel.receiverAddress.street + ' ' + orderEditorModel.receiverAddress.houseNr).trim();
        } else {
          // "Bežné" doručenie firme/osobe.
          this.recipient.customerRecipientTypeCode =
            (orderEditorModel.receiverAddress.companyName && orderEditorModel.receiverAddress.companyName.length > 0)
              ? this.b2b
              : this.b2c;

          this.recipient.name = (orderEditorModel.receiverAddress.companyName)
            ? orderEditorModel.receiverAddress.companyName
            : orderEditorModel.receiverAddress.name1;

          this.recipient.name2 = (orderEditorModel.receiverAddress.companyName2)
            ? orderEditorModel.receiverAddress.companyName2
            : orderEditorModel.receiverAddress.name2;

          this.recipient.contactPerson = orderEditorModel.receiverAddress.contactPerson;
          this.recipient.street = orderEditorModel.receiverAddress.street;
          this.recipient.streetDetail = orderEditorModel.receiverAddress.addressLine2;
          this.recipient.houseNr = orderEditorModel.receiverAddress.houseNr;
          this.recipient.city = orderEditorModel.receiverAddress.city;
          this.recipient.stateCode = orderEditorModel.receiverAddress.stateCode;
          this.recipient.countryCode = orderEditorModel.receiverAddress.countryCode;
          this.recipient.country = this.countries.find(c => c.code === this.recipient.countryCode);
          this.recipient.referenceNumber = orderEditorModel.receiver.shipperRecipientReferenceNumber;
          this.recipient.vatNumber = orderEditorModel.receiver.vatNumber;
          this.recipient.eoriNumber = orderEditorModel.receiver.eoriNumber;
          this.recipient.zip = orderEditorModel.receiverAddress.zip;
          this.recipient.email = orderEditorModel.receiverAddress.email;
          this.recipient.phone = orderEditorModel.receiverAddress.phone;
          this.recipient.comment = orderEditorModel.receiverAddress.comment;
          this.recipient.languageCode = orderEditorModel.receiverAddress.languageCode;
        }
        if (this.existingOrderEditorModel.hasCustomSenderAddress) {
          this.hasCustomSenderAddress = true;
          this.customSenderAddress.name = this.existingOrderEditorModel.senderAddress.name1;
          this.customSenderAddress.name2 = this.existingOrderEditorModel.senderAddress.name2;
          this.customSenderAddress.street = this.existingOrderEditorModel.senderAddress.street;
          this.customSenderAddress.houseNr = this.existingOrderEditorModel.senderAddress.houseNr;
          this.customSenderAddress.streetDetail = this.existingOrderEditorModel.senderAddress.addressLine2;
          this.customSenderAddress.countryCode = this.existingOrderEditorModel.senderAddress.countryCode;
          this.customSenderAddress.city = this.existingOrderEditorModel.senderAddress.city;
          this.customSenderAddress.stateCode = this.existingOrderEditorModel.senderAddress.stateCode;
          this.customSenderAddress.phone = this.existingOrderEditorModel.senderAddress.phone;
          this.customSenderAddress.email = this.existingOrderEditorModel.senderAddress.email;
          this.customSenderAddress.zip = this.existingOrderEditorModel.senderAddress.zip;

          const country =
              this.countries.find(c => c.code == this.existingOrderEditorModel.senderAddress.countryCode);

          this.customSenderAddress.country = country;

          if (this.isCustomerService) {
            this.customerServiceCustomerEditorData = {
              ...this.customSenderAddress,
              country,
              customerNumber: this.existingOrderEditorModel.senderInvoicingNr,
              customerDelisId: this.existingOrderEditorModel.senderDelisId
            }
          }
        }

        // Služby.

        orderEditorModel.additionalServiceIds.forEach(s => {
          // Služby nastavíme ako vybrané, povolenie nenastavujeme.
          let service = this.additionalServices.find(svc => svc.id === s);

          if (service) {
            service.isSelected = true;

            // Podľa typu služby doplníme údaje.

            switch (service.code) {
              // Fix na zlé hodnoty.
              case ConfigModel.WellKnownProductCodes.Cod:

                // Dostávame vyplnené aj orderEditorModel.cod aj .cods.
                // V princípe sa riadime tým, či môžeme mať špecifikované per parcel COD hodnotu
                // alebo nie.

                if (this.isEnabledCodForEachParcel) {
                  // Sanitizácia typu platby.
                  if (orderEditorModel.cods[0].collectionTypeId !== ShipmentsModel.CollectionType.Card &&
                    orderEditorModel.cods[0].collectionTypeId !== ShipmentsModel.CollectionType.Cash) {
                    orderEditorModel.cods[0].collectionTypeId = ShipmentsModel.CollectionType.Cash;
                  }

                  this.allParcelsHaveOwnCod = orderEditorModel.order.isCodAmountSetSeparatelyForEachParcel;

                  let codsData: ShipmentsModel.CodData[] = [];

                  if (this.allParcelsHaveOwnCod) {
                    orderEditorModel.cods.forEach((codModel) => {
                      let codData: ShipmentsModel.CodData = {
                        amount: codModel.amount,
                        purpose: codModel.purpose,
                        collectionTypeId: codModel.collectionTypeId,
                        currency: codModel.currencyCode
                      }

                      codsData.push(codData);

                    });
                  } else {
                    let codModel = orderEditorModel.cod;

                    let codData: ShipmentsModel.CodData = {
                      amount: codModel.amount,
                      purpose: codModel.purpose,
                      collectionTypeId: codModel.collectionTypeId,
                      currency: codModel.currencyCode
                    }

                    codsData.push(codData);
                  }

                  this.cods = codsData;

                  if (this._parcelsCount > this.cods.length) {
                    this.fillCodsWithEmptyData(this._parcelsCount - this.cods.length);
                  }

                  service.data = this.cods;

                  this.setSelectedBankAccountBasedOnCodData(orderEditorModel.cods[0]);
                } else {
                  // Pracujeme v móde, keď berieme do úvahy len .cod.

                  if (orderEditorModel.cod.collectionTypeId !== ShipmentsModel.CollectionType.Card &&
                    orderEditorModel.cod.collectionTypeId !== ShipmentsModel.CollectionType.Cash) {
                    orderEditorModel.cod.collectionTypeId = ShipmentsModel.CollectionType.Cash;
                  }

                  // Potrebujeme model, ktorý obsiahne sumu a iné náležitosti dobierky.
                  let codData: ShipmentsModel.CodData = {
                    amount: orderEditorModel.cod.amount,
                    purpose: orderEditorModel.cod.purpose,
                    collectionTypeId: orderEditorModel.cod.collectionTypeId,
                    currency: orderEditorModel.cod.currencyCode
                  }

                  service.data = codData;

                  this.setSelectedBankAccountBasedOnCodData(orderEditorModel.cod);
                }
                break;

              case ConfigModel.WellKnownProductCodes.IDCheck:
                // Potrebujeme model, ktorý obsiahne potrebné číslo OP alebo inej identifikačnej karty.
                let idCheckData: ShipmentsModel.IDCheckData = {
                  idCardNumber: orderEditorModel.receiverPerson.personIdNumber,
                  name: orderEditorModel.receiverPerson.personName
                }

                service.data = idCheckData;
                break;

              case ConfigModel.WellKnownProductCodes.Swap:

                // also put to swap type selected for show in combo
                this.swapTypeSelected = this.getSwapType(this.selectedSenderAddress, orderEditorModel.swapReceiverAddresses[0]);

                // Potrebujeme model pre výmenný balík a jeho náležitosti.
                let swapData: ShipmentsModel.SwapData = {
                  parcelsCount: orderEditorModel.swaps.length,
                  note: orderEditorModel.swaps[0] ? orderEditorModel.swaps[0].note : '',
                  type: this.swapTypeSelected
                }



                if (this.swapTypeSelected === ShipmentsModel.SwapTypeCode.ABC) {
                  this.swapAbcAddress = orderEditorModel.swapReceiverAddresses[0];
                }


                service.data = swapData;
                break;

              case ConfigModel.WellKnownProductCodes.ReturnService:
                this.returnRecipient = this.getEmptyRecipient();
                this.returnRecipient.name = orderEditorModel.returnReceiverAddress.companyName ? orderEditorModel.returnReceiverAddress.companyName : orderEditorModel.returnReceiverAddress.name1;

                this.returnRecipient.name2 = orderEditorModel.returnReceiverAddress.name2;
                this.returnRecipient.contactPerson = orderEditorModel.returnReceiverAddress.contactPerson;

                this.returnRecipient.street = orderEditorModel.returnReceiverAddress.street;
                this.returnRecipient.streetDetail = orderEditorModel.returnReceiverAddress.addressLine2;
                this.returnRecipient.houseNr = orderEditorModel.returnReceiverAddress.houseNr;
                this.returnRecipient.city = orderEditorModel.returnReceiverAddress.city;
                this.returnRecipient.stateCode = orderEditorModel.returnReceiverAddress.stateCode;
                this.returnRecipient.countryCode = orderEditorModel.returnReceiverAddress.countryCode;
                this.returnRecipient.country = this.countries.find(c => c.code === this.returnRecipient.countryCode);
                this.returnRecipient.referenceNumber = "";
                this.returnRecipient.zip = orderEditorModel.returnReceiverAddress.zip;
                this.returnRecipient.email = orderEditorModel.returnReceiverAddress.email;
                this.returnRecipient.phone = orderEditorModel.returnReceiverAddress.phone;
                this.returnRecipient.comment = orderEditorModel.returnReceiverAddress.comment;
                this.returnRecipient.languageCode = orderEditorModel.returnReceiverAddress.languageCode;

                break;

              default:
                // Nič nerobíme. Produkt/odplnkovú službu je možné len vybrať alebo nevybrať.
                // V UI by ju teda mal reprezentovať iba checkbox a jej názov.
                break;
            }
          }
        });


        if (orderEditorModel.shipment && orderEditorModel.shipment.invoiceShipment && orderEditorModel.invoice == null) {
          orderEditorModel.invoice = this.customClearanceViewComponent.invoice;
        }

        if (orderEditorModel.invoice) {
          this.isCustomClearance = true;

          this.customClearanceEditorData.incoTermCode = orderEditorModel.shipment.incoTermCode;
          this.customClearanceEditorData.prealertStatusCode = orderEditorModel.shipment.prealertStatusCode;
          this.customClearanceEditorData.shipmentTypeCode = orderEditorModel.shipment.shipmentTypeCode;
          this.customClearanceEditorData.clearenceClearedCode = orderEditorModel.shipment.clearanceClearedCode;
          this.customClearanceEditorData.hasAccompanyingDocs = orderEditorModel.shipment.hasAccompanyingDocs;
          this.customClearanceEditorData.vatNumber = orderEditorModel.sender.vatNumber;
          this.customClearanceEditorData.eoriNumber = orderEditorModel.sender.eoriNumber;
          this.customClearanceEditorData.webSite = orderEditorModel.sender.webSite;
          this.customClearanceEditorData.hmrcNumber = orderEditorModel.sender.destinationCountryRegistration;
          this.customClearanceEditorData.accompDocsTypeCodes = _.filter(orderEditorModel.shipmentAccompanyingDocs.map((ad) => ad.accompanyingDocTypeCode));

          this.invoice = orderEditorModel.invoice;

          const additionalInvoiceLines = orderEditorModel.invoiceLines.filter(invoiceLine => !invoiceLine.parcelId)
          this.invoiceAdditionalLines = additionalInvoiceLines.length;
          this.invoiceLines = additionalInvoiceLines;

          this.customSenderInvoiceAddress = orderEditorModel.senderInvoiceAddress !== null && orderEditorModel.senderInvoiceAddress != undefined;
           
          if (this.customSenderInvoiceAddress) {
            this.senderInvoicingAddress = orderEditorModel.senderInvoiceAddress;
            this.invoicingSender = {} as RecipientModel.Recipient;
          }

          this.customReceiverInvoiceAddress = orderEditorModel.receiverInvoiceAddress !== null && orderEditorModel.receiverInvoiceAddress != undefined;

          if (this.customReceiverInvoiceAddress) {
            this.receiverInvoicingAddress = orderEditorModel.receiverInvoiceAddress;
            this.invoicingReceiver = {} as RecipientModel.Recipient;
          }

          this.setSenderInvoicingFromInvoicingSenderAddress();
          this.setReceiverInvoicingFromInvoicingReceiverAddress();
        }

        this.sendCustomerPersonalizedNotification =
          orderEditorModel.sendCustomerPersonalizedNotification;
        this.customerPersonalizedNotificationLanguageCode =
          orderEditorModel.customerPersonalizedNotificationLanguageCode;
        this.customerPersonalizedNotificationRecipients =
          orderEditorModel.customerPersonalizedNotificationRecipients;

        this.sendReturnLabelByEmail = orderEditorModel.sendReturnLabelByEmail;
        this.excludeReturnLabelFromLabelPrinting =
          orderEditorModel.excludeReturnLabelFromLabelPrinting;
        this.isSaveAddressChecked = orderEditorModel.saveAddressToAddressBook;

        this.isBusy = false;

        this._suspendRoutingValidation = false;

        // Ak zobrazujeme editovateľnú objednávku, tak musíme validovať a získať možné produkty a služby.
        if (!this.isReadOnly) {
          this.setProductsAndAdditionalServicesAvailability(true);
          // this.checkProductsAndAdditionalServicesAvailability(true);
        } else {
          // Needitovateľná objednávka nemá čo notifikovať o zmene.
          this.hasUnsavedChanges = () => false
        }
        console.log('loadExisting check customs');
        this.checkCustomsObligations();
      },
        ex => {
          this.loggingService.logErrorData(ex);

          // Nenašli sme objednávku alebo nejaká iná chyba pri jej načítaní.
          this.isBusy = false;
        });
  }


  private getSwapType(sender: CustomerModel.Address, swapRecipient: ShipmentsModel.Address) {
    if (swapRecipient &&
      (sender.name1.trim() !== swapRecipient.name1.trim() ||
        sender.countryCode.trim() !== swapRecipient.countryCode.trim() ||
        sender.street.trim() !== swapRecipient.street.trim() ||
        sender.houseNr.trim() !== swapRecipient.houseNr.trim() ||
        sender.zip.trim() !== swapRecipient.zip.trim() ||
        sender.place.trim() !== swapRecipient.city.trim())
    ) {
      return ShipmentsModel.SwapTypeCode.ABC;
    } else {
      return ShipmentsModel.SwapTypeCode.AB;
    }
  }


  private initializeNewShipmentOrderOrLoadExisting = () => {
    if (this.isNewShipmentOrder) {
      this.initializeNewShipmentOrder();
    } else {
      this.loadExistingShipmentOrder();
    }
  }


  private setUpAddressChangeListeners() {
    combineLatest([
      this.addressZip.valueChanges,
      this.addressCity.valueChanges,
      this.addressCountry.valueChanges
    ]).pipe(
      map(() => ({
        city: this.addressCity.value,
        zip: this.addressZip.value,
        country: this.addressCountry.value
      })),
      distinctUntilChanged((c, p) => _.isEqual(c, p)),
      debounceTime(600)
    ).subscribe(value => {
      if (!this._suspendRoutingValidation) {
        if (this.isDpdPreciseProduct) {
          this.getValidDpdPreciseDeliveryWindows();
        } else {
          this.checkProductsAndAdditionalServicesAvailability();
        }

        this.checkCustomsObligations();
        this.checkZipWarningAddress();
      }
    });
  }

  private setUpPudoAddressChangeListeners() {
    combineLatest([
      this.pickupPointZip.valueChanges,
      this.pickupPointCountry.valueChanges
    ]).pipe(
      map(() => ({
        zip: this.pickupPointZip.value,
        country: this.pickupPointCountry.value
      })),
      distinctUntilChanged((c, p) => _.isEqual(c, p)),
      debounceTime(600)
    ).subscribe(() => {

      this.checkZipWarningPickupPoint();
    });
  }


  public recipientAddressChanged() {
    if (this.isDpdPreciseProduct) {
      this.validateRecipientZipAndCity(); // Musíme volať zvlášť, lebo ináč to máme v rámci validácie produktov a služieb.
      this.getValidDpdPreciseDeliveryWindows();
    } else {
      console.log('recipientAddresscahnged')
      this.checkProductsAndAdditionalServicesAvailability();
    }

    console.log('recipient changed check customs');
    this.checkCustomsObligations();
  }


  public validateRecipientZipAndCity() {
    this._shipmentService.checkZipAndCity({
      cityName: this.recipient.city,
      countryCode: this.recipient.country.code,
      postCode: this.recipient.zip,
      refDate: new Date()
    }).subscribe(result => {
      this.cityIsInvalid = !result.isCityValid;
      this.zipIsInvalid = !result.isZipValid;
    });

  }


  public exception: Shared.ExceptionInfo = null;


  private updateReceiverData = (
    receiver: ShipmentsModel.Receiver,
    receiverPerson: ShipmentsModel.ReceiverPerson,
    receiverAddress: ShipmentsModel.Address,
    receiverPersonAddress: ShipmentsModel.Address,
    sourceRecipient: RecipientModel.Recipient,
    ignoreProduct: boolean = false
  ) => {
    receiver.shipperRecipientReferenceNumber = sourceRecipient.referenceNumber;

    if (!receiver.shipperRecipientReferenceNumber) {
      receiver.shipperRecipientReferenceNumber = "";
    }

    receiver.eoriNumber = sourceRecipient.eoriNumber || "";
    receiver.vatNumber = sourceRecipient.vatNumber || "";

    if (receiverPersonAddress) {
      receiverPersonAddress.languageCode = sourceRecipient.languageCode;
    }

    if (receiverAddress) {
      receiverAddress.languageCode = sourceRecipient.languageCode;
    }

    // Ak máme pickup point vybraný.
    // if (!ignoreProduct && this.selectedProduct && this.selectedProduct.isDeliveryToPudoProduct && this.selectedPudo) {
    if (!ignoreProduct && this.isDeliveryToPudoProduct && this.selectedPudo) {
      receiver.pudoId = this.selectedPudo.pudoCode ?
        this.selectedPudo.pudoCode :
        this.selectedPudo.carrierPudoId;

      receiverPerson.personName = sourceRecipient.name;

      receiverPersonAddress.street = sourceRecipient.street;
      receiverPersonAddress.addressLine2 = sourceRecipient.streetDetail;
      receiverPersonAddress.houseNr = sourceRecipient.houseNr;
      receiverPersonAddress.city = sourceRecipient.city;
      receiverPersonAddress.name1 = sourceRecipient.name;

      receiverPersonAddress.zip = sourceRecipient.zip;
      receiverPersonAddress.stateCode = sourceRecipient.stateCode;
      receiverPersonAddress.countryCode = sourceRecipient.country.code;

      receiverPersonAddress.email = sourceRecipient.email;
      receiverPersonAddress.phone = sourceRecipient.phone;

      // Údaje z PUDA
      receiverAddress.companyName = "";
      receiverAddress.companyName2 = "";
      receiverAddress.name1 = this.selectedPudo.name;
      receiverAddress.name2 = sourceRecipient.name;
      receiverAddress.street = this.selectedPudo.street;
      receiverAddress.addressLine2 = "";
      receiverAddress.zip = this.selectedPudo.zip.replace(" ", ""); // Vyhadzujeme medzery.
      receiverAddress.stateCode = this.selectedPudo.stateCode;
      receiverAddress.countryCode = this.selectedPudo.countryCode;
      receiverAddress.city = this.selectedPudo.city;
      receiverAddress.houseNr = "";
      receiverAddress.comment = "";
      receiverAddress.email = sourceRecipient.email;
    } else {
      // Adresa
      receiverAddress.name1 = sourceRecipient.name;
      receiverAddress.name2 = sourceRecipient.name2;
      receiverAddress.contactPerson = sourceRecipient.contactPerson;

      // CompanyName nepoužívame, je to zbytočný zmätok.
      receiverAddress.companyName = "";
      receiverAddress.companyName2 = "";

      receiverAddress.street = sourceRecipient.street;
      receiverAddress.addressLine2 = sourceRecipient.streetDetail;
      receiverAddress.zip = sourceRecipient.zip;
      receiverAddress.stateCode = sourceRecipient.stateCode;
      receiverAddress.countryCode = sourceRecipient.country.code;
      receiverAddress.city = sourceRecipient.city;
      receiverAddress.houseNr = sourceRecipient.houseNr;
      receiverAddress.comment = sourceRecipient.comment;
      receiverAddress.email = sourceRecipient.email;
      receiverAddress.phone = sourceRecipient.phone;
    }
  }


  private getCityService120DeliveryWindowId() {
    if (!this.selectedProduct) {
      return null;
    }

    if (this.selectedProduct.code !== "SD") {
      return null;
    }

    let cs120dw = this.cityServiceDeliveryWindows.find(dw => dw.lengthInMinutes == 120);

    if (cs120dw) {
      return cs120dw.id;
    }

    return null;
  }

  /** Creates and returns `NewOrderModel` from form values for saving a new order. */
  public getNewShipmentOrderModel = () => {
    let receiverPerson: ShipmentsModel.ReceiverPerson = {} as any;
    let receiver: ShipmentsModel.Receiver = {} as any;

    let order: ShipmentsModel.Order = {} as any;
    let parcels: ShipmentsModel.Parcel[] = [];
    let goods: ShipmentsModel.Goods[] = [];
    let cod: ShipmentsModel.Cod = null;
    let cods: ShipmentsModel.Cod[] = [];
    let receiverAddress: ShipmentsModel.Address = {} as any;
    let receiverPersonAddress: ShipmentsModel.Address = {} as any;
    let deliveryPlan: ShipmentsModel.DeliveryPlan = {} as any;
    let swapParcels: ShipmentsModel.SwapParcel[] = [];
    let swapReceivers: ShipmentsModel.Receiver[] = [];
    let swapReceiverAddresses: ShipmentsModel.Address[] = [];
    let shipment: ShipmentsModel.Shipment = {} as any;

    let collectionRequestSender: ShipmentsModel.Receiver = {} as any;
    let collectionRequestPerson: ShipmentsModel.ReceiverPerson = {} as any;
    let collectingAddress: ShipmentsModel.Address = {} as any;
    let collectionRequestPersonAddress: ShipmentsModel.Address = {} as any;

    let returnSenderAddress: ShipmentsModel.Address = {} as any;
    let returnRecipientAddress: ShipmentsModel.Address = {} as any;

    let customSenderAddress: ShipmentsModel.Address = {} as any;

    let invoice: Invoice = {} as any;
    let invoiceLines: InvoiceLine[] = [];
    let senderEoriNumber: string = "";
    let senderVatNumber: string = "";
    let senderWebsite: string = "";
    let senderDestinationCountryRegistration: string = "";
    let senderDelisId: string = "";
    let senderInvoicingNr: string = "";
    let shipmentAccompanyingDocs: ShipmentsModel.ShipmentAccompanyingDoc[] = [];
    let senderInvoiceAddress: ShipmentsModel.Address = {} as any;
    let receiverInvoiceAddress: ShipmentsModel.Address = {} as any;

    let dynamicFieldOptions = this.shipmentsAdditionalFieldsComponent?.collectFieldValues();

    // ID produktu
    if (this.selectedProduct) {
      order.productId = this.selectedProduct.id;
    } else {
      order.productId = null;
    }

    shipment.referentialInfo1 = this.referentialInfo1;
    shipment.referentialInfo2 = this.referentialInfo2;
    shipment.referentialInfo3 = this.referentialInfo3;
    shipment.referentialInfo4 = this.referentialInfo4;

    order.deliveryWindowId = this.getCityService120DeliveryWindowId();

    order.content = this.note;

    order.customerReferenceNr = this.customerReferenceNr

    // Kvôli CR ukladáme aj počet balíkov zvlášť, lebo CR samotné neobsahuje definície balíkov.
    order.parcelsCount = this.parcelsCount;

    if (this.isCollectionRequest) {
      order.weight = this.crWeight != null ? Math.round(this.crWeight * 100) : null;
      order.partnerReference1 = this.crInfo1;
      order.partnerReference2 = this.crInfo2;
    }

    // Chceme deň tak aby mal "fixed" hodnotu bez ohľadu na timezone.
    order.pickupDate = this.toTimezonelessDate(this.pickupDate);
    shipment.expectedPickupDateTime = order.pickupDate;

    let firstParcel: ShipmentsModel.Parcel = null;

    for (let i = 0; i < this.parcelsCount; i++) {
      const parcel = {} as ShipmentsModel.Parcel;
      const goodsItem = {} as ShipmentsModel.Goods;
      parcels.push(parcel);

      const parcelData = this.parcels[i];

      parcel.id = (-i) - 1;
      goodsItem.id = (-i) - 1;

      goodsItem.minTemperature = parcelData.tempMin;
      goodsItem.maxTemperature = parcelData.tempMax;
      goodsItem.description = parcelData.description;
      goodsItem.limitDate = parcelData.limitDate;

      if (this.isFreshProductSelected) {
        goods.push(goodsItem);
      }

      if (i > 0 && this.allParcelsHaveSameReferences) {
        // Všetky balíky majú rovnakú referenciu.
        parcel.referentialInfo1 = firstParcel.referentialInfo1;
        parcel.referentialInfo2 = firstParcel.referentialInfo2;
        parcel.referentialInfo3 = firstParcel.referentialInfo3;
        parcel.referentialInfo4 = firstParcel.referentialInfo4;
      } else {
        // Každý balík má svoje referencie.
        parcel.referentialInfo1 = parcelData.referentialInfo1;
        parcel.referentialInfo2 = parcelData.referentialInfo2;
        parcel.referentialInfo3 = parcelData.referentialInfo3;
        parcel.referentialInfo4 = parcelData.referentialInfo4;
      }

      if (i > 0 && this.allParcelsHaveSameDimensions) {
        // Všetky balíky majú rovnaké rozmery.
        parcel.volume = firstParcel.volume;
        parcel.declaredWeight = firstParcel.declaredWeight;
      } else {
        // Každý balík má svoj rozmer.
        const widthString = ("000" + (parcelData.width ? parcelData.width : ""));
        const heightString = ("000" + (parcelData.height ? parcelData.height : ""));
        const lengthString = ("000" + (parcelData.length ? parcelData.length : ""));

        parcel.volume =
          lengthString.substr(lengthString.length - 3) +
          widthString.substr(widthString.length - 3) +
          heightString.substr(heightString.length - 3);

        // Server očakáva celé číslo, čo by sme teoreticky mali dostať,
        // keďže vstup je na 2 desatinné miesta, ale problém je násobenie,
        // keďže float/double sú nepresné a vznikajú tam artefakty.
        // Napríklad 4.44 * 100 = 444.00000000000006.
        parcel.declaredWeight = Math.round(parcelData.weight * 100);
      }

      // Pre RETURN vyčistíme hmotnosti balíkov.
      if (this.isReturnProductSelected) {
        parcel.declaredWeight = 0;
      }

      if (i === 0) {
        firstParcel = parcel;
      }

      if (this.isCustomClearance && this.selectedShipmentTypeCode !== ShipmentTypeCodeDocuments) {
        const invoiceLine = {} as InvoiceLine;

        parcel.customTransportCost = parcelData.customTransportCost;
        parcel.customTransportCostCurrencyCode = this.invoice.currencyCode;

        invoiceLine.id = - (invoiceLines.length + 1);
        invoiceLine.parcelId = parcel.id;
        invoiceLine.content = parcelData.content;
        invoiceLine.customsTariffNumber = parcelData.customsTariffNumber;
        invoiceLine.itemsNumber = parcelData.itemsNumber;
        invoiceLine.amountLine = parcelData.amountLine;
        invoiceLine.grossWeight = (parcelData.grossWeight !== null && parcelData.grossWeight !== undefined) ? Math.round(parcelData.grossWeight * 100) : null;
        invoiceLine.originCountryCode = parcelData.originCountryCode ? parcelData.originCountryCode : null;
        invoiceLine.receiverCustomsTariffNumber = parcelData.customsTariffNumber;// parcelData.receiverCustomsTariffNumber;

        invoiceLines.push(invoiceLine);
      } else {
        parcel.customTransportCost = null;
        parcel.customTransportCostCurrencyCode = null;
      }
    }

    // Údaje odosielateľa.
    // @todo - can be customId preserved for customer service?.
    order.customerAddressCustomId = this.selectedSenderAddress.customId;
    if (this.hasCustomSenderAddress) {
      customSenderAddress.name1 = this.customSenderAddress.name;
      customSenderAddress.name2 = this.customSenderAddress.name2;
      customSenderAddress.street = this.customSenderAddress.street;
      customSenderAddress.houseNr = this.customSenderAddress.houseNr;
      customSenderAddress.addressLine2 = this.customSenderAddress.streetDetail;
      customSenderAddress.countryCode = this.customSenderAddress.country.code;
      customSenderAddress.city = this.customSenderAddress.city;
      customSenderAddress.stateCode = this.customSenderAddress.stateCode;
      customSenderAddress.phone = this.customSenderAddress.phone;
      customSenderAddress.email = this.customSenderAddress.email;
      customSenderAddress.zip = this.customSenderAddress.zip;
    } else {
      customSenderAddress = null;
    }

    // TODO: dat do UI
    // deliveryPlan.podInfo1 - 5 dorobit

    order.orderingDepotCode = this.selectedSenderAddress.depotCode;
    order.collectingDepotCode = this.selectedSenderAddress.depotCode; // Toto sa v prípade CR zmení na back-ende.
    shipment.sendingDepotCode = this.selectedSenderAddress.depotCode;

    order.shipperTenantId = this._user.tenantId;

    shipment.invoiceShipment = this.isCustomClearance;
    if (this.isCustomClearance) {
      invoice.number = this.invoice.number;
      invoice.dateOfIssue = amendValueForModelFromFormattedFormControl(this.invoice.dateOfIssue);
      invoice.orderId = this.invoice.orderId;
      invoice.amount = this.invoice.amount;
      invoice.currencyCode = this.invoice.currencyCode;
      invoice.comment = this.invoice.comment;
      // invoice.amountEx = this.invoice.amountEx;
      // invoice.currencyCodeEx = this.invoice.currencyCodeEx;
      invoice.amountEx = this.invoice.amount;
      invoice.currencyCodeEx = this.invoice.currencyCode;
      invoice.isInvoceAddressDifferent = this.invoice.isInvoceAddressDifferent;
      invoice.exportReasonCode = this.invoice.exportReasonCode;
      invoice.mrn = this.invoice.mrn;

      shipment.incoTermCode = this.customClearanceEditorData.incoTermCode;
      shipment.prealertStatusCode = this.customClearanceEditorData.prealertStatusCode;
      shipment.shipmentTypeCode = this.customClearanceEditorData.shipmentTypeCode;
      shipment.clearanceClearedCode = this.customClearanceEditorData.clearenceClearedCode;
      shipment.hasAccompanyingDocs = this.customClearanceEditorData.hasAccompanyingDocs;

      senderVatNumber = this.customClearanceEditorData.vatNumber;
      senderEoriNumber = this.customClearanceEditorData.eoriNumber;
      senderWebsite = this.customClearanceEditorData.webSite;
      senderDestinationCountryRegistration = this.customClearanceEditorData.hmrcNumber;

      if (this.customClearanceEditorData.hasAccompanyingDocs) {
        for (let i = 0; i < this.customClearanceEditorData.accompDocsTypeCodes.length; i++) {
          const shipmentAccompanyingDoc = {} as ShipmentsModel.ShipmentAccompanyingDoc;
          shipmentAccompanyingDocs.push(shipmentAccompanyingDoc);

          const shipmentAccompanyingDocData = this.customClearanceEditorData.accompDocsTypeCodes[i];

          shipmentAccompanyingDoc.id = (-i) - 1;

          shipmentAccompanyingDoc.accompanyingDocTypeCode = shipmentAccompanyingDocData;
        }
      }

      if (this.selectedShipmentTypeCode !== ShipmentTypeCodeDocuments) {
        for (let i = 0; i < this._invoiceAdditionalLines; i++) {
          const invoiceLine = {} as InvoiceLine;

          const invoiceLineData = this.invoiceLines[i];

          invoiceLine.id = - (invoiceLines.length + 1);
          invoiceLine.content = invoiceLineData.content;
          invoiceLine.customsTariffNumber = invoiceLineData.customsTariffNumber;
          invoiceLine.itemsNumber = invoiceLineData.itemsNumber;
          invoiceLine.amountLine = invoiceLineData.amountLine,
            invoiceLine.grossWeight = (invoiceLineData.grossWeight !== null && invoiceLineData.grossWeight !== undefined) ? Math.round(invoiceLineData.grossWeight * 100) : null,
            invoiceLine.originCountryCode = invoiceLineData.originCountryCode ? invoiceLineData.originCountryCode : null,
            invoiceLine.receiverCustomsTariffNumber = invoiceLineData.customsTariffNumber;// invoiceLineData.receiverCustomsTariffNumber;

          invoiceLines.push(invoiceLine);
        }
      }

      if (this.customSenderInvoiceAddress) {
        this.populateSenderInvoicingAddress();
        senderInvoiceAddress = this.senderInvoicingAddress;
        invoice.sellerEoriNumber = this.invoice.sellerEoriNumber;
        invoice.sellerVatNumber = this.invoice.sellerVatNumber;
      } else {
        senderInvoiceAddress = null;
      }

      if (this.customReceiverInvoiceAddress) {
        this.populateReceiverInvoicingAddress();
        receiverInvoiceAddress = this.receiverInvoicingAddress
        invoice.payerEoriNumber = this.invoice.payerEoriNumber;
        invoice.payerVatNumber = this.invoice.payerVatNumber;
      } else {
        receiverInvoiceAddress = null;
      }

    } else {
      invoice = null;
      invoiceLines = [];
    }


    // Nájdeme si well-known služby.

    // COD
    let codProduct = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.Cod);

    if (codProduct && (codProduct.isAvailable || (this.isCollectionRequest && this.isCodForCrAllowed)) && codProduct.isSelected) {
      if (this.isEnabledCodForEachParcel) {
        codProduct.data.forEach((codData: ShipmentsModel.CodData, index: number) => {
          let cod = {} as any;

          if (codData != null) {
            cod.amount = codData.amount;
            cod.purpose = codData.purpose;
            cod.parcelId = parcels[index].id;
            cod.currencyCode = codProduct.data[0].currency;
            cod.collectionTypeId = codProduct.data[0].collectionTypeId;

            // Ak máme vybraný účet, uložíme si jeho údaje.
            if (this.selectedBankAccount != null) {
              cod.bankAccountCustomId = this.selectedBankAccount.customId;
              cod.iban = this.selectedBankAccount.iban;
              cod.bic = this.selectedBankAccount.bic;
              cod.accountNr = this.selectedBankAccount.accountNumber;
              cod.accountName = this.selectedBankAccount.name;
              cod.sbkName = this.selectedBankAccount.bankName.substr(0, 27);
            }
          }

          cods.push(cod);
        });

      } else {
        let codData = codProduct.data as ShipmentsModel.CodData;
        if (codData != null) {
          cod = {} as any;
          cod.amount = codData.amount;
          cod.purpose = codData.purpose;
          cod.parcelId = parcels[0].id;
          cod.currencyCode = codData.currency;
          cod.collectionTypeId = codData.collectionTypeId;

          // Ak máme vybraný účet, uložíme si jeho údaje.
          if (this.selectedBankAccount != null) {
            cod.bankAccountCustomId = this.selectedBankAccount.customId;
            cod.iban = this.selectedBankAccount.iban;
            cod.bic = this.selectedBankAccount.bic;
            cod.accountNr = this.selectedBankAccount.accountNumber;
            cod.accountName = this.selectedBankAccount.name;
            cod.sbkName = this.selectedBankAccount.bankName.substr(0, 27);
          }
        }
      }
    }

    if (cods.length === 1) {
      cod = cods[0];
      cods = [];
      order.isCodAmountSetSeparatelyForEachParcel = false;
    } else {
      order.isCodAmountSetSeparatelyForEachParcel = true;
    }

    // Prebijeme, pokial nie je povolene zadavat dobierku per balik
    if (!this.isEnabledCodForEachParcel) {
      order.isCodAmountSetSeparatelyForEachParcel = false;
    }

    // ID check
    let idCheckProduct = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.IDCheck);

    if (idCheckProduct && idCheckProduct.isAvailable && idCheckProduct.isSelected) {
      let idCheckData = idCheckProduct.data as ShipmentsModel.IDCheckData;
      receiverPerson.personName = idCheckData.name;
      receiverPerson.personIdNumber = idCheckData.idCardNumber;
    }

    // SWAP
    let swapProduct = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.Swap);

    if (swapProduct && swapProduct.isAvailable && swapProduct.isSelected) {
      let swapData = swapProduct.data as ShipmentsModel.SwapData;
      // Podľa počtu SWAP balíkov si vytvoríme inštancie.
      // Ak je povinné mať toľko SWAP balíkov, ako je pôvodných balíkov, tak ignorujeme počet SWAP balíkov, ktorý sme mali.
      for (let i = 0; i < (this.isSameNumberOfSwapParcelsRequiredAsOriginalParcels ? this.parcelsCount : swapData.parcelsCount); i++) {
        let swapParcel: ShipmentsModel.SwapParcel = {} as any;

        swapParcel.parcelId = parcels[i].id; // Zatiaľ dočasné záporné ID.
        swapParcel.id = -1 - i;
        swapParcel.note = swapData.note;

        swapParcels.push(swapParcel);
      }
    }

    if (this.swapTypeSelected === ShipmentsModel.SwapTypeCode.ABC) {
      swapReceiverAddresses = [this.swapAbcAddress as ShipmentsModel.Address];
    }


    let orderModel: ShipmentsModel.NewOrderModel = {
      cod,
      cods,
      additionalServiceIds: _.filter(this.additionalServices,
        (s: ConfigModel.OrderEditorProductViewModel) =>
          (s.isAvailable || (this.isCollectionRequest && this.isCodForCrAllowed && s.code === ConfigModel.WellKnownProductCodes.Cod)) &&
          s.isSelected
      )
        .map(p => p.id),
      deliveryPlan,
      order,
      parcels,
      goods,
      senderCustomerDetailId: this.selectedSenderAddress.customerDetailId,
      hasExternalSender: false,
      shipment,
      swapReceiverAddresses,
      swapReceivers,
      swaps: swapParcels,
      receiver: null,
      collectingAddress: null,
      isCollectionRequestSentToMe: true,
      receiverAddress: null,
      receiverPerson: null,
      receiverPersonAddress: null,
      returnReceiverAddress: null,
      hasCustomSenderAddress: this.hasCustomSenderAddress,
      senderAddress: customSenderAddress,
      deliveryWindowId: this.isDpdPreciseProduct ? this.selectedDeliveryWindowId : null,
      invoice,
      invoiceLines,
      senderEoriNumber,
      senderVatNumber,
      senderWebsite,
      senderDestinationCountryRegistration,
      senderDelisId,
      senderInvoicingNr,
      shipmentAccompanyingDocs,
      senderInvoiceAddress,
      receiverInvoiceAddress,
      sendCustomerPersonalizedNotification: this.sendCustomerPersonalizedNotification,
      customerPersonalizedNotificationLanguageCode: this.customerPersonalizedNotificationLanguageCode,
      customerPersonalizedNotificationRecipients: this.customerPersonalizedNotificationRecipients,
      sendReturnLabelByEmail: this.sendReturnLabelByEmail,
      excludeReturnLabelFromLabelPrinting: this.sendReturnLabelByEmail === true ?
        this.excludeReturnLabelFromLabelPrinting : false,
      saveAddressToAddressBook: this.isSaveAddressChecked,
      shipmentProductDynamicFieldsOptions: dynamicFieldOptions?.length > 0 ? dynamicFieldOptions : null,
    }

    if (this.isDpdPreciseProduct) {
      let selectedDeliveryWindow = this.deliveryWindows.find(dw => dw.id == this.selectedDeliveryWindowId);

      if (selectedDeliveryWindow) {
        deliveryPlan.deliveryDateFrom = this.deliveryPlan.deliveryDateFrom;
        deliveryPlan.deliveryDateTo = this.deliveryPlan.deliveryDateFrom;
        deliveryPlan.timeFrameFrom = selectedDeliveryWindow.timeFrom;
        deliveryPlan.timeFrameTo = selectedDeliveryWindow.timeTo;
      }
    } else {
      deliveryPlan.deliveryDateFrom = null;
      deliveryPlan.deliveryDateTo = null;
      deliveryPlan.timeFrameFrom = null;
      deliveryPlan.timeFrameTo = null;
    }

    // this.fixDeliveryPlanDatesAndTimesForSave(deliveryPlan);

    // Ak nemáme collection request alebo adresát collection requestu je špecifikovaný (je iný ako aktuálna pickup adresa),
    // tak vyplníme potrebné údaje. Toto platí aj pre RETURN produkt, ako cieľová adresa.
    if (!this.isCollectionRequest || !this.isCollectionRequestSentToMe) {
      this.updateReceiverData(receiver, receiverPerson, receiverAddress, receiverPersonAddress, this.recipient);
      orderModel.receiver = receiver;
      orderModel.receiverPerson = receiverPerson;
      orderModel.receiverAddress = receiverAddress;
      orderModel.receiverPersonAddress = receiverPersonAddress;
    }

    if (this.isCollectionRequest) {
      // Uložíme si miesto zberu zásielky. Má to rovnakú štruktúru ako v prípade adresáta.
      this.updateReceiverData(collectionRequestSender, collectionRequestPerson, collectingAddress, collectionRequestPersonAddress, this.collectionRequestRecipient, true)

      // V konečnom dôsledku ale použijeme len údaje o adrese.
      orderModel.collectingAddress = collectingAddress

      // Označíme, že collection request ide na pickup adresu, ktorá je aktuálne vybraná.
      orderModel.isCollectionRequestSentToMe = this.isCollectionRequestSentToMe;
    }

    orderModel.order.isCollectionRequest = this.isCollectionRequest;

    // The CR check was added due to #10783 (part of SHIPPER-698). The return sender address, which cannot be
    // filled in when CR is selected, overwrote the CR receiver address.
    // CR + return combination shoudn't be possible. The creation of such combination is not implemented.
    if (this.isReturnProductSelected && !this.isCollectionRequest) {
      this.updateReceiverData({} as any, {} as any, returnSenderAddress, {} as any, this.returnSender);

      orderModel.collectingAddress = returnSenderAddress;
    }

    // RETURN (2CRET - služba)
    let returnService = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.ReturnService);

    if (returnService && returnService.isAvailable && returnService.isSelected) {
      this.updateReceiverData({} as any, {} as any, returnRecipientAddress, {} as any, this.returnRecipient, true);

      orderModel.returnReceiverAddress = returnRecipientAddress;
    }

    // Customer service
    if (this.isCustomerService && this.isCollectionRequest) {
      orderModel.hasExternalSender = true;
      orderModel.senderDelisId = this.customerServiceCustomerEditorData.customerDelisId;
      orderModel.senderInvoicingNr = this.customerServiceCustomerEditorData.customerNumber;
    }

    return orderModel;
  }


  private updateExistingOrderModel = () => {
    // Aktualizujeme objekt this.existingOrderEditorModel.
    const invoiceLines: InvoiceLine[] = [];
    const goods: ShipmentsModel.Goods[] = [];

    // Goods are reset on each save.
    this.existingOrderEditorModel.goods = goods;

    // ID produktu
    if (this.selectedProduct) {
      this.existingOrderEditorModel.order.productId = this.selectedProduct.id;
    } else {
      this.existingOrderEditorModel.order.productId = null;
    }

    this.existingOrderEditorModel.order.deliveryWindowId = this.getCityService120DeliveryWindowId();

    if (this.existingOrderEditorModel.shipment) {
      this.existingOrderEditorModel.shipment.referentialInfo1 = this.referentialInfo1;
      this.existingOrderEditorModel.shipment.referentialInfo2 = this.referentialInfo2;
      this.existingOrderEditorModel.shipment.referentialInfo3 = this.referentialInfo3;
      this.existingOrderEditorModel.shipment.referentialInfo4 = this.referentialInfo4;
    }
    
    this.existingOrderEditorModel.shipmentProductDynamicFieldOptions = this.shipmentsAdditionalFieldsComponent?.collectFieldValues();

    this.existingOrderEditorModel.order.content = this.note;

    this.existingOrderEditorModel.order.customerReferenceNr = this.customerReferenceNr

    this.existingOrderEditorModel.order.parcelsCount = this.parcelsCount;

    this.existingOrderEditorModel.order.pickupDate = this.toTimezonelessDate(this.pickupDate);

    if (this.isCollectionRequest) {
      this.existingOrderEditorModel.order.weight = this.crWeight != null ? Math.round(this.crWeight * 100) : null;
      this.existingOrderEditorModel.order.partnerReference1 = this.crInfo1;
      this.existingOrderEditorModel.order.partnerReference2 = this.crInfo2;
    }

    if (!this.isCollectionRequest) {
      if (!this.existingOrderEditorModel.shipment) {
        this.existingOrderEditorModel.shipment = {} as any;
      }
    }

    // Chceme len den bez časovej zložky.
    if (this.existingOrderEditorModel.shipment) {
      this.existingOrderEditorModel.shipment.expectedPickupDateTime = this.existingOrderEditorModel.order.pickupDate;
      this.existingOrderEditorModel.shipment.sendingDepotCode = this.selectedSenderAddress.depotCode;
    }

    // Upravíme zoznam balíkov podľa toho, čo máme v UI.
    let currentParcelsCount = this.existingOrderEditorModel.parcels.length;

    this.existingOrderEditorModel.order.isCollectionRequest = this.isCollectionRequest;
    this.existingOrderEditorModel.isCollectionRequestSentToMe = this.isCollectionRequestSentToMe;

    if (this.existingOrderEditorModel.parcels.length < this.parcelsCount) {
      // Musíme pridať záznamy.

      for (let i = 0; i < this.parcelsCount - currentParcelsCount; i++) {
        let parcel = {} as ShipmentsModel.Parcel;
        this.existingOrderEditorModel.parcels.push(parcel);
      }
    } else if (this.existingOrderEditorModel.parcels.length > this.parcelsCount) {
      // Odstraňujeme nadbytočné záznamy.
      for (let i = 0; i < currentParcelsCount - this.parcelsCount; i++) {
        this.existingOrderEditorModel.parcels.splice(this.parcelsCount, 1);
      }
    }

    // Samotný update balíkov.
    let firstParcel: ShipmentsModel.Parcel = null;

    for (let i = 0; i < this.parcelsCount; i++) {
      const parcel = this.existingOrderEditorModel.parcels[i];
      const goodsItem = {} as ShipmentsModel.Goods;

      let parcelData = this.parcels[i];

      // Pre nové balíky si musíme poslať aj "virtuálne" ID, aby sa vedeli spárovať napríklad údaje o COD.
      if (parcelData.id < 0) {
        parcel.id = parcelData.id;
      }

      goodsItem.id = (-i) - 1;

      goodsItem.minTemperature = parcelData.tempMin;
      goodsItem.maxTemperature = parcelData.tempMax;
      goodsItem.description = parcelData.description;
      goodsItem.limitDate = parcelData.limitDate;

      if (this.isFreshProductSelected) {
        goods.push(goodsItem);
      }

      if (i > 0 && this.allParcelsHaveSameReferences) {
        // Všetky balíky majú rovnakú referenciu.
        parcel.referentialInfo1 = firstParcel.referentialInfo1;
        parcel.referentialInfo2 = firstParcel.referentialInfo2;
        parcel.referentialInfo3 = firstParcel.referentialInfo3;
        parcel.referentialInfo4 = firstParcel.referentialInfo4;
      } else {
        // Každý balík má svoje referencie.
        parcel.referentialInfo1 = parcelData.referentialInfo1;
        parcel.referentialInfo2 = parcelData.referentialInfo2;
        parcel.referentialInfo3 = parcelData.referentialInfo3;
        parcel.referentialInfo4 = parcelData.referentialInfo4;
      }

      if (i > 0 && this.allParcelsHaveSameDimensions) {
        // Všetky balíky majú rovnaké rozmery.
        parcel.volume = firstParcel.volume;
        parcel.declaredWeight = firstParcel.declaredWeight;
      } else {
        // Každý balík má svoj rozmer.
        const widthString = ("000" + (parcelData.width ? parcelData.width : ""));
        const heightString = ("000" + (parcelData.height ? parcelData.height : ""));
        const lengthString = ("000" + (parcelData.length ? parcelData.length : ""));

        parcel.volume =
          lengthString.substr(lengthString.length - 3) +
          widthString.substr(widthString.length - 3) +
          heightString.substr(heightString.length - 3);

        // Server očakáva celé číslo, čo by sme teoreticky mali dostať,
        // keďže vstup je na 2 desatinné miesta, ale problém je násobenie,
        // keďže float/double sú nepresné a vznikajú tam artefakty.
        // Napríklad 4.44 * 100 = 444.00000000000006.
        parcel.declaredWeight = Math.round(parcelData.weight * 100);
      }

      // Pre RETURN vyčistíme hmotnosti balíkov.
      if (this.isReturnProductSelected) {
        parcel.declaredWeight = 0;
      }

      if (i === 0) {
        firstParcel = parcel;
      }

      if (this.isCustomClearance && this.selectedShipmentTypeCode !== ShipmentTypeCodeDocuments) {
        const invoiceLine = {} as InvoiceLine;

        parcel.customTransportCost = parcelData.customTransportCost;

        if (this.invoice === null && this.customClearanceViewComponent !== null && this.customClearanceViewComponent.invoice != null) {
          parcel.customTransportCostCurrencyCode = this.customClearanceViewComponent.invoice.currencyCode;
        }
        else {
          parcel.customTransportCostCurrencyCode = this.invoice.currencyCode;
        }

        invoiceLine.id = parcelData.invoiceLineId || - (invoiceLines.length + 1);
        invoiceLine.parcelId = parcel.id;
        invoiceLine.content = parcelData.content;
        invoiceLine.customsTariffNumber = parcelData.customsTariffNumber;
        invoiceLine.itemsNumber = parcelData.itemsNumber;
        invoiceLine.amountLine = parcelData.amountLine;
        invoiceLine.grossWeight = (parcelData.grossWeight !== null && parcelData.grossWeight !== undefined) ? Math.round(parcelData.grossWeight * 100) : null;
        invoiceLine.originCountryCode = parcelData.originCountryCode ? parcelData.originCountryCode : null;
        invoiceLine.receiverCustomsTariffNumber = parcelData.customsTariffNumber;// parcelData.receiverCustomsTariffNumber;

        invoiceLines.push(invoiceLine);
      } else {
        parcel.customTransportCost = null;
        parcel.customTransportCostCurrencyCode = null;
      }
    }

    // Služby.
    // Nájdeme si well-known služby.

    // COD

    let codProduct = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.Cod);

    if (codProduct && (codProduct.isAvailable || (this.isCollectionRequest && this.isCodForCrAllowed)) && codProduct.isSelected) {
      if (this.isEnabledCodForEachParcel) {
        let firstCod = codProduct.data[0] as ShipmentsModel.CodData;

        codProduct.data.forEach((codData: ShipmentsModel.CodData, index: number) => {

          if (typeof this.existingOrderEditorModel.cods[index] === "undefined" || this.existingOrderEditorModel.cods[index] == null) {
            this.existingOrderEditorModel.cods[index] = {} as ShipmentsModel.Cod;
          }

          if (index == 0) {
            firstCod = codData;
          }

          this.existingOrderEditorModel.cods[index].amount = codData.amount;
          this.existingOrderEditorModel.cods[index].purpose = codData.purpose;
          this.existingOrderEditorModel.cods[index].parcelId = this.parcels[index].id;
          this.existingOrderEditorModel.cods[index].currencyCode = firstCod.currency;
          this.existingOrderEditorModel.cods[index].collectionTypeId = firstCod.collectionTypeId;

          // Ak máme vybraný účet, uložíme si jeho údaje.
          if (this.selectedBankAccount != null) {
            this.existingOrderEditorModel.cods[index].bankAccountCustomId = this.selectedBankAccount.customId;
            this.existingOrderEditorModel.cods[index].iban = this.selectedBankAccount.iban;
            this.existingOrderEditorModel.cods[index].bic = this.selectedBankAccount.bic;
            this.existingOrderEditorModel.cods[index].accountNr = this.selectedBankAccount.accountNumber;
            this.existingOrderEditorModel.cods[index].accountName = this.selectedBankAccount.name;
            this.existingOrderEditorModel.cods[index].sbkName = this.selectedBankAccount.bankName.substr(0, 27);
          }
        });

        // Treba odstrániť prebytočné údaje.
        if (codProduct.data.length < this.existingOrderEditorModel.cods.length) {
          this.existingOrderEditorModel.cods.splice(codProduct.data.length, this.existingOrderEditorModel.cods.length - codProduct.data.length);
        }

        // Nemáme centrálne nastavenie COD, pre istotu ho premažeme.
        this.existingOrderEditorModel.cod = null;
        this.existingOrderEditorModel.order.isCodAmountSetSeparatelyForEachParcel = true;

        // Ak máme jedno COD
        if (this.existingOrderEditorModel.cods.length === 1) {
          this.existingOrderEditorModel.cod = this.existingOrderEditorModel.cods[0];
          this.existingOrderEditorModel.order.isCodAmountSetSeparatelyForEachParcel = false;
          this.existingOrderEditorModel.cods = null;
        }
      } else {
        let codData = codProduct.data as ShipmentsModel.CodData;
        if (codData != null) {
          if (typeof this.existingOrderEditorModel.cod === "undefined" || this.existingOrderEditorModel.cod == null) {
            this.existingOrderEditorModel.cod = {} as ShipmentsModel.Cod;
          }

          this.existingOrderEditorModel.cod.amount = codData.amount;
          this.existingOrderEditorModel.cod.purpose = codData.purpose;
          this.existingOrderEditorModel.cod.parcelId = this.parcels[0].id;
          this.existingOrderEditorModel.cod.currencyCode = codData.currency;
          this.existingOrderEditorModel.cod.collectionTypeId = codData.collectionTypeId;

          // Ak máme vybraný účet, uložíme si jeho údaje.
          if (this.selectedBankAccount != null) {
            this.existingOrderEditorModel.cod.bankAccountCustomId = this.selectedBankAccount.customId;
            this.existingOrderEditorModel.cod.iban = this.selectedBankAccount.iban;
            this.existingOrderEditorModel.cod.bic = this.selectedBankAccount.bic;
            this.existingOrderEditorModel.cod.accountNr = this.selectedBankAccount.accountNumber;
            this.existingOrderEditorModel.cod.accountName = this.selectedBankAccount.name;
            this.existingOrderEditorModel.cod.sbkName = this.selectedBankAccount.bankName.substr(0, 27);
          }
        }

        // Nemáme nastavenia COD per balík, premažeme ich.
        this.existingOrderEditorModel.cods = null;
        this.existingOrderEditorModel.order.isCodAmountSetSeparatelyForEachParcel = false;
      }
    } else {
      // COD nemáme vybrané, premažeme dáta.
      this.existingOrderEditorModel.cod = null;
      this.existingOrderEditorModel.cods = [];
      this.existingOrderEditorModel.order.isCodAmountSetSeparatelyForEachParcel = false;
    }



    // ID check
    let idCheckProduct = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.IDCheck);

    if (idCheckProduct && idCheckProduct.isAvailable && idCheckProduct.isSelected) {
      let idCheckData = idCheckProduct.data as ShipmentsModel.IDCheckData;

      if (!this.existingOrderEditorModel.receiverPerson) {
        this.existingOrderEditorModel.receiverPerson = {} as any;
      }

      this.existingOrderEditorModel.receiverPerson.personName = idCheckData.name;
      this.existingOrderEditorModel.receiverPerson.personIdNumber = idCheckData.idCardNumber;
    }

    // SWAP
    let swapProduct = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.Swap);

    if (swapProduct && swapProduct.isAvailable && swapProduct.isSelected) {
      this.existingOrderEditorModel.swaps = [];
      let swapData = swapProduct.data as ShipmentsModel.SwapData;

      // Podľa počtu SWAP balíkov si vytvoríme inštancie.
      for (let i = 0; i < (this.isSameNumberOfSwapParcelsRequiredAsOriginalParcels ? this.parcelsCount : swapData.parcelsCount); i++) {
        let swapParcel: ShipmentsModel.SwapParcel = {} as any;

        swapParcel.parcelId = this.parcels[i].id; // Zatiaľ dočasné záporné ID.
        swapParcel.id = -1 - i;
        swapParcel.note = swapData.note;

        this.existingOrderEditorModel.swaps.push(swapParcel);

        // when swapType is ABC please add address
        // for third-recipient(C) into addresses
        // in another types of swap is AB address contained in
        // sender(A) and recipient(B)
        if (this.swapTypeSelected === ShipmentsModel.SwapTypeCode.ABC) {
          this.existingOrderEditorModel.swapReceiverAddresses = [this.swapAbcAddress as ShipmentsModel.Address];
        } else {
          // Aktuálne dáta zabúdame, takže premažeme údaje, ktoré sme negenerovali.
          this.existingOrderEditorModel.swapReceiverAddresses = [];
        }
      }

      this.existingOrderEditorModel.swapReceivers = [];
    } else {
      this.existingOrderEditorModel.swaps = [];
      // Aktuálne dáta zabúdame, takže premažeme údaje, ktoré sme negenerovali.
      this.existingOrderEditorModel.swapReceiverAddresses = [];
      this.existingOrderEditorModel.swapReceivers = [];
    }


    // RETURN (2CRET - služba)
    let returnService = _.find(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) => s.code === ConfigModel.WellKnownProductCodes.ReturnService);

    if (returnService && returnService.isAvailable && returnService.isSelected) {
      this.updateReceiverData({} as any, {} as any, this.existingOrderEditorModel.returnReceiverAddress, {} as any, this.returnRecipient, true);
    }

    if (!this.isCollectionRequest || !this.isCollectionRequestSentToMe) {
      // Ak máme Pudo produkt zvolený a predtým nebol, môžu nám chýbať niektoré polia.
      if (!this.existingOrderEditorModel.receiver) {
        this.existingOrderEditorModel.receiver = {} as any;
      }

      if (!this.existingOrderEditorModel.receiverPerson) {
        this.existingOrderEditorModel.receiverPerson = {} as any;
      }

      if (!this.existingOrderEditorModel.receiverAddress) {
        this.existingOrderEditorModel.receiverAddress = {} as any;
      }

      if (!this.existingOrderEditorModel.receiverPersonAddress) {
        this.existingOrderEditorModel.receiverPersonAddress = {} as any;
      }

      this.updateReceiverData(
        this.existingOrderEditorModel.receiver,
        this.existingOrderEditorModel.receiverPerson,
        this.existingOrderEditorModel.receiverAddress,
        this.existingOrderEditorModel.receiverPersonAddress,
        this.recipient);
    }

    if ((!this.selectedProduct || !this.selectedProduct.isDeliveryToPudoProduct) && this.existingOrderEditorModel.receiver) {
      // Musíme prečistiť PudoId z adresy.
      this.existingOrderEditorModel.receiver.pudoId = "";
    }

    if (this.hasCustomSenderAddress) {
      this.existingOrderEditorModel.senderAddress.name1 = this.customSenderAddress.name;
      this.existingOrderEditorModel.senderAddress.name2 = this.customSenderAddress.name2;
      this.existingOrderEditorModel.senderAddress.street = this.customSenderAddress.street;
      this.existingOrderEditorModel.senderAddress.houseNr = this.customSenderAddress.houseNr;
      this.existingOrderEditorModel.senderAddress.addressLine2 = this.customSenderAddress.streetDetail;
      this.existingOrderEditorModel.senderAddress.countryCode = this.customSenderAddress.country.code;
      this.existingOrderEditorModel.senderAddress.city = this.customSenderAddress.city;
      this.existingOrderEditorModel.senderAddress.stateCode = this.customSenderAddress.stateCode;
      this.existingOrderEditorModel.senderAddress.phone = this.customSenderAddress.phone;
      this.existingOrderEditorModel.senderAddress.email = this.customSenderAddress.email;
      this.existingOrderEditorModel.senderAddress.zip = this.customSenderAddress.zip;
    } else {
      this.existingOrderEditorModel.senderAddress = null; // Server si ju znovu vytvorí podľa aktuálnej pickup adresy.
    }

    if (this.isCollectionRequest) {
      if (!this.existingOrderEditorModel.pickupAddress) {
        this.existingOrderEditorModel.pickupAddress = {} as any;
      }
      // V prípade collection requestu potrebujeme len adresu späť.
      this.updateReceiverData(
        {} as any,
        {} as any,
        this.existingOrderEditorModel.pickupAddress,
        {} as any,
        this.collectionRequestRecipient
      );

      this.existingOrderEditorModel.order.isCollectionRequest = true;
      this.existingOrderEditorModel.isCollectionRequestSentToMe = this.isCollectionRequestSentToMe;
    }


    if (this.isReturnProductSelected) {
      if (!this.existingOrderEditorModel.senderAddress) {
        this.existingOrderEditorModel.senderAddress = {} as any;
      }

      this.updateReceiverData({} as any, {} as any, this.existingOrderEditorModel.senderAddress, {} as any, this.returnSender);
    }

    if (this.isDpdPreciseProduct) {
      let selectedDeliveryWindow = this.deliveryWindows.find(dw => dw.id == this.selectedDeliveryWindowId);

      this.existingOrderEditorModel.deliveryWindowId = this.selectedDeliveryWindowId;

      if (selectedDeliveryWindow) {
        this.existingOrderEditorModel.deliveryPlan.deliveryDateFrom = this.deliveryPlan.deliveryDateFrom;
        this.existingOrderEditorModel.deliveryPlan.deliveryDateTo = this.deliveryPlan.deliveryDateFrom;
        this.existingOrderEditorModel.deliveryPlan.timeFrameFrom = selectedDeliveryWindow.timeFrom;
        this.existingOrderEditorModel.deliveryPlan.timeFrameTo = selectedDeliveryWindow.timeTo;
      }
    } else if (this.existingOrderEditorModel.deliveryPlan) {
      this.existingOrderEditorModel.deliveryPlan.deliveryDateFrom = null;
      this.existingOrderEditorModel.deliveryPlan.deliveryDateTo = null;
      this.existingOrderEditorModel.deliveryPlan.timeFrameFrom = null;
      this.existingOrderEditorModel.deliveryPlan.timeFrameTo = null;
    }


    if (this.existingOrderEditorModel.shipment) {
      this.existingOrderEditorModel.shipment.invoiceShipment = this.isCustomClearance;
      if (this.isCustomClearance) {

        if (this.invoice == null) {
          this.invoice = this.customClearanceViewComponent.invoice
        }

        if (this.existingOrderEditorModel.invoice == null) {
          this.existingOrderEditorModel.invoice = this.getEmptyInvoice();
        }

        this.existingOrderEditorModel.invoice.number = this.invoice.number;
        this.existingOrderEditorModel.invoice.dateOfIssue = this.invoice.dateOfIssue;
        this.existingOrderEditorModel.invoice.orderId = this.existingOrderEditorModel.shipment.orderId;
        this.existingOrderEditorModel.invoice.amount = this.invoice.amount;
        this.existingOrderEditorModel.invoice.currencyCode = this.invoice.currencyCode;
        this.existingOrderEditorModel.invoice.comment = this.invoice.comment;
        // this.existingOrderEditorModel.invoice.amountEx = this.invoice.amountEx;
        // this.existingOrderEditorModel.invoice.currencyCodeEx = this.invoice.currencyCodeEx;
        this.existingOrderEditorModel.invoice.amountEx = this.invoice.amount;
        this.existingOrderEditorModel.invoice.currencyCodeEx = this.invoice.currencyCode;
        this.existingOrderEditorModel.invoice.isInvoceAddressDifferent = this.invoice.isInvoceAddressDifferent;
        this.existingOrderEditorModel.invoice.exportReasonCode = this.invoice.exportReasonCode;
        this.existingOrderEditorModel.invoice.mrn = this.invoice.mrn;

        this.existingOrderEditorModel.shipment.incoTermCode = this.customClearanceEditorData.incoTermCode;
        this.existingOrderEditorModel.shipment.prealertStatusCode = this.customClearanceEditorData.prealertStatusCode;
        this.existingOrderEditorModel.shipment.shipmentTypeCode = this.customClearanceEditorData.shipmentTypeCode;
        this.existingOrderEditorModel.shipment.clearanceClearedCode = this.customClearanceEditorData.clearenceClearedCode;
        this.existingOrderEditorModel.shipment.hasAccompanyingDocs = this.customClearanceEditorData.hasAccompanyingDocs;

        this.existingOrderEditorModel.receiver.vatNumber = this.recipient.vatNumber;

        this.existingOrderEditorModel.sender.eoriNumber = this.customClearanceEditorData.eoriNumber;
        this.existingOrderEditorModel.sender.vatNumber = this.customClearanceEditorData.vatNumber;
        this.existingOrderEditorModel.sender.webSite = this.customClearanceEditorData.webSite;
        this.existingOrderEditorModel.sender.destinationCountryRegistration = this.customClearanceEditorData.hmrcNumber ? this.customClearanceEditorData.hmrcNumber : "";

        // Upravíme zoznam sprievodnych dokumentov podľa toho, čo máme v UI.
        let currentShipmentAccompanyingDocs = this.existingOrderEditorModel.shipmentAccompanyingDocs.length;

        if (this.customClearanceEditorData.hasAccompanyingDocs) {
          if (this.existingOrderEditorModel.shipmentAccompanyingDocs.length < this.customClearanceEditorData.accompDocsTypeCodes.length) {
            // Musíme pridať záznamy.

            for (let i = 0; i < this.customClearanceEditorData.accompDocsTypeCodes.length - currentShipmentAccompanyingDocs; i++) {
              let accompDoc = {} as ShipmentsModel.ShipmentAccompanyingDoc;
              this.existingOrderEditorModel.shipmentAccompanyingDocs.push(accompDoc);
            }
          } else if (this.existingOrderEditorModel.shipmentAccompanyingDocs.length > this.customClearanceEditorData.accompDocsTypeCodes.length) {
            // Odstraňujeme nadbytočné záznamy.
            for (let i = 0; i < currentShipmentAccompanyingDocs - this.customClearanceEditorData.accompDocsTypeCodes.length; i++) {
              this.existingOrderEditorModel.shipmentAccompanyingDocs.splice(this.customClearanceEditorData.accompDocsTypeCodes.length, 1);
            }
          }

          for (let i = 0; i < this.customClearanceEditorData.accompDocsTypeCodes.length; i++) {
            let shipmentAccompanyingDoc = this.existingOrderEditorModel.shipmentAccompanyingDocs[i];

            let shipmentAccompanyingDocData = this.customClearanceEditorData.accompDocsTypeCodes[i];

            shipmentAccompanyingDoc.accompanyingDocTypeCode = shipmentAccompanyingDocData;
          }
        } else {
          this.existingOrderEditorModel.shipmentAccompanyingDocs = [];
        }




        for (let i = 0; i < this._invoiceAdditionalLines; i++) {
          let invoiceLine = {} as InvoiceLine;

          let invoiceLineData = this.invoiceLines[i];

          invoiceLine.id = invoiceLineData.id || - (invoiceLines.length + 1);
          invoiceLine.content = invoiceLineData.content;
          invoiceLine.customsTariffNumber = invoiceLineData.customsTariffNumber;
          invoiceLine.itemsNumber = invoiceLineData.itemsNumber;
          invoiceLine.amountLine = invoiceLineData.amountLine,
            invoiceLine.grossWeight = (invoiceLineData.grossWeight != null && invoiceLineData.grossWeight != undefined) ? Math.round(invoiceLineData.grossWeight * 100) : null;
          invoiceLine.originCountryCode = invoiceLineData.originCountryCode ? invoiceLineData.originCountryCode : null,
            invoiceLine.receiverCustomsTariffNumber = invoiceLineData.customsTariffNumber;// invoiceLineData.receiverCustomsTariffNumber;

          invoiceLines.push(invoiceLine);
        }

        this.existingOrderEditorModel.invoiceLines = invoiceLines;

        if (this.customSenderInvoiceAddress) {
          this.populateSenderInvoicingAddress();
          this.existingOrderEditorModel.senderInvoiceAddress = this.senderInvoicingAddress;
        } else {
          this.existingOrderEditorModel.senderInvoiceAddress = null;
          this.existingOrderEditorModel.sender.invoiceAddressId = null;
        }

        if (this.customReceiverInvoiceAddress) {
          this.populateReceiverInvoicingAddress();
          this.existingOrderEditorModel.receiverInvoiceAddress = this.receiverInvoicingAddress;
        } else {
          this.existingOrderEditorModel.receiverInvoiceAddress = null;
          this.existingOrderEditorModel.receiver.invoiceAddressId = null;
        }

      } else {
        this.existingOrderEditorModel.invoice = null;
        this.existingOrderEditorModel.invoiceLines = [];
      }
    } else {
      this.existingOrderEditorModel.invoice = null;
      this.existingOrderEditorModel.invoiceLines = [];
    }

    // this.fixDeliveryPlanDatesAndTimesForSave(this.existingOrderEditorModel.deliveryPlan);

    this.existingOrderEditorModel.additionalServiceIds = _.filter(this.additionalServices,
      (s: ConfigModel.OrderEditorProductViewModel) =>
        (s.isAvailable || (this.isCollectionRequest && this.isCodForCrAllowed && s.code === ConfigModel.WellKnownProductCodes.Cod)) &&
        s.isSelected
    )
      .map(p => p.id);

    this.existingOrderEditorModel.sendCustomerPersonalizedNotification =
      this.sendCustomerPersonalizedNotification;
    this.existingOrderEditorModel.customerPersonalizedNotificationLanguageCode =
      this.customerPersonalizedNotificationLanguageCode;
    this.existingOrderEditorModel.customerPersonalizedNotificationRecipients =
      this.customerPersonalizedNotificationRecipients;

    this.existingOrderEditorModel.sendReturnLabelByEmail =
      this.sendReturnLabelByEmail;
    this.existingOrderEditorModel.excludeReturnLabelFromLabelPrinting =
      this.sendReturnLabelByEmail === true ?
        this.excludeReturnLabelFromLabelPrinting : false;
    this.existingOrderEditorModel.saveAddressToAddressBook = this.isSaveAddressChecked;

    if (this.isCustomerService) {
      this.existingOrderEditorModel.hasExternalSender = true;
      this.existingOrderEditorModel.senderInvoicingNr = this.customerServiceCustomerEditorData.customerNumber;
      this.existingOrderEditorModel.senderDelisId = this.customerServiceCustomerEditorData.customerDelisId;
    }
  }


  public startLabelsGeneration() {
    const filename = `${this.parcels.length === 1 && this.parcels[0].nr ? this.parcels[0].nr + "_" : ""}${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`;

    this._shipmentService.printLabelsForOderIds(
      [this.shipmentOrderId],
      filename,
      () => {
        this.isBusy = true;
        this.statusMessage = this.localizationService.getLocalizedString("status_message_generating_labels");
      }
    ).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => this.loadExistingShipmentOrder(), () => { });
  }


  private checkMinimalOrderDate(): Promise<boolean> {
    const p = new Promise<boolean>((resolve) => {

      // CityService nerieši posun dátumu
      if (this._shipperSettingsService.isSophia && this.selectedProductCode === ConfigModel.WellKnownProductCodes.CityService) {
        return resolve(true);
      }

      if (typeof this.pickupDate === "undefined" || this.pickupDate === null) {
        return resolve(true);
      }

      this._shipmentService.getMinimalOrderDate(this.selectedSenderAddress.customerDetailId, this.selectedSenderAddress.customId, this.isCollectionRequest).subscribe(
        minimalOrderDateCore => {
          const offset = -1 * (new Date()).getTimezoneOffset();

          // úprava UTC dátumov a nastavenie na čas 00:00:00.000
          const minimalPickupDate = moment(minimalOrderDateCore).add(offset, "minutes").startOf("day").toDate();
          const requestedPickupDate = moment(this.pickupDate).startOf("day").toDate();

          if (requestedPickupDate < minimalPickupDate) {
            this._modal.confirm()
              .body(this.localizationService.getLocalizedString("minimal_order_date_shift_confirm_message", this._localizeDatePipe.transform(minimalPickupDate, "L")))
              .okBtn(this.localizationService.getLocalizedString("yes"))
              .cancelBtn(this.localizationService.getLocalizedString("no"))
              .open()
              .result.then(() => {
                this.pickupDate = minimalPickupDate;
                return resolve(true);
              }, () => {
                return resolve(false);
              });
          } else {
            return resolve(true);
          }

        }, ex => {
          const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
          this._toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo), this.localizationService.getLocalizedString("error_saving_order"), { timeOut: 0, extendedTimeOut: 2500 });
        }
      );
    });

    return p;
  }


  public saveOrder = (printLabels = false, redirectToNew = false) => {
     if (this.isReadOnly) {
       return;
     }

     const entityValidatorErrors = this._entityFormValidationService.getErrors();


     if (entityValidatorErrors.length) {
       const opt = new DefaultGlobalConfig();
       opt.toastComponent = EntityValidationToast;

       if (this.entityValidationToast) {
         this.entityValidationToast.toastRef.close();
       }

       this.entityValidationToast = this._toastr.error(
         null,
         this.localizationService.getLocalizedString("entity_validation_errors_list"),
         opt
       );

       const component = this.entityValidationToast.portal.instance as EntityValidationToast;
       component.errors = entityValidatorErrors;

       return;
     }

     if (this.formInvalid()) {
       return;
     }

    try {
      this.isBusy = true;
      this.statusMessage = "";
      this.exception = null;

      this.checkMinimalOrderDate().then((continueSavingOrder: boolean) => {
        this.isBusy = false;

        if (!continueSavingOrder) {
          return false;
        }

        // Customer service can edit CR (SHIPPER-636).
        if (this.isCollectionRequest && !this.isCustomerService) {
          this._modal.confirm()
            .body(this.localizationService.getLocalizedString("confirm_save_collection_request"))
            .okBtn(this.localizationService.getLocalizedString("yes"))
            .cancelBtn(this.localizationService.getLocalizedString("no"))
            .open()
            .result.then(() => {
              if (this.isNewShipmentOrder) {
                this.saveNewOrder(printLabels, redirectToNew);
              } else {
                this.updateExistingOrder(printLabels, redirectToNew);
              }
            }, () => { });
        } else {
          if (this.isNewShipmentOrder) {
            this.saveNewOrder(printLabels, redirectToNew);
          } else {
            this.updateExistingOrder(printLabels, redirectToNew);
          }
        }
      });
    } catch (ex) {
      this.loggingService.logErrorData(ex, "Error saving order.");

      const exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
      this._toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo), this.localizationService.getLocalizedString("error_saving_order"), { timeOut: 0, extendedTimeOut: 2500 });
    }

    // Aby to nainsto neodoslalo formulár.
    return false;
  }

  private _weightWasChecked = false;

  private saveNewOrder(printLabels = false, redirectToNew = false) {
    const orderModel = this.getNewShipmentOrderModel();

    // Ak je zadefinovaná default hmotnosť a ide pri CH o zásielku do zahraničia, je potrebná kontrola hmotnosti.
    if (this.checkDefaultWeightRequiredCountry && !this._weightWasChecked)
    {
      var addressToCheck = orderModel.order.isCollectionRequest ? orderModel.collectingAddress : orderModel.receiverAddress;
      if (addressToCheck.countryCode !== this.checkDefaultWeightRequiredCountry &&
          this.settings.parcel_default_dimensions.value.weightInKilograms > 0)
      {
        this._toastr.info(this.localizationService.getLocalizedString("check_the_entered_weight"));
        this._weightWasChecked = true;
        return;
      }
    }

    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("saving_shipment_order");
    this.exception = null;

    this._shipmentService.createOrder(orderModel)
      .subscribe(
        (order: ShipmentsModel.Order) => {
          this.isBusy = false;

          this.shipmentOrderId = order.id;

          this._toastr.success(this.localizationService.getLocalizedString("message_shipment_order_successfully_saved"));

          if (printLabels) {
            // Chceme vytlačiť štítky.
            this.startLabelsGeneration();
          }

          this.getEmptyCustomClearanceEditorData();
          this.isCustomClearance = false;

          if (!redirectToNew) {

            // Meníme stránku, tak necheceme, aby otravovalo s change handlerom.
            this.hasUnsavedChanges = () => false;

            // Nastavenia nám určujú, či sa vraciame na zoznam zásielok
            if (this.settings.return_to_shipment_list_after_saving.value) {
              this.backToList();
            } else {
              this.router.navigate(["/shipments/", order.id]);
            }
          } else {

            if (this._fromSenderScan) {
              // Ideme späť na sender scan.
              this.router.navigate(["/shipments/senderscan"]);
            } else {
              this.shipmentOrderId = 0;
              this.stateId = ShipmentsModel.ShipmentStates.new;
              this._recipientId = null;
              this.initialize();
            }
          }
        },
        ex => {
          this.loggingService.logErrorData(ex, "Error saving order.");

          this.isBusy = false;

          let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
          this._toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo), this.localizationService.getLocalizedString("error_saving_order"), { timeOut: 0, extendedTimeOut: 2500 });
        });
  }


  private updateExistingOrder(printLabels: boolean = false, redirectToNew: boolean = false) {

    try {
      // Existujúca objednávka zásielky.
      this.updateExistingOrderModel();
    } catch (ex) {
      this.loggingService.logErrorData(ex, "Error saving order.");

      this.isBusy = false;

      let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
      this._toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo), this.localizationService.getLocalizedString("error_saving_order"), { timeOut: 0, extendedTimeOut: 2500 });

      return false;
    }

    this.isBusy = true;
    this.statusMessage = this.localizationService.getLocalizedString("saving_shipment_order");
    this.exception = null;

    this._shipmentService.updateOrder(this.existingOrderEditorModel)
      .subscribe(() => {
        this.isBusy = false;

        // Toto nefugnuje v IE 10/11, tam reinicializácia zo servera nejako spôsobí touch formulára a nastavenie dirty flagu.
        window.setTimeout(() => this.setFormPristine(), 100);

        this._toastr.success(this.localizationService.getLocalizedString("message_shipment_order_successfully_saved"));

        if (printLabels) {
          // Chceme vytlačiť štítky.
          this.startLabelsGeneration();
        }

        this.getEmptyCustomClearanceEditorData();
        this.isCustomClearance = false;

        if (!redirectToNew) {
          // Nastavenia nám určujú, či sa vraciame na zoznam zásielok
          if (this.settings.return_to_shipment_list_after_saving.value) {
            this.backToList();
          } else {
            // Vyvoláme kompletný refresh.
            this.initialize();
          }

        } else {
          // Presmerujeme na novú objednávku.
          this.hasUnsavedChanges = () => false; // Aby nám nehučalo o zmenách.

          if (this._fromSenderScan) {
            // Ideme späť na sender scan.
            this.router.navigate(["/shipments/senderscan"]);
          } else {
            this.shipmentOrderId = 0;
            this._recipientId = null;
            this.stateId = ShipmentsModel.ShipmentStates.new;
            this.router.navigate(["/shipments/0"]);
          }
        }
      },
        ex => {
          this.loggingService.logErrorData(ex, "Error saving order.");

          this.isBusy = false;

          let exceptionInfo = this._exceptionsHandlerService.getExceptionInfo(ex);
          this._toastr.error(this.localizationService.getLocalizedExceptionString(exceptionInfo), this.localizationService.getLocalizedString("error_saving_order"), { timeOut: 0, extendedTimeOut: 2500 });
        });
  }


  public backToList = () => {
    this.hasUnsavedChanges = () => false

    if (this._fromSenderScan) {
      // Ideme späť na sender scan.
      this.router.navigate(["/shipments/senderscan"]);
    } else {
      this.router.navigate(["/shipments"]);
    }
  }


  public searchCities = (query: string, max: number, callback: Function) => {
    this._skDataConfigService.getCities(this.recipient.country.code, query).subscribe(cities => {
      callback(cities);
    }, ex => {
      this.loggingService.logDebug(ex);

      callback([]);
    });
  }


  private checkStopCredit() {
    if (this._shipperSettingsService.isSophia) {
      this._contextService.currentCustomerDetail.pipe(filter(cd => cd != null)).subscribe(cd => {
        if (cd.stopCredit) {
          this._customerService.openStopCreditModal();
          setTimeout(() => {
            this.router.navigate(["/shipments"]);
          }, 500);
        }
      });
    }
  }


  public onShipmentTypeChange() {
    this.refreshParcelsForEditing();
  }


  public onRecipientAddressCountryChange(country: Country) {
    this.recipientCountryChanges$.next(country);
  }

  public onCrRecipientAddressCountryChange(country: Country) {
    this.checkFixedPickupDate(country);
    this.crRecipientCountryChanges$.next(country);
  }

  public onCrRecipientZipChange(zip: string) {
    this.crRecipientZipChanges$.next(zip);
  }

  private initialize = () => {
    // Reset available products validation in case order was saved
    // and user didn't leave the editor.
    this._lastProductValidationParameters = {};
    this._areAvailableProductLoadedOnce = false;
    this._areAvailableAdditionalServicesLoadedOnce = false;

    // Teraz musíme načítať všetky údaje o produktoch a službách.
    // Následne načítam údaje objednávky.
    this.loadLookupsAndSettings()
      .then(() => {
        this.customSenderAddress = this.getEmptyRecipient();
        this.returnSender = null;

        if (this.isCustomSenderAddressAllowed && this.settings.defaultCustomSenderAddress.value) {
          this.customSenderAddress = this.getDefaultCustomSenderRecipient();
        }

        // Scroll into view once value of recipientFirstOrderForm is resolved.
        setTimeout(() => this._wizardOverlayService.scrollIntoView());
        this.initializeNewShipmentOrderOrLoadExisting();
      });
  }

  // This method loads the maximum of collection request date, based on BU settings <CollectionRequestPickupDateLimit>
  private loadMaxCRDate() {
    this._shipmentService.getMaximalCROrderDate(this.selectedSenderAddress.customerDetailId).subscribe(response => {
        const offset = -1 * (new Date()).getTimezoneOffset();
        this.maxDateCR = moment(response).add(offset, 'minutes').startOf('day').toDate();
        
        if (this.isCollectionRequest && this.pickupDate > this.maxDateCR) {
          this.pickupDate = this.maxDateCR;
          this._unavailableDaysLoad$.next({ year: moment().year(), month: moment().month() + 1, pickupDate: this.pickupDate });
        }
    });
  }

  private loadMinCRDate()
  {
    this._shipmentService.getMinimalOrderDate(this.selectedSenderAddress.customerDetailId, this.selectedSenderAddress.customId, true).subscribe(minimalOrderDateCore => {
        const offset = -1 * (new Date()).getTimezoneOffset();
        this.minDateCR = moment(minimalOrderDateCore).add(offset, "minutes").startOf("day").toDate();

        if (this.isCollectionRequest && this.pickupDate < this.minDateCR) {
          this.pickupDate = this.minDateCR;
          this._unavailableDaysLoad$.next({year: moment().year(), month: moment().month() + 1, pickupDate: this.pickupDate });
        }
    });
  }


  public ngOnInit() {

    this.checkStopCredit();
    this.setUpAddressChangeListeners();
    this.setUpPudoAddressChangeListeners();
    this.setUpPickupPointPrerequisitiesChangeSubscription();
    this.initCustomAmountsObserver();
    this.initIsRecipientEmailRequiredCrossBorderObserver();
    this.initProductsAndAdditionalServicesAvailabilityCheck();
    this.setPanelsOrder();
    this.initCrRecipientAddressCountryAndZipObserver();
    this.initUnvailableDaysLoadObserver();

    this.addressCountry.valueChanges.subscribe(c => {
      this.recipientCountryChanges$.next(c);
    });

    merge(
      this.recipientCountryChanges$,
      this._isCustomsClearanceChanges$
    ).pipe(
      map(() => this.isBrexitCountryRecipient),
      distinctUntilChanged()
    ).subscribe(() => {
      if (this.isBrexitCountryRecipient && this.brexitParcelsCountReadOnly) {
        this.parcelsCount = 1;
        this.allParcelsHaveSameReferences = true;
        this.allParcelsHaveSameDimensions = true;
      } else {
        this.allParcelsHaveSameReferences = this.settings?.all_parcels_in_a_shipment_have_the_same_references?.value;
        this.allParcelsHaveSameDimensions = this.settings?.all_parcels_in_a_shipment_have_the_same_dimensions?.value;
      }
    });

    // Adjust of required validation for customs clearance section fields - fields in custom-clearance component
    this.recipientCountryChanges$.pipe(
      distinctUntilChanged()
    ).subscribe((recipientCountry) => {
      if (!recipientCountry){
        return
      }
      // validation is explicitly turned off, we have nothing to do
      if (this.isCustomsClearanceRequiredValidationTurnedOff){
        return;
      }
      // validation is turned on in general, but there are no countries specified for which only is the validation enabled, it is enabled for all countries, we have nothing to adjust
      if (!this.customsClearanceRequiredValidationCountryCodes.length){
        return
      }

      // the validation is enabled only for such a selected recipient country that matches the configuration countries
      this.isCustomsClearanceRequiredValidationOn = _.some(this.customsClearanceRequiredValidationCountryCodes, configurationCountryCode => configurationCountryCode === recipientCountry.code);
      //console.log('CustomsClearanceRequiredValidation is: ' +  this.isCustomsClearanceRequiredValidationOn);
    });

    // Adjust of required validation for customs clearance bound fields - fields needed for customs in other components (not custom-clearance)
    this.recipientCountryChanges$.pipe(
      distinctUntilChanged()
    ).subscribe((recipientCountry) => {
      if (!recipientCountry){
        return
      }
      // validation is explicitly turned off, we have nothing to do
      if (this.isCustomsClearanceBoundFieldsRequiredValidationTurnedOff){
        return;
      }
      // validation is turned on in general, but there are no countries specified for which only is the validation enabled, it is enabled for all countries, we have nothing to adjust
      if (!this.customsClearanceBoundFieldsRequiredValidationCountryCodes.length){
        return
      }

      // the validation is enabled only for such a selected recipient country that matches the configuration countries
      this.isCustomsClearanceBoundFieldsRequiredValidationOn = _.some(this.customsClearanceBoundFieldsRequiredValidationCountryCodes, configurationCountryCode => configurationCountryCode === recipientCountry.code);
      //console.log('CustomsClearanceBoundRequiredValidation is: ' +  this.isCustomsClearanceBoundFieldsRequiredValidationOn);
    });

    // Sledujeme nastavenie parametrov pre aktuálny route.
    this._parametersChangeSubscription = combineLatest([this._route.params, this._route.queryParams]).subscribe(result => {
      try {
        let params = result[0];

        this.shipmentOrderId = +params[SHIPMENT_ORDER_ID_PARAMETER_KEY];

        let queryParams = result[1];

        this._recipientId = +queryParams[RECIPIENT_ID_PARAMETER_KEY];
        this._location.replaceState(`/shipments/${this.shipmentOrderId}`);

        if (queryParams[FROM_SENDER_SCAN_PARAMETER_KEY]) {
          this._fromSenderScan = queryParams[FROM_SENDER_SCAN_PARAMETER_KEY];
        }
      } catch (ex) {
        this.loggingService.logErrorData(ex);

        // Ak zadal ako parameter nejakú blbosť, tak nastavíme ID objednávky na 0.
        this.shipmentOrderId = 0;
      }

      this._route.paramMap.pipe(
        take(1)
      ).subscribe(paramMap => {
        if (Number(paramMap.get("shipmentOrderId")) === 0) {
          this._updateService.showVersionDeprecatedDialog();
        }
      });

      this.initialize();
    });

    /** Disable required validation of customs clearance fields for CH (bussines unit). */
    this._contextService.businessUnitCode.pipe(
      takeUntil(this.destroy$)
    ).subscribe(code => {
      // *hack*
      // special order is available when NL address provider is initialized and current BU is 010(NL)
      var addressProviderInUse = this._shipperSettingsService.addressProviderInUse;
      this.isSpecialOrderInRecipientView = addressProviderInUse === 'NL' && code === BUSINESS_UNIT_CODE_NL;
      this.brexitParcelsCountReadOnly = code !== BUSINESS_UNIT_CODE_CH;
    });

    // Set up customer service mode
    this.authenticationService.isCustomerService$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      this.isCustomerService = value
      if (this.isCustomerService) {
        // For now only CR is enabled for customer service.
        this.isCollectionRequest = true;
        // Customer service creates CR orders for different customers therefore it will
        // utilize existing logic for creating CRs for different recipient.
        this.isCollectionRequestSentToMe = false;
        // Sender will have the address of the selected customer.
        // NOTE: Additionally sender's delisId and invoicing number will be loaded from API.
        this.hasCustomSenderAddress = true;
      }
    });
    this.updateSelectedServices();
  }


  public ngOnDestroy() {
    if (this._parametersChangeSubscription != null) {
      this._parametersChangeSubscription.unsubscribe();
      this._parametersChangeSubscription = null;
    }

    if (this._customClearanceSubscription != null) {
      this._customClearanceSubscription.unsubscribe();
      this._customClearanceSubscription = null;
    }

    if (this._currentAddressSubscription != null) {
      this._currentAddressSubscription.unsubscribe();
      this._currentAddressSubscription = null;
    }

    this.destroy$.next();
    this.destroy$.complete();
  }


  public hasUnsavedChanges = (): boolean => {
     if (this._wizardService.isActive) {
       return false;
     }

    let recipientDirty = this.recipientsViewComponents.some((recipientView) => {
      return recipientView.hasUnsavedChanges();
    })

    let customClearanceDirty = false;

    if (this.customClearanceViewComponent) {
      customClearanceDirty = this.customClearanceViewComponent.hasUnsavedChanges();
    }

    return this.form.dirty || recipientDirty || customClearanceDirty;
  }

  public formInvalid() {
    if (this.isBusyValidating) {
      return true;
    }

    let recipientFormIsNotValid = false;
    let customClearanceFormIsNotValid = false;
    let additionalServicesIsNotValid = false;

    if (this.recipientsViewComponents) {
      this.recipientsViewComponents.forEach(item => {
        recipientFormIsNotValid = recipientFormIsNotValid || item.formInvalid();
      });
    }

    if (this.customClearanceViewComponent) {
      customClearanceFormIsNotValid = this.customClearanceViewComponent.formInvalid();
    }

    if (this.shipmentsAdditionalServicesComponents) {
      additionalServicesIsNotValid =
        this.shipmentsAdditionalServicesComponents.some(item => item.invalid);
    }

    let result = !this.form.form.valid || recipientFormIsNotValid || customClearanceFormIsNotValid || additionalServicesIsNotValid;

    if (!result) {
      if (!this.isCollectionRequest && !this.isDpdPreciseProduct) {
        return !this.isRoutable || result;
      }

      if (this.isDpdPreciseProduct) {
        return !this.selectedDeliveryWindowId || !this.isRoutable || result;
      }
    }


    return result;
  }


  getEntityControlErroLabel = (controlName: string, namePrefix: string) => {
    let label;
    let prefix;

    if (namePrefix && controlName.includes(namePrefix)) {
      controlName = controlName.replace(namePrefix, "");
      prefix = namePrefix.slice(0, -1);
    }

    switch (controlName) {
      case "recipient_type":
        label = this.recipient.customerRecipientTypeCode == this.b2c ?
          "recipient_type_b2c" :
          "recipient_type_b2b";
        break;
      case "recipient_name":
      case "recipient_name2":
        label = this.recipient.customerRecipientTypeCode == this.b2c ?
          "recipient_person_name" :
          "recipient_organization_name";
        break;
      default:
        label = typeof shipmentEditorValidationTexts[controlName]?.label !== "undefined" ?
          shipmentEditorValidationTexts[controlName].label :
          controlName;
        break;
    }

    if (prefix) {
      prefix = this.localizationService.getLocalizedString(prefix);
    }

    if (!prefix) {
      prefix = "";  // to avoid error "TypeError: Cannot read properties of undefined (reading 'trim')" below
    }

    label = this.localizationService.getLocalizedString(label);

    return [prefix, label].filter(v => Boolean(v.trim())).join(" - ");
  }

  getEntityControlErrorDesciription = (controlName: string, errorKey: string, errorData: any, namePrefix: string): string => {

    if (namePrefix && controlName.includes(namePrefix)) {
      controlName = controlName.replace(namePrefix, "");
    }

    let description = _.get(
      shipmentEditorValidationTexts,
      `${controlName}.errors.${errorKey}`,
      genericEntityFormValidatorErrorDescriptions[errorKey]
    );

    if (_.isObjectLike(errorData)) {
      errorData = Object.keys(errorData).map(key => errorData[key]);
    } else {
      errorData = [errorData]
    }

    description = this.localizationService.getLocalizedString(description, ...errorData);

    return description;
  }

  public ngAfterViewInit(): void {
    this.observeWizard();

    // Z nejakého dôvodu nepatria nasledujúce kontrolky pod centrálny formulár
    // a ich dirty flag nevplýva na dirty flag formulára.
    // Keď ich pridáme medzi kontrolky formulára takto explicitne, tak je to ok.

    // Tieto polia momentálne používa editáca v prípade, ak je cieľom PUDO.
    this.form.form.addControl("addressCountry", this.addressCountry);
    this.form.form.addControl("addressZip", this.addressZip);
    this.form.form.addControl("addressCity", this.addressCity);
    this.form.form.addControl("invoiceLines", this.invoiceLinesControl)

    // Zavesíme sa na zmenu pickup adresy a jej zmenu detegujeme, iba pokiaľ sme v režime novej objednávky
    this._currentAddressSubscription = this._contextService.currentAddress.subscribe(
      currentAddress => {
        if (currentAddress && this.isNewShipmentOrder && !this.isReadOnly && this.selectedSenderAddress && this.selectedSenderAddress.id != currentAddress.id) {
          // Zapamätáme si pôvodnú adresu
          let selectedSenderAddressBackup = _.cloneDeep(this.selectedSenderAddress);

          this._modal.confirm()
            .body(this.localizationService.getLocalizedString("status_message_changing_address_in_shipment_editor"))
            .okBtn(this.localizationService.getLocalizedString("yes"))
            .cancelBtn(this.localizationService.getLocalizedString("no"))
            .open()
            .result.then(() => {
              this.loggingService.logDebugData("Clearing new shipment order form.");
              this.selectedSenderAddress = currentAddress;
              this.initialize();
            }, () => {
              // Pokiaľ si neželáme zmenu, tak je potrebné vrátiť kontext pôvodnej adresy
              this._contextService.setCurrentAddress(selectedSenderAddressBackup);
            });
        }
      });
  }

  private observeWizard() {
    this._wizardService.state$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => {
      const displayCustomsSteps = [
        WizardStepName.NewShipmentIntro,
        WizardStepName.NewShipmentRecipient,
        WizardStepName.NewShipmentBaseData1,
        WizardStepName.NewShipmentBaseData2,
        WizardStepName.NewShipmentParcels,
        WizardStepName.NewShipmentCustoms,
        WizardStepName.NewShipmentSave,
      ];

      this.isCustomsDisplayedForWizard = this._wizardService.isOnStep(displayCustomsSteps);
    });
  }


  /**
   * Observes changes of `parcelsForDimensionsEditing`
   * and `invoiceLinesForEditing`. Initializes invoice
   * amount recount.
   */
  initCustomAmountsObserver() {
    merge(
      this.parcelsForDimensionsEditing,
      this.invoiceAdditionalLines
    ).pipe(
      filter(() => this.isCustomClearance)
    ).subscribe(() => this.calculateInvoiceAmount())
  }


  public get isBrexitCountryRecipient() {
    return this.recipientCountryCode === 'GB' && this.isCustomClearance;
  }


  /**
   * Recounts `amount` of invoice.
   * The value is sum of `customsAmounts` of parcels and invoice lines.
   * Called when count of parcels/invoice lines changes or
   * `costumAmount` of a parcel/invoice line changes.
   */
  private calculateInvoiceAmount() {

    // Ak to nie je BREXITová krajina, tak sumu máme ako celok.
    if (!this.isBrexitCountryRecipient || !this.invoice) {
      return;
    }

    const editedParcels = this.parcelsForDimensionsEditing.getValue();
    const editedInvoiceLines = this.invoiceLinesForEditing.getValue();
    const items = [
      ...editedParcels,
      ...editedInvoiceLines
    ];

    // Delay so NgModel controls change the model.
    Promise.resolve().then(() => {
      const sum = items.reduce((acc, item) => acc + Number(item.amountLine), 0);

      this.invoice.amount = sum;
    });
  }

  private setCustomerPersonalizedNotificationTooltip() {
    switch (true) {
      case !this._shipperSettingsService.isCentralShipper && !this.isEmailServerSet && !this.isCustomerPersonalizedNotificationTemplateSet:
        this.customerPersonalizedNotificationTooltipText =
          this.tooltipTexts.setEmailServerAndCustomerPersonalizedNotificationTemplate;
        break;
      case !this._shipperSettingsService.isCentralShipper && !this.isEmailServerSet:
        this.customerPersonalizedNotificationTooltipText =
          this.tooltipTexts.setEmailServer;
        break;
      case !this.isCustomerPersonalizedNotificationTemplateSet:
        this.customerPersonalizedNotificationTooltipText =
          this.tooltipTexts.setCustomerPersonalizedNotificationTemplate;
        break;
      default:
        this.customerPersonalizedNotificationTooltipText = "";
    }
  }

  private initIsRecipientEmailRequiredCrossBorderObserver() {
    merge(
      // this.isCollectionRequestSentToMeChanges$,
      this.selectedProductChanges$,
      this.recipientCountryChanges$,
      this.crRecipientCountryChanges$
    ).pipe(
      auditTime(0),
      map(() => this.isCollectionRequest ? this.collectionRequestRecipient : this.recipient),
      map(address => address.country && address.country.code),
      distinctUntilChanged(),
      mergeMap(countryCode => {
        if (!countryCode) {
          return of(false);
        }

        const isCrossBoarder = countryCode !== this.selectedSenderAddress.countryCode;

        return isCrossBoarder ?
          this._businessUnitSettingsService.getCountrySpecificIsEmailMandatoryForCrossBorder(countryCode) :
          of(false);
      }),
      map(result => result === null ? this.isEmailMandatoryForCrossBorderGeneral : result)
    ).subscribe((isRequired: boolean) => this._isRecipientEmailRequiredCrossBorder = isRequired);
  }

  private initCrRecipientAddressCountryAndZipObserver() {
    merge(
      this.crRecipientCountryChanges$,
      this.crRecipientZipChanges$
    ).pipe(
      auditTime(1000)
    ).subscribe(() => {
      // Emit Unavailable days reload for CR.
      this._unavailableDaysLoad$.next(null);
    });
  }

  // Load unavailable days either from BU Configuration Holidays source or from GeoRouting Non Working Days source.
  // Optionally adjusts current pickupDate to closes valid option.
  private initUnvailableDaysLoadObserver(){
    this._unavailableDaysLoad$.pipe(
      auditTime(500)
    ).subscribe((loadInput) => {
        //console.log('UnavailableDaysLoad emitted a value: ' + loadInput);

        if (loadInput) {
          this._unavailableDaysCurrentMonth = loadInput.month;
          this._unavailableDaysCurrentYear = loadInput.year;
        }
        let year = loadInput?.year ?? this._unavailableDaysCurrentYear;
        let month = loadInput?.month ?? this._unavailableDaysCurrentMonth;
        let pickupDate = loadInput?.pickupDate ?? this.pickupDate;

        if (this._isCollectionRequest){
          this.loadUnavailableDaysFromGeoRoutingSource(year, month, pickupDate);
        } else {
          this.loadUnavailableDaysFromConfigSource(year, month, pickupDate);
        }
      });
  }

  public filteredRecipients: RecipientModel.RecipientForGridView[];
  public filteredZips: ZipCityPair[];
  public filteredCities: CityZipRange[];

  public filterRecipients(event): void {

    let query = event.query;

    let filter: RecipientModel.RecipientsFilter = {
      searchText: query,
      addressId: this.selectedSenderAddress.id,
      customerDetailId: this.selectedSenderAddress.customerDetailId,

      tenantId: null,
      extendedSearch: null,
      customerRecipientTypeCode: null,
      name: null,
      address: null,
      contactInformation: null,
      referenceNumber: null,
      orderByFieldName: null,
      orderAscending: null,
      pageIndex: null,
      pageSize: null
    };

    this._recipientsService.getFilteredRecipients(filter).pipe(
      tap(({ items }) => {
        this.filteredRecipients = items;
      })
    ).subscribe();
  }

  public onRecipientSelected(selected: RecipientModel.Recipient) {
    if (selected) {
      this.setRecipient(selected);
    }
  }

  public filterZip(event): void {
    let query = event.query;
    let countryCode = this.recipient.country ? this.recipient.country.code : '';

    this._skDataConfigService.getCitiesByZip(countryCode, query)
      .subscribe(results => this.filteredZips = results);
  }

  filterPickupPointZip(event): void {
    const query = event.query;
    const countryCode = this.pickupPointCountryCodeValue ? this.pickupPointCountryCodeValue : '';

    this._skDataConfigService.getCitiesByZip(countryCode, query)
      .subscribe(results => this.filteredZips = results);
  }

  filterPickupPointCities(event): void {
    const query = event.query;
    const countryCode = this.pickupPointCountryCodeValue;

    this._skDataConfigService.getZipsByCity(countryCode, query)
      .subscribe(cities => this.filteredCities = cities, () => { });
  }

  filterRecipientCities(event): void {
    const query = event.query;
    const countryCode = this.recipient?.country?.code;

    this._skDataConfigService.getZipsByCity(countryCode, query)
      .subscribe(cities => this.filteredCities = cities, () => { });
  }

  public onDpdApiCustomerChange(customer: DpdApiCustomerEditorData) {
    if (this.isReadOnly ||
        !this.isCustomerService ||
        _.isEqual(customer, this.customerServiceCustomerEditorData)) {
      return;
    }

    this.customerServiceCustomerEditorData = customer;

    // Use obtained customer address for recipient and custom customer address
    // while creating a CR as customer Service.
    // Emulate behavior as if `isCollectionRequestSentToMe` was false and `hasCustomSenderAddress ` true.
    if (this.isCollectionRequest) {
      const address = {
        ...this.getEmptyRecipient(),
        addressId: null,
        name: customer.name,
        name2: customer.name2,
        street: customer.street,
        streetDetail: customer.streetDetail,
        houseNr: customer.houseNr,
        zip: customer.zip,
        city: customer.city,
        countryCode: customer.country?.code,
        phone: customer.phone,
        email: customer.email,
        languageCode: customer.languageCode,
        country: customer.country
      } as RecipientModel.Recipient;

      this.recipient = _.cloneDeep(address);
      this.customSenderAddress = _.cloneDeep(address);
    }
  }

  private setPanelsOrder() {
    let panelsOrder;

    switch (true) {
      case this.isCustomerService:
        panelsOrder = panelsOrders.crCustomerService;
        break;
      case !this.isCollectionRequest && !this.recipientFirstOrderForm:
        panelsOrder = panelsOrders.default;
        break;
      case !this.isCollectionRequest && this.recipientFirstOrderForm:
        panelsOrder = panelsOrders.recipientBeforeBasic;
        break;
      case this.isCollectionRequest && !this.recipientFirstOrderForm:
        panelsOrder = panelsOrders.cr;
        break;
      case this.isCollectionRequest && this.recipientFirstOrderForm:
        panelsOrder = panelsOrders.crRecipientBeforeBasic;
        break;
      default:
        panelsOrder = panelsOrders.default;
    }

    this.panelsOrder = panelsOrder.reduce((acc, curr, idx) => {
      acc[curr] = idx;
      return acc;
    }, {});
  }

  // Creates new date "without" timezone from input date by adding inverse timezone to it so that the original date is not changed when converted to UTC in JSON.stringify.
  // See also fromTimezonelessDate()
  private toTimezonelessDate(value: Date): Date{
    if (!value) {
      return value;
    }
    //console.log('toTimezonelessDate');
    //console.log('Original date: ' + value);
    var userTimezoneOffset = value.getTimezoneOffset() * 60000;

    let adjustedDate = new Date(value.getTime() - userTimezoneOffset);

    //console.log('Adjusted date: ' + adjustedDate);
    return adjustedDate;
  }

   // Creates new date from "timezoneless" input date by adding local timezone to it so that the date is correctly displayed in local timezone.
   // In this way, the given (saved in DB) "timezoneless" date is displayed as the fixed date (and time) no matter the locale the user is currently in.
  private fromTimezonelessDate(value: Date): Date{
    if (!value) {
      return value;
    }
    //console.log('fromTimezonelessDate');
    //console.log('Original date: ' + value);
    var userTimezoneOffset = value.getTimezoneOffset() * 60000;

    let adjustedDate = new Date(value.getTime() + userTimezoneOffset);

    //console.log('Adjusted date: ' + adjustedDate);
    return adjustedDate;
  }
}
