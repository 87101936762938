import { EntityDataBaseInteractive } from "../../shared/models/entity-data-base-interactive.model";

export const ShipmentTypeCodeDocuments = "D";

export interface CheckCustomsObligationsRequestModel {
  senderCountryCode: string;
  senderZipCode: string;
  receiverCountryCode: string;
  receiverZipCode: string;
}

export interface Invoice extends EntityDataBaseInteractive {
  number: string;
  dateOfIssue: Date | string;
  orderId: number;
  amount: number;
  currencyCode: string;
  comment: string;
  amountEx: number;
  currencyCodeEx: string;
  isInvoceAddressDifferent: boolean;
  exportReasonCode: string;
  payerEoriNumber: string;
  payerVatNumber: string;
  sellerEoriNumber: string;
  sellerVatNumber: string;
  mrn: string;
}

export interface InvoiceLine extends EntityDataBaseInteractive {
  parcelId?: number;
  invoiceId: number;
  itemsNumber: number;
  content: string;
  customsTariffNumber: string;
  amountLine: string;
  originCountryCode: string;
  receiverCustomsTariffNumber: string;
  grossWeight: number;
  internalCustomerProductCode: string;
}

export interface CustomClearanceEditorData {
  incoTermCode: string;
  shipmentTypeCode: string;
  clearenceClearedCode: string;
  prealertStatusCode: string;
  accompDocsTypeCodes: string[];
  eoriNumber: string;
  vatNumber: string;
  hasAccompanyingDocs: boolean;
  reasonForExport: string;
  webSite: string;
  hmrcNumber?: string;
}