<div class="top">
  <h1>
    <span *ngIf="isNewShipmentOrder && !isCollectionRequest">{{ 'new_shipment_order' | shpLocalize }}</span>
    <span *ngIf="isNewShipmentOrder && isCollectionRequest">{{ 'new_collection_request_shipment_order' | shpLocalize }}</span>
    <span *ngIf="!isNewShipmentOrder">{{ 'shipment_order' | shpLocalize }}</span>
  </h1>
  <!-- <button *ngIf="isNewShipmentOrder && !isCustomerService" class="top-button" (click)="switchShipmentType()"><span class="ic-switch"></span><span class="label">{{ 'switch_shipment_type' | shpLocalize }} {{ (isCollectionRequest ? 'normal_shipment_type' : 'collection_request_shipment_type') | shpLocalize }}</span></button> -->
  <button class="top-button" *shpPollyDialogTrigger="pollyCollection" id="shipment-editor-faq"><span class="ic-interface-question-mark"></span><span class="label">{{ 'action_open_faq_dialog' | shpLocalize }}</span></button>
  <div class="small-progress-overlay-container">
    <shp-progress-overlay [isActive]="isBusyValidating || hasScheduledValidations"></shp-progress-overlay>
  </div>
</div>
<form class="content-panel shipment" #f="ngForm" name="f" [class.submitted]="f.submitted" (submit)="saveOrder()" novalidate>
  <shp-progress-overlay [isActive]="isBusy" [message]="statusMessage"></shp-progress-overlay>
  <div class="data" cdkScrollable wizardScrollable>
    <div class="panels group">
      <div class="panel message" *ngIf="exception">
        <div class="error">{{ exception | shpExceptionInfo }}</div>
      </div>
      <!-- basic data begin -->
      <div class="panel order-{{panelsOrder.basicData}} shipments__basic-data" [entityValidatorOrder]="panelsOrder.basicData">
        <div wizardTag="shipmentEditorBaseData1">
          <h2>{{ 'shipment_order_base_data' | shpLocalize }}</h2>
          <div class="ctl">
            <label>{{ 'state' | shpLocalize }}</label>
            <span [class.labels-printed]="stateId === 'LabelsPrinted'"
                  [class.ready-for-export]="stateId === 'ReadyForExport'"
                  [class.transport]="stateId === 'Transport' || stateId === 'Ordered' || stateId === 'Delivered' || stateId === 'Cancelled' || stateId === 'CollectionFailure'"
                  class="state">{{ 'order_state_' + stateId | shpLocalize }}</span>
          </div>
          <!-- <div class="ctl" *ngIf="isReadOnly && !isCollectionRequest"> -->
          <div class="ctl" *ngIf="isReadOnly">
            <label for="selected-product-name">{{ 'shipment_order_product' | shpLocalize }}</label>
            <input id="input-selected-product-name" type="text" value="{{ selectedProduct?.name }}" readonly="readonly" />
          </div>
          <!-- <div class="error" *ngIf="productIsUnavailableAtDestination">Selected product is unavailable at the destination.</div> -->
          <div class="error my-2" *ngIf="isProductUnavailableMessageDisplayed">Selected product is unavailable at the destination.</div>

          <!-- <div class="ctl required product-selector" ngModelGroup="productsSelector" #productsSelector="ngModelGroup" *ngIf="!isReadOnly && !isCollectionRequest"> -->
          <div class="ctl required product-selector" ngModelGroup="productsSelector" #productsSelector="ngModelGroup" *ngIf="!isReadOnly">
            <label [class.invalid]="!productsSelector.valid">{{ 'shipment_order_product' | shpLocalize }}</label>
            <div class="radio-group multiline multiple-columns">
              <ng-template ngFor let-product [ngForOf]="products">
                <input *ngIf="product.isAllowed" type="radio" required entity-validation-group="product" name="{{ 'product_' + product.code }}"
                      [(ngModel)]="selectedProductCode" value="{{ product.code }}" (ngModelChange)="isSelectedProductChanged()"
                      [disabled]="recipientFirstOrderForm && !product.isAvailable" id="product_{{ product.code }}" />
                <label *ngIf="product.isAllowed" [attr.for]="'product_' + product.code">{{ product.name }}<ng-container *ngIf="product.description">&nbsp;<span class="ic-interface-information" [pTooltip]="product.description | shpLocalizedPartOfString" appendTo="body" escape="false" tooltipPosition="top"></span></ng-container></label>
              </ng-template>
            </div>
          </div>

          <!-- <div *ngIf="isAdditionalServicesSectionWithinBaseData && !(isCollectionRequest && !isCodForCrAllowed) && !(isDpdPreciseProduct)" class="ctl"> -->
          <div *ngIf="isAdditionalServicesSectionWithinBaseData && !(isDpdPreciseProduct)" class="ctl">
            <label>{{ 'shipment_order_additional_services' | shpLocalize }}</label>
            <shp-shipments-additional-services (selectedServicesChange)="updateSelectedServices()"></shp-shipments-additional-services>
          </div>
        </div>

        <!--<div class="ctl required" ngModelGroup="deliveryWindowSelector" #deliveryWindowSelector="ngModelGroup" *ngIf="!isReadOnly && !isCollectionRequest && selectedProductCode === 'SD'">
          <label [class.invalid]="!deliveryWindowSelector.valid">{{ 'shipment_order_city_service_delivery_windows' | shpLocalize }}</label>
          <div class="radio-group multiline multiple-columns">
            <ng-template ngFor let-deliveryWindow [ngForOf]="cityServiceDeliveryWindows">
              <input type="radio" required name="{{ 'window_' + deliveryWindow.id }}"
                [(ngModel)]="selectedCityServiceDeliveryWindowId" value="{{ 'dw_' + deliveryWindow.id }}"
                id="window_{{ deliveryWindow.id }}"/>
              <label [attr.for]="'window_' + deliveryWindow.id">{{ deliveryWindow.name }}</label>
            </ng-template>
          </div>
        </div>-->
        <div wizardTag="shipmentEditorBaseData2">
          <div class="ctl" *ngIf="!isReadOnly && (isCustomSenderAddressAllowed || selectedProductCode === 'SD') && !isCollectionRequest && !isReturnProductSelected">
            <input id="custom-sender-address-control" name="custom-sender-address-control" type="checkbox" [(ngModel)]="hasCustomSenderAddress" (ngModelChange)="onHasCustomSenderAddressModelChange()"/>
            <label for="custom-sender-address-control">{{ 'custom_sender' | shpLocalize }}</label>
            <!-- <label for="custom-sender-address-control"></label> -->
          </div>

          <div class="ctl" *ngIf="!isReadOnly && isCRAvailable && isSelectedCountryAvaiableForCr && !isCustomerService">
            <input id="collection-request-check-control" name="collection-request-check-control" type="checkbox" [disabled]="!canChangeIsCollectionRequest" [(ngModel)]="isCollectionRequest" />
            <label for="collection-request-check-control">{{ 'collection_request_check_control' | shpLocalize }}</label>
            <!-- <label for="collection-request-check-control"></label> -->
          </div>

          <div *ngIf="isCollectionRequest && !isReadOnly && !isCustomerService && !isDeliveryToPudoProduct" class="ctl">
            <input id="collection-request-send-to-my-pickup-control" name="collection-request-send-to-my-pickup-control" type="checkbox" [(ngModel)]="isCollectionRequestSentToMe" />
            <label for="collection-request-send-to-my-pickup-control">{{ 'collection_request_send_to_my_pickup_control' | shpLocalize }}</label>
            <!-- <label for="collection-request-send-to-my-pickup-control"></label> -->
          </div>

          <div class="ctl" *ngIf="isReadOnly && isCollectionRequest">
            <label>{{ 'collection_request_check_control' | shpLocalize }}</label>
            <span class="check-mark"></span>
          </div>
          <div class="ctl" *ngIf="isReadOnly && isCollectionRequest && isCollectionRequestSentToMe">
            <label>{{ 'collection_request_send_to_my_pickup_control' | shpLocalize }}</label>
            <span class="check-mark"></span>
          </div>

          <!-- send return label email begin  -->
          <ng-container *ngIf="isSendingReturnLabelByEmailEnabled">
            <div *ngIf="!isReadOnly" class="ctl">
              <input id="send-return-label-by-email" name="sendReturnLabelByEmail" type="checkbox" [(ngModel)]="sendReturnLabelByEmail" />
              <label for="send-return-label-by-email">{{ 'send_return_label_by_email' | shpLocalize }}</label>
              <!-- <label for="send-return-label-by-email"></label> -->
            </div>
            <div *ngIf="!isReadOnly && sendReturnLabelByEmail" class="ctl">
              <input id="exclude-return-label-from-label-printing" name="excludeReturnLabelFromLabelPrinting" type="checkbox" [(ngModel)]="excludeReturnLabelFromLabelPrinting" />
              <label for="exclude-return-label-from-label-printing">{{ 'exclude_return_label_from_label_printing' | shpLocalize }}</label>
              <!-- <label for="exclude-return-label-from-label-printing"></label> -->
            </div>

            <div *ngIf="isReadOnly && sendReturnLabelByEmail" class="ctl">
              <label>{{ 'send_return_label_by_email' | shpLocalize }}</label>
              <span class="check-mark"></span>
            </div>
            <div *ngIf="isReadOnly && sendReturnLabelByEmail && excludeReturnLabelFromLabelPrinting" class="ctl">
              <label>{{ 'exclude_return_label_from_label_printing' | shpLocalize }}</label>
              <span class="check-mark"></span>
            </div>
          </ng-container>
          <!-- send return label email end  -->

          <!-- personalized email notification fields begin -->
          <ng-container *ngIf="isCustomerPersonalizedNotificationAllowed">
            <div *ngIf="!isCollectionRequest && !isReturnProductSelected && !isReadOnly" class="ctl">
              <input id="send-customer-personalized-notification" name="sendCustomerPersonalizedNotification" type="checkbox" [(ngModel)]="sendCustomerPersonalizedNotification" [readonly]="isReadOnly" [attr.disabled]="customerPersonalizedNotificationTooltipText || null" />
              <label for="send-customer-personalized-notification"><span [innerHtml]="'order_info_email' | shpLocalize"></span><ng-container *ngIf="customerPersonalizedNotificationTooltipText">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customerPersonalizedNotificationTooltipText | shpLocalize" appendTo="body" escape="false" tooltipPosition="top"></span></ng-container></label>
              <!-- <label for="send-customer-personalized-notification"></label> -->
            </div>
            <div *ngIf="!isCollectionRequest && !isReturnProductSelected && isReadOnly && sendCustomerPersonalizedNotification" class="ctl">
              <label>{{ 'order_info_email' | shpLocalize }}</label>
              <span class="check-mark"></span>
            </div>
            <div *ngIf="sendCustomerPersonalizedNotification" class="ctl">
              <label for="customer-personalized-notification-language-code">{{ 'language' | shpLocalize }}</label>
              <select *ngIf="!isReadOnly; else orderInfoEmailReadOnly" [(ngModel)]="customerPersonalizedNotificationLanguageCode" id="customer-personalized-notification-language-code" name="customerPersonalizedNotificationLanguageCode">
                <option [ngValue]="''">-</option>
                <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
              </select>
              <ng-template #orderInfoEmailReadOnly>
                <input type="text" id="customer-personalized-notification-language-code" [(ngModel)]="customerPersonalizedNotificationLanguageCode" name="customerPersonalizedNotificationLanguageCode" readonly />
              </ng-template>
            </div>
            <div *ngIf="sendCustomerPersonalizedNotification" class="ctl">
              <label for="customer-personalized-notification-recipients">{{ 'email_recipients' | shpLocalize }}</label>
              <input type="text" id="customer-personalized-notification-recipients" [(ngModel)]="customerPersonalizedNotificationRecipients" name="customerPersonalizedNotificationRecipients" [readonly]="isReadOnly" shpValidateMultipleEmail [shpformatOnBlur]="formatCustomerPersonalizedNotificationRecipients" />
            </div>
          </ng-container>
          <!-- personalized email notification fields end -->

          <!-- pick up request start -->
          <ng-container *ngIf="canRequestPickup">
            <div *ngIf="!isFreshProductSelected && !isReadOnly" class="ctl">
              <input id="pickup-requested" name="pickupRequested" type="checkbox" [(ngModel)]="pickupRequested" />
              <label for="pickup-requested">{{ 'shipment_order_pickup_requested' | shpLocalize }}</label>
              <!-- <label for="pickup-requested"></label> -->
            </div>

            <div *ngIf="isFreshProductSelected && !isReadOnly" class="ctl">
              <label for="pickup-requested">{{"shipment_order_pickup_requested" | shpLocalize}}</label>
              <select name="shipment_order_pickup_requested" [(ngModel)]="freshPickupRequested" (ngModelChange)="freshPickupRequestedChanged()" required>
                <option [ngValue]="null"> - </option>
                <option [ngValue]="true">{{ 'yes' | shpLocalize }}</option>
                <option [ngValue]="false">{{ 'no' | shpLocalize }}</option>
              </select>
            </div>

            <div *ngIf="isReadOnly && (isFreshProductSelected ? freshPickupRequested : pickupRequested)" class="ctl">
              <label>{{ 'shipment_order_pickup_requested' | shpLocalize }}</label>
              <span class="check-mark"></span>
            </div>
          </ng-container>

          <div *ngIf="isPickupDateInputVisible" class="ctl" [class.required]="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-calendar *ngIf="!isPickupDateInputDisabled"
                            [(ngModel)]="pickupDate"
                            [showIcon]="true"
                            inputId="timestamp"
                            dateFormat="dd/mm/yy"
                            (onMonthChange)="onPickupMonthChanged($event)"
                            (onShow)="onPickupShow()"
                            [disabled]="isPickupDateInputDisabled"
                            required
                            [disabledDates]="_unavailableDays"
                            [disabledDays]="disabledDays"
                            [maxDate]="!isReadOnly ? (isCollectionRequest ? maxDateCR : null) : null"
                            [minDate]="!isReadOnly ? (isCollectionRequest ? minDateCR : minDate) : null"
                            icon="ic-calendar-1"
                            name="pickupDate"></p-calendar>
                <p-calendar *ngIf="isPickupDateInputDisabled" [(ngModel)]="pickupDate" [showIcon]="false" inputId="timestamp" dateFormat="dd/mm/yy" [disabled]="true" required icon="ic-calendar-1" name="pickupDate"></p-calendar>
                <label for="timestamp">{{ 'shipment_order_pickup_date' | shpLocalize }}</label>
              </span>
            </div>
          </div>
          <!-- pick up request end -->

          <div class="ctl">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <textarea id="note" [(ngModel)]="note" name="note" [readonly]="isReadOnly" pInputTextarea maxlength="70"></textarea>
                <label for="note">{{ 'shipment_order_note' | shpLocalize }}</label>
              </span>
            </div>
          </div>


          <div class="ctl" *ngIf="(isCollectionRequest && isCustomerRefUsedForCollectionRequest || isReturnProductSelected) && !isSophia">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="customerReferenceNr" [(ngModel)]="customerReferenceNr" maxlength="35" type="text" name="customerReferenceNr" pInputText [readonly]="isReadOnly">
                <label for="customerReferenceNr">{{ 'customer_reference_nr' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl" *ngIf="!isCollectionRequest && visibleShipmentReferencesCount > 0">
            <div class="ctl-group-multiline">
              <div class="ctl-group cols-2">
                <div class="p-field p-field--full mr-1" [class.mr-0]="visibleShipmentReferencesCount === 1" *ngIf="visibleShipmentReferencesCount > 0">
                  <span class="p-float-label">
                    <input id="shipment-referential-info1" class="reference" [(ngModel)]="referentialInfo1" [readonly]="isReadOnly" type="text" name="referentialInfo1" maxlength="35" pInputText autocomplete="off">
                    <label for="shipment-referential-info1">{{ 'parcel_referential_info_1' | shpLocalize }}</label>
                  </span>
                </div>

                <div class="p-field p-field--full mr-1" [class.mr-0]="visibleShipmentReferencesCount === 2" *ngIf="visibleShipmentReferencesCount > 1">
                  <span class="p-float-label">
                    <input id="shipment-referential-info2" [(ngModel)]="referentialInfo2" [readonly]="isReadOnly" type="text" name="referentialInfo2" maxlength="35" pInputText autocomplete="off">
                    <label for="shipment-referential-info2">{{ 'parcel_referential_info_2' | shpLocalize }}</label>
                  </span>
                </div>

                <div class="p-field p-field--full mr-1" [class.mr-0]="visibleShipmentReferencesCount === 3" *ngIf="visibleShipmentReferencesCount > 2">
                  <span class="p-float-label">
                    <input id="shipment-referential-info3" [(ngModel)]="referentialInfo3" [readonly]="isReadOnly" type="text" name="referentialInfo3" maxlength="35" pInputText autocomplete="off">
                    <label for="shipment-referential-info3">{{ 'parcel_referential_info_3' | shpLocalize }}</label>
                  </span>
                </div>

                <div class="p-field p-field--full mr-1" [class.mr-0]="visibleShipmentReferencesCount === 4" maxlength="35" *ngIf="visibleShipmentReferencesCount > 3">
                  <span class="p-float-label">
                    <input id="shipment-referential-info4" [(ngModel)]="referentialInfo4" [readonly]="isReadOnly" type="text" name="referentialInfo4" maxlength="35" pInputText autocomplete="off">
                    <label for="shipment-referential-info4">{{ 'parcel_referential_info_4' | shpLocalize }}</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- basic data end -->


      <!-- PANEL BREAK -->


      <!-- collection request - parcel info begin -->
      <div class="panel order-{{panelsOrder.parcelInfo}}" *ngIf="isCollectionRequest" [entityValidatorOrder]="panelsOrder.parcelInfo">
        <h2>{{ 'shipment_order_parcels' | shpLocalize }}</h2>

        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                [(ngModel)]="parcelsCount" [min]="1" [max]="maxParcelsCount > 0 ? maxParcelsCount : null" [step]="1" [showButtons]="true" [maxFractionDigits]="0" [isReadOnly]="isReadOnly || (isBrexitCountryRecipient && brexitParcelsCountReadOnly)"
                name="parcelsCount" inputId="parcels-count" mode="decimal" incrementButtonIcon="ic-add-2" decrementButtonIcon="ic-subtract-2">
              </p-inputNumber>
              <label for="parcels-count">{{ 'shipment_order_parcels_count' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                [(ngModel)]="crWeight" (ngModelChange)="weightChanged()" [min]="0" [max]="999" [maxFractionDigits]="2" [minFractionDigits]="2" [disabled]="isReadOnly"
                name="collection_request_weight" inputId="collection_request_weight" mode="decimal" suffix=" kg" [required]="isDeliveryToPudoProduct">
              </p-inputNumber>
              <label for="collection_request_weight">{{ 'parcel_weight' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="collection-request-info1" [(ngModel)]="crInfo1" type="text" name="collection_request_info1" pInputText [readonly]="isReadOnly">
              <label for="collection-request-info1">{{ 'import_profiles_field_Info1' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="collection-request-info2" [(ngModel)]="crInfo2" type="text" name="collection_request_info2" pInputText [readonly]="isReadOnly">
              <label for="collection-request-info2">{{ 'import_profiles_field_Info2' | shpLocalize }}</label>
            </span>
          </div>
        </div>
      </div>
      <!-- collection request - parcel info end -->


      <!-- PANEL BREAK -->


      <!-- parcel info - (readonly) - begin -->
      <div class="panel order-{{panelsOrder.parcelInfo}} parcels" *ngIf="isReadOnly && !isCollectionRequest && !isReturnProductSelected">
        <h2>{{ 'shipment_order_parcels' | shpLocalize }}</h2>

        <div *ngFor="let parcel of parcels" class="parcel">
          <strong>{{ parcel.nr }}</strong>
          <div class="dimensions">
            <span *ngIf="!hideDimensionEditors || isDpdPreciseProduct"><span title="{{ 'parcel_width' | shpLocalize }}" class="ic-expand-horizontal-1"></span> {{ parcel.width }} cm</span>
            <span *ngIf="!hideDimensionEditors || isDpdPreciseProduct"><span title="{{ 'parcel_height' | shpLocalize }}" class="ic-expand-vertical-1"></span> {{ parcel.height }} cm</span>
            <span *ngIf="!hideDimensionEditors || isDpdPreciseProduct"><span title="{{ 'parcel_length' | shpLocalize }}" class="ic-expand-diagonal-1"></span> {{ parcel.length }} cm</span>
            <span><span title="{{ 'parcel_weight' | shpLocalize }}" class="ic-weight"></span> {{ parcel.weight }} kg</span>
          </div>
          <div class="references">
            <span class="reference" *ngIf="visibleParcelReferencesCount > 0 && parcel.referentialInfo1 && parcel.referentialInfo1.length > 0">{{ parcel.referentialInfo1 }}</span>
            <span class="reference" *ngIf="visibleParcelReferencesCount > 1 && parcel.referentialInfo2 && parcel.referentialInfo2.length > 0">{{ parcel.referentialInfo2 }}</span>
            <span class="reference" *ngIf="visibleParcelReferencesCount > 2 && parcel.referentialInfo3 && parcel.referentialInfo3.length > 0">{{ parcel.referentialInfo3 }}</span>
            <span class="reference" *ngIf="visibleParcelReferencesCount > 3 && parcel.referentialInfo4 && parcel.referentialInfo4.length > 0">{{ parcel.referentialInfo4 }}</span>
          </div>

          <ng-container *ngIf="isFreshProductSelected">
            <div class="references">
              <span class="reference">{{ 'expiration_date' | shpLocalize }}: {{ parcel.limitDate | shpLocalizeDate:'L' }}</span>
            </div>
            <div class="references">
              <span class="reference">{{ 'storage_temparature' | shpLocalize }}: {{ parcel.tempMin }} - {{ parcel.tempMax }} °C</span>
            </div>
            <div *ngIf="parcel.description" class="references">
              <span class="reference">{{ 'description' | shpLocalize }}: {{ parcel.description }}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- parcel info - (readonly) - end -->


      <!-- PANEL BREAK -->


      <!-- parcel info - (edit) - begin -->
      <div class="panel order-{{panelsOrder.parcelInfo}} d-flex flex-direction-column" *ngIf="!isReadOnly && !isCollectionRequest && !isReturnProductSelected" [entityValidatorOrder]="panelsOrder.parcelInfo" wizardTag="shipmentEditorParcels">
        <h2>{{ 'shipment_order_parcels' | shpLocalize }}</h2>

        <div class="ctl mb-3">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                [(ngModel)]="parcelsCount"
                [disabled]="isReadOnly || (isBrexitCountryRecipient && brexitParcelsCountReadOnly)"
                [maxFractionDigits]="0"
                [max]="maxParcelsCount > 0 ? maxParcelsCount : null"
                [min]="1"
                [showButtons]="true"
                [step]="1"
                decrementButtonIcon="ic-subtract-2"
                incrementButtonIcon="ic-add-2"
                inputId="parcels-count"
                mode="decimal"
                name="parcelsCount"
                ></p-inputNumber>
              <label for="parcels-count">{{ 'shipment_order_parcels_count' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <shp-shipments-editor-parcel-references></shp-shipments-editor-parcel-references>

        <ng-container *ngIf="!(isBrexitCountryRecipient && brexitParcelsCountReadOnly)">
          <div *ngIf="!isReadOnly" class="ctl">
            <input id="all-parcel-same-dimensions" name="allParcelsHaveSameDimensions" type="checkbox" [(ngModel)]="allParcelsHaveSameDimensions" (ngModelChange)="weightChanged()"/>
            <label for="all-parcel-same-dimensions">{{ 'shipment_order_all_parcels_have_same_dimensions' | shpLocalize }}</label>
          </div>

          <div *ngIf="isReadOnly && allParcelsHaveSameDimensions">
            <label class="w-100">{{ 'shipment_order_all_parcels_have_same_dimensions' | shpLocalize }} <span class="check-mark"></span></label>
          </div>
        </ng-container>

        <shp-shipment-editor-parcels
          [areDimensionEditorsMandatory]="areDimensionEditorsMandatory"
          [countries]="countriesWithEmpty"
          [customsAmountCurrencyCode]="invoice?.currencyCode"
          [hideDimensionEditors]="hideDimensionEditors"
          [isBrexitCountryRecipient]="(isBrexitCountryRecipient && brexitParcelsCountReadOnly)"
          [isCustomsClearance]="false"
          [isDpdPreciseProduct]="isDpdPreciseProduct"
          [isParcelLengthFirstDimension]="isParcelLengthFirstDimension"
          [isReadOnly]="isReadOnly"
          [isSameDimensionsForAllParcels]="allParcelsHaveSameDimensions"
          [minLimitDate]="defaultMinLimitData"
          [parcels]="parcelsForDimensionsEditing"
          [recipientCountryCode]="recipientCountryCode"
          [selectedProduct]="selectedProduct"
          [senderCountryCode]="senderCountryCode"
          [showGoodsFields]="isFreshProductSelected"
          [tooltips]="customsClearanceTooltips"
          (parcelCustomsAmountChange)="calculateInvoiceAmount()"
          (parcelWeightChange)="weightChanged()"
          #parcelsEditor="shipmentEditorParcels"></shp-shipment-editor-parcels>
        <div *ngIf="isCustomClearance" class="mt-auto cursor-pointer" [shpScrollToElement]="getCustomsClearanceElement"><span class="ic-navigation-down-3 text-highlight"></span>&nbsp;{{ 'add_customs_data_and_parcel_contents' | shpLocalize }}</div>
      </div>
      <!-- parcel info - (edit) - end -->


      <!-- PANEL BREAK -->


      <!-- delivery to pudo begin -->
      <div class="panel order-{{panelsOrder.recipient}}" *ngIf="isDeliveryToPudoProduct && !isReturnProductSelected" [entityValidatorOrder]="panelsOrder.recipient">
        <h2>{{ 'shipment_order_pudo_and_recipient' | shpLocalize }}</h2>
        <h3>{{ 'recipient' | shpLocalize }} <button id="button-recipient-clear" *ngIf="!isReadOnly" type="button" class="clear-recipient-buttom" (click)="clearRecipient()"><span class="ic-close"></span></button></h3>
        <div class="mdl-grid compact" entityValidatorPrefix="recipient_">
          <div class="ctl required mdl-cell mdl-cell--12-col" *ngIf="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-autoComplete id="recipient-name" [(ngModel)]="recipient.name" [suggestions]="filteredRecipients" (completeMethod)="filterRecipients($event)" (onSelect)="onRecipientSelected($event)" [minLength]="4" autocomplete="none" name="recipient_recipient_name" required>
                  <ng-template let-recipient pTemplate="item"><div class="recipient-item"><div><b>{{recipient.name}}</b></div><div><small>{{recipient.details}}</small></div></div></ng-template>
                </p-autoComplete>
                <label for="recipient-name">{{ (recipient.customerRecipientTypeCode == b2c ? 'recipient_person_name' : 'recipient_organization_name') | shpLocalize }}</label>
              </span>
            </div>
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="isReadOnly">
            <input type="text" [(ngModel)]="recipient.name" id="recipient-name" name="recipient_recipient_name" readonly="readonly" />
          </div>
          <div class="ctl mdl-cell mdl-cell--8-col" [class.required]="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="recipient-street" [(ngModel)]="recipient.street" [pattern]="streetRegEx" [formControl]="pudoRecipientAddressStreet" type="text" name="recipient_recipient_street" maxlength="35" pInputText [readonly]="isReadOnly" required autocomplete="off">
                <label for="recipient-street">{{ 'recipient_street' | shpLocalize }}</label>
              </span>
            </div>
          </div>
          <div class="ctl mdl-cell mdl-cell--4-col" *ngIf="!isReadOnly || recipient.houseNr">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="recipient-house-nr" [(ngModel)]="recipient.houseNr" [formControl]="pudoRecipientAddressHouseNr" type="text" name="recipient_recipient_house_nr" maxlength="8" pInputText [readonly]="isReadOnly" autocomplete="off">
                <label for="recipient-house-nr">{{ 'recipient_house_number' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--8-col" *ngIf="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-dropdown id="recipient-country" [options]="countries" [(ngModel)]="recipient.country" [formControl]="addressCountry" name="recipient_recipient_country"
                            optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" required>
                </p-dropdown>
                <label for="recipient-country">{{ 'recipient_country' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--4-col" *ngIf="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <select [(ngModel)]="recipient.languageCode" id="recipient-language-code" name="recipient_recipient_language_code">
                  <option [ngValue]="''">-</option>
                  <option *ngFor="let language of languages" [ngValue]="language">{{ ('language_' + language) | shpLocalize }} ({{ language }})</option>
                </select>
                <label for="recipient-language-code">{{ 'language' | shpLocalize }}</label>
              </span>
            </div>
          </div>


          <div class="ctl mdl-cell mdl-cell--8-col" *ngIf="isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="recipient-country" [(ngModel)]="recipient.country.name" [formControl]="pudoRecipientAddressHouseNr" type="text" name="recipient_recipient_country" pInputText readonly="readonly" autocomplete="off">
                <label for="recipient-country">{{ 'recipient_house_number' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--4-col" *ngIf="isReadOnly">
            <input type="text" value="{{ ('language_' + recipient.languageCode) | shpLocalize }}  ({{ recipient.languageCode }})" readonly="readonly" id="recipient-language-code" name="recipient_recipient_language_code" />
          </div>

          <div class="ctl mdl-cell mdl-cell--5-col" *ngIf="isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="recipient-zip" [(ngModel)]="recipient.zip" [formControl]="pudoRecipientAddressHouseNr" type="text" name="recipient_recipient_zip" maxlength="14" pInputText [readonly]="true" autocomplete="off">
                <label for="recipient-zip">{{ 'recipient_zip' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--5-col" [class.required]="!isReadOnly" *ngIf="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-autoComplete id="recipient-zip" [(ngModel)]="recipient.zip" [formControl]="addressZip" [suggestions]="filteredZips" (completeMethod)="filterZip($event)" (onSelect)="zipCityPairSelected($event)" name="recipient_recipient_zip" [minLength]="1" autocomplete="none" required>
                  <ng-template let-zip pTemplate="item"><div class="zip-item"><div><b>{{zip.zip}}</b></div><div><small>{{zip.cityName}}</small></div></div></ng-template>
                </p-autoComplete>
                <label for="recipient-zip">{{ 'recipient_zip' | shpLocalize }}</label>
              </span>
              <em class="error" *ngIf="addressZip.hasError('zip')">{{ 'error_zip_invalid' | shpLocalize }}</em>
              <em class="error" *ngIf="addressZip.hasError('zipFormat')">{{ 'error_zip_expected_format' | shpLocalize:addressZip.errors.zipFormat.expectedFormat }}</em>
              <em class="error" *ngIf="addressZip.hasError('noSpaces')">{{'address_zip_cannot_contain_spaces' | shpLocalize }}</em>
              <em class="error" *ngFor="let warning of zipWarningsAddress">{{ warning }}</em>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--7-col" [class.required]="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input *ngIf="isReadOnly" id="recipient-city" [(ngModel)]="recipient.city" [formControl]="addressCity" type="text" name="recipient_recipient_city" maxlength="35" pInputText [readonly]="isReadOnly" required autocomplete="off">

                <p-autoComplete
                  *ngIf="!isReadOnly"
                  (completeMethod)="filterRecipientCities($event)"
                  (onSelect)="onCitySelected($event)"
                  [(ngModel)]="recipient.city"
                  [attr.name]="recipient_recipient_city"
                  [formControl]="addressCity"
                  [minLength]="2"
                  [suggestions]="filteredCities"
                  autocomplete="none"
                  id="recipient-city">
                  <ng-template let-city pTemplate="item"><div class="city-item"><div><b>{{city.cityNameLocal}}</b></div><div><small>{{city.beginPostCode}}{{city.endPostCode? " - " + city.endPostCode:""}}</small></div></div></ng-template>
                </p-autoComplete>

                <label for="recipient-city">{{ 'recipient_city' | shpLocalize }}</label>
              </span>
            </div>
          </div>
          <div class="ctl required mdl-cell mdl-cell--12-col" *ngIf="recipient?.country?.isStateDeliveryRequired">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="recipient-state" [(ngModel)]="recipient.stateCode" type="text" name="recipient_recipient_state" maxlength="2" pInputText [readonly]="isReadOnly" required autocomplete="off">
                <label for="recipient-state">{{ 'recipient_state_code' | shpLocalize }}</label>
              </span>
            </div>
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col" [class.required]="isRecipientEmailRequired">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="recipient-email" [(ngModel)]="recipient.email" [required]="isRecipientEmailRequired" type="text" shpValidateEmail name="recipient_recipient_email" maxlength="100" pInputText [readonly]="isReadOnly" autocomplete="off">
                <label for="recipient-email">{{ 'recipient_email' | shpLocalize }}</label>
              </span>
              <em class="error" *ngIf="f.controls.recipient_email && f.controls.recipient_email.touched && f.controls.recipient_email.errors && f.controls.recipient_email.errors.invalidEmail">{{ 'invalid_email' | shpLocalize }}</em>
            </div>
          </div>
          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="isReadOnly">
            <input type="text" [(ngModel)]="recipient.phone" maxlength="35" id="recipient-phone" [readonly]="isReadOnly" name="recipient_recipient_phone" placeholder="{{ 'recipient_phone' | shpLocalize }}" />
          </div>

          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="!isReadOnly">
            <shp-phone-number-input [(ngModel)]="recipient.phone" id="recipient-phone" maxlength="30" #phoneNumberCtrl="ngModel" #phoneNumberInput="phoneNumberInput" shpValidatePhone [countryCode]="recipient.country?.code" [phoneRegionCode]="phoneNumberInput.prefix" [defaultCountryCode]="settings.country_code.value" name="recipient_recipient_phone"></shp-phone-number-input>
            <em class="error" *ngIf="phoneNumberCtrl.touched && phoneNumberCtrl.errors?.wrongNumber">{{ 'error_invalid_phone_for_selected_region' | shpLocalize }}</em>
          </div>

          <div class="ctl mdl-cell mdl-cell--12-col">
            <input id="save-address-to-address-book-checkbox" type="checkbox" [(ngModel)]="isSaveAddressChecked" name="isSaveAddressChecked">
            <label for="save-address-to-address-book-checkbox">{{ 'save_address_to_address_book' | shpLocalize }}</label>
          </div>

        </div>
        <h3>
          {{ 'pudo' | shpLocalize }} <button id="button-pudo-sync" *ngIf="!isReadOnly" type="button" class="clear-recipient-buttom" (click)="copyRecipientDataToPudoFilter()"><span class="ic-arrow-down"></span></button>
          <button id="button-pudo-clear" *ngIf="!isReadOnly" type="button" class="clear-recipient-buttom" (click)="clearPudo()"><span class="ic-close"></span></button>
        </h3>
        <div class="mdl-grid compact" *ngIf="!isReadOnly">
          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-dropdown id="pickup-point-country" [options]="countries" [(ngModel)]="pickupPointCountryCodeValue" [formControl]="pickupPointCountry" name="pudo_country"
                            optionValue="code" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" required>
                </p-dropdown>
                <label for="pickup-point-country">{{ 'recipient_country' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--12-col">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="pickup-point-street" [(ngModel)]="pickupPointStreetValue" [pattern]="streetRegEx" maxlength="43" [formControl]="pickupPointStreet" type="text" name="pudo_street" pInputText autocomplete="off">
                <label for="pickup-point-street">{{ 'recipient_street' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--5-col" *ngIf="isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="pickup-point-zip" [(ngModel)]="pickupPointZipValue" maxlength="14" [formControl]="pickupPointZip" type="text" name="pudo_zip" pInputText autocomplete="off">
                <label for="pickup-point-zip">{{ 'recipient_zip' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--5-col" [class.required]="pickupPointCountryCodeValue != 'IE'" *ngIf="!isReadOnly">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <p-autoComplete id="pickup-point-zip" [(ngModel)]="pickupPointZipValue" [formControl]="pickupPointZip" [suggestions]="filteredZips" (completeMethod)="filterPickupPointZip($event)" (onSelect)="pickupPountZipCityPairSelected($event)" name="pudo_zip" [minLength]="1" autocomplete="none" required>
                  <ng-template let-zip pTemplate="item"><div class="zip-item"><div><b>{{zip.zip}}</b></div><div><small>{{zip.cityName}}</small></div></div></ng-template>
                </p-autoComplete>
                <label for="pickup-point-zip">{{ 'recipient_zip' | shpLocalize }}</label>
              </span>
              <em class="error" *ngIf="pickupPointZip.hasError('zip')">{{ 'error_zip_invalid' | shpLocalize }}</em>
              <em class="error" *ngIf="pickupPointZip.hasError('zipFormat')">{{ 'error_zip_expected_format' | shpLocalize:pickupPointZip.errors.zipFormat.expectedFormat }}</em>
              <em class="error" *ngIf="pickupPointZip.hasError('noSpaces')">{{'address_zip_cannot_contain_spaces' | shpLocalize }}</em>
              <em class="error" *ngFor="let warning of zipWarningsPickupPoint">{{ warning }}</em>
          </div>
        </div>


          <div class="ctl mdl-cell mdl-cell--7-col required">
            <div class="p-field p-field--full">
              <span class="p-float-label">
                <input *ngIf="isReadOnly" id="pickup-point-city" [(ngModel)]="pickupPointCityValue" maxlength="35" [formControl]="pickupPointCity" type="text" name="pudo_city" pInputText required autocomplete="off">

                <p-autoComplete
                  *ngIf="!isReadOnly"
                  (completeMethod)="filterPickupPointCities($event)"
                  (onSelect)="onPickupPlaceCitySelected($event)"
                  [(ngModel)]="pickupPointCityValue"
                  [attr.name]="recipient_recipient_city"
                  [formControl]="pickupPointCity"
                  [minLength]="2"
                  [suggestions]="filteredCities"
                  autocomplete="none"
                  id="recipient-city">
                  <ng-template let-city pTemplate="item"><div class="city-item"><div><b>{{city.cityNameLocal}}</b></div><div><small>{{city.beginPostCode}}{{city.endPostCode? " - " + city.endPostCode:""}}</small></div></div></ng-template>
                </p-autoComplete>

                <label for="pickup-point-city">{{ 'recipient_city' | shpLocalize }}</label>
              </span>
            </div>
          </div>

          <div class="ctl mdl-cell mdl-cell--12-col">
              <div class="p-field p-field--full">
              <span class="p-float-label">
                <input id="pickup-point-id" [(ngModel)]="pickupPointIdValue" maxlength="7" [formControl]="pickupPointId" type="text" name="pudo_id" pInputText autocomplete="off">
                <label for="pickup-point-id">ID</label>
              </span>
            </div>
          </div>

          <div class="ctl required select mdl-cell mdl-cell--12-col" *ngIf="canSelectPickupPoint && !pickupPointsLoadingFailed && !noPudosFound">
            <select name="pudo_selected_pudo" required id="selected-parcel-shop" [(ngModel)]="selectedPudo">
              <option *ngFor="let pudo of pickupPoints | async" [ngValue]="pudo">{{ pudo.street }}, {{ pudo.city}}, {{ pudo.countryCode }}-{{ pudo.zip }} ({{ pudo.name }}) - {{ pudo.distance }} m</option>
            </select>
          </div>

          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="noPudosFound">
            {{ 'no_pudos_found' | shpLocalize }}
          </div>

          <div class="ctl mdl-cell mdl-cell--12-col" *ngIf="!canSelectPickupPoint">
            {{ 'pudo_required_parameters_missing' | shpLocalize }}
          </div>

          <div class="mdl-cell mdl-cell--12-col" *ngIf="pickupPointsLoadingFailed">
            {{ 'pudos_loading_failed' | shpLocalize }} <button id="button-reload-pickup-points" type="button" (click)="reloadPickupPoints()">{{ 'retry' | shpLocalize }}</button>
          </div>
        </div>
        <div class="mdl-grid compact" *ngIf="isReadOnly && selectedPudo">
          <div class="ctl mdl-cell mdl-cell--12-col">
            <input id="input-name-street-zip-city" type="text" readonly value="{{ selectedPudo.name }}, {{ selectedPudo.street }}, {{ selectedPudo.zip }} {{ selectedPudo.city }}" />
          </div>
        </div>
      </div>
      <!-- delivery to pudo end -->


      <!-- PANEL BREAK -->


      <!-- custom sender begin -->
      <shp-recipient-view class="panel order-{{panelsOrder.customSender}}" *ngIf="hasCustomSenderAddress && !!customSenderAddress && !isCustomerService"
                          [entityValidatorOrder]="panelsOrder.customSender"
                          [isReadOnly]="isReadOnly"
                          [countries]="countries"
                          [name]="'custom_sender'"
                          [hideFieldsIrrelevantForSender]="true"
                          [isPresentedAsSender]="true"
                          [recipient]="customSenderAddress"
                          [recipientsDataService]="recipientsDataService2"
                          [hideFieldsIrrelevantForPickup]="false"
                          [settings]="settings"
                          [selectedProduct]="selectedProduct"
                          [suspendRoutingValidation]="_suspendRoutingValidation"
                          [citiesCountry]="customSenderAddress.country"
                          [languages]="languages"
                          [useB2BAddressFieldsOnly]="true"
                          (setProductsAndAdditionalServicesAvailability)="onCustomerSenderAddressChange()"
                          [zipIsInvalid]="customSenderZipIsInvalid"
                          [cityIsInvalid]="customSenderCityIsInvalid"
                          [isSpecialOrder]="isSpecialOrderInRecipientView"
                          [streetRegEx]="streetRegEx">
      </shp-recipient-view>
      <!-- custom sender end -->


      <!-- PANEL BREAK -->


      <!-- return product - returnSender - begin -->
      <div class="panel order-{{panelsOrder.returnSender}}" *ngIf="!isCollectionRequest && isReturnProductSelected && !!returnSender" [entityValidatorOrder]="panelsOrder.returnSender">
        <h2>{{ 'shipment_order_parcels' | shpLocalize }}</h2>

        <div class="ctl mb-3">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber [(ngModel)]="parcelsCount" [min]="1" [max]="maxParcelsCount > 0 ? maxParcelsCount : null" [step]="1" [showButtons]="true" [maxFractionDigits]="0" [isReadOnly]="isReadOnly || isBrexitCountryRecipient"
                name="parcelsCount" inputId="parcels-count"
                mode="decimal"
                decrementButtonClass="p-button-secondary"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="ic-add-2"
                decrementButtonIcon="ic-subtract-2"
              ></p-inputNumber>
              <label for="parcels-count">{{ 'shipment_order_parcels_count' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <ng-container *ngIf="isParcelReferenceDisplayedInReturnRequestPanel">
          <shp-shipments-editor-parcel-references class="mb-4"></shp-shipments-editor-parcel-references>
        </ng-container>

        <shp-recipient-view [isReadOnly]="isReadOnly"
                            [countries]="countries"
                            [name]="'return_sender'"
                            [recipient]="returnSender"
                            [recipientsDataService]="recipientsDataService2"
                            [hideFieldsIrrelevantForPickup]="true"
                            [settings]="settings"
                            [suspendRoutingValidation]="_suspendRoutingValidation"
                            [citiesCountry]="returnSender.country"
                            [languages]="languages"
                            [useB2BAddressFieldsOnly]="useB2BAddressFieldsOnly"
                            (setProductsAndAdditionalServicesAvailability)="validateReturnSenderZipAndCity()"
                            [zipIsInvalid]="returnSenderZipIsInvalid"
                            [cityIsInvalid]="returnSenderCityIsInvalid"
                            [streetRegEx]="streetRegEx"
                            [isSpecialOrder]="isSpecialOrderInRecipientView">
        </shp-recipient-view>
      </div>
      <!-- return product - returnSender - end -->


      <!-- PANEL BREAK -->


      <!-- collection request - collectionRequestRecipient - CR sender/pickup address - begin -->
      <shp-recipient-view class="panel order-{{panelsOrder.crSender}}" *ngIf="isCollectionRequest && collectionRequestRecipient"
                          [entityValidatorOrder]="panelsOrder.crSender"
                          [isReadOnly]="isReadOnly"
                          [countries]="crAvaiableCountries"
                          [name]="isCustomerService ? 'pickup_address' : 'collection_request_recipient'"
                          [recipient]="collectionRequestRecipient"
                          (recipientAddressCountryChange)="onCrRecipientAddressCountryChange($event)"
                          (recipientZipChange)="onCrRecipientZipChange($event)"
                          [recipientsDataService]="recipientsDataService2"
                          [hideFieldsIrrelevantForPickup]="true"
                          [settings]="settings"
                          [suspendRoutingValidation]="_suspendRoutingValidation"
                          [citiesCountry]="collectionRequestRecipient.country"
                          [languages]="languages"
                          [useB2BAddressFieldsOnly]="useB2BAddressFieldsOnly"
                          (setProductsAndAdditionalServicesAvailability)="validateCrSenderZipAndCity()"
                          [zipIsInvalid]="crSenderZipIsInvalid"
                          [cityIsInvalid]="crSenderCityIsInvalid"
                          [isSaveAddressCheckboxDisplayed]="true"
                          [isEmailOrPhoneRequired]="isCrSenderPhoneNumberOrEmailRequired"
                          [isRecipientEmailRequired]="isRecipientEmailRequired || isCrSenderEmailRequired"
                          isCollectionRequest="true"
                          [(isSaveAddressChecked)]="isSaveAddressChecked"
                          [isRecipientPhoneRequired]="isCrSenderPhoneNumberRequired"
                          [isSpecialOrder]="isSpecialOrderInRecipientView"
                          [streetRegEx]="streetRegEx">
      </shp-recipient-view>
      <!-- collection request - collectionRequestRecipient - CR sender/pickup address - end -->


      <!-- PANEL BREAK -->


      <!-- recipient - begin -->
      <shp-recipient-view class="panel order-{{panelsOrder.recipient}}" *ngIf="recipient && (!isCollectionRequest && (recipientFirstOrderForm || isDeliveryToHomeAddressProduct || isDeliveryToBusinessAddressProduct) && !isDeliveryToPudoProduct)"
                          wizardTag="shipmentEditorRecipient"
                          [entityValidatorOrder]="panelsOrder.recipient"
                          [name]="'shipment_order_recipient'"
                          [recipient]="recipient"
                          (recipientAddressCountryChange)="onRecipientAddressCountryChange($event)"
                          [countries]="countries"
                          [isReadOnly]="isReadOnly"
                          [suspendRoutingValidation]="_suspendRoutingValidation"
                          (setProductsAndAdditionalServicesAvailability)="recipientAddressChanged()"
                          [selectedProduct]="selectedProduct"
                          [recipientsDataService]="recipientsDataService"
                          [settings]="settings"
                          [zipIsInvalid]="zipIsInvalid"
                          [cityIsInvalid]="cityIsInvalid"
                          [languages]="languages"
                          [isCustomsClearance]="isCustomClearance"
                          [isBrexitCountryRecipient]="isBrexitCountryRecipient"
                          [useB2BAddressFieldsOnly]="useB2BAddressFieldsOnly"
                          [recipientType]="recipientType"
                          [isRecipientEmailRequired]="isRecipientEmailRequired"
                          [isSaveAddressCheckboxDisplayed]="true"
                          [(isSaveAddressChecked)]="isSaveAddressChecked"
                          [streetRegEx]="streetRegEx"
                          [isSpecialOrder]="isSpecialOrderInRecipientView"
                          [isCustomsClearanceBoundFieldsRequiredValidationOn]="isCustomsClearanceBoundFieldsRequiredValidationOn">
      </shp-recipient-view>
      <!-- recipient - begin -->


      <!-- PANEL BREAK -->


      <!-- DPD API customer address search begin -->
      <div *ngIf="isCustomerService" class="panel order-{{panelsOrder.apiCustomer}}" [entityValidatorOrder]="panelsOrder.apiCustomer">
        <shp-dpd-api-customer
          [isReadOnly]="isReadOnly"
          [countries]="crAvaiableCountries"
          [languages]="languages"
          [settings]="settings"
          [customer]="customerServiceCustomerEditorData"
          (customerChange)="onDpdApiCustomerChange($event)"></shp-dpd-api-customer>
      </div>
      <!-- DPD API customer address search end -->


      <!-- PANEL BREAK -->


      <!-- collection request (!isCollectionRequestSentToMe) - recipient - begin -->
      <shp-recipient-view class="panel order-{{panelsOrder.recipient}}" *ngIf="isCollectionRequest && recipient && !isCollectionRequestSentToMe && !isDeliveryToPudoProduct"
                          [entityValidatorOrder]="panelsOrder.recipient"
                          [name]="isCustomerService ? 'receiver_address' : 'shipment_order_recipient'"
                          [recipient]="recipient"
                          (recipientAddressCountryChange)="onRecipientAddressCountryChange($event)"
                          [countries]="crAvaiableCountries"
                          [isReadOnly]="isReadOnly"
                          [suspendRoutingValidation]="_suspendRoutingValidation"
                          (setProductsAndAdditionalServicesAvailability)="validateCrRecipientZipAndCity()"
                          [selectedProduct]="selectedProduct"
                          [recipientsDataService]="recipientsDataService"
                          [settings]="settings"
                          [zipIsInvalid]="crRecipientZipIsInvalid"
                          [cityIsInvalid]="crRecipientCityIsInvalid"
                          [languages]="languages"
                          [useB2BAddressFieldsOnly]="useB2BAddressFieldsOnly"
                          [isRecipientEmailRequired]="isRecipientEmailRequired"
                          [isBrexitCountryRecipient]="isBrexitCountryRecipient"
                          [isEmailOrPhoneRequired]="isCrRecipientPhoneNumberOrEmailRequired"
                          [isCollectionRequest]="true"
                          [isSpecialOrder]="isSpecialOrderInRecipientView"
                          [streetRegEx]="streetRegEx">
      </shp-recipient-view>
      <!-- collection request (!isCollectionRequestSentToMe) - recipient - end -->


      <!-- PANEL BREAK -->


      <!-- collection request (isCollectionRequestSentToMe) - dummyMeRecipient - begin -->
      <shp-recipient-view class="panel order-{{panelsOrder.recipient}}" *ngIf="isCollectionRequest && dummyMeRecipient && isCollectionRequestSentToMe && !isDeliveryToPudoProduct"
                          [entityValidatorOrder]="panelsOrder.recipient"
                          [name]="'shipment_order_recipient'"
                          [recipient]="dummyMeRecipient"
                          [countries]="crAvaiableCountries"
                          [isReadOnly]="true"
                          [suspendRoutingValidation]="_suspendRoutingValidation"
                          [selectedProduct]="selectedProduct"
                          [settings]="settings"
                          [languages]="languages"
                          [useB2BAddressFieldsOnly]="true"
                          [disableValidation]="true"
                          [streetRegEx]="streetRegEx"
                          [isRecipientEmailRequired]="isRecipientEmailRequired"
                          [isEmailOrPhoneRequired]="isCrRecipientPhoneNumberOrEmailRequired"
                          [isSpecialOrder]="false">
      </shp-recipient-view>
      <!-- collection request (isCollectionRequestSentToMe) - dummyMeRecipient - end -->


      <!-- PANEL BREAK -->


      <!-- customs clearance miniportal link - begin -->
      <shp-custom-clearance-miniportal-view class="panel order-{{panelsOrder.customsClearance}}" *ngIf="!isCollectionRequest && isCustomClearance && showMiniportalLink" [shipmentOrderId]="shipmentOrderId" #customClearanceMiniportalView>

      </shp-custom-clearance-miniportal-view>
      <!-- customs clearance miniportal link - end -->


      <!-- PANEL BREAK -->


      <!-- customs clearance - begin -->
      <shp-custom-clearance-view class="panel order-{{panelsOrder.customsClearance}}" *ngIf="(!isCollectionRequest && isCustomClearance && !showMiniportalLink && !useMiniportalIntegration) || isCustomsDisplayedForWizard" #customClearanceView
                                 wizardTag="shipmentEditorCustoms"
                                 [entityValidatorOrder]="panelsOrder.customsClearance"
                                 [name]="'shipment_order_custom_clearance'"
                                 [customClearanceEditorData]="customClearanceEditorData"
                                 [invoice]="invoice"
                                 [(invoiceAdditionalLines)]="invoiceAdditionalLines"
                                 [(customSenderInvoiceAddress)]="customSenderInvoiceAddress"
                                 [(customReceiverInvoiceAddress)]="customReceiverInvoiceAddress"
                                 [recipientCurrencyCode]="recipientCurrencyCode"
                                 [recipientCountryCode]="recipientCountryCode"
                                 [countries]="countries"
                                 [isReadOnly]="isReadOnly"
                                 [shipmentTypes]="shipmentTypes"
                                 [clearanceCleareds]="clearanceCleareds"
                                 [prealertStatuses]="prealertStatuses"
                                 [incoTerms]="incoTerms"
                                 [currencies]="currencies"
                                 [accompanyingDocsTypes]="accompanyingDocsTypes"
                                 [disableClearanceCleared]="disableClearanceCleared"
                                 [disablePrealertStatus]="disablePrealertStatus"
                                 [hideClearanceCleared]="hideClearanceCleared"
                                 [hidePrealertStatus]="hidePrealertStatus"
                                 [isInvoiceNumberRequired]="isInvoiceNumberRequired"
                                 [isDateOfIssueRequired]="isDateOfIssueRequired"
                                 [recipientType]="recipientType"
                                 [tooltips]="customsClearanceTooltips"
                                 [isRequiredValidationOn]="isCustomsClearanceRequiredValidationOn"
                                 [isExportMrnFieldVisible]="isCustomClearanceExportMrnFieldVisible"
                                 [senderCountryCode]="senderCountryCode"
                                 (shipmentTypeChange)="onShipmentTypeChange()">
      </shp-custom-clearance-view>
      <!-- customs clearance - end -->


      <!-- PANEL BREAK -->


      <!-- customs clearance - parcel content panel - (edit) - begin -->
      <div class="panel order-{{panelsOrder.customsClearanceContent}}" *ngIf="(!isReadOnly && isCustomClearance && !showMiniportalLink && !useMiniportalIntegration && !isCollectionRequest && !isReturnProductSelected && selectedShipmentTypeCode !== ShipmentTypeCodeDocuments) || isCustomsDisplayedForWizard" [entityValidatorOrder]="panelsOrder.customsClearanceContent" wizardTag="shipmentEditorCustomsParcels">
        <h2>{{ 'parcel_contents' | shpLocalize }}</h2>

        <shp-shipment-editor-parcels
          [countries]="countriesWithEmpty"
          [customsAmountCurrencyCode]="invoice?.currencyCode"
          [hideDimensionEditors]="true"
          [isBrexitCountryRecipient]="isBrexitCountryRecipient"
          [isCustomsClearance]="isCustomClearance && selectedShipmentTypeCode !== ShipmentTypeCodeDocuments"
          [isDpdPreciseProduct]="isDpdPreciseProduct"
          [isParcelLengthFirstDimension]="isParcelLengthFirstDimension"
          [isReadOnly]="isReadOnly"
          [isSameDimensionsForAllParcels]="true"
          [parcels]="parcelsForDimensionsEditing"
          [recipientCountryCode]="recipientCountryCode"
          [senderCountryCode]="senderCountryCode"
          [showGoodsFields]="false"
          [showLabels]="false"
          [tooltips]="customsClearanceTooltips"
          [isCustomsClearanceBoundFieldsRequiredValidationOn]="isCustomsClearanceBoundFieldsRequiredValidationOn"
          [buCode]="buCode"
          (parcelCustomsAmountChange)="calculateInvoiceAmount()"
          (parcelWeightChange)="weightChanged()"
          #parcelsEditor="shipmentEditorParcels"></shp-shipment-editor-parcels>

        <h3>{{ 'additional_invoice_lines' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.additionalInvoiceLines">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.additionalInvoiceLines"></span></ng-container></h3>
        <!-- <h3 *ngIf="(invoiceLinesForEditing | async)?.length">{{ 'additional_invoice_lines' | shpLocalize }}</h3> -->

        <ng-container ngModelGroup="invoiceLines">
          <div *ngIf="isCustomClearance && selectedShipmentTypeCode !== ShipmentTypeCodeDocuments && (invoiceLinesForEditing | async)?.length" entityValidatorArray>
            <div *ngFor="let invoiceLine of invoiceLinesForEditing | async; index as i; first as isFirst;" class="shipment-editor__invoice-line mb-4">
              <div class="shipment-editor__invoice-line__index"><span>{{ (i + 1) }}</span></div>
              <div class="w-100">
                <div class="ctl">
                  <div class="d-flex w-100">
                    <div class="ctl flex-grow-1 mb-0" [class.required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient">
                      <div class="p-field p-field--full">
                        <span class="p-float-label">
                          <input id="invoice-line-nature-of-goods-{{i}}" [(ngModel)]="invoiceLine.content" maxlength="35" type="text" name="natureOfGoods{{i}}" pInputText autocomplete="none" [required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient" [readonly]="isReadOnly">
                          <label for="invoice-line-nature-of-goods-{{i}}">{{ 'nature_of_goods' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.natureOfGoods">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.natureOfGoods"></span></ng-container></label>
                        </span>
                      </div>
                    </div>
                    <div class="ctl flex-grow-1 ml-1 mb-0" [class.required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient">
                      <div class="p-field p-field--full">
                        <span class="p-float-label">
                          <input
                            id="invoice-line-customs-tariff-numbers-{{i}}"
                            [(ngModel)]="invoiceLine.customsTariffNumber"
                            minlength="8"
                            maxlength="8"
                            type="text"
                            name="customsTariffNumber{{i}}"
                            pInputText
                            pKeyFilter="int"
                            autocomplete="none"
                            [required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient"
                            [readonly]="isReadOnly"
                            shpValidateCustomsTariffNumber
                            [customsTariffNumberShippingCountryCode]="senderCountryCode"
                            [customsTariffNumberDeliveryCountryCode]="recipientCountryCode">
                          <label for="invoice-line-customs-tariff-numbers-{{i}}">{{ 'hs_code' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.hsCode">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.hsCode"></span></ng-container></label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="invoiceLinesControl.get('customsTariffNumber' + i)?.errors?.minlength" class="ctl">
                  <em class="error">{{ 'error_description_hscode_minlength' | shpLocalize:invoiceLinesControl.get('customsTariffNumber' + i)?.errors?.minlength.requiredLength }}</em>
                </div>
                <div *ngIf="invoiceLinesControl.get('customsTariffNumber' + i)?.errors?.customsTariffNumber" class="ctl">
                  <!-- Using inner html because the text may contain links. -->
                  <em class="error" [innerHtml]="invoiceLinesControl.get('customsTariffNumber' + i)?.errors?.customsTariffNumber"></em>
                </div>

                <div class="ctl">
                  <div class="d-flex">
                    <div class="ctl mb-0" [class.required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient">
                      <div class="p-field p-field--full">
                        <span class="p-float-label" dpdFloatingLabel>
                          <shp-universal-number-input
                            (ngModelChange)="calculateInvoiceAmount()"
                            [(ngModel)]="invoiceLine.amountLine"
                            [isReadOnly]="isReadOnly"
                            [isRequired]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient"
                            [max]="999999"
                            [min]="isBrexitCountryRecipient ? 0.01 : 0"
                            [required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient"
                            dpdFloatingLabelControl
                            id="invoice-line-customs-amount-{{i}}"
                            name="customsAmount{{i}}"></shp-universal-number-input>
                          <label for="invoice-line-customs-amount-{{i}}">{{ 'amount_line' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.amountLine">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.amountLine"></span></ng-container></label>
                        </span>
                      </div>
                    </div>

                    <span class="legend">{{invoice?.currencyCode}}</span>

                    <div class="ctl mb-0" [class.required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient">
                      <div class="p-field p-field--full">
                        <span class="p-float-label" dpdFloatingLabel>
                          <shp-universal-number-input
                            [(ngModel)]="invoiceLine.grossWeight"
                            [isReadOnly]="isReadOnly"
                            [isRequired]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient"
                            [max]="999"
                            [min]="0"
                            [required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient"
                            dpdFloatingLabelControl
                            id="invoice-line-gross-weight-{{i}}"
                            name="grossWeight{{i}}"></shp-universal-number-input>
                          <label for="invoice-line-gross-weight-{{i}}">{{ 'gross_weight' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.grossWeight">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.grossWeight"></span></ng-container></label>
                        </span>
                      </div>
                    </div>

                    <span class="legend">kg</span>

                    <div class="ctl mb-0 w-25" [class.required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient">
                      <div class="p-field p-field--full">
                        <span class="p-float-label">
                          <p-inputNumber
                            [(ngModel)]="invoiceLine.itemsNumber"
                            [disabled]="isReadOnly"
                            [maxFractionDigits]="0"
                            [min]="1"
                            [required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient"
                            [showButtons]="true"
                            [step]="1"
                            decrementButtonClass="p-button-secondary"
                            decrementButtonIcon="ic-subtract-2"
                            id="invoice-line-items-number-{{i}}"
                            incrementButtonClass="p-button-secondary"
                            incrementButtonIcon="ic-add-2"
                            mode="decimal"
                            name="itemsNumber{{i}}"
                            suffix=" {{ 'pcs_label' | shpLocalize }}"></p-inputNumber>
                          <label for="invoice-line-items-number-{{i}}"><ng-container *ngIf="customsClearanceTooltips?.itemsNumber">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.itemsNumber"></span></ng-container></label>
                        </span>
                      </div>
                    </div>
                    <!-- <span class="legend mr-0">{{ 'pcs_label' | shpLocalize }}</span> -->
                  </div>
                </div>

                <div class="ctl" [class.required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient">
                  <div class="p-field p-field--full">
                    <span class="p-float-label">
                      <p-dropdown
                        [(ngModel)]="invoiceLine.originCountryCode"
                        [filter]="true"
                        [options]="countriesWithEmpty"
                        [readonly]="isReadOnly"
                        [required]="isCustomsClearanceRequiredValidationOn && isBrexitCountryRecipient"
                        [showClear]="true"
                        filterBy="name"
                        id="invoice-line-origin-country-code-{{i}}"
                        name="originCountryCode{{i}}"
                        optionLabel="name"
                        optionValue="code"></p-dropdown>
                      <label for="invoice-line-origin-country-code-{{i}}">{{ 'country_of_origin' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.countryOfOrigin">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.countryOfOrigin"></span></ng-container></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="ctl">
          <div class="shipment-editor__invoice-line__index"></div>
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                [(ngModel)]="invoiceAdditionalLines"
                [disabled]="isReadOnly"
                [maxFractionDigits]="0"
                [min]="0"
                [showButtons]="true"
                [step]="1"
                decrementButtonClass="p-button-secondary"
                decrementButtonIcon="ic-subtract-2"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="ic-add-2"
                inputId="invoice-lines"
                mode="decimal"
                name="invoiceAdditionalLinesCount"
              ></p-inputNumber>
              <label for="invoice-lines">{{ 'additional_invoice_lines' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="d-flex mt-4" *ngIf="isBrexitCountryRecipient && invoice">
          <div class="shipment-editor__invoice-line__index"></div>

          <div class="ctl w-100">
            <div class="p-field p-field--full">
              <span class="p-float-label" dpdFloatingLabel>
                <shp-universal-number-input dpdFloatingLabelControl [(ngModel)]="invoice.amount" id="invoice-amount" name="customs_value" [min]="0" [max]="999999" [isRequired]="isCustomsClearanceRequiredValidationOn" [required]="isCustomsClearanceRequiredValidationOn" isReadOnly="true"></shp-universal-number-input>
                <label for="invoice-amount">{{ 'customs_value' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.customsValue">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.customsValue"></span></ng-container></label>
              </span>
            </div>

            <div class="p-field">
              <span class="p-float-label">
                <select *ngIf="!isReadOnly" id="invoice-currency" name="invoice_currency" [(ngModel)]="invoice.currencyCode" [required]="isCustomsClearanceRequiredValidationOn">
                  <option *ngFor="let currency of currencies" [value]="currency.code">{{ currency.code }}</option>
                </select>
                <span *ngIf="isReadOnly" class="ml-1 legend">{{ invoice.currencyCode }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- customs clearance - parcel content panel - (edit) - end -->


      <!-- PANEL BREAK -->


      <!-- customs clearance - parcel content panel - (readonly) - begin -->
      <div class="panel parcels order-{{panelsOrder.customsClearanceContent}}" *ngIf="isReadOnly && isCustomClearance && !showMiniportalLink && !isCollectionRequest && !isReturnProductSelected">
        <h2>{{ 'parcel_contents' | shpLocalize }}</h2>

        <div *ngFor="let parcel of parcels" class="parcel">
          <strong>{{ parcel.nr }}</strong>
          <div class="references">
            <span class="reference">{{ 'nature_of_goods' | shpLocalize }}: {{ parcel.content }}</span>
            <span class="reference">{{ 'hs_code' | shpLocalize }}: {{ parcel.customsTariffNumber }}</span>
          </div>
          <div class="references">
            <span class="reference">{{ 'amount_line' | shpLocalize }}: {{ parcel.amountLine }} {{ invoice?.currencyCode }}</span>
            <span class="reference">{{ 'gross_weight' | shpLocalize }}: {{ parcel.grossWeight }} kg</span>
            <span class="reference">{{ 'pcs_label' | shpLocalize }}: {{ parcel.itemsNumber }}</span>
          </div>
          <div *ngIf="parcel.originCountryCode" class="references">
            <span class="reference">{{ 'country_of_origin' | shpLocalize }}: {{ getCountryNameByCode(parcel.originCountryCode) }}</span>
          </div>
          <!-- <ng-container *ngIf="isFreshProductSelected">
    <div class="references">
      <span class="reference">{{ 'expiration_date' | shpLocalize }}: {{ parcel.limitDate | shpLocalizeDate:'L' }}</span>
    </div>
    <div class="references">
      <span class="reference">{{ 'storage_temparature' | shpLocalize }}: {{ parcel.tempMin }} °C</span>
      <span class="reference">{{ 'minimal_temparature' | shpLocalize }}: {{ parcel.tempMax }} °C</span>
    </div>
    <div *ngIf="parcel.description" class="references">
      <span class="reference">{{ 'description' | shpLocalize }}: {{ parcel.description }}</span>
    </div>
  </ng-container> -->
        </div>

        <ng-container *ngIf="invoiceLines?.length">
          <h3>{{ 'additional_invoice_lines' | shpLocalize }}<span *ngIf="invoiceLines.length > 1">&nbsp;({{ invoiceLines.length }})</span><ng-container *ngIf="customsClearanceTooltips?.additionalInvoiceLines">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.additionalInvoiceLines"></span></ng-container></h3>

          <div *ngFor="let invoiceLine of invoiceLines" class="parcel">
            <div class="references">
              <span class="reference">{{ 'nature_of_goods' | shpLocalize }}: {{ invoiceLine.content }}</span>
              <span class="reference">{{ 'hs_code' | shpLocalize }}: {{ invoiceLine.customsTariffNumber }}</span>
            </div>
            <div class="references">
              <span class="reference">{{ 'amount_line' | shpLocalize }}: {{ invoiceLine.amountLine }} {{ invoice?.currencyCode }}</span>
              <span class="reference">{{ 'gross_weight' | shpLocalize }}: {{ invoiceLine.grossWeight }} kg</span>
              <span class="reference">{{ 'pcs_label' | shpLocalize }}: {{ invoiceLine.itemsNumber }}</span>
            </div>
            <div *ngIf="invoiceLine.originCountryCode" class="references">
              <span class="reference">{{ 'country_of_origin' | shpLocalize }}: {{ getCountryNameByCode(invoiceLine.originCountryCode) }}</span>
            </div>
          </div>
        </ng-container>

        <div class="mt-2" *ngIf="isBrexitCountryRecipient">
          <span>{{ 'customs_value' | shpLocalize }}<ng-container *ngIf="customsClearanceTooltips?.customsValue">&nbsp;<span class="ic-interface-question-mark" [pTooltip]="customsClearanceTooltips.customsValue"></span></ng-container></span>:
          <span class="ml-1">{{ invoice?.amount }} {{ invoice?.currencyCode }}</span>
        </div>
      </div>
      <!-- customs clearance - parcel content panel - (readonly) - end -->


      <!-- PANEL BREAK -->


      <!-- From when invoicing address of the sender is different from the postal address. -->
      <shp-recipient-view class="panel order-{{panelsOrder.invoiceSender}}" *ngIf="customSenderInvoiceAddress && !isCollectionRequest"
                          [entityValidatorOrder]="panelsOrder.invoiceSender"
                          [name]="'shipment_order_invoice_sender'"
                          [recipient]="invoicingSender"
                          [countries]="countries"
                          [isReadOnly]="isReadOnly"
                          [suspendRoutingValidation]="_suspendRoutingValidation"
                          [selectedProduct]="selectedProduct"
                          [recipientsDataService]="recipientsDataService"
                          [settings]="settings"
                          [languages]="languages"
                          [useB2BAddressFieldsOnly]="true"
                          [hideFieldsIrrelevantForSender]="true"
                          [isPresentedAsSender]="true"
                          [hideFieldsIrrelevantForPickup]="true"
                          [disableValidation]="true"
                          [isCustomsClearance]="true"
                          [showAllCustomsFields]="true"
                          [streetRegEx]="streetRegEx">
      </shp-recipient-view>


      <!-- PANEL BREAK -->


      <!-- Form when invoicing address of the recipient is different from the postal address -->
      <shp-recipient-view class="panel order-{{panelsOrder.invoiceRecipient}}" *ngIf="customReceiverInvoiceAddress && !isCollectionRequest"
                          [entityValidatorOrder]="panelsOrder.invoiceRecipient"
                          [name]="'shipment_order_invoice_receiver'"
                          [recipient]="invoicingReceiver"
                          [countries]="countries"
                          [isReadOnly]="isReadOnly"
                          [suspendRoutingValidation]="_suspendRoutingValidation"
                          [selectedProduct]="selectedProduct"
                          [recipientsDataService]="recipientsDataService"
                          [settings]="settings"
                          [languages]="languages"
                          [useB2BAddressFieldsOnly]="true"
                          [hideFieldsIrrelevantForSender]="true"
                          [hideFieldsIrrelevantForPickup]="true"
                          [disableValidation]="true"
                          [isCustomsClearance]="true"
                          [showAllCustomsFields]="true"
                          [streetRegEx]="streetRegEx">
      </shp-recipient-view>


      <!-- PANEL BREAK -->


      <!-- DPD precise product begin -->
      <div class="panel order-{{panelsOrder.preciseProduct}}" *ngIf="isDpdPreciseProduct && !isReadOnly" [entityValidatorOrder]="panelsOrder.preciseProduct">
        <h2>{{ 'delivery' | shpLocalize }}</h2>
        <div class="ctl">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-calendar [(ngModel)]="deliveryPlan.deliveryDateFrom" [showIcon]="true" inputId="delivery-date" dateFormat="dd/mm/yy" icon="ic-calendar-1" required name="deliveryDate"></p-calendar>
              <label for="delivery-date">{{ 'delivery_date' | shpLocalize }}</label>
            </span>
          </div>
        </div>
        <div class="ctl">
          <label>{{ 'delivery_window' | shpLocalize }}</label>
          <div class="radio-group multiline" *ngIf="deliveryWindows && deliveryWindows.length > 0">
            <ng-template ngFor let-deliveryWindow [ngForOf]="deliveryWindows">
              <input type="radio" required name="deliveryWindow" entity-validation-group="deliveryWindow" id="deliveryWindow_{{ deliveryWindow.id }}" [(ngModel)]="selectedDeliveryWindowId" [value]="deliveryWindow.id" />
              <label [attr.for]="'deliveryWindow_' + deliveryWindow.id">{{ deliveryWindow.timeFrom | shpLocalizeDate:"HH:mm" }} - {{ deliveryWindow.timeTo | shpLocalizeDate:"HH:mm" }}</label>
            </ng-template>
          </div>
          <div class="warning" *ngIf="deliveryWindows && deliveryWindows.length == 0">
            {{ 'no_delivery_windows_available_for_selected_date_and_recipient_address' | shpLocalize }}
          </div>
        </div>
      </div>
      <!-- DPD precise product end -->


      <!-- PANEL BREAK -->


      <!-- DPD precise product - (readonly) - begin -->
      <div class="panel order-{{panelsOrder.preciseProduct}}" *ngIf="isDpdPreciseProduct && isReadOnly">
        <h2>{{ 'delivery' | shpLocalize }}</h2>

        <p><span class="ic-calendar-2"></span> {{ deliveryPlan.deliveryDateFrom | shpLocalizeDate:"L" }} <span class="ic-smart-watch-circle-clock"></span> {{ deliveryPlan.timeFrameFrom | shpLocalizeDate:"HH:mm" }}-{{ deliveryPlan.timeFrameTo | shpLocalizeDate:"HH:mm" }}</p>
      </div>
      <!-- DPD precise product - (readonly) - end -->


      <!-- PANEL BREAK -->


      <!-- Additional services begin -->
      <div class="panel order-{{panelsOrder.additionalServices}}" *ngIf="!isAdditionalServicesSectionWithinBaseData && !(isDpdPreciseProduct)" [entityValidatorOrder]="panelsOrder.additionalServices">
        <h2>{{ 'shipment_order_additional_services' | shpLocalize }}</h2>
        <shp-shipments-additional-services (selectedServicesChange)="updateSelectedServices()"></shp-shipments-additional-services>
      </div>
      <!-- Additional services end -->

      <!-- Additional fields begin -->
      <div *ngIf="dynamicFieldsResults.length > 0" class="panel order-{{panelsOrder.additionalServices}}" >
        <h2>{{ 'shipment_order_additional_fields' | shpLocalize }}</h2>
        <shp-shipments-additional-fields [dynamicFieldsResults]="dynamicFieldsResults" [predefinedFieldsValues] = "predefinedFieldsValues"></shp-shipments-additional-fields>
      </div>
      <!-- Additional fields end -->
    </div>
  </div>
  <div class="toolbar">
    <div class="d-flex flex-flow-row-reverse" wizardTag="shipmentEditorButtons">
      <button *ngIf="!isReadOnly && !isCollectionRequest" id="print-and-new" type="button" [disabled]="!canSave" (click)="saveOrder(true, true)"><span class="ic-floppydisk"></span> {{  (fromSenderScan ? 'print_and_scan' : 'print_and_new') | shpLocalize }}</button>
      <!--<button *ngIf="!isReadOnly" id="save-and-print" type="button" [disabled]="this.formInvalid()" (click)="saveOrder(f.valid, true)"><span class="ic-floppydisk"></span> {{ 'save_and_print' | shpLocalize }}</button>-->
      <button *ngIf="!isReadOnly" id="save-and-new" type="button" [disabled]="!canSave" (click)="saveOrder(false, true)"><span class="ic-floppydisk"></span> {{  (fromSenderScan ? 'save_and_scan' : 'save_and_new') | shpLocalize }}</button>
      <button *ngIf="!isReadOnly && !isSophia" id="save" [disabled]="!canSave" type="submit"><span class="ic-floppydisk"></span> {{ 'save' | shpLocalize }}</button>
      <button type="button" id="back-to-list" class="secondary" (click)="backToList()"><span class="ic-arrow-left-1"></span>{{ (fromSenderScan ? 'back_to_sender_scan' : 'recipient_back_to_list') | shpLocalize }}</button>
    </div>
  </div>
</form>
