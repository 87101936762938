import { Component, AfterViewInit, Input, ElementRef, NgZone, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, Output } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"

import { Editor } from "brace";
import * as ace from 'brace';
// import 'brace/mode/javascript';
import 'brace/mode/sqlserver';
import 'brace/mode/razor';
import 'brace/theme/monokai';
import 'brace/theme/sqlserver';
import { EventEmitter } from "events";

const nullCommand = { command: "null", passEvent: false };

// declare var ace: any;


@Component({
  selector: "template-content-editor",
  template: "",
  host: {
    class: "template-content-editor"
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemplateContentEditorComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  exportAs: "templateEditor"
})
export class TemplateContentEditorComponent implements ControlValueAccessor, AfterViewInit {
  //@ViewChild("editor")
  //private _editorElement;

  private _editor: any;

  @Output() textChanged = new EventEmitter();
  @Output() textChange = new EventEmitter();

  private _content: string = "";
  private _isTextOnlyMode = false;

  private _oldContent: string = null;

  private _propagateChange = (_: any) => { };
  private _propagateTouched = () => { };


  constructor(
    private _elementRef: ElementRef,
    private _changeDetectionRef: ChangeDetectorRef,
    private _ngZone: NgZone
  ) {

    let el = _elementRef.nativeElement;
    let that = this;

    this._ngZone.runOutsideAngular(() => {
      that._editor = ace.edit(el);

      that._editor.$blockScrolling = Infinity;

      that._editor.setTheme("ace/theme/sqlserver");

      that.setMode();

      that._editor.setOptions({
        showPrintMargin: false,
        scrollPastEnd: true,
        showFoldWidgets: false,
        animatedScroll: false,
        tabSize: 2
      })

      var kb = {
        handleKeyboard: that.handleKeyboard.bind(that)
      };

      that._editor.keyBinding.setKeyboardHandler(kb);

      that._editor.commands.removeCommand("find");

      that._editor.on("focus", () => that.onFocus());
      that._editor.on("change", () => that.updateText());
      that._editor.on("paste", () => that.updateText());
    });
  }


  private onFocus() {
    this._ngZone.run(() => {
      this._propagateTouched();
    });
  }


  private updateText() {
    let newVal = this._editor.getValue(), that = this;

    if (newVal === this._oldContent) {
      return;
    }

    this._content = newVal;

    this._ngZone.run(() => {
      this.textChange.emit(newVal);
      this.textChanged.emit(newVal);
      this._propagateChange(newVal);
    });

    this._oldContent = newVal;
  }


  public get isTextOnlyMode(): boolean {
    return this._isTextOnlyMode;
  }


  @Input()
  public set isTextOnlyMode(value: boolean) {
    if (value !== this._isTextOnlyMode) {
      this._isTextOnlyMode = value;

      this.setMode();
    }
  }


  public get content(): string {
    return this._content;
  }


  public get editor(): Editor {
    if (this._editor) {
      return this._editor;;
    }

    return null;
  }


  private setMode() {
    if (this.isTextOnlyMode) {
      this.setTextOnlyEditingMode();
    } else {
      this.setFullEditingMode();
    }
  }


  private setFullEditingMode() {
    this.editor.getSession().setMode("ace/mode/razor");
  }


  private setTextOnlyEditingMode() {
    this.editor.getSession().setMode("ace/mode/text_only");
  }


  private isNavigationKey(event: KeyboardEvent) {
    return event.key === "ArrowUp" ||
      event.key === "ArrowDown" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Home" ||
      event.key === "End" ||
      event.key === "PageDown" ||
      (event.key.length > 1 && event.key[0].toLowerCase() === "f");
  }


  private isUndoRedoEvent(event: KeyboardEvent) {
    return event.ctrlKey && (event.key === "z" || event.key === "y");
  }


  private isCutOrPasteEvent(event: KeyboardEvent) {
    return (event.ctrlKey && (event.key === "v" || event.key === "x")) ||
      (event.shiftKey && (event.key === "Delete" || event.key === "Insert"));
  }


  private handleKeyboard(data, hash, keyString, keyCode, event: KeyboardEvent) {
    if (event && this.isTextOnlyMode) {
      var editor = data.editor as Editor;
      var anchor = editor.selection.getSelectionAnchor();
      var cursorPos = editor.getCursorPosition();
      var token = editor.session.getTokenAt(cursorPos.row, cursorPos.column) as any;
      var selection = editor.getSelection();

      if (!this.isNavigationKey(event) && !this.isUndoRedoEvent(event)) { // Kurzorové klávesy, home, end a podobné
        if (token.type !== "text") {
          // Ak sme na separátore, tak zistíme, či nie sme na jeho konci, ak je to začiatok textu {:
          if (event.key !== "Backspace" && token.type === "separator" && token.value.startsWith("{:") && cursorPos.column - token.start === 2) {
            // Zistíme, či tam nemáme náhodou :} hneď za tým a nestláčame delete.
            if (token.value.startsWith("{::}") && event.key === "Delete") {
              return nullCommand;
            }

            // Zatiaľ ani cut a paste.
            if (this.isCutOrPasteEvent(event)) {
              return nullCommand;
            }
          } else {
            return nullCommand;
          }
        } else {
          // Nepodporujeme insert mód.
          if (event.shiftKey === false && event.key === "Insert") {
            return nullCommand;
          }

          // Zatiaľ ani cut a paste.
          if (this.isCutOrPasteEvent(event)) {
            return nullCommand;
          }

          // Musíme zistiť, či nie sme na konci a nestláčame delete.
          if (event.key === "Delete" && token.value.length === cursorPos.column - token.start) {
            return nullCommand;
          }

          // Nesmieme po : napísať }.
          if (event.key === "}" && cursorPos.column > token.start && token.value[cursorPos.column - token.start - 1] === ":") {
            return nullCommand;
          }

          // A ani pred } napísať :.
          if (event.key === ":" && cursorPos.column < token.start + token.value.length && token.value[cursorPos.column - token.start] === "}") {
            return nullCommand;
          }
        }
      }
    }
  }


  public ngAfterViewInit() {

  }


  public registerOnChange(fn) {
    this._propagateChange = fn;
  }


  public registerOnTouched(fn) {
    this._propagateTouched = fn;
  }


  setText(text: any) {
    if (text === null || text === undefined) {
      text = "";
    }

    if (this._content !== text) {
      this._content = text;
      this._oldContent = text;
      this._editor.setValue(text);
      this._editor.clearSelection();
    }
  }


  public writeValue(value) {
    this.setText(value);
  }


  public setDisabledState(isDisabled: boolean) {
    if (this.editor) {
      this.editor.setReadOnly(isDisabled);
    }
  }
}
