<shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
<div class="settings-category">
  <div class="settings-wrapper settings-wrapper--full">
    <h1>{{ 'settings_automatic_import' | shpLocalize }}</h1>
    <form #f (submit)="save()" *ngIf="settings" class="">
      <div class="ctl" *ngIf="settings.automated_shipments_import.isVisible">
        <input type="checkbox" name="automatedShipmentsImport" id="automated-shipments-import" [(ngModel)]="settings.automated_shipments_import.value" />
        <label for="automated-shipments-import" class="w-100">{{ 'settings_automated_shipments_import' | shpLocalize }}</label>
      </div>

      <div class="ctl" *ngIf="settings.automated_shipments_import_source.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="automatedShipmentsImportSource"
                id="automated_shipments_import_source"
                [(ngModel)]="settings.automated_shipments_import_source.value">
              <option [ngValue]="AutomatedShipmentsImportSourceType.LocalFolder">{{ 'settings_automated_shipments_import_source_local_folder' | shpLocalize }}</option>
              <option [ngValue]="AutomatedShipmentsImportSourceType.Sftp">{{ 'settings_automated_shipments_import_source_sftp' | shpLocalize }}</option>
              <option [ngValue]="AutomatedShipmentsImportSourceType.Ftp">{{ 'settings_automated_shipments_import_source_ftp' | shpLocalize }}</option>
            </select>
            <label for="automated_shipments_import_source">{{ 'settings_automated_shipments_import_source' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <ng-container *ngIf="settings.automated_shipments_import_source.value === AutomatedShipmentsImportSourceType.Sftp || settings.automated_shipments_import_source.value === AutomatedShipmentsImportSourceType.Ftp">
        <div class="ctl" *ngIf="settings.automated_shipments_import_sftp_server.isVisible">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="automated_shipments_import_sftp_server" type="text" name="automated_shipments_import_sftp_server" pInputText [(ngModel)]="settings.automated_shipments_import_sftp_server.value">
              <label for="automated_shipments_import_sftp_server">{{ 'settings_automated_shipments_import_sftp_server' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl" *ngIf="settings.automated_shipments_import_sftp_port.isVisible">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <p-inputNumber
                [(ngModel)]="settings.automated_shipments_import_sftp_port.value"
                [min]="0"
                [max]="65535"
                [step]="1"
                [showButtons]="true"
                name="automatedShipmentsImportSftpPort"
                id="automated_shipments_import_sftp_port"
                mode="decimal"
                decrementButtonClass="p-button-secondary"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="ic-add-2"
                decrementButtonIcon="ic-subtract-2"
              ></p-inputNumber>
              <label for="automated_shipments_import_sftp_port">{{ 'settings_automated_shipments_import_sftp_port' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl" *ngIf="settings.automated_shipments_import_sftp_login.isVisible">
          <div class="p-field p-field--full">
            <span class="p-float-label">
              <input id="automated_shipments_import_sftp_login" type="text" name="automated_shipments_import_sftp_login" pInputText [(ngModel)]="settings.automated_shipments_import_sftp_login.value">
              <label for="automated_shipments_import_sftp_login">{{ 'settings_automated_shipments_import_sftp_login' | shpLocalize }}</label>
            </span>
          </div>
        </div>

        <div class="ctl" *ngIf="settings.automated_shipments_import_sftp_password.isVisible">
          <label for="automated_shipments_import_sftp_password">{{ 'settings_automated_shipments_import_sftp_password' | shpLocalize }}</label>
          <input type="password"
                name="automatedShipmentsImportSftPassword"
                id="automated_shipments_import_sftp_password"
                [(ngModel)]="settings.automated_shipments_import_sftp_password.value" />
        </div>
      </ng-container>
      <div class="ctl" *ngIf="settings.automated_shipments_import_path.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="automated-shipments-import-path" type="text" name="automatedShipmentsImportPath" pInputText [(ngModel)]="settings.automated_shipments_import_path.value">
            <label for="automated-shipments-import-path">{{ 'settings_automated_shipments_import_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_error_path.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="automated-shipments-import-error-path" type="text" name="automatedShipmentsImportErrorPath" pInputText [(ngModel)]="settings.automated_shipments_import_error_path.value">
            <label for="automated-shipments-import-error-path">{{ 'settings_automated_shipments_import_error_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_path.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.automated_shipments_import_periodicity_in_minutes.value"
              [min]="0"
              [max]="10080"
              [step]="1"
              [showButtons]="true"
              name="syncShipmentsHour"
              id="automated-shipments-import-periodicity"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="automated-shipments-import-periodicity">{{ 'settings_automated_shipments_import_periodicity' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_path.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.automated_shipments_import_periodicity_in_seconds.value"
              [min]="0"
              [max]="10080"
              [step]="1"
              [showButtons]="true"
              name="syncShipmentsSec"
              id="automated-shipments-import-periodicity-sec"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="automated-shipments-import-periodicity-sec">{{ 'settings_automated_shipments_import_periodicity_sec' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_use_semaphore_files.isVisible">
        <input type="checkbox" name="automatedShipmentsImportUseSemaphoreFiles" id="automated-shipments-import-use-semaphore-files" [(ngModel)]="settings.automated_shipments_import_use_semaphore_files.value" />
        <label for="automated-shipments-import-use-semaphore-files" class="w-100">{{ 'settings_automated_shipments_import_use_semaphore_files' | shpLocalize }}</label>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_disable_special_order_validation.isVisible && isAddressProviderInUse">
        <input type="checkbox" name="automatedShipmentsImportDisableSpecialOrderValidation" id="automated-shipments-import-disable-special-order-validation" [(ngModel)]="settings.automated_shipments_import_disable_special_order_validation.value" />
        <label for="automated-shipments-import-disable-special-order-validation" class="w-100">{{ 'automated_shipments_import_disable_special_order_validation' | shpLocalize }}</label>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_sender_Id.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="automatedShipmentsImportSenderId" id="automated-shipments-import-sender-id" [(ngModel)]="settings.automated_shipments_import_sender_Id.value">
              <option [ngValue]="null">--</option>
              <option *ngFor="let address of addresses" [ngValue]="address.customId">{{ address.customId }} - {{ address | shpFullAddress }}</option>
            </select>
            <label for="automated-shipments-import-sender-id">{{ 'settings_automated_shipments_import_sender_id' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_bank_account_Id.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="automatedShipmentsImportBankAccountId" id="automated-shipments-import-bank-account-id" [(ngModel)]="settings.automated_shipments_import_bank_account_Id.value">
              <option [ngValue]="null">--</option>
              <option *ngFor="let bankAccount of bankAccounts" [ngValue]="bankAccount.customId">{{ bankAccount.customId }} - {{ bankAccount.iban }}</option>
            </select>
            <label for="automated-shipments-import-bank-account-id">{{ 'settings_automated_shipments_import_bank_account_id' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_profile_code.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="automatedShipmentsImportProfileCode" id="automated-shipments-import-profile-code" [(ngModel)]="settings.automated_shipments_import_profile_code.value">
              <option [ngValue]="importProfileDefault.code">{{ 'import_profile_default' | shpLocalize }}</option>
              <option [value]="imp.code" *ngFor="let imp of importProfiles">{{imp.name}}</option>
            </select>
            <label for="automated-shipments-import-profile-code">{{ 'settings_automated_shipments_import_profile' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_delete_file_after_processing.isVisible">
        <input type="checkbox" name="automatedShipmentsImportDeleteFileAfterProcessing" id="automated-shipments-import-delete-file-after-processing" [(ngModel)]="settings.automated_shipments_import_delete_file_after_processing.value" />
        <label for="automated-shipments-import-delete-file-after-processing" class="w-100">{{ 'settings_automated_shipments_import_delete_file_after_processing' | shpLocalize }}</label>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_print_labels_after_processing.isVisible">
        <input type="checkbox" name="automatedShipmentsImportPrintLabelsAfterProcessing" id="automated-shipments-import-print-labels-after-processing" [(ngModel)]="settings.automated_shipments_import_print_labels_after_processing.value" />
        <label for="automated-shipments-import-print-labels-after-processing" class="w-100">{{ 'settings_automated_shipments_import_print_labels_after_processing' | shpLocalize }}</label>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_move_to_archive.isVisible">
        <input type="checkbox" name="automatedShipmentsImportMoveToArchive" id="automated-shipments-import-move-to-archive" [(ngModel)]="settings.automated_shipments_import_move_to_archive.value" />
        <label for="automated-shipments-import-move-to-archive" class="w-100">{{ 'settings_automated_shipments_import_move_to_archive' | shpLocalize }}</label>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_archive_path.isVisible">
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="automated-shipments-import-archive-path" type="text" name="automatedShipmentsImportArchivePath" pInputText [(ngModel)]="settings.automated_shipments_import_archive_path.value">
            <label for="automated-shipments-import-archive-path">{{ 'settings_automated_shipments_import_archive_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_include_parcelnrs_in_archive.isVisible">
        <input type="checkbox" name="automatedShipmentsImportIncludeParcelnrsInArchive" id="automated-shipments-import-include-parcelnrs-in-archive" [(ngModel)]="settings.automated_shipments_import_include_parcelnrs_in_archive.value" />
        <label for="automated-shipments-import-include-parcelnrs-in-archive" class="w-100">{{ 'settings_automated_shipments_import_include_parcelnrs_in_archive' | shpLocalize }}</label>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_import_semaphore_files_in_archive.isVisible">
        <input type="checkbox" name="automatedShipmentsImportSemaphoreFilesInArchive" id="automated-shipments-import-semaphore-files-in-archive" [(ngModel)]="settings.automated_shipments_import_semaphore_files_in_archive.value" />
        <label for="automated-shipments-import-semaphore-files-in-archive" class="w-100">{{ 'settings_automated_shipments_import_semaphore_files_in_archive' | shpLocalize }}</label>
      </div>
      <div class="commands">
        <button id="button-save" type="submit"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
      </div>
    </form>
    <h2 *ngIf="showShipmentsImportPaths">{{ 'shipments_import_paths' | shpLocalize }}</h2>
    <div *ngIf="showShipmentsImportPaths" class="commands">
      <button id="button-add-import-path" type="button" (click)="addImportPath()"><span class="ic-add-2"></span> {{ 'add' | shpLocalize }}</button>
    </div>
    <table *ngIf="showShipmentsImportPaths" class="nice">
      <thead>
        <tr>
          <th class="col-path">{{ 'path' | shpLocalize }}</th>
          <th class="col-import-profile-name">{{ 'settings_automated_shipments_import_profile' | shpLocalize }}</th>
          <th class="col-checkbox col-use-semaphore-files">{{ 'settings_automated_shipments_import_use_semaphore_files' | shpLocalize }}</th>
          <th class="col-commands"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ip of importPaths">
          <td class="col-path"><span title="{{ ip.path }}">{{ ip.path }}</span></td>
          <td class="col-import-profile-name">{{ ip.importProfileId | shpImportProfileId:importProfiles }}</td>
          <td class="col-checkbox col-use-semaphore-files"><span class="check-mark" *ngIf="ip.useSemaphoreFiles"></span></td>
          <td class="col-commands">
            <button id="button-edit-import-path" type="button" (click)="editImportPath(ip)"><span class="ic-pencil-1"></span></button>
            <button id="button-delete-import-path" type="button" (click)="deleteImportPath(ip)"><span class="ic-bin"></span></button>
          </td>
        </tr>
        <tr *ngIf="importPaths && importPaths.length === 0">
          <td colspan="10">
            <div class="message warning">
              <div class="message-content">
                {{ 'no_shipments_import_paths_defined' | shpLocalize }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
