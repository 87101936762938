import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import * as _ from "lodash";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, forkJoin, ReplaySubject, Subscription } from "rxjs";
import { delayWhen, filter, take } from "rxjs/operators";

import { DEMO_TENANT_CODE } from "../../../shared/globals";
import * as Shared from "../../../shared/index";
import { WizardService } from "../../../shared/modules/wizard/services/wizard.service";
import { ExceptionsHandlerService } from "../../../shared/services/exceptions-handler.service";
import { WizardStepName } from "../../../shared/services/shipper-wizard-steps";
import * as UserModel from "../../../user/models/user.model";
import { UsersService } from "../../../user/services/users.service";

interface UserForGrid extends UserModel.User {
  canDelete: boolean;
  deleteTooltip: string;
  displayRoles: UserModel.UserRole[];
  hasInsightsAccess: boolean;
}

@Component({
  selector: Shared.SELECTOR_PREFIX + "-user-management",
  templateUrl: "./user-management.component.html"
})
export class UserManagementComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy {
  allowAddingNewUsers = false; // BU settings
  canCreateUser = false; // DEMO TENANT
  canManageInsightsUsers = false;
  currentUser: Shared.User = null;
  exception: Shared.ExceptionInfo = null;
  isBusy = false;
  users$: BehaviorSubject<UserForGrid[]> = new BehaviorSubject([]);
  wizardStepNames = WizardStepName;

  private displayUserRoles = [...UserModel.shipperMainRoles];
  private initialized$ = new ReplaySubject<void>(1);
  private userSubscription: Subscription = null;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _usersService: UsersService,
    private _exceptionsHandlerService: ExceptionsHandlerService,
    private _modal: Modal,
    private _toastr: ToastrService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService,
    private _wizardService: WizardService) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router);
  }


  ngOnInit(): void {
    this.isBusy = true;

    forkJoin([
      this._businessUnitSettingsService.getDisallowAddingNewUsers(),
      this._businessUnitSettingsService.getShowInsightsInShipper(),
      this.authenticationService.currentUserAllowedOperations$.pipe(take(1))
    ]).subscribe(([disallowAddingNewUsers, showInsightsInShipper, operations]) => {
      this.allowAddingNewUsers = !disallowAddingNewUsers;

      if (showInsightsInShipper) {
        this.displayUserRoles = [
          UserModel.UserRole.MasterAdmin,
          ...UserModel.shipperMainRoles,
          ...UserModel.insightsMainRoles,
          ...UserModel.additionalInsightsRoles
        ];
      } else {
        this.displayUserRoles = [
          UserModel.UserRole.MasterAdmin,
          ...UserModel.shipperMainRoles
        ];
      }

      this.canManageInsightsUsers =
          operations.has(UserModel.UserOperation.ManageInsightsUsers);

      this.initialized$.next();
    });

    this.userSubscription = this.authenticationService.user.pipe(
      filter(u => Boolean(u)),
      delayWhen(() => this.initialized$)
    ).subscribe(user => {
      this.currentUser = _.cloneDeep(user);
      this.canCreateUser = this.currentUser.login.split("@")[1] !== DEMO_TENANT_CODE;
      // Urveme @... z loginu, lebo budeme porovnávať len s loginmi bez domény.
      this.currentUser.login = this.currentUser.login.split("@")[0];

      this.loadUsers();
    });
  }


  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }


  loadUsers() {
    this._usersService.getUsers(this.currentUser.tenantId).subscribe(
      users => {
        // Tu nie je dobré sortovanie diakritických znakov.
        const orderedUsers = _.orderBy(users, (u: UserModel.User) => u.name.toLowerCase());

        this.users$.next(orderedUsers.map(u => this.toUserForGrid(u)));
        this.isBusy = false;
      },
      ex => {
        this.loggingService.logErrorData(ex, "Error loading users.");

        this.exception = this._exceptionsHandlerService.getExceptionInfo(ex);

        this.isBusy = false;
      }
    );
  }


  editUser(user: UserModel.User) {
    this.router.navigate(["/settings/user-management/users", user.id]);
  }


  addUser() {
    if (this._wizardService.isActive) {
      return;
    }

    this.router.navigate(["/settings/user-management/users", 0]);
  }


  deleteUser(user: UserModel.User) {
    if (this.currentUser.tenantId === user.tenantId && this.currentUser.login === user.loginName) {
      return;
    }

    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("user_delete_confirmation_question"))
      .open()
      .result.then(
      value => {
        if (value) {
          this.isBusy = true;
          this.exception = null;

          this._usersService.deleteUser(user.id)
            .subscribe(
            () => {
              // Nechceme notifikáciu o prípadných zmenách.
              this.hasUnsavedChanges = () => false;

              this.isBusy = false;

              this._toastr.success(this.localizationService.getLocalizedString("user_deleted"));

              this.loadUsers();
            },
            ex => {
              this.isBusy = false;

              this.loggingService.logErrorData(ex, "User deleting failed");

              this.exception = this._exceptionsHandlerService.getExceptionInfo(ex);
            });
        }
      },
      () => {
        // ... nič nerobíme.
      });
  }


  private toUserForGrid(user: UserModel.User): UserForGrid {
    const displayRoles = _.intersection(this.displayUserRoles, user.roles);
    const isMasterAdmin = user.roles.includes(UserModel.UserRole.MasterAdmin);
    const hasInsightsAccess = user.roles.some(r => UserModel.insightsMainRoles.has(r));
    // Other types of access are not checked here,
    // as their respective admins should be able to remove them.
    // I.e. a customer tenant admin can always delete a customer tenant user.
    const canDelete = !isMasterAdmin && (hasInsightsAccess ? this.canManageInsightsUsers : true);

    let deleteTooltip = "delete_user";

    if (!canDelete) {
      if (isMasterAdmin) {
        deleteTooltip = "tooltip_cant_delete_master_admin";
      } else if (hasInsightsAccess && !this.canManageInsightsUsers) {
        deleteTooltip = "tooltip_cant_delete_user_with_insights_access";
      }
    }

    const result: UserForGrid = {
      ..._.cloneDeep(user),
      canDelete,
      deleteTooltip,
      displayRoles,
      hasInsightsAccess
    };

    return result;
  }
}
