import { Component, OnInit, OnDestroy, Type } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { Subject, timer } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { LoginData } from "../models/login-data.model";
import { CHANGE_PASSWORD_URL } from "../../shared/index";


/**
 * Komponent, ktorý sa objaví, ak sa vyprší používateľovi autentifikácia a nemá ani refresh token platný.
 */
@Component({
  selector: Shared.SELECTOR_PREFIX + "-login-popup",
  templateUrl: "./login-popup.component.html"
})
export class LoginPopupComponent implements OnInit, OnDestroy {
  public exception: Shared.ExceptionInfo = null;
  public isBusy = false;
  public show = false;
  // private timer: number = null;
  private destroy$ = new Subject<void>();


  constructor(
    private _authenticationService: Shared.AuthenticationService,
    private _loggin: Shared.LoggingService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
  }


  public processLogin(loginData: LoginData) {
    this.exception = null;
    this.isBusy = true;

    this._authenticationService.logInUser(loginData.login, loginData.password)
      .subscribe(
      (user) => {
        // Úspešne sme sa prihlásili.
        this.isBusy = false;
        this.show = false;
      },
      (ex: Shared.ExceptionInfo) => {
        this.exception = ex;
        this._loggin.logErrorData(ex);
        this.isBusy = false;
      });
  }


  private checkAuthentication() {
    if (this.show) {
      return;
    }

    // Checeme sa dohrabať k aktuálnemo komponentu, ktorý je načítaný v rámci routu
    // a zistiť, či vyžaduje autentifikáciu a či ju máme.
    var snapshot = this._router.routerState.snapshot; //this._router.routerState.snapshot;
    var childSnapshot = snapshot.root.firstChild.firstChild;
    if (childSnapshot.firstChild) {
      childSnapshot = childSnapshot.firstChild;
    }

    if ((childSnapshot.component as Type<any>).prototype.requiresAuthenticatedUser) {
      let requiresAuthentication = (childSnapshot.component as Type<any>).prototype.requiresAuthenticatedUser();

      if (requiresAuthentication && !this._authenticationService.isAuthenticated) {
        this.show = true;
      }
    }
  }


  private setAuthenticationCheckTimer() {
    timer(500, 500).pipe(
      takeUntil(this.destroy$),
      filter(() => this._authenticationService.isPeriodicalLogginStatusCheckActive)
    ).subscribe(() => this.checkAuthentication());
  }


  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  public ngOnInit() {
    this.setAuthenticationCheckTimer();
  }
}