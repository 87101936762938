import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";

import { debounceTime, distinctUntilChanged, finalize, skip } from "rxjs/operators";

import * as _ from "lodash";
import { indent } from "indent.js";
import * as Shared from "../../../shared/index";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NotificationsTemplateService } from "../../services/notification-templates.service";
import { Template } from "../../../shared/models/notification-template.model";
import { SkdataConfigService } from "../../../shared/index";
import { TemplateContentEditorComponent } from "../../../shared/components/template-content-editor.component";


const placeholders = [
  {
    name: "email_template_placeholder_parcelnumbers",
    desc: "email_template_placeholder_description_parcelnumbers",
    template: `@Model.GetParcelNumbersString()`
  },
  {
    name: "email_template_placeholder_parcelnumbers_tracking_link",
    desc: "email_template_placeholder_description_parcelnumbers_tracking_link",
    template: 
`
@foreach (var parcelNumber in @Model.ParcelNumbersList){
<text><a href="@Model.GetTrackingLink(@parcelNumber)" target="_blank">@parcelNumber</a>@if (@Model.ParcelNumbersList.IndexOf(@parcelNumber) != @Model.ParcelNumbersList.Count - 1) {<text>, </text>} </text>
}
`
  }, 
  {
    name: "email_template_placeholder_parcels_count",
    template: 
`
@Model.Shipment.ParcelsCount
@if (@Model.Shipment.ParcelsCount == 1)
{<text>parcel</text> }
else
{<text>parcels</text>}`
  },
  {
    name: "email_template_placeholder_receiver",
    template: 
`
@if (!string.IsNullOrEmpty(Model.ReceiverAddress.CompanyName))
{<text>@Model.ReceiverAddress.CompanyName</text> <br />}
@if (!string.IsNullOrEmpty(Model.ReceiverAddress.Name1))
{<text>@Model.ReceiverAddress.Name1</text> <br />}
@Model.ReceiverAddress.Street @Model.ReceiverAddress.HouseNr<br />
@Model.ReceiverAddress.Zip @Model.ReceiverAddress.City <br />
`
  }, 
  {
    name: "email_template_placeholder_sender",
    template:
`
@if (!string.IsNullOrEmpty(Model.SenderAddress.CompanyName))
{<text>@Model.SenderAddress.CompanyName</text> <br />}
@if (!string.IsNullOrEmpty(Model.SenderAddress.Name1))
{<text>@Model.SenderAddress.Name1</text> <br />}
@Model.SenderAddress.Street @Model.SenderAddress.HouseNr <br />
@Model.SenderAddress.Zip @Model.SenderAddress.City <br />
`
  }, 
  {
    name: "email_template_placeholder_COD_amount_with_currency",
    template: `
@if (Model.Cod != null) {<text>COD amount: </text>@Model.GetCodAmountWithCurrencyString}
`
  },    
  {
    name: "email_template_placeholder_COD_card_pay",
    desc: "email_template_placeholder_description_COD_card_pay",
    template: 
`
@if (Model.Cod != null && Model.GetCodCardPayAllowed)
{<text>Card payment is allowed.</text>}
`
  }, 
  {
    name: "email_template_placeholder_bold_text",
    template: `<strong></strong>`,
    cursorIndex: 8
  }, 
  {
    name: "email_template_placeholder_new_line",
    template: `<br />`,
    cursorIndex: 6
  },
  {
    name: "email_template_placeholder_age_check",
    template: `@if (Model.IsAgeCheckRequired())
{<text>You must be 18 years or older to receive your order. The driver validates this through a valid ID card. Make sure you have it ready when your order is delivered.</text>}`
  },
  {
    name: "email_template_placeholder_parcelreference1",
    template: `@Model.GetParcelReference1String()`
  },
  {
    name: "email_template_placeholder_parcelreference2",
    template: `@Model.GetParcelReference2String()`
  },
]

@Component({
  selector: Shared.SELECTOR_PREFIX + "-email-tempaltes-editor",
  templateUrl: "./email-tempaltes-editor.html"
})
export class EmailTempaltesEditor extends Shared.RoutedPageComponentBase {
   
  form: FormGroup;
  isBusy = false;
  isPreviewLoading = false;
  languages: string[] = [];
  template: Template;
  templateIsValid = true;
  templatePreviewErrorMessage: string;
  placeholders = placeholders;

  @ViewChild(TemplateContentEditorComponent) templateEditor: TemplateContentEditorComponent;

  private _templatePreview;
  
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _fb: FormBuilder,
    private _notificationTemplateService: NotificationsTemplateService,
    private _skDataService: SkdataConfigService, 
    ) {
    super(
      loggingService,
      globalEventsStreamService,
      localizationService,
      authenticationService,
      router);

    this.createForm();
  }


  get isNew(): boolean {
    return !this.template || !this.template.id;
  }


  ngOnInit() {
    this.loadTemplate();
    this.loadLanguages();
    this.initTemplatePreviewDataStream();
  }
  
  ngAfterViewInit() {
    console.warn(this.templateEditor);
  }


  applyText(text: string, cursorIndex?: number) {
    if (!this.templateEditor) {
      return;
    } 

    const editor = this.templateEditor.editor;
    const idx = editor.session.doc.positionToIndex(editor.getCursorPosition(), 0); 
    let value = editor.getValue();
    value = value.slice(0, idx) + text + value.slice(idx);
    this.template.content = value;
    
    this.updateForm();
    
    const newCursorIndex = idx + (typeof cursorIndex !== "undefined" ? cursorIndex : 0);
    const newCursorPosition = editor.session.doc.indexToPosition(newCursorIndex, 0); 
    editor.moveCursorToPosition(newCursorPosition);
    editor.focus();
  }


  createTemplate() {
    const value = this.form.value;
    if (value.content) {
      value.content = indent.html(value.content);
    }

    this.isBusy = true;

    this._notificationTemplateService.createCustomerPersonalizedNotificationTemplate(value.content, value.content2, value.languageCodes).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(template => {
      this.template = template;
      this.updateForm();
    }, () => { });
  }


  updateTemplate() {
    this.isBusy = true;
    
    this._notificationTemplateService.updateTemplate(this.template, this.form.value).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(() => { 
      }, () => { });
  }


  private loadLanguages() {
    this._skDataService.getLanguages()
      .subscribe(languages => this.languages = languages.sort());    
  }


  private loadTemplate() {
    this.isBusy = true;
    const templateLocalization = this.form.get("languageCodes").value;

    this._notificationTemplateService.getCustomerPersonalizedNotificationTemplate(templateLocalization).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe(template => {
        if (!template) {
          template = {
            content2: "",
            content: ""
          };
        }

        this.template = template;
        this.updateForm();
      }, () => { });
  }


  private initTemplatePreviewDataStream() {
    this.form.get("content").valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(val => this.getPreview(val));
  }


  private getPreview(templateCode: string) {
    this.isPreviewLoading = true;

    this._notificationTemplateService.getTemplatePreview(templateCode).pipe(
      finalize(() => this.isPreviewLoading = false)
    ).subscribe(previewHtml => {
        this.templateIsValid = true;
        this._templatePreview = previewHtml;
        this.templatePreviewErrorMessage = null;

        /** Wait for ngIf to display the iframe. */
        setTimeout(() => this.displayTemplatePreview(this._templatePreview));
      }, ex => {
        this.templatePreviewErrorMessage = ex.message;
        this._templatePreview = null;
        this.templateIsValid = false;
      });
  }


  private displayTemplatePreview(htmlString: string) {
    var iframe = document.getElementById("templatePreview") as any;
    iframe = iframe.contentWindow || 
        (iframe.contentDocument.document || iframe.contentDocument);

    iframe.document.open();
    iframe.document.write(htmlString);
    iframe.document.close();
  }


  private createForm() {
    this.form = this._fb.group({
      content2: ["", [Validators.required]],
      content: ["", [Validators.required]],
      languageCodes: ["EN", [Validators.required]]
    });

    this.form.get("languageCodes").valueChanges.pipe(
      distinctUntilChanged(),
      skip(1),
    ).subscribe(() => this.loadTemplate());
  }


  private updateForm() {
    if (this.template) {
      if (this.template.content) {
        this.template.content = indent.html(this.template.content);
      }
      this.form.patchValue(this.template);
    }
  }
}
