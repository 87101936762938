import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { saveAs } from "file-saver";
import * as _ from "lodash";
import { overlayConfigFactory } from "ngx-modialog-7";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";

import * as Shared from "../../shared/index";
import { WizardStepName } from "../../shared/services/shipper-wizard-steps";
import { ImportProfile } from "../models/import-profiles.models";
import { ImportProfilesService } from "../services/import-profiles.service";
import { ImportProfilesImportDialogComponent } from "./import-profiles-import-dialog.component";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-import-profiles-list",
  templateUrl: "./list.component.html",
  styleUrls: []
})
export class ListComponent extends Shared.RoutedPageComponentBase implements OnInit {
  public activeId = null;
  public activeIndex = null;
  public allowAddingNewImportProfiles = false;
  public customers = [];
  public data: Array<ImportProfile> = null;
  public isBusy = null;
  public isReady = false;
  public listTitleCustomer: string;
  public listTitleDate: string;
  public listTitleDescription: string;
  public listTitleName: string;
  public type: string;
  public typePrefix: string;
  public wizardStepNames = WizardStepName;

  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _contextService: Shared.ContextService,
    private _dynamicsImportsService: ImportProfilesService,
    private _modal: Modal,
    private _toastr: ToastrService,
    private _businessUnitSettingsService: Shared.BusinessUnitSettingsService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }


  public ngOnInit() {
    combineLatest([
      this._dynamicsImportsService.currentImportProfile,
      this._businessUnitSettingsService.getDisallowAddingNewImportProfiles(),
      this._contextService.customerDetails
    ]).subscribe(result => {
      // importProfile
      this.type = result[0].name;
      this.typePrefix = result[0].prefix;

      // disallowAddingNewImportProfiles
      this.allowAddingNewImportProfiles = !result[1];

      // customers
      this.customers = result[2];
      this.loadList();

      this.isReady = true;
    }, ex => {
      this.isReady = true;
    });

    this.listTitleName = this.localizationService.getLocalizedString("import_profile_edit_name");
    this.listTitleDescription = this.localizationService.getLocalizedString("import_profile_edit_description");
    this.listTitleCustomer = this.localizationService.getLocalizedString("import_profile_edit_customer");
    this.listTitleDate = this.localizationService.getLocalizedString("date");
  }

  public loadList() {
    this.isBusy = true;
    this._dynamicsImportsService.loadListOfImportProfilesByType(this.type).subscribe((data: Array<ImportProfile>) => {
      this.data = [] as Array<ImportProfile>;

      data.forEach((d: ImportProfile) => {
        this.data.push(d);
      });

      if (this.data.length > 0) {
        this.activeIndex = 0;
        this.activeId = this.data[this.activeIndex].id;


        // add index for table
        this.data.forEach((d, idx) => {
          const duckTypingD = d as any;
          duckTypingD.index = idx;
          duckTypingD.customerName = this.searchCustomerNameCustomerById(d.customerDetailId);
        });
      }

      this.isBusy = false;
    });
  }

  public searchCustomerNameCustomerById(customerDetailId: number) {
    let result = "-";

    this.customers.some((customer) => {
      if (customer.id === customerDetailId) {
        result = customer.name;
        return true;
      }
    });

    return result;
  }


  public selectionChanged(event) {
    this.activeIndex = event.row;
    this.activeId = this.data[this.activeIndex].id;
  }

  public deleteImportProfile(importProfile: ImportProfile) {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("import_profiles_delete_confirmation_question", importProfile.name))
      .open()
      .result.then(
      value => {
        if (value) {
          const index = this.data.indexOf(importProfile);
          const data = this.data;

          this._dynamicsImportsService.doDelete(importProfile.id).subscribe(() => {

            if (index > -1) {
              data.splice(index, 1);
            }

            this._toastr.success(this.localizationService.getLocalizedString("import_profiles_deleted"));
          },
            ex => {
              this.isBusy = false;
              this._toastr.error(this.localizationService.getLocalizedString("import_profiles_delete_failed"));

              this.loggingService.logErrorData(ex, "Import profile delete failed");
            });
        }
      });
  }


  public export(importProfile: ImportProfile) {
    this.isBusy = true;

    this._dynamicsImportsService.getImportProfileExport(importProfile.id)
      .subscribe(exportModel => {
        this.isBusy = false;

        if (exportModel.outputText && exportModel.outputText.length > 0) {
          const filename = `import-profile-${importProfile.code}.csv`;
          const blob = new Blob(["\ufeff", exportModel.outputText], { type: "text/csv;charset=utf-8" });

          saveAs(blob, filename);
        }
      },
      ex => {
        this.isBusy = false;
        this.loggingService.logErrorData(ex, "Import profile export failed.");

        this._toastr.error(this.localizationService.getLocalizedString("error_exporting_import_profile"));
      });
  }


  public import() {
    this._modal
      .open(ImportProfilesImportDialogComponent, overlayConfigFactory({}))
      .result.then(wasAnythingImported => {
        if (wasAnythingImported) {
          this.loadList();
        }
      }, ex => { });
  }


  setRowColoring(importProfile: ImportProfile): string {
    if (importProfile.customerDetailId === null) {
      return "global-profile-row";
    } else {
      return "";
    }
  }
}
