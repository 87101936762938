<div class="top">
  <h1>{{ 'transformation' | shpLocalize }}</h1>
  <button id="button-back" class="top-button" [routerLink]="['/import-profiles/transformations']"><span class="ic-arrow-left-1"></span><span class="label">{{ 'back' | shpLocalize}}</span></button>
  <button id="button-save" [disabled]="!hasUnsavedChanges() || !form || !form.valid" class="top-button" (click)="save()"><span class="ic-floppydisk"></span><span class="label">{{'save' | shpLocalize}}</span></button>
  <button id="button-delete" *ngIf="canDelete" class="top-button" (click)="delete()"><span class="ic-bin"></span><span class="label">{{'delete' | shpLocalize}}</span></button>
</div>
<div class="content-panel transformation-editor" *ngIf="model && form">
  <form novalidate [formGroup]="form">
    <div formGroupName="transformation">
      <div class="ctl required">
        <!-- <label for="transformation-name">{{ 'transformation_name' | shpLocalize }}</label>
        <input type="text" maxlength="255" formControlName="name" id="transformation-name" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" maxlength="255" formControlName="name" pInputText id="transformation-name" />
            <label for="transformation-name">{{ 'transformation_name' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl required">
        <!-- <label for="transformation-code">{{ 'transformation_code' | shpLocalize }}</label>
        <input type="text" maxlength="255" formControlName="code" id="transformation-code" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" maxlength="255" formControlName="code" pInputText id="transformation-code" />
            <label for="transformation-code">{{ 'transformation_code' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl">
        <!-- <label for="transformation-description">{{ 'transformation_description' | shpLocalize }}</label>
        <input type="text" maxlength="1000" formControlName="description" id="transformation-description" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input type="text" maxlength="1000" formControlName="description" pInputText id="transformation-description" />
            <label for="transformation-description">{{ 'transformation_description' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl">
        <!-- <label for="transformation-type">{{ 'transformation_type' | shpLocalize }}</label>
        <select formControlName="transformationTypeCode" id="transformation-transformationTypeCode">
          <option *ngFor="let transformationType of transformationTypes" [value]="transformationType.code">{{ 'import_profile_transformation_type_code_' + transformationType.name | shpLocalize }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select formControlName="transformationTypeCode" id="transformation-transformationTypeCode">
              <option *ngFor="let transformationType of transformationTypes" [value]="transformationType.code">{{ 'import_profile_transformation_type_code_' + transformationType.name | shpLocalize }}</option>
            </select>
            <label for="transformation-type">{{ 'transformation_type' | shpLocalize }}</label>
          </span>
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="selectedTransformationType">
      <div *ngSwitchCase="'Multiplication'">
        <shp-import-profile-transformation-data-multiplication></shp-import-profile-transformation-data-multiplication>
      </div>

      <div *ngSwitchDefault>
        <div class="ctl" formGroupName="transformation">
          <input type="checkbox" formControlName="isUnknownSourceValueAllowed" id="transformation-isUnknownSourceValueAllowed" />
          <label for="transformation-isUnknownSourceValueAllowed">{{ 'transformation_allow_unknown_values' | shpLocalize }}</label>
          <!-- <label for="transformation-isUnknownSourceValueAllowed"></label> -->
        </div>
        <h2>{{ 'transformation_value_mappings' | shpLocalize }}</h2>
        <div class="buttons">
          <button id="button-add-field" type="button" (click)="addEmptyFieldToForm()"><span class="ic-add-2"></span> {{ 'add' | shpLocalize }}</button>
        </div>
        <div class="transformation-editor-fields" formArrayName="fields">
          <div *ngFor="let field of form.controls.fields.controls; let i = index" [formGroupName]="i">
            <div>
              <input id="input-source-value" type="text" formControlName="sourceValue" />
              <span class="ic-arrow-right-1 ic-18"></span>
              <input id="input-destination-value" type="text" formControlName="destinationValue" />
              <button id="button-remove-field" type="button" (click)="removeFieldFromForm(i)"><span class="ic-bin"></span> {{ 'delete' | shpLocalize }}</button>
            </div>
            <div class="ctl">
              <em *ngIf="form.controls.fields.controls[i].touched && 
                         form.controls.fields.errors?.uniqueMapTransformationSourceValue[i]"
                class="error">
                {{ 'error_description_unique_map_transformation_source_value' | shpLocalize }}
              </em>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
