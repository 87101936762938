<form #f (submit)="save()" *ngIf="settings" class="">
  <shp-progress-overlay [isActive]="isBusy"></shp-progress-overlay>
  <div class="settings-category">
    <div class="settings-wrapper">
      <h1>{{ 'settings_automatic_rec_import' | shpLocalize }}</h1>
      <div class="ctl" *ngIf="settings.automated_recipients_import.isVisible">
        <input type="checkbox" name="automatedRecipientsImport" id="automated-recipients-import" [(ngModel)]="settings.automated_recipients_import.value" />
        <label for="automated-recipients-import" class="w-100">{{ 'settings_automated_recipients_import' | shpLocalize }}</label>
        <!-- <label for="automated-recipients-import"></label> -->
      </div>
      <div class="ctl" *ngIf="settings.automated_recipients_import_path.isVisible">
        <!-- <label for="automated-recipients-import-path">{{ 'settings_automated_recipients_import_path' | shpLocalize }}</label>
        <input type="text"
              name="automatedRecipientsImportPath" id="automated-recipients-import-path" [(ngModel)]="settings.automated_recipients_import_path.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="automated-recipients-import-path" [(ngModel)]="settings.automated_recipients_import_path.value" type="text" name="automatedRecipientsImportPath" pInputText autocomplete="off">
            <label for="automated-recipients-import-path">{{ 'settings_automated_recipients_import_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_recipients_import_periodicity_in_minutes.isVisible">
        <!-- <label for="automated-recipients-import-periodicity">{{ 'settings_automated_recipients_import_periodicity' | shpLocalize }}</label> -->
        <!-- <wj-input-number [(value)]="settings.automated_recipients_import_periodicity_in_minutes.value" name="syncRecipientsHour" id="automated-recipients-import-periodicity" [step]="1" [showSpinner]="true"
                        [min]="1" [max]="10080"></wj-input-number> -->

        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.automated_recipients_import_periodicity_in_minutes.value"
              [min]="1"
              [max]="10080"
              [step]="1"
              [showButtons]="true"
              name="syncRecipientsHour"
              id="automated-recipients-import-periodicity"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="automated-recipients-import-periodicity">{{ 'settings_automated_recipients_import_periodicity' | shpLocalize }}</label>
          </span>
        </div>

      </div>
      <div class="ctl" *ngIf="settings.automated_recipients_import_use_semaphore_files.isVisible">
        <input type="checkbox" name="automatedRecipientsImportUseSemaphoreFiles" id="automated-recipients-import-use-semaphore-files" [(ngModel)]="settings.automated_recipients_import_use_semaphore_files.value" />
        <label for="automated-recipients-import-use-semaphore-files" class="w-100">{{ 'settings_automated_recipients_import_use_semaphore_files' | shpLocalize }}</label>
        <!-- <label for="automated-recipients-import-use-semaphore-files"></label> -->
      </div>
      <div class="ctl" *ngIf="settings.automated_recipients_import_profile_code.isVisible">
        <!-- <label for="automated-recipients-import-profile-code">{{ 'settings_automated_recipients_import_profile' | shpLocalize }}</label>
        <select name="automatedRecipientsImportProfileCode" id="automated-recipients-import-profile-code" [(ngModel)]="settings.automated_recipients_import_profile_code.value">
          <option [ngValue]="importProfileDefault.code">{{ 'import_profile_default' | shpLocalize }}</option>
          <option [value]="imp.code" *ngFor="let imp of importProfiles">{{imp.name}}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select name="automatedRecipientsImportProfileCode" id="automated-recipients-import-profile-code" [(ngModel)]="settings.automated_recipients_import_profile_code.value">
              <option [ngValue]="importProfileDefault.code">{{ 'import_profile_default' | shpLocalize }}</option>
              <option [value]="imp.code" *ngFor="let imp of importProfiles">{{imp.name}}</option>
            </select>
            <label for="automated-recipients-import-profile-code">{{ 'settings_automated_recipients_import_profile' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_recipients_import_delete_file_after_processing.isVisible">
        <input type="checkbox" name="automatedRecipientsImportDeleteFileAfterProcessing" id="automated-recipients-import-delete-file-after-processing" [(ngModel)]="settings.automated_recipients_import_delete_file_after_processing.value" />
        <label for="automated-recipients-import-delete-file-after-processing" class="w-100">{{ 'settings_automated_recipients_import_delete_file_after_processing' | shpLocalize }}</label>
        <!-- <label for="automated-recipients-import-delete-file-after-processing"></label> -->
      </div>
    </div><!-- .settings-wrapper -->
    <div class="commands-toolbar">
      <button id="button-save" type="submit"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
    </div>
  </div>
</form>