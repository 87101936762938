<shp-shipment-editor-parcel
  *ngFor="let parcel of parcels | async; index as i; first as isFirst"
  [areCustomsClearanceFieldsHidden]="!isCustomsClearance"
  [isCustomsClearanceBoundFieldsRequiredValidationOn]="isCustomsClearanceBoundFieldsRequiredValidationOn"
  [areDimensionEditorsMandatory]="areDimensionEditorsMandatory"
  [areDimensionsFieldsHidden]="isSameDimensionsForAllParcels && !isFirst"
  [countries]="countries"
  [customsAmountCurrencyCode]="customsAmountCurrencyCode"
  [hideDimensionEditors]="hideDimensionEditors"
  [index]="i"
  [isBrexitCountryRecipient]="isBrexitCountryRecipient"
  [isDpdPreciseProduct]="isDpdPreciseProduct"
  [isParcelLengthFirstDimension]="isParcelLengthFirstDimension"
  [isReadOnly]="isReadOnly"
  [isSameDimensionsForAllParcels]="isSameDimensionsForAllParcels"
  [minLimitDate]="minLimitDate"
  [parcel]="parcel"
  [recipientCountryCode]="recipientCountryCode"
  [selectedProduct]="selectedProduct"
  [senderCountryCode]="senderCountryCode"
  [showGoodsFields]="showGoodsFields"
  [showLabels]="showLabels"
  [tooltips]="tooltips"
  (customsAmountChange)="onParcelCustomsAmountChange()"
  (weightChange)="onParcelWeightChange()"
  entityValidatorArray></shp-shipment-editor-parcel>
