import { EventEmitter, Injectable, Output } from "@angular/core";

import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";

import * as Shared from "../../shared/index";
import { UserRelatedItemModel } from "../../shared/models/user.model";


@Injectable()
export abstract class UserRelatedItemListEditorBase {
  multipleSelectModel = false;
  id: string;
  items: UserRelatedItemModel[] = [];
  selectedItems: UserRelatedItemModel[] = [];

  @Output() selectionChange = new EventEmitter<UserRelatedItemModel[]>();

  selectionChange$ = new Subject<UserRelatedItemModel[]>();

  private _originalChangeKey: string;
  private _currentChangeKey: string;
  private _isBusy = false;

  constructor(
    private _loadItemsFunc: (userId: number) => Observable<UserRelatedItemModel[]>,
    private _saveItemsFunc: (items: UserRelatedItemModel[]) => Observable<UserRelatedItemModel[]>,
    private _saveItemsSuccessMessageResourceKey: string,
    private _loggingService: Shared.LoggingService,
    private _exceptionHandlerService: Shared.ExceptionsHandlerService,
    private _localizationService: Shared.LocalizationService,
    private _toastr: ToastrService,
  ) {
    this.id = _.uniqueId("multiple-select-");
  }


  get hasChanges(): boolean {
    this._currentChangeKey = this.computeChangeKey();
    return this._originalChangeKey !== this._currentChangeKey;
  }


  get saveChangesEnabled(): boolean {
    return !this._isBusy && this.hasChanges;
  }


  get isBusy(): boolean {
    return this._isBusy;
  }

  saveChanges(): void {
    this._isBusy = true;
    this._saveItemsFunc(this.items)
      .subscribe(
        (newItems: UserRelatedItemModel[]) => this.handleSaveChangesSuccess(newItems),
        (error: any) => this.handleSaveChangesError(error));
  }

  updateSelectModel(event) {
    this.items.map(item => {
      item.isItemEnabled = !!_.find(this.selectedItems, selectedItem => selectedItem.itemId === item.itemId);
    });

    this.selectionChange.emit(this.items);
    this.selectionChange$.next(this.items);
  }

  protected setUserId(newId: number) {
    if (!newId) {
      this.onNewItemsLoaded([]);
      return;
    }

    this._isBusy = true;
    this._loadItemsFunc(newId)
      .subscribe((newItems: UserRelatedItemModel[]) => this.onNewItemsLoaded(newItems));
  }


  private resetChangeKeys() {
    this._currentChangeKey = this._originalChangeKey = this.computeChangeKey();
  }


  private onNewItemsLoaded(newItems: UserRelatedItemModel[]) {
    this.items = newItems || [];

    this.selectedItems = _.filter(this.items, "isItemEnabled");
    this.resetChangeKeys();
    this.selectionChange.emit(this.items);
    this._isBusy = false;
  }


  private computeChangeKey(): string {
    return this.items
      .map(i => `${i.itemId}${i.isItemEnabled}`)
      .join();
  }


  private handleSaveChangesSuccess(newItems: UserRelatedItemModel[]) {
    this.onNewItemsLoaded(newItems);

    this._toastr.success(this._localizationService.getLocalizedString(this._saveItemsSuccessMessageResourceKey));
    this._isBusy = false;
  }


  private handleSaveChangesError(error: any) {
    this._loggingService.logErrorData(error);
    const exception = this._exceptionHandlerService.getExceptionInfo(error);

    this._toastr.error(this._localizationService.getLocalizedExceptionString(exception));
    this._isBusy = false;
  }
}
