import { Component, OnInit, OnDestroy } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import * as Shared from "../../shared/index";
import { ColorThemeService } from "../services/color-theme.service";
import * as _ from "lodash";


@Component({
  selector: Shared.SELECTOR_PREFIX + "-color-theme-select",
  templateUrl: "./color-theme-select.component.html"
})
export class ColorThemeSelect implements OnInit, OnDestroy {
  
  themes: any[];
  currentTheme: string;

  private _destroy$ = new Subject<void>();


  constructor(
      private _colorThemeService: ColorThemeService) {
    // this.themes = [...this._colorThemeService.themes]
    this.themes = this._colorThemeService.themes.map(theme => {
      return {
        themeClass: theme,
        tooltip: this.computeThemeTooltipText(theme)
      }
    });
  }


  ngOnInit() {
    this._colorThemeService.currentTheme$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(theme => this.currentTheme = theme);
  }


  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


  setCurrentTheme(name: string) {
    this._colorThemeService.setCurrentTheme(name);
  }


  private computeThemeTooltipText(name: string): string {
    return "color_" + _.snakeCase(name)
  }
}