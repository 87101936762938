import { AbstractControl, FormArray } from "@angular/forms";
import * as _ from "lodash";

export const nullItemCode = "-";


export function amendValueForFormControl(value: any) {
  if (typeof value === "undefined" || value == null) {
    return nullItemCode;
  }

  return value;
}


export function amendValueForFormattedFormControl(value: any) {
  if (typeof value === "undefined" || value == null) {
    return "";
  }

  return value;
}


export function amendValueForModel(value: any) {
  if (value === nullItemCode) {
    return null;
  }

  return value;
}


export function amendValueForModelFromFormattedFormControl(value: any) {
  if (value === "") {
    return null;
  }

  return value;
}


export function hasModelChanges(pristine: any, current: any): boolean {
  return !_.isEqualWith(
    pristine,
    current,
    (p, c) => Array.isArray(p) && Array.isArray(c) ? _.isEqual(p.sort((a, b) => a - b), c.sort((a, b) => a - b)) : undefined
  );
}


export function claerFormArray(formArray: FormArray) {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
}


export function mergeModelWithFormValue(model, value) {
  return _.mergeWith(model, value, (m, v) => {
    if (Array.isArray(m)) {
      return v;
    }
  });
}


export function getMergedModelWithFormValue(model, value) {
  return _.mergeWith(_.cloneDeep(model) ?? {}, value, (m, v) => {
    if (Array.isArray(m)) {
      return v;
    }
  });
}


export function isEmptyInputValue(value: any): boolean {
  return value == null ||
      ((typeof value === "string" || Array.isArray(value)) && value.length === 0);
}


export function toggleControl(ctrl: AbstractControl, isEnabled: boolean) {
  if (!ctrl) {
    return;
  }

  if (ctrl.enabled) {
    if (!isEnabled) {
      ctrl.disable();
    }
  } else {
    if (isEnabled) {
      ctrl.enable();
    }
  }
}
