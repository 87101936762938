import { Directive, ElementRef, Input, Inject, Optional, OnDestroy, AfterViewInit, Attribute, SkipSelf } from "@angular/core";

import { NgControl, ControlContainer, ValidationErrors, ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl } from "@angular/forms";
import { EntityFormValidationService } from "../services/entity-validator.service";
import { Observable } from "rxjs";


@Directive({
  selector: `[ngModelGroup], [formGroupName], form:not([ngNoForm]):not([formGroup]), [formGroup], [formArrayName], [ngModel], [formControl], [formControlName]`
})
export class ControlElementDirective implements AfterViewInit, OnDestroy {

  control: AbstractControl;

  @Input() validationFocusElement: HTMLElement;

  private _name: string;
  private _isNested = false;

  constructor(
      @Optional() private ngControl: NgControl,
      @Optional() private container: ControlContainer,
      @Optional() @SkipSelf() @Inject(NG_VALUE_ACCESSOR) controlValueAccessors: ControlValueAccessor[],
      public host: ElementRef,
      @Attribute("entity-validation-group") private groupName: string,
      @Optional() private entityValidator: EntityFormValidationService,
      @Optional() private entityValidatorPrefix: EntityValidatorPrefixDirective,
      @Optional() private entityValidatorOrder: EntityValidatorOrderDirective,
      @Optional() private entityValidatorArray: EntityValidatorArrayDirective) {
    /**
     * Mark that AbstractControlDirective is used within a ControlValueAccessor
     * (custom made components). Nested AbstractControlDirective won't register
     * to EntityFormValidationService because validation should be handled
     * on parent's level.
     */
    if (controlValueAccessors && controlValueAccessors.length) {
      this._isNested = true;
    }
  }

  get name(): string {
    return this.groupName || this.host.nativeElement.getAttribute("name") || this._name;
  }

  get namePrefix(): string {
    return this.entityValidatorPrefix ? this.entityValidatorPrefix.entityValidatorPrefix : null;
  }

  get focusElement(): HTMLElement {
    return this.validationFocusElement || this.host.nativeElement;
  }

  get changes$(): Observable<any> {
    return this.control.statusChanges;
  }

  get valid(): boolean {
    return this.control.valid;
  }

  get invalid(): boolean {
    return this.control.invalid;
  }

  get errors(): ValidationErrors | null {
    return this.control.errors;
  }


  get ordinal(): number | null {
    return this.entityValidatorOrder ? Number(this.entityValidatorOrder.entityValidatorOrder) : null;
  }


  ngAfterViewInit() {
    Promise.resolve().then(() => {
      const controlDirective: NgControl | ControlContainer = this.ngControl || this.container;
      this.control = controlDirective.control;
      this._name = controlDirective.name as string;

      if (!this._isNested && this.entityValidatorArray) {
        this._name = this._name.replace(/\d+/g, "");
      }

      if (!this._isNested && this.entityValidator) {
        this.entityValidator.registerControl(this);
      }
    });
  }


  ngOnDestroy() {
    if (!this._isNested && this.entityValidator) {
      this.entityValidator.removeControl(this);
    }
  }
}



@Directive({
  selector: `[entityValidatorArray]`
})
export class EntityValidatorArrayDirective {

}


@Directive({
  selector: `[entityValidatorPrefix]`
})
export class EntityValidatorPrefixDirective {

  @Input() entityValidatorPrefix: string;
}


@Directive({
  selector: `[entityValidatorOrder]`
})
export class EntityValidatorOrderDirective {

  @Input() entityValidatorOrder: number;
}
