<form #f (submit)="save(); saveExportProfile();" *ngIf="settings">
  <shp-progress-overlay [isActive]="isBusy || isBusyExportProfile"></shp-progress-overlay>
  <div class="settings-category">
    <div class="settings-wrapper">
      <h1>{{ 'settings_automatic_export' | shpLocalize }}</h1>
      <!-- replaced "settings_automated_shipments_export" (backlog item 5319) -->
      <div class="ctl" *ngIf="settings.automated_shipments_export_time_mode.isVisible">
        <!-- <label for="automated-shipments-export-time-mode">{{ 'settings_automated_shipments_export_time_mode' | shpLocalize }}</label>
        <select name="automatedShipmentsExportTimeMode" id="automated-shipments-export-time-mode" [(ngModel)]="settings.automated_shipments_export_time_mode.value">
          <option *ngFor="let timeMode of timeModes" [ngValue]="timeMode">{{ 'automated_shipments_export_time_mode_' + timeMode | shpLocalize }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <label for="automated-shipments-export-time-mode">{{ 'settings_automated_shipments_export_time_mode' | shpLocalize }}</label>
            <select name="automatedShipmentsExportTimeMode" id="automated-shipments-export-time-mode" [(ngModel)]="settings.automated_shipments_export_time_mode.value">
              <option *ngFor="let timeMode of timeModes" [ngValue]="timeMode">{{ 'automated_shipments_export_time_mode_' + timeMode | shpLocalize }}</option>
            </select>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="settings.automated_shipments_export_path.isVisible">
        <!-- <label for="automated-shipments-export-path">{{ 'settings_automated_shipments_export_path' | shpLocalize }}</label>
        <input type="text"
              name="automatedShipmentsExportPath" id="automated-shipments-export-path" [(ngModel)]="settings.automated_shipments_export_path.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="automated-shipments-export-path" type="text" name="automatedShipmentsExportPath" pInputText [(ngModel)]="settings.automated_shipments_export_path.value">
            <label for="automated-shipments-export-path">{{ 'settings_automated_shipments_export_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="ctl" *ngIf="settings.automated_shipments_export_file_mask.isVisible">
        <!-- <label for="automated-shipments-export-file-mask">{{ 'settings_automated_shipments_export_file_mask' | shpLocalize }}</label>
        <input type="text"
              name="automatedShipmentsExportFileMask" id="automated-shipments-export-file-mask" [(ngModel)]="settings.automated_shipments_export_file_mask.value" /> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <input id="automated-shipments-export-file-mask" type="text" name="automatedShipmentsExportFileMask" pInputText [(ngModel)]="settings.automated_shipments_export_file_mask.value">
            <label for="automated-shipments-export-file-mask">{{ 'settings_automated_shipments_export_file_mask' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="settings.automated_shipments_export_periodicity_in_minutes.isVisible">
        <!-- <label for="automated-shipments-export-periodicity">{{ 'settings_automated_shipments_export_periodicity' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.automated_shipments_export_periodicity_in_minutes.value" name="syncShipmentsHour" id="automated-shipments-export-periodicity" [step]="1" [showSpinner]="true"
                        [min]="1" [max]="10080"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.automated_shipments_export_periodicity_in_minutes.value"
              [min]="1"
              [max]="10080"
              [step]="1"
              name="syncShipmentsHour"
              id="automated-shipments-export-periodicity"
              mode="decimal"
              [showButtons]="true"
              [maxFractionDigits]="0"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="automated-shipments-export-periodicity">{{ 'settings_automated_shipments_export_periodicity' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="settings.automated_shipments_export_hour.isVisible">
        <!-- <label for="automated-shipments-export-hour">{{ 'settings_automated_shipments_export_hour' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.automated_shipments_export_hour.value" name="automatedShipmentsExportHour" id="automated-shipments-export-hour" [step]="1" [showSpinner]="true"
                          [min]="0" [max]="23"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.automated_shipments_export_hour.value"
              [min]="0"
              [max]="23"
              [step]="1"
              name="automatedShipmentsExportHour"
              id="automated-shipments-export-hour"
              mode="decimal"
              [showButtons]="true"
              [maxFractionDigits]="0"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="automated-shipments-export-hour">{{ 'settings_automated_shipments_export_hour' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <div class="ctl" *ngIf="settings.automated_shipments_export_minute.isVisible">
        <!-- <label for="automated-shipments-export-minute">{{ 'settings_automated_shipments_export_minute' | shpLocalize }}</label>
        <wj-input-number [(value)]="settings.automated_shipments_export_minute.value" name="automatedShipmentsExportMinute" id="automated-shipments-export-minute" [step]="1" [showSpinner]="true"
                          [min]="0" [max]="59" format="D2"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              [(ngModel)]="settings.automated_shipments_export_minute.value"
              [min]="0"
              [max]="59"
              [step]="1"
              name="automatedShipmentsExportMinute"
              id="automated-shipments-export-minute"
              mode="decimal"
              [showButtons]="true"
              [maxFractionDigits]="0"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="automated-shipments-export-minute">{{ 'settings_automated_shipments_export_minute' | shpLocalize }}</label>
          </span>
        </div>
      </div>

      <ng-container *ngIf="isCustomShipmentsExportContentEnabled">
        <h2 class="my-3">{{ 'settings_export_file' | shpLocalize }}</h2>

        <shp-parcel-export-settings-fields [model]="exportProfileModel" #editor></shp-parcel-export-settings-fields>
      </ng-container>

    </div>

    <div class="commands-toolbar">
      <button id="button-save" type="submit" [disabled]="isCustomShipmentsExportContentEnabled && !exportSettingsEditor?.isValid"><span class="ic-floppydisk"></span> {{ 'settings_save' | shpLocalize }}</button>
    </div>
  </div>
</form>
