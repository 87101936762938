import { ShipperTenantVersionInfoText } from "./shipper-tenant-version-info-text.model";

export interface UpdateInfo {
  version: string;
  versionReleaseDate: string | Date;
  currentVersion: string;
  versionInfoTexts: ShipperTenantVersionInfoText[];
  isUpdateAvailable: boolean;
  isDeprecated: boolean;
  updateUrl: string;
  notifyEveryMinutes: number;
}

export const defaultNotificationTimerMinutes = 30; // Every 30 minutes

export function newUpdateInfo(): UpdateInfo {
  return {
    version: "0.0.0.0",
    versionReleaseDate: null,
    currentVersion: "0.0.0.0",
    versionInfoTexts: [],
    isUpdateAvailable: false,
    isDeprecated: false,
    updateUrl: "",
    notifyEveryMinutes: defaultNotificationTimerMinutes
  };
}
