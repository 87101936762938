<div class="content-panel support">
  <div class="support-categories-menu">
    <div class="d-flex align-items-center">
      <h1>{{ 'support' | shpLocalize }}</h1>
    </div>
    <ul>
      <li><a [routerLink]="['/support/manual']" [routerLinkActive]="['selected']">{{ 'manuals' | shpLocalize }}</a></li>
      <li *ngIf="isFaqDisplayed"><a [routerLink]="['/support/faq']" [routerLinkActive]="['selected']">{{ 'support_faq' | shpLocalize }}</a></li>
      <li *ngIf="isContactDisplayed"><a [routerLink]="['/support/contact']" [routerLinkActive]="['selected']">{{ 'support_contact' | shpLocalize }}</a></li>
      <li><a [routerLink]="['/support/change-log']" [routerLinkActive]="['selected']">{{ 'change_log' | shpLocalize }}</a></li>
      <li *ngIf="isWizardDisplayed"><a href="#" (click)="startWizard(); $event.preventDefault()">{{ 'start_wizard' | shpLocalize }}</a></li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>
