import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { DndModule } from "ng2-dnd";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";

import { UnreadNotificationsCounterCompoenent } from "../notifications/components/unread-notifications-counter.component";
import { AppInfoComponent } from "./components/app-info.component";
import { AppUpdateInfoDialog } from "./components/app-update-info-dialog.component";
import { ColorThemeSelect } from "./components/color-theme-select.component";
import { DragAndDropListComponent } from "./components/drag-and-drop-list.component";
import { EntityValidationToast } from "./components/entity-validation-toast.component";
import { ExtendedErrorToastrComponent } from "./components/extended-error-toastr.component";
import { NavigationComponent } from "./components/navigation.component";
import { NewVersionInfoDialog } from "./components/new-version-info-dialog.component";
import { NewsfeedMessageDialogComponent } from "./components/newsfeed-message-dialog.component";
import { NewsfeedToast } from "./components/newsfeed-toast.component";
import { PhoneNumberInputComponent } from "./components/phone-number-input.component";
import { PickupAddressSelectorDialogComponent } from "./components/pickup-address-selector-dialog";
import { AppTableSettingsDialog } from "./components/table-settings-dialog.component";
import { TemplateContentEditorComponent } from "./components/template-content-editor.component";
import { UniversalNumberInputComponent } from "./components/universal-number-input.component";
import { ShipperWizardPopupComponent } from "./components/wizard-components/shipper-wizard-popup.component";
import { ShipperWizardTestLabelPopupComponent } from "./components/wizard-components/shipper-wizard-test-label-popup.component";
import { WizardChaptersPreviewComponent } from "./components/wizard-components/wizard-chapters-preview.component";
import { ShipperWizardNavigationComponent } from "./components/wizard-components/wizard-navigation.component";
import { AlphanumericValidatorDirective } from "./directives/alphanumeric-validator.directive";
import { ControlElementDirective, EntityValidatorArrayDirective, EntityValidatorOrderDirective, EntityValidatorPrefixDirective } from "./directives/control-element.directive";
import { CustomsTariffNumberValidatorDirective } from './directives/customs-tariff-number-validator.directive';
import { DeselectableRadioButtonDirective } from './directives/deselectable-radio-button.directive';
import { DifferentFromValidatorDirective } from "./directives/different-from-validator.directive";
import { EmailValidatorDirective } from "./directives/email-validator.directive";
import { EqualValidatorDirective } from "./directives/equal-validator.directive";
import { FloatingLabelControlDirective, FloatingLabelDirective } from "./directives/floating-label.directive";
// import { AuthenticatedHttp } from "./authenticated-http"
import { FocusDirective } from "./directives/focus.directive";
import { FromatInputDirective } from "./directives/format-input.directive";
import { IbanInputFormatterDirective } from "./directives/iban-input-formatter.directive";
import { IbanValidatorDirective } from "./directives/iban-validator.directive";
import { InputOnlyNumbersDirective } from "./directives/input-only-numbers.directive";
import { MultipleEmailValidatorDirective } from "./directives/multiple-email-validator.directive";
import { PollyDialogTriggerDirective } from "./directives/open-polly-dialog-trigger.directive";
import { PhoneNumberValidatorDirective } from "./directives/phone-number-validator.directive";
import { SetCheckboxValuesDirective } from './directives/set-checkbox-values.directive';
import { ScrollToElementDirective } from "./directives/srcroll-to-element.directive";
import * as Shared from "./index";
import { CustomPrimengModule } from "./modules/custom-primeng/custom-primeng.module";
import { WizardModule } from "./modules/wizard/wizard.module";
// import { ShipperTooltipDirective } from "./directives/shipper-tooltip.directive";
import { ColumnDirective } from "./tables/column.directive";
import { RowDirective } from "./tables/row.directive";
import { TableDirective } from "./tables/table.directive";

// import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
// import { WjInputModule } from '@grapecity/wijmo.angular2.input';



const components = [
  AppInfoComponent,
  AppTableSettingsDialog,
  AppUpdateInfoDialog,
  ColorThemeSelect,
  DeselectableRadioButtonDirective,
  DifferentFromValidatorDirective,
  DragAndDropListComponent,
  EmailValidatorDirective,
  EntityValidationToast,
  EqualValidatorDirective,
  ExtendedErrorToastrComponent,
  FocusDirective,
  FromatInputDirective,
  InputOnlyNumbersDirective,
  MultipleEmailValidatorDirective,
  NavigationComponent,
  NewVersionInfoDialog,
  NewsfeedMessageDialogComponent,
  NewsfeedToast,
  PhoneNumberInputComponent,
  PickupAddressSelectorDialogComponent,
  PollyDialogTriggerDirective,
  SetCheckboxValuesDirective,
  Shared.LanguageBarComponent,
  Shared.ProgressOverlayComponent,
  Shared.RetryOverlayCompoent,
  ShipperWizardNavigationComponent,
  ShipperWizardPopupComponent,
  ShipperWizardTestLabelPopupComponent,
  TemplateContentEditorComponent,
  UniversalNumberInputComponent,
  UnreadNotificationsCounterCompoenent,
  WizardChaptersPreviewComponent,
];


const pipes = [
  Shared.LocalizePipe,
  Shared.DurationPipe,
  Shared.LocalizeDatePipe,
  Shared.NumeralPipe,
  Shared.ExceptionInfoPipe,
  Shared.FullAddressPipe,
  Shared.IbanPipe,
  Shared.LocalizedPartOfStringPipe
];


@NgModule({
  imports: [
    // HttpClientModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    // WjInputModule, // Kvôli inputu tel. čísla.
    // WjInputModule,
    // WjCoreModule,
    DndModule,
    WizardModule,
    PortalModule,
    CustomPrimengModule
  ],
  declarations: [
    ...components,
    ...pipes,
    FocusDirective,
    EmailValidatorDirective,
    EqualValidatorDirective,
    FloatingLabelControlDirective,
    FloatingLabelDirective,
    InputOnlyNumbersDirective,
    IbanValidatorDirective,
    IbanInputFormatterDirective,
    // ShipperTooltipDirective, //Not needed as we use PrimeNg tooltips
    ColumnDirective,
    RowDirective,
    TableDirective,
    ControlElementDirective,
    EntityValidatorArrayDirective,
    EntityValidatorPrefixDirective,
    EntityValidatorOrderDirective,
    PhoneNumberValidatorDirective,
    ScrollToElementDirective,
    AlphanumericValidatorDirective,
    CustomsTariffNumberValidatorDirective
  ],
  providers: [
    Shared.ApiHeartbeatService,
    Shared.ContextService,
    Shared.GlobalEventsStreamService,
    Shared.SettingsService,
    Shared.SkdataConfigService,
    Shared.CustomerService,
    Shared.AuthorizationGuard,
    Shared.UnsavedChangesGuard,
    Shared.GlobalActivationGuard,
    Shared.UnchangedPasswordGuard,
    Shared.ExceptionsHandlerService,
    Shared.BusinessUnitSettingsService,
    Shared.IbanPipe,
    Shared.LocalizeDatePipe,
    Shared.LinkService,
    Shared.ColorThemeService,
    // Shared.ShipperTooltipService, //Not needed as we use PrimeNg tooltips
    Shared.ShipperFileReaderService,
    Shared.NewsfeedService,
    Shared.PasswordService,
    Shared.PollyService,
    Shared.ShipperTenantVersionInfoService

  ],
  exports: [
    ...components,
    ...pipes,
    FocusDirective,
    EmailValidatorDirective,
    EqualValidatorDirective,
    InputOnlyNumbersDirective,
    FloatingLabelControlDirective,
    FloatingLabelDirective,
    IbanValidatorDirective,
    IbanInputFormatterDirective,
    // ShipperTooltipDirective,
    ColumnDirective,
    RowDirective,
    TableDirective,
    ControlElementDirective,
    EntityValidatorArrayDirective,
    EntityValidatorPrefixDirective,
    EntityValidatorOrderDirective,
    PhoneNumberValidatorDirective,
    ScrollToElementDirective,
    AlphanumericValidatorDirective,
    WizardModule,
    CustomPrimengModule,
    CustomsTariffNumberValidatorDirective
  ],
  entryComponents: [
    PickupAddressSelectorDialogComponent,
    AppUpdateInfoDialog,
    AppTableSettingsDialog,
    EntityValidationToast,
    ExtendedErrorToastrComponent,
    NewsfeedToast,
    NewsfeedMessageDialogComponent,
    NewVersionInfoDialog
  ]
})
export class SharedModule {

}
