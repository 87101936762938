import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";

import * as _ from "lodash";
import { forkJoin } from "rxjs";

import * as Shared from "../index";
import * as ConfigModel from "../models/config.models";


const PHONE_NUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneNumberInputComponent),
  multi: true
};


const noop = () => { };


@Component({
  selector: Shared.SELECTOR_PREFIX + "-phone-number-input",
  templateUrl: "./phone-number-input.component.html",
  providers: [PHONE_NUMBER_VALUE_ACCESSOR],
  exportAs: "phoneNumberInput"
})
export class PhoneNumberInputComponent implements ControlValueAccessor, OnInit {

  constructor(
    private _skdataConfigService: Shared.SkdataConfigService
  ) {

  }


  @Input()
  public set defaultCountryCode(code: string) {
    const defaultCountryCodeBackup = this._defaultCountryCode;
    this._defaultCountryCode = code;

    // Pokiaľ je telefónne číslo za predvoľbou prázdne, tak meníme predvoľbu podľa vybranej krajiny
    if (defaultCountryCodeBackup !== code && (this._number === null || this._number === "")) {
      this.setValueInternal(null);
    }
  }


  @Input()
  public set id(id: string) {
    this._id = id;
  }


  public get id(): string {
    return this._id;
  }


  /**
   * Celé telefónne číslo.
   */
  public get value(): string {
    // Ak máme ktorúkoľvek hodnotu prázdnu alebo null, tak vraciame null.
    // Toto by malo vyhovovať required validátorom.
    if (
      this._number == null ||
      this._number === "" ||
      this._prefix == null ||
      this._prefix === "") {
      return null;
    }

    if (typeof this._prefix === "object") {
      const prefixCode =  (this._prefix as ConfigModel.InternationalCallingCode).callingCode as string;
      return prefixCode + this._number;
    }

    return this._prefix + this._number;
  }


  public set value(tel: string) {
    if (tel !== this._value) {
      this.setValueInternal(tel);
      this.onChangeCallback(tel);
    }
  }


  /**
   * Medzinárodná predvoľba z čísla.
   */
  public get prefix(): string | ConfigModel.InternationalCallingCode {
    return this._prefix;
  }


  public set prefix(prefix: string | ConfigModel.InternationalCallingCode) {
    if (this._ignoreUpdate) {
      return;
    }

    if (this._prefix !== prefix) {
      this._prefix = prefix;
      this.onChangeCallback(this.value);
    }
  }


  /**
   * "Lokálna" časť tel. čísla.
   */
  public get number(): string {
    return this._number;
  }


  public set number(value: string) {
    // Odstránenie nečíselných znakov.
    value = value.replace(/\D/g, "");

    if (this._number !== value) {
      this._number = value;
      this.onChangeCallback(this.value);
    }
  }

  private _prefix: string | ConfigModel.InternationalCallingCode = null;
  private _number: string = null;
  private _value: string = null; // Full number including the calling code.
  private _defaultCountryCode: string = null;
  private _id = ""; // HTML ID elementu

  public prefixControl: FormControl = new FormControl();

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public callingCodes: ConfigModel.InternationalCallingCode[] = [];
  public callingCodesSortedForSelect: ConfigModel.InternationalCallingCode[] = [];

  /**
   * Toto je kvôli použitiu Wijmo combobox kontrolky, lebo tá ak sa jej mení zoznam možných
   * hodnôt, tak prepíše aj označenú hodnotu.
   * Nastavujeme na false po načítaní zoznamu predvolieb všetkých krajín.
   */
  private _ignoreUpdate = true;


  public isDisabled = false;


  private getDefaultPrefix(): string {
    if (this._defaultCountryCode == null) {
      return null;
    }

    const callingCode = this.callingCodes.find(cc => cc.countryCode === this._defaultCountryCode);

    if (callingCode) {
      return callingCode.callingCode;
    }

    return "+1"; // NA default.
  }


  private splitTelephoneNumberParts(tel: string): [string, string] {
    // Musíme prehľadať tabuľku predvolieb, máme ju zoradenú podľa dĺžky predvoľby, aby sme najprv trafili
    // tie, ktorým iné predvoľby tvoria prefix - napríklad +1684 je Americká Samoa, ale +1 sú USA.
    // Počítame tiež s tým, že tabuľka prefixov obsahuje prefix vo formáte +00..., nie sú tam už žiadne iné znaky.

    if (typeof tel === "undefined" || tel == null || tel === "") {
      return [this.getDefaultPrefix(), null];
    }

    // Prebehneme všetky predvoľby a hľadáme prvú, ktorá sedí.
    for (const cc of this.callingCodes) {
      // Máme iba predvoľbu.
      if (tel === cc.callingCode) {
        return [tel, null];
      }

      if (tel.startsWith(`${cc.callingCode}`)) {
        return [tel.substr(0, cc.callingCode.length), tel.substr(cc.callingCode.length)];
      }
    }

    // Žiadna predvoľba nevyhovovala, tak vraciame prázdnu predvoľbu a zvyšok ako samotné tel. číslo.
    return [null, tel];
  }


  /**
   * Nastaví hodnotu tel čísla a rozparsuje ho
   * na jednotlivé časti. Nerobí notifikáciu o zmene.
   *
   * @param tel Telefónne číslo, ktoré chceme editovať.
   */
  private setValueInternal(tel: string) {
    let prefix: string;
    let phoneNumber: string;

    this._value = tel;

    [prefix, phoneNumber] = this.splitTelephoneNumberParts(tel);

    if (typeof prefix === "string") {
      const prefixObj = this.callingCodesSortedForSelect.find(cc => cc.callingCode === prefix);
      this._prefix = prefixObj;
    }

    this._number = phoneNumber;
  }


  /**
   * Funkcia by mala byť volaná po blur evente jednotlivých inputov v rámci tohto komponentu.
   */
  public onBlur() {
    this.onTouchedCallback();
  }


  public writeValue(tel): void {
    this.setValueInternal(tel);
  }


  public registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn;
  }


  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }


  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }


  private loadInternationalTelephoneNumberPrefixesAndCountries() {
    forkJoin([
      this._skdataConfigService.getInternationalCountryCallingCodes(),
      this._skdataConfigService.getCountries()
    ])
      .subscribe((result: [ConfigModel.InternationalCallingCode[], Shared.Country[]]) => {
        let callingCodes = result[0];
        const countries = result[1];

        // Odstránime - a pridáme + na začiatok.
        callingCodes.forEach((cc: ConfigModel.InternationalCallingCode) => {
          cc.callingCode = cc.callingCode.replace("-", "");
          cc.callingCode = `+${cc.callingCode}`;

          // Chceme aj názov krajiny.
          const country = countries.find(c => c.code === cc.countryCode);

          cc.textForSearch = cc.callingCode;

          if (country) {
            cc.countryName = country.name;
            cc.textForSearch = `${cc.textForSearch} ${country.name}`;
          } else {
            // HACK: Kosovo a Francúzske Antily nám chýbajú v DB možno.
            if (cc.callingCode === "+383") {
              cc.countryName = "KOSOVO";
            }

            if (cc.callingCode === "+590") {
              cc.countryName = "FRENCH ANTILLES";
            }
          }
        });

        // Zoradíme podľa dĺžky od najdlhšieho po najkraší reťazec a sekundárne podľa abecedy.
        callingCodes = callingCodes.sort((a: ConfigModel.InternationalCallingCode, b: ConfigModel.InternationalCallingCode) => {
          return b.callingCode.length - a.callingCode.length || a.callingCode.localeCompare(b.callingCode);
        });

        this.callingCodes = callingCodes;

        // Pre zoznam chceme podľa abecedy.
        this.callingCodesSortedForSelect = _.orderBy(callingCodes, ["countryName"]);

        const currentValue = this._value;

        // Ak už sme medzitým dostali nejaké číslo, tak to skúsime znovu rozparsovať.
        // Timeout je kvôli Wijmo, lebo nastavenie hodnôt do zoznamu prepíše aj aktuálnu hodnotu.
        window.setTimeout(() => {
          this._ignoreUpdate = false;
          this.setValueInternal(currentValue);
        }, 0);
      });
  }


  public ngOnInit(): void {
    this.loadInternationalTelephoneNumberPrefixesAndCountries();
  }


  public numberPartKeyDown(e: KeyboardEvent) {
    if (
      // Povoliť: backspace, delete, tab, escape, enter.
      [46, 8, 9, 27, 13].find(i => e.keyCode === i) ||
      // Povoliť: Ctrl/cmd+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: Ctrl/cmd+Z
      (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) ||
      // Povoliť: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {

      return;
    }

    // Ak nemáme číslo, tak ručíme default akciu - vypísanie požadovaného znaku.
    if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].find(i => e.key === i)) {
      e.preventDefault();
    }
  }
}
