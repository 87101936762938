<div class="top" wizardTag="shipmnetsOverview">
  <h1>{{ 'shipments' | shpLocalize }}</h1>
  <button type="button" [disabled]="isCompactViewSelected" id="switch-to-compact-view" (click)="isCompactViewSelected = true" class="top-button"><span class="ic-content-view-headline"></span><span class="label" [innerHtml]="'shipments_list' | shpLocalize"></span></button>
  <button type="button" [disabled]="!isCompactViewSelected" id="switch-to-grid-view" (click)="isCompactViewSelected = false" class="top-button"><span class="ic-grid"></span><span class="label" [innerHtml]="'shipments_grid' | shpLocalize"></span></button>
  <a class="top-button spacer-before" [routerLinkActive]="'disabled'" id="add-new-shipment-order" [routerLink]="['/shipments', 0]" wizardTag="newShipment" [wizardCompleteStep]="wizardStepNames.ClickNewShipment"><span class="ic-add-2"></span><span class="label" [innerHtml]="'add_shipment_order' | shpLocalize"></span></a>
  <button id="show-import-shipment-orders" *ngIf="products && (products.length > 0)" class="top-button" type="button" (click)="openImportOrdersDialog()" wizardTag="importShipments" [wizardCompleteStep]="wizardStepNames.ClickImportShipments"><span class="ic-data-download-8"></span><span class="label" [innerHtml]="'import_shipment_orders' | shpLocalize"></span></button>
  <button id="export-all-shipment-parcels" class="top-button spacer-after" type="button" (click)="openExportAllParcelsDialog()"><span class="ic-data-upload-8"></span><span class="label" [innerHtml]="'export_all_parcels' | shpLocalize"></span></button>
  <a *ngIf="isSenderScanVisible" class="top-button" [routerLinkActive]="'disabled'" id="sender-scan" [routerLink]="['/shipments/senderscan']"><span class="ic-scanner"></span><span class="label" [innerHtml]="'sender_scan' | shpLocalize"></span></a>
  <button class="top-button" type="button" (click)="showDailyProcedureDialog()" id="show-daily-procedure"><span class="ic-calendar-timeout"></span><span class="label" [innerHtml]="'daily_procedure' | shpLocalize"></span></button>
  <button *ngIf="!isSophia && isDeleteAllShipmentsVisible" class="top-button" type="button" (click)="showConfirmDeleteAllShipmentsDialog()" id="show-confirm-delete-all-shipments-dialog"><span class="ic-bin"></span><span class="label" [innerHtml]="'delete_all_shipments' | shpLocalize"></span></button>
  <button class="top-button" type="button" [routerLink]="['/shipments/archive']"><span class="ic-content-books-2"></span><span class="label" [innerHtml]="'shipments_archive_icon' | shpLocalize"></span></button>
  <button class="top-button" *shpPollyDialogTrigger="pollyCollection" id="recipients-faq"><span class="ic-interface-question-mark"></span><span class="label" [innerHtml]="'action_open_faq_dialog' | shpLocalize"></span></button>
</div>

<div *ngIf="isCompactViewSelected">
  <shp-shipments-compact
        [isBusy]="isBusy"
        [statusMessage]="statusMessage"></shp-shipments-compact>
</div>
<div *ngIf="!isCompactViewSelected" wizardTag="shipmnetsOverview">
  <shp-shipments-grid
        [isBusy]="isBusy"
        [statusMessage]="statusMessage"></shp-shipments-grid>
</div>
