<div class="printer-rule">
  <div class="view" *ngIf="printerRule.id != 0">
    <div class="commands">
      <button id="button-edit" type="button" *ngIf="!isEdited && isEditEnabled" (click)="edit()"><span class="ic-pencil-1"></span></button>
      <button id="button-delete" type="button" *ngIf="!isEdited && isEditEnabled" (click)="delete()"><span class="ic-bin"></span></button>
      <button id="button-increase-priority" type="button" *ngIf="isEditEnabled" [disabled]="isFirst" (click)="increasePriority()"><span class="ic-arrow-up"></span></button>
      <button id="button-decrease-priority" type="button" *ngIf="isEditEnabled" [disabled]="isLast" (click)="decreasePriority()"><span class="ic-arrow-down"></span></button>
    </div>
    <div class="conditions">
      <div class="customer ctl">{{ printerRule.customerDetailId | shpCustomerId:customerDetails }}</div>
      <div class="address ctl">{{ printerRule.addressId | shpAddressId:addresses:customerDetails }}</div>
      <div class="product ctl">{{ printerRule.productId | shpProductId:products }}</div>
      <div class="user ctl">{{ printerRule.userId | shpUserId:users }}</div>
      <div class="path ctl" *ngIf="labelsPrintingRule">{{ printerRule.path }}</div>
      <div class="enabled ctl">{{ (printerRule.isEnabled ? "yes" : "no") | shpLocalize }}</div>
    </div>
    <div class="parameters">
      <div class="printer-type ctl"><strong>{{ 'printer_type' | shpLocalize }}</strong> {{ printerRule.printerTypeCode }}</div>
      <div class="printer-language ctl" *ngIf="labelsPrintingRule"><strong>{{ 'settings_printing_zebra_language' | shpLocalize }}</strong> {{ printerRule.printerLanguageCode }}</div>
      <div class="printer ctl"><strong>{{ 'printer' | shpLocalize }}</strong> {{ printerRule.printerName }}</div>
      <div class="local-folder ctl"><strong>{{ 'settings_print_protocols_local_folder' | shpLocalize }}</strong> {{ printerRule.localFolder }}</div>
      <div class="paper-size ctl" *ngIf="labelsPrintingRule"><strong>{{ 'settings_printing_paper_size' | shpLocalize }}</strong> {{ printerRule.paperSize }}</div>
      <div class="dpi ctl" *ngIf="labelsPrintingRule"><strong>{{ 'settings_printing_dpi' | shpLocalize }}</strong> {{ printerRule.dpi }}</div>
      <div class="left-offset ctl" *ngIf="labelsPrintingRule"><strong>{{ 'settings_printing_left_offset' | shpLocalize }}</strong> {{ printerRule.leftOffset }}</div>
      <div class="top-offset ctl" *ngIf="labelsPrintingRule"><strong>{{ 'settings_printing_top_offset' | shpLocalize }}</strong> {{ printerRule.topOffset }}</div>
      <div class="first-label-position ctl" *ngIf="labelsPrintingRule"><strong>{{ 'settings_printing_start_position' | shpLocalize }}</strong> {{ printerRule.firstLabelPosition }}</div>
    </div>
  </div>
  <div *ngIf="isEdited && form" class="editor">
    <form novalidate [formGroup]="form">
      <h2>{{ 'printer_rule' | shpLocalize }}</h2>
      <div class="customer ctl">
        <!-- <label>{{ 'customer' | shpLocalize }}</label>
        <select id="select-customer" formControlName="customerDetailId" #firstControl>
          <option [ngValue]="null">-</option>
          <option *ngFor="let customerDetail of customerDetails" [ngValue]="customerDetail.id">{{ customerDetail.name }} ({{ customerDetail.delisId }})</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-customer" formControlName="customerDetailId" #firstControl>
              <option [ngValue]="null">-</option>
              <option *ngFor="let customerDetail of customerDetails" [ngValue]="customerDetail.id">{{ customerDetail.name }} ({{ customerDetail.delisId }})</option>
            </select>
            <label>{{ 'customer' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="address ctl">
        <!-- <label>{{ 'address' | shpLocalize }}</label>
        <select id="select-address" formControlName="addressId">
          <option [ngValue]="null">-</option>
          <option *ngFor="let address of filteredAddresses" [ngValue]="address.id">{{ address.name1 }}, {{ address.street }} {{ address.houseNr }}, {{ address.place }}, {{ address.countryCode }}-{{ address.zip }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-address" formControlName="addressId">
              <option [ngValue]="null">-</option>
              <option *ngFor="let address of filteredAddresses" [ngValue]="address.id">{{ address.name1 }}, {{ address.street }} {{ address.houseNr }}, {{ address.place }}, {{ address.countryCode }}-{{ address.zip }}</option>
            </select>
            <label>{{ 'address' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="product ctl">
        <!-- <label>{{ 'product' | shpLocalize }}</label>
        <select id="select-product" formControlName="productId">
          <option [ngValue]="null">-</option>
          <option *ngFor="let product of products" [ngValue]="product.id">{{ product.name }} ({{ product.code }})</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-product" formControlName="productId">
              <option [ngValue]="null">-</option>
              <option *ngFor="let product of products" [ngValue]="product.id">{{ product.name }} ({{ product.code }})</option>
            </select>
            <label>{{ 'product' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="user ctl">
        <!-- <label>{{ 'user' | shpLocalize }}</label>
        <select id="select-user" formControlName="userId">
          <option [ngValue]="null">-</option>
          <option *ngFor="let user of users" [ngValue]="user.id">{{ user.name }} ({{ user.loginName }})</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-user" formControlName="userId">
              <option [ngValue]="null">-</option>
              <option *ngFor="let user of users" [ngValue]="user.id">{{ user.name }} ({{ user.loginName }})</option>
            </select>
            <label>{{ 'user' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="path ctl" *ngIf="labelsPrintingRule">
        <!-- <label>{{ 'automated_shipments_import_path' | shpLocalize }}</label>
        <select id="select-automated-shipments-import-path" formControlName="path">
          <option [ngValue]="''">-</option>
          <option *ngFor="let path of importPaths" [ngValue]="path">{{ path }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-automated-shipments-import-path" formControlName="path">
              <option [ngValue]="''">-</option>
              <option *ngFor="let path of importPaths" [ngValue]="path">{{ path }}</option>
            </select>
            <label>{{ 'automated_shipments_import_path' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="enabled ctl">
        <input type="checkbox" id="{{ componentId }}-enabled" formControlName="isEnabled" />
        <label>{{ 'printer_rule_enabled' | shpLocalize }}</label>
        <!-- <label [attr.for]="componentId + '-enabled'"></label> -->
      </div>
      <div class="printer-type ctl">
        <!-- <label>{{ 'printer_type' | shpLocalize }}</label>
        <select id="select-printer-type" formControlName="printerTypeCode">
          <option [ngValue]="null">-</option>
          <ng-container *ngFor="let printerType of printerTypes">
            <option *ngIf="printerType.code === 'PdfLocal'" [ngValue]="printerType.code">{{ 'settings_print_pdf_to_local_folder' | shpLocalize }}</option>
            <option *ngIf="printerType.code !== 'PdfLocal'" [ngValue]="printerType.code">{{ printerType.code }}</option>
          </ng-container>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-printer-type" formControlName="printerTypeCode">
              <option [ngValue]="null">-</option>
              <ng-container *ngFor="let printerType of printerTypes">
                <option *ngIf="printerType.code === 'PdfLocal'" [ngValue]="printerType.code">{{ 'settings_print_pdf_to_local_folder' | shpLocalize }}</option>
                <option *ngIf="printerType.code !== 'PdfLocal'" [ngValue]="printerType.code">{{ printerType.code }}</option>
              </ng-container>
            </select>
            <label>{{ 'printer_type' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="printer-type ctl" *ngIf="isLocalFolderInputVisible">
        <label for="printer-rule-local-folder">{{ 'settings_print_protocols_local_folder' | shpLocalize}}</label>
        <input type="text"
               name="printingProtocolPrintingTypePdfLocalFolder"
               maxlength="1024"
               id="printer-rule-local-folder"
               formControlName="localFolder" />
      </div>
      <div class="printer ctl" *ngIf="!isLocalFolderInputVisible">
        <!-- <label>{{ 'printer' | shpLocalize }}</label>
        <select id="select-printer" formControlName="printerName">
          <option [ngValue]="''">-</option>
          <option *ngFor="let printer of printers" [ngValue]="printer">{{ printer }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-printer" formControlName="printerName">
              <option [ngValue]="''">-</option>
              <option *ngFor="let printer of printers" [ngValue]="printer">{{ printer }}</option>
            </select>
            <label>{{ 'printer' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="printer-language ctl" *ngIf="labelsPrintingRule">
        <!-- <label>{{ 'settings_printing_zebra_language' | shpLocalize }}</label>
        <select id="select-printer-language-code" formControlName="printerLanguageCode">
          <option [ngValue]="null">-</option>
          <option *ngFor="let printerLanguage of printerLanguages" [ngValue]="printerLanguage.code">{{ printerLanguage.code }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-printer-language-code" formControlName="printerLanguageCode">
              <option [ngValue]="null">-</option>
              <option *ngFor="let printerLanguage of printerLanguages" [ngValue]="printerLanguage.code">{{ printerLanguage.code }}</option>
            </select>
            <label>{{ 'settings_printing_zebra_language' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="paper-size ctl" *ngIf="labelsPrintingRule">
        <!-- <label>{{ 'settings_printing_paper_size' | shpLocalize }}</label>
        <select id="select-printer-paper-size" formControlName="paperSize">
          <option [ngValue]="null">-</option>
          <option *ngFor="let paperSize of paperSizes" [ngValue]="paperSize.code">{{ paperSize.code }}</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-printer-paper-size" formControlName="paperSize">
              <option [ngValue]="null">-</option>
              <option *ngFor="let paperSize of paperSizes" [ngValue]="paperSize.code">{{ paperSize.code }}</option>
            </select>
            <label>{{ 'settings_printing_paper_size' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="dpi ctl" *ngIf="labelsPrintingRule">
        <!-- <label for="input-dpi">{{ 'settings_printing_dpi' | shpLocalize }}</label>
        <wj-input-number id="input-dpi" [format]="'N0'" formControlName="dpi" [step]="1" [min]="50" [max]="1200"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              formControlName="dpi"
              [min]="50"
              [max]="1200"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="printingDpi"
              id="input-dpi"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="input-dpi">{{ 'settings_printing_dpi' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="left-offset ctl" *ngIf="labelsPrintingRule">
        <!-- <label>{{ 'settings_printing_left_offset' | shpLocalize }}</label>
        <wj-input-number [format]="'N0'" formControlName="leftOffset" [step]="1" [min]="-50" [max]="50"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              formControlName="leftOffset"
              [min]="-50"
              [max]="50"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="leftOffset"
              id="input-left-offset"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="input-left-offset">{{ 'settings_printing_left_offset' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="top-offset ctl" *ngIf="labelsPrintingRule">
        <!-- <label>{{ 'settings_printing_top_offset' | shpLocalize }}</label>
        <wj-input-number [format]="'N0'" formControlName="topOffset" [step]="1" [min]="-50" [max]="50"></wj-input-number> -->
        <div class="p-field p-field--full">
          <span class="p-float-label">
            <p-inputNumber
              formControlName="topOffset"
              [min]="-50"
              [max]="50"
              [step]="1"
              [showButtons]="true"
              [maxFractionDigits]="0"
              name="topOffset"
              id="input-top-offset"
              mode="decimal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="ic-add-2"
              decrementButtonIcon="ic-subtract-2"
            ></p-inputNumber>
            <label for="input-top-offset">{{ 'settings_printing_top_offset' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="first-label-position ctl" *ngIf="labelsPrintingRule">
        <!-- <label>{{ 'settings_printing_start_position' | shpLocalize }}</label>
        <select id="select-first-label-position" formControlName="firstLabelPosition">
          <option [ngValue]="1">1</option>
          <option [ngValue]="2">2</option>
          <option [ngValue]="3">3</option>
          <option [ngValue]="4">4</option>
        </select> -->
        <div class="p-field p-field--full">
          <span class="p-float-label" dpdFloatingLabel>
            <select id="select-first-label-position" formControlName="firstLabelPosition">
              <option [ngValue]="1">1</option>
              <option [ngValue]="2">2</option>
              <option [ngValue]="3">3</option>
              <option [ngValue]="4">4</option>
            </select>
            <label>{{ 'settings_printing_start_position' | shpLocalize }}</label>
          </span>
        </div>
      </div>
      <div class="commands">
        <button id="button-save" type="button" [disabled]="!form.valid || form.pristine" *ngIf="isEdited" (click)="save()"><span class="ic-floppydisk"></span> {{ 'save' | shpLocalize }}</button>
        <button id="button-cancel" type="button" *ngIf="isEdited" (click)="cancelEdit()"><span class="ic-close"></span> {{ 'cancel' | shpLocalize }}</button>
      </div>
    </form>
  </div>
</div>