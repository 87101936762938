import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, catchError, shareReplay } from "rxjs/operators"

import * as Shared from "../../shared/index"
import * as ConfigModel from "../../shared/models/config.models";
import { CheckCustomsObligationsRequestModel } from "../models/custom-clearance.model";

const CACHE_SIZE = 1;

interface CustomClearanceCache {
  reasonsForExport?: Observable<ConfigModel.ReasonForExport[]>
}

@Injectable()
export class CustomClearanceService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private localizationService: Shared.LocalizationService,
    private _http: HttpClient,
    private _tokenStorageService: Shared.TokensStorageService
  ) { super(loggingService); }

  private cache: CustomClearanceCache = {};

  public checkCustomsObligations(model: CheckCustomsObligationsRequestModel) {
    return this.processRequest<boolean>(this._http.post(`${Shared.API_URL}/orders/check-customs-obligations`, model));
  }

  public getIsEuCountry(countryCode: string) {
    return this.processRequest<boolean>(this._http.get(`${Shared.API_URL}/orders/is-eu-member?countryCode=${countryCode}`));
  }

  public getShippmentTypes() {
    return this.processRequest<ConfigModel.ShipmentType[]>(this._http.get(`${Shared.API_URL}/shipment-types`));
  }

  public getClearanceCleareds() {
    return this.processRequest<ConfigModel.ClearanceCleared[]>(this._http.get(`${Shared.API_URL}/clearance-cleareds`));
  }

  public getPrealertStatuses() {
    return this.processRequest<ConfigModel.PrealertStatus[]>(this._http.get(`${Shared.API_URL}/prealert-statuses`));
  }

  public getIncoTerms() {
    return this.processRequest<ConfigModel.IncoTerm[]>(this._http.get(`${Shared.API_URL}/inco-terms`));
  }

  public getCurriencies() {
    return this.processRequest<ConfigModel.Currency[]>(this._http.get(`${Shared.API_URL}/currencies`));
  }

  public getAccompanyingDocsTypes() {
    return this.processRequest<ConfigModel.AccompanyingDocsType[]>(this._http.get(`${Shared.API_URL}/accompanying-docs-types`));
  }

  public getReasonsForExport(): Observable<ConfigModel.ReasonForExport[]> {
    return this.processRequest<ConfigModel.ReasonForExport[]>(this._http.get(`${Shared.API_URL}/export-reasons`)).pipe(
      map(reasons => {
        const result = reasons.map(reason => {
          reason.name = this.localizationService.getLocalizedString("export_reason_code_" + reason.code);
          return reason;
        });

        return result;
      }),
      catchError( error => {
        return of([]);
      })
    )
  }

  get reasonsForExport() {
    if (!this.cache.reasonsForExport) {
      this.cache.reasonsForExport = this.getReasonsForExport().pipe(
        shareReplay(CACHE_SIZE)
      )
    }

    return this.cache.reasonsForExport;
  }

  public getMiniportalToken(orderId: number)
  {
    return this.processRequest<string>(this._http.get(`${Shared.API_URL}/orders/miniportal-token/create/${orderId}`));
  }
}