import { Injectable, Renderer2 } from "@angular/core";

import * as _ from "lodash";

import { ControlElementDirective } from "../directives/control-element.directive";

export class EntitiyValidationError {

  constructor(
    public label: string,
    public descriptions: string[],
    public focusAction: () => void,
    public ordinal: number
  ) { }
}


export const genericEntityFormValidatorErrorDescriptions = {
  required: "error_description_generic_required",
  min: "error_description_generic_min",
  max: "error_description_generic_max",
  email: "error_description_generic_email",
  minlength: "error_description_generic_minlength",
  maxlength: "error_description_generic_maxlength",
  pattern: "error_description_generic_pattern",
  // Shipper validators
  validateEmail: "error_description_generic_validateEmail",
  invalidIban: "error_description_generic_invalidIban",
  differentFrom: "error_description_generic_differentFrom",
  validateEqual: "error_description_generic_validateEqual",
  invalidEmails: "error_description_generic_invalidEmails"
};


@Injectable()
export class EntityFormValidationService {

  controls: ControlElementDirective[] = [];
  getErrorDescription: (controlName: string, errorKey: string, errorData: any, namePrefix?: string) => string;
  getErrorLabel: (controlName: string, namePrefix?: string) => string;


  constructor(
    private _renderer: Renderer2,
   ) { }


  registerControl(control: ControlElementDirective) {
    this.controls.push(control);
  }


  removeControl(control: ControlElementDirective) {
    const index = this.controls.indexOf(control);

    if (index >= 0) {
      this.controls.splice(index, 1);
    }
  }


  getErrors() {
    const result = _.chain(this.controls)
      .filter(control => Boolean(control.errors))
      .groupBy("name")
      .map((group, name) => {

        return new EntitiyValidationError(
          this.getErrorLabel ? this.getErrorLabel(name, group[0].namePrefix) : name,
          this.getErrorDescriptions(name, group),
          this.getHiglightAction(group[0].focusElement),
          group[0].ordinal
        );
      })
      .sortBy("ordinal")
      .value();

    return result;
  }


  private getErrorDescriptions(name: string, controls: ControlElementDirective[]): string[] {
    let errors = controls.map(control => control.errors);
    errors = _.merge({}, ...errors);

    const result = Object.keys(errors).map(errorKey => {
      return this.getErrorDescription ?
        this.getErrorDescription(name, errorKey, errors[errorKey], controls[0].namePrefix) :
        errorKey;
    });

    return result;
  }


  private getHiglightAction(element): () => void {

    return () => {
      element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      element.focus();

      this._renderer.addClass(element, "highlight-form-control");

      setTimeout(() => {
        this._renderer.removeClass(element, "highlight-form-control");
      }, 1200);
    };
  }
}
