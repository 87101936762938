<div id="shipments-filter">
  <div id="left">
    <div class="select-all-toggle">
      <input type="checkbox" [ngModel]="allSelected" (change)="toggleAllVisibleSelected()" id="select-all-toggle" />
      <label for="select-all-toggle">{{ 'select_all' | shpLocalize }}</label>
    </div>

  </div>
  <div id="center">
    <div class="ctl">
      <input id="shipment_filter_in_preparation" name="shipment_filter_in_preparation" type="checkbox" [(ngModel)]="filter.isStateInPreparationVisible" (change)="filterValueChanged()" />
      <label for="shipment_filter_in_preparation">{{ 'shipment_filter_in_preparation' | shpLocalize }}</label>

      <input *ngIf="!isSophia && !isCustomerService" id="shipment_filter_labels_printed" name="shipment_filter_labels_printed" type="checkbox" [(ngModel)]="filter.isStateInLabelsPrintedVisible" (change)="filterValueChanged()" />
      <label *ngIf="!isSophia && !isCustomerService" for="shipment_filter_labels_printed">{{ 'shipment_filter_labels_printed' | shpLocalize }}</label>

      <input *ngIf="!isSophia" id="shipment_filter_prepared_for_delivery_or_send" name="shipment_filter_prepared_for_delivery_or_send" type="checkbox" [(ngModel)]="deliveryStateFilter" (ngModelChange)="filterValueChanged()" />
      <label *ngIf="!isSophia" for="shipment_filter_prepared_for_delivery_or_send">{{ 'shipment_filter_prepared_for_delivery_or_send' | shpLocalize }}</label>

      <input *ngIf="!isSophia" id="shipment_filter_exported" name="shipment_filter_exported" type="checkbox" [(ngModel)]="exportedFilter" (ngModelChange)="exportedFilterChange()" />
      <label *ngIf="!isSophia" for="shipment_filter_exported">{{ 'shipment_filter_exported' | shpLocalize }}</label>

      <input *ngIf="!isSophia" id="shipment_filter_not_exported" name="shipment_filter_not_exported" type="checkbox" [(ngModel)]="notExportedFilter" (ngModelChange)="exportedFilterChange()" />
      <label *ngIf="!isSophia" for="shipment_filter_not_exported">{{ 'shipment_filter_not_exported' | shpLocalize }}</label>

      <ng-container *ngIf="showDeletedFilter">
        <input id="shipment_filter_deleted" name="shipment_filter_deleted" type="checkbox" [(ngModel)]="filter.isStateDeletedVisible" (change)="filterValueChanged()" />
        <label for="shipment_filter_deleted">{{ 'shipment_filter_deleted' | shpLocalize }}</label>
      </ng-container>

    </div>
  </div>
  <div id="right">
    <div class="ctl" *ngIf="anyCRExists && !isCustomerService">
      <input id="shipment_filter_collection_request" name="shipment_filter_collection_request" type="checkbox" [(ngModel)]="filter.isCollectionRequest" (change)="filterValueChanged()" />
      <label for="shipment_filter_collection_request">{{ 'shipment_filter_collection_request' | shpLocalize }}</label>
      <input id="shipment_filter_normal_request" name="shipment_filter_normal_request" type="checkbox" [(ngModel)]="filter.isNormalRequest" (change)="filterValueChanged()" />
      <label for="shipment_filter_normal_request">{{ 'shipment_filter_normal_request' | shpLocalize }}</label>
    </div>
  </div>
</div>