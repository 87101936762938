<div class="one-time-pickups" [class.is-sophia]="isSophia">
  <form class="grid" [formGroup]="filterForm">
    <div class="pickup-order-type-header">
      <h2>{{ 'one_time_pickup_orders' | shpLocalize }}</h2>
      <button *ngIf="canCurrentUserAddPickupOrder" class="top-button" (click)="addPickupOrder()" id="add-pickup-order" wizardTag="newPickup" [wizardCompleteStep]="wizardStepNames.ClickNewPickup"><span class="ic-add-2"></span><span class="label" [innerHtml]="'pickup_order_add' | shpLocalize"></span></button>
    </div>
    <shp-progress-overlay [isActive]="isLoadingList" [message]="'loading_one_time_pickup_orders' | shpLocalize"></shp-progress-overlay>
    <shp-retry-overlay [isVisible]="loadingErrorOccured"
                       errorMessage="{{ 'pickup_orders_loading_error' | shpLocalize }}"
                       [reloadFunction]="reloadList"></shp-retry-overlay>
    <div #header class="grid-header">
      <table>
        <thead>
          <tr>
            <th class="col-date-2 can-order" (click)="setOrderBy('FromDate')" [class.is-order]="orderBy == 'FromDate'" [class.ascending]="orderAscending">{{ 'pickup_order_pickup_date' | shpLocalize }}</th>
            <!--<th class="col-date-2">{{ 'pickup_order_day_and_time' }}</th>-->
            <th class="col-address">{{ 'pickup_order_contact' | shpLocalize }}</th>
            <th class="col-date">{{ 'shipment_order_parcels_count' | shpLocalize }}</th>
            <th *ngIf="cityserviceAvailable" class="col-date">CityService</th>
            <th class="col-date can-order" (click)="setOrderBy('StateId')" [class.is-order]="orderBy == 'StateId'" [class.ascending]="orderAscending">{{ 'state' | shpLocalize }}</th>
            <th class="col-commands">&nbsp;</th>
            <th class="col-spacer">&nbsp;</th>
          </tr>
          <tr class="filter-row filters">
            <th class="col-date-2">
              <div class="filter">
                <div class="ctl">
                  <!-- <wj-input-date formControlName="pickupDateFrom" [isRequired]="false" placeholder="{{ 'filter_from' | shpLocalize }}"></wj-input-date>
                  <wj-input-date formControlName="pickupDateTo" [isRequired]="false" placeholder="{{ 'filter_to' | shpLocalize }}"></wj-input-date> -->
                  <div class="p-field p-field--half">
                    <span class="p-float-label">
                      <p-calendar formControlName="pickupDateFrom" [showIcon]="true" appendTo="body" inputId="filter_from" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd/mm/yy" icon="ic-calendar-1" name="filter_from"></p-calendar>
                      <label for="filter_from">{{ 'filter_from' | shpLocalize }}</label>
                    </span>
                  </div>
                  <div class="p-field p-field--half">
                    <span class="p-float-label">
                      <p-calendar formControlName="pickupDateTo" [showIcon]="true" appendTo="body" inputId="filter_to" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd/mm/yy" icon="ic-calendar-1" required name="filter_to"></p-calendar>
                      <label for="filter_to">{{ 'filter_to' | shpLocalize }}</label>
                    </span>
                  </div>
                </div>
              </div>
            </th>
            <th>
              &nbsp;
            </th>
            <th>
              &nbsp;
            </th>
            <th>
              &nbsp;
            </th>
            <th>
              &nbsp;
            </th>
            <th class="col-global-commands"><button id="button-clear-filter" type="button" name="clearFilter" (click)="clearFilter()"><span class="ic-close"></span></button></th>
            <th class="col-spacer">
              &nbsp;
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="grid-content" (scroll)="scrollGridContent()" #content>
      <div class="list-empty-message" *ngIf="total === 0 && !isFiltered">
        <div class="message-content">
          {{ 'message_no_pickup_orders_in_db' | shpLocalize }}
        </div>
      </div>

      <div class="list-empty-message" *ngIf="total === 0 && isFiltered">
        <div class="message-content">
          {{ 'message_no_pickup_orders_matching_filter' | shpLocalize }}
        </div>
      </div>
      <table>
        <tbody>
          <tr *ngFor="let pickupOrder of pickupOrders" [class.city-service-pickup-order]="pickupOrder.isSameDay">
            <td class="col-date-2">
              {{ pickupOrder.fromDate | shpLocalizeDate:"L" }}<span *ngIf="pickupOrder.isSameDay"> - CityService</span>
            </td>
            <td class="col-address">{{ pickupOrder.contactPerson }}<br />
            {{ pickupOrder.email }}<br />
            {{ pickupOrder.phone }}</td>
            <td class="col-date">{{ pickupOrder.parcelsCount }}</td>
            <td *ngIf="cityserviceAvailable" class="col-date">
              <span *ngIf="pickupOrder.isSameDay == true" class="check-mark"></span>
              <span *ngIf="pickupOrder.isSameDay == false" class="cross-mark"></span>
            </td>
            <td class="col-date">{{ 'pickup_order_state_' + pickupOrder.stateId | shpLocalize }}</td>
            <td class="col-commands">
              <button id="button-show-details" type="button" (click)="showDetails(pickupOrder.id)" title="{{ (pickupOrder.stateId != 'InPreparation' ? 'pickup_order_view' : 'pickup_order_edit') | shpLocalize }}">
                <span *ngIf="pickupOrder.stateId != 'InPreparation' && !isImpersonatingShipperAdminUser" class="ic-view-lock"></span>
                <span *ngIf="pickupOrder.stateId == 'InPreparation' || isImpersonatingShipperAdminUser" class="ic-pencil-1"></span>
              </button>
            </td>
            <td class="col-spacer"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="grid-footer">
      <div class="multi-select-commands">

      </div>
      <div class="grid-pager">
        <div class="pager" *ngIf="filter.pageSize < total">
          <button id="button-pager-first" class="first" type="button" (click)="goToPage(0)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-2"></span></button>
          <button id="button-pager-prev" class="prev" type="button" (click)="goToPage(filter.pageIndex - 1)" *ngIf="filter.pageIndex > 0"><span class="ic-navigation-left-3"></span></button>
          <button id="button-pager-next" class="next" type="button" (click)="goToPage(filter.pageIndex + 1)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-3"></span></button>
          <button id="button-pager-last" class="last" type="button" (click)="goToPage(lastPageIndex)" *ngIf="filter.pageIndex < lastPageIndex"><span class="ic-navigation-right-2"></span></button>
          <span class="numbers">{{ filter.pageIndex * filter.pageSize + 1 }} - {{ (filter.pageIndex + 1) * filter.pageSize < total ? (filter.pageIndex + 1) * filter.pageSize : total }}/{{ total }}</span>
        </div>
      </div>
      <div class="grid-options">
        <label *ngIf="filter.pageSize > total && total > 0">{{ 'total_items' | shpLocalize }}: {{ total }}</label>
        <label for="items-per-page">{{ 'items_per_page' | shpLocalize }}</label> <select id="items-per-page" class="items-per-page" formControlName="pageSize">
          <option *ngFor="let value of itemsPerPageOptions" value="{{ value }}">{{ value }}</option>
        </select>
      </div>
    </div>
  </form>
</div>
