import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http";

import * as Shared from "../../shared/index"

import * as CustomerModels from "../../shared/models/customer.models"

import { ContextService } from "../../shared/index";
import { ToastrService } from "ngx-toastr";
import { JobForGridView } from "../models/job.model";
import { JobsSearchParameters } from "../models/jobs-search-paramenters.model";
import { CodeBasedConfigDataBase } from "../../shared/models/config.models";
import { forkJoin, Observable } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";


@Injectable()
export class JobService extends Shared.ApiServiceBase {
  constructor(
    loggingService: Shared.LoggingService,
    private _contextService: ContextService,
    private _http: HttpClient,
  ) { super(loggingService); }


  public getJobStates(): Observable<any[]> {
    return this.processRequest<any[]>(this._http.get(`${Shared.API_URL}/jobs/job-states`))
  }


  public getJobTypes(): Observable<CodeBasedConfigDataBase[]> {
    return this.processRequest<CodeBasedConfigDataBase[]>(this._http.get(`${Shared.API_URL}/jobs/job-types`))
  }


  public getJobContentTypes(): Observable<CodeBasedConfigDataBase[]> {
    return this.processRequest<CodeBasedConfigDataBase[]>(this._http.get(`${Shared.API_URL}/jobs/job-content-types`))
  }


  public searchJobs(filtr: JobsSearchParameters): Observable<Shared.PagedResult<JobForGridView>> {
    return forkJoin([
      this._contextService.currentCustomerDetail.pipe(
        filter(val => !!val),
        take(1)
      ),
      this._contextService.currentAddress.pipe(
        filter(val => !!val),
        take(1)
      )
    ]).pipe(
      map(([customerDetail, customerAddress]: [CustomerModels.CustomerDetail, CustomerModels.Address]) => {
          return {
            customerDetailId: customerDetail.id,
            customerAddressId: customerAddress.id
          }
      }),
      switchMap(ids => {
        let filter = {
          ...filtr,
          ...ids
        };

        return this.processRequest<Shared.PagedResult<JobForGridView>>(this._http.post(`${Shared.API_URL}/jobs/filtered`, filter));
      })
    )
  }


  public searchImportJobs(filter: JobsSearchParameters): Observable<Shared.PagedResult<JobForGridView>> {
    filter.jobTypeCode = "ImportShipment";

    return this.searchJobs(filter);
  }


  public executeJob(id: number): Observable<any> {
    return this.processRequest<any>(this._http.put(`${Shared.API_URL}/jobs/${id}/execute`, null));
  }


  public cancelJob(id: number): Observable<any> {
    return this.processRequest<any>(this._http.put(`${Shared.API_URL}/jobs/${id}/cancel`, null));
  }


  public getJobLog(logJobContentId: number) {
    return this.processBlobRequest(this._http.get(`${Shared.API_URL}/jobs/contents/${logJobContentId}`, { responseType: 'blob' }));
  }

}