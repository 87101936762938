import { Location } from "@angular/common";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, NgForm, NgModel } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { default as DeepDiff } from "deep-diff";
import * as _ from "lodash";
import { CompleterItem, RemoteData } from "ng2-completer";
import { Modal } from "ngx-modialog-7/plugins/bootstrap";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, forkJoin, ReplaySubject, Subscription } from "rxjs";
import { debounceTime, filter, mergeMap, take, tap } from "rxjs/operators";

import * as SettingsModel from "../../settings/models/settings.model";
import { SettingsService } from "../../settings/services/settings.service";
import * as Shared from "../../shared/index";
import { BusinessUnitSettingsService, City } from "../../shared/index";
import { Address } from "../../shared/models/customer.models";
import { ZipCityPair } from "../../shared/models/zip-city-pair.model";
import { WizardService } from "../../shared/modules/wizard/services/wizard.service";
import { ShipperWizardService, ShipperWizardUserAction } from "../../shared/services/shipper-wizard.service";
import { CityAndZipCheckResult } from "../../shipments/models/shipment.model";
import { UsersService } from "../../user/services/users.service";
import * as Models from "../models/recipient.model";
import { RecipientsService } from "../services/recipients.service";

@Component({
  selector: Shared.SELECTOR_PREFIX + "-recipient",
  templateUrl: "./recipient.component.html"
})
export class RecipientComponent extends Shared.RoutedPageComponentBase implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    loggingService: Shared.LoggingService,
    globalEventsStreamService: Shared.GlobalEventsStreamService,
    localizationService: Shared.LocalizationService,
    authenticationService: Shared.AuthenticationService,
    router: Router,
    private _location: Location,
    private _route: ActivatedRoute,
    private _recipientsService: RecipientsService,
    private _skdataConfigService: Shared.SkdataConfigService,
    private _contextService: Shared.ContextService,
    private _settingsService: SettingsService,
    private _modal: Modal,
    private _exceptionHandlerService: Shared.ExceptionsHandlerService,
    private _toastr: ToastrService,
    private _buSettings: BusinessUnitSettingsService,
    private _wizardService: WizardService,
    private _shipperWizardService: ShipperWizardService
  ) {
    super(loggingService, globalEventsStreamService, localizationService, authenticationService, router);
  }

  public get isAdmin() { return this._isAdmin; }
  public get pickupAddresses() { return this._pickupAddresses; }

  public get showRecipientToPickupAddressAsignment() {
    return this._isAdmin && this._areRecipientsAddressSpecific;
  }


  public isBusy = false;
  public isInitialized = false;


  public hasLoadingFailed = false;


  public isValidatingCityAndZip = false;

  public isCityInvalid = false;
  public isZipInvalid = false;
  public isZipNotInCity = false;


  public citiesDataService: RemoteData = null;
  public zipCityPairsDataService: RemoteData = null;


  private settings: SettingsModel.TenantSettings = null;

  private _customerDetailId: number = null;
  private _pickupAddressId: number = null;
  private _isAdmin = false;
  private _areRecipientsAddressSpecific = false;
  private _pickupAddresses: Address[] = [];

  public isCustomerLevelTenant = false;


  public languages: string[];

  @ViewChild("f")
  public form: NgForm;

  @ViewChild("eoriControl")
  public eoriControl: NgModel;

  public addressZip = new FormControl();
  public addressCountry = new FormControl();
  public addressCity = new FormControl();


  // Aby sme hodnoty mali pr�stupn� vo view.
  public b2b = Models.CustomerRecipientTypeCode.B2B;
  public b2c = Models.CustomerRecipientTypeCode.B2C;


  private _paramsChangeSubscription: Subscription = null;


  public recipientId: number = null;


  public recipient: Models.Recipient = null;
  public recipientSnapshot: Models.Recipient = null;


  public countries: BehaviorSubject<Shared.Country[]> = new BehaviorSubject([]);
  public countriesByCode: ReplaySubject<Shared.Country[]> = new ReplaySubject();
  public eoriNumberWithoutCountryCode = "";
  public eoriNumberCountryCode = "";

  public filteredZips: ZipCityPair[];

  public filteredCities: any[];

  /**
   * Ulo�� k�pie aktu�lnej hodnoty recipiet pre ��ely neskor�eieho porovnania.
   */
  private storeRecipientSnapshot = () => {
    this.recipientSnapshot = _.cloneDeep(this.recipient);
  }

  public save = () => {
    this.isBusy = true;

    if (this.isCustomerLevelTenant) {
      this.recipient.customerDetailId = this._customerDetailId;
    }

    if (this.recipient.isNew) {
      // Nov� adres�t - pridanie �dajov.
      this._recipientsService.addRecipient(this.recipient)
        .subscribe(
          recipient => {
            this.recipient = recipient;
            this.storeRecipientSnapshot();
            this.isBusy = false;

            // Potrebujeme aktualizova� URL pod�a ID, ktor� sme obdr�ali.
            if (this.settings.return_to_recipient_list_after_saving.value) {
              this.router.navigate(["/", "recipients"]);
            } else {
              this._location.replaceState("/recipients/" + recipient.id);
            }

            this._toastr.success(this.localizationService.getLocalizedString("recipient_saved"));


            this._shipperWizardService.addUserAction(ShipperWizardUserAction.SavedRecipient);
            this._wizardService.goToNextStep();
          },
          ex => {
            this.isBusy = false;
            this._toastr.error(this.localizationService.getLocalizedString("recipient_save_failed"));
            this.loggingService.logErrorData(ex, "New recipient saving failed");
          });
    } else {
      // Existuj�ci adres�t - update �dajov.
      this._recipientsService.updateRecipient(this.recipient)
        .subscribe(
          recipient => {
            this.recipient = recipient;
            this.storeRecipientSnapshot();
            this.isBusy = false;

            if (this.settings.return_to_recipient_list_after_saving.value) {
              this.router.navigate(["/", "recipients"]);
            }

            this._toastr.success(this.localizationService.getLocalizedString("recipient_saved"));
          },
          ex => {
            this.isBusy = false;
            this._toastr.error(this.localizationService.getLocalizedString("recipient_save_failed"));
            this.loggingService.logErrorData(ex, "Recipient saving failed");
          });
    }
  }


  private loadOrInitializeNewRecipient = () => {
    // V pr�pade, �e m�me nov�ho adres�ta, tak vytvor�me si pr�zdny objekt.
    if (this.recipientId === 0) {
      this.initializeNewRecipient();
      this.isBusy = false; // isBusy sme "zap�nali" v loadCountries.
      this.isInitialized = true;
    } else {
      this.loadRecipient();
    }
  }


  /**
   * Na��tanie existuj�ceho recipienta.
   */
  private loadRecipient = () => {
    this.isBusy = true;

    this._recipientsService.getRecipient(this.recipientId).subscribe(
      recipient => {
        if (recipient == null) {
          this.router.navigate(["/recipients"]);
          return;
        }

        this.hasLoadingFailed = false;

        this.recipient = recipient;

        this.setEoriNumberModels();

        // Ulo��me si k�piu pre porovn�vanie.
        this.storeRecipientSnapshot();

        this.isBusy = false;
        this.isInitialized = true;
      },
      ex => {
        this.hasLoadingFailed = true;
        this.isBusy = false;

        this.loggingService.logErrorData(ex, "Recipient loading failed");
      }

    );
  }


  private getNullPickupAddress() {
    const pickupAddress: Address = {
      id: null,
      name1: "-",
      name2: "",
      name3: "",
      name4: "",
      addressForm: "",
      addressFormDescription: "",
      addressFormName: "",
      addressNr: "",
      addressTypeCode: "B",
      addressTypeCodeAdditional: "",
      street: "",
      street2: "",
      countryCode: "",
      createdByUser: "",
      creationDateTimeUtc: null,
      customerAddressType: "B",
      customerAddressTypeDescription: "",
      customerDetailId: null,
      customId: "",
      depotCode: "",
      email: "",
      fax: "",
      height: null,
      houseNr: "",
      isNew: false,
      isPersistent: false,
      latitude: null,
      longitude: null,
      maskingCountryCode: "",
      maskingHouseNr: "",
      maskingName: "",
      maskingName2: "",
      maskingStreet: "",
      maskingStreet2: "",
      maskingZip: "",
      maskingCity: "",
      modificationDateTimeUtc: null,
      modifiedByUser: "",
      phone: "",
      place: "",
      pOBoxText: "",
      pOBoxZip: "",
      turnoverTaxId: null,
      web: "",
      zip: "",
    };

    return pickupAddress;
  }


  private initializePickupAddessesList(pickupAddresses: Address[]) {
    this._pickupAddresses = [this.getNullPickupAddress(), ...pickupAddresses];
  }


  /**
   * Na��tanie zoznamu kraj�n a nastaven�.
   * @returns Promise<any>
   */
  private loadCountriesAndSettings: () => Promise<any> = () => {
    // Is busy len zapneme, lebo potom o�ak�vame, �e pr�du �al�ie na��tania �dajov.
    this.isBusy = true;

    const p = new Promise((resolve, reject) => {
      forkJoin([
        this._skdataConfigService.getCountries(),
        this._contextService.currentAddress.pipe(
          filter(ca => ca != null),
          mergeMap(ca => {
            this._pickupAddressId = ca.id;
            this._customerDetailId = ca.customerDetailId;

            return this._settingsService.activeConfigurationSettings$;
          }),
          take(1)
        ),
        this._skdataConfigService.getLanguages(),
        this.authenticationService.isAdmin$.pipe(take(1)),
        this._contextService.addresses.pipe(
          filter(a => a != null),
          take(1)
        ),
        this._buSettings.getAreRecipientsAddressSpecific(),
        this._contextService.isCustomerLevelTenant$.pipe(take(1))
      ]).subscribe(
          (result: any[]) => {
            const countries = result[0];
            const settings = result[1];
            const languages = result[2];

            this._isAdmin = result[3];
            this._areRecipientsAddressSpecific = result[5];

            this.initializePickupAddessesList(result[4]);

            this.settings = settings;

            this.countries.next(countries);

            this.languages = languages.sort();

            this.isCustomerLevelTenant = result[6];

            resolve(true);
          },
          ex => {
            this.hasLoadingFailed = true;

            this.loggingService.logErrorData(ex);

            reject();
          });
    });

    return p;
  }


  private isNullOrWhiteSpace(text: string) {
    if (typeof text === "undefined" || text == null) {
      return true;
    }

    text = text.trim();

    if (text === "") {
      return true;
    }

    return false;
  }


  public getAddressAsStringForDislay(address: Address) {
    let addressString = address.name1;

    if (!this.isNullOrWhiteSpace(address.name2)) {
      addressString += ", " + address.name2;
    }

    if (!this.isNullOrWhiteSpace(address.street)) {
      addressString += ", " + address.street;

      if (!this.isNullOrWhiteSpace(address.houseNr)) {
        addressString += address.houseNr;
      }
    }

    if (!this.isNullOrWhiteSpace(address.street2)) {
      addressString += ", " + address.street2;
    }

    if (!this.isNullOrWhiteSpace(address.place)) {
      addressString += ", " + address.place;
    }

    if (!this.isNullOrWhiteSpace(address.countryCode)) {
      addressString += ", " + address.countryCode;

      if (!this.isNullOrWhiteSpace(address.zip)) {
        addressString += "-" + address.zip;
      }
    }

    return addressString;
  }


  /**
   * Inicializuje nov�ho adres�ta s id = 0 as isNew = true.
   */
  private initializeNewRecipient = () => {
    this.recipient = {
      id: 0,
      city: "",
      countryCode: this.settings.country_code.value,
      comment: "",
      createdByUser: null,
      creationDateTimeUtc: null,
      customerRecipientTypeCode: Models.CustomerRecipientTypeCode.B2C,
      email: "",
      fax: "",
      houseNr: "",
      modificationDateTimeUtc: null,
      modifiedByUser: "",
      name: "",
      name2: "",
      contactPerson: "",
      phone: "",
      referenceNumber: "",
      stateCode: "",
      street: "",
      streetDetail: "",
      zip: "",
      isNew: true,
      isPersistent: false,
      languageCode: this.settings.default_recipient_language_code.value,
      addressId: this._pickupAddressId,
      vatNumber: "",
      eoriNumber: "",
      isUsedAsReceiver: true,
      isUsedAsReturnReceiver: false,
      isUsedAsCollectionRequestSender: false
    };

    if (!this.recipient.languageCode) {
      this.recipient.languageCode = "";
    }

    this.storeRecipientSnapshot();
  }


  private validateZipAndCity() {
    this.isValidatingCityAndZip = true;
    this.isCityInvalid = false;
    this.isZipInvalid = false;
    this.isZipNotInCity = false;

    this._recipientsService.checkZipAndCity({
      cityName: this.recipient.city,
      countryCode: this.recipient.countryCode,
      postCode: this.recipient.zip,
      refDate: null
    })
      .subscribe((result: CityAndZipCheckResult) => {
        this.isCityInvalid = !result.isCityValid && result.cityValidationRequirementTypeId == "VB";
        this.isZipNotInCity = !result.isZipInCity && result.cityValidationRequirementTypeId == "VB";
        this.isZipInvalid = !result.isZipValid && result.zipValidationRequirementTypeId == "VB";

        this.isValidatingCityAndZip = false;
      },
        (ex) => {
          const exceptionInfo = this._exceptionHandlerService.getExceptionInfo(ex);

          switch (exceptionInfo.key) {
            case Shared.ExceptionKeys.InvalidZipForCity:
              this.isZipNotInCity = true;
              break;

            case Shared.ExceptionKeys.InvalidCity:
              this.isCityInvalid = true;
              break;

            default:
              break;
          }
        });
  }


  public zipCityPairSelected(selected: CompleterItem) {
    if (selected) {
      const zipCityPairModel = selected.originalObject as ZipCityPair;

      this.recipient.city = zipCityPairModel.cityName;
    }
  }


  public ngOnInit() {
    this._paramsChangeSubscription = this._route.params.subscribe(params => {
      try {
        this.recipientId = +params.recipientId; // Typecast na ��slo.
      } catch (ex) {
        this.recipientId = 0;
      }

      // this.citiesDataService = this._completerService.remote(null, null, "cityNameLocal");
      // this.citiesDataService.urlFormater((term: string) => {
      //   var countryCode = this.recipient ? this.recipient.countryCode : "";
      //   return `${Shared.API_URL}/countries/${countryCode}/${term}`;
      // });


      // this.zipCityPairsDataService = this._completerService.remote(null, null, "zip").descriptionField("cityName");
      // this.zipCityPairsDataService.urlFormater((term: string) => {
      //   let countryCode = this.recipient ? this.recipient.countryCode : this.settings.country_code.value;

      //   return `${Shared.API_URL}/georouting/countries/${countryCode}/zips/${term}/city-names`;
      // });



      this.loadCountriesAndSettings().then(
        () => {
          this.loadOrInitializeNewRecipient();
        },
        () => {
          // Ni� nerob�me, ak nastala chyba, t� je u� o�etren� v r�mci na��tavania kraj�n.
        }
      );
    });


    combineLatest([
      this.addressCity.valueChanges.pipe(
        debounceTime(600),
      ),
      this.addressCountry.valueChanges.pipe(
        debounceTime(600),
      ),
      this.addressZip.valueChanges.pipe(
        debounceTime(600),
      )
    ]).subscribe(() => this.validateZipAndCity());


    this.countries.subscribe(countries => this.countriesByCode.next(_.sortBy(countries, "code")));
  }


  public ngOnDestroy() {
    if (this._paramsChangeSubscription != null) {
      this._paramsChangeSubscription.unsubscribe();
    }
  }


  public hasUnsavedChanges = () => {
    if (this._wizardService.isActive) {
      return false;
    }

    // S pomocou DeepDiff-u si porovn�me aku�lnu hodnotu this.recipient s ulo�enou k�piou.
    const diff = DeepDiff.diff(this.recipient, this.recipientSnapshot);

    return typeof diff != "undefined" && diff != null && diff.length !== 0;
  }


  private deleteRecipient = () => {
    this._modal.confirm()
      .body(this.localizationService.getLocalizedString("recipient_delete_confirmation_question"))
      .open()
      .result.then(
        value => {
          if (value) {
            this.isBusy = true;
            this._recipientsService.deleteRecipient(this.recipientId)
              .subscribe(
                () => {
                  // Nechceme notifik�ciu o pr�padn�ch zmen�ch.
                  this.hasUnsavedChanges = () => false;

                  this.isBusy = false;

                  this.router.navigate(["/recipients"]);
                  this._toastr.success(this.localizationService.getLocalizedString("recipient_deleted"));
                },
                ex => {
                  this.isBusy = false;
                  this._toastr.error(this.localizationService.getLocalizedString("recipient_delete_failed"));

                  this.loggingService.logErrorData(ex, "Recipient delete failed");
                });
          }
        },
        value => {
          // ... ni� nerob�me.
        }
      );
  }


  public ngAfterViewInit(): void {
    // Z nejak�ho d�vodu nepatria nasleduj�ce kontrolky pod centr�lny formul�r
    // a ich dirty flag nevpl�va na dirty flag formul�ra.
    // Ke� ich prid�me medzi kontrolky formul�ra takto explicitne, tak je to ok.
    window.setTimeout(() => {
      if (this.form) {
        this.form.form.addControl("addressCountry", this.addressCountry);
        this.form.form.addControl("addressZip", this.addressZip);
        this.form.form.addControl("addressCity", this.addressCity);
      }
    }, 500);
  }


  public back = () => {
    if (this._wizardService.isActive) {
      this._wizardService.goToNextStep();
    } else {
      this.router.navigate(["/recipients"]);
    }
  }


  public delete = () => {
    this.deleteRecipient();
  }


  public onEoriNumberChange() {
    if (this.eoriNumberCountryCode && this.eoriNumberWithoutCountryCode) {
      this.recipient.eoriNumber = this.eoriNumberCountryCode + this.eoriNumberWithoutCountryCode;
    } else {
      this.recipient.eoriNumber = "";
    }
  }


  public onCounrySelect() {
    if (!this.eoriNumberWithoutCountryCode) {
      this.eoriNumberCountryCode = this.recipient.countryCode;
    }
  }


  private setEoriNumberModels() {
    if (this.recipient.eoriNumber) {
      this.eoriNumberWithoutCountryCode = this.recipient.eoriNumber.slice(2);
      this.eoriNumberCountryCode = this.recipient.eoriNumber.slice(0, 2);
    }
  }

  public onZipSelected(selected: ZipCityPair): void {

    if (typeof selected == "object") {
      this.recipient.city = selected.cityName;
      this.recipient.zip = selected.zip;
      this.addressZip.setValue(selected.zip);
    }
  }

  public filterZip(event): void {
    const query = event.query;
    const countryCode = this.recipient ? this.recipient.countryCode : this.settings.country_code.value;

    this._skdataConfigService.getCitiesByZip(countryCode, query).pipe(
      tap((response: ZipCityPair[]) => this.filteredZips = response)
    ).subscribe();
  }

  public filterCities(event): void {
    const query = event.query;
    const countryCode = this.recipient ? this.recipient.countryCode : "";

    this._skdataConfigService.getCities(countryCode, query).pipe(
      tap((cities: City[]) => this.filteredCities = cities.map((city: City) => city.cityNameLocal))
    ).subscribe();
  }

  public onLangChange(event) {
    console.warn(event);
  }

  public getZipFormatForSelectedCountry() {
    const countryCode = this.recipient ? this.recipient.countryCode : "";
    const country = this.countries.value.find(c => c.code === countryCode);

    if (country) {
      return country.postCodePattern.split(",").filter(p => typeof p !== "undefined" && p !== null && p !== "").join(", ");
    } else {
      return this.localizationService.getLocalizedString("unspecified");
    }
  }
}
