import { Location } from "@angular/common";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import jwt_decode from "jwt-decode";
import * as _ from "lodash";
import { BehaviorSubject, Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, map, mergeMap, skip, switchMap, take, tap } from "rxjs/operators";


import { UserModel, UserOperation, UserRole } from "../../user/models/user.model";
import { UsersService } from "../../user/services/users.service";
import { ExceptionInfo, ExceptionKeys } from "../exceptions";
import { LOGIN_URL } from "../globals";
import { AccessToken } from "../models/access-token.model";
import { TokenRequestResult } from "../models/token-request-result.model";
import { DEMO_USER_LOGIN, User } from "../models/user.model";
import { API_URL } from "./../api-url";
import { LoggingService } from "./logging.service";
import { TokensStorageService } from "./tokens-storage.service";

const CLIENT_ID = "shipper_tenant_web_client";

/**
 * Koľko sekúnd pred vypršaním access tokenu chceme robiť jeho obnovu.
 */
const REFRESH_TRESHOLD_SECONDS = 30;


/**
 * Service slúžiaci na evidenciu aktuálne prihláseného používateľa,
 * samotné prihlasovanie a odhlasovanie (a aktualizáciu access a refresh tokenov).
 */
@Injectable()
export class AuthenticationService {
  public readonly currentUserAllowedOperations$: Observable<Set<UserOperation>>;
  public readonly currentUserModel$: Observable<UserModel>;
  public readonly isAdmin$: Observable<boolean>;
  public readonly isCustomerService$: Observable<boolean>;
  public readonly impersonatingShipperAdminUserLogin$: Observable<string>;
  public readonly isMasterAdmin$: Observable<boolean>;
  public readonly user: Observable<User>;

  /**
   * Used to pause periodical loggin status check that is
   * initialized in `LoginPopupComponent`. Can prevent
   * the popup showing up while other async code needs to
   * resolve (e.g. CanDeactivate guards).
   */
  public isPeriodicalLogginStatusCheckActive = true;

  private _currentUserModel$ = new ReplaySubject<UserModel>(1);
  private _impersonatingShipperAdminUserLogin$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _tokenRefreshTimeout: number = null;
  private _user: BehaviorSubject<User> = new BehaviorSubject(null);
  private _wasReinitialized = false;
  private _isAdmin$ = new ReplaySubject<boolean>(1);
  private _isMasterAdmin$ = new ReplaySubject<boolean>(1);
  private _isCustomerService$ = new ReplaySubject<boolean>(1);
  private _currentUserAllowedOperations$ = new ReplaySubject<Set<UserOperation>>(1);

  constructor(
    private _loggingService: LoggingService,
    private _http: HttpClient,
    private _tokensStorage: TokensStorageService,
    private _usersService: UsersService,
    private _location: Location
  ) {
    this.currentUserModel$ = this._currentUserModel$.asObservable();
    this.currentUserAllowedOperations$ = this._currentUserAllowedOperations$.asObservable();
    this.isAdmin$ = this._isAdmin$.asObservable();
    this.isMasterAdmin$ = this._isMasterAdmin$.asObservable();
    this.isCustomerService$ = this._isCustomerService$.asObservable();
    this.user = this._user.asObservable();
    this.impersonatingShipperAdminUserLogin$ = this._impersonatingShipperAdminUserLogin$.asObservable();
    this.initCurrentUserModel();
    this.initCurrentUserAllowedOperations();
  }

  public get isAuthenticated(): boolean {
    if (!this.checkTokens()) {
      return false;
    }

    return this._user.getValue() != null;
  }

  public can(userOperation: UserOperation): Observable<boolean> {
    return this._currentUserAllowedOperations$.pipe(
      map(operations => operations.has(userOperation))
    );
  }

  /**
   * Vypýta access token zo servera a dekóduje z neho údaje používateľa,
   * ak ten token úspešne dostal. Tiež uloží access token a refresh token
   * a nastaví časovať na refresh access tokenu.
   *
   * @param login Prihlasovacie meno používateľa.
   * @param password Heslo používateľa.
   */
  public logInUser(login: string, password: string): Observable<User> {
    const content = "username=" + encodeURIComponent(login) + "&password=" + encodeURIComponent(password) + "&grant_type=password&client_id=" + CLIENT_ID;

    // var headers = new Headers();
    // headers.append("Content-Type", "application/x-www-form-urlencoded");

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      })
    };

    // return this._http.post(API_URL + "/token", content, { headers: headers })
    return this._http.post(API_URL + "/token", content, httpOptions).pipe(
      mergeMap(result => {
        // @Adam - Need aditional check
        // let data = result.json() as TokenRequestResult;
        const data = result as TokenRequestResult;

        this._loggingService.logDebug("Received token request result:");
        this._loggingService.logDebugData(data);

        // Máme štruktúru, ktorá obsahuje aj access_token a refresh_token.
        // Access token obsahuje zakódované údaje používateľa.
        // Toto všetko musíme vyextrahovať.
        const accessToken = jwt_decode(data.access_token) as AccessToken;

        this._tokensStorage.setAccessToken(data.access_token);
        this._tokensStorage.setRefreshToken(data.refresh_token);

        this.setAccessTokenRefreshTimeout(accessToken);

        return this.setUser(accessToken);
      }),
      catchError((ex: HttpErrorResponse) => {
        this._loggingService.logErrorData(ex);

        const exceptionInfo: ExceptionInfo = { key: ExceptionKeys.GeneralException };

        try {
          if (ex.error.error) {
            exceptionInfo.key = ex.error.error;
          } else {
            exceptionInfo.key = ExceptionKeys.GeneralException;
          }
        } catch (unwrapError) {
          // Pravdepodobne sme nedostali JSON string v response body.
          exceptionInfo.key = ExceptionKeys.GeneralException;
        }

        // return Observable.throw(exceptionInfo);
        return throwError(exceptionInfo);
      })
    );
  }


  public logOutUser() {
    this.clearUser();
  }


  private setAccessTokenRefreshTimeout(accessToken: AccessToken) {
    const now = (new Date()).getTime();

    const exp = accessToken.exp * 1000; // Potebujeme milisekundy.

    this._tokenRefreshTimeout = window.setTimeout(
      () => {
        this.refreshAccessToken().subscribe(() => { }, () => {
          this._location.go(LOGIN_URL);
        });
      }, exp - now - REFRESH_TRESHOLD_SECONDS * 1000);
  }


  private setUser(accessToken: AccessToken, isStored = false): Observable<User> {
    
    var result = this._usersService.checkOnlyDemoUserExists().pipe(
      switchMap(onlyDemoUserAvailable => {
        if (onlyDemoUserAvailable && accessToken.unique_name != DEMO_USER_LOGIN){
          this.clearUser();
          return this.logInDemoUser();
        }

        this._loggingService.logDebugData(accessToken, "Decoded access token:");
  
        if (this._user.getValue()?.id === accessToken.user_id) {
          return this._user;
        }
      
        let user = this.convertTokenToUser(accessToken, isStored);
      
        // Ensure the isImpersonatingShipperAdminUser refreshes before emiting user data.
        this._impersonatingShipperAdminUserLogin$.pipe(
          // Ignore the current value of the BehaviorSubject.
          skip(1),
          take(1)
        ).subscribe(() => this._user.next(user));
      
        this.setCurrentImpersonatingShipperAdminUser();
      
        return this._user.pipe(skip(1), take(1));
      })
    );
    return result;
  }
  
  public convertTokenToUser(accessToken: AccessToken, isStored = false): User{

    const lastLoginDateTime = accessToken.last_login_date_time ? new Date(accessToken.last_login_date_time) : null;
      
    const user: User = {
      login: accessToken.sub,
      name: accessToken.full_name,
      roleKeys: accessToken.role_keys,
      tenantId: accessToken.tenant_id,
      shipperAdminUserId: accessToken.shipper_admin_user_id,
      lastLoginDateTime,
      lastPasswordChangeDateTimeUtc: accessToken.last_password_change_date_time ? new Date(accessToken.last_password_change_date_time) : null,
      id: accessToken.user_id,
      // If the token was stored, don't consider it a "first login", even if `lastLoginDate` is null.
      // I.e.: refreshing the page won't be considered a first login.
      // If DPD requests otherwise, it will be a CR for: https://jira.fores.group/browse/SHIPPER-579.
      isFirstLogin: !isStored && !Boolean(lastLoginDateTime)
    };
    return user;
  }

  private setCurrentImpersonatingShipperAdminUser() {
    this._usersService.getCurrentImpersonatingShipperAdminUser().subscribe(login => {
      this._impersonatingShipperAdminUserLogin$.next(login && (login != "" || login != null) ? login : null);
    });
  }


  private refreshAccessToken(): Observable<User> {
    this._loggingService.logDebug("Refreshing access token with refresh token " + this._tokensStorage.getRefreshToken() + ".");
    const content = "refresh_token=" + encodeURIComponent(this._tokensStorage.getRefreshToken()) + "&grant_type=refresh_token&client_id=" + CLIENT_ID;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      })
    };

    return this._http.post(API_URL + "/token", content, httpOptions).pipe(
      mergeMap(result => {
        // @Adam - Need aditional check
        // let data = result.json() as TokenRequestResult;
        const data = result as TokenRequestResult;

        this._loggingService.logDebugData(data, "Received token request result:");


        this._tokensStorage.setAccessToken(data.access_token);
        this._tokensStorage.setRefreshToken(data.refresh_token);

        // Máme štruktúru, ktorá obsahuje aj access_token a refresh_token.
        // Access token obsahuje zakódované údaje používateľa.
        // Toto všetko musíme vyextrahovať.
        const accessToken = jwt_decode(data.access_token) as AccessToken;

        this.setAccessTokenRefreshTimeout(accessToken);

        return this.setUser(accessToken);
      }),
      catchError(ex => {
        let response: any = null;
        const exceptionInfo: ExceptionInfo = { key: ExceptionKeys.GeneralException };

        try {
          response = ex.json();

          exceptionInfo.key = response.error;
        } catch (unwrapError) {
          // Pravdepodobne sme nedostali JSON string v response body.
          exceptionInfo.key = ExceptionKeys.GeneralException;
        }

        this.clearUser();

        // return Observable.throw(exceptionInfo);
        return throwError(exceptionInfo);
      })
    );
  }


  /**
   * Zavolá reinicializáciu používateľských údajov z access tokenu uloženého v local storage.
   * Ak tam nie je žiaden access token, tak sa snaží použiť refresh token (ak ho nájde),
   * na získanie access tokenu.
   *
   * @returns Promise<boolean>
   */
  public reinitializeFromTokenStorage: () => Promise<boolean> = (): Promise<boolean> => {
    if (this._wasReinitialized) {
      return Promise.resolve(true);
    }

    this._wasReinitialized = true;

    this._loggingService.logDebug("Reinitializing user data from access token.");

    const p = new Promise<boolean>((resolve, reject) => {
      if (this._tokensStorage.hasAccessToken()) {
        this._loggingService.logDebug("Access token found, deserializing access token data.");

        const accessToken = jwt_decode(this._tokensStorage.getAccessToken()) as AccessToken;

        const exp = accessToken.exp * 1000;
        const now = (new Date()).getTime();

        if (exp < now) {
          this._loggingService.logDebug("Access token expired, refreshing with refresh token.");
          // Access token vypršal, skúsime získať novy s pomocou refresh tokenu.
          this.refreshAccessToken()
            .subscribe(
              () => { resolve(true); },
              () => { resolve(false); });
        } else {
          this._loggingService.logDebug("Setting user data.");

          this.setAccessTokenRefreshTimeout(accessToken);

          this.setUser(accessToken, true).subscribe(
            () => { resolve(true); }, 
            () => { resolve(false);}
          );
        }
      } else if (this._tokensStorage.hasRefreshToken()) {

        this.refreshAccessToken().subscribe(() => { resolve(true); }, () => { resolve(false); });
      } else {
        this._loggingService.logDebug("No access or refresh token found.");

        resolve(true);
      }
    });

    return p;
  }


  private checkTokens() {
    if (!this._tokensStorage.hasAccessToken()) {

      if (this._user.getValue() != null) {
        this._user.next(null);
      }

      return false;
    }

    return true;
  }


  /**
   * Umelo vyvoláme notifikáciu o zmene používateľa.
   * Potrebujeme to v niektorých scenároch, keďže this.user je hot observable
   * a nechceme sa resubscribovať zbytočne.
   */
  public touchUser = () => {
    this._user.next(this._user.getValue());
  }


  private clearUser = () => {
    this._user.next(null);
    if (this._tokenRefreshTimeout) {
      clearTimeout(this._tokenRefreshTimeout);
    }

    this._tokensStorage.clearAccessToken();
    this._tokensStorage.clearRefreshToken();
  }

  private initCurrentUserModel() {
    this._user.pipe(
      switchMap(user => user ? this._usersService.getUser(user.id) : of(null)),
    ).subscribe((user: UserModel | null) => {
      this._currentUserModel$.next(user);

      if (user) {
        this._isAdmin$.next(user.roles.some(r => r === UserRole.TenantAdmin || r === UserRole.MasterAdmin));
        this._isMasterAdmin$.next(user.roles.includes(UserRole.MasterAdmin));
        this._isCustomerService$.next(user.roles.includes(UserRole.CustomerService));
      }
    });
  }

  private initCurrentUserAllowedOperations() {
    this._user.pipe(
      switchMap(user => user ? this._usersService.getCurrentUserAllowedOperations() : of([])),
    ).subscribe(result => this._currentUserAllowedOperations$.next(new Set(result)));
  }

  /**
   * Logovanie Demo usera po chybe po mazani DB - Shipper-1292
   *
   */
  public logInDemoUser(): Observable<User> {
    const content = "username=" + encodeURIComponent(DEMO_USER_LOGIN) + "&password=" + encodeURIComponent("") + "&grant_type=password&client_id=" + CLIENT_ID;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      })
    };

    return this._http.post(API_URL + "/token", content, httpOptions).pipe(
      mergeMap(result => {
        const data = result as TokenRequestResult;

        this._loggingService.logDebug("Received token request result:");    // redundant funkcie prepisat
        this._loggingService.logDebugData(data);

        // Máme štruktúru, ktorá obsahuje aj access_token a refresh_token.
        // Access token obsahuje zakódované údaje používateľa.
        // Toto všetko musíme vyextrahovať.
        const accessToken = jwt_decode(data.access_token) as AccessToken;

        this._tokensStorage.setAccessToken(data.access_token);
        this._tokensStorage.setRefreshToken(data.refresh_token);

        this.setAccessTokenRefreshTimeout(accessToken);
        
        let demoUser = this.convertTokenToUser(accessToken, false);

        return of(demoUser);
      }),
      catchError((ex: HttpErrorResponse) => {
        this._loggingService.logErrorData(ex);

        const exceptionInfo: ExceptionInfo = { key: ExceptionKeys.GeneralException };

        try {
          if (ex.error.error) {
            exceptionInfo.key = ex.error.error;
          } else {
            exceptionInfo.key = ExceptionKeys.GeneralException;
          }
        } catch (unwrapError) {
          // Pravdepodobne sme nedostali JSON string v response body.
          exceptionInfo.key = ExceptionKeys.GeneralException;
        }
        return throwError(exceptionInfo);
      })
    );
  }
}