import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { getExceptionInfoFromResponse } from "../response-helpers";
import { StringHelpers } from "../string-helpers";
import { LoggingService } from "./logging.service";

// import { getExceptionInfoFromResponse, LoggingService, StringHelpers } from "./../index"


export class ApiServiceBase {
  constructor(protected loggingService: LoggingService) {

  }


  /**
   * Wrapper na zjednodušenie spracovania HTTP requestov.
   * 
   * @param apiRequest Observable HTTP request.
   * @param mapper Funkcia, ktorá transformuje dáta vrátené z requestu (už po preklopení z JSON-u na JS objekty).
   */
  // public processRequest<T>(apiRequest: Observable<Response>, mapper?: (data: any) => T): Observable<T> {
    public processRequest<T>(apiRequest: Observable<T | Object>, mapper?: (data: any) => T): Observable<T> {
      return apiRequest.pipe(
        map((response: T) => {
          // return response.json();
          return response;
        }),
        map(data => {
          var typedData = data as T;
  
          // Upraceme časové a dátové reťazce na Date objekty.
          StringHelpers.convertDateStringsToDates(typedData);
  
          if (mapper) {
            typedData = mapper(typedData);
          }
  
          return typedData;
        }),
        catchError(ex => {
          this.loggingService.logDebugData(ex);
          var exception = getExceptionInfoFromResponse(ex);
  
          return throwError(exception);
        })
      )
    }


  /**
 * Wrapper na zjednodušenie spracovania HTTP requestov ako binary Blob.
 * 
 * @param apiRequest Observable HTTP request.
 */
    // public processBlobRequest(apiRequest: Observable<Response>): Observable<Blob> {
      public processBlobRequest(apiRequest: Observable<Blob>): Observable<Blob> {
        return apiRequest.pipe(
          map((response: Blob) => {
            // return response.blob();
            return response;
          }),
          catchError(ex => {
            this.loggingService.logDebugData(ex);
            var exception = getExceptionInfoFromResponse(ex);
    
            // return Observable.throw(exception);
            return throwError(exception);
          })
        )
      }
}