import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ 
  name: 'iban' 
})
export class IbanPipe implements PipeTransform {
    transform(value: string): string {
        let ibanValue: string = value.replace(/ /g, "");

        ibanValue = ibanValue.replace(/(.{4})/g, "$1 ");
        return ibanValue;
    }

    parse(value: string): string {
        let ibanValue: string = value.replace(" ", "");

        ibanValue = ibanValue.replace(/ /g, "");
        return ibanValue;
    }
}