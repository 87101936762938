import { HttpErrorResponse } from "@angular/common/http";
import { ExceptionInfo, ExceptionKeys } from "./exceptions";
// import { ExceptionInfo, ExceptionKeys } from "./../shared/index";


/**
 * Metóda vráti informáciu o chybe, ktorá prišla zo servera.
 * Ak server poslal JSON zodpovedajúci IExceptionInfo, tak vrátime ten,
 * ináč vrátime IExceptionInfo s generickým chybovým hlásením.
 *
 * @param errorResponse Odpoveď zo servera, ktorá obsahuje info o chybe.
 */
export function getExceptionInfoFromResponse(errorResponse: HttpErrorResponse): ExceptionInfo {
  try {
    // let exception = errorResponse.json();
    let exception = errorResponse.error;

    // Ak má objekt key atribút, tak si povieme, že je pravdepodobne ExceptionInfo objekt.
    if (exception.key) {
      return exception;
    }

    // Ináč si musíme ExceptionInfo vytvoriť, lebo nám došiel pravdepodobne serializovaný .NET Exception.
    return {
      httpStatusCode: errorResponse.status,
      message: exception.exceptionMessage,
      key: ExceptionKeys.GeneralException,
      stackTrace: exception.stackTrace
    };
  } catch (e) {
    // Prišlo nam niečo, čo sa nedá deserialozvať - pravdepodobne fatálna chyba
    // na strane webového servera a neprebla ani serializácia v rámci pipelinu spracovania
    // requestu.
    console.log("Error handling exception: ", e, errorResponse);

    return {
      httpStatusCode: errorResponse.status,
      message: errorResponse.statusText,
      key: ExceptionKeys.GeneralException,
      stackTrace: ""
    };
  }
}